import {
  TOGGLE_LOGIN,
  TOGGLE_FORGOT,
  TOGGLE_RESET,
  TOGGLE_SIGNUP,
  SIGNUP_PROCESS,
    ADD_BRAND
} from "../action/authSetting.actions";

const initialSettings = {
  login:false,
  register:false,
  forgot:false,
  reset:false,
  signupProcess:false,
  addBrand:false
};

export function authSetting(state = initialSettings, action) {
    switch (action.type) {

      case TOGGLE_LOGIN:
        let loginValue=!state.login;

        if(action.value){
          if(action.value===1) {
            loginValue = true
          }else{
            loginValue = false
          }
        }
        return Object.assign({},initialSettings,{login:loginValue});

      case TOGGLE_SIGNUP:
        let registerValue=!state.register;
        if(action.value){
          if(action.value===1) {
            registerValue = true;
          }else{
            registerValue = false;
          }
        }
        return Object.assign({},initialSettings,{register:registerValue});

      case TOGGLE_FORGOT:
        let forgotValue=!state.forgot;
        if(action.value){
          if(action.value===1) {
            forgotValue = true;
          }else{
            forgotValue = false;
          }
        }
        return Object.assign({},initialSettings,{forgot:forgotValue});

      case TOGGLE_RESET:
        let resetValue=!state.reset;
        if(action.value){
          if(action.value===1) {
            resetValue = true;
          }else{
            resetValue = false;
          }
        }
        return Object.assign({},initialSettings,{reset:resetValue});

      case SIGNUP_PROCESS:
        let signupprocessValue=!state.signupProcess;
        if(action.value){
          if(action.value===1) {
            signupprocessValue = true;
          }else{
            signupprocessValue = false;
          }
        }
        return Object.assign({},initialSettings,{signupProcess:signupprocessValue});

      case ADD_BRAND:
        let addBrandValue=!state.addBrand;
        if(action.value){
          if(action.value===1) {
            addBrandValue = true;
          }else{
            addBrandValue = false;
          }
        }
        return Object.assign({},initialSettings,{addBrand:addBrandValue});

      default:
        return state
  }
}

