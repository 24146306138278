import {
    IS_LOADING_START,
    IS_LOADING_STOP,
    GET_BRAND_DATA,
    GET_HOME_DATA,
    GET_LOUNGE_DATA,
    GET_CONFERENCE_DATA,
    GET_EXHIBITION_ID_SUCCESS,
    SHOW_SUPPORT_CHAT,
    HIDE_SUPPORT_CHAT,
    SHOW_PLAN_USER_POPUP,
    HIDE_PLAN_USER_POPUP
} from "../action/Type";


const initalState = {
    exhibitionId:null,
    eventType:0,
    isLoading: false,
    brandHome: null,
    homeHome: null,
    network:null,
    conference:null,
    messageCount:null,
    supportNotify:false,
    isSupportChat:false,
    showUserPlanPopup:false,
    start_Date: null,
    end_Date: null,
    timeZone:''
}

// login
export const loading = (state = initalState, action) => {
    // console.log("dkjsdghasjk", action?.payload)
    switch (action.type) {        
        case HIDE_PLAN_USER_POPUP:
            return {
                ...state, 
                showUserPlanPopup: false           
            }   
        case SHOW_PLAN_USER_POPUP:
            return {
                ...state, 
                showUserPlanPopup: true           
            }   
        case SHOW_SUPPORT_CHAT:
            return {
                ...state, 
                isSupportChat: true           
            } 
        case HIDE_SUPPORT_CHAT:
                return {
                    ...state, 
                    isSupportChat: false           
                } 
        case 'SET_COUNT':
            return {
                ...state, 
                messageCount: action.payload.req            
            }   
            case 'SET_COUNT_SUPPORT':
            return {
                ...state, 
                supportNotify: action.payload.req            
            }      
        case GET_EXHIBITION_ID_SUCCESS:
            return {
                ...state,
                exhibitionId : action?.payload?.exhibitionId,
                eventType: action?.payload?.eventType,
                start_Date: action?.payload?.start_Date,
                end_Date: action?.payload?.end_Date,
                timezone:action?.payload?.timezone,
            }
        case GET_CONFERENCE_DATA:
            return {
                ...state,
                conference: action?.payload
            }
        case GET_LOUNGE_DATA:
            return {
                ...state,
                network: action?.payload
            }
        case GET_BRAND_DATA:
            return {
                ...state,
                brandHome: action?.payload
            }
        case GET_HOME_DATA:
            return {
                ...state,
                homeHome: action?.payload
            }
        case IS_LOADING_START:
            return {
                ...state,
                isLoading: true,
            }

        case IS_LOADING_STOP:
            return {
                ...state,
                isLoading: false,
            }

        default:
            return state
    }

}