import React, {useEffect, useState} from 'react';
import { NavLink, Link , useLocation} from 'react-router-dom';
import { Menu } from 'antd';
import { MenuIcon, PaymentIcon, HalfLogo, SearchIcon, ExhibitionManagement, AdManagement, Brands, CategoryManagement, CommunityManagement, UserManagementIcon, PromoCodeIcon, Cross, Setting } from '../../SvgIcon'
const { SubMenu } = Menu;
const rootSubmenuKeys = ['sub1', 'sub2', 'sub3', 'sub4'];

const Sidebar = () => {
  // const rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];
  const handleMenuOpen = () => document.body.classList.toggle('menu_open')
  const handleCloseMenu = () => document.body.classList.remove('menu_open')
  // const [submenu, setSubMenu] = useState('1');
  const { pathname } = useLocation();
  

  const [openKeys, setOpenKeys] = React.useState([]);

  const onOpenChange = keys => {
    console.log('keys', keys);
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
    console.log('keys');
    if(keys[keys.length - 1]) {
      localStorage.setItem('menuOpenKeys',`${keys[keys.length - 1]}`)
    }
  };
  const onSelect = ({ item, key, keyPath, selectedKeys, domEvent }) => {
    if(keyPath.length === 1) {
      setOpenKeys(keyPath)
      localStorage.removeItem('menuOpenKeys')
    }
    console.log('select keys',{ item, key, keyPath, selectedKeys, domEvent });
  };

  const onClick = ({ item, key, keyPath, domEvent }) => {
    console.log('onclick select keys',{ item, key, keyPath, domEvent });
  };

  useEffect( () => {
    setOpenKeys([`${localStorage.getItem('menuOpenKeys')}`]);
  }, [])
  

  return (


    <> 
      <div className="mobile_trigger order-1" >
        <span className="menuicon cur-point" onClick={handleMenuOpen}><MenuIcon onClick={handleMenuOpen} /></span>
      </div>
      <div className="sidebar">
        <div className="cm-logo text-center">
          <Link to="/exhibition"><span className="logo"><HalfLogo /></span>professionalbeauty.world</Link>
        </div>

        <div className="cross float-right pr-3" onClick={handleCloseMenu}>
          <Cross />
        </div>

        <Menu
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          onClick={onClick}
          onSelect={onSelect}
          mode="inline"
          className="menu-wrapper-admin-sidebar"
        > 
          <Menu.Item key="1">
            <NavLink exact to="/exhibition" isActive={() => ['exhibition', 'professional-beauty-world', 'conferencedetails','exhibitiondetails'].includes(pathname.split('/')[1])}  onClick={handleCloseMenu}><span><ExhibitionManagement /></span><span className="menu_text">Exhibition management</span></NavLink>
          </Menu.Item>
          <Menu.Item key="2">
            <NavLink to="/businesses" 
            isActive={() => ['businesses', 'business'].includes(pathname.split('/')[1])}       
            onClick={handleCloseMenu}><span><Brands /></span><span className="menu_text">Businesses</span></NavLink>
          </Menu.Item>
          <Menu.Item key="3">
            <NavLink to="/user-management" isActive={() => ['user-profile','user-management' ].includes(pathname.split('/')[1])}     onClick={handleCloseMenu}><span><UserManagementIcon /></span><span className="menu_text">User Management</span></NavLink>
          </Menu.Item>
          
          <SubMenu title="Setting" icon={<Setting />} key="sub1" >
            <Menu.Item key="4">
              <NavLink to="/jobcategory" isActive={() => ['jobcategory',].includes(pathname.split('/')[1])} onClick={handleCloseMenu}><span><Brands /></span><span className="menu_text">Job Category</span></NavLink>
            </Menu.Item>
            <Menu.Item key="5">
              <NavLink isActive={() => ['questionnaire','addQuestionnaire'].includes(pathname.split('/')[1])} to="/questionnaire" onClick={handleCloseMenu}><span><SearchIcon /></span><span className="menu_text"></span>Questionnaire</NavLink>   
            </Menu.Item>
            <Menu.Item key="6">
              <NavLink isActive={() => ['sector'].includes(pathname.split('/')[1])} to="/sector" onClick={handleCloseMenu}><span><CategoryManagement /></span><span className="menu_text"></span>Sector</NavLink>
            </Menu.Item>
            <Menu.Item key="7">
              <NavLink isActive={() => ['placeofwork'].includes(pathname.split('/')[1])} to="/placeofwork" onClick={handleCloseMenu}><span><CategoryManagement /></span><span className="menu_text"></span>Place of Work</NavLink>
            </Menu.Item>
            <Menu.Item key="8">
              <NavLink isActive={() => ['role'].includes(pathname.split('/')[1])}
                to="/role" onClick={handleCloseMenu}><span><CategoryManagement /></span><span className="menu_text"></span>Role</NavLink>
            </Menu.Item>
            <Menu.Item key="9">
              <NavLink  isActive={() => ['subscription-plan'].includes(pathname.split('/')[1])} to="/subscription-plan" onClick={handleCloseMenu}><span><CategoryManagement /></span><span className="menu_text"></span>Subscription Plan</NavLink>
            </Menu.Item>
            <Menu.Item key="10">
              <NavLink  isActive={() => ['add-search','search','view-search'].includes(pathname.split('/')[1])}            
            to="/search" onClick={handleCloseMenu}><span><SearchIcon /></span><span className="menu_text">Search Management</span></NavLink>
            </Menu.Item>
          </SubMenu>
          <SubMenu title="Payment" icon={<PaymentIcon />} key="sub2">
            <Menu.Item key="11">
              <NavLink
                isActive={() => ['add-promo-code','promo-code'].includes(pathname.split('/')[1])}          
                to="/promo-code" onClick={handleCloseMenu}><span><PromoCodeIcon /></span><span className="menu_text">Activation Code</span></NavLink>
            </Menu.Item>
            <Menu.Item key="12">
              <NavLink isActive={() => ['transactionlist','transactiondetail'].includes(pathname.split('/')[1])}           
                to="/transactionlist" onClick={handleCloseMenu}><span><PaymentIcon /></span><span className="menu_text">Transaction</span>
                </NavLink>
            </Menu.Item>
          </SubMenu>
          <SubMenu title="Feature Management" icon={<Brands />} key="sub3">
            <Menu.Item key="13">
              <NavLink isActive={() => ['Add-feature-businesslist','feature-businesslist'].includes(pathname.split('/')[1])} to="/feature-businesslist" onClick={handleCloseMenu}><span><Brands /></span><span className="menu_text"></span>Featured Businesses</NavLink>
            </Menu.Item>
            <Menu.Item key="14">
              <NavLink  isActive={() => ['feature-joblist'].includes(pathname.split('/')[1])}  to="/feature-joblist" onClick={handleCloseMenu}><span><Brands /></span><span className="menu_text"></span>Popular Jobs</NavLink>
            </Menu.Item>
            <Menu.Item key="15">
            <NavLink isActive={() => ['Add-feature-joblist','manage-stages'].includes(pathname.split('/')[1])} to="/manage-seminar" onClick={handleCloseMenu}><span><ExhibitionManagement /></span><span className="menu_text"></span>Manage Seminars</NavLink>
            </Menu.Item>
            <Menu.Item key="16">
            <NavLink 
              to="/manage-lounges" onClick={handleCloseMenu}><span><ExhibitionManagement /></span><span className="menu_text"></span>Manage Lounges</NavLink>
            </Menu.Item>
          </SubMenu>
          <SubMenu title="Marketing" icon={<CommunityManagement />} key="sub4">
            <Menu.Item key="17">
              <NavLink isActive={() => ['ads-details','ads' ].includes(pathname.split('/')[1])} exact to="/ads" onClick={handleCloseMenu}><span><AdManagement /></span><span className="menu_text">Ad Management</span></NavLink>
            </Menu.Item>
            <Menu.Item key="18">
              <NavLink isActive={() => ['reminder'].includes(pathname.split('/')[1])}
                to="/reminder" onClick={handleCloseMenu}><span><CategoryManagement /></span><span className="menu_text"></span>Reminder</NavLink>
            </Menu.Item>
            <Menu.Item key="19">
              <NavLink isActive={() => ['analytics'].includes(pathname.split('/')[1])}
                to="/analytics" onClick={handleCloseMenu}><span><AdManagement /></span><span className="menu_text"></span>Analytics</NavLink>
            </Menu.Item>
            <Menu.Item key="20">
              <NavLink isActive={() => ['notification-list'].includes(pathname.split('/')[1])}
                  to="/notification-list" onClick={handleCloseMenu}><span><CategoryManagement /></span><span className="menu_text"></span>Notification</NavLink>
            </Menu.Item>
            <Menu.Item key="21">
              <NavLink
                  isActive={() => ['invite'].includes(pathname.split('/')[1])}
                  to="/invite" onClick={handleCloseMenu}><span><ExhibitionManagement /></span><span className="menu_text"></span>Invite</NavLink>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="22">
            <NavLink to="/user-report" onClick={handleCloseMenu}><span><UserManagementIcon /></span><span className="menu_text"></span>Reported User </NavLink>
          </Menu.Item>
        </Menu>




        <ul className="d-none">
          {/* <li>
            <NavLink
            isActive={() => ['transactionlist','transactiondetail'].includes(pathname.split('/')[1])}           
          to="/suport-admin" onClick={handleCloseMenu}><span></span><span className="menu_text">Support dashboard</span>
          </NavLink>
          </li> */}
          <li><NavLink exact to="/exhibition" 
            isActive={() => ['exhibition', 'professional-beauty-world', 'conferencedetails','exhibitiondetails'].includes(pathname.split('/')[1])}  
          
          onClick={handleCloseMenu}><span><ExhibitionManagement /></span><span className="menu_text">Exhibition management</span></NavLink></li>
          <li><NavLink 
           isActive={() => ['ads-details','ads' ].includes(pathname.split('/')[1])}  
          
          exact to="/ads" onClick={handleCloseMenu}><span><AdManagement /></span><span className="menu_text">Ad Management</span></NavLink></li>
          <li><NavLink to="/businesses" 
          isActive={() => ['businesses', 'business'].includes(pathname.split('/')[1])} 
          // isActive={() => pathname.split('/').includes('brand')
          // path={["/business", "/business"]}
        //  }          
          onClick={handleCloseMenu}><span><Brands /></span><span className="menu_text">Businesses</span></NavLink></li>

          
           <li><NavLink to="/jobcategory" 
          isActive={() => ['productcategory',].includes(pathname.split('/')[1])}
             
          onClick={handleCloseMenu}><span><Brands /></span><span className="menu_text">Job Category</span></NavLink></li>         
          <li><NavLink to="/user-management"
           isActive={() => ['user-profile','user-management' ].includes(pathname.split('/')[1])}          
          onClick={handleCloseMenu}><span><UserManagementIcon /></span><span className="menu_text">User Management</span></NavLink></li>
          <li><NavLink
           isActive={() => ['add-promo-code','promo-code'].includes(pathname.split('/')[1])}          
          to="/promo-code" onClick={handleCloseMenu}><span><PromoCodeIcon /></span><span className="menu_text">Activation Code</span></NavLink></li>
          <li>
            <NavLink
            isActive={() => ['transactionlist','transactiondetail'].includes(pathname.split('/')[1])}           
          to="/transactionlist" onClick={handleCloseMenu}><span><PaymentIcon /></span><span className="menu_text">Transaction</span>
          </NavLink>
          </li>
          <li><NavLink
           isActive={() => ['add-search','search','view-search'].includes(pathname.split('/')[1])}            
          to="/search" onClick={handleCloseMenu}><span><SearchIcon /></span><span className="menu_text">Search Management</span></NavLink></li>
          <li><NavLink to="/user-report" onClick={handleCloseMenu}><span><UserManagementIcon /></span><span className="menu_text"></span>Reported User </NavLink></li>
          <li><NavLink 
           isActive={() => ['Add-feature-businesslist','feature-businesslist'].includes(pathname.split('/')[1])}     
          
          to="/feature-businesslist" onClick={handleCloseMenu}><span><Brands /></span><span className="menu_text"></span>Featured Businesses</NavLink></li>
          <li><NavLink 
            isActive={() => ['feature-joblist'].includes(pathname.split('/')[1])}  
          
          
          to="/feature-joblist" onClick={handleCloseMenu}><span><Brands /></span><span className="menu_text"></span>Popular Jobs</NavLink></li>
          {/* <li><NavLink 
            isActive={() => ['Add-feature-joblist','manage-stages'].includes(pathname.split('/')[1])}  
          
          to="/manage-stages" onClick={handleCloseMenu}><span><ExhibitionManagement /></span><span className="menu_text"></span>Manage Stages</NavLink></li> */}
    
    <li><NavLink 
            isActive={() => ['Add-feature-joblist','manage-stages'].includes(pathname.split('/')[1])}  
          
          to="/manage-seminar" onClick={handleCloseMenu}><span><ExhibitionManagement /></span><span className="menu_text"></span>Manage Seminars</NavLink></li>








          <li><NavLink 
          // isActive={() => ['Add-feature-joblist'].includes(pathname.split('/')[1])}  
          to="/manage-lounges" onClick={handleCloseMenu}><span><ExhibitionManagement /></span><span className="menu_text"></span>Manage Lounges</NavLink></li>
          <li><NavLink to="/attendee" onClick={handleCloseMenu}><span><UserManagementIcon /></span><span className="menu_text"></span>Attendee</NavLink></li>
          <li><NavLink
           isActive={() => ['questionnaire','addQuestionnaire'].includes(pathname.split('/')[1])}
          
          to="/questionnaire" onClick={handleCloseMenu}><span><SearchIcon /></span><span className="menu_text"></span>Questionnaire</NavLink></li>
          <li><NavLink
              isActive={() => ['sector'].includes(pathname.split('/')[1])}
              to="/sector" onClick={handleCloseMenu}><span><CategoryManagement /></span><span className="menu_text"></span>Sector</NavLink></li>
          <li><NavLink
              isActive={() => ['placeofwork'].includes(pathname.split('/')[1])}
              to="/placeofwork" onClick={handleCloseMenu}><span><CategoryManagement /></span><span className="menu_text"></span>Place of Work</NavLink></li>
          <li><NavLink
              isActive={() => ['role'].includes(pathname.split('/')[1])}
              to="/role" onClick={handleCloseMenu}><span><CategoryManagement /></span><span className="menu_text"></span>Role</NavLink></li>

               <li><NavLink
              isActive={() => ['reminder'].includes(pathname.split('/')[1])}
              to="/reminder" onClick={handleCloseMenu}><span><CategoryManagement /></span><span className="menu_text"></span>Reminder</NavLink></li>


            <li><NavLink
              isActive={() => ['analytics'].includes(pathname.split('/')[1])}
              to="/analytics" onClick={handleCloseMenu}><span><AdManagement /></span><span className="menu_text"></span>Analytics</NavLink>
            </li>
            <li>
              <NavLink
                isActive={() => ['notification-list'].includes(pathname.split('/')[1])}
                to="/notification-list" onClick={handleCloseMenu}><span><CategoryManagement /></span><span className="menu_text"></span>Notification</NavLink>
            </li>
            <li>
              <NavLink
                isActive={() => ['subscription-plan'].includes(pathname.split('/')[1])}
                to="/subscription-plan" onClick={handleCloseMenu}><span><CategoryManagement /></span><span className="menu_text"></span>Subscription Plan</NavLink>
            </li>
            <li>
              <NavLink
                isActive={() => ['invite'].includes(pathname.split('/')[1])}
                to="/invite" onClick={handleCloseMenu}><span><ExhibitionManagement /></span><span className="menu_text"></span>Invite</NavLink>
            </li>
        </ul>
      </div>
    </>
  )
}

export default Sidebar;