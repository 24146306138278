import React, { Component } from 'react';
import { Hert, ExhibitionManagement, Pencil, Delete } from '../../../../SvgIcon';
import { Link } from "react-router-dom";
import Sidebar from '../../../layout/Sidebar';
import Header from '../../../layout/Header';
import AddStaff from '../popups/AddStaff'
import EditStaff from '../popups/EditStaff'
import EditProduct from '../popups/EditProduct'

import AddProduct from '../popups/AddProduct';
import { Checkbox } from 'antd';


import { _Api } from '../../../../services/Api';
import Url from '../../../../services/BaseUrl';
import { notify } from '../../../../components/Toaster';




class ViewProducts extends Component {

    constructor() {
        super();
        this.state = {
            addStaff: false,
            addProduct: false,
            editStaff: false,
            page: '',
            limit: 10,
            productListData: [],
            productId: ''
        };
    }

    togglePopup(id, productId) {

        if (id === "editProduct") {
            this.setState({
                editProduct: !this.state.editProduct, productId: productId
            });
        }

    }
    componentDidMount() {
        this.getStaffDetails()
        this.getProductDetails()
    }

    getStaffDetails = () => {
        this.setState({ isLoader: true })
        let { page, limit, search } = this.state
        let req = {
            page: page ? page : 1,
            limit: limit,
        }
        _Api(Url.GET_STAFF_LIST.method, Url.GET_STAFF_LIST.url, '', `?page=${req.page}&limit=${req.limit}`)
            .then(resp => {
                console.log('respexList', resp.responseData.result.data)
                this.setState({ StaffListData: resp.responseData.result.data, totalCount: resp.responseData.result.totalCount, isLoader: false })
            }).catch(err => {
                this.setState({ isLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
                this.setState({ isLoader: false })
            })
    }

    getProductDetails = () => {
        this.setState({ isLoader: true })
        let { page, limit, search } = this.state
        let req = {
            page: page ? page : 1,
            limit: limit,
        }
        _Api(Url.GET_PRODUCT_LIST.method, Url.GET_PRODUCT_LIST.url, '', `?page=${req.page}&limit=${req.limit}`)
            .then(resp => {
                console.log('respexList', resp.responseData.result.data)
                this.setState({ productListData: resp.responseData.result.data, totalCount: resp.responseData.result.totalCount, isLoader: false })
            }).catch(err => {
                this.setState({ isLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
                this.setState({ isLoader: false })
            })
    }

    deleteProduct = (id) => {
        let req = {
            isDeleted: 0,
            productId: id,
        }
        _Api(Url.DELETE_PRODUCT.method, Url.DELETE_PRODUCT.url, '', `?productId=${req.productId}&isDeleted=${req.isDeleted}`)
            .then(resp => {
                console.log('delete', resp.responseData.message)
                notify('success', resp.responseData.message)
                this.setState({ visible: false })
                this.getProductDetails()
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
            })
    }

    render() {
        let { StaffListData, productListData } = this.state;
        return (
            <>
                <div className="dash_body_container">
                    <Header />
                    <Sidebar />
                    <div className="body_container">



                        <div className="header-text  mb-4 mt-3">
                            <div className="d-flex align-items-center">
                                <h4>Loreal</h4>
                            </div>

                            {/* <Link target="_blank" to="/user-management" className="btn btn-light">Back</Link> */}
                            <a > <button onClick={()=> this.props.history.goBack()} className="btn btn-light"><i class="fa fa-arrow-left" aria-hidden="true"></i>Back</button></a>



                        </div>
                        <div className="">
                            <div className="rounded-body pr-5 card-wrapper mt-0">


                                <img className="img-fluid w-100" src={require('../../../../assets/images/brand-banner.png')} />

                                {/* <img className="img-fluid ggggggggg" src={require('../../../../assets/images/beauty-logo.png')} /> */}


                                <div className="text-wrapper w-95">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

                                </div>

                                <div className="fragment-wrapper">
                                    <div className="row text-center">

                                        <aside className="col-md-4 ">
                                            <div className="card community-card p-0 no-shadow">
                                                <div className="table-responsive no-shadow">
                                                    <table className="table table-bordered rounded-table">
                                                        <thead>
                                                            <tr>
                                                                <th>Explore by Sectors</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            <tr>
                                                                <td><Checkbox >Cosmetics</Checkbox></td>
                                                            </tr>
                                                            <tr>
                                                                <td><Checkbox >Hair care</Checkbox></td>
                                                            </tr>
                                                            <tr>
                                                                <td><Checkbox >Skin care</Checkbox></td>
                                                            </tr>
                                                            <tr>
                                                                <td><Checkbox >Essential Oils</Checkbox></td>
                                                            </tr>
                                                            <tr>
                                                                <td><Checkbox >Perfumes & Fragnances</Checkbox></td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </aside>

                                        <div className="col-md-8">
                                            <div className="row">
                                                {
                                                    productListData.map((item, index) => {
                                                        return (
                                                            <div className="col-md-4">
                                                                <div className="card community-card">
                                                                    <div className="text-right">
                                                                        <label class="switch">
                                                                            <input type="checkbox" checked />
                                                                            <span class="slider round"></span>
                                                                        </label>
                                                                    </div>



                                                                    <Link to={`/job-details/${item?._id}`}> <img className="img-fluid" src={item?.image} />
                                                                    </Link>

                                                                    <div className="action_group d-flex">
                                                                        <span className="flex-grow-1">{item?.productName} <br />
                                                                            {/* <span className="blue">$50</span> */}
                                                                        </span>
                                                                        <p><p className="circle-serial" onClick={this.togglePopup.bind(this, 'editProduct', item?._id)}><Pencil /></p></p>
                                                                        <p></p><p><p className="circle-serial" onClick={() => this.deleteProduct(item?._id)}><Delete /></p></p>

                                                                    </div>
                                                                 
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }





                                            </div>


                                        </div>

                                    </div>



                                </div>
                            </div>


                        </div>
                    </div>


                </div>








                {
                    this.state.addStaff ?
                        <AddStaff
                            text='Close Me'
                            closePopup={this.togglePopup.bind(this, "addStaff")}
                            getStaff={this.props.getStaffDetails}
                        />
                        : null
                }

                {
                    this.state.addProduct ?
                        <AddProduct
                            text='Close Me'
                            closePopup={this.togglePopup.bind(this, "addProduct")}
                        />
                        : null
                }

                {
                    this.state.editStaff ?
                        <EditStaff
                            text='Close Me'
                            closePopup={this.togglePopup.bind(this, "editStaff")}
                        />
                        : null
                }
                {
                    this.state.editProduct ?
                        <EditProduct
                            text='Close Me'
                            closePopup={this.togglePopup.bind(this, "editProduct")}
                            productId={this.state.productId}
                        />
                        : null
                }

            </>
        );
    }
}
export default ViewProducts;


