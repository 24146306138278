import React from 'react'
import { DatePicker, TimePicker, Select } from 'antd';
import { CircleCross, Pencil } from '../../../../SvgIcon';
import { _Api } from '../../../../services/Api';
import Url from '../../../../services/BaseUrl';
import InlineError from '../../../../components/InlineError';
import { notify } from '../../../../components/Toaster'

import { validateEmail } from '../../../../components/Validation'

import { MySpinner } from "../../../../components/index"

import PhoneInput from 'react-phone-input-2';

import Croper from "../../../../components/Croper";


class AddStaff extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            staffName: '',
            jobProfile: '',
            profileImage: '',
            staffNameError: '',
            jobProfileError: '',
            profileImageError: '',
            email: '',
            emailError: '',
            phoneNumber: '',
            phoneNumberError: '',
            isLoading: false,
            code:'',
            lastName:'',
            isCropper: false,
        }
    }
    staffOnChnage = (e) => {
        this.setState({ [e.target.name]: e.target.value, staffNameError: '', jobProfileError: '', emailError: '', phoneNumberError: '' })
    }

    componentDidMount() {

        document.body.style.overflow = 'hidden'; //prevent background scrolling

        // console.log('sjkdljdjkfldsjf', this.props.staffData)
        

        if (this.props.isEdit) {
            console.log("kjhgdhjkadhgvb", this.props.staffData)

            let data = this.props.staffData

            this.setState({ 
                staffName: `${data.firstName}`,
                lastName: `${data.lastName}`,
                jobProfile: data.jobTitleOther,
                email: data.email,
                isLoading: false,
                profilePicPreview: data.image ? data.thumbUrl : '',
                phoneNumber: data.phoneNumber ? String(data.phoneNumber) : ''

            })




        }

    }

    componentWillUnmount() {
        document.body.style.overflow = 'auto';

    }

    AddStaff = () => {
        if (this.props.isEdit)
            this.editStaff()
        else
            this.saveStaff()


    }



    editStaff = () => {
        let { staffName, jobProfile, profileImage, email, phoneNumber, lastName } = this.state
        if (staffName == '')
            this.setState({ staffNameError: '*Please enter staff name.' })

        else if (phoneNumber == '')
            this.setState({ phoneNumberError: '*Please enter phone number.' })

        else if (email == '')
            this.setState({ emailError: '*Please enter email.' })
        else if (!validateEmail(email).status)
            this.setState({ emailError: "*Please enter valid email." })
        else if (jobProfile == '')
            this.setState({ jobProfileError: '*Please enter job profile' })
        else {
            this.setState({ isLoading: true })
            let formData = new FormData()
            formData.append('userType', 4)
            formData.append('requestUserId', this.props.staffData._id)
            // formData.append('ownerId', this.props.match.params.brandId)
            formData.append('email', email) 
            formData.append('phoneNumber', phoneNumber)
            formData.append("firstName", staffName)
            formData.append("lastName", lastName)
            formData.append('jobTitleOther', jobProfile)
            formData.append('image', profileImage)
            _Api(Url.UPDATE_USER_INFO.method, Url.UPDATE_USER_INFO.url, formData)
                .then(resp => {
                    this.props.closePopup()
                    this.props.getStaff()
                    this.setState({ isLoading: false })
                    notify('success', resp.responseData.message)
                }).catch(err => {
                    this.setState({ isLoading: false })
                    if (err.error)
                        notify("err", err.error.responseMessage)
                   // else if (err)
                //     // notify("err", err.message)

                })
        }
    }




    saveStaff = () => {
        let { staffName, jobProfile, profileImage, email, phoneNumber, lastName } = this.state
        if (staffName.trim() == '')
            this.setState({ staffNameError: '*Please enter staff name.' })

        else if (phoneNumber.trim() == '')
            this.setState({ phoneNumberError: '*Please enter phone number.' })

        else if (email.trim() == '')
            this.setState({ emailError: '*Please enter email.' })
        // else if (!validateEmail(email).status)
        //     this.setState({ emailError: "*Please enter valid email." })
        else if (jobProfile.trim() == '')
            this.setState({ jobProfileError: '*Please enter job profile' })
        else {
            this.setState({ isLoading: true })
            let formData = new FormData()
            formData.append('userType', 4)
            formData.append('ownerId', this.props.match.params.brandId)
            formData.append('email', email) 
            formData.append('countryCode',  this.state.code)
            formData.append('phoneNumber', phoneNumber)
            formData.append("firstName", staffName)
            formData.append("lastName", lastName)
            formData.append('jobTitleOther', jobProfile)
            formData.append('image', profileImage)
            _Api(Url.ADD_NEW_STAFF.method, Url.ADD_NEW_STAFF.url, formData)
                .then(resp => {
                    this.props.closePopup()
                    this.props.getStaff()
                    this.setState({ isLoading: false })
                    notify('success', resp.responseData.message)
                }).catch(err => {
                    this.setState({ isLoading: false })
                    if (err.error)
                        notify("err", err.error.responseMessage)
                   // else if (err)
                //     // notify("err", err.message)

                })
        }
    }

    handleImageUpload = (event) => {
        // console.log("kdjhgdhjaksjshdjsabbdask")
        let reader = new FileReader();
        let file = event.target.files[0];
        let { valiDate } = this.state;
        reader.addEventListener("load", () => {
            this.setState({ profilePicPreview: reader.result }, function () {
            })
        }, false);
        if (file) {
            reader.readAsDataURL(file)
        }
        if (file) {
            console.log(event, "media1", event.target.files[0])
            if (file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png') {
                console.log("media2", event.target.files[0], file.size)
                if (file.size > 2000000) {
                    console.log("lkjhgfdsdfghjklkjhgfghjkmnbvccvbnm>>>>>>>>>>>>>>>>>>")
                    valiDate = false;
                    console.log(event, "media", event.target.files[0])
                    this.setState({
                        profileImage: event.target.files[0],
                        profilePicErrorMsg: 'Profile pic size should be less than 5Mb.'
                    });
                } else {
                    console.log("media3hhhjshs", event.target.files[0], file.size)
                    this.setState({
                        profileImage: event.target.files[0],
                        profileImageError: ''
                    });
                }
            } else {
                valiDate = false;
                this.setState({ media: 'File type not supported.' })

            }
        }
    }


    finalCropedImage = (categoryImgPrev, categoryImg, visibleVal) => {       
        this.setState({
            visibleVal,
            isCropper: false,
            profilePicPreview: categoryImgPrev,
            profileImage: categoryImg

        })
    }



    render() {        
        let { staffName,lastName, jobProfile, staffNameError, jobProfileError, profileImageError, email, phoneNumber, emailError, phoneNumberError, } = this.state
        return (
            <div className='popup'>
                <div className='popup_inner small form'>
                    <div className="mt-4 mb-4">
                        <div className="rounded-textbox-form">

                            <div className="header-text">
                                <h4> {this.props.isEdit ? "Edit Staff" : "Add Staff"}</h4>
                            </div>

                            <div className="upload-wrapper pos-relative" onClick={()=> this.setState({isCropper:true})}>
                                <label className="upload-photo-wrapper upload-container-pic">

                                    {this.state.profilePicPreview ?
                                        <span> <img src={this.state.profilePicPreview} alt="category image" /></span> : <>  <span> <img className="img-fluid" src={require('../../../../assets/images/smallPlaceholder.png')} /></span>
                                        </>}
                                   
                                    <div className="upload-wrapper edit-icon">
                                        <span className="upload-photo-wrapper" > <p className="m-0"><i><Pencil /></i></p></span>
                                    </div>
                                </label>






                            </div>

                            {/*<div className="upload-wrapper">*/}

                            {/*    <label for="file-upload" className="upload-photo-wrapper">*/}
                            {/*        <span> <p className="m-0"><i className="fa fa-cloud-upload"></i></p></span>*/}
                            {/*        <p className="m-0"> Add Photo</p>*/}
                            {/*    </label>*/}

                            {/*    <input id="file-upload" type="file"*/}


                            {/*        onChange={this.handleImageUpload}*/}
                            {/*    />*/}

                            {/*</div>*/}


                            <div className="form-group">
                                <p>First Name</p>
                                <input type="text"
                                    className="form-control"
                                    placeholder="First Name"
                                    maxLength={64}
                                    autoComplete="off"
                                    name="staffName"
                                    value={staffName}
                                    onChange={this.staffOnChnage}
                                />
                                <InlineError
                                    message={staffNameError}
                                />
                                 <p>Last Name</p>
                                <input type="text"
                                    className="form-control"
                                    placeholder="Last Name"
                                    maxLength={64}
                                    autoComplete="off"
                                    name="lastName"
                                    value={lastName}
                                    onChange={this.staffOnChnage}
                                />
                                {/* <InlineError
                                    message={staffNameError}
                                /> */}
                            </div>


                            <div className="form-group">
                                <p>Phone Number</p>
                                {/* <input type="number"
                                    className="form-control"
                                    placeholder="Phone Number"
                                    maxLength={64}
                                    name="phoneNumber"
                                    value={phoneNumber}
                                    onChange={this.staffOnChnage}
                                /> */}
                                <PhoneInput
                                    country={'ae'}
                                    placeholder="Phone number"
                                    enableLongNumbers="false"
                                    autoFormat="false"
                                    value={this.state.phoneNumber}
                                    onChange={(phoneNumber, code) => this.setState({ phoneNumber, phoneNumberError: '', code: code.dialCode })}
                                />
                                <InlineError
                                    message={phoneNumberError}
                                />
                            </div>

                            <div className="form-group">
                                <p>Email</p>
                                <input type="text"
                                    className="form-control"
                                    placeholder="Email"
                                    maxLength={64}
                                    name="email"
                                    value={email}
                                    onChange={this.staffOnChnage}
                                />
                                <InlineError
                                    message={emailError}

                                />
                            </div>




                            <div className="form-group">
                                <p>Job Profile</p>
                                <input type="text"
                                    className="form-control"
                                    placeholder="Job Profile"
                                    autoComplete="off"
                                    maxLength={64}
                                    name="jobProfile"
                                    value={jobProfile}
                                    onChange={this.staffOnChnage}
                                />
                                <InlineError
                                    message={jobProfileError}
                                />
                            </div>



                            <div className="text-center pt-4">
                                <button onClick={this.AddStaff} className="btn btn-red">
                                    Save
                                    &nbsp; {this.state.isLoading && <MySpinner />}

                                </button>
                            </div>

                            <button className="close-btn" onClick={this.props.closePopup}><CircleCross /></button>


                        </div>
                    </div>
                </div>
                {this.state.isCropper ?
                    <Croper aspect={1 / 1}
                        // onImageCrop={img => this.setState({ media: img })}
                        cropShape="round"
                            imgPrev={'1-1.jpg'}
                        finalCropedImage={(categoryImgPrev, categoryImg, visibleVal) => this.finalCropedImage(categoryImgPrev, categoryImg, visibleVal)} visibleVal={this.state.isCropper}
                        onClose={() => this.setState({ isCropper: false })}
                    />
                    : ""}
            </div>
        );
    }
}

export default AddStaff