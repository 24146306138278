import React, { Component, Suspense } from 'react';

import { BtnRed, CmBtn } from "../../../components/common/Button";
import CommingSoonLayer from "../../../components/common/CommingSoonLayer";
import { DownOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Select, DatePicker } from 'antd';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { Modal, Button, Checkbox } from 'antd';
import { Empty } from 'antd';
import { Calender, Clock, Hert, Ticket, TwoUsers, DownArrow, NoMediaICon } from "../../../SvgIcon";
import moment from "moment"
import Loader from "../../../components/Loader"
import InlineError from "../../../components/InlineError";
import { notify } from '../../../components/Toaster';

import Cookies from "js-cookie";

import WebHeader from '../../layout/WebHeader';
import _ from "lodash"

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import Footer from "../../layout/Footer";

import Media from "../../../components/common/WebMedia"

import { Link, withRouter } from "react-router-dom"
import { getTimeZone } from "../../../utils/DateZone"
import { connect } from "react-redux";


import { getHome } from "../../../redux/action/Get";

import {
    MyAlert,
    MySpinner
} from "../../../components/index"
import socket from "../../../services/socket";

const StagJoinCard = React.lazy(() => import('../components/stages/StagJoinCard'));
const LoungeJoinCard = React.lazy(() => import('../components/lounges/LoungeJoinCard'));

const antIcon = <LoadingOutlined style={{ fontSize: 72 }} spin />;

let isProd = process.env.REACT_APP_ENV == 'prod'

class ConferenceDetail extends Component {


    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.carousel = React.createRef();

        this.state = {
            isCommingSoon: true,
            isfree: true,
            conferenceDetail: '',
            isLoading: true,
            stagList: [],
            loungList: [],
            speakersList: [],
            timetableList: [],
            visible: false,
            password: '',
            passwordError: '',
            noOfDays: 1,
            dayStatus: [],
            npOfConference: 0,
            globalLoader: true,
            tab: 0,
            tabData: [],
            allTimeTableData: [],
            mediaData: [],
            isLoadingBtn: {},
        }

        window.scrollTo(0, 0)

    }


    next = () => {
        this.carousel.next();
    }
    previous = () => {
        this.carousel.prev();
    }

    componentDidMount() {
        let self = this;
        this.getConferenceDetail()
        this.props.dispatch({ type: 'PATH_ADD', payload: this.props.match })
        socket.on('loungeEnter', function (param) {
            console.log("dakjhgsbjm", param)
            let loungList = self.state.loungList;
            let lounge = _.find(loungList, { _id: param.loungeId });
            if (lounge) {
                lounge.onlineJoinUser = param.userCount;
                let index = _.findIndex(loungList, { _id: param.loungeId });
                loungList.splice(index, 1, lounge);
                self.setState({
                    loungList
                })
            }
        })

        socket.on('loungeExist', function (param) {
            let loungList = self.state.loungList;
            let lounge = _.find(loungList, { _id: param.loungeId });
            if (lounge) {
                if (param.userCount >= 0) {
                    lounge.onlineJoinUser = param.userCount;
                }
                let index = _.findIndex(loungList, { _id: param.loungeId });
                loungList.splice(index, 1, lounge);
                self.setState({
                    loungList
                })
            }
        })
    }

    removeDuplicates = (data) => {

        // Create an array of objects 


        // Display the list of array objects 


        // Declare a new array 
        let newArray = [];

        // Declare an empty object 
        let uniqueObject = {};

        // Loop for the array elements 
        for (let i in data) {

            // Extract the title 
            let objTitle = moment(data[i]['startDate']).format('DD-MM-YYYY')

            // Use the title as the index 
            uniqueObject[objTitle] = data[i];
        }

        // Loop to push unique object into array 
        for (let i in uniqueObject) {
            newArray.push(uniqueObject[i]);
        }

        // Display the unique objects 
        this.setState({ tabData: _.sortBy(newArray, 'startDate') }, () => {
            this.getTimeTableAccordingToDate(data, this.state.tabData[0]?.startDate)
        })

    }


    getTimeTableAccordingToDate = (data = [], search) => {

        this.setState({ timetableList: data.filter(item => moment(item.startDate).format('MM-DD-YYYY') == moment(search).format('MM-DD-YYYY')) })


    }


    getConferenceDetail = () => {
        let { page, limit, search } = this.state
        let req = {
            conferenceId: this.props.match.params.coId
        }
        _Api(Url.GET_CONFERENCE_WEB_DETAILS.method, Url.GET_CONFERENCE_WEB_DETAILS.url, req)
            .then(resp => {

                const { timeTableDetail, speakerDetail, stageDetail, loungeDetail, conferenceDetail, mediaDetail } = resp.responseData
                const { startDate, endDate } = conferenceDetail


                Cookies.set('cof_plan', JSON.stringify(conferenceDetail), { expires: 1 })

                this.removeDuplicates(timeTableDetail)

                let days = moment(endDate).diff(moment(startDate), 'days')

                console.log("safcgvhbdkm", conferenceDetail)



                this.setState({ npOfConference: days })

                var grades = {};
                timeTableDetail.forEach(function (item) {
                    var grade = grades[item.startDate] = grades[item.startDate] || {};
                    grade[item.startDate] = true;
                });


                this.setState({
                    globalLoader: false,
                    allTimeTableData: timeTableDetail,
                    mediaData: mediaDetail,
                    speakersList: speakerDetail, stagList: stageDetail, loungList: loungeDetail, conferenceDetail, isLoading: false
                })


            }).catch(err => {
                this.setState({ globalLoader: false, isLoading: false })

            })

    }




    onlyJoin = (name, id, coId, item = {}) => {
        const { numberofAttendees, onlineJoinUser } = item

        if (name == "lounge") {
            if (onlineJoinUser < numberofAttendees)
                window.open('/lounge/' + id, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
            else
                notify("err", "This lounges is full , you can not join at this time")
        }
        else
            this.props.history.push(`/seminar/${coId}/${name}/${id}`)

    }


    handleJoin = (conference, stageId = '', loungeId = '', ) => {
        //console.log("conference.isPassword && !conference.isAllowed",conference,conference.isPassword ,!conference.isAllowed);
        if (conference.isPassword && !conference.isAllowed) {
            this.setState({
                visible: true,

            });

        }

        else {
            this.setState({
                ['isLoadingBtn' + conference]: true, ['isLoadingBtn' + stageId]: true, ['isLoadingBtn' + loungeId]: true
            })

            let req = {
                conferenceId: conference,
                stageId: stageId,
                loungeId: loungeId,

            }
            _Api(Url.CONFERENCE_PAYMENT.method, Url.CONFERENCE_PAYMENT.url, req)
                .then(resp => {
                    this.setState({
                        ['isLoadingBtn' + conference]: false, ['isLoadingBtn' + stageId]: false, ['isLoadingBtn' + loungeId]: false
                    })
                    this.props.dispatch(getHome());

                    this.setState({ isLoadingBtn: false, })
                    this.getConferenceDetail()
                    if (stageId != '') {
                        this.onlyJoin('stage', stageId, conference)
                    }
                    else if (loungeId != '') {
                        //this.props.history.push(`/lounge/${loungeId}`)
                        window.open('/lounge/' + loungeId, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                    }
                }).catch(err => {
                    this.setState({
                        ['isLoadingBtn' + conference]: false, ['isLoadingBtn' + stageId]: false, ['isLoadingBtn' + loungeId]: false
                    })

                })
        }

    }

    handleJoinConf = (conference, conferenceDetail, stageId = '', loungeId = '', ) => {
        // console.log("jjjjjjjjjjjjjjjjjjjjj",{conferenceDetail, conference} );
        if (conferenceDetail.isPassword && !conferenceDetail.isAllowed) {
            this.setState({
                visible: true,

            });

        }

        else {
            this.setState({
                ['isLoadingBtn' + conference]: true, ['isLoadingBtn' + stageId]: true, ['isLoadingBtn' + loungeId]: true
            })

            let req = {
                conferenceId: conference,
                stageId: stageId,
                loungeId: loungeId,

            }
            _Api(Url.CONFERENCE_PAYMENT.method, Url.CONFERENCE_PAYMENT.url, req)
                .then(resp => {
                    this.setState({
                        ['isLoadingBtn' + conference]: false, ['isLoadingBtn' + stageId]: false, ['isLoadingBtn' + loungeId]: false
                    })
                    this.props.dispatch(getHome());

                    if (this.state.conferenceDetail?.isPaid) {
                        this.props.history.push(`/seminar/${this.props.match.params.coId}/payment`)
                    }
                    

                    this.setState({ isLoadingBtn: false, })
                    this.getConferenceDetail()
                    if (stageId != '') {
                        this.onlyJoin('stage', stageId, conference)
                    }
                    else if (loungeId != '') {
                        //this.props.history.push(`/lounge/${loungeId}`)
                        window.open('/lounge/' + loungeId, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                    }
                }).catch(err => {
                    this.setState({
                        ['isLoadingBtn' + conference]: false, ['isLoadingBtn' + stageId]: false, ['isLoadingBtn' + loungeId]: false
                    })

                })
        }

    }

    handleJoinConf2 = (conference, conferenceDetail, stageId = '', loungeId = '', ) => {
        // console.log("jjjjjjjjjjjjjjjjjjjjj",{conferenceDetail, conference} );
       
            this.setState({
                ['isLoadingBtn' + conference]: true, ['isLoadingBtn' + stageId]: true, ['isLoadingBtn' + loungeId]: true
            })

            let req = {
                conferenceId: conference,
                stageId: stageId,
                loungeId: loungeId,

            }
            _Api(Url.CONFERENCE_PAYMENT.method, Url.CONFERENCE_PAYMENT.url, req)
                .then(resp => {
                    this.setState({
                        ['isLoadingBtn' + conference]: false, ['isLoadingBtn' + stageId]: false, ['isLoadingBtn' + loungeId]: false
                    })
                    this.props.dispatch(getHome());

                   
                    this.setState({ isLoadingBtn: false, })
                    this.getConferenceDetail()
                    if (stageId != '') {
                        this.onlyJoin('stage', stageId, conference)
                    }
                    else if (loungeId != '') {
                        //this.props.history.push(`/lounge/${loungeId}`)
                        window.open('/lounge/' + loungeId, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                    }
                }).catch(err => {
                    this.setState({
                        ['isLoadingBtn' + conference]: false, ['isLoadingBtn' + stageId]: false, ['isLoadingBtn' + loungeId]: false
                    })

                })
        

    }





    handleOnChangeConference = (e) => {
        this.setState({ [e.target.name]: e.target.value, passwordError: '' })
    }
    submitPassword = (e, stageId = '', loungeId = '') => {
        if (e) e.preventDefault()
        if (this.state.password == '') {
            this.setState({ passwordError: '*Please enter password' })
        }
        else {
            this.setState({ isLoading: true })
            let req = {
                conferenceId: this.state.conferenceDetail._id,
                stageId: stageId,
                loungeId: loungeId,
                isPassword: this.state.password
            }
            _Api(Url.PASSWORD_CHECK.method, Url.PASSWORD_CHECK.url, req)
                .then(resp => {
                    this.setState({ isLoading: false, password: '', visible: false, })

                    

                    if (this.state.conferenceDetail?.isPaid) {
                        this.props.history.push(`/seminar/${this.props.match.params.coId}/payment`)
                    }
                    else{
                       
                        if (this.state.conferenceDetail?.isPaid == 0) {
                            console.log("dlhfdgh", this.state.conferenceDetail?.isPaid == 0)
                            this.handleJoinConf2(this.state.conferenceDetail._id, this.state.conferenceDetail)
                        }

                    }

                    this.getConferenceDetail()
                    this.handleCancel()
                }).catch(err => {
                    this.setState({ isLoading: false })
                    if (err.error)
                        notify("err", err.error.responseMessage)
                    // else if (err)
                    //     // notify("err", err.message)
                })
            // }    
        }

    }

    showModal = () => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {

        this.setState({
            visible: false,
        });
    };


    handleTab = (tab, date) => {
        this.getTimeTableAccordingToDate(this.state.allTimeTableData, date)
        this.setState({ tab })

    }


    render() {
        console.log("conferenceDetail", this.state.conferenceDetail);
        const { mediaData, tabData, tab, globalLoader, password, passwordError, conferenceDetail, stagList, loungList, speakersList, timetableList } = this.state

        if (globalLoader)
            return (
                <section className="brand-home-banner-bg webhome">
                    {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
                    <div className="centerLoader" >
                        <Spin size="large" tip="Loading..." indicator={antIcon} />
                    </div>
                </section>


            )

        else
            return (
                <section className="Home-banner-bg">
                    <Suspense fallback={<div></div>}>
                        <div className={this.state.isCommingSoon ? " container-fluid brandhome pos-relative" : " container-fluid"}>
                            <div className="cm-wrapper confreance-detail-parent pt-4">
                                <div className="leftborder-parent ssss mb-4">
                                    <h4>{conferenceDetail?.conferenceName}&nbsp;&nbsp;
                                </h4>
                                    <h6><span><Calender />&nbsp;&nbsp;</span> {moment(conferenceDetail?.startDate).format('MMM DD')},{getTimeZone(conferenceDetail?.startTime, conferenceDetail?.timezone)}  to   {moment(conferenceDetail?.endDate).format('MMM DD')}, {getTimeZone(conferenceDetail?.endTime, conferenceDetail?.timezone)}<span>&nbsp;&nbsp;&nbsp;
                                </span> </h6>
                                </div>
                                {(conferenceDetail.isAllowed == false) ?
                                    <>
                                        <div className="row">
                                            <div className="col-md-7 col-lg-9">
                                                <div className="image-banner">
                                                    <img src={conferenceDetail?.coverImage ? conferenceDetail?.coverImage : require('../../../assets/images/placeholder.png')} className="img-fluid" />
                                                </div>
                                            </div>
                                            <div className="col-md-5 col-lg-3 join-card">
                                                <p>Ticket</p>
                                                <div className="circle">
                                                    <Ticket />
                                                </div>
                                                <h5>{conferenceDetail?.conferenceName}</h5>
                                                {conferenceDetail?.euroAmount != undefined &&
                                                    <h2>{conferenceDetail?.euroAmount == 0 ? 'FREE' : `£${conferenceDetail?.euroAmount}`}
                                                        {/* {conferenceDetail?.euroAmount != undefined &&
                                                            conferenceDetail?.euroAmount == 0 ? '' : `£${conferenceDetail?.euroAmount}`
                                                        } */}

                                                    </h2>
                                                }

                                                <h6 className="mt-5">What are you waitng for ! </h6>
                                                <button onClick={() => this.handleJoinConf(conferenceDetail._id, conferenceDetail)} className="btn btn-primary btn-block" type='button'
                                                    disabled={this.state[`isLoadingBtn${conferenceDetail._id}`]}
                                                >
                                                    Join Now &nbsp; {this.state[`isLoadingBtn${conferenceDetail._id}`] &&
                                                        <MySpinner />}
                                                </button>

                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 mt-3 mb-3">
                                                <div className="header-text">
                                                    <h1 className="title">Description</h1>
                                                </div>
                                                <h6>{conferenceDetail?.description}</h6>

                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="full-image-banner">
                                                    <img src={conferenceDetail?.coverImage ? conferenceDetail?.coverImage : require('../../../assets/images/placeholder.png')} className="img-fluid" />
                                                </div>
                                            </div>

                                        </div>
                                     { <div className="row">
                                            <div className="col-sm-12 mt-3 mb-3">
                                                <div className="header-text">
                                                    <h1 className="title">Description</h1>
                                                </div>
                                                <h6>{conferenceDetail?.description}</h6>

                                            </div>
                                        </div>}
                                    </>

                                }
                                {/* ----------Stages--------- */}
                                <div className="header-text">
                                    <h1 className="title">Stages</h1>
                                </div>
                                <StagJoinCard
                                    {...this}
                                    list={stagList}
                                    conferenceDetail={conferenceDetail}

                                />

                                {
                                    !this.state.isLoading && stagList.length == 0 &&
                                    <div className="w-100 text-center row nodata m-auto">
                                        <div className="m-auto">
                                            <NoMediaICon />
                                            
                                            <h2>No Data Found</h2>
                                        </div>
                                    </div>
                                }

                                {/* --------Lounges----------- */}
                                <div className="header-text">
                                <h1 className="title">Lounges</h1>
                            </div>

                                <LoungeJoinCard
                                {...this}
                                list={loungList}
                                conferenceDetail={conferenceDetail}

                            />
                            {
                                    !this.state.isLoading && loungList.length == 0 &&
                                    <div className="w-100 text-center row nodata m-auto">
                                        <div className="m-auto">
                                            <NoMediaICon />                                            
                                            <h2>No Data Found</h2>
                                        </div>
                                    </div>
                                }












                                {/* {
                !this.state.isLoading && loungList.length == 0 &&
                <div className="w-100 text-center row nodata m-auto">
                    <div className="m-auto">
                        <NoMediaICon />
                        
                        <h2>No Data Found</h2>
                    </div>
                </div>
            } */}
                                <div className="event-timetable">
                                    <h1 className="home-heading pos-relative mb-5 text-center">Event Timetable
                                  <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                    </h1>
                                    <br />
                                    <div className="hert-tab-parent">
                                        <div className="downarrow">
                                        </div>

                                        {
                                            tabData.map((item, index) => {

                                                return (
                                                    <div className={`hert-tab text-center cursor-point ${tab == index ? 'active' : ''}`} onClick={() => this.handleTab(index, item.startDate)}>
                                                        <h4>Day </h4>
                                                        <div className="hert"><Hert />
                                                            <div className="centertext">
                                                                <h4>{moment(item?.startDate).format('Do')}</h4>
                                                                <p>{moment(item?.startDate).format('MMMM')}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                    <div class="table-responsive">
                                        <table class="table table-bordered rounded-table event-table">
                                            <thead>
                                                <tr>
                                                    <th>Session name</th>
                                                    <th>Speakers</th>
                                                    <th>Description</th>
                                                    <th>Time</th>
                                                    <th>Stage</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    timetableList.map((item, index) => {
                                                        let speaker = item?.speakerId ? item?.speakerId : []
                                                        return (
                                                            <tr>
                                                                <td> {item?.sessionName}</td>
                                                                <td className="speaker-details">
                                                                    <div className="img speaker_img">
                                                                        {
                                                                            speaker.map((speaker, ind) => {
                                                                                return (
                                                                                    speaker?.image ?
                                                                                        <img className="circle-image" src={speaker?.image} />
                                                                                        :
                                                                                        <p data-letters={`${speaker?.firstName ? speaker?.firstName.charAt(0) : ''} ${speaker?.lastName ? speaker?.lastName.charAt(0) : ''}`}></p>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <p>{item?.description}</p>
                                                                </td>
                                                                <td> <span>   {getTimeZone(item?.startTime, item?.timezone)}</span>
                                                                    to <span>{getTimeZone(item?.endTime, item?.timezone)}</span> </td>
                                                                <td>
                                                                    <div className="d-flex align-items-center justify-content-around">
                                                                        <span className="mr-3">{item?.stageId?.stageName}</span>
                                                                        {conferenceDetail.isAllowed && item?.videoUrl && <Link to={`/seminar/${this.props.match.params.coId}/${'table'}/${item._id}`} className="circle-calender"><span className="play-icon"><i class="fa fa-play" aria-hidden="true"></i></span></Link>}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }


                                            </tbody>

                                        </table>
                                        <div>
                                            {!this.state.isLoading && timetableList.length == 0 &&
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                                        </div>
                                    </div>
                                    <div>

                                    </div>

                                </div>
                                <br />
                                <div className="speakers-parent mt-5">
                                    <h1 className="home-heading pos-relative mb-5 text-center">Speakers
                                  <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                    </h1>
                                    <br />
                                    <div className="row">
                                        {
                                            speakersList.map((item, index) => {
                                                return (
                                                    <div className="col-sm-3 speaker">
                                                        <img className="img-fluid" src={item?.image ? item?.image : require("../../../assets/images/placeholder.png")} />
                                                        <div className="text-uperimg">
                                                            <h3>{item?.firstName} {item?.lastName}</h3>
                                                            <p>{item?.jobTitleOther}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                        {
                                            !this.state.isLoading && speakersList.length == 0 &&
                                            <div className="w-100 text-center row nodata m-auto">
                                                <div className="m-auto">
                                                    <NoMediaICon />
                                                    
                                                    <h2>No Data Found</h2>
                                                </div>
                                            </div>
                                        }


                                    </div>
                                </div>
                                <br />
                                {mediaData.length != 0 && <div className="speakers-parent mt-5">
                                    <h1 className="home-heading pos-relative mb-5 text-center">Media
                                  <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                    </h1>
                                    <br />
                                    <div className="row">
                                        <div className="images-video mt-4">
                                            <Media
                                                {...this.props}
                                                mediaData={mediaData}
                                                // getMedia={(data) => this.handleMedia(data)}
                                                addHide={true}
                                                isAdd={true}
                                            // onDeleteMedia={this.getMedia}
                                            />
                                        </div>

                                        {
                                            !this.state.isLoading && mediaData.length == 0 &&
                                            <div className="w-100 text-center row nodata m-auto">
                                                <div className="m-auto">
                                                    <NoMediaICon />
                                                    
                                                    <h2>No Data Found</h2>
                                                </div>
                                            </div>
                                        }


                                    </div>
                                </div>}
                                <br />
                            </div>
                            <Footer />
                        </div>
                        <Modal
                            footer={null}
                            centered
                            visible={this.state.visible}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                            className="paymentpopup"
                        >
                            <div className="rounded-textbox-form mt-5 mb-4 w-100" >
                                <div className="row">
                                    <form className="col-md-10 offset-md-1" onSubmit={this.submitPassword} >
                                        <div className="form-group">
                                            <level>Enter password</level>
                                            <input type="password"
                                                className="form-control"
                                                placeholder="Enter password"
                                                maxLength={50}
                                                name="password"
                                                value={password}
                                                onChange={this.handleOnChangeConference}
                                            />
                                            <InlineError
                                                message={this.state.passwordError}
                                            />
                                        </div>

                                        <div className="form-group text-center">
                                            <button type="submit" class="btn btn-red btn-label" onClick={this.submitPassword}>Submit</button>
                                        </div>

                                    </form>
                                </div>
                            </div>

                        </Modal>
                    </Suspense>
                </section>
            )
    }

}

function mapStateToProps(state) {


    return {
        ...state

    };
}

export default connect(mapStateToProps)(ConferenceDetail);
