import React, { Component } from 'react';

import { BtnRed, CmBtn } from "../../../components/common/Button";
import CommingSoonLayer from "../../../components/common/CommingSoonLayer";
import { DownOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Select, DatePicker } from 'antd';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { Modal, Button, Checkbox } from 'antd';
import { Empty } from 'antd';
import { Calender, Clock, Hert, Ticket, TwoUsers, DownArrow, NoMediaICon } from "../../../SvgIcon";
import moment from "moment"
import Loader from "../../../components/Loader"
import InlineError from "../../../components/InlineError";
import { notify } from '../../../components/Toaster';

import { Pagination } from '../../../components/common/Pagination';

import WebHeader from '../../layout/WebHeader';
import _ from "lodash"

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import Footer from "../../layout/Footer";

import Media from "../../../components/common/WebMedia"

import { Link } from "react-router-dom"
import { getTimeZone } from "../../../utils/DateZone"
import { connect } from "react-redux";

const antIcon = <LoadingOutlined style={{ fontSize: 72 }} spin />;

let isProd = process.env.REACT_APP_ENV == 'prod'

class ConferenceDetail extends Component {


    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.carousel = React.createRef();
        this.state = {
            isCommingSoon: true,
            isfree: true,
            conferenceDetail: '',
            isLoading: true,
            stagList: [],
            loungList: [],
            speakersList: [],
            timetableList: [],
            visible: false,
            password: '',
            passwordError: '',
            noOfDays: 1,
            dayStatus: [],
            npOfConference: 0,
            globalLoader: false,
            tab: 0,
            tabData: [],
            allTimeTableData: [],
            mediaData: [],
            snNo: 0,
            page: 1,
            limit: 20
        }

        window.scrollTo(0, 0)

    }


    next = () => {
        this.carousel.next();
    }
    previous = () => {
        this.carousel.prev();
    }

    componentDidMount() {
        this.props.dispatch({ type: 'PATH_ADD', payload: this.props.match })
        if (this.props.match.params.pageNo && !isNaN(this.props.match.params.pageNo)) {
            this.setState({ page: Number(this.props.match.params.pageNo), snNo : Number(this.props.match.params.pageNo) -1  }, () => {
                this.getConferenceDetail();
            })
        }
        else {
            this.getConferenceDetail();
        }

    }

    removeDuplicates = (data) => {

        // Create an array of objects 


        // Display the list of array objects 
        console.log(data);

        // Declare a new array 
        let newArray = [];

        // Declare an empty object 
        let uniqueObject = {};

        // Loop for the array elements 
        for (let i in data) {

            // Extract the title 
            let objTitle = moment(data[i]['startDate']).format('DD-MM-YYYY')

            // Use the title as the index 
            uniqueObject[objTitle] = data[i];
        }

        // Loop to push unique object into array 
        for (let i in uniqueObject) {
            newArray.push(uniqueObject[i]);
        }

        // Display the unique objects 
        this.setState({ tabData: _.sortBy(newArray, 'startDate') }, () => {
            this.getTimeTableAccordingToDate(data, this.state.tabData[0]?.startDate)
        })
        console.log("newArray====>", newArray);
    }


    getTimeTableAccordingToDate = (data = [], search) => {

        this.setState({ timetableList: data.filter(item => moment(item.startDate).format('MM-DD-YYYY') == moment(search).format('MM-DD-YYYY')) })


    }


    getConferenceDetail = () => {
        let { page, limit, search } = this.state

        let req = {
            page: page ? page : 1,
            limit: limit,
        }

        _Api(Url.OFFER_ANA_LIST.method, Url.OFFER_ANA_LIST.url, '', `?page=${page}&limit=${limit}`)
            .then(resp => {
                console.log('xnskadklsajdnasdm', resp.responseData)
                const { totalCount, data } = resp.responseData?.result;


                this.setState({
                    timetableList: data,
                    totalCount,
                    isLoading: false
                })


            }).catch(err => {
                this.setState({ globalLoader: false, isLoading: false })

            })

    }

    onlyJoin = (name, id, coId) => {

        if (name == "lounge") {
            //this.props.history.push(`/lounge/${id}`)
            window.open('/lounge/' + id, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
        }
        else
            this.props.history.push(`/seminar/${coId}/${name}/${id}`)

    }













    showModal = () => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    onPressBtn = (page) => {
        this.props.history.push(`/job-viewed/${page}`)
        this.setState({ page, snNo: page - 1, timetableList: [] }, () => {
            this.getConferenceDetail()
        })

    }




    render() {
        const { globalLoader, timetableList } = this.state

        console.log("fgdshfjkm", timetableList)



        if (globalLoader)
            return (
                <section className="brand-home-banner-bg webhome">
                    {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
                    <div className="centerLoader" >
                        <Spin size="large" tip="Loading..." indicator={antIcon} />
                    </div>
                </section>

            )

        else
            return (
                <section className="Home-banner-bg">

                    <div className={this.state.isCommingSoon ? " container-fluid brandhome pos-relative" : " container-fluid"}>

                        <div className="cm-wrapper confreance-detail-parent">

                            <div>
                            <h1 className="home-heading pos-relative pt-5 text-center">Training Viewed
                                  <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                </h1>


                                <div class="table-responsive">
                                    <table class="table table-bordered rounded-table event-table">
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Training Name</th>
                                                <th>User Name</th>
                                                {/* <th>Speakers</th>
                                                <th>Description</th>
                                                <th>Time</th> */}
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                timetableList.map((item, index) => {

                                                    return (
                                                        <tr>
                                                            <td><span className="circle-serial">{(this.state.snNo * 20) + index + 1}</span></td>
                                                            <td>
                                                                <Link to={`/web-training-details/${item?.productData?._id}`}><p>
                                                                    {item?.productData?.productName}
                                                                </p>
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <Link to={`/user/profile/${item?.userData?._id}`}> <p>{item?.userData.firstName} {item?.userData?.lastName}</p> </Link>
                                                            </td>

                                                            <td>
                                                                <div className="d-flex align-items-center justify-content-around">
                                                                    <span className="mr-3">{moment(new Date(item?.productLogData?.created)).format("DD/ MM/ YYYY")}</span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>

                                    </table>
                                    <div>
                                        {!this.state.isLoading && timetableList.length == 0 &&
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                                    </div>
                                    {
                                        timetableList.length != 0 &&
                                        <Pagination
                                            totalPage={Math.ceil(this.state.totalCount / 20)}
                                            currentPage={this.state.page}
                                            onPressBtn={this.onPressBtn}
                                        />
                                    }
                                </div>
                                <div>

                                </div>

                            </div>
                            <br />

                            <br />
                        </div>
                        

                    </div>
                    <Footer />


                </section>
            )
    }

}
// export default ConferenceDetail;
function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(ConferenceDetail);
