import promise from 'bluebird'
import { _Api } from "../../services/Api";
import Url from "../../services/BaseUrl";

export const GROUP_REQUEST = 'GROUP_REQUEST';
export const GROUP_SUCCESS = 'GROUP_SUCCESS';
export const GROUP_ERROR = 'GROUP_ERROR';

export const MEETING_REQUEST = 'MEETING_REQUEST';
export const MEETING_SUCCESS = 'MEETING_SUCCESS';
export const MEETING_ERROR = 'MEETING_ERROR';

export const EXPO_USER_SUCCESS = 'EXPO_USER_SUCCESS';
export const EXPO_USER_REQUEST = 'EXPO_USER_REQUEST';
export const EXPO_USER_ERROR = 'EXPO_USER_ERROR';

export const PAGE_USER_SUCCESS = 'PAGE_USER_SUCCESS';
export const PAGE_USER_REQUEST = 'PAGE_USER_REQUEST';
export const PAGE_USER_ERROR = 'PAGE_USER_ERROR';

export const USER_CONNECTION_SUCCESS = 'USER_CONNECTION_SUCCESS';
export const USER_CONNECTION_REQUEST = 'USER_CONNECTION_REQUEST';
export const USER_CONNECTION_ERROR = 'USER_CONNECTION_ERROR';

export const GROUP_ID = 'GROUP_ID';
export const PAGE_ID = 'PAGE_ID';
export const EXPO_ID = 'EXPO_ID';
export const ACTIVE_CHAT = 'ACTIVE_CHAT';
export const SELECTED_USER = 'SELECTED_USER';
export const CHAT_TOGGLE = 'CHAT_TOGGLE';
export const CHAT_OPEN = 'CHAT_OPEN';
export const NETWORK_TIME = 'NETWORK_TIME';
export const PAGE_TYPE = 'PAGE_TYPE';
export const EXPO_CHAT_TYPE='EXPO_CHAT_TYPE';
export const SHOWCASE_ID='SHOWCASE_ID';


export const groupActions = {
    joinMeeting,
    userConnectionList,
    pageUserList,
    expoUserList,
    joinOneMeeting,
    calling
};

function joinMeeting(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));

            _Api(Url.JOIN_MEETING.method, Url.JOIN_MEETING.url, params)
                .then(response => {
                    dispatch(success(response.responseData.result));
                    resolve(response);
                })
                .catch((err) => {
                    dispatch(failure(err));
                    reject(err);
                })
        })
    };
    function request(meetingRequest) { return { type: MEETING_REQUEST, meetingRequest } }
    function success(meetingSuccess) { return { type: MEETING_SUCCESS, meetingSuccess } }
    function failure(meetingFail) { return { type: MEETING_ERROR, meetingFail } }
}

function joinOneMeeting(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));

            _Api(Url.JOIN_ONE_MEETING.method, Url.JOIN_ONE_MEETING.url, params)
                .then(response => {
                    dispatch(success(response.responseData.result));
                    resolve(response);
                })
                .catch((err) => {
                    dispatch(failure(err));
                    reject(err);
                })
        })
    };
    function request(meetingRequest) { return { type: MEETING_REQUEST, meetingRequest } }
    function success(meetingSuccess) { return { type: MEETING_SUCCESS, meetingSuccess } }
    function failure(meetingFail) { return { type: MEETING_ERROR, meetingFail } }
}

function expoUserList(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));

            _Api(Url.GROUP_USER_LIST.method, Url.GROUP_USER_LIST.url, params)
                .then(response => {
                    dispatch(success(response.responseData.result));
                    resolve(response);
                })
                .catch((err) => {
                    dispatch(failure(err));
                    reject(err);
                })
        }).catch(err=>{})
    };
    function request(Request) { return { type: EXPO_USER_REQUEST, Request } }
    function success(Success) { return { type: EXPO_USER_SUCCESS, Success } }
    function failure(Fail) { return { type: EXPO_USER_ERROR, Fail } }
}

function pageUserList(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));

            _Api(Url.GROUP_USER_LIST.method, Url.GROUP_USER_LIST.url, params)
                .then(response => {
                    dispatch(success(response.responseData.result));
                    resolve(response);
                })
                .catch((err) => {
                    dispatch(failure(err));
                    reject(err);
                })
        })
    };
    function request(Request) { return { type: PAGE_USER_REQUEST, Request } }
    function success(Success) { return { type: PAGE_USER_SUCCESS, Success } }
    function failure(Fail) { return { type: PAGE_USER_ERROR, Fail } }
}

function userConnectionList(params) {
    try {
        return function (dispatch) {
            return new promise(function (resolve, reject) {
                dispatch(request(params));

                _Api(Url.GROUP_USER_LIST.method, Url.GROUP_USER_LIST.url, params)
                    .then(response => {
                        dispatch(success(response.responseData.result));
                        resolve(response);
                    })
                    .catch((err) => {
                        dispatch(failure(err));
                        reject(err);
                    })
            }).catch(err=>{})
        }

        function request(Request) { return { type: USER_CONNECTION_REQUEST, Request } }
        function success(Success) { return { type: USER_CONNECTION_SUCCESS, Success } }
        function failure(Fail) { return { type: USER_CONNECTION_ERROR, Fail } }
    } catch (err) { }

}

function calling(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));

            _Api(Url.JOIN_MEETING.method, Url.CALLING.url, params)
                .then(response => {
                    dispatch(success(response.responseData.result));
                    resolve(response);
                })
                .catch((err) => {
                    dispatch(failure(err));
                    reject(err);
                })
        })
    };
    function request(meetingRequest) { return { type: MEETING_REQUEST, meetingRequest } }
    function success(meetingSuccess) { return { type: MEETING_SUCCESS, meetingSuccess } }
    function failure(meetingFail) { return { type: MEETING_ERROR, meetingFail } }
}

