import React, { Component } from 'react';

import Footer from '../layout/Footer';

import CommingSoonLayer from "../../components/common/CommingSoonLayer";
class CommingSoon extends Component {
    constructor(props) {
        super(props);
        this.state= {
            isCommingSoon:true
        }
    }




    render() {
        return (
            <section className="brand-home-banner-bg">
            {this.props.hideHeader != true &&  {/* <WebHeader history={this.props.history} match={this.props.match} /> */}}
                <div className={this.state.isCommingSoon?"cm-section container-fluid pos-relative":"cm-section container-fluid"}>
                    {this.state.isCommingSoon && <CommingSoonLayer />}
                    <div className="cm-wrapper">
                        <div className="red-border">


                            {/*<h1 className="title">Main Stage</h1>*/}
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>

                    </div>

                 {this.props.hideHeader != true &&   <Footer />}
                </div>
            </section>

        )
    }
}
export default CommingSoon;