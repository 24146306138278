import React, { Component } from 'react';
import { Modal } from 'antd';
import Footer from "../../layout/Footer";
import {  Delete, Hert, UsersIcn, ListIcn , FooterLocation, } from '../../../components/common/SvgIcon';
import { Chat, Clock, PlayIcon } from '../../../SvgIcon';
import DiaryMeetingModal from './DiaryMeetingModal';
import DiaryCalendar from './DiaryCalendar';

import Calendar from 'react-awesome-calendar';



class Diary extends Component {
    constructor(){
        super()
        this.state = {
            meetingModal: false,
            meetingAcceptModal: false,
        }
    }

    showMeetingModal = () => {
        this.setState({
            meetingModal: true,
        });
    };
    showAcceptModal = () => {
        this.setState({
            meetingAcceptModal: true,
        });
    };
    handleCancel = () => {
        this.setState({ meetingModal: false });
    };
    handleAcceptCancel = () => {
        this.setState({ meetingAcceptModal: false });
    };

    render(){
        return(
            <>
            <section className="Home-banner-bg">
                <div className="container-fluid">
                    <div className="dairy-wrapper py-3 py-md-4">
                        <h1 className="home-heading pos-relative mb-5 text-center">Diary
                            <span className="heading-after-border heading-after-border-center">
                                <span className="heart"><Hert /></span>
                            </span>
                        </h1>

                        
                        <div className="calenar-btn-view text-center">
                            
                            <div className="dry-evnt-btns">
                                <button className="btn mr-3 mb-md-0 active"><i className="fa fa-calendar"></i> Calendar</button>
                                <button className="btn"><i className="fa fa-table"></i> Table Form</button>
                            </div>

                            <DiaryCalendar />

                            <div className="event-timetable py-3 py-md-4">
                                <div className="event-tab-parent diary-events-wrap">
                                    <div className="event-tab active" >
                                        <div>
                                            <h3>10th</h3>
                                            <h6>May</h6>
                                        </div>
                                    </div>
                                    <div className="event-tab" >
                                        <div>
                                            <h3>14th</h3>
                                            <h6>May</h6>
                                        </div>
                                    </div>
                                    <div className="event-tab" >
                                        <div>
                                            <h3>18th</h3>
                                            <h6>May</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="event-tabbody-parent mt-md-4 mt-3">
                                    <div className="event-tabbody">
                                        <div className="diaryevnt-time time-details">
                                            <span className="d-block mb-2"><Clock /></span>
                                            <h6><b>Start Time</b></h6>
                                            <h6>09:30 am, IST </h6>
                                            <h6><b>End Time</b></h6>
                                            <h6>11:45 am, IST </h6>
                                            <div className="btm-time-btns">
                                                <button className="btn btn-com w-100">View Conference</button>
                                                <button className="btn btn-comm w-100"><PlayIcon /> Replay</button>
                                                
                                            </div>
                                        </div>
                                        <div className="event-detail diary-evnt-dtls text-left">
                                            <h2>Why you Need to know about Patch Testing Before you Reopen <small className="d-block"> Session</small></h2>
                                            <h4>HJ Online<small>.&nbsp;Stage</small></h4>
                                            <div className="diary-evnt-spkr">
                                                <div className="row">
                                                    <div className="col-md-7">
                                                        <div className="speaker-details">
                                                            <div className="profile" style={{ marginTop: 8 }}>
                                                                <img src={require('../../../assets/images/login_user.png')} className="img-fluid" />

                                                                <span className="text">
                                                                    <p>Speaker 1 </p>
                                                                    <p className="mb-0">Software Engineerr</p>
                                                                </span>
                                                            </div>
                                                            <div className="profile" style={{ marginTop: 8 }}>
                                                                <img src={require('../../../assets/images/login_user.png')} className="img-fluid" />

                                                                <span className="text">
                                                                    <p>Speaker 1 </p>
                                                                    <p className="mb-0">Software Engineerr</p>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="btm-diaryevnt-btns text-md-right">
                                                            <ul className="list-inline mb-0">
                                                                <li className="list-inline-item">
                                                                    <button className="btn dbtn btn-success btn-sm" onClick={this.showAcceptModal}>Accept</button>
                                                                </li>
                                                                <li className="list-inline-item">
                                                                    <button className="btn dbtn btn-danger btn-sm">Decline</button>
                                                                </li>
                                                                <li className="list-inline-item">
                                                                    <span className="chat cursor-point yellow">
                                                                        <Chat />
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="topbar-diary-icn">
                                                <button className="btn">
                                                    <Delete />
                                                </button>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="event-tabbody">
                                        <div className="diaryevnt-time time-details">
                                            <span className="d-block mb-2"><Clock /></span>
                                            <h6><b>Start Time</b></h6>
                                            <h6>09:30 am, IST </h6>
                                            <h6><b>End Time</b></h6>
                                            <h6>11:45 am, IST </h6>
                                            <div className="btm-time-btns">
                                                <button className="btn btn-comm w-100">Updated</button>
                                                
                                            </div>
                                        </div>
                                        <div className="event-detail diary-evnt-dtls text-left">
                                            <h2>Why you Need to know about Patch Testing Before you Reopen <small className="d-block"> Session</small></h2>
                                            <h4>HJ Online<small>.&nbsp;Stage</small></h4>
                                            <div className="diary-evnt-spkr">
                                                <div className="row">
                                                    <div className="col-md-7">
                                                        <div className="speaker-details">
                                                            <div className="profile" style={{ marginTop: 8 }}>
                                                                <img src={require('../../../assets/images/login_user.png')} className="img-fluid" />

                                                                <span className="text">
                                                                    <p>Speaker 1 </p>
                                                                    <p className="mb-0">Software Engineerr</p>
                                                                </span>
                                                            </div>
                                                            <div className="profile" style={{ marginTop: 8 }}>
                                                                <img src={require('../../../assets/images/login_user.png')} className="img-fluid" />

                                                                <span className="text">
                                                                    <p>Speaker 1 </p>
                                                                    <p className="mb-0">Software Engineerr</p>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="btm-diaryevnt-btns text-md-right">
                                                            <ul className="list-inline mb-0">
                                                                <li className="list-inline-item">
                                                                    <button className="btn btn-outline-success btn-sm">Accept</button>
                                                                </li>
                                                                <li className="list-inline-item">
                                                                    <button className="btn btn-sm btn-outline-secondary">Decline</button>
                                                                </li>
                                                                <li className="list-inline-item">
                                                                    <span className="chat cursor-point yellow">
                                                                        <Chat />
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="topbar-diary-icn">
                                                <button className="btn">
                                                    <Delete />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                

                    {/* Shedule Meeting popup */}
                    <Modal
                        wrapClassName="signup_parent_modal"
                        className="cm_popup signup_modal "
                        //cm_popup term-services
                        visible={this.state.meetingModal}
                        // onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={null}
                        centered
                    >
                        <DiaryMeetingModal />
                    </Modal>
                    {/* Shedule Meeting popup End */}


                    {/* Accept Meeting popup */}
                    <Modal
                        wrapClassName="signup_parent_modal"
                        className="cm_popup signup_modal meeting-diary-mld-popup"
                        //cm_popup term-services
                        visible={this.state.meetingAcceptModal}
                        // onOk={this.handleOk}
                        onCancel={this.handleAcceptCancel}
                        width={500}
                        footer={null}
                        centered
                    >

                        <div className="sign-modal-content meeting-accept-mdal">
                            <div className="row align-items-center">
                                <div className="col-md-12" >
                                    <h3 className="auth-heading pos-relative text-left mt-3 mt-md-4 mb-md-3">
                                     Hair & Beauty Meeting
                                    </h3>
                                    <div className="meeting-accept-mdl mb-2 mb-md-3">
                                        <ul className="list-unstyled mb-0">
                                            <li>
                                                <UsersIcn />
                                                <span>Meeting in person</span>
                                            </li>
                                            <li>
                                                <Clock />
                                                <span>Tuesday, 22 December, 11:00AM</span>
                                            </li>
                                            <li>
                                                <div className="meetng-prsn-dry">
                                                    <div className="mtng-prsn-lft">
                                                        <UsersIcn />
                                                        <div className="mtng-desc">
                                                            <span className="d-block mb-2">Deepanshu Garg</span>
                                                            <span className="d-block">Diksha Chaudhary <strong className="d-block">(Organiser)</strong></span>
                                                        </div>
                                                    </div>
                                                    <div className="acpt-mtngD">
                                                        <button className="btn btn-success">Accepted</button>
                                                    </div>
                                                </div>
                                                
                                            </li>
                                            <li className="place-mtng">
                                                <FooterLocation />
                                                <span>Stand number 5</span>
                                            </li>
                                            <li className="smList">
                                                <ListIcn />
                                                <span>Beauty and saloon meeting</span>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="form-group text-center mb-0">
                                        <button className="btn btn-blue w-100">Updated</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </Modal>
                    {/* Accept Meeting popup End */}




                <Footer />
            </section>
            </>
        )
    }
}

export default Diary