import React, { Component } from 'react';
import { Hert } from '../../SvgIcon';
import { Link } from "react-router-dom"
import {Modal, Select} from 'antd';
import { notify } from '../../components/Toaster';
import Loader from '../../components/Loader';
import PhoneInput from 'react-phone-input-2';
//Api
import { login } from '../../redux/action/Auth'
import { getRole } from '../../redux/action/Auth'
import { _Api } from '../../services/Api';
import Url from '../../services/BaseUrl'
//validation
import { validateEmail } from '../../components/Validation';
//validation message
import InlineError from '../../components/InlineError'
// components
import {
    MyAlert,
    MySpinner
} from "../../components/index"


import Cookies from 'js-cookie';
import {connect} from "react-redux";

import {SIGNUP_PROCESS, TOGGLE_LOGIN, TOGGLE_SIGNUP} from "../../redux/action";

const { Option } = Select;


class SignUpStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            password: '',
            email: '',
            phoneNumber:'',
            companyName:'',
            emailError: '',
            firstNameError: '',
            lastNameError: '',
            passwordError: '',
            phoneNumberError:'',
            userTypeError: '',
            companyNameError:'',
            isLoader: false,
            showMessage: false,
            isPasswordShow: false,
            mobileCode:'',
            mobileNumber:'',
            mobileNumberError:''
        }
    }
    // componentDidMount() {
    //     if (localStorage.getItem('accessToken') && localStorage.getItem('accessToken') !== '') {
    //         //console.log("dkasghcdvhbj", localStorage.getItem('userType'))

    //         if (localStorage.getItem('userType') == 1) {
    //             //this.props.history.push("/exhibition");
    //             window.location.href = `/exhibition`
    //         }

    //         else if (localStorage.getItem('userType') == 4) {
    //             //this.props.history.push("/home");
    //             window.location.href = `/home`
    //         }

    //         else {
    //             if (localStorage.getItem('checkStatus') == 1)
    //                 //this.props.history.push("/home");
    //                 window.location.href = `/home`
    //             else
    //                 window.location.href = `/home`
    //                 //this.props.history.push("/home");
    //         }
    //     }
    // }

    toggleSignup=(value)=> {
        this.props.dispatch({ type: TOGGLE_SIGNUP, value: value });
    }

    toggleLogin=(value)=> {
        this.props.dispatch({ type: TOGGLE_LOGIN, value: value });
    }

    toggleSignupProcess=(value)=> {
        this.props.dispatch({ type: SIGNUP_PROCESS, value: value });
    }


    render() {
        console.log("thissSASaQD",this.props.userData?.userType == 0);
        let {userData, isStaff,  mobileNumberError, mobileNumber,mobileCode,loading, firstName, lastName, email, firstNameError, passwordError, password, lastNameError, emailError ,companyName,companyNameError,phoneNumberError,jobTitleOther } = this.props;
   
        return (
            <>
                    <div className="sign-modal-content">
                        <div className="row align-items-center">
                            <div className="col-md-12" >
                                  
                                    <h3 className="auth-heading pos-relative text-center mt-5">
                                    Profile Details
                                    <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                   </h3>
                                {/* <p className="home-subheading text-center">Free for professionals and low cost options for brands and suppliers </p> */}
                                <form className="rounded-textbox-form mt-5" onSubmit={this.props.signUpSubmit}>
                               
                                <div className="form-group">
                                        {
                                            this.props.showMessage &&
                                            <MyAlert
                                                message={this.props.message}
                                                type={this.props.erroStatus}
                                            />
                                        }
                                        <h6>First Name</h6>
                                        <input type="text"
                                            autoComplete="new-password"
                                            className="form-control"
                                            placeholder="First Name"
                                            id="pwd"
                                            maxLength={64}
                                            name='firstName'
                                           value={firstName}
                                           onChange={this.props.sugnUpOnChange}
                                        />
                                        <InlineError
                                            message={firstNameError}
                                        />
                                    </div>
                                    <div className="form-group">
                                       <h6>Last Name</h6>
                                        <input type="text"
                                               autoComplete="do-not-show-ac"
                                            className="form-control"
                                            placeholder="Last Name"
                                               maxLength={64}
                                               name='lastName'
                                               value={lastName}
                                               onChange={this.props.sugnUpOnChange}
                                        />
                                       <InlineError
                                            message={lastNameError}
                                        />
                                    </div>

                                    {this.props.userData?.userType == 0 &&  <div className="form-group">
                                       <h6>Company Name</h6>
                                        <input type="text"
                                               autoComplete="do-not-show-ac"
                                            className="form-control"
                                            placeholder="Company Name"
                                               maxLength={64}
                                               name='companyName'
                                               value={companyName}
                                               onChange={this.props.sugnUpOnChange}
                                        />
                                       {/* <InlineError
                                            message={lastNameError}
                                        /> */}
                                    </div>}

















                                    { isStaff && <div className="form-group">
                                       <h6>Job Profile</h6>
                                        <input type="text"
                                               autoComplete="new-password"
                                               className="form-control"
                                               placeholder="Job Profile"
                                               id="jobTitleOther"
                                               maxLength={64}
                                               name='jobTitleOther'
                                               value={jobTitleOther}
                                               onChange={this.props.sugnUpOnChange}
                                        />
                                        <InlineError
                                            message={companyNameError}
                                        />
                                    </div>}
                                    <div className="form-group">
                                           <h6>Phone number</h6>
                                            <PhoneInput
                                                country={'ae'}
                                                placeholder="Phone number"
                                                enableLongNumbers="false"
                                                autoFormat="false"
                                                autoComplete="do-not-show-ac"
                                                value={String(this.props.phoneNumber)}
                                                onChange={(phoneNumber, code) => this.props.setPhoneNumber(phoneNumber,code)}
                                            />
                                        <InlineError
                                            message={phoneNumberError}
                                        />
                                    </div>
                                    <div className="form-group">
                                            <h6>Mobile number</h6>
                                            <PhoneInput
                                                country={'ae'}
                                                placeholder="Mobile number"
                                                enableLongNumbers="false"
                                                autoFormat="false"
                                                autoComplete="do-not-show-ac"
                                                value={String(this.props.mobileNumber)}
                                                onChange={(mobileNumber, code) => this.props.setPhoneNumber2(mobileNumber,code)}
                                            />
                                        <InlineError
                                            message={mobileNumberError}
                                        />
                                    </div>
                                    {/* <div className="form-group">
                                        <input type="text"
                                               autoComplete="do-not-show-ac"
                                               className="form-control"
                                               placeholder="Email Address "
                                               id="email"
                                               maxLength={250}
                                               name='email'
                                               value={email}
                                               onChange={this.props.sugnUpOnChange}
                                        />
                                        <InlineError
                                            message={emailError}
                                        />
                                    </div> */}
                                    
                                    <div className="text-center">
                                        <button type="submit" disabled={this.props.isLoading} className="btn btn-red"> &nbsp;Submit
                                             &nbsp;
                                        {this.props.isLoading &&
                                                <MySpinner
                                                />}
                                        </button>
                                    </div>
                                    
                               
                                    
                                    {/*<div className="or_design">*/}
                                    {/*   <span>OR</span>*/}
                                    {/*</div>*/}
                                    
                                    {/*<div className="form-group sign-up d-flex justify-content-center align-items-center mb-4">*/}
                                    {/*   <p className="mb-0">Sign up With Social Account</p>*/}
                                    {/*    <span className="linkedin"><i className="fa fa-linkedin" aria-hidden="true"></i></span>*/}
                                    {/*</div>*/}
                                
                                </form>
                            </div>
                        </div>

                    </div>

            </>

        )
    }
}
function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(SignUpStep);