import React, {Component} from 'react'
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';
import { Link } from "react-router-dom";
import {CategoryManagement, Filter, Pencil, Delete } from '../../../SvgIcon';
import moment from 'moment-timezone';
import '../../../assets/css/adManagement.css';
import { DownOutlined } from '@ant-design/icons';
import {Menu, Dropdown, Checkbox, DatePicker, TimePicker, Select} from 'antd';
import {_Api} from "../../../services/Api";
import Url from "../../../services/BaseUrl";
import {notify} from "../../../components/Toaster";
import dateFormat from "dateformat";

import { Modal, Button, Space, Empty } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import {getTimeFormate} from "../../../utils/DateZone"

const { confirm } = Modal;



const { Option } = Select;

class CategoryList extends Component{
    constructor(props) {
        super(props);
        this.state = {
            page:1,
            limit:50,
            download:0,
            searchKey:'',
            sortField:'categoryTitle',
            sortType:1,
            parentCategoryId:'',
            categoryType:"",
            level:1,
            list:[],
            totalCount:0,
            isLoader:false
        }
        this.getCategory=this.getCategory.bind(this);
        this.getDate=this.getDate.bind(this);
        this.onChanged=this.onChanged.bind(this);
     
    }

    componentDidMount(){
        this.getCategory();
    }

    getDate(date){
        if(date&&date!=''){
            return getTimeFormate(date)
        }
    }

    getCategory(){
        let params = {
            page: this.state.page,
            limit: this.state.limit,
            searchKey: this.state.searchKey,
            sortField:this.state.sortField,
            sortType:this.state.sortType,
            parentCategoryId:this.state.parentCategoryId,
            categoryType: ""+this.state.categoryType,
            level: ""+this.state.level
        }
        _Api(Url.CATEGORY_LIST.method, Url.CATEGORY_LIST.url, params )
            .then(resp => {
                console.log('responseData', resp.responseData);
                this.setState({
                    list:resp.responseData.result,
                    totalCount:resp.responseData.totalCount,
                    isLoader:false
                })
            }).catch(err => {
            this.setState({ isLoader: false })
            if (err.error)
                notify("err", err.error.responseMessage)
            else if (err)
                // notify("err", err.message)
            this.setState({ isLoader: false })
        })
    }

    onChanged(e) {
        //console.log("e", e);
        let self=this;
        let keyName=e.target.name;
        let KeyValue=e.target.value;
        this.setState({[e.target.name]: e.target.value},function () {
            if(keyName==='searchKey'){
                if(KeyValue.length>=3){
                    self.getCategory();
                }
                else if(KeyValue.length == 0)
                self.getCategory();
            }
           
        });
    }

     onChange=(date, dateString) =>{
        console.log(date, dateString);
    };

    changeStatus = (e, id,status) => {
        console.log("change status")
        let req = {
            categoryId: id,
            status: status?"0":"1",
        }
        _Api(Url.CATEGORY_CHANGE_STATUS.method, Url.CATEGORY_CHANGE_STATUS.url, req)
            .then(resp => {
                this.getCategory()
            }).catch(err => {
            if (err.error)
                notify("err", err.error.responseMessage)
            // else if (err)
                // notify("err", err.message)
        })
    }

    deleteCategory = (categoryId)=>{
        let req = {
            categoryId: categoryId,
        }
        _Api(Url.CATEGORY_DELTED.method, Url.CATEGORY_DELTED.url, req)
            .then(resp => {
                this.getCategory()
            }).catch(err => {
            if (err.error)
                notify("err", err.error.responseMessage)
            // else if (err)
                // notify("err", err.message)
        })
    }

    selectOnChange = (type, e) => {
        let self=this;
        this.setState({
            [type]: e,
        },function () {
            self.getCategory();
        })
    }

    getCategoryType=(type)=>{
        if(type===1||type==="1"){
            return "Place of work"
        }else if(type===2||type==="2"){
            return "Job title"
        }else if(type===3||type==="3"){
            return "Services"
        }else if(type===4||type==="4"){
            return "Product"
        }
    }




    // delete modal
    showDeleteConfirm = (id) => {
        confirm({
            title: 'Are you sure you want to delete this?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            onOk: () => {
             this.deleteCategory(id)
            },
            onCancel: () => {
                this.handleCancel()
            },
        });
    }






      //Cancel modal
      handleCancel = () => {
        // console.log('delete', resp.responseData.message)
          this.setState({ visible: false });
    };


    render(){
        console.log("this.state",this.state)
        const menu = (
        <Menu className="filter-dropdown-list p-3">
            <ul className="list-unstyled">
                <li className="main-category mb-3"><Checkbox onChange={this.onChange} checked>Category</Checkbox>
                    <ul className="list-unstyled">
                        <li><Checkbox onChange={this.onChange}>Makeup Products</Checkbox></li>
                        <li><Checkbox onChange={this.onChange}>Furniture and salon supplies</Checkbox></li>
                        <li><Checkbox onChange={this.onChange}>Education/Training</Checkbox></li>
                    </ul>
                </li>
                <li className="main-category"><Checkbox onChange={this.onChange} checked>Date</Checkbox>
                    <ul className="list-unstyled">
                        <li>
                            <div className="row">
                            <div className="col-6">
                                    <DatePicker onChange={this.onChange} />
                                </div>
                                <div className="col-6">
                                    <TimePicker use12Hours onChange={this.onChange} />
                                </div>
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>
        </Menu>
        );

        return (
            <>
                <div className="dash_body_container">
                    <Header />
                    <Sidebar />
                    <div className="body_container categorymanagement">
                        <div className="header-text d-flex flex-wrap mb-4 mt-3">

                            <div className="d-flex flex-grow-1 align-items-center">
                                <p className="header-icon"><CategoryManagement /></p>
                                <h4>Category Management</h4>
                            </div>

                            <div className="rounded-textbox-form filter-select pr-2">
                                
                                    <Select placeholder="Select" name="categoryType" onChange={(e) => this.selectOnChange("categoryType", e)}>
                                        <Option value="">All</Option>
                                        <Option value="1">Place of work</Option>
                                        <Option value="2">Job title</Option>
                                        <Option value="3">Services</Option>
                                        <Option value="4">Product</Option>
                                        {/*<Option value="5">Budget</Option>*/}
                                        {/*<Option value="6">6=Number of people I work</Option>*/}
                                </Select>
                               
                            </div>
                            <div className="search_wrapper  mr-2">
                                <i className="fa fa-search" />
                                <input type="text"
                                    placeholder="Search..."
                                    name='searchKey'
                                    value={this.state.searchKey}
                                    onChange={this.onChanged}
                                />
                            </div>

                            <Link to="/category/add" className="btn btn-red">Add Category</Link>
                            {/*<Dropdown overlay={menu} trigger={['click']} className="filter-dropdown">*/}
                            {/*    <a className="ant-dropdown-link filter-icon ml-2" onClick={e => e.preventDefault()}>*/}
                            {/*    <Filter /><DownOutlined />*/}
                            {/*    </a>*/}
                            {/*</Dropdown>*/}
                            {/* <Link href="/" className="filter-icon ml-2"><Filter /></Link> */}
                        </div>

                        <div className="table-responsive">
                            <table className="table table-bordered rounded-table ">
                                <thead>
                                    <tr>
                                        <th>S.No.</th>
                                        <th>Category Name </th>
                                        <th>Description</th>
                                        <th>Type</th>
                                        <th>Create Date</th>
                                        <th>Status</th>
                                        <th >Action</th> 
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.list.length>0?
                                    this.state.list.map((data,index)=>(
                                    <tr key={data._id}>
                                        <td><span className="circle-serial">{((this.state.page-1)*this.state.limit)+index+1}</span></td>
                                        <td>
                                            <Link to={"/category/"+data._id}>{data.categoryTitle}</Link>
                                        </td>
                                        <td><p>{data.categoryDesc}</p></td>
                                        <td><p>{this.getCategoryType(data.categoryType)}</p></td>

                                        <td>{this.getDate(data.created)}</td>
                                        <td>
                                        <p className="toggle-parent ">
                                                <label className="switch">
                                                    <input type="checkbox"
                                                           checked={data.status === 1 ? true : false}
                                                           onChange={(e) => this.changeStatus(e, data._id,data.status)}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </p>
                                        </td>
                                        <td className="action_group">
                                            <Link to={"/category/edit/"+data._id}> <p><p className="circle-serial" ><Pencil /></p></p></Link>

                                          <p className="circle-serial" onClick={()=> this.showDeleteConfirm(data._id)}>  <p><Delete /></p></p>
                                        </td>
                                    </tr>
                                        )):""}

                                </tbody>
                            </table>
                        
                        <div>
                            {!this.state.isLoader && this.state.list?.length == 0 &&
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                        </div>

                        </div>
                        </div>
                </div>
             </>
        );
    }
}

export default CategoryList;
