import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import {HeaderInfo, QuestionMark } from '../../SvgIcon'
import { history } from "../../routing/index"
import { _Api } from "../../services/Api";
import Url from "../../services/BaseUrl";
import { notify } from "../../components/Toaster";
import { userActions, setCount } from "../../redux/action";

import { connect } from "react-redux";
import SuportAdmin from '../../components/support/SuportAdmin';
import socket from '../../services/socket';
var _ = require('lodash');

export class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            headerColor: "headerTransparent",
            userDetail: {}
        }
    }


    listenScrollEvent = e => {
        if (window.scrollY > 10) {
            this.setState({ headerColor: 'headerColor' })
        }
        else {
            this.setState({ headerColor: 'headerTransparent' })
        }
    }

    componentDidMount() {
        let self = this;
        window.addEventListener('scroll', this.listenScrollEvent)
        if (localStorage.getItem('accessToken') && localStorage.getItem('accessToken') !== '') {
            self.props.dispatch(userActions.profile())
            // socket.emit('getMyUserList', { type: 22 });
            // socket.on('getMyUserList', function (params) {
            //     console.log("getMyUserList", params);
            //     if (params.type === 22) {
        
                 
        
            //         self.unreadMsg()
        
            //     }
                
            // })
        
            // socket.on('userListData', function (params) {
            //     console.log("userListData", params);
            //     if (params.type === 22) {
        
            //         self.unreadMsg()
        
            //     }
        
            // })
        } else {
            history.push("/");
        }
        
    }



    // unreadMsg = ()=> {
    //     let self = this;
       
    //     self.props.dispatch(setCount(1))
       
    // }


   











    handleLogout = () => {
        localStorage.clear()
        history.push("/")
        window.location.reload()
    }

    render() {
        let { headerColor } = this.state;
        // console.log("this.state", this.state);
        // console.log("this.props", this.props);
        let queryUrl = `/user-profile/${this.props.userDetail._id}`;
        return (
            <div className={`inner_header ${headerColor}`}>
               <SuportAdmin {...this} onlySocket={true} />
                <header className="d-flex justify-content-end">
                  
                   <Link to="/suport-admin"  className="help mr-4">
                        <span className="pos-relative cursor-point notification">
                           <QuestionMark /> 
                  {this.props.messageCount ? <span className="counter"></span> : ''}
                        </span>
                    </Link>

                    <div className="header_drop">
                        <Dropdown overlay={
                            <Menu>
                                <Menu.Item key="0">
                                    <Link to="/change-password">Change Password</Link>
                                </Menu.Item>
                                {/* <Menu.Item key="1">
                                        <Link to="/privacy-policy">Privacy and Ploicy</Link>
                                    </Menu.Item> */}
                                <Menu.Item key="2">
                                <a href="https://pb-world-comman.s3.eu-west-2.amazonaws.com/Worldwidejobfinder+Terms+and+Conditions.pdf" target="_blank">Terms and Conditions</a>
                                </Menu.Item>
                                {/* <Menu.Item key="3">
                                        <Link to="/trainingobjective">Training Objective</Link>
                                    </Menu.Item> */}
                                <Menu.Item key="4">
                                    <Link to="/profile">Profile</Link>
                                </Menu.Item>
                                <Menu.Divider />
                                <Menu.Item key="5">
                                    <div onClick={() => { this.handleLogout() }}>Logout</div>
                                </Menu.Item>
                            </Menu>
                        } >
                            <div className="profile">
                                <img src={this.props.profile?.profileInfo?.image || require('../../assets/images/login_user.png')} className="img-fluid" />
                                <span className="text"><p>{this.props.profile.profileInfo.firstName ? this.props.profile.profileInfo.firstName : ""}</p><p className="mb-0">{this.props.userDetail.email}</p></span>
                                <span className="caret-icon"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            </div>
                        </Dropdown>
                    </div>
                </header>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        ...state,
        messageCount: state?.loading?.messageCount
    };
}

export default connect(mapStateToProps)(Header);
