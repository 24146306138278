import React, { Component } from 'react';
import { PaymentIcon, Pencil, Filter } from '../../../SvgIcon';
import { Link } from "react-router-dom";
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';
import { Menu, Dropdown, Descriptions, Empty } from 'antd';
import { DatePicker } from 'antd';
import moment from 'moment-timezone';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';
import { Modal, Button } from 'antd';
import { Pagination } from '../../../components/common/Pagination';
import Loader from '../../../components/Loader';
import util from '../../../utils/Util.js';
import InlineError from '../../../components/InlineError';
import { remove } from 'lodash';

const columnDetails = [{ fieldName: "S.No." },
{ fieldName: "Transaction Key" },
{ fieldName: "Name of the Person", isSortable: true, dataIndex: "firstName" },
{ fieldName: "Company Name" },
{ fieldName: "Exhibition Name" },
{ fieldName: "Email" },
{ fieldName: "Phone" },
{ fieldName: "Bought" },
{ fieldName: "Date of Purchase", isSortable: true, dataIndex: "created" },
{ fieldName: "VAT" },
{ fieldName: "Status" }
];

class TransactionList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDeleted: '',
            transactionId: '',
            status: "",
            page: 1,
            limit: 10,
            loading: false,
            visible: false,
            transactionListData: [],
            selectedId: '',
            isLoader: false,
            search: '',
            totalCount: [],
            vatNumber: "",
            All: true,
            isDateChecked: false,
            sortInfo: {},
            purchaseType0: false,
            purchaseType1: false,
            purchaseType2: false,
            startDate: "",
            endDate: "",
            endDateError: '',
            startDateError: '',
            snNo: 0,

        }
        this.onSubmit = this.onSubmit.bind();
        this.onClearFilter = this.onClearFilter.bind();
    }


    //Show modal
    showModal = (vatValue, transactionId) => {
        this.setState({
            visible: true,
            vatNumber: vatValue,
            transactionId: transactionId
        });
    };

    handleOk = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    componentDidMount() {
        this.getTransactionList();
    }
    onSubmit = () => {
        const { startDate, endDate, transactionListData } = this.state;
        let startDateValue = startDate._d, endDateValue = endDate._d
        let startDateError, endDateError;
        if (startDateValue && endDateValue && transactionListData.length > 0) {
            startDateError = startDateValue > endDateValue && "Start Date should be less than or equal to End Date";
        }
        if (startDateError || endDateError) {
            this.setState({ startDateError: startDateError, endDateError: endDateError });

        }
        else {
            // let filterButton = document.getElementsByClassName("profile ant-dropdown-trigger ant-dropdown-open");
            // let menuItem = document.getElementsByClassName("ant-dropdown ant-dropdown-placement-bottomRight ");
            // filterButton[0].classList.remove("ant-dropdown-open");
            // menuItem[0].classList.add("ant-dropdown-hidden");
            this.getTransactionList();
        }

    }
    onClearFilter = () => {
        // let filterButton = document.getElementsByClassName("profile ant-dropdown-trigger ant-dropdown-open");
        // let menuItem = document.getElementsByClassName("ant-dropdown ant-dropdown-placement-bottomRight ");
        // filterButton[0].classList.remove("ant-dropdown-open");
        // menuItem[0].classList.add("ant-dropdown-hidden");
        this.setState({ categorySearchValue: "", startDateError: "", endDateError: "", startDate: "", endDate: "" }, () => this.getTransactionList())

    }


    getTransactionList = (downloadValue) => {
        let { page, limit, search, sortInfo, categorySearchValue, startDate, endDate } = this.state;
        let { order, fieldName } = sortInfo;
        let shouldSort = order && fieldName;
        // let categorySearchValue = this.state.purchaseType0 && 0 || this.state.purchaseType1 && 1 && this.state.purchaseType2 && 2;
        let req = {
            page: page ? page : 1,
            limit: limit,
            search: search.toString()
        }
        _Api(Url.GET_TRANSACTION.method, Url.GET_TRANSACTION.url, '', `?page=${req.page}&limit=${req.limit}&search=${req.search}&sortField=${shouldSort && fieldName || ""}&sortType=${shouldSort && order || ""}&category=${categorySearchValue}&dateFrom=${startDate && Date.parse(startDate).toString() || ""}&dateTo=${endDate && Date.parse(endDate).toString() || ""}&download=${downloadValue && downloadValue || 0}`)
            .then(resp => {

                if (resp.responseData.file) {
                    window.open(resp.responseData.file, "_blank");
                    this.setState({ isLoader: false });
                }
                else {
                    let responseData = resp.responseData.result;
                    this.setState({ transactionListData: responseData.data, totalCount: responseData.totalCount, isLoader: false, filterVisible: false })
                }


            }).catch(err => {
                this.setState({ isLoader: false, fasle: false });
                let errorMessage = err.error && err.error.responseMessage || err.message;
                notify("err", errorMessage);

            })
    }
    onChange = page => this.setState({ loader: true, page }, () => this.getTransactionList());
    handlePageChange = (page) => {
        this.setState({ loader: true, page, snNo: page - 1, transactionListData: [] }, () => {
            this.getTransactionList()
        })

    }
    handleSearch = e => this.setState({ search: e.target.value, page: 1, snNo: 0, transactionListData: [] }, () => this.getTransactionList());


    exportCSV = () => {
        this.getTransactionList("1");

    }
    onVATNumberChange = (e) => {
        this.setState({ vatNumber: e.target.value });

    }

    onPurchaseCheckedboxClick = (value) => {
        switch (value) {
            case "purchaseType0":
                this.setState({ purchaseType0: !this.state.purchaseType0, purchaseType1: false, purchaseType2: false, All: false, categorySearchValue: 0 })

                break;
            case "purchaseType1":
                this.setState({ purchaseType1: !this.state.purchaseType1, purchaseType0: false, purchaseType2: false, All: false, categorySearchValue: 1 })

                break;

            case "purchaseType2":
                this.setState({ purchaseType2: !this.state.purchaseType2, purchaseType1: false, purchaseType0: false, All: false, categorySearchValue: 2 })

                break;
            case "All":
                this.setState({ All: true, purchaseType2: false, purchaseType1: false, purchaseType0: false, categorySearchValue: "" });

                break;
            default:
                this.setState({ All: true })
                break;


        }

    }

    onVATSubmit = () => {
        let { vatNumber, transactionId } = this.state;
        let params = {
            transactionId: transactionId,
            vatNumber: vatNumber

        }

        _Api(Url.EDIT_VAT_NUMBER.method, Url.EDIT_VAT_NUMBER.url, params)
            .then(resp => {
                notify("success", "VAT Number Successfully Saved");
                this.getTransactionList();
                this.setState({
                    visible: false,
                });

            }).catch(err => {
                let errorMessage = err.error && err.error.responseMessage || err.message;
                notify("err", errorMessage);

            })

    }

    onClickSorting = (field) => {
        let { order, fieldName } = this.state.sortInfo;
        let orderType = 1;
        if (order && fieldName === field) {
            orderType = order < 0 ? '' : -1;
        }
        this.setState({
            sortInfo: {
                fieldName: field,
                order: orderType
            }
        }, () => this.getTransactionList());

    }
    handleVisibleChange = flag => {
        this.setState({ filterVisible: flag });
    };

    handleOnChange = (type, e) => {
        this.setState({ [type]: e, startDateError: '', endDateError: '' });
    }
    // onDateChecked = e => this.setState({ isDateChecked: !this.state.isDateChecked, startDateError: '', endDateError: '' });

    render() {

        let { transactionListData, search, isLoader, vatNumber, startDateError, endDateError, sortInfo } = this.state;
        let { order, fieldName } = sortInfo;
        return (
            <>
                <div className="dash_body_container">
                    <Header />
                    <Sidebar />
                    <div className="body_container">
                        {isLoader && <Loader />}
                        <div className="header-text  mb-4 mt-3">

                            <div className="d-flex align-items-center">
                                <p className="header-icon"><PaymentIcon /></p>
                                <h4>Transaction </h4></div>
                            <div className="d-flex align-items-center">
                                <div className="search_wrapper mr-2">
                                    <i className="fa fa-search" />
                                    <input type="search"
                                        placeholder="Search..."
                                        name='search'
                                        value={search}
                                        onChange={this.handleSearch}
                                    />
                                </div>

                                {transactionListData.length > 0 && <Link onClick={this.exportCSV} className="btn btn-red ml-2 mr-2 ">Export CSV</Link>}
                                <Dropdown className="ml-2" onVisibleChange={this.handleVisibleChange}
                                    visible={this.state.filterVisible} overlay={
                                        <Menu className="filtermenu">

                                            <div class="form-group form-check">
                                                <label class="form-check-label checkbox-header">
                                                    <input class="form-check-input"
                                                        type="radio"
                                                        onClick={() => this.onPurchaseCheckedboxClick("All")}
                                                        checked={this.state.All}
                                                    /> All Category
                                            </label>
                                            </div>
                                            <div class="form-group form-check">
                                                <label class="form-check-label">
                                                    <input class="form-check-input"
                                                        type="radio"
                                                        checked={this.state.purchaseType0}
                                                        onClick={() => this.onPurchaseCheckedboxClick("purchaseType0")}

                                                    /> Brand Showcase

                                            </label>
                                            </div>
                                            <div class="form-group form-check">
                                                <label class="form-check-label">
                                                    <input class="form-check-input"
                                                        type="radio"
                                                        checked={this.state.purchaseType1}
                                                        onClick={() => this.onPurchaseCheckedboxClick("purchaseType1")}
                                                    />  Premium Brand Showcase
                                            </label>
                                            </div>
                                            <div class="form-group form-check">
                                                <label class="form-check-label">
                                                    <input class="form-check-input"
                                                        type="radio"
                                                        checked={this.state.purchaseType2}
                                                        onClick={() => this.onPurchaseCheckedboxClick("purchaseType2")}
                                                    />
                                                    Buy Searches
                                            </label>
                                            </div>
                                            <Menu.Divider className="mb-3" />
                                            <div class="form-group">
                                                <label class="form-check-label checkbox-header">
                                                    Date
                                            </label>
                                            </div>

                                            <div class="form-group ">
                                                <div className="form-row">
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <level>From</level>
                                                            <DatePicker defaultValue={moment(new Date(), 'YYYY-MM-DD')}
                                                                onChange={(e) => this.handleOnChange("startDate", e)}
                                                            />
                                                            <InlineError
                                                                message={startDateError}
                                                            />


                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <level>To</level>
                                                            <DatePicker defaultValue={moment(new Date(), 'YYYY-MM-DD')}
                                                                onChange={(e) => this.handleOnChange("endDate", e)}
                                                            />
                                                            <InlineError
                                                                message={endDateError}
                                                            />

                                                        </div>
                                                    </div>

                                                </div>

                                                <div class="form-group ">
                                                    <div className="form-row">

                                                        <div className="col-sm-6">
                                                            <button type="button" class="btn btn-red btn-block btn-label btn-navyblue" onClick={this.onClearFilter}>Clear All</button>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <button type="button" class="btn btn-red btn-block btn-label" onClick={this.onSubmit}>Apply</button>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </Menu>
                                    } trigger={['click']}>
                                    <div className="profile">
                                        <span className="filter"><Filter /></span>
                                    </div>

                                </Dropdown>

                            </div>

                        </div>


                        <div class="table-responsive">
                            <table class="table table-bordered rounded-table">

                                <thead>
                                    <tr>
                                        {columnDetails.map((item) => {
                                            let { isSortable, dataIndex } = item;
                                            //      return <th><div className="d-flex justify-content-around align-items-center" onClick={() => this.onClickSorting(item?.dataIndex)}>{item?.fieldName} {item?.isSortable && <span className="d-flex"><i className={order == 1 ? "fa fa-arrow-up ml-1" : order == -1 ? "fa fa-arrow-down ml-1" : "fa fa-sort ml-1"} aria-hidden="true" onClick={() => this.onClickSorting(item?.dataIndex)} /></span>}</div></th>

                                            return <th><div className="d-flex justify-content-around align-items-center"
                                                onClick={() => this.onClickSorting(dataIndex)}>{item?.fieldName}
                                                {isSortable && <span className="d-flex"><i className={util.getSortingIcon(dataIndex == fieldName && order)}
                                                    aria-hidden="true"
                                                /></span>}</div></th>

                                        })}
                                    </tr>
                                </thead>

                                {transactionListData.length > 0 && transactionListData.map((item, index) => {
                                    let transactionStatus = (item?.transactionObject?.status == "succeeded" || item.status == 1) && "Success" || "Pending";
                                    let isSuccess = transactionStatus == "Success"
                                    return (<tr>
                                        <td><span className="circle-serial">{(this.state.snNo * 10) + index + 1}</span></td>
                                        <td><Link to={`/transactiondetail/${item?._id}`}> {item?.transactionUniqueId}</Link> </td>
                                        <td>{item?.userData && item?.userData.firstName} {item?.userData && item?.userData.lastName && item?.userData.lastName}</td>
                                        <td>{item?.userData && item?.userData.companyName}</td>
                                        <td>{item?.userData && item?.exhibitionData && item?.exhibitionData.exhibitionName}</td>
                                        <td>{item?.userData && item?.userData.email}</td>
                                        <td>{item?.userData && item?.userData.phoneNumber}</td>
                                        <td>{util.getPurchaseValue(item?.purchased)}</td>
                                        <td>{moment(item?.created).format("DD/MM/ YYYY")}</td>
                                        <td>
                                            <div className="d-flex justfy-content-end" onClick={() => this.showModal(item?.vatNumber, item?._id)} >
                                                {item?.vatNumber}<p onClick={() => this.showModal(item?.vatNumber, item?._id)} className="action_group" ></p>
                                                <div className="action_group ml-2"><p className="circle-serial"><Pencil /></p></div>
                                            </div>
                                        </td>
                                        <td className="action_group">
                                            <span className="badge badge-common" style={{ color: (isSuccess && '#52c41a' || "#e52113") }} >{transactionStatus}</span>

                                        </td>
                                    </tr>)

                                })
                                }

                            </table>

                            <div>
                                {!isLoader && transactionListData.length == 0 &&
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                            </div>
                            {
                                transactionListData != 0 ?
                                    <div >
                                        <Pagination
                                            totalPage={Math.ceil(this.state.totalCount / 10)}
                                            currentPage={this.state.page}
                                            onPressBtn={this.handlePageChange}
                                        />
                                    </div>

                                    : ''
                            }
                        </div>
                    </div>
                    <Modal
                        centered
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={null}
                        className="cm_popup transaction-modal"
                        width="800px"
                    >
                        <div className="rounded-textbox-form mt-5 mb-4 w-100" >
                            <div className="row">
                                <div className="col-md-8 offset-md-2 text-center">
                                    <h2><label className="mb-2">Enter VAT Number</label></h2>
                                </div>
                                <div className="col-md-8 offset-md-2">
                                    <div class="search-container d-flex">
                                        <input type="text"
                                            placeholder="Enter VAT Number"
                                            name='vatNumber' git
                                            value={vatNumber}
                                            class="form-control"
                                            onChange={this.onVATNumberChange}></input>
                                        <button type="type" onClick={() => this.onVATSubmit()} className="btn">Submit</button>
                                    </div>

                                </div>


                            </div>
                        </div>


                    </Modal>
                </div>
            </>
        );
    }
}
export default TransactionList;