import React, { Component } from 'react';


import { Carousel  } from 'antd';
import {Calender, Clock} from "../../SvgIcon";
import {LiveBtn} from "../common/Button";


class IndustrieCarousel extends Component {


    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.carousel = React.createRef();
    }


    next = () => {
        this.carousel.next();
    }
    previous = () => {
        this.carousel.prev();
    }

    render() {

        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,

            responsive: [

                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2,
                        dots: true,
                        arrows: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true,
                        arrows: false
                    }
                }
            ]

        }
        return (


            <div className="carousel-wrapper carousel-industry">
                <i class="fa fa-long-arrow-left" onClick={this.previous}/>

                <Carousel className="row d-flex" ref={node => (this.carousel = node)} {...settings} >

                    {
                        loop.map(item => {

                            return (
                                <div key={item.id} className="col-md-12 wrapper">


                                <img className="img-fluid"
                                     src={require(`../../assets/images/${item.img}`)}
                                     // ${item.img}
                                />

                                    <div className="bottom-left skew-text  ">
                                        <div className="d-flex skew-text justify-content-center">
                                            <h5 className="title d-inline">Skin care</h5>
                                            <i className="fa fa-long-arrow-right" />
                                        </div>
                                    </div>
                                </div>
                            )
                        })

                    }

                </Carousel>

                <i class="fa fa-long-arrow-right" onClick={this.next}/>
            </div>


        )

    }
}
export default IndustrieCarousel;

const loop = [
    {
        id:1,
        img: "girl-mekup-1.png"
    },
    {
        id:2,
        img: "girl-mekup-2.png"
    },
    {
        id:3,
        img: "girl-mekup-3.png"
    },
    {
        id:1,
        img: "girl-mekup-1.png"
    },
    {
        id:2,
        img: "girl-mekup-2.png"
    },
    {
        id:3,
        img: "girl-mekup-3.png"
    }
]


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (

            <i class="fa fa-long-arrow-right" onClick={this.next}/>

    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "green" }}
            onClick={onClick}
        />
    );
}


