import React, { Component } from 'react';

import WebHeader from '../../layout/WebHeader';
import { NoMediaICon } from "../../../SvgIcon";
import Footer from "../../layout/Footer"
import { Pagination } from "../../../components";
import FilterHeader from "../../layout/FilterHeader";
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { Select, Menu, Dropdown, Button } from 'antd';
import { notify } from '../../../components/Toaster';
import { connect } from "react-redux";
import { getWishList } from '../../../redux/action';
import {
    MyAlert,
    MySpinner
} from "../../../components/index";

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Alert, Spinner } from "react-bootstrap";
var _ = require('lodash');
const { Option } = Select;

const antIcon = <LoadingOutlined style={{ fontSize: 72 }} spin />;

let isProd = process.env.REACT_APP_ENV == 'prod'

function hasNumber(n) {
    return !isNaN(n);
  }

class ViewBrand extends Component {
    constructor(props) {
        super(props)
        this.state = {

            exhibtionId: '',
            page: 1,
            limit: 36,
            loading: false,
            visible: false,
            isLoader: true,
            search: props.searchKey || "",
            brandListData: [],
            snNo: 0,
            sortInfo: {},
            totalCount: 1,
            sortField: "",
            sortType: ""

        }
    }



    componentDidMount() {
        this.props.dispatch({ type: 'PATH_ADD', payload: this.props.match })
        

        console.log("pagessstypingggg", hasNumber(this.props.match.params.searchKey) )

        if (this.props.match.params.searchKey && hasNumber(this.props.match.params.searchKey)) {
            this.setState({ page: Number(this.props.match.params.searchKey) },()=>{
                this.getList();
            })
        }
        else{
            this.getList();
        }
    }


    handleChange(value) {
        this.setState({ sortField: "companyName", sortType: Number(value.key) }, () => this.getList())
    }
    getList = (data, isLoader = true) => {
        const { servicesShowcaseId = "", sectorName = "" } = data ? data : {}
        let { appLocalState } = this.props;
        this.setState({ isLoader: isLoader })
        let { page, limit, search, sortField, sortType } = this.state;
        let { multiFilter } = appLocalState;
        let req = {
            page: page ? page : 1,
            limit: limit,
            search: appLocalState.search || search.toString(),
            sortField: sortField,
            sortType: sortType,
            servicesShowcaseId: servicesShowcaseId,
        }

        console.log("diasgfcdghjk", appLocalState)
        let filterAll = {
            servicesShowcaseId: servicesShowcaseId,
            // productAndServiceOfferId: multiFilter.productAndServiceId.join(),
            // noOfPeopleWorkId: multiFilter.noOfPeopleWorkId.join(),
            // budgetId: multiFilter.budgetId.join(),
            // jobTitleId: multiFilter.jobTitleId.join(),
            roleId: multiFilter.jobTitleId.toString(),
            // location: multiFilter.location.join(),
            country: multiFilter.location.toString(),
            city: multiFilter.city.toString(),
            // attendingOrLookingforId :multiFilter.attendingOrLookingforId.join(),
            sectorId: multiFilter.sectorId.toString(),
        }

        let userAns = multiFilter.userAns;
        let keys = _.keys(userAns);
        let userAnswer = [];
        _.map(keys, function (key) {
            let data = {
                questionId: key,
                optionAnswerArray: userAns[key]
            }
            userAnswer.push(data);
        })

        if (userAnswer.length > 0) {
            filterAll.userAnswer = userAnswer
        }
        if (filterAll) {
            Object.assign(req, filterAll)
        }

        if (userAnswer.length > 0) {
            filterAll.userAnswer = userAnswer
        }

        let url = Url["FILTER_BRAND_LIST_V2"].url, method = Url["FILTER_BRAND_LIST_V2"].method;
        _Api(method, url, req)
            .then(resp => {
                this.setState({ brandListData: resp.responseData?.result?.data ? resp.responseData?.result?.data : [], totalCount: resp.responseData?.result?.totalCount, isLoader: false });
            }).catch(() => {
                this.setState({ isLoader: false });
            })
    }

    onPressBtn = (page) => {
        this.props.history.push(`/business-list/${page}`)
        this.setState({ page, snNo: page - 1, brandListData: [] }, () => {
            this.getList()
        })
        window.scrollTo(0,0)

    }
    handleSearch = e => this.setState({ search: e.target.value, brandListData: [], page: 1, snNo: 0 }, () => this.getList());
    onClickBrand = item => {
        let { appLocalState, dispatch, } = this.props;
        if (appLocalState.search) {
            _Api(Url.SEARCH_COUNT.method, Url.SEARCH_COUNT.url)
                .then(resp => {


                }).catch(err => {
                    this.handleError(err);
                });
        }

        this.props.history.push(`/business/${item._id}/${item?.activeCompany}`);



    }
    handleError(err) {
        let errorMessage = err.error && err.error.responseMessage || err.message;
        notify("err", errorMessage);
    }

    addToWishList = (event, category, id, api) => {
        event.stopPropagation();
        let stateName = `wish${id}`;
        this.setState({ [`isWishlistLoader${id}`]: true, [stateName]: !this.state[stateName], });
        let self = this;
        let params = category == "brand" ? { brandId: id } : { productId: id };
        let { method, url } = Url[api];
        _Api(method, url, params)
            .then(resp => {
                self.props.dispatch(getWishList());

                this.getList("", false);

                setTimeout(() => {
                    this.setState({ [`isWishlistLoader${id}`]: false });

                }, 2000);


            }).catch((err) => {
                this.handleError(err);
            })
    }
    render() {


        let { brandListData, isLoader } = this.state;
        this.props.location.pathname.includes("featured")
        return (

            <section className="brand-home-banners-bg brand-home-banner-bg">
                {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
                {/*     <ChatBox history={this.props.history} match={this.props.match} />*/}
                {/* { true && <a > <FilterHeader scope={this} setSelected={"Brand"}/></a> || <FilterHeader scope={this} setSelected={"Brand"}/>} */}
                <FilterHeader scope={this} setSelected={"Businesses"}
                    // //   ishideHeader={false}
                    // isProductTab = {true}
                    // //   hideProductReset={true}
                    isBrand={true}

                // isPeopleScreen={true}


                />
                <div className="container-fluid cm-section viewbrand">
                    <div className="cm-wrapper">
                        <div className="row">
                            <div className="col-lg-3 col-md-12 col">
                                <div className="header-text mb-0">
                                    <h1 className="title">Businesses</h1>
                                </div>

                            </div>
                            <div className="col-lg-9 col-md-12 d-grid align-items-center justify-content-end">

                                <div className="row search-filter-responsive">
                                    <div className="pr-3">

                                    </div>
                                    {/* <div className=" d-grid align-items-center text-right">
                                        <label className="h5 m-0" htmlFor="inputState">Sort by</label>
                                    </div> */}

                                    <div className="rounded-textbox-form filter-select pt-0 p-0 ">

                                        {/* <Select placeholder="Sorting" onChange={(e) => this.handleChange(e)}>
                                                <Option value={1}>Brand Name (A-Z)</Option>
                                                <Option value={-1}>Brand Name (Z-A)</Option>

                                            </Select> */}
                                        {/* ---------new sorting design start -------------- */}
                                        <Dropdown overlay={
                                            <Menu onClick={(e) => this.handleChange(e)}>
                                                <Menu.Item key="1">
                                                    <a>
                                                        Showcase (A-Z)
                                                  </a>
                                                </Menu.Item>
                                                <Menu.Item key="-1">
                                                    <a>
                                                        Showcase (Z-A)
                                                  </a>
                                                </Menu.Item>

                                            </Menu>
                                        } placement="bottomRight">
                                            <span className="sorting-dropdown"><i class="fa fa-sort-amount-desc" aria-hidden="true"></i></span>
                                        </Dropdown>
                                        {/* ---------new sorting design end -------------- */}

                                        {/* <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                <button className="dropdown-item" type="button">Action</button>
                                                <button className="dropdown-item" type="button">Another action</button>
                                                <button className="dropdown-item" type="button">Something else here
                                                </button>
                                            </div> */}
                                    </div>



                                </div>



                            </div>




                        </div>


                        <div className="row mb-3">

                            {
                                brandListData.map((item, key) => (
                                    <div key={key} className="col-xl-3 col-lg-4 col-sm-6 cursor-point" onClick={() => this.onClickBrand(item)}>

                                        <div className="card brand-card mb-5">
                                            <img className="img-fluid mb-4" src={item?.coverImage || require('../../../assets/images/profile-placeholder.png')} />

                                            <div className="top-right">
                                                {
                                                    < div className={(item?.wishListData) && "blue-circle red-circle" || "blue-circle"} onClick={(e) => this.addToWishList(e, 'brand', item?._id, item?.wishListData && "REMOVE_WISHLIST" || "ADD_WISHLIST")}>
                                                        {this.state[`isWishlistLoader${item?._id}`] &&
                                                            <Spinner className="text-center" size="xl" animation="border" variant="primary" /> || <i className="fa fa-heart-o" ></i>}

                                                    </div>}
                                            </div>
                                            <div className=" brand-card-body cursor-point" 
                                            // onClick={() => this.onClickBrand(item)}
                                             >
                                                <div className="brand-img-wrapper">
                                                    <img className="img-fluid" src={item?.logoImage || require('../../../assets/images/placeholder.png')} />
                                                </div>
                                                <h4>{item?.companyName}</h4>
                                                <p>{item?.about}</p>
                                            </div>
                                        </div>
                                    </div>


                                ))
                            }
                            {
                                !this.state.isLoader && brandListData?.length == 0 && <div className="w-100 text-center row nodata m-auto">
                                    <div className="m-auto">
                                        <NoMediaICon />

                                        <h2>No business Found</h2>
                                    </div>
                                </div>
                            }
                        </div>
                        {
                            brandListData.length > 0 && Math.ceil(this.state.totalCount / 36) > 1 &&
                            <div >
                                <Pagination
                                    totalPage={Math.ceil(this.state.totalCount / 36)}
                                    currentPage={this.state.page}
                                    onPressBtn={this.onPressBtn}
                                />
                            </div>
                        }
                    </div>
                </div>


                <Footer />
            </section>




        )
    }
}

function mapStateToProps(state) {
    return {
        wishList: state.wishList,
        appLocalState: state.appLocalState
    };
}

export default connect(mapStateToProps)(ViewBrand);
