import React, { Component } from 'react';
import Footer from '../../layout/Footer';
import { Hert, SearchIcon, Pencil, Delete } from '../../../SvgIcon';
import { Link } from "react-router-dom";
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';
import { Modal, Button, Empty } from 'antd';
import { Pagination } from '../../../components/common/Pagination';
import Loader from '../../../components/Loader';
import util from '../../../utils/Util.js';

const columnDetails = [{ fieldName: "S.No." },
{ fieldName: "Title", isSortable: true, dataIndex: "planName" },
{ fieldName: "USD Amount" },
{ fieldName: "GBP Amount" },
{ fieldName: "Total Searches", isSortable: true, dataIndex: "numberOfSearches" },
{ fieldName: "Status" },
{ fieldName: "Action" }
];


class Search extends Component {


    constructor() {
        super();
        this.state = {
            viewDetails: false,
            limit: 10,
            loading: false,
            selectedId: '',
            subscriptionListData: [],
            isLoader: false,
            search: '',
            totalCount: [],
            fieldName: '',
            order: '',
            promoCodeDetailData: [],
            visible: false,
            sortInfo: {},
            snNo: 0,
            page: 1

        };
    }
    componentDidMount() {
        this.getSubsriptionList();
    }
    getSubsriptionList = (downloadValue) => {
        this.setState({ isLoader: true });

        let { page, limit, search, sortInfo } = this.state;
        let { order, fieldName } = sortInfo;
        let req = {
            page: page ? page : 1,
            limit: limit,
            search: search.toString()
        }
        _Api(Url.SUBSCRIPTION_PLAN_LIST.method, Url.SUBSCRIPTION_PLAN_LIST.url, '', `?page=${req.page}&limit=${req.limit}&search=${req.search}&sortField=${fieldName}&sortType=${order}`)
            .then(resp => {
                let responseData = resp.responseData.result;
                this.setState({ subscriptionListData: responseData.data, totalCount: responseData.totalCount, isLoader: false });

            }).catch(err => {
                this.handleError(err);
            });
    }
    onStatusChange = (e, id) => {
        _Api(Url.SUBSCRIPTION_PLAN_STATUS_CHANGE.method, Url.SUBSCRIPTION_PLAN_STATUS_CHANGE.url, '', `?searchId=${id}&status=${(e.target.checked ? 1 : 0).toString()}`)
            .then(resp => {
                notify('success', resp.responseData.message);
                this.setState({ visible: false, statusCode: resp.responseData.statusCode });
                this.getSubsriptionList();
            }).catch(err => {
                this.handleError(err);
            })

    }
    handleError(err) {
        this.setState({ isLoader: false });
        let errorMessage = err.error && err.error.responseMessage 
        notify("err", errorMessage);
    }

    // handle ok 
    handleOk = e => {
        this.setState({
            visible: false,
        });
    };
    //Show modal
    showModal = (id) => {
        this.setState({
            visible: true,
            selectedId: id
        });

    };
    //Cancel modal
    handleCancel = () => {
        this.setState({ visible: false });
    };
    handlePageChange = (page) => {
        this.setState({ loader: true, page, snNo: page - 1, subscriptionListData: [] }, () => {
            this.getSubsriptionList()
        })

    }

    onClickSorting = (field) => {
        let { order, fieldName } = this.state.sortInfo;
        let orderType = 1;
        if (order && fieldName === field) {
            orderType = order < 0 ? '' : -1;
        }
        this.setState({
            sortInfo: {
                fieldName: field,
                order: orderType
            }
        }, () => this.getSubsriptionList());

    }

    deleteSubscriptionList = (id) => {
        let req = {
            isDeleted: 0,
            searchId: id,

        }
        _Api(Url.SUBSCRIPTION_PLAN_DELETE.method, Url.SUBSCRIPTION_PLAN_DELETE.url, '', `?searchId=${req.searchId}&isDeleted=${req.isDeleted}`)
            .then(resp => {
                console.log('delete', resp.responseData.message)
                notify('success', resp.responseData.message)
                this.setState({ visible: false })
                this.getSubsriptionList();
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
            })


    }
    handleSearch = e => this.setState({ search: e.target.value, page: 1, snNo: 0, promoListData: [] }, () => this.getSubsriptionList());

    render() {
        let { subscriptionListData, promoCodeDetailData, visible, loading, search, isLoader, sortInfo } = this.state;
        let { order, fieldName } = sortInfo;
        return (
            <>
                <div className="dash_body_container">
                    <Header />
                    <Sidebar />
                    <div className="body_container">
                        <div className="header-text d-flex mb-4 mt-3">
                            <div className="d-flex  flex-grow-1 align-items-center">
                                <p className="header-icon"><SearchIcon /></p>
                                <h4>Search Management
                                </h4>
                            </div>

                            <div className="search_wrapper mr-3">
                                <i className="fa fa-search" />
                                <input type="search"
                                    placeholder="Search..."
                                    name='search'
                                    value={search}
                                    onChange={this.handleSearch}

                                />
                            </div>
                            <Link to="/add-plan" className="btn btn-red">Default Plan</Link> &nbsp;
                            <Link to="/add-search" className="btn btn-red">Add Plan</Link>

                        </div>


                        <div className="table-responsive">
                            <table className="table table-bordered rounded-table">
                                <thead>
                                    <tr>
                                        {columnDetails.map((item) => {
                                            let { isSortable, dataIndex } = item;

                                            return <th><div className="d-flex justify-content-center align-items-center"
                                                onClick={() => this.onClickSorting(dataIndex)}>{item?.fieldName}
                                                {isSortable && <span className="d-flex"><i className={'ml-2 ' + util.getSortingIcon(dataIndex == fieldName && order)}
                                                    aria-hidden="true"
                                                /></span>}</div></th>

                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        subscriptionListData.map((item, index) => (
                                            <tr>
                                                <td><span className="circle-serial">{(this.state.snNo * 10) + index + 1}</span></td>
                                                <td><Link to={`view-search/${item?._id}`}> {item?.planName}</Link> </td>
                                                <td>{item?.dollarAmount && `$${item?.dollarAmount}`}</td>
                                                <td>{item?.euroAmount && `€${item?.euroAmount}`}</td>
                                                <td>{item?.numberOfSearches}</td>
                                                <td>
                                                    <p className="toggle-parent ">
                                                        <label className="switch">
                                                            <input type="checkbox" checked={item?.status === 1 ? true : false}
                                                                onChange={(e) => this.onStatusChange(e, item?._id)} />
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </p>
                                                </td>
                                                <td className="action_group">
                                                    <Link to={`/edit-search/${item?._id}`}> <p><p className="circle-serial" ><Pencil /></p></p></Link>
                                                    <p onClick={() => this.showModal(item?._id)}><p className="circle-serial"><Delete /></p></p>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>

                            <div>
                                {!isLoader && subscriptionListData.length == 0 &&
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                            </div>
                            {
                                subscriptionListData.length > 0 &&
                                <div >
                                    <Pagination
                                        totalPage={Math.ceil(this.state.totalCount / 10)}
                                        currentPage={this.state.page}
                                        onPressBtn={this.handlePageChange}
                                    />
                                </div>
                            }
                        </div>
                        <Modal
                            visible={visible}
                            title="Delete"
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                            footer={[
                                <Button key="back" onClick={this.handleCancel}>
                                    Cancel
                             </Button>,
                                <Button key="submit" type="primary" loading={loading} onClick={() => this.deleteSubscriptionList(this.state.selectedId)}>
                                    Delete
                            </Button>,
                            ]}
                        >
                            <p>Are you sure you want to delete this ?</p>
                        </Modal>

                    </div>
                </div>
            </>
        );
    }
}
export default Search;