import React, { Component } from 'react';

import WebHeader from '../../layout/WebHeader';
import Footer from "../../layout/Footer"
import { Pagination } from "../../../components";
import FilterHeader from "../../layout/FilterHeader";
import CommingSoonLayer from "../../../components/common/CommingSoonLayer";
import { Brands, Location, Hert, NoMediaICon, ZoomVideo, Chat } from '../../../SvgIcon';
import { Link } from "react-router-dom";
import { Empty } from 'antd';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';

import { notify } from '../../../components/Toaster';
import { CmBtn } from '../../../components/common/Button';

import moment from 'moment-timezone';
import socket from "../../../services/socket";
import { groupActions } from "../../../redux/action";

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import LazyLoad from 'react-lazy-load';
const antIcon = <LoadingOutlined style={{ fontSize: 72 }} spin />;

class ViewSearch extends Component {



    constructor(props) {
        super(props);
        this.state = {
            exhibtionId: '',
            page: 1,
            limit: 10,
            loading: false,
            isLoading: true,
            search: '',
            snNo: 0,
            sortInfo: {},
            industryData: [],
            totalCount: 0,
            detailList: []
        }
    }

    componentDidMount() {



        this.props.dispatch({ type: 'PATH_ADD', payload: this.props.match })
        this.setState({ page: this.props.match.params.page ? Number(this.props.match.params.page) : 1 }, () => {
            this.getList()
        })

    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.searchKey !== this.props.match.params.searchKey) {
            this.setState({ page: 1, detailList: [], }, () => {
                this.getList()
            })

        }
    }

    onPressBtn = (page) => {
        window.scrollTo(0, 0)
        this.props.history.push(`/search/results/all/page=${page}&keywords=${this.props.match.params.searchKey}`)

        this.setState({ page, snNo: page - 1, brandListData: [] }, () => {
            this.getList()
        })

    }
    handleSearch = e => this.setState({ search: e.target.value, brandListData: [], page: 1, snNo: 0 }, () => this.getCategory());

    getList = () => {
        let { limit, page } = this.state;
        this.setState({ isLoading: true })
        let req = {
            page: page ? page : 1,
            limit: limit,
            searchKey: this.props.match.params.searchKey,
            exhibitionId: localStorage.getItem("exhibition")
        }
        let stateData = "seacrhData"

        let url = Url["SEARCH_LIST"].url, method = Url["SEARCH_LIST"].method;
        _Api(method, url, req)
            .then(resp => {
                this.setState({
                    isLoading: false,
                    detailList: resp.responseData?.details || [],
                    totalCount: resp.responseData.totalCount
                })

            }).catch((err) => {
                if (err.error?.errorCode == 19) {
                    this.setState({ isLoading: false, visible: true, isPaidUser: false, repayment: 1 });
                }
                if (err.error?.errorCode == 5) {
                    this.setState({
                        detailList: [],
                        isEmpty: true
                    })
                }
                else {

                    // this.handleError(err);
                    //handle for error : This will stop loader
                    this.setState({ isLoading: false, [`${stateData}Loaded`]: true });
                }


            })
    }
    handleError(err) {
        let errorMessage = err.error && err.error.responseMessage || err.message;
        notify("err", errorMessage);
    }

    onAcceptReject(e, requestId, status) {
        e.preventDefault();
        let req = {
            requestId: requestId,
            status: status
        }
        _Api(Url.COMMUNITY_ACCEPTREJECT.method, Url.COMMUNITY_ACCEPTREJECT.url, req)
            .then(resp => {
                this.getList();
            }).catch((err) => {
                this.handleError(err);
            })

    }
    onMoreClick = (e, id, userId, a, event, data) => {
        switch (e.key) {
            case "Remove":
                this.onRemoveAndBlock("COMMUNITY_CONNECTION_REMOVE", 3, id, event.domEvent);
                break;
            case "Block":
                this.onRemoveAndBlock("COMMUNITY_CONNECTION_BLOCK", 1, id, event.domEvent);
                break;
            case "Report":
                this.showModal(id, data, event.domEvent);
                break;
            case "AddBusinessConnection":
                this.onCreateBussinessRequest(userId);
                break;
            default:
                return null;


        }
    }
    onRemoveAndBlock = (api, status, id, e) => {
        this.setState({ [`connection${id}`]: true });
        e.stopPropagation();
        let req = {
            requestId: id,
            status: status
        }
        _Api(Url[api].method, Url[api].url, req)
            .then(resp => {
                this.setState({ [`connection${id}`]: false });
                this.getList();
            }).catch((err) => {
                this.handleError(err);
            })

    }









    onConnectRequest(e, requestId, status) {

        e.stopPropagation();
        let req = {
            requestedUserId: requestId,
            connectionType: 1
        }
        _Api(Url.COMMUNITY_CREATEREQUEST.method, Url.COMMUNITY_CREATEREQUEST.url, req)
            .then(resp => {
                notify("success", resp.responseData.message);
                this.getList();
            }).catch((err) => {
                this.handleError(err);
            })

    }

    videoCalling = (userId) => {
        let self = this;
        var windowReference = window.open();
        if (userId) {
            self.setState({
                ['videoConnectionLoder_' + userId]: true
            })
            self.props.dispatch(groupActions.joinOneMeeting({ userId: userId, type: 7 }))
                .then(function (res) {
                    self.setState({
                        ['videoConnectionLoder_' + userId]: false
                    })
                    if (res.statusCode === 1 || res.statusCode === "1") {
                        var newWin = window.open('/calling/' + self.props.groupDetail.meeting._id, "_blank", "width=" + window.screen.width + ",height=" + window.screen.height)
                        console.log("newWin", newWin);
                        if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
                            windowReference.location = '/calling/' + self.props.groupDetail.meeting._id;
                            //alert("New windows pop-up is blocked, Please allow.")
                        }
                    } else {

                    }
                    console.log("myWindow", res)
                })
        }
    }

    selectUser = (user) => {
        let self = this;
        // console.log("user",user);
        if (this.props.userDetail._id !== user._id) {
            this.setState({
                selectedUser: user,
                selectedUserId: user._id,
                chatType: 3,
                isShow: true
            }, function () {
                self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 3 })
                self.props.dispatch({ type: 'CHAT_OPEN' })
                document.body.classList.toggle('chat_open_mob');
                self.props.dispatch({ type: 'SELECTED_USER', selectedUser: user })
                socket.emit('groupAdd', { type: 7, userId: user._id });
                //socket.emit('userConnection', {userId: user._id});
            })
        }

    }









    onLinkClick = (value, scope) => {
        _Api(Url.SEARCH_COUNT.method, Url.SEARCH_COUNT.url)
            .then(resp => {


            }).catch(err => {
                // scope.handleError(err);
            });

        scope.props.history.push(value);

    }

    getSearchForPeople = (item) => {
        console.log("abcdefgh", item)
        let word = String(this.props.match.params.searchKey).toLocaleLowerCase()

        let { aboutMe, workExperience, qualification, sectorId, placeOfWork, roleId } = item

 

        let text = ""
        if (aboutMe) {
            let temp_text = String(aboutMe).toLocaleLowerCase();
            let index = temp_text.indexOf(word);
            if (index >= 0) {
                temp_text = "About: " + temp_text.split(word).join(`<b>${word}</b>`)
                text = temp_text;
            }

        }

        if (workExperience) {
            workExperience.forEach((item) => {
                let {
                    organization,
                    designation,
                    description } = item
                organization = String(organization).toLocaleLowerCase()
                designation = String(designation).toLocaleLowerCase()
                description = String(description).toLocaleLowerCase()
                let index1 = designation.indexOf(word)
                let index2 = description.indexOf(word)
                let index3 = organization.indexOf(word)
                if (index1 >= 0) {
                    text = "Designation: " + designation.split(word).join(`<b>${word}</b>`)

                }

                if (index2 >= 0) {
                    text = "Description: " + description.split(word).join(`<b>${word}</b>`)

                }
                if (index3 >= 0) {
                    text = "Organization: " + organization.split(word).join(`<b>${word}</b>`)

                }
               




            })

        }
        if (qualification) {
            qualification.forEach((item) => {
                let {
                    highestQualification,
                    specialization,
                    institute
                } = item
                highestQualification = String(highestQualification).toLocaleLowerCase()
                specialization = String(specialization).toLocaleLowerCase()
                institute = String(institute).toLocaleLowerCase()
                let index1 = highestQualification.indexOf(word);
                let index2 = specialization.indexOf(word);
                let index3 = institute.indexOf(word);
                if (index1 >= 0) {
                    text = "Qualification: " + highestQualification.split(word).join(`<b>${word}</b>`)

                }
                if (index2 >= 0) {
                    text = "Specialization: " + specialization.split(word).join(`<b>${word}</b>`)

                }
                if (index3 >= 0) {
                    text = "Institute: " + institute.split(word).join(`<b>${word}</b>`)

                }
               




            })

        }

        if (sectorId) {
            sectorId.forEach((item) => {
                let {
                    name } = item
                name = String(name).toLocaleLowerCase()
              
                let index3 = name.indexOf(word)
               
                if (index3 >= 0) {
                    text = "Sector: " + name.split(word).join(`<b>${word}</b>`)

                }
               




            })

        }

        if (placeOfWork) {
            placeOfWork.forEach((item) => {
                let {
                    name } = item
                name = String(name).toLocaleLowerCase()
              
                let index3 = name.indexOf(word)
               
                if (index3 >= 0) {
                    text = "Place Of Work: " + name.split(word).join(`<b>${word}</b>`)

                }
               




            })

        }

        if (roleId) {
            roleId.forEach((item) => {
                let {
                    name } = item
                name = String(name).toLocaleLowerCase()
              
                let index3 = name.indexOf(word)
               
                if (index3 >= 0) {
                    text = "Role: " + name.split(word).join(`<b>${word}</b>`)

                }
               




            })

        }



      

        return (text ?text : this.getListData(item))


    }

    getListData = (item)=>{
        console.log("testtttttttm", item)
        let { aboutMe, workExperience, qualification, companyName, country } = item
        
      if(aboutMe)
       return `About: ${aboutMe}`

       else if(workExperience){
           let {designation} = workExperience[0]
       return `Designation: ${designation}`
       }

       else
        return ''
          

    }

    getsearchBrand = (item) => {
        let word = String(this.props.match.params.searchKey).toLocaleLowerCase()
        let { about} = item
        console.log("brandssssss", item)
        let text = ""
        if (about) {
            let temp_text = String(about).toLocaleLowerCase();
            let index = temp_text.indexOf(word);
            if (index >= 0) {
                temp_text = "About: " + temp_text.split(word).join(`<b>${word}</b>`)
                text = temp_text;
            }
        }
        return text ? text : "About: " + about
    }

    getSearchProduct = (item)=>{
        let word = String(this.props.match.params.searchKey).toLocaleLowerCase()
        let { description} = item
        console.log("brandssssss", item)
        let text = ""
        if (description) {
            let temp_text = String(description).toLocaleLowerCase();
            let index = temp_text.indexOf(word);
            if (index >= 0) {
                temp_text = "Description: " + temp_text.split(word).join(`<b>${word}</b>`)
                text = temp_text;
            }
        }
        return text ? text : "Description: " + description
        

    }

    getSearchConference = (item)=>{
        console.log("conferenceeeddidsss", item) 
        let word = String(this.props.match.params.searchKey).toLocaleLowerCase()
        let { description} = item
        console.log("brandssssss", item)
        let text = ""
        if (description) {
            let temp_text = String(description).toLocaleLowerCase();
            let index = temp_text.indexOf(word);
            if (index >= 0) {
                temp_text = "Description: " + temp_text.split(word).join(`<b>${word}</b>`)
                text = temp_text;
            }
        }
        return text ? text : "Description: " + description
        

    }

    createSearchResult = (source, limit) => {
        let { detailList } = this.state
        let desArray = [];
        detailList?.length > 0 && detailList.forEach((item => {
            console.log("djsahghbjklsa", item)
            switch (Number(item.dataType)) {
                case 1:
                    desArray.push(
                        <div onClick={(e) => this.onLinkClick(`/business/${item._id}/${item?.activeCompany}`, this)} className="notify-row cursor-point">
                            <LazyLoad>
                                <div className="profile-dp">
                                    <img src={item.logoImage || require('../../../assets/images/placeholder/brand.jpg')} className="" />
                                </div>
                            </LazyLoad>
                            <div className="message">
                                <div>
                                    <Link ><h5><b className="mr-3">{item.companyName || item?.name}</b>• <span><small>  Business</small></span></h5> </Link>
                                    <div dangerouslySetInnerHTML={{ __html: this.getsearchBrand(item) }}></div>
                                    
                                    <h5 className="mt-3 mb-2"><span>{item?.streetAddress1 && <Location />}</span>{item?.streetAddress1}</h5>
                                </div>

                                {/* {<Link to={`/brand/${item?._id}/${item?.activeCompany}`} className="btn btn-blue business-btn" bg="#2d688d">View Brand Showcase </Link>} */}

                            </div>
                        </div>

                    );
                    break;
                case 2:
                    desArray.push(

                        <div onClick={(e) => this.onLinkClick(`/web-job-details/${item._id}`, this)} className="notify-row cursor-point">
                            <LazyLoad>
                                <div className="profile-dp">
                                    <img src={item?.image || require('../../../assets/images/placeholder/product.jpg')} className="" />
                                </div>
                            </LazyLoad>
                            <div className="message">
                                <div>
                                    <Link ><h5><b className="mr-3">{item.name}</b>• <span><small>  Job</small></span></h5> </Link>
                                    <div dangerouslySetInnerHTML={{ __html: this.getSearchProduct(item) }}></div>

                                    {/* <h5 className="mt-3 mb-2"><span><Location /></span>{item?.streetAddress1}</h5> */}
                                </div>

                            </div>
                        </div>

                    );
                    break;
                case 5:
                    desArray.push(

                        <div onClick={(e) => this.onLinkClick(`/seminar/${item._id}`, this)} className="notify-row cursor-point">
                            <LazyLoad>
                                <div className="profile-dp">
                                    <img src={item?.coverImage || require('../../../assets/images/placeholder/conferences.jpg')} className="" />
                                </div>
                            </LazyLoad>

                            <div className="message">
                                <div>
                                    <Link ><h5><b className="mr-3">{item?.name} </b>• <span><small>  Seminar</small></span></h5> </Link>
                                    <p>Start Date: {moment(item?.startDate).format("DD/MM/YY")} |   End Date: {moment(item?.endDate).format("DD/MM/YY")} </p>

                                    {/* <h5 className="mt-3 mb-2"><span>{item?.description}</span></h5> */}
                                    <div dangerouslySetInnerHTML={{ __html: this.getSearchConference(item) }}></div>
                                </div>

                            </div>
                        </div>

                    );
                    break;
                case 7:
                    desArray.push(
                        <div onClick={(e) => this.onLinkClick(`/home`, this)} className="notify-row cursor-point">
                            <LazyLoad>
                                <div className="profile-dp">
                                    <img src={item.image || require('../../../assets/images/placeholder/lounge.jpg')} className="" />
                                </div>
                            </LazyLoad>
                            <div className="message">
                                <div>
                                    <Link ><h5><b className="mr-3">{item?.name} </b>• <span><small>  Lounge</small></span></h5> </Link>
                                    <p>Start Date: {moment(item?.startDate).format("DD/MM/YY")} |   End Date: {moment(item?.endDate).format("DD/MM/YY")} </p>

                                    <h5 className="mt-3 mb-2"><span>{item?.description}</span></h5>
                                </div>

                            </div>
                        </div>
                    );
                    break;
                case 6:
                    desArray.push(
                        <div onClick={(e) => this.onLinkClick(`/home`, this)} className="notify-row cursor-point">
                            <LazyLoad>
                                <div className="profile-dp">
                                    <img src={item?.image || require('../../../assets/images/placeholder/stages.jpg')} className="" />
                                </div>
                            </LazyLoad>
                            <div className="message">
                                <div>
                                    <Link ><h5><b >{item?.name} </b><span className="ml-3"> • <span><small>  Stage</small></span></span></h5> </Link>
                                    <p>Start Date: {moment(item?.startDate).format("DD/MM/YY")} |   End Date: {moment(item?.endDate).format("DD/MM/YY")} </p>

                                    <h5 className="mt-3 mb-2"><span>{item?.description}</span></h5>
                                </div>

                            </div>
                        </div>
                    );
                    break;

                case 4:
                    desArray.push(
                        <div className="notify-row srch-list-rslt">
                            <LazyLoad>
                                <div className="profile-dp cursor-point  notify-cht">
                                    <img onClick={(e) => this.onLinkClick(`/user/profile/${item._id}`, this)} src={item?.image || require('../../../assets/images/placeholder/user.jpg')} className="circle-image" />
                                    {<span className={`chat cursor-point ${item.isOnline == 1 ? 'green' : 'yellow'}`} onClick={() => this.selectUser(item)} ><Chat /></span>}
                                </div>
                            </LazyLoad>
                            <div className="message">
                                <div onClick={(e) => this.onLinkClick(`/user/profile/${item._id}`, this)} >
                                    <Link ><h5><b >{item?.name}</b><span className="ml-3">• <span>&nbsp;<small > People</small></span></span></h5> </Link>
                                    {/* <p>{item?.jobTitleId}</p> */}
                                    <h6>{item?.companyName}</h6>
                                    <div dangerouslySetInnerHTML={{ __html: this.getSearchForPeople(item) }}></div>
                                    <h5 className="mt-3 mb-2"><span>{item?.country && <Location />}</span>{item?.country}</h5>
                                </div>
                                <div className="action_group  ">
                                    {localStorage.getItem('id') != item._id && <span className="action_group  ">
                                        {item?.userConnection?.status == 1 && <button className="btn btn-blue" onClick={(e) => this.videoCalling(item._id, e)} ><span className="zoom"><ZoomVideo /></span>Video Call</button>}
                                        {item?.userConnection?.status == item._id && <CmBtn text="Requst Received" className="btn btn-blue" onClick={(e) => this.onClickConnection(item?._id)} />}
                                        {item?.userConnection == 4 && <CmBtn text="Connect" className="btn btn-blue" bg='var(--btncolor)' onClick={(e) => this.onConnectRequest(e, item._id, 1)} />}
                                        {item?.userConnection?.status == 0 && item?.userConnection.requestedUserId != item._id && <CmBtn text="Cancel Request" className="btn btn-blue" bg='var(--connectBtn)' onClick={(e) => this.onRemoveAndBlock("COMMUNITY_CONNECTION_REMOVE", 3, item?.userConnection?._id, e)} />
                                        }
                                        {item?.userConnection?.status == 0 && item?.userConnection.requestedUserId == item._id && < div className="d-flex justify-content-start">
                                            <CmBtn text=" Accept" className="btn btn-blue mr-2" bg='var(--btncolor)' onClick={(e) => this.onAcceptReject(e, item?.userConnection?._id, 1)} />
                                            &nbsp;&nbsp; <CmBtn text="Ignore" className="btn btn-blue" bg="#fff" color="#A3AAB0" border="1px solid #91cbd3" onClick={(e) => this.onAcceptReject(e, item?.userConnection?._id, 2)} />
                                        </div>}
                                        {((item?.userPlan == 2 || item?.userPlan == 3) || item?.userType == 4) && <Link to={`/business/${item?.userType == 4 ? item?.ownerId : item?._id}/${item?.activeCompany}`} className="btn btn-blue business-btn" bg="#2d688d">View Brand Showcase </Link>}
                                    </span>}

                                </div>
                                {/* <div className="action_group  ">
                                    <CmBtn text="Connect" className="btn btn-blue" bg='var(--btncolor)' />
                                </div> */}
                            </div>
                        </div>)

                    break;

            }

        }))

        return desArray;
    }


    render() {
        let { detailList, totalCount } = this.state;
        return (
            <div className="Home-banner-bg">
                {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
                {/*<ChatBox history={this.props.history} match={this.props.match} />*/}
                {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
                {/*     <ChatBox history={this.props.history} match={this.props.match} />*/}
                <FilterHeader scope={this} ishideSearch={true} ishideHeader={true} />

                <div className="header-wrapper">
                    <div className="container search-result-parent">

                        {
                            this.state.isLoading ?
                                <center> <Spin size="large" tip="Loading..." indicator={antIcon} /> </center>
                                :
                                <div className="cm-wrapper">
                                    <div className="checkbox-item-wrap">
                                        {/* <div className="d-flex align-items-center">
                                            <p className="header-icon"><Hert className="red" /></p>
                                            <h6>About {totalCount} Results found</h6>
                                        </div> */}
                                    </div>
                                    <div >

                                        {this.createSearchResult()}
                                    </div>
                                    {!this.state.isLoading && detailList?.length == 0 && <div className="w-100 text-center row nodata m-auto">
                                        <div className="m-auto">
                                            <NoMediaICon />

                                            <h2>No data Found</h2>
                                        </div>   </div>
                                    }
                                    {!this.state.isLoading && this.state.totalCount >= 10 &&

                                        <div >
                                            <Pagination
                                                totalPage={Math.ceil(this.state.totalCount / 10)}
                                                currentPage={this.state.page}
                                                onPressBtn={this.onPressBtn}
                                            />
                                        </div>
                                    }
                                </div>


                        }

                    </div>
                </div>


                <Footer />
            </div>




        )
    }
}
// export default ViewSearch;
function mapStateToProps(state) {
    return {
        ...state,
    };
}

export default connect(mapStateToProps)(ViewSearch);
