import React,{Suspense} from 'react';
import ReactDOM from 'react-dom';
import useVH from 'react-viewport-height';
import './index.css';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import './App.css';
import "../node_modules/slick-carousel/slick/slick.css";
import "../node_modules/slick-carousel/slick/slick-theme.css";
// import "../node_modules/react-big-calendar/lib/css/react-big-calendar.css";
// import '@zach.codes/react-calendar/dist/calendar-tailwind.css';



//react-redux
import { Provider } from 'react-redux'
//store
import Store from './redux/store/Store';
import { hotjar } from 'react-hotjar';
// notification
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { StripeProvider, Elements } from 'react-stripe-elements';
import siteSetting from './config/env/Index';
import ClearCache from "react-clear-cache";
import ReactGA from 'react-ga';
import {getExhibitionId} from "./redux/action/index"
import {createBrowserHistory} from "history";

import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';

const Routes = React.lazy(() => import('../src/routing/root'));
const history = createBrowserHistory();

if (process.env.REACT_APP_ENV === 'prod' || process.env.REACT_APP_ENV === 'production'||process.env.REACT_APP_ENV === 'prod1') {
    // ReactGA.initialize(siteSetting.googleAnalyticsCode);
    // ReactGA.pageview(window.location.pathname + window.location.search);
    //
    hotjar.initialize(siteSetting.hotjarId, 6);
    console.log = function () {}
}
if(siteSetting.googleAnalyticsCode&&siteSetting.googleAnalyticsCode!==""){
    console.log("#######location",history);
    ReactGA.initialize(siteSetting.googleAnalyticsCode);
    // ReactGA.pageview(window.location.pathname + window.location.search);
}

if(siteSetting.hotjarId&&siteSetting.hotjarId!==""){
    hotjar.initialize(siteSetting.hotjarId, 6);
}

if (process.env.REACT_APP_ENV === 'amProd') {
    console.log = function () {}
}
Store.dispatch(getExhibitionId())

ReactDOM.render(

    <Provider store={Store}>
        <Suspense fallback={<></>} >
        
        <ClearCache auto={true}>
            {({ isLatestVersion }) => (
                <>
                    {/* {console.log( "isLatestVersion # ",isLatestVersion,localStorage.getItem('APP_VERSION'))} */}
                    <StripeProvider apiKey={siteSetting.stripe_key}>
                        <Elements>
                            <Routes />
                        </Elements>
                    </StripeProvider>
                    <ToastContainer />
                </>
            )}
        </ClearCache>
        </Suspense>
    </Provider>
    , document.getElementById('root'));


serviceWorker.unregister();
// if (!util.isSafari) {
//     console.log("mode", util.isSafari)
//     serviceWorker.registerFCMServiceWorker();
// }

