import React, { Component } from 'react';
import { BtnRed } from "../../../components/common/Button";
import Footer from "../../layout/Footer"
import VisitedBrands from "../../../components/carousel/VisitedBrands"
import VisitedProducts from "../../../components/carousel/VisitedProducts";
import CommingSoonLayer from "../../../components/common/CommingSoonLayer";
import { DeliverTraingle, NoMediaICon, Brands, NewBadges } from '../../../SvgIcon';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';
import { connect } from "react-redux";
import { getWishList } from '../..//../redux/action';
import { Link } from "react-router-dom";
import { Tooltip } from 'antd';
import { Alert, Spinner } from "react-bootstrap";

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { bindActionCreators } from "redux"

import { getBrandHome } from "../../../redux/action/Get";
import LazyLoad from 'react-lazy-load';
import Thankyou from "../../home/HomeCommingSoon"

import Const from "../../../services/Const"

const antIcon = <LoadingOutlined style={{ fontSize: 72 }} spin />;

let count = 0

let isProd = process.env.REACT_APP_ENV == 'prod'

const { isHideBrand, tab3body } = Const

class WebBrand extends Component {

    static getDerivedStateFromProps(nextProps, prevState) {
        const { brandHome } = nextProps
        if (brandHome != prevState?.brandHome) {
            return {
                isLoading: false,
                brandListData: brandHome?.brandData.data,
                mostVisitedBrand: brandHome?.mostVisited,
                productListData: brandHome?.productList.data,
                mostVisitedProduct: brandHome?.mostVisitedProduct,
                adsList: brandHome?.adsData,
                industryData: brandHome?.categoryData,
                isLoader: false
            }

        }
        return null

    }

    constructor(props) {
        super(props);
        this.state = {
            isCommingSoon: false,
            brandListData: [],
            productListData: [],
            industryData: [],
            isLoader: false,
            isLoading: true,
            brandListDataLoaded: false,
            productListDataLoaded: false,
            industryDataLoaded: false,
            adsList: [],
            adsImage: '',
            redirectionUrl: '',
            brandHome: []


        }
    }

    componentDidMount() {
        let self = this
        this.props.dispatch({ type: 'PATH_ADD', payload: this.props.match })
        // this.getList();     
        self.props.dispatch(getBrandHome());
        // this.getList("FEATURED_PRODUCT_LIST", "productListData", "mostVisitedProduct");
        // this.getCategory();
        // this.getAdList()
        if (localStorage.getItem('accessToken') && localStorage.getItem('accessToken') !== '') {

        } else {
            window.location.href = "/"
        }
    }

    // getAdList = () => {
    //     console.log('respexList')
    //     _Api(Url.GET_Ad_LIST.method, Url.GET_Ad_LIST.url, '')
    //         .then(resp => {
    //             console.log('respexList', resp.responseData.result.data)
    //             this.setState({ adsList: resp.responseData.result.data }, () => {
    //                 this.setImageInterval()
    //             })

    //         }).catch(err => {


    //         })
    // }


    // setImageInterval = () => {
    //     setInterval(() => {
    //         this.setImages()
    //     }, 5000)

    // }


    setImages = () => {
        let ads = this.state.adsList

        if (ads?.length == count + 1)
            count = 0
        else {
            if (ads[count])
                this.setState({ adsImage: ads[count].thumbUrl, redirectionUrl: ads[count].redirectionUrl })
            count = count + 1
        }


        // console.log("pokijhdskm", ads)



    }






    // getCategory = () => {
    //     let params = {
    //         categoryType: "4",
    //     }
    //     _Api(Url.GET_CATEGORY_LIST.method, Url.GET_CATEGORY_LIST.url, params)
    //         .then(resp => {
    //             this.setState({ industryData: resp.responseData?.result, industryDataLoaded: true })

    //         }).catch(() => {
    //             //handle for error : This will stop loader
    //             this.setState({ industryDataLoaded: true, });
    //         })
    // }

    getList = (isFromWishlist) => {
        if (!isFromWishlist) {
            this.setState({ isLoader: true })

        }


        let url = Url["GET_ALL_BRAND_DATA"].url, method = Url["GET_ALL_BRAND_DATA"].method;

        _Api(method, url)
            .then(resp => {
                this.setState({
                    isLoading: false,
                    brandListData: resp.responseData?.brandData.data,
                    mostVisitedBrand: resp.responseData?.mostVisited,
                    productListData: resp.responseData?.productList.data,
                    mostVisitedProduct: resp.responseData?.mostVisitedProduct,
                    adsList: resp.responseData?.adsData,
                    industryData: resp.responseData?.categoryData,
                    isLoader: false

                })
            }).catch((err) => {
                //handle for error : This will stop loader
                this.setState({ isLoading: false, isLoader: false });
            })
    }
    handleError(err) {
        let errorMessage = err.error && err.error.responseMessage || err.message;
        notify("err", errorMessage);
    }

    addToWishList = (event, category, id, api) => {
        event.preventDefault();
        event.stopPropagation();
        let stateName = `wish${id}`;
        this.setState({ [`isWishlistLoader${id}`]: true, [stateName]: !this.state[stateName] });
        let self = this;
        let params = category == "brand" ? { brandId: id } : { productId: id };
        let { method, url } = Url[api];
        _Api(method, url, params)
            .then(resp => {

                this.state.brandListData.forEach(item => {
                    if (item._id == id) {
                        item.wishListData = !item.wishListData
                    }
                });
                this.state.productListData.forEach(item => {
                    if (item._id == id) {
                        item.wishListData = !item.wishListData
                    }
                });
                // this.state.productListData.forEach(item => {
                //     if (item._id == id) {
                //         item.wishListData = !item.wishListData
                //     }
                // });

                // productListData




                // self.props.dispatch(getWishList());
                // self.props.dispatch(getBrandHome());
                // if (category == "brand") {
                //     this.getList("FEATURED_BRAND_LIST", "brandListData");
                // }
                // else {
                //     this.getList("FEATURED_PRODUCT_LIST", "productListData");
                // }
                // this.getList(true);
                setTimeout(() => {
                    this.setState({ [`isWishlistLoader${id}`]: false });

                }, 600);


            }).catch((err) => {
                this.setState({ [`isWishlistLoader${id}`]: false });


            })
    }
    onClickBrand = item => {
        // console.log("dsakjhgdhbjk", {item})
        this.props.history.push(`/business/${item._id}/${item?.activeCompany}`);
        // this.props.history.push(`/brand/${id}`);
    }


    render() {
        let { redirectionUrl, isLoader, adsImage, brandListData, productListData, industryData, mostVisitedBrand, mostVisitedProduct, industryDataLoaded, brandListDataLoaded, productListDataLoaded } = this.state;
        // let { redirectionUrl, isLoader, adsImage, brandListData, productListData, industryData, mostVisitedBrand, mostVisitedProduct, industryDataLoaded, brandListDataLoaded, productListDataLoaded } = this.props.brandHome ? this.props.brandHome : []

        // console.log("sdfgshaj", this.props.brandHome)

        const isShow = localStorage.getItem("showAll") == "true"


        if (isLoader)
            return (
                <section className="brand-home-banner-bg webhome">
                    {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
                    <div className="centerLoader" >
                        {
                            <Spin size="large" tip="Loading..." indicator={antIcon} />

                        }
                    </div>
                </section>


            )

        else if (isHideBrand)
            return <Thankyou />
        else
            return (

                <section className="brand-home-banner-bg">
                    {/* {(!industryDataLoaded || !brandListDataLoaded || !productListDataLoaded) && <Loader />} */}

                    {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
                    {/*    <ChatBox history={this.props.history} match={this.props.match} />*/}
                    {/*<div className="container-fluid cm-section">*/}
                    <div className={this.state.isCommingSoon ? "container-fluid brandhome pos-relative" : "container-fluid brandhome"}>
                        {this.state.isCommingSoon && <CommingSoonLayer />}
                        <div className="cm-wrapper featured-brands viewbrand">
                            <div className="header-text">

                                <h1 className="title">{tab3body.h1}</h1>
                                {brandListData && brandListData?.length > 8 && <BtnRed to="business-list" text="View All" />}
                            </div>


                            <div className=" row mb-3">
                                {
                                    brandListData?.filter((item, index) => index < 8).map((item, index) => (
                                        <div className="col-xl-3 col-lg-4 col-sm-6 cursor-point" onClick={(e) => this.onClickBrand(item, e)}>
                                            <LazyLoad>
                                                <div className="card brand-card mb-5">

                                                    <img className="img-fluid mb-4" src={item?.coverImage || require('../../../assets/images/profile-placeholder.png')} />

                                                    <div className="top-right">
                                                        {
                                                            < div className={(item?.wishListData) && "blue-circle red-circle" || "blue-circle"} onClick={(e) => this.addToWishList(e, 'brand', item?._id, item?.wishListData && "REMOVE_WISHLIST" || "ADD_WISHLIST")}>
                                                                {this.state[`isWishlistLoader${item?._id}`] &&
                                                                    <Spinner className="text-center" size="xl" animation="border" variant="primary" /> || <i className="fa fa-heart-o" ></i>}

                                                            </div>}

                                                    </div>
                                                    <div className=" brand-card-body cursor-point" >
                                                        <div className="brand-img-wrapper">

                                                            <img className="img-fluid" src={item?.logoImage || require('../../../assets/images/placeholder.png')} />

                                                        </div>
                                                        <h4>{item?.companyName}</h4>
                                                        <p>{item?.about}</p>
                                                    </div>
                                                </div>
                                            </LazyLoad>
                                        </div>


                                    ))
                                }
                                {!this.state.isLoading && brandListData?.length == 0 &&
                                    <div className="w-100 text-center row nodata m-auto">
                                        <div className="m-auto">
                                            <NoMediaICon />

                                            <h2>No Data Found</h2>
                                        </div>
                                    </div>
                                }

                            </div>

                            {mostVisitedBrand && <div className="header-text">

                                <h1 className="title">{tab3body.h2}</h1>
                                {mostVisitedBrand?.length > 5 && <BtnRed to="business-list" text="View All" />}
                            </div>
                            }

                            {mostVisitedBrand && < VisitedBrands {...this.props} isFromBrand={true} brandListData={mostVisitedBrand} scope={this} />}{/*slider*/}
                        </div>


                        {/* <div className="cm-wrapper featured-brands industries-serve">
                            <div cisNewProductlassName="header-text">
                                <h1 className="title">Industries We Serve</h1>
                                {industryData?.length > 10 && <BtnRed to="view-industry" text="View All" />}
                            </div> */}
                        {/* 
                            <div className="row d-flex flex-wrap five align-item-center mb-3">
                                {
                                    industryData?.map((item, index) => {
                                        return (
                                            <div key={index} className="card community-card">

                                                <Link to={`/product/${item?._id}`}>
                                                    <div className="mb-5 mt-0  pos-relative">
                                                        <LazyLoad>
                                                            <img className="img-fluid "
                                                                src={item?.imageUrl || require('../../../assets/images/placeholder.png')}
                                                            />
                                                        </LazyLoad>

                                                        <div className="bottom-text">

                                                            <div className="postion-rel d-flex align-items-center traingletext">
                                                                <span className="title d-flex justify-content-between align-items-center w-100">{item?.categoryTitle} <i class="fa fa-long-arrow-right float-right" aria-hidden="true"></i></span>
                                                                <span className="traingle"><DeliverTraingle /></span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    }
                                    )

                                }

                                {!this.state.isLoading && industryData?.length == 0 &&
                                    <div className="w-100 text-center row nodata m-auto">
                                        <div className="m-auto">
                                            <NoMediaICon />
                                            
                                            <h2>No Data Found</h2>
                                        </div>
                                    </div>
                                }



                            </div> */}
                        {/* </div> */}

                        <div className="cm-wrapper featured-brands showcase">
                            <div className="header-text">
                                <h1 className="title">{tab3body.h3}</h1>
                                {productListData?.length > 12 && <BtnRed to="job" text="View All" />}
                            </div>
                            <div className="row mb-3">
                                {
                                    productListData?.filter((item, index) => index < 12).map((item, index) =>
                                        // col-xl-2 col-lg-3 col-md-4 col-sm-6
                                        <div key={index} className=" product-showcase-devide">
                                            <Link to={`/web-job-details/${item._id}`} >
                                                <div className="card brand-card mb-5 new-product">
                                                    <LazyLoad>
                                                        <img className="img-fluid" src={item?.image || require('../../../assets/images/placeholder.png')} />
                                                    </LazyLoad>
                                                    {/* {item?.brandId?.companyName && <div className="top-left">
                                                        <button className="btn btn-card">{item?.brandId?.companyName}</button>
                                                    </div>
                                                    } */}
                                                    {item?.isNewProduct == 1 && <div className="newBadge">
                                                        <p> New<span className="traingle"><NewBadges /></span></p>
                                                    </div>}
                                                    <div className="top-right">
                                                        {
                                                            < div className={(item?.wishListData) && "blue-circle red-circle" || "blue-circle"} onClick={(e) => this.addToWishList(e, 'product', item?._id, item?.wishListData && "REMOVE_WISHLIST" || "ADD_WISHLIST")}>
                                                                {this.state[`isWishlistLoader${item?._id}`] &&
                                                                    <Spinner className="text-center" size="xl" animation="border" variant="primary" /> || <i className="fa fa-heart-o" ></i>}

                                                            </div>}

                                                    </div>
                                                    <hr />
                                                    <div className="action_group text-center">
                                                        <div className="w-100">
                                                            <Tooltip placement="top" title={item?.productName}>
                                                                <span className="wrap-p1">{item?.productName} <br />
                                                                </span>
                                                            </Tooltip>
                                                            <Tooltip placement="top" title={item?.brandId?.companyName}>
                                                                <hr className="w-100 mb-2" />
                                                                {item?.brandId?.companyName &&
                                                                    <h6 className="wrap-p1 w-100"><i><Brands /></i>{item?.brandId?.companyName}</h6>
                                                                }
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>

                                    )
                                }
                                {!this.state.isLoading && productListData?.length == 0 &&
                                    <div className="w-100 text-center row nodata m-auto">
                                        <div className="m-auto">
                                            <NoMediaICon />

                                            <h2>No Data Found</h2>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        {mostVisitedProduct && <div className="cm-wrapper">
                            <div className="header-text">
                                <h1 className="title">{tab3body.h4}</h1>
                                {mostVisitedProduct?.filter(item => item?.productData?.productName)?.length > 6 && <BtnRed to="job" text="View All" />}

                            </div>
                            <VisitedProducts productListData={mostVisitedProduct} scope={this} /> {/*slider*/}
                            {!this.state.isLoading && mostVisitedProduct?.length == 0 &&
                                <div className="w-100 text-center row nodata m-auto">
                                    <div className="m-auto">
                                        <NoMediaICon />

                                        <h2>No Data Found</h2>
                                    </div>
                                </div>
                            }

                        </div>
                        }
                        {adsImage && <center>
                            {<div className="ads d-block">
                                <a href={redirectionUrl} target="_blank">
                                    <LazyLoad> <img className="img-fluid" src={adsImage} /> </LazyLoad>
                                </a>
                            </div>}
                        </center>}

                    </div>

                    <Footer />
                </section >

            )
    }
}
function mapStateToProps(state) {
    const { brandHome } = state?.loading
    // console.log("dlkasjhgdhj", brandHome)
    return {
        wishList: state.wishList,
        brandHome: brandHome
    };
}


// const mapDispatchToProps = dispatch => {
//     return {
//         action: bindActionCreators({ getBrandHome }, dispatch)
//     }
// }


export default connect(mapStateToProps)(WebBrand);
