import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import PropTypes from "prop-types";
import { withRouter } from "react-router";

import Cookies from "js-cookie";

import { history } from "../../routing/index"







export default class Header extends Component {


    handleLogout = () => {
        //localStorage.clear()
        Cookies.remove('tokens')
        history.push("/")
        window.location.reload()
    }
    render() {
        return (


            <div className="inner_header">
                <header className="d-flex justify-content-end">
                    <div className="header_drop">
                        <Dropdown overlay={
                            <Menu>
                                <Menu.Item key="0">
                                    <Link to="/change-password">Change Password</Link>
                                </Menu.Item>
                                {/* <Menu.Item key="1">
                                        <Link to="/privacy-policy">Privacy and Ploicy</Link>
                                    </Menu.Item> */}
                                <Menu.Item key="2">
                                    <Link to="/terms-and-conditions">Terms and Conditions</Link>
                                </Menu.Item>
                                {/* <Menu.Item key="3">
                                        <Link to="/trainingobjective">Training Objective</Link>
                                    </Menu.Item> */}
                                <Menu.Item key="4">
                                    <Link to="/profile">Profile</Link>
                                </Menu.Item>
                                <Menu.Divider />
                                <Menu.Item key="5">
                                    <div onClick={() => { this.handleLogout() }}>Logout</div>
                                </Menu.Item>
                            </Menu>
                        } trigger={['click']}>
                            <div className="profile">
                                <img src={this.props.userDetail.image || require('../../assets/images/login_user.png')} className="img-fluid" />
                                <span className="text"><p>{this.props.userDetail.firstName ? this.props.userDetail.firstName : ""}</p><p className="mb-0">{this.props.userDetail.email}</p></span>

                                <span className="caret-icon"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            </div>
                        </Dropdown>
                    </div>
                </header>
            </div>


        )
    }

}

