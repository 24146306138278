
import React, { Component } from 'react';
import { Hert, UserManagementIcon, Pencil, Delete } from '../../../SvgIcon';
import { Link } from "react-router-dom";
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import Util from '../../../utils/Util';
import { Pagination } from '../../../components/common/Pagination';
import { notify } from '../../../components/Toaster';
import { Modal, Button, Empty, Select } from 'antd';
import Loader from '../../../components/Loader';
import util from '../../../utils/Util.js';
import moment from 'moment-timezone';
import {getTimeFormate} from "../../../utils/DateZone"


const { Option } = Select;




const columnDetails = [{ fieldName: "S.No." },
{ fieldName: "Company Name", isSortable: true, dataIndex: "companyName" },
{ fieldName: "User Name" },
{ fieldName: "Phone" },
{ fieldName: "Email" },
{ fieldName: "Country" },
{ fieldName: "Plan type" },
{ fieldName: "Gdpr" },
{ fieldName: "Date ", isSortable: true, dataIndex: "created" },
{ fieldName: "Status" },
{ fieldName: "Action" }
];


class UserManagement extends Component {
    constructor(props) {
        super(props)
        this.state = {
            viewDetails: false,
            limit: 50,
            loading: false,
            selectedId: '',
            userListData: [],
            isLoader: false,
            search: '',
            totalCount: [],
            fieldName: '',
            order: '',
            promoCodeDetailData: [],
            visible: false,
            isDownload: 0,
            sortInfo: {},
            snNo: 0,
            page: 1,
            userType: "",


        };
    }
    componentDidMount() {
        this.getUserList();
    }

    handleOk = e => {
        this.setState({
            visible: false,
        });
    };

    getUserList = () => {
        this.setState({ isLoader: true });
        let { page, limit, search, isDownload, sortInfo, userType } = this.state;
        let { fieldName, order } = sortInfo;
        let req = {
            page: page ? page : 1,
            limit: limit,
            search: search.toString(),
            userType: userType
        }
        _Api(Url.GET_USER.method, Url.GET_USER.url, '', `?page=${req.page}&limit=${req.limit}&search=${req.search}&sortField=${fieldName && fieldName || ""}&sortType=${order && order || ""}&userType=${req.userType}&download=${isDownload}`)
            .then(resp => {
                if (resp.responseData.file) {
                    window.open(resp.responseData.file, "_blank");
                    this.setState({ isLoader: false, isDownload: 0 });
                }
                else {
                    let responseData = resp.responseData.result;
                    this.setState({ userListData: responseData.data, totalCount: responseData.totalCount, isLoader: false });
                }

            }).catch(err => {
                this.handleError(err);
            });
    }

    onClickSorting = (field) => {
        let { order, fieldName } = this.state.sortInfo;
        let orderType = 1;
        if (order && fieldName === field) {
            orderType = order < 0 ? '' : -1;
        }
        this.setState({
            sortInfo: {
                fieldName: field,
                order: orderType
            }
        }, () => this.getUserList());

    }

    handlePageChange = (page) => {
        this.setState({ loader: true, page, snNo: page - 1, userListData: [] }, () => {
            this.getUserList()
        })

    }
    handleSearch = e => this.setState({ search: e.target.value, page: 1, snNo: 0, userListData: [] }, () => this.getUserList());

    showModal = (id) => {
        this.setState({
            visible: true,
            selectedId: id
        });

    };
    //Cancel modal
    handleCancel = () => {
        this.setState({ visible: false });
    };

    handleError(err) {
        this.setState({ isLoader: false });
        let errorMessage = err.error && err.error.responseMessage || err.message;
        notify("err", errorMessage);
    }

    onStatusChange = (e, id) => {
        _Api(Url.USER_STATUS_CHANGE.method, Url.USER_STATUS_CHANGE.url, '', `?requestUserId=${id}&status=${(e.target.checked ? 1 : 0).toString()}`)
            .then(resp => {
                notify('success', resp.responseData.message);
                this.setState({ visible: false, statusCode: resp.responseData.statusCode });
                this.getUserList();
            }).catch(err => {
                this.handleError(err);
            })

    }
    deleteUser = (id) => {
        let req = {
            isDeleted: 0,
            requestUserId: id,

        }
        _Api(Url.DELETE_USER.method, Url.DELETE_USER.url, '', `?requestUserId=${req.requestUserId}&isDeleted=${req.isDeleted}`)
            .then(resp => {
                console.log('delete', "User deleted successfully");
                notify('success', resp.responseData.message)
                this.setState({ visible: false })
                this.getUserList();
            }).catch(err => {
                this.handleError(err);
            })


    }
    exportCSV = () => {
        this.setState({ isDownload: 1 }, () => this.getUserList());

    }
    selectOnChange = (type, e) => {
        let self = this;
        this.setState({
            [type]: e,
        }, function () {
            self.getUserList();
        })
    }

    render() {
        let { userListData, search, visible, loading, isLoader, sortInfo,limit } = this.state;
        let { fieldName, order } = sortInfo;

        return (
            <>
                <div className="dash_body_container">
                    <Header />
                    <Sidebar />
                    <div className="body_container">
                        {isLoader && <Loader />}
                        <div className="header-container">

                            <div className="d-flex flex-grow-1 align-items-between">
                                <p className="header-icon"><UserManagementIcon /></p>
                                <h4>User Management</h4>
                            </div>

                            <div className="rounded-textbox-form filter-select pr-2">

                                <Select placeholder="Select" name="userType" onChange={(e) => this.selectOnChange("userType", e)}>
                                    <Option value="">All</Option>
                                    <Option value="0">Candidate Ticket</Option>                                   
                                    <Option value="2">Showcase</Option>                                    
                                </Select>
                            </div>
                            <div className="search_wrapper mr-3">
                                <i className="fa fa-search" />
                                <input type="search"
                                    placeholder="Search..."
                                    name='search'
                                    value={search}
                                    onChange={this.handleSearch}
                                />
                            </div>
                            {userListData.length > 0 && <Link onClick={this.exportCSV} className="btn btn-red">Export CSV</Link>}


                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered rounded-table">
                                <thead>
                                    <tr>
                                        {columnDetails.map((item) => {
                                            let { isSortable, dataIndex } = item;

                                            return <th><div className="d-flex justify-content-around align-items-center"
                                                onClick={() => this.onClickSorting(dataIndex)}>{item.fieldName}
                                                {isSortable && <span className="d-flex"><i className={util.getSortingIcon(dataIndex == fieldName && order)}
                                                    aria-hidden="true"
                                                /></span>}</div></th>

                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                
                                    {
                                        userListData.map((item, index) => (
                                            <tr>
                                                <td><span className="circle-serial">{(this.state.snNo * limit) + index + 1}</span></td>
                                                <td><Link to={`/user-profile/${item._id}`}>{item.companyName ? item.companyName : '---'}</Link> </td>
                                                <td>{item.firstName} {item.lastName}</td>
                                                <td>{item.phoneNumber && `+${item.phoneNumber}`}</td>
                                                <td>{item.email}</td>
                                                <td>{item.country}</td>
                                                <td>{item?.userPlan == 2 ? 'Operator showcase' : item?.userPlan == 3 ? 'Agency Showcase' : item?.isPlanBuy == 1 ? 'Candidate Ticket' : 'NA'}
                                                    </td>
                                                <td>{item.gdpr ? 'Yes' : 'No'}</td>
                                                <td>{getTimeFormate(item.created)}</td>
                                                <td>
                                                    <p className="toggle-parent ">
                                                        <label className="switch">
                                                            <input type="checkbox"
                                                                checked={item.status === 1 ? true : false}
                                                                onChange={(e) => this.onStatusChange(e, item._id)}

                                                            />
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </p>
                                                </td>
                                                <td className="action_group">
                                                    <Link to={`/user-profile/${item._id}`} > <p><p className="circle-serial" ><Pencil /></p></p></Link>
                                                    <p onClick={() => this.showModal(item._id)}><p className="circle-serial"><Delete /></p></p>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>

                            <div>
                                {!isLoader && userListData.length == 0 &&
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                            </div>
                            {
                                userListData != 0 ?
                                    <div >
                                        <Pagination
                                            totalPage={Math.ceil(this.state.totalCount / this.state.limit)}
                                            currentPage={this.state.page}
                                            onPressBtn={this.handlePageChange}
                                        />
                                    </div>

                                    : ''
                            }
                        </div>
                        <Modal
                            visible={visible}
                            title="Delete"
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                            footer={[
                                <Button key="back" onClick={this.handleCancel}>
                                    Cancel
                             </Button>,
                                <Button key="submit" type="primary" loading={loading} onClick={() => this.deleteUser(this.state.selectedId)}>
                                    Delete
                            </Button>,
                            ]}
                        >
                            <p>Are you sure you want to delete this ?</p>
                        </Modal>
                    </div>
                </div>

            </>


        );
    }
}
export default UserManagement;
