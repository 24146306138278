import React, { Component } from 'react';
import { DatePicker, TimePicker, InputNumber, Select } from 'antd';
import moment from 'moment-timezone';
import { BellIcn, Hert, CaretDown, ListIcn, UsersIcn, VideoCall } from '../../../components/common/SvgIcon';
import { Calendar, Clock } from '../../../SvgIcon';
import { NotificationIcon } from '../../../SvgIcon';
import { connect } from "react-redux";
// import TimezonePicker from 'react-timezone-picker';
// import TimezoneSelect from 'react-timezone-select'
import InlineError from "../../../components/InlineError"

import TimeZone from "../../../utils/timeZone"
import { TimezoneSelect, clientTz } from 'timezone-select-js';

import { notify } from "../../../components/Toaster"

import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
const format = 'HH:mm';
const { Option } = Select;


class DiaryMeetingModal extends Component {
    constructor() {
        super()
        this.state = {
            meetingModal: false,
            format: 'HH:mm',
            senderId: '',
            meetingType: 0,
            name: '',
            nameError: '',
            diaryType: 0,
            startDate: '',
            startDateError: '',
            startTime: '',
            endTime: '',
            timezone: clientTz(),
            description: '',
            meetingNotification: [
                {notificationType: 0, time: 30, timeType: 0}

            ],
            location: '',
            time: [],
            notiValue: 30,
            notiHr: 1,
            notiType: 1,
            isLoading: false,
            timeError: ''

        }
    }

    setTimeZonesss = ()=>{
        this.setState({timezone: this.props?.loading?.timezone})

    }

    componentDidMount() {
        console.log("thisistest", moment(this.props?.loading?.start_Date * 1000).format())
        if (this.props.setClick)
            this.props.setClick(this.reSet);

        this.setState({
            diaryType: this.props?.loading?.eventType,       


        },()=>{
            // console.log("yesssssss", this.props?.loading?.timezone);
            // if(this.props?.loading?.eventType == 1)
            // this.setTimeZonesss()
            
        })


        if (this.props.isEditTrue) {


        }
        else {
            
            if (moment().isSameOrAfter(this.props?.loading?.start_Date * 1000)) {
                this.setState({ userData: this.props.state.userData })
                this.setState({
                    startDate: moment(),

                }, () => {
                    this.setState({
                        startTime: moment(this.state.startDate),
                        endTime: moment().add(20, 'minutes'),
                        time: [this.state.startDate, moment().add(20, 'minutes')]
                    })
                })

            }
            else {
                this.setState({ userData: this.props.state.userData })
                this.setState({
                    startDate: moment(this.props?.loading?.start_Date * 1000),

                }, () => {
                    this.setState({
                        startTime: moment(this.state.startDate),
                        endTime: moment(this.props?.loading?.start_Date * 1000).add(20, 'minutes'),
                        time: [this.state.startDate, moment(this.props?.loading?.start_Date * 1000).add(20, 'minutes')]
                    })
                })
            }
            let title = `Meeting with ${this.props.profile.profileInfo?.firstName} ${this.props.profile.profileInfo?.lastName} and ${this.props.state.userData?.firstName} ${this.props.state.userData?.lastName}`
            this.setState({name: title})

        }
        function calcHeight(value) {
            let numberOfLineBreaks = (value.match(/\n/g) || []).length;
            let newHeight = 20 + numberOfLineBreaks * 20 + 12 + 2;
            return newHeight;
        }
        let textarea = document.querySelector(".resize-ta");
        textarea.addEventListener("keyup", () => {
            textarea.style.height = calcHeight(textarea.value) + "px";
        })
    }



    // meetingType:{type:Number},//0=online meeting1=meeting in person
    // diaryType:{type:Number},//0=online1=hybrid
    // eventType:{type:Number,default:0},//0=reminder1=meeting
    // meetingNotification:[{
    // notificationType:{type:Number},//0=email1=notification
    // time:{type:Date},
    // timeType:{type:Number},//0=min1=hour


    clearFields = () => {
        this.setState({
            nameError: '',
            startDateError: '',
            timeError: ''
        })
    }

    clearValues = () => {
        if (!this.props.isEditTrue) {
            this.setState({ startDate: moment(), 
                endTime:  moment().add(20, 'minutes')
                ,startTime: moment(),  time: [moment(), moment()] })
        }
        this.setState({
            meetingModal: false,
            format: 'HH:mm',
            senderId: '',
            meetingType: 0,
            
            nameError: '',
            diaryType: this.props?.loading?.eventType,

            startDateError: '',
          
           
            timezone: moment.tz.guess(),
            description: '',
            meetingNotification: [{notificationType: 0, time: 30, timeType: 0}],
            location: '',
            notiValue: 30,
            notiHr: 0,
            notiType: 0,
            isLoading: false,
            timeError: ''
        })
        let title = `Meeting with ${this.props.profile.profileInfo?.firstName} ${this.props.profile.profileInfo?.lastName} and ${this.props.userData?.firstName} ${this.props.userData?.lastName}`
            this.setState({name: title})

    }



    handleSubmit = () => {
   
        let method = Url.ADD_MEETING.method
        let url = Url.ADD_MEETING.url
        let req = {
            senderId: this.state.userData?._id,
            meetingType: this.state.meetingType,
            name: this.state.name,
            diaryType: this.state.diaryType,
            startDate:  moment(this.state.startDate).tz(this.state.timezone, true).format(),
            startTime: moment(this.state.startTime).tz(this.state.timezone, true).format(), 
            endTime: moment(this.state.endTime).tz(this.state.timezone, true).format() ,
            timezone: this.state.timezone,
            description: this.state.description,
            meetingNotification: this.state.meetingNotification,
            location: this.state.location.trim(),
            eventType: 1


        }
       let diff =  moment(this.state.endTime).diff(this.state.startTime, 'minutes')

        console.log("timeeeeDD", diff)


        if (this.props.isEditTrue) {
            method = Url.Edit_MEETING.method
            url = Url.Edit_MEETING.url
            req.meetingId = this.props?.diaryData?._id

        }
        if (this.state.name.trim() == "") {
            this.setState({ nameError: '*This field cannot be empty' })


        }
       
        else if (this.state.startDate == "") {
            this.setState({ startDateError: '*This field cannot be empty' })
        }
        else if (moment(this.state.startTime).format('hh:mm') == moment(this.state.endTime).format('hh:mm')) {
            this.setState({ timeError: 'Start and end time can not be same' })
        }
        else if(diff < 1){
            this.setState({ timeError: 'Please select a valid time' })

        }
        else {


            this.setState({ isLoading: true })
            _Api(method, url, req)
                .then(resp => {
                    console.log("respresprespresp", resp);
                    this.setState({ visible: false, isLoading: false })
                    this.clearValues()
                    notify("success", resp?.responseData?.message);


                    this.props.onMeetingClose()

                    if (this.props.getConferenceDetail)
                        this.props.getConferenceDetail()


                }).catch(err => {
                    this.setState({ isLoading: false });
                    let errorMessage = err.error && err.error.responseMessage || err.message;
                    notify("err", errorMessage);

                })
        }
    }


    handleOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
        this.clearFields()
    }
    handleMettingType = (type) => {
        if(type == 1){
            if(this.props?.loading?.eventType == 1){
                console.log("yesssssss", this.props?.loading?.timezone);
                this.setTimeZonesss()       
          
            }          

        }
       
        this.setState({ meetingType: type })

     

       
    }


    handleTimeZone = (timezone) => {
        this.setState({ timezone })
    }


    handleDate = (date, type) => {
        this.setState({ [type]: date, time: [date, date], startTime: date, endTime: date })
        this.clearFields()
    }

    handleTime1 = (time) => {
        // moment('12:08:23', 'HH:mm:ss')
        console.log("timeee", time)
        if (time)
            this.setState({ startTime: time,  })
        this.clearFields()

    }

    handleTime2 = (time) => {
        // moment('12:08:23', 'HH:mm:ss')
        console.log("timeee", time)
        if (time)
            this.setState({ endTime: time })
        this.clearFields()

    }

    handleChangeSelect = (value) => {
        console.log(`selected11 ${value}`);
        this.setState({ notiHr: value })
    }

    handleOnChangeNumber = (value) => {
        console.log(`value ${value}`);
        this.setState({ notiValue: value })
        // this.clearErrorMessage()
    }

    addNotification = () => {
        this.setState({
            meetingNotification: [... this.state.meetingNotification, {
                notificationType: this.state.notiType,
                time: this.state.notiValue,
                timeType: this.state.notiHr
            }]
        })

    }


    handleDelete = (id) => {
        this.setState({
            meetingNotification: this.state.meetingNotification.filter((item, index) => index != id)
        })

    }

    handleChangeTimeZone = (value) => {
        console.log("kjfhdjsklf", value.value)
        this.setState({ timezone: value.value })

    }

    handleChangeNotificationTye = (value) => {
        // console.log("sagvbhdnm", handleChangeNotificationTye)
        this.setState({ notiType: value })
    }

    reSet = () => {
        console.log("okaytest", this.props.diaryData)
        this.setState({ ...this.props.diaryData, userData: this.props.diaryData.senderData, startDate: moment(this.props.diaryData?.startDate), time: [moment.tz(this.props.diaryData?.startTime, this.props.diaryData?.timezone), moment.tz(this.props.diaryData?.endTime, this.props.diaryData?.timezone)] })
    }


    render() {

        const {
            senderId,
            meetingType,
            name,
            nameError,
            diaryType,
            startDate,
            startTime,
            endTime,
            timezone,
            description,
            meetingNotification,
            location,
            selectedTimezone,
            setSelectedTimezone,
            notiValue,
            notiHr,
            notiType,
            startDateError,
            timeError
        } = this.state
        // console.log("thispropsed", moment.unix(this.props?.loading?.end_Date))




        // start_Date: action?.payload?.start_Date,
        // end_Date: action?.payload?.end_Date



        // start_Date
        const disabledDateEnd = (current) => {

            return current && current < moment()
        }

        return (
            <>
                <div className="sign-modal-content diary-meeting-modal-wrap">
                    <div className="row align-items-center">
                        <div className="col-md-12" >
                            <h3 className="auth-heading pos-relative text-center mt-md-0 mt-2 mb-3 mb-md-4">
                                Schedule Meeting
                                <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                            </h3>

                            <div className="diary-meeting-form">
                                <form>
                                    {/* <div className="form-group text-center metToogle">
                                        <p className="toggle-parent ">
                                            <span>Online</span>
                                            <label className="switch">
                                                <input type="checkbox"
                                                    checked={this.state.diaryType == 0 ? false : true}
                                                    onChange={()=> this.setState({diaryType : this.state.diaryType == 0 ? 1 : 0 })}
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                            <span>Hybrid</span>
                                        </p>
                                    </div> */}
                                    <div className="form-group meetName">
                                        <label>Meeting Title</label>
                                        <textarea className="form-control resize-ta"
                                            placeholder="Meeting with person 1 and person 2"
                                            maxLength={100}
                                            minLength={3}
                                            name="name"
                                            value={name}
                                            rows="1"
                                            onChange={this.handleOnChange} />
                                        <InlineError
                                            message={nameError}
                                        />
                                    </div>
                                    {this.state.diaryType == 1 ? <div className="form-group">
                                        <label>Meeting Type</label>
                                        <div className="meeting-type">
                                            <button type="button" onClick={() => this.handleMettingType(0)} className={`btn ${this.state.meetingType == 0 ? 'active' : ''} mr-2`}><VideoCall /> Online Meeting</button>
                                            <button type="button" onClick={() => this.handleMettingType(1)} className={`btn ${this.state.meetingType == 1 ? 'active' : ''}`}><UsersIcn /> Meeting in person</button>
                                        </div>
                                    </div> : ''}
                                    <div className="form-group">
                                        <label>When</label>
                                        <div className="dirymeeting-time">
                                            <div className="row">
                                                <div className="col-md-3 col-5 pl-md-15-mtng pr-md-0 mb-3 mb-md-0">
                                                    <div className="datepicker-diary">
                                                        <div className="icn-wr">
                                                            <Calendar />
                                                            <DatePicker
                                                                disabledDate={disabledDateEnd}
                                                                format="DD/MM/YYYY"
                                                                value={this.state.startDate}
                                                                onChange={(e) => this.handleDate(e, "startDate")}
                                                            />
                                                        </div>
                                                        <InlineError message={startDateError} />
                                                    </div>

                                                </div>
                                                <div className="col-md-4 pl-m-2 col-7 mb-3 mb-md-0">
                                                    <div className="time-pcker-wrap">
                                                        <div className="strt-time icn-wr">
                                                            <Clock />
                                                            <TimePicker
                                                                title="Start Time"
                                                                defaultValue={moment(this.state.startTime, "HH:mm")}
                                                                value={moment(this.state.startTime)}
                                                                format={"HH:mm"}
                                                                onChange={(e) => this.handleTime1(e)}
                                                            />
                                                        </div>
                                                        <div className="end-time icn-wr">
                                                            <Clock />
                                                            <TimePicker
                                                                title="End Time"
                                                                defaultValue={moment(this.state.endTime, "HH:mm")}
                                                                value={moment(this.state.endTime)}
                                                                format={"HH:mm"}
                                                                onChange={(e) => this.handleTime2(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <InlineError message={timeError} />
                                                        
                                                        {/* <TimePicker.RangePicker
                                                            className="atdTimePicker"
                                                            inputReadOnly
                                                            value={[moment.tz(this.state.time[0], this.state.timezone), moment.tz(this.state.time[1], this.state.timezone)]}
                                                            onChange={(e) => this.handleTime(e)}
                                                            format={this.state.format} /> */}

                                                </div>
                                                <div className="col-md-5 mb-3 mb-md-0 pl-md-1">
                                                    {/* <button type="button" className="btn btn-gray w-100">{this.state.timezone}</button> */}
                                                    {/* <TimezonePicker onChange={console.log} /> */}
                                                    {/* <Select showSearch defaultValue={this.state.timezone} value={this.state.timezone} onChange={this.handleChangeTimeZone} className="form-control top-Rightmee" suffixIcon={<CaretDown />}>

                                                        {
                                                            TimeZone.map((item, index) => {
                                                                return (
                                                                    <Option value={item}>{item}</Option>
                                                                )
                                                            })
                                                        }
                                                    </Select> */}
                                                {this.state.meetingType == 1 && this.state.diaryType == 1 ?    <div className="timezone-wrapper">
                                                        <TimezoneSelect
                                                           isDisabled={true}
                                                            // labelStyle='original'
                                                            defaultValue={this.props?.loading?.timezone}
                                                            value={this.props?.loading?.timezone}
                                                            // onChange={this.handleChangeTimeZone}
                                                        />
                                                    </div>
                                                    :
                                                    <div className="timezone-wrapper">
                                                        <TimezoneSelect                                                          
                                                            labelStyle='original'
                                                            defaultValue={this.state.timezone}
                                                            value={this.state.timezone}
                                                            onChange={this.handleChangeTimeZone}
                                                        />
                                                    </div>}

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    {
                                        this.state.meetingType == 1 ?
                                            <div className="form-group meetName">
                                                <label>Location</label>
                                                <input
                                                    className="form-control"
                                                    placeholder="Enter stand no. or physical location."
                                                    maxLength={256}
                                                    name="location"
                                                    value={location}
                                                    onChange={this.handleOnChange}
                                                />
                                                {/* <InlineError
                                            message={nameError}
                                        /> */}
                                            </div> : ''
                                    }

                                    <div className="form-group mtng-atnd label-icn">
                                        <label><UsersIcn /> People Invited</label>
                                        <div className="diarypeople-invited">
                                            <div className="diarypeoplelist">
                                                <div className="dry-pl-img">
                                                    <img src={this.props.profile.profileInfo?.image ? this.props.profile.profileInfo.image : require("../../../assets/images/login_user.png")} />
                                                </div>
                                                <div className="dry-pl-desc">
                                                    <h4>{this.props.profile.profileInfo?.firstName} {this.props.profile.profileInfo?.lastName}</h4>
                                                    <span className="">(Organiser)</span>
                                                </div>
                                            </div>

                                            <div className="diarypeoplelist">
                                                <div className="dry-pl-img">
                                                    <img src={this.state.userData?.image ? this.state.userData?.image : require("../../../assets/images/login_user.png")} />
                                                </div>
                                                <div className="dry-pl-desc">
                                                    <h4>{this.state.userData?.firstName} {this.state.userData?.lastName}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group ntfy-atnd label-icn">
                                        <label><BellIcn /> Notification</label>
                                        <div className="notiftime">

                                            {
                                                this.state.meetingNotification.map((item, index) => {
                                                    return(
                                                        <div className="ntfy-rows py-2"> <div className="row">
                                                        <div className="col-md-4 pl-md-15-mtng pr-md-0 mb-3 mb-md-0">
                                                            <Select
                                                                defaultValue={item.notificationType == 1 ? 'Notification' : 'Email'}
                                                                value={item.notificationType == 1 ? 'Notification' : 'Email'}
                                                                onChange={this.handleChangeNotificationTye}
                                                                onChange={ (value) => {
                                                                    let values = this.state.meetingNotification;
                                                                    values[index].notificationType = value;
                                                                    this.setState({ meetingNotification });
                                                                  } }
                                                                className="form-control"
                                                                suffixIcon={<CaretDown />}
                                                            >
                                                                <Option value={1}>Notification</Option>
                                                                <Option value={0}>Email</Option>
                                                            </Select>
                                                        </div>
                                                        <div className="col-md-2 pr-md-0 pl-md-2 pr-m-2 col-5 mb-3 mb-md-0">
                                                            <InputNumber
                                                                bordered={true}
                                                                className="form-control"
                                                                min="1"
                                                                // min={1}
                                                                //  max={30} 
                                                                defaultValue={item.time}
                                                                onChange={this.handleOnChangeNumber}
                                                                value={item.time}
                                                                onChange={ (value) => {
                                                                    let values = this.state.meetingNotification;
                                                                    values[index].time = value;
                                                                    this.setState({ meetingNotification });
                                                                  } }
                                                            />
    
                                                        </div>
                                                        <div className="col-md-3 col-7 pl-m-2 mb-3 mb-md-0">
                                                            <Select
                                                                defaultValue={item.timeType == 1 ? 'Hour' : item.timeType == 2 ? 'Days': 'Minutes'}
                                                                value={item.timeType == 1 ? 'Hour' : item.timeType == 2 ? 'Days': 'Minutes'}
                                                                onChange={ (value) => {
                                                                    let values = this.state.meetingNotification;
                                                                    values[index].timeType = value;
                                                                    this.setState({ meetingNotification });
                                                                  } }
                                                                className="form-control"
                                                                suffixIcon={<CaretDown />}
                                                                overlayClassName="mt-stockup"
                                                            >
                                                                <Option value={0}>Minutes</Option>
                                                                <Option value={1}>Hour</Option>                                                            
                                                                <Option value={2}>Days</Option>
                                                            </Select>
                                                        </div>
                                                        <div className="col-md-1">
                                                            <div className="clse-ntfy-icn mb-md-0 text-center">
                                                                <a className="btn"><b onClick={() => this.handleDelete(index)}> <i className="fa fa-close"></i></b> </a>
                                                            </div>
                                                        </div>
                                                       { this.state.meetingNotification.length < 2  ? <div className="col-md-3 pl-md-0 mb-3 mb-md-0 d-none">
                                                            <button type="button" onClick={this.addNotification} className="btn btn-gray w-100">Add Notification</button>
                                                        </div> : ''}
                                                    </div></div> 
                                                    )
                                                    
                                                    // return (
                                                    //     <>
                                                    //         <div className="notifyMeetingList-wrp">
                                                    //             <div className="ntfy-Listmet mb-md-0">
                                                    //                 <span>{item.notificationType == 1 ? 'Notification' : 'Email'}</span>
                                                    //             </div>
                                                    //             <div className="ntfy-Listmet mb-md-0">
                                                    //                 <span>{item.time}</span>
                                                    //             </div>
                                                    //             <div className="ntfy-Listmet mb-md-0">
                                                    //                 <span>{item.timeType == 1 ? 'Hour' : item.timeType == 2 ? 'Days': 'Minutes'}</span>
                                                    //             </div>
                                                    //             <div className="ntfy-Listmet mb-md-0 text-right">
                                                    //                 <a className="btn"><b onClick={() => this.handleDelete(index)}> <i className="fa fa-close"></i></b> </a>
                                                    //             </div>
                                                    //         </div>
                                                    //     </>
                                                    // )
                                                })
                                            }
                                            <div className="meeting-body-frm-wrp">
                                                {false?
                                                <div className="ntfy-rows py-2"> <div className="row">
                                                    <div className="col-md-4 pl-md-15-mtng pr-md-0 mb-3 mb-md-0">
                                                        <Select
                                                            defaultValue={this.state.notiType}
                                                            value={this.state.notiType}
                                                            onChange={this.handleChangeNotificationTye}
                                                            className="form-control"
                                                            suffixIcon={<CaretDown />}
                                                        >
                                                            <Option value={1}>Notification</Option>
                                                            <Option value={0}>Email</Option>
                                                        </Select>
                                                    </div>
                                                    <div className="col-md-2 pr-md-0 pl-md-2 pr-m-2 col-5 mb-3 mb-md-0">
                                                        <InputNumber
                                                            bordered={true}
                                                            className="form-control"
                                                            min="1"
                                                            // min={1}
                                                            //  max={30} 
                                                            defaultValue={notiValue}
                                                            onChange={this.handleOnChangeNumber}
                                                            value={notiValue}
                                                        />

                                                    </div>
                                                    <div className="col-md-3 col-7 pl-m-2 mb-3 mb-md-0">
                                                        <Select
                                                            defaultValue={this.state.notiHr}
                                                            value={this.state.notiHr}
                                                            onChange={this.handleChangeSelect}
                                                            className="form-control"
                                                            suffixIcon={<CaretDown />}
                                                            overlayClassName="mt-stockup"
                                                        >
                                                            <Option value={0}>Minutes</Option>
                                                            <Option value={1}>Hour</Option>                                                            
                                                            <Option value={2}>Days</Option>
                                                        </Select>
                                                    </div>
                                                    {/* <div className="col-md-1">
                                                        <div className="clse-ntfy-icn mb-md-0 text-center">
                                                            <a className="btn"><b> <i className="fa fa-close"></i></b> </a>
                                                        </div>
                                                    </div> */}
                                                   { this.state.meetingNotification.length < 2  ? <div className="col-md-3 pl-md-0 mb-3 mb-md-0 d-none">
                                                        <button type="button" onClick={this.addNotification} className="btn btn-gray w-100">Add Notification</button>
                                                    </div> : ''}
                                                </div></div> : ''}
                                               {  this.state.meetingNotification.length < 2  ?  <div className="add-notity-btn">
                                                    <button type="button" onClick={this.addNotification} className="btn">Add Notification</button>
                                                </div> : ''}
                                            </div>
                                        </div>
                                    </div>




                                    <div className="form-group desc-atnd label-icn">
                                        {/* <label><ListIcn /> Add Description</label> */}
                                        <textarea
                                            placeholder="Add Description"
                                            className="form-control"
                                            name="description"
                                            rows="4"
                                            value={description}
                                            onChange={this.handleOnChange}
                                        ></textarea>
                                    </div>
                                    <div className="form-group text-center mb-0">
                                        <button type="button" disabled={this.state.isLoading} onClick={this.handleSubmit} className="btn btn-red m-0">{this.props.isEditTrue ? 'Update' : 'Send Meeting request'}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        ...state,
    };
}
export default connect(mapStateToProps)(DiaryMeetingModal);

