
import React, { Component } from 'react';
import { Modal } from 'antd';
import { CircleTickFill, Pencil, Hert, CircleCross } from '../../../SvgIcon';
import UserPlans from './UserPlans';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';


import {
    MySpinner

} from "../../../components/index"

import Loader from "../../../components/common/Loader"



import { Select } from 'antd';
const { Option } = Select;

class ManageSubscription extends Component {
    constructor() {
        super()
        this.state = {
            order: 1,
            snNo: 0,
            visible: false,
            titleModal: false,
            premiumShowcase: false,
            brandShowcase: false,
            planList: [],
            list: [],
            inputTitleError: '',
            inputTitle: '',
            selectedId: null,
            isLoader: false,
            showText: false,
            showLimit: false,
            selectType: 0
        }
    }

    handleOptionChange = (value) => {
        if (value && (value == 4)) {
            this.setState({selectType:value, showText: true, showLimit: false,inputTitle:'' })
        }
        else if (value && (value == 5)) {
            this.setState({selectType:value, showText: true, showLimit: false,inputTitle:'' })
        } else {
            this.setState({ selectType: value, showText: false, showLimit: false, inputTitle:'' })
        }
    }
    handleOptionChange2 = (value) => {       
            this.setState({inputTitle:  value})        
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };
    showTitleModal = (item) => {
        console.log("pkkoksd", item)
        this.setState({
            inputTitle: item.name,
            selectedId: item._id,
            titleModal: true,
        });
    }
    showPremModal = () => {
        this.setState({
            premiumShowcase: true,
        });
    };
    showBrandModal = (item, item2, type, value) => {
        console.log("abcdefgh", type)
        if(type == 5){
            this.setState({showText:true, showLimit:false, inputTitle:value})

        }
        if(type == 4){
            this.setState({showText:true, showLimit:false, inputTitle:value})
            // this.setState({showText:false, showLimit:true, inputTitle:value})

        }
        this.setState({
            selectedId: { id: item._id, _id: item2._id },
            selectType:type,
            brandShowcase: true,
        });
    };
    handlePremiCancel = () => {
        this.setState({ premiumShowcase: false });
    };
    handleBrandCancel = () => {
        this.setState({ brandShowcase: false, selectedId: null, selectType:0,inputTitle:'',
         showText: false,
        showLimit: false, });
    };
    handleCancel = () => {
        this.setState({ visible: false });
    };
    handleTitleCancel = () => {
        this.setState({
            inputTitle: '',
            showText: false,
            showLimit: false,
            selectedId: null,            
            titleModal: false
        });
    }


    getList = () => {
        this.setState({ isLoader: true })

        let req = {
            page: 1,
            limit: 20,
            search: ''
        }
        _Api(Url.PLAN_LIST.method, Url.PLAN_LIST.url, '', `?page=${req.page}&limit=${req.limit}&search=${req.search}`)
            .then(resp => {
                console.log('planList', resp.responseData.result)
                this.setState({ planList: resp.responseData.result.data, isLoader: false })

            }).catch(err => {
                this.setState({ isLoader: false })


            })
    }

    getList2 = () => {
        this.setState({ isLoader: true })

        let req = {
            page: 1,
            limit: 20,
            search: ''
        }
        _Api(Url.GET_LIST_ADMIN.method, Url.GET_LIST_ADMIN.url, '', `?page=${req.page}&limit=${req.limit}&search=${req.search}`)
            .then(resp => {
                console.log('planList223', resp.responseData.result)
                this.setState({ list: resp.responseData.result, isLoader: false })

            }).catch(err => {
                this.setState({ isLoader: false })


            })
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }



    componentDidMount() {
        this.getList()
        this.getList2()
    }

    //0=tick1=blank2=prohibited3=unlimited4=limit5=text
    getButtOn = (matched) => {
        console.log("inpppppppp", matched)
        switch (matched) {
            case 0:
                return <CircleTickFill />
            case 1:
                return <CircleCross/>
            case 2:
                return "prohibited"
            case 3:
                return "unlimited"
            case 4:
                return "limit"
            case 5:
                return "text"
            default:
                return <CircleCross/>;
        }
    }

    // MANAGE_SUBSCRIPTION_LIST


    handleChangeTitle = () => {
        let req = {
            name: this.state.inputTitle,
            optionId: this.state.selectedId
        }
        this.setState({ isLoader: true })
        _Api(Url.MANAGE_SUBSCRIPTION_LIST.method, Url.MANAGE_SUBSCRIPTION_LIST.url, req)
            .then(resp => {
                console.log("addPlan", resp)
                this.setState({ isLoader: false })
                this.handleTitleCancel()
                this.getList2()
            }).catch(err => {
                this.setState({ isLoader: false })
            })
    }


    handleTileChange = () => {
        console.log("okay", this.state.selectedId)
        let req = {
            value: this.state.inputTitle,
            optionId: this.state.selectedId._id,
            type: this.state.selectType,
            planId: this.state.selectedId.id
        }
        this.setState({ isLoader: true })
        _Api(Url.ADD_OPTION.method, Url.ADD_OPTION.url, req)
            .then(resp => {
                console.log("addPlan", resp)
                this.setState({ isLoader: false })
                this.handleBrandCancel()
                this.getList()
            }).catch(err => {
                this.setState({ isLoader: false })
            })
    }

    statusAd = (e, id) => {
        // let oldArr = this.state.selectedItems
        // oldArr.push(id)
        // this.setState({ selectedItems: oldArr })

        let req = {
            optionId: id.toString(),
            status: (e == 0 ? 1 : 0),
        }
        _Api(Url.STATUS_OPTION.method, Url.STATUS_OPTION.url, req)
            .then(resp => {
                // notification.success({ message: 'Success', description: resp.responseData.message });
                // this.setState({ visible: false, selectedItems: this.state.selectedItems.filter(item => item != id) })
                this.getList2(false)
            }).catch(err => {
                // this.setState({ visible: false, selectedItems: this.state.selectedItems.filter(item => item != id) })
              

            })

    }










    render() {
        const { visible, inputTitle, inputTitleError } = this.state;
        return (
            <>
            {
                this.state.isLoader && <Loader/>
            }
                <div className="table-responsive manageSubsplans-wrp">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th style={{ width: '29%' }}>&nbsp;</th>
                                {
                                    this.state.planList.map((item, index) => {
                                        return (
                                            <th className= {(index+1)% 2 == 0 ? "mngPl-brnd" : (index+1)% 3 == 0 ?  'mngPl-premi' : 'mngPl-user' } >
                                                <span>{item.planName}</span>
                                                {
                                                    item.planType == 0 ? <h5>Free</h5> : <h5>{item.defaultCurrency == 0 ?  `$${item.dollarAmount}` :  `£${item.euroAmount}`  }</h5>
                                                }

                                            </th>
                                        )
                                    })
                                }
                                <th className="mngPl-actn">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.list.map((item, index) => {
                                    return (
                                        <tr>
                                            <td className="planTitle">
                                                <span className="form-control">{item.name}</span>
                                                <button className="mngEdit" onClick={() => this.showTitleModal(item)}>
                                                    <Pencil />
                                                </button>
                                            </td>
                                            {
                                                this.state.planList.map((item2, index2) => {                                                   
                                                    return (
                                                        <td style={{cursor:'pointer'}} onClick={() => this.showBrandModal(item2, item, item2.optionPlan[index].type, item2.optionPlan[index].value)}>
                                                            <button >
                                                                {item2.optionPlan[index].type == 5 ? item2.optionPlan[index].value : item2.optionPlan[index].type == 4 ? item2.optionPlan[index].value :  this.getButtOn(item2.optionPlan[index].type)}
                                                            </button>
                                                        </td>
                                                    )
                                                })
                                            }
                                            <td>
                                                <p className="toggle-parent">
                                                    <label className="switch">
                                                        <input type="checkbox" checked={item.status == 0 ? false : true}
                                                         onChange={(e) => this.statusAd(item.status, item._id)}
                                                        />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </p>
                                            </td>
                                        </tr>

                                    )
                                })
                            }





                            <Modal
                                wrapClassName="signup_parent_modal"
                                className="cm_popup signup_modal"
                                visible={visible}
                                centered
                                onCancel={this.handleCancel}
                                footer={null}
                            >
                                <div className="sign-modal-content">
                                    <div className="row align-items-center">
                                        <div className="col-md-12" >
                                            <h3 className="auth-heading pos-relative text-center mt-5">
                                                Users
                                                    <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                            </h3>
                                            <UserPlans />
                                        </div>
                                    </div>
                                </div>

                            </Modal>

                            {/* Brand Showcase */}
                            <Modal
                                wrapClassName="signup_parent_modal"
                                className="cm_popup signup_modal"
                                visible={this.state.brandShowcase}
                                centered
                                onCancel={this.handleBrandCancel}
                                footer={null}
                            >
                                <div className="sign-modal-content">
                                    <div className="row align-items-center">
                                        <div className="col-md-12" >
                                            <h3 className="auth-heading pos-relative text-center mt-5">
                                           Manage Subscription
                                                    <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                            </h3>
                                            <form className="rounded-textbox-form mt-5">
                                                <div className="form-group">
                                                    <label>View Accessible/non-accessible </label>
                                                    <Select defaultValue="--Select Option--" value={this.state.selectType} onChange={this.handleOptionChange}>
                                                        <Option value={0}>Tick</Option>
                                                        <Option value={1}>Cross</Option>
                                                        <Option value={2}>Prohibited</Option>
                                                        <Option value={3}>Unlimited</Option>
                                                        <Option value={4}>Add Limitation</Option>
                                                        <Option value={5}>Add Text</Option>
                                                    </Select >
                                                </div>
                                                {this.state.showLimit && <div className="addLimt">
                                                    <div className="form-group">
                                                        <label>Limited to</label>
                                                        <Select value={this.state.inputTitle} onChange={this.handleOptionChange2}>
                                                        <Option value={5}>5</Option>
                                                        <Option value={10}>10</Option>
                                                        </Select>
                                                    </div>
                                                </div>}
                                                {this.state.showText && <div className="addText">
                                                    <div className="form-group">
                                                        <textarea className="form-control" placeholder="Write Something.............."
                                                        name="inputTitle"
                                                        value={inputTitle}
                                                        onChange={this.handleChange}
                                                        >

                                                        </textarea>
                                                    </div>
                                                </div>}
                                                <div className="form-group text-center">
                                                    <button type="button" onClick={() => this.handleTileChange()} className="btn btn-red w-100">Submit</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                            </Modal>


                            {/* Premium Showcase */}
                            <Modal
                                wrapClassName="signup_parent_modal"
                                className="cm_popup signup_modal"
                                visible={this.state.premiumShowcase}
                                centered
                                onCancel={this.handlePremiCancel}
                                footer={null}
                            >
                                <div className="sign-modal-content">
                                    <div className="row align-items-center">
                                        <div className="col-md-12" >
                                            <h3 className="auth-heading pos-relative text-center mt-5">
                                                Premium showcase
                                                    <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                            </h3>
                                            <UserPlans />
                                        </div>
                                    </div>
                                </div>

                            </Modal>




                            {/* Edit Title */}
                            <Modal
                                wrapClassName="signup_parent_modal"
                                className="cm_popup signup_modal"
                                visible={this.state.titleModal}
                                centered
                                onCancel={this.handleTitleCancel}
                                footer={null}
                            >
                                <div className="sign-modal-content">
                                    <div className="row align-items-center">
                                        <div className="col-md-12" >
                                            <h3 className="auth-heading pos-relative text-center mt-5">
                                                Edit Title
                                                    <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                            </h3>
                                            <form className="rounded-textbox-form mt-5">
                                                <div className="form-group">
                                                    <input
                                                        class="form-control"
                                                        name="inputTitle"
                                                        value={inputTitle}
                                                        onChange={this.handleChange}

                                                    />
                                                </div>
                                                <div className="form-group text-center">
                                                    <button disabled={this.isLoader} type="button" onClick={this.handleChangeTitle} className="btn btn-red">Save
                                                     {this.state.isLoader && <MySpinner />}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        </tbody>
                    </table>
                </div>
            </>
        )
    }
}

export default ManageSubscription