import React, { Component } from 'react';
import {CircleTick, Hert, FooterPhone, FooterLocation, FooterMail, Brands} from '../../SvgIcon';
import Footer from '../layout/Footer';
import { Link } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Loader from "../../components/Loader";
import InlineError from "../../components/InlineError";
import {Select, TreeSelect} from "antd";
class PageNotFoundInner extends Component {
    constructor(props) {
        super(props);
    }




    render() {
        return (
            <>
                <div className="dash_body_container">
                    <Header />
                    <Sidebar />
                    <div className="body_container">

                        <div className="header-text  mb-4 pt-3">

                            <div className="d-flex align-items-center">
                                {/*<p className="header-icon"><Brands /></p>*/}
                                <h4></h4>
                            </div>

                        </div>
                        <div className="py-5">
                            <div className="cm-form-wrapper rounded-textbox-form add-manag px-0">
                                <div className="row">
                                    <div className="col-lg-12 text-center">
                                        <div className="notfound">
                                            <div className="notfound-404">
                                                <h3>Oops! Page not found</h3>
                                                <h1><span>4</span><span>0</span><span>4</span></h1>
                                                <h2>we are sorry, but the page you requested was not found</h2>
                                            </div>

                                        </div>

                                    </div>
                                </div>


                            </div>


                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default PageNotFoundInner;