import React, { Component } from 'react';
import Footer from '../../../layout/Footer';
import { Hert, ExhibitionManagement, Pencil, UploadVideo, Delete } from '../../../../SvgIcon';
import { Link } from "react-router-dom";
import Sidebar from '../../../layout/Sidebar';
import Header from '../../../layout/Header';
import InlineError from '../../../../components/InlineError';
import { _Api } from '../../../../services/Api';
import Url from '../../../../services/BaseUrl';
import { notify } from '../../../../components/Toaster'
import { Tab, Tabs } from 'react-bootstrap';
// import moment from 'moment-timezone';
import Loader from '../../../../components/Loader'
import { BackBtn } from '../../../../components/common/Button';
// import { AddNewConference } from '../../../SvgIcon'
import { DatePicker, TimePicker, Select } from 'antd';

import { MediaSelector } from "../../../../components/index"

import Cookies from 'js-cookie';

import Croper from "../../../../components/Croper";

import 'react-dropzone-uploader/dist/styles.css'
import TimeZone from "../../../../utils/timeZone"
import Dropzone from 'react-dropzone-uploader'

import moment from 'moment-timezone'; // moment-timezone

const disabledDate = (current) => {
    // Can not select days before today and today
    return current && moment(current.valueOf()) < moment()
}

const { RangePicker } = DatePicker


// console.log("jksdnkjdjsf", moment.tz.names());

// const timezones = moment.tz.zone()
// console.log("fsgdhjfksdjhf=>>>",timezones)


// moment.tz.setDefault(moment.tz.guess())


class AddNewConference extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            // exhibitionId: this.props.match.params.exId,
            conferenceName: '',
            conferenceNameError: '',
            media: '',
            mediaError: '',
            exhibitionFee: '',
            exhibitionFeeError: '',
            isChecked: false,
            stageList: [],
            loungeList: [],
            loungeId: '',
            status: "",
            stageId: '',
            isLoader: false,
            videoUrl: '',
            isConfiranceAdd: false,
            profilePicPreview: '',
            conferenceUrl: '',
            startDate: moment(),
            endDate: moment(),
            startTime: moment(),
            endTime: moment(),
            description: '',
            dollarAmount: 0,
            dollarAmountError: ''
            , euroAmountError: '',
            euroAmount: 0,

            isPublic: true,
            password: '',
            timezone: moment.tz.guess(),
            isCropper: false,
            isFee: false,

            isFreeBrandShowCase: false,
            isFreeBrandShowCasePremium: false,

            mainVisibleVal: '',
            mainIsCropper: false,
            mainProfilePicPreview: '',
            mainMedia: ''

        }
    }

    handleOnChangeConference = (e) => {
        this.setState({ [e.target.name]: e.target.value, conferenceNameError: '', exhibitionFeeError: '', startDateError: '', endDateError: '', dollarAmountError: '', euroAmountError: '' })
    }


    addNewConference = () => {
        if (this.state.isConfiranceAdd)
            this.editConference()
        else
            this.submitConference()
    }
    onChangeDate = (value, dateString) => {      
        if (value)
            this.setState({ startDate: value[0] , startTime: value[0],  endDate: value[1], endTime: value[1] }, () => {
                console.log("abcdefghij", this.state.startDate, this.state.endDate)
            })
    }

    //onChange 
    handleOnChangeDate = (type, e) => {
        this.setState({ [type]: e, endDate: e, startDateError: '', endDateError: '', startTimeError: '', endTimeError: "", liveDateError: '', liveTimeError: '' })
    }

    submitConference = () => {
        let { mainMedia, timezone, isFreeBrandShowCase, isFreeBrandShowCasePremium, isChecked, isFee, password, isPublic, dollarAmount, euroAmount, conferenceName, exhibitionFee, media, description, startDate, endDate, startTime, endTime, } = this.state
        try {
            if (conferenceName == '')
                this.setState({ conferenceNameError: 'Please enter seminar name' })

            else if (this.state.isChecked && password == '')
                this.setState({ dollarAmountError: 'Please enter password' })

            else if (startDate == '')
                this.setState({ startDateError: '*Please enter start date.' })
            // else if (startTime == '')
            //     this.setState({ startTimeError: '*Please enter start time.' })
            else if (endDate == '')
                this.setState({ endDateError: '*Please enter end date.' })
            // else if (endTime == '')
            //     this.setState({ endTimeError: '*Please enter end time.' })
            else {
                this.setState({ isLoader: true })
                let formData = new FormData()
                formData.append("exhibitionId", this.props.match.params.exId)
                formData.append("conferenceName", conferenceName)
                formData.append("isPublic", isChecked == true ? 1 : 0)
                formData.append("isPaid", isFee == true ? 1 : 0)


                formData.append("dollarAmount", isFee == false ? 0 : dollarAmount)
                formData.append("isPassword", isChecked ? password : '')
                formData.append("euroAmount", isFee == false ? 0 : euroAmount)
                formData.append("description", description)
                formData.append("startDate",startDate)
                formData.append("endDate", endDate)
                formData.append("startTime",startDate)
                formData.append("endTime",  endDate)
                formData.append("coverImage", media)
                formData.append("mainStageImage", mainMedia)
                formData.append("timezone", this.state.timezone)

                formData.append("isFreeBrandShowcase", isFreeBrandShowCase ? 0 : 1)
                formData.append("isFreePremiumShowcase", isFreeBrandShowCasePremium ? 0 : 1)




                _Api(Url.ADD_CONFERENCE.method, Url.ADD_CONFERENCE.url, formData)
                    .then(resp => {

                        localStorage.setItem('conid', resp.responseData.conferenceDetails._id)
                        this.setState({ isLoader: false, isConfiranceAdd: true }, () => {
                            Cookies.set('data_cnf', JSON.stringify(this.state))
                        })
                        // http://localhost:3000/seminardetails/5f1bda298dff1d30e3949c8d/view/5f1bdafe866afb339a85b3d3
                        // this.props.history.push(`/seminardetails/${this.props.match.params.exId}/view/${resp.responseData.conferenceDetails._id}`)
                        this.props.history.goBack()
                        notify('success', resp.responseData.message)
                    }).catch(err => {
                        this.setState({ isLoader: false })
                        if (err.error)
                            notify("err", err.error.responseMessage)
                        else if (err)
                            this.setState({ isLoader: false })
                        // notify("err", err.message)
                    })
            }
        } catch{ }
    }
    handlePicUpload = (event) => {
        console.log(event, "eventeventevent", event.target.files[0])
        let reader = new FileReader();
        let file = event.target.files[0];
        let { valiDate } = this.state;
        reader.addEventListener("load", () => {
            this.setState({ profilePicPreview: reader.result }, function () {
            })
        }, false);
        if (file) {
            reader.readAsDataURL(file)
        }
        if (file) {
            console.log(event, "media1", event.target.files[0])
            if (file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png' || file.type === 'image/svg+xml') {
                console.log("media2", event.target.files[0], file.size)
                if (file.size > 2000000) {
                    console.log("lkjhgfdsdfghjklkjhgfghjkmnbvccvbnm>>>>>>>>>>>>>>>>>>")
                    valiDate = false;
                    console.log(event, "media", event.target.files[0])
                    this.setState({
                        media: event.target.files[0],
                        profilePicErrorMsg: 'Profile pic size should be less than 5Mb.'
                    });
                } else {
                    console.log("media3hhhjshs", event.target.files[0], file.size)
                    this.setState({
                        media: event.target.files[0],
                        mediaError: ''
                    });
                }
            } else {
                valiDate = false;
                this.setState({ media: 'File type not supported.' })

            }
        }
    }
    componentDidMount() {
        // moment.tz.setDefault(moment.tz.guess())
        // if (Cookies.get('data_cnf') != undefined) {
        //     this.setState({ ...JSON.parse(Cookies.get('data_cnf')) })
        // }

        if (this.props.match.params.coId) {
            this.setState({ isConfiranceAdd: true })
            this.getLoungeList()
            this.getStageList()
            this.getParticularConference()
        }




    }


    getParticularConference = () => {
        let req = {
            conferenceId: this.props.match.params.coId
        }
        _Api(Url.GET_PARTICULAR_CONFERENCE.method, Url.GET_PARTICULAR_CONFERENCE.url, '', `?conferenceId=${req.conferenceId}`)
            .then(resp => {
                console.log('bjsdbcj', resp.responseData.result)
                let params = resp.responseData.result
                const { isFreeBrandShowcase, isFreePremiumShowcase, timezone, isPaid, isPassword, isPublic, description, startDate, endDate, startTime, endTime, dollarAmount, euroAmount, } = resp.responseData.result
                this.setState({
                    timezone: timezone ? timezone :  moment.tz.guess(),
                    isChecked: isPublic == 1 ? true : false,
                    isFee: isPaid == 1 ? true : false,
                    dollarAmount: dollarAmount,
                    euroAmount: euroAmount,
                    password: isPassword ? isPassword : '',
                    description,
                    isFreeBrandShowCase: isFreeBrandShowcase == 0 ? true : false,
                    isFreeBrandShowCasePremium: isFreePremiumShowcase == 0 ? true : false,
                    conferenceName: params.conferenceName,
                    profilePicPreview: params.coverImage,
                    mainProfilePicPreview: params.mainStageImage,
                    isLoader: false,
                    startDate: moment(startDate), endDate: moment(endDate), startTime: moment(startDate), endTime: moment(endDate)


                })
                // this.props.history.push(`/seminardetails/${this.props.match.params.exid}`)
            }).catch(err => {
                this.setState({ isLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
                else if (err)
                    this.setState({ isLoader: false })
                // notify("err", err.message)
            })
    }



    editConference = () => {
        let { isFee, mainMedia, isFreeBrandShowCase, isFreeBrandShowCasePremium, timezone, isChecked, password, isPublic, dollarAmount, euroAmount, description, conferenceName, exhibitionFee, media, startDate, endDate, startTime, endTime } = this.state
        console.log("sdag", media)
        try {
            if (conferenceName == '')
                this.setState({ conferenceNameError: '*Please enter seminar name' })

            else if (this.state.isChecked && password == '')
                this.setState({ dollarAmountError: 'Please enter password' })
            else if (startDate == '')
                this.setState({ startDateError: '*Please enter start date.' })
            // else if (startTime == '')
            //     this.setState({ startTimeError: '*Please enter start time.' })
            else if (endDate == '')
                this.setState({ endDateError: '*Please enter end date.' })
            // else if (endTime == '')
            //     this.setState({ endTimeError: '*Please enter end time.' })


            else {
                this.setState({ isLoader: true })
                let formData = new FormData()
                formData.append("conferenceId", this.props.match.params.coId)
                formData.append("description", description)
                formData.append("isPublic", isChecked == true ? 1 : 0)
                formData.append("isPaid", isFee == true ? 1 : 0)
                formData.append("conferenceName", conferenceName)
                // formData.append("dollarAmount",this.state.isChecked  ? dollarAmount : 0)
                // formData.append("euroAmount", this.state.isChecked  ? euroAmount : 0)
                formData.append("dollarAmount", isFee == false ? 0 : dollarAmount)
                formData.append("isPassword", isChecked ? password : '')
                formData.append("euroAmount", isFee == false ? 0 : euroAmount)
                formData.append("startDate", startDate)
                formData.append("endDate", endDate)
                formData.append("startTime", startDate)
                formData.append("endTime", endDate)
                if (media)
                    formData.append("coverImage", media)
                if (mainMedia)
                    formData.append("mainStageImage", mainMedia)

                formData.append("timezone", this.state.timezone)


                formData.append("isFreeBrandShowcase", isFreeBrandShowCase ? 0 : 1)
                formData.append("isFreePremiumShowcase", isFreeBrandShowCasePremium ? 0 : 1)


                _Api(Url.EDIT_CONFERENCE.method, Url.EDIT_CONFERENCE.url, formData)
                    .then(resp => {

                        this.setState({ isLoader: false })

                        this.props.history.goBack()

                        notify('success', resp.responseData.message)
                    }).catch(err => {
                        this.setState({ isLoader: false })
                        if (err.error)
                            notify("err", err.error.responseMessage)
                        else if (err)
                            this.setState({ isLoader: false })
                        // notify("err", err.message)
                    })
            }
        } catch{ }
    }


    //lounge list
    getLoungeList = (props) => {
        try {
            _Api(Url.GET_LOUNGE_LIST.method, Url.GET_LOUNGE_LIST.url, "", "?conferenceId=" + this.props.match.params.coId)
                .then(resp => {
                    console.log('ytre', resp.responseData.result.data)
                    this.setState({ loungeList: resp?.responseData?.result?.data })
                }).catch(err => {
                    if (err.error)
                        notify("err", err.error.responseMessage)
                    // else if (err)
                    //     // notify("err", err.message)
                })
        } catch{ }
    }
    statusLounges = (e, id) => {
        let req = {
            loungeId: localStorage.getItem('loungeId').toString(),
            status: (e.target.checked ? 1 : 0),
        }
        _Api(Url.GET_LOUNGE_STATUS.method, Url.GET_LOUNGE_STATUS.url, '', `?loungeId=${req.loungeId}&status=${req.status}`)
            .then(resp => {
                console.log('delete', resp.responseData)
                notify('success', resp.responseData.message)
                this.setState({ visible: false })
                this.getLoungeList()
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
            })
    }

    statusStages = (status, id) => {
        let req = {
            stageId: id,
            status: status,
        }
        _Api(Url.GET_STAGE_STATUS.method, Url.GET_STAGE_STATUS.url, '', `?stageId=${req.stageId}&status=${req.status}`)
            .then(resp => {
                // console.log('delete', resp.responseData)
                // notify('success', resp.responseData.message)
                // this.setState({ visible: false })
                // this.getStageList()

                window.location.reload()
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
            })

    }

    //stae list
    getStageList = () => {
        try {
            _Api(Url.GET_STAGE_LIST.method, Url.GET_STAGE_LIST.url, "", "?conferenceId=" + this.props.match.params.coId)
                .then(resp => {
                    console.log('ytre', resp.responseData.result.data)
                    this.setState({ stageList: resp?.responseData?.result?.data })
                }).catch(err => {
                    if (err.error)
                        notify("err", err.error.responseMessage)
                    // else if (err)
                    //     // notify("err", err.message)
                })
        } catch{ }
    }

    //for check box
    handleChangedChecked = () => {
        this.setState({ isChecked: !this.state.isChecked })
    }

    handleFeeCheck = () => {
        this.setState({ isFee: !this.state.isFee })
    }


    handleIsFree = (key) => {
        this.setState({ [key]: !this.state[key] })
    }


    componentWillUnmount() {
        // Cookies.remove('data_cnf')
    }

    getFile = () => {
        this.inputElement.click();
    }

    handleImageUpload = (event) => {
        console.log(event, "eventeventevent", event.target.files[0])
        let reader = new FileReader();
        let file = event.target.files[0];
        let { valiDate } = this.state;

        if (file) {
            reader.readAsDataURL(file)
        }
        if (file) {
            console.log(file, "media1")
            if (file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png') {

                reader.addEventListener("load", () => {
                    this.setState({ profilePicPreview: reader.result }, function () {
                    })
                }, false);

                if (file.size > 2000000) {
                    console.log("lkjhgfdsdfghjklkjhgfghjkmnbvccvbnm>>>>>>>>>>>>>>>>>>")
                    valiDate = false;
                    console.log(event, "media", event.target.files[0])
                    this.setState({
                        media: event.target.files[0],
                        profilePicErrorMsg: 'Profile pic size should be less than 5Mb.'
                    });
                } else {
                    console.log("media3hhhjshs", event.target.files[0], file.size)
                    this.setState({
                        media: event.target.files[0],
                        mediaError: ''
                    });
                    // alert("wrong file")
                }
            } else {
                valiDate = false;
                this.setState({ mediaError: 'File type not supported.' })
            }
        }
    }




    handleOnChange = (type, e) => {
        console.log('qwerty', e)
        this.setState({ [type]: e, startDateError: '', stageTypeError: '', endDateError: '', startTimeError: '', endTimeError: "", })
    }

    handleChangeTimeZone = (value) => {
        // moment.tz.setDefault(value)
       console.log("dkjhasghdjk",  moment.tz(this.state.startDate, this.state.timezone).utcOffset() )
        this.setState({ timezone: value })
    }


    finalCropedImage = (categoryImgPrev, categoryImg, visibleVal) => {
        this.setState({
            visibleVal,
            isCropper: false,
            profilePicPreview: categoryImgPrev,
            media: categoryImg

        })

    }

    mainFinalCropedImage = (categoryImgPrev, categoryImg, mainVisibleVal) => {
        this.setState({
            mainVisibleVal,
            mainIsCropper: false,
            isCropper: false,
            mainProfilePicPreview: categoryImgPrev,
            mainMedia: categoryImg

        })

    }



    render() {
        // console.log("lkjashgdvhjkashg", moment("180825", 'HH:mm').format("LT"))
        let { dollarAmount, euroAmount, isLoader, videoUrl, conferenceName, conferenceNameError, exhibitionFee, media } = this.state
        let { password, description, endDate, startDate, startDateError, startTime, startTimeError, endTime, endTimeError, endDateError } = this.state

        const disabledDateEnd = (current) => {
            // Can not select days before today and today
            return current && current.valueOf() < startDate;
        }

        return (
            <>
                <div className="dash_body_container">
                    {isLoader && <Loader />}
                    <div className="body_container">
                        <div className="header-container">
                            <div className="d-flex align-items-center">
                                <p className="header-icon"><ExhibitionManagement /></p>
                                <h4>{this.state.isConfiranceAdd ? 'Edit Seminar' : 'Add New Seminar'}
                                    <br />
                                    {/* <ul className="header-breadcrum">
                                        <li><a >Exhibition Management</a>/</li>
                                        <li><a >New Conference Detail</a></li>
                                    </ul> */}
                                </h4></div>
                            {/* <BackBtn to="admin/exhibition" /> */}
                            <a > <button onClick={() => this.props.history.goBack()} className="btn btn-light"><i class="fa fa-arrow-left" aria-hidden="true"></i>Back</button></a>
                        </div>


                        <div className="rounded-body-form">
                            <form className="cm-form-wrapper add-manag">
                                <div className="row">
                                    <div className="col-lg-6">

                                        <div className="form-group">
                                            <level>Seminar Name</level>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Seminar Name"
                                                maxLength={64}
                                                name="conferenceName"
                                                value={conferenceName}
                                                onChange={this.handleOnChangeConference}
                                            />
                                            <InlineError
                                                message={conferenceNameError}
                                            />
                                        </div>







                                    </div>

                                    <div className="col-lg-6">


                                        <level>Seminar Type</level>
                                        <div className=" mb-5">

                                            <div className="form-group d-flex align-items-center">

                                                {/* <div>
                                                                <label className="cm_container">
                                                                    Public
                                                                    <input checked={this.state.isPublic} onClick={() => this.setState({ isPublic: !this.state.isPublic })} type="radio" name="radio" />
                                                                    <span className="radio-checkmark"></span>
                                                                </label>
                                                            </div> */}
                                                {/* <div>
                                                                <label className="cm_container">
                                                                    Private
                                                                    <input checked={!this.state.isPublic} onClick={() => this.setState({ isPublic: !this.state.isPublic })} type="radio" name="radio" />
                                                                    <span className="radio-checkmark"></span>
                                                                </label>
                                                            </div> */}
                                                <p className="ml-4">
                                                    <div className="check_wrapper">
                                                        <input type="checkbox"
                                                            value="option2"
                                                            checked={this.state.isChecked}
                                                            onChange={this.handleChangedChecked} /><span />
                                                    </div>
                                                    Is it password protected seminar?
                                                      </p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-lg-6">
                                        <level>Seminar Fee</level>
                                        <div className=" ">

                                            <div className="form-group d-flex align-items-center">
                                                <p>
                                                    <div className="check_wrapper">
                                                        <input type="checkbox"

                                                            value="option1"
                                                            checked={!this.state.isFee}
                                                            onChange={this.handleFeeCheck} />
                                                        <span />
                                                    </div>
                                                    Free
        </p>
                                                <p className="ml-4">
                                                    <div className="check_wrapper">
                                                        <input type="checkbox"

                                                            value="option2"
                                                            checked={this.state.isFee}
                                                            onChange={this.handleFeeCheck} /><span />
                                                    </div>
                                                    Input Amount
        </p>


                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-lg-6">
                                        {
                                            this.state.isChecked &&
                                            <div className="form-group">
                                                <level>Enter password</level>
                                                <input type="text"
                                                    className="form-control"
                                                    placeholder="Enter password"
                                                    maxLength={50}
                                                    name="password"
                                                    value={password}
                                                    onChange={this.handleOnChangeConference}
                                                />
                                                {/* <InlineError
                                                            message={this.state.dollarAmountError}
                                                        /> */}

                                            </div>
                                        }
                                    </div>

                                    <div className="col-lg-6">
                                        {
                                            this.state.isFee &&
                                            <div className="form-group">
                                                <level>Enter USD amount</level>
                                                <input type="text"
                                                    className="form-control"
                                                    placeholder="Enter USD amount"
                                                    maxLength={50}
                                                    name="dollarAmount"
                                                    value={dollarAmount}
                                                    onChange={this.handleOnChangeConference}
                                                />
                                                <InlineError
                                                    message={this.state.dollarAmountError}
                                                />

                                            </div>
                                        }
                                    </div>
                                    <div className="col-lg-6">
                                        {
                                            this.state.isFee &&
                                            <div className="form-group">
                                                <level>Enter GBP amount</level>
                                                <input type="number"
                                                    className="form-control"
                                                    placeholder="Enter GBP amount"
                                                    maxLength={15}
                                                    name="euroAmount"
                                                    value={euroAmount}
                                                    onChange={this.handleOnChangeConference}
                                                />
                                                <InlineError
                                                    message={this.state.euroAmountError}
                                                />

                                            </div>
                                        }
                                    </div>
                                </div>
                                {this.state.isFee &&
                                    <div className="col-lg-6">
                                        <level>Free For</level>
                                        <div className=" ">
                                            <div className="form-group d-flex align-items-center">
                                                <p>
                                                    <div className="check_wrapper">
                                                        <input type="checkbox"
                                                            value="option1"
                                                            checked={this.state.isFreeBrandShowCase}
                                                            onChange={() => this.handleIsFree("isFreeBrandShowCase")} />
                                                        <span />
                                                    </div>
                                                    Operator Showcase
                                                 </p>
                                                <p className="ml-4">
                                                    <div className="check_wrapper">
                                                        <input type="checkbox"

                                                            value="option2"
                                                            checked={this.state.isFreeBrandShowCasePremium}
                                                            onChange={() => this.handleIsFree("isFreeBrandShowCasePremium")} /><span />
                                                    </div>
                                                    Agency Showcase
                                                  </p>
                                            </div>
                                        </div>
                                    </div>}
                                <div className="row">
                                    <div className="col-lg-6">

                                        <div className="form-row">
                                            {/* <div className="col-md-12">
                                                <level>Conference Fee</level>
                                                <div className=" ">

                                                    <div className="form-group d-flex align-items-center">
                                                        <p>
                                                            <div className="check_wrapper">
                                                                <input type="checkbox"

                                                                    value="option1"
                                                                    checked={!this.state.isFee}
                                                                    onChange={this.handleFeeCheck} />
                                                                <span />
                                                            </div>
                                                            Free
        </p>
                                                        <p className="ml-4">
                                                            <div className="check_wrapper">
                                                                <input type="checkbox"

                                                                    value="option2"
                                                                    checked={this.state.isFee}
                                                                    onChange={this.handleFeeCheck} /><span />
                                                            </div>
                                                            Input Amount
        </p>
                                                       

                                                    </div>
                                                </div>

                                            </div> */}




                                        </div>





                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col-lg-6">
                                        {
                                            this.state.isChecked &&
                                            <div className="form-group">
                                                <level>Enter Amount</level>
                                                <input type="number"
                                                    className="form-control"
                                                    placeholder="Enter amount"
                                                    maxLength={15}
                                                    name="exhibitionFee"
                                                    value={exhibitionFee}
                                                    onChange={this.handleOnChangeConference}
                                                />
                                                <InlineError
                                                    message={this.state.exhibitionFeeError}
                                                />

                                            </div>
                                        }
                                    </div>

                                </div> */}



                                <div className="row">
                                <div className="col-md-6">
                                        <level>Time Zone</level>

                                        <div className="form-group">
                                            <Select showSearch autoComplete="new-password" placeholder="Time zone"
                                                value={this.state.timezone}
                                                defaultValue={this.state.timezone}

                                                onChange={(e) => this.handleChangeTimeZone(e)} name='country'

                                            >
                                                {TimeZone.map((item) => {
                                                    return (<Select.Option value={item}>{item}</Select.Option>)
                                                })}

                                            </Select>

                                        </div>

                                    </div>
                                    <div className="col-md-6">
                                        <level>Start, End date and time</level>



                                        <div className="form-group">
                                            <RangePicker
                                            inputReadOnly
                                                showTime={{ format: 'HH:mm' }}                                                
                                                value={[moment.tz(startDate, this.state.timezone), moment.tz(endDate, this.state.timezone)]}
                                                format="DD-MM-YYYY HH:mm"
                                                onChange={this.onChangeDate}
                                            // onOk={onOk}
                                            />
                                            <InlineError
                                                message={startDateError}
                                            />
                                        </div>




                                    </div>

                                   

                                </div>



                                <div className="row">
                                    <div className="col-md-6">
                                        {/* <span className="or-label">Or</span> */}
                                        <level>Cover image</level>
                                        <div className="form-group custom-upload-file">
                                            <ul className="list-inline mt-3 pt-1">
                                                <li className="list-inline-item">
                                                    <div className="upload-inner addMedia" onClick={() => this.setState({ isCropper: true })} >
                                                        {
                                                            this.state.profilePicPreview ?
                                                                <img onClick={() => this.setState({ isCropper: true })} src={this.state.profilePicPreview} />
                                                                : <span className="upload-icon"><UploadVideo /></span>
                                                        }

                                                        {/* <input accept="image/*" ref="fileUploader" type="file"  placeholder="Enter Video Link"
                                                            onChange={this.handleImageUpload}
                                                            className="d-block"

                                                        /> */}
                                                    </div>
                                                    {/* <level>Maximum Size : 128 gb</level> */}
                                                </li>


                                            </ul>
                                        </div>


                                        <div className="add-video">
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        {/* <span className="or-label">Or</span> */}
                                        <level>Stage holding slide</level>
                                        <div className="form-group custom-upload-file">
                                            <ul className="list-inline mt-3 pt-1">
                                                <li className="list-inline-item">
                                                    <div className="upload-inner addMedia" onClick={() => this.setState({ mainIsCropper: true })} >
                                                        {
                                                            this.state.mainProfilePicPreview ?
                                                                <img onClick={() => this.setState({ isCropper: true })} src={this.state.mainProfilePicPreview} />
                                                                : <span className="upload-icon"><UploadVideo /></span>
                                                        }

                                                        {/* <input accept="image/*" ref="fileUploader" type="file"  placeholder="Enter Video Link"
                                                            onChange={this.handleImageUpload}
                                                            className="d-block"

                                                        /> */}
                                                    </div>
                                                    {/* <level>Maximum Size : 128 gb</level> */}
                                                </li>


                                            </ul>
                                        </div>


                                        <div className="add-video">
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <br />

                                <div className="row">
                                    <level>Description</level>
                                    <textarea className="textarea form-control" rows="4"
                                        placeholder="Description..."
                                        maxLength={500}
                                        name="description"
                                        value={description}
                                        onChange={this.handleOnChangeConference}
                                    />
                                    <InlineError
                                    // message={descriptionError}
                                    />
                                </div>

                                <div className="text-right">
                                    <button className="btn btn-primary " type='button' onClick={this.addNewConference}>Save</button>
                                </div>

                            </form>


                            {this.state.isCropper ?
                                <Croper aspect={2.5 / 1}
                                    // onImageCrop={img => this.setState({ media: img })}
                                    imgPrev={'3.0-1.png'}
                                    finalCropedImage={(categoryImgPrev, categoryImg, visibleVal) => this.finalCropedImage(categoryImgPrev, categoryImg, visibleVal)} visibleVal={this.state.isCropper}
                                    onClose={() => this.setState({ isCropper: false })}
                                />
                                : ""}

                            {this.state.mainIsCropper ?
                                <Croper aspect={3 / 1}
                                    // onImageCrop={img => this.setState({ media: img })}
                                    imgPrev={'2.5-1.png'}
                                    finalCropedImage={(categoryImgPrev, categoryImg, visibleVal) => this.mainFinalCropedImage(categoryImgPrev, categoryImg, visibleVal)} visibleVal={this.state.isCropper}
                                    onClose={() => this.setState({ mainIsCropper: false })}
                                />
                                : ""}




                            {/* <div className="blue-border card-wrapper">

                                <Tabs defaultActiveKey="stages" id="uncontrolled-tab-example">
                                    <Tab eventKey="stages" title="Stages">
                                        <Stages
                                            {...this.props}
                                            stagesList={this.state.stageList}
                                            statusStages={this.statusStages}
                                            isAdded={this.state.isConfiranceAdd}
                                        />
                                    </Tab>
                                    <Tab eventKey="lounges" title="Lounges">
                                        <Lounges
                                            {...this.props}
                                            loungeList={this.state.loungeList}
                                            statusLounges={this.statusLounges}
                                            isAdded={this.state.isConfiranceAdd}
                                        />
                                    </Tab>
                                </Tabs>


                            </div> */}



                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default AddNewConference;


export const Stages = (props) => {
    const { stagesList } = props
    // console.log('propckjzxncjkzs', new AddNewConference().statusStages(1,123456))

    return (
        <>
            <div className="text-right">
                {props.isAdded && <Link to={`/add-stages/${props.match.params.coId}`} className="btn btn-red">Add Stages</Link>}
            </div>
            <div className="row mt-4 confrence-details admin-stage">
                {
                    stagesList?.map(item => (
                        <div className="col-md-4 mb-5">
                            <div className="card-img-wrapper">
                                <div className="card">
                                    <img className="img-fluid" src={item.image ? item.image : require("../../../../assets/images/placeholder.png")} alt='imgage not avaliable' />
                                </div>
                            </div>
                            <div className="cm-card-body">
                                <h4><strong>{item.stageName}</strong></h4>
                                <p className="des"> {moment(item.startDate).format('dddd')} {moment(item.startDate).format("DD/ MM/ YYYY")} <br />
                                    {moment(item.startTime).format('LT')} {moment(item.endDate).format('dddd')} {moment(item.endDate).format("DD/ MM/ YYYY")} {moment(item.endTime).format('LT')}</p>

                                <div className="d-flex">
                                    <div className="flex-grow-1">
                                        <Link to={`/stage-detail/${item._id}`}> <button className="btn btn-blue" >View Details</button></Link>
                                    </div>
                                    <div className="action_group">
                                        <a
                                            onClick={(e) => props.onStagDelete(item)}
                                        ><p className="circle-serial"><Delete /></p></a>

                                        <Link to={`/add-stages/${props.match.params.coId}/${item._id}`}><p className="circle-serial " ><Pencil /></p></Link>
                                        <p className="toggle-parent ">
                                            <label className="switch">
                                                <input type="checkbox"
                                                    checked={item.status === 1 ? true : false}
                                                    onChange={(e) => props.statusStages(item.status == 1 ? 0 : 1, item._id)}
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                        </p>
                                        <input type="checkbox"
                                            checked={item.isMainStageLive === 1 ? true : false}
                                            onChange={(e) => props.statusStagesForMain(item.status == 1 ? 0 : 1, item._id)}
                                        />

                                    </div>
                                </div>
                            </div>

                        </div>
                    ))
                }

            </div>
        </>

    )
}

export const Lounges = (props) => {
    console.log('loungeList', props)
    let { loungeList } = props
    return (
        <>
            <div className="text-right">
                {props.isAdded && <Link to={`/add-lounge/${props.match.params.coId}`} className="btn btn-red">Add Lounges</Link>}

            </div>
            <div className="row mt-4 admin-stage">
                {
                    loungeList?.map(item => (
                        <div className="col-md-4 mb-5">
                            <div className="card-img-wrapper">
                                <div className="card">
                                    <img className="img-fluid" src={item.image ? item.image : require("../../../../assets/images/placeholder-Lounges.png")} alt='imgage not avaliable' />
                                </div>
                            </div>
                            <div className="cm-card-body">
                                <h4><strong>{item.loungeName}</strong></h4>
                                <p className="des">{moment(item.startDate).format('dddd')} {moment(item.startDate).format("DD/ MM/ YYYY")} <br />
                                    {moment(item.startTime).format('LT')} {moment(item.endDate).format('dddd')} {moment(item.endDate).format("DD/ MM/ YYYY")} {moment(item.endTime).format('LT')}</p>

                                <div className="d-flex">
                                    <div className="flex-grow-1">
                                        <Link to={`/view/${item._id}`} className="btn btn-blue" >View Details</Link>
                                    </div>
                                    <div className="action_group">
                                        <a
                                            onClick={(e) => props.onDeleteLounge(item)}
                                        ><p className="circle-serial"><Delete /></p></a>
                                        <Link to={`/add-lounge/${props.match.params.coId}/${item._id}`}><p className="circle-serial " ><Pencil /></p></Link>
                                        <p className="toggle-parent ">
                                            <label className="switch">
                                                <input type="checkbox"
                                                    checked={item.status === 1 ? true : false}
                                                    onChange={(e) => props.statusLounges(item.status == 1 ? 0 : 1, item._id)}
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }

            </div>
        </>

    )
}