import { BtnRed, CmBtn, BackBtn } from "../../../components/common/Button";
import { ZoomVideo, Chat, NoMediaICon } from "../../../SvgIcon";
import React, { Component } from 'react';
import WebHeader from '../../layout/WebHeader';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';
import { Link } from "react-router-dom";
import Footer from "../../layout/Footer"
import { Pagination } from "../../../components";
import util from '../..//../utils/Util';
import { Menu, Dropdown, Button } from 'antd';
import ReportModal from '../../web/community/ReportUserModal';
import socket from "../../../services/socket";
import { groupActions } from "../../../redux/action";
import { MySpinner } from "../../../components/index";
import { MdLoader } from '../../../components/index';
import Loader from "../../../components/Loader"
import { Modal } from 'antd';
import { connect } from "react-redux";
import { history } from "../../../routing/index"

var _ = require('lodash');

class CommunityDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            exhibtionId: '',
            page: 1,
            limit: 40,
            loading: false,
            isLoader: false,
            loader: true,
            snNo: 0,
            totalCount: 0,
            DetailList: []


        }
    }

    componentDidMount() {
        this.props.dispatch({ type: 'PATH_ADD', payload: this.props.match })
        this.getConnection();

        let self = this

        socket.on('connect', function (data) {
        });

        socket.on('online', function (params) {
            console.log("onlineeeeeeeeeeeee", params);
            self.changeUserStatus(params.user._id, 1);
        })

        socket.on('offline', function (params) {
            console.log("offline", params);
            self.changeUserStatus(params.user._id, 0);
        })


    }

    changeUserStatus(userId, status) {
        let self = this;
        let users = this.state.DetailList
        var checkUser = _.find(users, function (obj) {
            return obj.userData._id === userId;
        });

        if (checkUser) {
            checkUser.userData.isOnline = status;
            let userConnection = this.state.DetailList
            var index = _.findIndex(userConnection, { _id: checkUser._id });
            //  userConnection[index].userData =  checkUser 
            self.setState({
                DetailList: userConnection
            })
            // console.log("sdafhgjaskld", {userConnection})

            // 
        }

        // console.log("sdafhgjaskld", {checkUser, users, })
    }



    getList = (api, title, status) => {
        let { page, limit } = this.state;
        this.setState({ title: title })


        let req = {
            page: page ? page : 1,

            limit: limit,
            connectionType: this.props.history.location.pathname.includes("business") ? 2 : 1,
            status: status ? status : ""

        }
        if (api == "NEW_ALL_CONNECTION_LIST") {
            req.requestUserId = this.props.match.params.id
        }

        let url = Url[api].url, method = Url[api].method;
        _Api(method, url, req)
            .then(resp => {
                if (this.state.commingFrom == "blocked") {
                    this.setState({ loader: false, DetailList: resp?.responseData?.blockList.record, totalCount: resp.responseData?.blockList?.totalCount })
                }
                else {
                    this.setState({ loader: false, DetailList: resp.responseData?.result?.record, totalCount: resp.responseData?.result?.totalCount })

                }
            }).catch((err) => {
                this.handleError(err);
                this.setState({ loader: false })
            })
    }
    getConnection() {
        let commingFrom = "block", toProfile = "/connection-profile";
        console.log("lkfkjjgklfdg", this.props.history.location.pathname.includes('view-connections'));

        if (this.props.match.params.id)
            this.getList("NEW_ALL_CONNECTION_LIST", "Connections");
        commingFrom = "connection";
        toProfile = "/connection-profile";

        switch (this.props.history.location.pathname) {
            case "/view-connections":
            case "/business-connections":
                this.getList("COMMUNITY_CONNECTION_LIST", "My Connections");
                commingFrom = "connection";
                toProfile = "/connection-profile";
                break;
            case "/view-requested-connections":
            case "/business-requested-connections":
                this.getList("COMMUNITY_REQUESTLIST", " Received Invitations");
                commingFrom = "requested";
                toProfile = "/requested-profile/";
                break;
            case "/view-pending-connections":
            case "/business-pending-connections":
                this.getList("COMMUNITY_PENDINGLIST", "Sent Invitations");
                commingFrom = "pending";
                toProfile = "/pending-profile";
                break;
            case "/view-suggested-connections":
                this.getList("COMMUNITY_SUGGESTIONLIST", "Suggested Connections");
                commingFrom = "suggested";
                toProfile = "/suggested-profile";
                break;
            case "/view-block-connections":
                this.getList("COMMUNITY_CONNECTION_LIST", "Block Connections");
                commingFrom = "blocked";
                break;


        }
        this.setState({ commingFrom: commingFrom, toProfile: toProfile });
    }

    handleError(err) {
        this.setState({ loader: false })
        let errorMessage = err.error && err.error.responseMessage || err.message;
        notify("err", errorMessage);
    }

    handleSearch = e => this.setState({ search: e.target.value, brandListData: [], page: 1, snNo: 0 }, () => this.getCategory());


    onAcceptReject(e, requestId, status) {
        e.preventDefault();
        let req = {
            requestId: requestId,
            status: status
        }
        _Api(Url.COMMUNITY_ACCEPTREJECT.method, Url.COMMUNITY_ACCEPTREJECT.url, req)
            .then(resp => {
                this.getConnection();
            }).catch((err) => {
                this.handleError(err);
            })

    }
    onMoreClick = (e, id, userId, a, event, data) => {
        switch (e.key) {
            case "Remove":
                this.onRemoveAndBlock("COMMUNITY_CONNECTION_REMOVE", 3, id, event.domEvent);
                break;
            case "Block":
                this.onRemoveAndBlock("COMMUNITY_CONNECTION_BLOCK", 1, id, event.domEvent);
                break;
            case "Report":

                this.showModal(id, data, event.domEvent);
                break;
            case "AddBusinessConnection":
                this.onCreateBussinessRequest(userId);
                break;
            default:
                return null;


        }
    }
    onRemoveAndBlock = (api, status, id, e) => {
        this.setState({ [`connection${id}`]: true });
        e.preventDefault();
        let req = {
            requestId: id,
            status: status
        }
        _Api(Url[api].method, Url[api].url, req)
            .then(resp => {
                this.setState({ [`connection${id}`]: false });
                this.getConnection();
            }).catch((err) => {
                this.handleError(err);
            })

    }
    showModal = (id, data, e) => {
        e.preventDefault();
        this.setState({
            isReport: true,
            userData: data
        });
    };

    onConnectRequest(e, requestId, status) {
        // console.log("asjdhghjkldefghjkldf", requestId)
        this.setState({ [`connection${requestId}`]: true });
        e.preventDefault();
        let req = {
            requestedUserId: requestId,
            connectionType: 1
        }
        _Api(Url.COMMUNITY_CREATEREQUEST.method, Url.COMMUNITY_CREATEREQUEST.url, req)
            .then(resp => {
                this.getConnection();
                notify(resp.responseData.message);
                this.setState({ [`connection${requestId}`]: false })
            }).catch((err) => {
                this.handleError(err);
            })

    }

    onClickConnection = (id, user_id, e, isFromRequested = false) => {
        // window
        window.location = `/user/profile/${user_id}`



    }

    // onClickConnection = (id, user_id, e, isFromRequested = false) => {
    //     let props = this.props.scope.props;
    //     if (isFromRequested)
    //         props.history.push(`/connection-profile/${user_id}/connection/${id}/requested`)

    //     else { props.history.push(`/connection-profile/${user_id}/connection/${id}`) }
    // }

    videoCalling = (userId) => {
        let self = this;
        if (userId && !this.props.isProd) {
            self.setState({
                ['videoConnectionLoder_' + userId]: true
            })
            self.props.dispatch(groupActions.joinOneMeeting({ userId: userId, type: 7 }))
                .then(function (res) {
                    self.setState({
                        ['videoConnectionLoder_' + userId]: false
                    })
                    if (res.statusCode === 1 || res.statusCode === "1") {
                        window.open('/calling/' + self.props.groupDetail.meeting._id, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                        //window.location.href = '/calling/' + self.props.groupDetail.meeting._id;
                        //history.push('/calling/'+self.props.groupDetail.meeting._id)
                    } else {

                    }
                    console.log("res", res)
                })
        }
        else {

            notify("success", 'Will be live on 8th September, 2020');

        }
    }


    // handlePageChange = (page) => {
    //     this.setState({ loader: true, page, snNo: page - 1 }, () => {
    //         this.getConnection();
    //     })

    // }


    selectUser = (user) => {
        let self = this;
        // console.log("user",user);
        if (this.props.userDetail._id !== user._id && !this.props.isProd) {
            this.setState({
                selectedUser: user,
                selectedUserId: user._id,
                chatType: 3,
                isShow: true
            }, function () {
                self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 3 })
                self.props.dispatch({ type: 'CHAT_OPEN' })
                document.body.classList.toggle('chat_open_mob');
                self.props.dispatch({ type: 'SELECTED_USER', selectedUser: user })
                socket.emit('groupAdd', { type: 7, userId: user._id });
                //socket.emit('userConnection', {userId: user._id});
            })
        }
        else {

            notify("success", 'Will be live on 8th September, 2020');

        }
    }
    handlePageChange = (page) => {
        window.scrollTo(0, 0)
        this.setState({ loader: true, page, snNo: page - 1, DetailList: [] }, () => {
            this.getConnection();
        })

    }


    handleOk = e => {
        console.log(e);
        this.setState({
            isReport: false
        });
    };

    handleCancel = e => {
        console.log(e);
        this.setState({
            isReport: false
        });
    };
    onCreateBussinessRequest = (id) => {
        this.setState({ [`connection${id}`]: true })
        let req = {
            requestedUserId: id,
            connectionType: 2
        }
        _Api(Url.COMMUNITY_CREATEREQUEST.method, Url.COMMUNITY_CREATEREQUEST.url, req)
            .then(resp => {
                this.getConnection();
                notify("success", resp.responseData.message);
                this.setState({ [`connection${id}`]: false })
            }).catch((err) => {
                this.handleError(err);
            })
    }
    // onConnectRequest(e, requestId, status) {
    //     this.setState({ [`connection${requestId}`]: true });
    //     e.preventDefault();
    //     let req = {
    //         requestedUserId: requestId,
    //         connectionType: 1
    //     }
    //     _Api(Url.COMMUNITY_CREATEREQUEST.method, Url.COMMUNITY_CREATEREQUEST.url, req)
    //         .then(resp => {
    //             this.getConnection();
    //             notify(resp.responseData.message);
    //             this.setState({ [`connection${requestId}`]: false })
    //         }).catch((err) => {
    //             this.handleError(err);
    //         })

    // }
    // onClickConnection = (id, user_id) => {
    //     this.props.history.push(`/connection-profile/${user_id}/connection/${id}`);
    // }

    render() {
        let { DetailList, commingFrom, toProfile, limit } = this.state;
        const Communtiy = (props) => (<Menu onClick={(e) => this.onMoreClick(e, props.Id, props.userId, "", e, props.data)} >
            {props.isRemove && <Menu.Item key="Remove"> Remove Connection</Menu.Item>}
            <Menu.Item key="Block">Block</Menu.Item>
            <Menu.Item key="Report">Report</Menu.Item>
            {props.isBusinessRequest && < Menu.Item key="AddBusinessConnection">Send business request</Menu.Item>}
        </Menu>)

        return (

            <section className="brand-home-banner-bg">
                {this.state.loader && <Loader />}
                {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
                {/* {    <ChatBox history={this.props.history} match={this.props.match} />}*/}
                <div className="container-fluid cm-section featured-brands industries-serve">
                    <BackBtn className="btn btn-light cursor-point float-right" to="community" />
                    <div className="cm-wrapper">
                        <div className="header-container">

                            <div className="header-text">
                                <h1 className="title">{this.state.title}</h1>
                            </div>
                            {/* <div className="search_wrapper">
                                <i className="fa fa-search" />
                                <input type="search"
                                    placeholder="Search by Brand"
                                    name='search'
                                    onChange={this.handleSearch}
                                />
                            </div> */}

                        </div>
                        <div className="row">
                            {
                                DetailList && DetailList.map(item => (
                                    <div className="four-div">

                                        <div className="card community-card connection-parent">
                                            <div className="connection-detail-parent">
                                                <div className=" imgparent pr-0">
                                                    <div className="img-wrapper pos-relative image-chat">
                                                        <div className="img-wrapper pos-relative image-chat " >
                                                            <img className="img-fluid cursor-point" onClick={(e) => this.onClickConnection(item?._id, item?.userData?._id, e)} src={item?.userData?.image || require('../../../assets/images/login_user.png')} />
                                                            {commingFrom == "connection" &&

                                                                <span className={`chat cursor-point ${item.userData?.isOnline == 1 ? 'green' : 'yellow'}`} onClick={() => this.selectUser(item?.userData)} ><Chat /></span>}

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className=" textparent no-gutter d-flex align-items-start flex-column">
                                                    <h4 className={"cursor-point"} onClick={(e) => this.onClickConnection(item?._id, item?.userData?._id)}><strong>{util.getTitleCase(item?.userData?.firstName)} {util.getTitleCase(item?.userData?.lastName)}</strong></h4>
                                                    <span><small className="gray-color">{item?.userData?.JobTitle}</small></span>
                                                    {/* <div className="logo-wrapper">
                                                            <img src={require('../../../assets/images/beautyZone.png')} />
                                                        </div> */}
                                                    <h5>{item?.userData?.companyName}</h5>
                                                    {commingFrom == "connection" && <p className=""><span className="action_group  ">
                                                        <button className="btn btn-blue" onClick={() => this.videoCalling(item?.userData?._id)} ><span className="zoom"><ZoomVideo /></span>Video Call {this.state['videoConnectionLoder_' + item?.userData?._id] &&
                                                            <MySpinner
                                                            />}</button>
                                                        {/* {item.isBusinessConnection != "2" &&

                                                            <button className="btn btn-blue width-auto businesscard" onClick={(e) => this.onCreateBussinessRequest(item?.userData?._id)}>
                                                                Send business request
                                                       {this.state[`connection${item?.userData?._id}`] &&
                                                                    <MySpinner />}
                                                            </button>
                                                        } */}

                                                    </span>
                                                    </p>
                                                    }
                                                    {commingFrom == "pending" && <p className="text-center"><span className="action_group  ">
                                                        <CmBtn text="Cancel Request" className="btn btn-blue" bg='var(--connectBtn)' onClick={(e) => this.onRemoveAndBlock("COMMUNITY_CONNECTION_REMOVE", 3, item?._id, e)} >{this.state[`connection${item?._id}`] && <MySpinner
                                                        />}</CmBtn>

                                                    </span>
                                                    </p>
                                                    }
                                                    {commingFrom == "suggested" && <p className="text-center"><span className="action_group  ">

                                                        <CmBtn text="Connect" className="btn btn-blue" bg='var(--btncolor)' onClick={(e) => this.onConnectRequest(e, item?.userData?.userId)} >{this.state[`connection${item?.userData?.userId}`] && <MySpinner
                                                        />}</CmBtn>


                                                    </span>
                                                    </p>
                                                    }
                                                    {commingFrom == "requested" && <p className="text-center"><span className="action_group  ">

                                                        <CmBtn text=" Accept" className="btn btn-blue mr-2" bg='var(--btncolor)' onClick={(e) => this.onAcceptReject(e, item?._id, 1)} >{this.state[`connection${item?._id}`] && <MySpinner
                                                        />}</CmBtn>
                                                        &nbsp;&nbsp; <CmBtn text="Ignore" className="btn btn-blue" bg="#fff" color="#A3AAB0" border="1px solid #91cbd3" onClick={(e) => this.onAcceptReject(e, item?._id, 2)} >{this.state[`connection${item?._id}`] && <MySpinner
                                                        />}</CmBtn>
                                                    </span>
                                                    </p>
                                                    }
                                                    {commingFrom == "blocked" && <p className="text-center"><span className="action_group  ">
                                                        <CmBtn text="Unblock" className="btn btn-blue" bg='var(--btncolor)' onClick={(e) => this.onRemoveAndBlock("COMMUNITY_CONNECTION_BLOCK", 0, item?._id, e)} />


                                                    </span>
                                                    </p>
                                                    }
                                                </div>
                                                {
                                                    <Dropdown overlay={
                                                        commingFrom == "connection" &&

                                                        <Communtiy Id={item?._id} userId={item?.userData?._id} isRemove={true} isBusinessRequest={item.isBusinessConnection != "2"} data={item?.userData} trigger="click" />
                                                        ||
                                                        <Communtiy Id={item?._id} trigger="click" />


                                                    } placement="bottomCenter">
                                                        <div className="top-right">
                                                            {commingFrom != "blocked" && < i className="fa fa-ellipsis-v"></i>}
                                                        </div>
                                                    </Dropdown>}

                                            </div>

                                        </div>



                                    </div>


                                )) || <div className="w-100 text-center row nodata m-auto">
                                    <div className="m-auto">
                                        <NoMediaICon />

                                        <h2>No Data Found</h2>
                                    </div>                    </div>
                            }
                        </div>
                        {
                            DetailList?.length > 0 &&
                            <div >
                                <Pagination
                                    totalPage={Math.ceil(this.state.totalCount / limit)}
                                    currentPage={this.state.page}
                                    onPressBtn={this.handlePageChange}
                                />
                            </div>
                        }
                    </div>
                </div>



                <Footer />
                {this.state.isReport &&

                    <ReportModal
                        text='Close Me'
                        userData={this.state.userData}
                        closePopup={() => { this.setState({ isReport: false }) }}

                    />

                }
            </section >




        )
    }
}

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(CommunityDetails);

