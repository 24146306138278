import React, { Component } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../layout/Sidebar";
import Header from "../../layout/Header";
// import { Tab, Tabs } from "react-bootstrap";
// import { BackBtn } from "../../../components/common/Button";
import { _Api } from "../../../services/Api";
import Url from "../../../services/BaseUrl";
import { notify } from "../../../components/Toaster";
import WebHeader from "../../layout/WebHeader";
import { connect } from "react-redux";
import { Modal, Select, Tabs } from 'antd';
import { ZoomVideo, Chat } from "../../../SvgIcon";
import { Menu, Dropdown, Button } from 'antd';
import ReportModal from '../../web/community/ReportUserModal';
import { BtnRed, CmBtn } from "../../../components/common/Button";
import ContentEditable from "react-contenteditable";

import Footer from "../../layout/Footer"
import moment from "moment"

import PersonalProfile from "../../authentication/PersonalProfile";
import SignUpProcess from "../../authentication/SignUpProcess";
import Question from "../../eventQuestionnaire/QuestionProfile";
import Croper from "../../../components/Croper";
import socket from "../../../services/socket";
import { groupActions } from "../../../redux/action";

import { getTimeFormate } from "../../../utils/DateZone"

import DiaryMeetingModal from '../../../screens/web/diary/DiaryMeetingModal';






import { None } from "amazon-chime-sdk-js";

// import { Tabs } from 'antd';

let isProd = process.env.REACT_APP_ENV == "prod";
const { TabPane } = Tabs;

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: {},
            userQuestionAns: [],
            showPersonalDetailModal: false,
            showQuestionModal: false,

            // personal profile
            firstName: '',
            lastName: '',
            password: '',
            email: '',
            code: "",
            phoneNumber: '',
            companyName: '',
            emailError: '',
            firstNameError: '',
            lastNameError: '',
            passwordError: '',
            phoneNumberError: '',
            userTypeError: '',
            companyNameError: '',
            isLoader: false,
            isLoading: false,
            showMessage: false,
            isPasswordShow: false,
            isShowSignUpStep: true,
            signupStep: 1,
            selectedSector: [],
            selectedSectorCount: 0,
            selectedPlaceOfWork: [],
            placeofworkCount: 0,
            selectedRole: [],
            roleCount: 0,
            registrationId: "",
            registrationIdRequired: false,
            selectedCountryCode: "",
            selectedCountry: "",
            selectedCity: "",
            selectedCityId: "",
            visible: false,
            stepEdit: 1,
            jobTitleOther: '',

            mobileCode: '',
            mobileNumber: '',
            mobileNumberError: '',
            isEditPlan: false,
            //
            isCropper: false,

            // show profileee
            connectionStatus: "",
            startLoader: false,
            userType: null,
            userConnection: null,
            brandType: false,
            currentType: 1,

            media: null,
            aboutMe: '',
            qualification: [],
            workExperience: [],
            nameCv:'',
            cvData:null,
            meetingModal:false,
            hideIndex:[]
        }
    }



    componentDidUpdate(prevProps) {

        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.getUserProfile()
            this.getJobDetails()
        }
    }





    componentDidMount() {
        let self = this;
        this.props.dispatch({ type: 'PATH_ADD', payload: this.props.match })
        this.getUserProfile()
        this.getParticularQuestionAnswer()
        this.getUserConnection()
        this.getJobDetails()

        socket.on('online', function (params) {
            //console.log("onlineeeeeeeeeeeee",params);
            let { userData } = self.state;
            //console.log("userDetail",userData);
            if (userData && params.user._id === userData._id) {
                userData.isOnline = 1;
                self.setState({
                    userData
                })
            }
        })

        socket.on('offline', function (params) {
            //console.log("offline", params);
            let { userData } = self.state;
            if (userData && params.user._id === userData._id) {
                userData.isOnline = 0;
                self.setState({
                    userData
                })
            }
        })
    }

    updatePlan = (id) => {
        let req = {
            requestId: id,
            plan: this.state.brandType,
        };

        _Api(Url.CHANGE_PLAN.method, Url.CHANGE_PLAN.url, req)
            .then((resp) => {
                this.getUserProfile()
                this.setState({ isEditPlan: false })
                notify("success", resp.responseData.message);
            })
            .catch((err) => {
                this.handleError(err);
            });
    };







    getUserConnection = () => {
        let req = {
            requestUserId: this.props.match.params.id || localStorage.id || '',
        }
        _Api(Url.GET_CONNECTION_PROFILE.method, Url.GET_CONNECTION_PROFILE.url, '', `?requestUserId=${req.requestUserId}`)
            .then(resp => {
                // console.log("dnskms", resp)
                this.setState({ userConnection: resp.responseData.result?.isOnline, userType: resp.responseData.result?.userType, connectionStatus: resp.responseData.connectionStatus })

                if (this.props.match.path == "/user/profile/connection/:id") {

                    this.onAccept(resp.responseData.connectionStatus?._id, 1)
                }

            }).catch(err => {
                this.setState({ isLoader: false });
                // let errorMessage = err.error && err.error.responseMessage || err.message;
                // notify("err", errorMessage) ;
            });
    }

    getUserProfile = () => {
        this.getUserConnection()
        let self = this;
        let req = {
            requestUserId: this.props.match.params.id || localStorage.id || "",
        };
        _Api(
            Url.GET_USER_PROFILE.method,
            Url.GET_USER_PROFILE.url,
            "",
            `?requestUserId=${req.requestUserId}`)
            .then((resp) => {
                let responseData = resp.responseData.result;
                console.log("dsavbhdjk", responseData.userPlan)
                this.setState({
                    currentType: responseData.userPlan, brandType: responseData.userPlan == 1 ? -1 : responseData.userPlan,
                    userData: responseData, ...responseData
                })
            })
            .catch((err) => {
                this.setState({ isLoader: false });
                // console.log("dsavbhdjk", err)
                // let errorMessage =
                //     (err.error && err.error.responseMessage) || err.message;
                // notify("err", errorMessage);
            });
    }


    onClose = () => {
        this.getUserProfile()
        this.getParticularQuestionAnswer()
        this.setState({
            showPersonalDetailModal: false,
            showQuestionModal: false
        })
    }

    getParticularQuestionAnswer = () => {

        let req = {
            requestUserId: this.props.match.params.id || "",
        };

        _Api(Url.USER_ANS_LIST.method, Url.USER_ANS_LIST.url, '', `?requestUserId=${req.requestUserId}`)
            .then(resp => {
                //console.log("respresprespresp" , resp);
                this.setState({ userQuestionAns: resp.responseData.result.data, isLoader: false });
                console.log("particularQuestionAnswerDataparticularQuestionAnswerData", resp.responseData.result.data);

            }).catch(err => {

                // this.setState({ isLoader: false });
                // let errorMessage = err.error && err.error.responseMessage || err.message;
                // notify("err", errorMessage);
            });
    }


    sugnUpOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, firstNameError: '', lastNameError: '', emailError: '', passwordError: '', companyNameError: '' })
    }

    // sign up submit
    signUpSubmit = (e) => {
        e.preventDefault();
        let rexx = /^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[789]\d{9}|(\d[ -]?){7,15}\d$/
        let { firstName, lastName, email, password, phoneNumber, companyName, jobTitleOther } = this.state
        if (firstName.trim() == '')
            this.setState({ firstNameError: "*Please enter first name." })
        else if (lastName.trim() == '')
            this.setState({ lastNameError: '*Please enter last name.' })
        // else if (companyName.trim() === '')
        //     this.setState({ companyNameError: '*Please enter company name.' })
        else if (phoneNumber === '')
            this.setState({ phoneNumberError: '*Please enter phone number.' })
        else if (!rexx.test(phoneNumber))
            this.setState({ phoneNumberError: '*Please enter valid phone number.' })
        // else if (email.trim() == '')
        //     this.setState({ emailError: '*Please enter email.' })
        // else if (!validateEmail(email).status)
        //     this.setState({ emailError: '*Please enter valid email.' })
        // else if (password == '')
        //     this.setState({ passwordError: '*Please enter password.' })
        // else if (password.length <= 4)
        //     this.setState({ passwordError: '*Password must be greater than 4 characters.' })
        else {
            this.setState({ isLoading: true, erroStatus: false, showMessage: false })
            let req2 = {

                // budgetId: budgetId,
                countryCode: this.state.code,
                companyName: companyName,
                phoneNo: this.state.phoneNumber,
                phoneNumber: this.state.phoneNumber,
                jobTitleOther: this.state.jobTitleOther,
                city: this.state.city,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                mobileNumber: this.state.mobileNumber,
                mobileCode: this.state.mobileCode,

                requestUserId: this.props.match.params.id || "",
                // isEuropean: this.state.isEuropeCT == true ? 1 : 0
            }
            _Api(Url.EDIT_USER_PROFILE.method, Url.EDIT_USER_PROFILE.url, req2)
                .then(resp => {
                    this.setState({ isLoading: false })
                    this.getUserProfile()
                    this.onClose()
                    // else if (resp.statusCode == 1) {
                    //     this.props.dispatch({ type: SIGNUP_PROCESS, value: 0 });
                    //     this.setState({
                    //         isLoading: false,
                    //         visible:true
                    //         // isShowSignUpStep:false,
                    //         // signupStep:2
                    //     })
                    // }
                    // else {
                    //     this.setState({ isLoading: false, erroStatus: false, message: resp?.error?.responseMessage, showMessage: true })
                    // }
                }).catch(err => {
                    if (err.error)
                        notify("err", err.error.responseMessage)
                })

        }
    }

    setPhoneNumber = (phoneNumber, code) => {
        this.setState({ phoneNumber, phoneNumberError: '', code: code.dialCode })
    }

    setPhoneNumber2 = (phoneNumber, code) => {
        this.setState({ mobileNumber: phoneNumber, phoneNumberError: '', mobileCode: code.dialCode })
    }


    finalCropedImage = (categoryImgPrev, categoryImg, visibleVal) => {
        // console.log("ldjshgvhbjkd", { categoryImgPrev, categoryImg, visibleVal })
        this.setState(
            {
                visibleVal,
                isCropper: false,
                profilePicPreview: categoryImgPrev,
                media: categoryImg,
            },
            () => this.submitImage()
        );
    }

    submitImage = () => {
        let self = this;
        let formData = new FormData();
        formData.append("requestUserId", this.props.match.params.id || "");
        formData.append("image", this.state.media);

        _Api(Url.USER_IMAGE_UPLOAD.method, Url.USER_IMAGE_UPLOAD.url, formData)
            .then((resp) => {
                // self.props.dispatch(userActions.profile());
                let responseData = resp.responseData.result;
                this.setState({
                    profilePicPreview: responseData.image,
                    isImageUpload: false,
                });
                window.location.reload();
            })
            .catch((err) => {
                this.setState({ isLoader: false });
                let errorMessage =
                    (err.error && err.error.responseMessage) || err.message;
                notify("err", errorMessage);
            });
    };







    //show user profileeeeeeeeeeeeeeeeeeeeeee

    changeUserStatus(userId, status) {
        console.log("nbzjkcldcdn", { params: this.props.match.params.id, userId, status })
        let self = this;
        if (status == 1) {
            if (this.props.match.params.id == userId)
                this.setState({ userConnection: 1 })
        }
        else {
            if (this.props.match.params.id == userId)
                this.setState({ userConnection: 0 })

        }

    }

    handleError(err) {
        this.setState({ isLoader: false });
        let errorMessage = err.error && err.error.responseMessage || err.message;
        notify("err", errorMessage);
    }

    onMoreClick = (e) => {
        switch (e.key) {
            case "Remove":
                this.onRemoveAndBlock("COMMUNITY_CONNECTION_REMOVE", 3);

                break;
            case "Block":
                this.onRemoveAndBlock("COMMUNITY_CONNECTION_BLOCK", 1);
                // this.props.history.push('/community');
                break;
            case "AddBusinessConnection":
                this.onCreateBussinessRequest("AddBusinessConnection");
                break;
            case "Report":
                this.showModal();
                break;


        }
    }
    showModal = () => {
        this.setState({
            isReport: true
        });
    };

    onRemoveAndBlock = (api, status, id) => {

        // console.log("poiugdhj", this.state.connectionStatus)

        let req = {
            requestId: this.state.connectionStatus._id,
            status: status
        }
        _Api(Url[api].method, Url[api].url, req)
            .then(resp => {
                this.getUserProfile();
                if (status == 1)
                    this.props.history.push('/community');
            }).catch((err) => {
                this.handleError(err);
            })

    }


    onRemoveAndBlock2 = (api, status, id) => {
        let req = {
            requestId: id,
            status: status
        }
        _Api(Url[api].method, Url[api].url, req)
            .then(resp => {
                this.getUserProfile();
                if (status == 1)
                    this.props.history.push('/community');
            }).catch((err) => {
                this.handleError(err);
            })

    }

    onCreateBussinessRequest = () => {
        let req = {
            requestedUserId: this.props.match.params.id,
            connectionType: 2
        }
        _Api(Url.COMMUNITY_CREATEREQUEST.method, Url.COMMUNITY_CREATEREQUEST.url, req)
            .then(resp => {
                notify("success", resp.responseData.message);
                this.getUserProfile();
                notify(resp.responseData.message);
            }).catch((err) => {
                this.handleError(err);
            })
    }
    getPathName = (value) => {
        if (value.includes("/connection-profile"))
            return "connection";
        else if (value.includes("/pending-profile"))
            return "pending";
        else if (value.includes("/suggested-profile"))
            return "suggested";
        else if (value.includes("/requested-profile"))
            return "requested";

    }
    onAccept(requestId, status) {
        let req = {
            requestId: requestId,
            status: status
        }
        let { connectionId, id } = this.props.match.params;
        _Api(Url.COMMUNITY_ACCEPTREJECT.method, Url.COMMUNITY_ACCEPTREJECT.url, req)
            .then(resp => {
                notify("success", resp.responseData.message);
                this.props.history.push(`/user/profile/${this.props.match.params.id}`);
                this.getUserProfile();

            }).catch((err) => {
                this.handleError(err);
            })

    }
    onAcceptReject(e, requestId, status) {
        e.preventDefault();
        let req = {
            requestId: requestId,
            status: status
        }
        let { connectionId, id } = this.props.match.params;
        _Api(Url.COMMUNITY_ACCEPTREJECT.method, Url.COMMUNITY_ACCEPTREJECT.url, req)
            .then(resp => {
                notify("success", resp.responseData.message);
                this.props.history.push(`/connection-profile/${id}/connection/${connectionId}`);
                this.getUserProfile();

            }).catch((err) => {
                this.handleError(err);
            })

    }
    onConnectRequest(e, requestId, status) {
        e.preventDefault();
        let req = {
            requestedUserId: requestId,
            connectionType: 1
        }
        _Api(Url.COMMUNITY_CREATEREQUEST.method, Url.COMMUNITY_CREATEREQUEST.url, req)
            .then(resp => {
                notify("success", resp.responseData.message);
                this.getUserProfile();
            }).catch((err) => {
                this.handleError(err);
            })
    }

    // end user profileeeeeeeeeeeeeeeeeeeeee



    handleUpdateType =                                                                                                                                                                                                                                                                                                                (type) => {
        if (
            this.state.currentType != 3 &&
            (type != 1 || this.state.currentType == 1)
        ) {
            this.setState({ brandType: type });
        }
    };


    updateUserType = () => {
        if (this.state.isEditPlan) {
            let userType = localStorage.getItem("userType");
            if (userType == 1) {
                this.updatePlan(this.props.match.params.id);
            }
        }
        else {
            this.setState({ isEditPlan: true })
        }
    }


    selectUser = (user) => {
        let self = this;
        // console.log("user",user);
        if (this.props.userDetail._id !== user._id) {
            this.setState({
                selectedUser: user,
                selectedUserId: user._id,
                chatType: 3,
                isShow: true
            }, function () {
                self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 3 })
                self.props.dispatch({ type: 'CHAT_OPEN' })
                document.body.classList.toggle('chat_open_mob');
                self.props.dispatch({ type: 'SELECTED_USER', selectedUser: user })
                socket.emit('groupAdd', { type: 7, userId: user._id });
                //socket.emit('userConnection', {userId: user._id});
            })
        }

    }

    videoCalling = (userId) => {
        let self = this;
        var windowReference = window.open();
        if (userId) {
            self.setState({
                ['videoConnectionLoder_' + userId]: true
            })
            self.props.dispatch(groupActions.joinOneMeeting({ userId: userId, type: 7 }))
                .then(function (res) {
                    self.setState({
                        ['videoConnectionLoder_' + userId]: false
                    })
                    if (res.statusCode === 1 || res.statusCode === "1") {
                        var newWin = window.open('/calling/' + self.props.groupDetail.meeting._id, "_blank", "width=" + window.screen.width + ",height=" + window.screen.height)
                        console.log("newWin", newWin);
                        if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
                            windowReference.location = '/calling/' + self.props.groupDetail.meeting._id;
                            //alert("New windows pop-up is blocked, Please allow.")
                        }
                    } else {

                    }
                    console.log("myWindow", res)
                })
        }
    }

    callback = (key) => {
        console.log(key);
    }


    getJobDetails = () => {
        _Api(Url.GET_JOB_PROFILE.method, Url.GET_JOB_PROFILE.url, '',  `?requestUserId=${this.props.match.params.id || ""}`)
            .then(resp => {
                const {name, media, aboutMe, workExperience, qualification } = resp.responseData.result || {}
                console.log("abcdjkcnjk", resp.responseData.result);

                               this.setState({cvData:resp.responseData.result ,nameCv :name, media, aboutMe, showAbout: aboutMe, workExperience, qualification })

            }).catch(err => {
                this.setState({ isLoader: false });
                let errorMessage = err.error && err.error.responseMessage || err.message;
            });
    }


    onMeetingClick = ()=>{
        this.setState({meetingModal:true})
    }


    onMeetingClose = ()=>{
        this.setState({meetingModal:false})
    }

    hideHide = (classm)=>{
        this.setState({hideIndex: [...this.state.hideIndex,classm]})     
    }

    render() {
        let { userData, userQuestionAns, companyName, firstName, lastName, email, country, phoneNumber, workExperience, qualification } = this.state;
        let { image, sectorId, placeOfWork, roleId, city, userPlan, isPlanBuy } = userData ? userData : "";

        let userType = localStorage.getItem("userType");
        const isEdit = (localStorage.getItem("id") == userData._id || userType == 1)
        let isConnectionProfile = true;
        let requestType = this.props.history.location.pathname.includes("requested");
        let isBlurr = this.state.connectionStatus?.status != 1 && !isEdit
        console.log("abcdefgh", userQuestionAns)

        let isAdmin = userData?.userType != 1

        return (
            <>
                <div
                    className={
                        userType == 1
                            ? "dash_body_container pt-3"
                            : "dash_body_container without-sidenav pt-3"
                    }
                >
                    {
                        userType == 1 && <>
                            <Header />
                            <Sidebar />
                        </>
                    }

                    {/* <WebHeader/> */}
                    {userType != 1 && <a > <button onClick={() => this.props.history.goBack()} className="btn btn-light right">Back</button></a>}
                    <div class="body_container profile-body profile-dtl">



                        <div class="pt-2 container">
                            <div className="header-text mb-4">
                                <div className="d-flex align-items-center">
                                    <h4> {userData?.firstName} {userData?.lastName} </h4>
                                </div>
                            </div>
                            <div className="row">
                                <div className="offset-sm-2 col-sm-8">
                                    <div className="profile-icon-wrapper pos-relative image-chat">
                                        <img
                                            className="img-fluid"
                                            src={
                                                (this.state.profilePicPreview &&
                                                    URL.createObjectURL(this.state.media)) ||
                                                image ||
                                                require("../../../assets/images/login_user.png")
                                            }
                                        />
                                        {/*{!isEdit && this.props.match.params.id && this.state?.connectionStatus?.status === 1 && <span className={`chat cur-point ${userData.isOnline == 1 ? 'green' : 'yellow'}`} onClick={() => this.selectUser(userData)} ><Chat /></span>}*/}
                                        {!isEdit && <span className={`chat cursor-point ${userData.isOnline == 1 ? 'green' : 'yellow'}`} onClick={() => this.selectUser(userData)} ><Chat /></span>}
                                        {/* <span className={`chat ${userData.isOnline == 1 ? 'green' : 'yellow'}`} onClick={() => this.selectUser(userData)} ><Chat /></span> */}
                                        {isEdit &&
                                            <div>

                                                <div
                                                    className="upload-wrapper edit-image cursor-point"
                                                    onClick={() => this.setState({ isCropper: true })}
                                                >
                                                    <label htmlFor="file-upload" className="cursor-point">
                                                        <span>
                                                            {" "}
                                                            <img
                                                                className="img-fluid"
                                                                src={require("../../../assets/images/upload-photo.svg")}
                                                            />
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        }    </div>

                                    {!isEdit && this.props.match.params.id && <Detail {...this}
                                        {...this.props} userConnection={this.state.userConnection}
                                        startLoader={this.startLoader} selectUser={this.selectUser}
                                        videoCalling={this.videoCalling} data={userData} scope={this}
                                        isOnline={this.state.isOnline} connectionStatus={this.state.connectionStatus}
                                        onMoreClick={this.onMoreClick} connectionId={this.props.match.params.connectionId}
                                        requestType={requestType} currentUser={this.props.profile.profileInfo}
                                        
                                    />
                                    }
                                    {/* <p className="text-center mt-2 mb-4">
                                        <strong>
                                            {userData?.companyName}
                                        </strong>
                                    </p> */}
                                    <div className="vertical-table table-responsive user-profile mt-4">
                                       {userData?.companyName && <ul className="detail_row">
                                            <li>Company Name</li>
                                            <li>{userData?.companyName}</li>
                                        </ul>}
                                        {userData?.userType == 4 &&
                                            <ul className="detail_row">
                                                <li>Job Profile</li>
                                                <li>{userData?.jobTitleOther}</li>
                                            </ul>}
                                        <ul className="detail_row">
                                            <li>Email Address</li>
                                            <li className={isBlurr ? "blurry-text" : ''}>{isBlurr ? '***********' : userData?.email}</li>
                                        </ul>
                                        <ul className="detail_row">
                                            <li>Phone Number</li>
                                            <li className={isBlurr ? "blurry-text" : ''}>{isBlurr ? '***********' : userData?.phoneNumber}</li>
                                        </ul>
                                        <ul className="detail_row">
                                            <li>Mobile Number</li>
                                            <li className={isBlurr ? "blurry-text" : ''}>{isBlurr ? '***********' : userData?.mobileNumber ? userData?.mobileNumber : 'NA'}</li>
                                        </ul>
                                        {/* <ul className="detail_row">
                                            <li>Country</li>
                                            <li>{country}</li>
                                            </ul> */}
                                    </div>

                                    <div className="text-center text-md-right mt-3 mb-3  mb-md-5">
                                        {userType != 1 && isEdit && <Link to="/change-password" className="btn btn-warning btn-sm changepass mr-3">Change Password</Link>}

                                        {isEdit && <button type="button" className="btn btn-warning btn-sm"
                                            onClick={() => this.setState({ showPersonalDetailModal: true })}
                                        >Edit</button>}
                                    </div>
                                </div>
                            </div>
                            <div className="userprofile-tbs-wrp UserPrf-wraps">
                                <Tabs defaultActiveKey="1" onChange={this.callback}>
                                {userData?.userType != 4 && isAdmin && ((isEdit || (this.state.hideIndex.length != userQuestionAns.length)) || (sectorId?.length !=0 || placeOfWork?.length != 0 || roleId?.length != 0) ) &&  <TabPane className="prfsnl-UserDtls" tab="Professional Details" key="1">
                                        

                                            <div class="rounded-body  mt-0">
                                                {/* <div class="header-text pt-4 pl-3 pr-3">
                                                    <h4 className="text-center mt-2 mb-0">Professional Details </h4>
                                                </div> */}
                                                {userType == 1 && <div className="edit-tabular mb-3">
                                                    <div class="table-responsive-sm">
                                                        <table class="table table-striped">
                                                            <tbody>
                                                                <tr><td><h5>Plan Type</h5></td></tr> <br />
                                                                {
                                                                    
                                                                    !this.state.isEditPlan ?
                                                                        <tr><td><p><p>{userPlan == 2 ? 'Operator showcase' : userPlan == 3 ? 'Agency Showcase' : isPlanBuy == 1 ? 'Candidate Ticket' : 'no plan'}</p></p></td></tr>
                                                                        :
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <div className="form-group">
                                                                                    {/* <level>Brand Type</level> */}
                                                                                    <div className="assign-checkboc-parent">
                                                                                        <div>
                                                                                            <label className="cm_container">
                                                                                            Candidate Ticket
                                                                                        <input
                                                                                                    checked={this.state.brandType == 1}
                                                                                                    onClick={() => this.handleUpdateType(1)}
                                                                                                    type="radio"
                                                                                                    name="radio"
                                                                                                />
                                                                                                <span className="radio-checkmark"></span>
                                                                                            </label>
                                                                                        </div>

                                                                                        <div>
                                                                                            <label className="cm_container">
                                                                                            Operator showcase
                                                        <input
                                                                                                    checked={this.state.brandType == 2}
                                                                                                    onClick={() => this.handleUpdateType(2)}
                                                                                                    type="radio"
                                                                                                    name="radio"
                                                                                                />
                                                                                                <span className="radio-checkmark"></span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div>
                                                                                            <label className="cm_container">
                                                                                            Agency Showcase
                                                        <input
                                                                                                    checked={this.state.brandType == 3}
                                                                                                    onClick={() => this.handleUpdateType(3)}
                                                                                                    type="radio"
                                                                                                    name="radio"
                                                                                                />
                                                                                                <span className="radio-checkmark"></span>
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {isEdit && <button
                                                        type="button"
                                                        className="btn btn-red " onClick={this.updateUserType}>{
                                                            this.state.isEditPlan ? 'Save' : 'Edit'
                                                        }</button>}
                                                </div>
                                                }

                                                

                                                { isEdit ? <div className="edit-tabular mb-3">
                                                    <div class="table-responsive-sm">
                                                        <table class="table table-striped">
                                                            <tbody>
                                                                <tr><td><h5 className="text-muted">What sector do you directly work in?</h5></td></tr>
                                                                 <tr><td><p>{sectorId?.map((item, index) => <p>{item.name}</p>)}{sectorId?.length == 0 ? <span className="text-mtd">To be completed</span> : ''}</p></td></tr>
                                                                <tr><td><h5>What is your Place of Work ?</h5></td></tr>
                                                                <tr><td><p>{placeOfWork?.map((item, index) => <p>{item.name}</p>)} {placeOfWork?.length == 0 ? <span className="text-mtd">To be completed</span> : ''} </p></td></tr>
                                                                <tr><td><h5>What is your role ?</h5></td></tr>
                                                                <tr><td><p>{roleId?.map((item, index) => <p>{item.name}</p>)} {roleId?.length == 0 ? <span className="text-mtd">To be completed</span> : ''}</p></td></tr>

                                                                {userData.registrationId && <> <tr><td><h5>GDC/ GMC/ NMC/ GPhC number</h5></td></tr>
                                                                    <tr><td><p>{<p>{userData.registrationId}</p>}</p></td></tr>   </>}



                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {isEdit && <button
                                                        type="button"
                                                        className="btn btn-red " onClick={() => this.setState({ stepEdit: 1, showQuestionModal: true })}>Edit</button>}
                                                </div>
                                                
                                                :
                                               (sectorId?.length !=0 || placeOfWork?.length != 0 || roleId?.length != 0 ) &&
                                                 <div className="edit-tabular mb-3">
                                                <div class="table-responsive-sm">
                                                    <table class="table table-striped">
                                                        <tbody>
                                                            {sectorId?.length !=0 && <> 
                                                            <tr><td><h5>What sector do you directly work in?</h5></td></tr>
                                                             <tr><td><p>{sectorId?.map((item, index) => <p>{item.name}</p>)}{sectorId?.length == 0 ? <span className="text-mtd">To be completed</span> : ''}</p></td></tr>
                                                           </>
                                                            }
                                                           {placeOfWork?.length != 0 && <>
                                                            <tr><td><h5>What is your Place of Work ?</h5></td></tr>
                                                            <tr><td><p>{placeOfWork?.map((item, index) => <p>{item.name}</p>)} {placeOfWork?.length == 0 ? <span className="text-mtd">To be completed</span> : ''} </p></td></tr>
                                                            </>}
                                                            {roleId?.length != 0 && <>
                                                            <tr><td><h5>What is your role ?</h5></td></tr>
                                                            <tr><td><p>{roleId?.map((item, index) => <p>{item.name}</p>)} {roleId?.length == 0 ? <span className="text-mtd">To be completed</span> : ''}</p></td></tr>
                                                            </>
                                                            }

                                                            {userData.registrationId && <> <tr><td><h5>GDC/ GMC/ NMC/ GPhC number</h5></td></tr>
                                                                <tr><td><p>{<p>{userData.registrationId}</p>}</p></td></tr>   </>}



                                                        </tbody>
                                                    </table>
                                                </div>                                                
                                            </div>
                                                
                                                
                                                }
                                               {  !isEdit ?
                                                country ?  <div className="edit-tabular mb-3">
                                                <div class="table-responsive-sm">
                                                    <table class="table table-striped">
                                                        <tbody>
                                                            <tr><td><h5>Address</h5></td></tr>
                                                            <tr><td><p>{city ? `${city},` : ''} {country ? country : <span className="text-mtd">To be completed</span>}</p></td></tr>
                                                        </tbody>
                                                    </table>
                                                </div> 

                                            </div> : ''
                                               :<div className="edit-tabular mb-3">
                                                    <div class="table-responsive-sm">
                                                        <table class="table table-striped">
                                                            <tbody>
                                                                <tr><td><h5>Address</h5></td></tr>
                                                                <tr><td><p>{city ? `${city},` : ''} {country ? country : <span className="text-mtd">To be completed</span>}</p></td></tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {isEdit && <button type="button" className="btn btn-red " onClick={() => this.setState({ stepEdit: 2, showQuestionModal: true })}>Edit</button>}
                                                </div>
                                                
                                                
                                                }




                                               { !isEdit ? 
                                                this.state.hideIndex.length != userQuestionAns.length &&  <div className="edit-tabular mb-3">
                                               <div class="table-responsive-sm">
                                                   <table class="table table-striped">
                                                       <tbody>
                                                           {
                                                               userQuestionAns?.map((item, index) => {
                                                                   let { optionAnswerArray } = item
                                                                   return (
                                                                       <>
                                                                          { this.state.hideIndex.indexOf(index) == -1 && <> <tr className={`question${index}`}><td><h5>{item.question}</h5></td></tr>
                                                                           <tr className={`question${index}`}><td><p>{item.questionType == 1 ? item?.plainTextAnswer : optionAnswerArray?.length != 0 ? optionAnswerArray?.map(item => <p>{item}</p>) : this.hideHide(index)}</p></td></tr>
                                                                           </>
                                                                           }
                                                                       </>
                                                                   )
                                                               })
                                                           }
                                                       </tbody>
                                                   </table>
                                               </div>
                                               {isEdit && <button
                                                   type="button"
                                                   className="btn btn-red " onClick={() => this.setState({ stepEdit: 3, showQuestionModal: true })} >Edit</button>}
                                           </div>                                   
                                               
                                               
                                               :
                                               <div className="edit-tabular mb-3">
                                                    <div class="table-responsive-sm">
                                                        <table class="table table-striped">
                                                            <tbody>
                                                                {
                                                                    userQuestionAns?.map((item, index) => {
                                                                        let { optionAnswerArray } = item
                                                                        return (
                                                                            <>
                                                                               { <> <tr className={`question${index}`}><td><h5>{item.question}</h5></td></tr>
                                                                                <tr className={`question${index}`}><td><p>{item.questionType == 1 ? item?.plainTextAnswer : optionAnswerArray?.length != 0 ? optionAnswerArray?.map(item => <p>{item}</p>) : <span className="text-mtd">To be completed</span> }</p></td></tr>
                                                                                </>
                                                                                }
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {isEdit && <button
                                                        type="button"
                                                        className="btn btn-red " onClick={() => this.setState({ stepEdit: 3, showQuestionModal: true })} >Edit</button>}
                                                </div>}
                                            </div>
                                
                                        
                                    </TabPane>
                                                    }
                                   {this.state.cvData && <TabPane tab="Career Profile" key="2">
                                        <div class="rounded-body  mt-0">
                                            {/* <div class="header-text pt-4 pl-3 pr-3">
                                                <h4 className="text-center mt-2 mb-0">Career Profile</h4>
                                            </div> */}
                                            <div className="job-profile-temp">
                                                <div className="job-dtls-cmn-list mb-3">
                                                    <div className="job-dtls-topbar">
                                                        <h4>Resume</h4>
                                                    </div>
                                                    <div className="job-dtls-bodyWrp">
                                                        <div className="row align-items-center">
                                                            <div className="col-md-6">
                                                                <div className="userCV">
                                                                    {
                                                                        this.state.media ? <img src={require("../../../assets/images/upload-cv.png")} className="img-fluid" /> :
                                                                       <p>Not Available</p>
                                                                    }
                                                                    
                                    <span className="fileName text-uppercase">{this.state.nameCv}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="downloadCV text-md-right">
                                                                   { (userData.userType == 2 || userData.userType == 4) && this.state.media && <a href={this.state.media} download className="btn btn-cmn"><i className="fa fa-download"></i> Download</a>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className="job-dtls-cmn-list mb-3">
                                                    <div className="job-dtls-topbar">
                                                        <h4>How many year's experience do I have?</h4>
                                                    </div>
                                                    <div className="job-dtls-bodyWrp">
                                                        <p>6 Years</p>
                                                    </div>
                                                </div> */}

                                                <div className="job-dtls-cmn-list mb-3">
                                                    <div className="job-dtls-topbar">
                                                        <h4>About Me</h4>
                                                    </div>
                                                    <div className="job-dtls-bodyWrp">
                                                    <ContentEditable
                                            html={this.state.aboutMe ? this.state.aboutMe : ''} // innerHTML of the editable div
                                            disabled={true} // use true to disable edition
                                        // onChange={this.handleChange} // handle innerHTML change
                                        />
                                                        
                                                    </div>
                                                </div>

                                                <div className="job-dtls-cmn-list mb-3">
                                                    <div className="job-dtls-topbar">
                                                        <h4>Work Experience</h4>
                                                    </div>
                                                    <div className="job-dtls-bodyWrp">
                                                        {
                                                            workExperience?.map((item, index) => {
                                                                return (
                                                                    <div className="jobprofile-list-wrp d-flex align-items-center">
                                                                        <div className="jobprofile-left-wrp">
                                                                            <div className="job-dtl-crd">
                                                                                <h4>{item.designation}</h4>
                                                                                <ul className="list-unstyled mb-0">
                                                                                    <li>{item.organization}</li>

                                                                                    <li className="text-muted">{getTimeFormate(item.workedFrom)}-  {item.currentCompany == 1 ? "Present" : getTimeFormate(item.workedTill)}</li>
                                                                                    <li>{item.description ? item.description : ''}</li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                )
                                                            })
                                                        }
                                                        {
                                                            workExperience.length == 0 ? <p>Not Available</p> : <></> 
                                                        }
                                                    </div>
                                                </div>

                                                <div className="job-dtls-cmn-list mb-3">
                                                    <div className="job-dtls-topbar">
                                                        <h4>Qualification</h4>
                                                    </div>
                                                    <div className="job-dtls-bodyWrp">
                                                        {qualification?.map((item, index) => {                                                           
                                                            return (
                                                                <div className="jobprofile-list-wrp d-flex align-items-center">
                                                                    <div className="jobprofile-left-wrp">
                                                                        <div className="job-dtl-crd">
                                                                            <h4>{item.highestQualification}</h4>
                                                                            <ul className="list-unstyled mb-0">
                                                                                <li>{item.specialization}</li>
                                                                                <li>{item.institute}</li>
                                                                                <li>{moment(item.startYear).year()}- {moment(item.endYear).year()}</li>
                                                                                <li className="text-muted">{
                                                                                    item.specialization == 0 ? "Full Time" : item.specialization == 1 ? 'Part Time' : 'Correspondence'
                                                                                }</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>                                                                   
                                                                </div>

                                                            )
                                                        })

                                                        }
                                                         {
                                                            qualification.length == 0 ? <p>Not Available</p> : <></> 
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </TabPane>}
                                </Tabs>
                            </div>
                        </div>
                    </div>
                    {/* <Footer /> */}
                </div>


            <Modal
                        wrapClassName="signup_parent_modal meetingModal"
                        className="cm_popup signup_modal "
                        //cm_popup term-services
                        visible={this.state.meetingModal}
                        // onOk={this.handleOk}
                        onCancel={this.onMeetingClose}
                        footer={null}
                        centered
                    >
                        <DiaryMeetingModal {...this} {...this.props} {...this.state} onMeetingClose = {this.onMeetingClose} />
                    </Modal>

                <Modal
                    wrapClassName="signup_parent_modal"
                    className="cm_popup signup_modal "
                    //cm_popup term-services
                    visible={this.state.showPersonalDetailModal}
                    // onOk={this.handleOk}
                    onCancel={this.onClose}
                    footer={null}
                    centered
                    maskClosable={false}
                >
                    <PersonalProfile
                        {...this.state}
                        isStaff={userData?.userType == 4}
                        sugnUpOnChange={this.sugnUpOnChange}
                        signUpSubmit={this.signUpSubmit}
                        setPhoneNumber={this.setPhoneNumber}
                        setPhoneNumber2={this.setPhoneNumber2}
                    />

                </Modal>
                {
                    this.state.showQuestionModal &&
                    <Question
                        {...this.props}
                        stepEdit={this.state.stepEdit}
                        showQuestionModal={this.state.showQuestionModal}
                        onClose={this.onClose}
                        userData={userData}
                        userQuestionAns={userQuestionAns}
                    />
                }



                {this.state.isCropper ? (
                    <Croper
                        aspect={1 / 1}
                        // onImageCrop={img => this.setState({ media: img })}
                        cropShape="round"
                        imgPrev={"1-1.jpg"}
                        finalCropedImage={(categoryImgPrev, categoryImg, visibleVal) =>
                            this.finalCropedImage(categoryImgPrev, categoryImg, visibleVal)
                        }
                        visibleVal={this.state.isCropper}
                        onClose={() => this.setState({ isCropper: false })}
                    />
                ) : (
                        ""
                    )}



            </>
        );
    }



}

const Detail = (props) => {
    console.log("ksjgvgdhjksakjdhbbh", props.connectionStatus)
    const CommunityMenu = (props) => {
        return (<Menu onClick={props.onMoreClick} >
            {props.isRemove && <Menu.Item key="Remove"> Remove Connection</Menu.Item>}
            <Menu.Item key="Block">Block</Menu.Item>
            <Menu.Item key="Report">Report</Menu.Item>
            {/* {props.isBusinessRequest && < Menu.Item key="AddBusinessConnection">Send business request</Menu.Item>} */}
        </Menu>)
    }
    let { roleId,
        sectorId,
        registrationId, placeOfWork, _id, firstName, lastName, companyName, jobTitleOther, city, country, phoneNumber, email, image, productAndServiceOfferId, attendingOrLookingforId, streetAddress1, zipCode, placeOfWorkId, jobTitleId, budgetId, noOfPeopleWorkId, userType, placeOfWorkOther } = props.data;
    return (

        <>
            <div className="table-wrapper">
                {/* <a > <button onClick={() => props.scope.goBack(props.scope)} className="btn btn-light cursor-point float-right"><i class="fa fa-arrow-left" aria-hidden="true"></i>Back</button></a> */}


                <br />
                <div className="self-detail btns-Userprf">
                    

                    {(!props.requestType && (props.connectionStatus === 4)) && <p className="text-center "><span className="action_group  ">
                        <CmBtn text="Connect" className="btn btn-blue" bg='var(--btncolor)' onClick={(e) => props.scope.onConnectRequest(e, _id, 1)} />
                    </span>
                        <button className="btn btn-blue" onClick={() => props.videoCalling(_id)} ><span className="zoom"><ZoomVideo /></span>Video Call</button>
                        <button className="btn btn-blue" onClick={() => props.selectUser(props.data)} ><span className="zoom"><Chat /></span>Chat</button>
                        {props?.data?.userType != 0 && <Link to={`/business/${props?.data?.userType == 4 ? props?.data?.ownerId : props?.data?._id}/${props?.data?.activeCompany}`} className="btn btn-blue business-btn" bg="#2d688d">View business</Link>}

                        <Dropdown overlay={<CommunityMenu onMoreClick={props.onMoreClick} />} placement="bottomCenter" trigger="click">
                            <button className="btn btn-blue ml-2 mr-2" >More</button>
                        </Dropdown>
                    </p>
                    }

                    {(!props.requestType && (props.connectionStatus === 1 || props.connectionStatus?.status === 1)) && <p className="text-center"><span className="action_group  ">


                        <button className="btn btn-blue" onClick={() => props.videoCalling(_id)} ><span className="zoom"><ZoomVideo /></span>Video Call</button>
                        <button className="btn btn-blue" onClick={() => props.selectUser(props.data)} ><span className="zoom"><Chat /></span>Chat</button>

                        {props?.data?.userType != 0 && <Link to={`/business/${props?.data?.userType == 4 ? props?.data?.ownerId : props?.data?._id}`} className="btn btn-blue business-btn ml-2 mr-2" bg="#2d688d">View Brand Showcase </Link>}

                        <Dropdown overlay={<CommunityMenu isBusinessRequest={true} isRemove={true} onMoreClick={props.onMoreClick} />} placement="bottomCenter" trigger="click">
                            <button className="btn btn-blue ml-2 mr-2" >More</button>
                        </Dropdown>


                    </span>
                    </p>
                    }
                    {((props.connectionStatus === 0 || props.connectionStatus?.status === 0) && props.connectionStatus.requestedUserId == props.userDetail._id) && <p className="text-center"><span className="action_group  ">
                        <CmBtn text="Cancel Request" className="btn btn-blue" bg='var(--connectBtn)' onClick={(e) => props.scope.onRemoveAndBlock2("COMMUNITY_CONNECTION_REMOVE", 3, props?.connectionStatus?._id)} />
                        <button className="btn btn-blue" onClick={() => props.videoCalling(_id)} ><span className="zoom"><ZoomVideo /></span>Video Call</button>
                        <button className="btn btn-blue" onClick={() => props.selectUser(props.data)} ><span className="zoom"><Chat /></span>Chat</button>
                        {props?.data?.userType != 0 && <Link to={`/business/${props?.data?.userType == 4 ? props?.data?.ownerId : props?.data?._id}`} className="btn btn-blue business-btn" bg="#2d688d">View Brand Showcase </Link>}

                    </span>
                        <Dropdown overlay={<CommunityMenu onMoreClick={props.onMoreClick} />} placement="bottomCenter" trigger="click">
                            <button className="btn btn-blue ml-2 mr-2" >More</button>
                        </Dropdown>
                    </p>
                    }

                    {!props.requestType && (props.connectionStatus === 2 || props.connectionStatus === 3) && <p className="text-center"><span className="action_group  ">
                        <CmBtn text="Video Call" className="btn btn-blue" onClick={(e) => props.scope.onConnectRequest(e, _id, 1)} />
                        <button className="btn btn-blue" onClick={() => props.videoCalling(_id)} ><span className="zoom"><ZoomVideo /></span>Video Call</button>
                        <button className="btn btn-blue" onClick={() => props.selectUser(props.data)} ><span className="zoom"><Chat /></span>Chat</button>
                        {props?.data?.userType != 0 && <Link to={`/business/${props?.data?.userType == 4 ? props?.data?.ownerId : props?.data?._id}`} className="btn btn-blue business-btn" bg="#2d688d">View Brand Showcase </Link>}

                        <Dropdown overlay={<CommunityMenu onMoreClick={props.onMoreClick} />} placement="bottomCenter" trigger="click">
                            <button className="btn btn-blue ml-2 mr-2">More</button>
                        </Dropdown>
                    </span>
                    </p>

                    }
                    {((props.connectionStatus === 0 || props.connectionStatus?.status === 0) && props.connectionStatus.requestedUserId != props.userDetail._id) && <p className="text-center "><span className="action_group">
                        <CmBtn text=" Accept" className="btn btn-blue" bg='var(--btncolor)' onClick={(e) => props.scope.onAcceptReject(e, props.connectionStatus._id, 1)} />
                        <CmBtn text="Ignore" className="btn btn-blue" bg="#fff" color="#A3AAB0" border="1px solid #91cbd3" onClick={(e) => props.scope.onAcceptReject(e, props.connectionStatus._id, 2)} />
                        {props?.data?.userType != 0 && <Link to={`/business/${props?.data?.userType == 4 ? props?.data?.ownerId : props?.data?._id}`} className="btn btn-blue business-btn" bg="#2d688d">View Brand Showcase </Link>}
                    </span>
                    </p>
                    }

                    {(!props.requestType && (props.connectionStatus === 1 || props.connectionStatus?.status === 1) && props.connectionStatus?.isBusinessConnection != 2) && <p className="text-center "><span className="action_group  ">
                        <p className="text-center viewbrandshowcse">
                            {/* <CmBtn onClick={() => props.onCreateBussinessRequest("AddBusinessConnection")} text="Send business request" className="btn btn-blue business-btn" bg='var(--btncolor)' /> */}
                            {/* { props?.data?.userType != 0 &&  <Link  to={`/brand/${props?.data?.userType == 4 ? props?.data?.ownerId : props?.data?._id }`}   className="btn btn-blue business-btn"  bg="#2d688d">View Brand Showcase </Link>} */}
                        </p>

                    </span>
                    </p>
                    }



                  <p className="text-center">
                      <CmBtn className="btn btn-blue" text=" Request a meeting"  bg='var(--btncolor)' onClick={(e) => props.scope.onMeetingClick()} />
                </p>




                </div>
                {props.scope.state.isReport &&

                    <ReportModal
                        text='Close Me'
                        userData={props.data}
                        closePopup={() => { props.scope.setState({ isReport: false }) }}

                    />

                }
            </div>






        </>

    )
}

function mapStateToProps(state) {
    return {
        ...state,
    };
}
export default connect(mapStateToProps)(Profile);


