import React, { Component } from 'react';
import Footer from '../../../layout/Footer';
import { Hert, ExhibitionManagement, Pencil, Delete , UploadVideo} from '../../../../SvgIcon';
import { Link } from "react-router-dom";
import Sidebar from '../../../layout/Sidebar';
import Header from '../../../layout/Header';
import { DatePicker, TimePicker,Select } from 'antd';
import { _Api } from '../../../../services/Api';
import Url from '../../../../services/BaseUrl';
import { notify } from '../../../../components/Toaster';
import InlineError from '../../../../components/InlineError';
import moment from 'moment-timezone';
import Loader from '../../../../components/Loader';

import Croper from "../../../../components/Croper";
import TimeZone from "../../../../utils/timeZone"

const { Option } = Select;
const { RangePicker } = DatePicker

const disabledDate = (current) => {
    // Can not select days before today and today
    return current && moment(current.valueOf()).format('MM/DD/YYYY') < moment().format('MM/DD/YYYY');;
}

class AddNetworkingLounge extends Component {
    constructor(props) {
        super(props)
        this.state = {
             
            loungeName: '',
            conferenceId: '',
            loungeUrl: '',
            setOrder: '',
            startDate: moment(),
            endDate: moment(),
            startTime: moment(),
            endTime: moment(),
            numberofAttendees: '',
            loungeNameError: '',
            numberofAttendeesError: '',
            startTimeError: '',
            endDateError: '',
            setOrderError: '',
            isLoader: false,
            description:'',
            isCropper:false,
            timezone: moment.tz.guess(),
        }
    }
    onChangeDate = (value, dateString) => {      
        if (value)
            this.setState({ startDate: value[0] , startTime: value[0],  endDate: value[1], endTime: value[1] }, () => {
                console.log("abcdefghij", this.state.startDate, this.state.endDate)
            })
    }

    handleChangeTimeZone = (value) => {
        // moment.tz.setDefault(value)
       console.log("dkjhasghdjk",  moment.tz(this.state.startDate, this.state.timezone).utcOffset() )
        this.setState({ timezone: value })
    }
    handleOnChange = (type, e) => {
        
        this.setState({ [type]: e, setOrderError:"",startDateError: '', endDateError: '', startTimeError: '', endTimeError: "", liveDateError: '', liveTimeError: '' })
    }

    networkingLoungeOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value,setOrderError:'', loungeNameError: '', numberofAttendeesError: '', startTimeError: '', endDateError: '', setOrderError: '' })
    }

    AddNetworkingLounge = () => {
        let { loungeName, description, media, loungeUrl, conferenceId, loungesUrl, setOrder, startDate, endDate, startTime, endTime, numberofAttendees } = this.state
        console.log("statecallllll", this.state)
        try {

            console.log("mxksnxjns", {loungeName,startDate,
                startTime,
                endDate,
                endTime,
                numberofAttendees



            
            })


            if (loungeName == '')
                this.setState({ loungeNameError: '*Please enter lounge name.' })
            // else if (setOrder == '')
            //     this.setState({ setOrderError: '*Please select order.' })
            // else if (startDate == '')
            //     this.setState({ startDateError: '*Please enter start date.' })
            // else if (startTime == '')
            //     this.setState({ startTimeError: '*Please enter start time.' })
            // else if (endDate == '')
            //     this.setState({ endDateError: '*Please enter end date.' })
            // else if (endTime == '')
            //     this.setState({ endTimeError: '*Please enter end time.' })
            else if (numberofAttendees == '')
                this.setState({ numberofAttendeesError: '*Please enter attendees.' })
            else {
                this.setState({ isLoader: true })
                console.log("statecallllll2222", this.state)

                let formData = new FormData()
                formData.append("exhibitionId", this.props.match.params.exid)
                formData.append("description", description)              
                formData.append("loungeName", loungeName)           
                formData.append("loungesUrl",loungesUrl)
                // formData.append("setOrder", setOrder)
                // formData.append("euroAmount", 0)
                formData.append("startDate", startDate.valueOf())
                formData.append("endDate", endDate.valueOf())
                formData.append("startTime", startDate)
                formData.append("endTime", endDate)
                formData.append("image", media)
                formData.append("numberofAttendees", numberofAttendees)
                formData.append("timezone", this.state.timezone)
                // let req = {

                //     exhibitionId: this.props.match.params.exid,
                //     loungeName: loungeName,
                //     loungesUrl: loungeUrl,
                //     // setOrder: setOrder,
                //     startDate: (Date.parse(endDate)).toString(),
                //     endDate: (Date.parse(endDate)).toString(),
                //     startTime: startTime,
                //     endTime: endTime,
                //     numberofAttendees: numberofAttendees,
                //     description:description,           
                // }
                _Api(Url.ADD_NETWORKING_LOUNGE.method, Url.ADD_NETWORKING_LOUNGE.url, formData)
                    .then(resp => {
                        this.setState({ isLoader: false })
                        this.props.history.goBack()
                        // this.props.history.goback()
                        notify('success', resp.responseData.message)
                    }).catch(err => {
                        this.setState({ isLoader: false })
                        if (err.error)
                            notify("err", err.error.responseMessage)
                        else if (err)
                            this.setState({ isLoader: false })
                        // notify("err", err.message)
                    })
            }

        } catch  { }
    }

    handleImageUpload = (event) => {
        
        let reader = new FileReader();
        let file = event.target.files[0];
        let { valiDate } = this.state;

        if (file) {
            reader.readAsDataURL(file)
        }
        if (file) {
            
            if (file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png') {

                reader.addEventListener("load", () => {
                    this.setState({ profilePicPreview: reader.result }, function () {
                    })
                }, false);

                if (file.size > 2000000) {
                   
                    valiDate = false;
                 
                    this.setState({
                        media: event.target.files[0],
                        profilePicErrorMsg: 'Profile pic size should be less than 5Mb.'
                    });
                } else {
                   
                    this.setState({
                        media: event.target.files[0],
                        mediaError: ''
                    });
                    // alert("wrong file")
                }
            } else {
                valiDate = false;
                this.setState({ mediaError: 'File type not supported.' })
            }
        }
    }


    finalCropedImage = (categoryImgPrev, categoryImg, visibleVal) => {
        this.setState({
            visibleVal,
            isCropper: false,
            profilePicPreview: categoryImgPrev,
            media: categoryImg

        })
      
    }

    render() {
        let {description, isLoader, loungeUrl, loungeName, loungeNameError, conferenceId, loungesUrl, setOrder, setOrderError, startDate, startDateError, endDate, endDateError, startTime, startTimeError, endTime, endTimeError, numberofAttendees, numberofAttendeesError } = this.state
        return (
            <>
                <div className="dash_body_container">
                    {isLoader && <Loader />}
                    <div className="body_container">
                        <div className="header-container">
                            <div className="d-flex align-items-center">
                                <p className="header-icon"><ExhibitionManagement /></p>
                                <h4>Add Networking Lounge
                                    <br />
                                    {/* <ul className="header-breadcrum">
                                        <li><a href="#">Exhibition Management</a>/</li>
                                        <li><a href="#">Networking Lounge</a></li>
                                    </ul> */}
                                </h4>
                            </div>
                            <a > <button onClick={()=> this.props.history.goBack()} className="btn btn-light"><i class="fa fa-arrow-left" aria-hidden="true"></i>Back</button></a>

                        </div>
                        <div className="rounded-body-form">

                            <form className="cm-form-wrapper">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <level>Lounge Name</level>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter Lounge Name"
                                                maxLength={64}
                                                name="loungeName"
                                                value={loungeName}
                                                onChange={this.networkingLoungeOnChange}
                                            />
                                            <InlineError
                                                message={loungeNameError}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <level>Allowed No. of attendees</level>
                                            <input type="number"
                                                className="form-control"
                                                placeholder="Enter Number"
                                                maxLength={64}
                                                name="numberofAttendees"
                                                value={numberofAttendees}
                                                onChange={this.networkingLoungeOnChange}
                                            />
                                            <InlineError
                                                message={numberofAttendeesError}
                                            />

                                        </div>
                                    </div>

                                    {/* <div className="col-md-6"> */}
                                        {/* <div className="form-group">
                                            <level>Set Order</level>
                                            <Select
                                                name='setOrder'
                                                // value={setOrder}
                                                placeholder="Select"
                                                onChange={(e) => this.handleOnChange("setOrder", e)}
                                            >
                                                <Option value="1">1</Option>
                                                <Option value="2">2</Option>
                                                <Option value="3">3</Option>
                                                <Option value="4">4</Option>
                                                <Option value="5">5</Option>
                                            </Select>
                                            <InlineError
                                                message={setOrderError}
                                            />
                                        </div> */}
                                    {/* </div> */}
                                </div>

                                <div className="row">
                                <div className="col-lg-6">
                                    <level>Time Zone</level>

<div className="form-group">
    <Select showSearch autoComplete="new-password" placeholder="Time zone"
        value={this.state.timezone}
        defaultValue={this.state.timezone}

        onChange={(e) => this.handleChangeTimeZone(e)} name='country'

    >
        {TimeZone.map((item) => {
            return (<Select.Option value={item}>{item}</Select.Option>)
        })}

    </Select>

</div>


                                    </div>
                                    <div className="col-lg-6">
                                    <level>Start, End date and time</level>
                                        <div className="form-group">
                                            <RangePicker
                                            inputReadOnly
                                                showTime={{ format: 'HH:mm' }}
                                                value={[moment.tz(startDate, this.state.timezone), moment.tz(endDate, this.state.timezone)]}
                                                format="DD-MM-YYYY HH:mm"
                                                onChange={this.onChangeDate}
                                            // onOk={onOk}
                                            />
                                            <InlineError
                                                message={startDateError}
                                            />
                                        </div>


                                    </div>
                                   
                                </div>

                                <div className="row">
                                    {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <level>Add Lounge URL</level>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Lounge URL"
                                                maxLength={500}
                                                name="loungeUrl"
                                                value={loungeUrl}
                                                onChange={this.networkingLoungeOnChange}
                                            />
                                        </div>
                                    </div> */}

                                    {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <level>Allowed No. of attendees</level>
                                            <input type="number"
                                                className="form-control"
                                                placeholder="Enter Number"
                                                maxLength={64}
                                                name="numberofAttendees"
                                                value={numberofAttendees}
                                                onChange={this.networkingLoungeOnChange}
                                            />
                                            <InlineError
                                                message={numberofAttendeesError}
                                            />

                                        </div>
                                    </div> */}
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                       
                                        <level>Cover image</level>
                                        <div className="form-group custom-upload-file">
                                            <ul className="list-inline mt-3 pt-1">
                                                <li className="list-inline-item">
                                                    <div className="upload-inner" onClick={() => this.setState({isCropper: true})} >
                                                        {
                                                            this.state.profilePicPreview ?
                                                                <img onClick={() => this.setState({isCropper: true})} src={this.state.profilePicPreview} />
                                                                : <span className="upload-icon"><UploadVideo /></span>
                                                        }

                                                        {/* <input accept="image/*" ref="fileUploader" type="file"  placeholder="Enter Video Link"
                                                            onChange={this.handleImageUpload}
                                                            className="d-block"

                                                        /> */}
                                                    </div>                                                    
                                                </li>


                                            </ul>
                                        </div>


                                        <div className="add-video">
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <br/>
                                <br/>
                             

                                <div className="row">
                                    <level>Description</level>
                                    <textarea className="textarea form-control" rows="4"
                                        placeholder="Description..."
                                        maxLength={500}
                                        name="description"
                                        value={description}
                                        onChange={this.networkingLoungeOnChange}
                                    />                                 
                                </div>

                                <div className="text-right">
                                    {/* <Link to="/seminardetails" onClick={this.AddNetworkingLounge} className="btn btn-primary">Save</Link> */}
                                    <button type='button' onClick={this.AddNetworkingLounge} className="btn btn-primary">Save</button>
                                </div>
                            </form>



                {this.state.isCropper ?
                    <Croper aspect={2.5 / 2.5}
                        // onImageCrop={img => this.setState({ media: img })}
                        imgPrev={"1-1.jpg"}
                        finalCropedImage={(categoryImgPrev, categoryImg, visibleVal) => this.finalCropedImage(categoryImgPrev, categoryImg, visibleVal)} visibleVal={this.state.isCropper}
                        onClose={() => this.setState({ isCropper: false })}
                    />
                    : ""}


                        </div>

                    </div>
                </div>
            </>
        );
    }
}
export default AddNetworkingLounge;