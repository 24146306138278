import React from 'react'
import { CircleCross } from '../../../../SvgIcon';
import { _Api } from '../../../../services/Api';
import Url from '../../../../services/BaseUrl';
import { notify } from '../../../../components/Toaster';
import InlineError from '../../../../components/InlineError';
// import Select,{ components } from 'react-select';
import { Select } from 'antd'
import { Link } from "react-router-dom"
import { history } from "../../../../routing/index"

import Croper from "../../../../components/Croper";


const { Option } = Select;

const CustomOption = props => {
    const { data, innerRef, innerProps } = props;
    return (
        <div ref={innerRef} {...innerProps} className="custom-select-option">
            {data.label} - <b style={{color:"#5383a2"}}> <i>{data.sectorName}</i> </b>
        </div>
    ) ;
};
let userType = localStorage.getItem("userType")
var _ = require('lodash');
class EditProduct extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            productName: '',
            description: '',
            productImage: '',
            productNameError: '',
            descriptionError: '',
            productImageError: '',
            productCategoryError: '',
            productCategory: '',
            image: '',
            price: '',
            productCategoryList: [],
            isLoading: false,
            isCropper: false,
            productCategoryOption:null,

            isSelected: true,
            manageCategoriesId: [],
            sectorList: [],
            sectorId: [],
            isChecked:false,
            sectorError:''
        }
    }
    productOnChnage = (e) => {
        this.setState({ [e.target.name]: e.target.value, productNameError: '', descriptionError: '' })
    }
    handleOnChange = (e, data) => {
        console.log("kjhgfsdghjkl", e, data)
        if (e == -1) {
            // history.push("/manage-services")
            // window.location.reload
            if (userType == 1) {
                window.location.href = `/edit-sectors-categories/${this.props.match.params.brandId}`;

            }
            else
                window.location.href = '/edit-sectors-categories';
            // this.props.closePopup()
        }
        else
            this.setState({sectorId:[], productCategory: e , productCategoryError: '', isSelected: true, sectorList: data?.sector, sectorError:'' })
    }

    handleOnChangeOnSector = (e, ) => {
        this.setState({ sectorId: e, sectorError:'' })
    }

    getParticularViewProduct = () => {
        _Api(Url.VIEW_PARTICULAR_PRODUCT.method, Url.VIEW_PARTICULAR_PRODUCT.url, '', `?productId=${this.props.productId}`)
            .then(resp => {
                console.log('reaERTYp', resp.responseData.result.productCategory)
                // productCategory
                let params = resp?.responseData?.result
                let option = resp?.responseData?.result?.manageCategory
                this.setState({ isChecked : params?.isNewProduct == 1 ? true : false, price: params.price, productName: params.productName, description: params.description, productCategory: params.manageCategory, profilePicPreview: params.image })
                this.setState({
                    productCategoryOption:{ value:params.manageCategory, label: `${params.categoryName}` ,sectorName: `${params.sectorName}`,custom:true },
                   

                },()=> {
                    setTimeout(()=>{
                       
                       let categories =  this.state.manageCategoriesId.filter(item=> item?.id?._id == option )[0]   
                        // console.log("pkjhbnjkl", this.state.manageCategoriesId)
                        
                       if(categories){
                           this.setState({sectorList: categories?.sectorId,  sectorId:params?.manageSector})
                       }
                        
                    }, 300)
                })
               
            }).catch(err => {
                this.setState({ isLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
                this.setState({ isLoader: false })
            })
    }
    componentDidMount() {
        document.body.style.overflow = 'hidden';
        this.getCategory("4", "productCategoryList");
        this.getParticularViewProduct()
       
    }
    componentWillUnmount() {
        document.body.style.overflow = 'auto';
    }
    editProduct = () => {
        let {sectorId, productName, description, productImage, productCategory } = this.state
        if (productName.trim() === '')
            this.setState({ productNameError: '*Please enter product name.' })
        else if (productCategory === '')
            this.setState({ productCategoryError: '*Please select product category.' })
            else if (sectorId.length == 0)
            this.setState({ sectorError: '*Please select sector.' })
        // else if (description === '')
        //     this.setState({ descriptionError: '*Please enter description.' })
        else {
            this.setState({ isLoading: true })
            let formData = new FormData()
            formData.append('productId', this.props.productId)
            formData.append('productName', productName)
            formData.append('brandId', this.props.match.params.brandId)            
            formData.append('description', description)
            formData.append('image', productImage)
            formData.append('productCategory', productCategory ? productCategory : '')
            formData.append('manageCategory', productCategory)
            formData.append('manageSector', this.state.sectorId.toString())
            formData.append('isNewProduct', this.state.isChecked ? 1 : 2)
            _Api(Url.EDIT_PRODUCT.method, Url.EDIT_PRODUCT.url, formData)
                .then(resp => {
                    console.log('reap', resp)
                    this.props.closePopup()
                    this.props.editSuccess()
                    notify('success', resp.responseData.message)
                    this.setState({ isLoading: false })
                }).catch(err => {
                    this.setState({ isLoading: false })
                    if (err.error)
                        notify("err", err.error.responseMessage)
                   // else if (err)
                //     // notify("err", err.message)
                    this.setState({ isLoading: false })
                })
        }
    }

    handleImageUpload = (event) => {
        console.log(event, "eventeventevent", event.target.files[0])
        let reader = new FileReader();
        let file = event.target.files[0];
        let { valiDate } = this.state;
       
        if (file) {
            reader.readAsDataURL(file)
        }
        if (file) {
            console.log(event, "media1", event.target.files[0])
            if (file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png' || file.type === 'image/svg+xml') {
                console.log("media2", event.target.files[0], file.size)
                reader.addEventListener("load", () => {
                    this.setState({ profilePicPreview: reader.result }, function () {
                    })
                }, false);
                if (file.size > 2000000) {
                    console.log("lkjhgfdsdfghjklkjhgfghjkmnbvccvbnm>>>>>>>>>>>>>>>>>>")
                    valiDate = false;
                    console.log(event, "media", event.target.files[0])
                    this.setState({
                        productImage: event.target.files[0],
                        profilePicErrorMsg: 'Profile pic size should be less than 5Mb.'
                    });
                } else {
                    console.log("media3hhhjshs", event.target.files[0], file.size)
                    this.setState({
                        productImage: event.target.files[0],
                        productImageError: ''
                    });
                }
            } else {
                valiDate = false;
                this.setState({ media: 'File type not supported.' })

            }
        }
    }









    getCategory = (categoryType, keyName) => {
        let params = {
            categoryType: categoryType,
        }
        _Api(Url.GET_PRODUCT_CATEGORY.method, Url.GET_PRODUCT_CATEGORY.url,'',`?requestId=${this.props.match.params.brandId}`)
            .then(resp => {
                console.log('responseData', resp.responseData);
                this.setState({productCategoryList: resp.responseData?.result?.servicesShowcaseId,  manageCategoriesId: resp.responseData?.result?.manageCategoriesId })
                
            }).catch(err => {
                this.setState({ isLoader: false })              
            })
    }

    finalCropedImage = (categoryImgPrev, categoryImg, visibleVal) => {       
        this.setState({
            visibleVal,
            isCropper: false,
            profilePicPreview: categoryImgPrev,
            productImage: categoryImg

        })
    }

      //for check box
  handleChangedChecked = () => {
    this.setState({ isChecked: !this.state.isChecked })
}


    render() {
        console.log('nkajsndjkasndkjsandk', this.state.sectorList)
        let {sectorError, sectorId, sectorList, manageCategoriesId, productName, description, productNameError, descriptionError, productCategoryError, productCategory,productCategoryOption } = this.state
        let productData = [];
        let sector = []
        console.log("kjhgfcdghjk", sectorId)
        {
            manageCategoriesId[0] ? manageCategoriesId.map((item, index) => {
                return (
                    productData.push({ value: item.id._id, label: `${item.id.name}`, sector: item.sectorId, custom: true })
                )
            })
                :
                productData.push(
                    { value: -1, label: "Please manage your services" }
                )
        }
        {
            sectorList.map((item, index) => {
                return (
                    sector.push({ value: item._id, label: `${item.name}` })
                )
            })

        }

        return (
            <div className='popup addproduct-popup'>
            <div className='popup_inner form'>
                <div className="mb-4 mt-4">
                    <div className="rounded-textbox-form">
                        <div className="header-text">
                            <h4>Edit Job</h4>
                        </div>
                        <div className="row mt-4">
                        <div className="col-md-6 mb-5">

                        <level>Job Image </level>  
                        <label className="mb-0 mt-3"><span className="popupdimention">
                             {/* (.jpeg, .png, .jpg, Image dimension: 270 * 300 )  */}
                             </span></label>  
                        <div  className="upload-wrapper product mt-1" onClick={()=> this.setState({isCropper: true})}>                                                        
                            <label className="upload-photo-wrapper upload-container-pic rectangle">
                            

                          
                            {this.state.profilePicPreview ? <span> <img className="img-fluid" src={this.state.profilePicPreview} /></span> 
                             : 
                            <>    <span> <img className="img-fluid" src={require('../../../../assets/images/placeholder.png')} /></span>
                            </> 
                            }
                                    
                            </label>             

                        </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <level>Job Name</level>
                                <input type="text"
                                    className="form-control"
                                    placeholder="Job Name"
                                    maxLength={64}
                                    autoComplete="off"
                                    name="productName"
                                    value={productName}
                                    onChange={this.productOnChnage}
                                />
                                <InlineError
                                    message={productNameError}
                                />

                            </div>
                            <div className="form-group d-flex align-items-center">
                                <p className="ml-4">
                                    <div className="check_wrapper">
                                        <input type="checkbox"
                                            value="option2"
                                            checked={this.state.isChecked}
                                            onChange={this.handleChangedChecked}
                                             />
                                            <span />
                                    </div>
                                    Mark as new Job ?
                                 </p>
                            </div>
                            <div className="form-group">
                                <level>Job Category</level>

                                <Select
                                    name='selectType'

                                    value={productCategory}
                                    placeholder='Select'
                                    onChange={(e, data) => this.handleOnChange(e, data)}
                                    components={{ Option: CustomOption }}
                                    options={productData}
                                >

                                </Select>
                                <InlineError
                                    message={productCategoryError}
                                />
                            </div>
                            {this.state.isSelected && <div className="form-group">
                                <level>Select Sector</level>

                                <Select
                                    name='selectType'
                                    mode="multiple"
                                    value={sectorId}
                                    placeholder='Select Sector'
                                    onChange={(e) => this.handleOnChangeOnSector(e)}
                                    components={{ Option: CustomOption }}
                                    options={sector}
                                    className="cm_multiselect cmslect_multicheck"
                                >

                                </Select>
                                <InlineError
                                    message={sectorError}
                                />
                            </div>}


                            {/* <div className="form-group">
                                <level>Price</level>
                                <input type="number"
                                       className="form-control"
                                       placeholder="Price"
                                       maxLength={64}
                                       onChange={this.productOnChnage}
                                       name="price"
                                       value = {price}

                                />
                                <InlineError
                                    message={productNameError}
                                />

                            </div> */}
                            <div className="form-group">
                                <level>Description</level>
                                <textarea type="text"
                                    className="form-control textarea"
                                    placeholder="Description"
                                    rows="5"
                                    maxLength={500}
                                    autoComplete="off"
                                    name="description"
                                    value={description}
                                    onChange={this.productOnChnage}
                                />
                                {/* <InlineError
                                    message={descriptionError}
                                /> */}
                            </div>

                            <div className="text-center pt-4">
                                <button className="btn btn-red" disabled={this.state.isLoading} onClick={this.editProduct}>Save</button>
                            </div>
                            </div>
                         
                               
                        </div>
                            <button className="close-btn" onClick={this.props.closePopup}><CircleCross /></button>


                        </div>
                    </div>
                </div>
                {this.state.isCropper ?
                    <Croper aspect={2.5 / 3}
                        // onImageCrop={img => this.setState({ media: img })}

                            imgPrev={'2.5-3.jpg'}
                        finalCropedImage={(categoryImgPrev, categoryImg, visibleVal) => this.finalCropedImage(categoryImgPrev, categoryImg, visibleVal)} visibleVal={this.state.isCropper}
                        onClose={() => this.setState({ isCropper: false })}
                    />
                    : ""}
            </div>
        );
    }
}

export default EditProduct;