import React, { Component } from 'react';
class profilebody extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
        }
      
    }
    render() {
        
        return (
            <>
            <h1>Lorem Chatbody</h1>
            </>
        );
    }
}
export default profilebody;