import React, { Component } from 'react';


import { Carousel  } from 'antd';
import {Delete, Chat , ZoomVideo } from "../../SvgIcon";
import {Link} from "react-router-dom";


class RecentContacted extends Component {


    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.carousel = React.createRef();
    }


    next = () => {
        this.carousel.next();
    }
    previous = () => {
        this.carousel.prev();
    }

    render() {

        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1
            // responsive: [
            //     {
            //         breakpoint: 1350,
            //         settings: {
            //             slidesToShow: 4,
            //             slidesToScroll: 1,
            //             infinite: true,
            //             dots: false
            //         }
            //     },
            //     {
            //         breakpoint: 991,
            //         settings: {
            //             slidesToShow: 3,
            //             slidesToScroll:1
            //         }
            //     },
            //     {
            //         breakpoint: 600,
            //         settings: {
            //             slidesToShow: 1,
            //             slidesToScroll: 1
            //         }
            //     }
            // ]

        }


        let list =  this.props.recentConnections
        return (


            <div className="carousel-wrapper">
                <i class="fa fa-long-arrow-left" onClick={this.previous}/>

                <Carousel className="row d-flex" ref={node => (this.carousel = node)} {...settings} >
                    {
                        list.map(index => {
                            return (

                                <div className="card recent-contract-card">
                                    <div className="img-wrapper">
                                        <img className="img-fluid" src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcR04Y9YQ59uATLECEfA605xY3za-yNLbSRtRA&usqp=CAU" />
                                    </div>
                                    <div className="texts d-flex align-items-center flex-column">
                                        <h4>Jeennifer Moore</h4>
                                        <p>Sales Technical Lead Loreal Paris</p>
                                       <div className=" btn-combo">
                                       <button className="btn btn-blue" ><span className="zoom"><ZoomVideo/></span>Connect</button>
                                        <Link className="chat-btn"> <span className="chat"><Chat/></span></Link>
                                       </div>

                                    </div>
                               </div>                   
                            )
                        })

                    }

                </Carousel>

                <i class="fa fa-long-arrow-right" onClick={this.next}/>
            </div>


        )

    }
}
export default RecentContacted;

const loop = [0, 1, 2, 3, 4, 5, 6, 7]

