import {
  TOGGLE_QUESTION
} from "../action/setting.actions";

const initialSettings = {
  eventQuestionnaire:false,
}

export function setting(state = initialSettings, action) {

    switch (action.type) {

      case TOGGLE_QUESTION:
        let eventQuestionnaire=!state.eventQuestionnaire;
        if(action.value){
          if(action.value===1) {
            eventQuestionnaire = true
          }else{
            eventQuestionnaire = false
          }
        }
        return Object.assign({},initialSettings,{eventQuestionnaire:eventQuestionnaire});

      default:
        return state
  }
}

