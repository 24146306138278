import { BtnRed, CmBtn } from "../../../components/common/Button";
import { ZoomVideo, Chat, NoMediaICon, BusinessCard } from "../../../SvgIcon";
import React, { Component } from 'react';
import WebHeader from '../../layout/WebHeader';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';
import { Link } from "react-router-dom";
import util from '../../../utils/Util';
import { Menu, Dropdown, Button, Empty } from 'antd';
import { MdLoader } from '../../../components/index';
import { Modal } from 'antd';
import userProfile from './CommunityUserProfile.js';
import ReportModal from './ReportUserModal';
import { groupActions } from "../../../redux/action";
import { connect } from "react-redux";
import { Spinner } from "react-bootstrap";
import socket from "../../../services/socket";
import { Pagination } from "../../../components";
import { MySpinner } from "../../../components/index"


var _ = require('lodash');
const myWindow = window;

class Connections extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requestList: null,
            pendingList: null,
            connectionList: [],
            suggestedList: [],
            blockList: [],
            isReport: false,
            videoConnectionLoder: false
        }
    }
    componentDidMount() {
        this.getConnection();

        let self = this


        socket.on('connect', function (data) {
        });

        socket.on('online', function (params) {
            console.log("onlineeeeeeeeeeeee", params);
            // self.changeUserStatus(params.user._id, 1);
        })

        socket.on('offline', function (params) {
            console.log("offline", params);
            // self.changeUserStatus(params.user._id, 0);
        })


    }

    changeUserStatus(userId, status) {
        let self = this;
        let users = this.state.connectionList
        var checkUser = _.find(users, function (obj) {
            return obj.userData._id === userId;
        });

        if (checkUser) {
            checkUser.userData.isOnline = status;
            let userConnection = this.state.connectionList
            var index = _.findIndex(userConnection, { _id: checkUser._id });
            //  userConnection[index].userData =  checkUser 
            self.setState({
                connectionList: userConnection
            })
            // console.log("sdafhgjaskld", {userConnection})

            // 
        }

        // console.log("sdafhgjaskld", {checkUser, users, })
    }




    componentWillUpdate(props) {
        // console.log("dshgdhasbjmk", props.isRefreshed, this.props.isRefreshed)
        if (props.isRefreshed != this.props.isRefreshed)
            this.getConnection();
    }


    getConnection() {
        let req = {
            page: 1,
            limit: 12,
            connectionType: 1

        }

        let url = Url["GET_ALL_CONNECTION"].url, method = Url["GET_ALL_CONNECTION"].method;
        _Api(method, url, req)
            .then(resp => {
                //this.setState({ [stateData]: resp.responseData?.result?.record })

                this.setState({
                    blockList: resp?.responseData?.blockList.record,
                    connectionList: resp?.responseData?.connectionList,
                    requestList: resp?.responseData?.receiveList.record,
                    pendingList: resp?.responseData?.pendingConnection.record,
                    suggestedList: resp?.responseData?.suggestionList?.result.record


                })
            }).catch((err) => {
                this.handleError(err);
            });

    }

    handleError(err) {
        let errorMessage = err.error && err.error.responseMessage || err.message;
        notify("err", errorMessage);
    }
    onAcceptReject(e, requestId, status) {
        e.preventDefault();
        let req = {
            requestId: requestId,
            status: status
        }
        _Api(Url.COMMUNITY_ACCEPTREJECT.method, Url.COMMUNITY_ACCEPTREJECT.url, req)
            .then(resp => {
                this.getConnection();
            }).catch((err) => {
                this.handleError(err);
            })

    }
    onMoreClick = (e, id, userId, a, event, data) => {
        switch (e.key) {
            case "Remove":
                this.onRemoveAndBlock("COMMUNITY_CONNECTION_REMOVE", 3, id, event.domEvent);
                break;
            case "Block":
                this.onRemoveAndBlock("COMMUNITY_CONNECTION_BLOCK", 1, id, event.domEvent);
                break;
            case "Report":
                this.showModal(id, data, event.domEvent);
                break;
            case "AddBusinessConnection":
                this.onCreateBussinessRequest(userId);
                break;
            default:
                return null;


        }
    }
    onRemoveAndBlock = (api, status, id, e) => {
        this.setState({ [`connection${id}`]: true });
        e.preventDefault();
        let req = {
            requestId: id,
            status: status
        }
        _Api(Url[api].method, Url[api].url, req)
            .then(resp => {
                this.setState({ [`connection${id}`]: false });
                this.getConnection();
            }).catch((err) => {
                this.handleError(err);
            })

    }
    // ----------download csv-------------
    downloadCsv = (req) => {
        let reqq = {
            otherUserId: localStorage.getItem('id'),
            type: 5,
            exhibitionId: localStorage.getItem('exhibition')
        }
        _Api(Url.CHECK_PLAN_USER.method, Url.CHECK_PLAN_USER.url, reqq)
            .then(resp => {
                const { isBuy } = resp?.responseData
                if (isBuy == 0) {
                    this.props.dispatch({ type: 'SHOW_PLAN_USER_POPUP' })

                }
                else {
                    _Api(Url.DOWNLOAD_CSV.method, Url.DOWNLOAD_CSV.url, req)
                        .then(resp => {
                            console.log("check response", resp)
                            window.open(resp.responseData.result, "_blank");
                        }).catch((err) => {
                            this.handleError(err);
                        })
                }

            }).catch(err => { })
    }

    showModal = (id, data, e) => {
        e.preventDefault();
        this.setState({
            isReport: true,
            userData: Object.assign(data, id)
        });
    };

    onCreateBussinessRequest = (id) => {
        this.setState({ [`connection${id}`]: true })
        let req = {
            requestedUserId: id,
            connectionType: 2
        }
        _Api(Url.COMMUNITY_CREATEREQUEST.method, Url.COMMUNITY_CREATEREQUEST.url, req)
            .then(resp => {
                this.getConnection();
                notify("success", resp.responseData.message);
                this.setState({ [`connection${id}`]: false })
            }).catch((err) => {
                this.handleError(err);
            })
    }
    onConnectRequest(e, requestId, status) {
        this.setState({ [`connection${requestId}`]: true });
        e.preventDefault();
        let req = {
            requestedUserId: requestId,
            connectionType: 1
        }
        _Api(Url.COMMUNITY_CREATEREQUEST.method, Url.COMMUNITY_CREATEREQUEST.url, req)
            .then(resp => {
                this.getConnection();
                notify(resp.responseData.message);
                this.setState({ [`connection${requestId}`]: false })
            }).catch((err) => {
                this.handleError(err);
            })

    }

    onClickConnection = (id, user_id, e, isFromRequested = false) => {
        let props = this.props.scope.props;
        // if (isFromRequested)
        props.history.push(`/user/profile/${user_id}`)

        // else { props.history.push(`/connection-profile/${user_id}/connection/${id}`) }
    }

    videoCalling = (userId) => {
        let self = this;
        var windowReference = window.open();
        if (userId) {
            self.setState({
                ['videoConnectionLoder_' + userId]: true
            })
            self.props.dispatch(groupActions.joinOneMeeting({ userId: userId, type: 7 }))
                .then(function (res) {
                    self.setState({
                        ['videoConnectionLoder_' + userId]: false
                    })
                    if (res.statusCode === 1 || res.statusCode === "1") {
                        var newWin = window.open('/calling/' + self.props.groupDetail.meeting._id, "_blank", "width=" + window.screen.width + ",height=" + window.screen.height)
                        console.log("newWin", newWin);
                        if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
                            windowReference.location = '/calling/' + self.props.groupDetail.meeting._id;
                            //alert("New windows pop-up is blocked, Please allow.")
                        }
                    } else {

                    }
                    // console.log("myWindow", res)
                })
        }
    }

    selectUser = (user) => {

        let self = this;
        // console.log("user",user);
        if (this.props.userDetail._id !== user._id) {
            this.setState({
                selectedUser: user,
                selectedUserId: user._id,
                chatType: 3,
                isShow: true
            }, function () {
                self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 3 })
                self.props.dispatch({ type: 'CHAT_OPEN' })
                document.body.classList.toggle('chat_open_mob');
                self.props.dispatch({ type: 'SELECTED_USER', selectedUser: user })
                socket.emit('groupAdd', { type: 7, userId: user._id });
                //socket.emit('userConnection', {userId: user._id});
            })
        }

    }

    render() {
        if (this.state["requestList"]?.length > 0 || this.state["pendingList"]?.length > 0 || this.state["connectionList"]?.length > 0 || this.state["suggestedList"]?.length > 0 || this.state["blockList"]?.length > 0) {
            this.props.filterHideShow(true);
        }
        else {
            this.props.filterHideShow(false);
        }
        const { connectionFilter } = this.props.appLocalState;
        console.log("poidsuayghb", connectionFilter)
        const { all, myConnection, requestConnection, suggestionConnection, pendingConnection, blockConnection } = connectionFilter;

        let { requestList, pendingList, connectionList, suggestedList, blockList } = this.state;
        let selectedItem = this.props.selectedItem;
        const Communtiy = (props) => (<Menu onClick={(e) => this.onMoreClick(e, props.Id, props.userId, "", e, props.data)} >
            {props.isRemove && <Menu.Item key="Remove"> Remove Connection</Menu.Item>}
            <Menu.Item key="Block">Block</Menu.Item>
            <Menu.Item key="Report">Report</Menu.Item>
            {/* {props.isBusinessRequest && < Menu.Item key="AddBusinessConnection">Send business request</Menu.Item>} */}
        </Menu>)

        console.log("abcdefgh", connectionList)
        return (
            <div className="brandhome cummunity" >
                {/*{this.state.isCommingSoon && <CommingSoonLayer />}*/}

                {/* {
                    console.log("dsahgdhjks", requestConnection, pendingConnection)
                } */}


                {

                    (pendingConnection && requestList && requestList.length != 0 || all || pendingConnection) && !!requestList && <div className="cm-wrapper">
                        <div className="header-text">

                            <h1 className="title"> Received Invitations</h1>
                            {requestList && <BtnRed to="view-requested-connections" text="View All" />}
                        </div>

                        <div className="row">
                            {requestList && requestList.map((item, index) => {
                                return (

                                    <div className="four-div">
                                        <div className="card community-card connection-parent">
                                            <div className="connection-detail-parent">
                                                <div className=" imgparent pr-0">
                                                    <div className="img-wrapper pos-relative image-chat">
                                                        <img className="img-fluid cursor-point" onClick={(e) => this.onClickConnection(item?._id, item?.userData?._id, e, true)} src={item?.userData?.image || require('../../../assets/images/login_user.png')} />
                                                    </div>
                                                </div>
                                                <div className=" textparent no-gutter d-flex align-items-start flex-column">
                                                    <h4 className={"cursor-point"} onClick={(e) => this.onClickConnection(item?._id, item?.userData?._id, e, true)}><strong>{util.getTitleCase(item?.userData?.firstName)} {util.getTitleCase(item?.userData?.lastName)}</strong></h4>
                                                    <span><small>{item?.userData?.JobTitle}</small></span>
                                                    <h5>
                                                        {item?.userData?.companyName}
                                                    </h5>
                                                    <div className="d-block">
                                                        <div className="d-flex justify-content-start">
                                                            <CmBtn text=" Accept" className="btn btn-blue mr-2" bg='var(--btncolor)' onClick={(e) => this.onAcceptReject(e, item?._id, 1)} >{this.state[`connection${item?._id}`] && <MySpinner
                                                            />}</CmBtn>
                                                            &nbsp;&nbsp; <CmBtn text="Ignore" className="btn btn-blue" bg="#fff" color="#A3AAB0" border="1px solid #91cbd3" onClick={(e) => this.onAcceptReject(e, item?._id, 2)} >{this.state[`connection${item?._id}`] && <MySpinner
                                                            />}</CmBtn>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="top-right">
                                                <Dropdown overlayClassName="more-dropdown-parent" overlay={<Communtiy Id={item?._id} data={item?.userData} trigger="click" />
                                                } placement="bottomLeft">
                                                    <i className="fa fa-ellipsis-v"></i>
                                                </Dropdown>

                                            </div>

                                        </div>

                                    </div>
                                )
                            })
                            }

                            {requestList?.length == 0 || requestList == undefined && <div className="w-100 text-center row nodata m-auto">
                                <div className="m-auto">
                                    <NoMediaICon />

                                    <h2>No Data Found</h2>
                                </div>
                            </div>

                            }
                        </div>
                    </div>
                }
                {(myConnection || all) &&

 localStorage.getItem("userType") == 0 ? 
                < div className="cm-wrapper" >
                    <div className="header-text">

                        <div className="d-flex align-items-center">
                        <h1 className="title cap">My Connections</h1>
                            {connectionList.length != 0 && <i class="fa fa-download" style={{ fontSize: '30px', marginLeft: '30px', cursor: 'pointer' }} aria-hidden="true" title="Download csv" onClick={(e) => this.downloadCsv()}></i>}
                        </div>
                        {connectionList.length > 2 && <BtnRed to="view-connections" text="View All" />

                        }

                    </div>
                    <div className="row">
                        {connectionList && connectionList.map((item, index) => {
                            const { userData } = item
                            return (
                                <div className="four-div">
                                    <div className="card community-card connection-parent">
                                        <div className="connection-detail-parent">
                                            <div className=" imgparent pr-0">
                                                <div className="img-wrapper pos-relative image-chat">
                                                    <div className="P" >
                                                        <img className="img-fluid cursor-point" onClick={(e) => this.onClickConnection(item?._id, item?.userData?._id, false)} src={item?.userData?.image || require('../../../assets/images/login_user.png')} />
                                                        <span className={`chat ${userData.isOnline == 1 ? 'green' : 'yellow'}`} onClick={() => this.selectUser(item?.userData)} ><Chat /></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" textparent no-gutter d-flex align-items-start flex-column">

                                                <h4 className={"cursor-point"} onClick={(e) => this.onClickConnection(item?._id, item?.userData?._id, false)}><strong>{util.getTitleCase(item?.userData?.firstName)} {util.getTitleCase(item?.userData?.lastName)}</strong></h4>

                                                <span><small>{item?.userData?.JobTitle}</small></span>
                                                <div className="logo-wrapper">

                                                    {item?.userData?.companyName}

                                                </div>
                                                <span className="action_group  d-flex align-items-center">
                                                    <button className="btn btn-blue" onClick={() => this.videoCalling(item?.userData?._id)} >
                                                        <span className="zoom"><ZoomVideo /></span>Video Call {this.state['videoConnectionLoder_' + item?.userData?._id] &&
                                                            <MySpinner
                                                            />}</button>


                                                </span>
                                                {/* {item.isBusinessConnection != "2" &&

                                                    <button className="btn btn-blue width-auto businesscard" onClick={(e) => this.onCreateBussinessRequest(item?.userData?._id)}>
                                                        Send business request
                                                        {this.state[`connection${item?.userData?._id}`] &&
                                                            <MySpinner />}
                                                    </button>
                                                } */}
                                            </div>

                                            <div className="top-right">
                                                <Dropdown overlayClassName="more-dropdown-parent" overlay={<Communtiy Id={item?._id} userId={item?.userData?._id} isRemove={true} isBusinessRequest={item.isBusinessConnection != "2"} data={item?.userData} trigger="click" />
                                                } placement="bottomLeft">
                                                    <i className="fa fa-ellipsis-v"></i>
                                                </Dropdown>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )
                        })
                        }

                        {(connectionList?.length == 0) && <div className="w-100 text-center row nodata m-auto">
                            <div className="m-auto">
                                <NoMediaICon />

                                <h2>You Haven't made any connections yet.</h2>
                                <Link to="/community/view/page=1" className="btn btn-primary">Find People</Link>
                            </div>                    </div>
                        }




                    </div>
                </div>
                
                :

                    < div className="cm-wrapper" >
                      
                            {connectionList && connectionList.map((item, index) => {
                                const { userData, connection } = item
                                return (
                                    <>
                                        <div className="header-text">

                                            <div className="d-flex align-items-center">
                                            <h1 className="title cap">{index == 0 ? 'My Connections' : `${item.firstName} ${item.lastName} Connections`}</h1>
                                                {index == 0 && connection.length != 0 && <i class="fa fa-download" style={{ fontSize: '30px', marginLeft: '30px', cursor: 'pointer' }} aria-hidden="true" title="Download csv" onClick={(e) => this.downloadCsv()}></i>}
                                            </div>
                                            {connection.length > 2 &&( index == 0 ? <BtnRed to={`view-connections`} text="View All" />  : <BtnRed to={`view-connections/${item._id}`} text="View All" /> )

                                            }

                                        </div>
                                        <div className="row">
                                       {connection.length == 0 && <div className="w-100 text-center row nodata m-auto">
                                    <div className="m-auto">
                                        <NoMediaICon />

                                        <h2>No Data Found</h2>
                                    </div>                    </div>}

                                        {
                                            
                                            connection.map((item2, index2)=>{
                                                const { userData } = item2
                                                return(
                                                    <div className="four-div">
                                                    <div className="card community-card connection-parent">
                                                        <div className="connection-detail-parent">
                                                            <div className=" imgparent pr-0">
                                                                <div className="img-wrapper pos-relative image-chat">
                                                                    <div className="P " >
                                                                        <img className="img-fluid cursor-point" onClick={(e) => this.onClickConnection(item2?._id, item2?.userData?._id, false)} src={item2?.userData?.image || require('../../../assets/images/login_user.png')} />
                                                                        <span className={`chat ${userData.isOnline == 1 ? 'green' : 'yellow'}`} onClick={() => this.selectUser(item2?.userData)} ><Chat /></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="textparent no-gutter d-flex align-items-start flex-column">        
                                                                <h4 className={"cursor-point"} onClick={(e) => this.onClickConnection(item2?._id, item2?.userData?._id, false)}><strong>{util.getTitleCase(item2?.userData?.firstName)} {util.getTitleCase(item2?.userData?.lastName)}</strong></h4>
        
                                                                <span><small>{item2?.userData?.JobTitle}</small></span>
                                                                <div className="logo-wrapper">
        
                                                                    {item2?.userData?.companyName}
        
                                                                </div>
                                                                <span className="action_group  d-flex align-items-center">
                                                                    <button className="btn btn-blue" onClick={() => this.videoCalling(item2?.userData?._id)} >
                                                                        <span className="zoom"><ZoomVideo /></span>Video Call {this.state['videoConnectionLoder_' + item2?.userData?._id] &&
                                                                            <MySpinner
                                                                            />}</button>
        
        
                                                                </span>
                                                                {/* {item.isBusinessConnection != "2" &&
        
                                                            <button className="btn btn-blue width-auto businesscard" onClick={(e) => this.onCreateBussinessRequest(item?.userData?._id)}>
                                                                Send business request
                                                                {this.state[`connection${item?.userData?._id}`] &&
                                                                    <MySpinner />}
                                                            </button>
                                                        } */}
                                                            </div>
        
                                                          {index == 0 &&  <div className="top-right">
                                                                <Dropdown overlayClassName="more-dropdown-parent" overlay={<Communtiy Id={item2?._id} userId={item2?.userData?._id} isRemove={true} isBusinessRequest={item.isBusinessConnection != "2"} data={item?.userData} trigger="click" />
                                                                } placement="bottomLeft">
                                                                    <i className="fa fa-ellipsis-v"></i>
                                                                </Dropdown>
        
                                                            </div>}
                                                        </div>
                                                    </div>
        
                                                </div>
                                                )

                                            })
                                        }     
                                         </div>                                  
                                    </>
                                )
                            })
                            }

                            {connectionList.length == 0 && <div className="w-100 text-center row nodata m-auto">
                                <div className="m-auto">
                                    <NoMediaICon />

                                    <h2>You Haven't made any connections yet.</h2>
                                    <Link to="/community/view/page=1" className="btn btn-primary">Find People</Link>
                                </div>                    </div>
                            }




                       

                    </div>
                } {
                    (requestConnection || all) && !!pendingList && <div className="cm-wrapper">

                        < div className="header-text">
                            <h1 className="title">Sent Invitations</h1>
                            {pendingList && <BtnRed to="view-pending-connections" text="View All" />}
                        </div>

                        <div className="row">

                            {pendingList && pendingList.map((item, index) => {
                                return (

                                    <div className="four-div">
                                        <div className="card community-card connection-parent">

                                            <div className="connection-detail-parent">
                                                <div className=" imgparent pr-0">
                                                    <div className="img-wrapper pos-relative image-chat">
                                                        <img onClick={(e) => this.onClickConnection(item?._id, item?.userData?._id, false)} className="img-fluid cursor-point" src={item?.userData?.image || require('../../../assets/images/login_user.png')} />
                                                    </div>
                                                </div>
                                                <div className=" textparent no-gutter d-flex align-items-start flex-column">
                                                    <h4 className={"cursor-point"} onClick={(e) => this.onClickConnection(item?._id, item?.userData?._id, false)}><strong>{util.getTitleCase(item?.userData?.firstName)} {util.getTitleCase(item?.userData?.lastName)}</strong></h4>
                                                    <span><small>{item?.userData?.JobTitle}</small></span>
                                                    <h5>
                                                        {item?.userData?.companyName}
                                                    </h5>
                                                    <CmBtn text="Cancel Request" className="btn btn-blue width-auto" bg='var(--connectBtn)' onClick={(e) => this.onRemoveAndBlock("COMMUNITY_CONNECTION_REMOVE", 3, item?._id, e)} >{this.state[`connection${item?._id}`] && <MySpinner
                                                    />}</CmBtn>
                                                </div>
                                                <div className="top-right">
                                                    <Dropdown overlayClassName="more-dropdown-parent" overlay={<Communtiy Id={item?._id} data={item?.userData} trigger="click" />
                                                    } placement="bottomLeft">
                                                        <i className="fa fa-ellipsis-v"></i>
                                                    </Dropdown>

                                                </div>
                                            </div>

                                        </div>


                                    </div>
                                )
                            }) || <div className="w-100 text-center row nodata m-auto">
                                    <div className="m-auto">
                                        <NoMediaICon />

                                        <h2>No Data Found</h2>
                                    </div>                    </div>
                            }

                        </div>

                    </div>

                } {
                    ((suggestionConnection || all) && suggestedList?.length > 0) && <div className="cm-wrapper">
                        <div className="header-text">
                            <h1 className="title">Suggested Connections</h1>
                            {suggestedList?.length != 0 && < BtnRed to="view-suggested-connections" text="View All" />}
                        </div>

                        <div className="row">

                            {suggestedList.map((item, index) => {
                                return (

                                    <div className="four-div">
                                        <div className="card community-card connection-parent">
                                            <div className="connection-detail-parent">
                                                <div className=" imgparent pr-0">
                                                    <div className="img-wrapper pos-relative image-chat">
                                                        <img onClick={(e) => this.onClickConnection(item?._id, item?._id, false)} className="img-fluid cursor-point" src={item?.userData?.image || require('../../../assets/images/login_user.png')} />

                                                    </div>
                                                </div>
                                                <div className=" textparent no-gutter d-flex align-items-start flex-column">
                                                    <h4 className={"cursor-point"} onClick={(e) => this.onClickConnection(item?._id, item?._id, false)} ><strong>{util.getTitleCase(item?.userData?.firstName)} {util.getTitleCase(item?.userData?.lastName)}</strong></h4>

                                                    <span><small>{item?.userData?.JobTitle}</small></span>
                                                    <h5>
                                                        {item?.userData?.companyName}
                                                    </h5>
                                                    <CmBtn text="Connect" className="btn btn-blue width-auto" bg='var(--btncolor)' onClick={(e) => this.onConnectRequest(e, item?.userData?.userId)} >

                                                        {this.state[`connection${item?.userData?.userId}`] && <MySpinner
                                                        />}</CmBtn>
                                                </div>
                                                <div className="top-right">
                                                    <Dropdown overlayClassName="more-dropdown-parent" overlay={<Communtiy Id={item?._id} data={item?.userData} trigger="click" />
                                                    } placement="bottomLeft">
                                                        <i className="fa fa-ellipsis-v"></i>
                                                    </Dropdown>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                )
                            }) || <div className="w-100 text-center row nodata m-auto">
                                    <div className="m-auto">
                                        <NoMediaICon />

                                        <h2>No Data Found</h2>
                                    </div>                    </div>
                            }

                        </div>
                    </div>

                }




                {
                    (blockConnection || all) && <div className="cm-wrapper">
                        <div className="header-text">

                            <h1 className="title">Blocked Connections</h1>
                            {blockList && <BtnRed to="view-block-connections" text="View All" />}
                        </div>

                        <div className="row">
                            {blockList && blockList.map((item, index) => {
                                return (

                                    <div className="four-div">


                                        <div className="card community-card connection-parent">
                                            <div className="connection-detail-parent">
                                                <div className=" imgparent pr-0">
                                                    <div className="img-wrapper pos-relative image-chat">
                                                        <img className="img-fluid cursor-point" src={item?.userData?.image || require('../../../assets/images/login_user.png')} />
                                                    </div>
                                                </div>
                                                <div className=" textparent no-gutter d-flex align-items-start flex-column">
                                                    <h4 className={"cursor-point"} ><strong>{util.getTitleCase(item?.userData?.firstName)} {util.getTitleCase(item?.userData?.lastName)}</strong></h4>
                                                    <span><small>{item?.userData?.JobTitle}</small></span>
                                                    <h5>
                                                        {item?.userData?.companyName}
                                                    </h5>
                                                    <div className="d-block">
                                                        <div className="d-flex justify-content-between">
                                                            <CmBtn text="Unblock" className="btn btn-blue" bg="#D13A27" onClick={(e) => this.onRemoveAndBlock("COMMUNITY_CONNECTION_BLOCK", 0, item?._id, e)} >{this.state[`connection${item?._id}`] && <MySpinner />}</CmBtn>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                )
                            })}
                            {blockList.length == 0 && <div className="w-100 text-center row nodata m-auto">
                                <div className="m-auto">
                                    <NoMediaICon />

                                    <h2>No Data Found</h2>
                                </div>                    </div>
                            }


                        </div>
                    </div>
                }
                {this.state.isReport &&

                    <ReportModal
                        text='Close Me'
                        userData={this.state.userData}
                        closePopup={() => { this.setState({ isReport: false }) }}

                    />

                }

            </div >
        )
    }
}
// export default Connections;
function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(Connections);