import React, { Component } from 'react';
import { Brands } from '../../../SvgIcon';
import { Link } from "react-router-dom";
import { Empty } from 'antd';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';
import { Pagination } from '../../../components/common/Pagination';
// import { Modal, Button } from 'react-bootstrap';
import { Delete } from '../../../SvgIcon';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Loader from '../../../components/Loader';
const { confirm } = Modal;

const DragHandle = sortableHandle((props) => {
    if (props.isIndex) {
        return <td> <span className="circle-serial">{props.children}</span></td>
    }
    else {
        return (<td>{props.children}</td>)
    }

});

const SortableItem = sortableElement(({ item, indexValue, scope }) => {
    
    return <tr>
        <DragHandle isIndex={true}>{(scope.state.snNo * 10) + indexValue + 1} </DragHandle>
        {/* </DragHandle> <DragHandle> */}
   
        <td> <Link to={`/business/details/${item?._id}`} > {item?.companyName}</Link></td>
        
        
        {/* </DragHandle> */}
     
        <DragHandle>{item?.userPlan == 3 ? "Agency Showcase" : "Operator Showcase"}</DragHandle>
        <DragHandle>{item?.productData?.length || 0}</DragHandle>
        <DragHandle>{item?.staffData?.length || 0}</DragHandle>
        <td className="action_group">
            <p onClick={() => scope.showModal(item?._id)}><p className="circle-serial"><Delete /></p></p>

        </td>
    </tr>
});

const SortableContainer = sortableContainer(({ children }) => {
    return <tbody>{children}</tbody>
});

class FeatureBrandList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 1,
            limit: 20,
            visible: false,
            exhibitionListData: [],
            isLoader: false,
            search: '',
            brandListData: [],
            snNo: 0,
            totalCount: 1


        }
    }

    componentDidMount() {
        this.getBrandList()
    }
    /** brands start */

    getBrandList = (isLoader = true) => {
        this.setState({ isLoader: isLoader })
        let { page, limit, search } = this.state
        let req = {
            page: page ? page : 1,
            limit: 20,
            search: search.toString(),
            fromAdmin: true
        }
        _Api(Url.FEATURED_BRAND_LIST.method, Url.FEATURED_BRAND_LIST.url, '', `?page=${req.page}&limit=${req.limit}&search=${req.search}`)
            .then(resp => {
                this.setState({ brandListData: resp.responseData?.result?.data, totalCount: resp.responseData?.result?.totalCount, isLoader: false })

            }).catch(() => {
                this.setState({ isLoader: false });
            })
    }

    handleSearch = e => this.setState({ search: e.target.value, brandListData: [], page: 1, snNo: 0 }, () => this.getBrandList());
    // Update feature modal
    showModal = (id) => {
        confirm({
            title: 'Are you sure you want to remove from featured brand?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            onOk: () => {
                this.onUpdateFeature(id)
            },
            onCancel: () => {
                this.handleCancel()
            },
        });
    }
    updateOrderList() {
        let sortedList = [];
        this.state.brandListData.forEach((element, index) => {
            sortedList.push({ _id: element._id, orderValue: index + 1 })

        });
        let params = {
            orderArray: sortedList
        }
        _Api(Url.UPDATE_OREDER_FEATURE_BRAND.method, Url.UPDATE_OREDER_FEATURE_BRAND.url, params)
            .then(resp => {
                // notify("success", "Brands Sorted Successfully");

            }).catch(err => {
                let errorMessage = err?.error?.responseMessage || err.message;
                // notify("err", errorMessage);

            })


    }

    //Cancel modal
    handleCancel = () => {
        this.setState({ visible: false });
    };
    onUpdateFeature = (id) => {
        let params = {
            brand: [id],
            isFeatured: 2

        }

        _Api(Url.FEATURED_BRAND_FEATURE_UPDATE.method, Url.FEATURED_BRAND_FEATURE_UPDATE.url, params)
            .then(resp => {
                notify("success", "Feature removed Successfully");
                this.getBrandList();
            }).catch(err => {
                let errorMessage = err?.error?.responseMessage || err.message;
                notify("err", errorMessage);

            })
    }
    onPressBtn = (page) => {
        this.setState({ page, snNo: page - 1, brandListData: [] }, () => {
            this.getBrandList()
        })

    }
    onSortEnd = ({ oldIndex, newIndex }) => {
        this.setState(({ brandListData }) => ({
            brandListData: arrayMove(brandListData, oldIndex, newIndex),
        }), () => this.updateOrderList());
    };

    render() {

        let id = this.props?.match?.params?.exId ? this.props.match.params.exId : ''
        const { isLoader, brandListData, totalCount } = this.state;
        return (
            <>
                <div className="dash_body_container">
                    {isLoader && <Loader />}
                    <Header />
                    <Sidebar />
                    <>
                        <div class="header-text d-flex mb-4 mt-3">
                            <div class="d-flex flex-grow-1 align-items-center">
                                <p className="header-icon"><Brands /></p><h4>Featured Businesses</h4>
                            </div>
                            <div class="search_wrapper  mr-2">
                                <i class="fa fa-search"></i>
                                <input type="search" placeholder="Search..." name="search"
                                    onChange={this.handleSearch}
                                />
                            </div>
                            <div class="">
                                <Link to={`/Add-feature-businesslist/${totalCount}`} className="btn btn-blue-large mr-2">Add Feature Businesses</Link>
                            </div>
                        </div>
                    </>
                    <div class="table-responsive">
                        <table class="table table-bordered rounded-table">
                            <thead>
                                <tr>

                                    <th>S.No.</th>
                                    <th>Company Name</th>                                    
                                    <th>Business Type</th>                                    
                                    <th>No of Jobs</th>
                                    <th>No of Staff</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>

                            </tbody>
                            <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
                                {brandListData.map((item, index) => {
                                    return (<SortableItem key={item?._id} index={index} item={item} scope={this} indexValue={index} />)
                                })}
                            </SortableContainer>
                        </table>

                        <div>
                            {!isLoader && brandListData && brandListData.length == 0 &&
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                        </div>                        
                    </div>
                </div>
            </>
        )
    }
}

export default FeatureBrandList