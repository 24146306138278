import React, { Component } from 'react';
import Footer from "../../layout/Footer"
import Qualification from './Qualification';
import WorkExperience from './WorkExperience';
import { Hert } from '../../../SvgIcon';
import siteSetting from '../../../config/env/Index';

import {notify} from "../../..//components/Toaster"

import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import ContentEditable from "react-contenteditable";

class JobProfile extends Component {
    constructor() {
        super()
        this.state = {
            visible: false,
            _id:'',
            cvFile: '',
            media: null,
            isAboutEdit: false,
            aboutMe: '',
            showAbout: '',
            qualification:[],
            workExperience:[],
            name:''

        }
    }


    updateCV = () => {

        let req = new FormData()
        req.append("media", this.state.cvFile)
        _Api(Url.ADD_JOB_PROFILE.method, Url.ADD_JOB_PROFILE.url, req)
            .then(resp => {
                console.log("respresprespresp", resp);

               
              this.getDetails()

            }).catch(err => {
                //alert("radha catch");
                this.setState({ isLoader: false });
                let errorMessage = err.error && err.error.responseMessage || err.message;
                // notify("err", errorMessage);
            });
    }


    delete = () => {

        let req = {
            profileId:this.state._id
        }
        _Api(Url.DELETE_RESUME.method, Url.DELETE_RESUME.url, req)
            .then(resp => {
                console.log("delete", resp);               
              this.getDetails()

            }).catch(err => {
                //alert("radha catch");
                this.setState({ isLoader: false });
                let errorMessage = err.error && err.error.responseMessage || err.message;
                // notify("err", errorMessage);
            });
    }


    


    handleUpload = (event) => {
        console.log(event, "eventeventevent", event.target.files[0])
        let file = event.target.files[0];      
        console.log("sajdsa", file)
        console.log(`originalFile size ${file.size / 1024 / 1024} MB`);
        if(file.type == "application/msword" ||file.type == "application/pdf" || file.type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document"){
   
            let size =( Math.round(file.size / 1024 / 1024) )

        if (size <= siteSetting.cvUploadLimit){
        if (file) {
            console.log(event, "media1", event.target.files[0])
            this.setState({ cvFile: file }, () => {
                this.updateCV()
            })
        }
    }
    else{
        notify("err", "file size to big")

    }
    }
    else{
        notify("err", "file type not support");
        // alert()
    }
    }


    getDetails = () => {
        _Api(Url.GET_JOB_PROFILE.method, Url.GET_JOB_PROFILE.url, '')
            .then(resp => {
                const {name, media, aboutMe, workExperience, qualification, _id } = resp.responseData.result || {}
                console.log("respresprespresp", resp.responseData.result);
                this.setState({name,_id, media, aboutMe, showAbout: aboutMe , workExperience, qualification})              

            }).catch(err => {               
                this.setState({ isLoader: false });
                let errorMessage = err.error && err.error.responseMessage || err.message;               
            });
    }


    componentDidMount() {
        this.getDetails()
    }








    handleOnChange = (e) => {
        this.setState({ aboutMe: e.target.value })
    }
    

    handleAbout = () => {

        let req = new FormData()
        req.append("aboutMe", this.state.aboutMe)
        _Api(Url.ADD_JOB_PROFILE.method, Url.ADD_JOB_PROFILE.url, req)
            .then(resp => {
                console.log("respresprespresp", resp);
                this.setState({ isAboutEdit:false });
                this.getDetails()
               

            }).catch(err => {
                //alert("radha catch");
                this.setState({ isLoader: false });
                let errorMessage = err.error && err.error.responseMessage || err.message;
                // notify("err", errorMessage);
            });


    }




    render() {

        return (
            <div className="brand-home-banner-bg">
                <section className="job-finder-page-temp py-3 py-md-5">
                    <div className="container-fluid">
                        <h1 className="heading-4 text-center mb-3 mb-md-4">Career Profile</h1>

                        {/* Upload CV */}
                        <div className="job-common-list upload-cv-wrp">
                            <div className="carrir-job jb-cmn-topbar">
                                <div className=" d-flex align-items-center">
                                    <h3 className="mb-0">Upload CV (Max size 5 mb / .pdf, .doc, .docx )</h3>
                                    <div className="icn-top ml-auto">
                                        {/* <i className="fa fa-upload"></i> */}
                                    </div>
                                </div>
                                <div className="text-carr">
                                    <p className="mb-0">we advise not including any personal details on your CV</p>
                                </div>
                            </div>
                            <div className="job-cmnbody p-2 p-md-3 upload-cv-wrp">
                                <div className="uploadbtn-wrapper d-flex align-items-center">
                                    <div className="upld-cv-btn">
                                        <img src={require("../../../assets/images/upload-cv.png")} className="img-fluid" />
                                    </div>
                                    <div className="upld-cv-rgt pl-2">
                                        <div className="file-name-cv">
                                        <span className="text-uppercase d-block mb-2">{this.state.name}</span>
                                        </div>
                                        <div className="upld-cv-btn">
                                           {!this.state.media && <label for="upldcv" className="btn btn-primary jb-btn">
                                                <input type="file" id='upldcv' onChange={this.handleUpload} />
                                                <span>Upload CV</span>
                                            </label>}
                                            {this.state.media && <>   <a download href={this.state.media} className="btn btn-primary jb-btn">Download</a>
                                                <button onClick={()=> this.delete()} className="btn btn-primary jb-btn">Delete</button>
                                            </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>{/* Upload CV ENd */}


                        <div className="job-common-list aboutme-wrp">
                            <div className="jb-cmn-topbar d-flex align-items-center">
                                <h3 className="mb-0">About Me</h3>
                                <div className="icn-topbtn ml-auto">
                                    <button className="btn-jbcn" onClick={() => this.setState({ isAboutEdit: true })}>
                                        <i className="fa fa-pencil"></i>
                                    </button>
                                </div>

                            </div>
                            <div className="job-cmnbody p-2 p-md-3">
                                <div className="form-group about-textarea">
                                    {
                                        this.state.isAboutEdit ?
                                            // <textarea className="form-control"
                                            //     placeholder="Enter here..."
                                            //     maxLength={500}
                                            //     value={this.state.aboutMe}
                                            //     onChange={this.handleOnChange}
                                            // />
                                            <ContentEditable
                                className="editable"
                                    html={this.state.aboutMe ? this.state.aboutMe : ''} // innerHTML of the editable div
                                    disabled={false} // use true to disable edition
                                    onChange={this.handleOnChange} // handle innerHTML change
                                />
                                            :
                                            <ContentEditable
                                            html={this.state.showAbout ? this.state.showAbout : ''} // innerHTML of the editable div
                                            disabled={true} // use true to disable edition
                                        // onChange={this.handleChange} // handle innerHTML change
                                        />
                                         
                                    }
                                </div>
                                {this.state.isAboutEdit && <div className="btn-jb-btm text-md-right">
                                    <button onClick={() => this.setState({ isAboutEdit: false })} className="btn btn-primary mr-2 jb-btn">Cancel</button>
                                    <button onClick={this.handleAbout} className="btn btn-primary jb-btn">Save</button>
                                </div>}
                            </div>
                        </div>
                        {/* Work experience */}
                        <WorkExperience
                        {...this}
                        workExperience = {this.state.workExperience}

                         />

                        {/* Work experience */}
                        <Qualification
                          {...this}
                          qualification = {this.state.qualification}
                         />

                    </div>
                </section>
                <Footer />
            </div>
        )
    }
}

export default JobProfile