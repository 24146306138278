import React, { Component } from 'react';
import { Hert } from '../../../SvgIcon';
import { Modal } from 'antd';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import moment from "moment"
import InlineError from "../../../components/InlineError"
import { DatePicker } from 'antd';


class Qualification extends Component {
    constructor() {
        super()
        this.state = {
            visible: false,
            eduType: 0,
            heighQua: '',
            heighQuaError: '',
            specialization: '',
            specializationError: '',
            institute: '',
            instituteError: '',
            startYears: '',
            startYearsError:'',
            endYearsError:'',
            endYears: '',
            editId: ''
        }
    }

    showModal = () => {
        this.setState({
            visible: true,
        })
    }

    handleOk = () => {
        this.setState({ loading: true })
    }

    handleCancel = () => {
        this.setState({ visible: false })
    }

    handleOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
        this.handleClearError()
    }

    handleSubmit = () => {
        let req = {
            qualification: [{
                "highestQualification": this.state.heighQua,
                "specialization": this.state.specialization,
                "institute": this.state.institute,
                "startYear": this.state.startYears.valueOf(),
                "endYear": this.state.endYears.valueOf(),
                "educationType": this.state.eduType
            }]
        }
        _Api(Url.ADD_JOB_PROFILE.method, Url.ADD_JOB_PROFILE.url, req)
            .then(resp => {
                console.log("respresprespresp", resp);
                this.handleClear()
                this.props.getDetails()

            }).catch(err => {
                this.setState({ isLoader: false });
                let errorMessage = err.error && err.error.responseMessage || err.message;

            });
    }


    setEdit = (item) => {
        console.log("dgvhbjnmkd", item)
        this.setState({
            editId: item._id,
            heighQua: item.highestQualification,
            specialization: item.specialization,
            institute: item.institute,
            startYears: moment(item.startYear),
            endYears: moment(item.endYear),
            eduType: item.educationType,
            visible: true,
            // dateTime:  moment(new Date(msgBroadcastTimestamp))
        })
    }

    submitEdit = () => {

        let req = {
            "qualificationId": this.state.editId,
            qualification: {
                "highestQualification": this.state.heighQua,
                "specialization": this.state.specialization,
                "institute": this.state.institute,
                "startYear": this.state.startYears.valueOf(),
                "endYear": this.state.endYears.valueOf(),
                "educationType": this.state.eduType
            }
        }
        _Api(Url.EDIT_JOB_PROFILE.method, Url.EDIT_JOB_PROFILE.url, req)
            .then(resp => {
                console.log("respresprespresp", resp);

                this.props.getDetails()
                this.handleClear()

            }).catch(err => {
                this.setState({ isLoader: false });
                let errorMessage = err.error && err.error.responseMessage || err.message;

            })

    }



    handleClear = () => {
        this.setState({
            visible: false, heighQua: '', specialization: '', institute: '', startYears: '', endYears: '', eduType: 0, editId: ''
        })

    }

    handleClearError = () => {
        this.setState({
            heighQuaError: '', specializationError: '', instituteError: '',
            startYearsError:'', endYearsError:''
        })

    }


    submit = () => {
        if (this.state.heighQua == "") {
            this.setState({ heighQuaError: "*Please enter value" })
        }
        else if (this.state.specialization == "") {
            this.setState({ specializationError: "*Please enter value" })
        }
        else if (this.state.institute == "") {
            this.setState({ instituteError: "*Please enter value" })
        }
        else if (this.state.startYears == "") {
            this.setState({ startYearsError: "*Please select" })
        }
        else if (this.state.endYears == "") {
            this.setState({ endYearsError: "*Please select" })
        }

        else if (this.state.editId != '') {
            this.submitEdit()

        }
        else {
            this.handleSubmit()

        }

    }



    dateonChange = (date, type) => {
        console.log("sdvhjnkd", date.years())
        if(type == "startYears")
        {
            this.setState({endYears:''})
        }
        this.setState({[type]: date, startYearsError:'', endYearsError:''})


    }

    delete = (id) => {

        let req = {
            qualificationId: id
        }
        _Api(Url.DELETE_RESUME.method, Url.DELETE_RESUME.url, req)
            .then(resp => {
                console.log("delete", resp);
                this.props.getDetails()

            }).catch(err => {
                //alert("radha catch");
                this.setState({ isLoader: false });
                let errorMessage = err.error && err.error.responseMessage || err.message;
                // notify("err", errorMessage);
            });
    }



    render() {
        const { visible, heighQua, specialization, institute } = this.state;
        const { qualification } = this.props
        console.log("hsgfgvh", moment().year())
        const disabledDateEnd = (current) => {
            // Can not select days before today and today
            return current && current.valueOf() < this.state.startYears;
        }
        return (
            <>
                <div className="job-common-list wrk-exprcnt-wrp">
                    <div className="jb-cmn-topbar d-flex align-items-center">
                        <h3 className="mb-0">Qualification</h3>
                        <div className="icn-topbtn ml-auto">
                            <button className="btn-jbcn" onClick={this.showModal}>
                                <i className="fa fa-plus"></i>
                            </button>
                        </div>
                        <Modal
                            centered
                            wrapClassName="signup_parent_modal"
                            className="cm_popup signup_modal "
                            visible={visible}
                            onOk={this.handleOk}
                            onCancel={this.handleClear}
                            footer={null}
                        >
                            <div className="sign-modal-content job-frm-mdl">
                                <div className="row align-items-center">
                                    <div className="col-md-12" >
                                        <h3 className="auth-heading pos-relative text-center mt-5 mb-5">
                                            Qualification
                                            <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                        </h3>
                                        <form className="cm-form-wrapper ">
                                            <div className="form-group">
                                                <label>Highest Qualification</label>
                                                <input
                                                    className="form-control"
                                                    placeholder="enter here..."
                                                    maxLength={256}
                                                    name="heighQua"
                                                    value={heighQua}
                                                    onChange={this.handleOnChange}
                                                />
                                                <InlineError
                                                    message={this.state.heighQuaError}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Specialization</label>
                                                <input className="form-control"
                                                    placeholder="enter here..."
                                                    maxLength={256}
                                                    name="specialization"
                                                    value={specialization}
                                                    onChange={this.handleOnChange}
                                                />
                                                <InlineError
                                                    message={this.state.specializationError}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Institute</label>
                                                <input className="form-control"
                                                    placeholder="enter here..."
                                                    maxLength={256}
                                                    name="institute"
                                                    value={institute}
                                                    onChange={this.handleOnChange}

                                                />
                                                <InlineError
                                                    message={this.state.instituteError}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label>Start Year</label>
                                                        <DatePicker                                                       
                                                        value={this.state.startYears} onChange={(e)=> this.dateonChange(e, "startYears")} picker="year" />
                                                        {/* <select className="form-control">
                                                            <option>--Select--</option>
                                                        </select> */}
                                                        <InlineError
                                                    message={this.state.startYearsError}
                                                />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label>End Year</label>
                                                        <DatePicker 
                                                          disabledDate={disabledDateEnd}
                                                        value={this.state.endYears} onChange={(e)=> this.dateonChange(e, "endYears")} picker="year" />
                                                        {/* <select className="form-control">
                                                            <option>--Select--</option>
                                                        </select> */}
                                                        <InlineError
                                                    message={this.state.endYearsError}
                                                />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group csmt-rad-btn">
                                                <label>Education Type</label>
                                                <div className="assign-checkboc-parent">
                                                    <div>
                                                        <label className="cm_container">
                                                            Full Time
                                                            <input checked={this.state.eduType == 0} onClick={() => this.setState({ eduType: 0 })} type="radio" name="educType" />
                                                            <span className="radio-checkmark"></span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label className="cm_container">
                                                            Part Time
                                                            <input checked={this.state.eduType == 1} onClick={() => this.setState({ eduType: 1 })} type="radio" name="educType" />
                                                            <span className="radio-checkmark"></span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label className="cm_container">
                                                            Correspondence
                                                            <input checked={this.state.eduType == 2} onClick={() => this.setState({ eduType: 2 })} type="radio" name="educType" />
                                                            <span className="radio-checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group text-center popCar-btn">
                                                <button type="button" onClick={this.handleClear} className="btn btn-primary mr-2 jb-btn">Cancel</button>
                                                <button type="button" onClick={this.submit} className="btn btn-primary jb-btn">Save</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>

                    <div className="job-cmnbody p-2 p-md-3">
                        {qualification?.map((item, index) => {
                            console.log("dshjfds", item)
                            return (
                                <div className="jobprofile-list-wrp d-flex align-items-center">
                                    <div className="jobprofile-left-wrp">
                                        <div className="job-dtl-crd">
                                            <h4>{item.highestQualification}</h4>
                                            <ul className="list-unstyled mb-0">
                                                <li>{item.specialization}</li>
                                                <li>{item.institute}</li>
                                                <li>{moment(item.startYear).year()}- {moment(item.endYear).year()}</li>
                                                <li className="text-muted">{
                                                    item.educationType == 0 ? "Full Time" : item.educationType == 1 ? 'Part Time' : 'Correspondence'
                                                }</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="jobprofile-right-wrp ml-auto">
                                        <button onClick={() => this.setEdit(item)} className="btn-jbcn">
                                            <i className="fa fa-pencil"></i>
                                        </button>
                                        <button onClick={() => this.delete(item._id)} className="btn-jbcn btn-danger">
                                            <i className="fa fa-trash"></i>
                                        </button>
                                    </div>
                                </div>

                            )
                        })

                        }
                    </div>
                </div>
            </>
        )
    }
}

export default Qualification