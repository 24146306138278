import React, { Component } from 'react';
import { Hert } from '../../SvgIcon';
import {Modal, Select} from 'antd';
import Loader from '../../components/Loader'

import {
    MyAlert,
    MySpinner
} from "../../components/index"

import {connect} from "react-redux";
import {_Api} from "../../services/Api";
import Url from "../../services/BaseUrl";
import {notify} from "../../components/Toaster";
import util from "../../utils/Util";
import InlineError from "../../components/InlineError";

const { Option } = Select;
var _ = require('lodash');

class SignUpProcess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoader: false,
            showMessage: false,
            isPasswordShow: false,
            sectorList:[],
            placeOfWorkList:[],
            roleList:[],
            sectorError:"",
            placeofWorkError:"",
            roleError:"",

            registrationIdError:"",
            countryError:"",
            cityError:"",
            countryList:[],
            cityList:[]
        }
    }
    componentDidMount() {
        this.getSector();
    }

    getSector=()=>{
        _Api(Url.GET_SECTOR_LIST.method, Url.GET_SECTOR_LIST.url)
            .then(resp => {
                if (resp.statusCode == 1) {
                    this.setState({
                        sectorList: resp.responseData.result.data,
                    })
                }
            }).catch(err => {
            if (err.error)
                notify("err", err.error.responseMessage)
        })
    }

    checkSignupStepClass=(signupStep)=>{
         if(signupStep<this.props.signupStep){
            return 'signup-step done';
        }else if(signupStep===this.props.signupStep){
            return 'signup-step active';
        }else{
            return 'signup-step';
        }
    }

    checkSelected=(field,value)=>{
        let selectedField=this.props[field]
        let checkResult=_.find(selectedField,function (obj){
            return obj===value;
        })
        //console.log("selectedField",selectedField,checkResult,value)
        if(checkResult){
            return true;
        }else{
            return false;
        }
    }

    getplaceOfWork=(e)=>{
        let self=this;
        e.preventDefault();
        let sectorId=this.props.selectedSector;
        self.props.stateSet("isLoading",true)
        _Api(Url.GET_PLACE_OF_WORK_LIST.method, Url.GET_PLACE_OF_WORK_LIST.url,{sectorId:sectorId.toString()})
            .then(resp => {
                if (resp.statusCode == 1) {
                    this.setState({
                        placeOfWorkList: resp.responseData.result.data,
                    })
                    self.props.stateSet("isLoading",false)
                    self.props.signupNext(3);
                }
            }).catch(err => {
            if (err.error)
                notify("err", err.error.responseMessage)
        })

    }

    getRole=(e)=>{
        let self=this;
        e.preventDefault();
        let placeOfWorkId=this.props.selectedPlaceOfWork;
        self.props.stateSet("isLoading",true)
            _Api(Url.GET_ROLE_LIST.method, Url.GET_ROLE_LIST.url, {placeOfWorkId: placeOfWorkId.toString()})
                .then(resp => {
                    if (resp.statusCode == 1) {
                        this.setState({
                            roleList: resp.responseData.result.data,
                        })
                        self.props.stateSet("isLoading",false)
                        self.props.signupNext(4);
                    }
                }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
            })
    }

    signupNextStep=(e)=>{
        let self=this;
        e.preventDefault();
        if(this.props.signupStep==2){
            console.log("this.props.selectedSector.length",this.props.selectedSector.length,this.props.selectedSector);
            if(this.props.selectedSector.length>0){
                self.getplaceOfWork(e);
            }else{
                this.setState({
                    sectorError:"*Please select any sector"
                })
            }
        }else if(this.props.signupStep==3){
            if(this.props.selectedPlaceOfWork.length>0) {
                self.getRole(e);
            }else{
                this.setState({
                    placeofWorkError:"*Please select any place of work"
                })
            }
        }else if(this.props.signupStep==4){
            if(this.props.registrationIdRequired==true){
                if(this.props.registrationId.trim()===""){
                    this.setState({ registrationIdError: '*Please enter.' })
                }else{
                    this.getCountry();
                    this.props.signupNext(5);
                }
            }else {
                let checkRegistrationId = 0;
                if (this.props.selectedRole.length > 0) {
                    let roleArr = ["5fc9d333611cf52928910101",
                        "5fc73e5a237eae12638c982e",
                        "5fc72577237eae12638c9823",
                        "5fc72850237eae12638c9825",
                        "5fc724ba237eae12638c9822",
                        "5fc78d3c237eae12638c9834"];
                    _.map(this.props.selectedRole, function (role) {
                        let checkResult = _.find(roleArr, function (obj) {
                            return obj === role;
                        })
                        if (checkResult) {
                            checkRegistrationId++;
                        }
                    })

                    if (checkRegistrationId > 0) {
                        self.props.stateSet('registrationIdRequired',true)
                    } else {
                        this.getCountry();
                        this.props.signupNext(5);
                    }
                } else {
                    this.setState({
                        roleError: "*Please select any role"
                    })
                }
            }
        }else if(this.props.signupStep==5){
            if(this.props.selectedCountry===""){
                this.setState({ countryError: '*Please select country.' })
            }else if(this.props.selectedCity===""){
                this.setState({ cityError: '*Please select city.' })
            }else{
                this.props.submitSignup();
            }

        }
    }

    getCountry=()=>{
        let self=this;
        self.props.stateSet("isLoading",true)
        _Api(Url.GET_COUNTRY_LIST_V2.method, Url.GET_COUNTRY_LIST_V2.url)
            .then(resp => {
                if (resp.statusCode == 1) {
                    this.setState({
                        countryList: resp.responseData.result,
                    })
                }
                self.props.stateSet("isLoading",false)
            }).catch(err => {
            if (err.error)
                notify("err", err.error.responseMessage)
        })
    }

    getCity=(selectedCountryCode)=>{
        let self=this;
        //let selectedCountryCode=this.props.selectedCountryCode;
        _Api(Url.GET_CITY_LIST.method, Url.GET_CITY_LIST.url,'', '?countryCode='+selectedCountryCode)
            .then(resp => {
                if (resp.statusCode == 1) {
                    this.setState({
                        cityList: resp.responseData.result,
                    })
                }
            }).catch(err => {
            if (err.error)
                notify("err", err.error.responseMessage)
        })
    }

    selectCountry=(value)=>{
        let data=value.split("#");
        this.props.selectCountry(data[0],data[1]);
        this.getCity(data[0]);
    }

    selectCity=(value)=>{
        let data=value.split("#");
        this.props.selectCity(data[1],data[0])

    }


    render() {
        console.log("this.props",this.props);
        console.log("this.state",this.state);
        let {sectorError,placeofWorkError,roleError,registrationIdError,countryError,cityError}=this.state;
        let {registrationId}=this.props;
        return (
            <>
                <div className="sign-modal-content">
                    <div className="row align-items-center">
                        <div className="col-md-12" >
                                <button type="button" className="btn sign-backbtn" onClick={this.props.signupBack}> &nbsp;Back
                                </button>
                            <div className="signup-tab mt-5">
                                <div className="Thing-line"><span className="circle_bullet"></span><span className="heart"><Hert /></span></div>
                                <div  className={this.checkSignupStepClass(1)}><span>1</span></div>
                                <div  className={this.checkSignupStepClass(2)}><span>2</span></div>
                                <div  className={this.checkSignupStepClass(3)}><span>3</span></div>
                                <div  className={this.checkSignupStepClass(4)}><span>4</span></div>
                                <div  className={this.checkSignupStepClass(5)}><span>5</span></div>

                            </div>
                            {this.props.signupStep===2?
                            <div id="step1" >
                                <h3 className="auth-heading pos-relative text-center mt-5">
                                What Sector(s) do you work in?
                                <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                               </h3>
                                {/*<p className="home-subheading text-center">Free for professionals and low cost options for brands and suppliers </p>*/}
                                <form className="rounded-textbox-form mt-5" onSubmit={this.signupNextStep}>

                                    {this.state.sectorList.length>0&&this.state.sectorList.map((item) => (
                                        <div className={this.checkSelected('selectedSector',item._id)?"form-group form-control select_field cursor-point":"form-group form-control cursor-point"} key={item._id} onClick={(e)=>this.props.onCheckedBox(item._id,'selectedSector','selectedSectorCount')}>
                                            <div className="check_wrapper circle-checkbox ">
                                                <input name={'selectedSector-'+item.name} checked={this.checkSelected('selectedSector',item._id)} type="checkbox" value={item._id} />
                                                <span />
                                            </div>
                                            <span>{item.name}</span>
                                        </div>
                                    ))}
                                    <InlineError
                                        message={sectorError}/>
                                <div className="text-center">
                                        <button type="submit" disabled={this.props.isLoading}  className="btn btn-red"> &nbsp;Next
                                            &nbsp;
                                        {this.state.isLoading &&
                                                <MySpinner
                                                />}
                                        </button>
                                    </div>

                                </form>
                            </div>
                            :""}
                            {this.props.signupStep===3?
                            <div id="step2" >
                            <h3 className="auth-heading pos-relative text-center mt-5">
                                What is your place of work?
                                <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                               </h3>
                                {/*<p className="home-subheading text-center">Free for professionals and low cost options for brands and suppliers </p>*/}
                                <form className="rounded-textbox-form mt-5" onSubmit={this.signupNextStep}>
                                    {this.state.placeOfWorkList.length>0&&this.state.placeOfWorkList.map((item) => (
                                        <div className={this.checkSelected('selectedPlaceOfWork',item._id)?"form-group form-control select_field cursor-point":"form-group form-control cursor-point"} key={item._id} onClick={(e)=>this.props.onCheckedBox(item._id,'selectedPlaceOfWork','placeofworkCount')}>
                                            <div className="check_wrapper circle-checkbox ">
                                                <input name={'selectedPlaceOfWork-'+item.name} checked={this.checkSelected('selectedPlaceOfWork',item._id)} type="checkbox" value={item._id} />
                                                <span />
                                            </div>
                                            <span>{item.name}</span>
                                        </div>
                                    ))}
                                    <InlineError
                                        message={placeofWorkError}/>
                                    <div className="text-center">
                                        <button type="submit"  disabled={this.props.isLoading} className="btn btn-red"> &nbsp;Next
                                            &nbsp;
                                        {this.state.isLoading &&
                                                <MySpinner
                                                />}
                                        </button>
                                    </div>
                                </form>
                            </div>
                            :""}
                            {this.props.signupStep===4?
                                this.props.registrationIdRequired===false?
                            <div id="step3" >
                            <h3 className="auth-heading pos-relative text-center mt-5">
                                What is your role?
                                <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                               </h3>
                                {/*<p className="home-subheading text-center">Free for professionals and low cost options for brands and suppliers </p>*/}
                                <form className="rounded-textbox-form mt-5" onSubmit={this.signupNextStep}>
                                    {this.state.roleList.length>0&&this.state.roleList.map((item) => (
                                        <div className={this.checkSelected('selectedRole',item._id)?"form-group form-control select_field cursor-point":"form-group form-control cursor-point"} key={item._id} onClick={(e)=>this.props.onCheckedBox(item._id,'selectedRole','roleCount')}>
                                            <div className="check_wrapper circle-checkbox ">
                                                <input name={'selectedRole-'+item.name} checked={this.checkSelected('selectedRole',item._id)} type="checkbox" value={item._id} />
                                                <span />
                                            </div>
                                            <span>{item.name}</span>
                                        </div>
                                    ))}
                                    <InlineError
                                        message={roleError}/>
                                    <div className="text-center">
                                        <button type="submit" disabled={this.props.isLoading} className="btn btn-red"> &nbsp;Next
                                            &nbsp;
                                        {this.state.isLoading &&
                                                <MySpinner
                                                />}
                                        </button>
                                    </div>

                                </form>
                            </div>

                            :

                                <div className="mt-5">
                                    <h4 className="pl-4 pr-4">Do you have a registered GDC/ GMC/ NMC/ GPhc number or overseas equivalent?</h4>
                                    <form className="rounded-textbox-form mt-5" onSubmit={this.signupNextStep}>
                                        <div className="form-group">
                                            <input type="text"
                                                   autocomplete="do-not-show-ac"
                                                   className="form-control"
                                                   placeholder="Enter GDC/ GMC/ NMC/ GPhc number"
                                                   name='registrationId'
                                                   value={registrationId}
                                                   onChange={this.props.sugnUpOnChange}
                                            />
                                            <InlineError
                                                message={registrationIdError}/>
                                        </div>

                                        <div className="text-center">
                                            <button type="submit"  disabled={this.props.isLoading} className="btn btn-red"> &nbsp;Next
                                                &nbsp;
                                                {this.state.isLoading &&
                                                <MySpinner
                                                />}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                :""}

                            {this.props.signupStep===5?
                             <div id="step4" >
                                <form className="rounded-textbox-form mt-5 " onSubmit={this.signupNextStep}>

                                    <div className="form-group">
                                        <h4 className="text-center"><b>Which country you are based in?</b>
                                            {/* <span className="red-star"> * </span> */}
                                        </h4>
                                        <Select showSearch
                                         placeholder="Select Country"
                                                onChange={this.selectCountry}
                                         >
                                            {this.state.countryList.length>0&&this.state.countryList.map((item) => (
                                                <Option key={item.country_code} value={item.country_code+"#"+item.country_name}>{item.country_name}</Option>
                                                ))}
                                        </Select>
                                        <InlineError
                                            message={countryError}/>
                                    </div>
                                    <div className="form-group">
                                        <h4 className="text-center"><b>What is your closest large city?</b>
                                            {/* <span className="red-star"> * </span> */}
                                        </h4>
                                        <Select showSearch
                                         placeholder="Select City"
                                                onChange={this.selectCity}
                                         >
                                            {this.state.cityList.length>0&&this.state.cityList.map((item) => (
                                                <Option key={item._id} value={item._id+"#"+item.city_name}>{item.city_name}</Option>
                                            ))}

                                        </Select>
                                        <InlineError
                                            message={cityError}/>
                                    </div>

                                    <div className="text-center">
                                        <button type="submit" disabled={this.props.isLoading} className="btn btn-red"> &nbsp;Next
                                            &nbsp;
                                        {this.state.isLoading &&
                                                <MySpinner
                                                />}
                                        </button>
                                    </div>

                                </form>

                            </div> :""}
                        </div>
                    </div>
               
                </div>
            </>

        )
    }
}
function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(SignUpProcess);