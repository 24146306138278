import {
    IS_LOADING_START,
    IS_LOADING_STOP,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    IS_LOGGED_IN,
    IS_LOGGED_OUT,
    LOG_OUT_SUCCESS,
    LOG_OUT_FAILURE,
} from "../action/Type";

const initalState = {
    isLoggedIn: false,
    accessToken: '',
}

// login
export const login = (state = initalState, action) => {
    switch (action.type) {
        case LOG_OUT_SUCCESS:
            //localStorage.clear()
            return {
                isLoggedIn: false,
                accessToken: ''
            }
        default:
            return state
    }

}