import React, { Component } from 'react';
import { ExhibitionManagement } from '../../../SvgIcon';
import { Link } from "react-router-dom";
import { Empty } from 'antd';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';
import { Pagination } from '../../../components/common/Pagination';
// import { Modal, Button } from 'react-bootstrap';
import { Delete } from '../../../SvgIcon';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import util from '../../../utils/Util';
import moment from 'moment-timezone';
const { confirm } = Modal;

const DragHandle = sortableHandle((props) => {
    if (props.isIndex) {
        return <td> <span className="circle-serial">{props.children}</span></td>
    }
    else {
        return (<td>{props.children}</td>)
    }

});

const SortableItem = sortableElement(({ item, indexValue, scope }) => {
    return <tr>
        <DragHandle isIndex={true}>{(scope.state.snNo * 10) + indexValue + 1}</DragHandle>
        <DragHandle>{item.conferenceName}</DragHandle>
        {/* <DragHandle>{item.setOrder}</DragHandle> */}
        <DragHandle>{moment(item?.startDate).format(util.DateFormat)}</DragHandle>
        <DragHandle>{moment(item?.startTime).format(util.TimeFormat)}</DragHandle>
        <DragHandle>{moment(item?.endDate).format(util.DateFormat)}</DragHandle>
        <DragHandle>{moment(item?.endTime).format(util.TimeFormat)}</DragHandle>
    </tr>
});

const SortableContainer = sortableContainer(({ children }) => {
    return <tbody>{children}</tbody>
});

class ManageStage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 1,
            limit: 300,
            visible: false,
            exhibitionListData: [],
            isLoader: false,
            search: '',
            stageListData: [],
            snNo: 0,
            sortInfo: {}

        }
    }

    componentDidMount() {
        this.getStageList()
        // this.getConferenceList()
    }

    getStageList = (isLoader = true) => {
        this.setState({ isLoader: isLoader })
        let { page, limit, search, sortInfo } = this.state;
        let { order, fieldName } = sortInfo;
        let req = {
            page: page ? page : 1,
            limit: limit,
            search: search.toString()
        }

        _Api(Url.GET_CONFERENCE_WEB.method, Url.GET_CONFERENCE_WEB.url, '', `?exhibitionId=${localStorage.getItem('exhibition')}`)
            .then(resp => {
                console.log('respexListxxxxxxxx', resp.responseData.result.data)
                this.setState({ stageListData: resp.responseData?.result,  isLoader: false })

            }).catch(err => {
                this.setState({ isLoader: false })

            })
    }



    /** brands start */

    // getStageList = (isLoader = true) => {
    //     this.setState({ isLoader: isLoader })
    //     let { page, limit, search, sortInfo } = this.state;
    //     let { order, fieldName } = sortInfo;
    //     let req = {
    //         page: page ? page : 1,
    //         limit: limit,
    //         search: search.toString()
    //     }
    //     _Api(Url.MANAGE_STAGE_LIST.method, Url.MANAGE_STAGE_LIST.url, '', `?page=${req.page}&limit=${req.limit}&search=${req.search}&sortField=${fieldName || ""}&sortType=${order || ""}`)
    //         .then(resp => {
    //             this.setState({ stageListData: resp.responseData?.result?.data, totalCount: resp.responseData?.result?.totalCount, isLoader: false })

    //         }).catch(() => {
    //             this.setState({ isLoader: false });
    //         })
    // }

    handleSearch = e => this.setState({ search: e.target.value, stageListData: [], page: 1, snNo: 0 }, () => this.getStageList());
    // Update feature modal

    updateOrderList() {
        let sortedList = [];
        this.state.stageListData.forEach((element, index) => {
            sortedList.push({ _id: element._id, orderValue: index + 1 })

        });
        let params = {
            orderArray: sortedList
        }
        _Api(Url.UPDATE_OREDER_CONFERENCE.method, Url.UPDATE_OREDER_CONFERENCE.url, params)
            .then(resp => {
                // notify("success", "Stage Sorted Successfully");

            }).catch(err => {
                let errorMessage = err.error && err.error.responseMessage || err.message;
                // notify("err", errorMessage);

            })


    }
    onSortEnd = ({ oldIndex, newIndex }) => {
        this.setState(({ stageListData }) => ({
            stageListData: arrayMove(stageListData, oldIndex, newIndex),
        }), () => this.updateOrderList());
    };
    onClickSorting = (field) => {
        let { order, fieldName } = this.state.sortInfo;
        let orderType = 1;
        if (order && fieldName === field) {
            orderType = order < 0 ? '' : -1;
        }
        this.setState({
            sortInfo: {
                fieldName: field,
                order: orderType
            }
        }, () => this.getStageList());

    }

    render() {

        let id = this.props?.match?.params?.exId ? this.props.match.params.exId : ''
        const { isLoader, stageListData, totalCount, sortInfo } = this.state;
        let { order, fieldName } = sortInfo;
        return (
            <>
                <div className="dash_body_container">
                    <Header />
                    <Sidebar />
                    <>
                        <div class="header-text d-flex mb-4 mt-3">
                            <div class="d-flex flex-grow-1 align-items-center">
                                <p className="header-icon"><ExhibitionManagement /></p><h4>Manage Seminars</h4>
                            </div>
                            {/* <div class="search_wrapper  mr-2">
                                <i class="fa fa-search"></i>
                                <input type="search" placeholder="Search..." name="search"
                                    onChange={this.handleSearch}
                                />
                            </div> */}
                        </div>
                    </>
                    <div class="table-responsive">
                        <table class="table table-bordered rounded-table">
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th><div className="d-flex justify-content-around align-items-center"
                                        onClick={() => this.onClickSorting("stageName")}>Seminar Name
                                        {<span className="d-flex"><i className={util.getSortingIcon("stageName" && order)}
                                            aria-hidden="true"
                                        /></span>}</div></th>
                                    {/* <th>Set Order</th> */}
                                    <th>Start Date</th>
                                    <th>Start Time</th>
                                    <th>End Date</th>
                                    <th>End Time</th>
                                </tr>
                            </thead>
                            <tbody>

                            </tbody>
                            <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
                                {stageListData.map((item, index) => {
                                    return (<SortableItem key={item._id} index={index} item={item} scope={this} indexValue={index} />)
                                })}
                            </SortableContainer>
                        </table>
                        <div>
                        {!isLoader && stageListData && stageListData.length == 0 &&
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                       </div>
                    </div>
                  
                </div>
            </>
        )
    }
}

export default ManageStage