import React, { Component } from 'react';
import { Hert, ExhibitionManagement, Pencil, Delete, UserManagementIcon } from '../../../SvgIcon';
import { Link } from "react-router-dom";
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';
import moment from 'moment-timezone';
import { Pagination } from '../../../components/common/Pagination';
import { Empty } from 'antd';
import { MySpinner, AttendeeLoader } from "../../../components/index"

import { Modal, Button, Space, Select } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;
const { Option } = Select;

class Attendee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDeleted: '',
            exhibtionId: "5fd1a93a52b4ec54fa21d1b4",
            status: "",
            page: 1,
            limit: 10,
            loading: false,
            visible: false,
            exhibitionListData: [],
            selectedId: '',
            isLoader: false,
            search: '',
            totalCount: [],
            snNo: 0,
            selectedItems: [],
            order: 1,

            conferenceDetails: [],
            stageList: [],
            loungeList: [],
            loungeId: '',
            selectedConfrenceId: '',
            AttendeeList: [],
            conferenceId: '',
            stageId: '',









        }
    }

    componentDidMount() {
        this.getConferenceList()
        this.getAttendee()
    }
    getAttendee = (isShowLoader = true) => {
        const { conferenceId, stageId, loungeId } = this.state

        this.setState({ isLoader: isShowLoader })
        let { page, limit, search, order } = this.state
        let req = {
            page: page ? page : 1,
            limit: limit,
            search: search.toString(),
            order: order,
            conferenceId: conferenceId,
            stageId: stageId,
            loungeId: loungeId,
            exhibitionId:this.state.exhibtionId
        }
        _Api(Url.GET_ATTENDEE_LIST.method, Url.GET_ATTENDEE_LIST.url, req)
            .then(resp => {
                console.log('hjbdkjasmlasndk', resp)
                this.setState({ AttendeeList: resp.responseData.result.data, totalCount: resp.responseData.result.totalCount, isLoader: false })

            }).catch(err => {
                this.setState({ isLoader: false })
            })
    }

    handlePageChange = page => this.setState({ loader: true, page }, () => this.getExhibitionList());

    handleSearch = e => this.setState({ search: e.target.value, page: 1, snNo: 0, exhibitionListData: [] }, () => this.getExhibitionList());



    deleteExhibitionList = (id) => {
        let req = {
            isDeleted: 0,
            exhibtionId: id,

        }
        _Api(Url.EXHIBITION_LIST_DELETE.method, Url.EXHIBITION_LIST_DELETE.url, '', `?exhibtionId=${req.exhibtionId}&isDeleted=${req.isDeleted}`)
            .then(resp => {
                notify('success', resp.responseData.message)
                this.setState({ visible: false })
                this.getExhibitionList(false)
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
            })


    }

    //Show modal
    showModal = (id) => {
        this.setState({
            visible: true,
            selectedId: id
        });

    };
    //Cancel modal
    handleCancel = () => {
        // console.log('delete', resp.responseData.message)
        this.setState({ visible: false });
    };



    statusExihibitionList = (e, id) => {
        let oldArr = this.state.selectedItems
        oldArr.push(id)

        this.setState({ selectedItems: oldArr })

        let req = {
            exhibtionId: id,
            status: (e.target.checked ? 1 : 0).toString(),
        }
        _Api(Url.EXHIBITION_LIST_STATUS.method, Url.EXHIBITION_LIST_STATUS.url, req)
            .then(resp => {
                this.setState({ visible: false, selectedItems: this.state.selectedItems.filter(item => item != id) })
                this.getExhibitionList(false)
            }).catch(err => {
                this.setState({ visible: false, selectedItems: this.state.selectedItems.filter(item => item != id) })
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
            })

    }

    onPressBtn = (page) => {
        this.setState({ page, snNo: page - 1, AttendeeList: [] }, () => {
            this.getAttendee()
        })

    }

    // delete modal
    showDeleteConfirm = (id) => {
        confirm({
            title: 'Are you sure you want to delete this?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            onOk: () => {
                this.deleteExhibitionList(id)
            },
            onCancel: () => {
                this.handleCancel()
            },
        });
    }

    handleToggle = () => {
        this.setState({ order: this.state.order == 1 ? 2 : 1, exhibitionListData: [] }, () => {
            this.getExhibitionList()
        })
    }

    handleClickLabel = (status) => {
        this.setState({ order: status ? 1 : 2, exhibitionListData: [] }, () => {
            this.getExhibitionList()
        })
    }


    getConferenceList = (isLoader = true) => {
        this.setState({ isLoader: isLoader })
        let { page, limit } = this.state
        let req = {
            page: page ? page : 1,
            limit: limit,
        }

        _Api(Url.GET_CONFERENCE_LIST.method, Url.GET_CONFERENCE_LIST.url, '', `?exhibitionId=${"5fd1a93a52b4ec54fa21d1b4"}`)
            .then(resp => {
                console.log('respexListxxxxxxxx', resp.responseData.result.data)
                this.setState({ conferenceDetails: resp.responseData?.result?.data, totalCount: resp.responseData?.result?.totalCount, isLoader: false })

            }).catch(err => {
                this.setState({ isLoader: false })

            })
    }
    //stae list
    getStageList = (id) => {
        try {
            _Api(Url.GET_STAGE_LIST.method, Url.GET_STAGE_LIST.url, "", "?conferenceId=" + id)
                .then(resp => {
                    console.log('ytre', resp.responseData.result.data)
                    this.setState({ stageList: resp?.responseData?.result?.data })
                }).catch(err => {

                })
        } catch{ }
    }

    //lounge list
    getLoungeList = (id) => {
        try {
            _Api(Url.GET_LOUNGE_LIST.method, Url.GET_LOUNGE_LIST.url, "", "?conferenceId=" + id)
                .then(resp => {
                    console.log('ytre', resp.responseData.result.data)
                    this.setState({ loungeList: resp?.responseData?.result?.data })
                }).catch(err => {

                })
        } catch{ }
    }






    selectOnConference = (id) => {
        this.setState({ conferenceId: id,AttendeeList:[]  }, () => this.getAttendee())
        this.getStageList(id)
        this.getLoungeList(id)
    }


    selectOnChangeStage = (id) => {
        this.setState({ stageId: id, AttendeeList:[] }, () => this.getAttendee())
    }
    selectOnChangeLounge = (id) => {
        this.setState({ loungeId: id , AttendeeList:[]}, () => this.getAttendee())
    }



    render() {
        let { loungeList, stageList, conferenceDetails, AttendeeList, visible, loading, search, isLoader, selectedItems } = this.state;
        return (
            <>
                <div className="dash_body_container">
                    <div className="body_container">
                        <div className="header-container d-flex align-items-center justify-content-between">
                            <div className="d-flex flex-grow-1 align-items-center">
                                <p className="header-icon"><UserManagementIcon /></p>
                                <h4>Attendee Management</h4>
                            </div>
                            <div className="d-flex">
                            <div className="rounded-textbox-form filter-select pr-3">

                                <Select placeholder="Select seminar" name="categoryType" onChange={(e) => this.selectOnConference(e)}>
                                    {
                                        conferenceDetails.map((item, index) => {
                                            return (

                                                <Option key={index} value={item._id}>{item.conferenceName}</Option>
                                            )
                                        })
                                    }
                                </Select>

                            </div>
                            <div className="rounded-textbox-form filter-select pr-2">

                                <Select placeholder="Select stage" name="categoryType" onChange={(e) => this.selectOnChangeStage(e)}>
                                    {
                                        stageList.map((item, index) => {
                                            return (

                                                <Option key={index} value={item._id}>{item.stageName}</Option>
                                            )
                                        })
                                    }
                                </Select>

                            </div>
                            <div className="rounded-textbox-form filter-select pr-2">

                                <Select placeholder="Select lounge" name="categoryType" onChange={(e) => this.selectOnChangeLounge(e)}>
                                    {
                                        loungeList.map((item, index) => {
                                            return (

                                                <Option key={index} value={item._id}>{item.loungeName}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </div>
                            {/* <div className="search_wrapper  mr-2">
                                <i className="fa fa-search" />
                                <input type="search"
                                    placeholder="Search by name"
                                    name='search'
                                    value={search}
                                    onChange={this.handleSearch}
                                />
                            </div> */}

                            {/* <Link to="/addexhibition/exhibitiondetails/1" className="btn btn-red">Add New Exhibition</Link> */}
                            </div>
                        </div>



                        <div class="table-responsive">
                            <table class="table table-bordered rounded-table">
                                <thead>
                                    <tr>
                                        <th>S.No.</th>
                                        <th>Attendee Name </th>
                                        <th>Seminar</th>
                                        <th>Stage</th>
                                        <th>Lounge</th>
                                        {/* <th>No.of Conferences</th> */}
                                        {/* <th>Networking Lounges</th> */}
                                        {/* <th>Brands</th> */}
                                        <th>Create Date</th>
                                        {/* <th>Status</th>
                                        <th>Action</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoader && <AttendeeLoader />}
                                    {AttendeeList?.map((item, index) => {
                                        console.log('item', item)
                                        return (

                                            <tr>
                                                {/* conferencedetails/5f058d7073866056602d2ae5/view */}
                                                <td><span className="circle-serial">{(this.state.snNo * 10) + index + 1}</span></td>
                                                <td><Link to={`/user-profile/${item?.userId?._id}`} >{item?.userId?.firstName} {item?.userId?.lastName} </Link></td>
                                                <td> <Link to={`/seminardetails/${item?.conferenceData?.exhibitionId}/view/${item?.conferenceData?._id}`} >{item?.conferenceData?.conferenceName}  </Link></td>
                                                <td> <Link to={`/stage-detail/${item?.stageData?._id}`} >{item?.stageData?.stageName ? item?.stageData?.stageName : 'NA'} </Link> </td>
                                                <td><Link to={`/view/${item?.loungeData?._id}`} > {item?.loungeData?.loungeName ? item?.loungeData?.loungeName : 'NA'} </Link> </td>
                                                <td>{moment(item.created).format("DD/ MM/ YYYY")}</td>
                                                {/* <td>
                                                    {
                                                        selectedItems.indexOf(item._id) == -1 ?
                                                            <p className="toggle-parent ">
                                                                <label className="switch">
                                                                    <input type="checkbox"
                                                                        checked={item.status === 1 ? true : false}
                                                                        onChange={(e) => this.statusExihibitionList(e, item._id)}
                                                                    />
                                                                    <span className="slider round"></span>
                                                                </label>

                                                            </p>
                                                            :
                                                            <MySpinner />
                                                    }


                                                </td>
                                                <td className="action_group">
                                                    <Link to={`/exhibition/edit/${item._id}`}> <p><p className="circle-serial" ><Pencil /></p></p></Link>

                                                    <a onClick={() => this.showDeleteConfirm(item._id)}><p className="circle-serial"><Delete /></p></a>
                                                    <a onClick={() => this.showModal(item._id)}><p className="circle-serial"><Delete /></p></a>


                                                </td> */}
                                            </tr>

                                        )
                                    })

                                    }


                                </tbody>
                            </table>
                            <div>
                            {!isLoader && AttendeeList.length == 0 &&
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                        </div>

                        {
                            AttendeeList.length != 0 &&
                            <Pagination
                                totalPage={Math.ceil(this.state.totalCount / 10)}
                                currentPage={this.state.page}
                                onPressBtn={this.onPressBtn}
                            />
                        }
                        </div>
                       

                    </div>


                </div>
            </>
        );
    }
}
export default Attendee;