import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    AudioVideoFacade,
    MeetingSession,
    ConsoleLogger,
    DefaultDeviceController,
    DefaultMeetingSession,
    DefaultModality,
    LogLevel,
    MeetingSessionConfiguration,
    DefaultActiveSpeakerPolicy
} from 'amazon-chime-sdk-js';

import { Modal } from "antd";
import socket from "../../services/socket";
import LazyLoad from 'react-lazy-load';
import {withRouter} from "react-router-dom";

const logger = new ConsoleLogger();
const deviceController = new DefaultDeviceController(logger);
var meetingSession = MeetingSession;

class CallModal extends Component {
    constructor() {
        super();
        this.state = {
            visible: false,
            callingUser: {},
            calligGroupId: "",
            messageId: ""
        }
        this.audioVideo = AudioVideoFacade;
    }

    componentDidMount() {
        let self = this;
        if (localStorage.getItem('accessToken') && localStorage.getItem('accessToken') !== '') {
            socket.on('callFrom', function (params) {

                self.setState({
                    visible: true,
                    callingUser: params.userData,
                    calligGroupId: params.groupId,
                    messageId: params.messageId,
                })
                var x = document.getElementById("rigntone");
                if(x){
                    x.play();
                }
            })
            socket.on('callEnd', function (params) {

                if (self.props.match && self.props.match.path === "/calling/:id") {
                    //window.location.href="/home"
                } else {
                    let x = document.getElementById("rigntone");
                    if(x){
                        x.pause();
                    }
                    self.setState({
                        visible: false
                    })
                }
            })
            socket.on('callReject', function (params) {
                console.log("callReject");
                self.setState({
                    visible: false,
                })
                var x = document.getElementById("rigntone");
                if(x){
                    x.pause();
                }
                if (self.props.match && (self.props.match.path === "/calling/:id" || self.props.match.path === "/networking/search")) {
                    //window.location.href="/home"
                    window.close();
                }
            })
            socket.on('error', function (error) {


            })
            socket.on('callAccept', function (params) {
                console.log("callAccept");
                self.setState({
                    visible: false,
                })
                var x = document.getElementById("rigntone");
                if(x){
                    x.pause();
                }
            })
            socket.on('groupId', function (params) {

                //socket.emit('getMessage',params);
                if (params.type === 7) {
                    self.props.dispatch({ type: 'GROUP_ID', id: params.groupId })
                }
            })
            // if(self.props.match&&self.props.match.path!=="/calling/:id") {
            //     socket.emit('callBreak',{});
            // }
            // if(self.props.match&&self.props.match.path!=="/networking/search") {
            //     socket.emit('networkCallingBreak',{});
            // }
            socket.on('callBreak', function (params) {

            })
            socket.on('networkCallingBreak', function (params) {

            })
            socket.on('loungeExist', function (params) {

                if (self.props.match && self.props.match.path === "/lounge/:id" && self.props.match.params.id === params.groupId) {
                    //window.location.href="/home"
                    window.close();
                }
            })
        }
    }

    callEnd = () => {
        let self = this;
        var x = document.getElementById("rigntone");
        if(x){
            x.pause();
        }
        socket.emit('callAction', { action: 2, groupId: self.state.calligGroupId, userId: self.state.callingUser._id, messageId: self.state.messageId });
        this.setState({
            visible: false
        })
    }

    joinMeeing = (e) => {
        let self = this;
        e.preventDefault();
        var x = document.getElementById("rigntone");
        if(x){
            x.pause();
        }
        self.setState({
            visible: false
        })
        socket.emit('callAction', { action: 1, groupId: self.state.calligGroupId, userId: self.state.callingUser._id, messageId: self.state.messageId });
        // window.location.href="/calling/"+self.state.calligGroupId
        window.open("/calling/" + self.state.calligGroupId, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
    }

    render() {

        return (
            <>
                <audio id="rigntone" loop>
                    <source src={require(`../../assets/audio/video_call.mp3`)} type="audio/mp3" />
                </audio>
                <Modal centered visible={this.state.visible} className="cm_popup calling-popup " footer={null} closable={false} width="750px" zIndex="99999">
                    <div id="flow-devices" className="flow">
                        <div className="container">
                            <form id="form-devices" onSubmit={this.joinMeeing}>
                                <div className="mt-3 video-call-popup">
                                    <h2>Incoming video chat</h2>
                                    <div className="d-flex align-items-center">
                                        <div className="profile-dp" >
                                            {/*<LazyLoad>*/}

                                                <img className="img-fluid" src={this.state.callingUser && this.state.callingUser.image && this.state.callingUser.image !== "" ? this.state.callingUser.image : require(`../../assets/images/login_user.png`)} />
                                            {/*</LazyLoad>*/}
                                        </div>
                                        <div className="text">
                                            <h3> {this.state.callingUser.firstName} is calling you.</h3>
                                            <p> The call will start as soon as you answer.</p>
                                        </div>

                                    </div>
                                    <br />
                                    <div className="d-flex align-items-center justify-content-end">
                                    <button type="button" className="btn btn-lg btn-primary btn-block" onClick={() => this.callEnd()}>Decline</button>
                                        <button id="joinButton" className="btn btn-lg btn-primary btn-blue btn-block" type="submit">Accept</button>
                                  

                                    </div>
                                    <br />
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(withRouter(CallModal));
