import React, {Component} from 'react'
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';
import { Link } from "react-router-dom";
import '../../../assets/css/adManagement.css'
import {AdManagement, Filter, Pencil, Delete, UploadVideo} from '../../../SvgIcon';

class EditManagement extends Component{
    render(){
        return (
            <div className="dash_body_container">
                <Header />
                <Sidebar />
                <div className="body_container">
                    <div className="header-text  mb-4 mt-3">
                        <div className="d-flex align-items-center">
                            <p className="header-icon"><AdManagement /></p>
                            <h4>Edit Management
                            <br />
                                {/* <ul className="header-breadcrum">
                                    <li><a href="#">Ad Management</a>/</li>
                                    <li><a href="#">Edit Detail</a></li>
                                </ul> */}
                            </h4></div>
                            <Link to="/ads" className="btn btn-light">Back</Link>
                    </div>
                </div>
                <div className="rounded-body py-5">
                    <form className="cm-form-wrapper rounded-textbox-form add-manag px-0">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <level>Ad Name</level>
                                    <input type="text"
                                        className="form-control"
                                        placeholder="Enter Name" name="" value=""/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <level>Ad Category</level>
                                    <input type="text"
                                        className="form-control"
                                        placeholder="Enter Category" name="" value=""/>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <level>Brand</level>
                                    <input type="text" className="form-control" placeholder="Brand Name" name="" value=""/>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <level>Email</level>
                                    <input type="email" className="form-control" placeholder="Enter Email Address" name="" value=""/>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <level>Phone</level>
                                    <input type="text" className="form-control" placeholder="Enter Phone Number" name="" value=""/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <level>Add link or upload multiple videos</level>
                                    <input type="text" className="form-control" placeholder="Enter Video Link" name="" value=""/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <span className="or-label">Or</span>
                                <div className="form-group custom-upload-file">
                                    <ul className="list-inline mt-3 pt-1">
                                        <li className="list-inline-item">
                                            <div className="upload-inner">
                                                <span className="upload-icon"><UploadVideo /></span>
                                                <input type="file" className="d-block" placeholder="Enter Video Link" name=""/>
                                            </div>
                                            <level>Maximum Size : 128 gb</level>
                                        </li>
                                        <li className="list-inline-item">
                                            <button className="btn btn-primary h-100" type="button">+</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="text-right">
                            <button className="btn btn-primary" type='button'>Save</button>
                        </div>
                    </form>
                </div>
            </div>
            )
        }
    }
export default EditManagement;