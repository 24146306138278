import React, { Component } from 'react';
import { Hert, ExhibitionManagement, Pencil, Delete } from '../../../../SvgIcon';
import { Link } from "react-router-dom";
import Sidebar from '../../../layout/Sidebar';
import Header from '../../../layout/Header';
import { _Api } from '../../../../services/Api';
import Url from '../../../../services/BaseUrl';
import { notify } from '../../../../components/Toaster';
import moment from 'moment-timezone'
import Media from "../../../../components/common/Media"





// components
import {
    ConferenceDetails,
    NetworkLounge,
    Brands,
    MyAlert,
    MySpinner,
} from "../../../../components/index"
import {BackBtn} from "../../../../components/common/Button";



class ProfessionalBeautyWorld extends Component {
    constructor(props) {
        super(props)
        this.state = {
            exhibitionDetails: '',
            mediaData:[]
        }
    }
    componentDidMount() {
        if(this.props.match.params.exId)
        localStorage.setItem('exhibition', this.props.match.params.exId)
        
        this.getExhibitionDetails()
        this.getMedia()
        this.handleAllMedia()
    }

    getExhibitionDetails = () => {
        _Api(Url.GET_EXHIBITION_VIEW_PARTICULAR.method, Url.GET_EXHIBITION_VIEW_PARTICULAR.url, '', `?exhibtionId=${this.props.match.params.exId}`)
            .then(resp => {
                console.log('reaERTYp', resp.responseData.exhibitionDetails)
                this.setState({ exhibitionDetails: resp?.responseData?.exhibitionDetails })

            }).catch(err => {
                this.setState({ isLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
                this.setState({ isLoader: false })
            })
    }


    handleMedia = (data, urll) => {        
        const {media, url} = data
        let formData = new FormData() 
        formData.append('exhibitionId', this.props.match.params.exId)
        formData.append('media', media? media: '' )
        formData.append('exhibitionUrl', url? url: '' )
        
        _Api(Url.ADD_MEDIA.method, Url.ADD_MEDIA.url, formData)
            .then(resp => {
                console.log('delete', resp.responseData)
                notify('success', resp.responseData.message)
                // this.setState({ visible: false, isEdit:false })
                this.getMedia()
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
            })    

}

getMedia = ()=>{
    _Api(Url.GET_MEDIA.method, Url.GET_MEDIA.url, '', `?exhibitionId=${this.props.match.params.exId}&limit=${3}&page=${1}`)
        .then(resp => {          
            this.setState({
            mediaData
            : resp.responseData.result
            })          
            // this.setState({ visible: false, isEdit:false })
       
        }).catch(err => {
            // if (err.error)
            //     notify("err", err.error.responseMessage)
            // else if (err)
            //     // notify("err", err.message)
        })    

}


handleAllMedia = () => {
    localStorage.setItem("mediaType", "exhibitionId")
    localStorage.setItem("mediaId", this.props.match.params.exId)
   
}



    render() {
        let { exhibitionDetails } = this.state
        console.log("cdoijsuhbjcsdil", exhibitionDetails)
        return (
            <>
                <div className="dash_body_container">
                    <Header />
                    <Sidebar />
                    <div className="body_container">


                        <div className="header-text  mb-4 mt-3">
                            <div className="d-flex align-items-center">
                            <p className="header-icon"><ExhibitionManagement /></p>

                             <h4>{exhibitionDetails?.exhibitionName}</h4>
                            </div>

                            <a > <button onClick={()=> this.props.history.goBack()} className="btn btn-light"><i class="fa fa-arrow-left" aria-hidden="true"></i>Back</button></a>


                        </div>

                        <div className="rounded-body-form">
                       
                            <div className="header-container mt-0">
                                    <div class="header-text d-flex w-100 justify-content-betweenmb-4 mt-3">
                                        <div class="d-flex flex-grow-1 align-items-center">
                                            <h4>Exhibition management details</h4>
                                        </div>
                                        <div class="">
                                            {/* <Link to="#" className="btn btn-blue-large mr-2">View Analytics</Link> */}
                                            <Link to={`/exhibition/edit/${this.props.match.params.exId}`} className="btn btn-red">Edit</Link>
                                        </div>
                                    </div>
                            </div>
                                <div className="table-responsive">
                                    <table className="table vertical-table table-bordered rounded-table">

                                        <tr>
                                            <th>Exhibition Name</th>
                                            <td>{exhibitionDetails.exhibitionName}</td>
                                            <th>Exhibition type</th> <td colSpan="3">{exhibitionDetails.eventType == 1 ? 'Hybrid'  :'Online'}</td>
                                        </tr>
                                        {/* <tr>
                                            <th>Live Date</th>
                                            <td>{moment.unix(exhibitionDetails.live_Date).format('dddd')} {moment.unix(exhibitionDetails.live_Date).format('LL')}</td><th>Start Time</th><td>{moment(exhibitionDetails.liveTime).format('LT')}</td>
                                        </tr> */}
                                        <tr>
                                            <th>Start Date</th>
                                            <td>{moment.unix(exhibitionDetails.start_Date).format('dddd')} {moment.unix(exhibitionDetails.start_Date).format('LL')}</td><th>Start Time</th><td>{moment(exhibitionDetails.startTime).format('LT')}</td>
                                        </tr>
                                        <tr>
                                            <th>End Date</th>
                                            <td>{moment.unix(exhibitionDetails.end_Date).format('dddd')} {moment.unix(exhibitionDetails.end_Date).format('LL')}</td><th>End Time</th><td>{ moment(exhibitionDetails.endTime).format('LT')}</td>
                                        </tr>                                     

                                    </table> 
                                </div>

                            {/* <div className="table-responsive">
                                <table className="table vertical-table table-bordered rounded-table">

                                    <tr>
                                        <th>Exhibition Name</th>
                                        <td>{exhibitionDetails.exhibitionName}</td><th>Live Date</th><td>{moment(exhibitionDetails.live_Date).format('dddd')} {moment(exhibitionDetails.live_Date).format('LL')}</td>
                                    </tr>
                                    <tr>
                                        <th>Start Date</th>
                                        <td>{moment.unix(exhibitionDetails.start_Date).format('dddd')} {moment(exhibitionDetails.start_Date).format('LL')}</td><th>Start Time</th><td>{moment(exhibitionDetails.start_Time).format('LT')}</td>
                                    </tr>
                                    <tr>
                                        <th>End Date</th>
                                        <td>{moment(exhibitionDetails.end_Date).format('dddd')} {moment(exhibitionDetails.end_Date).format('LL')}</td><th>End Time</th><td>{moment(exhibitionDetails.end_Time).format('LT')}</td>
                                    </tr>
                                    <tr>
                                        <th>Exhibition Fee</th>
                                        <td colSpan="3">$ {exhibitionDetails.exhibitionFee}</td>
                                    </tr>

                                </table>
                            </div> */}

                            {/* <div className="header-container">
                                <div className="d-flex align-items-center">
                                    <h4>Conference Details</h4>
                                </div>
                            </div> */}

                            <ConferenceDetails {...this.props} />




                            {/* <div className="header-container">
                                <div className="d-flex align-items-center">
                                    <h4>Networking Lounge</h4>
                                </div>
                            </div> */}

                            <NetworkLounge {...this.props} />


                            {/* <div className="header-container">
                                <div className="d-flex align-items-center">
                                    <h4>Brands</h4>
                                </div>
                            </div> */}

                            <Brands {...this.props} />


                            {/* <div className="header-container">
                                <div className="d-flex align-items-center">
                                    <h4>Media</h4>
                                </div>
                            </div> */}
                            {/* <div className="images-video mt-5"> 
                            <Media
                              {...this.props}
                              mediaData = {this.state.mediaData}
                               getMedia = {(data) =>this.handleMedia(data)}
                               onDeleteMedia = {this.getMedia}
                            />
                        
                            </div> */}


                        </div>

                        </div>

                    </div>
                {/* </div> */}
            </>
        );
    }
}
export default ProfessionalBeautyWorld;

const loop = [0, 1, 2, 3]