import React, { Component } from 'react';
import { _Api } from '../../../../services/Api';
import Url from '../../../../services/BaseUrl';
import { notify } from '../../../../components/Toaster';
import { Link } from "react-router-dom"
import moment from 'moment-timezone'

import { notification } from 'antd';
import { TimezoneSelect, clientTz } from 'timezone-select-js';




// components
import {
    ProgressStepBar,
    TopBarStatus,
    ExhibitionDetail,
    ConferenceDetails,
    NetworkLounge,
    Brands,
    MyAlert,
    MySpinner,
} from "../../../../components/index"
import _ from "lodash";


//Add New Exhibition screen
class AddNewExhibition extends Component {
    constructor(props) {
        super(props)
        this.state = {
            exhibitionName: '',
            exhibitionNameError: '',
            startDate: moment(),
            startDateError: '',
            endDateError: '',
            endDate: moment(),
            startTime: moment(),
            startTimeError: '',
            endTime: moment(),
            endTimeError: '',
            exhibitionFee: '',
            exhibitionFeeError: '',
            type: '',
            liveDate: '',
            liveTime: '',
            liveDateError: '',
            liveTimeError: '',
            isChecked: false,
            isLoader: false,

            // new state 
            step: 1,
            isEditExhibition: false,
            allStep: false,
            timezone: clientTz(),
            sectorList:[],
            selectedSectorValue:[]
        }
    }

    componentDidMount() {
        if (this.props.match.params.step) {
            this.setState({ step: Number(this.props.match.params.step) })
        }
        this.getSector();

        if (this.props.match.params.exId)
            this.getParticularExhibition()
    }

    getSector=()=>{
        _Api(Url.GET_SECTOR_LIST.method, Url.GET_SECTOR_LIST.url)
            .then(resp => {
                if (resp.statusCode == 1) {
                    this.setState({
                        sectorList: resp.responseData.result.data,
                    })
                }
            }).catch(err => {
            if (err.error)
                notify("err", err.error.responseMessage)
        })
    }

    handleSectorSelect=(value)=> {
        let selectedSectorValue =[];
        value.map((item)=>selectedSectorValue.push(item))
        this.setState({selectedSectorValue })
    }

    getParticularExhibition = () => {
        let req = {
            exhibtionId: this.props.match.params.exId,
        }
        _Api(Url.GET_EXHIBITION_VIEW_PARTICULAR.method, Url.GET_EXHIBITION_VIEW_PARTICULAR.url, '', `?exhibtionId=${req.exhibtionId}`)
            .then(resp => {
                console.log('respppppp', resp.responseData.exhibitionDetails)
                let params = resp.responseData.exhibitionDetails
                // if (params.exhibitionFee) {
                //     this.setState({ exhibitionFee: params.exhibitionFee, isChecked: true })
                // }
                let selectedSectorValue=[];
                if(params.sectorId&&params.sectorId.length>0){
                    _.map(params.sectorId,function (obj){
                        selectedSectorValue.push(obj._id)
                    })
                }
                this.setState({
                    isChecked : params?.eventType == 1 ? true : false,
                    exhibitionName: params.exhibitionName,
                    startDate: moment.unix(new Date(params.start_Date)),
                    endDate: moment.unix(new Date(params.end_Date)),
                    liveDate: moment.unix(new Date(params.live_Date)),
                    startTime: moment.unix(new Date(params.startTime)),
                    liveTime: moment.unix(new Date(params.liveTime)),
                    endTime: moment.unix(new Date(params.endTime)),
                    isEditExhibition: true,
                    selectedSectorValue:selectedSectorValue,
                    timezone:params?.timezone
                    // allStep:true
                })
            }).catch(err => {
                // this.props.history.push('/seminardetails')
                // if (err.error)
                // notify("err", err.error.responseMessage)
                // else if (err)
                // // notify("err", err.message)
            })
    }

    //exhibitaion onhange
    handleOnChangeExhibition = (e) => {
        console.log("dkasdjk", e)
        this.setState({ [e.target.name]: e.target.value, exhibitionNameError: '', exhibitionFeeError: '' })
    }

    handleChangeTimeZone = (value) => {
        // console.log("kjfhdjsklf", )
        this.setState({ timezone: value.value })
    }


    //onChange 
    handleOnChange = (type, e) => {
        this.setState({ [type]: e, startDateError: '', endDateError: '', startTimeError: '', endTimeError: "", liveDateError: '', liveTimeError: '' })
    }
    //onChange 
    handleOnChangeDate = (type, e) => {
        this.setState({ [type]: e, endDate: e, startDateError: '', endDateError: '', startTimeError: '', endTimeError: "", liveDateError: '', liveTimeError: '' })
    }


    onChangeDate = (value, dateString) => {      
        if (value)
            this.setState({ startDate: value[0] , startTime: value[0],  endDate: value[1], endTime: value[1] }, () => {
                console.log("abcdefghij", this.state.startDate, this.state.endDate)
            })
    }
  


    //Create exhibition submit 
    exhibitionSubmit = () => {
        let reg1 = /^\d+$/;
        let { isChecked, exhibitionName, startDate, endDate, liveDate, liveTime, startTime, endTime, exhibitionFee } = this.state
        if (exhibitionName == '')
            this.setState({ exhibitionNameError: '*Please enter exhibition name.' })
        else if (startDate == '')
            this.setState({ startDateError: '*Please enter start date.' })
        else if (startTime == '')
            this.setState({ startTimeError: '*Please enter start time.' })
        else if (endDate == '')
            this.setState({ endDateError: '*Please enter end date.' })
        else if (endTime == '')
            this.setState({ endTimeError: '*Please enter end time.' }) 

      
        else {
            this.setState({ isLoader: true })
            let req = {
                eventType:isChecked? 1 : 0,
                exhibitionName: exhibitionName, 
                start_Date: ((Date.parse(startDate)) / 1000).toString(),
                startTime: startTime,
                end_Date: (Date.parse(endDate) / 1000).toString(),
                endTime: endTime,
                live_Date: ((Date.parse(startDate)) / 1000).toString(),
                liveTime: startTime,
                timezone:this.state.timezone,
                // exhibitionFee: (!isChecked ? 0 : exhibitionFee).toString(),
               
            }
            if(this.state.selectedSectorValue.length>0){
                req.sectorId=this.state.selectedSectorValue.toString();
            }
            _Api(Url.ADD_EXHIBITION.method, Url.ADD_EXHIBITION.url, req)
                .then(resp => {
                    this.props.history.push(`/addexhibition/exhibitiondetails/2/${resp.responseData?.exhibtionDetails?._id}`)
                    this.setState({ isLoader: false, step: 2, isEditExhibition: true })

                }).catch(err => {
                    this.setState({ isLoader: false })
                    if (err.error)
                        notify("err", err.error.responseMessage)
                    else if (err)
                        this.setState({ isLoader: false })
                    // notify("err", err.message)
                })
        }
    }

    exhibitionEditSubmit = () => {
        let { isChecked, exhibitionName, startDate, endDate, liveDate, liveTime, startTime, endTime, exhibitionFee } = this.state
        if (exhibitionName == '')
            this.setState({ exhibitionNameError: '*Please enter Exhibition name.' })
        else if (startDate == '')
            this.setState({ startDateError: '*Please enter start date.' })
        else if (startTime == '')
            this.setState({ startTimeError: '*Please enter start time.' })
        else if (endDate == '')
            this.setState({ endDateError: '*Please enter end date.' })
        else if (endTime == '')
            this.setState({ endTimeError: '*Please enter end time.' })
        // else if (liveDate == '')
        //     this.setState({ liveDateError: '*Please enter end time.' })
        // else if (liveTime == '')
        //     this.setState({ liveTimeError: '*Please enter live time.' })
      
        else {
            this.setState({ isLoader: true })
            let req = {
                eventType:isChecked? 1 : 0,
                exhibtionId: this.props.match.params.exId,
                exhibitionName: exhibitionName,
                start_Date: (Date.parse(startDate) / 1000).toString(),
                startTime: startTime,
                end_Date: (Date.parse(endDate) / 1000).toString(),
                endTime: endTime,
                live_Date: (Date.parse(startDate) / 1000).toString(),
                liveTime: startTime,
                timezone:this.state.timezone,
                // exhibitionFee: (!isChecked ? "0" : exhibitionFee).toString(),
              
            }
            _Api(Url.EDIT_EXHIBITION.method, Url.EDIT_EXHIBITION.url, req)
                .then(resp => {
                    this.setState({ isLoader: false, step: 2, isEditExhibition: true })
                    this.props.history.push(`/addexhibition/exhibitiondetails/2/${this.props.match.params.exId}`)
                }).catch(err => {
                    this.setState({ isLoader: false })
                    // this.props.history.push('/seminardetails')
                    if (err.error)
                        notify("err", err.error.responseMessage)
                    // else if (err)
                        // notify("err", err.message)
                })
        }
    }

    //for check box
    handleChangedChecked = () => {
        this.setState({ isChecked: !this.state.isChecked })
    }


    // getComponent with step
    getCmp = (props, state, self) => {
        switch (this.state.step) {
            case 1:
                return <ExhibitionDetail {...props} {...state} {...self} />
            case 2:
                return <ConferenceDetails {...props} {...state} {...self} />
            case 3:
                return <NetworkLounge {...props} {...state} {...self} />
            case 4:
                return <Brands {...props} {...state} {...self} />
            default:
                return <ExhibitionDetail {...props} {...state} {...self} />
        }
    }


    goToNext = () => {
        if (this.state.step == 1 && this.state.isEditExhibition == false) {
            this.exhibitionSubmit()
        }
        else if (this.state.step == 1 && this.state.isEditExhibition) {
            this.exhibitionEditSubmit()
        }
        else {
            this.setState({ step: this.state.step + 1 }, () => {
                this.props.history.push(`/addexhibition/exhibitiondetails/${this.state.step}/${this.props.match.params?.exId}`)
            })

        }
    }

    goToBack = () => {
        if (this.state.step > 1)
            this.setState({ step: this.state.step - 1 }, () => {
                this.props.history.push(`/addexhibition/exhibitiondetails/${this.state.step}/${this.props.match.params?.exId}`)
            })
    }




    render() {
        let { isLoader } = this.state
        //console.log("this.state",this.state);
     
        return (
            <>
                <div className="dash_body_container">
                    {/* {isLoader && <Loader />} */}
                    <div className="body_container">
                        <TopBarStatus
                            {...this.props} {...this.state} {...this}
                        />

                        <div className="rounded-body-form">
                            <ProgressStepBar
                                {...this.props} {...this.state} {...this} mapper="exhibition"
                            />

                            {this.getCmp(this.props, this.state, this)}
                            {/* exhibition-btn-wrapper */}
                            <div className="">
                                <div className={this.state.step == 1 ? "text-right" : "d-flex justify-content-between pt-4"}>
                                    {this.state.step != 1 && <button onClick={this.goToBack} className="btn btn-secondary">Previous</button>}
                                    {
                                        this.state.step == 4 ?
                                            <Link to="/exhibition" className="btn btn-primary" type='button' >Submit
                                            </Link>
                                            :
                                            <button className="btn btn-primary " disabled={this.state.isLoader} type='button' onClick={this.goToNext}>Next
                                            &nbsp;
                                    {this.state.isLoader &&
                                                    <MySpinner
                                                    />}
                                            </button>
                                    }
                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </>
        );
    }
}
export default AddNewExhibition;