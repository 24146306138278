import React, { Component, useState } from 'react';
import { Logout, CircleTickFill, Hert, CircleCross, WhatWeDeliverTraingle, LandingUser, LandingBrand, LandingPremiumBrand, HertBorder } from '../../SvgIcon';
import { injectStripe } from 'react-stripe-elements'
import { Link } from "react-router-dom";
import ReactMomentCountDown from 'react-moment-countdown';
import InlineError from "../../components/InlineError";
import moment from 'moment-timezone';
import Cookies from 'js-cookie'
import { _Api } from '../../services/Api';
import Url from '../../services/BaseUrl';
import YouTube from 'react-youtube';
import WebHeader from '../layout/WebHeader'
import { connect } from "react-redux";
import { notify } from '../../components/Toaster';
import Question from "../eventQuestionnaire/Question";
import { userActions } from "../../redux/action";
import { Modal, Button, Tabs } from 'antd';
import { validateEmail } from '../../components/Validation';
import Footer from '../layout/Footer';
import Const from "../../services/Const"


const currentDate = moment();
const { TabPane } = Tabs;

//Landing page 
const { planPrice, eventDate } = Const
var countDownDate = eventDate
class Plan_landing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            days: '',
            hours: '',
            minutes: '',
            seconds: '',
            totalAmount: '$1000.00',
            isPlan: false,
            visible: false,
            emailName: '',
            emailNameError: '',
            emailvalueForLabel: [],
            plans: [],
            sideList: []

        }
        window.scrollTo(0, 0);

    }

    componentDidMount() {
        if (localStorage.getItem('accessToken') && localStorage.getItem('accessToken') !== '') {
            this.props.dispatch(userActions.profile());
            this.getPlan()
            this.getPlan2()
            // window.location.href="/home"
            setInterval(() => this.time(), 1000);
        } else {
            this.props.history.push('/')
        }
        window.addEventListener('scroll', () => {
            let activeClass = 'fixed-header';
            if (window.scrollY === 0) {
                activeClass = 'top-RFheader';
            }
            this.setState({ activeClass });
        });
    }
    time = () => {

        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        this.setState({ days: days, hours: hours, minutes: minutes, seconds: seconds })

    }


    goToNext = (amount) => {
        Cookies.set("amt_plan", JSON.stringify(amount))
        // this.props.history.push("/payment")
        this.props.history.push("/pay")
    }


    getPlan2 = () => {

        _Api(Url.GET_PLAN_USER_SIDE_LIST.method, Url.GET_PLAN_USER_SIDE_LIST.url, '')
            .then(resp => {
                console.log("pankajyadav", resp.responseData.result)
                this.setState({
                    sideList: resp.responseData.result

                })
            }).catch(err => { })
    }



    getPlan = () => {

        _Api(Url.GET_PLAN_USER_SIDE.method, Url.GET_PLAN_USER_SIDE.url, '')
            .then(resp => {
                console.log("pankajyadav", resp.responseData.result)
                this.setState({
                    plans: resp.responseData.result

                })
            }).catch(err => { })
    }


    getRole = () => {
        _Api(Url.GET_ROLE.method, Url.GET_ROLE.url, '')
            .then(resp => {
                if (resp.statusCode == 1) {

                    this.setState({ isPlan: resp.responseData.result?.userPlan == 1 ? false : true })

                }
                else { }
            }).catch(err => { })
    }
    onAddEmail = (e) => {
        if (!validateEmail(e.target.value).status) {
            this.setState({ emailName: e.target.value, emailError: "Please enter valid email" });

        }
        else {
            this.setState({ emailName: e.target.value, emailError: "" });
        }


    }
    getCollleaguesList = () => {
        let label = this.state.emailvalueForLabel;
        if (this.state.emailName == '') {
            this.setState({ emailvalueForLabel: label, emailError: "Please enter valid email" });
        }
        else if (this.state.emailName) {
            label.push(this.state.emailName);
            this.setState({ emailvalueForLabel: label, emailName: "" });
        }




    }
    submitCollleaguesList = () => {
        let label = this.state.emailvalueForLabel;
        console.log("pankajjj", this.state.emailvalueForLabel.length == 0 && this.state.emailName == '')
        if (this.state.emailvalueForLabel.length == 0 && this.state.emailName == '') {
            this.setState({ emailError: "Please enter valid email" });
        }
        else {
            let req = {
                email: this.state.emailvalueForLabel != "" ? this.state.emailvalueForLabel.join() : this.state.emailName
            }

            _Api(Url.GET_REGISTER_COLLEAGUES.method, Url.GET_REGISTER_COLLEAGUES.url, req)
                .then(resp => {
                    console.log("resres", resp)
                    //notify('success', resp.responseData.message)
                    this.setState({ isLoader: false, isSubmitted: true })
                }).catch(err => {
                    this.setState({ isLoader: false })

                })

        }

    }


    registerOther = () => {
        localStorage.clear();
        window.location.href = "/signup"
    }

    showAll = () => {
        localStorage.setItem("showAll", "true")
        window.location.href = "/home"
        //window.location.reload()
    }

    logout = () => {
        localStorage.clear();
        window.location.href = "/"
    }

    handleCancel = () => {
        this.setState({
            visible: false,
            emailName: '',
            emailvalueForLabel: []
        });
    };
    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    hideModal = () => {
        this.setState({
            visible: false,
        });
    };

    removeSelectedEmail = (email) => {
        let stateEmail = this.state.emailvalueForLabel
        let index = stateEmail.indexOf(email);
        if (index > -1) {
            stateEmail.splice(index, 1);
        }
        this.setState({ emailvalueForLabel: stateEmail });
    }

    backCancel = () => {
        this.setState({
            isSubmitted: false,
            visible: false,
            emailvalueForLabel: []
        });
    }

    getButtOn = (matched) => {
        switch (matched) {
            case 0:
                return <CircleTickFill />
            case 1:
                return <CircleCross />
            case 2:
                return "prohibited"
            case 3:
                return "unlimited"
            case 4:
                return "limit"
            case 5:
                return "text"
            default:
                return <CircleCross />;
        }
    }


    render() {
        let { days, hours, minutes, seconds } = this.state;

        let isNewUser = this.props?.userDetail?.userPlan == 1
        // let isNewUser=true;
        return (
            <>
                <section className="Home-banner-bg landing-page cm-home brand-home-banner-bg landing-temp-wrp aesthetic hj" >
                    <div className="home-evnt-wrp-bg   home-sectn-new">
                        <div className={`job-landing-header ${this.state.activeClass}`} >
                            <div className="container-fluid">
                                <div className="row align-items-center">
                                    <div className="col-md-4 col-5">
                                        <div className="am-logo-wrp">
                                            <a>
                                                <img src={require('../../assets/images/aesthetic/navbar_logo.jpg')} className="img-fluid" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-8 col-7">
                                        <div className="am-evnt-hdr-right">
                                            <div className=" levebtn top-lgn-btn text-right">
                                                <ul className="list-inline mb-0 p-0">
                                                    <li className="list-inline-item">
                                                        <button type="button" className="btn btn-red logt addbtn" onClick={() => this.logout()}>Logout</button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="evnt-am-wrp pt-4 pt-md-4">
                                <div className="am-hme-caption">
                                    <h2>Your dream job in beauty,<br /> hair, spa or aesthetics<br /> in the UAE starts here…</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                   


                   
                    <div className="container-fluid thnkutext">
                        {isNewUser && <div className="col-sm-12 text-center">
                            <div className="thanku m-auto thnkYou-new-wrp">
                                <h1 className="heading-5 mb-3"><span>Thank you for registering</span></h1>
                                <Link onClick={()=> localStorage.setItem("isComming","true")} to={"/career-profile"} type="button" className="btn btn-red w-40">Upload CV and create Personal Profile</Link>
                              
                                {/* <h6>UPGRADE TO A SHOWCASE</h6> */}

                                <div className="text-center mb-0 mb-md-3">
                                    {/* <Link className="btn btn-red" onClick={() => this.setState({ visible: true })}>TELL A FRIEND</Link> */}
                                   <h5><b>looking to recruit or have access to candidate CV' s? </b></h5> 
                                </div>
                            </div>


                        </div>
                        }
                    </div>


                    <section className="py-3 py-md-4 pb-md-5 selectYour-Type">
                        {isNewUser && <div className="container minusmargin-header am-heading-new text-center mb-5">
                            <h2 className="text-uppercase">UPGRADE TO A SHOWCASE</h2>
                            {/* <p className="home-subheading">Brands and Suppliers choose your plan, starting from just $500.</p> */}
                        </div>}

                        {isNewUser && <div className="container ">

                            <div className="tablediv hidemobile991">
                                <div className="first gray" style={{ border: "none" }}>
                                    <div className="first gray" style={{ width: "100%" }}>

                                        <div className="header">
                                            <h1 className="text-center"><span><LandingBrand /></span></h1>
                                            {/* <h6>Brand Showcase</h6> */}
                                            <h3>{this.state.totalAmount}</h3>
                                        </div>
                                        <div className="tb_body">
                                            {
                                                this.state.sideList.map((item, index) => {
                                                    return (
                                                        <p>{item.name}</p>
                                                    )
                                                })
                                            }

                                        </div>
                                    </div>
                                    {/* <span className="mt-2"><small>* Subject to editorial control. Video to be supplied 1 week ahead of the event** Subject to timing</small></span> */}
                                </div>
                                {
                                    this.state.plans.map((item, index) => {
                                        console.log("ehwjfkm", item)
                                        return (
                                            <div className={(index + 1) % 2 == 0 ? "Second red" : (index + 1) % 3 == 0 ? 'Second blue' : 'Second sky-blue'} style={{ border: "none" }}>
                                                <div className={(index + 1) % 2 == 0 ? "Second red" : (index + 1) % 3 == 0 ? 'Second blue' : 'Second sky-blue'} style={{ width: "100%" }}>
                                                    <div className="header">
                                                        {/* <h1 className="text-center"><span><LandingUser /></span></h1> */}
                                                        <h6>{item.planName}</h6>
                                                        {
                                                            item.planType == 0 ? <h3>Free</h3> : <h3>{item.defaultCurrency == 1 ? `£${item.euroAmount}` : `$${item.dollarAmount}`}</h3>
                                                        }
                                                         {index == 0 && <span className="jobPlnSml">for those activity looking for work</span>}
                                                        {index == 1 && <span className="jobPlnSml">for salons, spas etc</span>}
                                                        {index == 2 && <span className="jobPlnSml">for recruitment, travel agencies etc</span>}
                                                    </div>

                                                    {
                                                        item.optionPlan.map((item2, index2) => {
                                                            return (
                                                                <p><span className="correct">{item2.type == 5 ? item2.value : item2.type == 4 ? item2.value : this.getButtOn(item2.type)}</span></p>

                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div className="btn-parent mt-2">
                                                    {index !=0 && item.planType != 0 && <button onClick={() => this.goToNext({ defaultVatText: item.defaultVatText, defaultCurrency: item.defaultCurrency, usdVat: item.dollarVatAmount, gbpVat: item.euroVatAmount, usd: item.dollarAmount, gbp: item.euroAmount, purchased: 0, planId: item._id })} type="button" className="btn btn-red w-75">Buy Now</button>}
                                                </div>
                                            </div>
                                        )
                                    })
                                }




                            </div>
                            {/* -----------------------for responsive------------------ */}
                            <div className="showmobile991 plan-responsive">

                                <Tabs defaultActiveKey="1" className="home-tab">
                                    {
                                        this.state.plans?.map((item, index) => {
                                            return (
                                                <TabPane tab={item.planName} key={index + 1}>
                                                    <div className="table-responsivee">
                                                        <table className="table table-bordered rounded-table">
                                                            <thead>
                                                                <td colSpan="2">
                                                                    <div className="header" >
                                                                        <h6 className="text-light-black">{item.planName}</h6>
                                                                        {
                                                                            item.planType == 0 ? <h3 className="text-light-black">Free</h3> : <h3 className="text-light-black">{item.defaultCurrency == 1 ? `£${item.euroAmount}` : `$${item.dollarAmount}`}</h3>
                                                                        }
                                                                          {index == 0 && <span className="jobPlnSml">for those activity looking for work</span>}

                                                                        {index == 1 && <span className="jobPlnSml">for salons, spas etc</span>}
                                                                        {index == 2 && <span className="jobPlnSml">for recruitment, travel agencies etc</span>}

                                                                    </div>
                                                                </td>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    item.optionPlan.map((item2, index2) => {
                                                                        return (
                                                                            <tr>
                                                                                <td> <p>
                                                                                    {this.state.sideList[index2]?.name}
                                                                                </p></td>
                                                                                <td><p><span className="correct">{item2.type == 5 ? item2.value : item2.type == 4 ? item2.value : this.getButtOn(item2.type)}</span></p></td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>

                                                        </table>
                                                        <center> <div className="btn-parent mt-2">
                                                            {index !=0 && item.planType != 0 && <button onClick={() => this.goToNext({ defaultVatText: item.defaultVatText, defaultCurrency: item.defaultCurrency, usdVat: item.dollarVatAmount, gbpVat: item.euroVatAmount, usd: item.dollarAmount, gbp: item.euroAmount, purchased: 0, planId: item._id })} type="button" className="btn btn-red w-75">Buy Now</button>}
                                                        </div>
                                                        </center>
                                                    </div>
                                                </TabPane>
                                            )
                                        })
                                    }
                                    {/* <TabPane tab="Operator Showcase" key="2">
                                        <div className="table-responsivee">
                                            <table className="table table-bordered rounded-table red">
                                                <thead>
                                                    <td colSpan="2">
                                                        <div className="header" >
                                                            <h6 className="text-red">Operator Showcase</h6>
                                                            <h3 className="text-red">£{planPrice.gbpPricePremium}</h3>
                                                        </div>
                                                    </td>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td> <p>Access to online expo</p></td>
                                                        <td><p><span className="correct"><CircleTickFill /></span></p></td>
                                                    </tr>

                                                    <tr>
                                                        <td> <p>Access to matchmaking for candidates</p></td>
                                                        <td><p><span className="correct"><CircleTickFill /></span></p></td>
                                                    </tr>
                                                    <tr>
                                                        <td> <p>Upload work experience, qualifications and CV</p></td>
                                                        <td><p><span className="correct"><CircleCross /></span></p></td>
                                                    </tr>
                                                    <tr>
                                                        <td> <p>Access to matchmaking for operators/agencies</p></td>
                                                        <td><p><span className="correct"><CircleTickFill /></span></p></td>
                                                    </tr>
                                                    <tr>
                                                        <td> <p>Access to seminars</p></td>
                                                        <td><p><span className="correct"><CircleTickFill /></span></p></td>
                                                    </tr>
                                                    <tr>
                                                        <td> <p>Access to networking lounges - candidates</p></td>
                                                        <td><p><span className="correct"><CircleTickFill /></span></p></td>
                                                    </tr>
                                                    <tr>
                                                        <td><p>Access to networking lounges - operators/agencies</p></td>
                                                        <td><p><span className="correct"><CircleTickFill /></span></p></td>
                                                    </tr>
                                                    <tr>
                                                        <td> <p>Free seminar lounge access - candidates</p></td>
                                                        <td><p><span className="correct"><CircleTickFill /></span></p></td>
                                                    </tr>
                                                    <tr>
                                                        <td> <p>Free seminar lounge access - operators/agencies </p></td>
                                                        <td><p><span className="correct"><CircleTickFill /></span></p></td>
                                                    </tr>

                                                    <tr>
                                                        <td> <p> Chat and video calling</p></td>
                                                        <td><p><span className="correct"><CircleTickFill /></span></p></td>
                                                    </tr>
                                                    <tr>
                                                        <td> <p>Searches by Candidates</p></td>
                                                        <td> <p>Unlimited</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td> <p>Searches by operators/agencies</p></td>
                                                        <td><p>Unlimited</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td> <p>Linking your company to Job roles or services</p></td>
                                                        <td><p>Up to 10</p></td>
                                                    </tr>

                                                    <tr>
                                                        <td> <p>See who’s contacted you as soon as you enter the site</p></td>
                                                        <td><p><span className="correct"><CircleTickFill /></span></p></td>
                                                    </tr>
                                                    <tr>
                                                        <td> <p>Upload jobs and services</p></td>
                                                        <td><p>8</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td> <p> Upload videos to your profile</p></td>
                                                        <td><p>3</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td> <p>Upload training courses</p></td>
                                                        <td><p>2</p></td>
                                                    </tr>

                                                    <tr>
                                                        <td> <p> Upload documents candidates can download</p></td>
                                                        <td><p>2</p></td>
                                                    </tr>


                                                    <tr>
                                                        <td> <p>Your logo rotates on home page and appears on other pages</p></td>
                                                        <td><p>Optional – $250</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td> <p>Your company appears at the top in six product search categories</p></td>
                                                        <td><p><span className="correct"><CircleCross /></span></p></td>
                                                    </tr>
                                                    <tr>
                                                        <td><p>Featured jobs or services</p></td>
                                                        <td><p>2</p></td>
                                                    </tr>

                                                    <tr>
                                                        <td> <p>Revolving logo placed under the conference seminars linking to showcase</p></td>
                                                        <td><p>Optional – $100</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td> <p>Your brand logo recognised in pre show marketing*</p></td>
                                                        <td><p>Inquire for cost</p></td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="Agency Showcase" key="3">
                                        <div className="table-responsivee">
                                            <table className="table table-bordered rounded-table blue">
                                                <thead>
                                                    <td colSpan="2">
                                                        <div className="header" >
                                                            <h6 className="text-black">Agency Showcase</h6>
                                                            <h3 className="text-black">£{planPrice.gbpPricePremium}</h3>
                                                        </div>
                                                    </td>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td> <p>Access to online expo</p></td>
                                                        <td><p><span className="correct"><CircleTickFill /></span></p></td>
                                                    </tr>

                                                    <tr>
                                                        <td> <p>Access to matchmaking for candidates</p></td>
                                                        <td><p><span className="correct"><CircleTickFill /></span></p></td>
                                                    </tr>
                                                    <tr>
                                                        <td> <p>Upload work experience, qualifications and CV</p></td>
                                                        <td><p><span className="correct"><CircleCross /></span></p></td>
                                                    </tr>
                                                    <tr>
                                                        <td> <p>Access to matchmaking for operators/agencies</p></td>
                                                        <td><p><span className="correct"><CircleTickFill /></span></p></td>
                                                    </tr>
                                                    <tr>
                                                        <td> <p>Access to seminars</p></td>
                                                        <td><p><span className="correct"><CircleTickFill /></span></p></td>
                                                    </tr>
                                                    <tr>
                                                        <td> <p>Access to networking lounges - candidates</p></td>
                                                        <td><p><span className="correct"><CircleTickFill /></span></p></td>
                                                    </tr>
                                                    <tr>
                                                        <td><p>Access to networking lounges - operators/agencies</p></td>
                                                        <td><p><span className="correct"><CircleTickFill /></span></p></td>
                                                    </tr>
                                                    <tr>
                                                        <td> <p>Free seminar lounge access - candidates</p></td>
                                                        <td><p><span className="correct"><CircleTickFill /></span></p></td>
                                                    </tr>
                                                    <tr>
                                                        <td> <p>Free seminar lounge access - operators/agencies </p></td>
                                                        <td><p><span className="correct"><CircleTickFill /></span></p></td>
                                                    </tr>

                                                    <tr>
                                                        <td> <p> Chat and video calling</p></td>
                                                        <td><p><span className="correct"><CircleTickFill /></span></p></td>
                                                    </tr>
                                                    <tr>
                                                        <td> <p>Searches by Candidates</p></td>
                                                        <td> <p>Unlimited</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td> <p>Searches by operators/agencies</p></td>
                                                        <td><p>Unlimited</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td> <p>Linking your company to Job roles or services</p></td>
                                                        <td><p>Up to 20</p></td>
                                                    </tr>

                                                    <tr>
                                                        <td> <p>See who’s contacted you as soon as you enter the site</p></td>
                                                        <td><p><span className="correct"><CircleTickFill /></span></p></td>
                                                    </tr>
                                                    <tr>
                                                        <td> <p>Upload jobs and services</p></td>
                                                        <td><p>Unlimited</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td> <p> Upload videos to your profile</p></td>
                                                        <td><p>6</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td> <p>Upload training courses</p></td>
                                                        <td><p>20</p></td>
                                                    </tr>

                                                    <tr>
                                                        <td> <p> Upload documents candidates can download</p></td>
                                                        <td><p>10</p></td>
                                                    </tr>


                                                    <tr>
                                                        <td> <p>Your logo rotates on home page and appears on other pages</p></td>
                                                        <td><p><span className="correct"><CircleTickFill /></span></p></td>
                                                    </tr>
                                                    <tr>
                                                        <td> <p>Your company appears at the top in six product search categories</p></td>
                                                        <td><p><span className="correct"><CircleTickFill /></span></p></td>
                                                    </tr>
                                                    <tr>
                                                        <td><p>Featured jobs or services</p></td>
                                                        <td><p>5</p></td>
                                                    </tr>

                                                    <tr>
                                                        <td> <p>Revolving logo placed under the conference seminars linking to showcase</p></td>
                                                        <td><p><span className="correct"><CircleTickFill /></span></p></td>
                                                    </tr>
                                                    <tr>
                                                        <td> <p>Your brand logo recognised in pre show marketing*</p></td>
                                                        <td><p><span className="correct"><CircleTickFill /></span></p></td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>
                                    </TabPane> */}
                                </Tabs>

                            </div>

                        </div>
                        }
                    </section>

                    <br />


                    {/* <div className="container-fluid plan-feture chs-plan-wrp light_blue mt-3">
                        {isNewUser && <div className="am-heading-new text-center">
                            <h2 className="text-uppercase">Choose your plan to…</h2>
                        </div>}
                        <div className="row mt-3 text-center sect-2 justify-content-center">
                            {
                                Plans.map((item, i) => {
                                    return (
                                        <div className="col-xs-4 col-sm-6 col-md-4 feature" key={"spk" + i}>
                                            <div className="features-card chs-plancrd-wrp">
                                                <div className="speaker-profile-img ">
                                                    <img className="img-fluid" src={require(`../../assets/images/aesthetic/${item.img}`)} />
                                                </div>
                                                <div className="card-body">
                                                    <h5 style={{textTransform: "unset"}}>{item.text}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div> */}
                    <div className="container-fluid">


                        <div className="row ">
                            <div className="col-md-10 offset-sm-1 mt-2 mt-md-4">
                                <div className="thankyou-text">
                                    <div className="callu_parent">
                                        <div className="text-dark">
                                            <span onClick={this.showAll}> If you </span> have any questions in the meantime please do not hesitate
                                                to get in touch with the Worldwide Job Finder team on
                                </div>
                                        <div>
                                            <span className="hert-phone">
                                                {/* <img src={require('../../assets/images/hert-phone.png')} className="img-fluid" /> */}
                                            </span>
                                            <a className="colorW" href="mailto:info@worldwidejobfinder.com">info@worldwidejobfinder.com</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <Footer />

                </section>
                {/* <Question /> */}
                <Modal
                    wrapClassName="mail_modal"
                    className="cm_popup signup_modal "
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    centered
                    footer={null}
                >
                    <div className="sign-modal-content">
                        {!this.state.isSubmitted && <div className="row align-items-center">
                            <div className="col-md-12" >
                                <h3 className="auth-heading pos-relative text-center mt-5">
                                    Tell your colleague(s)<span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                </h3>
                                <form className="rounded-textbox-form mt-5" >
                                    <div className="form-group">
                                        <h4> Enter their email address</h4>
                                        <a>When entering multiple email addresses separate with a comma.</a>

                                        <div class="search-container d-flex mt-4">
                                            <input type="text" placeholder="Enter email" value={this.state.emailName} name="promocodeTitle" onChange={(e) => this.onAddEmail(e)} class="form-control" />
                                            {!this.state.emailError &&
                                                <button type="button" class="btn" onClick={(e) => this.getCollleaguesList()}>Add</button>}
                                        </div>
                                        <InlineError
                                            message={this.state.emailError}
                                        />

                                        <div className="mt-4 d-flex flex-wrap">
                                            {this.state.emailvalueForLabel.map((item) => <span className="mailtag">{item}<span className="mailtag-cross" aria-hidden="true" onClick={(e) => this.removeSelectedEmail(item)}>&times;</span></span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button type="button" className="btn btn-red" onClick={(e) => this.submitCollleaguesList()}>Submit</button>
                                    </div>
                                </form>

                            </div>

                        </div>}
                        {this.state.isSubmitted &&
                            <div className="row align-items-center">
                                <div className="col-md-12 pt-5">
                                    <h3 className="auth-heading pos-relative text-center mt-5">
                                        Email sent <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                    </h3>
                                    <h4 className="text-center mt-5">Your colleague will receive an email invitation. Please tell them to check their inbox (and junk folder)</h4>
                                    <div className="text-center mt-5">
                                        <button type="submit" disabled={this.props.isLoading} className="btn btn-red" onClick={this.backCancel}>Back</button>
                                    </div>

                                </div>
                            </div>
                        }
                    </div>

                </Modal>

            </>
        )
    }
}
//export default Plan_landing;
function mapStateToProps(state) {
    console.log("dkjahdjk", state)
    return {
        ...state
    };
}

export default connect(mapStateToProps)(Plan_landing);

const Plans = [
    {
        img: 'plan1.jpeg',
        text: 'Reach more practitioners with higher brand exposure'
    },
    {
        img: 'plan2.jpeg',
        text: 'Gain exposure with your logo displayed on conference pages linking to brand showcase'
    },
    {
        img: 'plan3.jpeg',
        text: 'Meet new and existing clients and colleagues in lounges'
    }

]
