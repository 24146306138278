import React, { Component } from 'react';
import { Brands, Pencil, Delete } from '../../../SvgIcon';
import { Link } from "react-router-dom";
import { DatePicker, TimePicker, Empty } from 'antd';
import moment from 'moment-timezone';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';
// import { Modal, Button } from 'react-bootstrap';

import Loader from '../../../components/Loader'
import { notification } from 'antd';

import { Modal, Button, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { BrandLoader, Pagination } from "../../index"

import {getTimeFormate} from "../../../utils/DateZone"
import {ADD_BRAND} from "../../../redux/action";
import {connect} from "react-redux";

const { confirm } = Modal;

class Brandss extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // brands
            isDeleted: '',
            exhibtionId: '',
            status: "",
            page: 1,
            limit: 10,
            loading: false,
            visible: false,
            exhibitionListData: [],
            selectedId: '',
            isLoader: false,
            search: '',
            brandListData: [],
            isEditExhibition: false,
            snNo: 0,
            isDownload: 0

        }
    }


    componentDidMount() {
        this.getBrandList()
    }

    /** brands start */

    getBrandList = (isLoader = true) => {
        this.setState({ isLoader: isLoader })
        let { page, limit, search, isDownload } = this.state
        let req = {
            page: page ? page : 1,
            limit: limit,
            search: search.toString()
        }

        

        _Api(Url.GET_USER_LIST.method, Url.GET_USER_LIST.url, '', `?page=${req.page}&limit=${req.limit}&search=${req.search}&download=${isDownload}&exhibitionId=${this.props?.match?.params?.exId ? this.props?.match?.params?.exId : ''}`)
            .then(resp => {
                if (resp.responseData.file) {
                    window.open(resp.responseData.file, "_blank");
                    this.setState({ isLoader: false, isDownload: 0 });
                }

               else if (this.props.match.params.exId)
                    this.setState({ brandListData: resp.responseData?.result?.data.map(item => ({ ...item, ...item?.brandId })), totalCount: resp.responseData?.result?.totalCount, isLoader: false })
                else
                    this.setState({ brandListData: resp.responseData?.result?.data, totalCount: resp.responseData?.result?.totalCount, isLoader: false })

            }).catch(err => {
                this.setState({ isLoader: false })
                // if (err.error)
                //     notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
                // this.setState({ isLoader: false })
            })
    }

    handlePageChange = page => this.setState({ loader: true, page }, () => this.getBrandList());

    handleSearch = e => this.setState({ search: e.target.value, brandListData: [], page: 1, snNo: 0 }, () => this.getBrandList());


    // delete modal
    showModal = (id) => {
        confirm({
            title: 'Are you sure you want to delete this?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            onOk: () => {
                this.deleteBrandList(id)
            },
            onCancel: () => {
                this.handleCancel()
            },
        });
    }


    //Cancel modal
    handleCancel = () => {
      
        this.setState({ visible: false });
    };





    deleteBrandList = (id) => {
        let req = {
            isDeleted: 0,
            brandId: id,

        }
        _Api(Url.USER_DELETE.method, Url.USER_DELETE.url, '', `?requestUserId=${req.brandId}&exhibitionId=${this.props?.match?.params?.exId ? this.props?.match?.params?.exId : ''}`)
            .then(resp => {
                // notify('success', resp.responseData.message)
                notification.success({ message: 'Success', description: resp.responseData.message });
                this.setState({ visible: false })
                this.getBrandList(false)
            }).catch(err => {
                if (err.error)
                    notification.error({ message: 'Error', description: err.error.responseMessage });
                else if (err)
                    notification.error({ message: 'Error', description: err.error.responseMessage });
            })


    }

    // //Show modal
    // showModal = (id) => {
    //     this.setState({
    //         visible: true,
    //         selectedId: id
    //     });
    // };
    //Cancel modal
    handleCancel = () => {
        this.setState({ visible: false });
    };
    statusBrandList = (e, id) => {
        let req = {
            brandId: id,
            status: (e.target.checked ? 1 : 0).toString(),
        }
        _Api(Url.USER_STATUS.method, Url.USER_STATUS.url, '', `?requestUserId=${req.brandId}&status=${req.status}`)
            .then(resp => {
              
                // notify('success', resp.responseData.message)
                this.setState({ visible: false })
                this.getBrandList(false)
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
            })

    }


    onPressBtn = (page) => {
        this.setState({ page, snNo: page - 1, brandListData: [] }, () => {
            this.getBrandList()
        })

    } 
    exportCSV = () => {
        this.setState({ isDownload: 1 }, () => this.getBrandList());

    }
    

    exportCSV2 = () => {
        // this.setState({ isDownload: 1 }, () => this.getBrandList());
        
        _Api(Url.STAFF_DOWNLOAD.method, Url.STAFF_DOWNLOAD.url, '', `?download=${1}`)
        .then(resp => {
            window.open(resp.responseData.file, "_blank");          
                      
        }).catch(err => {
            if (err.error)
                notify("err", err.error.responseMessage)
           // else if (err)
            //     // notify("err", err.message)
        })


    }

    addBrand=(value)=>{
        this.props.dispatch({ type: ADD_BRAND, value: value });
    }


    render() {

        let id = this.props?.match?.params?.exId ? this.props.match.params.exId : ''
        const { exhibitionListData, visible, loading, search, isLoader, brandListData } = this.state;
        
        return (
            <>
                {

                    <>
                        <div class="header-text d-flex mb-4 mt-3">
                            <div class="d-flex flex-grow-1 align-items-center">
                                {this.props.isShow && <p className="header-icon">
                                    <Brands />
                                </p>
                                }

                                <h4>Businesses</h4>
                            </div>
                            {this.props.isShow && <div class="search_wrapper  mr-2">
                                <i class="fa fa-search"></i>
                                <input type="search" placeholder="Search..." name="search"
                                    onChange={this.handleSearch}
                                />
                            </div>}
                            <div class="">
                                {!this.props.isShow && <Link to={`/assignbusiness/${id}`} className="btn btn-red mr-2">Assign Business</Link>}
                                {this.props.isShow && <Link
                                    // to={`/addbusiness/${id}`}
                                    onClick={()=>this.addBrand(1)}
                                    className="btn btn-red">Add New Business</Link>}
                                {brandListData.length > 0 && <Link onClick={this.exportCSV} className="btn btn-red ml-2">Export CSV</Link>}
                                {brandListData.length > 0 && <Link onClick={this.exportCSV2} className="btn btn-red ml-2"> Staff Export CSV</Link>}
                            </div>
                        </div>

                    </>

                }
                {/* <div className="header-container ">
                    <div class="header-text d-flex w-100 justify-content-betweenmb-4 mt-3">
                        <div class="d-flex flex-grow-1 align-items-center">
                            {!this.props.isShow && <h4>Brands</h4>}
                        </div>
                      
                    </div>
                </div> */}

                <div class="table-responsive">
                    <table class="table table-bordered rounded-table">
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>Company Name</th>
                                <th>Email</th>
                                <th>Business Type</th>
                                <th>No of Job</th>
                                <th>No of Staff</th>
                                <th>Status</th>
                             {this.props.isShow && <th>Company edit</th>}
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoader && <BrandLoader />}
                            {brandListData?.map((item, index) => {
                                //   let  item = resp?.brandId
                               
                                return (
                                    <tr key={item._id}>
                                        <td><span className="circle-serial">{(this.state.snNo * 10) + index + 1}</span></td>
                                        <td> <Link to={`/business/details/${item?._id}/${item?.activeCompany}`} > {item?.companyName ? item?.companyName : '---'}</Link></td>
                                        <td>{item?.email}</td>
                                        <td>{item?.userPlan == 3 ? "Agency Showcase" : "Operator Showcase"}</td>
                                        <td>{item?.productCount}</td>
                                        <td>{item?.staffCount}</td>
                                        <td>
                                            <p className="toggle-parent ">
                                                <label className="switch">
                                                    <input type="checkbox"
                                                        checked={item?.status === 1 ? true : false}
                                                        onChange={(e) => this.statusBrandList(e, item?._id)}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </p>
                                        </td>
                                        {this.props.isShow && <td className="action_group" >
                                         <Link to={`/manage-company/${item?._id}/${item?.activeCompany}`} ><p className="circle-serial"><Pencil /></p></Link>                                     
                                        </td> }  
                                        <td className="action_group">
                                            {this.props.isShow && <Link to={`/user-profile/${item?._id}`}><p><p className="circle-serial"><Pencil /></p></p></Link>}
                                            <p onClick={() => this.showModal(item?._id)}><p className="circle-serial"><Delete /></p></p>
                                          

                                        </td>
                                    </tr>
                                )
                            })

                            }
                        </tbody>
                    </table>
               
                    <div>
                        {!isLoader && brandListData && brandListData.length == 0 &&
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                    </div>
                    {
                        brandListData && brandListData.length != 0 &&
                        <Pagination
                            totalPage={Math.ceil(this.state.totalCount / 10)}
                            currentPage={this.state.page}
                            onPressBtn={this.onPressBtn}
                        />
                    }

             </div>
            </>
        )
    }
}

// export default Brandss
function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(Brandss);