import React, { Component } from 'react';
import { useHistory } from "react-router-dom";
import { SearchIcon } from '../../../SvgIcon';
import { Link } from "react-router-dom";
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { BackBtn } from '../../../components/common/Button'
import InlineError from '../../../components/InlineError';
import { Select, Checkbox } from 'antd';
import Loader from '../../../components/Loader';
import util from '../../../utils/Util.js';
import { notify } from '../../../components/Toaster';
import { TreeSelect } from 'antd';
import _ from "lodash";
import {connect} from "react-redux";

const { Option } = Select;
const { SHOW_PARENT, SHOW_CHILD, SHOW_ALL } = TreeSelect;

const format = 'HH:mm';

//Add New Exhibition screen
class AddQuestionnaire extends Component {
    constructor(props) {
        super(props)
        this.state = {
            exhibitionId: "",
            typeOfQuestion: null,
            question: '',
            questionType: '',
            selectedAnswerOption:"",
            answerCounter : [{answerValue:"" }] ,
            answer : '',
            selectedSectorValue:[],
            sectorDropDown:[],
            perviousQuestionDropDown:[],
            perviousQuestionOption:[]  ,
            perviousQuestionList:[],
            isRelation:0,
            isMandatory:1,
            previousQuestionId:"",
            previousQuestionType:"",
            exhibitionError:'',
            questionError:'',
            questionTypeError:'',
            filterQuestion:'',
            filterQuestionError:''
        }
    }
    componentDidMount() {
        this.getSector();
        //this.getPerviousQuestionList();
        this.getexhibitionList();
    }
    getexhibitionList = () => {
        _Api(Url.PROMO_EXHIBITION_DATA.method, Url.PROMO_EXHIBITION_DATA.url)
            .then(resp => {
                let responseData = resp.responseData.exhibitionDetails;
                this.setState({ exhibitionData: responseData });

            }).catch(err => {
                this.handleError(err);
            });
    }

    getSector = () => {
        _Api(Url.GET_SECTOR_LIST.method, Url.GET_SECTOR_LIST.url)
        .then(resp => {
            console.log('getSector responseData', resp.responseData);
            var sectorDropDown =[]
            let sectorList = resp.responseData.result.data.map((item) => {
                console.log("sectorList sectorList",item)
              sectorDropDown.push(
                <Option key={item._id}>{item.name}</Option>
              )
              
            })
            this.setState({sectorDropDown,isLoader: false});
           
        }).catch(err => {
            this.setState({ isLoader: false })
            if (err.error)
                notify("err", err.error.responseMessage)
            this.setState({ isLoader: false })
        })
    }

    getPerviousQuestionList = () => {
        let req = {
            exhibitionId: this.state.exhibitionId,
        }
    _Api(Url.PREVIOUS_QUESTION_LIST.method, Url.PREVIOUS_QUESTION_LIST.url, '', `?exhibitionId=${req.exhibitionId}`)
        .then(resp => {
            //console.log('PREVIOUS QUESTION LIST', resp.responseData.result);
            var perviousQuestionDropDown =[]
            var perviousQuestionOption=[];
            this.setState({
                perviousQuestionList:resp.responseData.result,
            })
            let perviousQuestionList = resp.responseData.result.map((item) => {
              perviousQuestionDropDown.push(
                <Option key={item._id}>{item.question}</Option>
              )
            })
           this.setState({perviousQuestionDropDown});
           
        }).catch(err => {
            this.setState({ isLoader: false })
            if (err.error)
                notify("err", err.error.responseMessage)
            this.setState({ isLoader: false })
        })
}

    handleOnChangeValue = (e) => {
        let errorStateObject = `${e.target.name}Error`;
        this.setState({ [e.target.name]: e.target.value, questionError:'', [errorStateObject]: "", currencyTypeError: e.target.name == "amount" && !this.state.currencyType && "Currency Type can not be empty" });
    }
    selectOnChange = (type, e) => {
        let self=this;
        //let stateObject = type + 'Error';
        this.setState({
            [type]: e,
            exhibitionError:'',
            questionError:'',
            questionTypeError:''
        },function (){
            if(type==='exhibitionId'){
                self.getPerviousQuestionList();
            }else if(type==='previousQuestionId'){
                let perviousQuestionOption=[]
                let previousQuestion=_.find(self.state.perviousQuestionList,function (ques){
                    return ques._id===self.state.previousQuestionId
                });
                if((previousQuestion.questionType===2)||(previousQuestion.questionType===3)){
                    previousQuestion.option.map((opt)=>{
                        perviousQuestionOption.push(opt.name);
                        self.setState({
                            ["preOpt-"+opt.name]:[""]
                        })
                    })
                }
                self.setState({
                    perviousQuestionOption,
                    previousQuestionType:previousQuestion.questionType
                })
            }
        });
    }

    handleError(err) {
        this.setState({ isLoader: false });
        let errorMessage = err.error && err.error.responseMessage || err.message;
        notify("err", errorMessage);
    }

    onSubmit = () => {
        let self=this;
        let userQuesOpt = [];

        if(this.state.exhibitionId == ""){
            this.setState({exhibitionError:'*Please select exhibition'})

        }
        else if(this.state.question == ''){
            this.setState({questionError:'*Please enter question'})
        }
        else if(this.state.questionType== ''){
            this.setState({questionTypeError:'*Please select question type'})
        }

        

        else {

        if(this.state.isRelation==0){
            this.state.answerCounter.map((item)=> item.answerValue && userQuesOpt.push( {name:item.answerValue}))
        }else{
            if((this.state.previousQuestionType===2)||(this.state.previousQuestionType===3)) {
                let perviousQuestionOption = this.state.perviousQuestionOption
                perviousQuestionOption.map((opt) => {
                    let option = {
                        name: opt,
                        value: self.state["preOpt-" + opt]
                    }
                    userQuesOpt.push(option)
                })
            }
        }

        let param = {
            question: this.state.question,
            filterQuestion:this.state.filterQuestion,
            questionType: this.state.questionType,
            exhibitionId: this.state.exhibitionId ,
            isRelation:this.state.isRelation,
            isMandatory:this.state.isMandatory,
            option:userQuesOpt
        }

        if(this.state.selectedSectorValue.length>0){
            param.category=this.state.selectedSectorValue.join()
        }

        if(this.state.isRelation==1){
            param.previousQuestionId=this.state.previousQuestionId
        }

        _Api(Url.ADMIN_ADD_QUESTION.method, Url.ADMIN_ADD_QUESTION.url, param)
            .then(resp => {
                notify('success', resp.responseData.message);
                
                this.props.history.goBack();
            }).catch(err => {
                this.handleError(err);
            })

        }
    }

   onAddAnswer=(e) => {
       let answerCounter = this.state.answerCounter;
       answerCounter.push({answerValue:"" });
       this.setState({
            answerCounter
       })
   }

   onRemoveAnswer = (index) => {
        let answerCounter = this.state.answerCounter;
        //console.log("index value" , index)
        answerCounter.splice(index, 1);
        this.setState({
            answerCounter
        })
    };
    onChangeSelect = (key, value) => {
        let stateObject = key + 'Error';
        this.setState({
            [key]: value,
            [stateObject]: ""
        });
    }

    handleQuestionSelect(value) {
        let selectedSectorValue =[];
        value.map((item)=>selectedSectorValue.push(item))
        this.setState({selectedSectorValue })
    }

    handleOnChangeAnswer =(e,index)=>{
            let { name, value } = e.target;
          let list = this.state.answerCounter;
          list[index][name] = value;
          this.setState ({answerCounter :list})
    }

    onAddAnswerRelation=(e,preOption) => {
        let answer = this.state["preOpt-"+preOption];
        answer.push("");
        this.setState({
            ["preOpt-"+preOption]:answer
        })
    }

    onRemoveAnswerRelation = (index,preOption) => {
        let answer = this.state["preOpt-"+preOption];
        answer.splice(index, 1);
        this.setState({
            ["preOpt-"+preOption]:answer
        })
    };

    handleOnChangeAnswerRelation =(e,index,preOption)=>{
        let { name, value } = e.target;
        let list = this.state["preOpt-"+preOption];
        list[index] = value;
        this.setState ({
            ["preOpt-"+preOption] :list
        })
    }

    render() {
        const { Option } = Select;
        const {filterQuestion, title, titleError, exhibitionData, exhibitionError, placeOfWorkIdError, dollarAmountError,  searchPlanTypeError } = this.state;
        console.log("this.state",this.state);
        return (

            <>
                <div className="dash_body_container">
                    {/*<Header />*/}
                    {/*<Sidebar />*/}
                    <div className="body_container">
                        <div className="header-text  mb-4 mt-3">
                            <div className="d-flex align-items-center">
                                <p className="header-icon"><SearchIcon /></p>
                                <h4>Add Questionnaire</h4>
                            </div>
                            <BackBtn to="questionnaire" />

                        </div>
                        <div className="rounded-body-form">
                            <form className="cm-form-wrapper add-promo-code">

                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <level>Exhibition Name</level>
                                        <Select
                                            // defaultValue={exhibition}
                                            name='exhibitionId'
                                            placeholder="Select"
                                            onChange={(e) => this.selectOnChange("exhibitionId", e)}
                                        >
                                            {exhibitionData &&
                                                exhibitionData.map((item) => {
                                                    return (<Option value={item._id}>{item.exhibitionName}</Option>)
                                                })
                                            }
                                        </Select>
                                        <InlineError message={exhibitionError} />
                                    </div>
                                </div>
                                
                                <div className="col-lg-6 ">
                                        <div className="form-group">
                                            <level>Sector</level>
                                            {/* <level>Job Title </level> */}
                                             <Select
                                                mode="multiple"
                                                className="cm_multiselect cmslect_multicheck"
                                                placeholder="Please select"
                                                style={{ width: '100%' }}
                                                onChange ={(e) =>this.handleQuestionSelect(e)}
                                            >
                                                {this.state.sectorDropDown}
                                            </Select>
                                            <InlineError
                                                message={placeOfWorkIdError}
                                                //message={ selectorIdError}
                                            />
                                        </div>

                                    </div>
                               
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <level>Sign Up Question</level>
                                        <input type="text"
                                            className="form-control"
                                            placeholder="question"
                                            maxLength={64}
                                            name="question"
                                            value={title}
                                            onChange={this.handleOnChangeValue}
                                        />
                                        <InlineError message={this.state.questionError} />
                                    </div>

                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <level>Filter Question</level>
                                        <input type="text"
                                            className="form-control"
                                            placeholder="question"
                                            maxLength={64}
                                            name="filterQuestion"
                                            value={filterQuestion}
                                            onChange={this.handleOnChangeValue}
                                        />
                                        <InlineError message={this.state.filterQuestionError} />
                                    </div>

                                </div>

                                <div className="col-lg-6 ">

                                    <div className="form-group">
                                        <level>Question Type </level>
                                        <Select
                                            name='questionType'
                                            placeholder="Select"
                                            // value={searchPlanType}
                                            onChange={(e) => this.selectOnChange("questionType", e)}
                                        >
                                            {util.typeOfQuestion.map((item) => {
                                                return (<Option value={item.value}>{item.displayValue}</Option>)
                                            })}
                                        </Select>
                                        <InlineError message={this.state.questionTypeError} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <level>Is this question mandatory</level>
                                        <div className="assign-checkboc-parent">
                                            <div>
                                                <label className="cm_container">
                                                    Yes
                                                    <input checked={2 == this.state.isMandatory} onClick={() => this.setState({ isMandatory: 2 })} type="radio" name="isManYes" />
                                                    <span className="radio-checkmark"></span>
                                                </label>
                                            </div>
                                            <div>
                                                <label className="cm_container">
                                                    No
                                                    <input checked={1 == this.state.isMandatory} onClick={() => this.setState({ isMandatory: 1 })} type="radio" name="isManYes" />
                                                    <span className="radio-checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <level>Is Related to other question ?</level>
                                        <div className="assign-checkboc-parent">
                                            <div>
                                                <label className="cm_container">
                                                    Yes
                                                    <input checked={1 == this.state.isRelation} onClick={(e) => this.setState({ isRelation: 1 })} type="radio" name="isRelYes" />
                                                    <span className="radio-checkmark"></span>
                                                </label>
                                            </div>
                                            <div>
                                                <label className="cm_container">
                                                    No
                                                    <input checked={0 == this.state.isRelation} onClick={(e) => this.setState({ isRelation: 0 })} type="radio" name="isRelNo" />
                                                    <span className="radio-checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                {this.state.isRelation === 1?
                                <>
                                <div className="row">
                                    <div className="col-lg-6 ">
                                        <div className="form-group">
                                            <level>Previous Question</level>
                                            <Select
                                                name='perviousQuestionId'
                                                //className="cm_multiselect cmslect_multicheck"
                                                name='exhibitionId'
                                                placeholder="Select"
                                                onChange={(e) => this.selectOnChange("previousQuestionId", e)}
                                            >
                                                {this.state.perviousQuestionDropDown}
                                            </Select>
                                            
                                            <InlineError message={titleError} />
                                        </div>
                                    </div>
                                </div>
                                
                                {
                                    this.state.questionType!==1&&this.state.questionType!==""&&this.state.perviousQuestionOption.map((preOption, i)=>(
                                  
                                    <>
                                        <div className="row">

                                    <div className="col-lg-6 ">
                                        <div className="form-group">
                                            <level>Previous Options  : </level>
                                            {preOption}
                                            {this.state["preOpt-"+preOption]&&this.state["preOpt-"+preOption].map((x, i)=> (
                                                <div className="row align-items-center">
                                                    <div className="col-lg-6 ">
                                                        <div className="form-group">
                                                            <level>Current Option</level>
                                                            <input type="text"
                                                                   className="form-control"
                                                                   placeholder="Text"
                                                                   name="answerValue"
                                                                    value={x}
                                                                    onChange={(e)=>this.handleOnChangeAnswerRelation(e,i,preOption)}
                                                            />
                                                            {/*<InlineError message={dollarAmountError}/>*/}

                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <level></level>
                                                        {this.state["preOpt-"+preOption].length !== 1 &&
                                                        <button className="btn addsm-btn mr-2" type='button'
                                                                onClick={(e) => this.onRemoveAnswerRelation(i,preOption)}>
                                                            <i className="fa fa-minus" aria-hidden="true"></i>
                                                        </button>}
                                                        {this.state["preOpt-"+preOption].length - 1 === i &&
                                                        <button className="btn addsm-btn" type='button'
                                                                onClick={(e) => this.onAddAnswerRelation(e,preOption)}>
                                                            <i className="fa fa-plus" aria-hidden="true"></i>
                                                        </button>}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                </>
                                    
                                    ))
                                }
                                  

                                </>:( this.state.questionType == 2 || this.state.questionType == 3 )&&

                                    this.state.answerCounter.map((x, i)=>{
                                        return(
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 ">
                                                    <div className="form-group">
                                                        <level>Option : </level>
                                                        <input type="text"
                                                               className="form-control"
                                                               placeholder="Text"
                                                               name="answerValue"
                                                               value={x.answerValue}
                                                               onChange={(e)=>this.handleOnChangeAnswer(e,i)}
                                                        />
                                                        <InlineError message={dollarAmountError} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 ">
                                                    <level></level>
                                                    {this.state.answerCounter.length !== 1 &&  <button className="btn addsm-btn mr-2" type='button' onClick={(e)=> this.onRemoveAnswer(i)} >
                                                        <i class="fa fa-minus" aria-hidden="true"></i>
                                                    </button>}
                                                    {this.state.answerCounter.length - 1 === i && <button className="btn addsm-btn" type='button' onClick={(e)=> this.onAddAnswer(e)} >
                                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                                    </button>
                                                    }
                                                </div>
                                            </div>)

                                    })

                                }
                               
                               {/* -------if is related true or Question Type is 2 or 3 end--------- */}
                                <div className="text-right">
                                    <button className="btn btn-primary " onClick={() => this.onSubmit()} type='button'>Save</button>
                                </div>
                            </form>

                        </div>

                    </div>
                </div>
            </>
        );
    }

}
// export default AddQuestionnaire;

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(AddQuestionnaire);
