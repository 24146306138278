import React, { Component } from 'react';


import { Carousel  } from 'antd';
import {Delete, Pencil} from "../../SvgIcon";
import {Link} from "react-router-dom";


class MediaCarousel extends Component {


    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.carousel = React.createRef();
    }


    next = () => {
        this.carousel.next();
    }
    previous = () => {
        this.carousel.prev();
    }

    render() {

        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,

            responsive: [

                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 2,
                        initialSlide: 2,
                        dots: true,
                        arrows: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        dots: true,
                        arrows: false
                    }
                }
            ]
         

        }
        return (

           
            <div className="carousel-wrapper d-flex">
                <i class="fa fa-long-arrow-left" onClick={this.previous}/>
                <div className="w-95"> 
                <Carousel className="row d-flex " ref={node => (this.carousel = node)} {...settings} >
               
                    {
                            videoLoop.map(item => (
                               
                                    <div className="card video-card">
                                        <iframe
                                            src="https://www.youtube.com/embed/tgbNymZ7vqY">
                                        </iframe>
                                    </div>
                              


                            ))



                        }
                      

                </Carousel>
               </div> 

            <button type="button btn btn-primary h-100" id="get_file" value="Grab file" ref="get_file" onClick={this.getFile} >+</button>
            <input type="file" id="my_file" ref={my_file => this.inputElement = my_file} />


         
                <i class="fa fa-long-arrow-right" onClick={this.next}/>
            </div>


        )

    }
}
export default MediaCarousel;
const videoLoop = [0, 1, 2, 3, 4, 5, 6, 7]


