
import React, { Component } from 'react';
import { Pencil, Delete, ZoomVideo } from '../../../SvgIcon';
import { BtnRed } from "../../../components/common/Button";
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';
import Media from "../../../components/common/Media"
import Brochures from "../../../components/common/Brochures"
import ProductShowcase from "../../../components/common/ProductShowcase";
import IndustrieCarousel from "../../../components/carousel/IndustrieCarousel";
import LazyLoad from 'react-lazy-load';

import { Empty } from "antd"
import {connect} from "react-redux";


class ViewIndustryDetail extends Component {

    constructor() {
        super();
        this.state = {
            addStaff: false,
            addProduct: false,
            editStaff: false,
            page: '',
            limit: 10,
            productListData: [],
            staffId: '',
            loading: false,
            visible: false,
            selectedId: '',
            productId: '',
            brandInfo: ''
        };
    }

    togglePopup(id, staffId) {
        console.log('produwertyuictId', id, staffId)
        if (id === "addStaff") {
            this.setState({
                addStaff: !this.state.addStaff,
            });
        }
        else if (id === "editStaff") {
            this.setState({
                editStaff: !this.state.editStaff, staffId: staffId
            });
        }

        else if (id === "editProduct") {
            this.setState({
                editProduct: !this.state.editProduct, productId: staffId
            });
        }
        else {
            this.setState({
                addProduct: !this.state.addProduct
            });
        }
    }
    componentDidMount() {
        this.getBrandDetails()
        // this.getStaffDetails()
        this.getProductDetails()

    }

    // getStaffDetails = () => {
    //     this.setState({ isLoader: true })
    //     let { page, limit, search } = this.state
    //     let req = {
    //         page: page ? page : 1,
    //         limit: limit,
    //     }
    //     _Api(Url.GET_STAFF_LIST.method, Url.GET_STAFF_LIST.url, '', `?page=${req.page}&limit=${req.limit}`)
    //         .then(resp => {
    //             console.log('respexList', resp.responseData.result.data)
    //             this.setState({ StaffListData: resp.responseData.result.data, totalCount: resp.responseData.result.totalCount, isLoader: false })
    //         }).catch(err => {
    //             this.setState({ isLoader: false })
    //             if (err.error)
    //                 notify("err", err.error.responseMessage)
    //             else if (err)
    //                 // notify("err", err.message)
    //             this.setState({ isLoader: false })
    //         })
    // }

    getProductDetails = () => {
        this.setState({ isLoader: true })
        let { page, limit } = this.state
        let req = {
            page: page ? page : 1,
            limit: limit,
        }
        _Api(Url.GET_PRODUCT_LIST.method, Url.GET_PRODUCT_LIST.url, '', `?page=${req.page}&limit=${req.limit}&brandId=${this.props.match.params.brandId}`)
            .then(resp => {
                console.log('respexList', resp.responseData.result.data)
                this.setState({ productListData: resp.responseData.result.data, totalCount: resp.responseData.result.totalCount, isLoader: false })
            }).catch(err => {
                this.setState({ isLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
                this.setState({ isLoader: false })
            })
    }

    deleteProduct = (id) => {
        let req = {
            isDeleted: 0,
            productId: id,
        }
        _Api(Url.DELETE_PRODUCT.method, Url.DELETE_PRODUCT.url, '', `?productId=${req.productId}&isDeleted=${req.isDeleted}`)
            .then(resp => {
                console.log('delete', resp.responseData.message)
                notify('success', resp.responseData.message)
                this.setState({ visible: false })
                this.getProductDetails()
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
            })


    }

    deleteStaff = (id) => {
        let req = {
            isDeleted: 0,
            staffId: id,

        }
        _Api(Url.DELETE_STAFF.method, Url.DELETE_STAFF.url, '', `?staffId=${req.staffId}&isDeleted=${req.isDeleted}`)
            .then(resp => {
                console.log('delete', resp.responseData.message)
                notify('success', resp.responseData.message)
                this.setState({ visible: false })
                this.getStaffDetails()
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
            })


    }
    //Show modal
    showModal = (id) => {
        this.setState({
            visible: true,
            selectedId: id
        });

    };
    //Cancel modal
    handleCancel = () => {
        this.setState({ visible: false });
    };


    productStatus = (e, id) => {
        let req = {
            productId: id.toString(),
            status: (e.target.checked ? 1 : 0),
        }
        _Api(Url.PRODUCT_STATUS.method, Url.PRODUCT_STATUS.url, '', `?productId=${req.productId}&status=${req.status}`)
            .then(resp => {
                console.log('delete', resp.responseData)
                notify('success', resp.responseData.message)
                this.setState({ visible: false })
                this.getProductDetails()
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
            })
    }

    getBrandDetails = () => {

        _Api(Url.USER_DETAILS.method, Url.USER_DETAILS.url, '', `?requestedUserId=${this.props.match.params.brandId}`)
            .then(resp => {
                console.log('jadnsklsandl', resp.responseData)

                this.setState({ StaffListData: resp.responseData.result.staffData, brandInfo: resp.responseData.result })

            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
            })
    }

    render() {
        let { StaffListData, productListData, visible, loading, brandInfo } = this.state;

        console.log("dsjahgdhj", brandInfo)
        return (
            <>
                <div className="dash_body_container brand ">
                    {/* <WebHeader/> */}
                    {/*<ChatBox/>*/}

                    <div className="body_container">



                        <div className="header-text  mb-4 pt-3">
                            <div className="d-flex align-items-center">
                                <h4>{brandInfo?.companyName}</h4>
                            </div>

                            {/* <Link target="_blank" to="/user-management" className="btn btn-light">Back</Link> */}
                            <a > <button onClick={() => this.props.history.goBack()} className="btn btn-light"><i class="fa fa-arrow-left" aria-hidden="true"></i>Back</button></a>



                        </div>
                        <div className="">
                            <div className="rounded-body pr-5 card-wrapper mt-0">



                                <>

                                    <LazyLoad>
                                        <div className="pos-relative">
                                            <div className="professional-beauty-banner-wrapper">
                                                <img className="img-fluid w-100 banner" src={brandInfo?.coverImage ? brandInfo?.coverImage : require('../../../assets/images/professional-beauty-banner.png')} />

                                            </div>
                                            <div className="brand-logo-parent">
                                                <img className="img-fluid w-100 banner" src={require('../../../assets/images/beauty-logo.png')} />

                                            </div>
                                        </div>
                                    </LazyLoad>

                                    <div className="company-profile mt-5">
                                        <div class="header-text mb-4 mt-5 d-flex justify-content-between">
                                            <h4>Company Profile</h4>
                                        </div>
                                        <div className="profile-edit">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                            Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.  eiusmod tempor incididunt ut
        labore et dolore magna aliqua.  Quis ipsum suspendisse ultrices gravida. Risus commodo viver</p>

                                        </div>

                                        {/* <div className="form-group ">

                                            <textarea rows="4"
                                                className="form-control hi"
                                                placeholder="Lorem ipsum" name="categoryDesc"

                                            />

                                        </div> */}

                                    </div>
                                    <div className="staff mt-5">
                                        <div className="header-text mb-4 mt-5 d-flex justify-content-between">

                                            <h4>Staf on the Stand</h4>
                                            {/* <button className="btn btn-red" onClick={this.togglePopup.bind(this, 'addStaff')}>Add Staff</button> */}

                                        </div>
                                        <row className="row">
                                            {StaffListData?.map((item) => {
                                                return (

                                                    <div className="col-md-4 ">
                                                        <div className="card community-card">
                                                            <div className="row">
                                                                <LazyLoad>
                                                                    <div className="col-md-4 pr-0">
                                                                        <div className="img-wrapper">
                                                                            <img className="img-fluid" src={item?.thumbUrl ? item?.thumbUrl : require('../../../assets/images/placeholder.png')} />
                                                                        </div>
                                                                    </div>
                                                                </LazyLoad>
                                                                <div className="col-md-8 no-gutter d-flex align-items-start flex-column">
                                                                    <h4><strong>{item.firstName} {item.lastName}</strong></h4>
                                                                    <span><small>{item.jobTitleOther}</small></span>
                                                                    <span className="action_group  ">
                                                                        {/* <button className="btn btn-blue" ><span className="zoom"><ZoomVideo /></span>Video Call</button> */}
                                                                        <p><p className="circle-serial" ><Pencil /></p></p>
                                                                        <p></p>
                                                                        <p><p className="circle-serial" ><Delete /></p></p>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                            {StaffListData && StaffListData.length == 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                                        </row>



                                    </div>

                                    <div className="Industries">
                                        <div className="text-center">
                                            <div className="header-text mb-4 mt-5 d-flex justify-content-between">
                                                <h4>Sectors We serve</h4>
                                            </div>

                                            <IndustrieCarousel /> {/* Indestries Slider **/}

                                            <div className="text-right">
                                                <BtnRed to="#" text="View All Sectors" />
                                            </div>
                                        </div>


                                    </div>

                                    <div className="produçt-showcase">
                                        <div className="text-center">
                                            <div className="header-text mb-4 mt-5 d-flex justify-content-between">
                                                <h4>Products/Brands</h4>
                                                <button className="btn btn-red" onClick={this.togglePopup.bind(this)}>Add Products</button>
                                            </div>
                                            <ProductShowcase
                                                {...this.props}
                                                productListData={productListData}
                                                statusProduct={this.productStatus}
                                                deleteProduct={this.deleteProduct}
                                                editSuccess={this.getProductDetails}
                                            />


                                            <div className="text-right">
                                                <BtnRed to="#" text="View All Product" />
                                            </div>
                                        </div>


                                    </div>

                                    <div className="images-video mt-5">
                                        <div className="header-text mb-4 mt-5 d-flex justify-content-between">
                                            <h4>Images/Videos</h4>

                                        </div>

                                        <Media />

                                        <div className="text-right">
                                            <BtnRed to="#" text="View All Media" />
                                        </div>
                                    </div>

                                    {/* ----------------------Brochures -------------- */}
                                    <div className="fragment-wrapper">
                                        <div className="header-text ">
                                            <h4>Document</h4>
                                        </div>
                                        <Brochures />
                                    </div>
                                </>
                            </div>
                        </div>


                    </div>
                </div>

            </>

        );
    }
}
// export default ViewIndustryDetail;
function mapStateToProps(state) {
    return {
        ...state,
    };
}

export default connect(mapStateToProps)(ViewIndustryDetail);