import React, { Component } from "react";
import InlineError from "../../../components/InlineError";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import PhoneInput from "react-phone-input-2";
import util from "../../../utils/Util.js";
import { Select } from "antd";
import Sidebar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import { _Api } from "../../../services/Api";
import Url from "../../../services/BaseUrl";
import { notify } from "../../../components/Toaster";
import Loader from "../../../components/common/Loader";
import { ProgressStepBar } from "../../../components/index";
import { validateEmail } from "../../../components/Validation";
import { TreeSelect, Modal, Tree } from "antd";
import { connect } from "react-redux";
import Croper from "../../../components/Croper";
import { userActions } from "../../../redux/action";
import { Hert } from "../../../SvgIcon";
import { MySpinner } from "../../../components/index";
import _, { divide } from "lodash";

const { Option } = Select;
const { SHOW_PARENT, SHOW_CHILD, SHOW_ALL } = TreeSelect;

let isProd = process.env.REACT_APP_ENV == "prod";
class EditUserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileStep: 1,
      isImageUpload: false,
      step: 1,
      brandType: 1,
      currentType: 1,
      isCropper: false,
      userId: null,
      visible: false,
      placeOfWorkMobileTitle: "",
      noOfPeopleWorkMobile: "",
      jobTitleMobile: "",
      visible2: false,
      visible3: false,
      sectorError: "",
      placeofWorkError: "",
      sectorId: [],
      placeOfWork: [],
      roleList: [],
      roleError: "",
      selectedSector: [],
      selectedPlaceOfWork: [],
      selectedRole: [],
      signupStep: 1,
      isShowSignUpStep: true,
      registrationIdError: "",
      placeOfWorkList: [],
      registrationId: "",
      registrationIdRequired: false,
      roleId: [],
      secIds: false,
      countryList: [],
      cityList:[],
      country:'',
      city:''

    };
  }
  componentDidMount() {
    // console.log("testtsts");
    this.getUserProfile();
    this.getCountry();
    this.getSector();
    // this.getCategory("1", "placeOfWorkData");
    // this.getCategory("3", "productAndServiceOfferData");
    // this.getCategory("5", "budgetData");
    // this.getCategory("6", "noOfPeopleWorkData");
  }

  handleChange = (address) => {
    this.setState({ address });
  };

  onChangePlacework2 = (key, info) => {
    console.log("valueeee", info);
    let { value, title } = info.node;
    if (value === "other") {
      this.setState({
        placeOfWorkOther: "",
        showOtherPlaceOfWork: true,
        placeOfWorkError: "",
      });
    } else {
      this.setState({
        placeOfWorkOther: "",
        showOtherPlaceOfWork: false,
        placeOfWorkError: "",
      });
    }

    this.setState(
      {
        dropDown3: [],
        placeOfWorkId: value,
        placeOfWorkMobileTitle: title,
        visible: false,
        attandingLookingForError: "",
        productAndServiceError: "",
        statusError: "",
        selectBudgetError: "",
        jobTitleError: "",
        noOfPeopleWorkError: "",
        budgetError: "",
        stateRoleError: "",
        showChangeJobOther: false,
        jobTitleOther: "",
        jobTitle: "",
      },
      () => {
        let params = {
          categoryType: "2",
        };
        if (value !== "other") {
          params.placeofWorkId = value;
        }
        _Api(Url.GET_CATEGORY_LIST.method, Url.GET_CATEGORY_LIST.url, params)
          .then((resp) => {
            //console.log('responseData', resp.responseData);
            let jobTitleData = resp.responseData.result.map((item1, index1) => {
              let childData = item1.subCategory.map((data, index2) => {
                let ddd = {
                  title: data.categoryTitle,
                  value: data._id,
                  children: [],
                };
                return ddd;
              });
              //console.log("childData",childData)
              let dd = {
                selectable: childData.length > 0 ? false : true,
                title:
                  childData.length > 0 ? (
                    <b style={{ color: "#000" }}>{item1.categoryTitle}</b>
                  ) : (
                    item1.categoryTitle
                  ),
                value: item1._id,
                checkable: childData.length > 0 ? false : true,
                children: childData,
                checked: true,
              };
              return dd;
            });

            jobTitleData.push({
              title: "Other",
              value: "other",
              children: [],
            });

            this.setState({
              jobTitleData,
            });
          })
          .catch((err) => {
            this.setState({ isLoader: false });
            if (err.error) notify("err", err.error.responseMessage);
            // else if (err)
            //     // notify("err", err.message)
            this.setState({ isLoader: false });
          });
      }
    );
  };

  onChangeNoOfPeopleWork = (key, info) => {
    let { value, title } = info.node;
    this.setState(
      {
        noOfPeopleWorkId: value,
        visible2: false,
        noOfPeopleWorkMobile: title,
        attandingLookingForError: "",
        productAndServiceError: "",
        statusError: "",
        selectBudgetError: "",
        jobTitleError: "",
        noOfPeopleWorkError: "",
        budgetError: "",
        stateRoleError: "",
      },
      function () {
        if (key === "jobTitleId") {
          if (value === "other") {
            this.setState({ showChangeJobOther: true });
          } else {
            this.setState({ showChangeJobOther: false });
          }
        }
      }
    );
  };

  onChangeJobTitle = (key, info) => {
    let { value, title } = info.node;
    this.setState(
      {
        jobTitleId: value,
        visible3: false,
        jobTitleMobile: title,
        attandingLookingForError: "",
        productAndServiceError: "",
        statusError: "",
        selectBudgetError: "",
        jobTitleError: "",
        noOfPeopleWorkError: "",
        budgetError: "",
        stateRoleError: "",
      },
      function () {
        if (key === "jobTitleId") {
          if (value === "other") {
            this.setState({ showChangeJobOther: true });
          } else {
            this.setState({ showChangeJobOther: false });
          }
        }
      }
    );
  };

  getCategory = (categoryType, keyName) => {
    let params = {
      categoryType: categoryType,
    };
    _Api(Url.GET_CATEGORY_LIST.method, Url.GET_CATEGORY_LIST.url, params)
      .then((resp) => {
        //console.log('responseData', resp.responseData);
        let keyNameData = resp.responseData.result.map((item1) => {
          let childData = item1.subCategory.map((data) => {
            let ddd = {
              title: data.categoryTitle,
              value: data._id,
              children: [],
            };
            return ddd;
          });
          //console.log("childData",childData)
          let dd = {
            selectable: childData.length > 0 ? false : true,
            title:
              childData.length > 0 ? (
                <b style={{ color: "#000" }}>{item1.categoryTitle}</b>
              ) : (
                item1.categoryTitle
              ),
            value: item1._id,
            checkable: childData.length > 0 ? false : true,
            children: childData,
          };
          return dd;
        });
        if (keyName === "placeOfWorkData" || keyName === "jobTitleData") {
          keyNameData.push({
            title: "Other",
            value: "other",
            selectable: true,
            children: [],
          });
        }
        this.setState({
          [keyName]: keyNameData,
        });
      })
      .catch((err) => {
        this.setState({ isLoader: false });
        if (err.error) notify("err", err.error.responseMessage);
        // else if (err)
        //     // notify("err", err.message)
        this.setState({ isLoader: false });
      });
  };

  // on change
  signupProcessOnChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      firstNameError: "",
      attandingLookingForError: "",
      productAndServiceError: "",
      companyNameError: "",
      countryAllError: "",
      cityError: "",
      countryError: "",
      streetAddress1Error: "",
      streetAddress2Error: "",
      postalCodeError: "",
      phoneNumberError: "",
    });
  };
  checkEmailExist = (e) => {
    let req = {
      email: e.target.value,
    };
    _Api(
      Url.IS_EMAIL_ID_ALREADY_EXIST.method,
      Url.IS_EMAIL_ID_ALREADY_EXIST.url,
      "",
      `?email=${req.email}`
    )
      .then((resp) => {
        if (resp.responseData.status) {
          if (this.state.email !== this.state.userOldEmail) {
            this.setState({ emailError: resp.responseData.message });
          }
        }
      })
      .catch((err) => {
        this.handleError(err);
      });
  };

  selectOnChange = (type, e) => {
    this.setState({
      [type]: e,
      placeOfWorkError: "",
      attandingLookingForError: "",
      productAndServiceError: "",
      statusError: "",
      selectBudgetError: "",
      jobTitleError: "",
      noOfPeopleWorkError: "",
      budgetError: "",
      stateRoleError: "",
    });
  };

  //sign up process submit
  SignUpProcessProfileSubmit = () => {
    this.setState({
      companyNameError: "",
      phoneNumberError: "",
      streetAddress1Error: "",
      cityError: "",
      countryError: "",
      postalCodeError: "",
      firstNameError: "",
    });

    let rexx = /^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[789]\d{9}|(\d[ -]?){7,15}\d$/;
    let {
      firstName,
      companyName,
      city,
      country,
      postalCode,
      phoneNumber,
      address,
      email,
    } = this.state;
    if (!firstName)
      this.setState({ firstNameError: "*Please enter first name" });
    // else if (!companyName)
    //   this.setState({ companyNameError: "*Please enter company name." });
    else if (phoneNumber === "")
      this.setState({ phoneNumberError: "*Please enter phone number." });
    else if (!rexx.test(phoneNumber))
      this.setState({ phoneNumberError: "*Please enter valid phone number." });
    // else if (!address)
    //   this.setState({ streetAddress1Error: "*Please enter street address 1." });
    else if (!city) this.setState({ cityError: "*Please enter city." });
    else if (!country)
      this.setState({ countryError: "*Please enter country." });
    // else if (!postalCode)
    //   this.setState({ postalCodeError: "*Please enter zip code/postal code." });
    else if (!email) this.setState({ emailError: "*Please enter eamil" });
    else if (!validateEmail(email).status)
      this.setState({ emailError: "*Please enter valid email." });
    else {
      this.setState(
        { profileStep: 2, stepStatus: 2, firstFill: true, step: 2 },
        () => {
          // Cookies.set('step', JSON.stringify(this.state))
        }
      );
    }
  };

  updatePlan = (id) => {
    let req = {
      requestId: id,
      plan: this.state.brandType,
    };

    _Api(Url.CHANGE_PLAN.method, Url.CHANGE_PLAN.url, req)
      .then((resp) => {})
      .catch((err) => {});
  };

  SignUpProcessWorkSubmit = () => {
    // Cookies.set('step', JSON.stringify(this.state), { expires: 1 })
    //
    let self = this;
    this.setState({
      firstNameError: "",
      companyNameError: "",
      phoneNumberError: "",
      streetAddress1Error: "",
      cityError: "",
      countryError: "",
      postalCodeError: "",
      placeOfWorkError: "",
      jobTitleError: "",
      noOfPeopleWorkError: "",
      productAndServiceError: "",
      attandingLookingForError: "",
      jobTitleOtherError: "",
      placeOfWorkOtherError: "",
    });
    let {
      brandType,
      placeOfWorkId,
      jobTitleId,
      noOfPeopleWorkId,
      productAndServiceOfferId,
      attendingOrLookingforId,
      budgetId,
    } = this.state;

    if (
      placeOfWorkId === "" ||
      (typeof placeOfWorkId === "object" && placeOfWorkId.length === 0)
    )
      this.setState({ placeOfWorkError: "*Please select place of work." });
    else if (placeOfWorkId === "other" && this.state.placeOfWorkOther === "")
      this.setState({ placeOfWorkOtherError: "*Please enter place of work." });
    else if (
      jobTitleId === "" ||
      (typeof jobTitleId === "object" && jobTitleId.length === 0)
    )
      this.setState({ jobTitleError: "*Please select job title." });
    else if (jobTitleId === "other" && this.state.jobTitleOther === "")
      this.setState({ jobTitleOtherError: "*Please enter job title." });
    else if (
      noOfPeopleWorkId === "" ||
      (typeof noOfPeopleWorkId === "object" && noOfPeopleWorkId.length === 0)
    )
      this.setState({
        noOfPeopleWorkError: "*Please select number of people work.",
      });
    // else if (productAndServiceOfferId.length === 0)
    //     this.setState({ productAndServiceError: '*Please select product and service you offer.' })
    // else if (attendingOrLookingforId.length === 0)
    //     this.setState({ attandingLookingForError: '*Please select, what are you attanding/ what are you looking for ' })
    // else if (budgetId === '' || (typeof budgetId === 'object' && budgetId.length === 0))
    //     this.setState({ selectBudgetError: '*Please select your budget for next 6 months.' })
    //  }
    else {
      // this.setState({ profileStep: 4, stepStatus: 4 }, () => {
      // let info = JSON.parse(Cookies.get("step0"))
      // })
      this.setState({ isLoader: true });
      let req2 = {
        noOfPeopleWorkId: noOfPeopleWorkId,
        productAndServiceOfferId: this.state.productAndServiceOfferId.toString(),
        attendingOrLookingforId: this.state.attendingOrLookingforId.toString(),
        // budgetId: budgetId,
        countryCode: (1).toString(),
        phoneNo: this.state.phoneNumber,
        phoneNumber: this.state.phoneNumber,
        companyName: this.state.companyName,
        streetAddress1: this.state.address,
        streetAddress: this.state.address,
        streetAddress2: this.state.streetAddress2,
        zipCode: this.state.postalCode,
        country: this.state.country,
        city: this.state.city,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        placeOfWorkOther: this.state.placeOfWorkOther,
        jobTitleOther: this.state.jobTitleOther,
        requestUserId: this.props.match.params.id || "",
        // isEuropean: this.state.isEuropeCT == true ? 1 : 0
      };
      let userType = localStorage.getItem("userType");
      if (userType == 1) {
        this.updatePlan(this.props.match.params.id);
      }

      if (placeOfWorkId !== "other") {
        req2.placeOfWorkId = placeOfWorkId;
      }
      if (jobTitleId !== "other") {
        req2.jobTitleId = jobTitleId;
      }

      _Api(Url.EDIT_USER_PROFILE.method, Url.EDIT_USER_PROFILE.url, req2)
        .then((resp) => {
          self.props.dispatch(userActions.profile());

          this.setState({ isLoader: false });
          notify("success", resp.responseData.message);
          // this.props.history.goBack();
        })
        .catch((err) => {
          this.handleError(err);
        });
    }
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
      visible2: false,
      visible3: false,
    });
  };

  handlePrevious = () => {
    this.setState({ profileStep: 1, stepStatus: 1, firstFill: false }, () => {
      // Cookies.set('step', JSON.stringify(this.state))
    });
  };
  handleStep = (step) => {
    if (this.state.firstFill) this.setState({ profileStep: step });
  };

  onChangeSelect = (key, value) => {
    this.setState(
      {
        [key]: value,
        attandingLookingForError: "",
        productAndServiceError: "",
        statusError: "",
        selectBudgetError: "",
        jobTitleError: "",
        noOfPeopleWorkError: "",
        budgetError: "",
        stateRoleError: "",
      },
      function () {
        if (key === "jobTitleId") {
          if (value === "other") {
            this.setState({ showChangeJobOther: true });
          } else {
            this.setState({ showChangeJobOther: false });
          }
        }
      }
    );
  };

  onChangePlacework = (value) => {
    //console.log('value', value)
    if (value === "other") {
      this.setState({ placeOfWorkOther: "", showOtherPlaceOfWork: true });
    } else {
      this.setState({ placeOfWorkOther: "", showOtherPlaceOfWork: false });
    }

    this.setState(
      {
        dropDown3: [],
        placeOfWorkId: value,
        attandingLookingForError: "",
        productAndServiceError: "",
        statusError: "",
        selectBudgetError: "",
        jobTitleError: "",
        noOfPeopleWorkError: "",
        budgetError: "",
        stateRoleError: "",
        showChangeJobOther: false,
        jobTitleOther: "",
        jobTitle: "",
      },
      () => {
        let params = {
          categoryType: "2",
        };
        if (value !== "other") {
          params.placeofWorkId = value;
        }
        _Api(Url.GET_CATEGORY_LIST.method, Url.GET_CATEGORY_LIST.url, params)
          .then((resp) => {
            //console.log('responseData', resp.responseData);
            let jobTitleData = resp.responseData.result.map((item1) => {
              let childData = item1.subCategory.map((data) => {
                let ddd = {
                  title: data.categoryTitle,
                  value: data._id,
                  children: [],
                };
                return ddd;
              });
              //console.log("childData",childData)
              let dd = {
                selectable: childData.length > 0 ? false : true,
                title:
                  childData.length > 0 ? (
                    <b style={{ color: "#000" }}>{item1.categoryTitle}</b>
                  ) : (
                    item1.categoryTitle
                  ),
                value: item1._id,
                checkable: childData.length > 0 ? false : true,
                children: childData,
              };
              return dd;
            });

            jobTitleData.push({
              title: "Other",
              value: "other",
              children: [],
            });

            this.setState({
              jobTitleData,
            });
          })
          .catch((err) => {
            this.setState({ isLoader: false });
            if (err.error) notify("err", err.error.responseMessage);
            // else if (err)
            //     // notify("err", err.message)
            this.setState({ isLoader: false });
          });
      }
    );
  };

  onChnageOther = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  getUserProfile = () => {
    let self=this;
    let req = {
      requestUserId: this.props.match.params.id || localStorage.id || "",
    };
    _Api(
      Url.GET_USER_PROFILE.method,
      Url.GET_USER_PROFILE.url,
      "",
      `?requestUserId=${req.requestUserId}`
    )
      .then((resp) => {
        let responseData = resp.responseData.result;
        //console.log("dsahghdj", responseData);
        if (responseData.placeOfWorkId && responseData.placeOfWorkId._id) {
          this.onChangePlacework(responseData.placeOfWorkId._id);
        }
        this.setState(
          {
            placeOfWork: responseData.placeOfWork,
            registrationId: responseData.registrationId,
            roleId: responseData.roleId,
            sectorId: responseData.sectorId,
            userId: responseData?._id,
            brandType: responseData.userPlan,
            currentType: responseData.userPlan,
            companyName: responseData.companyName,
            contactName: responseData.contactName,
            // firstName: responseData.firstName,
            // lastName: responseData.lastName,
            city: responseData.city,
            cityId:responseData.cityId,
            email: responseData.email,
            country: responseData.country,
            country_Code:responseData.country_Code,
            placeOfWorkMobileTitle: responseData?.placeOfWorkId?.categoryTitle
              ? responseData?.placeOfWorkId?.categoryTitle
              : "other",
            jobTitleMobile: responseData?.jobTitleId?.categoryTitle,
            noOfPeopleWorkMobile:
              responseData.noOfPeopleWorkId &&
              responseData.noOfPeopleWorkId.categoryTitle,

           
            phoneNumber: String(responseData.phoneNumber),
            
            // address: responseData.streetAddress1,
            // postalCode: responseData.zipCode,
            // streetAddress2: responseData.streetAddress2,
            // placeOfWorkId: responseData?.placeOfWorkOther
            //   ? "other"
            //   : responseData.placeOfWorkId && responseData.placeOfWorkId._id,
            // jobTitleId: responseData?.jobTitleOther
            //   ? "other"
            //   : responseData.jobTitleId && responseData.jobTitleId._id,
            // noOfPeopleWorkId:
            //   responseData.noOfPeopleWorkId &&
            //   responseData.noOfPeopleWorkId._id,
            // productAndServiceOfferId:
            //   responseData.productAndServiceOfferId &&
            //   responseData.productAndServiceOfferId.map((e) => e["_id"]),
            // attendingOrLookingforId:
            //   responseData.attendingOrLookingforId &&
            //   responseData.attendingOrLookingforId.map((e) => e["_id"]),
            // budgetId: responseData.budgetId && responseData.budgetId._id,
            firstName: responseData.firstName,
            lastName: responseData.lastName,
            image: responseData.image,
            userOldEmail: responseData.email,
            placeOfWorkOther: responseData?.placeOfWorkOther,
            showOtherPlaceOfWork: responseData?.placeOfWorkOther ? true : false,
            jobTitleOther: responseData?.jobTitleOther,
            showChangeJobOther: responseData?.jobTitleOther ? true : false,
          },
          () => {
            let secId = [];
            let placeOfWorkId = [];
            let rolId = [];
            this.state.sectorId.map((item, ind) => {
              secId.push(item._id);
              this.setState({ selectedSector: secId });
              console.log("secidddd", secId);
            });
            this.state.placeOfWork.map((item, ind) => {
              placeOfWorkId.push(item._id);
              this.setState({ selectedPlaceOfWork: placeOfWorkId });
              console.log("placeOfWorkIdddd", placeOfWorkId);
            });
            this.state.roleId.map((item, ind) => {
              rolId.push(item._id);
              this.setState({ selectedRole: rolId });
              console.log("rolIdddd", rolId);
            });
            self.getCity(responseData.country_Code);
          }
        );
      })
      .catch((err) => {
        this.setState({ isLoader: false });
        let errorMessage =
          (err.error && err.error.responseMessage) || err.message;
        notify("err", errorMessage);
      });
  };

  selectOnChange = (type, e) => {
    this.setState({
      [type]: e,
    });
  };
  handlesubmitEdit = () => {
    const {
      companyName,
      contactName,
      address,
      email,
      phoneNumber,
      postalCode,
      lastName,
      firstName,
      city,
      country,
      country_Code,
      cityId,
    } = this.state;

    let param = {
      companyName: companyName,
      // contactName: contactName,
      // streetAddress: address,
      firstName: firstName,
      lastName: lastName,
      city: city,
      cityId:cityId,
      country_Code:country_Code,
      // zipCode: postalCode,
      phoneNo: phoneNumber,
      country:country,
      email: email,
      requestUserId: this.props.match.params.id || localStorage.id || "",
      placeOfWork: this.state.selectedPlaceOfWork.toString(),
      roleId: this.state.selectedRole.toString(),
      sectorId: this.state.selectedSector.toString(),
      registrationId: this.state.registrationId,
    };
    _Api(Url.EDIT_USER_PROFILE.method, Url.EDIT_USER_PROFILE.url, param)
      .then((resp) => {
        notify("success", resp.responseData.message);
        this.props.history.goBack();
      })
      .catch((err) => {
        this.handleError(err);
      });
  };
  handleError(err) {
    this.setState({ isLoader: false });
    let errorMessage = (err.error && err.error.responseMessage) || err.message;
    notify("err", errorMessage);
  }
  handleOnChangeValue = (e) => {
    let stateObject = e.target.name + "Error";
    this.setState({ [e.target.name]: e.target.value, [stateObject]: "" });
  };

  handleSelect = (address) => {
    this.setState({
      companyNameError: "",
      phoneNumberError: "",
      streetAddress1Error: "",
      cityError: "",
      countryError: "",
      postalCodeError: "",
    });

    geocodeByAddress(address)
      .then((results) => {
        let data = results[0]?.address_components;

        let cityArr = data.filter((item) => item.types[0] == "country")[0];

        this.setState({ address: results[0]?.formatted_address });

        if (cityArr) {
          this.setState({
            country: cityArr.long_name,
            // isEuropeCT: isEurop[cityArr.short_name] == "EU"
          });
        }
        return getLatLng(results[0]).then((latLng) => {
          address = address.split(",");

          // this.setState({ lng: latLng.lng, lat: latLng.lat, address: address[0] })
          this.setState({ lng: latLng.lng, lat: latLng.lat });
          // this.postalCode(latLng)

          let Url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat},${latLng.lng}&key=AIzaSyAjSodVOYLA8VVU2oOSToc7VtM8xKs13fI`;

          fetch(Url)
            .then((response) => response.json())
            .then((result) => {
              let zipcode;
              let city;
              let state;
              let street;
              let flag = true;
              for (var i = 0; i < result.results.length; i++) {
                for (
                  var j = 0;
                  j < result.results[i].address_components.length;
                  j++
                ) {
                  for (
                    var k = 0;
                    k < result.results[i].address_components[j].types.length;
                    k++
                  ) {
                    if (
                      result.results[i].address_components[j].types[k] ==
                      "route"
                    ) {
                      street =
                        result.results[i].address_components[j].long_name;

                      // this.setState({ zip: zipcode })
                      if (street) {
                        this.setState({ street: street });
                      } else {
                        this.setState({ street: "" });
                      }
                    }
                    if (
                      result.results[i].address_components[j].types[k] ==
                        "postal_code" &&
                      flag
                    ) {
                      flag = false;

                      zipcode =
                        result.results[i].address_components[j].long_name;

                      if (zipcode) {
                        this.setState({ postalCode: zipcode });
                      } else {
                        this.setState({ postalCode: "" });
                      }
                    }
                    if (
                      result.results[i].address_components[j].types[k] ==
                      "locality"
                    ) {
                      city = result.results[i].address_components[j].long_name;
                      if (city) {
                        this.setState({ city: city });
                      } else {
                        this.setState({ city: "" });
                      }
                    }
                    if (
                      result.results[i].address_components[j].types[k] ==
                      "administrative_area_level_1"
                    ) {
                      state = result.results[i].address_components[j].long_name;
                      if (zipcode) {
                        this.setState({ state: state });
                      } else {
                        this.setState({ state: "" });
                      }
                    }
                  }
                }
              }
            });
        });
      })
      .catch(() => {});
  };
  handlePicUpload = (event) => {
    console.log("eventeventevent", event.target.files[0]);
    let reader = new FileReader();
    let file = event.target.files[0];
    let { valiDate } = this.state;
    if (file) {
      reader.readAsDataURL(file);
    }
    if (file) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/gif" ||
        file.type === "image/png" ||
        file.type === "image/jpg"
      ) {
        if (file.size > 5000000) {
          valiDate = false;
          console.log(event, "media", event.target.files[0]);
          this.setState({
            // media: event.target.files[0],
            profilePicErrorMsg: "Profile pic size should be less than 5Mb.",
          });
          notify("err", "Profile pic size should be less than 5Mb.");
          // alert("Profile pic size should be less than 5Mb.")
        } else {
          reader.addEventListener(
            "load",
            () => {
              this.setState(
                { profilePicPreview: reader.result, isImageUpload: true },
                function () {}
              );
            },
            false
          );

          this.setState(
            {
              media: event.target.files[0],
              mediaError: "",
            },
            () => this.submitImage()
          );
        }
      } else {
        valiDate = false;
        // alert("File type not supported.");
        notify("err", "File type not supported.");
        this.setState({ mediaError: "File type not supported." });
      }
    }
  };
  submitImage = () => {
    let self = this;
    let formData = new FormData();
    formData.append("requestUserId", this.props.match.params.id || "");
    formData.append("image", this.state.media);

    _Api(Url.USER_IMAGE_UPLOAD.method, Url.USER_IMAGE_UPLOAD.url, formData)
      .then((resp) => {
        self.props.dispatch(userActions.profile());
        let responseData = resp.responseData.result;
        this.setState({
          profilePicPreview: responseData.image,
          isImageUpload: false,
        });
        window.location.reload();
      })
      .catch((err) => {
        this.setState({ isLoader: false });
        let errorMessage =
          (err.error && err.error.responseMessage) || err.message;
        notify("err", errorMessage);
      });
  };

  handleUpdateType = (type) => {
    if (
      this.state.currentType != 3 &&
      (type != 1 || this.state.currentType == 1)
    ) {
      this.setState({ brandType: type });
    }
  };

  finalCropedImage = (categoryImgPrev, categoryImg, visibleVal) => {
    // console.log("ldjshgvhbjkd", { categoryImgPrev, categoryImg, visibleVal })
    this.setState(
      {
        visibleVal,
        isCropper: false,
        profilePicPreview: categoryImgPrev,
        media: categoryImg,
      },
      () => this.submitImage()
    );
  };

  checkSignupStepClass = (signupStep) => {
    if (signupStep < this.state.signupStep) {
      return "signup-step done";
    } else if (signupStep === this.state.signupStep) {
      return "signup-step active";
    } else {
      return "signup-step";
    }
  };

  checkSelected = (field, value) => {
    let selectedField = this.state[field];
    let checkResult = _.find(selectedField, function (obj) {
      return obj === value;
    });
    // console.log("selectedFieldererer", selectedField, checkResult, value);
    if (checkResult) {
      return true;
    } else {
      return false;
    }
  };

  signupNextStep = (e) => {
    let self = this;
    // e.preventDefault();
    // this.setState({ signupStep: e })
    if (self.state.signupStep == 1) {
      this.setState({ signupStep: 2 })
    }
    if (self.state.signupStep == 2) {
      if (self.state.selectedSector.length > 0) {
        self.getplaceOfWork(e);
      } else {
        self.setState({
          sectorError: "*Please select any sector",
        });
      }
    } else if (self.state.signupStep == 3) {
      if (self.state.selectedPlaceOfWork.length > 0) {
        self.getRole(e);
      } else {
        self.setState({
          placeofWorkError: "*Please select any place of work",
        });
      }
    } else if (self.state.signupStep == 4) {
      if (self.state.registrationIdRequired == true) {
        if (self.state.registrationId.trim() === "") {
          self.setState({ registrationIdError: "*Please enter." });
        } else {
          //   this.getCountry();
          //   this.signupNext(5);
        }
      } else {
        let checkRegistrationId = 0;
        //console.log("selectedRole", this.state.selectedRole);
        if (this.state.selectedRole.length > 0) {
          let roleArr = [
            "5fc9d333611cf52928910101",
            "5fc73e5a237eae12638c982e",
            "5fc72577237eae12638c9823",
            "5fc72850237eae12638c9825",
            "5fc724ba237eae12638c9822",
            "5fc78d3c237eae12638c9834",
          ];
          _.map(this.state.selectedRole, function (role) {

            let checkResult = _.find(roleArr, function (obj) {
                return obj === role;
              }
            );

            if (checkResult) {
               checkRegistrationId++;
            }

          });

          if (checkRegistrationId > 0) {
            this.setState({ secIds: true });
            this.setState({ registrationIdRequired: true });
            this.signupNext(4);
          } else {
            // this.getCountry();
            // this.state.signupNext(5);
          }
        } else {
          this.setState({
            roleError: "*Please select any role",
          });
        }
      }
    }

  };

  signupNext = (step) => {
    //console.log("signupNext", step);
    this.setState({
      signupStep: step,
    });
  };

  stateSet = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  onChecked = (e, field, countField) => {
    //console.log("wertyuiuytrewqerty", e.target.value, field, countField);
    if (
      e.target.value == "5fc9d333611cf52928910101" ||
      "5fc73e5a237eae12638c982e" ||
      "5fc72577237eae12638c9823" ||
      "5fc72850237eae12638c9825" ||
      "5fc724ba237eae12638c9822" ||
      "5fc78d3c237eae12638c9834"
    ) {
      this.setState({ registrationIdRequired: true,secIds: true });
    }
    let selectedField = this.state[field];
    if (field === "selectedSector") {
      this.setState({
        selectedPlaceOfWork: [],
        placeofworkCount: 0,
        selectedRole: [],
        roleCount: 0,
        registrationId: "",
        registrationIdRequired: false,
      });
    } else if (field === "selectedPlaceOfWork") {
      this.setState({
        selectedRole: [],
        roleCount: 0,
        registrationId: "",
        registrationIdRequired: false,
      });
    } else if (field === "selectedRole") {
      this.setState({
        registrationId: "",
        registrationIdRequired: false,
      });
    }
    let checkResult = _.find(selectedField, function (obj) {
      return obj === e.target.value;
    });
    //console.log("onChecked", e.target.value, selectedField, checkResult);
    if (checkResult) {
      let newData = _.remove(selectedField, function (n) {
        return e.target.value === n;
      });
      //console.log("======",newData);
      this.setState({
        [field]: selectedField,
        [countField]: this.state[countField] - 1,
      });
    } else {
      //console.log("selectedField1", e.target.value);
      selectedField.push(e.target.value);
      this.setState({
        [field]: selectedField,
        [countField]: this.state[countField] + 1,
      });
      //console.log("selectedField111", selectedField);
    }
  };

  getplaceOfWork = (e) => {
    let self = this;
    //e.preventDefault();
    let sectorId = this.state.selectedSector;
    this.stateSet("isLoading", true);
    _Api(Url.GET_PLACE_OF_WORK_LIST.method, Url.GET_PLACE_OF_WORK_LIST.url, {
      sectorId: sectorId.toString(),
    })
      .then((resp) => {
        if (resp.statusCode == 1) {
          this.setState({
            placeOfWorkList: resp.responseData.result.data,
          });
          this.stateSet("isLoading", false);
          this.signupNext(3);
        }
      })
      .catch((err) => {
        if (err.error) notify("err", err.error.responseMessage);
      });
  };

  getRole = (e) => {
    let self = this;
    //e.preventDefault();
    let placeOfWorkId = this.state.selectedPlaceOfWork;
    this.stateSet("isLoading", true);
    _Api(Url.GET_ROLE_LIST.method, Url.GET_ROLE_LIST.url, {
      placeOfWorkId: placeOfWorkId.toString(),
    })
      .then((resp) => {
        if (resp.statusCode == 1) {
          this.setState({
            roleList: resp.responseData.result.data,
          });
          this.stateSet("isLoading", false);
          this.signupNext(4);
        }
      })
      .catch((err) => {
        if (err.error) notify("err", err.error.responseMessage);
      });
  };

  getSector = () => {
    _Api(Url.GET_SECTOR_LIST.method, Url.GET_SECTOR_LIST.url)
      .then((resp) => {
        if (resp.statusCode == 1) {
          this.setState({
            sectorList: resp.responseData.result.data,
          });
        }
      })
      .catch((err) => {
        if (err.error) notify("err", err.error.responseMessage);
      });
  };

  sugnUpOnChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  getCountry = () => {
    let self = this;
    this.stateSet("isLoading", true);
    _Api(Url.GET_COUNTRY_LIST_V2.method, Url.GET_COUNTRY_LIST_V2.url)
      .then((resp) => {
        if (resp.statusCode == 1) {
          this.setState({
            countryList: resp.responseData.result,
          });
        }
        this.stateSet("isLoading", false);
      })
      .catch((err) => {
        if (err.error) notify("err", err.error.responseMessage);
      });
  };

  getCity = (selectedCountryCode) => {
    let self = this;
    //let selectedCountryCode=this.props.selectedCountryCode;
    _Api(
      Url.GET_CITY_LIST.method,
      Url.GET_CITY_LIST.url,
      "",
      "?countryCode=" + selectedCountryCode
    )
      .then((resp) => {
        if (resp.statusCode == 1) {
          this.setState({
            cityList: resp.responseData.result,
          });
        }
      })
      .catch((err) => {
        if (err.error) notify("err", err.error.responseMessage);
      });
  };

  selectCountry = (value) => {
    console.log('value',value);
    let data = value.split("#");
    // this.selectCountry(data[0], data[1]);
    this.setState({country:data[1],city:"",country_Code:data[0]})
    this.getCity(data[0]);
    
  };

  selectCity = (value) => {
    let data = value.split("#");
    this.setState({city:data[1],cityId:data[0]})
    // console.log('wertyuuytrwert',data);
  };

  render() {
    // console.log("qwertyuiytrwer", this.state.registrationIdRequired);
    console.log("signupStep", this.state);

    let {
      lastNameError,
      firstNameError,
      isLoader,
      streetAddress2,
      streetAddress2Error,
      streetAddress1Error,
      countryError,
      cityError,
      companyName,
      profileStep,
      address,
      postalCode,
      companyNameError,
      postalCodeError,
      phoneNumberError,
      jobTitleError,
      noOfPeopleWorkError,
      placeOfWorkError,
      selectBudgetError,
      productAndServiceError,
      attandingLookingForError,
      email,
      emailError,
      firstName,
      lastName,
      image,
      sectorError,
      placeofWorkError,
      roleError,
      registrationIdError,
      registrationId,
    } = this.state;

    let userType = localStorage.getItem("userType");

    // console.log("pkoijhwbfnkjbh", this.state.brandType)
    return (
      // <div className="dash_body_container">
      //     <Header />
      //     <Sidebar />

      <div
        className={
          this.props.userDetail &&
          (this.props.userDetail.userType === 1 ||
            this.props.userDetail.userType === "1")
            ? "dash_body_container"
            : "dash_body_container without-sidenav"
        }
      >
        {this.props.userDetail &&
        (this.props.userDetail.userType === 1 ||
          this.props.userDetail.userType === "1") ? (
          <>
            <Header />
            <Sidebar />
          </>
        ) : (
          <>
            {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
            {/* {    <ChatBox history={this.props.history} match={this.props.match} />}*/}
          </>
        )}
        <div className="header-text mb-4"></div>
        <div className="body_container profile-body">
          {this.props.match.params.id ? (
            <div className="header-text mb-4">
              <div className="d-flex align-items-center">
                <h4>
                  Profile Details {"/"} {firstName} {lastName}
                </h4>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="rounded-body card-wrapper sign-modal-content">
            <div className="profile-icon-wrapper">
              <img
                className="img-fluid"
                src={
                  (this.state.profilePicPreview &&
                    URL.createObjectURL(this.state.media)) ||
                  image ||
                  require("../../../assets/images/login_user.png")
                }
              />
              {
                <div>
                  <div
                    className="upload-wrapper edit-image"
                    onClick={() => this.setState({ isCropper: true })}
                  >
                    <label htmlFor="file-upload" className="">
                      <span>
                        {" "}
                        <img
                          className="img-fluid"
                          src={require("../../../assets/images/upload-photo.svg")}
                        />
                      </span>
                    </label>

                    {/* <input id="file-upload"
                                        type="file"
                                        accept="image/*"
                                        onChange={this.handlePicUpload}
                                    /> */}
                  </div>
                </div>
              }
            </div>
            <br></br>
            {/* <ProgressStepBar
              {...this.props}
              {...this.state}
              {...this}
              mapper="userProfile"
            /> */}
            <div className="signup-tab mt-5">
              <div className="Thing-line">
                <span className="circle_bullet"></span>
                <span className="heart">
                  <Hert />
                </span>
              </div>
              <div className={this.checkSignupStepClass(1)}>
                <span>1</span>
              </div>
              <div className={this.checkSignupStepClass(2)}>
                <span>2</span>
              </div>
              <div className={this.checkSignupStepClass(3)}>
                <span>3</span>
              </div>
              <div className={this.checkSignupStepClass(4)}>
                <span>4</span>
              </div>

            </div>

            <div className="">
              {isLoader && <Loader />}
              {profileStep == 1 && (
                <div className="">
                  <div className="rounded-textbox-form mt-5 edituser">
                    {this.state.userId &&
                      userType == 1 &&
                      localStorage.getItem("id") != this.state.userId && (
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <level>Brand Type</level>
                              <div className="assign-checkboc-parent">
                                <div>
                                  <label className="cm_container">
                                    User
                                    <input
                                      checked={this.state.brandType == 1}
                                      onClick={() => this.handleUpdateType(1)}
                                      type="radio"
                                      name="radio"
                                    />
                                    <span className="radio-checkmark"></span>
                                  </label>
                                </div>

                                <div>
                                  <label className="cm_container">
                                    Brand showcase
                                    <input
                                      checked={this.state.brandType == 2}
                                      onClick={() => this.handleUpdateType(2)}
                                      type="radio"
                                      name="radio"
                                    />
                                    <span className="radio-checkmark"></span>
                                  </label>
                                </div>
                                <div>
                                  <label className="cm_container">
                                    Premium brand showcase
                                    <input
                                      checked={this.state.brandType == 3}
                                      onClick={() => this.handleUpdateType(3)}
                                      type="radio"
                                      name="radio"
                                    />
                                    <span className="radio-checkmark"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    <div className="">
                      {this.state.signupStep === 1 ? (
                        <div id="step1">
                          {/*<p className="home-subheading text-center">Free for professionals and low cost options for brands and suppliers </p>*/}
                          <div
                            className="rounded-textbox-form mt-5"
                            //onSubmit={this.signupNextStep}
                          >
                            <>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>First name</label>
                                    <input
                                      type="text"
                                      autoComplete="new-password"
                                      className="form-control"
                                      placeholder="First name "
                                      id="firstName"
                                      maxLength={64}
                                      name="firstName"
                                      value={firstName}
                                      onChange={this.signupProcessOnChange}
                                    />
                                    <InlineError message={firstNameError} />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Last name</label>
                                    <input
                                      type="text"
                                      autoComplete="new-password"
                                      className="form-control"
                                      placeholder="Last name"
                                      id="lastName"
                                      maxLength={64}
                                      name="lastName"
                                      value={lastName}
                                      onChange={this.signupProcessOnChange}
                                    />
                                    <InlineError message={lastNameError} />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Company name</label>
                                    <input
                                      type="text"
                                      autoComplete="new-password"
                                      className="form-control"
                                      placeholder="Company name "
                                      id="companyName"
                                      maxLength={64}
                                      name="companyName"
                                      value={companyName}
                                      onChange={this.signupProcessOnChange}
                                    />
                                    <InlineError message={companyNameError} />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Phone number</label>
                                    <PhoneInput
                                      country={"ae"}
                                      placeholder="Phone number"
                                      enableLongNumbers="false"
                                      autoFormat="false"
                                      value={this.state.phoneNumber}
                                      onChange={(phoneNumber) =>
                                        this.setState({
                                          phoneNumber,
                                          phoneNumberError: "",
                                        })
                                      }
                                    />
                                    <InlineError message={phoneNumberError} />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Email Address</label>
                                    <input
                                      type="email"
                                      autocomplete="do-not-show-ac"
                                      className="form-control"
                                      placeholder="Email Address "
                                      id="email"
                                      maxLength={64}
                                      name="email"
                                      value={email}
                                      onChange={this.handleOnChangeValue}
                                      onBlur={this.checkEmailExist}
                                    />
                                    <InlineError message={emailError} />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Country</label>
                                    {console.log('wertyuiiuytrwert',this.state.country)}
                                    <Select
                                      showSearch
                                      placeholder="Select Country"
                                      onChange={this.selectCountry}
                                      value={this.state.country}
                                    >
                                      {this.state.countryList?.length > 0 &&
                                        this.state.countryList?.map((item) => (
                                          <Option
                                            key={item.country_code}
                                            value={
                                              item.country_code +
                                              "#" +
                                              item.country_name
                                            }
                                          >
                                            {item.country_name}
                                          </Option>
                                        ))}
                                    </Select>
                                    <InlineError message={countryError} />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                              <div className="col-md-6">
                                  <div className="form-group">
                                    <label>City</label>
                                    <Select
                                      showSearch
                                      placeholder="Select City"
                                      onChange={this.selectCity}
                                      autoComplete="off"
                                      value={this.state.city}
                                    >
                                      {this.state.cityList?.length > 0 &&
                                        this.state.cityList?.map((item) => (
                                          <Option
                                            key={item._id}
                                            value={
                                              item._id + "#" + item.city_name
                                            }
                                          >
                                            {item.city_name}
                                          </Option>
                                        ))}
                                    </Select>
                                    <InlineError message={cityError} />
                                  </div>
                                
                              </div>
                              </div>
                            </>

                            {/* <div className="text-center">
                              <button
                                type="submit"
                                disabled={this.props.isLoading}
                                className="btn btn-red"
                              >
                                {" "}
                                &nbsp;Next1 &nbsp;
                                {this.state.isLoading && <MySpinner />}
                              </button>
                            </div> */}
                            <div className="col-md-6 offset-md-3">
                              <div className="d-flex justify-content-between">
                                <button
                                  type="button"
                                  className="btn btn-light"
                                  onClick={() => this.props.history.goBack()}
                                >
                                  Previous
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-red"
                                  onClick={() => this.signupNextStep(2)}
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {this.state.signupStep === 2 ? (
                        <div id="step1">
                          <h3 className="auth-heading pos-relative text-center mt-5">
                            What Sector(s) do you work in?
                            <span className="heading-after-border heading-after-border-center">
                              <span className="heart">
                                <Hert />
                              </span>
                            </span>
                          </h3>
                          {/*<p className="home-subheading text-center">Free for professionals and low cost options for brands and suppliers </p>*/}
                          <form
                            className="rounded-textbox-form mt-5"
                            //onSubmit={this.signupNextStep}
                          >
                            {this.state.sectorList.length > 0 &&
                              this.state.sectorList.map((item) => (
                                <div
                                  className={
                                    this.checkSelected(
                                      "selectedSector",
                                      item._id
                                    )
                                      ? "form-group form-control select_field"
                                      : "form-group form-control"
                                  }
                                  key={item._id}
                                >
                                  <div className="check_wrapper circle-checkbox check_wrapper ">
                                    <input
                                      name={"selectedSector-" + item.name}
                                      checked={this.checkSelected(
                                        "selectedSector",
                                        item._id
                                      )}
                                      type="checkbox"
                                      value={item._id}
                                      onChange={(e) =>
                                        this.onChecked(
                                          e,
                                          "selectedSector",
                                          "selectedSectorCount"
                                        )
                                      }
                                    />
                                    <span />
                                  </div>
                                  <span>{item.name}</span>
                                </div>
                              ))}
                            <InlineError message={sectorError} />
                            <div className="text-center">
                              <div className="d-flex justify-content-between">
                                <button
                                  type="button"
                                  className="btn btn-light"
                                  onClick={() => this.signupNext(1)}
                                >
                                  Previous
                                </button>
                                <button
                                  type="button"
                                  disabled={this.props.isLoading}
                                  className="btn btn-red"
                                  onClick={() => this.signupNextStep(3)}
                                >
                                  {" "}
                                  &nbsp;Next &nbsp;
                                  {this.state.isLoading && <MySpinner />}
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.signupStep === 3 ? (
                        <div id="step2">
                          <h3 className="auth-heading pos-relative text-center mt-5">
                            What is your place of work?
                            <span className="heading-after-border heading-after-border-center">
                              <span className="heart">
                                <Hert />
                              </span>
                            </span>
                          </h3>
                          {/*<p className="home-subheading text-center">Free for professionals and low cost options for brands and suppliers </p>*/}
                          <form
                            className="rounded-textbox-form mt-5"
                            // onSubmit={this.signupNextStep}
                          >
                            {this.state.placeOfWorkList.length > 0 &&
                              this.state.placeOfWorkList.map((item) => (
                                <div
                                  className={
                                    this.checkSelected(
                                      "selectedPlaceOfWork",
                                      item._id
                                    )
                                      ? "form-group form-control select_field"
                                      : "form-group form-control"
                                  }
                                  key={item._id}
                                >
                                  <div className="check_wrapper circle-checkbox ">
                                    <input
                                      name={"selectedPlaceOfWork-" + item.name}
                                      checked={this.checkSelected(
                                        "selectedPlaceOfWork",
                                        item._id
                                      )}
                                      type="checkbox"
                                      value={item._id}
                                      onChange={(e) =>
                                        this.onChecked(
                                          e,
                                          "selectedPlaceOfWork",
                                          "placeofworkCount"
                                        )
                                      }
                                    />
                                    <span />
                                  </div>
                                  <span>{item.name}</span>
                                </div>
                              ))}
                            <InlineError message={placeofWorkError} />
                            <div className="text-center">
                              <div className="d-flex justify-content-between">
                                <button
                                  type="button"
                                  className="btn btn-light"
                                  onClick={() => this.signupNext(2)}
                                >
                                  Previous
                                </button>
                                <button
                                  type="button"
                                  disabled={this.props.isLoading}
                                  className="btn btn-red"
                                  onClick={() => this.signupNextStep(4)}
                                >
                                  {" "}
                                  &nbsp;Next &nbsp;
                                  {this.state.isLoading && <MySpinner />}
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      ) : (
                        ""
                      )}

                      {this.state.signupStep === 4 ? (
                        this.state.registrationIdRequired === false ? (
                          <div id="step3">
                            <h3 className="auth-heading pos-relative text-center mt-5">
                              What is your role?
                              <span className="heading-after-border heading-after-border-center">
                                <span className="heart">
                                  <Hert />
                                </span>
                              </span>
                            </h3>
                            {/*<p className="home-subheading text-center">Free for professionals and low cost options for brands and suppliers </p>*/}
                            <form
                              className="rounded-textbox-form mt-5"
                              //onSubmit={this.signupNextStep}
                            >
                              {this.state.roleList.length > 0 &&
                                this.state.roleList.map((item) => (
                                  <div
                                    className={
                                      this.checkSelected(
                                        "selectedRole",
                                        item._id
                                      )
                                        ? "form-group form-control select_field"
                                        : "form-group form-control"
                                    }
                                    key={item._id}
                                  >
                                    <div className="check_wrapper circle-checkbox ">
                                      <input
                                        name={"selectedRole-" + item.name}
                                        checked={this.checkSelected(
                                          "selectedRole",
                                          item._id
                                        )}
                                        type="checkbox"
                                        value={item._id}
                                        onChange={(e) =>
                                          this.onChecked(
                                            e,
                                            "selectedRole",
                                            "roleCount"
                                          )
                                        }
                                      />
                                      <span />
                                    </div>
                                    <span>{item.name}</span>
                                  </div>
                                ))}
                              {console.log(
                                "button",
                                this.state.signupStep,
                                this.state.secIds
                              )}

                              <InlineError message={roleError} />
                              <div className="text-center">
                                <div className="d-flex justify-content-between">
                                  <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={() => this.signupNext(3)}
                                  >
                                    Previous
                                  </button>
                                  {this.state.signupStep === 4 &&
                                  this.state.secIds === true ? (
                                    <button
                                      type="button"
                                      disabled={this.props.isLoading}
                                      className="btn btn-red"

                                      onClick={()=>this.signupNextStep()}
                                    >
                                      {" "}
                                      &nbsp;Next &nbsp;
                                      {this.state.isLoading && <MySpinner />}
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      disabled={this.props.isLoading}
                                      className="btn btn-red"
                                      onClick={this.handlesubmitEdit}
                                    >
                                      {" "}
                                      &nbsp;Submit &nbsp;
                                      {this.state.isLoading && <MySpinner />}
                                    </button>
                                  )}
                                </div>
                              </div>
                            </form>
                          </div>
                        ) : (
                          <div className="mt-5">
                            <h4 className="pl-4 pr-4">
                              Do you have a registered GDC/ GMC/ NMC/ GPhc
                              number or overseas equivalent?
                            </h4>
                            <div
                              className="rounded-textbox-form mt-5"
                              //   onSubmit={this.signupNextStep}
                            >
                              <div className="form-group">
                                <input
                                  type="text"
                                  autocomplete="do-not-show-ac"
                                  className="form-control"
                                  placeholder="Enter GDC/ GMC/ NMC/ GPhc number"
                                  name="registrationId"
                                  value={registrationId}
                                  onChange={this.sugnUpOnChange}
                                />
                                <InlineError message={registrationIdError} />
                              </div>

                              <div className="text-center">
                                <div className="d-flex justify-content-between">
                                  <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={() => this.signupNext(3)}
                                  >
                                    Previous
                                  </button>
                                  <button
                                    type="button"
                                    disabled={this.props.isLoading}
                                    className="btn btn-red"
                                    onClick={this.handlesubmitEdit}
                                  >
                                    {" "}
                                    &nbsp;Submit &nbsp;
                                    {this.state.isLoading && <MySpinner />}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      ) : (
                        ""
                      )}

                      {/* <div className="col-md-6">
                        <div className="form-group">
                          <label>Street address 1 </label>

                          <PlacesAutocomplete
                            value={this.state.address}
                            onChange={this.handleChange}
                            onSelect={this.handleSelect}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div className="">
                                <input
                                  autocomplete="do-not-show-ac"
                                  value={address}
                                  {...getInputProps({
                                    placeholder: "Search address",
                                    className: "form-control",
                                  })}
                                />
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion, i) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item";
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                      ? {
                                          backgroundColor: "#fafafa",
                                          cursor: "pointer",
                                        }
                                      : {
                                          backgroundColor: "#ffffff",
                                          cursor: "pointer",
                                        };
                                    return (
                                      <div
                                        key={i + "-add"}
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>

                          <InlineError message={streetAddress1Error} />
                        </div>
                      </div> */}
                      {/* <div className="col-md-6">
                        <div className="form-group">
                          <label>City</label>
                          <input
                            type="text"
                            autocomplete="do-not-show-ac"
                            className="form-control"
                            placeholder="City"
                            id="email"
                            maxLength={200}
                            name="city"
                            value={this.state.city}
                            onChange={this.signupProcessOnChange}
                          />
                          <InlineError message={cityError} />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="form-group">
                            <label>Country</label>
                            <Select
                              showSearch
                              autoComplete="new-password"
                              placeholder="Country"
                              value={
                                this.state.country ? this.state.country : null
                              }
                              onChange={(e) =>
                                this.selectOnChange("country", e)
                              }
                              name="country"
                              filterOption={(input, option) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) === 0
                              }
                            >
                              {util.countryOtption.map((item) => {
                                return (
                                  <Option value={item.displayValue}>
                                    {item.displayValue}
                                  </Option>
                                );
                              })}
                            </Select>
                            <InlineError message={countryError} />
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="col-md-6">
                        <div className="form-group">
                          <label>Street address 2</label>
                          <input
                            type="text"
                            autocomplete="do-not-show-ac"
                            className="form-control"
                            placeholder="Landmark / Locality"
                            id="email"
                            maxLength={200}
                            name="streetAddress2"
                            value={streetAddress2}
                            onChange={this.signupProcessOnChange}
                          />
                          <InlineError message={streetAddress2Error} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Zip / Postal code</label>
                          <input
                            type="text"
                            autocomplete="do-not-show-ac"
                            className="form-control"
                            placeholder="Zip / Postal code "
                            id="email"
                            autoComplete="off"
                            maxLength={10}
                            name="postalCode"
                            value={postalCode}
                            onChange={this.signupProcessOnChange}
                          />
                          <InlineError message={postalCodeError} />
                        </div>
                      </div> */}
                    </div>
                    {/* <div className="d-flex justify-content-between">
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => this.props.history.goBack()}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-red"
                        onClick={this.SignUpProcessProfileSubmit}
                        isHide={this.state.display}
                      >
                        Next
                      </button>
                    </div> */}
                  </div>
                </div>
              )}
              {/*{profileStep == 2 && (*/}
              {/*  <div className="work">*/}
              {/*    <div className="rounded-textbox-form mt-5">*/}
              {/*      <div className="row">*/}
              {/*        <div className="col-md-6 offset-md-3">*/}
              {/*          /!* <div className="form-group hidemobile991">*/}
              {/*            <label>Place of work</label>*/}
              {/*            <TreeSelect*/}
              {/*              style={{ width: "100%" }}*/}
              {/*              dropdownClassName="new_single_check"*/}
              {/*              value={this.state.placeOfWorkId}*/}
              {/*              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}*/}
              {/*              treeData={this.state.placeOfWorkData}*/}
              {/*              placeholder="Please select work"*/}
              {/*              //treeDefault*/}
              {/*              multiple={false}*/}
              {/*              treeCheckable={false}*/}
              {/*              dropdownClassName={"new_multi_check"}*/}
              {/*              onChange={this.onChangePlacework}*/}
              {/*              className="cm_multiselect"*/}
              {/*              showArrow*/}
              {/*              showCheckedStrategy={SHOW_ALL}*/}
              {/*              //treeCheckStrictly={true}*/}
              {/*              //dropdownMatchSelectWidth={50}*/}
              {/*            />*/}
              {/*          </div>*/}
              {/*          <div className="form-group showmobile991">*/}
              {/*            <label>*/}
              {/*              Place of work<span className="red-star"> * </span>*/}
              {/*            </label>*/}
              {/*            <div class="form-group">*/}
              {/*              <p*/}
              {/*                className="form-control"*/}
              {/*                onClick={() => this.setState({ visible: true })}*/}
              {/*              >*/}
              {/*                {this.state.placeOfWorkMobileTitle}*/}
              {/*              </p>*/}
              {/*            </div>*/}
              {/*          </div>*/}
              {/*          <InlineError message={placeOfWorkError} /> *!/*/}
              {/*          /!* <div className="form-group">*/}

              {/*                                      {*/}
              {/*                                          this.state.showOtherPlaceOfWork === true ?*/}
              {/*                                              <>*/}
              {/*                                                  <label>Please state</label>*/}
              {/*                                                  <input type='text' value={this.state.placeOfWorkOther} placeholder={'Enter place of work'} name="placeOfWorkOther" className="form-control" onChange={this.onChnageOther} />*/}
              {/*                                              </>*/}
              {/*                                              : ''*/}
              {/*                                      }*/}
              {/*                                      <InlineError*/}
              {/*                                          message={this.state.placeOfWorkOtherError}*/}
              {/*                                      />*/}
              {/*                                  </div> *!/*/}
              {/*          /!* <div>*/}
              {/*            <div className="form-group hidemobile991">*/}
              {/*              <label>Job title</label>*/}
              {/*              <TreeSelect*/}
              {/*                style={{ width: "100%" }}*/}
              {/*                value={*/}
              {/*                  this.state.jobTitleId*/}
              {/*                    ? this.state.jobTitleId*/}
              {/*                    : null*/}
              {/*                }*/}
              {/*                dropdownStyle={{*/}
              {/*                  maxHeight: 400,*/}
              {/*                  overflow: "auto",*/}
              {/*                }}*/}
              {/*                treeData={this.state.jobTitleData}*/}
              {/*                placeholder="Please select job"*/}
              {/*                dropdownClassName={"new_multi_check"}*/}
              {/*                treeDefault*/}
              {/*                // treeDefaultExpandAll*/}
              {/*                onChange={(e) =>*/}
              {/*                  this.onChangeSelect("jobTitleId", e)*/}
              {/*                }*/}
              {/*                className="cm_multiselect"*/}
              {/*                showArrow*/}
              {/*                showCheckedStrategy={SHOW_PARENT}*/}
              {/*              />*/}
              {/*            </div>*/}
              {/*            <div className="form-group showmobile991">*/}
              {/*              <label>*/}
              {/*                Job title<span className="red-star"> * </span>*/}
              {/*              </label>*/}
              {/*              <div class="form-group">*/}
              {/*                <p*/}
              {/*                  className="form-control"*/}
              {/*                  onClick={() =>*/}
              {/*                    this.setState({ visible3: true })*/}
              {/*                  }*/}
              {/*                >*/}
              {/*                  {this.state.jobTitleMobile}*/}
              {/*                </p>*/}
              {/*              </div>*/}
              {/*            </div>*/}
              {/*            <InlineError message={jobTitleError} /> *!/*/}

              {/*          /!* <div className="form-group">*/}

              {/*                                          {*/}
              {/*                                              this.state.showChangeJobOther == true ?*/}
              {/*                                                  <>*/}
              {/*                                                      <label>Please state</label>*/}
              {/*                                                      <input type='text' value={this.state.jobTitleOther} placeholder={'Enter job title'} name="jobTitleOther" className="form-control" onChange={this.onChnageOther} />*/}
              {/*                                                  </>*/}
              {/*                                                  : ''*/}
              {/*                                          }*/}
              {/*                                          <InlineError*/}
              {/*                                              message={this.state.jobTitleOtherError}*/}
              {/*                                          />*/}
              {/*                                      </div> *!/*/}
              {/*          /!* </div> *!/*/}
              {/*          /!* <div className="form-group hidemobile991">*/}
              {/*            <label>Number of people I work with</label>*/}
              {/*            <TreeSelect*/}
              {/*              style={{ width: "100%" }}*/}
              {/*              value={this.state.noOfPeopleWorkId}*/}
              {/*              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}*/}
              {/*              treeData={this.state.noOfPeopleWorkData}*/}
              {/*              placeholder="Please select"*/}
              {/*              treeDefault*/}
              {/*              // treeDefaultExpandAll*/}
              {/*              onChange={(e) =>*/}
              {/*                this.onChangeSelect("noOfPeopleWorkId", e)*/}
              {/*              }*/}
              {/*              className="cm_multiselect"*/}
              {/*              showArrow*/}
              {/*            />*/}
              {/*          </div>*/}
              {/*          <div className="form-group showmobile991">*/}
              {/*            <label>*/}
              {/*              Number of people I work with*/}
              {/*              <span className="red-star"> * </span>*/}
              {/*            </label>*/}
              {/*            <div class="form-group">*/}
              {/*              <p*/}
              {/*                className="form-control"*/}
              {/*                onClick={() => this.setState({ visible2: true })}*/}
              {/*              >*/}
              {/*                {this.state.noOfPeopleWorkMobile}*/}
              {/*              </p>*/}
              {/*            </div>*/}
              {/*          </div>*/}
              {/*          <InlineError message={noOfPeopleWorkError} />*/}
              {/*        </div> *!/*/}

              {/*          /!* <div className="col-md-6 "> *!/*/}
              {/*          /!* <div className="form-group triggerwrapper">*/}
              {/*                                      <label>The product and service you offer</label>*/}

              {/*                                      <TreeSelect*/}
              {/*                                          dropdownClassName="new_multi_check"*/}
              {/*                                          treeData={this.state.productAndServiceOfferData}*/}
              {/*                                          value={this.state.productAndServiceOfferId}*/}
              {/*                                          onChange={(e) => this.onChangeSelect("productAndServiceOfferId", e)}*/}
              {/*                                          treeCheckable={true}*/}
              {/*                                          // treeCheckStrictly: false,*/}
              {/*                                          showCheckedStrategy={SHOW_CHILD}*/}
              {/*                                          placeholder='Please select'*/}
              {/*                                          style={{*/}
              {/*                                              width: '100%',*/}
              {/*                                              border: 'none'*/}
              {/*                                          }}*/}
              {/*                                          showSearch={false}*/}
              {/*                                          className="cm_multiselect cmslect_multicheck"*/}
              {/*                                          showArrow*/}
              {/*                                      />*/}
              {/*                                      <InlineError*/}
              {/*                                          message={productAndServiceError}*/}
              {/*                                      />*/}
              {/*                                  </div> *!/*/}
              {/*          /!* <div className="form-group">*/}
              {/*                                      <label>Why are you attending/ what are you looking for?</label>*/}

              {/*                                      <TreeSelect*/}
              {/*                                          treeData={this.state.productAndServiceOfferData}*/}
              {/*                                          dropdownClassName={"new_multi_check"}*/}
              {/*                                          value={this.state.attendingOrLookingforId}*/}
              {/*                                          onChange={(e) => this.onChangeSelect("attendingOrLookingforId", e)}*/}
              {/*                                          treeCheckable={true}*/}
              {/*                                          // treeCheckStrictly: false,*/}
              {/*                                          showCheckedStrategy={SHOW_CHILD}*/}
              {/*                                          placeholder={'Please select'}*/}
              {/*                                          style={{*/}
              {/*                                              width: '100%',*/}
              {/*                                              border: 'none'*/}
              {/*                                          }}*/}
              {/*                                          showSearch={false}*/}
              {/*                                          className="cm_multiselect cmslect_multicheck"*/}
              {/*                                          showArrow*/}
              {/*                                      />*/}

              {/*                                      <InlineError*/}
              {/*                                          message={attandingLookingForError}*/}
              {/*                                      />*/}
              {/*                                  </div> *!/*/}
              {/*          /!* <div className="form-group">*/}
              {/*                                      <label>What is your budget to spend in next 6 months? </label>*/}
              {/*                                      <TreeSelect*/}
              {/*                                          style={{ width: '100%' }}*/}
              {/*                                          value={this.state.budgetId ? this.state.budgetId : null}*/}
              {/*                                          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}*/}
              {/*                                          treeData={this.state.budgetData}*/}
              {/*                                          placeholder={"Please select"}*/}
              {/*                                          treeDefault*/}
              {/*                                          // treeDefaultExpandAll*/}
              {/*                                          onChange={(e) => this.onChangeSelect("budgetId", e)}*/}
              {/*                                          className="cm_multiselect"*/}
              {/*                                          showArrow*/}
              {/*                                      />*/}

              {/*                                      <InlineError*/}
              {/*                                          message={selectBudgetError}*/}
              {/*                                      />*/}
              {/*                                  </div> *!/*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*      /!* <div className="col-md-6 offset-md-3">*/}
              {/*        <div className="d-flex justify-content-between">*/}
              {/*          <button*/}
              {/*            type="button"*/}
              {/*            className="btn btn-light"*/}
              {/*            onClick={() => this.handlePrevious()}*/}
              {/*          >*/}
              {/*            Previous*/}
              {/*          </button>*/}
              {/*          <button type="button" className="btn btn-light" onClick={() => this.props.history.goBack()}>Cancel</button>*/}
              {/*          <button*/}
              {/*            type="button"*/}
              {/*            className="btn btn-red"*/}
              {/*            onClick={this.SignUpProcessWorkSubmit}*/}
              {/*          >*/}
              {/*            Submit*/}
              {/*          </button>*/}
              {/*        </div>*/}
              {/*      </div> *!/*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*)}*/}
            </div>
         
          </div>

          <Modal
            title="Place of work"
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            wrapClassName="signup-mobilepopup"
            footer={false}
            centered
          >
            <Tree
              // showLine={CarryOutOutlined}
              // showIcon={false }
              checkable={false}
              style={{ width: "100%" }}
              dropdownClassName="new_single_check"
              //treeDefault
              value={this.state.placeOfWorkId}
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              treeData={this.state.placeOfWorkData}
              placeholder="Please select work"
              //treeDefault
              multiple={false}
              treeCheckable={false}
              dropdownClassName={"new_multi_check"}
              onSelect={this.onChangePlacework2}
              //onCheck={this.onChangePlacework}
              defaultExpandAll={true}
              className="cm_multiselect"
              showArrow
              // defaultCheckedKeys ={this.state.placeOfWorkId}
              //  checkedKeys ={this.state.placeOfWorkId}
              showCheckedStrategy={SHOW_ALL}
            />
          </Modal>

          <Modal
            title="Number of people I work with"
            visible={this.state.visible2}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            wrapClassName="signup-mobilepopup"
            footer={false}
            centered
          >
            <Tree
              //  showLine={CarryOutOutlined}
              //  showIcon={false }
              checkable={false}
              style={{ width: "100%" }}
              dropdownClassName="new_single_check"
              //treeDefault
              value={this.state.noOfPeopleWork}
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              treeData={this.state.noOfPeopleWorkData}
              placeholder="Please select work"
              //treeDefault
              multiple={false}
              treeCheckable={false}
              dropdownClassName={"new_multi_check"}
              onSelect={this.onChangeNoOfPeopleWork}
              //onCheck={this.onChangePlacework}
              defaultExpandAll={true}
              className="cm_multiselect"
              showArrow
              // defaultCheckedKeys ={this.state.placeOfWorkId}
              //  checkedKeys ={this.state.placeOfWorkId}
              showCheckedStrategy={SHOW_ALL}
            />
          </Modal>

          <Modal
            title="Job title"
            visible={this.state.visible3}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            wrapClassName="signup-mobilepopup"
            footer={false}
            centered
          >
            <Tree
              //  showLine={CarryOutOutlined}
              //  showIcon={false }
              checkable={false}
              style={{ width: "100%" }}
              dropdownClassName="new_single_check"
              //treeDefault
              value={this.state.jobTitle}
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              treeData={this.state.jobTitleData}
              placeholder="Please select work"
              //treeDefault
              multiple={false}
              treeCheckable={false}
              dropdownClassName={"new_multi_check"}
              onSelect={this.onChangeJobTitle}
              //onCheck={this.onChangePlacework}
              defaultExpandAll={true}
              className="cm_multiselect"
              showArrow
              // defaultCheckedKeys ={this.state.placeOfWorkId}
              //  checkedKeys ={this.state.placeOfWorkId}
              showCheckedStrategy={SHOW_ALL}
            />
          </Modal>
        </div>

        {this.state.isCropper ? (
          <Croper
            aspect={1 / 1}
            // onImageCrop={img => this.setState({ media: img })}
            cropShape="round"
            imgPrev={"1-1.jpg"}
            finalCropedImage={(categoryImgPrev, categoryImg, visibleVal) =>
              this.finalCropedImage(categoryImgPrev, categoryImg, visibleVal)
            }
            visibleVal={this.state.isCropper}
            onClose={() => this.setState({ isCropper: false })}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

// export default EditUserProfile;
function mapStateToProps(state) {
  return {
    ...state,
  };
}
export default connect(mapStateToProps)(EditUserProfile);
