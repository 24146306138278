import React from 'react'
import { DatePicker, TimePicker, Select } from 'antd';
import InlineError from '../../../../components/InlineError';
import moment from 'moment-timezone';
import { _Api } from '../../../../services/Api';
import Url from '../../../../services/BaseUrl';
import { notify } from '../../../../components/Toaster'
import { CircleCross } from "../../../../SvgIcon";
import { MySpinner } from "../../../../components/index"
import TimeZone from "../../../../utils/timeZone"

const { RangePicker } = DatePicker;

const { Option } = Select;

const disabledDate = (current) => {
    return current && moment(current.valueOf()).format('MM/DD/YYYY') < moment().format('MM/DD/YYYY');
}


class TimeablePopup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            speakerName: '',
            speakerNameError: '',
            speakerDesignation: '',
            startDate: moment().valueOf(),
            endDate: moment().valueOf(),
            startTime: moment().valueOf(),
            endTime: moment().valueOf(),
            startError: '',
            startTimeError: '',
            endDateError: '',
            endTimeError: '',
            stage: '',
            stageError: '',
            isEdit: this.props.isEdit,
            videoUrl: '',
            description: '',
            descriptionError: '',
            sessionName: '',
            sessionNameError: '',
            loading: false,
            timezone: moment.tz.guess(),


            speakerId: []
        }
    }
    handleOnChange = (type, e) => {
        console.log('sakldlsakdnjasnkdsam', e)
        this.setState({ [type]: e, stageError: "", startDateError: '', endDateError: '', startTimeError: '', endTimeError: "", liveDateError: '', liveTimeError: '', sessionNameError: '' })
    }

    onChangeDate = (value, dateString) => {
        console.log('Selected Time: ', value)
        console.log('Formatted Selected Time: ', dateString);
        if (value)
            this.setState({ startDate: moment(value[0]).valueOf(), startTime: moment(value[0]).valueOf(), endDate: moment(value[1]).valueOf(), endTime: moment(value[1]).valueOf() })
    }


    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, speakerNameError: '', speakerDesignationError: '', stageError: '', sessionNameError: '', })
    }
    AddTimeTable = () => {
        console.log("kdsbvncd", this.state.isEdit)
        if (this.state.isEdit == true) {
            this.editTimeTable()
        }
        else
            this.submitTimeTable()
    }


    submitTimeTable = async () => {
        let { description, sessionName, videoUrl, speakerId, selectedFile, state, speakerDesignation, speakerName, stageType, startDate, endDate, startTime, endTime, stage, } = this.state
        let fillSlots = this.props.timeTableDetails


        try {

            // let isFlag = await this.checkSlotAvailable(fillSlots, { startDate, endDate, startTime, endTime })

            console.log("lkjhgfsdgvhbj")


            if (sessionName == '')
                this.setState({ sessionNameError: '*Please enter session name.' })
            else if (startDate == '')
                this.setState({ startDateError: '*Please enter start date.' })
            else if (startTime == '')
                this.setState({ startTimeError: '*Please enter start time.' })
            else if (endDate == '')
                this.setState({ endDateError: '*Please enter end date.' })
            else if (endTime == '')
                this.setState({ endTimeError: '*Please enter end time.' })

            // else if (isFlag) {
            //     this.setState({startDateError:'*Time slot not available.'})
            // }


            else {
                this.setState({ loading: true })
                let req = {
                    // conferenceId: this.props.match.params.coId,                   
                    sessionName: sessionName,
                    exhibitionId: localStorage.getItem('exhibition'),
                    stageId: this.props.match.params.id,
                    // speakerId: this.state.speakerId.toString(),
                    videoUrl: videoUrl,
                    startDate: startDate,
                    endDate: endDate,
                    startTime: startTime,
                    endTime: endTime,
                    media: selectedFile,
                    timezone: this.state.timezone,
                    description: description
                }
                if (this.state.speakerId != "") {
                    req.speakerId = this.state.speakerId.toString()

                }
                _Api(Url.ADD_NEW_TIMETABLE.method, Url.ADD_NEW_TIMETABLE.url, req)
                    .then(resp => {
                        // console.log('resp1111111', resp.responseData.stageDetails._id)
                        this.setState({ loading: false })
                        localStorage.setItem('stagid', resp.responseData.stageDetails._id)
                        this.props.closePopup()

                        notify('success', resp.responseData.message)
                    }).catch(err => {
                        this.setState({ loading: false })
                        if (err.error)
                            notify("err", err.error.responseMessage)
                        // else if (err)
                        // notify("err", err.message)
                    })
            }
        } catch (err) {
            console.log("lkjhgfsdgvhbj", err)

        }
    }

    editTimeTable = () => {
        let { description, sessionName, videoUrl, speakerId, selectedFile, state, speakerDesignation, speakerName, stageType, startDate, endDate, startTime, endTime, stage, } = this.state
        try {
            // if (speakerName == '')
            //     this.setState({ speakerNameError: '*Please enter speaker name.' })
            // else if (speakerDesignation == '')
            //     this.setState({ speakerDesignationError: '*Please enter job title.' })
            if (sessionName == '')
                this.setState({ sessionNameError: '*Please enter session name.' })
            else if (startDate == '')
                this.setState({ startDateError: '*Please enter start date.' })
            else if (startTime == '')
                this.setState({ startTimeError: '*Please enter start time.' })
            else if (endDate == '')
                this.setState({ endDateError: '*Please enter end date.' })
            else if (endTime == '')
                this.setState({ endTimeError: '*Please enter end time.' })
            // else if (stage == '')
            //     this.setState({ stageError: '*Please enter stage.' })
            else {
                this.setState({ loading: true })
                let req = {
                    sessionName: sessionName,
                    timeTableId: this.props.timeTable._id,
                    conferenceId: this.props.match.params.coId,
                    stageId: this.props.match.params.stId,
                    speakerId: this.state.speakerId.toString(),
                    videoUrl: videoUrl,
                    // designation: speakerDesignation,
                    // stage: stage,
                    startDate: startDate,
                    endDate: endDate,
                    startTime: startDate,
                    endTime: endDate,
                    media: selectedFile,
                    description: description,
                    timezone: this.state.timezone
                }
                _Api(Url.EDIT_TIME_TABLE.method, Url.EDIT_TIME_TABLE.url, req)
                    .then(resp => {
                        // console.log('resp1111111', resp.responseData.stageDetails._id)
                        this.setState({ loading: false })
                        localStorage.setItem('stagid', resp.responseData.stageDetails._id)
                        this.setState({ isEdit: false })
                        this.props.closePopup()

                        notify('success', resp.responseData.message)
                    }).catch(err => {
                        this.setState({ loading: false })
                        if (err.error)
                            notify("err", err.error.responseMessage)
                        // else if (err)
                        // notify("err", err.message)
                    })
            }
        } catch  { }
    }

    componentDidMount() {
        if (this.props.isEdit) {
            let timeTableData = this.props.timeTable;
            const { speakerId } = timeTableData || {}
            console.log("sadjksad", speakerId)
            console.log("datatata", moment(timeTableData.startDate).toDate())
            this.setState({
                sessionName: timeTableData.sessionName ? timeTableData.sessionName : '',
                speakerId: speakerId?.map(item => item._id),
                description: timeTableData.description ? timeTableData.description : '',
                videoUrl: timeTableData?.videoUrl ? timeTableData?.videoUrl : '',
                // stage: timeTableData.stage,
                startDate: timeTableData.startDate,
                endDate: timeTableData.endDate,
                timezone: timeTableData?.timezone ? timeTableData?.timezone :  moment.tz.guess(),
                startTime: moment(new Date(timeTableData.startTime)),
                endTime: moment(new Date(timeTableData.endTime)),
            })
        }
    }



    handleChangeTimeZone = (value) => {
        // moment.tz.setDefault(value)
        console.log("dkjhasghdjk", moment.tz(this.state.startDate, this.state.timezone).utcOffset())
        this.setState({ timezone: value })
    }




    checkSlotAvailable = (allSlot, select) => {

        return new Promise((resolve, reject) => {
            allSlot.forEach(item => {
                console.log("itemTime", item)

                let startDate = item.startDate
                let endDate = item.endDate
                let startTime = item.startTime
                let endTime = item.endTime





                let selectDateStart = select.startDate
                let selectEndDate = select.endDate.valueOf()
                let selectStartTime = select.startTime
                let selectEndTime = select.endTime

                var beginningTime = moment(startTime).valueOf();
                var endTimee = moment(endTime).valueOf();

                var selectedBeginningTime = moment(selectStartTime).valueOf();
                var selectedEndTimee = moment(selectEndTime).valueOf();

                // console.log("difff", {diff: startDate == selectDateStart,s: moment(startDate).format("DDMMYYYY") == moment(selectDateStart).format("DDMMYYYY") , chk: selectDateStart.isSame(moment(startDate)) } )

                if (moment(startDate).format("DDMMYYYY") == moment(selectDateStart).format("DDMMYYYY")) {


                    if (moment(beginningTime).format("HHmm") <= moment(selectedBeginningTime).format("HHmm") && moment(endTimee).format("HHmm") >= moment(selectedBeginningTime).format("HHmm")) {
                        console.log("sbdhjdbnkajshdhbaskd111111", { s: moment(beginningTime).format("HHmm"), ed: moment(endTimee).format("HHmm"), ss: moment(selectedBeginningTime).format("HHmm"), endTimee, selectedEndTimee })

                        resolve(true)

                    }
                    else
                        console.log("sbdhjdbnkajshdhbaskd222222", { s: moment(beginningTime).format("HHmm"), ed: moment(endTimee).format("HHmm"), ss: moment(selectedBeginningTime).format("HHmm"), endTimee, selectedEndTimee })

                    // console.log("gasjkdashbdnmaslmd", { startDate, endDate, dateCmp: startDate == endDate, startTime, endTime })

                }







            })

            resolve(false)

        })

    }

    render() {
        let { description, sessionName, sessionNameError, descriptionError, videoUrl, stage, startDate, endDate, endTime, startTime, stageError, speakerName, speakerDesignation, speakerNameError, speakerDesignationError, startTimeError, endTimeError, startDateError, endDateError } = this.state

        console.log("djjkfllsdhfhjk", startDate)

        let dateFormat = 'DD-MM-YYYY'

        return (
            <div className='popup'>
                <div className='popup_inner'>
                    <div className="rounded-body-form">
                        <div className="rounded-textbox-form modal-wrapper">
                            <div className="header-text mb-5">
                                <h4> {this.props.isEdit ? "Edit TimeTable" : "Add TimeTable"}</h4>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    {/* <div className="col-md-6"> */}
                                    <div className="form-group">
                                        <level>Session Name</level>
                                        <input type="text"
                                            className="form-control"
                                            placeholder="Enter session name."
                                            maxLength={256}
                                            name="sessionName"
                                            value={sessionName}
                                            onChange={this.handleChange}
                                        />
                                        <InlineError
                                            message={sessionNameError}
                                        />
                                    </div>
                                </div>
                                {/* </div> */}
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <level>Select speaker</level>
                                        <Select
                                            className="cm_multiselect cmslect_multicheck"
                                            mode="multiple"
                                            style={{ width: '100%' }}
                                            tokenSeparators={[',']}
                                            name='selectType'
                                            value={[...this.state.speakerId]}
                                            defaultValue={this.state.speakerId}
                                            placeholder='Select speakers'
                                            onChange={(e) => this.handleOnChange("speakerId", e)}
                                        >
                                            {
                                                this.props?.speakerData?.map((item, index) => {
                                                    return (
                                                        <Option key={index} value={item._id}>{item.firstName} {item.lastName}</Option>

                                                    )
                                                })
                                            }
                                        </Select>
                                        {/* <InlineError */}
                                        {/* // message={productCategoryError} */}
                                        {/* /> */}
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <level>Video url</level>
                                        <input type="url"
                                            className="form-control"
                                            placeholder="Enter video url"
                                            // maxLength={64}
                                            name="videoUrl"
                                            value={videoUrl}
                                            onChange={this.handleChange}
                                        />

                                    </div>

                                </div>

                            </div>

                            <div className="row">
                                <div className="col-md-12">

                                    <level>Time Zone</level>

                                    <div className="form-group">
                                        <Select showSearch autoComplete="new-password" placeholder="Time zone"
                                            value={this.state.timezone}
                                            defaultValue={this.state.timezone}

                                            onChange={(e) => this.handleChangeTimeZone(e)} name='country'

                                        >
                                            {TimeZone.map((item) => {
                                                return (<Select.Option value={item}>{item}</Select.Option>)
                                            })}

                                        </Select>
                                    </div>

                                </div>
                            </div>

                            <div className="row">
                                {/* <div className="col-md-6"> */}
                                <div className="col-md-12">
                                    <level>Start, End date and time</level>
                                    <RangePicker
                                        inputReadOnly
                                        showTime={{ format: 'HH:mm' }}
                                        value={[moment.tz(startDate, this.state.timezone), moment.tz(endDate, this.state.timezone)]}
                                        format="DD-MM-YYYY HH:mm"
                                        onChange={this.onChangeDate}
                                    // onOk={onOk}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <level>Description</level>
                                        <textarea className="textarea form-control" rows="4"
                                            placeholder="Description..."
                                            maxLength={500}
                                            name="description"
                                            value={description}
                                            onChange={this.handleChange}
                                        />
                                        {/* <InlineError
                                        message={descriptionError}
                                    /> */}
                                    </div>
                                </div>
                            </div>
                            <div className="text-right pt-4">
                                <button type='button' disabled={this.state.loading} className="btn btn-primary" onClick={this.AddTimeTable}>Save
                                {this.state.loading &&
                                        <MySpinner />
                                    }
                                </button>
                            </div>
                            <button className="close-btn" onClick={this.props.closePopup}><CircleCross /></button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TimeablePopup