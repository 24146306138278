import React, { Component } from 'react';
import { Calender, Delete, Hert, Pencil, Chat, ZoomVideo } from "../../../SvgIcon";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Loader from '../../../components/Loader'
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';
import { Select, Checkbox, Table, Button, Dropdown, Menu } from 'antd';
import socket from "../../../services/socket";
import { groupActions } from "../../../redux/action";

const { Option } = Select;
const data = [];
for (let i = 0; i < 10; i++) {
    data.push();
}

function hasNumber(n) {
    return !isNaN(n);
  }

const COLUMN_STYLE = { width: 200 };
class Enquiry extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoader: true,
            value: false,
            checkedColumns: [],
            download: 0,
            limit:20,
            page:1,
            list: [],
            selectedItems: [],
            visibleMenuSettings: false,
            columns: [

                {
                    title: 'Name	',
                    dataIndex: 'Name',
                   
                    style:{COLUMN_STYLE}
                    
                }
                // ,
                // {
                //     title: 'Last Name	',
                //     dataIndex: 'LastName',
                // }
                ,
                {
                    title: 'Email',
                    dataIndex: 'Email',
                    
                },
                {
                    title: 'Phone Number',
                    dataIndex: 'PhoneNumber',
                    
                },
                {
                    title: 'Category',
                    dataIndex: 'Category',
                    
                },
                {
                    title: 'Enquired For',
                    dataIndex: 'EnquiredFor',
                    
                },
                {
                    title: 'Reason for enquiry',
                    dataIndex: 'ReasonForEnquiry',
                    
                },
                {
                    title: 'Enquiry status',
                    dataIndex: 'EnquiryStatus',
                },
                {
                    title: 'Assign to',
                    dataIndex: 'AssignStatus',
                },
                {
                    title: 'Last Modified',
                    dataIndex: 'LastModified',
                     width: '200px'
                },
                {
                    title: 'Action',
                    dataIndex: 'Action',
                    fixed: 'right',
                    width: 160,
                },

            ],
        }


    }

    componentDidMount() {
        this.props.dispatch({ type: 'PATH_ADD', payload: this.props.match })
        this.setState({ initialColumns: this.state.columns })

        
        
        if (this.props.match.params.pageNO && hasNumber(this.props.match.params.pageNO)) {
            this.setState({ page: Number(this.props.match.params.pageNO) },()=>{
                this.getListData();
            })
        }
        else{
            this.getListData()
        }
        this.getStaffDetails()
    }


    getListData = () => {
        let { page, limit, search, sortField, sortType } = this.state;


        _Api(Url.GET_LIST_ENQUIRES.method, Url.GET_LIST_ENQUIRES.url, '', `?download=${0}&limit=${limit}&page=${page}`)
            .then(resp => {
                console.log("dghjkl", resp)
                this.setState({ list: resp?.responseData?.result?.data, isLoader: false, totalCount : resp?.responseData?.result?.totalCount })
            }).catch(err => {
                this.setState({ isLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
                this.setState({ isLoader: false })
            })
    }

    handleDownload = () => {
        _Api(Url.GET_LIST_ENQUIRES.method, Url.GET_LIST_ENQUIRES.url, '', `?download=${1}`)
            .then(resp => {
                window.open(resp.responseData.file, "_blank");
            }).catch(err => {
                this.setState({ isLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
                this.setState({ isLoader: false })
            })
    }


    handleVisibleChange = flag => {
        this.setState({ visibleMenuSettings: flag });
    }



    assignEnquire = (assignId, enquiryId) => {
        this.setState({ isLoader: true })

        _Api(Url.ASSIGN_ENQUIRE.method, Url.ASSIGN_ENQUIRE.url, '', `?enquiryId=${enquiryId}&assignId=${assignId}`)
            .then(resp => {
                console.log('respexList', resp.responseData.result.data)
                this.setState({ isLoader: false })
                this.getListData()
            }).catch(err => {
                this.setState({ isLoader: false })
            })
    }



    getStaffDetails = () => {
        // this.setState({ isLoader: true })
        let { page, limit, search } = this.state
        let req = {
            page: 1,
            limit: 100,
        }
        _Api(Url.GET_STAFF_LIST_Details.method, Url.GET_STAFF_LIST_Details.url, '', `?page=${req.page}&limit=${req.limit}&brandId=${localStorage.getItem("ownerId") ? localStorage.getItem("ownerId") : localStorage.getItem("id")}`)
            .then(resp => {
                console.log('respexList', resp.responseData.result.data)
                this.setState({ StaffListData: resp.responseData.result, isLoader: false })
            }).catch(err => {
                this.setState({ isLoader: false })
            })
    }


    handleVisibleChange1 = (flag, index) => {
        console.log("djawhgsvhbn", flag, index)
        this.setState({ [`drop1${index}`]: flag })
    }

    onChange = (e) => {
        var checkedColumns = this.state.checkedColumns
        if (e.target.checked) {
            checkedColumns = checkedColumns.filter(id => { return id !== e.target.id })

        }
        else if (!e.target.checked) {
            checkedColumns.push(e.target.id)

        }
        var filtered = this.state.initialColumns;
        for (var i = 0; i < checkedColumns.length; i++)
            filtered = filtered.filter(el => { return el.dataIndex !== checkedColumns[i] })
        this.setState({ columns: filtered, checkedColumns: checkedColumns })
    }

    handleStatus = (e, id) => {
        this.setState({ isLoader: true })
        _Api(Url.CHANGE_STATUES_ENQUIRES.method, Url.CHANGE_STATUES_ENQUIRES.url,
            '', `?enquiryId=${id}&status=${e}`)
            .then(resp => {
                this.getListData()
            }).catch(err => {
                this.setState({ isLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
                this.setState({ isLoader: false })
            })

    }

    getStatus = (number) => {
        switch (number) {
            case 1:
                return 'Pending'
            case 2:
                return 'In Progress'
            case 3:
                return 'Done'
            default:
                return 'In Progress'
        }

    }

    getCategory = (number) => {
        switch (number) {
            case 0:
                return 'Training'
            case 1:
                return 'Job'
            case 2:
                return 'Business'
            default:
                return 'Job'
        }

    }

    selectUser = (user) => {
        let self = this;
        console.log("user", this.props.userDetail._id, user._id);
        if (this.props.userDetail._id !== user._id) {
            this.setState({
                selectedUser: user,
                selectedUserId: user._id,
                chatType: 3,
                isShow: true
            }, function () {
                self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 3 })
                self.props.dispatch({ type: 'CHAT_OPEN' })
                document.body.classList.toggle('chat_open_mob');
                self.props.dispatch({ type: 'SELECTED_USER', selectedUser: user })
                socket.emit('groupAdd', { type: 7, userId: user._id });
                //socket.emit('userConnection', {userId: user._id});
            })
        }

    }



    getName = (number, item) => {
        // console.log("djshgghfddddddd", item)
        switch (number) {
            case 0:
                return <Link to={`/web-training-details/${item?.offerId}`}> {item?.name}</Link>
            case 1:
                return <Link to={`/web-job-details/${item?.productId}`}> {item?.productName}</Link>
            case 2:
                return <>
                    {
                        item?.userData?.activeCompany ?
                            <Link to={`/business/${item?.userData?._id}/${item?.userData?.activeCompany}`}> {`${item?.userData?.companyName}`}</Link>
                            :
                            <Link to={`/business/${item?.userData?._id}`}> {`${item?.userData?.companyName}`}</Link>
                    }
                </>


            default:
                return ''
        }

    }
    videoCalling = (userId) => {
        let self = this;

        if (userId) {
            this.setState({ startLoader: true })

            let oldArr = this.state.selectedItems
            oldArr.push(userId)

            self.setState({
                selectedItems: oldArr,

                ['videoConnectionLoder_' + userId]: true
            })
            self.props.dispatch(groupActions.joinOneMeeting({ userId: userId, type: 7 }))
                .then(function (res) {
                    self.setState({
                        ['videoConnectionLoder_' + userId]: false, startLoader: false,
                        selectedItems: self.state.selectedItems.filter(item => item != userId)
                    })
                    if (res.statusCode === 1 || res.statusCode === "1") {
                        //window.location.href = '/calling/' + self.props.groupDetail.meeting._id;
                        window.open('/calling/' + self.props.groupDetail.meeting._id, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                        //history.push('/calling/'+self.props.groupDetail.meeting._id)
                    } else {
                        self.setState({ startLoader: false, selectedItems: self.state.selectedItems.filter(item => item != userId) })

                    }
                    console.log("res", res)
                    // self.setState({ startLoader: false,  selectedItems: self.state.selectedItems.filter(item => item != userId) })
                })
        }


    }

    handlePageChange = (page)=>{
        console.log("dbhnjmsak", page)
        this.props.history.push(`/enquiry/${page}`)
        this.setState({ page, snNo: page - 1, list: [] }, () => {
            this.getListData()
        })
    }

    render() {

        console.log("dafghjkl", this.state)
        const pagination = {
            current: 3,
            pageSize: 10,
          }

        const menu = (
            <Menu>
                <Menu.ItemGroup title="Columns" >
                    <Menu.Item key="1"><Checkbox id="Name" onChange={this.onChange} defaultChecked>Name</Checkbox></Menu.Item>
                    {/* <Menu.Item key="2"><Checkbox id="LastName" onChange={this.onChange} defaultChecked>Last Name</Checkbox></Menu.Item> */}
                    <Menu.Item key="3"><Checkbox id="Email" onChange={this.onChange} defaultChecked>Email</Checkbox></Menu.Item>
                    <Menu.Item key="4"><Checkbox id="PhoneNumber" onChange={this.onChange} defaultChecked>Phone Number</Checkbox></Menu.Item>
                    <Menu.Item key="5"><Checkbox id="Category" onChange={this.onChange} defaultChecked>Category</Checkbox></Menu.Item>
                    <Menu.Item key="6"><Checkbox id="EnquiredFor" onChange={this.onChange} defaultChecked>Enquired For</Checkbox></Menu.Item>
                    <Menu.Item key="7"><Checkbox id="ReasonForEnquiry" onChange={this.onChange} defaultChecked>Reason for enquiry</Checkbox></Menu.Item>
                    <Menu.Item key="8"><Checkbox id="EnquiryStatus" onChange={this.onChange} defaultChecked>Enquiry status</Checkbox></Menu.Item>
                    <Menu.Item key="9"><Checkbox id="AssignStatus" onChange={this.onChange} defaultChecked>Assign to</Checkbox></Menu.Item>
                    <Menu.Item key="10"><Checkbox id="LastModified" onChange={this.onChange} defaultChecked>Last Modified</Checkbox></Menu.Item>


                </Menu.ItemGroup>
            </Menu>
        )


        const MenuStatus = (data) => {
            console.log("hihihihih", data)
            return (
                <Menu>
                    <Menu.ItemGroup title="" >
                        <Menu.Item key="0" onClick={(e) => this.handleStatus(e, data?.data?._id)}>In-progress</Menu.Item>
                        <Menu.Item key="1" onClick={(e) => this.handleStatus(e, data?.data?._id)}>Done</Menu.Item>
                    </Menu.ItemGroup>
                </Menu>
            )
        }

      




        return (
            <div className="container-fluid enquire_parents">
                {
                    this.state.isLoader && <Loader />
                }
                <div className="row">
                    <div className="col-sm-12">
                        <h2 className=" pos-relative text-center mt-5">
                            Enquiries Details
                    <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                        </h2>
                        <br />
                        <div className="d-flex align-items-center justify-content-between">
                            <Dropdown
                                overlay={menu}
                                onVisibleChange={this.handleVisibleChange}
                                visible={this.state.visibleMenuSettings}
                            >
                                <Button>Show/Hide</Button>
                            </Dropdown>
                            <button class="btn btn-warning btn-sm changepass mr-3" onClick={this.handleDownload}>
                                <i class="fa fa-download mr-2" aria-hidden="true" title="Download csv"></i>Download
                    </button>
                        </div>

                        {/* ----new dynamic table---- */}
                        <div class="table-responsive ant-table-container mt-3">




                            <Table
                            pagination={{
                                onChange:this.handlePageChange,
                                current: this.state.page,
                                pageSize: 20
                            }}
                            
                            columns={this.state.columns} columnWidth='200px' dataSource={
                                this.state?.list?.map((item, index) => {
                                    console.log("dsahdjkas", item)

                                    const {
                                        _id,
                                        enquireStatus,
                                        status,
                                        isDeleted,
                                        enquiryReason,
                                        category,
                                        created,
                                        productName,
                                        firstName,
                                        lastName,
                                        Name,
                                        email,
                                        name,
                                        phoneNumber,
                                        isInfoPublic,
                                        assignName,
                                        lastModifiedName,
                                        isVideoPublic,
                                        brandData,                                        
                                        isChatPublic,                                        
                                        isMobilePublic,

                                    } = item

                                    let flag = this.state[`drop1${index}`]
                                   

                                    return (
                                        {
                                            key: index,
                                            Name: <p className="wrap">{firstName + " "+ lastName}</p>,
                                            Email: isInfoPublic == 1 ? <p className="wrap">{email}</p> : '*****',
                                            PhoneNumber: isMobilePublic == 1 ? <p className="wrap">{phoneNumber}</p> : '*****',
                                            Category: <p className="wrap">{this.getCategory(category)}</p>,
                                            EnquiredFor: <p className="wrap">{this.getName(category, item)}</p>,
                                            ReasonForEnquiry: <p className="wrap">{enquiryReason}</p>,
                                            EnquiryStatus:
                                                <Select defaultValue={this.getStatus(item.enquireStatus)} className="enqire" onSelect={e => this.handleStatus(e, _id)} style={{ width: '150px' }}>
                                                    <Option value={1} > {'Pending'}</Option>
                                                    <Option value={2} > {'In Progress'} </Option>
                                                    <Option value={3} > {'Done'} </Option>

                                                </Select>,
                                            AssignStatus:
                                                <Select defaultValue={assignName ? assignName : "Please  Select"} className="enqire" onSelect={e => this.assignEnquire(e, _id)} style={{ width: '200px' }}>
                                                    {
                                                        this.state.StaffListData?.map((item) => {
                                                            return (
                                                                <Option value={item._id} > {item?.firstName} {item?.lastName}</Option>

                                                            )
                                                        })
                                                    }

                                                </Select>,
                                            LastModified: <p className="wrap">{lastModifiedName}</p>,

                                            Action: <div className="action_group d-flex">
                                                <Link to={`/enquiry-details/${item._id}`}><p><p className="circle-serial"><i class="fa fa-eye" aria-hidden="true"></i></p></p></Link>
                                               {isChatPublic == 1 && <a><p><p onClick={() => this.selectUser(item.brandData)} className="circle-serial"><Chat /></p></p></a>}
                                                {isVideoPublic == 1 && <a className="ml-1"
                                                   onClick={() => this.videoCalling(brandData?._id) }
                                                ><p><p className="circle-serial"><ZoomVideo /></p></p></a>}
                                            </div>
                                        }
                                    )
                                }
                                )


                            } className="cm_ant-table" />




                        </div>
                    </div>
                </div>
            </div>
        )
    }

}



function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(Enquiry);

