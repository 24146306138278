import { BtnRed, CmBtn } from "../../../components/common/Button";
import { ZoomVideo, Chat, NoMediaICon } from "../../../SvgIcon";
import React, { Component } from 'react';
import WebHeader from '../../layout/WebHeader';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';
import { Link } from "react-router-dom";
import util from '../..//../utils/Util';
import { Menu, Dropdown, Button } from 'antd';
import { groupActions } from "../../../redux/action";
import { MySpinner } from "../../../components/index"
import { connect } from "react-redux";

import { Spinner } from "react-bootstrap";
import socket from "../../../services/socket";
import LazyLoad from "react-lazy-load";

var _ = require('lodash');

class BusinessConnections extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requestList: [],
            pendingList: [],
            connectionList: [],
            suggestedList: [],
            blockList: []
        }
    }
    componentDidMount() {
        this.getConnection();
    }

    componentWillUpdate(props) {
        // console.log("dshgdhasbjmk", props.isRefreshed, this.props.isRefreshed)
        if (props.isRefreshed != this.props.isRefreshed)
            this.getConnection();
           
  
            let self = this
            
            socket.on('connect', function (data) {
            });
    
            socket.on('online', function (params) {
                console.log("onlineeeeeeeeeeeee",params);
                self.changeUserStatus(params.user._id, 1);
            })
       
            socket.on('offline', function (params) {
                console.log("offline", params);
                self.changeUserStatus(params.user._id, 0);
            })
    
    
        }  
    
        changeUserStatus(userId, status) {
            let self = this;
            let users = this.state.connectionList
            var checkUser = _.find(users, function (obj) {
                return obj.userData._id === userId;
            });
    
            if (checkUser) {
                checkUser.userData.isOnline = status;
                let userConnection = this.state.connectionList
                var index = _.findIndex(userConnection, { _id: checkUser._id });
                //  userConnection[index].userData =  checkUser 
                self.setState({
                    connectionList : userConnection
                })
                // console.log("sdafhgjaskld", {userConnection})
    
                // 
            }
    
            // console.log("sdafhgjaskld", {checkUser, users, })
        }
    
    
    
    getList = (api, stateData, status) => {
        let req = {
            page: 1,
            limit: 15,
            connectionType: 2,
            status: status ? status : ""

        }
        let url = Url[api].url, method = Url[api].method;
        _Api(method, url, req)
            .then(resp => {
                this.setState({ [stateData]: resp.responseData?.result?.record }, () => {
                    if (this.state["requestList"]?.length > 0 || this.state["pendingList"]?.length > 0 || this.state["connectionList"]?.length > 0) {
                        this.props.BusinessFilterHideShow(true);
                    }
                    else {
                        this.props.BusinessFilterHideShow(false);
                    }
                })
                if (resp?.responseData?.blockList) {
                    this.setState({ blockList: resp?.responseData?.blockList.record })

                }
            }).catch((err) => {
                this.handleError(err);
            })

    }
    getConnection() {
        // this.getList("COMMUNITY_REQUESTLIST", "requestList");
        // this.getList("COMMUNITY_PENDINGLIST", "pendingList");
        // this.getList("COMMUNITY_CONNECTION_LIST", "connectionList");

        let req = {
            page: 1,
            limit: 12,
            connectionType: 2           

        }
        
        let url = Url["GET_ALL_CONNECTION"].url, method = Url["GET_ALL_CONNECTION"].method;
        _Api(method, url, req)
            .then(resp => {
               // this.setState({ [stateData]: resp.responseData?.result?.record })

                this.setState({
                    blockList: resp?.responseData?.blockList.record,
                    connectionList: resp?.responseData?.connectionList.record,
                    requestList: resp?.responseData?.receiveList.record,
                    pendingList: resp?.responseData?.pendingConnection.record,
                    suggestedList: resp?.responseData?.suggestionList?.result.record


                })
            }).catch((err) => {
                this.handleError(err);
            });
    }

    handleError(err) {
        let errorMessage = err.error && err.error.responseMessage || err.message;
        notify("err", errorMessage);
    }
    onAcceptReject(e, requestId, status, id) {
        this.setState({ [`connection${id}`]: true });
        e.preventDefault();
        let req = {
            requestId: requestId,
            status: status
        }
        _Api(Url.COMMUNITY_ACCEPTREJECT.method, Url.COMMUNITY_ACCEPTREJECT.url, req)
            .then(resp => {
                this.setState({ [`connection${id}`]: false });
                this.getConnection();

            }).catch((err) => {
                this.handleError(err);
            })

    }
    onMoreClick = (e, id, a) => {
        switch (e.key) {
            case "Remove":
                this.onRemoveAndBlock("COMMUNITY_CONNECTION_REMOVE", 3, id);
                break;
            case "Block":
                this.onRemoveAndBlock("COMMUNITY_CONNECTION_BLOCK", 1, id);
                break;


        }
    }
    onRemoveAndBlock = (api, status, id) => {

        this.setState({ [`connection${id}`]: true });
        let req = {
            requestId: id,
            status: status
        }
        _Api(Url[api].method, Url[api].url, req)
            .then(resp => {
                this.setState({ [`connection${id}`]: false });
                this.getConnection();
            }).catch((err) => {
                this.handleError(err);
            })

    }
    onClickConnection = (id, user_id) => {
        let props = this.props.scope.props;
        props.history.push(`/user/profile/${user_id}`);
    }





    videoCalling = (userId) => {
        let self = this;
        if (userId) {
            self.setState({
                ['videoConnectionLoder_' + userId]: true
            })
            self.props.dispatch(groupActions.joinOneMeeting({ userId: userId, type: 7 }))
                .then(function (res) {
                    self.setState({
                        ['videoConnectionLoder_' + userId]: false
                    })
                    if (res.statusCode === 1 || res.statusCode === "1") {
                        window.open('/calling/' + self.props.groupDetail.meeting._id, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                        //window.location.href = '/calling/' + self.props.groupDetail.meeting._id;
                        //history.push('/calling/'+self.props.groupDetail.meeting._id)
                    } else {

                    }
                    console.log("res", res)
                })
        }

    }

    selectUser = (user) => {
        let self = this;
        // console.log("user",user);
        if (this.props.userDetail._id !== user._id) {
            this.setState({
                selectedUser: user,
                selectedUserId: user._id,
                chatType: 3,
                isShow: true
            }, function () {
                self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 3 })
                self.props.dispatch({ type: 'CHAT_OPEN' })
                document.body.classList.toggle('chat_open_mob');
                self.props.dispatch({ type: 'SELECTED_USER', selectedUser: user })
                socket.emit('groupAdd', { type: 7, userId: user._id });
                //socket.emit('userConnection', {userId: user._id});
            })
        }

    }

    render() {
        let { requestList, pendingList, connectionList } = this.state;
        console.log("dsahdhgkjasilk", this.state)
        let selectedItem = this.props.selectedItem;
        const Communtiy = (props) => (<Menu onClick={(e) => this.onMoreClick(e, props.Id)} >
            <Menu.Item key="Remove">
                Remove Connection
        </Menu.Item>
            <Menu.Item key="Block">
                Report /Block
        </Menu.Item>
        </Menu>)

        const { businsessFilter } = this.props.appLocalState;
        const { all, myConnection, requestConnection, pendingConnection } = businsessFilter
        return (
            <div className="brandhome cummunity" >
                {/*{this.state.isCommingSoon && <CommingSoonLayer />}*/}

                {
                    (requestConnection || all) && <div className="cm-wrapper">
                        <div className="header-text">

                            <h1 className="title"> Received Invitations</h1>
                            {requestList && <BtnRed to="business-requested-connections" text="View All" />}
                        </div>

                        <div className="row">
                            {requestList && requestList.map((item, index) => {
                                return (

                                    <div className="four-div">
                                        <div className="card text-center community-card">
                                            <LazyLoad>
                                                <div className="logo-wrapper">
                                                    <img src={item?.userData?.logoImage ? item?.userData?.logoImage : require('../../../assets/images/placeholder.png')} />
                                                </div>
                                            </LazyLoad>
                                            <h5 className="text-capitalize"> {item?.userData?.companyName}</h5>
                                            <hr />
                                            <div className="connection-detail-parent">
                                                <div className=" imgparent pr-0">
                                                    <LazyLoad>
                                                        <div className="img-wrapper pos-relative image-chat">
                                                            <img className="img-fluid cursor-point" onClick={(e) => this.onClickConnection(item?._id, item?.userData?._id, e)} src={item?.userData?.image || require('../../../assets/images/login_user.png')} />
                                                            {/* <span className={ `chat ${userData.isOnline == 1 ?  'green' : 'yellow' }` } onClick={() => this.selectUser(item?.userData)} ><Chat /></span> */}
                                                        </div>
                                                    </LazyLoad>
                                                </div>
                                                <div className="business-bottompart">
                                                    <div className=" textparent no-gutter d-flex align-items-start flex-column">
                                                        <h4 onClick={(e) => this.onClickConnection(item?._id, item?.userData?._id, e)} ><strong>{util.getTitleCase(item?.userData?.firstName)} {util.getTitleCase(item?.userData?.lastName)}</strong></h4>
                                                        <span><small>{item?.userData?.JobTitle}</small></span>
                                                    </div>
                                                    <div className="d-block">
                                                        <div className="d-flex justify-content-start">
                                                            <CmBtn text=" Accept" className="btn btn-blue" bg='var(--btncolor)' onClick={(e) => this.onAcceptReject(e, item?._id, 1)} >
                                                                {this.state['connection' + item?._id] &&
                                                                    <MySpinner
                                                                    />}
                                                            </CmBtn>
                                                            &nbsp;&nbsp;<CmBtn text="Ignore" className="btn btn-blue ignore" bg="#fff" color="#A3AAB0" border="1px solid #91cbd3" onClick={(e) => this.onAcceptReject(e, item?._id, 2)} >
                                                                {this.state['connection' + item?._id] &&
                                                                    <MySpinner
                                                                    />}
                                                            </CmBtn>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) || <div className="w-100 text-center row nodata m-auto">
                                    <div className="m-auto">
                                        <NoMediaICon />
                                        
                                        <h2>No Data Found</h2>
                                    </div>                    </div>
                            }

                        </div>
                    </div>
                }




















                {(myConnection || all) && < div className="cm-wrapper" >
                    <div className="header-text">

                        <h1 className="title">My Connections</h1>
                        { connectionList && <BtnRed to="business-connections" text="View All" />}
                    </div>
                    <div className="row">

                        {connectionList && connectionList.map((item, index) => {
                              const {userData} = item
                            return (
                                <div className="four-div">
                                    <div className="card text-center community-card">
                                        <LazyLoad>
                                            <div className="logo-wrapper">
                                                <img src={item?.userData?.logoImage ? item?.userData?.logoImage : require('../../../assets/images/placeholder.png')} />
                                            </div>
                                        </LazyLoad>
                                        <h5 className="text-capitalize"> {item?.userData?.companyName}</h5>
                                        <span>{item?.userData?.email}</span>
                                        <span>{item?.userData?.phoneNumber}</span>
                                        <hr />
                                        <div className="connection-detail-parent">
                                            <div className=" imgparent pr-0">
                                                <LazyLoad>
                                                    <div className="img-wrapper pos-relative image-chat">
                                                        <img className="img-fluid cursor-point" onClick={(e) => this.onClickConnection(item?._id, item?.userData?._id, e)} src={item?.userData?.image || require('../../../assets/images/login_user.png')} />
                                                        <span className={ `chat ${userData.isOnline == 1 ?  'green' : 'yellow' }` } onClick={() => this.selectUser(item?.userData)} ><Chat /></span>
                                                    </div>
                                                </LazyLoad>
                                            </div>
                                            <div className=" textparent no-gutter d-flex align-items-start flex-column">
                                                <h4 onClick={(e) => this.onClickConnection(item?._id, item?.userData?._id, e)} ><strong>{util.getTitleCase(item?.userData?.firstName)} {util.getTitleCase(item?.userData?.lastName)}</strong></h4>
                                                <span><small>{item?.userData?.JobTitle}</small></span>


                                            </div>


                                            {/* <div className="top-right">
                                                    <i className="fa fa-ellipsis-v"></i>
                                                </div> */}
                                        </div>


                                    </div>
                                </div>
                            )
                        }) || <div className="w-100 text-center row nodata m-auto">
                                <div className="m-auto">
                                    <NoMediaICon />
                                    
                                    <h2>No Data Found</h2>
                                </div>                    </div>
                        }
                    </div>
                </div>
                }  
                
                
                {(pendingConnection || all) && <div className="cm-wrapper">

                    < div className="header-text">
                        <h1 className="title">Sent Invitations</h1>
                        {pendingList && <BtnRed to="business-pending-connections" text="View All" />}
                    </div>

                    <div className="row">

                        {pendingList && pendingList.map((item, index) => {
                              const {userData} = item
                            return (

                                <div className="four-div">
                                    <div className="card text-center community-card">
                                        <LazyLoad>
                                            <div className="logo-wrapper">
                                                <img src={item?.userData?.logoImage ? item?.userData?.logoImage : require('../../../assets/images/placeholder.png')} />
                                            </div>
                                        </LazyLoad>
                                        <h5 className="text-capitalize"> {item?.userData?.companyName}</h5>

                                        <hr />
                                        <div className="connection-detail-parent ">
                                            <div className=" imgparent pr-0">
                                                <LazyLoad>
                                                    <div className="img-wrapper pos-relative image-chat">
                                                        <img className="img-fluid cursor-point" onClick={(e) => this.onClickConnection(item?._id, item?.userData?._id, e)} src={item?.userData?.image || require('../../../assets/images/login_user.png')} />
                                                        {/* <span className={ `chat ${userData.isOnline == 1 ?  'green' : 'yellow' }` } onClick={() => this.selectUser(item?.userData)} ><Chat /></span> */}
                                                    </div>
                                                </LazyLoad>
                                            </div>
                                            <div className=" textparent no-gutter d-flex align-items-start flex-column business-bottompart">
                                                <h4 onClick={(e) => this.onClickConnection(item?._id, item?.userData?._id, e)} ><strong>{util.getTitleCase(item?.userData?.firstName)} {util.getTitleCase(item?.userData?.lastName)}</strong></h4>
                                                <span><small>{item?.userData?.JobTitle}</small></span>
                                                <CmBtn text="Cancel Request" bg='var(--btncolor)' className="btn btn-blue" onClick={(e) => this.onRemoveAndBlock("COMMUNITY_CONNECTION_REMOVE", 3, item?._id, 3)}>
                                                    {this.state['connection' + item?.userData?._id] &&
                                                        <MySpinner
                                                        />}
                                                </CmBtn>


                                            </div>

                                            {/* <div className="top-right">
                                                    <i className="fa fa-ellipsis-v"></i>
                                                </div> */}
                                        </div>
                                    </div>
                                </div>

                            )

                        }) ||
                            <div className="w-100 text-center row nodata m-auto">
                                <div className="m-auto">
                                    <NoMediaICon />
                                    
                                    <h2>No Data Found</h2>
                                </div>                    </div>
                        }

                    </div>

                </div>

                }

            </div >
        )
    }
}
function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(BusinessConnections);