import React, { Component } from 'react';
import { useHistory } from "react-router-dom";
import { SearchIcon } from '../../../SvgIcon';
import { Link } from "react-router-dom";
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { BackBtn } from '../../../components/common/Button'
import InlineError from '../../../components/InlineError';
import { Select, Checkbox } from 'antd';
import Loader from '../../../components/Loader';
import util from '../../../utils/Util.js';
import { notify } from '../../../components/Toaster';
import { TreeSelect } from 'antd';
const { Option } = Select;
const { SHOW_PARENT, SHOW_CHILD, SHOW_ALL } = TreeSelect;


const format = 'HH:mm';
const fieldDetails = [{ fieldTitle: "title", isRequired: true },
{ fieldTitle: "euroAmount", isRequired: true },
{ fieldTitle: "dollarAmount", isRequired: true },
{ fieldTitle: "numberOfSearches", isRequired: true },
{ fieldTitle: "searchPlanType", isRequired: true },
{ fieldTitle: "placeOfWorkId", isRequired: true }]

//Add New Exhibition screen
class AddSearch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            exhibition: "",
            limitOfChat: '',
            limitOfCalls: '',
            limitOfNetworkLounge: '',
            limitOfLounge: '',
            limitOfConference: '',
            limitOfDownload: '',


            limitOfChatError: '',
            limitOfCallsError: '',
            limitOfNetworkLoungeError: '',
            limitOfLoungeError: '',
            numberOfSearchesError: ''

        }
    }
    componentDidMount() {
        // this.getCategory("1", "placeOfWorkData");
        this.getplaceOfWork()
        // if (this.props.match.params.id) {
        this.getSubscription();
        // }
        this.getexhibitionList();
    }
    getexhibitionList = () => {

        _Api(Url.GET_EXHIBITION_ID.method, Url.GET_EXHIBITION_ID.url, '')
            .then(resp => {
                console.log("fdghjkl", resp?.responseData?.exhibitionDetails)
                this.setState({ exhibitionData: resp?.responseData?.exhibitionDetails, exhibition: resp?.responseData?.exhibitionDetails?._id });


            }).catch((err) => {

            })









        // _Api(Url.PROMO_EXHIBITION_DATA.method, Url.PROMO_EXHIBITION_DATA.url)
        //     .then(resp => {
        //         let responseData = resp.responseData.exhibitionDetails;


        //     }).catch(err => {
        //         this.handleError(err);
        //     });
    }
    getSubscription = () => {

        _Api(Url.GET_DEFAULT_PLAN.method, Url.GET_DEFAULT_PLAN.url, '', `?planId=${'602f93d8d2225963a6695d29'}`)
            .then(resp => {
                console.log("dsahdsss", resp.responseData)

                const { limitOfChat,
                    numberOfSearches,
                    limitOfCalls,
                    limitOfNetworkLounge,
                    limitOfLounge,
                    limitOfDownload
                } = resp.responseData.res;
                this.setState({
                    numberOfSearches,
                    planId: '602f93d8d2225963a6695d29',
                    limitOfChat,
                    limitOfCalls,
                    limitOfNetworkLounge,
                    limitOfLounge,
                    limitOfDownload,

                });

            }).catch(err => {
                this.setState({ isLoader: false });
                let errorMessage = err.error && err.error.responseMessage || err.message;
                notify("err", errorMessage);
            });
    }
    getCategory = (categoryType, keyName) => {
        let params = {
            categoryType: categoryType,
        }
        _Api(Url.GET_PLACE_OF_WORK_LIST_placeOfWorkList.method, Url.GET_PLACE_OF_WORK_LIST_placeOfWorkList.url, '')
            .then(resp => {

                let keyNameData = resp.responseData.result.map((item1, index1) => {
                    let childData = item1.subCategory.map((data, index2) => {
                        let ddd = {
                            title: data.categoryTitle,
                            value: data._id,
                            children: []
                        }
                        return ddd
                    })
                    // console.log("childData", childData)
                    let dd = {
                        selectable: childData.length > 0 ? false : true,
                        title: childData.length > 0 ? <b style={{ color: '#000' }}>{item1.categoryTitle}</b> : item1.categoryTitle,
                        value: item1._id,
                        checkable: childData.length > 0 ? false : true,
                        children: childData
                    }
                    return dd
                })
                if (keyName === "placeOfWorkData" || keyName === "jobTitleData") {
                    keyNameData.push({
                        title: 'Other',
                        value: 'other',
                        children: []
                    })
                }
                console.log('responseData', keyNameData);


                this.setState({
                    [keyName]: keyNameData
                })
            }).catch(err => {
                this.setState({ isLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
                this.setState({ isLoader: false })
            })
    }


    handleOnChangeValue = (e) => {
        let errorStateObject = `${e.target.name}Error`;
        this.setState({
            [e.target.name]: e.target.value, [errorStateObject]: "",
            limitOfChatError: '',
            limitOfCallsError: '',
            limitOfNetworkLoungeError: '',
            limitOfLoungeError: '',
            numberOfSearchesError: ''
        });
    }
    selectOnChange = (type, e) => {
        let stateObject = type + 'Error';
        this.setState({
            [type]: e, [stateObject]: '', amountError: !this.state.amount && "Amount can not be empty"
        })
    }

    handleError(err) {
        this.setState({ isLoader: false });
        let errorMessage = err.error && err.error.responseMessage || err.message;
        notify("err", errorMessage);
    }


    getplaceOfWork = (e) => {
        let self = this;
        _Api(Url.GET_PLACE_OF_WORK_LIST_placeOfWorkList.method, Url.GET_PLACE_OF_WORK_LIST_placeOfWorkList.url, '')
            .then(resp => {
                if (resp.statusCode == 1) {

                    this.setState({
                        placeOfWorkData: resp.responseData.result.map((item1, index1) => {
                            let dd = {
                                title: item1.name,
                                value: item1._id,
                                checkable: true,

                            }
                            return dd
                        })
                    })

                }
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
            })
    }




    onSubmit = () => {
        let isError = false;
        // fieldDetails.forEach((item) => {

        //     if (!this.state[item.fieldTitle] && item.isRequired) {
        //         let stateObject = item.fieldTitle + 'Error', fieldValue = item.fieldTitle.replace(/([A-Z])/g, ' $1').trim();
        //         isError = true;
        //         this.setState({ [stateObject]: `${(fieldValue.toLowerCase()).charAt(0).toUpperCase() + fieldValue.slice(1)} can not be empty` });

        //     };
        // });
        // limitOfChatError: '',
        // limitOfCallsError: '',
        // limitOfNetworkLoungeError: '',
        // limitOfLoungeError: '',
        // numberOfSearchesError:''
        let { title, amount, description, currencyType, numberOfSearches, placeOfWorkId, dollarAmount, euroAmount, searchPlanType, exhibition,

            limitOfChat,
            limitOfCalls,
            limitOfNetworkLounge,
            limitOfLounge,
            limitOfConference,
            limitOfDownload,
        } = this.state;


        if (numberOfSearches == "") {
            this.setState({ numberOfSearchesError: '*Please enter value.' })
        }
        else if (limitOfNetworkLounge == "") {
            this.setState({ limitOfNetworkLoungeError: '*Please enter value.' })
        }
        else if (limitOfCalls == "") {
            this.setState({ limitOfCallsError: '*Please enter value.' })
        }
        else if (limitOfChat == "") {
            this.setState({ limitOfChatError: '*Please enter value.' })
        }
        else if (limitOfLounge == "") {
            this.setState({ limitOfLoungeError: '*Please enter value.' })
        }


        else {

            let isDollarType = currencyType == 1;
            let isEdit = this.props.match.params.id && true || false;
            let param = {
                // planName: title,
                // description: description,
                numberOfSearches: Number(numberOfSearches),
                planType: 1,
                // dollarAmount: dollarAmount,
                // euroAmount: euroAmount || "",
                // placeOfWorkId: placeOfWorkId && placeOfWorkId.toString(),
                planId: '602f93d8d2225963a6695d29',
                limitOfChat,
                limitOfCalls,
                limitOfNetworkLounge,
                limitOfLounge,
                // limitOfDownload : 1



            };

            // let url = !isEdit ? Url.ADD_SUBSCRIPTION_PLAN.url : Url.EDIT_SUBSCRIPTION_DETAIL.url;
            _Api(Url.EDIT_DEFAULT_PLAN.method, Url.EDIT_DEFAULT_PLAN.url, param)
                .then(resp => {
                    notify('success', resp.responseData.message);
                    this.props.history.goBack();

                }).catch(err => {
                    this.handleError(err);
                })
        }

    }

    onChangeSelect = (key, value) => {
        let stateObject = key + 'Error';
        this.setState({
            [key]: value,
            [stateObject]: ""

        });
    }

    render() {
        const { Option } = Select;
        const { limitOfDownload, title, titleError, euroAmount, euroAmountError, exhibition, exhibitionData, exhibitionError, placeOfWorkIdError, dollarAmount, dollarAmountError, placeOfWorkId, searchPlanTypeError, searchPlanType, numberOfSearches, numberOfSearchesError, description, currencyTypeError, currencyType, placeOfWorkError,

            limitOfChat,
            limitOfCalls,
            limitOfNetworkLounge,
            limitOfLounge,
            limitOfConference,


            limitOfChatError,
            limitOfCallsError,
            limitOfNetworkLoungeError,
            limitOfLoungeError



        } = this.state;

        console.log("jhgfhjk", this.state.placeOfWorkData)
        return (

            <>
                <div className="dash_body_container">
                    <Header />
                    <Sidebar />
                    <div className="body_container">
                        <div className="header-text  mb-4 mt-3">
                            <div className="d-flex align-items-center">
                                <p className="header-icon"><SearchIcon /></p>
                                <h4>{this.props.match.params.id && "Default Plan" || "Default Plan"}</h4>
                            </div>
                            <BackBtn to="search" />

                        </div>
                        <div className="rounded-body-form">
                            <form className="cm-form-wrapper add-promo-code">


                                <div className="row">
                                    {/* <div className="col-lg-6">
                                        < div className="form-group">
                                            <level>Title</level>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Title"
                                                maxLength={64}
                                                name="title"
                                                value={title}
                                                onChange={this.handleOnChangeValue}
                                            />
                                            <InlineError message={titleError} />
                                        </div>

                                    </div> */}

                                    <div className="col-lg-6">

                                        <div className="form-group">
                                            <level>Number of Searches</level>
                                            <input type="number"
                                                className="form-control"
                                                placeholder="Number of Searches"
                                                maxLength={64}
                                                name="numberOfSearches"
                                                value={numberOfSearches}
                                                onChange={this.handleOnChangeValue}
                                            />
                                            <InlineError message={numberOfSearchesError} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">

                                        <div className="form-group">
                                            <level>Number of Matchmaking Sessions</level>
                                            <input type="number"
                                                className="form-control"
                                                placeholder="Number of Matchmaking Sessions"
                                                maxLength={64}
                                                name="limitOfNetworkLounge"
                                                value={limitOfNetworkLounge}
                                                onChange={this.handleOnChangeValue}
                                            />
                                            <InlineError message={limitOfNetworkLoungeError} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">

                                        <div className="form-group">
                                            <level>Number of Call</level>
                                            <input type="number"
                                                className="form-control"
                                                placeholder="Number of Call"
                                                maxLength={64}
                                                name="limitOfCalls"
                                                value={limitOfCalls}
                                                onChange={this.handleOnChangeValue}
                                            />
                                            <InlineError message={limitOfCallsError} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">

                                        <div className="form-group">
                                            <level>Number of Chat</level>
                                            <input type="number"
                                                className="form-control"
                                                placeholder="Number of Chat"
                                                maxLength={64}
                                                name="limitOfChat"
                                                value={limitOfChat}
                                                onChange={this.handleOnChangeValue}
                                            />
                                            <InlineError message={limitOfChatError} />
                                        </div>
                                    </div>


                                    <div className="col-lg-6">

                                        <div className="form-group">
                                            <level>Number of Lounges</level>
                                            <input type="number"
                                                className="form-control"
                                                placeholder="Number of Lounges"
                                                maxLength={64}
                                                name="limitOfLounge"
                                                value={limitOfLounge}
                                                onChange={this.handleOnChangeValue}
                                            />
                                            <InlineError message={limitOfLoungeError} />
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-6">

                                        <div className="form-group">
                                            <level>Number of Download Connection</level>
                                            <input type="number"
                                                className="form-control"
                                                placeholder="Number of Lounges"
                                                maxLength={64}
                                                name="limitOfDownload"
                                                value={limitOfDownload}
                                                onChange={this.handleOnChangeValue}
                                            />
                                            <InlineError message={numberOfSearchesError} />
                                        </div>
                                    </div> */}

                                    {/* <div className="col-lg-6">
                                        <level>Download Connection</level>
                                        <div className="form-group mb-0">
                                            <div className="justify-content-between">

                                                <div className="d-flex">
                                                    <p>
                                                        <div className="check_wrapper">
                                                            <input
                                                                type="checkbox"

                                                                value="option1"
                                                                checked={!limitOfDownload}
                                                                onChange={() => this.setState({ limitOfDownload: !this.state.limitOfDownload })}
                                                            />
                                                            <span />
                                                        </div>
                                                        No
                                                </p>
                                                    <p className="ml-4">
                                                        <div className="check_wrapper">
                                                            <input
                                                                type="checkbox"
                                                                value="option2"
                                                                checked={limitOfDownload}
                                                                onChange={() => this.setState({ limitOfDownload: !this.state.limitOfDownload })} />
                                                            <span />
                                                        </div>
                                                        Yes
                                                </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}



                                    {/* <div className="col-lg-6 ">

                                        <div className="form-group">
                                            <level>Search Plan Type</level>
                                            <Select
                                                name='searchPlanType'
                                                placeholder="Select"
                                                value={searchPlanType}
                                                onChange={(e) => this.selectOnChange("searchPlanType", e)}
                                            >
                                                {util.searchPlanType.map((item) => {
                                                    return (<Option value={item.value}>{item.displayValue}</Option>)
                                                })}
                                            </Select>
                                            <InlineError message={searchPlanTypeError} />
                                        </div>
                                    </div> */}

                                    {/* <div className="col-lg-6 ">
                                        <div className="form-group">
                                            <level>Place of Work</level>

                                            <TreeSelect
                                                dropdownClassName="new_multi_check"
                                                treeData={this.state.placeOfWorkData}
                                                value={this.state.placeOfWorkId}
                                                onChange={(e) => this.onChangeSelect("placeOfWorkId", e)}
                                                treeCheckable={true}
                                                // treeCheckStrictly: false,

                                                placeholder='Please select'
                                                style={{
                                                    width: '100%',
                                                    border: 'none'
                                                }}
                                                showSearch={false}
                                                className="cm_multiselect cmslect_multicheck"
                                                showArrow
                                            />
                                            <InlineError
                                                message={placeOfWorkIdError}
                                            />
                                        </div>

                                    </div> */}
                                    {/* 
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <level>USD Amount</level>
                                            <input type="number"
                                                className="form-control"
                                                placeholder="Dollar Amount"
                                                maxLength={64}
                                                name="dollarAmount"
                                                value={dollarAmount}
                                                onChange={this.handleOnChangeValue}
                                            />
                                            <InlineError message={dollarAmountError} />
                                        </div>


                                    </div> */}

                                    {/* <div className="col-lg-6">
                                        <div className="form-group">
                                            <level>GBP Amount</level>
                                            <input type="number"
                                                className="form-control"
                                                placeholder="GBP Amount"
                                                maxLength={64}
                                                name="euroAmount"
                                                value={euroAmount}
                                                onChange={this.handleOnChangeValue}
                                            />
                                            <InlineError message={euroAmountError} />
                                        </div>
                                    </div> */}

                                    {/* <div className="col-lg-6">
                                        <div className="form-group">
                                            <level>Exhibition Name</level>
                                            <Select
                                                // defaultValue={exhibition}
                                                value={exhibition}
                                                name='exhibition'
                                                placeholder="Select"
                                                onChange={(e) => this.selectOnChange("exhibition", e)}
                                            >
                                                <Option value={this.state.exhibitionData?._id}>{this.state.exhibitionData?.exhibitionName}</Option>
                                            </Select>
                                            <InlineError message={exhibitionError} />
                                        </div>
                                    </div> */}
                                    {/* 
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <level>Description</level>
                                            <textarea type="text"
                                                className="form-control textarea"
                                                placeholder="Description"
                                                rows="6"
                                                name="description"
                                                value={description}
                                                onChange={this.handleOnChangeValue}
                                            />
                                        </div>
                                    </div> */}
                                </div>

                                <div className="text-right">
                                    <button className="btn btn-primary " onClick={() => this.onSubmit()} type='button'>Save</button>
                                </div>
                            </form>

                        </div>

                    </div>
                </div>
            </>
        );
    }

} export default AddSearch;

