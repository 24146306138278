import React from 'react'
import { CircleCross } from '../../../SvgIcon';
import moment from 'moment-timezone';
import Util from '../../../utils/Util.js';


class UserDetails extends React.Component {
    constructor(props) {
        super();
    }
    render() {
        let data = this.props.data[0];

        return (
            <div className='popup'>
                <div className='popup_inner user-report-popup'>
                    <div className="table-responsive no-shadow">
                        <table className="table vertical-table table-bordered rounded-table">
                            <tr>
                                <th>Reported User </th>
                                <td>{data.reportedUserId && data.reportedUserId.firstName} {data.reportedUserId && data.reportedUserId.lastName}</td>


                                <th>Reported By User</th>
                                <td>{data.userId && data.userId.firstName} {data.userId && data.userId.lastName}</td>
                            </tr>
                            <tr>
                                <th>Status </th>
                                <td>{data.status === 1 && "Active" || "Inactive"}</td>

                                <th>Date</th>
                                <td>{moment(data.created.toString()).format("MM-DD-YYYY")}</td>
                            </tr>
                            <tr >
                                <th>Reported Message</th>
                                <td colSpan="4" rowSpan="10">{data.reportedMsg}</td>

                            </tr>
                        </table>
                    </div>
                    <button className="close-btn" onClick={this.props.closePopup}><CircleCross /></button>

                </div>
            </div>
        );
    }
}

export default UserDetails