import React, {Component} from 'react'
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';
import { Link } from "react-router-dom";
import '../../../assets/css/adManagement.css';
import ReactPlayer from 'react-player';

import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';

import moment from "moment"



class ViewDetails extends Component{

    constructor(props) {
        super(props);
        this.state = {
            
            isLoading: false,
            adData:''
           

        }

    }




    componentDidMount(){
        
        if(this.props.match.params.adId)
        this.getParticularAd()
    }



    getParticularAd = () => {
        let req = {
            stageId: this.props.match.params.adId
        }
        _Api(Url.GET_PARTICULAR_AD.method, Url.GET_PARTICULAR_AD.url, '', `?adsId=${this.props.match.params.adId}`)
            .then(resp => {
                console.log('cbjdbcjkbc', resp.responseData)              

                this.setState({
                    adData : resp.responseData.result
                   
                })
            }).catch(err => {
            })
    }


    render(){
        // let columnDetails = ["Ad Name", "Ad Category", "Brand"];
        return (
            <div className="dash_body_container">
                <Header />
                <Sidebar />
                <div className="body_container">
                    <div className="header-text  mb-4 pt-3">
                        <div className="d-flex align-items-center">
                    <h4>{this.state.adData?.adName}</h4></div>
                            <Link to="/ads">
                                <button className="btn btn-light">
                                    <i className="fa fa-arrow-left" aria-hidden="true"></i>Back</button></Link>
                           
                    </div>
                </div>
                <div className="rounded-body py-5 px-4">
                <div className="profile-section-details">
                            
             {/* {   this.state.adData?.media  && <img src= {this.state.adData?.media} />} */}
                    <div className="text-right">
                   <Link to={`/add-ads/${this.props.match.params.adId}`} className="btn btn-primary">Edit</Link>
                    </div>
                    <div className="table-responsive table-inverse-color">
                        <table class="table vertical-table table-bordered rounded-table inverse-color">
                            <thead>
                                <tr>
                                    <td>Ad Name</td>
                            <td>{this.state.adData?.adName}</td> 
                                </tr>
                                <tr>
                                    <td>Redirection Url</td>
                                    <td>{this.state.adData?.redirectionUrl}</td>
                                </tr>
                                <tr>
                                    <td>Created At</td>
                                 <td>{ this.state.adData &&  moment(this.state.adData.created).format("MM/ DD/ YYYY")}</td>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div className="mt-4">
                        <h5>Media</h5>
                        <div className="media-list mt-2">
                            <ul className="list-inline mb-0">
                                <li className="list-inline">
                                { this.state.adData?.media  && <img  src= {this.state.adData?.media} /> }
                                </li>
                                {/* <li className="list-inline">
                                    <ReactPlayer url='https://www.youtube.com/watch?v=lE6RYpe9IT0' />
                                </li>
                                <li className="list-inline">
                                    <ReactPlayer url='https://www.youtube.com/watch?v=lE6RYpe9IT0' />
                                </li>
                                <li><button className="btn add-btn h-100" type="button">+</button></li> */}
                            </ul>
                         </div>
                    </div>  
                </div>
            </div>
            </div>
        )
    }
}
{/* 
{this.state.adData?.media}?
                                <div className="profile-dp">
                                  <img src={this.state.adData?.media} />
                                </div>:""} */}

export default ViewDetails