import React, { Component } from 'react';

import Footer from "../../layout/Footer"
import { Link } from "react-router-dom";
import { Select, Menu, Dropdown, Modal, Tooltip } from "antd";
import { Pagination } from "../../../components";
import FilterHeader from "../../layout/FilterHeader";
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { CategoryManagement, Product, Brands, NoMediaICon } from "../../../SvgIcon";
import { notify } from '../../../components/Toaster';
import { connect } from "react-redux";
import { getWishList } from '../../../redux/action';
import { Spinner } from "react-bootstrap";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import LazyLoad from 'react-lazy-load';


const antIcon = <LoadingOutlined style={{ fontSize: 72 }} spin />;


class WebViewProduct extends Component {

    constructor(props) {
        super(props)
        this.state = {
            page: 1,
            limit: 16,
            loading: false,
            isLoader: false,
            search: '',
            snNo: 0,
            sortInfo: {},
            industryData: [],
            totalCount: 1,
            productListData: [],
            brandListData: [],
            brandIds: [],
            industryIds: [],
            mostVisitedProduct: [],
            childCategoryIds: []

        }

    }
    state = { visible: false };

    componentDidMount() {
        this.props.dispatch({ type: 'PATH_ADD', payload: this.props.match })
        window.scrollTo(0, 0);
        if (this.props.match.params.id) {
            if (this.props.match.params.id) {
                this.setState({ [`isExpand${this.props.match.params.id}`]: true });
            }
        }
        this.getProductList();
        this.getList("GET_BRAND_MOSTVISITED_PRODUCT", "mostVisitedProduct");
        this.getCategory();
    }
    getCategory = () => {
        let params = {
            categoryType: "4",
        }
        _Api(Url.GET_PRODUCT_CATEGORY_LIST.method, Url.GET_PRODUCT_CATEGORY_LIST.url, params)
            .then(resp => {
                this.setState({ industryData: resp.responseData?.result });
                this.getProductList();

            }).catch(() => {

            })
    }
    handleError(err) {
        let errorMessage = err.error && err.error.responseMessage || err.message;
        notify("err", errorMessage);
    }

    getList = (api, stateData, visited) => {
        let { sortField, sortType } = this.state;
        let req = {
            page: 1,
            limit: 15,
            brandId: this.props.match.params.brandId
        }

        let url = Url[api]?.url, method = Url[api]?.method;

        _Api(method, url, req)
            .then(resp => {
                this.setState({ [stateData]: resp.responseData?.result })

            }).catch((err) => {
                this.handleError(err);
            })
    }




    /** brands start */

    getProductList = (isLoader = true) => {
        let { appLocalState } = this.props
        this.setState({ isLoader: isLoader })
        let { page, limit, search, brandIds, industryIds, sortField, sortType, childCategoryIds } = this.state
        let req = {
            page: page ? page : 1,
            limit: limit,
            search: appLocalState.search || search.toString(),
            productBrandId: this.props.match.params.brandId,
            productCategory: industryIds ? industryIds.join() : "",
            sortField: sortField,
            sortType: sortType
        }
        _Api(Url.FILTER_PRODUCT_LIST.method, Url.FILTER_PRODUCT_LIST.url, req)
            .then(resp => {
                this.setState({ productListData: resp.responseData?.result?.data, totalCount: resp.responseData?.result?.totalCount, isLoader: false })

            }).catch(() => {
                this.setState({ isLoader: false });
            })

    }
    onPressBtn = (page) => {
        alert("hi radha")
        this.setState({ page, snNo: page - 1, brandListData: [] }, () => {

            this.getProductList();
        })

    }
    handleChange(value) {
        this.setState({ sortField: "productName", sortType: Number(value.key) }, () => this.getProductList());
    }


    addToWishList = (category, id, e) => {
        let stateName = `wish${id}`;
        this.setState({ [`isWishlistLoader${id}`]: true, [stateName]: !this.state[stateName], });
        let self = this;
        let params = category == "brand" ? { brandId: id } : { productId: id };
        let { method, url } = Url[e];
        _Api(method, url, params)
            .then(() => {
                self.props.dispatch(getWishList());

                this.getProductList(false);

                setTimeout(() => {
                    this.setState({ [`isWishlistLoader${id}`]: false });

                }, 2000);


            }).catch((err) => {
                this.handleError(err);
            })
    }
    handleSearch = e => this.setState({ search: e.target.value, brandListData: [], page: 1, snNo: 0 }, () => this.getProductList());

    handleSelect = (e, id, type) => {
        if (e.target.checked) {
            type == "brand" && this.state.brandIds.push(id) || this.state.industryIds.push(id);
        }
        else {
            var index = this.state.industryIds.indexOf(id);
            if (index > -1) {
                this.state.industryIds.splice(index, 1);
            }
        }
        let checkboxFiield = `${type}Checkbox${id}`;
        this.setState({ [checkboxFiield]: e.target.checked }, () => this.getProductList());


    }
    onLinkClick = (value, scope) => {
        _Api(Url.SEARCH_COUNT.method, Url.SEARCH_COUNT.url)
            .then(() => {


            }).catch(() => {
                // scope.handleError(err);
            });
        scope.props.history.push(value);
    }
    onClickOnExpand = (id) => {
        this.setState({ [`isExpand${id}`]: !this.state[`isExpand${id}`] })

    }
    render() {
        let { isLoader, productListData, industryData, brandListData, mostVisitedProduct, modalData } = this.state;
        return (

            <section className="brand-home-banner-bg">
                <FilterHeader scope={this} setSelected={"Product"} ishideHeader={true} isMobile={true} />

                <div className="container-fluid cm-section">
                    <div className="cm-wrapper featured-brands showcase">
                        <div className="row mt-4">
                            <aside className="col-xl-3">
                                <div className="table-responsive explore-by-ndustry mb-4">
                                    <table className="table table-bordered ">
                                        <thead>
                                            <tr>
                                                <th><span><CategoryManagement /></span>Explore By Sectors</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                industryData.map((item) => (
                                                    item?.level == 1 && <>
                                                        <tr >
                                                            <td className="pl-3 cursor-point">
                                                                <h5 onClick={() => { this.onClickOnExpand(`${item._id}`) }}><i className={this.state[`isExpand${item?._id}`] ? "fa fa-caret-down" : "fa fa-caret-right"} aria-hidden="true">&nbsp;</i> {item.categoryTitle}</h5>
                                                                {/* <input type="checkbox" value="option1"
                                                                    onChange={(e) => this.handleSelect(e, item._id, "industry")}
                                                                    checked={this.state[`industryCheckbox${item._id}`]}
                                                                    value="option1" /> */}
                                                                <span />
                                                            </td>
                                                        </tr>
                                                        {this.state[`isExpand${item?._id}`] && item.subCategory.map((item) =>
                                                            this.getSubCategoryData(item))}
                                                    </>


                                                ))
                                            }


                                        </tbody>
                                    </table>
                                </div>
                                {mostVisitedProduct > 0 && <div className="table-responsive mb-4">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th colSpan="2"><span><Product /></span>Most Visited Products</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <div className="product-card-wrapper  p-0">
                                                <div className="row m-0 p-3">
                                                    {

                                                        mostVisitedProduct.map((item, index) => (
                                                            index < 5 &&
                                                            <div className="col-lg-6">
                                                                <LazyLoad>
                                                                    <div className="card community-card wrapper">
                                                                        <Link to={`/web-job-details/${item?.productData?._id}`}>

                                                                            <img className="img-fluid" src={item?.productData?.image || require('../../../assets/images/placeholder.png')} />

                                                                            {/* </div> */}
                                                                            <hr />
                                                                            <div className="action_group text-center">
                                                                                <Tooltip placement="top" title={item?.productData?.productName}>
                                                                                    <p className="mb-0 wrap-p">{item?.productData?.productName} <br /></p>
                                                                                </Tooltip>
                                                                            </div>

                                                                        </Link>
                                                                    </div>
                                                                </LazyLoad>

                                                            </div>



                                                        ))

                                                    }
                                                </div>
                                            </div>
                                        </tbody>
                                    </table>
                                </div>
                                }
                            </aside>

                            <main className="col-xl-9 web-view-job">
                                <header className="mb-4">
                                    <div className="d-flex flex-wrap break-header">
                                        <div className="flex-grow-1">
                                            <div className="red-border">
                                                <h1 className="mb-0">All Products</h1>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="rounded-textbox-form filter-select  pt-0 p-0">
                                                <Dropdown overlay={
                                                    <Menu onClick={(e) => this.handleChange(e)}>
                                                        <Menu.Item key="1">
                                                            <a > Product Name (A-Z)
                                                        </a>
                                                        </Menu.Item>
                                                        <Menu.Item key="-1">
                                                            <a >  Product Name (Z-A)
                                                        </a>
                                                        </Menu.Item>

                                                    </Menu>
                                                } placement="bottomRight">
                                                    <span className="sorting-dropdown"><i class="fa fa-sort-amount-desc" aria-hidden="true"></i></span>
                                                </Dropdown>
                                            </div>
                                        </div>

                                        <a className="ml-2"> <button onClick={() => this.props.history.goBack()} className="btn btn-light">Back</button></a>
                                    </div>
                                </header>

                                <div className="product-card-wrapper">


                                    <div className="row">
                                        {
                                            productListData.map(item => (
                                                <div className="four-div">

                                                    <div className="card community-card wrapper cursor-point">
                                                        <LazyLoad>
                                                            <div onClick={() => this.onLinkClick(`/web-job-details/${item._id}`, this)}>

                                                                <img className="img-fluid" src={item.image || require('../../../assets/images/placeholder.png')} />
                                                                <Tooltip placement="bottom" title={item?.brandId?.companyName}>
                                                                    <div className="top-left">
                                                                        {item.brandId?.companyName && <button className="mb-auto btn btn-brand btn-card">{item.brandId?.companyName}</button>}

                                                                    </div>
                                                                </Tooltip>
                                                            </div>
                                                        </LazyLoad>

                                                        <div className="top-right">
                                                            {
                                                                < div className={(item?.wishListData) && "blue-circle red-circle" || "blue-circle"} onClick={() => this.addToWishList('product', item?._id, item?.wishListData && "REMOVE_WISHLIST" || "ADD_WISHLIST")}>
                                                                    {this.state[`isWishlistLoader${item?._id}`] &&
                                                                        <Spinner className="text-center" size="xl" animation="border" variant="primary" /> || <i className="fa fa-heart-o" ></i>}

                                                                </div>}

                                                        </div>
                                                        <hr />
                                                        <div className="action_group text-center">
                                                            <Tooltip placement="top" title={item.productName}>
                                                                <h6 className="wrap-p2">{item.productName} <br />
                                                                    {/* <span className="blue">${item.price ? item.price : 0}</span> */}
                                                                </h6>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                        {isLoader &&
                                            <div className="centerLoader" >
                                                <Spin size="large" tip="Loading..." indicator={antIcon} />
                                            </div>

                                        }

                                    </div>
                                    {!isLoader && productListData?.length == 0 && <div className="w-100 text-center row nodata m-auto">
                                        <div className="m-auto">
                                            <NoMediaICon />
                                            
                                            <h2>No Product Found</h2>
                                        </div>                    </div>
                                    }

                                    {
                                        productListData.length > 0 &&
                                        <div >
                                            <Pagination
                                                totalPage={Math.ceil(this.state.totalCount / 10)}
                                                currentPage={this.state.page}
                                                onPressBtn={this.onPressBtn}
                                            />
                                        </div>
                                    }

                                </div>
                            </main>
                        </div>

                    </div>
                </div>


                <Footer />
            </section >




        )
    }

    getSubCategoryData(item) {
        if (item.parentCategoryId === this.props.match.params.id && this.state[`industryCheckbox${item._id}`] == undefined && this.state.industryIds.indexOf(item?._id) == -1) {
            this.state.industryIds.push(item._id);

        }
        return <tr className="form-check form-check-inline brandcategory-list">
            <td className="">
                <input type="checkbox" value="option1"
                    className="form-check-input"
                    onChange={(e) => this.handleSelect(e, item._id, "industry")}
                    checked={this.state[`industryCheckbox${item._id}`] || this.state.industryIds.includes(item._id)} />
                <span />
                <label className="form-check-label"
                    htmlFor="inlineCheckbox2">{item?.categoryTitle}</label></td>
        </tr>;
    }
}
function mapStateToProps(state) {
    return {
        wishList: state.wishList,
        appLocalState: state.appLocalState
    };
}

export default connect(mapStateToProps)(WebViewProduct);
