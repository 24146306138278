import React, { Component } from 'react';
import CommingSoonLayer from "../../components/common/CommingSoonLayer";
import { Modal, Button, Checkbox } from 'antd';
import CommonModal from '../layout/CommonModal';
import { _Api } from '../../services/Api';
import Url from '../../services/BaseUrl';
import { notify } from '../../components/Toaster';
import { Menu, Dropdown, Descriptions, Empty } from 'antd';
import { Link, NavLink } from 'react-router-dom';
import util from '../../utils/Util.js';
import { DeliverTraingle, NoMediaICon, NotificationIcon } from '../../SvgIcon';
import { connect } from "react-redux";
import { getNotificationList } from '../../redux/action';
import LazyLoad from 'react-lazy-load';
import moment from "moment"

class SearchPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificatonListData: [],
      closeNotification:false
    }
  }
  componentDidMount() {
    this.resetNotification();
    this.props.dispatch(getNotificationList())
  }

  closeNotification=(value)=>{
    let scope = this.props.scope;
    scope.setState({isNotificationVisible:false})

  }

  getNotificationData = (arrray, type) => {
    return arrray.filter((item) => item.notificationType == type)

  }
  resetNotification = () => 
  {
    _Api(Url.NOTIFICATION_RESET.method, Url.NOTIFICATION_RESET.url)
                .then(resp => {  
                  this.props.dispatch(getNotificationList())  

                   
                }).catch(err => {
                    this.setState({ isLoading: false })
                    if (err.error)
                        notify("err", err.error.responseMessage);                  
                })
    
  }

  render() {
    let { notification } = this.props.notificatonList;
    return (

      <Menu className=" header-search header-notification" onSelect={()=> alert("okkokoko")}  trigger="click" >
        <h3 className="ml-4 mt-3 text-center"><strong>Notifications</strong>
        </h3>
        <hr className="mb-0" />
        {notification?.length > 0 && notification.map((element) => {

          console.log("element", element)
          
          let item = element?.sender[0];

            if(element.notificationType===6)
            return (
                <div>
                    <div className="notify-row">
                        <div className="profile-dp">
                            <LazyLoad>
                                <img src={item.image || require('../../assets/images/login_user.png')} className="circle-image" />
                            </LazyLoad>
                        </div>
                        <div className="message">
                            <Link onClick={()=>this.closeNotification(element.senderId)} to={"/user/profile/"+element.senderId}><h5>{element?.text} <b>{item.firstName} {item.lastName}</b> </h5>
                            <p className="mt-2"> {moment(element.created).format('DD/MM/YYYY')} at {moment(element.created).format('hh:mm a')} </p>
                             </Link>
                        </div>
                    </div>
                </div>
            )
           else if(element.notificationType===7)
            return (
                <div>
                    <div className="notify-row">
                        <div className="profile-dp">
                            <LazyLoad>
                                <img src={item.image || require('../../assets/images/login_user.png')} className="circle-image" />
                            </LazyLoad>
                        </div>
                        <div className="message">
                            <Link onClick={()=>this.closeNotification(element.senderId)} to={"/enquiry"}><h5>{element?.text}</h5>
                            <p className="mt-2"> {moment(element.created).format('DD/MM/YYYY')} at {moment(element.created).format('hh:mm a')} </p>
                            </Link>
                        </div>
                    </div>
                </div>
            )
            else if(element.notificationType===8)
            return (
                <div>
                    <div className="notify-row">
                        <div className="profile-dp">
                            <LazyLoad>
                                <img src={require('../../assets/images/logo-half.png')} className="circle-image" />
                            </LazyLoad>
                        </div>
                        <div className="message">
                      <Link onClick={()=>this.closeNotification(element.senderId)} to={`/event-detail/${moment(element?.startDate).valueOf()}/${moment(element?.created).valueOf()}/${element.meetingId}/${moment().valueOf()}`}>
                        <h5>{element?.text}</h5>
                        <p className="mt-2"> {moment(element.created).format('DD/MM/YYYY')} at {moment(element.created).format('hh:mm a')} </p>
                        </Link>
                        </div>
                    </div>
                </div>
            )
            else if(element.notificationType===9)
            return (
                <div>
                    <div className="notify-row">
                        <div className="profile-dp">
                            <LazyLoad>
                                <img src={require('../../assets/images/logo-half.png')} className="circle-image" />
                            </LazyLoad>
                        </div>
                        <div className="message">
                      <Link onClick={()=>this.closeNotification(element.senderId)} to={`/event-detail/${moment(element?.startDate).valueOf()}/${moment(element?.created).valueOf()}/${element.meetingId}/${moment().valueOf()}`}>
                        <h5>{element?.text}</h5>
                        <p className="mt-2"> {moment(element.created).format('DD/MM/YYYY')} at {moment(element.created).format('hh:mm a')} </p>
                        </Link>
                        </div>
                    </div>
                </div>
            )

            else if(element.notificationType===3 || element.notificationType == 1)
            return (
              <div>
              <div className="notify-row">
                <div className="profile-dp">
                  <LazyLoad>
                    <img src={item.image || require('../../assets/images/login_user.png')} className="circle-image" />
                  </LazyLoad>
                </div>
                <div className="message">
                  <Link onClick={()=>this.closeNotification(element.senderId)} to={`/event-detail/${moment(element?.startDate).valueOf()}/${moment(element?.created).valueOf()}/${element.meetingId}/${moment().valueOf()}`}><h5><b>{item.firstName} {item.lastName}</b> {element?.text} </h5>
                  
                  <p className="mt-2"> {moment(element.created).format('DD/MM/YYYY')} at {moment(element.created).format('hh:mm a')} </p>
                   </Link>

                </div>
              </div>
            </div>
            )

            


            
            else if(element.notificationType===10)
            return (
              <div>
              <div className="notify-row">
                <div className="profile-dp">
                  <LazyLoad>
                    <img src={item.image || require('../../assets/images/login_user.png')} className="circle-image" />
                  </LazyLoad>
                </div>
                <div className="message">
                  <Link onClick={()=>this.closeNotification(element.senderId)} to={`/event-detail/${moment(element?.startDate).valueOf()}/${moment(element?.created).valueOf()}/${element.meetingId}/${moment().valueOf()}`}><h5><b>{item.firstName} {item.lastName}</b> {element?.text} </h5>
                  
                  <p className="mt-2"> {moment(element.created).format('DD/MM/YYYY')} at {moment(element.created).format('hh:mm a')} </p>
                   </Link>

                </div>
              </div>
            </div>
            )

            


            else
          return (
            <div>
              <div className="notify-row">
                <div className="profile-dp">
                  <LazyLoad>
                    <img src={item.image || require('../../assets/images/login_user.png')} className="circle-image" />
                  </LazyLoad>
                </div>
                <div className="message">
                  <Link onClick={()=>this.closeNotification(element.senderId)} to={"/community"}><h5><b>{item.firstName} {item.lastName}</b> {element?.text} </h5>
                  
                  <p className="mt-2"> {moment(element.created).format('DD/MM/YYYY')} at {moment(element.created).format('hh:mm a')} </p>
                   </Link>

                </div>
              </div>
            </div>
          )

        })
        }


        {
          notification?.length == 0 && <div className="w-100 text-center row nodata m-auto">
            <div className="m-auto notification-nodata">
              <NotificationIcon />

              <h2 className="mt-3">Notifiction not available</h2>
            </div>
          </div>
        }



      </Menu >
    )
  }
}
function mapStateToProps(state) {
  return {
    notificatonList: state.notificatonList
  };
}

export default connect(mapStateToProps)(SearchPopUp);