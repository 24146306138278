import React, { Component } from 'react';
import { Link } from "react-router-dom"
import {Modal, Select} from 'antd';
import { notify } from '../../../../components/Toaster';
import Loader from '../../../../components/Loader';

import { _Api } from '../../../../services/Api';
import Url from '../../../../services/BaseUrl'
//validation
import { validateEmail } from '../../../../components/Validation';

import {connect} from "react-redux";

import {SIGNUP_PROCESS, TOGGLE_LOGIN, TOGGLE_SIGNUP} from "../../../../redux/action";
import AddBrandStep from "./AddBrandStep";
import QuestionStep from "./QuestionStep";
// import InlineError from "../../components/InlineError";
// import {MySpinner} from "../../components";

const { Option } = Select;
var _ = require('lodash');

class AddBrands extends Component {
    constructor(props) {
        super(props);
        this.signUpProcessRef = React.createRef();
        this.state = {
            firstName: '',
            lastName: '',
            password: '',
            email: '',
            code:"",
            phoneNumber:'',
            companyName:'',
            emailError: '',
            firstNameError: '',
            lastNameError: '',
            passwordError: '',
            phoneNumberError:'',
            userTypeError: '',
            companyNameError:'',
            isLoader: false,
            isLoading:false,
            showMessage: false,
            isPasswordShow: false,
            isShowSignUpStep:true,
            signupStep:1,
            selectedSector:[],
            selectedSectorCount:0,
            selectedPlaceOfWork:[],
            placeofworkCount:0,
            selectedRole:[],
            roleCount:0,
            registrationId:"",
            registrationIdRequired:false,
            selectedCountryCode:"",
            selectedCountry:"",
            selectedCity:"",
            selectedCityId:"",
            visible:false,

            term1: false,
            term2: false,
            term3: false,
            termError1: '',
            termError2: '',
            termError3: '',
            brandType:false
        }

    }
    componentDidMount() {

    }

    toggleSignup=(value)=> {
        if(value==0){
            this.setState({
                firstName: '',
                lastName: '',
                password: '',
                email: '',
                code:"",
                phoneNumber:'',
                companyName:'',
                emailError: '',
                firstNameError: '',
                lastNameError: '',
                passwordError: '',
                phoneNumberError:'',
                userTypeError: '',
                companyNameError:'',
                isLoader: false,
                showMessage: false,
                isPasswordShow: false,
                isShowSignUpStep:true,
                signupStep:1,
                selectedSector:[],
                selectedSectorCount:0,
                selectedPlaceOfWork:[],
                placeofworkCount:0,
                selectedRole:[],
                roleCount:0,
                registrationId:"",
                registrationIdRequired:false,
                selectedCountryCode:"",
                selectedCountry:"",
                selectedCity:"",
                selectedCityId:"",
                brandType:false
            })
        }
        this.props.dispatch({ type: TOGGLE_SIGNUP, value: value });
        //console.log("this.props",this.props);
        // if(this.props.match.path==="/signup" && value==0){
        //     this.props.history.push("/")
        // }
    }

    toggleLogin=(value)=> {
        this.props.dispatch({ type: TOGGLE_LOGIN, value: value });
    }

    toggleSignupProcess=(value)=> {
        this.props.dispatch({ type: SIGNUP_PROCESS, value: value });
    }

    setPhoneNumber=(phoneNumber,code)=>{
        this.setState({ phoneNumber, phoneNumberError: '', code: code.dialCode })
    }

    // sign up submit
    signUpSubmit = (e) => {
        e.preventDefault();
        let rexx = /^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[789]\d{9}|(\d[ -]?){7,15}\d$/
        let { firstName, lastName, email, password,phoneNumber,companyName } = this.state
        if (firstName.trim() == '')
            this.setState({ firstNameError: "*Please enter first name." })
        else if (lastName.trim() == '')
            this.setState({ lastNameError: '*Please enter last name.' })
        // else if (companyName.trim() === '')
        //     this.setState({ companyNameError: '*Please enter company name.' })
        else if (phoneNumber === '')
            this.setState({ phoneNumberError: '*Please enter phone number.' })
        else if (!rexx.test(phoneNumber))
            this.setState({ phoneNumberError: '*Please enter valid phone number.' })
        else if (email.trim() == '')
            this.setState({ emailError: '*Please enter email.' })
        else if (!validateEmail(email).status)
            this.setState({ emailError: '*Please enter valid email.' })
        // else if (password == '')
        //     this.setState({ passwordError: '*Please enter password.' })
        // else if (password.length <= 4)
        //     this.setState({ passwordError: '*Password must be greater than 4 characters.' })
        else {
            this.setState({ isLoading: true, erroStatus: false, showMessage: false })
            let req = {
                email: email
            }
            _Api(Url.IS_EMAIL_ID_ALREADY_EXIST.method, Url.IS_EMAIL_ID_ALREADY_EXIST.url, '', `?email=${req.email}`)
                .then(resp => {
                    if (resp.statusCode == 1 && resp.responseData?.status == true) {
                        this.setState({ isLoading: false, erroStatus: false, message: resp.responseData.message, showMessage: true })
                    }
                    else if (resp.statusCode == 1) {
                        this.setState({
                            isLoading: false,
                            visible:true,
                            isShowSignUpStep:false,
                        })
                    }
                    else {
                        this.setState({ isLoading: false, erroStatus: false, message: resp?.error?.responseMessage, showMessage: true })
                    }
                }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
            })

        }
    }

    sugnUpOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, firstNameError: '', lastNameError: '', emailError: '', passwordError: '',companyNameError:'' })
    }

    stateSet=(field,value)=>{
        this.setState({
            [field]:value
        })
    }

    onCheckedBox=(id,field,countField)=>{
        let selectedField=this.state[field]
        if(field==='selectedSector'){
            this.setState({
                selectedPlaceOfWork:[],
                placeofworkCount:0,
                selectedRole:[],
                roleCount:0,
                registrationId:"",
                registrationIdRequired:false,
            })
        }else if(field==='selectedPlaceOfWork'){
            this.setState({
                selectedRole:[],
                roleCount:0,
                registrationId:"",
                registrationIdRequired:false,
            })
        }else if(field==='selectedRole'){
            this.setState({
                registrationId:"",
                registrationIdRequired:false,
            })
        }
        let checkResult=_.find(selectedField,function (obj){
            return obj===id;
        })
        //console.log("onChecked",e.target.value,selectedField,checkResult);
        if(checkResult){
            let newData = _.remove(selectedField, function(n) {
                return id===n;
            });
            //console.log("======",newData);
            this.setState({
                [field]:selectedField,
                [countField]:this.state[countField]-1
            })
        }else {
            selectedField.push(id);
            this.setState({
                [field]:selectedField,
                [countField]:this.state[countField]+1
            })
        }
    }

    onChecked=(e,field,countField)=>{
        let selectedField=this.state[field]
        if(field==='selectedSector'){
            this.setState({
                selectedPlaceOfWork:[],
                placeofworkCount:0,
                selectedRole:[],
                roleCount:0,
                registrationId:"",
                registrationIdRequired:false,
            })
        }else if(field==='selectedPlaceOfWork'){
            this.setState({
                selectedRole:[],
                roleCount:0,
                registrationId:"",
                registrationIdRequired:false,
            })
        }else if(field==='selectedRole'){
            this.setState({
                registrationId:"",
                registrationIdRequired:false,
            })
        }
        let checkResult=_.find(selectedField,function (obj){
            return obj===e.target.value;
        })
        //console.log("onChecked",e.target.value,selectedField,checkResult);
        if(checkResult){
            let newData = _.remove(selectedField, function(n) {
                return e.target.value===n;
            });
            //console.log("======",newData);
            this.setState({
                [field]:selectedField,
                [countField]:this.state[countField]-1
            })
        }else {
            selectedField.push(e.target.value);
            this.setState({
                [field]:selectedField,
                [countField]:this.state[countField]+1
            })
        }
    }

    submitSignup=()=>{
        let addBrandResuest = {
            userType: 2,
            planId: this.state.brandType ? 3 : 2,
            exhibitionId: this.props?.match?.params?.exId,
            email: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            password: this.state.password,
            countryCode: this.state.code,
            companyName: this.state.companyName,
            phoneNumber: this.state.phoneNumber,
            country: this.state.selectedCountry,
            city: this.state.selectedCity,
            cityId:this.state.selectedCityId,
            country_Code: this.state.selectedCountryCode,
            registrationId: this.state.registrationId,
            placeOfWork: this.state.selectedPlaceOfWork.toString(),
            sectorId: this.state.selectedSector.toString(),
            roleId: this.state.selectedRole.toString(),

        }
        this.setState({ isLoading: true })
        _Api(Url.ADD_USER.method, Url.ADD_USER.url, addBrandResuest)
            .then(resp => {
                // notify('success', resp.responseData.message)
                this.setState({ isLoading: false })
                this.props.history.goBack()

            }).catch(err => {
            this.setState({ isLoader: false })
            if (err.error)
                notify("err", err.error.responseMessage)
        })
    }


    handleCheck = (which) => {
        this.setState({
            [which]: !this.state[which],
            termError1:'',
            termError2:'',
            termError3:''

        })
    }

    handleOk = e => {
        console.log(e);
        this.setState({
            visible: false,
            term1: false,
            term2: false,
            term3: false,
            termError1: '',
            termError2: '',
            termError3: '',
        });
    };

    render() {
        console.log("this.props",this.props);
        console.log("this.state",this.state);
        let { loading, firstName, lastName, email, firstNameError, passwordError, password, lastNameError, emailError ,companyName,companyNameError} = this.state


        return (
            <>
                <Modal
                    wrapClassName="signup_parent_modal"
                    className="cm_popup signup_modal "
                    //cm_popup term-services
                    visible={this.props.authSetting.addBrand}
                    // onOk={this.handleOk}
                    onCancel={()=>this.toggleSignup(0)}
                    footer={null}
                    centered
                    maskClosable={false}
                >
                    {this.state.isShowSignUpStep?
                        <AddBrandStep {...this.state} sugnUpOnChange={this.sugnUpOnChange} signUpSubmit={this.signUpSubmit} setPhoneNumber={this.setPhoneNumber} stateSet={this.stateSet}/>:
                        <QuestionStep {...this.state} stateSet={this.stateSet}/>
                    }
                </Modal>

            </>

        )
    }
}
function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(AddBrands);