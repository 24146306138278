import React, { Component } from 'react';
import CommingSoonLayer from "../../components/common/CommingSoonLayer";
import { Modal, Button, Checkbox } from 'antd';

class CommonModal extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }


    handleCancel = e => {
        console.log(e);
        this.props.scope.setState({ visible: false })
    }   

    render() {
        return (
            <Modal
                footer={null}
                centered
                onCancel={this.props.handleCancel}
                className="paymentpopup"
                visible={this.props.isOpen}  >

                {this.props.children}

            </Modal>

        )
    }
}
export default CommonModal;