import React, { Component } from 'react';

import {
    SvgIcon
} from "../index"

class Footer extends Component {
    render() {
        return (
            <>
                <footer className="footer-area footer--light mt-5" >
                    <div className="footer-big">

                        <div className="">
                            <div className="row ">
                                <div className="col-xl-2 col-lg-12 ">
                                    <div className="footer-widget ">
                                        <div className="mt-5 mb-5">
                                            <a href="/"><img src={require('../../assets/images/logo.png')} className="img-fluid" /></a>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-xl-2 col-lg-6">
                                    <div className="footer-widget">
                                        <div className="footer-menu">
                                            <h4 className="footer-widget-title">About</h4>
                                            <p>The Professional Beauty Group has been serving the beauty industry for over 30 years.
                                           We have offices in Dubai , Dublin, Johannesburg, London & Mumbai</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-2 col-lg-6">
                                    <div className="footer-widget">
                                        <div className="footer-menu link">
                                            <h4 className="footer-widget-title">Useful Links</h4>
                                            <ul>
                                                
                                                <li>
                                                    <a name="about-us" href="https://www.professionalbeauty.co.uk"> www.professionalbeauty.co.uk</a>
                                                </li>
                                                <li>
                                                    <a name="membership-plan" href="https://www.professionalbeauty.ae">www.professionalbeauty.ae</a>
                                                </li>
                                                <li>
                                                    <a name="our-brand" href="https://www.professionalbeauty.ie">www.professionalbeauty.ie</a>
                                                </li>
                                                <li>
                                                    <a name="our-product" href="https://www.professionalbeauty.in">www.professionalbeauty.in</a>
                                                </li>
                                                <li>
                                                    <a name="our-product" href="https://www.probeauty.co.za">www.probeauty.co.za</a>
                                                </li>
                                               

                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-2 col-lg-6">
                                    <div className="footer-widget">
                                        <div className="footer-menu no-padding">
                                            <h4 className="footer-widget-title">Quick Links</h4>
                                            <ul>
                                                <li>
                                                    <a name="faq" >Home</a>
                                                </li>
                                                <li>
                                                    <a href="/blog/nutritionist">About Us</a>
                                                </li>
                                                <li>
                                                    <a name="Privacy" >Services</a>
                                                </li>
                                                <li>
                                                    <a name="Privacy" >Terms of services</a>
                                                </li>
                                                <li>
                                                    <a name="Privacy" >Privacy Policy</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-xl-4 col-sm-6">
                                    <div className="footer-widget">
                                        <div className="footer-menu no-padding">
                                            <h4 className="footer-widget-title">Contact US</h4>
                                            <ul>
                                                <li>
                                                    <a href="#" className="d-flex">
                                                        <span className="svgicon"><SvgIcon.FooterLocation /></span>Professional Beauty Digital Limited
                                                         Registered in England & Wales
                                                         Allington House
                                                         25 High Street , London
                            SW19 5DX</a>
                                                </li>
                                                <li>
                                                    <a href="#"><span className="svgicon"><SvgIcon.FooterPhone /></span>+ 44 20 7351 0536</a>
                                                </li>
                                                <li>
                                                    <a href="#"><span className="svgicon"><SvgIcon.FooterMail /></span>steve@professionalbeauty.co.uk</a>
                                                </li>

                                            </ul>
                                            <ul className="social-networks pt-0">
                                                <a href="#" className="facebook" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                                <a href="#" className="facebook" target="_blank"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                                <a href="#" className="facebook" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                                <a href="#" className="facebook" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                                                <a href="#" className="facebook" target="_blank"><i class="fa fa-google-plus" aria-hidden="true"></i></a>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-sm-10 offset-sm-1 text-center">
                                    <hr />

                                    <p className="footer-bottom ff-CPL pb-3">Copyright@ 2020. Professional Beauty. All rights reserved</p>

                                </div>
                            </div>
                        </div>

                    </div>

                </footer>
            </>

        );
    }

}

export default Footer;