import React, { Component } from 'react';
import { Calender, Delete, Hert, Pencil, Chat, ZoomVideo } from "../../../SvgIcon";
import { Link } from "react-router-dom";
import { Select, Checkbox } from 'antd';
import { connect } from "react-redux";
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';
import { Table, Button, Dropdown, Menu } from 'antd';
import Loader from '../../../components/Loader'
import socket from "../../../services/socket";
import { groupActions } from "../../../redux/action";



const { Option } = Select;
class EnquiryDetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            detail: {},
            visibleMenuSettings: false,
            isLoader: true,
            StaffListData: [],
            selectedItems: [],

        }
    }


    componentDidMount() {
        this.props.dispatch({ type: 'PATH_ADD', payload: this.props.match })
        this.getInfo()
        this.getStaffDetails()
        this.assignEnquire()
    }


    assignEnquire = (assignId, enquiryId) => {
        this.setState({ isLoader: true })

        _Api(Url.ASSIGN_ENQUIRE.method, Url.ASSIGN_ENQUIRE.url, '', `?enquiryId=${enquiryId}&assignId=${assignId}`)
            .then(resp => {
                console.log('respexList', resp.responseData.result.data)
                this.setState({ isLoader: false })
                this.getInfo()
            }).catch(err => {
                this.setState({ isLoader: false })
            })
    }



    getStaffDetails = () => {
        // this.setState({ isLoader: true })
        let { page, limit, search } = this.state
        let req = {
            page: 1,
            limit: 100,
        }
        _Api(Url.GET_STAFF_LIST_Details.method, Url.GET_STAFF_LIST_Details.url, '', `?page=${req.page}&limit=${req.limit}&brandId=${localStorage.getItem("ownerId") ? localStorage.getItem("ownerId") : localStorage.getItem("id")}`)
            .then(resp => {
                console.log('respexList', resp.responseData.result.data)
                this.setState({ StaffListData: resp.responseData.result, isLoader: false })
            }).catch(err => {
                this.setState({ isLoader: false })
            })
    }



    getInfo = () => {
        _Api(Url.GET_PARTICULAR_ENQ.method, Url.GET_PARTICULAR_ENQ.url, '', `?enquiryId=${this.props.match.params.enId}`)
            .then(resp => {
                // console.log('reaERTYp', resp.responseData.result)
                this.setState({ detail: resp.responseData.result, isLoader: false })

            }).catch(err => {
                this.setState({ isLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
                this.setState({ isLoader: false })
            })
    }

    //     enquireStatus: 0
    // status: 1
    // isDeleted: 0
    // enquiryReason: "xnksmxjsax ksc"
    // category: 1
    // created: "2021-01-07T04:17:17.649Z"
    // productId: "5fe334b197d4197626a454d4"
    // productName: "vhjd"
    // userId: "5fd9a7ab01483034b804f1c3"
    // firstName: "mmmm"
    // lastName: "ml"
    // email: "abc@yopmail.com"
    // phoneNumber: 3811211111111

    getStatus = (number) => {
        console.log("dssss", number)
        switch (number) {
            case 1:
                return 'Pending'
            case 2:
                return 'In Progress'
            case 3:
                return 'Done'
            default:
                return 'In Progress'
        }

    }
    handleStatus = (e, id) => {
        this.setState({ isLoader: true, visibleMenuSettings: false })
        _Api(Url.CHANGE_STATUES_ENQUIRES.method, Url.CHANGE_STATUES_ENQUIRES.url,
            '', `?enquiryId=${id}&status=${e}`)
            .then(resp => {
                this.getInfo()
            }).catch(err => {
                this.setState({ isLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
                this.setState({ isLoader: false })
            })

    }



    handleVisibleChange = flag => {
        this.setState({ visibleMenuSettings: flag });
    }

    selectUser = (user) => {
        let self = this;
        // console.log("user", this.props.userDetail._id !== user._id);
        if (this.props.userDetail._id !== user._id) {
            this.setState({
                selectedUser: user,
                selectedUserId: user._id,
                chatType: 3,
                isShow: true
            }, function () {
                self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 3 })
                self.props.dispatch({ type: 'CHAT_OPEN' })
                document.body.classList.toggle('chat_open_mob');
                self.props.dispatch({ type: 'SELECTED_USER', selectedUser: user })
                socket.emit('groupAdd', { type: 7, userId: user._id });
                //socket.emit('userConnection', {userId: user._id});
            })
        }

    }

    getCategory = (number) => {
        switch (number) {
            case 0:
                return 'Training'
            case 1:
                return 'Job'
            case 2:
                return 'Business'
            default:
                return 'Job'
        }

    }


    getName = (number, item) => {
        switch (number) {
            case 0:
                return <Link to={`/web-training-details/${item?.offerId}`}> {item?.name}</Link>
            case 1:
                return <Link to={`/web-job-details/${item?.productId}`}> {item?.productName}</Link>
            case 2:
                return <>
                    {
                        item?.userData?.activeCompany ?
                            <Link to={`/business/${item?.userData?._id}/${item?.userData?.activeCompany}`}> {`${item?.userData?.companyName}`}</Link>
                            :
                            <Link to={`/business/${item?.userData?._id}`}> {`${item?.userData?.companyName}`}</Link>
                    }
                </>


            default:
                return ''
        }

    }

    videoCalling = (userId) => {
        let self = this;

        if (userId) {
            this.setState({ startLoader: true })

            let oldArr = this.state.selectedItems
            oldArr.push(userId)

            self.setState({
                selectedItems: oldArr,

                ['videoConnectionLoder_' + userId]: true
            })
            self.props.dispatch(groupActions.joinOneMeeting({ userId: userId, type: 7 }))
                .then(function (res) {
                    self.setState({
                        ['videoConnectionLoder_' + userId]: false, startLoader: false,
                        selectedItems: self.state.selectedItems.filter(item => item != userId)
                    })
                    if (res.statusCode === 1 || res.statusCode === "1") {
                        //window.location.href = '/calling/' + self.props.groupDetail.meeting._id;
                        window.open('/calling/' + self.props.groupDetail.meeting._id, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                        //history.push('/calling/'+self.props.groupDetail.meeting._id)
                    } else {
                        self.setState({ startLoader: false, selectedItems: self.state.selectedItems.filter(item => item != userId) })

                    }
                    console.log("res", res)
                    // self.setState({ startLoader: false,  selectedItems: self.state.selectedItems.filter(item => item != userId) })
                })
        }


    }


    render() {
        const { detail } = this.state
        const { isInfoPublic,
            isVideoPublic,
            assignName,
            lastModifiedName,
            isChatPublic,
            isMobilePublic,
            _id, userData, brandData, name, email, category, enquiryReason, productId, status, productName, firstName, lastName, phoneNumber, enquireStatus, offerId } = detail

        const MenuStatus = (data) => {
            console.log("dhbjd", data)
            return (
                <Menu>
                    <Menu.ItemGroup title="" >
                        <Menu.Item key="0" onClick={(e) => this.handleStatus(e, data?.data?._id)}>In-progress</Menu.Item>
                        <Menu.Item key="1" onClick={(e) => this.handleStatus(e, data?.data?._id)}>Done</Menu.Item>
                    </Menu.ItemGroup>
                </Menu>
            )
        }

        return (
            <div className="container-fluid enquiredetail_parents">
                {
                    this.state.isLoader && <Loader />
                }
                <div className="row">
                    <div className="col-sm-12 mt-3 text-right">
                    <button onClick={()=> this.props.history.goBack()} className="btn btn-light cursor-point">
                        <i class="fa fa-arrow-left" aria-hidden="true"></i>Back
                        </button>
                    </div>
                    <div className="col-sm-12">
                        <h2 className="pos-relative text-center mt-3">
                            Enquire Details
                    <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                        </h2>
                        <br />
                        {/* <div className="text-right">
                    <Link class="btn btn-warning btn-sm changepass mr-3" to="">
                        <i class="fa fa-download mr-2" aria-hidden="true"title="Download csv"></i>Download
                    </Link>
                </div> */}
                        <div className="row mt-4">
                            <div className="col-sm-10 offset-sm-1">

                                <div className="vertical-table table-responsive user-profile">
                                    <ul className="detail_row">
                                        <li>First Name</li>
                                        <li>{firstName}</li>
                                    </ul>
                                    <ul className="detail_row">
                                        <li>Last Name</li>
                                        <li>{lastName}</li>
                                    </ul>
                                    <ul className="detail_row">
                                        <li>Email</li>
                                        <li>{isInfoPublic == 1 ? email : '*****'}</li>
                                    </ul>
                                    <ul className="detail_row">
                                        <li>Phone Number</li>
                                        <li>{isMobilePublic == 1 ? phoneNumber : '*****'}</li>
                                    </ul>
                                    <ul className="detail_row">
                                        <li>Category</li>
                                        <li>{this.getCategory(category)}</li>
                                    </ul>
                                    <ul className="detail_row">
                                        <li>Enquired For</li>
                                        <li>{this.getName(category, detail)}</li>
                                    </ul>
                                    <ul className="detail_row">
                                        <li>Reason for enquiry</li>
                                        <li>{enquiryReason}</li>
                                    </ul>
                                    <ul className="detail_row">
                                        <li>Enquiry status</li>
                                        <li>
                                            <Select value={this.getStatus(enquireStatus)} className="enqire" style={{ width: '30%' }} onSelect={e => this.handleStatus(e, _id)}>
                                                <Option value={1} > {'Pending'}</Option>
                                                <Option value={2} > {'In Progress'} </Option>
                                                <Option value={3} > {'Done'} </Option>
                                            </Select>
                                        </li>
                                    </ul>

                                    <ul className="detail_row">
                                        <li>Assign to</li>
                                        <li>
                                            <Select value={assignName ? assignName : 'Please Select'} style={{ width: '200px' }} className="enqire" onSelect={e => this.assignEnquire(e, _id)}>

                                                {
                                                    this.state.StaffListData?.map((item) => {
                                                        return (
                                                            <Option value={item._id} > {item?.firstName} {item?.lastName}</Option>

                                                        )
                                                    })
                                                }

                                            </Select>
                                        </li>
                                    </ul>
                                    <ul className="detail_row">
                                        <li >Last Modified</li>
                                        <li>{lastModifiedName}</li>


                                        {/* <li style={{width:10, height:10}}><Chat /></li> */}
                                    </ul>


                                    <ul className="detail_row">
                                        <li >Chat</li>
                                        <li className="enqirechat-p">
                                            {isChatPublic == 1 && <span onClick={() => this.selectUser(brandData)} className="enqirechat">
                                                <Chat /></span>}
                                            {isVideoPublic == 1 && <a> <span
                                                onClick={() => this.videoCalling(brandData?._id)}
                                                className="enqirechat ml-2"><ZoomVideo /></span> </a>}
                                        </li>
                                        {/* <a onClick={() => this.selectUser(userData)} className="enqirechat-p"><span className="enqirechat"><Chat/></span></a> */}

                                        {/* <li style={{width:10, height:10}}><Chat /></li> */}
                                    </ul>

                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

}



function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(EnquiryDetails);

