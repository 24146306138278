import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';
import moment from 'moment-timezone';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';
import util from '../../../utils/Util.js';
import { PaymentIcon, SearchIcon } from '../../../SvgIcon'

class SearchDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchData: []

        }
    }

    componentDidMount() {

        this.getSubscription();


    }
    getSubscription = () => {
        let req = {
            searchId: this.props.match.params.id,
        }
        _Api(Url.GET_SUBSCRIPTION_DETAIL.method, Url.GET_SUBSCRIPTION_DETAIL.url, '', `?searchId=${req.searchId}`)
            .then(resp => {
                let responseData = resp.responseData.result;
                this.setState({
                    searchData: responseData

                });

            }).catch(err => {
                this.setState({ isLoader: false });
                let errorMessage = err.error && err.error.responseMessage || err.message;
                notify("err", errorMessage);
            });
    }

    render() {
        let { searchData } = this.state;
        return (
            <>
                <div className="dash_body_container">
                    <Header />
                    <Sidebar />
                    <div className="body_container tran-details">

                        <div className="header-text d-flex mb-4 mt-3">
                            <div className="d-flex  flex-grow-1 align-items-center">
                                <p className="header-icon"><SearchIcon /></p>
                                <h4>Search
                                </h4>
                            </div>
                            <Link to={`/edit-search/${searchData && searchData._id}`} className="btn btn-red">Edit Search</Link>
                        </div>

                        <div className="rounded-box">
                            <div class="table-responsive no-shadow transaction-details-table">
                                <table class="table table-bordered rounded-table">
                                    <tr>
                                        <td>Title.</td>
                                        <td>{searchData && searchData.planName}</td>
                                        <td>No. Of Searches :</td>
                                        <td>{searchData && searchData.numberOfSearches}</td>
                                    </tr>
                                    <tr>
                                        <td>USD Amount </td>
                                        <td>{searchData && searchData.dollarAmount}</td>
                                        <td>GBP Amount</td>
                                        <td>{searchData && searchData && searchData.euroAmount}</td>
                                    </tr>
                                    <tr>
                                        <td>Place Of Work</td>
                                        <td>{searchData.placeOfWorkId && searchData.placeOfWorkId.map(e => e["name"]).join(", ")}</td>
                                        <td>Description</td>
                                        <td>{searchData && searchData.description}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div className="bottom-btn">
                            <Link to="/search" className="btn btn-warning">Back</Link>
                        </div>


                    </div>
                </div>
            </>
        );
    }
}
export default SearchDetail;