import React from 'react'
import { DatePicker, TimePicker, Select } from 'antd';
import { CircleCross } from '../../../../SvgIcon';
import { _Api } from '../../../../services/Api';
import Url from '../../../../services/BaseUrl';
import InlineError from '../../../../components/InlineError';
import { notify } from '../../../../components/Toaster'


class EditStaff extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            staffName: '',
            jobProfile: '',
            profileImage: '',
            staffNameError: '',
            jobProfileError: '',
            profileImageError: '',
            page: '',
            limit: 10,
        }
    }
    staffOnChnage = (e) => {
        this.setState({ [e.target.name]: e.target.value, staffNameError: '', jobProfileError: '' })
    }

    componentDidMount(){
        document.body.style.overflow = 'hidden'; //prevent background scrolling

        this.getParticularStaffView()
        this.getStaffDetails()
        // this.getProductDetails()
        console.log("cnkasbcnskmajcbhvb ", this.props.staffData)
    }

    componentWillUnmount() {
        document.body.style.overflow = 'auto';

    }

getStaffDetails = () => {
    this.setState({ isLoader: true })
    let { page, limit, search } = this.state
    let req = {
        page: page ? page : 1,
        limit: limit,
    }
    _Api(Url.GET_STAFF_LIST.method, Url.GET_STAFF_LIST.url, '', `?page=${req.page}&limit=${req.limit}`)
        .then(resp => {
            console.log('respexList', resp.responseData.result.data)
            this.setState({ StaffListData: resp.responseData.result.data, totalCount: resp.responseData.result.totalCount, isLoader: false })
        }).catch(err => {
            this.setState({ isLoader: false })
            if (err.error)
                notify("err", err.error.responseMessage)
            else if (err)
                // notify("err", err.message)
            this.setState({ isLoader: false })
        })
}

    getParticularStaffView = () => {
        _Api(Url.VIEW_PARTICULAR_STAFF.method,Url.VIEW_PARTICULAR_STAFF.url,'',`?staffId=${this.props.staffId}`)
        .then(resp => {
            console.log('reaERTYp', resp.responseData.result)
            let params=resp?.responseData?.result
            this.setState({staffName:params.staffName,jobProfile:params.jobProfile,image:params.image})
            // this.props.closePopup()
            // notify('success', resp.responseData.message)
        }).catch(err => {
            this.setState({ isLoader: false })
            if (err.error)
                notify("err", err.error.responseMessage)
            else if (err)
                // notify("err", err.message)
            this.setState({ isLoader: false })
        })
    }

    editStaff = () => {
        let { staffName, jobProfile, profileImage } = this.state
        if (staffName == '')
            this.setState({ staffNameError: '*Please enter staff name' })
        else if (jobProfile == '')
            this.setState({ jobProfileError: '*Please enter job profile' })
        else {
            let formData = new FormData()
            formData.append('staffId', this.props.staffId)
            formData.append('staffName', staffName)
            formData.append('jobProfile', jobProfile)
            formData.append('image', profileImage)
            _Api(Url.EDIT_STAFF.method, Url.EDIT_STAFF.url, formData)
                .then(resp => {
                    console.log('reap', resp)
                    this.props.closePopup()
                    this.getStaffDetails()
                    notify('success', resp.responseData.message)
                }).catch(err => {
                    this.setState({ isLoader: false })
                    if (err.error)
                        notify("err", err.error.responseMessage)
                   // else if (err)
                //     // notify("err", err.message)
                    this.setState({ isLoader: false })
                })
        }
    }

    handleImageUpload = (event) => {
        console.log(event, "eventeventevent", event.target.files[0])
        let reader = new FileReader();
        let file = event.target.files[0];
        let { valiDate } = this.state;
        reader.addEventListener("load", () => {
            this.setState({ profilePicPreview: reader.result }, function () {
            })
        }, false);
        if (file) {
            reader.readAsDataURL(file)
        }
        if (file) {
            console.log(event, "media1", event.target.files[0])
            if (file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png' ) {
                console.log("media2", event.target.files[0], file.size)
                if (file.size > 2000000) {
                    console.log("lkjhgfdsdfghjklkjhgfghjkmnbvccvbnm>>>>>>>>>>>>>>>>>>")
                    valiDate = false;
                    console.log(event, "media", event.target.files[0])
                    this.setState({
                        profileImage: event.target.files[0],
                        profilePicErrorMsg: 'Profile pic size should be less than 5Mb.'
                    });
                } else {
                    console.log("media3hhhjshs", event.target.files[0], file.size)
                    this.setState({
                        profileImage: event.target.files[0],
                        profileImageError: ''
                    });
                }
            } else {
                valiDate = false;
                this.setState({ media: 'File type not supported.' })

            }
        }
    }
    render() {
        console.log('props', this.props.staffId)
        let { staffName, jobProfile, staffNameError, jobProfileError, profileImageError } = this.state
        return (
            <div className='popup'>
                <div className='popup_inner small form'>
                    <div className="rounded-body-form">
                        <div className="cm-form-wrapper rounded-textbox-form">

                            <div className="header-text">
                                <h4>Add Staff</h4>
                            </div>
                            <div className="col-md-8 offset-md-2">
                            <div className="upload-wrapper">

                                {/* <label for="file-upload" className="upload-photo-wrapper">
                                    <span> <p className="m-0"><i className="fa fa-cloud-upload"></i></p></span>
                                    <p className="m-0"> Add Photo</p>
                                </label>

                                <input id="file-upload" type="file" onChange={this.handleImageUpload} /> */}
                                 <span className="upload-icon">
                                         <img src={this.state.profilePicPreview} alt="category image"/>
                                    </span>
                                  
                                    <input id="file-upload"  className="d-block"type="file" onChange={this.handleImageUpload} />

                            </div>

                            {/* <div className="upload-inner">
                                    <span className="upload-icon">
                                         <img src={this.state.profilePicPreview} alt="category image"/>
                                    </span>
                                  
                                    <input id="file-upload"  className="d-block"type="file" onChange={this.handleImageUpload} />

                            </div> */}


                            <div className="form-group">
                                <level>Staff Name</level>
                                <input type="text"
                                    className="form-control"
                                    placeholder="Staff Name"
                                    maxLength={64}
                                    autoComplete="off"
                                    name="staffName"
                                    value={staffName}
                                    onChange={this.staffOnChnage}
                                />
                                <InlineError
                                    message={staffNameError}
                                />
                            </div>


                            <div className="form-group">
                                <level>Job Profile</level>
                                <input type="text"
                                    className="form-control"
                                    placeholder="Job Profile"
                                    autoComplete="off"
                                    maxLength={64}
                                    name="jobProfile"
                                    value={jobProfile}
                                    onChange={this.staffOnChnage}
                                />
                                <InlineError
                                    message={jobProfileError}
                                />
                            </div>



                            <div className="text-center pt-4">
                                <button onClick={this.editStaff} className="btn btn-red">Save</button>
                            </div>
                            </div>

                            <button className="close-btn" onClick={this.props.closePopup}><CircleCross /></button>


                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EditStaff