
var moment = require("moment-timezone")

export const getTimeZone = (time, zone) => {
    if (time) {
        

        // console.log("dsfdghjks", moment(time).toDate().getTime() , moment( moment(time).toDate().getTime()).tz(moment.tz.guess()).format('LT'))
        // var london =moment.tz(`${time.split(' GMT')[0]}+01:00`, zone ? zone : "Europe/London")
        // // var india = london.clone().tz(moment.tz.guess());
        // console.log("njfsdkfmds get time",  time )
        // console.log("njfsdkfmds",  {time , zone, split:time.split(' GMT')[0]})    

        // console.log("njfsdkfmds",  moment(`${time.split(' GMT')[0]}`).tz(moment.tz.guess()).format("LT"))
        // return moment(`${time.split(' GMT')[0]}+01:00`).tz(moment.tz.guess()).format('hh:mm a, z')
        // console.log("dkjshghdjk", moment.tz.zone())

        // return moment.tz(time,  moment.tz.guess()).format('hh:mm a, z') 
        return moment.tz(new Date(time), zone).format('hh:mm a, z ') 
    }
    else return ''

}


export const getTimeFormate = (date) => moment(date).format('DD/ MM/ YYYY')
  





// export const getTimeZone = (time, zone) => {
//     if(time){
//     var london = moment.tz(`${time.split(' GMT')[0]} +01:00`, zone ? zone : "Europe/London")
//     var india =  london.local();
//     let dd =  moment(time)
//     console.log("njfsdkfmds",london, moment.tz("Europe/London").format('LT') )
//     return india.format('hh:mm a, z')
//     }
//     else return ''

// }