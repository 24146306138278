import React, { Component } from 'react';
import WebHeader from '../../layout/WebHeader';
import { Link } from "react-router-dom";
import { Hert } from '../../../SvgIcon';
import Footer from "../../layout/Footer"
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import Masonry from 'react-masonry-css'
import { Menu, Dropdown, Checkbox, DatePicker, TimePicker, Empty } from 'antd';
import Select from 'react-select';
import InlineError from "../../../components/InlineError";
import { connect } from "react-redux";
import { notify } from "../../../components/Toaster";
import Loader from "../../../components/Loader";
import { userActions } from "../../../redux/action";
import Croper from "../../../components/Croper";

var _ = require('lodash');
class EditSectorsCategories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCheckboxTab: false,
            list: [],
            selectedId: [],
            toggle: false,
            userData: {},
            message: '',


            productName: '',
            description: '',
            productImage: '',
            productNameError: '',
            descriptionError: '',
            productImageError: '',
           
            isCropper: false,
           


            isSelected: false,
            manageCategoriesId: [],
            sectorList: [],
            sectorId: [],
            isChecked:false,
            sectorError:''













        }

    }

    componentDidMount() {
        this.getInfo()
        this.getUserProfile()
    }

    getInfo = () => {
        _Api(
            Url.GET_CATEGORY_PRODUCT_WEB.method,
            Url.GET_CATEGORY_PRODUCT_WEB.url,
            "",
            // `?requestUserId=${req.requestUserId}`
        )
            .then((resp) => {

                let responseData = resp.responseData.result;
                console.log("kjdhagdhjk", responseData)

                this.setState({
                    isLoader: false,
                    list: responseData

                })
            })
            .catch((err) => {
                this.setState({ isLoader: false });
            });
    }

    handleSelect = (id, item) => {
        console.log("dkjhgfdhjksahb", id)
        let oldArr = this.state.selectedId
        console.log("dkjhgfdhjksahb", oldArr.findIndex(item => item?.id == id))

        let flag = oldArr.findIndex(item => item?.id == id)
        if (flag == -1) {

            if (oldArr.length > 0) {
                let arr = oldArr.filter(item => item.sectorId.length == 0)
                if (arr.length != 0) {
                    this.setState({ message: '*Please select sector' })

                }
                else {
                    let obj = {
                        id: id,
                        sectorId: []
                    }
                    oldArr.push(obj)
                    this.setState({ selectedId: oldArr, message: '' })
                }

            }
            else {
                let obj = {
                    id: id,
                    sectorId: []
                }
                oldArr.push(obj)
                this.setState({ selectedId: oldArr, message: '' })
            }






        }
        else {
            this.setState({ selectedId: this.state.selectedId.filter(item => item?.id != id), message: '' })

        }

    }

    getUserProfile = () => {
        let self = this;
        let req = {
            requestUserId: this.props.match.params.id || localStorage.id || "",
        };
        _Api(
            Url.GET_USER_PROFILE.method,
            Url.GET_USER_PROFILE.url,
            "",
            // `?requestUserId=${req.requestUserId}`
        )
            .then((resp) => {
                let responseData = resp.responseData.result;
                let arr = responseData?.manageCategoriesId
                arr.forEach(item => item?.sectorId?.forEach(item2 => { self.onSubCheck(item.id, item2) }))
                this.setState({
                    isLoader: false,
                    currentType: responseData.userPlan, brandType: responseData.userPlan,
                    userData: responseData, ...responseData,
                })


            })
            .catch((err) => {
                this.setState({ isLoader: false });
            });
    }


    showCheckboxTab = (e) => {
        this.setState({
            showCheckboxTab: true
        })
    }

    // sign up submit
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ isLoading: true, })
        let req = {
            manageCategoriesId: this.state.selectedId,
            requestUserId: this.props.match.params.id || "",
        }

        _Api(Url.EDIT_COMPANY.method, Url.EDIT_COMPANY.url, req)
            .then(resp => {
                this.setState({ isLoading: false })
                this.props.history.goBack()
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
            })


    }


    checkInputCheck = (id, data) => {
        let arr = this.state.selectedId.filter(item => (item.id == id && item?.sectorId.indexOf(data) != -1) ? true : false)
        return arr.length ? true : false
    }

    onSubCheck = (data, item, ind) => {
        let oldArr = this.state.selectedId
        let flag = oldArr.findIndex(itemIn => itemIn?.id == data._id)
        if (flag == -1) {
            let obj = {
                id: data._id,
                sectorId: [item._id]
            }
            oldArr.push(obj)
            this.setState({ selectedId: oldArr })
        }
        else {

            let arr = this.state.selectedId[flag].sectorId
            if (arr.indexOf(item._id) == -1)
                this.state.selectedId[flag].sectorId.push(item._id)
            else {
                let arr = this.state.selectedId[flag].sectorId.filter(itemm => itemm != item._id)
                this.state.selectedId[flag].sectorId = arr

            }

        }

        this.setState({ toggle: !this.state.toggle, message: '' })
    }


    finalCropedImage = (categoryImgPrev, categoryImg, visibleVal) => {
        this.setState({
            visibleVal,
            isCropper: false,
            profilePicPreview: categoryImgPrev,
            productImage: categoryImg

        })
    }




    render() {
        console.log("shdgjkssssssss", this.state.selectedId)

        let { productName,  description, productNameError  } = this.state

        return (
            <section className="brand-home-banner-bg">
                {this.state.isLoader && <Loader />}

                <div className="container-fluid  manage-company edit-companysector">
                    <a > <button onClick={() => this.props.history.goBack()} className="btn btn-light"><i class="fa fa-arrow-left" aria-hidden="true"></i>Back</button></a>
                   










                   
                   
                   
                   
                   
                   
                   
                   
                   
                   
                   
                   
                   
                   
                   
                   
                   
                   
                   
                   
                   
                   
                    <div className="row">
                        <div className="col-sm-8 offset-sm-2 col-md-6 offset-md-3">

                            <>

                    <div className="form-group">
                            <div className="header-text">
                                <h4>Add Product</h4>
                            </div>
                            <level>Product Image </level>
                            <label className="mb-0 mt-3"><span className="popupdimention">
                                {/* (.jpeg, .png, .jpg, Image dimension: 270 * 300 )  */}
                            </span></label>
                            <div className="upload-wrapper product mt-1" onClick={() => this.setState({ isCropper: true })}>
                                <label className="upload-photo-wrapper upload-container-pic rectangle">



                                    {this.state.profilePicPreview ? <span> <img className="img-fluid" src={this.state.profilePicPreview} /></span>
                                        :
                                        <>    <span> <img className="img-fluid" src={require('../../../assets/images/placeholder.png')} />
                                            <div class="coverImage_placholder logo" ><h6>Image should be 250X300 <br />Max size 2MB / JPG format</h6></div>
                                        </span>
                                        </>
                                    }

                                </label>

                            </div>
                            <div className="form-group">
                                <level>Product Name</level>
                                <input type="text"
                                    className="form-control"
                                    placeholder="Product Name"
                                    maxLength={64}
                                    autoComplete="off"
                                    name="productName"
                                    value={productName}
                                    onChange={this.productOnChnage}
                                />
                                <InlineError
                                    message={productNameError}
                                />

                            </div>
                            <div className="form-group">
                                <level>Description</level>
                                <textarea type="text"
                                    className="form-control textarea"
                                    placeholder="Description"
                                    rows="5"
                                    maxLength={500}
                                    autoComplete="off"
                                    name="description"
                                    value={description}
                                    onChange={this.productOnChnage}
                                />
                                {/* <InlineError
                                    message={descriptionError}
                                /> */}
                            </div>
                            </div>
                            
                            </>





















                            <div className=" pt-5 text-center">
                                <h1 className="home-heading pos-relative mb-5">
                                    Choose Sectors & Categories
                         <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                </h1>
                                {/* <div className="pos-relative">
                                   <h2 className="heighlighter">Categories Selected ({this.state.selectedId?.length}/20)</h2>
                                </div> */}

                            </div>
                            <form class="rounded-textbox-form mt-5">
                                <InlineError message={this.state.message} />

                                {
                                    this.state?.list?.map((item, index) => {
                                        return (
                                            <>
                                                <div className="checkbox-tab-parent">
                                                    <div className="checkbox-tab" onClick={(e) => this.showCheckboxTab()}>
                                                        <div className="check_wrapper">
                                                            <input onChange={() => this.handleSelect(item?._id, item)} type="checkbox" value={item._id} checked={this.state.selectedId.findIndex(item2 => item2?.id == item._id) != -1} />
                                                            <span />
                                                        </div>
                                                        {item.name}
                                                    </div>

                                                    <div className="checkbox_tab-body">
                                                        {
                                                            item?.sectorData?.map((itemIn, ind) => {
                                                                return (
                                                                    <div className="form-group form-control select_field cursor-point">
                                                                        <div className="check_wrapper circle-checkbox ">

                                                                            <input onChange={() => this.onSubCheck(item, itemIn, ind)} checked type="checkbox" checked={this.checkInputCheck(item?._id, itemIn._id)} />

                                                                            <span /></div>
                                                                        <span>{itemIn?.name}</span>
                                                                    </div>

                                                                )
                                                            })
                                                        }




                                                    </div>
                                                </div>

                                            </>
                                        )
                                    })
                                }
                                {/* </div> */}
                            </form>

                            <div className="d-flex justify-content-center mt-4 mb-5">
                                <button type="button" class="btn btn-warning btn-sm changepass mr-3" >Cancel</button>
                                <button type="button" onClick={this.handleSubmit} class="btn btn-warning btn-sm" >Save</button>
                            </div>
                        </div>
                    </div>

                </div>
                <Footer />

                {this.state.isCropper ?
                    <Croper aspect={2.5 / 3}
                        // onImageCrop={img => this.setState({ media: img })}
                        imgPrev={'2.5-3.jpg'}
                        finalCropedImage={(categoryImgPrev, categoryImg, visibleVal) => this.finalCropedImage(categoryImgPrev, categoryImg, visibleVal)} visibleVal={this.state.isCropper}
                        onClose={() => this.setState({ isCropper: false })}
                    />
                    : ""}

            </section >
        )
    }
}
function mapStateToProps(state) {
    // console.log("abcdefghhfiekjnmkwejnfnw", state)
    return {
        ...state
    };
}

export default connect(mapStateToProps)(EditSectorsCategories);