import React, { Component } from 'react';


import { Carousel, Tooltip } from 'antd';
import { Delete, Pencil,Brands,NewBadges } from "../../SvgIcon";
import { Link } from "react-router-dom";
import { CmBtn } from "../common/Button";
import { _Api } from '../../services/Api';
import Url from '../../services/BaseUrl';
import { notify } from '../Toaster';
import { connect } from "react-redux";
import { getWishList } from '../../redux/action';
import { Spinner } from "react-bootstrap"
import { getBrandHome } from "../../redux/action/Get";
import LazyLoad from 'react-lazy-load';


class VisitedProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.carousel = React.createRef();
    }


    next = () => {
        this.carousel.next();
    }
    previous = () => {
        this.carousel.prev();
    }

    handleError(err) {
        let errorMessage = err.error && err.error.responseMessage || err.message;
        // notify("err", errorMessage);
    }



    addToWishList = (id, api) => {
        let stateName = `wish${id}`;
        let scope = this.props.scope;
        this.setState({ [`isWishlistLoader${id}`]: true, [stateName]: !this.state[stateName] });
        _Api(Url[api].method, Url[api].url, { productId: id })
            .then(resp => {
                this.props.dispatch(getWishList());
                // this.props.dispatch(getBrandHome())
                this.props.productListData.forEach(item => {
                    if (item?.productData?._id == id) {
                        item.wishListData = !item.wishListData
                    }
                });
                // scope.getList("FEATURED_PRODUCT_LIST", "productListData", "mostVisitedProduct");
                setTimeout(() => {
                    this.setState({ [`isWishlistLoader${id}`]: false });

                }, 600);
            }).catch((err) => {
                this.handleError(err);
            })
    }
    render() {
        let productListData = this.props.productListData;
        let scope = this.props.scope;


        const settings = {
            infinite: true,
            speed: 500,
            slidesToShow: this.props.productListData?.length < 5 ? 1 : 6,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 1500,
            dots: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                    breakpoint: 1700,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        dots: true
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true
                    }
                }
            ]
        }
        console.log("sakjhgbjdsa", productListData)


        return (
            <div className="carousel-wrapper featured-brands showcase">
                <div className="row mb-3">
                    {

                        productListData?.filter(item => item?.productData?.productName, index => index < 12).map(item =>
                            // col-xl-2 col-lg-3 col-md-4 col-sm-6
                            <div className=" product-showcase-devide six-div new-product">

                                <div className="card brand-card mb-5">
                                    <Link to={`/web-job-details/${item?.productData?._id}`}>
                                        {/* <LazyLoad> */}
                                            <img className="img-fluid" src={item?.productData?.image ? item?.productData?.image : require('../../assets/images/placeholder.png')} />
                                            {/* </LazyLoad> */}
                                    </Link>
                                    <hr />
                                    {item?.productData?.isNewProduct == 1 && <div className="newBadge">
                                        <p> New<span className="traingle"><NewBadges /></span></p>
                                    </div>}
                                    <div className="top-right">
                                        {
                                            <div className={(item?.wishListData) && "blue-circle red-circle" || "blue-circle"} onClick={() => this.addToWishList(item?.productData?._id, item?.wishListData && "REMOVE_WISHLIST" || "ADD_WISHLIST")}>
                                                {this.state[`isWishlistLoader${item?.productData?._id}`] &&
                                                    <Spinner className="text-center" size="xl" animation="border" variant="primary" /> || <i className="fa fa-heart-o" ></i>}

                                            </div>
                                        }
                                    </div>
                                    <div className="action_group text-center">
                                    <div className="w-100">
                                        <Tooltip placement="top" title={item?.productData.productName}>
                                            <span className="wrap-p2">{item?.productData.productName} <br /></span>
                                        </Tooltip>
                                            <hr className="w-100 mb-2"/>
                                            <Tooltip placement="top" title={item?.brandId?.companyName}>
                                                {item?.brandId?.companyName &&
                                                <h6 className="wrap-p1 w-100"><Link to={`/business/${item?.brandId?._id}`} className="ml-0"><i><Brands /></i>{item?.brandId?.companyName}</Link></h6> 
                                                } 
                                            </Tooltip>
                                    </div>  
                                    </div>
                                </div>

                            </div>

                        )
                    }
                </div>
            </div>





        )

    }
}
function mapStateToProps(state) {
    return {
        wishList: state.wishList
    };
}

export default connect(mapStateToProps)(VisitedProducts);
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div>
            <i className="fa fa-long-arrow-right" onClick={this.next} />

        </div>

    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div>
            <i className="fa fa-long-arrow-right" onClick={this.next} />

        </div>

    );
}


