import React, { Component } from 'react';
import { Badge } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Clock, FooterLocation, MapMarker, VideoCallIcn , NoMediaICon, Chat, ReplayIcon} from '../../../SvgIcon';

// import Calendar from 'react-awesome-calendar';
import { getTimeZone, getTimeFormate } from "../../../utils/DateZone"

import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Delete, Pencil } from '../../../components/common/SvgIcon';
// import globalize from 'globalize'


const Header = () => {
    return (
        <div></div>
    )
}

const localizer = momentLocalizer(moment)

function isSameDay(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() &&
        d1.getDate() === d2.getDate() &&
        d1.getMonth() === d2.getMonth();
}

const convertDateTimeToDate = (datetime, timeZoneName) => {
    const m = moment.tz(datetime, timeZoneName)
    return new Date(m.year(), m.month(), m.date(), m.hour(), m.minute(), 0)
}


class DiaryCalendar extends Component {
    static getDerivedStateFromProps(nextProps, prevState) {
        // console.log("lfkjknkfmsjfmksdlnfjksdm,", nextProps)
        if (prevState.flag  == false && nextProps.isEdit == true) {
            let {speakerviewData} = nextProps
                return {
                    speakerName: `${speakerviewData.firstName} ${speakerviewData.lastName}`,
                    email: speakerviewData.email,
                    profilePicPreview: speakerviewData.image,
                    speakerDesignation: speakerviewData.jobTitleOther,
                    phoneNumber: speakerviewData.phoneNumber,
                    description: speakerviewData.about,
                    spekerProfilePic: speakerviewData.profileImage,
                    flag:true
                }

            }
        return null;
    }



    constructor() {
        super()
        this.state = {
            events: [],
            showMeetings: []
        }
    }



    componentDidMount() {
       this.setData()
    }


    setData = ()=>{
         // console.log("pankajyadav", this.props.allTimeTableData)
         this.setState({
            events: this.props.allTimeTableData?.map((item, index) => {
                if (item.eventType == 1)
                    return ({
                        id: index,
                        title: item.name,
                        isSelected:true,
                        start: convertDateTimeToDate(item.startTime, moment.tz.guess()),
                        end: convertDateTimeToDate(item.endTime, moment.tz.guess()),



                    }
                    )
                else
                    return ({
                        id: index,
                        title: item?.sessionId?.sessionName,
                        start: convertDateTimeToDate(item?.sessionId?.startDate, moment.tz.guess()),
                        end: convertDateTimeToDate(item?.sessionId?.startDate, moment.tz.guess()),
                     

                    })

            })
        })

        // let year = new Date(this.props.loading.start_Date * 1000).getFullYear()
        // let month = new Date(this.props.loading.start_Date * 1000).getMonth()
        // let day = new Date(this.props.loading.start_Date * 1000).getDate()
        // this.getData({year,month,day})
        this.setState({
            showMeetings:this.props.allTimeTableData
        })
    }


    getData = (data) => {

        const { year, month, day } = data

        console.log("hgdjs", data)
        this.setState({ showMeetings: this.props.allTimeTableData.filter((item, index) => item.eventType == 1 ?  isSameDay(new Date(item.startDate), new Date(year, month, day)) : isSameDay(new Date(item?.sessionId?.startDate), new Date(year, month, day)) )      })





    }


      handleOnView = (data)=>{
          console.log("datee", data)
          if(data){
            let year = new Date(data).getFullYear()
            let month = new Date(data).getMonth()
            let day = new Date(data).getDate()
            this.getData({year,month,day})
          }

      }

      handleOnView1 = ({start})=>{
        console.log("datee", start)
        if(start){
          let year = new Date(start).getFullYear()
          let month = new Date(start).getMonth()
          let day = new Date(start).getDate()
          this.getData({year,month,day})
        }

    }


   



    render() {
        // const { events } = this.state
        console.log("ndskjfn", this.props.loading.start_Date)

        let events =  this.props.allTimeTableData?.map((item, index) => {
            if (item.eventType == 1)
                return ({
                    id: index,
                    title: item.name,
                    isSelected:true,
                    start: convertDateTimeToDate(item.startTime, moment.tz.guess()),
                    end: convertDateTimeToDate(item.endTime, moment.tz.guess()),



                }
                )
            else
                return ({
                    id: index,
                    title: item?.sessionId?.sessionName,
                    start: convertDateTimeToDate(item?.sessionId?.startDate, moment.tz.guess()),
                    end: convertDateTimeToDate(item?.sessionId?.startDate, moment.tz.guess()),
                 

                })

        })

        // this.setState({
        //     events: this.props.allTimeTableData?.map((item, index) => {
        //         if (item.eventType == 1)
        //             return ({
        //                 id: index,
        //                 title: item.name,
        //                 isSelected:true,
        //                 start: convertDateTimeToDate(item.startTime, moment.tz.guess()),
        //                 end: convertDateTimeToDate(item.endTime, moment.tz.guess()),



        //             }
        //             )
        //         else
        //             return ({
        //                 id: index,
        //                 title: item?.sessionId?.sessionName,
        //                 start: convertDateTimeToDate(item?.sessionId?.startDate, moment.tz.guess()),
        //                 end: convertDateTimeToDate(item?.sessionId?.startDate, moment.tz.guess()),
                     

        //             })

        //     })
        // })









        return (
            <>
                <div className="diary-calendar-view-wrp py-3 py-md-0">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="dry-clnd-lft-wrp">
                                <div className="cstm-tbl-calendar">
                                    <Calendar
                                       view='week'
                                       views={['week']}
                                        
                                        localizer={localizer}
                                        events={events}
                                        startAccessor="start"
                                        endAccessor="end"
                                        style={{ height: 800 }}                                                                        
                                        onNavigate={this.handleOnView}
                                        onSelectEvent={this.handleOnView1}
                                        defaultDate={new Date(this.props.loading.start_Date * 1000)}
                                        toolbar={true}
                                        className="calnder-view-wrps"

                                    />

                                </div>
                            </div>

                            {/* Calendar Mobile View */}
                            <div className="mobileView-calendar-wrap mt-3 d-none d-md-none">
                                {/* <Calendar
                                    events={events}
                                /> */}
                                {/* <div className="mbv-clnd-topbar">
                                    <ul className="mb-0 d-flex list-unstyled p-0">
                                        <li>
                                            <span className="d-block">Sun</span>
                                            <strong>7</strong>
                                        </li>
                                        <li className="active">
                                            <span className="d-block">Mon</span>
                                            <strong>8</strong>
                                        </li>
                                        <li>
                                            <span className="d-block">Tue</span>
                                            <strong>9</strong>
                                        </li>
                                        <li>
                                            <span className="d-block">Wed</span>
                                            <strong>10</strong>
                                        </li>
                                        <li>
                                            <span className="d-block">Thu</span>
                                            <strong>11</strong>
                                        </li>
                                        <li>
                                            <span className="d-block">Fri</span>
                                            <strong>12</strong>
                                        </li>
                                        <li>
                                            <span className="d-block">Sut</span>
                                            <strong>13</strong>
                                        </li>
                                    </ul>
                                </div> */}
                                <div className="mbv-clnd-body pt-3">
                                    <div className="mbv-clnd-list">

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div className="dry-clnd-rgt-sidebar pt-3 pt-md-0">
                                <div className="dry-top-sidebar d-flex align-items-center">
                                    <h4>Meetings</h4>
                                    {/* <Link to="" className="ml-auto">View All </Link> */}
                                </div>
                                <div className="dry-clnd-rgt-body scrll-evnt-wrp">

                                    {
                                        this.props.allTimeTableData?.map((item, index) => {
                                            // console.log("hbjakSAL", item)
                                            
                                            let sameUser = item?.userData?._id == localStorage.getItem("id")
                                            if (item.eventType == 1)
                                                return (
                                                    <div className="drysidebar-mtng-card">
                                                        <div className="meeting-dry-crd-top">
                                                            <div className="mtng-dry-icn">
                                                                <MapMarker />
                                                            </div>
                                                            <div className="mtng-dry-cnt">
                                                                <h4 style={{ cursor: 'pointer' }} onClick={() => this.props.showAcceptModal(item)}>{item?.name}</h4>
                                                                <ul className="list-unstyled mb-0">
                                                                    <li className="tmdry">
                                                                        <Clock />
                                                                        <span>{getTimeFormate(item?.startDate)}, {getTimeZone(item?.startTime, moment.tz.guess())}</span>
                                                                    </li>
                                                                    {item?.location && <li>
                                                                        <FooterLocation />
                                                                        <span>{item?.location}</span>
                                                                    </li>}
                                                                </ul>
                                                                
                                                            </div>
                                                            <div className="mtng-dry-cnt-right-button">
                                                                
                                                            {<button type="button"  title="Chat" onClick={() => this.props.selectUser(item?.senderData?._id == localStorage.getItem("id") ?  item?.userData: item?.senderData)} className="btn mr-1">
                                                            <Chat />
                                                                </button>}

                                                                {item?.senderData?._id == localStorage.getItem("id") &&   <button type="button"  title="Reshdule" onClick={()=> this.props.selectedMettingData2(item)} className="btn mr-1 svg-20">
                                                                <ReplayIcon />
                                                                </button>}


                                                               {item?.senderData?._id != localStorage.getItem("id") &&   <button  title="Edit" type="button" onClick={() => this.props.selectedMettingData(item)} className="btn mr-1">
                                                                    <Pencil />
                                                                </button>}
                                                                <button type="button"  title="Delete" onClick={()=>this.props.showDeleteConfirm(item?._id)} className="btn">
                                                                    <Delete />
                                                                </button>
                                                            </div>
                                                            <div className="dry-mtng-invt-users w-100 pl-md-4">
                                                                    <div className="dry-usLeft">
                                                                        <div className="dryUsers" title={`${item?.senderData?.firstName} ${item?.senderData?.lastName}`}>
                                                                            <img src={item?.senderData?.image ? item?.senderData?.image : require("../../../assets/images/login_user.png")} />
                                                                        </div>
                                                                        <div className="dryUsers" title={`${item?.userData?.firstName} ${item?.userData?.lastName}`}>
                                                                            <img src={item?.userData?.image ? item?.userData?.image : require("../../../assets/images/login_user.png")} />
                                                                        </div>
                                                                    </div>

                                                                    {item.meetingStatus == 1 && <div className="invt-acceptd text-success">
                                                                        <i class="fa fa-check wtic"></i>
                                                                        <span>Accepted</span>
                                                                    </div>
                                                                    }
                                                                    {item.meetingStatus == 0 && sameUser && <div className="invt-acceptd text-primary">
                                                                        <i class="fa fa-clock-o wtic"></i>
                                                                        <span>Pending</span>
                                                                    </div>
                                                                    }
                                                                    {item.meetingStatus == 3 && sameUser && <div className="invt-acceptd text-danger">
                                                                        <i class="fa fa-times wtic"></i>
                                                                        <span>Cancel</span>
                                                                    </div>
                                                                    }
                                                                    {item.meetingStatus == 2 && <div className="invt-acceptd text-warning">
                                                                        <i class="fa fa-check wtic"></i>
                                                                        <span>Declined</span>
                                                                    </div>
                                                                    }
                                                                </div>
                                                        </div>
                                                        {item?.senderData?._id == localStorage.getItem("id") && <div className="meeting-dry-crd-btm">
                                                            {item.meetingStatus == 0 && <ul className="mb-0 list-unstyled d-flex pb-0 justify-content-center">
                                                                <li>
                                                                    <button type="button" onClick={() => this.props.handleAcceptReject(item._id, 1)} className="btn btn-success"><i class="fa fa-check wtic"></i>Accept</button>
                                                                </li>
                                                                <li>
                                                                    <button type="button" onClick={() => this.props.onReject(item._id, 2)} className="btn btn-danger"><i class="fa fa-times wtic"></i>Decline</button>
                                                                </li>
                                                            </ul>}
                                                        </div>}

                                                    </div>


                                                )
                                            else {
                                                let speaker = item?.speakerId ? item?.speakerId : []
                                                return (
                                                    <div className="drysidebar-mtng-card meetingCard-whatKnow">
                                                        <div className="meeting-dry-crd-top">
                                                            <div className="mtng-dry-icn">
                                                                <VideoCallIcn />
                                                            </div>
                                                            <div className="mtng-dry-cnt">
                                                                <h4>{item?.sessionId?.sessionName}</h4>
                                                                <ul className="list-unstyled mb-0">
                                                                    <li className="tmdry">
                                                                        <Clock />
                                                                        <span>{getTimeFormate(item?.startDate)}, {getTimeZone(item?.startDate, moment.tz.guess())}</span>
                                                                    </li>
                                                                </ul>
                                                                <div className="dry-mtng-invt-users">
                                                                    <div className="dry-usLeft">
                                                                        {
                                                                            speaker.map((speaker, ind) => {
                                                                                return (

                                                                                    <div className="dryUsers">
                                                                                        {speaker?.image ?
                                                                                            <img src={speaker?.image} />
                                                                                            :
                                                                                            <img src={require('../../../assets/images/login_user.png')} />
                                                                                        }

                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }


                                                                    </div>
                                                                </div>
                                                                <div className="know-Metngbtn">
                                                                    <Link to={`/seminar/${item?.stageId?.conferenceId}`} class="btn btn-danger mr-1" >View Conference</Link>
                                                                    {item?.videoUrl &&
                                                                        <Link onClick={() => this.props.handleJoinReplay(item?.stageId?.conferenceId, item?.ssId, item?.stageId?._id)} to={`/seminar/${item?.stageId?.conferenceId}/${'table'}/${item?.ssId}`} className="btn btn-blue">Replay</Link>}

                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                )
                                            }
                                        })



                                    }
                                    {this.state.showMeetings.length == 0 ?
                                    
                                    <div className="w-100 text-center row nodata m-auto">
                                    <div className="m-auto">
                                        <NoMediaICon />

                                        <h2>No meetings for the day</h2>
                                    </div>
                                </div>
                                    : ''}
                                    {/* </div> */}


                                    {/* <div className="drysidebar-mtng-card accepted-invinted">
                                        <div className="meeting-dry-crd-top">
                                            <div className="mtng-dry-icn">
                                                <MapMarker />
                                            </div>
                                            <div className="mtng-dry-cnt">
                                                <h4>Hair and beauty Meeting</h4>
                                                <ul className="list-unstyled mb-0">
                                                    <li className="tmdry">
                                                        <Clock />
                                                        <span>02 Dec, 09:00 PM</span>
                                                    </li>
                                                    <li>
                                                        <FooterLocation />
                                                        <span>Stand number 5</span>
                                                    </li>
                                                </ul>
                                                <div className="dry-mtng-invt-users">
                                                    <div className="dry-usLeft">

                                                        <div className="dryUsers">
                                                            <img src={require("../../../assets/images/networking.png")} />
                                                        </div>
                                                        <div className="dryUsers">
                                                            <img src={require("../../../assets/images/Mark Hayes.png")} />
                                                        </div>
                                                    </div>
                                                    <div className="invt-acceptd">
                                                        <i class="fa fa-check wtic"></i>
                                                        <span>Accepted</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div> */}


                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </>
        )
    }
}

export default DiaryCalendar