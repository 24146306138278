import React, { Component } from 'react';

import WebHeader from '../../layout/WebHeader';
import { Carousel, Tooltip } from 'antd';
import { CommunityManagement, Hert, Product, TwoUsers, ProfileUser, Brouchure, Media, PlayIcon, NoMediaICon, Brands } from '../../../SvgIcon';
import { BtnRed } from "../../../components/common/Button";
import VisitedProductCarousel from "../../../components/carousel/VisitedProductCarousel";
import RecentContacted from "../../../components/carousel/RecentContacted";
import Footer from "../../layout/Footer"
import CommingSoonLayer from "../../../components/common/CommingSoonLayer";
import { Link } from "react-router-dom"

import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';

import VisitedProducts from "../../../components/carousel/VisitedProducts";
import { connect } from "react-redux";
import Thankyou from "../../home/HomeCommingSoon"

class WebDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isCommingSoon: true,
            countInfo: [],
            recentConnections: [],
            productListData: []
        }
    }


    componentDidMount() {
        this.getInfo()
        this.getRecentConnections()
        this.getMostVistedProduct()
        this.props.dispatch({ type: 'PATH_ADD', payload: this.props.match })
    }


    getInfo = () => {
        _Api(Url.GET_BRAND_DASHBOARD.method, Url.GET_BRAND_DASHBOARD.url, '')
            .then(resp => {
                console.log("kdjagsffdghjks", resp.responseData.result)

                this.setState({
                    countInfo: resp.responseData.result ? resp.responseData.result : []
                })

            }).catch(err => {
                this.setState({ isLoader: false })
            })
    }


    getRecentConnections = () => {
        _Api(Url.MOST_RECENT_CONNECTION.method, Url.MOST_RECENT_CONNECTION.url, '')
            .then(resp => {
                console.log("mmmmmmmmmmmmmm", resp.responseData.result?.data)

                this.setState({
                    recentConnections: resp.responseData.result?.data
                })

            }).catch(err => {
                this.setState({ isLoader: false })
            })
    }


    getMostVistedProduct = () => {
        _Api(Url.MOST_VISTITED_PRODUCT.method, Url.MOST_VISTITED_PRODUCT.url, '')
            .then(resp => {
                console.log("xsaxasxsax", resp.responseData.result?.data)

                this.setState({
                    productListData: resp.responseData.result?.data
                })

            }).catch(err => {
                this.setState({ isLoader: false })
            })
    }

    render() {

        const { countInfo, recentConnections, productListData } = this.state

        const isShow = localStorage.getItem("showAll") == "true"


        return (

            <section className="brand-home-banner-bg">
                {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
                {/*     <ChatBox history={this.props.history} match={this.props.match} />*/}
                <div className={this.state.isCommingSoon ? "cm-section container-fluid brandhome pos-relative" : "cm-section container-fluid"}>
                    {/* <div  className={this.state.isCommingSoon?"container-fluid brandhome pos-relative":"container-fluid brandhome"}> */}
                    {/*{this.state.isCommingSoon && <CommingSoonLayer />}*/}
                    <div className="stack-wrapper">
                        <Link to="/document-download" className="stack gray cursor-point">
                            <div className="icon">
                                <Brouchure />
                            </div>
                            <h5>Document Downloaded</h5>
                            <h3>{countInfo[1]?.record?.length}</h3>
                            <div className="graph">
                                <img src={require('../../../assets/images/graph_gray.png')} className="img-fluid" />
                            </div>
                        </Link>
                        <Link to="/community" className="stack red cursor-point">
                            <div className="icon">
                                <CommunityManagement />
                            </div>
                            <h5>My Connections</h5>
                            <h3>{countInfo[3]?.totalCount}</h3>
                            <div className="graph">
                                <img src={require('../../../assets/images/graph_red.png')} className="img-fluid" />
                            </div>
                        </Link>
                        <Link to="/job-viewed" className="stack blue cursor-point">

                            <div className="icon">
                                <Product />
                            </div>
                            <h5>Jobs Viewed</h5>
                            <h3>{countInfo[4]?.totalCount}</h3>
                            <div className="graph">
                                <img src={require('../../../assets/images/graph_blue.png')} className="img-fluid" />
                            </div>
                        </Link>
                        <Link to="/training-viewed" className="stack blue cursor-point">

                            <div className="icon">
                                <TwoUsers />
                            </div>
                            <h5>Training Viewed</h5>
                            <h3>{countInfo[5]?.totalCount}</h3>
                            <div className="graph">
                                <img src={require('../../../assets/images/graph_blue.png')} className="img-fluid" />
                            </div>
                        </Link>
                        <Link to="/media-viewed" className="stack skyblue cursor-point">

                            <div className="icon">
                                <PlayIcon />
                            </div>
                            <h5>Media Viewed</h5>
                            <h3>{countInfo[0]?.totalCount}</h3>
                            <div className="graph">
                                <img src={require('../../../assets/images/graph_skyblue.png')} className="img-fluid" />
                            </div>
                        </Link>

                        {/* <div className="stack purpal">
                            <div className="icon">
                                <CommunityManagement />
                            </div>
                            <h5>Staff</h5>
                            <h3>{countInfo[2]?.totalCount}</h3>
                            <div className="graph">
                                <img src={require('../../../assets/images/graph_purpal.png')} className="img-fluid" />
                            </div>
                        </div> */}



                    </div>
                    {/* <div className="graph-wrapper pt-5 text-center">
                        <h1 className="home-heading pos-relative mb-5">
                           Visitors per Day View
                           <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                            </h1>
                            <div className="search_wrapper  mr-2 m-auto ">
                                <i className="fa fa-search" />
                                <input type="text"
                                    placeholder="Enter Your Product Name..."
                                    name='searchKey'
                                 
                                />
                            </div>
                            <div className="graph-container">

                            </div>

                   </div> */}

                    {<div className="cm-wrapper featured-brands showcase">
                        <div className="header-text">
                            <h1 className="title">Most Visited Jobs</h1>
                            {productListData.length > 5 && <BtnRed to="web-view-job" text="View All" />}
                        </div>
                        <div className=" row mb-3">
                            {productListData.length > 0 &&
                                productListData.map((item, i) =>
                                    (

                                        <div className="six-div" >
                                            <div className="card brand-card new-product mb-5">
                                                <Link to={`/web-job-details/${item?.productData?._id}`} ><img className="img-fluid" src={item?.productData?.image || require('../../../assets/images/placeholder.png')} /></Link>



                                                <div className="action_group text-center">
                                                    <div className="w-100">
                                                        <Tooltip placement="top" title={item?.productData?.productName}>
                                                            <span className="wrap-p2">{item?.productData?.productName} <br /></span>
                                                        </Tooltip>
                                                        <hr className="w-100 mb-2" />
                                                        <Tooltip placement="bottom" title={item?.companyName}>
                                                            {item?.companyName &&
                                                                <Link className="wrap-p2 w-100"><i><Brands /></i>{item?.companyName}</Link>
                                                            }
                                                        </Tooltip>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>
                                    ))
                                ||
                                <div className="w-100 text-center row nodata m-auto">
                                    <div className="m-auto">
                                        <NoMediaICon />

                                        <h2>No Data Found</h2>
                                    </div>                    </div>
                            }
                        </div>
                    </div>}


                    {/* { <div className="slider-container">
                        <h1 className="home-heading pos-relative  text-center">
                            Recent Contacted
                                        <span className="heading-after-border heading-after-border-center"><span
                                className="heart"><Hert /></span></span>
                        </h1>

                       


                        <RecentContacted data = {recentConnections} />
                    </div>} */}

                </div>

                <Footer />
            </section>




        )
    }
}
// export default WebDashboard;
function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(WebDashboard);