import React, { Component } from 'react'
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';
import { Link } from "react-router-dom";
import '../../../assets/css/adManagement.css'
import { AdManagement, Filter, Pencil, Delete, UploadVideo } from '../../../SvgIcon';
import { DatePicker, TimePicker, Select } from 'antd';
import Croper from "../../../components/Croper";

// components
import {

    MyAlert,
    MySpinner,
    AdLoader
} from "../../../components/index"
import InlineError from '../../../components/InlineError'
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';

const { Option } = Select;

class AddNewAd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            adName: '',
            adNameError: '',
            adCategory: '',
            adCategoryError: '',
            media: null,
            adLinkUrl: '',
            brand: '',
            brandError: '',
            email: '',
            emailError: '',
            phone: '',
            phoneError: '',
            isLoading: false,
            isEdit: false,
            redirectionUrl: '',
            redirectionUrlError:'',
            brandListData: [],
            imageError:'',
            isCropper:false

        }

    }



    //exhibitaion onhange
    handleOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, adNameError: '', adCategoryError: '', brandError: '', emailError: '', phoneError: '',redirectionUrlError:'', imageError:'' })
    }

    handleOnChangeSelect = (type, e) => {
        this.setState({ [type]: e,redirectionUrlError:'' })
    }



    // getCategory(){
    //     let params = {
    //         page: 1,
    //         limit: 10,
    //         searchKey: this.state.searchKey,
    //         sortField:'categoryTitle',
    //         sortType:1,
    //         parentCategoryId:this.state.parentCategoryId,
    //         categoryType: '1',
    //         level: '1'
    //     }
    //     _Api(Url.CATEGORY_LIST.method, Url.CATEGORY_LIST.url, params )
    //         .then(resp => {
    //             console.log('responseData', resp.responseData);
    //             this.setState({
    //                 categoryList:resp.responseData.result,
    //                 // totalCount:resp.responseData.totalCount
    //             })
    //         }).catch(err => {
    //         this.setState({ isLoader: false })
    //         if (err.error)
    //             notify("err", err.error.responseMessage)
    //         else if (err)
    //             // notify("err", err.message)
    //         this.setState({ isLoader: false })
    //     })
    // }


    handleImageUpload = (event) => {
        this.setState({imageError: ''})
        console.log(event, "eventeventevent", event.target.files[0])
        let reader = new FileReader();
        let file = event.target.files[0];
        let { valiDate } = this.state;
        // reader.addEventListener("load", () => {
        //     this.setState({ profilePicPreview: reader.result }, function () {
        //     })
        // }, false);
        if (file) {
            reader.readAsDataURL(file)
        }
        if (file) {
            console.log(event, "media1", event.target.files[0])
            if (file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png' ) {
                reader.addEventListener("load", () => {
                    this.setState({ profilePicPreview: reader.result }, function () {
                    })
                }, false);
                console.log("media2", event.target.files[0], file.size)
                if (file.size > 2000000) {
                    console.log("lkjhgfdsdfghjklkjhgfghjkmnbvccvbnm>>>>>>>>>>>>>>>>>>")
                    valiDate = false;
                    console.log(event, "media", event.target.files[0])
                    this.setState({
                        media: event.target.files[0],
                        profilePicErrorMsg: 'Profile pic size should be less than 5Mb.'
                    });
                } else {
                    console.log("media3hhhjshs", event.target.files[0], file.size)
                    this.setState({
                        media: event.target.files[0],
                        mediaError: ''
                    });
                    // alert("wrong file")
                }
            } else {
                valiDate = false;
                this.setState({ mediaError: 'File type not supported.' })
            }
        }
    }

    submitAd = () => {
        if (this.state.isEdit)
            this.EditAdd()
        else
            this.saveAd()
    }

    saveAd = () => {
        let { adName,
            adCategory,
            media,
            adLinkUrl,
            brand,
            email,
            phone,
            redirectionUrl
        } = this.state
        const UrlRegx = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
        console.log("dkjhgfshd", UrlRegx.test(redirectionUrl), redirectionUrl !='' && UrlRegx.test(redirectionUrl), redirectionUrl)
        try {
            if (adName == '')
                this.setState({ adNameError: '*Please enter ad name.' })
              else   if (redirectionUrl !='' && !UrlRegx.test(redirectionUrl))
                this.setState({ redirectionUrlError: '*Please enter a valid url' })
                else   if (this.state.profilePicPreview == undefined || this.state.profilePicPreview  == "" )               
                {
                    this.setState({ imageError: '*Please select  image' })
                    
                }
            else {
                this.setState({ isLoading: true })
                let formData = new FormData()
                formData.append("adName", adName)
                formData.append("redirectionUrl", redirectionUrl)
                formData.append("adLinkUrl", adLinkUrl)
                formData.append("brand", brand)
                // formData.append("email", email)
                // formData.append("phone", phone)
                formData.append("media", media)
                _Api(Url.ADD_Ad.method, Url.ADD_Ad.url, formData)
                    .then(resp => {
                        this.setState({ isLoading: false })
                        this.props.history.goBack()
                    }).catch(err => {
                        this.setState({ isLoading: false })
                        if (err.error)
                            notify("err", err.error.responseMessage)
                        // else if (err)
                            // notify("err", err.message)
                    })
            }



        } catch  { }

    }

    componentDidMount() {
        if (this.props.match.params.adId)
            this.getParticularAd()
        // this.getBrandList()
    }





    // getBrandList = () => {
    //     this.setState({ isLoader: true })
    //     let { page, limit, search } = this.state
    //     let req = {
    //         page: page ? page : 1,
    //         limit: 10,
    //         // search: search.toString()
    //     }

    //     _Api(Url.GET_USER_LIST.method, Url.GET_USER_LIST.url, '', `?page=${req.page}&limit=${req.limit}`)
    //         .then(resp => {
    //             console.log('respexListtttttttttttt', resp.responseData.result)
    //             this.setState({ brandListData: resp.responseData?.result?.data, isLoader: false })
    //         }).catch(err => {
    //             this.setState({ isLoader: false })

    //         })
    // }



    getParticularAd = () => {
        let req = {
            stageId: this.props.match.params.adId
        }
        _Api(Url.GET_PARTICULAR_AD.method, Url.GET_PARTICULAR_AD.url, '', `?adsId=${this.props.match.params.adId}`)
            .then(resp => {
                console.log("kjdhhgfdv", resp.responseData.result)
                // const { stageName, setOrder, startDate , endDate, stageUrl, startTime, endTime } = resp.responseData.result
                this.setState({
                    ...resp.responseData.result,
                    profilePicPreview: resp.responseData.result.media,
                    brand: '',
                    isEdit: true,
                    //  stageName, stageType: setOrder, startDate :  moment(new Date(startDate)) , endDate : moment(new Date(endDate)), url: stageUrl, startTime : moment(new Date(startTime)), endTime: moment(new Date(endTime))
                })
            }).catch(err => {
            })
    }





    EditAdd = () => {
        let { adName,
            adCategory,
            media,
            adLinkUrl,
            brand,
            email,
            phone,
            redirectionUrl
        } = this.state

        const UrlRegx = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
        try {
            if (adName == '')
                this.setState({ adNameError: '*Please enter ad name.' })
                else   if (redirectionUrl !='' && !UrlRegx.test(redirectionUrl))
                this.setState({ redirectionUrlError: '*Please enter a valid url' })

                
            else {
                this.setState({ isLoading: true })
                let formData = new FormData()
                formData.append("adsId", this.props.match.params.adId)
                formData.append("adName", adName)
                formData.append("redirectionUrl", redirectionUrl)
                formData.append("adLinkUrl", adLinkUrl)
                // formData.append("brand", brand)
                // formData.append("email", email)
                // formData.append("phone", phone)
                formData.append("media", media)
                _Api(Url.EDIT_ADD.method, Url.EDIT_ADD.url, formData)
                    .then(resp => {
                        this.setState({ isLoading: false })
                        this.props.history.goBack()
                    }).catch(err => {
                        this.setState({ isLoading: false })
                        if (err.error)
                            notify("err", err.error.responseMessage)
                        // else if (err)
                            // notify("err", err.message)
                    })
            }



        } catch  { }

    }

    finalCropedImage = (categoryImgPrev, categoryImg, visibleVal) => {
        this.setState({
            visibleVal,
            isCropper: false,
            profilePicPreview: categoryImgPrev,
            media: categoryImg

        })
      
    }



    render() {
        let { adName,
            adNameError,
            adCategory,
            adCategoryError,
            media,
            adLinkUrl,
            brand,
            email,
            phone,
            brandError,
            emailError,
            phoneError,
            redirectionUrl,
            brandListData,
            redirectionUrlError,
            isEdit
        } = this.state

        return (
            <div className="dash_body_container">
                <div className="body_container">
                    <div className="header-text  mb-4 mt-3">
                        <div className="d-flex align-items-center">
                            <p className="header-icon"><AdManagement /></p>
                            <h4> {isEdit ? "Edit Management"  : "Ad Management"}
                            <br />
                                {/* <ul className="header-breadcrum">
                                    <li><a href="#">Ad Management</a>/</li>
                                    <li><a href="#">Ad Detail</a></li>
                                </ul> */}
                            </h4></div>
                        <Link to="/ads" className="btn btn-light">Back</Link>
                    </div>
                </div>
                <div className="rounded-body py-5">
                    <form className="cm-form-wrapper rounded-textbox-form add-manag px-0">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <level>Ad Name</level>
                                    <input type="text"
                                        className="form-control"
                                        placeholder="Enter Name"
                                        maxLength={64}
                                        name="adName"
                                        value={adName}
                                        onChange={this.handleOnChange}
                                    />

                                    <InlineError
                                        message={adNameError}
                                    />
                                </div>
                            </div>
                            {/* <div className="col-md-6">
                                <div className="form-group">
                                    <level>Brand</level>
                                    <Select
                                        name='selectType'
                                        value={brand ? brand : 'Select brand'}
                                        name="brand"
                                        // placeholder="Select"
                                        onChange={(e) => this.handleOnChangeSelect("brand", e)}
                                    >
                                        {brandListData.map((item, index) => (
                                            <Option value={item._id}>{item.companyName}</Option>

                                        ))}
                                    </Select>
                                </div>
                            </div> */}
                            <div className="col-md-6">
                                <div className="form-group">
                                    <level>Redirection url</level>
                                    <input type="text"
                                        className="form-control"
                                        placeholder="Enter url"
                                        name="redirectionUrl"
                                        value={redirectionUrl}
                                        onChange={this.handleOnChange}
                                    />

                                       <InlineError
                                        message={redirectionUrlError}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            {/* <div className="col-md-6">
                                <div className="form-group">
                                    <level>Add link or upload multiple videos</level>
                                    <input
                                        type="text" className="form-control" placeholder="Enter Video Link"
                                        value={adLinkUrl}
                                        name="adLinkUrl"
                                        onChange={this.handleOnChange}
                                    />
                                </div>
                            </div> */}
                            <div className="col-md-6">
                                {/* <span className="or-label">Media</span> */}
                                <div className="form-group custom-upload-file">
                                <level>Image</level>
                                    <ul className="list-inline mt-3 pt-1">
                                        <li className="list-inline-item">
                                            <div className="upload-inner"  onClick={() => this.setState({isCropper: true})}>
                                            {
                                                            this.state.profilePicPreview ?
                                                                <img onClick={() => this.setState({isCropper: true})} src={this.state.profilePicPreview} />
                                                                : <span className="upload-icon"><UploadVideo /></span>
                                                        }
                                              
                                            </div>
                                            {/* <level>Maximum Size : 128 gb</level> */}
                                        </li>
                                        {/* <li className="list-inline-item">
                                            <button className="btn add-btn h-100" type="button">+</button>
                                        </li> */}
                                    </ul>
                                    <InlineError
                                        message={this.state.imageError}
                                    />
                                         
                                </div>
                            </div>
                        </div>
                        <div className="text-right">
                            <button className="btn btn-primary" type='button' disabled={this.state.isLoading} onClick={this.submitAd}>Save&nbsp;
                            {
                                    this.state.isLoading && <MySpinner />
                                }

                            </button>
                        </div>


                {this.state.isCropper ?
                    <Croper aspect={3 / 1}
                        // onImageCrop={img => this.setState({ media: img })}
                            //imgPrev={'2.5-1.png'}
                        finalCropedImage={(categoryImgPrev, categoryImg, visibleVal) => this.finalCropedImage(categoryImgPrev, categoryImg, visibleVal)} visibleVal={this.state.isCropper}
                        onClose={() => this.setState({ isCropper: false })}
                    />
                    : ""}
                    </form>
                </div>
            </div>
        )
    }
}
export default AddNewAd;