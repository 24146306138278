import React, { Component } from 'react';
import WebHeader from '../../layout/WebHeader';
import { Hert } from '../../../SvgIcon';
import Footer from "../../layout/Footer"
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import Masonry from 'react-masonry-css'
import { Tree } from 'antd';
import { connect } from "react-redux";
import { notify } from "../../../components/Toaster";
import Loader from "../../../components/Loader";
import { userActions } from "../../../redux/action";
var _ = require('lodash');

class ManageServices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productAndServiceOfferData: [],
            autoExpandParent: true,
            expandedKeys: [],
            checkedKeys: [],
            selectedKeys: [],
            isLoader: false,
            count: 0,
            serviceDisable: false,

            selectedItem: [],
            selectedCheck: []

        }
        this.saveService = this.saveService.bind(this);
    }

    componentDidMount() {
        this.props.dispatch({ type: 'PATH_ADD', payload:this.props.match })
        let self = this;
        if (localStorage.getItem('accessToken') && localStorage.getItem('accessToken') !== '') {
            self.setState({ isLoader: true })
            if(self.props.userDetail?.userPlan){
                self.props.dispatch(userActions.profile())
                    .then(function () {
                        self.getCategory("4", "productAndServiceOfferData");
                    })
            }else{
                self.props.dispatch(userActions.profile())
                    .then(function (){
                        self.getCategory("4", "productAndServiceOfferData");
                    })
            }


        } else {
            self.props.history.push("/")
        }
    }

    getCategory = (categoryType, keyName) => {
        let self = this;
        let params = {
            categoryType: categoryType,
        }
        this.setState({ isLoader: true })
        _Api(Url.GET_CATEGORY_LIST.method, Url.GET_CATEGORY_LIST.url, params)
            .then(resp => {
                self.setState({ isLoader: false })
                let expandedKeys = [];
                //servicesShowcaseId
                let servicesShowcaseId = self.props.userDetail.servicesShowcaseId;
                let topListedShowcaseId = self.props.userDetail?.topListedShowcaseId ? self.props.userDetail?.topListedShowcaseId : []
                let userPlan = self.props.userDetail?.userPlan
                let count = servicesShowcaseId ? servicesShowcaseId.length : 0;
                self.setState({
                    count
                })
                console.log("resp.responseData.result",resp.responseData.result);
                if (userPlan == 3 ? count == 30 : count == 20) {
                    // console.log("ifffff");
                    let selectedItm = []
                    let keyNameData = resp.responseData.result.map((item1, index1) => {
                        let checkedData = [];
                        let childData = item1.subCategory.map((data, index2) => {

                            let ddd = {}
                            let result = _.find(servicesShowcaseId, function (o) {
                                return o === data._id;
                            });

                            if (result) {
                                ddd = {
                                    disabled: false,
                                    key: data._id,
                                    title: data.categoryTitle,
                                    value: data._id,
                                    sectorName:item1.categoryTitle,
                                    children: []
                                }
                                if (servicesShowcaseId.indexOf(data._id) != -1) {
                                    selectedItm.push(ddd)

                                }
                            } else {
                                ddd = {
                                    disabled: true,
                                    key: data._id,
                                    title: data.categoryTitle,
                                    value: data._id,
                                    sectorName:item1.categoryTitle,
                                    children: []
                                }
                                if (servicesShowcaseId.indexOf(data._id) != -1) {
                                    selectedItm.push(ddd)

                                }
                            }
                            if (result) {
                                checkedData.push(result)
                            }
                            return ddd;
                        });
                        self.setState({
                            ['checkedKeys-' + item1._id]: checkedData
                        })
                        expandedKeys.push(item1.categoryTitle)
                        let dd = {
                            selectable: childData.length > 0 ? false : true,
                            // title: childData.length>0?<b style={{ color: '#000' }}>{item1.categoryTitle}</b>:item1.categoryTitle,
                            title: item1.categoryTitle,
                            value: item1._id,
                            key: item1._id,
                            checkable: childData.length > 0 ? false : true,
                            children: childData
                        }
                        return dd
                    })
                    console.log("keyNameData", keyNameData)
                    this.setState({
                        [keyName]: keyNameData,
                        selectedItem: _.orderBy(selectedItm, ['title'], ['asc']) ,
                        selectedCheck: topListedShowcaseId,
                        expandedKeys
                    },()=>{
                        this.topSelectedEnableOrDisable()
                    })
                } else {
                    let selectedItm = []
                    let keyNameData = resp.responseData.result.map((item1, index1) => {
                        let checkedData = [];
                        console.log("item1item1item1item1item1", item1)
                        let childData = item1.subCategory.map((data, index2) => {
                            // console.log("ndwkjndwjqhdk", { data, servicesShowcaseId })
                            let ddd = {
                                //checkable:false,
                                key: data._id,
                                title: data.categoryTitle,
                                value: data._id,
                                sectorName:item1.categoryTitle,
                                children: []
                            }
                            if (servicesShowcaseId.indexOf(data._id) != -1) {
                                selectedItm.push(ddd)

                            }
                            let result = _.find(servicesShowcaseId, function (o) {
                                return o === data._id;
                            });

                            if (result) {
                                checkedData.push(result)
                            }
                            return ddd;
                        });
                        self.setState({
                            ['checkedKeys-' + item1._id]: checkedData,
                        })
                        console.log("dsajhgdvhbjask", checkedData)
                        expandedKeys.push(item1.categoryTitle)
                        let dd = {
                            selectable: childData.length > 0 ? false : true,
                            // title: childData.length>0?<b style={{ color: '#000' }}>{item1.categoryTitle}</b>:item1.categoryTitle,
                            title: item1.categoryTitle,
                            value: item1._id,
                            key: item1._id,
                            checkable: childData.length > 0 ? false : true,
                            children: childData
                        }
                        return dd
                    })
                    console.log("keyNameData", keyNameData)
                    this.setState({
                        [keyName]: keyNameData,
                        selectedItem: _.orderBy(selectedItm, ['title'], ['asc']),
                        selectedCheck: topListedShowcaseId,
                        expandedKeys
                    },()=>{
                        this.topSelectedEnableOrDisable()
                    })
                }
            }).catch(err => {
                this.setState({ isLoader: false })
            })
    }

    onCheck = (productAndServiceOffer, checkedKeys, userType, node) => {
        let count = this.state.count;
        let isPremium = userType == 3
        let oldCheckedData = this.state['checkedKeys-' + productAndServiceOffer.key];
        let oldTopListData = this.state.selectedItem
        // console.log('onCheck', node);

        this.onCheckTopSelected('direct', node)

        let selectNode = node?.node
        //console.log("selectNode",selectNode);
        // console.log("lskjahgsfcvgH", this.state.selectedItem.findIndex((item) => item.value == selectNode.value))
        if (this.state.selectedItem.findIndex((item) => item.value == selectNode.value) == -1) {
            let newArr = [...oldTopListData, {
                ...selectNode
            }]            

            this.setState({
                selectedItem: _.orderBy(newArr, ['title'], ['asc'])
            })
            //console.log("newArr",newArr);
        }

        this.setState({
            ['checkedKeys-' + productAndServiceOffer.key]: checkedKeys,



        }, function () {
            let checkedKeysAll = [];
            _.map(this.state, function (value, key) {
                let splitData = key.split("-");
                if (splitData.length > 1) {
                    checkedKeysAll = checkedKeysAll.concat(value);
                }
            });
            if (checkedKeys.length > oldCheckedData.length) {
                if (count + 1 >= 30 && isPremium) {
                    let keyNameData = this.state.productAndServiceOfferData.map((item1, index1) => {
                        let checkedData = [];
                        let childData = item1.children.map((data, index2) => {
                            let ddd = {}
                            let result = _.find(checkedKeysAll, function (o) {
                                return o === data.key;
                            });
                            if (result) {
                                ddd = {
                                    disabled: false,
                                    key: data.key,
                                    title: data.title,
                                    value: data.value,
                                    sectorName:item1.title,
                                    children: []
                                }
                            } else {
                                ddd = {
                                    disabled: true,
                                    key: data.key,
                                    title: data.title,
                                    value: data.value,
                                    sectorName:item1.title,
                                    children: []
                                }
                            }
                            return ddd;
                        });
                        let dd = {
                            selectable: childData.length > 0 ? false : true,
                            title: item1.title,
                            value: item1.value,
                            key: item1.key,
                            checkable: childData.length > 0 ? false : true,
                            children: childData
                        }
                        return dd
                    })
                    console.log("lskjahgsfcvgH", oldCheckedData)
                    this.setState({
                        "productAndServiceOfferData": keyNameData,
                        count: count + 1,

                    })
                } else {
                    this.setState({
                        count: count + 1,
                        // selectedItem: keyNameData
                    })

                    console.log("dshgahsjk", checkedKeys)
                }
                if (count + 1 >= 20 && !isPremium) {
                    let keyNameData = this.state.productAndServiceOfferData.map((item1, index1) => {
                        let checkedData = [];
                        let childData = item1.children.map((data, index2) => {
                            let ddd = {}
                            let result = _.find(checkedKeysAll, function (o) {
                                return o === data.key;
                            });
                            if (result) {
                                ddd = {
                                    disabled: false,
                                    key: data.key,
                                    title: data.title,
                                    value: data.value,
                                    sectorName:item1.title,
                                    children: []
                                }
                            } else {
                                ddd = {
                                    disabled: true,
                                    key: data.key,
                                    title: data.title,
                                    value: data.value,
                                    sectorName:item1.title,
                                    children: []
                                }
                            }
                            return ddd;
                        });
                        let dd = {
                            selectable: childData.length > 0 ? false : true,
                            title: item1.title,
                            value: item1.value,
                            key: item1.key,
                            checkable: childData.length > 0 ? false : true,
                            children: childData
                        }
                        return dd
                    })
                    this.setState({
                        "productAndServiceOfferData": keyNameData,
                        count: count + 1,
                        // selectedItem: keyNameData

                    })
                } else {
                    this.setState({
                        count: count + 1,
                    })
                }
            } else {
                console.log("elseeeeeeee")
                let keyNameData = this.state.productAndServiceOfferData.map((item1, index1) => {
                    let checkedData = [];
                    let childData = item1.children.map((data, index2) => {
                        let ddd = {
                            disabled: false,
                            key: data.key,
                            title: data.title,
                            value: data.value,
                            sectorName:item1.title,
                            children: []
                        }
                        return ddd;
                    });
                    let dd = {
                        selectable: childData.length > 0 ? false : true,
                        title: item1.title,
                        value: item1.value,
                        key: item1.key,
                        checkable: childData.length > 0 ? false : true,
                        children: childData
                    }
                    return dd
                })
                this.setState({
                    "productAndServiceOfferData": keyNameData,
                    count: count - 1,
                    // selectedItem: keyNameData
                })
            }
        })



    };

    onSelect = (selectedKeys, info) => {
        console.log('onSelect', info);
        this.setState({
            selectedKeys
        })
    };

    onExpand = expandedKeys => {
        //console.log('onExpand', expandedKeys); // if not set autoExpandParent to false, if children expanded, parent can not collapse.
        // or, you can remove all expanded children keys.

        this.setState({
            expandedKeys,
            autoExpandParent: false
        })
    };

    saveService() {
        let self = this;
        //console.log("saveService");
        this.setState({
            productAndServiceError: '',
        })
        let checkedKeys = [];
        _.map(this.state, function (value, key) {
            let splitData = key.split("-");
            if (splitData.length > 1) {
                console.log(key, value, splitData);
                checkedKeys = checkedKeys.concat(value);
            }
        });
        // console.log("checkedKeys", checkedKeys);
        // let {checkedKeys} = this.state;
        if (checkedKeys.length === 0) {
            notify("err", 'Please select any service');
            //this.setState({productAndServiceError: '*Please select any service.'})
        } else {
            this.setState({ isLoader: true })
            let req2 = {
                servicesShowcaseId: checkedKeys.toString(),
                topListedShowcaseId: this.state.selectedCheck.toString()
            }
            _Api(Url.SAVE_SHOWCASE.method, Url.SAVE_SHOWCASE.url, req2)
                .then(resp => {
                    this.setState({ isLoader: false })
                    notify("success", "Services updated sucessfully");
                    this.props.history.goBack()
                    self.props.dispatch(userActions.profile())
                        .then(result => {
                            self.getCategory();
                        })
                }).catch(err => {
                    this.setState({ isLoader: false })
                    if (err.error)
                        notify("err", err.error.responseMessage)
                   // else if (err)
                //     // notify("err", err.message)
                })
        }
    }

    onCheckTopSelected = (indexId, data) => {
        let oldArr = this.state.selectedCheck
        const { node } = data
        let id = node?.value

        if (indexId == "direct") {
            this.setState({
                selectedCheck: oldArr.filter(item => item != id),
                selectedItem: this.state.selectedItem.filter(item => item.value != id)
            })
        }
        else {


            if (oldArr.indexOf(id) == -1) {
                if (this.state.selectedCheck.length < 6) {
                    oldArr.push(id)
                    this.setState({ selectedCheck: oldArr })
                }
            }
            else
                this.setState({
                    selectedCheck: oldArr.filter(item => item != id)
                })

        }

        this.topSelectedEnableOrDisable()


    }


    topSelectedEnableOrDisable = ()=>{
        setTimeout(() => {

            // if (this.state.selectedItem.findIndex((item) => item.value == selectNode.value) == -1) {
            let newArr = this.state.selectedItem


            if (this.state.selectedCheck.length >= 6) {

                this.setState({
                    selectedItem:
                        newArr.map(item => {
                            if (this.state.selectedCheck.indexOf(item.value) != -1) {
                                return (
                                    {
                                        disabled: false,
                                        key: item.key,
                                        //title:<div> {item.title}-<b style={{color:"#5383a2"}}> <i>{item.sectorName}</i></b></div>,
                                        title: item.title,
                                        value: item.value,
                                        children: [],
                                        sectorName:item.sectorName,
                                    }
                                )


                            }
                            else {

                                return (
                                    {
                                        disabled: true,
                                        key: item.key,
                                        title: item.title,
                                        //title:<div> {item.title}-<b style={{color:"#5383a2"}}> <i>{item.sectorName}</i></b></div>,
                                        value: item.value,
                                        children: [],
                                        sectorName:item.sectorName,
                                    }
                                )

                            }
                        })
                })
            }
            else {
                let soltedArr = newArr.map(item => (
                    {
                        disabled: false,
                        key: item.key,
                        title: item.title,
                        value: item.value,
                        children: [],
                        sectorName:item.sectorName,
                    }
                ))
                this.setState({
                    selectedItem: _.orderBy(soltedArr, ['title'], ['asc'])

                })
            }
        }, 300)
    }

    render() {
        const breakpointColumnsObj = {
            default: 2,
            1100: 2,
            700: 1,
            500: 1
        };
        // console.log("this.state", this.state);
        // console.log("props", this.props);
        const { userDetail } = this.props ? this.props : {}

        let selectedItem = this.state.selectedItem.map(item => (
            {
                disabled: false,
                key: item.key,
                title: <div> {item.title}-<b style={{color:"#5383a2"}}> <i>{item.sectorName}</i></b></div>,
                value: item.value,
                children: [],
                sectorName:item.sectorName,
            }
        ))

        const childElements = this.state.productAndServiceOfferData && this.state.productAndServiceOfferData.length > 0 ?
            this.state.productAndServiceOfferData.map((productAndServiceOffer, i) =>

                (
                    <div className="manage-card" key={productAndServiceOffer.key}>

                        <h5 ><i className="fa fa-caret-right" aria-hidden="true">&nbsp;</i> {productAndServiceOffer.title}</h5>

                        <Tree
                            checkable
                            onExpand={this.onExpand}
                            //selectable={this.state.serviceDisable}
                            autoExpandParent={false}
                            expandedKeys={this.state.expandedKeys}
                            autoExpandParent={this.state.autoExpandParent}
                            //onCheck={this.onCheck.bind(productAndServiceOffer._id)}
                            onCheck={(e, node) => this.onCheck(productAndServiceOffer, e, userDetail?.userPlan, node)}
                            checkedKeys={this.state['checkedKeys-' + productAndServiceOffer.key]}
                            //onSelect={this.onSelect}
                            // selectedKeys={this.state['selectedKeys-'+productAndServiceOffer.key]}
                            treeData={productAndServiceOffer.children}
                        />

                    </div>
                )
            ) : ""

        return (
            <section className="Home-banner-bg">
               {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
                {this.state.isLoader && <Loader />}
                    {/*<ChatBox history={this.props.history} match={this.props.match} />*/}
                <div className="container-fluid manage-service">
                    <div className=" pt-5 text-center">
                        <h1 className="home-heading pos-relative mb-5">
                            Manage Categories
                    <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                        </h1>
                        <div className="pos-relative">
                            <h2 className="heighlighter"> Promoted Search Categories Selected ({this.state.count}/{userDetail?.userPlan == 3 ? 30 : 20})</h2>
                            <p className="home-subheading">Choose {userDetail?.userPlan == 3 ? 30 : 20} categories you want your brand to feature higher in the search listings for.</p>
                            <p className="text-danger mb-0"><b>Unchecking a category will delete the products related to that category.</b></p>
                            <button type="button" className="btn btn-red btn-absolute" onClick={this.saveService.bind(this)}>Save</button>
                        </div>
                    </div>

                    <div className="cm_row">
                        <Masonry
                            breakpointCols={breakpointColumnsObj}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column"
                        >
                            {childElements}
                        </Masonry>

                    </div>

                    {userDetail?.userPlan == 3 && this.state.selectedItem[0] && <div className="manage-card" >

                        <h5 ><i className="fa fa-caret-right" aria-hidden="true">&nbsp;</i>Top Listed Categories</h5>
                        <h6 > Top Listed Categories Selected ({this.state.selectedCheck?.length}/{6})</h6>
                        <Tree
                            checkable
                            onExpand={this.onExpand}
                            //selectable={this.state.serviceDisable}
                            autoExpandParent={false}
                            expandedKeys={this.state.expandedKeys}
                            autoExpandParent={this.state.autoExpandParent}
                            //onCheck={this.onCheck.bind(productAndServiceOffer._id)}
                            onCheck={(e, node) => this.onCheckTopSelected(e, node)}
                            // selectedKeys={this.state.selectedCheck}
                            checkedKeys={[...this.state.selectedCheck]}
                            //onSelect={this.onSelect}
                            // selectedKeys={this.state['selectedKeys-'+productAndServiceOffer.key]}
                            // treeData={this.state.selectedItem}
                            treeData={selectedItem}
                        />
                    </div>}
                </div>
                <Footer />
            </section >
        )
    }
}
function mapStateToProps(state) {
    // console.log("abcdefghhfiekjnmkwejnfnw", state)
    return {
        ...state
    };
}

export default connect(mapStateToProps)(ManageServices);