import React, { Component } from 'react';
import InlineError from '../../InlineError';
import { DatePicker, TimePicker, Select } from 'antd';
import util from "../../../utils/Util";
import TimeZone from "../../../utils/timeZone"
// import TimezoneSelect from 'react-timezone-select'
import { TimezoneSelect, clientTz } from 'timezone-select-js';


var moment = require('moment-timezone'); // moment-timezone



const timezones = moment.tz.names()
const { Option } = Select;
const { RangePicker } = DatePicker




const ExhibitionDetail = (props) => {
    const { sectorList, selectedSectorValue, timezone, liveTime, liveDate, exhibitionNameError, exhibitionName, startDate, endTime, startTime, startTimeError, endTimeError, startDateError, endDateError, endDate, exhibitionFee, exhibitionFeeError, liveDateError, liveTimeError } = props

    const disabledDate = (current) => {
        // Can not select days before today and today
        // return current && moment(current.valueOf()).format('MM/DD/YYYY') < moment().format('MM/DD/YYYY');;
        return current && moment(current.valueOf()) < moment();
    }

    const disabledDateEnd = (current) => {
        // Can not select days before today and today
        return current && current.valueOf() < startDate;
    }

    console.log("timedetails",timezone)


    return (
        <>
            <form className="cm-form-wrapper">
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <level>Exhibition Name</level>
                            <input type="text"
                                className="form-control"
                                placeholder="Exhibition Name"
                                maxLength={64}
                                name="exhibitionName"
                                value={exhibitionName}
                                onChange={props.handleOnChangeExhibition}
                            />
                            <InlineError
                                message={exhibitionNameError}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <level>Sector</level>
                            <Select
                                mode="multiple"
                                className="cm_multiselect cmslect_multicheck"
                                placeholder="Please select"
                                style={{ width: '100%' }}
                                onChange={(e) => props.handleSectorSelect(e)}
                                value={selectedSectorValue}
                            >
                                {
                                    sectorList?.map((item, index) =>
                                        <Option key={"sect-" + item._id} value={item._id}>{item.name}</Option>
                                    )
                                }
                            </Select>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <level>Time Zone</level>

                        <div className="form-group">
                        <TimezoneSelect
                                                        
                                                            labelStyle='original'
                                                            defaultValue={timezone}
                                                            value={timezone}
                                                            onChange={props.handleChangeTimeZone}
                                                        />
                            {/* <Select showSearch autoComplete="new-password" placeholder="Time zone"
                                value={timezone}
                                defaultValue={timezone}

                                onChange={(e) => props.handleChangeTimeZone(e)} name='country'

                            >
                                {TimeZone.map((item) => {
                                    return (<Select.Option value={item}>{item}</Select.Option>)
                                })}

                            </Select> */}

                        </div>

                    </div>

                    <div className="col-md-6">
                        <level>Start, End date and time</level>
                        <div className="form-group">
                            <RangePicker
                                inputReadOnly
                                showTime={{ format: 'HH:mm' }}
                                value={[moment.tz(startDate, props.state.timezone), moment.tz(endDate, props.state.timezone)]}
                                format="DD-MM-YYYY HH:mm"
                                onChange={props.onChangeDate}
                            // onOk={onOk}
                            />
                            <InlineError
                                message={startDateError}
                            />
                        </div>
                    </div>

                    <div className="col-lg-4 ">
                        <level>Exhibition type</level>
                        <div className="form-row">
                            <div className="d-flex align-items-center">
                                <div className="form-group mb-0">
                                    <div className="justify-content-between">

                                        <div className="d-flex">
                                            <p>
                                                <div className="check_wrapper">
                                                    <input
                                                        type="checkbox"

                                                        value="option1"
                                                        checked={!props.state.isChecked}
                                                        onChange={props.handleChangedChecked}
                                                    />
                                                    <span />
                                                </div>
                                                Online
                                                </p>
                                            <p className="ml-4">
                                                <div className="check_wrapper">
                                                    <input
                                                        type="checkbox"
                                                        value="option2"
                                                        checked={props.state.isChecked}
                                                        onChange={props.handleChangedChecked} />
                                                    <span />
                                                </div>
                                                Hybrid
                                                </p>
                                        </div>



                                    </div>
                                </div>
                            </div>


                        </div>



                    </div>
                    {/* <div className="col-md-3">
                                {
                                    props.state.isChecked
                                    &&
                                    <div className="">
                                        <input type="text"
                                               className="form-control"
                                               placeholder="Enter amount"
                                               maxLength={15}
                                               name="exhibitionFee"
                                               value={exhibitionFee}
                                               onChange={props.handleOnChangeExhibition}
                                        />
                                        <InlineError
                                            message={exhibitionFeeError}
                                        />
                                    </div>
                                }
                            </div> */}
                </div>
            </form>
        </>


    )
}

export default ExhibitionDetail
