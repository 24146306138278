import React, { Component } from 'react';
import Footer from '../layout/Footer';
import { Link } from "react-router-dom";
import {
    Hert, CommunityManagement,
    PaymentIcon
}
    from '../../SvgIcon';
//Inline error
import InlineError from '../../components/InlineError';
import { _Api } from '../../services/Api';
import Url from '../../services/BaseUrl';
import { notify } from '../../components/Toaster'
import Cookies from 'js-cookie'

import Loader from '../../components/Loader';
import {connect} from "react-redux";
import {getExhibitionId} from "../../redux/action";
import { loadStripe } from '@stripe/stripe-js';
import siteSetting from '../../config/env/Index';

const stripePromise = loadStripe(siteSetting.stripe_key);

//Payment  screen
class PaymentNew extends Component {
    constructor(props) {
        super(props)
        this.state = {
            firstName: '',
            lastName: '',
            firstNameError: '',
            lastNameError: '',
            cardError: '',
            isVat: true,
            amount: 0,
            vatNumber: '',
            vatNumberError: '',
            paymentType: 1,
            isLoader: false,
            purchased: 0,

            checkBtn: 2,
            promocodeTitle: "",
            promocodeTitleError: "",
            promoCodeDiscount: 0,
            finalAmount: 0,
            vatAmount: 0,
            gbpVat:0,
            usdVat:0,
            percentageDiscount: 0,
            isPromoApply: 0,
            systemRequirement: 0,
            declaration: 0,
            systemRequirementError: "",
            declarationError: "",
            cardErrorExpiration:"",
            cardErrorCVV:"",
            exhibitionId:this.props.loading.exhibitionId,
            gbpAmount:null,
        }
        this.applyPromoCode = this.applyPromoCode.bind(this);
        window.scrollTo(0, 0);
        this.firstNameDiv = React.createRef();
        this.systemRequirementDiv=React.createRef();
        this.sdeclarationDiv=React.createRef();
        this.cardErrorDiv=React.createRef();
        this.cardErrorCvvDiv=React.createRef();
    }



    componentDidMount() {
        let self=this;
        console.log("this.pro",this.props);
        if (localStorage.getItem('accessToken') && localStorage.getItem('accessToken') !== '') {

        } else {
            this.props.history.push('/')
        }
        // this.setState({categoryAllData})
        if (Cookies.get('amt_plan') == undefined) {
            if (localStorage.getItem('accessToken') && localStorage.getItem('accessToken') !== '') {
                this.props.history.push('/home')
            }else{
                this.props.history.push('/')
            }
        }
        else {
            if(this.props.loading.exhibitionId){
                this.setState({
                    exhibitionId:this.props.loading.exhibitionId
                })
            }else{
                this.props.dispatch(getExhibitionId())
                    .then(function (){
                        self.setState({
                            exhibitionId:self.props.loading.exhibitionId
                        })
                    })
            }
            
            //console.log("+++++",JSON.parse(Cookies.get('amt_plan')))
            const {defaultVatText, defaultCurrency, usdVat, gbpVat,  usd, gbp, purchased, planId } = JSON.parse(Cookies.get('amt_plan'))
            console.log("pbpays", {defaultVatText, defaultCurrency,   usdVat, gbpVat,  usd, gbp, purchased, planId })
            if(defaultCurrency == 0)
            {
                this.setState({
                    paymentType:0,
                    amount: usd,
                    purchased: purchased,
                    usdVat:usdVat,
                    gbpVat:gbpVat,
                    finalAmount: usd,
                    planId: planId,
                    gbpAmount:gbp,
                    checkBtn:defaultVatText == 0 ? 2 : 3
                }, () => {
                    self.handleOnClick(defaultVatText == 0 ? 2 : 3)
                })

            }
            else{
                this.setState({
                    gbpAmount:gbp,
                    paymentType:1,
                    usdVat:usdVat,
                    gbpVat:gbpVat,
                    amount: gbp,
                    purchased: purchased,
                    finalAmount: gbp,
                    planId: planId,
                    checkBtn:defaultVatText == 0 ? 2 : 3
                }, () => {
                    self.handleOnClick(defaultVatText == 0 ? 2 : 3)
                })

            }
           
        }
    }



    getPercentage = (partialValue, totalValue) => {
        return partialValue * totalValue / 100;
    }

    goToPayment = async() => {
            let self = this;
            const stripe = await stripePromise;
            // if (this.state.isVat && this.state.vatNumber.trim() == "") {
            if (false) {
                this.setState({ vatNumberError: '*Please enter vat number' })
            }
            else if (this.state.systemRequirement === 0 || this.state.systemRequirement === false) {
                this.setState({
                    systemRequirementError: " Please confirm you have read the system requirements."
                },function (){
                    self.systemRequirementDiv.current.scrollIntoView({ behavior: 'smooth' });
                })
            } else if (this.state.declaration === 0 || this.state.declaration === false) {
                this.setState({
                    declarationError: " Please confirm that you have read the terms and conditions."
                },function (){
                    self.sdeclarationDiv.current.scrollIntoView({ behavior: 'smooth' });
                })
            } else {
                let req = {
                    planId: this.state.planId ? this.state.planId : 1,
                    promocodeTitle: this.state.isPromoApply === 1 ? this.state.promocodeTitle : "",
                    //cardToken: cardToken,
                    exhibitionId: this.state.exhibitionId,
                    purchased: this.state.purchased,
                    region: this.state.checkBtn == 3 ? 'UK' : this.state.checkBtn == 2 ? 'rest all' : 'EU',
                    amount: `${this.state.amount}`,
                    paymentType: this.state.paymentType === 1||this.state.paymentType === true ? 1 : 0,        //0 usd 1 gbp
                    vatNumber: this.state.vatNumber,
                    totalAmount: this.state.finalAmount,
                    vatAmount: this.state.vatAmount,
                    promocodeType : this.state.planId == 3 ? 3 : 0
                }
                if(this.props.match.path==="/pay"){
                    req.successUrl="payment-success";
                    req.failUrl="pay"
                }else{
                    req.successUrl="pay-success";
                    req.failUrl="pay-user"
                }
                _Api(Url.USER_TRANSACTION_V2.method, Url.USER_TRANSACTION_V2.url, req)
                    .then(resp => {
                        console.log("resp",resp);

                        let sessionId=resp.responseData.data.id;
                        localStorage.setItem("transactionId", resp.responseData.transactionId)
                        // localStorage.removeItem("showAll")
                        //localStorage.setItem("checkStatus", this.state.purchased)
                        stripe.redirectToCheckout({
                            sessionId: sessionId,
                        }).then(function (res){
                            console.log("stripe res=",res);
                        }).catch(function (error){
                            console.log("stripe error=",error);
                        })
                        this.setState({ isLoader: false },()=>{
                            //Cookies.remove('amt_plan');
                            //this.props.history.replace({ pathname: 'paymentsuccess', });
                        })
                    }).catch(err => {
                        this.setState({ isLoader: false })
                        if (err.error)
                            notify("err", err.error.responseMessage)
                    })
            }

    }


    goToPaymentWithoutCard = () => {
        let self=this;
        if (this.state.systemRequirement === 0 || this.state.systemRequirement === false) {
             this.setState({
                 systemRequirementError: " Please confirm you have read the system requirements."
             },function (){
                 self.systemRequirementDiv.current.scrollIntoView({ behavior: 'smooth' });
             })
         } else if (this.state.declaration === 0 || this.state.declaration === false) {
             this.setState({
                 declarationError: " Please confirm that you have read the terms and conditions."
             },function (){
                 self.sdeclarationDiv.current.scrollIntoView({ behavior: 'smooth' });
             })
         }
        else{
        this.setState({ isLoader: true })       
        let req = {
            planId: this.state.planId ? this.state.planId : 1,
            promocodeTitle: this.state.isPromoApply === 1 ? this.state.promocodeTitle : "",
            cardToken: '',
            exhibitionId: this.state.exhibitionId,
            purchased: this.state.purchased,
            region: this.state.checkBtn == 3 ? 'UK' : this.state.checkBtn == 2 ? 'rest all' : 'EU',
            amount: `${this.state.amount}`,
            paymentType: this.state.paymentType === 1 ? 1 : 0,        //0 usd 1 gbp
            vatNumber: this.state.vatNumber,
            totalAmount: this.state.finalAmount,
            vatAmount: this.state.vatAmount,
            promocodeType : this.state.planId == 3 ? 3 : 0
        }
        _Api(Url.USER_TRANSACTION.method, Url.USER_TRANSACTION.url, req)
            .then(resp => {
                this.setState({ isLoader: false })
                localStorage.setItem("checkStatus", this.state.purchased)
                localStorage.setItem("isPlanBuy", 1)
                Cookies.remove('amt_plan');
                //window.location.href = `coming-soon`
                if(localStorage.getItem("showAll")) {
                    window.location.href = `/home`;
                }else{
                    window.location.href ='/home';
                }
                if(this.state.finalAmount == 0) {
                    //this.props.history.push(`/business/profile/${localStorage.getItem("id")}`)
                    // this.props.history.push("/home")
                }else {
                    // window.location.href = `paymentsuccess`
                    //this.props.history.replace({ pathname: 'paymentsuccess', });
                    // this.props.history.push("/paymentsuccess")
                }
            }).catch(err => {
                this.setState({ isLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
            })
        }
    }


    handlePaymentSuccessPatment = (evt) => {
        evt.preventDefault();
        // this.setState({ addcarderrmsg: '', cardHolderErrorMsg: '' })
        let price = this.state.finalAmount
        if (this.state.finalAmount == 0) {
            this.goToPaymentWithoutCard()

        }
        else {
            this.goToPayment()
        }

    };



    handleChange = ({ error }) => {
        if (error) {
            this.setState({ errorMessage: error.message });
        }
    };
    handleOnChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            firstNameError: '',
            lastNameError: '',
            vatNumberError: '',
            promocodeTitleError: "",
            systemRequirementError: "",
            declarationError: ""
        })

    }
    handlePaymentType = () => {
        this.setState({ paymentType: !this.state.paymentType }, () => {
            // this.setState({categoryAllData})
            if (Cookies.get('amt_plan') == undefined) {
            }
            else {
                this.setState({
                    amount: this.state.paymentType ? JSON.parse(Cookies.get('amt_plan')).gbp:JSON.parse(Cookies.get('amt_plan')).usd,
                }, function () {
                    let promoCodeDiscount = this.state.promoCodeDiscount;
                    let finalAmount = this.state.amount - promoCodeDiscount;
                    //console.log("finalAmount",finalAmount)
                    let vatAmount = 0;
                    if (this.state.checkBtn === 3) {
                        let vat = this.state.paymentType == 0 ? this.state.usdVat : this.state.gbpVat
                        vatAmount = (vat * finalAmount / 100);
                        finalAmount = finalAmount + vatAmount
                    }
                    this.setState({
                        finalAmount : finalAmount < 0 ? 0 : finalAmount,
                        vatAmount
                    })
                })
            }
        })
    }

    handleOnClick = (btn) => {
        if (btn === 3) {
            let promoCodeDiscount = this.state.promoCodeDiscount;
            let finalAmount = this.state.amount>=promoCodeDiscount?this.state.amount - promoCodeDiscount:0;
            let vat = this.state.paymentType == 0 ? this.state.usdVat : this.state.gbpVat
            let vatAmount = (vat * finalAmount / 100);
            finalAmount = finalAmount + vatAmount
            this.setState({
                finalAmount,
                vatAmount
            })
        } else {
            let promoCodeDiscount = this.state.promoCodeDiscount;
            let finalAmount = this.state.amount>=promoCodeDiscount?this.state.amount - promoCodeDiscount:0;
            this.setState({
                finalAmount: finalAmount,
                vatAmount: 0
            })
        }
        // this.setState({ isVat: !this.state.isVat, vatNumber: '', vatNumberError: '' })
        this.setState({ checkBtn: btn, vatNumber: '', vatNumberError: '' })
    }

    applyPromoCode() {
        this.setState({
            promocodeTitleError: "",
            promoCodeDiscount: 0,
            isPromoApply: 0
        })
        if (this.state.promocodeTitle === "") {
            let vatAmount = 0;
            let finalAmount = this.state.amount;
            if (this.state.checkBtn == 3) {
                let vat = this.state.paymentType == 0 ? this.state.usdVat : this.state.gbpVat
                vatAmount = (vat * finalAmount / 100);
                finalAmount = finalAmount + vatAmount;
            }
            this.setState({
                promocodeTitleError: "*Activation Code required",
                promoCodeDiscount:0,
                percentageDiscount:0,
                finalAmount,
                vatAmount
            })
        } else {
            let param = {
                promocodeTitle: this.state.promocodeTitle,
                exhibitionId: this.state.exhibitionId,
                promocodeType : this.state.planId == 3 ? 3 : 0,
                planId : this.state.planId
            }
            _Api(Url.APPLY_PROMOCODE.method, Url.APPLY_PROMOCODE.url, param)
                .then(resp => {
                    console.log("resp", resp);
                    let promoCodeDiscount = 0;
                    let percentageDiscount = 0;
                    let vatAmount = 0;
                    if (resp.responseData.result.discountType === 1) {      //fixed discount
                        promoCodeDiscount = resp.responseData.result.discountValue;
                    } else {                                              //% discount
                        percentageDiscount = resp.responseData.result.discountValue;
                        let maximumDiscount = resp.responseData.result.maximumDiscount;
                        let percentageAmount = (this.state.amount * percentageDiscount / 100);
                        promoCodeDiscount = percentageAmount > maximumDiscount ? maximumDiscount : percentageAmount
                    }
                    console.log("promoCodeDiscount", promoCodeDiscount);
                    //let finalAmount = this.state.amount - promoCodeDiscount;
                    let finalAmount = this.state.amount>=promoCodeDiscount?this.state.amount - promoCodeDiscount:0;
                    if (this.state.checkBtn == 3) {
                        let vat = this.state.paymentType == 0 ? this.state.usdVat : this.state.gbpVat
                        vatAmount = (vat * finalAmount / 100);
                        finalAmount = finalAmount + vatAmount;
                    }
                    this.setState({
                        isLoader: false,
                        finalAmount : finalAmount < 0 ? 0 : finalAmount,
                        promoCodeDiscount,
                        percentageDiscount,
                        isPromoApply: 1,
                        vatAmount
                    })
                    //this.props.history.push("/paymentsuccess")
                }).catch(err => {
                    this.setState({ isLoader: false })
                    console.log("err", err);
                    if (err.error) {
                        if (err.error.errorCode === 11) {
                            this.setState({
                                promocodeTitleError: err.error.responseMessage
                            })
                        } else {
                            notify("err", err.error.responseMessage)
                        }
                    } else if (err) {
                        // notify("err", err.message)
                    }
                })
        }
    }

    toggleSystemRequirement = () => {
        this.setState({
            systemRequirementError: "",
            declarationError: "",
            systemRequirement: !this.state.systemRequirement,
        });
    }

    toggleDeclaration = () => {
        this.setState({
            systemRequirementError: "",
            declarationError: "",
            declaration: !this.state.declaration,
        });
    }

    backPayment=()=>{
        if(this.props.match.path==="/pay"){
            this.props.history.push('/plans')
        }else{
            this.props.history.push('/plan')
        }
        //this.props.history.goBack()
    }

    render() {
        console.log('thisstate', this.state.usdVat, this.state.gbpVat);
        // console.log('this.props', this.props);
        let { cardToken, email, amount, cardError, firstName, lastName, firstNameError, lastNameError,cardErrorExpiration,cardErrorCVV } = this.state
        return (
            <>
                {this.state.isLoader && <Loader />}
                <div className="container-fluid signin-bg pos-relative payment-parent">

                    <div className="container payment">
                        <div className="reset-pass text-center">
                            <div className="logo">
                                {/* <img src={require('../../assets/images/logo-half.png')} className="img-fluid" /> */}
                                <img src={require('../../assets/images/aesthetic/navbar_logo.jpg')} className="img-fluid" />
                            </div>
                            <div>
                                <h3 className="f-700">Select Payment Currency</h3>
                                <div className="radio-parent">
                                    <div className="circular-btn-radio">
                                        <label className="cm_container"><img src={require('../../assets/images/usd.png')} className="img-fluid" />USD
                                            <input checked={!this.state.paymentType} onClick={this.handlePaymentType} type="radio" disabled={this.state.gbpAmount ? false : true} name="radio" />
                                            <span className="radio-checkmark"></span>
                                        </label>
                                    </div>
                                   {this.state.gbpAmount  ?  <div className="circular-btn-radio">
                                        <label className="cm_container"><img src={require('../../assets/images/gbp.png')} className="img-fluid" />GBP
                                        <input checked={this.state.paymentType} onClick={this.handlePaymentType} type="radio" name="radio" />
                                            <span className="radio-checkmark"></span>
                                        </label>
                                    </div> : ''}
                                </div>
                            </div>
                            <div className="shadow-wrapper">
                            <div className="row payment">
                                <div className="col-lg-12">
                                    <div className="paymentcard">
                                        <div className="ml-3 mr-3">
                                            <h5 className="main-heading">VAT (TAX)</h5>
                                            <h4 className="f-700 mb-4">Where are you  based?</h4>
                                            {/*<div className="form-group form-check vat-checkbox">*/}
                                            {/*    <label class="cm_container"><span>EU</span>*/}
                                            {/*        <span>VAT will not be charged provided we receive your VAT number before the event.</span>*/}
                                            {/*        <input type="checkbox"*/}
                                            {/*            name="remember"*/}
                                            {/*            checked={this.state.checkBtn == 1}*/}
                                            {/*            onClick={() => this.handleOnClick(1)} />*/}
                                            {/*        <span class="checkmark"></span>*/}
                                            {/*    </label>*/}

                                            {/*</div>*/}
                                            <div className="form-group form-check vat-checkbox">

                                                <label class="cm_container">
                                                    <span>Rest of the World</span>
                                                    <span>No VAT/Tax is applicable</span>

                                                    <input type="checkbox"
                                                        name="remember"
                                                        checked={this.state.checkBtn == 2}
                                                        onClick={() => this.handleOnClick(2)}
                                                    />
                                                    <span class="checkmark"></span>
                                                </label>

                                            </div>
                                            <div className="form-group form-check vat-checkbox">
                                                <label class="cm_container">
                                                    <span>UK</span>                                                    
                             <span>Costs shown exclude VAT, which is chargeable at {this.state.paymentType == 0 ? this.state.usdVat : this.state.gbpVat}%</span>

                                                    <input type="checkbox"
                                                        name="remember"
                                                        checked={this.state.checkBtn == 3}
                                                        onClick={() => this.handleOnClick(3)}
                                                    />
                                                    <span class="checkmark"></span>
                                                </label>

                                            </div>
                                            {
                                                this.state.checkBtn == 1 &&
                                                <div>
                                                    <hr />
                                                    <h6 className="f-700">Please provide your VAT number</h6>
                                                    <div className="search-container d-flex col-sm-6 ">

                                                        <input type="text"
                                                            placeholder="Enter VAT number"
                                                            name="vatNumber"
                                                            onChange={this.handleOnChange}
                                                            value={this.state.vatNumber}
                                                            className="form-control" />
                                                        {/*<button type="type" className="btn">Submit</button>*/}
                                                    </div>
                                                    <InlineError
                                                        message={this.state.vatNumberError} cardError
                                                    />

                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 mt-5">
                                    <div className="paymentcard pb-0">
                                        <div className="ml-3 mr-3">
                                            <h5 className="d-flex main-heading justify-content-between align-items-center"><span>Plan</span><span className="f-400 colorB fs-16"> &nbsp; {this.state.paymentType ? '£':'$'  }{this.state.amount}</span></h5>
                                            <p>If you have been issued an activation code enter it here</p>
                                            <div className="d-flex justify-content-between align-items-center">
                                                {/* <span>Activation Code</span> */}
                                                <div className="search-container d-flex mt-3 mb-2">

                                                    <input type="text" placeholder="Enter code" name="promocodeTitle" className="form-control" onChange={this.handleOnChange} />
                                                    <button type="button" className="btn" onClick={this.applyPromoCode.bind(this)}>Apply</button>

                                                </div>

                                            </div>
                                            <div className="d-flex mb-4">
                                                <div >
                                                    <InlineError
                                                        message={this.state.promocodeTitleError}
                                                    />
                                                    {this.state.promoCodeDiscount !== 0 ? <div className="success-msz">Activation code applied successfully</div> : ""}
                                                </div>
                                            </div>
                                            <p className="d-flex justify-content-between"><span>Subtotal</span><span> {this.state.paymentType ? '£':'$'}{this.state.amount}</span></p>
                                            {
                                                this.state.promoCodeDiscount !== 0 &&
                                                <>
                                                    <hr />
                                                    <p className="d-flex justify-content-between"><span>Activation code discount {this.state.percentageDiscount ? "(" + this.state.percentageDiscount + "%)" : ""}</span><span> {this.state.paymentType ? '£':'$'}{this.state.promoCodeDiscount <= this.state.amount ? this.state.promoCodeDiscount : this.state.amount  }</span></p>
                                                </>
                                            }
                                            {
                                                this.state.checkBtn == 3 &&
                                                <>
                                                    <hr />
                                            <p className="d-flex justify-content-between"><span>VAT count ({this.state.paymentType == 0 ? this.state.usdVat : this.state.gbpVat}%)</span><span> {this.state.paymentType ? '£':'$'}{this.state.vatAmount}</span></p>
                                                </>
                                            }
                                            <p className="d-flex justify-content-between total"><span>Total</span><span> {this.state.paymentType ? '£':'$'}{this.state.finalAmount}</span></p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div className="row payment-acknowledge">
                                <div className="">
                                    <h2>Acknowledgement</h2>
                                    <h3><span className="hert"><Hert /></span>System Requirement :</h3> 
                                    <label class="form-check-label ml-4">
                                        <input class="form-check-input" type="checkbox" name="systemRequirement" checked={this.state.systemRequirement} onChange={this.toggleSystemRequirement} ref={this.systemRequirementDiv}/>
                                        I understand the minimum system requirements to use the worldwidejobfinder.com platform are:
    
                                    <InlineError
                                            message={this.state.systemRequirementError}
                                        />

                                    </label>

                                    <ul>
                                        <li><span>•</span> An internet connection – ideally broadband wired or wireless (3G or 4G/LTE)</li>
                                        <li><span>•</span> Speakers and a microphone – built-in or USB plug-in or wireless Bluetooth</li>
                                        <li><span>•</span> A webcam or HD webcam - built-in or USB plug-in</li>
                                        <li><span>•</span> Or, a HD cam or HD camcorder with video capture card</li>
                                        {/*<li><span>•</span> The latest version of Zoom </li>*/}
                                    </ul>
                                    <p className="note">Note: See the list of <a href="https://pb-world-comman.s3.eu-west-2.amazonaws.com/Worldwidejobfinder+EVENT+PLATFORM+System+Requirements.pdf" target="_blank">supported devices.</a> </p>
                                    <h3><span className="hert"><Hert /></span>Terms & Conditions</h3>

                                    <p className="mb-4">By ticking the box below you are saying you have read and agreed to the terms & conditions which you can download by <a href="https://pb-world-comman.s3.eu-west-2.amazonaws.com/Worldwidejobfinder+Terms+and+Conditions.pdf" target="_blank">clicking here.</a></p>
                                    <h3>Declaration:</h3>
                                    <label class="form-check-label  ml-4 mb-4">
                                        <input class="form-check-input" type="checkbox" name="declaration" checked={this.state.declaration} onChange={this.toggleDeclaration} ref={this.sdeclarationDiv}/>
                                        I have read and understood the terms & conditions of participating on the worldwidejobfinder.com
                                            platform as detailed above.
                                <InlineError message={this.state.declarationError} />
                                    </label>
                                </div>


                            </div>
                            <div className="row payment-acknowledge">
                                <div className="">
                                    <p> clicking 'CONFIRM' will take you to a payment screen from stripe where you can enter your card details.
                            </p>
                                </div>
                            </div>
                            
                            <div className="row">
                             <div className="col-6 ">
                                    <div className="form-group text-left">
                                        <button type="button" onClick={this.backPayment} className="btn btn-red purpalbtn" >Back</button>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group text-right">
                                        <button type="button" onClick={this.handlePaymentSuccessPatment} className="btn btn-red" >Confirm</button>
                                    </div>
                                </div>
                                
                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}

//export default injectStripe(Payment);
function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(PaymentNew);