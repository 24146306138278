import React, { Component } from 'react';
import { useHistory } from "react-router-dom";
import { SearchIcon } from '../../../SvgIcon';
import { Link } from "react-router-dom";
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { BackBtn } from '../../../components/common/Button'
import InlineError from '../../../components/InlineError';
import { Select, Checkbox } from 'antd';
import Loader from '../../../components/Loader';
import util from '../../../utils/Util.js';
import { notify } from '../../../components/Toaster';
import { Pagination } from '../../../components/common/Pagination';
import { TreeSelect , Empty} from 'antd';
import moment from "moment-timezone";
//import { Item } from 'react-bootstrap/lib/breadcrumb';
const { Option } = Select;
const { SHOW_PARENT, SHOW_CHILD, SHOW_ALL } = TreeSelect;

const format = 'HH:mm';
const fieldDetails = [{ fieldTitle: "title", isRequired: true },
{ fieldTitle: "euroAmount", isRequired: true },
{ fieldTitle: "dollarAmount", isRequired: true },
{ fieldTitle: "numberOfSearches", isRequired: true },
{ fieldTitle: "searchPlanType", isRequired: true },
{ fieldTitle: "placeOfWorkId", isRequired: true }]

//Add New Exhibition screen
const columnDetails = [{ fieldName: "S.No." },
{ fieldName: "User Name" },
{ fieldName: "Date ", isSortable: true, dataIndex: "created" },
{ fieldName: "Answer" },
];
class ViewQuestionAnswerList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fieldName: '',
            particularQuestionAnswerData: [],
            limit: 10,
            snNo: 0,
            page: 1,
            totalCount: [],
        }
    }
   
    componentDidMount() {
      
        this.getParticularQuestionAnswer();
        
    }
    getParticularQuestionAnswer = () => {
        
        this.setState({ isLoader: true });
        let { page, limit, search, isDownload, sortInfo, userType } = this.state;
        console.log("propspropsprops", this.props.match.params.id);
        let req = {
            page: page ? page : 1,
            limit: limit,
            // search: search.toString(),
            questionId: this.props.match.params.id,
        }
        _Api(Url.QUESTION_ALL_ANSWER_LIST.method, Url.QUESTION_ALL_ANSWER_LIST.url, '', `?questionId=${req.questionId}&page=${req.page}&limit=${req.limit}`)
            .then(resp => {
                //console.log("respresprespresp" , resp);
                this.setState({ particularQuestionAnswerData: resp.responseData.result.data , totalCount: resp.responseData.result.totalCount, isLoader: false });
                console.log("particularQuestionAnswerDataparticularQuestionAnswerData" , resp.responseData.result.data);

            }).catch(err => {               
                this.setState({ isLoader: false });
                let errorMessage = err.error && err.error.responseMessage || err.message;
                notify("err", errorMessage);
            });
    }
    handlePageChange = (page) => {
        this.setState({ loader: true, page, snNo: page - 1, particularQuestionAnswerData: [] }, () => {
            this.getParticularQuestionAnswer()
        })

    }
    handleSearch = e => this.setState({ search: e.target.value, page: 1, snNo: 0, particularQuestionAnswerData: [] }, () => this.getParticularQuestionAnswer());
    handleCancel = () => {
        this.setState({ visible: false });
    };


    render() {
        let { particularQuestionAnswerData, search, visible, loading, isLoader, sortInfo,limit } = this.state;
     
        //let { fieldName, order } = sortInfo;
        
        return (


            <>
           
          {particularQuestionAnswerData && particularQuestionAnswerData?.length > 0 &&
                <div className="dash_body_container">
                    {/*<Header />*/}
                    {/*<Sidebar />*/}
                    <div className="body_container">
                        <div className="header-text  mb-4 mt-3">
                            <div className="d-flex align-items-center">
                                <p className="header-icon"><SearchIcon /></p>
                                <h4>Answer List</h4>
                            </div>
                            <BackBtn to="questionnaire" />

                        </div>
                        <div className="row">
                            <div className="col-md-12">

                           
                        <div className="rounded-body-form">

                        <div class="table-responsive table-inverse-color">
                            <table class="table vertical-table table-bordered rounded-table inverse-color">
                                <thead>
                                    <tr>
                                        <td style={{width : '40%'}}>Question</td>
                                        <td>{particularQuestionAnswerData && particularQuestionAnswerData[0]?.question}</td>
                                    </tr>
                                    <tr>
                                        <td style={{width : '40%'}}>Question Type</td>
                                        <td>
                                                   {particularQuestionAnswerData && particularQuestionAnswerData[0]?.questionType == 1 &&
                                                    <p>Text answer</p>
                                                    }
                                                    {particularQuestionAnswerData && particularQuestionAnswerData[0]?.questionType == 2 &&
                                                    <p>Multiple choice</p>
                                                    }
                                                    {particularQuestionAnswerData && particularQuestionAnswerData[0]?.questionType == 3 &&
                                                    <p>Single choice</p>
                                                    }
                                        </td>
                                    </tr>
                                    {/*<tr>*/}
                                    {/*    <td style={{width : '40%'}}>Question Field</td>*/}
                                    {/*    <td> {particularQuestionAnswerData && particularQuestionAnswerData[0]?.isMandatory}</td>*/}
                                    {/*    */}
                                    {/*</tr>*/}
                                </thead>
                            </table>
                        </div>
                        <div class="mt-4">
                            <div className="table-responsive">
                            <table className="table table-bordered rounded-table">
                                <thead>
                                    <tr>
                                        {columnDetails.map((item) => {
                                            let { isSortable, dataIndex } = item;

                                            return <th><div className="d-flex ">{item.fieldName}</div></th>

                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                { particularQuestionAnswerData?.map((item ,index)=> ( 
                                <tr>
                                    <td><span className="circle-serial">{(this.state.snNo * limit) + index + 1}</span></td>
                                    <td>{item?.firstName } {item?.lastName }</td>
                                    <td>{item.created?moment(item.created).format("MM/ DD/ YYYY"):""}</td>
                                    <td>
                                        {item.questionType===1?item.plainTextAnswer
                                    : item.optionAnswerArray?.map((i)=> (
                                    <p>{i}</p>
                                    ))
                                    }
                                    </td>
                                                
                                </tr>
                                ))
                                }
                                </tbody>
                            </table>

                            <div>
                                {!isLoader && particularQuestionAnswerData.length == 0 &&
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                            </div>
                            {
                                particularQuestionAnswerData != 0 ?
                                    <div >
                                        <Pagination
                                            totalPage={Math.ceil(this.state.totalCount / this.state.limit)}
                                            currentPage={this.state.page}
                                            onPressBtn={this.handlePageChange}
                                        />
                                    </div>

                                    : ''
                            }
                        </div>
                        </div>
                        </div>
                        
                        </div>
                        </div>
                    </div>
                </div>
                    
          } </>
        );
    }

} export default ViewQuestionAnswerList;

