import React, { Component, Suspense } from 'react';
import { NoMediaICon } from "../../../SvgIcon"
import Footer from "../../layout/Footer";
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import socket from "../../../services/socket";

const LoungeCard = React.lazy(() => import('../components/lounges/LoungeCard'))
const antIcon = <LoadingOutlined style={{ fontSize: 72 }} spin />
var _ = require('lodash')
class Lounges extends Component {

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.carousel = React.createRef();
        this.state = {
            isLoading: true,
            loungList: [],

        }
        window.scrollTo(0, 0)

    }



    componentDidMount() {
        let self = this;
        this.getHomeDetails()
        this.props.dispatch({ type: 'PATH_ADD', payload: this.props.match })

        socket.on('loungeEnter', function (param) {
            let loungList = self.state.loungList;
            let lounge = _.find(loungList, { _id: param.loungeId });
            if (lounge) {
                lounge.onlineJoinUser=param.userCount;
                let index = _.findIndex(loungList, { _id: param.loungeId });
                loungList.splice(index, 1, lounge);
                self.setState({
                    loungList
                })
            }
        })

        socket.on('loungeExist', function (param) {
            let loungList = self.state.loungList;
            let lounge = _.find(loungList, { _id: param.loungeId });
            if (lounge) {
                if(param.userCount>=0){
                    lounge.onlineJoinUser=param.userCount;
                }
                let index = _.findIndex(loungList, { _id: param.loungeId });
                loungList.splice(index, 1, lounge);
                self.setState({
                    loungList
                })
            }
        })

        socket.on('networkLoungeEnter', function (param) {
            console.log("networkLoungeEnter",param)
            let networkLoungList=self.state.loungList;
            let lounge = _.find(networkLoungList, {_id: param.loungeId});
            if(lounge) {
                // console.log("lounge", lounge)
                lounge.onlineJoinUser=param.userCount;
                let index = _.findIndex(networkLoungList, {_id: param.loungeId});
                //networkLoungList.splice(index, 1, lounge);
                networkLoungList[index]=lounge;
                // console.log("######",networkLoungList)
                self.setState({
                    networkLoungList:networkLoungList
                },function (){
                    // console.log("self.state",self.state)
                })
            }
        })

        socket.on('networkLoungeExist', function (param) {
            console.log("networkLoungeExist",param)
            let networkLoungList=self.state.loungList;
            let lounge = _.find(networkLoungList, {_id: param.loungeId});
            if(lounge) {
                // console.log("lounge", lounge)
                if(param.userCount>=0){
                    lounge.onlineJoinUser=param.userCount;
                }

                let index = _.findIndex(networkLoungList, {_id: param.loungeId});
                //networkLoungList.splice(index, 1, lounge);
                networkLoungList[index]=lounge;
                self.setState({
                    networkLoungList:networkLoungList
                },()=>{
                    // console.log("updateee valuee", index)
                })
            }
        })
    }

    componentWillMount(){
        socket.off("loungeEnter");
        socket.off("loungeExist");
        socket.off("networkLoungeEnter");
        socket.off("networkLoungeExist");
    }

    handleError(err) {
        let errorMessage = err.error && err.error.responseMessage || err.message;
        notify("err", errorMessage);
    }

    getHomeDetails = (e, id) => {
        _Api(Url.GET_EXHIBITION_ID.method, Url.GET_EXHIBITION_ID.url, '')
            .then(resp => {
                _Api(Url.GET_WEB_LOUNGE.method, Url.GET_WEB_LOUNGE.url, '', `?exhibitionId=${resp?.responseData?.exhibitionDetails?._id}`)
                    .then(resp => {
                        const { totalCount, result } = resp.responseData
                        this.setState({ isLoading: false, loungList: result, })

                    }).catch(err => {
                        this.setState({ isLoading: false })
                    })

            }).catch((err) => { })
    }


    addToWishList = (category, id, wishListData) => {
        let stateName = `wish${id}`
        this.setState({ [stateName]: !this.state[stateName] }, () => {
            let params = category == "brand" ? { brandId: id } : { productId: id }
            if (this.state[stateName] && !wishListData) {
                _Api(Url["ADD_WISHLIST"].method, Url["ADD_WISHLIST"].url, params)
                    .then(resp => {
                        if (category == "brand") {
                            this.getHomeDetails("FEATURED_BRAND_LIST", "brandListData");
                        }
                        else {
                            this.getHomeDetails("FEATURED_PRODUCT_LIST", "productListData");
                        }

                    }).catch((err) => {
                        this.handleError(err);
                    })
            }
            else {
                _Api(Url["REMOVE_WISHLIST"].method, Url["REMOVE_WISHLIST"].url, params)
                    .then(resp => {
                        if (category == "brand") {
                            this.getHomeDetails("FEATURED_BRAND_LIST", "brandListData");
                        }
                        else {
                            this.getHomeDetails("FEATURED_PRODUCT_LIST", "productListData");
                        }
                    }).catch((err) => {
                        this.handleError(err);
                    })
            }
        }
        );
    }


    next = () => {
        this.carousel.next();
    }
    previous = () => {
        this.carousel.prev();
    }


    handleJoin = (id) => {
        this.props.history.push(`/seminar/${id}`)
    }

    onlyJoin = (name, id, coId, item = {}) => {
        const { numberofAttendees, onlineJoinUser } = item
        if (name == "lounge") {
            if (onlineJoinUser < numberofAttendees)
                window.open('/lounge/' + id, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
            else
                notify("err", "This lounges is full , you can not join at this time")
        }
        else
            this.props.history.push(`/seminar/${coId}/${name}/${id}`)
    }


    render() {
        const { loungList } = this.state
        if (this.state.isLoading)
            return (
                <section className="brand-home-banner-bg webhome">
                    <div className="centerLoader" >
                        <Spin size="large" tip="Loading..." indicator={antIcon} />
                    </div>
                </section>
            )

        else
            return (
                <section className="brand-home-banner-bg">
                    <Suspense fallback={<div></div>}>
                        <div className={"cm-section container-fluid"}>
                            <div className="cm-wrapper home-networking">
                                <div className="header-text">
                                    <h1 className="title">Lounges</h1>
                                    <a > <button onClick={() => this.props.history.goBack()} className="btn btn-light"><i class="fa fa-arrow-left" aria-hidden="true"></i>Back</button></a>
                                </div>
                                <LoungeCard
                                    {...this}
                                    list={loungList}
                                />

                                {
                                    !this.state.isLoading && loungList?.length == 0 &&
                                    <div className="w-100 text-center row nodata m-auto">
                                        <div className="m-auto">
                                            <NoMediaICon />
                                            
                                            <h2>No Data Found</h2>
                                        </div>
                                    </div>
                                }

                            </div>
                            <Footer />
                        </div>
                    </Suspense>
                </section>
            )
    }

}
// export default Lounges;
function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(Lounges);



