import React, { Component } from 'react';
import Footer from '../../layout/Footer';
import { Hert, UserManagementIcon, Pencil, Delete } from '../../../SvgIcon';
import { Link } from "react-router-dom";
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';
import { Modal, Button, Empty } from 'antd';
import { Pagination } from '../../../components/common/Pagination';
import Loader from '../../../components/Loader';
import util from '../../../utils/Util.js';
import moment from 'moment-timezone';
import UserDetails from './UserDetails';
const columnDetails = [{ fieldName: "S.No." },
{ fieldName: "Reported User" },
{ fieldName: "Reported By User" },
{ fieldName: "Reported Message" },
{ fieldName: "Date", isSortable: true, dataIndex: "created" },
{ fieldName: "User Status" }
];


class UserReport extends Component {


    constructor() {
        super();
        this.state = {
            viewDetails: false,
            limit: 10,
            loading: false,
            selectedId: '',
            userReportData: [],
            isLoader: false,
            searchKey: '',
            totalCount: [],
            fieldName: '',
            order: '',
            promoCodeDetailData: [],
            visible: false,
            sortInfo: {},
            snNo: 0,
            page: 1,

        };
    }
    componentDidMount() {
        this.getUserReportList();
    }
    getUserReportList = (downloadValue) => {
        this.setState({ isLoader: true });

        let { page, limit, search, sortInfo } = this.state;
        let { order, fieldName } = sortInfo;
        let params = {
            page: this.state.page,
            limit: this.state.limit,
            download: this.state.download,
            searchKey: this.state.searchKey,
            sortField: fieldName,
            sortType: order
        }
        _Api(Url.USER_REPORT_LIST.method, Url.USER_REPORT_LIST.url, params)
            .then(resp => {
                let responseData = resp.responseData.result;
                this.setState({ userReportData: responseData, totalCount: resp.responseData.totalCount, isLoader: false });
            }).catch(err => {
                this.handleError(err);
            });
    }
    onStatusChange = (e, id) => {
        let param = {
            reportId: id,
            status: (e.target.checked ? 1 : 0).toString()
        }
        _Api(Url.USER_REPORT_STATUS_CHANGE.method, Url.USER_REPORT_STATUS_CHANGE.url, param)
            .then(resp => {
                notify('success', resp.responseData.message);
                this.setState({ visible: false, statusCode: resp.responseData.statusCode });
                this.getUserReportList();
            }).catch(err => {
                this.handleError(err);
            })

    }
    handlePageChange = (page) => {
        this.setState({ loader: true, page, snNo: page - 1, subscriptionListData: [] }, () => {
            this.getUserReportList()
        })

    }
    handleError(err) {
        this.setState({ isLoader: false });
        let errorMessage = err.error && err.error.responseMessage || err.message;
        notify("err", errorMessage);
    }


    onClickSorting = (field) => {
        let { order, fieldName } = this.state.sortInfo;
        let orderType = 1;
        if (order && fieldName === field) {
            orderType = order < 0 ? '' : -1;
        }
        this.setState({
            sortInfo: {
                fieldName: field,
                order: orderType
            }
        }, () => this.getUserReportList());

    }
    togglePopup(value, id) {
        let userDetailData = this.state.userReportData.filter(item => item?._id == id);
        this.setState({ isViewDetails: !this.state.isViewDetails, userDetailData: userDetailData });
    }
    handleSearch = e => this.setState({ searchKey: e.target.value, page: 1, snNo: 0, promoListData: [] }, () => this.getUserReportList());

    render() {
        let { userReportData, search, isLoader, sortInfo, searchKey } = this.state;
        let { order, fieldName } = sortInfo;
        return (
            <>
                <div className="dash_body_container">
                    <Header />
                    <Sidebar />
                    <div className="body_container">
                        <div className="header-text d-flex mb-4 mt-3">
                            <div className="d-flex  flex-grow-1 align-items-center">
                                <p className="header-icon"><UserManagementIcon /></p>
                                <h4>Reported User
                                </h4>
                            </div>

                            <div className="search_wrapper mr-3">
                                <i className="fa fa-search" />
                                <input type="search"
                                    placeholder="Search..."
                                    name='search'
                                    value={searchKey}
                                    onChange={this.handleSearch}

                                />
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered rounded-table">
                                <thead>
                                    <tr>
                                        {columnDetails.map((item) => {
                                            let { isSortable, dataIndex } = item;

                                            return <th><div className="d-flex justify-content-center align-items-center"
                                                onClick={() => this.onClickSorting(dataIndex)}>{item?.fieldName}
                                                {isSortable && <span className="d-flex"><i className={'ml-2 ' + util.getSortingIcon(dataIndex == fieldName && order)}
                                                    aria-hidden="true"
                                                /></span>}</div></th>

                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        userReportData.map((item, index) => (
                                            <tr>
                                                <td><span className="circle-serial">{(this.state.snNo * 10) + index + 1}</span></td>                                               
                                                <td><Link to={this.props.myroute} onClick={this.togglePopup.bind(this, 'viewDetails', item?._id)}>{item?.reportedUserId && `${item?.reportedUserId.firstName && item?.reportedUserId.firstName} ${item?.reportedUserId.lastName && item?.reportedUserId.lastName}`}</Link> </td>
                                                <td> {item?.userId && item?.userId.firstName} {item?.userId && item?.userId.lastName} </td>
                                                <td className="wrap-text-msg">{item?.reportedMsg}</td>
                                                <td>{moment(item?.created.toString()).format("DD/ MM/ YY")}</td>
                                                <td>
                                                    <p className="toggle-parent ">
                                                        <label className="switch">
                                                            <input type="checkbox" checked={item?.status === 1 ? true : false}
                                                                onChange={(e) => this.onStatusChange(e, item?._id)} />
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </p>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        
                        <div>
                            {!isLoader && userReportData.length == 0 &&
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                        </div>
                        {
                            userReportData.length > 0 &&
                            <div >
                                <Pagination
                                    totalPage={Math.ceil(this.state.totalCount / 10)}
                                    currentPage={this.state.page}
                                    onPressBtn={this.handlePageChange}
                                />
                            </div>
                        }
                     </div>
                    </div>
                    {this.state.isViewDetails ?
                        <UserDetails
                            text='Close Me'
                            closePopup={this.togglePopup.bind(this, "viewDetails")}
                            data={this.state.userDetailData}
                        />
                        : null
                    }
                </div>
            </>
        );
    }
}
export default UserReport;