import React, { Component, useState } from 'react';
import { Hert, ExhibitionManagement, Pencil, Delete } from '../../../SvgIcon';
import { Link } from "react-router-dom";
import { DatePicker, TimePicker, Empty } from 'antd';
import moment from 'moment-timezone';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';
import {  Button } from 'react-bootstrap';
import { Modal } from 'antd';

import Loader from '../../../components/Loader'
import { notification } from 'antd';

import {getTimeFormate} from "../../../utils/DateZone"
import { ExclamationCircleOutlined } from '@ant-design/icons';


import {ConferenceDetailsLoader, Pagination} from "../../index"


const { confirm } = Modal;

class ConferenceDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // confirance
            loading: false,
            visible: false,
            conferenceDetails: [],
            limit: 10,
            page: 1,
            totalCount: '',
            isLoader: false,
            snNo: 0

        }
    }

    componentDidMount() {

     
        if (this.props.match.params.exId)
            this.getConferenceList()
    }


    getConferenceList = (isLoader = true) => {
        this.setState({ isLoader: isLoader })
        let { page, limit } = this.state
        let req = {
            page: page ? page : 1,
            limit: limit,
        }

        _Api(Url.GET_CONFERENCE_LIST.method, Url.GET_CONFERENCE_LIST.url, '', `?page=${req.page}&limit=${req.limit}&exhibitionId=${this.props.match.params.exId}`)
            .then(resp => {
                 this.setState({ conferenceDetails: resp.responseData?.result?.data, totalCount: resp.responseData?.result?.totalCount, isLoader: false })

            }).catch(err => {
                this.setState({ isLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
                this.setState({ isLoader: false })
            })
    }

    handlePageChange = page => this.setState({ loader: true, page }, () => this.getConferenceList());

    handleSearch = e => this.setState({ search: e.target.value }, () => this.getConferenceList());



    deleteConferenceList = (id) => {
        let req = {
            isDeleted: 0,
            conferenceId: id,
        }
        _Api(Url.CONFERENCE_LIST_DELETE.method, Url.CONFERENCE_LIST_DELETE.url, '', `?conferenceId=${req.conferenceId}&isDeleted=${req.isDeleted}`)
            .then(resp => {
               
                // notify('success', resp.responseData.message)
                notification.success({ message: 'Success', description: resp.responseData.message });

                this.setState({ visible: false })
                this.getConferenceList(false)
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
            })
    }

    //Show modal
    showModal = (id) => {
        this.setState({
            visible: true,
            selectedId: id
        });

    };
    //Cancel modal
    handleCancel = () => {
        this.setState({ visible: false });
    };



    statusConferenceList = (e, id) => {
        let req = {
            conferenceId: id,
            status: (e.target.checked ? 1 : 0).toString(),
        }
        _Api(Url.CONFERENCE_LIST_STATUS.method, Url.CONFERENCE_LIST_STATUS.url, '', `?conferenceId=${req.conferenceId}&status=${req.status}`)
            .then(resp => {               
                notification.success({ message: 'Success', description: resp.responseData.message });
                this.setState({ visible: false })
                this.getConferenceList(false)
            }).catch(err => {
                if (err.error)
                    notification.error({ message: 'Error', description: err.error.responseMessage });
                // notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
            })
    }


    onPressBtn = (page) => {
        this.setState({ page, snNo: page - 1,conferenceDetails:[] }, () => {
            this.getConferenceList()
        })

    }

     // delete modal
     showDeleteConfirm = (id) => {
        confirm({
            title: 'Are you sure you want to delete this?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            onOk: () => {
                this.deleteConferenceList(id)
            },
            onCancel: () => {
                this.handleCancel()
            },
        });
    }




    render() {
        const { isLoader, loading, visible, conferenceDetails } = this.state

        console.log("dsadjmk", conferenceDetails)



        return (
            <>
               <div className="header-container ">
                                    <div class="header-text d-flex w-100 justify-content-betweenmb-4 mt-3">
                                        <div class="d-flex flex-grow-1 align-items-center">
                                            <h4>Seminars Details</h4>
                                        </div>
                                        <div class="">
                                        <Link to={`/seminardetails/${this.props.match.params.exId}/add-new-seminar`} className="btn btn-red">Add New Seminar</Link>
                                        </div>
                                    </div>
                </div>
                  

                    <div className="table-responsive">
                        <table className="table table-bordered rounded-table">
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>Seminar</th>
                                    <th>Fee</th>
                                    <th>Lounges</th>
                                    <th>Stages</th>
                                    <th>Created Date</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {isLoader && <ConferenceDetailsLoader/>}
                                {
                                    conferenceDetails.map((item, index) => (

                                        <tr key={item._id}>
                                            <td><span className="circle-serial">{(this.state.snNo * 10) + index + 1}</span></td>
                                            <td><Link to={`/seminardetails/${this.props.match.params.exId}/view/${item?._id}`} >{item?.conferenceName} </Link></td>
                                           
                                            <td>{item.isPaid == 1 ?<p><p>{item?.dollarAmount} $</p>  <p>{item?.euroAmount} €</p> </p> : 'Free'}</td>
                                            <td>{item?.loungeData?.length}</td>
                                            <td>{item?.stageData?.length}</td>
                                            <td>{getTimeFormate(item?.created)}</td>
                                            <td>
                                                <p className="toggle-parent ">
                                                    <label className="switch">
                                                        <input type="checkbox"
                                                            checked={item?.status === 1 ? true : false}
                                                            onChange={(e) => this.statusConferenceList(e, item?._id)}
                                                        />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </p>
                                            </td>
                                            <td className="action_group">
                                            <Link to={`/seminardetails/${this.props.match.params.exId}/add-new-seminar/${item?._id}`} ><p><p className="circle-serial"><Pencil /></p></p></Link>

                                                <p onClick={() => this.showDeleteConfirm(item?._id)}><p className="circle-serial"><Delete /></p></p>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        <div>
                            {!isLoader && conferenceDetails.length == 0 &&
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                        </div>
                        {
                            conferenceDetails && conferenceDetails.length != 0 &&
                            <Pagination
                                totalPage={Math.ceil(this.state.totalCount / 10)}
                                currentPage={this.state.page}
                                onPressBtn={this.onPressBtn}
                            />
                        }
                    </div>         


                <Modal show={visible} onHide={this.handleCancel} className="cm_order_view dlt_item">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <img src={require('../../assets/img/Delete-Email.png')} /> */}
                        <br />
                        <br />
                        <p>Are you sure you want to delete this ?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="footer_btn_wrapper btn_nospace">
                            <Button variant="warning" loading={loading} onClick={() => this.deleteConferenceList(this.state.selectedId)} >
                                Yes
                        </Button>
                            <Button variant="dark" onClick={this.handleCancel}>
                                No
                        </Button>
                        </div>
                    </Modal.Footer>
                </Modal>

            </>
        )
    }
}


export default ConferenceDetails;