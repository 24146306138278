import React, { Component } from 'react';


import { Carousel  } from 'antd';
import {Delete, Pencil} from "../../SvgIcon";
import {Link} from "react-router-dom";


class VisitedProductCarousel extends Component {


    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.carousel = React.createRef();
    }


    next = () => {
        this.carousel.next();
    }
    previous = () => {
        this.carousel.prev();
    }

    render() {

        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 1
            // responsive: [
            //     {
            //         breakpoint: 1350,
            //         settings: {
            //             slidesToShow: 4,
            //             slidesToScroll: 1,
            //             infinite: true,
            //             dots: false
            //         }
            //     },
            //     {
            //         breakpoint: 991,
            //         settings: {
            //             slidesToShow: 3,
            //             slidesToScroll:1
            //         }
            //     },
            //     {
            //         breakpoint: 600,
            //         settings: {
            //             slidesToShow: 1,
            //             slidesToScroll: 1
            //         }
            //     }
            // ]

        }
        return (


            <div className="carousel-wrapper">
                <i class="fa fa-long-arrow-left" onClick={this.previous}/>

                <Carousel className="row d-flex" ref={node => (this.carousel = node)} {...settings} >

                    {
                        loop.map(index => {
                            return (

                                <div className="card community-card">

                                    <Link> <img className="img-fluid"
                                                src={require('../../assets/images/imgpsh_fullsize_anim.png')}/>
                                    </Link>
                                    <hr/>
                                    <div className="action_group text-center">
                                        <span className="">Product Name <br/>
                                        <span className="blue">$50</span> </span>

                                    </div>

                                </div>
                            )
                        })

                    }

                </Carousel>

                <i class="fa fa-long-arrow-right" onClick={this.next}/>
            </div>


        )

    }
}
export default VisitedProductCarousel;

const loop = [0, 1, 2, 3, 4, 5, 6, 7]

