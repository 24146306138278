
const isShow = localStorage.getItem("showAll") == "true" ? false : false
let Const = {
    eventDate: new Date("July 25, 2021 08:00:00 GMT").getTime(),
    isHideHome: isShow,
    isHideBrand: isShow,
    isHideCommunity: isShow,
    isHideConference: isShow,
    isHideNetwork: isShow,
    isHideOffers: isShow,
    isHideNewproduct: isShow,
    planPrice: {
        gbpPriceBrand: 750,
        dollarPriceBrand: 1050,
        gbpPricePremium: 1900,
        dollarPricePremium: 2650,
    },
    isHeader: {
        isManageShowCase: true,
        isManageCompany: true,
        isEnquiries: true,
        isNotification:true,
        isSearch: !isShow,
        isChat:true
    },
    webHeader: {
        tab1: "HOME",
        tab2: "SEMINARS",
        tab3: "BUSINESSES",
        tab4: "CONNECTIONS",
        tab5: "NETWORKING",
        tab6: "JOBS",
        tab7: "TRAINING"
    },
    MyCompany: {

    },
    addProduct: {

    },
    manageProfile: {
        h1: "Jobs",
        h2: "Training",
        h3: "Documents"

    },
    home: {
        h1: 'Seminars',
        h2: 'Explore Businesses',
        h3: 'Explore Jobs'
    },
    tab2Body: {
        h1: 'Seminars'

    },
    tab3body: {
        h1: 'Featured Businesses',
        h2: 'All Businesses',
        h3: 'Jobs',
        h4: 'Popular Jobs'
    },
    tab4body: {
        h1: 'Featured Businesses',
        h2: 'All Businesses',
        h3: 'Jobs',
        h4: 'Popular Jobs'
    },
    tab6body: {
        h1: 'Jobs',
        h2: 'Explore by Businesses',
        h3: 'New Jobs',
        h4: 'Popular Jobs'
    },
    tab7body: {
        h1: 'Training',
        h2: 'Explore by Business',

    },
    searchTab: {
        tab1: 'Businesses',
        tab2: 'Jobs',
        tab3: 'People',
        tab4: 'Training'

    }

}

console.log("consst", Const)

export default Const;