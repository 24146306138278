import React, { Component } from 'react';

import { Tick, Hert } from '../../SvgIcon';
import { _Api } from '../../services/Api';
import Url from '../../services/BaseUrl';
import moment from 'moment-timezone'
import { Modal, Button, Select, Checkbox } from 'antd';
import { TreeSelect } from 'antd';
import { getDataAndFilter } from "../../MyFilter";
import Cookies from "js-cookie";
import { notify } from "../../components/Toaster";
import InlineError from "../../components/InlineError";
import {connect} from "react-redux";
const { SHOW_PARENT, SHOW_CHILD } = TreeSelect;

class PaymentSuccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            transactionDetails: '',
            visible: false,
            productAndService: []
        }
    }
    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };
    submitAfterSuccess = () => {
        if (this.props.location.pathname.includes("search")) {
            this.props.history.push('/home');
            //this.props.history.push('/coming-soon')
        }
        else {
            if(localStorage.getItem("showAll")) {
                window.location.href = `/home`;
            }else{
                window.location.href ='/home';
            }
            // this.props.history.push('/coming-soon')
            //this.props.history.push(`/business/profile/${localStorage.getItem("id")}`)
        }

    }
    componentDidMount() {
        if (localStorage.getItem('accessToken') && localStorage.getItem('accessToken') !== '') {
            this.getPaymentDetails();
            //this.getCategoryDetails();
            localStorage.setItem("isComming", "true")
            localStorage.setItem("isPlanBuy", 1)
            const {history} = this.props

        } else {
            this.props.history.push('/')
        }

    }

    getPaymentDetails = () => {
        _Api(Url.GET_TRANSACTION_DETAILS_V2.method, Url.GET_TRANSACTION_DETAILS_V2.url+'?transactionId='+localStorage.getItem("transactionId"))
            .then(resp => {
                this.setState({ transactionDetails: resp.responseData.paymentDetails })
            })
    }

    render() {
        console.log("this.state", this.state);
        let { transactionDetails } = this.state
        const tProps = {
            dropdownClassName: "new_multi_check",
            treeData: this.state.dropDown2,
            value: this.state.productAndService,
            onChange: this.onChange,
            treeCheckable: true,
            // treeCheckStrictly: false,
            showCheckedStrategy: SHOW_CHILD,
            placeholder: 'Please select',
            style: {
                width: '100%',
                border: 'none'
            },
            showSearch: false
        };
        return (
            <>
                <div className="container-fluid  pos-relative payment-sucess-screen">
                    <div className="container ">
                        <div className="update-password text-center">
                            <div className="text-center">
                                {/* <span className="pos-relative heart"><i class="fa fa-heart" aria-hidden="true"></i>
                                    <span className="tick"><Tick /></span>
                                </span> */}
                               <img src={require('../../assets/images/feb-event/logo.png')} className="img-fluid logo-img"  style={{marginBottom: '40px'}}/>
                            </div>
                            <h1 className="home-heading pos-relative ">
                                Payment Successfully
                                    <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                            </h1>
                            <div className="payment-msz-parent">
                                <p><span>Date</span><span>{moment(transactionDetails?.created).format('LLL')}</span></p>
                                <p><span>Transaction ID </span><span>{transactionDetails?.transactionUniqueId} </span></p>
                                {/*<p><span>Payment Method </span><span> {transactionDetails?.transactionObject?.payment_method_details?.card?.brand} </span></p>*/}
                                {/* <p><span> Total</span><span> <img src={require('../../assets/images/card.png')} className="img-fluid" />Visa Credit Card </span></p> */}
                                <p><span>Cost </span><span>{transactionDetails?.paymentType == 1 ? '£' : '$'}{transactionDetails?.amount} </span></p>
                                {transactionDetails?.discountAmount&&transactionDetails?.discountAmount !== 0 &&
                                <p>
                                    <span>Activation code discount </span><span>{transactionDetails?.paymentType == 1 ? '£' : '$'}{transactionDetails?.discountAmount} </span>
                                </p>
                                }

                               {transactionDetails.vatAmount != 0 ? <p>
                                    <span>VAT count</span><span>{transactionDetails.vatAmount !== 0 ? transactionDetails?.paymentType == 1 ? '£'+transactionDetails?.vatAmount : '$'+transactionDetails?.vatAmount:"N/A"} </span>
                                </p>
                                :''
                                }
                                    <p><span>TOTAL PAID </span><span>{transactionDetails?.paymentType == 1 ? '£' : '$'}{transactionDetails?.totalAmount} </span></p>
                            </div>
                            <center> <button type="button" onClick={this.submitAfterSuccess} className="btn btn-red">Done</button></center>

                        </div>

                    </div>

                </div>

            </>

        )
    }
}
// export default PaymentSuccess;
function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(PaymentSuccess);