import React, { Component } from 'react';
import { Select } from 'antd';
const { Option } = Select;
class UserPlans extends Component{
    constructor(){
        super()
        this.state={
            showText: false,
            showLimit: false
        }
    }
    handleOptionChange= (value)=>{
        if(value && (value=='limitation')){
            this.setState({ showLimit: true, showText: false})
        }
        else if(value && (value=='text')){
            this.setState({ showText: true, showLimit: false})
        } else{
            this.setState({ showText: false, showLimit: false})
        }
    }
    render(){
        return(
            <>
            <form className="rounded-textbox-form mt-5">
                <div className="form-group">
                    <label>View Accessible/non-accessible </label>
                    <Select defaultValue="--Select Option--" onChange={this.handleOptionChange}>
                        <Option value="tick">Tick</Option>
                        <Option value="blank">Blank</Option>
                        <Option value="prohibited">Prohibited</Option>
                        <Option value="unlimited">Unlimited</Option>
                        <Option value="limitation">Add Limitation</Option>
                        <Option value="text">Add Text</Option>
                    </Select >
                </div>
                {this.state.showLimit && <div className="addLimt">
                    <div className="form-group">
                        <label>Limited to</label>
                        <select className="form-control">
                            <option>-Select-</option>
                        </select>
                    </div>
                </div> }
                {this.state.showText && <div className="addText">
                    <div className="form-group">
                        <textarea className="form-control" placeholder="Write Something..............">

                        </textarea>
                    </div>
                </div> }
                <div className="form-group text-center">
                    <button type="submit" className="btn btn-red w-100">Submit</button>
                </div>
            </form>
                
            </>
        )
    }
}

export default UserPlans