import React, { Component } from 'react';


import Footer from "../../layout/Footer"
import { Link } from "react-router-dom";
import { Select, Menu, Dropdown, Modal, Tooltip } from "antd";
import { Pagination } from "../../../components";
import FilterHeader from "../../layout/FilterHeader";
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { CategoryManagement, Product, Brands, NoMediaICon, Filter, Cross, NewBadges } from "../../../SvgIcon";
import { notify } from '../../../components/Toaster';
import { connect } from "react-redux";
import { getWishList } from '../../../redux/action';
import { Spinner } from "react-bootstrap";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import LazyLoad from 'react-lazy-load';
import { AllProductLoader } from '../../../components/index'

import Thankyou from "../../home/HomeCommingSoon"
import Const from "../../../services/Const"

const { Option } = Select;


const antIcon = <LoadingOutlined style={{ fontSize: 72 }} spin />;


const {isHideNewproduct, tab6body} = Const

class Newproduct extends Component {

    constructor(props) {
        super(props)
        this.state = {
            page: 1,
            limit: 16,
            loading: false,
            isLoader: false,
            search: '',
            snNo: 0,
            sortInfo: {},
            industryData: [],
            totalCount: 1,
            productListData: [],
            brandListData: [],
            tempBrand: [],
            brandIds: [],
            industryIds: [],
            mostVisitedProduct: [],
            featuredProduct: [],
            childCategoryIds: [],
            isAsidevisible: false,
            searchBrand: '',
            uniqueState: []

        }
        localStorage.removeItem("filters")
        localStorage.setItem("productType", 2)

    }
    state = { visible: false, asidevisible: false };

    showModal = (stateKey, value) => {
        this.setState({
            visible: true,
            asidevisible: true,
            modalData: value,
            stateKey: stateKey
        });
    };

    handleOk = e => {
        console.log(e);
        this.setState({
            visible: false,
            asidevisible: false,
        });
    };

    handleCancel = e => {
        console.log(e);
        this.setState({
            visible: false,
            asidevisible: false
        });
    };
    componentDidMount() {


        console.log("dlkjhghj", this.props.match.params.pageNo)










        this.props.dispatch({ type: 'PATH_ADD', payload: this.props.match })
        window.scrollTo(0, 0);
        if (this.props.match.params.id) {
            if (this.props.match.params.id) {
                this.setState({ [`isExpand${this.props.match.params.id}`]: true });
            }
        }
        // this.getProductList();
        this.getList1("GET_USER_LIST", "brandListData");
        this.getList1("FEATURED_PRODUCT_LIST", "product", "mostVisitedProduct");
        this.getList1("FEATURE_PRODUCT_WEB", "featuredProduct");
        this.getCategory();

        if (this.props.match.params.pageNo && !isNaN(this.props.match.params.pageNo)) {
            this.setState({ page: Number(this.props.match.params.pageNo), snNo: Number(this.props.match.params.pageNo) - 1 }, () => {
                this.getProductList();
            })
        }
        else {
            this.getProductList();
        }






    }












    getCategory = () => {
        let params = {
            categoryType: "4",
        }
        _Api(Url.GET_PRODUCT_CATEGORY_LIST.method, Url.GET_PRODUCT_CATEGORY_LIST.url, params)
            .then(resp => {
                this.setState({ industryData: resp.responseData?.result });
                this.getProductList();

            }).catch(() => {

            })
    }
    handleError(err) {
        let errorMessage = err.error && err.error.responseMessage || err.message;
        notify("err", errorMessage);
    }

    getList1 = (api, stateData, visited) => {
        let { sortField, sortType } = this.state;
        let req = {
            page: 1,
            limit: 300,
            sortField: sortField,
            sortType: sortType


        }

        let url = Url[api]?.url, method = Url[api]?.method;

        _Api(method, url, '', `?page=${req.page}&limit=${req.limit}&status=1`)
            .then(resp => {
                if (stateData == "brandListData") {
                    this.setState({ [stateData]: resp.responseData?.result?.data, tempBrand: resp.responseData?.result?.data, [visited]: resp.responseData?.mostVisited })
                    let num = resp.responseData?.result?.data?.filter(item => item?.userPlan == 3)
                    let brandNum = this.getRandom(num, num.length)
                    this.setState({ uniqueState: brandNum })
                }
                else
                    this.setState({ [stateData]: resp.responseData?.result?.data, [visited]: resp.responseData?.mostVisited })

            }).catch((err) => {
                this.handleError(err);
            })
    }




    /** brands start */
    getList = (data) => {
        console.log("skjSKLJS", data)
        const { servicesShowcaseId = "", sectorName = "" } = data
        let { appLocalState } = this.props
        // this.setState({ isLoader: isLoader })
        let { page, limit, search, brandIds, industryIds, sortField, sortType, childCategoryIds } = this.state
        let req = {
            page: page ? page : 1,
            limit: limit,
            search: appLocalState.search || search.toString(),
            productBrandId: brandIds ? brandIds.join() : "",
            productCategory: industryIds ? industryIds.join() : "",
            manageCategory: servicesShowcaseId,
            manageSector: sectorName,
            sortField: sortField,
            sortType: sortType,
            isNewProduct: localStorage.getItem('productType') ? Number(localStorage.getItem('productType')) : 2,

        }
        _Api(Url.FILTER_PRODUCT_LIST.method, Url.FILTER_PRODUCT_LIST.url, req)
            .then(resp => {
                this.setState({ productListData: resp.responseData?.result?.data, totalCount: resp.responseData?.result?.totalCount, isLoader: false })

            }).catch(() => {
                this.setState({ isLoader: false });
            })

    }

    getProductList = (isLoader = true) => {
        console.log("kjhghbjn", this.state)
        let { appLocalState } = this.props
        this.setState({ isLoader: isLoader })
        let { page, limit, search, brandIds, industryIds, sortField, sortType, childCategoryIds } = this.state
        let req = {
            page: page ? page : 1,
            limit: limit,
            search: appLocalState.search || search.toString(),
            productBrandId: brandIds ? brandIds.join() : "",
            productCategory: industryIds ? industryIds.join() : "",
            // manageCategory : manageCategory,
            // manageSector: manageSector,
            sortField: sortField,
            sortType: sortType,
            isNewProduct: localStorage.getItem('productType') ? Number(localStorage.getItem('productType')) : 2,

        }
        _Api(Url.FILTER_PRODUCT_LIST.method, Url.FILTER_PRODUCT_LIST.url, req)
            .then(resp => {
                this.setState({ productListData: resp.responseData?.result?.data, totalCount: resp.responseData?.result?.totalCount, isLoader: false })

            }).catch(() => {
                this.setState({ isLoader: false });
            })

    }
    onPressBtn = (page) => {
        // window.scrollTo(0,1000);getProductList
        window.scrollTo(0, 0)
        this.props.history.push(`/product/${page}`)
        this.setState({ page, snNo: page - 1, brandListData: [] }, () => {
            this.getProductList()
        })

    }
    handleChange(value) {
        this.setState({ sortField: "productName", sortType: Number(value.key) }, () => this.getProductList());
    }


    addToWishList = (category, id, e) => {
        let stateName = `wish${id}`;
        this.setState({ [`isWishlistLoader${id}`]: true, [stateName]: !this.state[stateName], });
        let self = this;
        let params = category == "brand" ? { brandId: id } : { productId: id };
        let { method, url } = Url[e];
        _Api(method, url, params)
            .then(() => {
                self.props.dispatch(getWishList());

                this.getProductList(false);

                setTimeout(() => {
                    this.setState({ [`isWishlistLoader${id}`]: false });

                }, 2000);


            }).catch((err) => {
                this.handleError(err);
            })
    }
    handleSearch = e => this.setState({ search: e.target.value, brandListData: [], page: 1, snNo: 0 }, () => this.getProductList());

    handleSelect = (e, id, type) => {
        if (e.target.checked) {
            type == "brand" && this.state.brandIds.push(id) || this.state.industryIds.push(id);
        }
        else {
            if (type == "brand") {
                var index = this.state.brandIds.indexOf(id);
                if (index > -1) {
                    this.state.brandIds.splice(index, 1);
                }
            }
            else {
                var index = this.state.industryIds.indexOf(id);
                if (index > -1) {
                    this.state.industryIds.splice(index, 1);
                }
            }


        }
        let checkboxFiield = `${type}Checkbox${id}`;
        this.setState({ [checkboxFiield]: e.target.checked }, () => this.getProductList());


    }
    onLinkClick = (value, scope) => {
        _Api(Url.SEARCH_COUNT.method, Url.SEARCH_COUNT.url)
            .then(() => {


            }).catch(() => {
                // scope.handleError(err);
            });
        scope.props.history.push(value);
    }
    onClickOnExpand = (id) => {
        this.setState({ [`isExpand${id}`]: !this.state[`isExpand${id}`] })

    }

    onReset = () => {
        // alert("okkkk")
        this.setState({ productCategory: '', productBrandId: '', })
        window.location.reload()

    }
    handlesidenavbar = () => {
        this.setState({ isAsidevisible: true })
    }

    handleSelect1 = (a, b, c) => {
        let self = this
        this.setState({ brandIds: a }, this.getProductList())


    }

    onChangeSearch = (e) => {
        let val = String(e.target.value).toLocaleLowerCase()
        this.setState({ searchBrand: val })
        this.setState({
            brandListData: this.state.tempBrand?.filter(item => {
                let char = String(item.companyName).toLocaleLowerCase()
                return char.includes(val)
            }
            )
        })


    }


    // handleSelect = (e, id, type) => {
    //     if (e.target.checked) {
    //         type == "brand" && this.state.brandIds.push(id) || this.state.industryIds.push(id);
    //     }
    //     else {
    //         if (type == "brand") {
    //             var index = this.state.brandIds.indexOf(id);
    //             if (index > -1) {
    //                 this.state.brandIds.splice(index, 1);
    //             }
    //         }
    //         else {
    //             var index = this.state.industryIds.indexOf(id);
    //             if (index > -1) {
    //                 this.state.industryIds.splice(index, 1);
    //             }
    //         }


    //     }
    //     let checkboxFiield = `${type}Checkbox${id}`;
    //     this.setState({ [checkboxFiield]: e.target.checked }, () => this.getProductList());


    // }









    handlesidenavbarOpen = () => document.body.classList.add('aside_open');
    handlesidenavbarClose = () => {

        document.body.classList.remove('aside_open');
    }

    getRandom = (arr, n = 5) => {
        var result = new Array(n),
            len = arr.length,
            taken = new Array(len);
        if (n > len)
            throw new RangeError("getRandom: more elements taken than available");
        while (n--) {
            var x = Math.floor(Math.random() * len);
            result[n] = arr[x in taken ? taken[x] : x];
            taken[x] = --len in taken ? taken[len] : len;
        }
        return result;
    }





    render() {

        let { featuredProduct, isLoader, productListData, industryData, brandListData, mostVisitedProduct, modalData } = this.state
        console.log("this state data===", this.state);
        const isShow = localStorage.getItem("showAll") == "true"

        let num = brandListData.filter(item => item.userPlan == 3)
        let brandNum = this.getRandom(num, num.length)
        console.log("dsahdhjk", brandNum)
        if (isHideNewproduct)
        return <Thankyou />
        return (

            <section className="brand-home-banner-bg">

                {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
                {/*<ChatBox history={this.props.history} match={this.props.match} />*/}
                <FilterHeader scope={this} setSelected={"Jobs"} ishideHeader={true} productCustomfilter={true}
                    isProductTab={true}

                    productReset={this.state.brandIds.length > 0 || this.state.industryIds.length > 0}
                    onReset={() => this.onReset()}
                />


                <div className="container-fluid cm-section">
                    <div className="cm-wrapper featured-brands showcase">
                        <div className="row mt-4">
                            <aside className="col-xl-3 d-none  d-xl-block">

                                <div className="table-responsive mb-4">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th><span><Brands /></span>Explore By Businessess</th>

                                            </tr>
                                        </thead>
                                        <div className="text-center pt-2 pb-2">
                                            <input
                                                className="search_wrapper search-type "
                                                placeholder="Enter here"
                                                onChange={this.onChangeSearch}
                                            />
                                        </div>
                                        <tbody>
                                            {
                                                this.state.searchBrand != "" ?
                                                    brandListData.map((item, i) => (
                                                        i < 5 && < tr >
                                                            <td>
                                                                <div className="check_wrapper">
                                                                    <input type="checkbox" value="option1"
                                                                        onChange={(e) => this.handleSelect(e, item._id, "brand")}
                                                                        checked={this.state.brandIds.indexOf(item._id) != -1} />
                                                                    <span />
                                                                </div>
                                                                {item.companyName}

                                                            </td>
                                                        </tr>
                                                    ))
                                                    :

                                                    this.state.uniqueState.map((item, i) => (
                                                        i < 5 && < tr >
                                                            <td>
                                                                <div className="check_wrapper">
                                                                    <input type="checkbox" value="option1"
                                                                        onChange={(e) => this.handleSelect(e, item._id, "brand")}
                                                                        checked={this.state.brandIds.indexOf(item._id) != -1} />
                                                                    <span />
                                                                </div>
                                                                {item.companyName}

                                                            </td>
                                                        </tr>
                                                    ))


                                            }
                                            <tr>
                                                <td ><a href="#" className="cursor-point" onClick={() => this.showModal("brand", "tempBrand")}>view all</a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>


                                <div className="table-responsive mb-4">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th colSpan="2"><span><Product /></span>Feature Jobs</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <div className="product-card-wrapper  p-0">
                                                <div className="row m-0 p-3">
                                                    {
                                                        featuredProduct.map((item, index) => (
                                                            index < 5 &&
                                                            <div className="two-div">
                                                                {/* <LazyLoad> */}
                                                                <div className="card community-card wrapper">
                                                                    <Link to={`/web-job-details/${item?._id}`}>
                                                                        <img className="img-fluid" src={item?.image || require('../../../assets/images/placeholder.png')} />
                                                                        <hr />
                                                                        <div className="action_group text-center">
                                                                            <Tooltip placement="top" title={item?.productName}>
                                                                                <p className="mb-0 wrap-p">{item?.productName} <br /></p>
                                                                            </Tooltip>
                                                                        </div>

                                                                    </Link>
                                                                </div>
                                                                {/* </LazyLoad> */}
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="table-responsive mb-4">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th colSpan="2"><span><Product /></span>Most Visited Jobs</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <div className="product-card-wrapper  p-0">
                                                <div className="row m-0 p-3">
                                                    {
                                                        mostVisitedProduct.map((item, index) => (
                                                            index < 5 &&
                                                            <div className="two-div">
                                                                {/* <LazyLoad> */}
                                                                <div className="card community-card wrapper">
                                                                {item?.productData?.isNewProduct == 1 && <div className="newBadge">
                                                <p> New<span className="traingle"><NewBadges /></span></p>
                                            </div>}
                                                                    <Link to={`/web-job-details/${item?.productData?._id}`}>
                                                                        <img className="img-fluid" src={item?.productData.image || require('../../../assets/images/placeholder.png')} />
                                                                        <hr />
                                                                        <div className="action_group text-center">
                                                                            <Tooltip placement="top" title={item?.productData.productName}>
                                                                                <p className="mb-0 wrap-p">{item?.productData.productName}<br /></p>
                                                                            </Tooltip>
                                                                        </div>

                                                                    </Link>
                                                                </div>
                                                                {/* </LazyLoad> */}
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </tbody>
                                    </table>
                                </div>

                            </aside>

                            {/* --------------aside for responsive start--------------- */}
                            <div className="col-xl-3 d-flex flex-row align-items-center mb-2 d-xl-none">
                                <div className="cummunity-filter"> <div className="profile" onClick={(e) => this.handlesidenavbarOpen()}><span className="filter"><Filter /></span></div> </div>
                                <span>Filter by business</span>
                            </div>

                            <aside className="asidebar">
                                <div className="cross float-right pr-3" onClick={(e) => this.handlesidenavbarClose()}>
                                    <Cross />
                                </div>
                                {/* <div className="table-responsive explore-by-ndustry mb-4">
                           <table className="table table-bordered ">
                               <thead>
                                   <tr>
                                       <th><span><CategoryManagement /></span>Explore By Sectors</th>
                                   </tr>
                               </thead>
                               <tbody>
                                   {
                                       industryData.map((item) => (
                                           item?.level == 1 && <>
                                               <tr >
                                                   <td className="pl-3 cursor-point">
                                                       <h5 onClick={() => { this.onClickOnExpand(`${item._id}`) }}><i className={this.state[`isExpand${item?._id}`] ? "fa fa-caret-down" : "fa fa-caret-right"} aria-hidden="true">&nbsp;</i> {item.categoryTitle}</h5>                                                                
                                                       <span />
                                                   </td>
                                               </tr>
                                               {this.state[`isExpand${item?._id}`] && item.subCategory.map((item) =>
                                                   this.getSubCategoryData(item))}
                                           </>


                                       ))
                                   }


                               </tbody>
                              
                           </table>
                       </div> */}

                                <div className="table-responsive mb-4">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th><span><Brands /></span>Explore By Brands</th>

                                            </tr>
                                        </thead>
                                        <input
                                            className="search_wrapper search-type  mt-2 mb-2"
                                            placeholder="Enter here"
                                            onChange={this.onChangeSearch}
                                        />
                                        {/* <Select
                                            showSearch
                                            mode="multiple"
                                            className="cm_multiselect cmslect_multicheck"
                                            style={{ width: '100%', margin: '10px 0', padding: '0 10px' }}
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            onChange={(a, b, c) => this.handleSelect1(a, b, c)}
                                        >
                                            {
                                                brandListData?.map((item, index) => {
                                                    return (
                                                        <Option value={item._id}>{item.companyName}</Option>
                                                    )
                                                })
                                            }

                                        </Select> */}
                                        <br />
                                        <tbody>
                                            {
                                                brandListData.map((item, i) => (
                                                    i < 5 && < tr >
                                                        <td>
                                                            <div className="check_wrapper">
                                                                <input type="checkbox" value="option1"
                                                                    onChange={(e) => this.handleSelect(e, item._id, "brand")}
                                                                    checked={this.state.brandIds.indexOf(item._id) != -1} />
                                                                <span />
                                                            </div>
                                                            {item.companyName}

                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                            <tr>
                                                <td ><a href="#" className="cursor-point" onClick={() => this.showModal("brand", "brandListData")}>view all</a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>


                                <div className="table-responsive mb-4">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th colSpan="2"><span><Product /></span>Feature Jobs</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <div className="product-card-wrapper  p-0">
                                                <div className="row m-0 p-3">
                                                    {
                                                        mostVisitedProduct.map((item, index) => (
                                                            index < 5 &&
                                                            <div className="two-div">
                                                                <LazyLoad>
                                                                    <div className="card community-card wrapper">
                                                                        <Link to={`/web-job-details/${item?.productData?._id}`}>
                                                                            <img className="img-fluid" src={item?.productData.image || require('../../../assets/images/placeholder.png')} />
                                                                            <hr />
                                                                            <div className="action_group text-center">
                                                                                <Tooltip placement="top" title={item?.productData.productName}>
                                                                                    <p className="mb-0 wrap-p">{item?.productData.productName} <br /></p>
                                                                                </Tooltip>
                                                                            </div>

                                                                        </Link>
                                                                    </div>
                                                                </LazyLoad>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="table-responsive mb-4">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th colSpan="2"><span><Product /></span>Most Visited Jobs</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <div className="product-card-wrapper  p-0">
                                                <div className="row m-0 p-3">
                                                    {
                                                        mostVisitedProduct.map((item, index) => (
                                                            index < 5 &&
                                                            <div className="two-div">
                                                                <LazyLoad>
                                                                    <div className="card community-card wrapper">
                                                                    {item?.productData?.isNewProduct == 1 && <div className="newBadge">
                                                <p> New<span className="traingle"><NewBadges /></span></p>
                                            </div>}
                                                                        <Link to={`/web-job-details/${item?.productData?._id}`}>
                                                                            <img className="img-fluid" src={item?.productData.image || require('../../../assets/images/placeholder.png')} />
                                                                            <hr />
                                                                            <div className="action_group text-center">
                                                                                <Tooltip placement="top" title={item?.productData.productName}>
                                                                                    <p className="mb-0 wrap-p">{item?.productData.productName} <br /></p>
                                                                                </Tooltip>
                                                                            </div>

                                                                        </Link>
                                                                    </div>
                                                                </LazyLoad>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </tbody>
                                    </table>
                                </div>

                            </aside>

                            {/* } */}

                            {/* --------------aside for responsive end--------------- */}
                            <main className="col-xl-9 web-view-job">
                                <header className="mb-4">
                                    <div className="d-flex flex-wrap break-header">
                                        <div className="flex-grow-1">
                                            <div className="red-border">
                                                <h1 className="mb-0">Jobs</h1>
                                                {/* <span>Showing 1 - 12 of 16 Results</span> */}
                                            </div>
                                        </div>
                                        {/* <div className="mr-4">
                                            <div className="search_wrapper" style={{ maxWidth: '100%' }}>
                                                <i className="fa fa-search" />
                                                <input type="search"
                                                    placeholder="Search by Product"
                                                    name='search'
                                                    onChange={this.handleSearch}
                                                />
                                            </div>
                                        </div> */}
                                        <div>
                                            <div className="rounded-textbox-form filter-select  pt-0 p-0">


                                                {/* <div className="dropdown "> */}
                                                {/* <Select placeholder="Sorting" onChange={(e) => this.handleChange(e)}>
                                                    <Option value={1}>Product Name (A-Z)</Option>
                                                    <Option value={-1}>Product Name (Z-A)</Option>

                                                </Select> */}

                                                {/* ---------new sorting design start -------------- */}
                                                <Dropdown overlay={
                                                    <Menu onClick={(e) => this.handleChange(e)}>
                                                        <Menu.Item key="1">
                                                            <a > Job Name (A-Z)
                                                        </a>
                                                        </Menu.Item>
                                                        <Menu.Item key="-1">
                                                            <a >  Job Name (Z-A)
                                                        </a>
                                                        </Menu.Item>

                                                    </Menu>
                                                } placement="bottomRight">
                                                    <span className="sorting-dropdown"><i class="fa fa-sort-amount-desc" aria-hidden="true"></i></span>
                                                </Dropdown>
                                                {/* ---------new sorting design end -------------- */}


                                                {/* <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                    <button className="dropdown-item" type="button">Action</button>
                                                    <button className="dropdown-item" type="button">Another action</button>
                                                    <button className="dropdown-item" type="button">Something else here
                                                  </button>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </header>

                                <div className="product-card-wrapper">


                                    <div className="row">
                                        {
                                            productListData.map(item => (
                                                <div className="four-div">
                                                    <div className="card community-card wrapper cursor-point new-product">
                                                        <LazyLoad>
                                                            <div onClick={() => this.onLinkClick(`/web-job-details/${item._id}`, this)}>
                                                                <img className="img-fluid" src={item.image || require('../../../assets/images/placeholder.png')} />
                                                                {/* <Tooltip placement="bottom" title={item?.brandId?.companyName}>
                                                                    <div className="top-left">
                                                                        {item.brandId?.companyName && <button className="mb-auto btn btn-brand btn-card">{item.brandId?.companyName}</button>}

                                                                    </div> 
                                                                </Tooltip> */}


                                                            </div>

                                                        </LazyLoad>
                                                        {item?.isNewProduct == 1 && <div className="newBadge">
                                                            <p> New<span className="traingle"><NewBadges /></span></p>
                                                        </div>}

                                                        <div className="top-right">
                                                            {
                                                                < div className={(item?.wishListData) && "blue-circle red-circle" || "blue-circle"} onClick={() => this.addToWishList('product', item?._id, item?.wishListData && "REMOVE_WISHLIST" || "ADD_WISHLIST")}>
                                                                    {this.state[`isWishlistLoader${item?._id}`] &&
                                                                        <Spinner className="text-center" size="xl" animation="border" variant="primary" /> || <i className="fa fa-heart-o" ></i>}

                                                                </div>}

                                                        </div>
                                                        <hr />
                                                        <div className="action_group ">
                                                            <Tooltip placement="top" title={item.productName}>
                                                                <h6 className="wrap-p2 w-100">{item.productName} <br />
                                                                    {/* <span className="blue">${item.price ? item.price : 0}</span> */}
                                                                </h6>
                                                                <hr className="w-100 mb-2" />
                                                                {item.brandId?.companyName &&
                                                                    <h6 className="wrap-p2 w-100"><i><Brands /></i>{item.brandId?.companyName}</h6>
                                                                }
                                                            </Tooltip>


                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                        {/* {isLoader &&
                                            <div className="centerLoader" >
                                                <Spin size="large" tip="Loading..." indicator={antIcon} />
                                            </div>
                                            <AllProductLoader/>

                                        } */}

                                    </div>
                                    {!isLoader && productListData?.length == 0 && <div className="w-100 text-center row nodata m-auto">
                                        <div className="m-auto">
                                            <NoMediaICon />

                                            <h2>No Job Found</h2>
                                        </div>                    </div>
                                    }

                                    {
                                        productListData.length > 0 &&
                                        <div >
                                            <Pagination
                                                totalPage={Math.ceil(this.state.totalCount / 16)}
                                                currentPage={this.state.page}
                                                onPressBtn={this.onPressBtn}
                                            />
                                        </div>
                                    }

                                </div>
                            </main>
                        </div>

                    </div>
                </div>


                <Footer />

                <Modal
                    title={`Explore By ${this.state.stateKey == "industry" ? "Industry" : "Brand"}`}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    className="viewpopup-product"
                    zIndex="99999"
                >
                    <div className="mt-0 mb-0 ml-0 mr-0">
                        <div className="table-responsive mb-4">
                            <table className="table table-bordered">
                                {/* <thead>
                                            <tr>
                                                <th><span><Brands /></span>Explore By Brand</th>
                                            </tr>
                                        </thead> */}
                                <tbody>
                                    {
                                        this.state[modalData] && this.state[modalData].map(item => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <div className="check_wrapper">
                                                            <input type="checkbox" value="option1"
                                                                onChange={(e) => this.handleSelect(e, item._id, this.state.stateKey)}
                                                                checked={this.state[`${this.state.stateKey}Checkbox${item._id}`]} />
                                                            <span />
                                                        </div>
                                                        {item.companyName || item.categoryTitle}

                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }

                                </tbody>
                            </table>

                            {!isLoader && this.state[modalData]?.length == 0 && <div className="w-100 text-center row nodata m-auto">
                                <div className="m-auto">
                                    <NoMediaICon />

                                    <h2>No Data Found</h2>
                                </div>                    </div>
                            }


                        </div>
                    </div>
                </Modal>




            </section >




        )
    }

    getSubCategoryData(item) {
        if (item.parentCategoryId === this.props.match.params.id && this.state[`industryCheckbox${item._id}`] == undefined && this.state.industryIds.indexOf(item?._id) == -1) {
            this.state.industryIds.push(item._id);

        }
        return <tr className="form-check form-check-inline brandcategory-list">
            <td className="">
                <input type="checkbox" value="option1"
                    className="form-check-input"
                    onChange={(e) => this.handleSelect(e, item._id, "industry")}
                    checked={this.state[`industryCheckbox${item._id}`] || this.state.industryIds.includes(item._id)} />
                <span />
                <label className="form-check-label"
                    htmlFor="inlineCheckbox2">{item?.categoryTitle}</label></td>
        </tr>;
    }
}
function mapStateToProps(state) {
    return {
        wishList: state.wishList,
        appLocalState: state.appLocalState
    };
}

export default connect(mapStateToProps)(Newproduct);
