import React, { Component } from 'react';
import { Hert } from '../../../../SvgIcon';
import { Link } from "react-router-dom"
import {Modal, Select} from 'antd';
import { notify } from '../../../../components/Toaster';
import Loader from '../../../../components/Loader';
import PhoneInput from 'react-phone-input-2';

//validation
import { validateEmail } from '../../../../components/Validation';
//validation message
import InlineError from '../../../../components/InlineError'
// components
import {
    MyAlert,
    MySpinner
} from "../../../../components/index"

import {connect} from "react-redux";

import {SIGNUP_PROCESS, TOGGLE_LOGIN, TOGGLE_SIGNUP} from "../../../../redux/action";

const { Option } = Select;


class AddBrandStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            password: '',
            email: '',
            phoneNumber:'',
            companyName:'',
            emailError: '',
            firstNameError: '',
            lastNameError: '',
            passwordError: '',
            phoneNumberError:'',
            userTypeError: '',
            companyNameError:'',
            isLoader: false,
            showMessage: false,
            isPasswordShow: false,
            brandType:false
        }
    }
    componentDidMount() {

    }

    toggleSignup=(value)=> {
        this.props.dispatch({ type: TOGGLE_SIGNUP, value: value });
    }

    toggleLogin=(value)=> {
        this.props.dispatch({ type: TOGGLE_LOGIN, value: value });
    }

    toggleSignupProcess=(value)=> {
        this.props.dispatch({ type: SIGNUP_PROCESS, value: value });
    }


    render() {
        console.log("this.props",this.props);
        let { loading, firstName, lastName, email, firstNameError, passwordError, password, lastNameError, emailError ,companyName,companyNameError,phoneNumberError} = this.props;

        return (
            <>
                    <div className="sign-modal-content">
                        <div className="row align-items-center">
                            <div className="col-md-12" >
                                  
                                    <h3 className="auth-heading pos-relative text-center mt-5">
                                        Add Business
                                    <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                   </h3>
                                {/*<p className="home-subheading text-center">Attendance is free, if you’re a brand or supplier please register to see exhibiting options </p>*/}
                                <form className="rounded-textbox-form mt-5" onSubmit={this.props.signUpSubmit}>
                               
                                <div className="form-group">
                                        {
                                            this.props.showMessage &&
                                            <MyAlert
                                                message={this.props.message}
                                                type={this.props.erroStatus}
                                            />
                                        }
                                        <label>First Name</label>
                                        <input type="text"
                                            autoComplete="new-password"
                                            className="form-control"
                                            placeholder="First Name"
                                            id="pwd"
                                            maxLength={64}
                                               name='firstName'
                                           value={firstName}
                                           onChange={this.props.sugnUpOnChange}
                                        />
                                        <InlineError
                                            message={firstNameError}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Last Name</label>
                                        <input type="text"
                                               autoComplete="do-not-show-ac"
                                            className="form-control"
                                            placeholder="Last Name"
                                               maxLength={64}
                                               name='lastName'
                                               value={lastName}
                                               onChange={this.props.sugnUpOnChange}
                                        />
                                       <InlineError
                                            message={lastNameError}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Company Name</label>
                                        <input type="text"
                                               autoComplete="new-password"
                                               className="form-control"
                                               placeholder="Company name "
                                               id="companyName"
                                               maxLength={64}
                                               name='companyName'
                                               value={companyName}
                                               onChange={this.props.sugnUpOnChange}
                                        />
                                        <InlineError
                                            message={companyNameError}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Phone number</label>
                                            <PhoneInput
                                                country={'ae'}
                                                placeholder="Phone number"
                                                enableLongNumbers="false"
                                                autoFormat="false"
                                                autoComplete="do-not-show-ac"
                                                value={this.props.phoneNumber}
                                                onChange={(phoneNumber, code) => this.props.setPhoneNumber(phoneNumber,code)}
                                            />
                                        <InlineError
                                            message={phoneNumberError}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Email Address</label>
                                        <input type="text"
                                               autoComplete="do-not-show-ac"
                                               className="form-control"
                                               placeholder="Email Address "
                                               id="email"
                                               maxLength={250}
                                               name='email'
                                               value={email}
                                               onChange={this.props.sugnUpOnChange}
                                        />
                                        <InlineError
                                            message={emailError}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Brand Type</label>
                                        <div className="assign-checkboc-parent">
                                            <div>
                                                <label className="cm_container" onClick={() => this.props.stateSet('brandType',false)}>
                                                    Brand showcase
                                                    <input checked={!this.props.brandType}  type="radio" name="isManYes" />
                                                    <span className="radio-checkmark"></span>
                                                </label>
                                            </div>
                                            <div>
                                                <label className="cm_container" onClick={() => this.props.stateSet('brandType',true)}>
                                                    Premium brand showcase
                                                    <input checked={this.props.brandType}  type="radio" name="isManYes" />
                                                    <span className="radio-checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className="form-group pos-relative">*/}
                                    {/*    <input type={this.state.isPasswordShow ? 'text' : 'password'}*/}

                                    {/*           autoComplete="do-not-show-ac"*/}
                                    {/*           className="form-control"*/}
                                    {/*           placeholder="Password "*/}
                                    {/*           id="password"*/}
                                    {/*           maxLength={50}*/}
                                    {/*           name='password'*/}
                                    {/*           value={password}*/}
                                    {/*           onChange={this.props.sugnUpOnChange}*/}
                                    {/*    />*/}
                                    {/*     <div className="password-show">*/}
                                    {/*     {*/}
                                    {/*            this.props.isPasswordShow ?*/}
                                    {/*            <a onClick={()=> this.setState({isPasswordShow: !this.state.isPasswordShow})} ><i className="fa fa-eye" aria-hidden="true"></i> </a>*/}
                                    {/*                :*/}
                                    {/*                <a onClick={()=> this.setState({isPasswordShow: !this.state.isPasswordShow})}><i className="fa fa-eye-slash" aria-hidden="true"></i></a>*/}
                                    {/*           */}
                                    {/*        }*/}
                                    {/*    </div>*/}
                                    {/*    <InlineError*/}
                                    {/*        message={passwordError}*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                    <div className="text-center">
                                        <button type="submit" disabled={this.props.isLoading} className="btn btn-red"> &nbsp;Submit
                                             &nbsp;
                                        {this.props.isLoading &&
                                                <MySpinner
                                                />}
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>

                    </div>

            </>

        )
    }
}
function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(AddBrandStep);