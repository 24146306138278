import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';
import { Tab, Tabs } from 'react-bootstrap'
import { BackBtn } from '../../../components/common/Button';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';
import WebHeader from "../../layout/WebHeader";
import { connect } from "react-redux";
import { ZoomVideo, Chat } from "../../../SvgIcon";
import { Menu, Dropdown, Button } from 'antd';
import { BtnRed, CmBtn } from "../../../components/common/Button";

import ReportModal from '../../web/community/ReportUserModal';



import { groupActions } from "../../../redux/action";
import socket from "../../../services/socket";



// import { Tabs } from 'antd';


import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons'


let isProd = process.env.REACT_APP_ENV == 'prod'
const antIcon = <LoadingOutlined style={{ fontSize: 72 }} spin />

class CommunityUserProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userData: [],
            connectionStatus: "",
            startLoader: false,

        }
    }

    componentDidMount() {
        this.props.dispatch({ type: 'PATH_ADD', payload:this.props.match })
        this.getUserProfile();
    }

    handleError(err) {
        this.setState({ isLoader: false });
        let errorMessage = err.error && err.error.responseMessage || err.message;
        notify("err", errorMessage);
    }

    getUserProfile = () => {
        let req = {
            requestUserId: this.props.match.params.id || localStorage.id || '',
        }
        _Api(Url.GET_CONNECTION_PROFILE.method, Url.GET_CONNECTION_PROFILE.url, '', `?requestUserId=${req.requestUserId}`)
            .then(resp => {
                this.setState({ userData: resp.responseData.result, connectionStatus: resp.responseData.connectionStatus });

            }).catch(err => {
                this.setState({ isLoader: false });
                let errorMessage = err.error && err.error.responseMessage || err.message;
                notify("err", errorMessage);
            });
    }
    onMoreClick = (e) => {
        switch (e.key) {
            case "Remove":
                this.onRemoveAndBlock("COMMUNITY_CONNECTION_REMOVE", 3);
                this.props.history.push('/community');
                break;
            case "Block":
                this.onRemoveAndBlock("COMMUNITY_CONNECTION_BLOCK", 1);
                this.props.history.push('/community');
                break;
            case "AddBusinessConnection":
                this.onCreateBussinessRequest("AddBusinessConnection");
                break;
            case "Report":
                this.showModal();
                break;


        }
    }
    showModal = () => {
        this.setState({
            isReport: true
        });
    };

    onRemoveAndBlock = (api, status) => {
        let req = {
            requestId: this.props.match.params.connectionId,
            status: status
        }
        _Api(Url[api].method, Url[api].url, req)
            .then(resp => {
                this.getUserProfile();
            }).catch((err) => {
                this.handleError(err);
            })

    }
    onCreateBussinessRequest = () => {
        let req = {
            requestedUserId: this.props.match.params.id,
            connectionType: 2
        }
        _Api(Url.COMMUNITY_CREATEREQUEST.method, Url.COMMUNITY_CREATEREQUEST.url, req)
            .then(resp => {
                notify("success", resp.responseData.message);
                this.getUserProfile();
                notify(resp.responseData.message);
            }).catch((err) => {
                this.handleError(err);
            })
    }
    getPathName = (value) => {
        if (value.includes("/connection-profile"))
            return "connection";
        else if (value.includes("/pending-profile"))
            return "pending";
        else if (value.includes("/suggested-profile"))
            return "suggested";
        else if (value.includes("/requested-profile"))
            return "requested";

    }
    onAcceptReject(e, requestId, status) {
        e.preventDefault();
        let req = {
            requestId: requestId,
            status: status
        }
        let { connectionId, id } = this.props.match.params;
        _Api(Url.COMMUNITY_ACCEPTREJECT.method, Url.COMMUNITY_ACCEPTREJECT.url, req)
            .then(resp => {
                notify("success", resp.responseData.message);
                this.props.history.push(`/connection-profile/${id}/connection/${connectionId}`);
                this.getUserProfile();

            }).catch((err) => {
                this.handleError(err);
            })

    }
    onConnectRequest(e, requestId, status) {
        e.preventDefault();
        let req = {
            requestedUserId: requestId,
            connectionType: 1
        }
        _Api(Url.COMMUNITY_CREATEREQUEST.method, Url.COMMUNITY_CREATEREQUEST.url, req)
            .then(resp => {
                notify("success", resp.responseData.message);
                this.getUserProfile();
            }).catch((err) => {
                this.handleError(err);
            })

    }


    selectUser = (user) => {
        let self = this;
        // console.log("user",user);
        if (this.props.userDetail._id !== user._id) {
            this.setState({
                selectedUser: user,
                selectedUserId: user._id,
                chatType: 3,
                isShow: true
            }, function () {
                self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 3 })
                self.props.dispatch({ type: 'CHAT_OPEN' })
                document.body.classList.toggle('chat_open_mob');
                self.props.dispatch({ type: 'SELECTED_USER', selectedUser: user })
                socket.emit('groupAdd', { type: 7, userId: user._id });
                //socket.emit('userConnection', {userId: user._id});
            })
        }
    }



    videoCalling = (userId) => {
        let self = this;

        if (userId) {
            this.setState({ startLoader: true })
            self.setState({
                ['videoConnectionLoder_' + userId]: true
            })
            self.props.dispatch(groupActions.joinOneMeeting({ userId: userId, type: 7 }))
                .then(function (res) {
                    self.setState({
                        ['videoConnectionLoder_' + userId]: false, startLoader: false
                    })
                    if (res.statusCode === 1 || res.statusCode === "1") {
                        //window.location.href = '/calling/' + self.props.groupDetail.meeting._id;
                        window.open('/calling/'+self.props.groupDetail.meeting._id,"professionalbeautyworldwide","width="+window.screen.width+",height="+window.screen.height)
                        //history.push('/calling/'+self.props.groupDetail.meeting._id)
                    } else {
                        this.setState({ startLoader: false })

                    }
                    console.log("res", res)
                    this.setState({ startLoader: false })
                })
        }
    }
















    render() {
        let { userData } = this.state;
        let { companyName } = userData;
        let isConnectionProfile = true;
        let requestType = this.props.history.location.pathname.includes("requested");
        let userType = localStorage.getItem("userType")

        // if (isProd)
        // return (
        //     <section className="brand-home-banner-bg webhome">
        //         // {userType != 1 &&{/* <WebHeader history={this.props.history} match={this.props.match} /> */}}               
        //         <div className="centerLoader" >
        //             {
        //                 isProd ? 
        //                 <h1><b>This page will be live on the 8th September, 2020. </b></h1>
        //                 :
        //                 <Spin size="large" tip="Loading..." indicator={antIcon} />

        //             }                      
                   
        //         </div>
        //     </section>


        // )

        // else


        return (
            <>
                <div className={this.props.userDetail && (this.props.userDetail.userType === 1 || this.props.userDetail.userType === "1") ? 'dash_body_container' : 'dash_body_container without-sidenav'} >
                    {
                        <>
                           {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
                           {/*     <ChatBox history={this.props.history} match={this.props.match} />*/}
                        </>
                    }
                    <div className="body_container profile-body">
                        <div className="">
                            <div className="rounded-body card-wrapper">
                                {this.props.match.params.id && isConnectionProfile && <Detail {...this.props} startLoader={this.startLoader} selectUser={this.selectUser} videoCalling={this.videoCalling} data={userData} scope={this} connectionStatus={this.state.connectionStatus} onMoreClick={this.onMoreClick} connectionId={this.props.match.params.connectionId} requestType={requestType} />}

                            </div>
                        </div>

                    </div>
                </div>

            </>
        );
    }
}
// export default UserProfile;
function mapStateToProps(state) {
    console.log("kdjhsgad", state)
    return {
        ...state
    };
}

export default connect(mapStateToProps)(CommunityUserProfile);

const Detail = (props) => {
    const CommunityMenu = (props) => {
        return (<Menu onClick={props.onMoreClick} >
            {props.isRemove && <Menu.Item key="Remove"> Remove Connection</Menu.Item>}
            <Menu.Item key="Block">Block</Menu.Item>
            <Menu.Item key="Report">Report</Menu.Item>
            {/* {props.isBusinessRequest && < Menu.Item key="AddBusinessConnection">Send business request</Menu.Item>} */}
        </Menu>)
    }
    let { _id, firstName, lastName, companyName, jobTitleOther, city, country, phoneNumber, email, image, productAndServiceOfferId, attendingOrLookingforId, streetAddress1, zipCode, placeOfWorkId, jobTitleId, budgetId, noOfPeopleWorkId, userType } = props.data;
    return (

        <>
            <div className="table-wrapper">
                <a > <button onClick={() => props.history.goBack()} className="btn btn-light cursor-point float-right"><i class="fa fa-arrow-left" aria-hidden="true"></i>Back</button></a>
                <div className="profile-icon-wrapper pos-relative image-chat chat-profile">
                    <img className="img-fluid" src={image || require('../../../assets/images/login_user.png')} />
                    {props.connectionStatus === 1 && <span className="chat" onClick={() => props.selectUser(props.data)} ><Chat /></span>}
                    <input type="file" />
                </div>
                <br />


                <div className="self-detail">
                    <h4 className="text-center mb-2">{firstName} {lastName}</h4>
                    <p>{jobTitleOther ? jobTitleOther : ''}</p>
                    <p className="pb-3">{companyName ? companyName : ''}</p>

                    {(!props.requestType && (props.connectionStatus === 4)) && <p className="text-center mb-4 pb-3"><span className="action_group  ">
                        <CmBtn text="Connect" className="btn btn-blue" bg='var(--btncolor)' onClick={(e) => props.scope.onConnectRequest(e, _id, 1)} />

                    </span>
                        <Dropdown overlay={<CommunityMenu onMoreClick={props.onMoreClick} />} placement="bottomCenter">
                            <button className="btn btn-blue" >More</button>
                        </Dropdown>
                    </p>
                    }

                    {(!props.requestType && (props.connectionStatus === 1 || props.connectionStatus?.status === 1)) && <p className="text-center mb-4 pb-3"><span className="action_group  ">
                        {/* <button className="btn btn-blue" onClick={() => props.videoCalling(_id)} ><span className="zoom"><ZoomVideo /></span>Video Call</button> */}
                        <Dropdown overlay={<CommunityMenu isBusinessRequest={true} isRemove={true} onMoreClick={props.onMoreClick} />} placement="bottomCenter">
                            <button className="btn btn-blue" >More</button>
                        </Dropdown>

                    </span>
                    </p>
                    }
                    {((props.connectionStatus === 0 || props.connectionStatus?.status === 0) && props.connectionStatus.requestedUserId == props.userDetail._id) && <p className="text-center mb-4 pb-3"><span className="action_group  ">
                        <CmBtn text="Cancel Request" className="btn btn-blue" bg='var(--btncolor)' onClick={(e) => props.scope.onRemoveAndBlock2("COMMUNITY_CONNECTION_REMOVE", 3, props?.connectionStatus?._id)} />

                    </span>
                        <Dropdown overlay={<CommunityMenu onMoreClick={props.onMoreClick} />} placement="bottomCenter">
                            <button className="btn btn-blue" >More</button>
                        </Dropdown>
                    </p>
                    }

                    {!props.requestType && (props.connectionStatus === 2 || props.connectionStatus === 3) && <p className="text-center mb-4 pb-3"><span className="action_group  ">
                        <CmBtn text="Connect" className="btn btn-blue" onClick={(e) => props.scope.onConnectRequest(e, _id, 1)} />
                        <Dropdown overlay={<CommunityMenu onMoreClick={props.onMoreClick} />} placement="bottomCenter">
                            <button className="btn btn-blue">More</button>
                        </Dropdown>
                    </span>
                    </p>
                    }
                    {((props.connectionStatus === 0 || props.connectionStatus?.status === 0) && props.connectionStatus.requestedUserId != props.userDetail._id) && <p className="text-center mb-4 pb-3"><span className="action_group">
                        <CmBtn text=" Accept" className="btn btn-blue" bg='var(--btncolor)' onClick={(e) => props.scope.onAcceptReject(e, props.connectionStatus._id, 1)} />
                        <CmBtn text="Ignore" className="btn btn-blue" bg="#fff" color="#A3AAB0" border="1px solid #91cbd3" onClick={(e) => props.scope.onAcceptReject(e, props.connectionStatus._id, 2)} />

                    </span>
                    </p>
                    }
                </div>     

                <div class="header-text text-left self-deatils-table">
                    <h1 class="title">Work Details</h1>

                </div>
                <div className="table-responsive vertical-table">
                    <table className="table table-bordered rounded-table">

                    <ul className="detail_row">
                            <li>Place of work</li>
                            <li>{placeOfWorkId?.categoryTitle}</li>
                            <li>Product & Service you offer</li>
                            <li>{productAndServiceOfferId?.map((item, index) => <p key={index}>{item?.categoryTitle}</p>)}</li>
                        </ul>
                        <ul className="detail_row">
                            <li>Job Title</li>
                            <li>{jobTitleId?.categoryTitle}</li>
                            <li>Why are your attending & what are you looking for ?</li>
                            <li>{attendingOrLookingforId?.map((item, index) => <p key={index}>{item?.categoryTitle}</p>)}</li>
                            </ul>
                        <ul className="detail_row">
                            <li>No of person work with</li>
                            <li>{noOfPeopleWorkId?.categoryTitle}</li>
                            <li>What is your budget to spend in next 6 months ?</li>
                            <li>{budgetId?.categoryTitle}</li>
                            </ul>
                        {/* <tr>
                            <td></td>
                            <td></td>
                            <td>City</td>
                            <td>{city}</td>
                        </tr> */}

                    </table>
                </div>


                {props.scope.state.isReport &&

                    <ReportModal
                        text='Close Me'
                        userData={props.data}
                        closePopup={() => { props.scope.setState({ isReport: false }) }}

                    />

                }
            </div>

        </>

    )
}