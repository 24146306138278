import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Sidebar from '../../../layout/Sidebar';
import Header from '../../../layout/Header';
import TimeTablePopup from '../popups/TimeTablePopup';
import { BackBtn } from '../../../../components/common/Button';



import { Tab, Tabs, } from 'react-bootstrap';
import { DatePicker, TimePicker, Select } from 'antd';
import { Pencil, Delete, UploadVideo, ExhibitionManagement, CircleCross } from '../../../../SvgIcon';
import { _Api } from '../../../../services/Api'
import Url from '../../../../services/BaseUrl'
import InlineError from '../../../../components/InlineError';
import { notify } from '../../../../components/Toaster';
import moment from 'moment-timezone';
import { validateEmail } from '../../../../components/Validation'
import Cookies from 'js-cookie';
import ReactPlayer from 'react-player';

import { Modal, Button, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import Croper from "../../../../components/Croper";

import TimeZone from "../../../../utils/timeZone"
import Loader from '../../../../components/Loader'

const { confirm } = Modal;

const { RangePicker } = DatePicker

const { Option } = Select;


// import { AddNewConference } from '../../../SvgIcon'

const disabledDate = (current) => {
    // Can not select days before today and today
    return current && moment(current.valueOf()) < moment();
}

class AddStages extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            stageName: '',
            stageNameError: '',
            conferenceId: '',
            url: '',
            urlError: '',
            stageType: '',
            stageTypeError: '',
            startDate: moment(),
            endDate: moment(),
            startTime: moment(),
            endTime: moment(),
            numberofAttendees: '',
            loungeNameError: '',
            numberofAttendeesError: '',
            startTimeError: '',
            endDateError: '',

            setOrderError: '',
            selectedFile: '',
            file: null,
            loading: false,
            visible: false,
            media: [],
            isStage: false,
            isLoading: false,
            spekerData: [],
            viewStageDetails: '',
            isEdit:false,
            streamUrl:'',
            streamUrlError:'',
            isCropper: false,
            timezone: moment.tz.guess(),

        }
    }
    // this.props.match.params.exId

    handleOnChange = (type, e) => {
        console.log('qwerty', e)
        this.setState({ [type]: e, startDateError: '', stageTypeError: '', endDateError: '', startTimeError: '', endTimeError: "", liveDateError: '', liveTimeError: '', streamUrlError:'' })
    }

    stageOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, stageNameError: '', numberofAttendeesError: '', startTimeError: '', endDateError: '', setOrderError: '', streamUrlError:'' })
    }

    componentDidMount() {


        if (this.props.match.params.stId) {
            console.log("ckjhddd", this.props.match.params.stId)
            this.setState( {isEdit: true})
            this.getParticularStage(true)

        }

    }

    AddStages = () => {
        if (this.state.isEdit)
            this.editStage()
        else
            this.submitStag()

    }

    getParticularStage = (status = false) => {
        let req = {
            stageId: this.props.match.params.stId
        }
        _Api(Url.GET_PARTICULAR_STAGE.method, Url.GET_PARTICULAR_STAGE.url, '', `?stageId=${this.props.match.params.stId}`)
            .then(resp => {
                console.log('cbjdbcjkbc', resp.responseData)

                const {streamUrl,timezone, image, speakerData, stageName, setOrder, startDate, endDate, stageUrl, startTime, endTime } = resp.responseData.result

                this.setState({
                    timezone: timezone ? timezone :  moment.tz.guess(),
                    isStage: true,
                    streamUrl,
                    profilePicPreview: image,
                    viewStageDetails: resp.responseData.result,
                    spekerData: speakerData,
                    stageName, stageType: setOrder, startDate: moment(new Date(startDate)), endDate: moment(new Date(endDate)), url: stageUrl, startTime: moment(new Date(startTime)), endTime: moment(new Date(endTime))
                })
            }).catch(err => {
            })
    }




    submitStag = () => {
        let {streamUrl, selectedFile, conferenceId, media, url, stageType, stageName, setOrder, startDate, endDate, startTime, endTime, numberofAttendees } = this.state

        // console.log("poiuhgbjnckuherjn", this.props)

        try {
            if (stageName == '')
                this.setState({ stageNameError: '*Please enter stage name.' })
            // else if (stageType == '')
            //     this.setState({ stageTypeError: '*Please select order.' })
            else if (startDate == '')
                this.setState({ startDateError: '*Please enter start date.' })
            else if (startTime == '')
                this.setState({ startTimeError: '*Please enter start time.' })
            else if (endDate == '')
                this.setState({ endDateError: '*Please enter end date.' })
            else if (endTime == '')
                this.setState({ endTimeError: '*Please enter end time.' })
            else {
                this.setState({ isLoading: true })
                let formData = new FormData()
                formData.append("conferenceId", this.props.match.params.coId)
                formData.append("exhibitionId", localStorage.getItem('exhibition'))               
                formData.append("stageName", stageName)
                formData.append("streamUrl", streamUrl)
                // formData.append("setOrder", stageType)
                formData.append("startDate", (Date.parse(startDate)).toString())
                formData.append("endDate", (Date.parse(endDate)).toString())
                formData.append("startTime", startTime)
                formData.append("endTime", endTime)
                formData.append("image", media)
                 formData.append( "timezone",this.state.timezone)
                _Api(Url.ADD_STAGES.method, Url.ADD_STAGES.url, formData)
                    .then(resp => {
                        // console.log('resp1111111', resp.responseData.stageDetails._id)
                        // this.props.history.push(`/add-stages/${this.props.match.params.coId}/${resp.responseData.stageDetails._id}`)
                        this.props.history.goBack()
                        this.setState({ isLoading: false, isStage: false }, () => {
                            Cookies.set('data_stage', JSON.stringify(this.state))
                        })

                        localStorage.setItem('stagid', resp.responseData.stageDetails._id)

                        // this.props.history
                        // this.props.history.goBack()
                        this.getParticularStage(true)
                        notify('success', resp.responseData.message)
                    }).catch(err => {
                        this.setState({ isLoading: false })
                        if (err.error)
                            notify("err", err.error.responseMessage)
                        // else if (err)
                            // notify("err", err.message)
                    })
            }



        } catch  { }

    }



    editStage = () => {
        let {streamUrl, selectedFile, conferenceId, media, url, stageType, stageName, setOrder, startDate, endDate, startTime, endTime, numberofAttendees } = this.state
        try {
            if (stageName == '')
                this.setState({ stageNameError: '*Please enter stage name.' })           
            if (startDate == '')
                this.setState({ startDateError: '*Please enter start date.' })
           else if (startTime == '')
                this.setState({ startTimeError: '*Please enter start time.' })
           else if (endDate == '')
                this.setState({ endDateError: '*Please enter end date.' })
           else if (endTime == '')
                this.setState({ endTimeError: '*Please enter end time.' })
            else {
                this.setState({ isLoading: true })
                let formData = new FormData()
                formData.append("conferenceId", this.props.match.params.coId)
                formData.append("stageId", this.props.match.params.stId)
                formData.append("stageName", stageName)
                formData.append("streamUrl", streamUrl)
                // formData.append("setOrder", stageType)
                formData.append("startDate", (Date.parse(startDate)).toString())
                formData.append("endDate", (Date.parse(endDate)).toString())
                formData.append("startTime", startTime)
                formData.append("endTime", endTime)
                formData.append("image", media)
                 formData.append( "timezone",this.state.timezone)
                _Api(Url.EDIT_STAGES.method, Url.EDIT_STAGES.url, formData)
                    .then(resp => {
                        // console.log('resp1111111', resp.responseData.stageDetails._id)
                        // this.props.history.push(`/add-stages/${this.props.match.params.coId}/${resp.responseData.stageDetails._id}`)
                        this.setState({ isLoading: false, isStage: false }, () => {
                            Cookies.set('data_stage', JSON.stringify(this.state))
                        })
                        this.props.history.goBack()

                        // localStorage.setItem('stagid', resp.responseData.stageDetails._id)

                        // this.props.history
                        // this.props.history.goBack()
                        // notify('success', resp.responseData.message)
                        this.getParticularStage(true)
                    }).catch(err => {
                        this.setState({ isLoading: false })
                        if (err.error)
                            notify("err", err.error.responseMessage)
                        // else if (err)
                            // notify("err", err.message)
                    })
            }



        } catch  { }

    }








    handleChange = (e) => {
        console.log('console', e.target.files)
        this.setState({ selectedFile: e.target.files, file: URL.createObjectURL(e.target.files[0]) })

    }
    handleImageUpload = (event) => {
        console.log(event, "eventeventevent", event.target.files[0])
        let reader = new FileReader();
        let file = event.target.files[0];
        let { valiDate } = this.state;

        if (file) {
            reader.readAsDataURL(file)
        }
        if (file) {
            console.log(file, "media1")
            if (file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png' || file.type === 'image/svg+xml') {

                reader.addEventListener("load", () => {
                    this.setState({ profilePicPreview: reader.result }, function () {
                    })
                }, false);

                if (file.size > 2000000) {
                    console.log("lkjhgfdsdfghjklkjhgfghjkmnbvccvbnm>>>>>>>>>>>>>>>>>>")
                    valiDate = false;
                    console.log(event, "media", event.target.files[0])
                    this.setState({
                        media: event.target.files[0],
                        profilePicErrorMsg: 'Profile pic size should be less than 5Mb.'
                    });
                } else {
                    console.log("media3hhhjshs", event.target.files[0], file.size)
                    this.setState({
                        media: event.target.files[0],
                        mediaError: ''
                    });
                    // alert("wrong file")
                }
            } else {
                valiDate = false;
                this.setState({ media: 'File type not supported.' })
            }
        }
    }


    componentWillUnmount() {
        // Cookies.remove('data_stage')
    }

    finalCropedImage = (categoryImgPrev, categoryImg, visibleVal) => {
        this.setState({
            visibleVal,
            isCropper: false,
            profilePicPreview: categoryImgPrev,
            media: categoryImg

        })
      
    }


    onChangeDate = (value, dateString) => {      
        if (value)
            this.setState({ startDate: value[0] , startTime: value[0],  endDate: value[1], endTime: value[1] }, () => {
                console.log("abcdefghij", this.state.startDate, this.state.endDate)
            })
    }

    handleChangeTimeZone = (value) => {
        // moment.tz.setDefault(value)
       console.log("dkjhasghdjk",  moment.tz(this.state.startDate, this.state.timezone).utcOffset() )
        this.setState({ timezone: value })
    }




    render() {
        console.log('getDatatatat', this.state.media)
        let {streamUrl,streamUrlError, viewStageDetails, stageType, endDate, stageName, stageNameError, url, urlError, stageTypeError, startDate, startDateError, startTime, startTimeError, endTime, endTimeError, endDateError } = this.state
       
        const disabledDateEnd = (current) => {
            // Can not select days before today and today
            return current && current.valueOf() < startDate;
        }
        return (
            <>
                {this.state.isLoading && <Loader />}
                <div className="dash_body_container">
                    <div className="body_container">
                        <div className="header-container">
                            <div className="d-flex align-items-center">
                                <p className="header-icon"><ExhibitionManagement /></p>
                                <h4>{
                                    this.state.isEdit ? 'Edit Stage Details' : 'Add Stage Details'}
                                <br />
                                   

                                </h4></div>

                            <a > <button onClick={() => this.props.history.goBack()} className="btn btn-light"><i class="fa fa-arrow-left" aria-hidden="true"></i>Back</button></a>
                        </div>

                        <div className="rounded-body-form">
                            


                                    <form className="cm-form-wrapper add-manag">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <level>Stage Name</level>
                                                    <input type="text"
                                                        className="form-control"
                                                        placeholder="Stage Name"
                                                        maxLength={64}
                                                        name="stageName"
                                                        value={stageName}
                                                        onChange={this.stageOnChange}
                                                    />
                                                    <InlineError
                                                        message={stageNameError}
                                                    />


                                                </div>

                                            </div>
                                            <div className="col-md-6">

                                            <div className="form-group">
                                            <level>Stream url</level>
                                            <input                                           
                                                className="form-control"
                                                placeholder="Enter stream url"                                                
                                                name="streamUrl"
                                                value={streamUrl}
                                                onChange={this.stageOnChange}
                                            />
                                            <InlineError
                                                message={streamUrlError}
                                            />

                                        </div>
                                            </div>
                                        </div>

                                        <div className="row">

                                        <div className="col-md-6">
                                        <level>Time Zone</level>

                                        <div className="form-group">
                                            <Select showSearch autoComplete="new-password" placeholder="Time zone"
                                                value={this.state.timezone}
                                                defaultValue={this.state.timezone}

                                                onChange={(e) => this.handleChangeTimeZone(e)} name='country'

                                            >
                                                {TimeZone.map((item) => {
                                                    return (<Select.Option value={item}>{item}</Select.Option>)
                                                })}

                                            </Select>

                                        </div>

                                    </div>
                                    <div className="col-md-6">
                                        <level>Start, End date and time</level>



                                        <div className="form-group">
                                            <RangePicker
                                                showTime={{ format: 'HH:mm' }}    
                                                inputReadOnly                                            
                                                value={[moment.tz(startDate, this.state.timezone), moment.tz(endDate, this.state.timezone)]}
                                                format="DD-MM-YYYY HH:mm"
                                                onChange={this.onChangeDate}
                                            // onOk={onOk}
                                            />
                                            <InlineError
                                                message={startDateError}
                                            />
                                        </div>




                                    </div>

                                        </div>
                                        <div className="row">                                          
                                            <div className="col-md-6">
                                                {/* <span className="or-label">Or</span> */}
                                                <level>Cover image</level>
                                                <div className="form-group custom-upload-file">
                                                    <ul className="list-inline mt-3 pt-1">
                                                        <li className="list-inline-item">
                                                            <div className="upload-inner" onClick={() => this.setState({isCropper:true})} >
                                                                {
                                                                    this.state.profilePicPreview ?
                                                                        <img onClick={() => this.setState({isCropper:true})} src={this.state.profilePicPreview} />
                                                                        : <span className="upload-icon"><UploadVideo /></span>
                                                                }

                                                                {/* <input accept="image/*" ref="fileUploader" type="file" className="d-block" placeholder="Enter Video Link"
                                                                    onChange={this.handleImageUpload}

                                                                /> */}
                                                            </div>
                                                            {/* <level>Maximum Size : 128 gb</level> */}
                                                        </li>


                                                    </ul>
                                                </div>


                                                <div className="add-video">
                                                </div>
                                            </div>
                                        </div>

                                        <div className="text-right pt-4">
                                            <button type='button' onClick={this.AddStages} className="btn btn-primary">Save</button>
                                            {/* <Link target="_blank" to="" className="btn btn-primary">Save</Link> */}
                                        </div>
                                    </form>


                                    {this.state.isCropper ?
                    <Croper aspect={2.5 / 2.5}
                        // onImageCrop={img => this.setState({ media: img })}
                        imgPrev={"1-1.jpg"}
                        finalCropedImage={(categoryImgPrev, categoryImg, visibleVal) => this.finalCropedImage(categoryImgPrev, categoryImg, visibleVal)} visibleVal={this.state.isCropper}
                        onClose={() => this.setState({ isCropper: false })}
                    />
                    : ""}


                            {/* <div className="blue-border card-wrapper">

                                <Tabs defaultActiveKey="speaker" id="uncontrolled-tab-example">
                                    <Tab eventKey="speaker" title="Speaker">
                                        <Speaker getParticularStage={() => this.getParticularStage()} speakerData={this.state.spekerData} {...this.props} isAdd={this.state.isStage} />
                                    </Tab>
                                    <Tab eventKey="timetable" title="Timetable">
                                        <Timetable speakerData={this.state.spekerData} {...this.props} isAdd={this.state.isStage} />
                                    </Tab>

                                </Tabs>
                            </div> */}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default AddStages;



class Speaker extends React.Component {

    constructor() {
        super();
        this.state = {
            showPopup: false,
            speakerDetails: [],
            visible: false,
            loading: false,
            speakerviewData: {},
            isEdit: false

        };
    }
    togglePopup(item, isEdit=false) {
        // console.log('sdfghj', item);

        this.setState({
            showPopup: !this.state.showPopup, speakerviewData: item, isEdit: isEdit
        });
        this.props.getParticularStage()
        if (this.props.isAdd)
            this.getSpeakerDetails()

    }
    componentDidMount() {
        if (this.props.isAdd)
            this.getSpeakerDetails()

    }



    // delete modal
    showDeleteConfirm = (id) => {
        confirm({
            title: 'Are you sure you want to delete this?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            onOk: () => {
                this.deleteSpeaker(id)
            },
            onCancel: () => {
                this.handleCancel()
            },
        });
    }



    getSpeakerDetails = () => {
        _Api(Url.GET_SPEAKER_LIST.method, Url.GET_SPEAKER_LIST.url, "", "?stageId=" + "" + this.props.match.params.stId)
            .then(resp => {
                console.log('resp1111111', resp?.responseData?.result?.data)
                this.setState({ speakerDetails: resp?.responseData?.result?.data })
                // this.props.history.push(`/seminardetails/${this.props.match.params.exid}/networking`)
                // this.props.history.goback()
            }).catch(err => {

            })

    }


    deleteSpeaker = (id) => {
        let req = {
            isDeleted: 0,
            speakerId: id,

        }
        _Api(Url.USER_DELETE.method, Url.USER_DELETE.url, '', `?requestUserId=${req.speakerId}&isDeleted=${req.isDeleted}&userType=3`)
            .then(resp => {
                // console.log('delete', resp.responseData.message)
                notify('success', resp.responseData.message)
                this.setState({ visible: false })
                this.getSpeakerDetails()
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
            })


    }

    //Show modal
    showModal = (id) => {
        this.setState({
            visible: true,
            selectedId: id
        });

    };
    //Cancel modal
    handleCancel = () => {
        this.setState({ visible: false });
    };





    render() {
        let { speakerDetails, visible, loading } = this.state
        const { speakerData } = this.props
        console.log("kjhdsgvshbn", this.props.speakerData)
        return (
            <div className="pl-4 pr-4">
                {this.props.isAdd && <div className="text-right">
                    <button className="btn btn-red" onClick={this.togglePopup.bind(this, false)}>Add Speaker</button>
                </div>
                }

                <div className="table-responsive mt-4">
                    <table className="table table-bordered rounded-table">
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>Speaker Name</th>
                                <th>Bio</th>
                                <th>Job Profile</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                speakerData?.map((item, index) => (
                                    <tr>
                                        <td><span className="circle-serial">{index + 1}</span></td>
                                        <td>{item?.firstName} {item?.lastName}</td>
                                        <td>{item?.about ? item?.about : 'NA'}</td>
                                        <td>{item?.jobTitleOther}</td>
                                        <td className="action_group">
                                            <p onClick={() => this.togglePopup.bind(this)(item, true)}><p className="circle-serial"><Pencil /></p></p>
                                            <p onClick={() => this.showDeleteConfirm(item?._id)}><p className="circle-serial"><Delete /></p></p>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                <Modal show={visible} onHide={this.handleCancel} className="cm_order_view dlt_item">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <img src={require('../../assets/img/Delete-Email.png')} /> */}
                        <br />
                        <br />
                        <p>Are you sure you want to delete this ?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="footer_btn_wrapper btn_nospace">
                            <Button variant="warning" loading={loading} onClick={() => this.deleteSpeaker(this.state.selectedId)} >
                                Yes
                        </Button>
                            <Button variant="dark" onClick={this.handleCancel}>
                                No
                        </Button>
                        </div>
                    </Modal.Footer>
                </Modal>

                <div className="text-right pt-4">
                    {/* <button to="" className="btn btn-primary">Save</button> */}
                </div>
                {this.state.showPopup ?
                    <StagePopup
                        {...this.props}
                        text='Close Me'
                        closePopup={this.togglePopup.bind(this)}
                        speakerviewData={this.state.speakerviewData}
                        isEdit={this.state.isEdit}

                    />
                    : null
                }
            </div>

        )
    }
}


//
class Timetable extends Component {
    constructor() {
        super();
        this.state = {
            showPopup: false,
            visible: false,
            loading: false,
            timeTableDetails: [],
            timeTable: {},
            isEdit: true
        };

    }
    togglePopup(item, isEdit) {
        this.setState({
            showPopup: !this.state.showPopup,
            timeTable: item,
            isEdit: isEdit
        });
        this.getTimeTableDetails()
    }

    componentDidMount() {
        if (this.props.match.params.stId)
            this.getTimeTableDetails()
    }
    getTimeTableDetails = () => {
        _Api(Url.GET_TIME_TABLE_LIST.method, Url.GET_TIME_TABLE_LIST.url, "", "?page=1&limit=100&stageId=" + "" + this.props.match.params.stId)
            .then(resp => {
                console.log('aaaaaaaaaaaaa', resp?.responseData?.result?.data)
                this.setState({ timeTableDetails: resp?.responseData?.result?.data })
                // this.props.history.push(`/seminardetails/${this.props.match.params.exid}/networking`)
                // this.props.history.goback()

            }).catch(err => {
            })

    }


    deleteTimeTable = (id) => {
        let req = {
            isDeleted: 0,
            timeTableId: id,
        }
        _Api(Url.DELETE_TIME_TABLE.method, Url.DELETE_TIME_TABLE.url, '', `?timeTableId=${req.timeTableId}&isDeleted=${req.isDeleted}`)
            .then(resp => {
                console.log('delete', resp.responseData.message)
                notify('success', resp.responseData.message)
                this.setState({ visible: false })
                this.getTimeTableDetails()
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
            })
    }

    //Show modal
    showModal = (id) => {
        this.setState({
            visible: true,
            selectedId: id
        });

    };
    //Cancel modal
    handleCancel = () => {
        this.setState({ visible: false });
    };



    render() {
        let { timeTableDetails, visible, loading } = this.state
        console.log("dkjshagfghj", timeTableDetails)
        
        return (
            <div className="pl-4 pr-4">

                {this.props.isAdd && <div className="text-right">
                    <button className="btn btn-red" onClick={this.togglePopup.bind(this)}>Add Timetable</button>
                </div>
                }

                <div className="table-responsive mt-4">
                    <table className="table table-bordered rounded-table">
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>Speaker Name</th>
                                <th>Job Profile</th>
                                <th>Start Time</th>
                                <th>End Time</th>
                                <th>Date</th>
                                <th>Stage</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                timeTableDetails?.map((item, index) => (
                                    <tr>
                                        <td><span className="circle-serial">{index + 1}</span></td>
                                        <td>{item?.speakerId?.firstName} {item?.speakerId?.lastName}</td>
                                        <td>{item?.designation}</td>
                                        <td>{moment(item?.startTime).format('LT')}</td>
                                        <td>{moment(item?.endTime).format('LT')}</td>
                                        <td><p><p>{moment(item?.startDate).format('L')}</p> <p>{moment(item?.startDate).format('dddd')}</p></p></td>
                                        <td>{item?.stage}</td>
                                        <td className="action_group">
                                            <p onClick={() => this.togglePopup.bind(this)(item, true)}><p className="circle-serial"><Pencil /></p></p>
                                            <p onClick={() => this.showModal(item?._id)}><p className="circle-serial"><Delete /></p></p>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>

                <Modal show={visible} onHide={this.handleCancel} className="cm_order_view dlt_item">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <img src={require('../../assets/img/Delete-Email.png')} /> */}
                        <br />
                        <br />
                        <p>Are you sure you want to delete this ?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="footer_btn_wrapper btn_nospace">
                            <Button variant="warning" loading={loading} onClick={() => this.deleteTimeTable(this.state.selectedId)} >
                                Yes
                        </Button>
                            <Button variant="dark" onClick={this.handleCancel}>
                                No
                        </Button>
                        </div>
                    </Modal.Footer>
                </Modal>


                <div className="text-right pt-4">
                    {/* <button to="" className="btn btn-primary">Save</button> */}
                </div>
                {this.state.showPopup ?
                    <TimeTablePopup
                        {...this.props}
                        speakerData = {this.props.speakerData}
                        timeTableDetails = {timeTableDetails}
                        text='Close Me'
                        closePopup={this.togglePopup.bind(this)}
                        timeTable={this.state.timeTable}
                        isEdit={this.state.isEdit}
                    />
                    : null
                }

            </div>

        )
    }
}

class StagePopup extends React.Component {


    static getDerivedStateFromProps(nextProps, prevState) {
        // console.log("lfkjknkfmsjfmksdlnfjksdm,", nextProps)
        if (prevState.flag  == false && nextProps.isEdit == true) {
            let {speakerviewData} = nextProps
                return {
                    speakerName: `${speakerviewData.firstName} ${speakerviewData.lastName}`,
                    email: speakerviewData.email,
                    profilePicPreview: speakerviewData.image,
                    speakerDesignation: speakerviewData.jobTitleOther,
                    phoneNumber: speakerviewData.phoneNumber,
                    description: speakerviewData.about,
                    spekerProfilePic: speakerviewData.profileImage,
                    flag:true
                }

            }
        return null;
    }



    constructor(props) {
        super(props);
        this.state = {
            speakerName: '',
            speakerNameError: '',
            speakerDesignation: '',
            speakerDesignationError: '',
            email: '',
            emailError: '',
            phoneNumber: '',
            phoneNumberError: '',
            spekerProfilePic: '',
            description: '',
            descriptionError: '',
            profilePicPreview: '',
            isEdit: this.props.isEdit,
            emailEdit: true,
            id: '',
            flag:false,
            isCropper:false

        };
    }


    handleChange = (e) => {

        console.log('asasas', e.target.value, e.target.name)
        this.setState({ [e.target.name]: e.target.value, descriptionError: '', speakerNameError: '', phoneNumberError: '', speakerDesignationError: '', emailError: '' })
        if (e.target.value != '' && e.target.name == 'email')
            this.setState({ emailEdit: false })
    }


    addSpeaker = () => {
        if (this.state.isEdit == true) {
            this.editSpeaker()
        }
        else
            this.submitSpeaker()

    }

    finalCropedImage = (categoryImgPrev, categoryImg, visibleVal) => {
        console.log("ldjshgvhbjkd", { categoryImgPrev, categoryImg, visibleVal })
        this.setState({
            visibleVal,
            isCropper: false,
            profilePicPreview: categoryImgPrev,
            media: categoryImg

        })
    }



    handlePicUpload = (event) => {
        let reader = new FileReader();
        let file = event.target.files[0];
        let { valiDate } = this.state;
        
        if (file) {
            reader.readAsDataURL(file)
        }
        if (file) {
            console.log(event, "media1", event.target.files[0])
            if (file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png' || file.type === 'image/svg+xml') {
                reader.addEventListener("load", () => {
                    this.setState({ profilePicPreview: reader.result }, function () {
                    })
                }, false);
                if (file.size > 2000000) {
                    console.log("lkjhgfdsdfghjklkjhgfghjkmnbvccvbnm>>>>>>>>>>>>>>>>>>")
                    valiDate = false;
                    console.log(event, "media", event.target.files[0])
                    this.setState({
                        media: event.target.files[0],
                        profilePicErrorMsg: 'Profile pic size should be less than 5Mb.'
                    });
                } else {
                    console.log("media3hhhjshs", event.target.files[0], file.size)
                    this.setState({
                        media: event.target.files[0],
                        mediaError: ''
                    });
                }
            } else {
                valiDate = false;
                this.setState({ mediaError: 'File type not supported.' })

            }
        }
    }


    submitSpeaker = () => {
        let { speakerName, description, speakerDesignation, spekerProfilePic, email, phoneNumber, media } = this.state
        let reg = /^[1-9][0-9]{7,18}$/;
        if (speakerName == '')
            this.setState({ speakerNameError: '*Please enter speaker name.' })
        else if (phoneNumber == '')
            this.setState({ phoneNumberError: '*Please enter phone number.' })
            else if (!reg.test(phoneNumber))
            this.setState({ phoneNumberError: '*Please enter valid phone number.' })
        else if (speakerDesignation == '')
            this.setState({ speakerDesignationError: '*Please enter Designation.' })
        else if (email == '')
            this.setState({ emailError: '*Please enter email.' })
        else if (!validateEmail(email).status)
            this.setState({ emailError: "*Please enter valid email." })
        // else if (description == '')
        //     this.setState({ descriptionError: '*Please enter bio.' })

        else {
            let formData = new FormData()
            formData.append("userType", 3)
            formData.append("stageId", this.props.match.params.stId)
            formData.append("firstName", speakerName.split(' ')[0])
            formData.append("lastName", speakerName.split(' ')[1] ? speakerName.split(' ')[1] : '')
            formData.append("phoneNumber", phoneNumber)
            formData.append("jobTitleOther", speakerDesignation)
            formData.append("image", media)
            formData.append("email", email)
            formData.append("about", description ? description : '')
            _Api(Url.ADD_NEW_USER.method, Url.ADD_NEW_USER.url, formData)
                .then(resp => {
                    // localStorage.setItem('speakerId', resp.responseData.stageDetails._id)
                    notify('success', resp.responseData.message)
                    this.props.closePopup()
                }).catch(err => {
                    if (err.error)
                        notify("err", err.error.responseMessage)
                   // else if (err)
                //     // notify("err", err.message)
                })
        }

    }

    editSpeaker = () => {
        let reg = /^[1-9][0-9]{7,18}$/;
        let { speakerName, description, speakerDesignation, spekerProfilePic, email, phoneNumber, media } = this.state
        if (speakerName == '')
            this.setState({ speakerNameError: '*Please enter speaker name.' })
        else if (phoneNumber == '')
            this.setState({ phoneNumberError: '*Please enter phone number.' })
         else if (!reg.test(phoneNumber))
            this.setState({ phoneNumberError: '*Please enter valid phone number.' })
        else if (speakerDesignation == '')
            this.setState({ speakerDesignationError: '*Please enter Designation.' })
        else if (email == '')
            this.setState({ emailError: '*Please enter email.' })
        // else if (!validateEmail(email).status)
        //     this.setState({ emailError: "*Please enter valid email." })
        // else if (description == '')
        //     this.setState({ descriptionError: '*Please enter bio.' })

        else {
            console.log("dashgcdvhbjk", this.props.speakerviewData._id)
            if (this.state.emailEdit == false) {
                var formData = new FormData()
                formData.append("requestUserId", this.props.speakerviewData._id)
                formData.append("userType", 3)
                formData.append("stageId", this.props.match.params.stId)
                formData.append("firstName", speakerName.split(' ')[0])
                formData.append("lastName", speakerName.split(' ')[1] ? speakerName.split(' ')[1] : '')
                formData.append("phoneNumber", phoneNumber)
                formData.append("jobTitleOther", speakerDesignation)
                formData.append("image", media)
                // formData.append("email", email)
                formData.append("about", description ? description : '')
            } else {
                var formData = new FormData()
                formData.append("requestUserId", this.props.speakerviewData._id)
                formData.append("userType", 3)
                formData.append("stageId", this.props.match.params.stId)
                formData.append("firstName", speakerName.split(' ')[0])
                formData.append("lastName", speakerName.split(' ')[1] ? speakerName.split(' ')[1] : '')
                formData.append("phoneNumber", phoneNumber)
                formData.append("jobTitleOther", speakerDesignation)
                formData.append("image", media)
                formData.append("about", description ? description : '')
                // formData.append("email", email)
            }
            // formData.append("jobTitleOther", description ? description : '')
            _Api(Url.EDIT_SPEAKER.method, Url.EDIT_SPEAKER.url, formData)
                .then(resp => {
                    // localStorage.setItem('speakerId', resp.responseData.stageDetails._id)
                    notify('success', resp.responseData.message)
                    this.setState({ isEdit: false })
                    this.props.closePopup()
                }).catch(err => {
                    if (err.error)
                        notify("err", err.error.responseMessage)
                   // else if (err)
                //     // notify("err", err.message)
                })
        }
    }


    // componentDidMount() {
    //     // this.getSpeakerList()
    //     // console.log("dijaushyStagePopupgdvahbjks", this.props.speakerviewData._id)
    //     let speakerviewData = this.props.speakerviewData
    //     if (this.props.isEdit) {

    //         this.setState({
    //             speakerName: `${speakerviewData.firstName} ${speakerviewData.lastName}`,
    //             email: speakerviewData.email,
    //             speakerDesignation: speakerviewData.jobTitleOther,
    //             phoneNumber: speakerviewData.phoneNumber,
    //             description: speakerviewData.about,
    //             spekerProfilePic: speakerviewData.profileImage,
    //             // id:this.props.speakerviewData._id




    //         })
    //     }
    // }

    getSpeakerList = () => {
        _Api(Url.GET_SPEAKER_LIST.method, Url.GET_SPEAKER_LIST.url)
            .then(resp => {
                console.log('resp1111111', resp.responseData)

                // notify('success', resp.responseData.message)
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
            })
    }



    render() {
        // console.log('props', this.props.isEdit);
        let { speakerName, speakerDesignation, spekerProfilePic, description, descriptionError, email, phoneNumber, emailError, phoneNumberError, speakerNameError, speakerDesignationError } = this.state
      
      
        return (
            <div className='popup'>
                <div className='popup_inner'>
                    <div className="rounded-body-form">
                        <div className="cm-form-wrapper rounded-textbox-form">
                            <h3 className="text-center bold"><strong> {this.state.isEdit == true ? "Edit Speaker" : "Add Speaker"}</strong></h3>
                            <div style={{ cursor: 'poniter' }} className="upload-wrapper" onClick={()=> this.setState({isCropper:true})}>
                                <label className="upload-photo-wrapper">
                                    {this.state.profilePicPreview ? <img className="circle-image"  src={this.state.profilePicPreview} /> :
                                        <>    <span> <img className="img-fluid circle-image" src={require('../../../../assets/images/upload-photo.svg')} /></span>
                                            <p className="m-0">upload photo</p> </>
                                    }
                                    {/* <>    <span> <img className="img-fluid" src={require('../../../../assets/images/upload-photo.svg')} /></span>
                                            <p className="m-0">upload photo</p> </> */}
                                    {/* <input id="file-upload"
                                        type="file"
                                        onChange={this.handlePicUpload}
                                    /> */}
                                </label>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <level>Speaker Name</level>
                                        <input type="text"
                                            className="form-control"
                                            placeholder="Speaker Name"
                                            maxLength={64}
                                            name="speakerName"
                                            value={speakerName}
                                            onChange={this.handleChange}
                                        />
                                        <InlineError
                                            message={speakerNameError}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <level>Designation</level>
                                        <input type="text"
                                            className="form-control"
                                            placeholder="Designation"
                                            maxLength={64}
                                            name="speakerDesignation"
                                            value={speakerDesignation}
                                            onChange={this.handleChange}
                                        />
                                        <InlineError
                                            message={speakerDesignationError}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <level>Phone Number</level>
                                        <input type="number"
                                            className="form-control"
                                            placeholder="Phone Number"
                                            maxLength={64}
                                            name="phoneNumber"
                                            value={phoneNumber}
                                            onChange={this.handleChange}
                                        />
                                        <InlineError
                                            message={phoneNumberError}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <level>Email</level>
                                        <input type="text"
                                            className="form-control"
                                            placeholder="Email"
                                            maxLength={64}
                                            name="email"
                                            value={email}
                                            onChange={this.handleChange}
                                        />
                                        <InlineError
                                            message={emailError}

                                        />
                                    </div>
                                </div>
                                <div className="col-12">


                                    <div className="form-group">
                                        <level>Bio</level>
                                        <textarea className="textarea form-control" rows="4"
                                            placeholder="Bio..."
                                            maxLength={256}
                                            name="description"
                                            value={description}
                                            onChange={this.handleChange}
                                        />
                                        <InlineError
                                            message={descriptionError}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="text-center">
                                <button className="btn btn-primary" onClick={this.addSpeaker}>Save</button>
                            </div>
                        </div>
                    </div>
                    <button className="close-btn" onClick={this.props.closePopup}><CircleCross /></button>
                </div>
                {this.state.isCropper ?
                    <Croper aspect={2.5 / 3}
                        // onImageCrop={img => this.setState({ media: img })}
                        imgPrev={'2.5-3.jpg'}
                        finalCropedImage={(categoryImgPrev, categoryImg, visibleVal) => this.finalCropedImage(categoryImgPrev, categoryImg, visibleVal)} visibleVal={this.state.isCropper}
                        onClose={() => this.setState({ isCropper: false })}
                    />
                    : ""}




            </div>
        );
    }
}
