import React, { Component } from 'react';
import { FooterPhone, FooterLocation, FooterMail, Has } from '../../SvgIcon';
import LazyLoad from 'react-lazy-load';




class Footer extends Component {


  render() {

    return (
      <>

        <footer className="footer-area footer--light mt-2 mt-md-5" >
          <div className="footer-big">

            <div className="container-fluid">
              <div className="row ">
                <div className="col-xl-2 col-lg-12 ">
                  <div className="footer-widget ">
                    {/* <LazyLoad> */}
                      <div className="mt-2 mb-2 mb-md-0 my-md-0">
                        <a href="/"><img src={require('../../assets/images/jobfinder-logo-footer.png')} className="img-fluid" /></a>
                      </div>
                    {/* </LazyLoad> */}

                  </div>
                </div>

                <div className="col-xl-3 col-lg-6">
                  <div className="footer-widget footAbot pl-md-3">
                    <div className="footer-menu">
                      <p>worldwidejobfinder.com is powered by the Professional Beauty Group who has been serving the beauty industry for over 30 years. We have offices in Dubai, Dublin, Johannesburg, London & Mumbai</p>
                    </div>
                  </div>
                </div>
             

                 <div className="col-xl-2 col-lg-6">
                  <div className="footer-widget pl-md-2">
                    <div className="footer-menu link">
                      <ul>
                        <li>
                          <a target="_blank" href="https://worldwidejobfinder.com/">www.worldwidejobfinder.com</a>
                        </li>
                        <li>
                          <a target="_blank" href="https://professionalbeauty.ae/">www.professionalbeauty.ae</a>
                        </li>
                        <li>
                          <a href="mailto:info@worldwidejobfinder.com">info@worldwidejobfinder.com</a>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div> 
                <div className="col-xl-3 col-lg-6">
                  <div className="footer-widget socialMedia-wrp text-center">
                    <ul className="mb-0 list-inline">
                      <li className="list-inline-item">
                        <a href="https://www.facebook.com/worldwidejobfinder/" target="_blank"> <i className="fa fa-facebook"></i> </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="https://instagram.com/worldwidejobfinder?igshid=19g708g8izq5c" target="_blank"> <i className="fa fa-instagram"></i> </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="https://www.linkedin.com/company/worldwide-job-finder" target="_blank"> <i className="fa fa-linkedin"></i> </a>
                      </li>
                      <li className="list-inline-item tikTok">
                        <a href="https://vm.tiktok.com/ZSJUPJtDQ/" target="_blank"> 
                          <img src={require('../../assets/images/tik-tok.svg')} className="img-fluid" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* <div className="col-xl-2 col-lg-6">
                  <div className="footer-widget">
                    <div className="footer-menu no-padding">
                      <h4 className="footer-widget-title">Quick Links</h4>
                      <ul>
                        <li>
                            <a name="faq" >Home</a>
                        </li>
                        <li>
                          <a href="/blog/nutritionist">About Us</a>
                        </li>
                        <li>
                          <a name="Privacy" >Services</a>
                        </li>
                        <li>
                          <a name="Privacy" >Terms of services</a>
                        </li>
                        <li>
                          <a name="Privacy" >Privacy Policy</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
            */}

                <div className="col-xl-2 col-lg-6">
                  <div className="footer-widget">
                    <div className="footer-menu no-padding text-center text-lg-right">
                      <p className="mb-0">© 2021 worldwidejobfinder.com</p>
                      {/* <ul className="social-networks pt-0">
                        <a href="#" className="facebook" target="_blank"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                        <a href="#" className="facebook" target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                        <a href="#" className="facebook" target="_blank"><i className="fa fa-instagram" aria-hidden="true"></i></a>
                        <a href="#" className="facebook" target="_blank"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                        <a href="#" className="facebook" target="_blank"><i className="fa fa-google-plus" aria-hidden="true"></i></a> 
                      </ul> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-none">
                <div className="col-sm-10 offset-sm-1 text-center">
                  <hr />

                  <p className="footer-bottom ff-CPL pb-3">Copyright@ 2021. Professional Beauty Group. All rights reserved</p>

                </div>
              </div>
            </div>

          </div>

        </footer>
      </>

    );
  }

}

export default Footer;