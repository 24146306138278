import React, { Component } from 'react';
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';
import { Brands } from "../../../components/index"
import {connect} from "react-redux";
import AddBrands from "./addBrand/AddBrands"

class BrandsList extends Component {
    render() {
        return (
            <>
                <div className="dash_body_container">
                    <Header />
                    <Sidebar />
                    <div className="body_container">
                        <Brands
                          {...this.props}
                            isShow={true}
                        />
                    </div>
                </div>
                <AddBrands/>
            </>
        );
    }
}
// export default BrandsList;
function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(BrandsList);