import React, { Component } from 'react'
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';
import { Link } from "react-router-dom";
import { AdManagement, Filter, Pencil, Delete } from '../../../SvgIcon';
import moment from 'moment-timezone';
import '../../../assets/css/adManagement.css';
import { DownOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Checkbox, DatePicker, TimePicker, Empty } from 'antd';

import { notify } from '../../../components/Toaster';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { Pagination } from '../../../components/common/Pagination';
import { Modal, Button, Space } from 'antd';

import { notification } from 'antd';
import { CategoryManagement, } from '../../../components/common/SvgIcon';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import {
    MyAlert,
    MySpinner,
    AdLoader
} from "../../../components/index"

import { getTimeFormate } from "../../../utils/DateZone"

const { confirm } = Modal;

class NotificationList extends Component {


    constructor(props) {
        super(props);
        this.state = {
            isDeleted: '',
            listId: '',
            status: "",
            page: 1,
            limit: 10,
            loading: false,
            visible: false,
            selectedId: '',
            list: [],
            selectedId: '',
            isLoader: false,
            search: '',
            totalCount: [],
            snNo: 0,
            selectedItems: [],
        }
    }



    componentDidMount() {
        this.getList()
    }
    getList = (isShowLoader = true) => {
        this.setState({ isLoader: isShowLoader })
        let { page, limit, search } = this.state
        let req = {
            page: page ? page : 1,
            limit: limit,
            search: search.toString()
        }
        _Api(Url.AdminBroadcastList.method, Url.AdminBroadcastList.url, req)
            .then(resp => {

                this.setState({ list: resp.responseData.data, totalCount: resp.responseData.totalCount, isLoader: false })

            }).catch(err => {
                this.setState({ isLoader: false })
            })
    }




    handlePageChange = page => this.setState({ loader: true, page, adData: [] }, () => this.getList(false));

    handleSearch = e => this.setState({ search: e.target.value, page: 1, snNo: 0, adData: [] }, () => this.getList());



    deleteAd = (id) => {
        let req = {
            isDeleted: 0,
            messageId: id,

        }
        this.setState({ visible: false })
        _Api(Url.AdminBroadcastDelete.method, Url.AdminBroadcastDelete.url, req)
            .then(resp => {
                notification.success({ message: 'Success', description: resp.responseData.message }); this.setState({ visible: false })
                this.getList(false)
            }).catch(err => {
                this.setState({ visible: false })
                if (err.error)
                    notification.error({ message: 'Error', description: err.error.responseMessage });
                else if (err)
                    notification.error({ message: 'Error', description: err.message });
            })
    }

    //Show modal
    showModal = (id) => {
        this.setState({
            visible: true,
            selectedId: id
        });

    };
    //Cancel modal
    handleCancel = () => {
        // console.log('delete', resp.responseData.message)
        this.setState({ visible: false });
    };

    statusAd = (e, id) => {

        let oldArr = this.state.selectedItems
        oldArr.push(id)

        this.setState({ selectedItems: oldArr })




        let req = {
            adsId: id.toString(),
            status: (e.target.checked ? 1 : 0),
        }
        _Api(Url.AD_CHANGE_STATUS.method, Url.AD_CHANGE_STATUS.url, '', `?adsId=${req.adsId}&status=${req.status}`)
            .then(resp => {
                notification.success({ message: 'Success', description: resp.responseData.message });
                this.setState({ visible: false, selectedItems: this.state.selectedItems.filter(item => item != id) })
                this.getList(false)
            }).catch(err => {
                this.setState({ visible: false, selectedItems: this.state.selectedItems.filter(item => item != id) })
                if (err.error)
                    notification.error({ message: 'Error', description: err.error.responseMessage });
                else if (err)
                    notification.error({ message: 'Error', description: err.message });

            })

    }





    onPressBtn = (page) => {
        this.setState({ page, snNo: page - 1, adData: [] }, () => {
            this.getList()
        })

    }

    // delete modal
    showDeleteConfirm = (id) => {
        confirm({
            title: 'Are you sure you want to delete this?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            onOk: () => {
                this.deleteAd(id)
            },
            onCancel: () => {
                this.handleCancel()
            },
        });
    }

    getUserType = (key) => {
        switch (key) {
            case 1:
                return "All, "
                break;
            case 2:
                return "Brand Showcase, "
                break;
            case 3:
                return "All, "
                break;
            default:
                return "All, ";
        }
    }


    render() {
        const { list, isLoader } = this.state
        return (
            <>
                <div className="dash_body_container">
                    <div className="body_container">
                        <div className="header-container">
                            <div className="d-flex flex-grow-1 align-items-center">
                                <p className="header-icon"><CategoryManagement /></p>
                                <h4>Notification List</h4>
                            </div>
                            <div className="search_wrapper  mr-2">
                                <i className="fa fa-search" />
                                <input type="search"
                                    placeholder="Search by name"
                                    name='search'
                                    placeholder="Search..."
                                />
                            </div>
                            <Link to={`/send-notification`} className="btn btn-red">Add Notification</Link>
                        </div>

                        {/* List Start from Here */}
                        <div class="table-responsive">
                            <table class="table table-bordered rounded-table">
                                <thead>
                                    <tr>
                                        <th>S.No.</th>
                                        <th>User Type</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        list.map((item, index) => {
                                            return (
                                                <tr>

                                                    <td>{(this.state.snNo * 10) + index + 1}</td>
                                            <td>{
                                                item.userType.map(item => this.getUserType(item))
                                                }</td>
                                                    <td>{getTimeFormate(item.msgBroadcastTimestamp)}</td>
                                                    <td>{moment(item.msgBroadcastTimestamp).format("hh:mm a")} </td>
                                                    <td className="action_group">
                                                     {  new Date(item.msgBroadcastTimestamp).getTime() > new Date().getTime()  && <Link to={`/send-notification/${item._id}`}> <p><p className="circle-serial" ><Pencil /></p></p></Link>}
                                                        <a onClick={() => this.showDeleteConfirm(item._id)} ><p className="circle-serial"><Delete /></p></a>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>
                        {!isLoader && list.length == 0 &&
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}


                        {
                            list.length != 0 &&
                            <Pagination
                                totalPage={Math.ceil(this.state.totalCount / 10)}
                                currentPage={this.state.page}
                                onPressBtn={this.onPressBtn}
                            />
                        }
                    </div>
                </div>
            </>
        );
    }
}



export default NotificationList;
