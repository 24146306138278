
import React, { Component } from 'react';
import { PromoCodeIcon, Pencil, Delete } from '../../../SvgIcon';
import { Link } from "react-router-dom";
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';
import ViewPromoDetails from '../../admin/exhibiton_management/popups/ViewPromoDetail';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';
import { Modal, Button, Empty } from 'antd';
import { Pagination } from '../../../components/common/Pagination';
import Loader from '../../../components/Loader';
import moment from 'moment-timezone';
import util from '../../../utils/Util.js';

import {getTimeFormate} from "../../../utils/DateZone"

const columnDetails = [{ fieldName: "S.No." },
{ fieldName: "Activation Code" },
{ fieldName: "Value" },
{ fieldName: "Discount Type" },
{ fieldName: "Max Discount Amount", isSortable: true, dataIndex: "maximumDiscount" },
{ fieldName: "Maximum Users" },
{ fieldName: "Effective From", isSortable: true, dataIndex: "startDate" },
{ fieldName: "Effective Until", isSortable: true, dataIndex: "endDate" },
{ fieldName: "Valid For" },
{ fieldName: "Status" },
{ fieldName: "Action" }
];




class PromoCode extends Component {
    constructor() {
        super();
        this.state = {
            viewDetails: false,
            limit: 10,
            loading: false,
            selectedId: '',
            promoListData: [],
            isLoader: false,
            search: '',
            totalCount: [],
            fieldName: '',
            order: '',
            promoCodeDetailData: [],
            visible: false,
            sortInfo: {},
            snNo: 0,
            page: 1

        };
    }
    componentDidMount() {
        this.getPromoList();
    }
    getPromoList = (downloadValue) => {
        this.setState({ isLoader: true });

        let { page, limit, search, sortInfo } = this.state;
        let { order, fieldName } = sortInfo;
        let req = {
            page: page ? page : 1,
            limit: limit,
            search: search.toString()
        }
        _Api(Url.GET_PROMOLIST.method, Url.GET_PROMOLIST.url, '', `?page=${req.page}&limit=${req.limit}&search=${req.search}&sortField=${fieldName}&sortType=${order}`)
            .then(resp => {
                let responseData = resp.responseData.result;
                this.setState({ promoListData: responseData.data, totalCount: responseData.totalCount, isLoader: false });

            }).catch(err => {
                this.handleError(err);
            });
    }
    onStatusChange = (e, id) => {
        let params = {
            promocodeId: id,
            status: (e.target.checked ? 1 : 0).toString(),
        }
        _Api(Url.PROMO_STATUS_CHANGE.method, Url.PROMO_STATUS_CHANGE.url, '', `?promocodeId=${id}&status=${(e.target.checked ? 1 : 0).toString()}`)
            .then(resp => {
                notify('success', resp.responseData.message);
                this.setState({ visible: false, statusCode: resp.responseData.statusCode });
                this.getPromoList();
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
            })

    }
    handlePageChange = (page) => {
        this.setState({ loader: true, page, snNo: page - 1, promoListData: [] }, () => {
            this.getPromoList()
        })

    }

    togglePopup(value, id) {
        if (value === "viewDetails") {
            this.setState({ isLoader: true, viewDetails: !this.state.viewDetails });
            !this.state.viewDetails && _Api(Url.GET_PROMODETAIL.method, Url.GET_PROMODETAIL.url, '', `?promocodeId=${id}`)
                .then(resp => {
                    let responseData = resp.responseData.result;
                    this.setState({ promoCodeDetailData: responseData, isLoader: false });

                }).catch(err => {
                    this.handleError(err);
                });
            this.state.viewDetails && this.setState({ isLoader: false })
        }

    }
    onClickSorting = (field) => {
        let { order, fieldName } = this.state.sortInfo;
        let orderType = 1;
        if (order && fieldName === field) {
            orderType = order < 0 ? '' : -1;
        }
        this.setState({
            sortInfo: {
                fieldName: field,
                order: orderType
            }
        }, () => this.getPromoList());

    }
    deletePromoList = (id) => {
        let req = {
            isDeleted: 0,
            promocodeId: id,

        }
        _Api(Url.PROMO_LIST_DELETE.method, Url.PROMO_LIST_DELETE.url, '', `?promocodeId=${req.promocodeId}&isDeleted=${req.isDeleted}`)
            .then(resp => {
                console.log('delete', resp.responseData.message)
                notify('success', resp.responseData.message)
                this.setState({ visible: false })
                this.getPromoList();
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
            })


    }
    // handle ok 
    handleOk = e => {
        this.setState({
            visible: false,
        });
    };
    //Show modal
    showModal = (id) => {
        this.setState({
            visible: true,
            selectedId: id
        });

    };
    //Cancel modal
    handleCancel = () => {
        this.setState({ visible: false });
    };

    handleError(err) {
        this.setState({ isLoader: false });
        let errorMessage = err.error && err.error.responseMessage || err.message;
        notify("err", errorMessage);
    }

    copyOnClip=(code)=>{
        // event.target.select();
        try{
            navigator.clipboard.writeText(code).then(resp =>{
                notify('success', "Copied code")
            })


        }catch(err){}
       
    }

    handleSearch = e => this.setState({ search: e.target.value, page: 1, snNo: 0, promoListData: [] }, () => this.getPromoList());

    render() {
        let { promoListData, promoCodeDetailData, visible, loading, search, isLoader, sortInfo } = this.state;
        let { order, fieldName } = sortInfo;

        return (
            <>
                <div className="dash_body_container">
                    <Header />
                    <Sidebar />
                    <div className="body_container">
                        {isLoader && <Loader />}
                        <div className="header-container">

                            <div className="d-flex flex-grow-1 align-items-center">
                                <p className="header-icon"><PromoCodeIcon /></p>
                                <h4>Activation Code</h4></div>
                            <div className="search_wrapper  mr-2">
                                <i className="fa fa-search" />
                                <input type="search"
                                    placeholder="Search..."
                                    name='search'
                                    value={search}
                                    onChange={this.handleSearch}

                                />
                            </div>
                            <Link to="/add-promo-code" className="btn btn-red">Add New Activation Code</Link>

                        </div>


                        <div class="table-responsive">
                            <table class="table table-bordered rounded-table">
                                <thead>
                                    <tr>

                                        {columnDetails.map((item) => {
                                            let { isSortable, dataIndex } = item;

                                            return <th><div className="d-flex justify-content-around align-items-center"
                                                onClick={() => this.onClickSorting(dataIndex)}>{item?.fieldName}
                                                {isSortable && <span className="d-flex"><i className={util.getSortingIcon(dataIndex == fieldName && order)}
                                                    aria-hidden="true"
                                                /></span>}</div></th>

                                        })}
                                    </tr>
                                </thead>

                                <tbody>
                                    {promoListData.map((item, index) => {
                                        return (
                                            <tr>
                                                <td><span className="circle-serial">{(this.state.snNo * 10) + index + 1}</span></td>
                                                <td> <Link to={this.props.myroute} onClick={this.togglePopup.bind(this, 'viewDetails', item?._id)}>{item?.title}</Link><i onClick={()=> this.copyOnClip(item?.title)} class="fa fa-files-o float-right cursor-point" aria-hidden="true" title="Copy code"></i></td>
                                                <td>{item?.discountValue}</td>
                                                <td>{item?.discountType == 1 && "Fixed Value" || "Percentage"} </td>
                                                <td>{item?.maximumDiscount}</td>
                                                <td>{item?.numberofUser}</td>
                                                <td>{getTimeFormate(item?.startDate.toString())}</td>
                                                <td>{getTimeFormate(item?.endDate.toString())}</td>
                                                <td>{util.getPurchaseValue(item?.validFor)}</td>
                                                <td>
                                                    <p className="toggle-parent ">
                                                        <label className="switch">
                                                            <input type="checkbox"
                                                                checked={item?.status === 1 ? true : false}
                                                                onChange={(e) => this.onStatusChange(e, item?._id)}

                                                            />
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </p>
                                                </td>
                                                <td className="action_group">                                                   
                                                    <p onClick={() => this.showModal(item?._id)}><p className="circle-serial"><Delete /></p></p>

                                                </td>
                                            </tr>
                                        )
                                    })

                                    }


                                </tbody>
                            </table>
                      
                        <div>
                            {!isLoader && promoListData.length == 0 &&
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                        </div>
                        {
                            promoListData.length != 0 &&
                            <div >
                                <Pagination
                                    totalPage={Math.ceil(this.state.totalCount / 10)}
                                    currentPage={this.state.page}
                                    onPressBtn={this.handlePageChange}
                                />
                            </div>
                        }
  </div>
                        <div>

                        </div>

                    </div>

                    {this.state.viewDetails ?
                        <ViewPromoDetails
                            text='Close Me'
                            closePopup={this.togglePopup.bind(this, "viewDetails")}
                            data={promoCodeDetailData}
                        />
                        : null
                    }
                    <Modal
                        visible={visible}
                        title="Delete"
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={[
                            <Button key="back" onClick={this.handleCancel}>
                                Cancel
                             </Button>,
                            <Button key="submit" type="primary" loading={loading} onClick={() => this.deletePromoList(this.state.selectedId)}>
                                Delete
                            </Button>,
                        ]}
                    >
                        <p>Are you sure you want to delete this ?</p>
                    </Modal>

                </div>
            </>
        );
    }
}
export default PromoCode;

















































