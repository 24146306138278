import React, { Component } from 'react';
import { Brands, Pencil, Delete } from '../../../../SvgIcon';
import { Link } from "react-router-dom";
import { DatePicker, TimePicker, Empty } from 'antd';
import moment from 'moment-timezone';
import { _Api } from '../../../../services/Api';
import Url from '../../../../services/BaseUrl';
import { notify } from '../../../../components/Toaster';
// import { Modal, Button } from 'react-bootstrap';

import { notification } from 'antd';

import { Modal, Button, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { BrandLoader, Pagination } from "../../../../components/index"

const { confirm } = Modal;

class Brandss extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // brands
            isDeleted: '',
            exhibtionId: '',
            status: "",
            page: 1,
            limit: 10,
            loading: false,
            visible: false,
            selectedId: '',
            exhibitionListData: [],
            selectedId: '',
            isLoader: false,
            search: '',
            brandListData: [],
            isEditExhibition: false,
            snNo: 0,
            selectedArr: [],
            isChange:false

        }
    }


    componentDidMount() {
        this.getBrandList()
    }

    /** brands start */

    getBrandList = (isLoader = true) => {
        this.setState({ isLoader: isLoader })
        let { page, limit, search } = this.state
        let req = {
            page: page ? page : 1,
            limit: limit,
            search: search.toString()
        }

        _Api(Url.GET_BRAND_FOR_ASSIGN.method, Url.GET_BRAND_FOR_ASSIGN.url, '', `?&exhibitionId=${this.props?.match?.params?.exId ? this.props?.match?.params?.exId : ''}`)
            .then(resp => {
                console.log("dkjhgadhj", resp)


                this.setState({ brandListData: resp.responseData?.result, isLoader: false })

            }).catch(err => {
                this.setState({ isLoader: false })
                // if (err.error)
                //     notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
                // this.setState({ isLoader: false })
            })
    }

    handlePageChange = page => this.setState({ loader: true, page }, () => this.getBrandList());

    handleSearch = e => this.setState({ search: e.target.value, brandListData: [], page: 1, snNo: 0 }, () => this.getBrandList());


    // delete modal
    showModal = (id) => {
        confirm({
            title: 'Are you sure you want to delete this?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            onOk: () => {
                this.deleteBrandList(id)
            },
            onCancel: () => {
                this.handleCancel()
            },
        });
    }


    //Cancel modal
    handleCancel = () => {
        // console.log('delete', resp.responseData.message)
        this.setState({ visible: false });
    };





    deleteBrandList = (id) => {
        let req = {
            isDeleted: 0,
            brandId: this.state.selectedId,

        }
        _Api(Url.USER_DELETE.method, Url.USER_DELETE.url, '', `?requestUserId=${req.brandId}&isDeleted=${req.isDeleted}`)
            .then(resp => {
                console.log('delete', resp.responseData.message)
                notify('success', resp.responseData.message)
                this.setState({ visible: false })
                this.getBrandList(false)
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
            })


    }

    // //Show modal
    // showModal = (id) => {
    //     this.setState({
    //         visible: true,
    //         selectedId: id
    //     });
    // };
    //Cancel modal
    handleCancel = () => {
        this.setState({ visible: false });
    };
    statusBrandList = (e, id) => {
        let req = {
            brandId: id,
            status: (e.target.checked ? 1 : 0).toString(),
        }
        _Api(Url.USER_STATUS.method, Url.USER_STATUS.url, '', `?requestUserId=${req.brandId}&status=${req.status}`)
            .then(resp => {
                console.log('delete', resp.responseData)
                notify('success', resp.responseData.message)
                this.setState({ visible: false })
                this.getBrandList(false)
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
            })

    }


    onPressBtn = (page) => {
        this.setState({ page, snNo: page - 1, brandListData: [] }, () => {
            this.getBrandList()
        })

    }

    handleSelect = (id, item) => {
        console.log("dkjhgfdhjksahb", id)
        let oldArr = this.state.selectedArr
        console.log("dkjhgfdhjksahb", oldArr.findIndex(item => item?.brandId == id))
        if (oldArr.findIndex(item => item?.brandId == id) == -1) {
            let obj = {
                exhibitionId: this.props?.match?.params?.exId,
                brandId: id,
                planId: item?.userPlan
            }
            oldArr.push(obj)
            this.setState({ selectedArr: oldArr })
        }
        else {
            this.setState({ selectedArr: this.state.selectedArr.filter(item => item?.brandId != id) })

        }

    }


    assignBrand = () => {
        let req = {
            brand: this.state.selectedArr
        }
        this.setState({loading:true})
        _Api(Url.ASSIGN_BRAND.method, Url.ASSIGN_BRAND.url, req)
            .then(resp => {
                this.setState({loading:false})
                this.props.history.goBack()
            }).catch(err => {
                this.setState({loading:false})
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
            })
    }

    changePlane = (item, index) => {
        console.log("djhgvdhbjdjashbdj", { item, index })
        const { userPlan } = item
        if (userPlan == 2) {
            let data = this.state.brandListData
            data[index].userPlan = 3
            this.setState({ brandListData: data })
        }
    }


    render() {

        let id = this.props?.match?.params?.exId ? this.props.match.params.exId : ''
        const { exhibitionListData, visible, loading, search, isLoader, brandListData, selectedArr } = this.state;
        console.log("dsjahgfadghsajk", selectedArr)
        return (
            <div className="dash_body_container">
                <div className="body_container">
                    <>
                        <>
                            <div class="header-text d-flex mb-4 mt-3">
                                <div class="d-flex flex-grow-1 align-items-center">
                                    <p className="header-icon"><Brands /></p><h4>Businesses</h4>
                                </div>
                                {/* <div class="search_wrapper  mr-2">
                                    <i class="fa fa-search"></i>
                                    <input type="search" placeholder="Search..." name="search"
                                        onChange={this.handleSearch}
                                    />
                                </div> */}
                            </div>

                        </>
                        <div className="header-container ">
                            <div class="header-text d-flex w-100 justify-content-betweenmb-4 mt-3">
                                <div class="d-flex flex-grow-1 align-items-center">
                                </div>
                                <div class="">
                                    {/* <Link to="#" className="btn btn-blue-large mr-2">Assign Brands</Link> */}
                                     <button disabled={this.state.selectedArr[0] == undefined || loading} onClick={this.assignBrand} className="btn btn-red">Assign Businesses</button>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive">
                            <table class="table table-bordered rounded-table">
                                <thead>
                                    <tr>
                                        <th>Selected ({this.state.selectedArr?.length})</th>
                                        <th>Plan Type</th>
                                        <th>Company Name</th>
                                        <th>Email</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {/* {isLoader && <BrandLoader />} */}
                                    {brandListData?.map((item, index) => {
                                        //   let  item = resp?.brandId
                                        console.log('itemmmmm', item)
                                        return (
                                            <tr>

                                                <td>
                                                    <div className="check_wrapper">
                                                        <input type="checkbox"
                                                            onClick={() => this.handleSelect(item?._id, item)}
                                                            // checked={}
                                                            value="option1"
                                                        />

                                                        <span />
                                                    </div>
                                                    {/* <div className="assign-checkboc-parent">
                                                  <div>
                                                    <label className="d-block">Brand Showcase</label>
                                                    <div className="check_wrapper">
                                                        
                                                            <input type="checkbox"
                                                                    
                                                                    value="option1"
                                                                  
                                                                        />
                                                                    <span />
                                                        </div>
                                                  </div>
                                                    <div className="check_wrapper">
                                                    <div>
                                                        <label className="d-block">Showcase</label>
                                                        <div className="check_wrapper">
                                                        
                                                        <input type="checkbox"
                                                                
                                                                value="option1"
                                                                
                                                                    />
                                                                <span />
                                                        </div>
                                                        </div>
                                                        </div>
                                                        </div> */}
                                                </td>
                                                <td>
                                                    <div className="assign-checkboc-parent">
                                                        <div>
                                                            {console.log("pdkmasn", item?.userPlan == 2)}
                                                            <label className="cm_container">
                                                            Operator Showcase
                                                                    <input onClick={() => this.changePlane(item, index)} checked={item?.userPlan == 2} type="radio" />
                                                                <span className="radio-checkmark"></span>
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label className="cm_container">
                                                                  Agency Showcase
                                                                    <input onClick={() => this.changePlane(item, index)} checked={item?.userPlan == 3} type="radio" />
                                                                <span className="radio-checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td> <Link to={`/business/details/${item?._id}`} > {item?.companyName}</Link></td>
                                                <td>{item?.email}</td>
                                            </tr>
                                        )
                                    })

                                    }
                                </tbody>
                            </table>
                       
                        <div>
                            {!isLoader && brandListData && brandListData.length == 0 &&
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                        </div>
                        {/* {
                            brandListData && brandListData.length != 0 &&
                            <Pagination
                                totalPage={Math.ceil(this.state.totalCount / 10)}
                                currentPage={this.state.page}
                                onPressBtn={this.onPressBtn}
                            />
                        } */}
                     </div>

                    </>
                </div>
            </div>
        )
    }
}

export default Brandss