import React, { Component } from 'react';
import { Link } from "react-router-dom";
import WebHeader from '../../layout/WebHeader';
import { Hert } from '../../../SvgIcon';
import Footer from "../../layout/Footer"
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import Masonry from 'react-masonry-css'
import { Tree } from 'antd';
import { connect } from "react-redux";

import { notify } from "../../../components/Toaster";
import Loader from "../../../components/Loader";
import { userActions } from "../../../redux/action";
var _ = require('lodash');

class ManageCompany extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productAndServiceOfferData: [],
            autoExpandParent: true,
            expandedKeys: [],
            checkedKeys: [],
            selectedKeys: [],
            isLoader: false,
            count: 0,
            serviceDisable: false,
            sectorSS:[],

            selectedItem: [],
            selectedCheck: [],
            manageCategoriesId2:[]

        }
        this.saveService = this.saveService.bind(this);
    }

    componentDidMount() {
        this.props.dispatch({ type: 'PATH_ADD', payload: this.props.match })
        let self = this;
        if (localStorage.getItem('accessToken') && localStorage.getItem('accessToken') !== '') {
            self.setState({ isLoader: true })
            if (self.props.userDetail?.userPlan) {
                self.props.dispatch(userActions.profile())
                    .then(function () {
                        self.getCategory("4", "productAndServiceOfferData");
                    })
            } else {
                self.props.dispatch(userActions.profile())
                    .then(function () {
                        self.getCategory("4", "productAndServiceOfferData");
                    })
            }


        } else {
            self.props.history.push("/")
        }

        setTimeout(()=> this.getUserProfile(), 500)
        this.getUserProfile2()

        
    }

    getCategory = (categoryType, keyName) => {
        let self = this;
        let params = {
            categoryType: categoryType,
        }
        this.setState({ isLoader: true })
        _Api(Url.GET_CATEGORY_LIST.method, Url.GET_CATEGORY_LIST.url, params)
            .then(resp => {
                self.setState({ isLoader: false })
                let expandedKeys = [];
                //servicesShowcaseId
                let servicesShowcaseId = self.props.userDetail.servicesShowcaseId;
                let topListedShowcaseId = self.props.userDetail?.topListedShowcaseId ? self.props.userDetail?.topListedShowcaseId : []
                let userPlan = self.props.userDetail?.userPlan
                let count = servicesShowcaseId ? servicesShowcaseId.length : 0;
                self.setState({
                    count
                })
                console.log("resp.responseData.result", resp.responseData.result);
                if (userPlan == 3 ? count == 30 : count == 20) {
                    // console.log("ifffff");
                    let selectedItm = []
                    let keyNameData = resp.responseData.result.map((item1, index1) => {
                        let checkedData = [];
                        let childData = item1.subCategory.map((data, index2) => {

                            let ddd = {}
                            let result = _.find(servicesShowcaseId, function (o) {
                                return o === data._id;
                            });

                            if (result) {
                                ddd = {
                                    disabled: false,
                                    key: data._id,
                                    title: data.categoryTitle,
                                    value: data._id,
                                    sectorName: item1.categoryTitle,
                                    children: []
                                }
                                if (servicesShowcaseId.indexOf(data._id) != -1) {
                                    selectedItm.push(ddd)

                                }
                            } else {
                                ddd = {
                                    disabled: true,
                                    key: data._id,
                                    title: data.categoryTitle,
                                    value: data._id,
                                    sectorName: item1.categoryTitle,
                                    children: []
                                }
                                if (servicesShowcaseId.indexOf(data._id) != -1) {
                                    selectedItm.push(ddd)

                                }
                            }
                            if (result) {
                                checkedData.push(result)
                            }
                            return ddd;
                        });
                        self.setState({
                            ['checkedKeys-' + item1._id]: checkedData
                        })
                        expandedKeys.push(item1.categoryTitle)
                        let dd = {
                            selectable: childData.length > 0 ? false : true,
                            // title: childData.length>0?<b style={{ color: '#000' }}>{item1.categoryTitle}</b>:item1.categoryTitle,
                            title: item1.categoryTitle,
                            value: item1._id,
                            key: item1._id,
                            checkable: childData.length > 0 ? false : true,
                            children: childData
                        }
                        return dd
                    })
                    console.log("keyNameData", keyNameData)
                    this.setState({
                        [keyName]: keyNameData,
                        selectedItem: _.orderBy(selectedItm, ['title'], ['asc']),
                        selectedCheck: topListedShowcaseId,
                        expandedKeys
                    }, () => {
                        this.topSelectedEnableOrDisable()
                    })
                } else {
                    let selectedItm = []
                    let keyNameData = resp.responseData.result.map((item1, index1) => {
                        let checkedData = [];
                        console.log("item1item1item1item1item1", item1)
                        let childData = item1.subCategory.map((data, index2) => {
                            // console.log("ndwkjndwjqhdk", { data, servicesShowcaseId })
                            let ddd = {
                                //checkable:false,
                                key: data._id,
                                title: data.categoryTitle,
                                value: data._id,
                                sectorName: item1.categoryTitle,
                                children: []
                            }
                            if (servicesShowcaseId.indexOf(data._id) != -1) {
                                selectedItm.push(ddd)

                            }
                            let result = _.find(servicesShowcaseId, function (o) {
                                return o === data._id;
                            });

                            if (result) {
                                checkedData.push(result)
                            }
                            return ddd;
                        });
                        self.setState({
                            ['checkedKeys-' + item1._id]: checkedData,
                        })
                        console.log("dsajhgdvhbjask", checkedData)
                        expandedKeys.push(item1.categoryTitle)
                        let dd = {
                            selectable: childData.length > 0 ? false : true,
                            // title: childData.length>0?<b style={{ color: '#000' }}>{item1.categoryTitle}</b>:item1.categoryTitle,
                            title: item1.categoryTitle,
                            value: item1._id,
                            key: item1._id,
                            checkable: childData.length > 0 ? false : true,
                            children: childData
                        }
                        return dd
                    })
                    console.log("keyNameData", keyNameData)
                    this.setState({
                        [keyName]: keyNameData,
                        selectedItem: _.orderBy(selectedItm, ['title'], ['asc']),
                        selectedCheck: topListedShowcaseId,
                        expandedKeys
                    }, () => {
                        this.topSelectedEnableOrDisable()
                    })
                }
            }).catch(err => {
                this.setState({ isLoader: false })
            })
    }

    onCheck = (productAndServiceOffer, checkedKeys, userType, node) => {
        let count = this.state.count;
        let isPremium = userType == 3
        let oldCheckedData = this.state['checkedKeys-' + productAndServiceOffer.key];
        let oldTopListData = this.state.selectedItem
        // console.log('onCheck', node);

        this.onCheckTopSelected('direct', node)

        let selectNode = node?.node
        //console.log("selectNode",selectNode);
        // console.log("lskjahgsfcvgH", this.state.selectedItem.findIndex((item) => item.value == selectNode.value))
        if (this.state.selectedItem.findIndex((item) => item.value == selectNode.value) == -1) {
            let newArr = [...oldTopListData, {
                ...selectNode
            }]

            this.setState({
                selectedItem: _.orderBy(newArr, ['title'], ['asc'])
            })
            //console.log("newArr",newArr);
        }

        this.setState({
            ['checkedKeys-' + productAndServiceOffer.key]: checkedKeys,



        }, function () {
            let checkedKeysAll = [];
            _.map(this.state, function (value, key) {
                let splitData = key.split("-");
                if (splitData.length > 1) {
                    checkedKeysAll = checkedKeysAll.concat(value);
                }
            });
            if (checkedKeys.length > oldCheckedData.length) {
                if (count + 1 >= 30 && isPremium) {
                    let keyNameData = this.state.productAndServiceOfferData.map((item1, index1) => {
                        let checkedData = [];
                        let childData = item1.children.map((data, index2) => {
                            let ddd = {}
                            let result = _.find(checkedKeysAll, function (o) {
                                return o === data.key;
                            });
                            if (result) {
                                ddd = {
                                    disabled: false,
                                    key: data.key,
                                    title: data.title,
                                    value: data.value,
                                    sectorName: item1.title,
                                    children: []
                                }
                            } else {
                                ddd = {
                                    disabled: true,
                                    key: data.key,
                                    title: data.title,
                                    value: data.value,
                                    sectorName: item1.title,
                                    children: []
                                }
                            }
                            return ddd;
                        });
                        let dd = {
                            selectable: childData.length > 0 ? false : true,
                            title: item1.title,
                            value: item1.value,
                            key: item1.key,
                            checkable: childData.length > 0 ? false : true,
                            children: childData
                        }
                        return dd
                    })
                    console.log("lskjahgsfcvgH", oldCheckedData)
                    this.setState({
                        "productAndServiceOfferData": keyNameData,
                        count: count + 1,

                    })
                } else {
                    this.setState({
                        count: count + 1,
                        // selectedItem: keyNameData
                    })

                    console.log("dshgahsjk", checkedKeys)
                }
                if (count + 1 >= 20 && !isPremium) {
                    let keyNameData = this.state.productAndServiceOfferData.map((item1, index1) => {
                        let checkedData = [];
                        let childData = item1.children.map((data, index2) => {
                            let ddd = {}
                            let result = _.find(checkedKeysAll, function (o) {
                                return o === data.key;
                            });
                            if (result) {
                                ddd = {
                                    disabled: false,
                                    key: data.key,
                                    title: data.title,
                                    value: data.value,
                                    sectorName: item1.title,
                                    children: []
                                }
                            } else {
                                ddd = {
                                    disabled: true,
                                    key: data.key,
                                    title: data.title,
                                    value: data.value,
                                    sectorName: item1.title,
                                    children: []
                                }
                            }
                            return ddd;
                        });
                        let dd = {
                            selectable: childData.length > 0 ? false : true,
                            title: item1.title,
                            value: item1.value,
                            key: item1.key,
                            checkable: childData.length > 0 ? false : true,
                            children: childData
                        }
                        return dd
                    })
                    this.setState({
                        "productAndServiceOfferData": keyNameData,
                        count: count + 1,
                        // selectedItem: keyNameData

                    })
                } else {
                    this.setState({
                        count: count + 1,
                    })
                }
            } else {
                console.log("elseeeeeeee")
                let keyNameData = this.state.productAndServiceOfferData.map((item1, index1) => {
                    let checkedData = [];
                    let childData = item1.children.map((data, index2) => {
                        let ddd = {
                            disabled: false,
                            key: data.key,
                            title: data.title,
                            value: data.value,
                            sectorName: item1.title,
                            children: []
                        }
                        return ddd;
                    });
                    let dd = {
                        selectable: childData.length > 0 ? false : true,
                        title: item1.title,
                        value: item1.value,
                        key: item1.key,
                        checkable: childData.length > 0 ? false : true,
                        children: childData
                    }
                    return dd
                })
                this.setState({
                    "productAndServiceOfferData": keyNameData,
                    count: count - 1,
                    // selectedItem: keyNameData
                })
            }
        })



    };

    onSelect = (selectedKeys, info) => {
        console.log('onSelect', info);
        this.setState({
            selectedKeys
        })
    };

    onExpand = expandedKeys => {
        //console.log('onExpand', expandedKeys); // if not set autoExpandParent to false, if children expanded, parent can not collapse.
        // or, you can remove all expanded children keys.

        this.setState({
            expandedKeys,
            autoExpandParent: false
        })
    };

    saveService() {
        let self = this;
        //console.log("saveService");
        this.setState({
            productAndServiceError: '',
        })
        let checkedKeys = [];
        _.map(this.state, function (value, key) {
            let splitData = key.split("-");
            if (splitData.length > 1) {
                console.log(key, value, splitData);
                checkedKeys = checkedKeys.concat(value);
            }
        });
        // console.log("checkedKeys", checkedKeys);
        // let {checkedKeys} = this.state;
        if (checkedKeys.length === 0) {
            notify("err", 'Please select any service');
            //this.setState({productAndServiceError: '*Please select any service.'})
        } else {
            this.setState({ isLoader: true })
            let req2 = {
                servicesShowcaseId: checkedKeys.toString(),
                topListedShowcaseId: this.state.selectedCheck.toString()
            }
            _Api(Url.SAVE_SHOWCASE.method, Url.SAVE_SHOWCASE.url, req2)
                .then(resp => {
                    this.setState({ isLoader: false })
                    notify("success", "Services updated sucessfully");
                    this.props.history.goBack()
                    self.props.dispatch(userActions.profile())
                        .then(result => {
                            self.getCategory();
                        })
                }).catch(err => {
                    this.setState({ isLoader: false })
                    if (err.error)
                        notify("err", err.error.responseMessage)
                    // else if (err)
                    //     // notify("err", err.message)
                })
        }
    }

    onCheckTopSelected = (indexId, data) => {
        let oldArr = this.state.selectedCheck
        const { node } = data
        let id = node?.value

        if (indexId == "direct") {
            this.setState({
                selectedCheck: oldArr.filter(item => item != id),
                selectedItem: this.state.selectedItem.filter(item => item.value != id)
            })
        }
        else {


            if (oldArr.indexOf(id) == -1) {
                if (this.state.selectedCheck.length < 6) {
                    oldArr.push(id)
                    this.setState({ selectedCheck: oldArr })
                }
            }
            else
                this.setState({
                    selectedCheck: oldArr.filter(item => item != id)
                })

        }

        this.topSelectedEnableOrDisable()


    }


    topSelectedEnableOrDisable = () => {
        setTimeout(() => {

            // if (this.state.selectedItem.findIndex((item) => item.value == selectNode.value) == -1) {
            let newArr = this.state.selectedItem


            if (this.state.selectedCheck.length >= 6) {

                this.setState({
                    selectedItem:
                        newArr.map(item => {
                            if (this.state.selectedCheck.indexOf(item.value) != -1) {
                                return (
                                    {
                                        disabled: false,
                                        key: item.key,
                                        //title:<div> {item.title}-<b style={{color:"#5383a2"}}> <i>{item.sectorName}</i></b></div>,
                                        title: item.title,
                                        value: item.value,
                                        children: [],
                                        sectorName: item.sectorName,
                                    }
                                )


                            }
                            else {

                                return (
                                    {
                                        disabled: true,
                                        key: item.key,
                                        title: item.title,
                                        //title:<div> {item.title}-<b style={{color:"#5383a2"}}> <i>{item.sectorName}</i></b></div>,
                                        value: item.value,
                                        children: [],
                                        sectorName: item.sectorName,
                                    }
                                )

                            }
                        })
                })
            }
            else {
                let soltedArr = newArr.map(item => (
                    {
                        disabled: false,
                        key: item.key,
                        title: item.title,
                        value: item.value,
                        children: [],
                        sectorName: item.sectorName,
                    }
                ))
                this.setState({
                    selectedItem: _.orderBy(soltedArr, ['title'], ['asc'])

                })
            }
        }, 300)
    }


















    //**********************************************NEWWWWWWWW COMPANY SECTION******************************************* */




    getUserProfile = () => {
        let self = this;
        let req = {
            requestUserId: this.props.match.params.id || localStorage.id || "",
            companyId: this.props.match.params.cId || localStorage.company || "",

        };
        _Api(
            Url.GET_COMPANY_NEW.method,
            Url.GET_COMPANY_NEW.url,
            "",
            `?requestUserId=${req.requestUserId}&companyId=${req.companyId}`
        )
            .then((resp) => {
                let responseData = resp.responseData?.result                          
                console.log('dhgasvhjk', responseData) 
                this.setState({
                    isLoader: false,
                    currentType: responseData.userPlan, brandType: responseData.userPlan,
                    email: responseData?.companyEmail ? responseData?.companyEmail : '',
                    userData: responseData, ...responseData,
                    countrySelectedOption: responseData?.country,
                    cityPlaceholder: responseData?.cityId ? responseData?.city : responseData.city && responseData.city !== "" ? 'Other' : 'Select City',
                    showOtherCity: responseData?.cityId ? false : responseData.city && responseData.city !== "" ? true : false,
                    otherCity: responseData?.city,
                })

            })
            .catch((err) => {
                this.setState({ isLoader: false });
                console.log("dhgasvhjk", err)
                // let errorMessage =
                //     (err.error && err.error.responseMessage) || err.message;
                // notify("err", errorMessage);
            });
    }




    getUserProfile2 = () => {
        let self = this;
        let req = {
            requestUserId: this.props.match.params.id || localStorage.id || "",
            companyId: this.props.match.params.cId || localStorage.company || "",

        };
        _Api(
            Url.GET_USER_PROFILE.method,
            Url.GET_USER_PROFILE.url,
            "",
            `?requestUserId=${req.requestUserId}&companyId=${req.companyId}`
        )
            .then((resp) => {
                // let responseData = resp.responseData.result;
                
                // this.setState({
                //     // isLoader: false,
                //     manageCategoriesId2 :responseData?.manageCategoriesId
                   
                // })

 let responseData = resp.responseData.result;
    let arr = responseData.manageCategoriesId
    let temp = []
    arr.map((item, index) => {
                                                              
            item.sectorId.map(item => temp.push( item.name))
    })     
    
    setTimeout(()=> this.setState({manageCategoriesId2 : arr, sectorSS: [...new Set(temp)] }), 300)
        

   
    
    console.log("kjhgbjd", [...new Set(temp)])


            })
            .catch((err) => {
                // this.setState({ isLoader: false });
            });
    }

























    render() {
        let userType = localStorage.getItem("userType")

        let { userData, userQuestionAns, companyName, firstName, lastName, email, country, phoneNumber, manageCategoriesId2 } = this.state;
        let { twitterLink, instagramLink, facebookLink, youtubeLink, linkedinLink, image, sectorId, placeOfWork, roleId, city, userPlan, manageCategoriesId, companyWebsite } = userData ? userData : "";
        console.log("ufgh", manageCategoriesId)
        return (
            <section className={`brand-home-banner-bg brandprofile-banner-bg ${userType == 1 ? "brand dash_body_container withsidenavbar" : ''} `}>
                {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
                {this.state.isLoader && <Loader />}
                {/*<ChatBox history={this.props.history} match={this.props.match} />*/}
                <div className="container-fluid  manage-company">
                    <div className="row">
                        <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2">
                            <div className=" pt-5 text-center">
                                <h1 className="home-heading pos-relative mb-5">
                                    Manage Company

                         <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                </h1>

                            </div>
                            <div class="edit-tabular mb-3 p-0 border-0 profile-dtl">
                                <div class="table-responsive-sm">
                                    <div class="vertical-table table-responsive user-profile pb-4">
                                        <ul class="detail_row">
                                            <li className=""> Showcase Name</li>
                                            <li>{userData?.companyName ? userData?.companyName : '---'}</li>
                                        </ul>
                                        <ul class="detail_row">
                                            <li>Country</li>
                                            <li class="">{userData?.country ? userData?.country : '---'}</li>
                                        </ul>
                                        <ul class="detail_row">
                                            <li>Closest Large City</li>
                                            <li class="">{userData?.city ? userData?.city : '---'}</li>
                                        </ul>
                                        <ul class="detail_row">
                                            <li>Company Website</li>
                                            <li class="">{companyWebsite ? companyWebsite : '---'}</li>
                                        </ul>
                                        <ul class="detail_row">
                                            <li>Company Telephone</li>
                                            <li class="">{userData?.phoneNumber ? userData?.phoneNumber : '---'}</li>
                                        </ul><ul class="detail_row">
                                            <li>Company Email</li>
                                            <li class="">{userData?.companyEmail ? userData?.companyEmail : '---'}</li>
                                        </ul><ul class="detail_row">
                                            <li>LinkedIn</li>
                                            <li class="">{linkedinLink ? linkedinLink : '---'}</li>
                                        </ul><ul class="detail_row">
                                            <li>Twitter</li>
                                            <li class="">{twitterLink ? twitterLink :'---'}</li>
                                        </ul>
                                        <ul class="detail_row">
                                            <li>Instagram</li>
                                            <li class="">{instagramLink ? instagramLink : '---'}</li>
                                        </ul>
                                        <ul class="detail_row">
                                            <li>FaceBook</li>
                                            <li class="">{facebookLink ? facebookLink : '---'}</li>
                                        </ul>
                                        <ul class="detail_row">
                                            <li>YouTube</li>
                                            <li class="">{youtubeLink ? youtubeLink : '---'}</li>
                                        </ul>

                                    </div>
                                </div>
                                <Link to={ userType == 1 ? `/edit-manage-company/${this.props.match.params.id}/${this.props.match.params.cId}` : "/edit-manage-company"} class="btn btn-red ">Edit</Link>
                            </div>
                            {/* ---------Sectors & Categories------------- */}
                            <div className=" pt-5 text-center">
                                <h1 className="home-heading pos-relative mb-5">
                                    Categories &  Sectors
                         <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                </h1>

                            </div>
                            <div class="edit-tabular mb-3 p-0 border-0 profile-dtl mb-5">
                                <div class="table-responsive-sm">
                                    <div class="vertical-table table-responsive user-profile pb-4">
                                        <ul class="detail_row">
                                            <li className="">Which roles are your jobs or trainings for?</li>
                                            <li> {
                                                manageCategoriesId2?.map((item, index) => {
                                                    return (                                                       
                                                            `${item?.id?.name}, `
                                                        
                                                    )
                                                }).length == 0 ? '---' : manageCategoriesId2?.map((item, index) => {
                                                    return (                                                       
                                                            `${item?.id?.name}, `
                                                        
                                                    )
                                                })
                                            }</li>
                                        </ul>
                                        <ul class="detail_row">
                                            <li className=""> Which Sector(s) does your company operate it?</li>
                                            <li> {
                                                manageCategoriesId2?.map((item, index) => {
                                                    return (                                                       
                                                            `${item?.sectorId?.map(item => item.name)}, `
                                                        
                                                    )
                                                }).length == 0 ?   '---' :this.state.sectorSS.toString()

                                            }</li>

{/* [...new Set(manageCategoriesId2?.map((item, index) => {
                                                    return (                                                       
                                                            `${item?.sectorId?.map(item => item.name)}, `
                                                        
                                                    )
                                                }))] */}
                                        </ul>
                                    </div>
                                </div>
                                <Link to= { userType == 1 ? `/edit-sectors-categories/${this.props.match.params.id}/${this.props.match.params.cId}` : "/edit-sectors-categories"} class="btn btn-red ">Edit</Link>
                            </div>


                        </div>
                    </div>


                </div>
                {userType != 1 && <Footer />}
            </section >
        )
    }
}
function mapStateToProps(state) {
    // console.log("abcdefghhfiekjnmkwejnfnw", state)
    return {
        ...state
    };
}

export default connect(mapStateToProps)(ManageCompany);