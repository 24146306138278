import React from "react"
import Skeleton from "react-loading-skeleton"





// loader
export const PeoppleLoader = () => (
    <div className="notify-row cursor-point">
        <div className="profile-dp">
            <td><Skeleton circle={true} height={60} width={60} /></td>

        </div>
        <div className="message">
            <div>
                <h5><b className="mr-3"><Skeleton width={100} /> </b></h5>
                {/* <h6><Skeleton width={100} /></h6> */}
                <Skeleton width={300} />
            </div>
            <div className="action_group  ">
                <span className="action_group  ">
                    <Skeleton height={50} width={150} />
                </span>
            </div>
        </div>
    </div >


)










// loader
export const ExhibitionLoader = () => (
    <tr>
        <td><Skeleton circle={true} height={30} width={30} /></td>
        <td><Skeleton /></td>
        <td><p><p><Skeleton /></p>  <p><Skeleton /></p> <p><Skeleton /></p></p></td>
        <td><p><p><Skeleton /></p>  <p><Skeleton /></p> <p><Skeleton /></p></p></td>
        <td><Skeleton /></td>
        <td><Skeleton /></td>
        <td><Skeleton /></td>
        <td><Skeleton /></td>
        <td><Skeleton /> </td>
        <td className="action_group">
            <Skeleton circle={true} height={30} width={30} /> &nbsp;
            <Skeleton circle={true} height={30} width={30} />
        </td>
    </tr>
)


// loader
export const AdLoader = () => (
    <tr>
        <td><Skeleton circle={true} height={30} width={30} /></td>
        <td><Skeleton /></td>
        <td><Skeleton /></td>
        <td><Skeleton /></td>
        <td><Skeleton /> </td>
        <td className="action_group">
            <Skeleton circle={true} height={30} width={30} /> &nbsp;
            <Skeleton circle={true} height={30} width={30} />
        </td>
    </tr>
)
export const AdLoader1 = () => (
    <tr>
        <td><Skeleton circle={true} height={30} width={30} /></td>
        <td><Skeleton /></td>
        <td><Skeleton /></td>
        <td><Skeleton /></td>
        <td><Skeleton /> </td>
       
    </tr>
)

// loader
export const BrandLoader = () => (
    <tr>
        <td><Skeleton circle={true} height={30} width={30} /></td>
        <td><Skeleton /></td>
        <td><Skeleton /></td>
        <td><Skeleton /></td>
        <td><Skeleton /></td>
        <td><Skeleton /></td>
        <td><Skeleton /> </td>
        <td className="action_group">
            <Skeleton circle={true} height={30} width={30} /> &nbsp;
            <Skeleton circle={true} height={30} width={30} />
        </td>
    </tr>
)

// loader
export const ConferenceDetailsLoader = () => (
    <tr>
        <td><Skeleton circle={true} height={30} width={30} /></td>
        <td><Skeleton /></td>
        <td><Skeleton /></td>
        <td><Skeleton /></td>
        <td><Skeleton /></td>
        <td>
            <Skeleton />
        </td>
        <td className="action_group">
            <Skeleton circle={true} height={30} width={30} /> &nbsp;
            <Skeleton circle={true} height={30} width={30} />
        </td>
    </tr>

)


// loader
export const NetworkingLoungeLoader = () => (
    <tr>
        <td><Skeleton circle={true} height={30} width={30} /></td>
        <td><Skeleton /></td>
        <td>
            <p><p><Skeleton /></p>  <p><Skeleton /></p> <p><Skeleton /></p></p>
        </td>
        <td>
            <p><p><Skeleton /></p>  <p><Skeleton /></p> <p><Skeleton /></p></p>
        </td>
        <td><Skeleton /></td>
        <td><Skeleton /></td>
        <td>
            <Skeleton />
        </td>
        <td className="action_group">
            <Skeleton circle={true} height={30} width={30} /> &nbsp;
            <Skeleton circle={true} height={30} width={30} />
        </td>
    </tr>


)
export const StageLoader = () => (
    <>
      <div className="header-text">
                                        <h1 className="title">Stages</h1>
                                       </div>
        <div className="row">
            <div className="col-md-4 col-sm-6  stage-banner">
                <div className="w-100 wrapper stage-banner"  >
                    <Skeleton height={400} />
                    <div className="progress-bar-wrapper">
                        <Skeleton height={40} />
                    </div>


                </div>
            </div>
            <div className="col-md-4 col-sm-6  stage-banner">
                <div className="w-100 wrapper stage-banner"  >
                    <Skeleton height={400} />


                </div>
            </div>
            <div className="col-md-4 col-sm-6  stage-banner">
                <div className="w-100 wrapper stage-banner"  >
                    <Skeleton height={400} />


                </div>
            </div>

        </div>
        <div className="header-text">
                                        <h1 className="title">Lounges</h1>
                                       </div>
        <div className="row">
            <div className="col-xl-3 col-lg-4 col-sm-6  mb-5 conference-lounges">
                <div className="card-img-wrapper cursor-point">
                    <div className="offer-badge">
                        <div className="offer-badge-text "><Skeleton height={20} /></div>
                    </div>
                    <div className="card">
                        <Skeleton height={400} />
                    </div>
                </div>
                <div className="cm-card-body">

                    <p className="h5 wrap-p2 ">
                        <Skeleton height={10} />
                    </p>

                    <p className="des">
                        <Skeleton height={10} />
                    </p>
                    <>
                        <hr />
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                                <Skeleton height={10} />
                            </div>
                        </div>
                    </>

                </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6  mb-5 conference-lounges">
                <div className="card-img-wrapper cursor-point">
                    <div className="offer-badge">
                        <div className="offer-badge-text "><Skeleton height={20} /></div>
                    </div>
                    <div className="card">
                        <Skeleton height={400} />
                    </div>
                </div>
                <div className="cm-card-body">

                    <p className="h5 wrap-p2 ">
                        <Skeleton height={10} />
                    </p>

                    <p className="des">
                        <Skeleton height={10} />
                    </p>
                    <>
                        <hr />
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                                <Skeleton height={10} />
                            </div>
                        </div>
                    </>

                </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6  mb-5 conference-lounges">
                <div className="card-img-wrapper cursor-point">
                    <div className="offer-badge">
                        <div className="offer-badge-text "><Skeleton height={20} /></div>
                    </div>
                    <div className="card">
                        <Skeleton height={400} />
                    </div>
                </div>
                <div className="cm-card-body">

                    <p className="h5 wrap-p2 ">
                        <Skeleton height={10} />
                    </p>

                    <p className="des">
                        <Skeleton height={10} />
                    </p>
                    <>
                        <hr />
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                                <Skeleton height={10} />
                            </div>
                        </div>
                    </>

                </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6  mb-5 conference-lounges">
                <div className="card-img-wrapper cursor-point">
                    <div className="offer-badge">
                        <div className="offer-badge-text "><Skeleton height={20} /></div>
                    </div>
                    <div className="card">
                        <Skeleton height={400} />
                    </div>
                </div>
                <div className="cm-card-body">

                    <p className="h5 wrap-p2 ">
                        <Skeleton height={10} />
                    </p>

                    <p className="des">
                        <Skeleton height={10} />
                    </p>
                    <>
                        <hr />
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                                <Skeleton height={10} />
                            </div>
                        </div>
                    </>

                </div>
            </div>
        
        </div>
        <div className="header-text">
                                        <h1 className="title">Explore Businesses</h1>
                                       </div>
        <div className="row">
      
        <div className="col-xl-3 col-lg-4 col-sm-6 cursor-point"  >
                    <div className="card brand-card mb-5">
                    <Skeleton height={300} />
                                        <div className=" brand-card-body" >
                                            <div className="brand-img-wrapper">
                                                <Skeleton height={100} />
                                             </div>
                                                <h4><Skeleton height={10} /></h4>
                                        </div>
                    </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-sm-6 cursor-point"  >
                    <div className="card brand-card mb-5">
                    <Skeleton height={300} />
                                        <div className=" brand-card-body" >
                                            <div className="brand-img-wrapper">
                                                <Skeleton height={100} />
                                             </div>
                                                <h4><Skeleton height={10} /></h4>
                                        </div>
                    </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-sm-6 cursor-point"  >
                    <div className="card brand-card mb-5">
                    <Skeleton height={300} />
                                        <div className=" brand-card-body" >
                                            <div className="brand-img-wrapper">
                                                <Skeleton height={100} />
                                             </div>
                                                <h4><Skeleton height={10} /></h4>
                                        </div>
                    </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-sm-6 cursor-point"  >
                    <div className="card brand-card mb-5">
                    <Skeleton height={300} />
                                        <div className=" brand-card-body" >
                                            <div className="brand-img-wrapper">
                                                <Skeleton height={100} />
                                             </div>
                                                <h4><Skeleton height={10} /></h4>
                                        </div>
                    </div>
        </div>
        
        </div>
        <div className="header-text">
            <h1 className="title">Industries</h1>
        </div>
        <div className="row d-flex flex-wrap five align-item-center mb-3">
        <div className="card community-card" >
                   
                                                <div className="mb-5 mt-0  pos-relative">
                                                  <Skeleton height={300} />
                                                       
                                                    

                                                    <div className="bottom-text">

                                                        <div className="postion-rel d-flex align-items-center traingletext">
                                                            <span className="title d-flex justify-content-between align-items-center w-100"> <Skeleton height={10} /></span>
                                                            <span className="traingle"> <Skeleton height={10} /></span>
                                                        </div>
                                                    </div>

                                                </div>
                                              
                                            </div>
                                            <div className="card community-card" >
                   
                   <div className="mb-5 mt-0  pos-relative">
                     <Skeleton height={300} />
                          
                       

                       <div className="bottom-text">

                           <div className="postion-rel d-flex align-items-center traingletext">
                               <span className="title d-flex justify-content-between align-items-center w-100"> <Skeleton height={10} /></span>
                               <span className="traingle"> <Skeleton height={10} /></span>
                           </div>
                       </div>

                   </div>
                 
               </div>

               <div className="card community-card" >
                   
                   <div className="mb-5 mt-0  pos-relative">
                     <Skeleton height={300} />
                          
                       

                       <div className="bottom-text">

                           <div className="postion-rel d-flex align-items-center traingletext">
                               <span className="title d-flex justify-content-between align-items-center w-100"> <Skeleton height={10} /></span>
                               <span className="traingle"> <Skeleton height={10} /></span>
                           </div>
                       </div>

                   </div>
                 
               </div>

               <div className="card community-card" >
                   
                   <div className="mb-5 mt-0  pos-relative">
                     <Skeleton height={300} />
                          
                       

                       <div className="bottom-text">

                           <div className="postion-rel d-flex align-items-center traingletext">
                               <span className="title d-flex justify-content-between align-items-center w-100"> <Skeleton height={10} /></span>
                               <span className="traingle"> <Skeleton height={10} /></span>
                           </div>
                       </div>

                   </div>
                 
               </div>

               <div className="card community-card" >
                   
                   <div className="mb-5 mt-0  pos-relative">
                     <Skeleton height={300} />
                          
                       

                       <div className="bottom-text">

                           <div className="postion-rel d-flex align-items-center traingletext">
                               <span className="title d-flex justify-content-between align-items-center w-100"> <Skeleton height={10} /></span>
                               <span className="traingle"> <Skeleton height={10} /></span>
                           </div>
                       </div>

                   </div>
                 
               </div>

        </div>
        <div className="header-text">
            <h1 className="title">Jobs</h1>
        </div>
        <div className="row">
        <div className="six-div">
                                            <div className="card brand-card mb-5">
                                            <Skeleton height={300} />
                                                
                                                    <div className="top-left">
                                                    <Skeleton height={10} />
                                                    </div>
                                            
                                                <hr />
                                                
                                                    <div className="action_group text-center">
                                                       
                                                            <span className="wrap-p2"><Skeleton height={10} />
                                                            </span>
                                                        
                                                    </div>
                                               
                                            </div>
                                        </div> 
                                        <div className="six-div">
                                            <div className="card brand-card mb-5">
                                            <Skeleton height={300} />
                                                
                                                    <div className="top-left">
                                                    <Skeleton height={10} />
                                                    </div>
                                            
                                                <hr />
                                                
                                                    <div className="action_group text-center">
                                                       
                                                            <span className="wrap-p2"><Skeleton height={10} />
                                                            </span>
                                                        
                                                    </div>
                                               
                                            </div>
                                        </div> 
        
                                        <div className="six-div">
                                            <div className="card brand-card mb-5">
                                            <Skeleton height={300} />
                                                
                                                    <div className="top-left">
                                                    <Skeleton height={10} />
                                                    </div>
                                            
                                                <hr />
                                                
                                                    <div className="action_group text-center">
                                                       
                                                            <span className="wrap-p2"><Skeleton height={10} />
                                                            </span>
                                                        
                                                    </div>
                                               
                                            </div>
                                        </div> 
        
                                        <div className="six-div">
                                            <div className="card brand-card mb-5">
                                            <Skeleton height={300} />
                                                
                                                    <div className="top-left">
                                                    <Skeleton height={10} />
                                                    </div>
                                            
                                                <hr />
                                                
                                                    <div className="action_group text-center">
                                                       
                                                            <span className="wrap-p2"><Skeleton height={10} />
                                                            </span>
                                                        
                                                    </div>
                                               
                                            </div>
                                        </div> 
        
                                        <div className="six-div">
                                            <div className="card brand-card mb-5">
                                            <Skeleton height={300} />
                                                
                                                    <div className="top-left">
                                                    <Skeleton height={10} />
                                                    </div>
                                            
                                                <hr />
                                                
                                                    <div className="action_group text-center">
                                                       
                                                            <span className="wrap-p2"><Skeleton height={10} />
                                                            </span>
                                                        
                                                    </div>
                                               
                                            </div>
                                        </div> 
        
                                        <div className="six-div">
                                            <div className="card brand-card mb-5">
                                            <Skeleton height={300} />
                                                
                                                    <div className="top-left">
                                                    <Skeleton height={10} />
                                                    </div>
                                            
                                                <hr />
                                                
                                                    <div className="action_group text-center">
                                                       
                                                            <span className="wrap-p2"><Skeleton height={10} />
                                                            </span>
                                                        
                                                    </div>
                                               
                                            </div>
                                        </div> 
        
        </div>

    </>
)

// loader
export const ConferenceLoader = () => (
    <div className="col-sm-6 col-md-4 col-xl-3">
        <div className="conference-block">
            <div className="img" style={{ padding: 2 }}>
                <Skeleton height={250} />
                <div className="free-paid">
                    <Skeleton />
                </div>
            </div>
            <div className="text">
                <div className="leftborder-parent">
                    <h4><Skeleton /></h4>
                    <h6><span><Skeleton /></span>
                    </h6>
                </div>
            </div>
        </div>
    </div>
)



// loader
export const AttendeeLoader = () => (
    <tr>
        <td><Skeleton circle={true} height={30} width={30} /></td>
        <td><Skeleton /></td>
        <td><Skeleton /></td>
        <td><Skeleton /></td>
        <td><Skeleton /></td>
        <td><Skeleton /> </td>

    </tr>
)

export const AllProductLoader = () => (
    <div className="four-div">

    <div className="card community-card wrapper cursor-point">
        
            <div >
                <Skeleton />
                
                    <div className="top-left">
                    <Skeleton />
                    </div>
              
            </div>

        <div className="top-right">
        <Skeleton />

        </div>
        <hr />
        <div className="action_group text-center">
            <h6 className="wrap-p2"> <Skeleton /><br />
            </h6>
        </div>
    </div>
</div>
);





