import React, { Component } from 'react';
import WebHeader from '../../layout/WebHeader';
import { Link } from "react-router-dom";
import { Hert } from '../../../SvgIcon';
import Footer from "../../layout/Footer"
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import Masonry from 'react-masonry-css'
import PhoneInput from 'react-phone-input-2';
import { Tree } from 'antd';
import Select from 'react-select';
import InlineError from "../../../components/InlineError";
import { connect } from "react-redux";
import { notify } from "../../../components/Toaster";
import Loader from "../../../components/Loader";
import { userActions } from "../../../redux/action";
import socket from "../../../services/socket";
import { groupActions } from "../../../redux/action";

var _ = require('lodash');
class EditManageCompany extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: {},
            userQuestionAns: [],
            showPersonalDetailModal: false,
            showQuestionModal: false,

            // personal profile
            firstName: '',
            lastName: '',
            password: '',
            email: '',
            code: "",
            phoneNumber: '',
            companyName: '',
            emailError: '',
            firstNameError: '',
            lastNameError: '',
            passwordError: '',
            phoneNumberError: '',
            userTypeError: '',
            companyNameError: '',
            isLoader: true,
            isLoading: false,
            showMessage: false,
            isPasswordShow: false,
            isShowSignUpStep: true,
            signupStep: 1,
            selectedSector: [],
            selectedSectorCount: 0,
            selectedPlaceOfWork: [],
            placeofworkCount: 0,
            selectedRole: [],
            roleCount: 0,
            registrationId: "",
            registrationIdRequired: false,
            selectedCountryCode: "",
            selectedCountry: "",
            selectedCity: "",
            selectedCityId: "",
            visible: false,
            stepEdit: 1,

            mobileCode: '',
            mobileNumber: '',
            mobileNumberError: '',
            isEditPlan: false,
            //
            isCropper: false,

            // show profileee
            connectionStatus: "",
            startLoader: false,
            userType: null,
            userConnection: null,
            brandType: false,
            currentType: 1,

            countryError: "",
            cityError: "",
            countryList: [],
            cityList: [],
            selectedCountryCode: "",
            // selectedCountry:"",
            // selectedCity:"",
            selectedCityId: "",
            otherCity: "",
            showOtherCity: false,
            countrySelectedOption: null,
            citySelectedOption: null,
            cityPlaceholder: '',

            // end

            twitterLink: '', instagramLink: '', facebookLink: '', youtubeLink: '', linkedinLink: '', companyWebsite: '',


        }

    }






    componentDidMount() {
        let self = this;
        this.props.dispatch({ type: 'PATH_ADD', payload: this.props.match })
        this.getUserProfile()
        this.getParticularQuestionAnswer()
        this.getCountry()


    }

    updatePlan = (id) => {
        let req = {
            requestId: id,
            plan: this.state.brandType,
        };

        _Api(Url.CHANGE_PLAN.method, Url.CHANGE_PLAN.url, req)
            .then((resp) => {
                this.getUserProfile()
                this.setState({ isEditPlan: false })
                notify("success", resp.responseData.message);
            })
            .catch((err) => {
                this.handleError(err);
            });
    }

    getUserProfile = () => {
        let self = this;
        let req = {
            requestUserId: this.props.match.params.id || localStorage.id || "",
            companyId: this.props.match.params.cId || localStorage.company || "",

        };
        _Api(
            Url.GET_COMPANY_NEW.method,
            Url.GET_COMPANY_NEW.url,
            "",
            `?requestUserId=${req.requestUserId}&companyId=${req.companyId}`
        )
            .then((resp) => {
                let responseData = resp.responseData?.result
                this.getCity(responseData?.country_Code)
                console.log('dhgasvhjk', responseData)
                this.setState({
                    isLoader: false,
                    currentType: responseData.userPlan, brandType: responseData.userPlan,
                    email: responseData?.companyEmail ? responseData?.companyEmail : '',
                    userData: responseData, ...responseData,
                    countrySelectedOption: responseData?.country,
                    cityPlaceholder: responseData?.cityId ? responseData?.city : responseData.city && responseData.city !== "" ? 'Other' : 'Select City',
                    showOtherCity: responseData?.cityId ? false : responseData.city && responseData.city !== "" ? true : false,
                    otherCity: responseData?.city,
                })

            })
            .catch((err) => {
                this.setState({ isLoader: false });
                console.log("dsavbhdjk", err)
                // let errorMessage =
                //     (err.error && err.error.responseMessage) || err.message;
                // notify("err", errorMessage);
            });
    }


    onClose = () => {
        this.getUserProfile()
        this.getParticularQuestionAnswer()
        this.setState({
            showPersonalDetailModal: false,
            showQuestionModal: false
        })
    }

    getParticularQuestionAnswer = () => {

        let req = {
            requestUserId: this.props.match.params.id || localStorage.id || "",
        };

        _Api(Url.USER_ANS_LIST.method, Url.USER_ANS_LIST.url, '', `?requestUserId=${req.requestUserId}`)
            .then(resp => {
                //console.log("respresprespresp" , resp);
                this.setState({ userQuestionAns: resp.responseData.result.data, isLoader: false });
                console.log("particularQuestionAnswerDataparticularQuestionAnswerData", resp.responseData.result.data);

            }).catch(err => {

                // this.setState({ isLoader: false });
                // let errorMessage = err.error && err.error.responseMessage || err.message;
                // notify("err", errorMessage);
            });
    }


    onChangeInput = (e) => {
        console.log("dahgsbdjk", {n:e.target.name,v:e.target.value})
        this.setState({
            [e.target.name]: e.target.value, firstNameError: '', lastNameError: '', emailError: '', passwordError: '', companyNameError: '',
            companyWebsiteError: '',
            instagramLinkError: '',
            linkedinLinkError: '',
            twitterLinkError: '',
            youtubeLinkError: '',
            facebookLinkError: ''
        })
    }

    // sign up submit
    signUpSubmit = (e) => {
        e.preventDefault();
        let rexx = /^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[789]\d{9}|(\d[ -]?){7,15}\d$/
        let { firstName, lastName, email, password, phoneNumber, companyName,
            linkedinLink,
            twitterLink,
            instagramLink,
            facebookLink,
            youtubeLink,
            companyWebsite
        } = this.state
        const UrlRegx =  /(https?:\/\/[^\s]+)/
        

        if (linkedinLink != '' && !UrlRegx.test(linkedinLink))
            this.setState({ linkedinLinkError: "*Please enter valid url" })
        else if (twitterLink != '' && !UrlRegx.test(twitterLink))
            this.setState({ twitterLinkError: "*Please enter valid url" })
        else if (instagramLink != '' && !UrlRegx.test(instagramLink))
            this.setState({ instagramLinkError: "*Please enter valid url" })
        else if (facebookLink != '' && !UrlRegx.test(facebookLink))
            this.setState({ facebookLinkError: "*Please enter valid url" })
        else if (youtubeLink != '' && !UrlRegx.test(youtubeLink))
            this.setState({ youtubeLinkError: "*Please enter valid url" })
        else if (companyWebsite != '' && !UrlRegx.test(companyWebsite))
            this.setState({ companyWebsiteError: "*Please enter valid url" })

        // else if (lastName.trim() == '')
        //     this.setState({ lastNameError: '*Please enter last name.' })
        // else if (companyName.trim() === '')
        //     this.setState({ companyNameError: '*Please enter company name.' })
        // else if (phoneNumber === '')
        //     this.setState({ phoneNumberError: '*Please enter phone number.' })
        // else if (!rexx.test(phoneNumber))
        //     this.setState({ phoneNumberError: '*Please enter valid phone number.' })
        // else if (email.trim() == '')
        //     this.setState({ emailError: '*Please enter email.' })
        // else if (!validateEmail(email).status)
        //     this.setState({ emailError: '*Please enter valid email.' })
        // else if (password == '')
        //     this.setState({ passwordError: '*Please enter password.' })
        // else if (password.length <= 4)
        //     this.setState({ passwordError: '*Password must be greater than 4 characters.' })

        else {

        this.setState({ isLoading: true, erroStatus: false, showMessage: false })
        let req2 = {
            // budgetId: budgetId,    
            companyId: this.props.match.params.cId || localStorage.getItem("company") || "" ,            
            country: this.state.selectedCountry,
            country_Code: this.state.selectedCountryCode,
            phoneNo: this.state.phoneNumber,
            phoneNumber: this.state.phoneNumber,
            companyName: this.state.companyName,
            companyEmail: this.state.email,
            city: this.state.city,
            linkedinLink,
            twitterLink,
            instagramLink,
            facebookLink,
            youtubeLink,
            companyWebsite,
            requestUserId: this.props.match.params.id || "",
            // isEuropean: this.state.isEuropeCT == true ? 1 : 0
        }
        if (this.state.showOtherCity === true) {
            req2.city = this.state.otherCity.trim();
            req2.cityId = ""
        } else {
            req2.city = this.state.selectedCity;
            req2.cityId = this.state.selectedCityId;
        }
        _Api(Url.EDIT_COMPANY_NEW.method, Url.EDIT_COMPANY_NEW.url, req2)
            .then(resp => {
                this.setState({ isLoading: false })
                this.props.history.goBack()
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
            })

        }

    }

    setPhoneNumber = (phoneNumber, code) => {
        this.setState({ phoneNumber, phoneNumberError: '', code: code.dialCode })
    }

    setPhoneNumber2 = (phoneNumber, code) => {
        this.setState({ mobileNumber: phoneNumber, phoneNumberError: '', mobileCode: code.dialCode })
    }


    finalCropedImage = (categoryImgPrev, categoryImg, visibleVal) => {
        // console.log("ldjshgvhbjkd", { categoryImgPrev, categoryImg, visibleVal })
        this.setState(
            {
                visibleVal,
                isCropper: false,
                profilePicPreview: categoryImgPrev,
                media: categoryImg,
            },
            () => this.submitImage()
        );
    }

    submitImage = () => {
        let self = this;
        let formData = new FormData();
        formData.append("requestUserId", this.props.match.params.id || "");
        formData.append("image", this.state.media);

        _Api(Url.USER_IMAGE_UPLOAD.method, Url.USER_IMAGE_UPLOAD.url, formData)
            .then((resp) => {
                // self.props.dispatch(userActions.profile());
                let responseData = resp.responseData.result;
                this.setState({
                    profilePicPreview: responseData.image,
                    isImageUpload: false,
                });
                window.location.reload();
            })
            .catch((err) => {
                this.setState({ isLoader: false });
                let errorMessage =
                    (err.error && err.error.responseMessage) || err.message;
                notify("err", errorMessage);
            });
    };







    //show user profileeeeeeeeeeeeeeeeeeeeeee

    changeUserStatus(userId, status) {
        // console.log("nbzjkcldcdn", { params: this.props.match.params.id, userId, status })
        let self = this;
        if (status == 1) {
            if (this.props.match.params.id == userId)
                this.setState({ userConnection: 1 })
        }
        else {
            if (this.props.match.params.id == userId)
                this.setState({ userConnection: 0 })

        }

    }

    handleError(err) {
        this.setState({ isLoader: false });
        let errorMessage = err.error && err.error.responseMessage || err.message;
        notify("err", errorMessage);
    }

    onMoreClick = (e) => {
        switch (e.key) {
            case "Remove":
                this.onRemoveAndBlock("COMMUNITY_CONNECTION_REMOVE", 3);

                break;
            case "Block":
                this.onRemoveAndBlock("COMMUNITY_CONNECTION_BLOCK", 1);
                // this.props.history.push('/community');
                break;
            case "AddBusinessConnection":
                this.onCreateBussinessRequest("AddBusinessConnection");
                break;
            case "Report":
                this.showModal();
                break;


        }
    }
    showModal = () => {
        this.setState({
            isReport: true
        });
    };

    onRemoveAndBlock = (api, status, id) => {

        // console.log("poiugdhj", this.state.connectionStatus)

        let req = {
            requestId: this.state.connectionStatus._id,
            status: status
        }
        _Api(Url[api].method, Url[api].url, req)
            .then(resp => {
                this.getUserProfile();
                this.props.history.push('/community');
            }).catch((err) => {
                this.handleError(err);
            })

    }


    onRemoveAndBlock2 = (api, status, id) => {
        let req = {
            requestId: id,
            status: status
        }
        _Api(Url[api].method, Url[api].url, req)
            .then(resp => {
                this.getUserProfile();
                this.props.history.push('/community');
            }).catch((err) => {
                this.handleError(err);
            })

    }

    onCreateBussinessRequest = () => {
        let req = {
            requestedUserId: this.props.match.params.id,
            connectionType: 2
        }
        _Api(Url.COMMUNITY_CREATEREQUEST.method, Url.COMMUNITY_CREATEREQUEST.url, req)
            .then(resp => {
                notify("success", resp.responseData.message);
                this.getUserProfile();
                notify(resp.responseData.message);
            }).catch((err) => {
                this.handleError(err);
            })
    }
    getPathName = (value) => {
        if (value.includes("/connection-profile"))
            return "connection";
        else if (value.includes("/pending-profile"))
            return "pending";
        else if (value.includes("/suggested-profile"))
            return "suggested";
        else if (value.includes("/requested-profile"))
            return "requested";

    }
    onAccept(requestId, status) {
        let req = {
            requestId: requestId,
            status: status
        }
        let { connectionId, id } = this.props.match.params;
        _Api(Url.COMMUNITY_ACCEPTREJECT.method, Url.COMMUNITY_ACCEPTREJECT.url, req)
            .then(resp => {
                notify("success", resp.responseData.message);
                this.props.history.push(`/user/profile/${this.props.match.params.id}`);
                this.getUserProfile();

            }).catch((err) => {
                this.handleError(err);
            })

    }
    onAcceptReject(e, requestId, status) {
        e.preventDefault();
        let req = {
            requestId: requestId,
            status: status
        }
        let { connectionId, id } = this.props.match.params;
        _Api(Url.COMMUNITY_ACCEPTREJECT.method, Url.COMMUNITY_ACCEPTREJECT.url, req)
            .then(resp => {
                notify("success", resp.responseData.message);
                this.props.history.push(`/connection-profile/${id}/connection/${connectionId}`);
                this.getUserProfile();

            }).catch((err) => {
                this.handleError(err);
            })

    }
    onConnectRequest(e, requestId, status) {
        e.preventDefault();
        let req = {
            requestedUserId: requestId,
            connectionType: 1
        }
        _Api(Url.COMMUNITY_CREATEREQUEST.method, Url.COMMUNITY_CREATEREQUEST.url, req)
            .then(resp => {
                notify("success", resp.responseData.message);
                this.getUserProfile();
            }).catch((err) => {
                this.handleError(err);
            })

    }


    // end user profileeeeeeeeeeeeeeeeeeeeee



    handleUpdateType = (type) => {
        if (
            this.state.currentType != 3 &&
            (type != 1 || this.state.currentType == 1)
        ) {
            this.setState({ brandType: type });
        }
    };


    updateUserType = () => {
        if (this.state.isEditPlan) {
            let userType = localStorage.getItem("userType");
            if (userType == 1) {
                this.updatePlan(this.props.match.params.id);
            }
        }
        else {
            this.setState({ isEditPlan: true })
        }
    }


    selectUser = (user) => {
        let self = this;
        // console.log("user",user);
        if (this.props.userDetail._id !== user._id) {
            this.setState({
                selectedUser: user,
                selectedUserId: user._id,
                chatType: 3,
                isShow: true
            }, function () {
                self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 3 })
                self.props.dispatch({ type: 'CHAT_OPEN' })
                document.body.classList.toggle('chat_open_mob');
                self.props.dispatch({ type: 'SELECTED_USER', selectedUser: user })
                socket.emit('groupAdd', { type: 7, userId: user._id });
                //socket.emit('userConnection', {userId: user._id});
            })
        }

    }


    videoCalling = (userId) => {
        let self = this;
        if (userId) {
            self.setState({
                ['videoConnectionLoder_' + userId]: true
            })
            self.props.dispatch(groupActions.joinOneMeeting({ userId: userId, type: 7 }))
                .then(function (res) {
                    self.setState({
                        ['videoConnectionLoder_' + userId]: false
                    })
                    if (res.statusCode === 1 || res.statusCode === "1") {
                        window.open('/calling/' + self.props.groupDetail.meeting._id, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                        //window.location.href = '/calling/' + self.props.groupDetail.meeting._id;
                        //history.push('/calling/'+self.props.groupDetail.meeting._id)
                    } else {

                    }
                    console.log("res", res)
                })
        }
    }

    selectCountry = (val) => {
        //console.log("value",val);
        let value = val.value;
        let data = value.split("#");
        // this.props.selectCountry(data[0],data[1]);
        this.setState({
            countrySelectedOption: val,
            selectedCountryCode: data[0],
            selectedCountry: data[1],
            selectedCity: "",
            selectedCityId: "",
            otherCity: "",
            // cityPlaceholder: 'Select City',
            showOtherCity: false,
            citySelectedOption: null,
            cityPlaceholder: 'Select City',
            selectedCityOption:'Select City'
            
        })
        this.getCity(data[0]);
    }

    selectCity = (val) => {
        let value = val.value;
        let data = value.split("#");
        //this.props.selectCity(data[1],data[0])
        if (value === "other") {
            this.setState({
                citySelectedOption: val,
                selectedCityOption:val,
                showOtherCity: true,
                selectedCity: "Other",
                otherCity: '',
                
            })
        } else {
            console.log("jhgcsfvdghjkl", { val, data })
            this.setState({
                citySelectedOption: val,
                selectedCityOption:val,
                showOtherCity: false,
                selectedCity: data[1],
                selectedCityId: data[0],
                otherCity: ''
            })
        }
    }


    getCountry = () => {
        let self = this;
        self.setState({ "isLoading": true })
        _Api(Url.GET_COUNTRY_LIST_V2.method, Url.GET_COUNTRY_LIST_V2.url)
            .then(resp => {
                if (resp.statusCode == 1) {
                    this.setState({
                        countryList: resp.responseData.result,
                    })
                }
                self.setState({ "isLoading": false })
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
            })
    }

    getCity = (selectedCountryCode) => {
        let self = this;
        //let selectedCountryCode=this.props.selectedCountryCode;
        _Api(Url.GET_CITY_LIST.method, Url.GET_CITY_LIST.url, '', '?countryCode=' + selectedCountryCode)
            .then(resp => {
                if (resp.statusCode == 1) {
                    this.setState({
                        cityList: resp.responseData.result,
                    })
                }
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
            })
    }


    render() {

        let { userData, twitterLink, instagramLink, facebookLink, youtubeLink, linkedinLink, companyWebsite, companyName, phoneNumber, email } = this.state;
        let { mobileNumberError, mobileNumber, mobileCode, loading, firstName, lastName,  firstNameError, passwordError, password, lastNameError, emailError,   } = userData ? userData : ""




        let countryData = [];
        {
            this.state.countryList.length > 0 && this.state.countryList.map((item) => (
                countryData.push({ value: item.country_code + "#" + item.country_name, label: item.country_name })
            ))
        }
        let cityData = [];
        {
            this.state.cityList.length > 0 && this.state.cityList.map((item) => (
                cityData.push({ value: item._id + "#" + item.city_name, label: item.city_name })
            ))
        }
        cityData.push({ value: 'other', label: 'Other' })

        let userType = localStorage.getItem("userType")

        console.log("lhsagdhjk", this.state.companyName)
        return (
            <section className={`brand-home-banner-bg brandprofile-banner-bg ${userType == 1 ? "brand dash_body_container withsidenavbar" : ''} `}>
                {/* <WebHeader history={this.props.history} match={this.props.match} /> */}

                {this.state.isLoader && <Loader />}
                {/*<ChatBox history={this.props.history} match={this.props.match} />*/}
                <div className="container-fluid  manage-company">
                    <a > <button onClick={() => this.props.history.goBack()} className="btn btn-light"><i class="fa fa-arrow-left" aria-hidden="true"></i>Back</button></a>
                    <div className="row">
                        <div className="col-sm-8 offset-sm-2 col-md-6 offset-md-3">
                            <div className=" pt-5 text-center">
                                <h1 className="home-heading pos-relative mb-5">
                                    Manage Company

                         <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                </h1>

                            </div>
                            <form class="rounded-textbox-form mt-5">
                                <div className="form-group">
                                    <label>Showcase Name</label>
                                    <input type="text"
                                        autoComplete="do-not-show-ac"
                                        className="form-control"
                                        placeholder="Company name "
                                        id="companyName"
                                        maxLength={64}
                                        name='companyName'
                                        value={companyName}
                                        onChange={this.onChangeInput}
                                    />
                                    <InlineError />
                                </div>
                                <div className="form-group">
                                    <label>Country</label>
                                    <Select showSearch
                                        placeholder={this.state.countrySelectedOption}
                                        onChange={(e) => this.selectCountry(e)}
                                        value={this.state.countrySelectedOption}
                                        options={countryData}

                                    >

                                    </Select>
                                    <InlineError />
                                </div>

                                <div className="form-group">
                                    <label>Closest Large City</label>
                                    <Select 
                                    showSearch
                                        placeholder={this.state.cityPlaceholder}
                                        onChange={this.selectCity}
                                        value={this.state.selectedCityOption}
                                        options={cityData}

                                    >

                                    </Select>
                                    <br />
                                    {
                                        this.state.showOtherCity === true ?
                                            <div className="form-group">
                                                <input type="text"
                                                    autoComplete="do-not-show-ac"
                                                    className="form-control"
                                                    placeholder="City Name"
                                                    id="city"
                                                    maxLength={250}
                                                    name='otherCity'
                                                    value={this.state.otherCity}
                                                    onChange={this.onChangeInput}
                                                />
                                            </div> : ""}
                                    <InlineError />
                                </div>

                                <div className="form-group">
                                    <label>Company Website</label>
                                    <input type="text"
                                        autoComplete="do-not-show-ac"
                                        className="form-control"
                                        placeholder="Company Website "
                                        id="email"
                                        maxLength={250}
                                        name='companyWebsite'
                                        onChange={this.onChangeInput}
                                        value={companyWebsite}
                                    />
                                    <InlineError message={this.state.companyWebsiteError} />
                                </div>
                                <div className="form-group">
                                    <label>Company Telephone</label>
                                    <PhoneInput
                                        country={'ae'}
                                        placeholder="Phone number"
                                        enableLongNumbers="false"
                                        autoFormat="false"
                                        autoComplete="do-not-show-ac"
                                        value={String(phoneNumber)}
                                        onChange={(phoneNumber, code) => this.setPhoneNumber(phoneNumber, code)}
                                    />
                                    {/* <input type="text"
                                               autoComplete="do-not-show-ac"
                                               className="form-control"
                                               placeholder="Email Address "
                                               id="email"
                                               maxLength={250}
                                               name='email'
                                               value="+440208085007"
                                        /> */}
                                    <InlineError />
                                </div>
                                <div className="form-group">
                                    <label>Company Email</label>
                                    <input type="text"
                                        
                                        autoComplete="do-not-show-ac"
                                        className="form-control"
                                        placeholder="Email Address "
                                        id="email"
                                        maxLength={250}
                                        onChange={this.onChangeInput}
                                        name='email'
                                        value={email}
                                    />
                                    <InlineError />
                                </div>
                                <div className="form-group">
                                    <label>LinkedIn</label>
                                    <input type="text"
                                        autoComplete="do-not-show-ac"
                                        className="form-control"
                                        placeholder="LinkedIn"
                                        id="email"
                                        maxLength={256}
                                        name='linkedinLink'
                                        onChange={this.onChangeInput}
                                        value={linkedinLink}
                                    />
                                    <InlineError message={this.state.linkedinLinkError} />
                                </div>
                                <div className="form-group">
                                    <label>Twitter</label>
                                    <input type="text"
                                        autoComplete="do-not-show-ac"
                                        className="form-control"
                                        placeholder="Twitter"
                                        id="email"
                                        maxLength={256}
                                        name='twitterLink'
                                        onChange={this.onChangeInput}
                                        value={twitterLink}
                                    />
                                    <InlineError message={this.state.twitterLinkError} />
                                </div>
                                <div className="form-group">
                                    <label>Instagram</label>
                                    <input type="text"
                                        autoComplete="do-not-show-ac"
                                        className="form-control"
                                        placeholder="Instagram"
                                        id="email"
                                        maxLength={256}
                                        name='instagramLink'
                                        onChange={this.onChangeInput}
                                        value={instagramLink}
                                    />
                                    <InlineError message={this.state.instagramLinkError} />
                                </div>

                                <div className="form-group">
                                    <label>Facebook</label>
                                    <input type="text"
                                        autoComplete="do-not-show-ac"
                                        className="form-control"
                                        placeholder="Facebook"
                                        id="email"
                                        maxLength={256}
                                        name='facebookLink'
                                        onChange={this.onChangeInput}
                                        value={facebookLink}
                                    />
                                    <InlineError message={this.state.facebookLinkError} />
                                </div>
                                <div className="form-group">
                                    <label>Youtube</label>
                                    <input type="text"
                                        autoComplete="do-not-show-ac"
                                        className="form-control"
                                        placeholder="youtube"
                                        id="email"
                                        maxLength={256}
                                        name='youtubeLink'
                                        onChange={this.onChangeInput}
                                        value={youtubeLink}
                                    />
                                    <InlineError message={this.state.youtubeLinkError} />
                                </div>
                            </form>

                            <div className="d-flex justify-content-center mt-4 mb-5">
                                <button type="button" onClick={() => this.props.history.goBack()} class="btn btn-warning btn-sm changepass mr-3" >Cancel</button>
                                <button type="submit" onClick={this.signUpSubmit} class="btn btn-warning btn-sm" >Save</button>
                            </div>
                        </div>
                    </div>

                </div>
                {userType != 1 && <Footer />}
            </section >
        )
    }
}
function mapStateToProps(state) {
    console.log("abcdefghhfiekjnmkwejnfnw", state)
    return {
        ...state
    };
}

export default connect(mapStateToProps)(EditManageCompany);