import { combineReducers } from "redux"

import { loading } from "./Common";
import { login } from './Auth';
import { profile } from './userProfile';
import { userDetail } from './user';
import { wishList, appLocalState, notificatonList, recentSearchList,brandDetailData} from './getCart';
import { groupDetail, expoUser, pageUser, userConnection, chatId, networking } from './group';
import { setting } from './setting';
import { pathDatail } from './path';
import {authSetting} from "./authSetting.reducer";

export default combineReducers({
    loading,
    login,
    profile,
    userDetail,
    wishList,
    appLocalState,
    groupDetail,
    setting,
    expoUser,
    pageUser,
    userConnection,
    chatId,
    networking,
    notificatonList,
    recentSearchList,
    pathDatail,
    brandDetailData,
    authSetting
})