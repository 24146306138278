import React, { Component } from 'react';
import { Brands } from '../../../SvgIcon';
import { Link } from "react-router-dom";
import { Empty } from 'antd';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';
import util from '../../../utils/Util.js';
import Loader from '../../../components/Loader';
// import { Modal, Button } from 'react-bootstrap';


import { Modal } from 'antd';



class BrandList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isDeleted: '',
            exhibtionId: '',
            status: "",
            page: 1,
            limit: 5000,
            loading: false,
            visible: false,
            selectedId: '',
            exhibitionListData: [],
            isLoader: false,
            search: '',
            brandListData: [],
            isEditExhibition: false,
            snNo: 0,
            assignBrandIds: [],
            sortInfo: {},

        }
    }


    componentDidMount() {
        this.getBrandList()
    }

    /** brands start */

    getBrandList = (isLoader = true) => {
        this.setState({ isLoader: isLoader })
        let { page, limit, search, sortInfo } = this.state;
        let req = {
            page: page ? page : 1,
            limit: limit,
            search: search.toString()
        }

        _Api(Url.GET_USER_LIST.method, Url.GET_USER_LIST.url, '', `?page=${req.page}&limit=${req.limit}&search=${req.search}&status=1`)
            .then(resp => {

                this.setState({ brandListData: resp.responseData?.result?.data, totalCount: resp.responseData?.result?.totalCount, isLoader: false });


            }).catch(() => {
                this.setState({ isLoader: false });
            })
    }


    handleSearch = e => this.setState({ search: e.target.value, brandListData: [], page: 1, snNo: 0 }, () => this.getBrandList());

    handleSelect = (e, id, isFeatured) => {
        if (isFeatured && !e.target.checked) {
            let params = {
                brand: [id],
                isFeatured: 2

            }

            _Api(Url.FEATURED_BRAND_FEATURE_UPDATE.method, Url.FEATURED_BRAND_FEATURE_UPDATE.url, params)
                .then(resp => {
                    // notify("success", "Feature removed Successfully");
                    this.getBrandList(false);
                }).catch(err => {
                    let errorMessage = err?.error?.responseMessage || err.message;
                    notify("err", errorMessage);

                })

        }

        if (e.target.checked) {
            this.state.assignBrandIds.push(id);
        }
        else {
            var index = this.state.assignBrandIds.indexOf(id);
            if (index > -1) {
                this.state.assignBrandIds.splice(index, 1);
            }
        }
        let checkboxFiield = 'checkbox' + id
        this.setState({ [checkboxFiield]: e.target.checked })

    }

    onPressBtn = (page) => {
        this.setState({ page, snNo: page - 1, brandListData: [] }, () => {
            this.getBrandList()
        })

    }

    onClickAssign = () => {
        let assignLimit = Number(this.props.match.params.featuredCount);
        let { assignBrandIds } = this.state;
        let params = {
            brand: assignBrandIds,
            isFeatured: 1

        }
        // if (assignBrandIds.length > 0) {
            _Api(Url.FEATURED_BRAND_FEATURE_UPDATE.method, Url.FEATURED_BRAND_FEATURE_UPDATE.url, params)
                .then(() => {
                    notify("success", "Feature Added Successfully");
                    this.props.history.push("/feature-businesslist");

                }).catch(err => {
                    let errorMessage = err.error && err.error.responseMessage || err.message;
                    notify("err", errorMessage);

                })
        // }
        // else {
        //     notify("err", "Please select at least one new brand for add in featured brand")

        // }

    }

    render() {

        const { isLoader, brandListData, sortInfo } = this.state;
        return (
            <>
                <div className="dash_body_container">
                    {isLoader && <Loader />}
                    <Header />
                    <Sidebar />

                    <>
                        <div class="header-text d-flex mb-4 mt-3">
                            <div class="d-flex flex-grow-1 align-items-center">
                                <p className="header-icon"><Brands /></p><h4>Businesses</h4>
                            </div>
                            <div class="search_wrapper  mr-2">
                                <i class="fa fa-search"></i>
                                <input type="search" placeholder="Search..." name="search"
                                    onChange={this.handleSearch}
                                />
                            </div>
                            <div class="">
                                <Link onClick={this.onClickAssign} className="btn btn-blue-large mr-2">Assign to feature Businesses</Link>
                                <Link to={'/feature-businesslist'} > <button className="btn btn-light"><i class="fa fa-arrow-left" aria-hidden="true"></i>Back</button></Link>
                            </div>
                        </div>

                    </>

                    <div class="table-responsive">
                        <table class="table table-bordered rounded-table">
                            <thead>
                                <tr>
                                    <th>Select</th>
                                    <th>Company Name</th>
                                    <th>Email</th>
                                    <th>Businesses Type</th>
                                    <th>No of Jobs</th>
                                    <th>No of Staff</th>
                                </tr>
                            </thead>
                            <tbody>

                                {brandListData?.map((item) => {
                                    return (
                                        <tr>
                                            <td><input type="checkbox"
                                                onChange={(e) => this.handleSelect(e, item?._id, item?.isFeatured == 1)}
                                                checked={this.state[`checkbox${item?._id}`] == undefined ? item?.isFeatured == 1 : this.state[`checkbox${item?._id}`]}
                                                value="option1"
                                            />
                                            </td>
                                            <td> <Link to={`/business/details/${item?._id}`} > {item?.companyName}</Link></td>
                                            <td>{item?.email}</td>
                                            <td> {item?.userPlan == 3 ? "Premium brand showcase" : "Brand showcase"}</td>
                                            <td>{item?.productCount}</td>
                                            <td>{item?.staffCount}</td>
                                           
                                        </tr>
                                    )
                                })

                                }
                            </tbody>
                        </table>

                        <div>
                            {!isLoader && brandListData && brandListData.length == 0 &&
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

export default BrandList