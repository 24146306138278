/**
 * @about auth api file that contains login function
 * that get data from servers
 */

import axios from "axios";
// api end point

import { history } from "../routing/index";

import siteSetting from '../config/env/Index';
const url = siteSetting.api.url;


const authKey = "Basic cGJ3b3JsZDpwYndvcmxk"


//common for all api
export const _Api = (methodType, endPoint, request = '', query = '') => {

    try {
        return new Promise((resolve, reject) => {
            let Cmp_Url = `${url}${endPoint}`
            axios({
                method: methodType,
                url: `${Cmp_Url}${query}`,
                data: request,
                headers: {
                    "Authorization": authKey,
                    'Content-Type': 'application/json',
                    accessToken: localStorage.getItem("accessToken")
                }
            }).then(resp => {              
                if (resp.data.statusCode) {
                    resolve(resp.data)
                }
                else {
                    if (resp.data.error && resp.data.error.errorCode == 2 || (resp.data.error && resp.data.error.errorCode == 17)) {
                        localStorage.clear()
                        history.push("/")
                        window.location.reload()
                    }
                    // resolve(resp.data)
                    reject(resp.data)
                }
            }).catch(err => {
                reject(err)
            }

            )
        })

    }
    catch (err) {

    }
}


export const _Api2 = (methodType, endPoint, request , query = '') => {    
    let Cmp_Url = `${url}${endPoint}`
    let  headers= {
        "Authorization": authKey,
        'Content-Type' : 'application/x-www-form-urlencoded',
        accessToken: localStorage.getItem("accessToken")
    };
    return axios.post(`${Cmp_Url}${query}`,request, {headers:headers})
}

export const _ApiMultipart = (methodType, endPoint, request) => {
    //console.log('request',request);

    let Cmp_Url = `${url}${endPoint}`
    let  headers= {
        "Authorization": authKey,
        'Content-Type' : 'multipart/form-data',
        accessToken: localStorage.getItem("accessToken")
    };
    return axios.post(`${Cmp_Url}`,request, {headers:headers})
}



// function signUpProcess (params) {

//     let  headers= {
//         "Authorization": authKey,
//         'Content-Type' : 'application/x-www-form-urlencoded',
//         accessToken: localStorage.getItem("accessToken")
//     };
//     return axios.post(API+params.hairdresserId+'/client/details',params, {headers:headers})
// }







 