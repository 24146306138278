import React, { Component } from 'react';
import { WhatWeDeliverTraingle, Hert } from '../../SvgIcon';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { injectStripe } from "react-stripe-elements";
import Footer from '../layout/Footer';
import { validateEmail } from '../../components/Validation';
import InlineError from "../../components/InlineError";
import moment from 'moment-timezone';
import { _Api } from '../../services/Api';
import Url from '../../services/BaseUrl';



import { Modal, Button, Tabs } from 'antd';




const currentDate = moment();
const { TabPane } = Tabs;


var countDownDate = new Date("July 25, 2021 08:00:00 GMT").getTime();
class CountDown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            days: '',
            hours: '',
            minutes: '',
            seconds: '',
            activeClass: '',
            visible: false,


            totalAmount: '$1000.00',
            isPlan: false,

            emailName: '',
            emailNameError: '',
            emailvalueForLabel: [],
            plans: [],
            sideList: []







        }

    }

    componentDidMount() {
        if (localStorage.getItem('accessToken') && localStorage.getItem('accessToken') !== '') {
            // window.location.href="/home"
            // // this.getRole()
            setInterval(() => this.time(), 1000);
        } else {
            // this.props.history.push('/')
        }
        window.addEventListener('scroll', () => {
            let activeClass = 'fixed-header';
            if (window.scrollY === 0) {
                activeClass = 'top-RFheader';
            }
            this.setState({ activeClass });
        });
    }
    time = () => {
        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        this.setState({ days: days, hours: hours, minutes: minutes, seconds: seconds })
    }

    upgradePlatinum = () => {
        //window.open("https://news.aestheticmed.co.uk/am-live-online-2021-platinum-sponsorship-ryi","_blank")
    }

    showAll = () => {
        localStorage.setItem("showAll", "true")
        localStorage.setItem("isComming", "true")
        window.location.href = "/home"
        //window.location.reload()
    }

    logout = () => {
        localStorage.clear();
        window.location.href = "/"
    }

    manageBrand = () => {
        window.location.href = `/business/profile/${localStorage.getItem("ownerId") ? localStorage.getItem("ownerId") : localStorage.getItem("id")}`
    }

    handleCancel = () => {
        this.setState({
            visible: false,
            emailName: '',
            emailvalueForLabel: []
        });
    }

    removeSelectedEmail = (email) => {
        let stateEmail = this.state.emailvalueForLabel
        let index = stateEmail.indexOf(email);
        if (index > -1) {
            stateEmail.splice(index, 1);
        }
        this.setState({ emailvalueForLabel: stateEmail });
    }

    onAddEmail = (e) => {
        if (!validateEmail(e.target.value).status) {
            this.setState({ emailName: e.target.value, emailError: "Please enter valid email" });

        }
        else {
            this.setState({ emailName: e.target.value, emailError: "" });
        }


    }

    getCollleaguesList = () => {
        let label = this.state.emailvalueForLabel;
        if (this.state.emailName == '') {
            this.setState({ emailvalueForLabel: label, emailError: "Please enter valid email" });
        }
        else if (this.state.emailName) {
            label.push(this.state.emailName);
            this.setState({ emailvalueForLabel: label, emailName: "" });
        }




    }
    submitCollleaguesList = () => {
        let label = this.state.emailvalueForLabel;
        console.log("pankajjj", this.state.emailvalueForLabel.length == 0 && this.state.emailName == '')
        if (this.state.emailvalueForLabel.length == 0 && this.state.emailName == '') {
            this.setState({ emailError: "Please enter valid email" });
        }
        else {
            let req = {
                email: this.state.emailvalueForLabel != "" ? this.state.emailvalueForLabel.join() : this.state.emailName
            }

            _Api(Url.GET_REGISTER_COLLEAGUES.method, Url.GET_REGISTER_COLLEAGUES.url, req)
                .then(resp => {
                    console.log("resres", resp)
                    //notify('success', resp.responseData.message)
                    this.setState({ isLoader: false, isSubmitted: true })
                }).catch(err => {
                    this.setState({ isLoader: false })

                })

        }

    }

    backCancel = () => {
        this.setState({
            isSubmitted: false,
            visible: false,
            emailvalueForLabel: []
        });
    }


    render() {
        let { days, hours, minutes, seconds } = this.state;
        console.log("this.props", this.props)
        return (


            <section className="Home-banner-bg hj landing-temp-wrp thankyou-wrp-temp">
                {/*<div className="container-fluid">*/}
                {/*    <a onClick={this.showAll} className=" transparent-btn"></a>*/}
                {/*</div>*/}

                <div className="landing_bannerbg countdown-page">
                    <div className="home-evnt-wrp-bg   home-sectn-new">
                        <div className={`job-landing-header ${this.state.activeClass}`} >
                            <div className="container-fluid">
                                <div className="row align-items-center">
                                    <div className="col-md-4 col-5">
                                        <div className="am-logo-wrp">
                                            <a>
                                                <img src={require('../../assets/images/aesthetic/navbar_logo.jpg')} className="img-fluid" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-8 col-7">
                                        <div className="am-evnt-hdr-right">
                                            <div className=" levebtn top-lgn-btn text-right">
                                                <ul className="list-inline mb-0 p-0">
                                                    <li className="list-inline-item">
                                                        <button type="button" className="btn btn-red logt addbtn" onClick={() => this.logout()}>Logout</button>
                                                    </li>
                                                    {localStorage.getItem("userType") !== 0 && localStorage.getItem("userType") !== "0" ?
                                                        <li className="list-inline-item">
                                                            <Link className="btn btn-red mange addbtn" onClick={() => this.manageBrand()}>Manage Showcase</Link>
                                                        </li>
                                                        : ""}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div className="container-fluid">
                            <div className="evnt-am-wrp pt-4 pt-md-4">
                                <div className="am-hme-caption">
                                    <h2>Your dream job in<br />beauty, hair, spa or aesthetics<br /> in the UAE starts here…</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="countDown-wrapper text-center py-3 py-md-4">
                        <div className="conatainer-fluid">
                            <div className="ent-am-strt-wrp pt-2 pt-md-3">
                                <h4 className="w-100 text-center  timer-wrapper"><b onClick={this.showAll} style={{ cursor: 'auto' }}>This event will start in...</b></h4>
                                <div className="timer_parent">
                                    <div className="timer">
                                        <div>
                                            <h1>{days}</h1>
                                            <p className="d-none d-sm-block">Days</p>
                                            <p className="d-block d-sm-none">DAYS</p>
                                        </div>
                                    </div>
                                    <div className="timer">
                                        <div><h1>{hours}</h1>
                                            <p className="d-none d-sm-block">Hours</p>
                                            <p className="d-block d-sm-none">HRS</p>
                                        </div>
                                    </div>
                                    <div className="timer">
                                        <div><h1>{minutes}</h1>
                                            <p className="d-none d-sm-block">Minutes</p>
                                            <p className="d-block d-sm-none">MINS</p>
                                        </div>
                                    </div>
                                    <div className="timer">
                                        <div><h1>{seconds}</h1>
                                            <p className="d-none d-sm-block">Seconds</p>
                                            <p className="d-block d-sm-none">SECS</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    <div className="container-fluid ">
                        <div className="row thnkutext">
                            <div className="col-sm-12 text-center">
                                <div className="thanku m-auto thnkYou-new-wrp">
                                    <h1 className="heading-5 mb-3"><span>Thank You</span></h1>
                                    <h3>Your payment is being processed.</h3>
                                    <h6>We will send an invoice to you within 7 working days<br /> once your payment has been processed. </h6>
                                    <h6>You will receive further details via email including platform<br /> opening dates, how to access the system and next steps<br /> regarding completing your profile.</h6>
                                    <h3 className="mt-2 mt-md-4 mb-3">We look forward to welcoming you to Worldwide Job Finder</h3>
                                    <h6><span className="d-block">25 – 27 July</span> 8am - 6pm (Gulf Standard Time)</h6>
                                    <h6 className="my-3">Please make a note of the email and password you used <br /> to register as you will need these in order to access the platform.</h6>

                                    <div className="text-center py-3">
                                        <Link className="btn btn-com" onClick={() => this.setState({ visible: true })}>tell a friend</Link>
                                    </div>

                                </div>
                                {/* <Link className="btn btn-red mt-4 mb-5" onClick={() => this.toggleSignup(1)} >UPGRADE TO A PREMIUM SHOWCASE</Link> */}
                                {/* <div className="text-center mt-5">
                                <Link className="btn btn-red" onClick={()=>this.upgradePlatinum()} >UPGRADE TO PLATINUM SPONSORSHIP</Link>
                            </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid queryMsg mb-5">
                        <div className="row">
                            <div className="col-md-12 col-xl-10 offset-xl-1 mt-4 mb-4">
                                <div className="thankyou-text">
                                    <div className="callu_parent">
                                        <div className="text-dark">
                                            If you have any questions in the meantime please do not hesitate to get in touch with the Worldwide Job Finder team</div>
                                        <div>
                                            <span className="hert-phone">
                                                {/* <img src={require('../../assets/images/hert-phone.png')} className="img-fluid" /> */}
                                            </span>


                                            {/* <a href="tel:+4402073510536" className="colorW">+44 (0)207 351 0536</a><br /> */}
                                            <a className="colorW" href="mailto:info@worldwidejobfinder.com">info@worldwidejobfinder.com
</a></div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <Footer />

                <Modal
                    wrapClassName="mail_modal"
                    className="cm_popup signup_modal "
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    centered
                    footer={null}
                >
                    <div className="sign-modal-content">
                        {!this.state.isSubmitted && <div className="row align-items-center">
                            <div className="col-md-12" >
                                <h3 className="auth-heading pos-relative text-center mt-5">
                                    Tell your colleague(s)<span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                </h3>
                                <form className="rounded-textbox-form mt-5" >
                                    <div className="form-group">
                                        <h4> Enter their email address</h4>
                                        <a>When entering multiple email addresses separate with a comma.</a>

                                        <div class="search-container d-flex mt-4">
                                            <input type="text" placeholder="Enter email" value={this.state.emailName} name="promocodeTitle" onChange={(e) => this.onAddEmail(e)} class="form-control" />
                                            {!this.state.emailError &&
                                                <button type="button" class="btn" onClick={(e) => this.getCollleaguesList()}>Add</button>}
                                        </div>
                                        <InlineError
                                            message={this.state.emailError}
                                        />

                                        <div className="mt-4 d-flex flex-wrap">
                                            {this.state.emailvalueForLabel.map((item) => <span className="mailtag">{item}<span className="mailtag-cross" aria-hidden="true" onClick={(e) => this.removeSelectedEmail(item)}>&times;</span></span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button type="button" className="btn btn-red" onClick={(e) => this.submitCollleaguesList()}>Submit</button>
                                    </div>
                                </form>

                            </div>

                        </div>}
                        {this.state.isSubmitted &&
                            <div className="row align-items-center">
                                <div className="col-md-12 pt-5">
                                    <h3 className="auth-heading pos-relative text-center mt-5">
                                        Email sent <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                    </h3>
                                    <h4 className="text-center mt-5">Your colleague will receive an email invitation. Please tell them to check their inbox (and junk folder)</h4>
                                    <div className="text-center mt-5">
                                        <button type="submit" disabled={this.props.isLoading} className="btn btn-red" onClick={this.backCancel}>Back</button>
                                    </div>

                                </div>
                            </div>
                        }
                    </div>

                </Modal>

            </section>
        )
    }
}



// export default CountDown;
function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(injectStripe(CountDown));