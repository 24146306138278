import React, { Component } from 'react';

import { notify } from '../../components/Toaster';
import Loader from '../../components/Loader'
//Api
import { getRole } from '../../redux/action/Auth'
import { _Api } from '../../services/Api';
import Url from '../../services/BaseUrl'

import { connect } from "react-redux"
import { bindActionCreators } from "redux"

// components
import {
    MyAlert,
    MySpinner
} from "../../components/index"


class CheckRole extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoader: false,
        }
        this.props.action.getRole()
    }

    componentDidMount() {
        let showAll = localStorage.getItem('showAll');
        if (this.props.userType == 1)
            this.props.history.push("/exhibition")
        else if (this.props.userType == 0 )
            this.props.history.push("/plans")
        else if (this.props.userType == 2 || this.props.userType == 4 || this.props.userType == 3 ){
            if(showAll){
                this.props.history.push("/home");
            }else{
                this.props.history.push("/coming-soon");
            }
        }
        // else if (!(localStorage.getItem("accessToken") && localStorage.getItem("isLoggedIn")))
        //     this.props.history.push("/dashboard")

    }
    render() {
        return (
            <>
                {/*<Loader />*/}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    // console.log("state",state);
    const { profile } = state
    //console.log("pjhgdhdjkdlhgv", profile)
    return {
        userType: profile?.profileInfo?.userType,
        userPlan: profile?.profileInfo?.userPlan
    }
}
const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators({ getRole }, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CheckRole);