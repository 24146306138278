import React, { Component } from 'react';
import { ExhibitionManagement } from '../../../SvgIcon';
import { Link } from "react-router-dom";

import { BackBtn } from "../../common/Button"

const TopBarStatus = (props) => {
    const data = ["Exhibition Detail", "Conference Detail", "Networking Lounge", "Brands"]
    return (
        <div className="header-container">
            <div className="d-flex align-items-center">
                <p className="header-icon"><ExhibitionManagement /></p>
                <h4>Add New Exhibition
            <br />
                    {/* <ul className="header-breadcrum">
                        {data.filter((item, index) => props.step > index).map((item, index) => (
                            <li>{index != 0 && '/'}<Link to="#">{item}</Link></li>
                        ))}
                    </ul> */}
                </h4></div>
                <Link to={'/exhibition'} > <button className="btn btn-light"><i class="fa fa-arrow-left" aria-hidden="true"></i>Back</button></Link>
            
        </div>
    )
}

export default TopBarStatus
