import React, { Component } from "react"
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import { _Api } from '../../services/Api';
import Url from '../../services/BaseUrl';
import { notify } from '../../components/Toaster';
import { TreeSelect, Tree, Modal } from 'antd';
import { Select } from 'antd';
import ReactSelect from 'react-select';
import { CmBtn } from "../../components/common/Button";
import { Hert, Cross } from "../../SvgIcon";
import util from '../../utils/Util';
import { getExhibitionId, setLocalState } from '../../redux/action';
import { appLocalState } from "../../redux/reducer/getCart";
var _ = require('lodash');
const { SHOW_PARENT, SHOW_CHILD, SHOW_ALL } = TreeSelect;
const { Option } = Select;

// const CustomOption = props => {
//     debugger;
//     const { data, innerRef, innerProps } = props;
//     return (
//         <div ref={innerRef} {...innerProps} className="custom-select-option">
//             {data.label} - <b style={{color:"#5383a2"}}> <i>{data.sectorName}</i> </b>
//         </div>
//     ) ;
// };
let url = window.location.pathname
class FilterHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            dropdownValue: props.setSelected || "",
            ishideHeader: props.ishideHeader || false,
            ishideSearch: props.ishideSearch || false,
            searchKey: props.searchKey,
            countryList: [],
            cityList: [],
            questionList: [],
            userAns: [],
            productAndServiceOfferData: [],
            sectorData: [],
            dataOption: [],
            productReset: props.productReset,
            productType: 'jobs',
            categoryData: [],


            manageCategoriesId: []


        }


    }

    setInit = () => {
        let { appLocalState, dispatch, scope } = this.props;
        appLocalState.filter = {
            placeOfWorkId: [],
            productAndServiceOfferId: [],
            noOfPeopleWorkId: [],
            budgetId: [],
            jobTitleId: [],
            location: [],
            city: [],
            attendingOrLookingforId: [],
            sectorId: []
        };
        appLocalState.multiFilter = {
            placeOfWorkId: [],
            jobTitleId: [],
            noOfPeopleWorkId: [],
            productAndServiceId: [],
            budgetId: [],
            location: [],
            attendingOrLookingforId: [],
            sectorId: [],
            city: [],
            userAns: []
        };
        dispatch(setLocalState(appLocalState));

        localStorage.removeItem('productType')
        localStorage.setItem("filters", JSON.stringify(appLocalState))
    }


    fetchToggle = () => {
        this.setState({
            visible: !this.state.visible,
        })
    }

    showFilter = () => {
        Filter()
    }

    componentDidMount() {
        // console.log("yessssssssssssssss")
        // this.getCategory("1", "placeOfWorkData");
        // this.getCategory("2", "jobTitleData");
        // this.getCategory("3", "productAndServiceOfferData");
        // this.getCategory("5", "budgetData");
        // this.getCategory("6", "noOfPeopleWorkData");        
        if (this.props.newProduct) {
            this.setState({ productType: "Jobs" })

        }

        let data = localStorage.getItem("filters")
        let filter = JSON.parse(data)
        // console.log("ojhhjkl", filter?.filter?.sectorName)
        if (filter?.filter?.sectorName && filter?.filter?.sectorName != "All Sectors")
            this.getCategory("4", "productAndServiceOfferData", filter?.filter?.sectorName);
        else
            this.getCategory("4", "productAndServiceOfferData");
        this.getInfo()
        this.getSector();
        this.getCountry();
        this.getCategoryProduct()
        let self = this;
        let exhibitionId = self.props.loading.exhibitionId;
        if (exhibitionId && exhibitionId !== "") {
            self.getQuestion(exhibitionId);
        } else {
            self.props.dispatch(getExhibitionId())
                .then(function () {
                    self.getQuestion(self.props.loading.exhibitionId);
                })
        }
    }
    getCountry = () => {
        let self = this;
        self.setState({ "isLoading": true })
        _Api(Url.GET_COUNTRY_LIST_V2.method, Url.GET_COUNTRY_LIST_V2.url)
            .then(resp => {
                if (resp.statusCode == 1) {
                    this.setState({
                        countryList: resp.responseData.result,
                    })
                    self.createTreeSelectCountryData(resp.responseData.result, 'countryData');
                }
                self.setState({ "isLoading": false })
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
            })
    }
    getCity = (selectedCountryCode) => {
        let self = this;
        self.setState({
            cityList: [],
        })
        _Api(Url.GET_CITY_LIST2.method, Url.GET_CITY_LIST2.url, { countryName: selectedCountryCode })
            .then(resp => {
                if (resp.statusCode == 1) {
                    if (selectedCountryCode !== "") {
                        this.setState({
                            cityList: resp.responseData.result,
                        })
                    }
                }
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
            })
    }
    getSector = () => {
        let self = this;
        _Api(Url.GET_SECTOR_SIGNUP_LIST.method, Url.GET_SECTOR_SIGNUP_LIST.url)
            .then(resp => {
                if (resp.statusCode == 1) {
                    self.setState({
                        sectorList: resp.responseData.result,
                    });
                    let sectorId = [];
                    resp.responseData.result.map(function (sector) {
                        sectorId.push(sector._id)
                    })
                    self.createTreeSelectData(resp.responseData.result, 'sectorData');
                    self.getplaceOfWork(sectorId);
                }
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
            })
    }

    getplaceOfWork = (sectorId) => {
        let self = this;
        self.setState({ "isLoading": true })
        _Api(Url.GET_PLACE_OF_WORK_LIST.method, Url.GET_PLACE_OF_WORK_LIST.url, { sectorId: sectorId.toString() })
            .then(resp => {
                if (resp.statusCode == 1) {
                    this.setState({
                        placeOfWorkList: resp.responseData.result.data,
                    })
                    let placeOfWorkId = [];
                    resp.responseData.result.data.map(function (pow) {
                        placeOfWorkId.push(pow._id)
                    })
                    self.getRole(placeOfWorkId);
                    self.createTreeSelectData(resp.responseData.result.data, 'placeOfWorkData');
                    self.setState({ "isLoading": false })
                }
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
            })
    }

    getRole = (placeOfWorkId) => {
        let self = this;
        self.setState({ "isLoading": true })
        _Api(Url.GET_ROLE_LIST.method, Url.GET_ROLE_LIST.url, { placeOfWorkId: placeOfWorkId.toString() })
            .then(resp => {
                if (resp.statusCode == 1) {
                    this.setState({
                        roleList: resp.responseData.result.data,
                    })
                    self.setState({ "isLoading": false })
                    self.createTreeSelectData(resp.responseData.result.data, 'jobTitleData');
                }
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
            })
    }

    createTreeSelectData = (arrayList, keyName) => {
        let keyNameData = arrayList.map((item1) => {
            let dd = {
                selectable: true,
                title: item1.name,
                value: item1._id,
                checkable: true,
            }
            return dd
        })
        // if(keyName=='sectorData'){
        //     let data = {
        //         selectable: true,
        //         title: 'All Sectors',
        //         value: '',
        //         checkable: true,
        //     }
        //     keyNameData.splice( 0, 0, data )
        // }
        // else
        // if (keyName == 'placeOfWorkData') {
        //     let data = {
        //         selectable: true,
        //         title: 'Place Of Work',
        //         value: '',
        //         checkable: true,
        //     }
        //     keyNameData.splice(0, 0, data)
        // }
        //  else if (keyName == 'jobTitleData') {
        //     // let data = {
        //     //     selectable: true,
        //     //     title: 'All Job Role',
        //     //     value: '',
        //     //     checkable: true,
        //     // }
        //     keyNameData.splice(0, 0, data)
        // }
        this.setState({
            [keyName]: keyNameData
        })
    }

    createTreeSelectCountryData = (arrayList, keyName) => {
        let keyNameData = arrayList.map((item1) => {
            let dd = {
                selectable: true,
                title: item1.country_name,
                value: item1.country_code + '#' + item1.country_name,
                checkable: true,
            }
            return dd
        })
        let data = {
            selectable: true,
            title: 'All Country',
            value: '',
            checkable: true,
        }
        keyNameData.splice(0, 0, data)
        this.setState({
            [keyName]: keyNameData
        })
    }

    getQuestion = (exhibitionId) => {
        let self = this;
        _Api(Url.EVENT_QUESTION.method, Url.EVENT_QUESTION.url, { exhibitionId: exhibitionId })
            .then(resp => {
                if (resp.statusCode == 1) {
                    this.setState({
                        questionList: resp.responseData.result.data
                    })
                }
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
            })
    }

    getInfo = () => {
        _Api(
            Url.GET_CATEGORY_PRODUCT_WEB.method,
            Url.GET_CATEGORY_PRODUCT_WEB.url,
            "",
            // `?requestUserId=${req.requestUserId}`
        )
            .then((resp) => {

                let responseData = resp.responseData.result;
                // console.log("sajdbsajkd", responseData)           



                this.setState({
                    isLoader: false,
                    categoryData: responseData.map((item) => {
                        return (
                            {
                                title: item?.name,
                                value: item?._id,

                            }
                        )

                    })

                })
            })
            .catch((err) => {
                console.log("sajdbsajkd", err)
                this.setState({ isLoader: false });
            });
    }

    getCategory = (categoryType, keyName, sId = '') => {
        let params = {
            categoryType: categoryType,
            sector: sId
        }
        _Api(Url.GET_CATEGORY_LIST.method, Url.GET_CATEGORY_LIST.url, params)
            .then(resp => {
                let dataOption = [];
                let keyNameData = resp.responseData.result.map((item1) => {
                    let childData = item1.subCategory.map((data) => {

                        //dataOption.push({ value:  data._id, label: `${data.categoryTitle}`,sectorName:`${item1.categoryTitle}`,custom:true })
                        let ddd = {
                            title: <div> {data.categoryTitle}-<b style={{ color: "#5383a2" }}> <i>{item1.categoryTitle}</i></b></div>,
                            value: data._id,
                            children: []
                        }
                        return ddd;
                    })
                    if (dataOption.length === 0) {
                        dataOption = childData;
                    } else {
                        dataOption = _.union(dataOption, childData);
                    }
                    //return childData;
                    let dd = {
                        selectable: childData.length > 0 ? false : true,
                        // title: childData.length > 0 ? <b style={{ color: '#000', display:'none' }}>{item1.categoryTitle}</b> : item1.categoryTitle,
                        title: '',
                        value: item1._id,
                        checkable: childData.length > 0 ? false : true,
                        children: childData
                    }
                    return dd
                })
                this.setState({
                    // [keyName]: keyNameData,
                    [keyName]: dataOption,
                    //dataOption:dataOption,
                    //dataOption44:dataOption

                })
            }).catch(err => {
                this.setState({ isLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
                this.setState({ isLoader: false })
            })
    }
    onChangeSelect = (key, value, label) => {
        // console.log("onChangeSelect #########",key, value, label[0])
        if (key === "location") {
            let val = value;
            let data = val.split("#");
            let { appLocalState, dispatch, scope } = this.props;
            appLocalState.filter[key] = data[1];
            appLocalState.multiFilter[key] = [data[1]];
            dispatch(setLocalState(appLocalState));
            localStorage.setItem("filters", JSON.stringify(appLocalState))
            if (scope)
                scope.getList(appLocalState.filter)
        } else {
            let { appLocalState, dispatch, scope } = this.props;
            appLocalState.filter[key] = value;
            if (key == "sectorId")
                appLocalState.filter['sectorName'] = label[0];
            else if (key == "servicesShowcaseId")
                appLocalState.filter['servicesShowcaseId'] = value;
            appLocalState.multiFilter[key] = [value];
            dispatch(setLocalState(appLocalState));
            localStorage.setItem("filters", JSON.stringify(appLocalState))
            if (scope)
                scope.getList(appLocalState.filter)
        }

    }
    onChangeSelectProduct = (key, value, label) => {
        console.log("selectttt", key, value, key == "servicesShowcaseId")
        if (key === "location") {
            let val = value;
            let data = val.split("#");
            let { appLocalState, dispatch, scope } = this.props;
            appLocalState.filter[key] = data[1];
            appLocalState.multiFilter[key] = [data[1]];
            dispatch(setLocalState(appLocalState));
            localStorage.setItem("filters", JSON.stringify(appLocalState))
            scope.getList(appLocalState.filter)

        } else {
            let { appLocalState, dispatch, scope } = this.props;
            appLocalState.filter[key] = value;
            if (key == "sectorId")
                appLocalState.filter['sectorName'] = value;
            else if (key == "servicesShowcaseId")
                appLocalState.filter['servicesShowcaseId'] = value;
            appLocalState.multiFilter[key] = [value];
            dispatch(setLocalState(appLocalState));
            localStorage.setItem("filters", JSON.stringify(appLocalState))
            scope.getList(appLocalState.filter)

            console.log("consoleeee", { appLocalState, loc: appLocalState.filter })
        }

    }
    clearFilter = () => {
        console.log("clearFilter");
        let { appLocalState, dispatch, scope } = this.props;
        appLocalState.filter = {
            placeOfWorkId: "",
            productAndServiceId: "",
            noOfPeopleWorkId: "",
            budgetId: "",
            sectorId: "",
            location: ""
        };
        appLocalState.multiFilter = {
            placeOfWorkId: [],
            jobTitleId: [],
            noOfPeopleWorkId: [],
            productAndServiceId: [],
            budgetId: [],
            location: [],
            attendingOrLookingforId: [],
            sectorId: [],
            city: [],
            userAns: []
        };
        dispatch(setLocalState(appLocalState));
        // if(scope && scope.getList)
        scope.getList(appLocalState.filter);
        setTimeout(() => window.location.reload())
    }
    getData = () => {
        let { scope, appLocalState } = this.props;
        let { multiFilter } = appLocalState
        let filter = {
            placeOfWorkId: multiFilter.placeOfWorkId.join(),
            productAndServiceOfferId: multiFilter.productAndServiceId.join(),
            noOfPeopleWorkId: multiFilter.noOfPeopleWorkId.join(),
            budgetId: multiFilter.budgetId.join(),
            jobTitleId: multiFilter.jobTitleId.join(),
            location: multiFilter.location.join(),
            city: multiFilter.city.join(),
            attendingOrLookingforId: multiFilter.attendingOrLookingforId.join(),
            sectorId: multiFilter.sectorId.join()
        }
        localStorage.setItem("filters", JSON.stringify(appLocalState))
        scope.getList(filter);
    }
    getDataProduct = () => {
        let { scope, appLocalState } = this.props;
        let { multiFilter } = appLocalState
        let filter = {
            placeOfWorkId: multiFilter.placeOfWorkId.join(),
            productAndServiceOfferId: multiFilter.productAndServiceId.join(),
            noOfPeopleWorkId: multiFilter.noOfPeopleWorkId.join(),
            budgetId: multiFilter.budgetId.join(),
            jobTitleId: multiFilter.jobTitleId.join(),
            location: multiFilter.location.join(),
            city: multiFilter.city.join(),
            attendingOrLookingforId: multiFilter.attendingOrLookingforId.join(),
            sectorId: multiFilter.sectorId.join()
        }
        localStorage.setItem("filters", JSON.stringify(appLocalState))
        scope.getList(filter);
    }
    handleVisibleChange = flag => {
        this.setState({ filterVisible: flag });
    };

    componentWillUnmount() {
        console.log("fghjk", url, url.includes('/community/view/page'))
        if (!url.includes('/community/view/page'))
            this.setInit()


    }


    onClick = (value) => {


        switch (value) {
            case "People":
                this.props.scope.props.history.push('/community/view/page=1');
                break;
            case "Brand":
                this.props.scope.props.history.push("/business-list");
                break;
            case "Industry":
                this.props.scope.props.history.push('/industry');
                break;
            case "Product":
                this.props.scope.props.history.push("/job");
                break;
            case "All":
                this.props.scope.props.history.push(`/search/results/all/page=1&keywords=${this.props.appLocalState.searchKey}`);
                break;


        }

    }

    getDeviceType = () => {
        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            return "tablet";
        }
        if (
            /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
                ua
            )
        ) {
            return "mobile";
        }
        return "desktop";
    };

    checkActivePage = (value) => {
        console.log("this.props.scope.props", this.props.scope.props, value);

        return null;
    }

    handleCountryChange1 = (value) => {
        console.log("pklnjknjknjk", this.props)
        localStorage.setItem('productType', value)
        this.setState({ productType: value })
        let { appLocalState, dispatch, scope } = this.props
        this.props.scope.getList(appLocalState.filter)


    }

    getCategoryProduct = (categoryType, keyName) => {
        let params = {
            categoryType: categoryType,
        }
        _Api(Url.GET_PRODUCT_CATEGORY.method, Url.GET_PRODUCT_CATEGORY.url, '',)
            .then(resp => {
                console.log("fksnfkjnsdkj", resp.responseData?.result)
                // this.setState({manageCategoriesId: resp.responseData?.result?.manageCategoriesId })

                this.setState({
                    isLoader: false,
                    manageCategoriesId: resp.responseData?.result?.manageCategoriesId?.map((item) => {
                        return (
                            {
                                title: item?.id.name,
                                value: item?.id._id,

                            }
                        )

                    })

                })



            }).catch(err => {
                this.setState({ isLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
                this.setState({ isLoader: false })
            })
    }




    render() {
        url = window.location.pathname
        // console.log("categoryData", this.state.categoryData);
        let dataOption = this.state.dataOption;
        let { visible, dropdownValue, ishideSearch, countryList } = this.state;
        const { filter, search, multiFilter } = this.props.appLocalState;

        const { servicesShowcaseId, placeOfWorkId, productAndServiceId, budgetId, noOfPeopleWorkId, location, jobTitleId, sectorId } = filter;
        let isPeopleScreen = dropdownValue == "People";

        let isFilterApplied = _.values(filter).every(_.isEmpty);
        let isMobile = this.props.isMobile;
        console.log("dkjgv", dropdownValue)

        console.log("filterdddddddddddddddddddddd", Object.values(multiFilter).filter(item => item.length != 0).length);

        let countryListData = [];
        {
            countryList.length > 0 && countryList.map((item) => (
                countryListData.push({ value: item.country_code + "#" + item.country_name, label: item.country_name })
            ))
        }

        // console.log("sjhajdkla", this.state.categoryData)

        if (isMobile) {
            return (
                <Modal
                    footer={null}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <>

                        <header className="filter-header-wrapper respo-screen container-fluid pl-0 pr-0 ">
                            <div className="cm-section  d-flex align-items-center justify-content-left overflow-auto mobile-responsive-filter-header">
                                {dropdownValue && <div className="filter-header-dropdown">
                                    <Dropdown overlay={<DropDownMenu {...this} dropdownValue={[dropdownValue]} searchKey={search} selectedUrl={dropdownValue} />}>
                                        <a className="ant-dropdown-link d-flex align-items-center" onClick={e => e.preventDefault()}
                                        >
                                            <span>{dropdownValue}</span><RoundSuffixIcon />
                                        </a>
                                    </Dropdown>

                                </div>}
                                {<div className={"cursor-point mt-0 mb-0"}>
                                    {ishideSearch && <span bg="#B8D0D6" onClick={() => this.onClick("People")} style={{ color: "#fff", fontSize: '16px' }} className="reset-btn ml-2">People</span>}
                                    {ishideSearch && <span bg="#B8D0D6" onClick={() => this.onClick("Brand")} style={{ color: "#fff", fontSize: '16px' }} className="reset-btn ml-2">Business</span>}
                                    {ishideSearch && <span bg="#B8D0D6" onClick={() => this.onClick("Product")} style={{ color: "#fff", fontSize: '16px' }} className="reset-btn ml-2">Jobs</span>}
                                    {ishideSearch && <span bg="#B8D0D6" onClick={() => this.onClick("Industry")} style={{ color: "#fff", fontSize: '16px' }} className="reset-btn ml-2">Training</span>}

                                </div>}

                                {!this.state.ishideHeader &&
                                    <div className="middle-wrapper">
                                        {this.state.sectorData?.length != 0 && <TreeSelect
                                            // style={{ width: '50%' }}
                                            style={{ width: '200px' }}
                                            value={sectorId || null}

                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                            treeData={this.state.sectorData}
                                            placeholder={"All Sectors"}
                                            treeDefault
                                            // treeDefaultExpandAll
                                            onChange={(e, label) => this.onChangeSelect("sectorId", e, label)}
                                            className="cm_multiselect dropdown-filter"
                                            dropdownClassName="dropdown-filter-box"
                                            showArrow
                                        />}
                                        {/* <TreeSelect
                                        // style={{ width: '50%' }}
                                        style={{ width: '200px'}}
                                        value={servicesShowcaseId || null}
                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        treeData={this.state.productAndServiceOfferData}
                                        placeholder={"All Categories"}
                                        treeDefault
                                        // treeDefaultExpandAll
                                        onChange={(e) => this.onChangeSelect("servicesShowcaseId", e)}
                                        className="cm_multiselect dropdown-filter"
                                        dropdownClassName="dropdown-filter-box"
                                        showArrow
                                    /> */}

                                        {isPeopleScreen && <TreeSelect
                                            // style={{ width: '50%' }}
                                            style={{ width: '200px' }}
                                            value={jobTitleId || null}
                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                            treeData={this.state.jobTitleData}
                                            placeholder={"All Job Title"}
                                            treeDefault
                                            // treeDefaultExpandAll
                                            onChange={(e) => this.onChangeSelect("jobTitleId", e)}
                                            className="cm_multiselect dropdown-filter"
                                            dropdownClassName="dropdown-filter-box"
                                            showArrow
                                        />
                                        }

                                        {isPeopleScreen && <TreeSelect
                                            // style={{ width: '50%' }}
                                            style={{ width: '200px' }}
                                            value={location || null}
                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                            treeData={this.state.countryData}
                                            placeholder={"All Country"}
                                            treeDefault
                                            // treeDefaultExpandAll
                                            onChange={(e) => this.onChangeSelect("location", e)}
                                            className="cm_multiselect dropdown-filter"
                                            dropdownClassName="dropdown-filter-box"
                                            showArrow
                                        />}

                                        <TreeSelect
                                            dropdownClassName="new_multi_check"
                                            treeData={this.state.productAndServiceOfferData}
                                            value={productAndServiceId || null}
                                            onChange={(e) => this.onChangeSelect("productAndServiceId", e)}

                                            // treeCheckStrictly: false,
                                            showCheckedStrategy={SHOW_CHILD}
                                            placeholder='Product & Services'
                                            style={{
                                                width: '50%',
                                                border: 'none'
                                            }}
                                            showSearch={false}
                                            className="cm_multiselect dropdown-filter"
                                            dropdownClassName="dropdown-filter-box"
                                            showArrow
                                        />
                                    </div>
                                }  {!this.state.ishideHeader && <div className="filter-header-dropdown ml-3 ">
                                    <Dropdown data-backdrop="static" data-keyboard="false" visible={this.state.filterVisible} overlayClassName="filterpopup" overlay={<Filter scope={this} clearFilter={this.clearFilter} className="radha" />} trigger={['click']} onVisibleChange={this.handleVisibleChange}>
                                        <p className="mt-0 mb-0 d-flex align-items-center cursor-point">
                                            <span className="">All Filter</span><RoundSuffixIcon />
                                        </p>
                                    </Dropdown>

                                </div>
                                }
                                {!this.state.ishideHeader && !isFilterApplied && <p className="m-0 d-flex align-items-center cursor-point ml-3">
                                    <span bg="#B8D0D6" onClick={this.clearFilter} style={{ color: "#fff", fontSize: '16px' }} className="reset-btn">Reset
            {/* <i class="fa fa-times" aria-hidden="true"></i>   */}
                                    </span>
                                </p>
                                }

                            </div>
                        </header>



                    </>
                </Modal>
            )

        }
        else {

            return (
                <>

                    <header className="filter-header-wrapper container-fluid">
                        <div className="cm-section  d-flex align-items-center justify-content-left overflow-auto">
                            {dropdownValue && <div className="filter-header-dropdown">
                                <Dropdown overlay={<DropDownMenu {...this} dropdownValue={[dropdownValue]} searchKey={search} selectedUrl={dropdownValue} />}>
                                    <a className="ant-dropdown-link d-flex align-items-center" onClick={e => e.preventDefault()}>
                                        <span>{dropdownValue}</span><RoundSuffixIcon />
                                    </a>
                                </Dropdown>

                            </div>}
                            {<div className={"cursor-point mt-4 mb-4"}>

                                {ishideSearch && <span bg="#B8D0D6" onClick={() => this.onClick("People")} style={{ color: "#fff", fontSize: '16px' }} className="reset-btn ml-2">People</span>}
                                {ishideSearch && <span bg="#B8D0D6" onClick={() => this.onClick("Brand")} style={{ color: "#fff", fontSize: '16px' }} className="reset-btn ml-2">Businesses</span>}
                                {ishideSearch && <span bg="#B8D0D6" onClick={() => this.onClick("Product")} style={{ color: "#fff", fontSize: '16px' }} className="reset-btn ml-2">Jobs</span>}
                                {ishideSearch && <span bg="#B8D0D6" onClick={() => this.onClick("Industry")} style={{ color: "#fff", fontSize: '16px' }} className="reset-btn ml-2">Training</span>}

                            </div>}

                            {this.props.isBrand != true && (!this.state.ishideHeader || this.props?.isOfferProduct) && <div className="middle-wrapper">
                                {/* {this.state.sectorData?.length != 0 && <TreeSelect
                                    style={{ width: '50%' }}
                                    value={sectorId || null}
                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    treeData={this.state.sectorData}
                                    placeholder={"All Sectors"}
                                    treeDefault
                                    // treeDefaultExpandAll
                                    onChange={(e, label) => this.onChangeSelect("sectorId", e, label)}
                                    className="cm_multiselect dropdown-filter"
                                    dropdownClassName="dropdown-filter-box"
                                    showArrow
                                />} */}
                                {isPeopleScreen && <TreeSelect
                                    // style={{ width: '50%' }}
                                    style={{ width: '200px' }}
                                    value={placeOfWorkId || null}
                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    treeData={this.state.placeOfWorkData}
                                    placeholder={"Place Of Work"}
                                    treeDefault
                                    // treeDefaultExpandAll
                                    onChange={(e) => this.onChangeSelect("placeOfWorkId", e)}
                                    className="cm_multiselect dropdown-filter"
                                    dropdownClassName="dropdown-filter-box"
                                    showArrow
                                />}


                                {this.props?.isOfferProduct &&
                                    <TreeSelect
                                        // style={{ width: '50%' }}
                                        style={{ width: '200px' }}
                                        value={servicesShowcaseId || null}
                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        treeData={this.state.categoryData}
                                        placeholder={"All Categories"}
                                        treeDefault
                                        treeDefaultExpandAll
                                        onChange={(e) => this.onChangeSelectProduct("servicesShowcaseId", e)}
                                        className="cm_multiselect dropdown-filter"
                                        dropdownClassName="dropdown-filter-box"
                                        showArrow
                                    />

                                    //     <Select
                                    //     name='selectType'
                                    //     // value={productAndService}
                                    //     placeholder='All Categories'
                                    //     onChange={(e) => this.onChangeSelect("servicesShowcaseId", e)}
                                    //     // components={{ Option: CustomOption }}
                                    //     className="dropdown-filter"
                                    //     option ={dataOption} 
                                    // >
                                    // </Select>
                                }

                                {isPeopleScreen && <TreeSelect
                                    // style={{ width: '50%' }}
                                    style={{ width: '200px' }}
                                    value={jobTitleId || null}
                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    treeData={this.state.jobTitleData}
                                    placeholder={"All Job Role"}
                                    treeDefault
                                    // treeDefaultExpandAll
                                    onChange={(e) => this.onChangeSelect("jobTitleId", e)}
                                    className="cm_multiselect dropdown-filter"
                                    dropdownClassName="dropdown-filter-box"
                                    showArrow
                                />
                                }
                                {isPeopleScreen && <TreeSelect
                                    // style={{ width: '50%' }}
                                    style={{ width: '200px' }}
                                    value={location || null}
                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    treeData={this.state.countryData}
                                    placeholder={"All Country"}
                                    treeDefault
                                    // treeDefaultExpandAll
                                    onChange={(e) => this.onChangeSelect("location", e)}
                                    className="cm_multiselect dropdown-filter"
                                    dropdownClassName="dropdown-filter-box"
                                    showArrow
                                />}

                            </div>
                            }


                            {this.props.productCustomfilter &&
                                this.props.newProduct ? <div className="middle-wrapper">
                                    <div className="cm-form-wrapper" style={{ maxWidth: "100%" }}>

                                        <Select
                                            defaultValue={"New product"}
                                            style={{ width: '100%' }}
                                            placeholder="Please select"
                                            value={this.state.productType}
                                            onChange={(e) => this.handleCountryChange1(e)}
                                            // dropdownClassName="search-multidropdown "
                                            // className="cm_multiselect cmslect_multicheck brandfiltersearch"
                                            className="cm_multiselect cmslect_multicheck cm_simpleselect whiteoption"
                                            dropdownClassName="dropdown-filter-box"
                                        >
                                            <Option value="2" >Jobs</Option>
                                            <Option value="1" >New Jobs</Option>
                                        </Select>


                                    </div>
                                </div>
                                :
                                this.props.productCustomfilter && <div className="middle-wrapper d-flex align-items-center">
                                    {/* <div className="cm-form-wrapper" style={{ maxWidth: "100%" }}> */}

                                        <Select
                                            defaultValue={this.state.productType}
                                            style={{ width: '100%' }}
                                            placeholder="Please select"
                                            value={this.state.productType}
                                            onChange={(e) => this.handleCountryChange1(e)}
                                            // dropdownClassName="search-multidropdown "
                                            // className="cm_multiselect cmslect_multicheck brandfiltersearch"
                                            className="cm_multiselect cmslect_multicheck cm_simpleselect whiteoption"
                                            dropdownClassName="dropdown-filter-box"
                                        >
                                            <Option value="2" >All Jobs</Option>
                                            <Option value="1" >New Jobs</Option>
                                        </Select>

                                        <TreeSelect
                                        // style={{ width: '50%' }}
                                        style={{ width: '200px' }}
                                        value={servicesShowcaseId || null}
                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        treeData={this.state.categoryData}
                                        placeholder={"All Categories"}
                                        treeDefault
                                        treeDefaultExpandAll
                                        onChange={(e) => this.onChangeSelectProduct("servicesShowcaseId", e)}
                                        className="cm_multiselect dropdown-filter"
                                        dropdownClassName="dropdown-filter-box"
                                        showArrow
                                    />
                                    <TreeSelect
                                        // style={{ width: '50%' }}
                                        style={{ width: '200px' }}
                                        value={sectorId || null}
                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        treeData={this.state.sectorData}
                                        placeholder={"All Sectors"}
                                        treeDefault
                                        // treeDefaultExpandAll
                                        onChange={(e, label) => this.onChangeSelectProduct("sectorId", e, label)}
                                        className="cm_multiselect dropdown-filter"
                                        dropdownClassName="dropdown-filter-box"
                                        showArrow
                                    />
                                    

                                    {/* </div> */}
                                </div>


                            }






                            {
                                this.props.isProductTab && <>
                                   
                                </>
                            }


                            {
                                this.props.isBrand && <>
                                   <div className="middle-wrapper">
                                   <TreeSelect
                                        // style={{ width: '50%' }}
                                        style={{ width: '200px' }}
                                        value={servicesShowcaseId || null}
                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        treeData={this.state.categoryData}
                                        placeholder={"All Categories"}
                                        treeDefault
                                        treeDefaultExpandAll
                                        onChange={(e) => this.onChangeSelectProduct("servicesShowcaseId", e)}
                                        className="cm_multiselect dropdown-filter"
                                        dropdownClassName="dropdown-filter-box"
                                        showArrow
                                    />
                                    <TreeSelect
                                        // style={{ width: '50%' }}
                                        style={{ width: '200px' }}
                                        value={sectorId || null}
                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        treeData={this.state.sectorData}
                                        placeholder={"All Sectors"}
                                        treeDefault
                                        // treeDefaultExpandAll
                                        onChange={(e, label) => this.onChangeSelectProduct("sectorId", e, label)}
                                        className="cm_multiselect dropdown-filter"
                                        dropdownClassName="dropdown-filter-box"
                                        showArrow
                                    />
                                   </div>
                                </>
                            }




















                            {/* {(this.props.productReset || servicesShowcaseId )&& <p className="m-0 d-flex align-items-center cursor-point ml-3">
                                <span bg="#B8D0D6" onClick={this.clearFilter} style={{ color: "#fff", fontSize: '16px' }} className="reset-btn">Reset
                            <i class="fa fa-times" aria-hidden="true"></i>  
                                </span>
                            </p>
                            } */}
                            {!this.state.ishideHeader && this.props?.setSelected == "People" && <div className="filter-header-dropdown ml-3 ">
                                <Dropdown data-backdrop="static" data-keyboard="false" visible={this.state.filterVisible} overlayClassName="filterpopup cstm-fltr-wrp-mdl" overlay={<Filter scope={this} clearFilter={this.clearFilter} className="radha" />} trigger={['click']} onVisibleChange={this.handleVisibleChange}>
                                    <p className="mt-0 mb-0 d-flex align-items-center cursor-point">
                                        <span className="">All Filter</span><RoundSuffixIcon />
                                    </p>
                                </Dropdown>

                            </div>
                            }
                            {(!this.state.ishideHeader && !isFilterApplied || servicesShowcaseId || (Object.values(multiFilter).filter(item => item.length != 0).length != 0)) && <p className="m-0 d-flex align-items-center cursor-point ml-3">
                                <span bg="#B8D0D6" onClick={this.clearFilter} style={{ color: "#fff", fontSize: '16px' }} className="reset-btn">Reset
                            {/* <i class="fa fa-times" aria-hidden="true"></i>   */}
                                </span>
                            </p>
                            }

                        </div>


                    </header>



                </>
            )
        }
    }
}

// const children = [];
// for (let i = 10; i < 36; i++) {
//     children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
// }


function mapStateToProps(state) {
    console.log("dlkjhsdjklas", state.appLocalState);

    return {
        ...state,
        appLocalState: state.appLocalState
    };
}

export default connect(mapStateToProps)(FilterHeader);

const SuffixIcon = () => {
    return (
        <>
            <i className="fa fa-sort-desc"></i>

        </>
    )
}

const RoundSuffixIcon = () => {
    return (
        <>
            <div className="rounded-circle">
                <i className="fa fa-sort-desc"></i>
            </div>


        </>
    )
}

const DropDownMenu = (props) => (
    <Menu selectedKeys={[props.dropdownValue]} onSelect={() => props.clearFilter()} >
        {/*{console.log("DropDownMenu=====",props)}*/}
        {props.searchKey && < Menu.Item key="All">
            <Link to={`/search/results/all/page=1&keywords=${props.searchKey}`}>All</Link>
        </Menu.Item >}

        <Menu.Item key="People" className={props.selectedUrl === "People" ? "active-url" : ""}>
            <Link to="/community/view/page=1">People</Link>
        </Menu.Item >

        <Menu.Item key="Product" className={props.selectedUrl === "Product" ? "active-url" : ""}>
            <Link to="/job">Jobs</Link>
        </Menu.Item>
        <Menu.Item key="offers" className={props.selectedUrl === "offers" ? "active-url" : ""}>
            <Link to="/trainings">Training</Link>
        </Menu.Item>
        <Menu.Item key="Brand" className={props.selectedUrl === "Brands" ? "active-url" : ""}>
            <Link to="/business-list">Businesses</Link>
        </Menu.Item>
        {/* <Menu.Item key="Industry" className={props.selectedUrl === "Industry" ? "active-url" : ""}>
            <Link to="/industry">Sectors</Link>
        </Menu.Item> */}
    </Menu >
);

export class Filter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {


        }
    }
    onClickOnExpand = (id) => {
        this.setState({ [`isExpand${id}`]: !this.state[`isExpand${id}`] })

    }
    onApplyFilter = () => {
        let { scope } = this.props;
        scope.setState({ filterVisible: false }, () => scope.getData());

    }
    onClearFilter = () => {
        let filter = {
            placeOfWorkId: [],
            productAndServiceId: [],
            noOfPeopleWorkId: [],
            budgetId: [],
            jobTitleId: [],
            location: [],
            attendingOrLookingforId: [],
            sectorId: []
        }
        Object.keys(this.state).map(
            i => { this.setState({ [i]: false }) }
        )

        this.setState({ filter: filter, filterVisible: false }, () => this.props.scope.clearFilter());

        // this.props.scope({ filter: scopeFilter, filterVisible: false }, () => () => {  ; this.props.scope.getData() });

    }
    onCancelclick = () => {
        let scope = this.props.scope;
        scope.setState({ filterVisible: false });

    }

    selectOnChange = (type, e) => {
        this.setState({
            [type]: e
        })
    }

    handleCountryChange(val) {
        let value = val.value;
        let data = value.split("#");
        let { scope } = this.props;
        let { multiFilter } = scope.props.appLocalState;
        let array = multiFilter["location"];
        console.log("array", array);
        array = [...new Set([...array, data[1]])];
        multiFilter["location"] = array;
        scope.props.dispatch(setLocalState(scope.props.appLocalState));
        scope.getCity(array.toString());
    }



    handleCityChange(val) {
        let value = val.value;
        let data = value.split("#");
        let { scope } = this.props;
        let { multiFilter } = scope.props.appLocalState;
        let array = multiFilter["city"];
        array = [...new Set([...array, data[1]])];
        multiFilter["city"] = array;
        scope.props.dispatch(setLocalState(scope.props.appLocalState));
    }

    handleOnChange = (e, item) => {
        let questionId = item._id       
        let { scope } = this.props;
        let id = e.target.value
        let { multiFilter } = scope.props.appLocalState;
        let array = multiFilter['plainTextAnswer'];
        console.log("arrayarray", array);
        // if(!array){
        //     array=[];
        // }
      
       
        array[questionId] = e.target.value;

        multiFilter["plainTextAnswer"] = array;


        scope.props.dispatch(setLocalState(scope.props.appLocalState));
        this.setState({ [`plainTextAnswer${item._id}`]: e.target.value })


    }

    render() {
        // console.log("FILTER###########",this.props);
        // console.log("FILTER###########",this.state);
        let { scope } = this.props;
        let { placeOfWorkData, noOfPeopleWorkData, budgetData, jobTitleData, productAndServiceOfferData, countryList, sectorData, cityList, questionList } = scope.state;
        let { multiFilter } = scope.props.appLocalState;
        let { placeOfWorkId, jobTitleId, noOfPeopleWorkId, productAndServiceId, budgetId, location, attendingOrLookingforId, sectorId, city, userAns } = multiFilter;
        const placeOfWorkchildElements = this.getFilterComboData(placeOfWorkData, "placeOfWorkId", placeOfWorkId);
        const sectorElements = this.getFilterComboData(sectorData, "sectorId", sectorId);
        //const productAndServiceOfferchildElements = this.getFilterComboData(productAndServiceOfferData, "productAndServiceId", productAndServiceId);
        //const noOfPeopleWorkDatachildElements = this.getFilterComboData(noOfPeopleWorkData, "noOfPeopleWorkId", noOfPeopleWorkId);
        //const budgetComboData = this.getFilterComboData(budgetData, "budgetId", budgetId);
        const jobTitleComboData = this.getFilterComboData(jobTitleData, "jobTitleId", jobTitleId);
        //const productAndServiceLookingchildElements = this.getFilterComboData(productAndServiceOfferData, "attendingOrLookingforId", attendingOrLookingforId);
        let countryData = [
            { checkable: true, value: "Australia", displayValue: "Australia", country_code: "AU" },
            { checkable: true, value: "India", displayValue: "India", country_code: "IN" },
            { checkable: true, value: "United Kingdom", displayValue: "United Kingdom", country_code: "GB" },
            { checkable: true, value: "United States", displayValue: "United States", country_code: "US" }
        ]
        location.forEach(element => {
            let isContain = countryData.some(countryData => countryData.value === element)
            if (!isContain) {
                countryData.push({ checkable: true, value: element, displayValue: element });
            }
        });


        const countryComboData = this.getFilterComboData(countryData, "location", location);
        let event = {
            target: {
                checked: true
            }
        }

        let countryListData = [];
        {
            countryList.length > 0 && countryList.map((item) => (
                countryListData.push({ value: item.country_code + "#" + item.country_name, label: item.country_name })
            ))
        }

        let cityListData = []
        {
            cityList.length > 0 && cityList.map((item) => (
                cityListData.push({ value: item._id + "#" + item.city_name, label: item.city_name })
            ))
        }

        let cityData = [

        ]
        // console.log("city",city);
        city.forEach(element => {
            let isContain = cityData.some(countryData => countryData.value === element)
            if (!isContain) {
                cityData.push({ checkable: true, value: element, displayValue: element });
            }
        });

        const cityComboData = this.getFilterComboData(cityData, "city", city);

        let array = multiFilter["location"];

        // console.log("lkjdshagfghsjk", array)


        return (

            <div className="web-filter cm-section w-100" >

                <div className="header-container pt-0">
                    <div className="header-text d-flex flex-grow-1">
                        <h1 className="title">All Job Filter</h1>
                    </div>

                    <div className="fltrsBtns">
                        <CmBtn text="Clear" bg="#B8D0D6" className="btn-filter" onClick={scope.clearFilter} />
                        <CmBtn text="Cancel" bg="#D23A27" className="btn-filter" onClick={this.onCancelclick} />
                        <CmBtn text="Apply" bg="#316689" className="btn-filter" onClick={this.onApplyFilter} />
                    </div>
                </div>

                <hr className="mb-0" />

                <div className="row no-gutters border-bottom">

                    <div className="col-lg-4 border-right">
                        <div className="checkbox-item-wrap ">
                            <div className="d-flex align-items-center">
                                <p className="header-icon"><Hert className="red" /></p>
                                <h5>Sector</h5>
                            </div>
                            {sectorElements}
                        </div>
                        <div className="checkbox-item-wrap">
                            <div className="d-flex align-items-center">
                                <p className="header-icon"><Hert className="red" /></p>
                                <h5>Place of work</h5>
                            </div>
                            {placeOfWorkchildElements}
                        </div>

                    </div>

                    <div className="col-lg-4 border-right">

                        <div className="checkbox-item-wrap">

                            <div className="d-flex align-items-center">
                                <p className="header-icon"><Hert className="red" /></p>
                                <h5>Job Role</h5>
                            </div>
                            {jobTitleComboData}
                        </div>


                    </div>

                    <div className="col-lg-4">
                        <div className="checkbox-item-wrap">
                            <div className="d-flex align-items-center">
                                <p className="header-icon"><Hert className="red" /></p>
                                <h5>Add a country</h5>
                            </div>
                            <div className="cm-form-wrapper" style={{ maxWidth: "100%" }}>
                                <div className="form-group">
                                    <ReactSelect
                                        showSearch
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        placeholder="Please select"
                                        onChange={(e) => this.handleCountryChange(e)}
                                        dropdownClassName="search-multidropdown "
                                        className="cm_multiselect cmslect_multicheck brandfiltersearch"
                                        options={countryListData}
                                    >
                                    </ReactSelect>
                                </div>

                            </div>
                            {countryComboData}
                        </div>
                        {array?.length != 0 && <div className="checkbox-item-wrap">
                            <div className="d-flex align-items-center">
                                <p className="header-icon"><Hert className="red" /></p>
                                <h5>Nearest largest city</h5>
                            </div>
                            <div className="cm-form-wrapper" style={{ maxWidth: "100%" }}>
                                <div className="form-group">
                                    <ReactSelect
                                        showSearch
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        placeholder="Please select"
                                        onChange={(e) => this.handleCityChange(e)}
                                        dropdownClassName="search-multidropdown "
                                        className="cm_multiselect cmslect_multicheck brandfiltersearch"
                                        options={cityListData}
                                    >
                                    </ReactSelect>
                                </div>

                            </div>
                            {cityComboData}
                        </div>}
                    </div>
                </div>
                <div className="row no-gutters border-bottom">
                    {questionList.length > 0 && questionList.map((item, i) => (
                        (item.questionType === 2 || item.questionType === 3) && item.isHide== 1?
                            <div className="col-lg-4 border-right">
                                <div className="checkbox-item-wrap ">
                                    <div className="d-flex align-items-center">
                                        <p className="header-icon"><Hert className="red" /></p>
                                        <h5>{item.filterQuestion}</h5>
                                    </div>
                                    {
                                        item?.questionType == 1 ? <div className="cm-form-wrapper" style={{ maxWidth: "100%" }}>
                                            <div className="form-group">
                                                <input type="text"
                                                    className="form-control"
                                                    placeholder="Enter here..."
                                                    maxLength={250}
                                                    name={`plainTextAnswer${item?._id}`}
                                                    // value={adName}
                                                    onChange={(e) => this.handleOnChange(e, item)}
                                                />

                                            </div>

                                        </div>
                                            : ''
                                    }
                                    {
                                        item.isRelation === 1 ?
                                            this.getFilterComboDataQuestion(item.option, item._id, userAns[item._id], 1, item._id) :
                                            this.getFilterComboDataQuestion(item.option, item._id, userAns[item._id], 0, item._id)
                                    }
                                </div>

                            </div>
                            : ""
                    ))}
                </div>
            </div >


        )
    }

    handleSelect = (e, id, stateKey) => {
        let { scope } = this.props;
        let { multiFilter } = scope.props.appLocalState;
        let array = multiFilter[stateKey];
        //console.log("array",array,e, id, stateKey)
        let checkResult = _.find(array, function (obj) {
            return obj === id;
        })
        if (!checkResult) {
            array.push(id);
        } else {
            const index = array.indexOf(id);
            if (index > -1) {
                array.splice(index, 1);
            }
        }
        //console.log("array",array);
        scope.props.dispatch(setLocalState(scope.props.appLocalState));

        if (stateKey === "location") {
            scope.getCity(array.toString())
        }

        this.setState({ [`${stateKey}Checkbox${id}`]: !this.state[`${stateKey}Checkbox${id}`] })
    }

    getFilterComboData(DropdownData, stateKey, value) {
        //console.log("value",value,DropdownData);
        return DropdownData && DropdownData.length > 0 ?
            DropdownData.map((DropdownData, i) => (
                <div key={stateKey + "#" + i}>

                    {!DropdownData.checkable && <h5 onClick={() => { this.onClickOnExpand(`${stateKey}${DropdownData.value}`); }}><i className={this.state[`isExpand${stateKey}${DropdownData.value}`] ? "fa fa-caret-down" : "fa fa-caret-right"} aria-hidden="true">&nbsp;</i> {DropdownData.title}</h5> ||
                        <div className="form-check form-check-inline cursor-point" onClick={(e) => this.handleSelect(e, DropdownData.value, stateKey)}>
                            <input className="form-check-input cursor-point"
                                type="checkbox"
                                //onChange={(e) => this.handleSelect(e, DropdownData.value, stateKey)}
                                checked={value.includes(DropdownData.value)}
                            />
                            <label className="form-check-label cursor-point"
                                htmlFor="inlineCheckbox2">{DropdownData?.title || DropdownData?.displayValue}</label>
                        </div>}

                    {this.state[`isExpand${stateKey}${DropdownData.value}`] &&
                        DropdownData.children.map((item) => <div className="form-check form-check-inline ml-5 cursor-point" onClick={(e) => this.handleSelect(e, item?._id || item?.value, stateKey)}>
                            <input className="form-check-input cursor-point"
                                type="checkbox"
                                //onChange={(e) => this.handleSelect(e, item?._id || item?.value, stateKey)}
                                checked={value.includes(item?._id || item?.value)}
                            />
                            <label className="form-check-label cursor-point"
                                htmlFor="inlineCheckbox2">{item?.title}</label>
                        </div>)
                    }

                </div>

            )
            ) : "";
    }

    getOption = (DropdownData) => {
        let option = [];
        _.map(DropdownData, function (optionValue) {
            option = _.union(option, optionValue.value);
        })
        return option;
    }

    handleSelectQues = (e, id, questionId) => {
        let { scope } = this.props;
        let { multiFilter } = scope.props.appLocalState;
        let array = multiFilter['userAns'];
        //console.log("array",array,e, id, questionId,scope.props.appLocalState);
        // if(!array){
        //     array=[];
        // }
        let userQues = array[questionId] ? array[questionId] : [];
        let checkResult = _.find(userQues, function (obj) {
            return obj === id;
        })
        if (!checkResult) {
            userQues.push(id);
        } else {
            const index = userQues.indexOf(id);
            if (index > -1) {
                userQues.splice(index, 1);
            }
        }
        array[questionId] = userQues;

        scope.props.dispatch(setLocalState(scope.props.appLocalState));

        this.setState({ [`${questionId}#Checkbox#${id}`]: !this.state[`${questionId}#Checkbox#${id}`] })
    }

    getFilterComboDataQuestion(DropdownData, stateKey, value, isOptionRelation, questionId) {
        value = value ? value : []
        if (isOptionRelation === 0) {
            return DropdownData && DropdownData.length > 0 ?
                DropdownData.map((DropdownData, i) => (
                    <div key={questionId + "#" + i}>
                        <div className="form-check form-check-inline cursor-point"
                            onClick={(e) => this.handleSelectQues(e, DropdownData.name, questionId)}
                        >
                            <input className="form-check-input cursor-point"
                                type="checkbox"
                                //onChange={(e) => this.handleSelect(e, DropdownData.value, stateKey)}
                                checked={value.includes(DropdownData.name)}
                            />
                            <label className="form-check-label cursor-point"
                                htmlFor="inlineCheckbox2">{DropdownData?.name}</label>
                        </div>
                    </div>

                )
                ) : ""
        } else {
            return this.getOption(DropdownData).map((optionValue, i) => (
                <div key={questionId + "#" + i}>
                    <div className="form-check form-check-inline cursor-point"
                        onClick={(e) => this.handleSelectQues(e, optionValue, questionId)}
                    >
                        <input className="form-check-input cursor-point"
                            type="checkbox"
                            //onChange={(e) => this.handleSelect(e, DropdownData.value, stateKey)}
                            checked={value.includes(optionValue)}
                        />
                        <label className="form-check-label cursor-point"
                            htmlFor="inlineCheckbox2">{optionValue}</label>
                    </div>
                </div>
            ));
        }
    }
}