import React, { Component } from 'react';
import { Hert } from '../../SvgIcon';
import { Link } from "react-router-dom"
import { Modal } from 'antd';
import { notify } from '../../components/Toaster';
import Loader from '../../components/Loader';
import Select from 'react-select';
import { _Api } from '../../services/Api';
import Url from '../../services/BaseUrl'
//validation
import { validateEmail } from '../../components/Validation';

import { connect } from "react-redux";

import { getExhibitionId, SIGNUP_PROCESS, TOGGLE_QUESTION } from "../../redux/action";
import InlineError from "../../components/InlineError";
import { MySpinner } from "../../components";

var _ = require('lodash');

class Question extends Component {
    constructor(props) {
        super(props);
        this.state = {
            questionStep: 1,
            questionList: [],
            userAns: [],
            questionCount: [],
            staticQuestion: 4,
            sectorList: [],
            placeOfWorkList: [],
            roleList: [],
            sectorError: "",
            placeofWorkError: "",
            roleError: "",
            registrationIdError: "",
            countryError: "",
            cityError: "",
            countryList: [],
            cityList: [],
            registrationId: "",
            selectedSector: [],
            selectedSectorCount: 0,
            selectedPlaceOfWork: [],
            placeofworkCount: 0,
            selectedRole: [],
            roleCount: 0,
            selectedCountryCode: "",
            // selectedCountry:"",
            // selectedCity:"",
            selectedCityId: "",
            otherCity: "",
            showOtherCity: false,
            countrySelectedOption: null,
            citySelectedOption: null,
            cityPlaceholder: '',
            isShowRegisterId: true,

            tempQuestionAns: [],
            isSubmit: false,

            isNext: false,
            lastQuestion: []

        }

    }
    componentDidMount() {
        let self = this;
        const { userData, userQuestionAns } = this.props
        const { sectorId, placeOfWork, roleId } = userData
        if (localStorage.getItem('accessToken') && localStorage.getItem('accessToken') !== '') {

        }
        this.getSector();
        // console.log("hgfhjkl", this.props.stepEdit)

        let exhibitionId = self.props.loading.exhibitionId;
        if(this.props.stepEdit != 1) {
            if (exhibitionId && exhibitionId !== 1) {
                self.getQuestion(exhibitionId, userQuestionAns[4]?.plainTextAnswer ? true : false);
            } else {
                self.props.dispatch(getExhibitionId())
                    .then(function () {
                        self.getQuestion(self.props.loading.exhibitionId, userQuestionAns[4]?.plainTextAnswer ? true : false);
                    })
            }
        }
        else{

            this.setState({
                questionCount:[1,2,3]
            })
        }



        sectorId.forEach(item => this.state.selectedSector.push(item._id))
        placeOfWork.forEach(item => this.state.selectedPlaceOfWork.push(item._id))
        roleId.forEach(item => this.state.selectedRole.push(item._id))


        this.setState({ registrationId: userData.registrationId ? userData?.registrationId : '' })


        // console.log("skjHGVHB", userData)


        if (this.props.stepEdit == 1)
            this.setState({ questionStep: 1 })
        if (this.props.stepEdit == 2)
            this.setState({
                questionStep: 4,
                countrySelectedOption: userData?.country,
                cityPlaceholder: userData?.cityId ? userData?.city : userData.city && userData.city !== "" ? 'Other' : 'Select City',
                showOtherCity: userData?.cityId ? false : userData.city && userData.city !== "" ? true : false,
                otherCity: userData?.city,
            })

        if (this.props.stepEdit == 3)
            this.setState({ questionStep: 5 })
        this.getCountry()
        this.getCity(userData?.country_Code)


        //console.log("thispropsssssss ", userQuestionAns);


        userQuestionAns.forEach((item, indx) => {
            const id = item.questionId
            const opt = item.optionAnswerArray
            item.questionType == 1 ? this.textAnsSave1(item?.plainTextAnswer, id) :
                opt.forEach((itemm, index) => {
                    item.questionType == 3 ?
                        this.onRadioSelectBox(itemm, id) : setTimeout(function () { self.onCheckedBox(itemm, id) }, 300)
                })
        })
    }






    checkStepClass = (step) => {
        // console.log("okgfsdaghjkl", {step, abc:this.state.questionStep})
        if (this.props.stepEdit == 3)
            step += 4
        if (step < this.state.questionStep) {
            return 'signup-step done';
        } else if (step === this.state.questionStep) {
            return 'signup-step active';
        } else {
            return 'signup-step';
        }
    }

    checkSelected = (field, value) => {
        let selectedField = this.state['userAns-' + field]
        let checkResult = _.find(selectedField, function (obj) {
            return obj == value;
        })
        //console.log("selectedField",selectedField,checkResult,value)
        if (checkResult) {
            return true;
        } else {
            return false;
        }
    }

    checkSelectedStatic = (field, value) => {
        let selectedField = this.state[field]
        let checkResult = _.find(selectedField, function (obj) {
            return obj === value;
        })
        //console.log("selectedField",selectedField,checkResult,value)
        if (checkResult) {
            return true;
        } else {
            return false;
        }
    }

    checkRadioSelected = (field, value) => {
        let selectedField = this.state['userAns-' + field]
        if (selectedField === value) {
            return true;
        } else {
            return false;
        }
    }

    getSector = () => {
        _Api(Url.GET_SECTOR_SIGNUP_LIST.method, Url.GET_SECTOR_SIGNUP_LIST.url)
            .then(resp => {
                if (resp.statusCode == 1) {
                    this.setState({
                        sectorList: resp.responseData.result,
                    })
                }
            }).catch(err => {
            if (err.error)
                notify("err", err.error.responseMessage)
        })
    }

    getplaceOfWork = (e) => {
        let self = this;
        e.preventDefault();
        let sectorId = this.state.selectedSector;
        self.setState({ "isLoading": true })
        _Api(Url.GET_PLACE_OF_WORK_LIST.method, Url.GET_PLACE_OF_WORK_LIST.url, { sectorId: sectorId.toString() })
            .then(resp => {
                if (resp.statusCode == 1) {
                    this.setState({
                        placeOfWorkList: resp.responseData.result.data,
                    })
                    self.setState({ "isLoading": false })
                    self.signupNext(2);
                }
            }).catch(err => {
            if (err.error)
                notify("err", err.error.responseMessage)
        })
    }

    signupNext = (step) => {
        this.setState({
            questionStep: step
        })
    }

    getRole = (e) => {
        let self = this;
        e.preventDefault();
        let placeOfWorkId = this.state.selectedPlaceOfWork;
        self.setState({ "isLoading": true })
        _Api(Url.GET_ROLE_LIST.method, Url.GET_ROLE_LIST.url, { placeOfWorkId: placeOfWorkId.toString() })
            .then(resp => {
                if (resp.statusCode == 1) {
                    this.setState({
                        roleList: resp.responseData.result.data,
                    })
                    self.setState({ "isLoading": false })
                    self.signupNext(3);
                }
            }).catch(err => {
            if (err.error)
                notify("err", err.error.responseMessage)
        })
    }

    signupNextStep = (e) => {
        let self = this;
        e.preventDefault();
        this.setState({
            sectorError: "",
            placeofWorkError: "",
            roleError: "",
            registrationIdError: "",
            countryError: "",
            cityError: "",
        })
        if (this.state.questionStep == 1) {
            //console.log("this.props.selectedSector.length",this.props.selectedSector.length,this.props.selectedSector);
            if (this.state.selectedSector.length > 0) {
                self.getplaceOfWork(e);
            } else {
                this.setState({
                    sectorError: "*Please select any sector"
                })
            }
        } else if (this.state.questionStep == 2) {
            if (this.state.selectedPlaceOfWork.length > 0) {
                self.getRole(e);
            } else {
                this.setState({
                    placeofWorkError: "*Please select any place of work"
                })
            }
        } else if (this.state.questionStep == 3) {
            this.setState({ isShowRegisterId: false })
            if (this.state.registrationIdRequired == true) {
                // if(this.state.registrationId.trim()===""){
                //     this.setState({ registrationIdError: '*Please enter.' })
                // }else{
                this.getCountry();
                // this.signupNext(4);
                self.saveProfile()
                //}
            } else {
                let checkRegistrationId = 0;
                if (this.state.selectedRole.length > 0) {
                    let roleArr = ["5fc9d333611cf52928910101",
                        "5fc73e5a237eae12638c982e",
                        "5fc72577237eae12638c9823",
                        "5fc72850237eae12638c9825",
                        "5fc724ba237eae12638c9822",
                        "5fc78d3c237eae12638c9834"];
                    _.map(this.state.selectedRole, function (role) {
                        let checkResult = _.find(roleArr, function (obj) {
                            return obj === role;
                        })

                        if (checkResult) {
                            checkRegistrationId++;
                        }
                        else {
                            if (self.props.stepEdit == 1 && self.state.questionStep == 3 && checkRegistrationId > 0) {
                                // self.saveProfile()
                            }
                        }
                        // console.log("lkfdsghhfjkl", checkRegistrationId)
                    })

                    if (checkRegistrationId > 0 && this.state.questionStep == 3) {
                        self.setState({
                            registrationIdRequired: true
                        })
                        //self.state.stateSet('registrationIdRequired',true)
                    } else {
                        this.getCountry()
                        self.saveProfile()
                        // this.signupNext(4);

                    }
                } else {
                    this.setState({
                        roleError: "*Please select any role"
                    })
                }










            }
        } else if (this.state.questionStep == 4) {
            if (!this.state.selectedCountry || this.state.selectedCountry === "") {
                this.setState({ countryError: '*Please select country.' })
            }
                // else if(!this.state.selectedCity||this.state.selectedCity===""){
                //     this.setState({ cityError: '*Please select city.' })
            // }
            else {
                this.signupNext(5);
            }
        }



        // else{
        //     // this.submitEventQuestion()

        // }


    }




    saveProfile = () => {
        // let req2 = {
        //     // budgetId: budgetId,
        //     countryCode: this.state.code,
        //     phoneNo: this.state.phoneNumber,
        //     phoneNumber: this.state.phoneNumber,
        //     companyName: this.state.companyName,
        //     city: this.state.city,
        //     firstName: this.state.firstName,
        //     lastName: this.state.lastName,
        //     // isEuropean: this.state.isEuropeCT == true ? 1 : 0
        //   }

        let req = {
            requestUserId: this.props.match.params.id,
        }



        let data = {
            // requestUserId: this.props.match.params.id || "",
            // exhibitionId:self.props.loading.exhibitionId,
            // userAnswer:userAns, selectedCityOption
            country: this.state.selectedCountry,
            country_Code: this.state.selectedCountryCode,
            registrationId: this.state.registrationId,
            placeOfWork: this.state.selectedPlaceOfWork.toString(),
            sectorId: this.state.selectedSector.toString(),
            roleId: this.state.selectedRole.toString(),
            requestUserId: req.requestUserId
        }

        if (this.state.showOtherCity === true) {
            data.city = this.state.otherCity;
            data.cityId = ""
        } else {
            data.city = this.state.selectedCity;
            data.cityId = this.state.selectedCityId;
        }



        _Api(Url.EDIT_USER_PROFILE.method, Url.EDIT_USER_PROFILE.url, data)
            .then(resp => {
                this.setState({ isLoading: false })
                this.props.onClose()
            }).catch(err => {
            // if (err.error)
            //     notify("err", err.error.responseMessage)
        })
    }

    getCountry = () => {
        let self = this;
        self.setState({ "isLoading": true })
        _Api(Url.GET_COUNTRY_LIST_V2.method, Url.GET_COUNTRY_LIST_V2.url)
            .then(resp => {
                if (resp.statusCode == 1) {
                    this.setState({
                        countryList: resp.responseData.result,
                    })
                }
                self.setState({ "isLoading": false })
            }).catch(err => {
            if (err.error)
                notify("err", err.error.responseMessage)
        })
    }

    getCity = (selectedCountryCode) => {
        let self = this;
        //let selectedCountryCode=this.props.selectedCountryCode;
        _Api(Url.GET_CITY_LIST.method, Url.GET_CITY_LIST.url, '', '?countryCode=' + selectedCountryCode)
            .then(resp => {
                if (resp.statusCode == 1) {
                    this.setState({
                        cityList: resp.responseData.result,
                    })
                }
            }).catch(err => {
            if (err.error)
                notify("err", err.error.responseMessage)
        })
    }

    selectCountry = (val) => {
        //console.log("value",val);
        let value = val.value;
        let data = value.split("#");
        // this.props.selectCountry(data[0],data[1]);
        this.setState({
            countrySelectedOption: val,
            selectedCityOption: 'Select City',
            selectedCountryCode: data[0],
            selectedCountry: data[1],
            selectedCity: "",
            selectedCityId: "",
            otherCity: "",
            cityPlaceholder: 'Select City',
            showOtherCity: false,
            citySelectedOption: null,

        })
        this.getCity(data[0]);
    }

    selectCity = (val) => {
        let value = val.value;
        let data = value.split("#");
        //this.props.selectCity(data[1],data[0])
        if (value === "other") {
            this.setState({
                citySelectedOption: val,
                selectedCityOption:val,
                showOtherCity: true,
                selectedCity: "Other",
                otherCity:''
            })
        } else {
            console.log("jhgcsfvdghjkl", { val, data })
            this.setState({
                citySelectedOption: val,
                selectedCityOption:val,
                otherCity:'',
                showOtherCity: false,
                selectedCity: data[1],
                selectedCityId: data[0]
            })
        }
    }

    getQuestion = (exhibitionId, flag) => {
        console.log("flagggggggg", flag)
        let self = this;
        _Api(Url.EVENT_QUESTION.method, Url.EVENT_QUESTION.url, { exhibitionId: exhibitionId })
            .then(resp => {
                if (resp.statusCode == 1) {
                    if(flag){
                        this.setState({
                            questionList: resp.responseData.result.data,
                            lastQuestion: resp.responseData.result.data[4]
                        }, function () {
                            let questionCount = self.state.questionCount

                            // this.setState({lastQuestion : questionCount.slice(-1).pop()})
                            self.state.questionList.map((ques, i) => {
                                questionCount.push(self.state.questionCount.length + 1)
                            })
                            self.setState({
                                questionCount
                            })
                        })

                    }

                    else {
                        let arr = resp.responseData.result.data
                        // let lastObj = arr.splice(-1).pop()


                        console.log("isdaghjkl", arr)
                        this.setState({
                            questionList: arr, 
                            // lastQuestion: lastObj
                        }, function () {
                            let questionCount = self.state.questionCount

                            // this.setState({lastQuestion : questionCount.slice(-1).pop()})
                            self.state.questionList.map((ques, i) => {
                                questionCount.push(self.state.questionCount.length + 1)
                            })
                            self.setState({
                                questionCount
                            })
                        })
                    }





                }
            }).catch(err => {
            if (err.error)
                notify("err", err.error.responseMessage)
        })
    }
    questionNextStep = (e, i, question) => {
        let self = this;
        e.preventDefault();

        // if(this.state.questionCount.length === this.state.questionStep - 4)
        // this.submitEventQuestion()

        if (question.isMandatory === 2) {
            if (question.questionType === 1) {
                if (this.state['userAns-' + question._id] && this.state['userAns-' + question._id].trim() !== "") {
                    if (this.state.questionStep < this.state.questionCount.length) {
                        this.setState({
                            questionStep: this.state.staticQuestion + i + 1
                        })
                        console.log("yeeeeeeeeeeeeeeee00" )
                    } else {
                        console.log("yeeeeeeeeeeeeeeee111" )
                        this.submitEventQuestion();
                    }
                } else {
                    this.setState({
                        [question._id + '-error']: "*Please answer this question"
                    })
                }
            } else {
                if (this.state['userAns-' + question._id] && this.state['userAns-' + question._id].length > 0) {






                    if (this.state.questionStep < (this.state.questionCount.length + this.state.questionList.length + 1) && this.state.questionCount.length != this.state.questionStep - 4) {
                        this.setState({
                            questionStep: this.state.staticQuestion + i + 1
                        })
                    } else {
                        console.log("yeeeeeeeeeeeeeeee222" )
                        // console.log("dahgjk", this.state.questionStep < (this.state.questionCount.length + this.state.questionList.length + 1))
                        this.submitEventQuestion();
                    }
                } else {
                    this.setState({
                        [question._id + '-error']: "*Please answer this question"
                    })
                }
            }
        } else {
            let len = this.state.questionCount.length
            if (this.props.stepEdit == 3)
                len = len + 4
            if (this.state.questionStep < (len)) {
                this.setState({
                    questionStep: this.state.staticQuestion + i + 1
                })
            } else {
                // console.log("yeeeeeeeeeeeeeeee3333", this.state.questionStep < (this.state.questionCount.length) , this.state.questionStep ,   this.state.questionCount.length )
                this.submitEventQuestion();
            }
        }

    }

    backQuestionStep = () => {
        if (this.state.questionStep == 3) {
            if (this.state.registrationIdRequired === false) {
                this.setState({
                    questionStep: this.state.questionStep - 1
                })
            } else {
                this.setState({
                    registrationIdRequired: false
                })
            }
        } else {
            this.setState({
                questionStep: this.state.questionStep - 1
            })
        }
    }

    onChecked = (e, question) => {
        let selectedField = this.state['userAns-' + question] ? this.state['userAns-' + question] : [];
        let checkResult = _.find(selectedField, function (obj) {
            return obj === e.target.value;
        })
        //console.log("onChecked",e.target.value,selectedField,checkResult);
        if (checkResult) {
            let newData = _.remove(selectedField, function (n) {
                return e.target.value === n;
            });
            //console.log("======",newData);
            // if (question == "5fffd3682112b80659e30dc5" && this.state.questionCount.length == 5) {
            //     this.state.questionList.splice(-1).pop()
            //     this.state.questionCount.splice(-1).pop()
            //     this.setState({ isNext: false })
            // }
            this.setState({
                [question + '-error']: "",
                ['userAns-' + question]: selectedField,
            })
        } else {
            if (question == "5fffd3682112b80659e30dc5" && this.state.questionCount.length == 4) {
                this.state.questionList.push(this.state.lastQuestion)
                this.state.questionCount.push(this.state.questionCount.length + 1)
                this.setState({ isNext: true })
            }



            selectedField.push(e.target.value);
            this.setState({
                [question + '-error']: "",
                ['userAns-' + question]: selectedField,
            })
        }
    }

    onCheckedBox = (id, question) => {
        console.log('jhsgbj', { id, question })

        let questionId = question;
        let nextQuestion = _.find(this.state.questionList, function (obj) {
            return obj.previousQuestionId === questionId;
        })
        if (nextQuestion) {
            this.setState({
                ['userAns-' + nextQuestion._id]: []
            })
        }
        let selectedField = this.state['userAns-' + question] ? this.state['userAns-' + question] : [];
        let checkResult = _.find(selectedField, function (obj) {
            return obj === id;
        })
        // console.log("onChecked",selectedField,checkResult);
        if (checkResult) {
            let newData = _.remove(selectedField, function (n) {
                return id === n;
            });
            //console.log("======",newData);
            // if (question == "5fffd3682112b80659e30dc5" && this.state.questionCount.length == 5) {
            //     this.state.questionList.splice(-1).pop()
            //     this.state.questionCount.splice(-1).pop()
            //     this.setState({ isNext: false })
            // }
            this.setState({
                [question + '-error']: "",
                ['userAns-' + question]: selectedField,
            })
        } else {
            selectedField.push(id);
            if (question == "5fffd3682112b80659e30dc5" && this.state.questionCount.length == 4) {

                this.state.questionCount.push(this.state.questionCount.length + 1)
                this.state.questionList.push(this.state.lastQuestion)
                this.setState({ isNext: true })
            }
            this.setState({
                [question + '-error']: "",
                ['userAns-' + question]: selectedField,
            })
        }
    }

    onRadioSelect = (e, question) => {
        this.setState({
            [question + '-error']: "",
            ['userAns-' + question]: e.target.value,
        })
    }

    onRadioSelectBox = (id, question) => {
        this.setState({
            [question + '-error']: "",
            ['userAns-' + question]: id,
        })
    }

    getOption = (question, index) => {
        let option = [];

        let preQuesAns = this.state['userAns-' + question.previousQuestionId];
        _.map(preQuesAns, function (obj) {
            let optionValue = _.find(question.option, function (opt) {
                if (opt.name === obj) {
                    return opt;
                }
            });
            console.log("=========", optionValue, obj);
            if (optionValue) {
                option = _.union(option, optionValue.value);
            }

        })
        //console.log("=========",option);
        return option;
    }

    textAnsSave = (e, question) => {
        this.setState({
            [question._id + '-error']: "",
            ['userAns-' + question._id]: e.target.value
        })
    }
    textAnsSave1 = (e, question) => {
        console.log("dghjk", { e, question })
        this.setState({
            [question._id + '-error']: "",
            ['userAns-' + question]: e
        })
    }

    submitEventQuestion = () => {
        let self = this;
        // console.log("submitEventQuestion");
        let req = {
            requestUserId: this.props.match.params.id || "",
        };
        let questionList = this.state.questionList;
        let userAns = [];
        _.map(questionList, function (ques) {
            let ans = {};
            ans.questionId = ques._id;
            if (ques.questionType === 1) {
                ans.plainTextAnswer = self.state['userAns-' + ques._id] ? self.state['userAns-' + ques._id] : "";
            } else if (ques.questionType === 2) {
                ans.optionAnswerArray = self.state['userAns-' + ques._id];
            } else {
                ans.optionAnswerArray = self.state['userAns-' + ques._id] ? [self.state['userAns-' + ques._id]] : [];
            }
            userAns.push(ans);
        })
        console.log("userAns", userAns);
        let data = {
            exhibitionId: self.props.loading.exhibitionId,
            userAnswer: userAns,
            requestUserId: req.requestUserId ? req.requestUserId : ''
        }
        if (this.state.showOtherCity === true) {
            data.city = this.state.otherCity;
        } else {
            data.city = this.state.selectedCity;
            data.cityId = this.state.selectedCityId;
        }
        _Api(Url.EDIT_ANSWER_LIST.method, Url.EDIT_ANSWER_LIST.url, data)
            .then(resp => {
                if (resp.statusCode == 1) {
                    // this.props.dispatch({ type: TOGGLE_QUESTION, value: 0 });
                    this.props.onClose()
                }
            }).catch(err => {
            if (err.error)
                notify("err", err.error.responseMessage)
        })
    }

    onCheckedBoxStatic = (id, field, countField) => {
        let selectedField = this.state[field]
        if (field === 'selectedSector') {
            this.setState({
                selectedPlaceOfWork: [],
                placeofworkCount: 0,
                selectedRole: [],
                roleCount: 0,
                registrationId: "",
                registrationIdRequired: false,
            })
        } else if (field === 'selectedPlaceOfWork') {
            this.setState({
                selectedRole: [],
                roleCount: 0,
                registrationId: "",
                registrationIdRequired: false,
                isShowRegisterId: true
            })
        } else if (field === 'selectedRole') {
            this.setState({
                registrationId: "",
                isShowRegisterId: false,
                registrationIdRequired: false,
            })
        }
        let checkResult = _.find(selectedField, function (obj) {
            return obj === id;
        })
        //console.log("onChecked",e.target.value,selectedField,checkResult);
        if (checkResult) {
            let newData = _.remove(selectedField, function (n) {
                return id === n;
            });
            //console.log("======",newData);
            this.setState({
                [field]: selectedField,
                [countField]: this.state[countField] - 1
            })
        } else {
            selectedField.push(id);
            this.setState({
                [field]: selectedField,
                [countField]: this.state[countField] + 1
            })
        }
    }

    sugnUpOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    skipQuestion = () => {
        this.submitEventQuestion();

    }

    render() {
        // console.log("thisstate",  this.state.questionStep);
        // console.log("this.propssssssss", this.props.userDetail._id !== localStorage.getItem("id"));
        // console.log("thisstatekdhgdhjksal",this.state.showOtherCity, this.props.userData);
        let { sectorError, placeofWorkError, roleError, registrationIdError, countryError, cityError, registrationId } = this.state;
        let option;
        let countryData = [];
        {
            this.state.countryList.length > 0 && this.state.countryList.map((item) => (
                countryData.push({ value: item.country_code + "#" + item.country_name, label: item.country_name })
            ))
        }
        let cityData = [];
        {
            this.state.cityList.length > 0 && this.state.cityList.map((item) => (
                cityData.push({ value: item._id + "#" + item.city_name, label: item.city_name })
            ))
        }
        cityData.push({ value: 'other', label: 'Other' })





        return (
            <>

                <Modal
                    wrapClassName="signup_parent_modal transparent"
                    className="cm_popup signup_modal "
                    visible={this.props.showQuestionModal}
                    //visible={false}
                    onCancel={() => this.props.onClose()}
                    footer={null}
                    centered
                    maskClosable={false}
                    closable={true}
                >
                    <div className="sign-modal-content">
                        <div className="top_banner pos-relative">
                            <img src={require('../../assets/images/popup-image.png')} className="img-fluid" />

                            <h4 className="text-center popup_mid_test ">
                                <b className="pos-relative">Explore Brands & Innovations</b>
                                <span className="heading-after-border heading-after-border-center">
                                    <span className="heart"><Hert /></span></span>
                            </h4>
                            {this.state.questionStep >5?
                                <button type="button" className="btn sign-backbtn btn-on-img" onClick={this.backQuestionStep}> &nbsp;Back
                                </button>:""}
                            {(this.state.questionStep === 6 || this.state.questionStep === 7) ?
                                <button type="button" className="btn btn-on-img question-skip" onClick={this.skipQuestion}>Skip
                                </button> : ""}
                        </div>
                        <div className="row align-items-center">
                            <div className="col-md-12">

                                <div className="signup-tab mt-3 mb-5">
                                    <div className="Thing-line"><span className="circle_bullet"></span><span
                                        className="heart"><Hert /></span></div>
                                    {this.props.stepEdit != 2 && this.state.questionCount.map((ques, i) => (
                                        <div key={"step-" + ques} className={this.checkStepClass(i + 1)}><span>{i + 1}</span></div>
                                    ))}
                                </div>
                                {this.state.questionStep === 1 ?
                                    <div id="step1" >
                                        <h3 className="auth-heading pos-relative text-center mt-5">
                                            Which Sector(s) do you currently work in?
                                            <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                        </h3>
                                        {/*<p className="home-subheading text-center">Free for professionals and low cost options for brands and suppliers </p>*/}
                                        <form className="rounded-textbox-form mt-5" onSubmit={this.signupNextStep}>

                                            {this.state.sectorList.length > 0 && this.state.sectorList.map((item) => (
                                                <div className={this.checkSelectedStatic('selectedSector', item._id) ? "form-group form-control select_field cursor-point" : "form-group form-control cursor-point"} key={item._id} onClick={(e) => this.onCheckedBoxStatic(item._id, 'selectedSector', 'selectedSectorCount')}>
                                                    <div className="check_wrapper circle-checkbox ">
                                                        <input name={'selectedSector-' + item.name} checked={this.checkSelectedStatic('selectedSector', item._id)} type="checkbox" value={item._id} />
                                                        <span />
                                                    </div>
                                                    <span>{item.name}</span>
                                                </div>
                                            ))}
                                            <InlineError
                                                message={sectorError} />
                                            <div className="text-center">
                                                <button type="submit" disabled={this.state.isLoading} className="btn btn-red"> &nbsp;Next
                                                    &nbsp;
                                                    {this.state.isLoading &&
                                                    <MySpinner
                                                    />}
                                                </button>
                                            </div>

                                        </form>
                                    </div>
                                    : ""}
                                {this.state.questionStep === 2 ?
                                    <div id="step2" >
                                        <h3 className="auth-heading pos-relative text-center mt-5">
                                            What is your place of work?
                                            <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                        </h3>
                                        {/*<p className="home-subheading text-center">Free for professionals and low cost options for brands and suppliers </p>*/}
                                        <form className="rounded-textbox-form mt-5" onSubmit={this.signupNextStep}>
                                            {this.state.placeOfWorkList.length > 0 && this.state.placeOfWorkList.map((item) => (
                                                <div className={this.checkSelectedStatic('selectedPlaceOfWork', item._id) ? "form-group form-control select_field cursor-point" : "form-group form-control cursor-point"} key={item._id} onClick={(e) => this.onCheckedBoxStatic(item._id, 'selectedPlaceOfWork', 'placeofworkCount')}>
                                                    <div className="check_wrapper circle-checkbox ">
                                                        <input name={'selectedPlaceOfWork-' + item.name} checked={this.checkSelectedStatic('selectedPlaceOfWork', item._id)} type="checkbox" value={item._id} />
                                                        <span />
                                                    </div>
                                                    <span>{item.name}</span>
                                                </div>
                                            ))}
                                            <InlineError
                                                message={placeofWorkError} />
                                            <div className="text-center">
                                                <button type="submit" disabled={this.state.isLoading} className="btn btn-red"> &nbsp;Next
                                                    &nbsp;
                                                    {this.state.isLoading &&
                                                    <MySpinner
                                                    />}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                    : ""}
                                {this.state.questionStep === 3 ?
                                    this.state.isShowRegisterId || this.state.registrationIdRequired === false ?
                                        <div id="step3" >
                                            <h3 className="auth-heading pos-relative text-center mt-5">
                                                What is your role?
                                                <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                            </h3>
                                            {/*<p className="home-subheading text-center">Free for professionals and low cost options for brands and suppliers </p>*/}
                                            <form className="rounded-textbox-form mt-5" onSubmit={this.signupNextStep}>
                                                {this.state.roleList.length > 0 && this.state.roleList.map((item) => (
                                                    <div className={this.checkSelectedStatic('selectedRole', item._id) ? "form-group form-control select_field cursor-point" : "form-group form-control cursor-point"} key={item._id} onClick={(e) => this.onCheckedBoxStatic(item._id, 'selectedRole', 'roleCount')}>
                                                        <div className="check_wrapper circle-checkbox ">
                                                            <input name={'selectedRole-' + item.name} checked={this.checkSelectedStatic('selectedRole', item._id)} type="checkbox" value={item._id} />
                                                            <span />
                                                        </div>
                                                        <span>{item.name}</span>
                                                    </div>
                                                ))}
                                                <InlineError
                                                    message={roleError} />
                                                <div className="text-center">
                                                    <button type="submit" disabled={this.state.isLoading} className="btn btn-red"> &nbsp; {
                                                        this.state.registrationIdRequired === false ? 'Submit' : 'Submit'
                                                    }
                                                        &nbsp;
                                                        {this.state.isLoading &&
                                                        <MySpinner
                                                        />}
                                                    </button>
                                                </div>

                                            </form>
                                        </div>
                                        :
                                        <div className="mt-5">
                                            <h4 className="pl-4 pr-4">Do you have a registered GDC/ GMC/ NMC/ GPhC number or overseas equivalent?</h4>
                                            <form className="rounded-textbox-form mt-5" onSubmit={this.signupNextStep}>
                                                <div className="form-group">
                                                    <input type="text"
                                                           autocomplete="do-not-show-ac"
                                                           className="form-control"
                                                           placeholder="Enter GDC/ GMC/ NMC/ GPhc number"
                                                           name='registrationId'
                                                           value={registrationId}
                                                           onChange={this.sugnUpOnChange}
                                                    />
                                                    <InlineError
                                                        message={registrationIdError} />
                                                </div>

                                                <div className="text-center">
                                                    <button type="submit" disabled={this.props.isLoading} className="btn btn-red"> &nbsp;Submit
                                                        &nbsp;
                                                        {this.state.isLoading &&
                                                        <MySpinner
                                                        />}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    : ""}
                                {this.state.questionStep === 4 ?
                                    <div id="step4" >
                                        <form className="rounded-textbox-form mt-5 " >

                                            <div className="form-group">
                                                <h4 className="text-center"><b>Which country are you currently based?</b>
                                                    {/* <span className="red-star"> * </span> */}
                                                </h4>
                                                <Select showSearch
                                                        placeholder={this.state.countrySelectedOption}
                                                        onChange={(e) => this.selectCountry(e)}
                                                        value={this.state.countrySelectedOption}
                                                        options={countryData}
                                                >

                                                </Select>
                                                <InlineError
                                                    message={countryError} />
                                            </div>
                                            <div className="form-group">
                                                <h4 className="text-center"><b>Which is your closest large city?</b>
                                                    {/* <span className="red-star"> * </span> */}
                                                </h4>
                                                <Select showSearch
                                                        placeholder={this.state.cityPlaceholder}
                                                        onChange={this.selectCity}
                                                        value={this.state.selectedCityOption}
                                                        options={cityData}
                                                >


                                                </Select>

                                                <InlineError
                                                    message={cityError} />
                                            </div>
                                            {
                                                this.state.showOtherCity === true ?
                                                    <div className="form-group">
                                                        <input type="text"
                                                               autoComplete="do-not-show-ac"
                                                               className="form-control"
                                                               placeholder="City Name"
                                                               id="city"
                                                               maxLength={250}
                                                               name='otherCity'
                                                               value={this.state.otherCity}
                                                               onChange={this.sugnUpOnChange}
                                                        />
                                                    </div> : ""}

                                            <div className="text-center">
                                                <button type="button" onClick={this.saveProfile} disabled={this.state.isLoading} className="btn btn-red"> &nbsp;Submit
                                                    &nbsp;
                                                    {this.state.isLoading &&
                                                    <MySpinner
                                                    />}
                                                </button>
                                            </div>

                                        </form>

                                    </div> : ""}
                                {this.state.questionList.length > 0 && this.state.questionList.map((item, i) => (
                                    this.state.questionStep === (this.state.staticQuestion + i + 1) ?
                                        <div key={"step-ques-" + item._id} id={"step-" + i}>
                                            <h4 className="text-center"><b> {item.question}</b></h4>
                                            <form className="rounded-textbox-form mt-4" onSubmit={(e) => this.questionNextStep(e, i + 1, item)} >
                                                {item.questionType === 1 ?
                                                    <div>
                                                        <input type="text" maxLength={item?.textLimit} rows="4" placeholder="Please Enter" className="form-control hi" value={this.state['userAns-' + item._id]} onChange={(e) => this.textAnsSave(e, item)} />



                                                    </div>
                                                    : ""}
                                                {item.questionType === 2 ?
                                                    item.isRelation === 1 && this.state.questionList[i - 1].questionType !== 1 ?

                                                        this.getOption(item, i).map((opt, i) => (
                                                            <div
                                                                className={this.checkSelected(item._id, opt) ? "form-group form-control select_field cursor-point" : "form-group form-control cursor-point"}
                                                                key={item._id + "-" + opt}
                                                                onClick={(e) => this.onCheckedBox(opt, item._id)}
                                                            >
                                                                <div className="check_wrapper circle-checkbox ">
                                                                    <input name={'selectedSector-' + opt}
                                                                           checked={this.checkSelected(item._id, opt)}
                                                                           type="checkbox" value={opt}
                                                                        //onChange={(e) => this.onChecked(e, item._id)}
                                                                    />
                                                                    <span />
                                                                </div>
                                                                <span>{opt}</span>
                                                            </div>
                                                        ))
                                                        :
                                                        item.option.map((opt, i) => (
                                                            <div
                                                                className={this.checkSelected(item._id, opt.name) ? "form-group form-control select_field cursor-point" : "form-group form-control cursor-point"}
                                                                key={item._id + "-" + opt.name}
                                                                onClick={(e) => this.onCheckedBox(opt.name, item._id)}
                                                            >
                                                                <div className="check_wrapper circle-checkbox ">
                                                                    <input name={'selectedSector-' + opt.name}
                                                                           checked={this.checkSelected(item._id, opt.name)}
                                                                           type="checkbox" value={opt.name}
                                                                        //onChange={(e) => this.onChecked(e, item._id)}
                                                                    />
                                                                    <span />
                                                                </div>
                                                                <span>{opt.name}</span>
                                                            </div>
                                                        ))

                                                    : ""}
                                                {item.questionType === 3 ?
                                                    item.isRelation === 1 && this.state.questionList[i - 1].questionType !== 1 ?
                                                        this.getOption(item, i).map((opt, i) => (
                                                            <div
                                                                className={this.checkRadioSelected(item._id, opt) ? "form-group form-control select_field cursor-point" : "form-group form-control cursor-point"}
                                                                key={item._id + "-" + opt}
                                                                onClick={(e) => this.onRadioSelectBox(opt, item._id)}
                                                            >
                                                                <div className="check_wrapper circle-checkbox ">
                                                                    <input name={'radio-' + item._id}
                                                                           checked={this.checkRadioSelected(item._id, opt)}
                                                                           type="radio" value={opt}
                                                                        //onChange={(e) => this.onRadioSelect(e, item._id)}
                                                                    />
                                                                    <span />
                                                                </div>
                                                                <span>{opt}</span>
                                                            </div>
                                                        ))
                                                        :
                                                        item.option.map((opt, i) => (
                                                            <div
                                                                className={this.checkRadioSelected(item._id, opt.name) ? "form-group form-control select_field cursor-point" : "form-group form-control cursor-point"}
                                                                key={item._id + "-" + opt.name}
                                                                onClick={(e) => this.onRadioSelectBox(opt.name, item._id)}
                                                            >
                                                                <div className="check_wrapper circle-checkbox ">
                                                                    <input name={'radio-' + item._id}
                                                                           checked={this.checkRadioSelected(item._id, opt.name)}
                                                                           type="radio" value={opt.name}
                                                                        //onChange={(e) => this.onRadioSelect(e, item._id)}
                                                                    />
                                                                    <span />
                                                                </div>
                                                                <span>{opt.name}</span>
                                                            </div>

                                                        ))
                                                    : ""}

                                                <InlineError
                                                    message={this.state[item._id + "-error"] ? this.state[item._id + "-error"] : ""} />
                                                <div className="text-center">
                                                    <button type="submit" disabled={this.props.isLoading}
                                                        // className="btn btn-red"> &nbsp;{this.state.questionCount.length === this.state.questionStep - 4 ? 'Submit' : 'Next'}
                                                            className="btn btn-red"> &nbsp;{this.state.isNext && this.state.questionList.length != 5 ? 'Next' : (this.state.questionCount.length === this.state.questionStep - 4) ? 'Submit' : 'Next'}
                                                        &nbsp;
                                                        {this.state.isLoading &&
                                                        <MySpinner
                                                        />}
                                                    </button>
                                                </div>

                                            </form>
                                        </div>
                                        : ""
                                ))}
                            </div>
                        </div>
                    </div>
                </Modal>
            </>

        )
    }
}
function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(Question);