import React, { Component } from 'react';
import Footer from '../../../layout/Footer';
import { Hert, ExhibitionManagement, Pencil, Delete } from '../../../../SvgIcon';
import { Link } from "react-router-dom";
import { _Api } from '../../../../services/Api';
import Url from '../../../../services/BaseUrl';
import { notify } from '../../../../components/Toaster';
import moment from 'moment-timezone';
import { Pagination } from '../../../../components/common/Pagination';
import { Empty } from 'antd';
import { MySpinner, ExhibitionLoader } from "../../../../components/index"

import { Modal, Button, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;




class ExhibitonManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDeleted: '',
            exhibtionId: '',
            status: "",
            page: 1,
            limit: 10,
            loading: false,
            visible: false,
            exhibitionListData: [],
            selectedId: '',
            isLoader: false,
            search: '',
            totalCount: [],
            snNo: 0,
            selectedItems: [],
            order:1,
        }
    }

    componentDidMount() {
        this.getExhibitionList()
    }

    getExhibitionList = (isShowLoader = true) => {
        this.setState({ isLoader: isShowLoader })
        let { page, limit, search, order } = this.state
        let req = {
            page: page ? page : 1,
            limit: limit,
            search: search.toString(),
            // order:order
        }
        _Api(Url.GET_EXHIBITION_LIST.method, Url.GET_EXHIBITION_LIST.url, '', `?page=${req.page}&limit=${req.limit}&search=${req.search}&order=${req.order}`)
            .then(resp => {
                // console.log('respexList', resp.responseData.result.totalCount)
                this.setState({ exhibitionListData: resp.responseData.result.data, totalCount: resp.responseData.result.totalCount, isLoader: false })

            }).catch(err => {
                this.setState({ isLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
                this.setState({ isLoader: false })
            })
    }

    handlePageChange = page => this.setState({ loader: true, page }, () => this.getExhibitionList());

    handleSearch = e => this.setState({ search: e.target.value, page: 1, snNo: 0, exhibitionListData: [] }, () => this.getExhibitionList());



    deleteExhibitionList = (id) => {
        let req = {
            isDeleted: 0,
            exhibtionId: id,

        }
        _Api(Url.EXHIBITION_LIST_DELETE.method, Url.EXHIBITION_LIST_DELETE.url, '', `?exhibtionId=${req.exhibtionId}&isDeleted=${req.isDeleted}`)
            .then(resp => {               
                notify('success', resp.responseData.message)
                this.setState({ visible: false })
                this.getExhibitionList(false)
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
            })


    }

    //Show modal
    showModal = (id) => {
        this.setState({
            visible: true,
            selectedId: id
        });

    };
    //Cancel modal
    handleCancel = () => {
        // console.log('delete', resp.responseData.message)
          this.setState({ visible: false });
    };



    statusExihibitionList = (e, id) => {
        let oldArr = this.state.selectedItems
        oldArr.push(id)

        this.setState({ selectedItems: oldArr })

        let req = {
            exhibtionId: id,
            status: (e.target.checked ? 1 : 0).toString(),
        }
        _Api(Url.EXHIBITION_LIST_STATUS.method, Url.EXHIBITION_LIST_STATUS.url, req)
            .then(resp => {
                this.setState({ visible: false, selectedItems: this.state.selectedItems.filter(item => item != id) })
                this.getExhibitionList(false)
            }).catch(err => {
                this.setState({ visible: false, selectedItems: this.state.selectedItems.filter(item => item != id) })
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
            })

    }

    onPressBtn = (page) => {
        this.setState({ page, snNo: page - 1, exhibitionListData: [] }, () => {
            this.getExhibitionList()
        })

    }

    // delete modal
     showDeleteConfirm = (id) => {
        confirm({
            title: 'Are you sure you want to delete this?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            onOk: () => {
             this.deleteExhibitionList(id)
            },
            onCancel: () => {
                this.handleCancel()
            },
        });
    }

    handleToggle = ()=>{
        this.setState({order : this.state.order == 1 ? 2 : 1, exhibitionListData:[]},()=>{
            this.getExhibitionList()
        })
    }

    handleClickLabel = (status)=>{
        this.setState({order : status ? 1 : 2, exhibitionListData:[]},()=>{
            this.getExhibitionList()
        })
    }
    

    render() {
        let { exhibitionListData, visible, loading, search, isLoader, selectedItems } = this.state;
        return (
            <>
                <div className="dash_body_container">
                    <div className="body_container">                      
                        <div className="header-container">
                            <div className="d-flex flex-grow-1 align-items-center">
                                <p className="header-icon"><ExhibitionManagement /></p>
                                <h4>Exhibition Management</h4>
                            </div>
                            <div className="search_wrapper  mr-2">
                                <i className="fa fa-search" />
                                <input type="search"
                                    placeholder="Search by name"
                                    name='search'
                                    value={search}
                                    onChange={this.handleSearch}
                                />
                            </div>

                            <Link to="/addexhibition/exhibitiondetails/1" className="btn btn-red">Add New Exhibition</Link>

                        </div>
                        {/* <p className="toggle-parent ">
                           <a onClick={()=> this.handleClickLabel(true)}> Upcoming </a>
                                      <label className="switch">                               
                                <input type="checkbox"
                                        checked={this.state.order === 1 ? false : true}
                                        onChange={(e) => this.handleToggle()}
                                                                    />
                                <span className="slider round"></span>
                            </label>
                            <a onClick={()=> this.handleClickLabel(false)}> History </a>
                                </p> */}


                        <div class="table-responsive">
                            <table class="table table-bordered rounded-table">
                                <thead>
                                    <tr>
                                        <th>S.No.</th>
                                        <th>Exhibition Name </th>                                      
                                        <th>Start Date & Time</th>
                                        <th>End Date & Time</th>
                                        <th>No.of Seminar</th>
                                        <th>Networking Lounges</th>
                                        <th>Businesses</th>
                                        <th>Create Date</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoader && <ExhibitionLoader />}
                                    {exhibitionListData?.map((item, index) => {
                                        console.log('item', item)
                                        return (

                                            <tr key={item._id} >                                               
                                                <td><span className="circle-serial">{(this.state.snNo * 10) + index + 1}</span></td>
                                                <td><Link to={`/professional-beauty-world/${item._id}`} >{item.exhibitionName} </Link></td>                                               
                                                <td><p><p>{moment.unix(item.start_Date).format("DD/ MM/ YYYY")}</p>  <p>{moment(item.startTime).format('LT')}</p> <p>{moment.unix(item.start_Date).format('dddd')}</p></p></td>
                                                <td><p><p>{moment.unix(item.end_Date).format("DD/ MM/ YYYY")}</p>  <p>{moment(item.endTime).format('LT')}</p> <p>{moment.unix(item.end_Date).format('dddd')}</p></p></td>
                                                <td>{item?.conferenceData.length}</td>
                                                <td>{item?.networkLoungeData.length}</td>
                                                <td>{item?.brandData.length}</td>
                                                <td>{moment(item.created).format("DD/ MM/ YYYY")}</td>
                                                <td>
                                                    {
                                                        selectedItems.indexOf(item._id) == -1 ?
                                                            <p className="toggle-parent ">
                                                                <label className="switch">
                                                                    <input type="checkbox"
                                                                        checked={item.status === 1 ? true : false}
                                                                        onChange={(e) => this.statusExihibitionList(e, item._id)}
                                                                    />
                                                                    <span className="slider round"></span>
                                                                </label>

                                                            </p>
                                                            :
                                                            <MySpinner />
                                                    }


                                                </td>
                                                <td className="action_group">
                                                    <Link to={`/exhibition/edit/${item._id}`}> <p><p className="circle-serial" ><Pencil /></p></p></Link>

                                                    <a onClick={() => this.showDeleteConfirm(item._id)}><p className="circle-serial"><Delete /></p></a>
                                                    {/*<a onClick={() => this.showModal(item._id)}><p className="circle-serial"><Delete /></p></a>*/}


                                                </td>
                                            </tr>

                                        )
                                    })

                                    }


                                </tbody>
                            </table>
                       
                        <div>
                            {!isLoader && exhibitionListData.length == 0 &&
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                        </div>

                        {
                            exhibitionListData.length != 0 &&
                            <Pagination
                                totalPage={Math.ceil(this.state.totalCount / 10)}
                                currentPage={this.state.page}
                                onPressBtn={this.onPressBtn}
                            />
                        }
                   </div>
                    </div>

                   
                </div>
            </>
        );
    }
}
export default ExhibitonManagement;