import React, { Component } from 'react';
import { BackBtn, BtnRed, CmBtn } from "../../../components/common/Button";
import CommingSoonLayer from "../../../components/common/CommingSoonLayer";
import { Calender, Clock, Hert } from "../../../SvgIcon";
import Footer from "../../layout/Footer";
import WebHeader from '../../layout/WebHeader';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';
import { Select, Modal } from 'antd';
import { connect } from "react-redux";
import { getWishList } from '../../../redux/action';
import { Link } from "react-router-dom"
import {
    MyAlert,
    MySpinner
} from "../../../components/index";

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import InlineError from '../../../components/InlineError';
import Linkify from 'react-linkify';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { SecureLink } from "react-secure-link";

const { Option } = Select;





let isProd = process.env.REACT_APP_ENV == 'prod'
const antIcon = <LoadingOutlined style={{ fontSize: 72 }} spin />;
class ProductDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ProductDetails: [],
            loading: false,
            visible: false,
            message: '',

            isInfoPublic: true,
            isChatPublic: true,
            isVideoPublic: true,
            isMobilePublic: true,

            messageError: ''
        }

        window.scrollTo(0, 0)

    }

    componentDidUpdate(prevProps) {

        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.getParticularViewProduct()
        }
    }

    getParticularViewProduct = () => {
        _Api(Url.VIEW_PARTICULAR_PRODUCT.method, Url.VIEW_PARTICULAR_PRODUCT.url, '', `?productId=${this.props.match.params.id}`)
            .then(resp => {
                console.log('reaERTYp', resp.responseData.result)

                this.setState({ ProductDetails: resp.responseData.result })
                // this.props.closePopup()
                // notify('success', resp.responseData.message)
            }).catch(err => {
                this.setState({ isLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
                this.setState({ isLoader: false })
            })
    }
    componentDidMount() {
        this.props.dispatch({ type: 'PATH_ADD', payload: this.props.match })
        this.getParticularViewProduct();
    }


    addToWishList = (category, id, e) => {
        let stateName = `wish${id}`;
        this.setState({ [`isWishlistLoader${id}`]: true, [stateName]: !this.state[stateName], });
        let self = this;
        let params = category == "brand" ? { brandId: id } : { productId: id };
        let { method, url } = Url[e];
        _Api(method, url, params)
            .then(resp => {
                self.props.dispatch(getWishList());

                this.getParticularViewProduct();

                setTimeout(() => {
                    this.setState({ [`isWishlistLoader${id}`]: false });

                }, 2000);


            }).catch((err) => {
                this.handleError(err);
            })
    }
    handleError(err) {
        let errorMessage = err.error && err.error.responseMessage || err.message;
        notify("err", errorMessage);
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    }

    handleCancel = () => {
        this.setState({
            visible: false, message: '',
            isInfoPublic: true,
            isChatPublic: true,
            isVideoPublic: true,
            isMobilePublic: true
        });
    }


    handleOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, })
    }



    //Create exhibition submit 
    submitMessage = () => {
        if (this.state.isInfoPublic == false && this.state.isVideoPublic == false && this.state.isChatPublic == false && this.state.isMobilePublic == false) {
            this.setState({ messageError: '*Please select at least one.' })

        }
        else {
            this.setState({ isLoader: true })
            let req = {
                // exhibitionName: exhibitionName,  
                enquiryReason: this.state.message,
                productId: this.state.ProductDetails._id,
                category: 1,
                isInfoPublic: this.state.isInfoPublic ? 1 : 2,
                isVideoPublic: this.state.isVideoPublic ? 1 : 2,
                isChatPublic: this.state.isChatPublic ? 1 : 2,
                isMobilePublic: this.state.isMobilePublic ? 1 : 2,
                brandId: this.state?.ProductDetails?.brandData?._id
            }

            _Api(Url.ADD_ENQUIRE_MESSAGE.method, Url.ADD_ENQUIRE_MESSAGE.url, req)
                .then(resp => {
                    this.setState({
                        isLoader: false, visible: false, message: '',
                        isInfoPublic: true,
                        isChatPublic: true,
                        isVideoPublic: true,
                        isMobilePublic: true
                    })
                }).catch(err => {
                    this.setState({ isLoader: false })
                    if (err.error)
                        notify("err", err.error.responseMessage)
                    else if (err)
                        this.setState({ isLoader: false })
                })
        }

    }








    render() {
        let { ProductDetails } = this.state;
        let userType = localStorage.getItem("userType")
        let { brandData } = ProductDetails ? ProductDetails : {}
        const { visible, loading, message } = this.state;
        let isSameeee = brandData ? brandData._id != localStorage.getItem("id") : false

        console.log("dchgj", isSameeee, this.props.userDetail._id, localStorage.getItem("id"), ProductDetails)


        // if ( userType != 1 && isProd)
        // return (
        //     <section className="brand-home-banner-bg webhome">
        //         // {userType != 1 &&{/* <WebHeader history={this.props.history} match={this.props.match} /> */}}               
        //         <div className="centerLoader" >
        //             {
        //                 isProd ? 
        //                 <h1><b>This page will be live on the 8th September, 2020. </b></h1>
        //                 :
        //                 <Spin size="large" tip="Loading..." indicator={antIcon} />

        //             }                      

        //         </div>
        //     </section>


        // )

        // else

        return (<section className={`brand-home-banner-bg brandprofile-banner-bg  ${userType == 1 ? "brand dash_body_container withsidenavbar" : ''} `} >
            {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
            {/* <ChatBox history={this.props.history} match={this.props.match} />*/}
            <div className="cm-section container-fluid">
                <div className=" card-wrapper product-detail mt-0">
                    <div className="row">
                        <div className="col-md-5 pr-5">
                            {/* {brandData && <h5><Link to={`/brand/${brandData?._id}`}>{brandData?.companyName}</Link></h5>} */}
                            <div className="card community-card">
                                <img className="img-fluid" src={ProductDetails.image ? ProductDetails.image : require('../../../assets/images/placeholder.png')} />

                            </div>

                        </div>
                        <div className="col-md-7">
                            <div className="d-flex justify-content-between align-items-center">
                                {/* <span>dhcgbndhvgcnd /dhyv7i i8hj/dvfdv</span>  */}
                                {/* {brandData && <h5><Link to={`/brand/${brandData?._id}`}>{brandData?.companyName}</Link></h5>} */}
                                <a > <button onClick={() => this.props.history.goBack()} className="btn btn-light">Back</button></a>
                            </div>
                            <div className="product-detale">
                                <h1 className="light-black res-h1">{ProductDetails.productName} <br />
                                    {/* <span className="light-blue">{ProductDetails.price ? `$${ProductDetails.price}` : ''}</span> */}
                                </h1>
                                <p><b>Showcase : </b><Link to={`/business/${brandData?._id}/${brandData?.activeCompany}`}>{brandData?.companyName}</Link></p>
                                <br />
                                <p><b>Category : </b>{ProductDetails.manageCategoryData?.map((item) => item?.name)}</p>
                                <p><b>Sector : </b>{ProductDetails.manageSectorData?.map((item) => item?.name)}</p>
                                <hr />
                                <p>
                                    <Linkify
                                        componentDecorator={(
                                            decoratedHref,
                                            decoratedText,
                                            key
                                        ) => (
                                                <SecureLink href={decoratedHref} key={key}>
                                                    {decoratedText}
                                                </SecureLink>
                                            )}
                                    >
                                        {ReactHtmlParser(ProductDetails.description)}
                                    </Linkify>




                                </p>
                            </div>
                            {<div className="product-details-btn-parent">
                                <button type="button" class={!ProductDetails?.wishListData ? "btn btn-red  btn-label btn-navyblue " : "btn btn-red  btn-label"} onClick={() => this.addToWishList('product', ProductDetails?._id, ProductDetails?.wishListData && "REMOVE_WISHLIST" || "ADD_WISHLIST")} >
                                    {!ProductDetails.wishListData ?
                                        "Add to Wishlist "
                                        :
                                        "Remove from Wishlist"}
                                    {this.state[`isWishlistLoader${ProductDetails?._id}`] &&
                                        <MySpinner />}
                                </button> &nbsp;&nbsp;
                               {isSameeee && <button className="btn btn-red btn-label" onClick={this.showModal}>
                                    Enquire Now</button>}
                                {/* <button className="btn btn-blue width-auto businesscard" onClick={(e) => this.onCreateBussinessRequest(item?.userData?._id)}> 
                                            Send business request
                                                        {this.state[`connection${item?.userData?._id}`] && 
                                                            <MySpinner/>}
                                                    </button> */}
                            </div>}
                        </div>
                        {/* <div className="col-md-2">
                                

                            </div> */}
                    </div>


                </div>



            </div>

            <Footer />

            <Modal
                wrapClassName="signup_parent_modal"
                className="cm_popup signup_modal "
                visible={visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={null}
                centered
                maskClosable={false}
            >
                <div className="sign-modal-content">
                    <div className="row align-items-center">
                        <div className="col-md-12" >
                            {/* <h3 className="auth-heading pos-relative text-center mt-5">
                                Reason for enquiry
                                    <span className="heading-after-border heading-after-border-center">
                                    <span className="heart"><Hert /></span>
                                </span>
                            </h3> */}
                            <form className="rounded-textbox-form mt-5" >
                                <div className="form-group">
                                    <h5><b>What is the reason for your enquiry?</b></h5>
                                    <textarea rows="4" placeholder="Please Enter, What is the reason for your enquiry?"
                                        className="form-control hi"
                                        name="message"
                                        value={message}
                                        onChange={this.handleOnChange}
                                    ></textarea>
                                    {/* <InlineError
                                                message={firstNameError}
                                            /> */}
                                    <h5 className="mt-4 mb-1"><b>How would you like to be contacted?</b></h5>
                                    <h5><b>uncheck those that do not apply</b></h5>


                                    <div>
                                        <label class="form-check-label ml-4">
                                            <input class="form-check-input" type="checkbox"
                                                name="remember"
                                                checked={this.state.isMobilePublic}
                                                onClick={() => this.setState({ messageError: '', isMobilePublic: !this.state.isMobilePublic })}
                                            /> Phone
                                    </label>
                                    </div>
                                    <div>
                                        <label class="form-check-label ml-4">
                                            <input class="form-check-input" type="checkbox"
                                                name="remember"
                                                checked={this.state.isInfoPublic}
                                                onClick={() => this.setState({ messageError: '', isInfoPublic: !this.state.isInfoPublic })}
                                            /> Email
                                    </label>
                                    </div>
                                    <div>
                                        <label class="form-check-label ml-4">
                                            <input class="form-check-input" type="checkbox"
                                                name="remember"
                                                checked={this.state.isChatPublic}
                                                onClick={() => this.setState({ messageError: '', isChatPublic: !this.state.isChatPublic })}
                                            /> Chat
                                    </label>
                                    </div>
                                    <div>
                                        <label class="form-check-label ml-4">
                                            <input class="form-check-input" type="checkbox"
                                                name="remember"
                                                checked={this.state.isVideoPublic}
                                                onClick={() => this.setState({ messageError: '', isVideoPublic: !this.state.isVideoPublic })}

                                            /> Video Call
                                    </label>
                                        <InlineError
                                            message={this.state.messageError}
                                        />
                                    </div>
                                    <h6 className="mt-3"><b>Note- </b>by checking phone or email you are agreeing to share the contact information recorded in your profile to the showcase holder</h6>
                                    {/* <label class="form-check-label ml-4 fs-12 mt-3">
                                    <input class="form-check-input mt-1" type="checkbox" name="remember"
                                        checked={this.state.isInfoPublic}
                                        onClick={() => this.setState({ isInfoPublic: !this.state.isInfoPublic })}/>
                                   
                                    </label> */}
                                </div>
                                <div class="text-center">

                                    <button type="button" onClick={this.submitMessage} class="btn btn-red"
                                        disabled={this.state.isLoader}

                                    > &nbsp;Submit Enquiry &nbsp;
                                    {this.state.isLoader &&
                                            <MySpinner />}


                                    </button>
                                </div>
                                {/* <div class="form-group form-check text-center">
                                    <Link to="">Skip</Link>
                                </div> */}
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>











        </section>




        )
    }

}
function mapStateToProps(state) {
    return {
        wishList: state.wishList,
        ...state
    };
}

export default connect(mapStateToProps)(ProductDetails);

