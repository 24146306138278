
import React, { Component } from 'react';
import { Hert, UserManagementIcon, Pencil, Delete, Edit } from '../../../SvgIcon';
import { Link } from "react-router-dom";
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import Util from '../../../utils/Util';
import { Pagination } from '../../../components/common/Pagination';
import { notify } from '../../../components/Toaster';
import { Modal, Button, Empty, Select } from 'antd';
import Loader from '../../../components/Loader';
import util from '../../../utils/Util.js';
import moment from 'moment-timezone';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import { getExhibitionId } from "../../../redux/action";

import { getTimeFormate } from "../../../utils/DateZone"


const { Option } = Select;
const { confirm } = Modal;



const columnDetails = [{ fieldName: "S.No." },
{ fieldName: "Question", isSortable: true, dataIndex: "companyName" },
// { fieldName: "User Name" },
// { fieldName: "Phone" },
// { fieldName: "Email" },
// { fieldName: "Country" },
{ fieldName: "Event name" },
{ fieldName: "Question Type" },
{ fieldName: "Date ", isSortable: true, dataIndex: "created" },
{ fieldName: "Status" },
{ fieldName: "Filter Status" },
{ fieldName: "Action" }
];


class QuestionnaireList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            viewDetails: false,
            limit: 10,
            loading: false,
            selectedId: '',
            userListData: [],
            isLoader: false,
            search: '',
            totalCount: [],
            fieldName: '',
            order: '',
            promoCodeDetailData: [],
            visible: false,
            isDownload: 0,
            sortInfo: {},
            snNo: 0,
            page: 1,
            userType: "",
            exhibitionData: null,
            selectedExhibition: '',
            questionId: '',
            exhibitionId: ""
        };
    }
    componentDidMount() {
        let self = this;
        // console.log("=========",this.props.loading.exhibitionId)
        if (this.props.loading.exhibitionId && this.props.loading.exhibitionId !== "") {
            this.getQuestionList(this.props.loading.exhibitionId);
        } else {
            this.props.dispatch(getExhibitionId())
                .then(function () {
                    self.setState({
                        exhibitionId: self.props.loading.exhibitionId
                    }, function () {
                        this.getQuestionList(this.props.loading.exhibitionId);
                    })
                })
        }
        this.getexhibitionList()
    }

    handleOk = e => {
        this.setState({
            visible: false,
        });
    };






    getexhibitionList = () => {
        _Api(Url.PROMO_EXHIBITION_DATA.method, Url.PROMO_EXHIBITION_DATA.url)
            .then(resp => {
                let responseData = resp.responseData.exhibitionDetails;
                this.setState({ exhibitionData: responseData, });

            }).catch(err => {
                this.handleError(err);
            });
    }

    getQuestionList = (exhibitionId) => {
        this.setState({ isLoader: true });
        let { page, limit, search, isDownload, sortInfo, userType } = this.state;
        let { fieldName, order } = sortInfo;
        let req = {
            page: page ? page : 1,
            limit: limit,
            search: search.toString(),
            userType: userType
        }
        _Api(Url.GET_EXHIBITION_ID.method, Url.GET_EXHIBITION_ID.url, '')
            .then(resp => {
                _Api(Url.ADMIN_GET_QUESTION_LIST.method, Url.ADMIN_GET_QUESTION_LIST.url, '', `?exhibitionId=${resp?.responseData?.exhibitionDetails?._id}&page=${req.page}&limit=${req.limit}&search=${req.search}`)
                    .then(resp => {
                        if (resp.responseData.file) {
                            window.open(resp.responseData.file, "_blank");
                            this.setState({ isLoader: false, isDownload: 0 });
                        }
                        else {
                            let responseData = resp.responseData.result;
                            //console.log("responseDataresponseData",responseData );

                            this.setState({ userListData: responseData.data, totalCount: responseData.totalCount, isLoader: false })
                        }

                    }).catch((err) => {this.setState({ isLoader: false,      }); })

            }).catch((err) => { this.setState({ isLoader: false, });})


    }

    onClickSorting = (field) => {
        let { order, fieldName } = this.state.sortInfo;
        let orderType = 1;
        if (order && fieldName === field) {
            orderType = order < 0 ? '' : -1;
        }
        this.setState({
            sortInfo: {
                fieldName: field,
                order: orderType
            }
        }, () => this.getQuestionList());
    }

    handlePageChange = (page) => {
        this.setState({ loader: true, page, snNo: page - 1, userListData: [] }, () => {
            this.getQuestionList()
        })
    }

    handleSearch = e => this.setState({ search: e.target.value, page: 1, snNo: 0, userListData: [] }, () => this.getQuestionList());

    showModal = (id) => {
        this.setState({
            visible: true,
            selectedId: id
        });

    };
    //Cancel modal
    handleCancel = () => {
        this.setState({ visible: false });
    };

    handleError(err) {
        this.setState({ isLoader: false });
        let errorMessage = err.error && err.error.responseMessage || err.message;
        notify("err", errorMessage);
    }

    onStatusChange = (e, id) => {
        _Api(Url.QUESTION_STATUS_CHANGE.method, Url.QUESTION_STATUS_CHANGE.url, '', `?questionId=${id}&status=${(e.target.checked ? 1 : 0).toString()}`)
            .then(resp => {
                notify('success', resp.responseData.message);
                this.setState({ visible: false, statusCode: resp.responseData.statusCode });
                this.getQuestionList();
            }).catch(err => {
                this.handleError(err);
            })
    }

    onStatusChange2 = (e, id) => {
        let req = {
            isHide : e == 1 ? 2 : 1,
            questionId:id
        }
        _Api(Url.FILTER_STATUS_CHANGE.method, Url.FILTER_STATUS_CHANGE.url, req)
            .then(resp => {
                notify('success', resp.responseData.message);
                this.setState({ visible: false, statusCode: resp.responseData.statusCode });
                this.getQuestionList();
            }).catch(err => {
                this.handleError(err);
            })
    }

    deleteQuestion = (id) => {
        let req = {
            isDeleted: 0,
            questionId: id,
        }
        _Api(Url.QUESTION_DATA_DELTED.method, Url.QUESTION_DATA_DELTED.url, '', `?questionId=${req.questionId}`)
            .then(resp => {
                console.log('delete', "User deleted successfully");
                notify('success', resp.responseData.message)
                this.setState({ visible: false })
                this.getQuestionList();
            }).catch(err => {
                this.handleError(err);
            })
    }

    exportCSV = () => {
        this.setState({ isDownload: 1 }, () => this.getUserList());
    }

    selectOnChange = (type, e) => {
        let self = this;
        this.setState({
            [type]: e,
        }, function () {
            if (type === "exhibitionId") {
                self.getQuestionList(e);
            }
        })
    }

    handleCancel = () => {
        // console.log('delete', resp.responseData.message)
        this.setState({ visible: false });
    };

    showDeleteConfirm = (id) => {
        confirm({
            title: 'Are you sure you want to delete this?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            onOk: () => {
                this.deleteQuestion(id)
            },
            onCancel: () => {
                this.handleCancel()
            },
        });
    }

    render() {
        let { userListData, search, visible, loading, isLoader, sortInfo, limit } = this.state;
        let { fieldName, order } = sortInfo;
        console.log("this.state", this.props);
        return (
            <>
                <div className="dash_body_container">
                    {/*<Header />*/}
                    {/*<Sidebar />*/}
                    <div className="body_container">
                        {isLoader && <Loader />}
                        <div className="header-container">

                            <div className="d-flex flex-grow-1 align-items-between">
                                <p className="header-icon"><UserManagementIcon /></p>
                                <h4>Questionnaire</h4>
                            </div>

                            <div className="rounded-textbox-form filter-select pr-2">

                                <Select placeholder="Select" name="userType" onChange={(e) => this.selectOnChange("exhibitionId", e)}>
                                    {
                                        this.state.exhibitionData?.map((item, index) =>
                                            <Option value={item._id}>{item.exhibitionName}</Option>
                                        )
                                    }
                                </Select>
                            </div>
                            {/* <div className="search_wrapper mr-3">
                                <i className="fa fa-search" />
                                <input type="search"
                                    placeholder="Search..."
                                    name='search'
                                    value={search}
                                    onChange={this.handleSearch}
                                />
                            </div> */}
                            <Link to="/addQuestionnaire" className="btn btn-red">Add New</Link>


                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered rounded-table">
                                <thead>
                                    <tr>
                                        {columnDetails.map((item) => {
                                            let { isSortable, dataIndex } = item;

                                            return <th><div className="d-flex ">{item.fieldName}</div></th>

                                        })}
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        userListData.map((item, index) => (

                                            <tr>
                                                <td><span className="circle-serial">{(this.state.snNo * limit) + index + 1}</span></td>
                                                <td>{item.question}</td>
                                                <td>{item.exhibitionName}</td>
                                                <td>
                                                    {item.questionType == 1 &&
                                                        <p>Text answer</p>
                                                    }
                                                    {item.questionType == 2 &&
                                                        <p>Multiple choice</p>
                                                    }
                                                    {item.questionType == 3 &&
                                                        <p>Single choice</p>
                                                    }
                                                </td>

                                                {/* <td>{item.phoneNumber && `+${item.phoneNumber}`}</td>                                                */}
                                                <td>{getTimeFormate(item.created)}</td>
                                                <td>
                                                    <p className="toggle-parent ">
                                                        <label className="switch">
                                                            <input type="checkbox"
                                                                checked={item.status == 1 ? true : false}
                                                                onChange={(e) => this.onStatusChange(e, item._id)}

                                                            />
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="toggle-parent ">
                                                        <label className="switch">
                                                            <input type="checkbox"
                                                                checked={item.isHide === 1 ? true : false}
                                                                onChange={(e) => this.onStatusChange2(item.isHide, item._id)}

                                                            />
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </p>
                                                </td>
                                                <td className="action_group">
                                                    <Link to={`/view-questionnaire/${item?._id}`}> <p><p className="circle-serial" ><Edit /></p></p></Link>
                                                    <p onClick={() => this.showDeleteConfirm(item._id)}><p className="circle-serial"><Delete /></p></p>
                                                </td>
                                                <td>

                                                    <Link className="btn btn-small view-answer" to={`/view-questionn-answer-list/${item?._id}`}>View Answer</Link>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>

                            <div>
                                {!isLoader && userListData.length == 0 &&
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                            </div>
                            {
                                userListData != 0 ?
                                    <div >
                                        <Pagination
                                            totalPage={Math.ceil(this.state.totalCount / this.state.limit)}
                                            currentPage={this.state.page}
                                            onPressBtn={this.handlePageChange}
                                        />
                                    </div>

                                    : ''
                            }
                        </div>
                        <Modal
                            visible={visible}
                            title="Delete"
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                            footer={[
                                <Button key="back" onClick={this.handleCancel}>
                                    Cancel
                             </Button>,
                                <Button key="submit" type="primary" loading={loading} onClick={() => this.deleteQuestion(this.state.selectedId)}>
                                    Delete
                            </Button>,
                            ]}
                        >
                            <p>Are you sure you want to delete this ?</p>
                        </Modal>
                    </div>
                </div>

            </>


        );
    }
}
// export default QuestionnaireList;
function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(QuestionnaireList);
