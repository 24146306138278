import React, { Component } from 'react';
import { Pencil, Delete, UploadVideo, ExhibitionManagement, CircleCross } from '../../../../SvgIcon';
import { Link } from "react-router-dom";
import Sidebar from '../../../layout/Sidebar';
import Header from '../../../layout/Header';
import { DatePicker, TimePicker, Select } from 'antd';
import { _Api } from '../../../../services/Api';
import Url from '../../../../services/BaseUrl';
import { notify } from '../../../../components/Toaster';
import InlineError from '../../../../components/InlineError';
import moment from 'moment-timezone';
import Loader from '../../../../components/Loader'
import Croper from "../../../../components/Croper";
import TimeZone from "../../../../utils/timeZone"

const { Option } = Select;

const { RangePicker } = DatePicker

const disabledDate = (current) => {
    // Can not select days before today and today
    return current && moment(current.valueOf()) < moment();
}


class AddLounge extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loungeName: '',
            conferenceId: '',
            loungesUrl: '',
            setOrder: '',
            startDate: moment(),
            endDate: moment(),
            startTime: moment(),
            endTime: moment(),
            numberofAttendees: '',
            loungeNameError: '',
            numberofAttendeesError: '',
            startTimeError: '',
            endDateError: '',
            setOrderError: '',
            isLoader: false,
            isLaungAdd: false,
            streamUrl:'',
            streamUrlError:'',
            isCropper:false,
            timezone: moment.tz.guess(),
        }
    }
    handleOnChange = (type, e) => {
        console.log('qwerty', e)
        this.setState({ [type]: e, setOrderError: "", startDateError: '', endDateError: '', startTimeError: '', endTimeError: "", liveDateError: '', liveTimeError: '' })
    }

    networkingLoungeOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, loungeNameError: '', numberofAttendeesError: '', startTimeError: '', endDateError: '', setOrderError: '' })
    }

    AddNetworkingLounge = () => {
        if (this.state.isLaungAdd)
            this.editLounge()
        else
            this.submitNetworkingLounge()
    }


    componentDidMount() {
        if (this.props.match.params.lgId)
            this.getLoungeViewDetails()

    }


    //     status: 1
    // isDeleted: 1
    // loungeName: "df"
    // setOrder: 4
    // startDate: 1595274460000
    // startTime: "Tue Jul 28 2020 01:17:45 GMT+0530"
    // endDate: 1595879266000
    // endTime: "Tue Jul 28 2020 01:17:51 GMT+0530"
    // conferenceId: "5f1eb033d4cadc4c47c30ebb"
    // numberofAttendees: 345
    // created: "2020-07-27T19:48:23.853Z"
    // updated: "2020-07-27T19:48:23.853Z"


    getLoungeViewDetails = () => {
        _Api(Url.VIEW_LOUNGE.method, Url.VIEW_LOUNGE.url, '', `?loungeId=${this.props.match.params.lgId}`)
            .then(resp => {
                console.log('respexList', resp.responseData.result)
                const {streamUrl,timezone, image, loungeName, loungesUrl, setOrder, startDate, endDate, stageUrl, startTime, endTime, numberofAttendees } = resp.responseData.result
                this.setState({
                    timezone: timezone ? timezone :  moment.tz.guess(),
                    streamUrl,
                    profilePicPreview: image,
                    viewStageDetails: resp.responseData.result,
                    isLaungAdd: true, loungeName, numberofAttendees, loungesUrl: loungesUrl ? loungesUrl : '', setOrder, startDate: moment(new Date(startDate)), endDate: moment(new Date(endDate)), startTime: moment(new Date(startTime)), endTime: moment(new Date(endTime))
                })
            }).catch(err => {              
            })
    }









    submitNetworkingLounge = () => {
        try {
            let {streamUrl, loungeName, conferenceId, loungesUrl, setOrder, startDate, endDate, startTime, endTime, numberofAttendees, media } = this.state
            if (loungeName == '')
                this.setState({ loungeNameError: '*Please enter lounge name.' })           
            // else if (startDate == '')
            //     this.setState({ startDateError: '*Please enter start date.' })
            // else if (startTime == '')
            //     this.setState({ startTimeError: '*Please enter start time.' })
            // else if (endDate == '')
            //     this.setState({ endDateError: '*Please enter end date.' })
            // else if (endTime == '')
            //     this.setState({ endTimeError: '*Please enter end time.' })
            else if (numberofAttendees == '')
                this.setState({ numberofAttendeesError: '*Please enter no of attendee.' })
            else {
                this.setState({ isLoader: true })
                let formData = new FormData()
                formData.append("conferenceId", this.props.match.params.coId)
                formData.append("loungeName", loungeName)
                formData.append("streamUrl", streamUrl)
                // formData.append("setOrder", setOrder)
                formData.append("startDate", (Date.parse(startDate)).toString())
                formData.append("endDate", (Date.parse(endDate)).toString())
                formData.append("startTime", startTime)
                formData.append("endTime", endTime)
                formData.append("image", media)
                formData.append("numberofAttendees", numberofAttendees)
                formData.append( "timezone",this.state.timezone)
                formData.append("exhibitionId", localStorage.getItem('exhibition')) 
                // let req = {
                //     conferenceId: this.props.match.params.coId,
                //     loungeName: loungeName,
                //     loungesUrl: loungesUrl,
                //     setOrder: setOrder,
                //     startDate: (Date.parse(endDate)).toString(),
                //     endDate: (Date.parse(endDate)).toString(),
                //     startTime: startTime,
                //     endTime: endTime,
                //     numberofAttendees: numberofAttendees
                // }
                _Api(Url.ADD_LOUNGE.method, Url.ADD_LOUNGE.url, formData)
                    .then(resp => {                      
                        this.setState({ isLoader: false })
                        this.props.history.goBack()
                        notify('success', resp.responseData.message)
                    }).catch(err => {
                        this.setState({ isLoader: false })
                        if (err.error)
                            notify("err", err.error.responseMessage)
                        else if (err)
                            this.setState({ isLoader: false })
                        // notify("err", err.message)
                    })
            }
        } catch  { }
    }



    editLounge = () => {
        try {
            let {streamUrl, loungeName, conferenceId, loungesUrl, setOrder, startDate, endDate, startTime, endTime, numberofAttendees, media } = this.state
            if (loungeName == '')
                this.setState({ loungeNameError: '*Please enter lounge name.' })
            // else if (setOrder == '')
            //     this.setState({ setOrderError: '*Please enter otder set.' })
            // else if (startDate == '')
            //     this.setState({ startDateError: '*Please enter start date.' })
            // else if (startTime == '')
            //     this.setState({ startTimeError: '*Please enter start time.' })
            // else if (endDate == '')
            //     this.setState({ endDateError: '*Please enter end date.' })
            // else if (endTime == '')
            //     this.setState({ endTimeError: '*Please enter end time.' })
            else if (numberofAttendees == '')
                this.setState({ numberofAttendeesError: '*Please enter no of attendee.' })
            else {
                this.setState({ isLoader: true })
                let formData = new FormData()
                formData.append("loungeId", this.props.match.params.lgId)
                formData.append("loungeName", loungeName)
                formData.append("streamUrl", streamUrl)
                // formData.append("setOrder", setOrder)
                formData.append("startDate", (Date.parse(startDate)).toString())
                formData.append("endDate", (Date.parse(endDate)).toString())
                formData.append("startTime", startTime)
                formData.append("endTime", endTime)
                formData.append("image", media)
                formData.append("numberofAttendees", numberofAttendees)
                formData.append( "timezone",this.state.timezone)
                // let req = {
                //     conferenceId: this.props.match.params.coId,
                //     loungeName: loungeName,
                //     loungesUrl: loungesUrl,
                //     setOrder: setOrder,
                //     startDate: (Date.parse(endDate)).toString(),
                //     endDate: (Date.parse(endDate)).toString(),
                //     startTime: startTime,
                //     endTime: endTime,
                //     numberofAttendees: numberofAttendees
                // }
                _Api(Url.EDIT_LOUNGE.method, Url.EDIT_LOUNGE.url, formData)
                    .then(resp => {                     
                       
                        this.setState({ isLoader: false })
                        this.props.history.goBack()
                        notify('success', resp.responseData.message)
                    }).catch(err => {
                        this.setState({ isLoader: false })
                        if (err.error)
                            notify("err", err.error.responseMessage)
                        else if (err)
                            this.setState({ isLoader: false })
                        // notify("err", err.message)
                    })
            }
        } catch  { }
    }
    
    handleImageUpload = (event) => {
        console.log(event, "eventeventevent", event.target.files[0])
        let reader = new FileReader();
        let file = event.target.files[0];
        let { valiDate } = this.state;
        reader.addEventListener("load", () => {
            this.setState({ profilePicPreview: reader.result }, function () {
            })
        }, false);
        if (file) {
            reader.readAsDataURL(file)
        }
        if (file) {
            console.log(event, "media1", event.target.files[0])
            if (file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png' || file.type === 'image/svg+xml') {
                console.log("media2", event.target.files[0], file.size)
                if (file.size > 2000000) {
                    console.log("lkjhgfdsdfghjklkjhgfghjkmnbvccvbnm>>>>>>>>>>>>>>>>>>")
                    valiDate = false;
                    console.log(event, "media", event.target.files[0])
                    this.setState({
                        media: event.target.files[0],
                        profilePicErrorMsg: 'Profile pic size should be less than 5Mb.'
                    });
                } else {
                    console.log("media3hhhjshs", event.target.files[0], file.size)
                    this.setState({
                        media: event.target.files[0],
                        mediaError: ''
                    });
                    // alert("wrong file")
                }
            } else {
                valiDate = false;
                this.setState({ media: 'File type not supported.' })
            }
        }
    }

    onChangeDate = (value, dateString) => {      
        if (value)
            this.setState({ startDate: value[0] , startTime: value[0],  endDate: value[1], endTime: value[1] }, () => {
                console.log("abcdefghij", this.state.startDate, this.state.endDate)
            })
    }

    handleChangeTimeZone = (value) => {
        // moment.tz.setDefault(value)
       console.log("dkjhasghdjk",  moment.tz(this.state.startDate, this.state.timezone).utcOffset() )
        this.setState({ timezone: value })
    }


    finalCropedImage = (categoryImgPrev, categoryImg, visibleVal) => {
        this.setState({
            visibleVal,
            isCropper: false,
            profilePicPreview: categoryImgPrev,
            media: categoryImg

        })
      
    }

    render() {
        console.log("pojhvbsdnnm", this.state.media)
        let {streamUrl,  isLoader, loungeName, loungeNameError, conferenceId, loungesUrl, setOrder, setOrderError, startDate, startDateError, endDate, endDateError, startTime, startTimeError, endTime, endTimeError, numberofAttendees, numberofAttendeesError } = this.state
      
       
        const disabledDateEnd = (current) => {
            // Can not select days before today and today
            return current && current.valueOf() < startDate;
        }
        return (
            <>
                <div className="dash_body_container">
                    {isLoader && <Loader />}
                    <div className="body_container">
                        <div className="header-text  mb-4 mt-3">
                            <div className="d-flex align-items-center">
                                <p className="header-icon"><ExhibitionManagement /></p>
                                   <h4>{this.state.isLaungAdd ? "Edit Lounge Detail" : "Add Lounge Detail"}
                                    <br />
                                    {/* <ul className="header-breadcrum">
                                        <li><a href="#">Exhibition Management</a>/</li>
                                        <li><a href="#">Lounge Detail</a></li>
                                    </ul> */}

                                </h4></div>

                            <a > <button onClick={() => this.props.history.goBack()} className="btn btn-light"><i class="fa fa-arrow-left" aria-hidden="true"></i>Back</button></a>
                        </div>
                        <div className="rounded-body-form">

                            <form className="cm-form-wrapper rounded-textbox-form">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <level>Lounge Name</level>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter Lounge Name"
                                                maxLength={64}
                                                name="loungeName"
                                                value={loungeName}
                                                onChange={this.networkingLoungeOnChange}
                                            />
                                            <InlineError
                                                message={loungeNameError}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <level>Allowed No. of attendees</level>
                                            <input type="number"
                                                className="form-control"
                                                placeholder="Enter Number"
                                                maxLength={64}
                                                name="numberofAttendees"
                                                value={numberofAttendees}
                                                onChange={this.networkingLoungeOnChange}
                                            />
                                            <InlineError
                                                message={numberofAttendeesError}
                                            />

                                        </div>
                                    </div>

                                    {/* <div className="col-md-6"> */}
                                        {/* <div className="form-group">
                                            <level>Set Order</level>
                                           
                                            <Select
                                                name='setOrder'
                                                value={setOrder}
                                                placeholder="Select"
                                                onChange={(e) => this.handleOnChange("setOrder", e)}
                                            >
                                                <Option value="1">1</Option>
                                                <Option value="2">2</Option>
                                                <Option value="3">3</Option>
                                                <Option value="4">4</Option>
                                                <Option value="5">5</Option>
                                            </Select>
                                            <InlineError
                                                message={setOrderError}
                                            />
                                        </div> */}






                                    {/* </div> */}
                                </div>
                                <div className="row">
                                <div className="col-md-6">
                                        <level>Time Zone</level>

                                        <div className="form-group">
                                            <Select showSearch autoComplete="new-password" placeholder="Time zone"
                                                value={this.state.timezone}
                                                defaultValue={this.state.timezone}

                                                onChange={(e) => this.handleChangeTimeZone(e)} name='country'

                                            >
                                                {TimeZone.map((item) => {
                                                    return (<Select.Option value={item}>{item}</Select.Option>)
                                                })}

                                            </Select>

                                        </div>

                                    </div>
                                    <div className="col-md-6">
                                        <level>Start, End date and time</level>



                                        <div className="form-group">
                                            <RangePicker
                                                showTime={{ format: 'HH:mm' }}    
                                                inputReadOnly                                            
                                                value={[moment.tz(startDate, this.state.timezone), moment.tz(endDate, this.state.timezone)]}
                                                format="DD-MM-YYYY HH:mm"
                                                onChange={this.onChangeDate}
                                            // onOk={onOk}
                                            />
                                            <InlineError
                                                message={startDateError}
                                            />
                                        </div>




                                    </div>
                                </div>

                                <div className="row">
                                    {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <level>Stream url</level>
                                            <input
                                            //  type="number"
                                                className="form-control"
                                                placeholder="Enter stream url"                                                
                                                name="streamUrl"
                                                value={streamUrl}
                                                onChange={this.networkingLoungeOnChange}
                                            />
                                           
                                        </div>
                                    </div> */}

                                    

                    
                                    <div className="col-md-6">
                                        {/* <span className="or-label">Or</span> */}
                                        <level>Cover image</level>
                                        <div className="form-group custom-upload-file">
                                            <ul className="list-inline mt-3 pt-1">
                                                <li className="list-inline-item">
                                                    <div className="upload-inner" onClick={() => this.setState({isCropper: true})} >
                                                        {
                                                            this.state.profilePicPreview ?
                                                                <img onClick={() => this.setState({isCropper: true})} src={this.state.profilePicPreview} />
                                                                : <span className="upload-icon"><UploadVideo /></span>
                                                        }

                                                        {/* <input ref="fileUploader" type="file" className="d-block" placeholder="Enter Video Link"
                                                            onChange={this.handleImageUpload}

                                                        /> */}
                                                    </div>
                                                    {/* <level>Maximum Size : 128 gb</level> */}
                                                </li>


                                            </ul>
                                        </div>


                                        <div className="add-video">
                                        </div>
                                    </div>
                                </div>

                                <div className="text-right">
                                    {/* <Link to="/seminardetails" onClick={this.AddNetworkingLounge} className="btn btn-primary">Save</Link> */}
                                    <button type='button' onClick={this.AddNetworkingLounge} className="btn btn-primary">Save</button>
                                </div>
                            </form>

                            {this.state.isCropper ?
                    <Croper aspect={2.5 / 2.5}
                        // onImageCrop={img => this.setState({ media: img })}
                        imgPrev={"1-1.jpg"}
                        finalCropedImage={(categoryImgPrev, categoryImg, visibleVal) => this.finalCropedImage(categoryImgPrev, categoryImg, visibleVal)} visibleVal={this.state.isCropper}
                        onClose={() => this.setState({ isCropper: false })}
                    />
                    : ""}


                        </div>

                    </div>
                </div>
            </>
        );
    }
}
export default AddLounge;