import React, { Component } from 'react';

import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { Empty } from 'antd';
import { Calender, Clock, Hert, Ticket, TwoUsers, DownArrow, NoMediaICon } from "../../../SvgIcon";
import moment from "moment"
import ReactPlayer from 'react-player';

import { Pagination } from '../../../components/common/Pagination';

import WebHeader from '../../layout/WebHeader';
import _ from "lodash"

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import Footer from "../../layout/Footer";

import Media from "../../../components/common/WebMedia"

import { Link } from "react-router-dom"
import { getTimeZone } from "../../../utils/DateZone"
import { connect } from "react-redux";



import { Player, BigPlayButton } from 'video-react';

import { Modal, Button, Space } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;




const antIcon = <LoadingOutlined style={{ fontSize: 72 }} spin />;

let isProd = process.env.REACT_APP_ENV == 'prod'

class ConferenceDetail extends Component {


    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.carousel = React.createRef();

        this.state = {
            isCommingSoon: true,
            isfree: true,
            conferenceDetail: '',
            isLoading: true,
            stagList: [],
            loungList: [],
            speakersList: [],
            timetableList: [],
            visible: false,
            password: '',
            passwordError: '',
            noOfDays: 1,
            dayStatus: [],
            npOfConference: 0,
            globalLoader: false,
            tab: 0,
            tabData: [],
            allTimeTableData: [],
            mediaData: [],
            snNo: 0,
            page: 1,
            limit: 20,
            visible: false,
            selectedItem: '',
            showUrl:''
        }

        window.scrollTo(0, 0)

    }


    next = () => {
        this.carousel.next();
    }
    previous = () => {
        this.carousel.prev();
    }

    componentDidMount() {
       
        this.props.dispatch({ type: 'PATH_ADD', payload: this.props.match })

        if (this.props.match.params.pageNo && !isNaN(this.props.match.params.pageNo)) {
            this.setState({ page: Number(this.props.match.params.pageNo), snNo : Number(this.props.match.params.pageNo) -1 },()=>{
                this.getConferenceDetail();
            })
        }
        else{
            this.getConferenceDetail();
        }
    }

    removeDuplicates = (data) => {

        // Create an array of objects 


        // Display the list of array objects 
        console.log(data);

        // Declare a new array 
        let newArray = [];

        // Declare an empty object 
        let uniqueObject = {};

        // Loop for the array elements 
        for (let i in data) {

            // Extract the title 
            let objTitle = moment(data[i]['startDate']).format('DD-MM-YYYY')

            // Use the title as the index 
            uniqueObject[objTitle] = data[i];
        }

        // Loop to push unique object into array 
        for (let i in uniqueObject) {
            newArray.push(uniqueObject[i]);
        }

        // Display the unique objects 
        this.setState({ tabData: _.sortBy(newArray, 'startDate') }, () => {
            this.getTimeTableAccordingToDate(data, this.state.tabData[0]?.startDate)
        })
        console.log("newArray====>", newArray);
    }


    getTimeTableAccordingToDate = (data = [], search) => {

        this.setState({ timetableList: data.filter(item => moment(item.startDate).format('MM-DD-YYYY') == moment(search).format('MM-DD-YYYY')) })


    }


    getConferenceDetail = () => {
        let { page, limit, search } = this.state

        let req = {
            page: page ? page : 1,
            limit: limit,
        }

        _Api(Url.MEDIA_ANALYSIS.method, Url.MEDIA_ANALYSIS.url, '',`?page=${page}&limit=${limit}`)
            .then(resp => {
                console.log('xnskadklsajdnasdm', resp.responseData)
                const { totalCount, data } = resp.responseData?.result
                    ;


                this.setState({
                    timetableList: data,
                    totalCount,
                    isLoading: false
                })


            }).catch(err => {
                this.setState({ globalLoader: false, isLoading: false })

            })

    }

    onlyJoin = (name, id, coId) => {

        if (name == "lounge") {
            //this.props.history.push(`/lounge/${id}`)
            window.open('/lounge/' + id, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
        }
        else
            this.props.history.push(`/seminar/${coId}/${name}/${id}`)

    }

    showModal = () => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {
        console.log(e);
        this.setState({
            visible: false,
            selectedItem: null, visible: '', showUrl:''
        });
    };

    onPressBtn = (page) => {
        this.props.history.push(`/media-viewed/${page}`)
        this.setState({ page, snNo: page - 1, timetableList: [] }, () => {
            this.getConferenceDetail()
        })

    }

    getName = (arr) => {
        return arr[0]?.url
    }

    playModal = (media, url) => {
        console.log("dlfkjhbjfm", media)
        let obj = media ? media[0] : null
        this.setState({ selectedItem: obj, visible: true, showUrl : url })

    }




    render() {
        const { globalLoader, timetableList, visible, confirmLoading, ModalText, selectedItem, isFlag } = this.state;

        if (globalLoader)
            return (
                <section className="brand-home-banner-bg webhome">
                   
                    <div className="centerLoader" >
                        <Spin size="large" tip="Loading..." indicator={antIcon} />
                    </div>
                </section>

            )

        else
            return (
                <section className="Home-banner-bg">                  
                 
                    <div className={this.state.isCommingSoon ? " container-fluid brandhome pos-relative" : " container-fluid"}>
                        
                        <div className="cm-wrapper confreance-detail-parent">

                            <div className="">
                                <h1 className="home-heading pos-relative pt-5 text-center">Media Viewed
                                  <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                </h1>


                                <div class="table-responsive">
                                    <table class="table table-bordered rounded-table event-table">
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Media Url</th>
                                                <th>User Name</th>                                            
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                timetableList.map((item, index) => {

                                                    let media = item?.mediaData?.media[0]
                                                    console.log("thumbUrl", media)

                                                    return (
                                                        <tr>
                                                            <td><span className="circle-serial">{(this.state.snNo * 20) + index + 1}</span></td>
                                                            <td>
                                                            {
                                                 media?.urlType  ?
                                                    <div className="card video-card sm-videoCard">
                                                      
                                                        <img
                                                             onClick={()=> this.playModal(item?.mediaData?.media)}
                                                            src={media?.thumbUrl}
                                                        />
                                                    </div>
                                                    :
                                                    <div className="video-parent media-viewed" >  
                                                    <ReactPlayer width="40" height="30" url={item?.mediaData?.brandUrl[0]} />
                                                    <div className="layerdiv" onClick={()=> this.playModal(null, item?.mediaData?.brandUrl[0])} ></div>
                                                    </div>
                                                    // <div className="card video-card sm-videoCard">                                                      
                                                    //     <img
                                                    //          onClick={()=> this.playModal(item?.mediaData?.media)}
                                                    //         src={media?.thumbUrl}
                                                    //     />
                                                    // </div>
                                                    // :
                                                    // <div className="card video-card">
                                                       
                                                    //     <div className="video-parent " >                                                         

                                                    //         {/* <ReactPlayer width="442" height="390" url={brandUrl[0] ? brandUrl[0] : conferenceUrl[0] ? conferenceUrl[0] : exhibitionUrl[0] ? exhibitionUrl[0] : ''} /> */}

                                                           
                                                    //     </div>
                                                    //     <div className="layerdiv" onClick={() => this.onClickItem(item, index)} ></div>


                                                    // </div>
                                               
                                            }
                                                            </td>
                                                            {/* <td onClick={()=> this.playModal(item?.mediaData?.media)}> {this.getName(item?.mediaData?.media)}</td> */}
                                                            <td>
                                                            <Link to={`/user/profile/${item?.userData?._id}`}> <p>{item?.userData?.firstName} {item?.userData?.lastName}</p> </Link>
                                                            </td>

                                                            <td>
                                                                <div className="d-flex align-items-center justify-content-around">
                                                                    <span className="mr-3">{moment(new Date(item?.mediaLogData?.created)).format("DD/ MM/ YYYY")}</span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>

                                    </table>
                                    <div>
                                        {!this.state.isLoading && timetableList.length == 0 &&
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                                    </div>
                                    {
                                        timetableList.length != 0 &&
                                        <Pagination
                                            totalPage={Math.ceil(this.state.totalCount / 20)}
                                            currentPage={this.state.page}
                                            onPressBtn={this.onPressBtn}
                                        />
                                    }
                                </div>
                                <div>

                                </div>

                            </div>
                            <br />

                            <br />
                        </div>
                        

                    </div>
                    <Footer />

                    <Modal
                        visible={visible}
                        onCancel={this.handleCancel}
                        footer={null}
                        destroyOnClose={true}
                        centered
                        className="media-popup"
                    >
                        <div className="col-md-10 offset-md-1">
                            {/* {this.state.selectedIndex != 0 && <div className="prev" onClick={() => this.goBack(this.state.selectedIndex, mediaData)}>
                                <i class="fa fa-arrow-circle-o-left" aria-hidden="true"></i>
                            </div>} */}
                            {

                                selectedItem?.urlType == 'I' ?
                                    <div className="card video-card">
                                        <img
                                            src={selectedItem?.url}
                                            className="img-fluid" />
                                    </div>
                                    :
                                    selectedItem?.urlType == 'V' ?
                                        <div className="card video-card">
                                            <Player
                                            >
                                                <source src={selectedItem?.url} />
                                                <BigPlayButton position="center" />
                                            </Player>
                                        </div>
                                        :
                                        <div className=" mb-0">
                                            <div className="card video-card">
                                                <ReactPlayer
                                                    playing={this.state.playing}
                                                    url={this.state.showUrl} />
                                            </div>
                                        </div>
                            }
                            {/* {this.state.selectedIndex + 1 != mediaData?.length && <div className="next">
                                <i onClick={() => this.goNext(this.state.selectedIndex, mediaData)} class="fa fa-arrow-circle-o-right" aria-hidden="true"></i>
                            </div>} */}

                        </div>
                    </Modal>



                </section>
            )
    }

}
// export default ConferenceDetail;
function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(ConferenceDetail);
