
import React, { useMemo } from 'react';
import classNames from 'classnames';

const stepBarMapper ={
    exhibition : ['Exhibition Detail', 'Seminars Detail', 'Networking Lounge', 'Businesses'],
    userProfile : ['Profile', 'Work']
}

const ProgressStepBar = (props) => {
    const { step, allStep, mapper } = props
    const stepInfo = useMemo(() => {
        let stepMapper = stepBarMapper[mapper];
        return  stepMapper.map((stepName, index) =>  {
            let stepNum = index+1;
            let isFirst = index === 0;
            let isLast = index === stepMapper.length -1;
            return <div className={`status-process-step ${(step == stepNum || allStep) ? 'complete' : ''} `}>
                {(isFirst || isLast) && <i className={classNames("fa fa-circle", {'first': isFirst}, {'last': isLast})} aria-hidden="true"></i>}
                <div className={`${(step > stepNum || allStep) ? 'success-circle complete' : "process-circle active"}`} >
                    {stepNum}
             </div>
                <p>{stepName}</p>
        </div>
        })
    }, [step, allStep]);
    return (
        <div className="order-status-process">
            {stepInfo}
        </div>
    )
}

export default ProgressStepBar;