import React from 'react'
import { CircleCross , Pencil} from '../../../../SvgIcon';
import { _Api } from '../../../../services/Api';
import Url from '../../../../services/BaseUrl';
import { notify } from '../../../../components/Toaster';
import InlineError from '../../../../components/InlineError';
import { Select } from 'antd'

import { MySpinner } from "../../../../components/index"

import Croper from "../../../../components/Croper";

const { Option } = Select;
class Brochures extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            productName: '',
            description: '',
            productImage: '',
            productNameError: '',
            descriptionError: '',
            productImageError: '',
            productCategoryError: '',
            productCategory: '',
            page: '',
            limit: 10,
            isLoading: false,
            price: '',
            fileError:'',
            isCropper: false,

        }
    }
    productOnChnage = (e) => {
        this.setState({ [e.target.name]: e.target.value, productNameError: '', descriptionError: '', fileError:'' })
    }
    handleOnChange = (e) => {
        this.setState({ productCategory: e, productCategoryError: '', fileError:'' })
    }

    componentDidMount() {
        // this.getProductDetails()
    }

    // getProductDetails = () => {
    //     this.setState({ isLoader: true })
    //     let { page, limit, search } = this.state
    //     let req = {
    //         page: page ? page : 1,
    //         limit: limit,
    //     }
    //     _Api(Url.GET_PRODUCT_LIST.method, Url.GET_PRODUCT_LIST.url, '', `?page=${req.page}&limit=${req.limit}`)
    //         .then(resp => {
    //             console.log('respexList', resp.responseData.result.data)
    //             this.setState({ productListData: resp.responseData.result.data, totalCount: resp.responseData.result.totalCount, isLoader: false })
    //         }).catch(err => {
    //             this.setState({ isLoader: false })
    //             if (err.error)
    //                 notify("err", err.error.responseMessage)
    //             else if (err)
    //                 // notify("err", err.message)
    //             this.setState({ isLoader: false })
    //         })
    // }

    addProduct = () => {
        let { productName, description, productImage, productCategory, price, media, docFile } = this.state
        if (productName === '')
            this.setState({ productNameError: '*Please enter brochure name.' })
        else if (docFile == '' || docFile == undefined )
            this.setState({ fileError: '*Please select a file' })
        // else if (description === '')
        //     this.setState({ descriptionError: '*Please enter description.' })
        else {
            this.setState({ isLoading: true })
            let formData = new FormData()
            formData.append('name', productName)
            formData.append('brandId', this.props.match.params.brandId)
            formData.append('coverImage', productImage)
            formData.append('media', docFile)
            _Api(Url.ADD_BROCHURE.method, Url.ADD_BROCHURE.url, formData)
                .then(resp => {
                    this.setState({ isLoading: true })
                    // this.props.getProductDetails()
                    this.props.closePopup()

                    notify('success', resp.responseData.message)
                }).catch(err => {
                    this.setState({ isLoading: true })
                    if (err.error)
                        notify("err", err.error.responseMessage)
                   // else if (err)
                //     // notify("err", err.message)
                })
        }
    }

   

    uploadDoc = (event) => {
        this.setState({fileError:''})
     
        let reader = new FileReader();
        let file = event.target.files[0];

        console.log("typeeeeeeee", file)

        const type = file?.type?.split('/')[0] 
       
        if (file) {
            console.log(event, "media1", event.target.files[0])
            if (file.type != "application/vnd.android.package-archive" && type == "application"  ) {
                // console.log("media2", event.target.files[0], file.size)
                this.setState({
                    docFile: file
                })
                console.log("eventeventevent", file )
                
            } else {
                alert("File type not supported.'")
                this.setState({ mediaError: 'File type not supported.' })

            }
        }
    }


    finalCropedImage = (categoryImgPrev, categoryImg, visibleVal) => {       
        this.setState({
            visibleVal,
            isCropper: false,
            profilePicPreview: categoryImgPrev,
            productImage: categoryImg

        })
    }

    render() {
        let { docFile,productName,fileError, description, productNameError, descriptionError, productCategoryError, productCategory } = this.state
    //    console.log("lkdjnfbhgsh", docFile)
      
        return (
            <div className='popup'>
                <div className='popup_inner small form brochure_parent'>
                    <div className="mb-4 mt-4">
                        <div className="rounded-textbox-form" >

                            <div className="header-text">
                                <h4>Add Document</h4>
                            </div>
                            {/* <label className="mb-0 mt-3">Cover Image <span className="popupdimention"> */}
                                 {/* ( Image dimension: 100 * 100 )  */}
                                 {/* </span></label>   */}

                                 <div className="upload-wrapper pos-relative" onClick={()=> this.setState({isCropper:true})}>
                                <label className="upload-photo-wrapper upload-container-pic">                              
{this.state.profilePicPreview ? <span> <img className="img-fluid" src={this.state.profilePicPreview} /></span> 

 : 
<><span> 
    <div className="photo-placeholder"></div>
    {/* <img className="img-fluid" src={require('../../../../assets/images/placeholder.png')} /> */}
<div class="brochure_placholder" ><h6>upload <br/>Thumbnail</h6></div>
</span>
</> 
} 
{/* <div className="upload-wrapper edit-icon">
                                        <span className="upload-photo-wrapper" > <p className="m-0"><i><Pencil /></i></p></span>
                                    </div> */}
</label>                      </div>

                            <div className="form-group">
                                <level>Document Name</level>
                                <input type="text"
                                    className="form-control"
                                    placeholder="Document Name"
                                    maxLength={64}
                                    autoComplete="off"
                                    name="productName"
                                    value={productName}
                                    onChange={this.productOnChnage}
                                />
                                <InlineError
                                    message={productNameError}
                                />

                            </div>
                            <div className="">
                                <level>Select file (.pdf, .doc)&nbsp;<b>Max size 2MB</b>
                                <input
                                    id="file-upload"
                                    type="file"
                                    accept="application/pdf,application/msword,
                                    application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    ref={my_file => this.inputElement = my_file}
                                    onChange={this.uploadDoc} />
                                    </level>
                                    <input type="text"
                                    style={{cursor:'pointer', caretColor: 'transparent'}}
                                    className="form-control"
                                    placeholder={docFile ? docFile?.name : "Brochure file"}
                                    maxLength={64}
                                    autoComplete="off"
                                    name="productName"
                                    value={''}
                                   
                                    // readOnly
                                    onClick={() => this.inputElement.click()}
                                />                                  


                                <InlineError
                                    message={fileError}
                                />
                            </div>



                            <div className="text-center pt-4">
                                <button className="btn btn-red" onClick={this.addProduct} disabled={this.state.isLoading}>Save
                                {this.state.isLoading && <MySpinner />}
                                </button>
                            </div>

                            <button className="close-btn" onClick={this.props.closePopup}><CircleCross /></button>


                        </div>
                    </div>
                </div>
                {this.state.isCropper ?
                    <Croper aspect={1 / 1}
                        // onImageCrop={img => this.setState({ media: img })}
                        cropShape="round"
                            imgPrev={'1-1.jpg'}
                        finalCropedImage={(categoryImgPrev, categoryImg, visibleVal) => this.finalCropedImage(categoryImgPrev, categoryImg, visibleVal)} visibleVal={this.state.isCropper}
                        onClose={() => this.setState({ isCropper: false })}
                    />
                    : ""}
            </div>
        );
    }
}

export default Brochures