import React, { Component } from 'react';
import { Tabs } from 'antd';
import { Link } from "react-router-dom";
import { UserManagementIcon, Pencil, Delete, Edit } from '../../../SvgIcon';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { getTimeFormate } from "../../../utils/DateZone"
import { Empty, Modal } from 'antd';
import { Pagination } from '../../../components/common/Pagination';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
    MyAlert,
    MySpinner,
    AdLoader
} from "../../../components/index"


import ManageSubscription from './ManageSubscription';
const { TabPane } = Tabs;
const { confirm } = Modal;


class SubscriptionPlan extends Component {
    constructor() {
        super()
        this.state = {
            order: 1,
            page: 1,
            limit: 10,
            loading: false,
            search: '',
            list: [],
            totalCount: 0,
            snNo: 0,
            selectedItems: [],
        }
    }

    callback = (key) => {
        console.log(key);
    }
    componentDidMount() {
        this.getList()
    }

    getList = (isShowLoader = true) => {
        this.setState({ isLoader: isShowLoader })
        let { page, limit, search } = this.state
        let req = {
            page: page ? page : 1,
            limit: limit,
            search: search.toString()
        }
        _Api(Url.PLAN_LIST.method, Url.PLAN_LIST.url, '', `?page=${req.page}&limit=${req.limit}&search=${req.search}`)
            .then(resp => {
                console.log('planList', resp.responseData.result)
                this.setState({ totalCount: resp.responseData.result.totalCount, list: resp.responseData.result.data })

            }).catch(err => {
                this.setState({ isLoader: false })


            })
    }


    onPressBtn = (page) => {
        this.setState({ page, snNo: page - 1, list: [] }, () => {
            this.getList()
        })

    }

    // delete modal
    showDeleteConfirm = (id) => {
        confirm({
            title: 'Are you sure you want to delete this?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            onOk: () => {
                this.deleteAd(id)
            },
            onCancel: () => {
                this.handleCancel()
            },
        });
    }
    handleCancel = () => {

    }


    deleteAd = (id) => {
        let req = {

            planId: id,

        }
        this.setState({ visible: false })
        _Api(Url.DELETE_PLAN.method, Url.DELETE_PLAN.url, req)
            .then(resp => {
                // notification.success({ message: 'Success', description: resp.responseData.message }); this.setState({ visible: false })
                this.getList()
            }).catch(err => {
                this.setState({ visible: false })
                // if (err.error)
                // notification.error({ message: 'Error', description: err.error.responseMessage });
                // else if (err)
                // notification.error({ message: 'Error', description: err.message });
            })
    }


    statusAd = (e, id) => {
        let oldArr = this.state.selectedItems
        oldArr.push(id)
        this.setState({ selectedItems: oldArr })

        let req = {
            planId: id.toString(),
            status: (e.target.checked ? 1 : 0),
        }
        _Api(Url.STATUS_OPTION_PLAN.method, Url.STATUS_OPTION_PLAN.url, req)
            .then(resp => {
                // notification.success({ message: 'Success', description: resp.responseData.message });
                this.setState({ visible: false, selectedItems: this.state.selectedItems.filter(item => item != id) })
                this.getList(false)
            }).catch(err => {
                this.setState({ visible: false, selectedItems: this.state.selectedItems.filter(item => item != id) })
                // if (err.error)
                    // notification.error({ message: 'Error', description: err.error.responseMessage });
                // else if (err)
                    // notification.error({ message: 'Error', description: err.message });

            })

    }


    handleSearch = e => this.setState({ search: e.target.value, page: 1, list: [], snNo:0 }, () => this.getList());


    render() {
        const {selectedItems} = this.state
        return (
            <>
                <div className="dash_body_container">
                    <div className="body_container">
                        <div className="header-container">
                            <div className="d-flex flex-grow-1 align-items-center">
                                <p className="header-icon"><UserManagementIcon /></p>
                                <h4>Subscription Plan</h4>
                            </div>
                        </div>
                        <div className="subscrptn-body">
                            <Tabs defaultActiveKey="1" onChange={this.callback}>
                                <TabPane tab="Plans" key="1">
                                    <div className="addNewPlans-tab">
                                        <div className="search_wrapper  mr-2">
                                            <i className="fa fa-search" />
                                            {/* <input type="search"
                                                placeholder="Search by name"
                                                name='search'
                                                placeholder="Search..."
                                                onChange={this.handleSearch}
                                            /> */}
                                        </div>
                                        {/* <Link to={`/add-subscription-plan`} className="btn btn-red">Add New</Link> */}
                                    </div>
                                    <div className="pnls-bodywrp py-2 px-3">
                                        <div class="table-responsive">
                                            <table class="table table-bordered rounded-table">
                                                <thead>
                                                    <tr>
                                                        <th>S.No.</th>
                                                        <th className="tc">Name</th>
                                                        <th>Type</th>
                                                        <th className="text-center tc">Pricing</th>
                                                        <th className="text-center tc">Created Date</th>
                                                        <th className="text-center tc">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.list?.map((item, index) => {
                                                            return (
                                                                <tr>
                                                                    <td>{(this.state.snNo * 10) + index + 1}</td>
                                                                    <td className="tc">{item.planName}</td>
                                                                    <td>{item.planType == 0 ? "Free" : "Payable"}</td>
                                                                    <>
                                                                        {
                                                                            item.planType == 0 ? <td>Free</td> :
                                                                            <td className="tc"> {item.defaultCurrency == 0 ?  `$${item.dollarAmount}` :  `£${item.euroAmount}`} </td>
                                                                                 

                                                                        }
                                                                    </>



                                                                    <td className="tc">{getTimeFormate(item.created)}</td>
                                                                    <td className="action_group pln-sub-tbl text-center">
                                                                        {
                                                                            selectedItems.indexOf(item._id) == -1 ?
                                                                                <p className="toggle-parent ">
                                                                                    <label className="switch">
                                                                                        <input type="checkbox"
                                                                                            checked={item.status === 1 ? true : false}
                                                                                            onChange={(e) => this.statusAd(e, item._id)}
                                                                                        />
                                                                                        <span className="slider round"></span>
                                                                                    </label>

                                                                                </p>
                                                                                :
                                                                                <MySpinner />
                                                                        }
                                                                        {/* <a><p className="circle-serial"><Edit /></p></a> */}
                                                                        <Link to={`/edit-subscription-plan/${item._id}`}> <p><p className="circle-serial" ><Pencil /></p></p></Link>
                                                                        <a onClick={() => this.showDeleteConfirm(item._id)} ><p className="circle-serial"><Delete /></p></a>

                                                                    </td>
                                                                </tr>

                                                            )
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                            {!this.state.loading && this.state.list.length == 0 &&
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}

                                        </div>

                                        {
                                            this.state.list.length != 0 &&
                                            <Pagination
                                                totalPage={Math.ceil(this.state.totalCount / 10)}
                                                currentPage={this.state.page}
                                                onPressBtn={this.onPressBtn}
                                            />
                                        }
                                    </div>
                                </TabPane>
                                <TabPane tab="Manage Subscriotion" key="2">
                                    <ManageSubscription />
                                </TabPane>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default SubscriptionPlan;