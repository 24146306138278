import React, { Component } from 'react';
import { Brands, Hert, ZoomVideo, Delete, PhotoUploadIcon, Chat, Pencil, NoMediaICon } from '../../../SvgIcon';
import { BtnRed, CmBtn } from "../../../components/common/Button";
import ProductShowcase from "../../../components/common/ProductShowcase";
import OfferShowCase from "../../../components/common/OfferShowcase";
import Media from "../../../components/common/Media";
import Footer from "../../layout/Footer"
import Brochures from "../../../components/common/Brochures";
import { connect } from "react-redux";
import { getWishList } from '../../../redux/action';
import { Tooltip } from "antd";
import ContentEditable from "react-contenteditable";
import { Link } from "react-router-dom";
// import { BtnRed, LiveBtn } from "../../../../components/common/Button";
// import Sidebar from '../../../layout/Sidebar';
// import Header from '../../../layout/Header';
import AddStaff from '../../admin/exhibiton_management/popups/AddStaff'
import EditStaff from '../../admin/exhibiton_management/popups/EditStaff'
import EditProduct from '../../admin/exhibiton_management/popups/EditProduct';

// import { Modal, Button } from 'react-bootstrap';
import AddProduct from '../../admin/exhibiton_management/popups/AddProduct';
import AddOffer from '../../admin/exhibiton_management/popups/AddOffer';
// import ReactPlayer from 'react-player';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';
// import Media from "../../../../components/common/Media"
// import Brochures from "../../../../components/common/Brochures"
// import ProductShowcase from "../../../../components/common/ProductShowcase";
// import MediaCarousel from "../../../../components/carousel/MediaCarousel";
// import IndustrieCarousel from "../../../../components/carousel/IndustrieCarousel";

import Loader from "../../../components/Loader"

import { MySpinner } from "../../../components/index"

import { Empty, Menu, Dropdown } from "antd"

import { Modal, Button, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Spinner } from "react-bootstrap";
import socket from "../../../services/socket";
import { groupActions } from "../../../redux/action";

import Croper from "../../../components/Croper";

import Const from "../../../services/Const"


const { confirm } = Modal;

const {manageProfile} = Const

let isProd = process.env.REACT_APP_ENV == 'prod'

class BrandProfile extends Component {

    constructor() {
        super();
        this.state = {
            addStaff: false,
            addProduct: false,
            addOffer: false,
            editStaff: false,
            page: '',
            limit: 10,
            productListData: [],
            StaffListData: [],
            staffId: '',
            loading: true,
            visible: false,
            selectedId: '',
            productId: '',
            brandInfo: '',
            about: '',
            isEdit: false,
            mediaData: [],
            coverLoader: false,
            logoLoader: false,
            adsList: [],
            loaderMedia: false,

            selectedItems: [],
            selectStaff: [],
            isCropper: false,
            copperType: '',
            offerData: [],
            userData: {},
            html: "Edit <b>me</b> !"

        };

        window.scrollTo(0, 0)
    }

    togglePopup(id, staffId) {
        console.log('produwertyuictId', id, staffId)
        if (id === "addStaff") {
            this.setState({
                addStaff: !this.state.addStaff,
            });
        }
        else if (id === "editStaff") {
            this.setState({
                editStaff: !this.state.editStaff, staffId: staffId
            });
        }

        else if (id === "editProduct") {
            this.setState({
                editProduct: !this.state.editProduct, productId: staffId
            });
        }

        else if (id === "addOffer") {
            this.setState({
                addOffer: !this.state.addOffer,
            });
        }



        else {
            this.setState({
                addProduct: !this.state.addProduct
            });
        }
    }
    componentDidMount() {
        this.props.dispatch({ type: 'PATH_ADD', payload: this.props.match })
        window.scrollTo(0, 0);
        // if ((this.props.history.location.pathname.includes("profile")) && (localStorage?.userType == 1 || localStorage.id != this.props.match.params.brandId)) {
        //     this.props.history.push('/404');
        // }

        this.getData()

        localStorage.setItem("isComming", "true")
    }


    getAdditionalInfo = () => {
        let self = this;

        _Api(
            Url.GET_COMPANY_NEW.method,
            Url.GET_COMPANY_NEW.url,
            "",
            `?companyId=${this.props.match.params.companyId ? this.props.match.params.companyId : localStorage.getItem("company")}`
        )
            .then((resp) => {
                let responseData = resp.responseData?.result
                console.log('dhgasvhjk', responseData)
                this.setState({

                    userData: responseData,

                })

            })
            .catch((err) => {
            });
    }




















    getData = () => {
        this.getBrandDetails()
        this.getStaffDetails()
        this.getProductDetails()
        this.getMedia()
        this.handleAllMedia()
        this.getOfferDetails()
        this.getAdditionalInfo()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.brandId !== this.props.match.params.brandId) {
            this.getData()
            
        }
    }



    getStaffDetails = () => {
        // this.setState({ isLoader: true })
        let { page, limit, search } = this.state
        let req = {
            page: page ? page : 1,
            limit: limit,
        }
        _Api(Url.GET_STAFF_LIST_Details.method, Url.GET_STAFF_LIST_Details.url, '', `?page=${req.page}&limit=${req.limit}&brandId=${this.props.match.params.brandId}`)
            .then(resp => {
                console.log('respexList', resp.responseData.result.data)
                this.setState({ StaffListData: resp.responseData.result, isLoader: false })
            }).catch(err => {
                this.setState({ isLoader: false })
            })
    }

    getProductDetails = () => {
        this.setState({ isLoader: true })
        let { page, limit, search } = this.state
        let req = {
            page: page ? page : 1,
            limit: 100,
        }
        _Api(Url.GET_PRODUCT_LIST.method, Url.GET_PRODUCT_LIST.url, '', `?page=${req.page}&limit=${req.limit}&brandId=${this.props.match.params.brandId}`)
            .then(resp => {
                console.log('respexList', resp.responseData.result.data)
                this.setState({ productListData: resp.responseData.result.data, totalCount: resp.responseData.result.totalCount, isLoader: false })
            }).catch(err => {
                this.setState({ isLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
                this.setState({ isLoader: false })
            })
    }

    getOfferDetails = () => {
        this.setState({ isLoader: true })
        let { page, limit, search } = this.state
        let req = {
            page: page ? page : 1,
            limit: 100,
        }
        _Api(Url.GET_OFFER_LIST.method, Url.GET_OFFER_LIST.url, '', `?page=${req.page}&limit=${req.limit}&brandId=${this.props.match.params.brandId}`)
            .then(resp => {
                console.log('respexList', resp.responseData.result.data)
                this.setState({ offerData: resp.responseData.result.data, totalCount: resp.responseData.result.totalCount, isLoader: false })
            }).catch(err => {
                this.setState({ isLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
                this.setState({ isLoader: false })
            })
    }




    // delete modal
    deleteProduct = (id, type = "product") => {
        console.log("dlsh", id)
        confirm({
            title: 'Are you sure you want to delete this?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            onOk: () => {
                if (type == "product")
                    this.handleDeleteProduct(id)
                else
                    this.handleDeleteOffer(id)
            },
            onCancel: () => {
                this.handleCancel()
            },
        });
    }



    handleDeleteOffer = (id) => {
        let req = {
            isDeleted: 0,
            offerId: id,
        }
        _Api(Url.DELETE_OFFER.method, Url.DELETE_OFFER.url, '', `?offerId=${req.offerId}&isDeleted=${req.isDeleted}`)
            .then(resp => {
                console.log('delete', resp.responseData.message)
                notify('success', resp.responseData.message)
                this.setState({ visible: false })
                this.getOfferDetails()
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
            })


    }


    handleDeleteProduct = (id) => {
        let req = {
            isDeleted: 0,
            productId: id,
        }
        _Api(Url.DELETE_PRODUCT.method, Url.DELETE_PRODUCT.url, '', `?productId=${req.productId}&isDeleted=${req.isDeleted}`)
            .then(resp => {
                console.log('delete', resp.responseData.message)
                notify('success', resp.responseData.message)
                this.setState({ visible: false })
                this.getProductDetails()
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
            })


    }

    deleteStaff = (id) => {
        let req = {
            isDeleted: 0,
            id: id,

        }
        _Api(Url.USER_DELETE.method, Url.USER_DELETE.url, '', `?requestUserId=${id}&isDeleted=${req.isDeleted}`)
            .then(resp => {
                console.log('delete', resp.responseData.message)
                notify('success', resp.responseData.message)
                this.setState({ visible: false })
                this.getStaffDetails();
                this.getBrandDetails();
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
            })


    }
    //Show modal
    showModal = (id) => {
        this.setState({
            visible: true,
            selectedId: id
        });

    };
    //Cancel modal
    handleCancel = () => {
        this.setState({ visible: false });
    };


    productStatus = (e, id) => {
        let req = {
            productId: id.toString(),
            status: (e.target.checked ? 1 : 0),
        }
        _Api(Url.PRODUCT_STATUS.method, Url.PRODUCT_STATUS.url, '', `?productId=${req.productId}&status=${req.status}`)
            .then(resp => {
                notify('success', resp.responseData.message)
                this.setState({ visible: false })
                this.getProductDetails()
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
            })
    }


    productStatus1 = (e, id) => {
        let req = {
            offerId: id.toString(),
            status: (e.target.checked ? 1 : 0),
        }
        _Api(Url.OFFER_STATUS.method, Url.OFFER_STATUS.url, '', `?offerId=${req.offerId}&status=${req.status}`)
            .then(resp => {
                notify('success', resp.responseData.message)
                this.setState({ visible: false })
                this.getOfferDetails()
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
            })
    }






    getBrandDetails = (e, id) => {
        _Api(Url.USER_DETAILS.method, Url.USER_DETAILS.url, '', `?requestedUserId=${this.props.match.params.brandId}`)
            .then(resp => {
                this.setState({ loading: false, brandInfo: resp.responseData.result, about: resp.responseData.result?.about ? resp.responseData.result?.about : '', })

            }).catch(err => {
                this.setState({ loading: false })
            })
    }

    handleImageUpload = (event, type) => {

        let reader = new FileReader();
        let img2 = new Image();

        let file = event.target.files[0];
        let { valiDate } = this.state;

        console.log(reader.result, " ", file)

        if (file) {
            reader.readAsDataURL(file)
            img2.src = URL.createObjectURL(file)

            img2.onload = function () {
                const imgWidth = img2.naturalWidth;
                const imgHeight = img2.naturalHeight;
            }

        }
        if (file) {
            // console.log("kdclsdmehbwhqjkewqehjk", event.target.files[0])
            if (file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png') {
                // console.log("media2", event.target.files[0], file.size)
                reader.addEventListener("load", () => {
                    if (type == "logo")
                        this.setState({ logoImgPreview: reader.result }, function () {
                        })
                    if (type == "cover")
                        this.setState({ coverImgPreview: reader.result }, function () {
                        })
                }, false);
                if (file.size > 2000000) {
                    console.log("lkjhgfdsdfghjklkjhgfghjkmnbvccvbnm>>>>>>>>>>>>>>>>>>")
                    valiDate = false;
                    console.log(event, "media", event.target.files[0])
                    if (type == "logo")
                        this.setState({
                            logoLoader: true,
                            logoImg: event.target.files[0],
                            profilePicErrorMsg: 'Profile pic size should be less than 5Mb.'
                        }, () => {
                            this.updateInfoBrand()
                        });

                    if (type == "cover")
                        this.setState({
                            coverLoader: true,
                            coverImg: event.target.files[0],
                            profilePicErrorMsg: 'Profile pic size should be less than 5Mb.'
                        }, () => {
                            this.updateInfoBrand()
                        });

                } else {
                    // console.log("media3hhhjshs", event.target.files[0], file.size)
                    if (type == "logo")
                        this.setState({
                            logoLoader: true,
                            logoImg: event.target.files[0],
                            profileImageError: ''
                        }, () => {
                            this.updateInfoBrand()
                        });
                    if (type == "cover")
                        this.setState({
                            coverLoader: true,
                            coverImg: event.target.files[0],
                            profileImageError: ''
                        }, () => {
                            this.updateInfoBrand()
                        });
                }
            } else {
                valiDate = false;
                this.setState({ media: 'File type not supported.' })

            }
        }
    }



    updateInfoBrand = (e, id) => {
        let formData = new FormData()
        formData.append('requestUserId', this.props.match.params.brandId)
        formData.append('logoImage', this.state.logoImg)
        formData.append('coverImage', this.state.coverImg)
        formData.append('about', this.state.about.trim())


        _Api(Url.UPDATE_BRAND_INFO.method, Url.UPDATE_BRAND_INFO.url, formData)
            .then(resp => {
                this.setState({ visible: false, isEdit: false, logoLoader: false, coverLoader: false, coverImg: '', logoImg: '' })
                this.getBrandDetails()
            }).catch(err => {
                this.setState({ visible: false, isEdit: false, logoLoader: false, coverLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
            })
    }


    // delete modal
    showDeleteConfirm = (id, type) => {
        confirm({
            title: 'Are you sure?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            className: 'del-cstm-mdl',
            content: (
                <div className="modal_data_wrapper">
                  <p className="mb-0">You will lose all the connections made by this staff member!</p>
                  <p className="mb-0"><b>This can not be undone!!</b></p>
                </div>
              ),
            centered: true,
            onOk: () => {
                this.deleteStaff(id)
            },
            onCancel: () => {
                this.handleCancel()
            },
        });
    }



    //Cancel modal
    handleCancel = () => {
        // console.log('delete', resp.responseData.message)
        this.setState({ visible: false });
    };




    handleEdit = () => {
        this.setState({ isEdit: !this.state.isEdit })
    }

    handleMedia = (data, urll) => {
        this.setState({ loaderMedia: true })
        const { media, url } = data
        let formData = new FormData()
        formData.append('brandId', this.props.match.params.brandId)
        formData.append('media', media ? media : '')
        formData.append('brandUrl', url ? url : '')
        _Api(Url.ADD_MEDIA.method, Url.ADD_MEDIA.url, formData)
            .then(resp => {
                this.setState({ loaderMedia: false })
                notify('success', resp.responseData.message)
                this.getMedia()
            }).catch(err => {
                this.setState({ loaderMedia: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
            })

    }

    getMedia = () => {
        _Api(Url.GET_MEDIA.method, Url.GET_MEDIA.url, '', `?brandId=${this.props.match.params.brandId}&limit=${3}&page=${1}`)
            .then(resp => {
                this.setState({
                    mediaData: resp.responseData.result
                })
                // this.setState({ visible: false, isEdit:false })

            }).catch(err => {
                // if (err.error)
                //     notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
            })

    }


    addToWishList = (event, category, id, api) => {
        event.preventDefault();
        event.stopPropagation();
        let stateName = `wish${id}`;
        this.setState({ [`isWishlistLoader${id}`]: true, [stateName]: !this.state[stateName] });
        let self = this;
        let params = category == "brand" ? { brandId: id } : { productId: id };
        let { method, url } = Url[api];
        _Api(method, url, params)
            .then(resp => {
                self.props.dispatch(getWishList());
                this.getProductDetails();
                setTimeout(() => {
                    this.setState({ [`isWishlistLoader${id}`]: false });

                }, 300);


            }).catch((err) => {
                this.handleError(err);
            })
    }
    handleError(err) {
        let errorMessage = err.error && err.error.responseMessage || err.message;
        notify("err", errorMessage);
    }

    handleAllMedia = () => {
        localStorage.setItem("mediaType", "brandId")
        localStorage.setItem("mediaId", this.props.match.params.brandId)


    }

    onConnectRequest(e, requestId, status) {
        e.preventDefault();
        let oldArr = this.state.selectedItems
        oldArr.push(requestId)
        this.setState({ selectedItems: oldArr })

        let req = {
            requestedUserId: requestId,
            connectionType: 1
        }
        _Api(Url.COMMUNITY_CREATEREQUEST.method, Url.COMMUNITY_CREATEREQUEST.url, req)
            .then(resp => {
                this.getStaffDetails()
                setTimeout(() => this.setState({ selectedItems: this.state.selectedItems.filter(item => item != requestId) }), 1000)
                notify(resp.responseData.message);
            }).catch((err) => {
                this.setState({ selectedItems: [] })
                this.handleError(err);
            })

    }

    getConnectionStatusButton = (item, index) => {
        

        let toReturn;
        if (item?.userConnection) {
            switch (item.userConnection.status) {
                case 0:
                case "0":
                    toReturn = <button  className="btn btn-blue mt-2"><span className="zoom"></span>Pending</button>
                    break;
                case 1:
                case "1":
                    toReturn = <button  onClick={(e) => this.videoCalling(item._id, index)} className="btn btn-blue mt-2"><span className="zoom"><ZoomVideo /></span>Video Call</button>
                    break;
                case 2:
                case "2":
                    toReturn = <CmBtn  text="Connect" className="btn btn-blue mt-2" bg='var(--btncolor)' onClick={(e) => this.onConnectRequest(e, item?._id, 1)} />
                    break;
            }
        }
        else {
            toReturn = <CmBtn text="Connect"  className="btn btn-blue" bg='var(--btncolor)' onClick={(e) => this.onConnectRequest(e, item?._id, 1)} />

        }
        return <span className="">{toReturn} </span>;
    }

    removeImage = (type) => {

        let formData = {
            requestUserId: this.props.match.params.brandId

        }
        if (type == 'logo')
            formData.logoImage = 1
        else
            formData.coverImage = 1

        if (type == 'logo') {
            this.setState({ logoImgPreview: '' })
        }
        else if (type == 'cover')
            this.setState({ coverImgPreview: '' })

        _Api(Url.DELETE_BRAND_IMG.method, Url.DELETE_BRAND_IMG.url, formData)
            .then(resp => {
                this.setState({ visible: false, isEdit: false, logoLoader: false, coverLoader: false })
                this.getBrandDetails()
            }).catch(err => {
                this.setState({ visible: false, isEdit: false, logoLoader: false, coverLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
            })
    }



    videoCalling = (userId, index) => {
        let self = this;

        if (userId) {
            this.setState({ startLoader: true })

            let oldArr = this.state.selectedItems
            oldArr.push(userId)

            self.setState({
                selectedItems: oldArr,

                ['videoConnectionLoder_' + userId]: true
            })
            self.props.dispatch(groupActions.joinOneMeeting({ userId: userId, type: 7 }))
                .then(function (res) {
                    self.setState({
                        ['videoConnectionLoder_' + userId]: false, startLoader: false,
                        selectedItems: self.state.selectedItems.filter(item => item != userId)
                    })
                    if (res.statusCode === 1 || res.statusCode === "1") {
                        //window.location.href = '/calling/' + self.props.groupDetail.meeting._id;
                        window.open('/calling/' + self.props.groupDetail.meeting._id, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                        //history.push('/calling/'+self.props.groupDetail.meeting._id)
                    } else {
                        self.setState({ startLoader: false, selectedItems: self.state.selectedItems.filter(item => item != userId) })

                    }
                    console.log("res", res)
                    // self.setState({ startLoader: false,  selectedItems: self.state.selectedItems.filter(item => item != userId) })
                })
        }


    }



    selectUser = (user) => {
        let self = this;
        // console.log("userrrrrr",user);
        if (this.props.userDetail._id !== user._id) {
            this.setState({
                selectedUser: user,
                selectedUserId: user._id,
                chatType: 3,
                isShow: true
            }, function () {
                self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 3 })
                self.props.dispatch({ type: 'CHAT_OPEN' })
                document.body.classList.toggle('chat_open_mob');
                self.props.dispatch({ type: 'SELECTED_USER', selectedUser: user })
                socket.emit('groupAdd', { type: 7, userId: user._id });
                //socket.emit('userConnection', {userId: user._id});
            })
        }


    }

    finalCropedImage = (categoryImgPrev, categoryImg, visibleVal) => {
        console.log("ldjshgvhbjkd", { categoryImgPrev, categoryImg, visibleVal })
        if (this.state.copperType == "cover") {
            this.setState({
                visibleVal,
                isCropper: false,
                coverImgPreview: categoryImgPrev,
                coverImg: categoryImg

            }, () => this.updateInfoBrand())
        }
        else {
            this.setState({
                visibleVal,
                isCropper: false,
                logoImgPreview: categoryImgPrev,
                logoImg: categoryImg

            }, () => this.updateInfoBrand())

        }
    }


    handleCropperOpen = (type) => {
        console.log("dsajhgfdghjkas", type)
        this.setState({ copperType: type, isCropper: true })

    }

    onStafStatusChange = (e, item) => {
        let id = item._id
        let oldArr = this.state.selectStaff
        oldArr.push(id)

        this.setState({ selectStaff: oldArr })

        let req = {
            requestId: item._id,
            isHide: item.isHide ? item.isHide == 1 ? 2 : 1 : 2,
        }
        _Api(Url.STAFF_ENABLE_DISABLE.method, Url.STAFF_ENABLE_DISABLE.url, req)
            .then(resp => {
                this.getStaffDetails()
                this.setState({ visible: false, selectStaff: this.state.selectStaff.filter(item => item != id) })

            }).catch(err => {
                this.setState({ visible: false, selectStaff: this.state.selectStaff.filter(item => item != id) })
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
            })

    }

    handleChange = evt => {
        this.setState({ about: evt.target.value });
    };

    render() {

        // console.log("lkjhgdsafghjklsdaghj",   !this.state.isEdit && (brandInfo?.about == undefined || brandInfo?.about == '') )

        const isShow = localStorage.getItem("showAll") == "true" ? true : false

        const DropdownCover = (props) => {
            return (
                props.isCover ?
                    <Menu  >
                        <Menu.Item onClick={() => this.handleCropperOpen('cover')} key="Block">Upload</Menu.Item>
                        {props.isRemove && <Menu.Item onClick={() => this.removeImage('cover')} key="Report">Remove</Menu.Item>}
                    </Menu  >
                    :
                    <Menu  >
                        <Menu.Item onClick={() => this.handleCropperOpen('logo')} key="Block">Upload</Menu.Item>
                        {props.isRemove && <Menu.Item onClick={() => this.removeImage('logo')} key="Report">Remove</Menu.Item>}
                    </Menu>
            )
        }

        let { selectStaff, selectedItems, StaffListData, productListData, visible, loading, brandInfo, offerData } = this.state;
        let userType = localStorage.getItem("userType")
        let isFromVeiwProfile = userType == 1 ? false : !this.props.history.location.pathname.includes("profile");

        let isAddStaff = (brandInfo?.userPlan == 2 && StaffListData?.length > 3) ? false : true
        let imageSizeDisplay = true;
        if (this.state.coverImgPreview || brandInfo.coverImage) {
            imageSizeDisplay = false;
        }
        let logoImageSizeDisplay = true;
        if (this.state.logoImgPreview || brandInfo?.logoImage) {
            logoImageSizeDisplay = false;
        }

        const {
            facebookLink,
            instagramLink,
            linkedinLink,
            phoneNumber,
            twitterLink,
            youtubeLink,
            companyEmail,
            companyWebsite,
            companyName
        } = this.state.userData || {}
        
        return (
            <section className={`brand-home-banner-bg brandprofile-banner-bg ${userType == 1 ? "brand dash_body_container withsidenavbar" : ''} `}>
                {this.state.loaderMedia && <Loader />}
                <div className="container-fluid brandhome">
                    <div className="header-text  mb-4 pt-3">
                        <div className="d-flex align-items-center justify-content-start">
                            <p className="header-icon"><Brands /></p>
                            <h4>{companyName ? companyName : brandInfo?.companyName}</h4>
                        </div>
                        <a > <button onClick={() => this.props.history.goBack()} className="btn btn-light"><i class="fa fa-arrow-left" aria-hidden="true"></i>Back</button></a>
                    </div>
                    <div className=" mt-3">
                        {!loading && <div className="pos-relative">
                            <div className="professional-beauty-banner-wrapper">
                                <img className={`img-fluid w-100 banner ${this.state.coverImgPreview ? '': brandInfo?.coverImage ? '' : 'placeholder' }`} src={this.state.coverImgPreview ? this.state.coverImgPreview : brandInfo?.coverImage ? brandInfo?.coverImage : require('../../../assets/images/profile-placeholder.png')} />
                                {
                                    imageSizeDisplay &&
                                    <div class="coverImage_placholder" ><h6>Image should be 1000x400 Max size 2MB / JPG format</h6></div>
                                }

                                <input id="file-upload"
                                    style={{ width: 500, height: 500 }}
                                    type="file"
                                    hidden
                                    // disabled={true}
                                    ref={my_file => this.inputElement = my_file}
                                    accept="image/*"
                                    onChange={(e) => this.handleImageUpload(e, "cover")}
                                />
                                {!isFromVeiwProfile &&
                                    <Dropdown placement="bottomRight" overlay={<DropdownCover isRemove={brandInfo?.coverImage ? true : false} isCover={true} />} trigger={['click']}>

                                        <div className="upload-wrapper">
                                            <div className="top-right">
                                                <span
                                                // onClick={() => this.inputElement.click()}
                                                >
                                                    <i className="upload_croperimg">{this.state.coverLoader ? <MySpinner /> :
                                                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                                            <Pencil />
                                                        </a>
                                                    }
                                                    </i>
                                                </span>

                                            </div>
                                        </div>
                                    </Dropdown>
                                }
                            </div>

                            <div className="brand-logo-parent">
                                {
                                    console.log("jhduygtsfaghdjkl", brandInfo)
                                }
                                <img className="img-fluid w-100 banner" src={this.state.logoImgPreview ? this.state.logoImgPreview : brandInfo?.logoImage ? brandInfo?.logoImage : require('../../../assets/images/placeholder/blank_placeholder_img.png')} />
                                {
                                    logoImageSizeDisplay &&
                                    <div class="coverImage_placholder logo" ><h6>Image should be 250x100 Max size 2MB / JPG format</h6></div>
                                }
                                {!isFromVeiwProfile &&
                                    <Dropdown placement="bottomLeft" overlayClassName="edit-profile-dropdown"
                                        overlay={<DropdownCover isCover={false} isRemove={brandInfo?.logoImage ? true : false} />} trigger={['click']}  >
                                        <div className="upload-wrapper"
                                        // onClick={() => this.logoInput.click()}
                                        >
                                            <label className="upload-photo-wrapper">
                                                <span>
                                                    <i> {this.state.logoLoader ? <MySpinner /> :

                                                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                                            <Pencil />
                                                        </a>
                                                        // <PhotoUploadIcon />
                                                    }</i></span>
                                            </label>
                                        </div>
                                    </Dropdown>
                                }
                                {/* {!isFromVeiwProfile && <p className="dimention">Image dimension: 250 * 100 </p>} */}
                            </div>
                            {/* {!isFromVeiwProfile && <p className="dimention respo-dimention">Image dimension: 1200 * 400 </p>} */}

                            {/* <input id="file-upload"
                                type="file"
                                hidden
                                ref={my_file => this.logoInput = my_file}
                                accept="image/*"
                                onChange={(e) => this.handleImageUpload(e, "logo")}
                            /> */}
                        </div>}
                        <br /><br />
                        {!isFromVeiwProfile && <div className="company-profile mt-5">
                            <div className="profile-edit">
                                <h1 className="home-heading pos-relative mb-5 text-center">Add Your Company Bio
                                  <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span><br />

                                </h1>


                                <div className="upload-wrapper " onClick={this.handleEdit}>
                                    <span className="upload-photo-wrapper" onClick={this.handleEdit}> <p className="m-0"><i><Pencil /></i></p></span>
                                </div>
                                <div className="custom-icons mb-4">
                                    <ul className="list-unstyled mb-0 text-center">
                                        {companyEmail &&
                                            <li className="list-inline-item">
                                                <a target="_blank" href={`mailto:${companyEmail}`}>
                                                    <i class="fa fa-envelope-o" aria-hidden="true"></i>
                                                    {/* <span>{companyEmail}</span> */}
                                                </a>

                                            </li>
                                        }
                                        {phoneNumber &&
                                            <li className="list-inline-item">
                                                <a target="_blank" href={`tel:${phoneNumber}`} className="d-flex align-items-center">
                                                    <i class="fa fa-phone" aria-hidden="true"></i>
                                                    <span>{phoneNumber}</span>
                                                </a>
                                            </li>
                                        }
                                        {companyWebsite && <li className="list-inline-item">
                                            <a target="_blank" href={companyWebsite}>
                                                <i class="fa fa-globe" aria-hidden="true"></i>
                                            </a>
                                        </li>}
                                        {linkedinLink && <li className="list-inline-item">
                                            <a target="_blank" href={linkedinLink}>
                                                <i class="fa fa-linkedin-square" aria-hidden="true"></i>
                                            </a>
                                        </li>}
                                        {twitterLink && <li className="list-inline-item">
                                            <a target="_blank" href={twitterLink}>
                                                <i class="fa fa-twitter" aria-hidden="true"></i>
                                            </a>
                                        </li>}
                                        {facebookLink && <li className="list-inline-item">
                                            <a target="_blank" href={facebookLink}>
                                                <i class="fa fa-facebook-official" aria-hidden="true"></i>
                                            </a>
                                        </li>}
                                        {youtubeLink && <li className="list-inline-item">
                                            <a target="_blank" href={youtubeLink}>
                                                <i class="fa fa-youtube" aria-hidden="true"></i>
                                            </a>
                                        </li>}
                                        {instagramLink &&
                                            <li className="list-inline-item">
                                                <a target="_blank" href={instagramLink}>
                                                    <i class="fa fa-instagram" aria-hidden="true"></i>
                                                </a>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>

                            {
                                !this.state.isEdit && (brandInfo?.about == undefined || brandInfo?.about == '') &&
                                <div className="w-100 text-center row nodata m-auto">
                                    <div className="m-auto">
                                        <NoMediaICon />
                                        
                                        <h2>No Data Found</h2>
                                    </div>
                                </div>
                            }

                            {!this.state.isEdit && brandInfo?.about != '' &&
                                <div className="profile-edit">
                                    <div className="row">
                                      <div className="col-md-8 offset-md-2">

                                     
                                    <ContentEditable
                                        html={this.state.about} // innerHTML of the editable div
                                        disabled={true} // use true to disable edition
                                    // onChange={this.handleChange} // handle innerHTML change
                                    />
                                     </div>
                                    </div>
                                    {/* <textarea readOnly
                                        rows="5"
                                        value={brandInfo?.about}
                                        className="form-control showtextarea"
                                    /> */}
                                </div>
                            }
                            {this.state.isEdit && <div className="form-group ">
                                {/* contenteditable="true" */}
                                <div className="row">
                                      <div className="col-md-8 offset-md-2">
                                <ContentEditable
                                className="editable"
                                    html={this.state.about} // innerHTML of the editable div
                                    disabled={false} // use true to disable edition
                                    onChange={this.handleChange} // handle innerHTML change
                                />
                                  </div>
                                    </div>

                                {/*                                 
                                <textarea rows="4"
                                    value={this.state.about}

                                    onChange={(e) => this.setState({ about: e.target.value })}
                                    className="form-control "
                                    placeholder="Add Your Company Bio" name="categoryDesc"

                                /> */}
                                <div className="col-sm-12 text-center">
                                    <button type="button" onClick={this.handleEdit} className="btn btn-red addbtn addbtn-gray">Cancel</button>
                                    <button type="button" onClick={this.updateInfoBrand} className="btn btn-red addbtn">Save</button>
                                </div>

                            </div>}

                        </div> ||
                            <div className="company-profile mt-3">
                                <h1 className="home-heading pos-relative mb-5 text-center">Companycccc Profile<span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                </h1>
                                <div className="profile-edit">
                                    {/* <pre>{brandInfo?.about} {!this.state.isEdit} </pre> */}
                                    <textarea readOnly
                                        rows="5"
                                        value={brandInfo?.about}
                                        className="form-control showtextarea"
                                    />
                                </div>
                            </div>
                        }
                    </div>

                    {
                        // localStorage.getItem("userType") != 4 &&
                        <>

                            <div className="staff mt-5">
                                <h1 className="home-heading pos-relative mb-5 text-center">Staff on the Stand
                                    <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                </h1>
                                <div className="row ">
                                    {StaffListData?.map((item, index) =>
                                        (
                                            <div className="four-div">
                                                <div className="card community-card staff-container">
                                                    <div className="row ">
                                                        <div className=" imgparent pr-0">
                                                            <div className="img-wrapper pos-relative image-chat">
                                                                <img src={item?.thumbUrl ? item?.thumbUrl : require('../../../assets/images/placeholder.png')} />
                                                                {item?.userConnection?.status == "1" &&
                                                                    item._id == localStorage.getItem("id") ? '' :
                                                                    item._id == localStorage.getItem("id") ? '' :
                                                                        userType != 1 &&
                                                                       
                                                                            <span
                                                                                onClick={() => this.selectUser(item)}
                                                                                className={`chat cursor-point ${item.isOnline == 1 ? 'green' : 'yellow'}`} >
                                                                                <Chat /></span>
                                                                       

                                                                }
                                                            </div>
                                                        </div>
                                                        <div className=" textparent no-gutter d-flex align-items-start flex-column">
                                                            {
                                                                <Tooltip placement="top" title={`${item?.firstName} ${item?.lastName}`}>
                                                                    {
                                                                        userType == 1 ?
                                                                            <h4><strong>{item?.firstName} {item?.lastName}</strong></h4>
                                                                            :
                                                                            <Link to={`/user-profile/${item?._id}`}>  <h4><strong>{item?.firstName} {item?.lastName}</strong></h4>     </Link>
                                                                        // <Link to={`/business/staf/${item?._id}`}>  <h4><strong>{item?.firstName} {item?.lastName}</strong></h4> </Link>
                                                                    }
                                                                </Tooltip>

                                                                   


                                                            }






                                                            <span className="descript mb-2"><small >{item?.jobTitleId.length ? item?.jobTitleId.toString() : item?.jobTitleOther}</small></span>
                                                            <div className="staff-bottom">
                                                                {
                                                                     userType == 1 ? null : selectedItems.indexOf(item._id) == -1 ? item._id == localStorage.getItem("id") ? '' : this.getConnectionStatusButton(item, index, userType) : <MySpinner />





                                                                }

                                                                {!isFromVeiwProfile && <span className="action_group  mt-3">
                                                                    {
                                                                        selectStaff.indexOf(item._id) == -1 ?
                                                                            <p className="toggle-parent ">
                                                                                <label className="switch">
                                                                                    <input type="checkbox"
                                                                                        checked={item.isHide ? item.isHide == 1 ? true : false : true}
                                                                                        onChange={(e) => this.onStafStatusChange(e, item)}
                                                                                    />
                                                                                    <span className="slider round"></span>
                                                                                </label>

                                                                            </p>
                                                                            :
                                                                            <MySpinner />
                                                                    }
                                                                    {
                                                                        item?.userType != 4 ?
                                                                            <Link to={`/user-profile/${item?._id}`}><p><p className="circle-serial"><Pencil /></p></p></Link> :
                                                                            <a onClick={() => this.togglePopup('editStaff', item)}> <p><p className="circle-serial" ><Pencil /></p></p> </a>

                                                                    }

                                                                    {/* <p><p className="circle-serial" ><Pencil /></p></p> */}
                                                                    {
                                                                        (item?.userType == 4 && item._id != localStorage.id && localStorage.getItem("ownerId") != item._id) && <a onClick={() => this.showDeleteConfirm(item?._id)}> <p><p className="circle-serial" ><Delete /></p></p></a>}
                                                                </span>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )
                                    }

                                    {StaffListData && StaffListData.length == 0 &&
                                        <>
                                            <div className="w-100 text-center row nodata m-auto">
                                                <div className="m-auto">
                                                    <NoMediaICon />
                                                    
                                                    <h2>No Data Found</h2>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                                {!isFromVeiwProfile && <div className="row">
                                    {isAddStaff && <div className="col-sm-12 text-center">
                                        <button type="button" onClick={this.togglePopup.bind(this, 'addStaff')} className="btn btn-red addbtn">Add Staff</button>
                                    </div>}
                                </div>
                                }
                            </div>
                        </>

                    }


                    <div className="produçt-showcase mt-5">
                        <h1 className="home-heading pos-relative mb-5 text-center">{manageProfile.h1}
                          <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                        </h1>


                        <ProductShowcase
                            {...this.props}
                            productListData={productListData}
                            statusProduct={this.productStatus}
                            deleteProduct={this.deleteProduct}
                            editSuccess={this.getProductDetails}
                            isFromVeiwProfile={isFromVeiwProfile}
                            addToWishList={this.addToWishList}
                            scope={this}

                        />
                        {!isFromVeiwProfile && <div className="row">
                            <div className="col-sm-12 text-center">
                                {/* <button type="button" className="btn btn-red addbtn addbtn-gray">View All Products</button> */}
                                <button type="button" onClick={this.togglePopup.bind(this)} className="btn btn-red addbtn">Add job</button>
                                {/* {userType != 1 && <button type="button" onClick={this.togglePopup.bind(this)} className="btn btn-red addbtn">Add Products</button>} */}
                            </div>
                        </div>
                        }
                    </div>


                    <div className="produçt-showcase mt-5">
                        <h1 className="home-heading pos-relative mb-5 text-center">{manageProfile.h2}
                          <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                        </h1>


                        <OfferShowCase
                            {...this.props}
                            productListData={offerData}
                            statusProduct={this.productStatus1}
                            deleteProduct={(e) => this.deleteProduct(e, "offer")}
                            editSuccess={this.getOfferDetails}
                            isFromVeiwProfile={isFromVeiwProfile}
                            addToWishList={this.addToWishList}
                            scope={this}

                        />
                        {!isFromVeiwProfile && <div className="row">
                            <div className="col-sm-12 text-center">
                                {/* <button type="button" className="btn btn-red addbtn addbtn-gray">View All Products</button> */}
                                <button type="button" onClick={this.togglePopup.bind(this, 'addOffer')} className="btn btn-red addbtn">Add Training</button>
                                {/* {userType != 1 && <button type="button" onClick={this.togglePopup.bind(this)} className="btn btn-red addbtn">Add Products</button>} */}
                            </div>
                        </div>
                        }
                    </div>
                    <div className="images-video mt-5">
                        <h1 className="home-heading pos-relative mb-5 text-center">Images/ Videos
                          <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                        </h1>
                        <Media
                            {...this.props}
                            mediaData={this.state.mediaData}
                            getMedia={(data, url) => this.handleMedia(data)}
                            mediaType="brandId"
                            mediaId={this.props.match.params.brandId}
                            onDeleteMedia={this.getMedia}
                            isFromVeiwProfile={isFromVeiwProfile}
                        />


                        {/* {this.state.mediaData?.length != 0 && <div className="row">
                            <div className="col-sm-12 text-center">
                                <button onClick={this.handleAllMedia} type="button" className="btn btn-red addbtn">View All Media</button>
                                <button onClick={this.handleAllMedia} type="button" className="btn btn-red addbtn addblue">+ Add</button>
                            </div>
                        </div>} */}
                    </div>

                    <div className=" mt-5">
                        <h1 className="home-heading pos-relative mb-5 text-center">{manageProfile.h3}
                            <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                        </h1>
                        <Brochures
                            {...this.props}
                            isFromVeiwProfile={isFromVeiwProfile}
                        />
                        <br />

                    </div>

                </div>

                {userType != 1 && <Footer />}


                {
                    this.state.addStaff ?
                        <AddStaff
                            {...this.props}
                            text='Close Me'
                            closePopup={this.togglePopup.bind(this, "addStaff")}
                            getStaff={this.getStaffDetails}
                        />
                        : null
                }

                {
                    this.state.addProduct ?
                        <AddProduct
                            {...this.props}
                            text='Close Me'
                            getProductDetails={this.getProductDetails}
                            closePopup={this.togglePopup.bind(this, "addProduct")}
                        />
                        : null
                }

                {
                    this.state.addOffer ?
                        <AddOffer
                            {...this.props}
                            text='Close Me'
                            getProductDetails={this.getOfferDetails}
                            closePopup={this.togglePopup.bind(this, "addOffer")}
                        />
                        : null
                }

                {
                    this.state.editStaff ?
                        <AddStaff
                            {...this.props}
                            text='Close Me'
                            closePopup={this.togglePopup.bind(this, "editStaff")}
                            getStaff={this.getStaffDetails}
                            isEdit={true}
                            staffData={this.state.staffId}
                        />
                        : null
                }
                {
                    this.state.editProduct ?
                        <EditProduct
                            text='Close Me'
                            closePopup={this.togglePopup.bind(this, "editProduct")}
                            productId={this.state.productId}
                        />
                        : null
                }

                {this.state.isCropper ?
                    <Croper aspect={2.5 / 1}
                        // onImageCrop={img => this.setState({ media: img })}
                        imgPrev={'2.5-1.png'}
                        finalCropedImage={(categoryImgPrev, categoryImg, visibleVal) => this.finalCropedImage(categoryImgPrev, categoryImg, visibleVal)} visibleVal={this.state.isCropper}
                        onClose={() => this.setState({ isCropper: false })}
                    />
                    : ""}
            </section >
        )
    }
}


function mapStateToProps(state) {

    return {
        ...state,
        wishList: state.wishList

    };
}

export default connect(mapStateToProps)(BrandProfile);
