import { Link } from "react-router-dom";
import React from "react";

export const BtnRed = (data) => (
    <Link to={'/' + data.to} className={`btn btn-primary cursor-point ${data.className}`}>{data.text}</Link>

)

export const BackBtn = (data) => {
    return (
        <Link to={'/' + data.to} > <button className={data.className ? data.className : "btn btn-light cursor-point"}><i class="fa fa-arrow-left" aria-hidden="true"></i>Back</button></Link>
    )
}

export const LiveBtn = (data) => {
    return (
        // <Link to={'/' + data.to} >
        <button className="btn btn-live"><i className="fa fa-circle"></i>Live</button>
        // </Link>
    )
}

export const CmBtn = (data) => {
    return (
        // <Link  onClick={data.onClick} >
        <button  type="button" onClick={data.onClick} className={`btn ml-2 mr-2 ${data.className}`} style={{ backgroundColor: data.bg, color: data.color, border: data.border }} >{data.text} {data.children}  </button>
        // </Link>

    )
}

export const CmBtnRef = (data) => {
    return (
        // <Link  onClick={data.onClick} >
        <button ref={data.refs}  type="button" onClick={data.onClick} className={`btn ${data.className}`} style={{ backgroundColor: data.bg, color: data.color, border: data.border }} >{data.text} {data.children}  </button>
        // </Link>

    )
}


