import io from 'socket.io-client';

import siteSetting from "../config/env/Index";
// import device from "device-uuid";

// var uuid = device.DeviceUUID().get();
// console.log("uuid",uuid);

const socket = io(siteSetting.socketUrl,{
    query:{accessToken:localStorage.getItem('accessToken')},
    transports: ['websocket'],
    auth: {
        token: localStorage.getItem('accessToken')
    },
});



export default socket;