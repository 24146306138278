import {
    PATH_ADD,
    PATH_REMOVE
} from "../action/path.actions";

const InitalState = {

}

export const pathDatail = (state = InitalState , action) => {

    switch (action.type) {

        case PATH_ADD:
            return action.payload;
    
        default:
            return state
    }

}



