import React, { Component } from 'react';
import { Hert, ExhibitionManagement, Pencil, Delete } from '../../../../SvgIcon';
import { Link } from "react-router-dom";
import Sidebar from '../../../layout/Sidebar';
import Header from '../../../layout/Header';
import { Tab, Tabs } from 'react-bootstrap'
import AddStaff from '../popups/AddStaff'
import AddProduct from '../popups/AddProduct';
import { _Api } from '../../../../services/Api';
import Url from '../../../../services/BaseUrl';
import { notify } from '../../../../components/Toaster';
import moment from 'moment-timezone'
import { CmBtn } from "../../../../components/common/Button";


class NetworkingDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addStaff: false,
            addProduct: false,
            networkingLoungeViewData: '',
        };
    }

    togglePopup(id) {

        if (id === "addStaff") {
            this.setState({
                addStaff: !this.state.addStaff,
            });
        }
        else {
            this.setState({
                addProduct: !this.state.addProduct
            });
        }

    }
    componentDidMount() {
        this.getNetworkingLoungeVieeDetails()
    }

    getNetworkingLoungeVieeDetails = () => {
        // let req ={
        //     loungeId:this.props.match.params.id
        // }
        _Api(Url.VIEW_NETWORKING_LOUNGE.method, Url.VIEW_NETWORKING_LOUNGE.url, '', `?loungeId=${this.props.match.params.id}`)
            .then(resp => {
                console.log('respexList', resp.responseData.result)
                this.setState({ networkingLoungeViewData: resp.responseData.result, totalCount: resp.responseData.result.totalCount, isLoader: false })
            }).catch(err => {
                this.setState({ isLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
                this.setState({ isLoader: false })
            })
    }

    handleJoin = (id, item) => {
        console.log("test123", navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1)
        if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
            this.handleJoin1(id, item)

        }
        else {
            this.handleJoin2(id, item)
        }

    }

    handleJoin2 = (id, item) => {
        // console.log("onee11222",  localStorage.getItem)
  
        const { numberofAttendees, onlineJoinUser } = item
        let req = {
            otherUserId: localStorage.getItem('id'),
            type: 4,
            networkLoungeId: item._id,
            exhibitionId: localStorage.getItem('exhibition')
        }
        _Api(Url.CHECK_PLAN_USER.method, Url.CHECK_PLAN_USER.url, req)
            .then(resp => {
                const { isBuy } = resp?.responseData
                if (isBuy == 0) {
                    this.props.dispatch({ type: 'SHOW_PLAN_USER_POPUP', payload: this.props.match })
                }
                else {
                    if (true) {
                        console.log("onee11222",id,  localStorage.getItem('accessToken'))
                        console.log("onee11", `https://video.worldwidejobfinder.com?lounge=${id}&token=${localStorage.getItem('accessToken')}`)

                        window.open(`https://video.worldwidejobfinder.com?lounge=${id}&token=${localStorage.getItem('accessToken')}&name=${item.loungeName}`, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                        let req = { networkLoungeId: item._id }

                        _Api(Url.JOIN_LOUNG_PLAN.method, Url.JOIN_LOUNG_PLAN.url, req)
                            .then(resp => {
                            }).catch(err => { })
                    }
                    else
                        notify("err", "This lounges is full , you can not join at this time")
                }

            }).catch(err => {

            })

    }




    handleJoin1 = (id, item) => {
        console.log("onee", id)
        const { numberofAttendees, onlineJoinUser } = item
        var windowReference = window.open();
        let req = {
            otherUserId: localStorage.getItem('id'),
            type: 4,
            networkLoungeId: item._id,
            exhibitionId: localStorage.getItem('exhibition')
        }
        _Api(Url.CHECK_PLAN_USER.method, Url.CHECK_PLAN_USER.url, req)
            .then(resp => {
                const { isBuy } = resp?.responseData
                if (isBuy == 0) {
                    this.props.dispatch({ type: 'SHOW_PLAN_USER_POPUP', payload: this.props.match })
                }
                else {

                   
                    
                    if (true) {
                        var newWin = window.open(`https://video.worldwidejobfinder.com?lounge=${id}&token=${localStorage.getItem('accessToken')}&name=${item.loungeName}`, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                        if (!newWin || newWin.closed || typeof newWin.closed == 'undefined')
                            windowReference.location = `https://video.worldwidejobfinder.com?lounge=${id}&token=${localStorage.getItem('accessToken')}&name=${item.loungeName}`
                        let req = { networkLoungeId: item._id }

                        _Api(Url.JOIN_LOUNG_PLAN.method, Url.JOIN_LOUNG_PLAN.url, req)
                            .then(resp => {
                            }).catch(err => { })
                    }


                    // if (onlineJoinUser < numberofAttendees) {
                    //     var newWin = window.open('/network-lounge/' + id, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                    //     if (!newWin || newWin.closed || typeof newWin.closed == 'undefined')
                    //         windowReference.location = '/network-lounge/' + id
                    //     let req = { networkLoungeId: item._id }

                    //     _Api(Url.JOIN_LOUNG_PLAN.method, Url.JOIN_LOUNG_PLAN.url, req)
                    //         .then(resp => {
                    //         }).catch(err => { })
                    // }
                    else
                        notify("err", "This lounges is full , you can not join at this time")
                }

            }).catch(err => {

            })

    }




    render() {
        let { networkingLoungeViewData } = this.state;
        return (
            <>
                <div className="dash_body_container">
                    <div className="body_container">
                        <div className="header-text  mb-4 mt-3">
                            <div className="d-flex align-items-center">
                                <h4>{networkingLoungeViewData?.loungeName}</h4>
                            </div>
                            <a > <button onClick={() => this.props.history.goBack()} className="btn btn-light"><i class="fa fa-arrow-left" aria-hidden="true"></i>Back</button></a>

                        </div>
                        <div className="rounded-body pb-5 pl-4 pr-4">
                            <div className=" pb-5 pl-4 pr-4 pt-5">
                                <div className="header-text  mb-4 mt-3">
                                    <div className="d-flex align-items-center">
                                        {/* <div className="flex-grow-1"> */}
                                            <CmBtn onClick={() => this.handleJoin(networkingLoungeViewData._id, networkingLoungeViewData)} className="btn btn-red" bg='var(--btncolor)' text="Join" />
                                        {/* </div> */}
                                    </div>


                                    <div className="ml-auto">
                                        <Link className="btn btn-red" to={`/seminardetails/${this.props.match.params.exId}/networking/editnetworkinglounge/${networkingLoungeViewData?._id}`}>Edit</Link>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table vertical-table table-bordered rounded-table">
                                        <tr>
                                            <th>Lounge Name</th>
                                            <td>{networkingLoungeViewData?.loungeName}</td>
                                            <th></th>
                                            <td></td>


                                        </tr>
                                        <tr>
                                            <th>Start Date</th>
                                            <td>{moment(networkingLoungeViewData?.startDate).format('LL')}</td><th>Start Time</th><td>{moment(networkingLoungeViewData.startTime).format('LT')}</td>
                                        </tr>
                                        <tr>
                                            <th>End Date</th>
                                            <td>{moment(networkingLoungeViewData?.endDate).format('LL')}</td><th>End Time</th><td>{moment(networkingLoungeViewData.endTime).format('LT')}</td>
                                        </tr>
                                        <tr>
                                            <th>Allowed no. of Attendees</th>
                                            <td>{networkingLoungeViewData?.numberofAttendees}</td>
                                            <th></th>
                                            <td></td>

                                            {/* <th>Lounge URL</th>
                                            <td>{networkingLoungeViewData?.loungesUrl ? networkingLoungeViewData?.loungesUrl : "NA"}</td> */}
                                        </tr>
                                    </table>
                                </div>


                                {/* <div className="header-text  mb-4 mt-5 d-flex justify-content-start">
                                    <h4 className="mb-auto">Cover image</h4>
                                </div>
                                <div className="confrence-cover">
                                    <div className="card video-card">
                                        <img className="img-fluid" src={networkingLoungeViewData?.image} />
                                    </div>
                                </div> */}




                            </div>


                        </div>

                    </div>
                </div>
            </>
        );
    }
}
export default NetworkingDetails;

const loop = [0, 1, 2, 3]

const chatLoop = [0, 1, 2, 3, 4, 5, 6, 7, 8]



