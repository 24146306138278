import React, { Component } from 'react';
import { Hert, Brands, Pencil, Delete ,UploadVideo} from '../../../SvgIcon';
import { Link } from "react-router-dom";
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';
import Pagination from 'react-js-pagination';
import {Select, TreeSelect} from 'antd';
import InlineError from "../../../components/InlineError";
import {_Api, _ApiMultipart} from "../../../services/Api";
import Url from "../../../services/BaseUrl";
import {notify} from "../../../components/Toaster";
import Loader from "../../../components/Loader";
const { Option } = Select;
const { SHOW_PARENT,SHOW_CHILD,SHOW_ALL } = TreeSelect;
// import BackBtn from '../../../components/common/Button'

class EditPow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryTitle:"",
            categoryDesc:"",
            categoryType:0,

            categoryTitleError:"",
            categoryDescError:"",
            categoryTypeError:"",
            categoryImgPrev:require('../../../assets/images/placeholder-img.png'),
            categoryImg:"",
            isLoader:false,
            categoryId:"",
            placeOfworkList:[],
            placeOfWorkData:[],
            placeOfWorkError:'',
            placeofWorkId:[],
            sectorError:"",
            selectedSectorValue:[]
        }
        this.onChanged=this.onChanged.bind(this);
        this.editCategory=this.editCategory.bind(this);
        this.getCategoryDetails=this.getCategoryDetails.bind(this);
    }

    componentDidMount(){
        this.getCategoryDetails();
        this.getSector();
    }

    getCategoryDetails(){
        let self=this;
        let params = {
            placeOfWorkId: this.props.match.params.id,
        }
        _Api(Url.POW_DETAILS.method, Url.POW_DETAILS.url, params )
            .then(resp => {
                console.log("resp",resp);
                self.setState({
                    categoryId:resp.responseData.result._id,
                    categoryTitle:resp.responseData.result.name,
                    selectedSectorValue:resp.responseData.result.sectorId,
                })
            }).catch(err => {
            this.setState({ isLoader: false })
            if (err.error)
                notify("err", err.error.responseMessage)
            else if (err)
                // notify("err", err.message)
            this.setState({ isLoader: false })
        })
    }

    getSector = () => {
        _Api(Url.GET_SECTOR_LIST.method, Url.GET_SECTOR_LIST.url)
            .then(resp => {
                console.log('getSector responseData', resp.responseData);
                var sectorDropDown =[]
                let sectorList = resp.responseData.result.data.map((item) => {
                    console.log("sectorList sectorList",item)
                    sectorDropDown.push(
                        <Option key={item._id}>{item.name}</Option>
                    )
                })
                this.setState({sectorDropDown,isLoader: false});

            }).catch(err => {
            this.setState({ isLoader: false })
            if (err.error)
                notify("err", err.error.responseMessage)
            this.setState({ isLoader: false })
        })
    }

    editCategory() {
        let self=this;
        let {placeofWorkId}=this.state;
        this.setState({
            categoryTitleError:"",
        })
        if (this.state.categoryTitle === '') {
            this.setState({ categoryTitleError: '*Please enter place of work name' })
        } else if (this.state.selectedSectorValue.length === 0) {
            this.setState({ sectorError: '*Please select sector' })
        }
        else{
            this.setState({ isLoader: true })
            let data = {
                name:this.state.categoryTitle,
                placeOfWorkId:this.props.match.params.id,
                sectorId:this.state.selectedSectorValue.join()
            };

            _Api(Url.POW_EDIT.method, Url.POW_EDIT.url, data)
                .then(resp => {
                    this.setState({ isLoader: false })
                    self.props.history.push('/placeofwork')
                }).catch(err => {
                this.setState({ isLoader: false })
                if (err.error) {
                    if(err.error.errorCode==5){
                        self.setState({
                            categoryTitleError:err.error.responseMessage
                        })
                    }else{
                        notify("err", err.error.responseMessage)
                    }
                }else if (err) {
                    // notify("err", err.message)
                }
            })
        }

    }

    onChanged(e) {
        console.log("e", e);
        let self=this;
        let keyName=e.target.name;
        let KeyValue=e.target.value;
        this.setState({
            categoryTitleError:"",
        })
        this.setState({[e.target.name]: e.target.value},function () {

        });
    }

    sectorSelect=(value)=> {
        let selectedSectorValue =[];
        value.map((item)=>selectedSectorValue.push(item))
        this.setState({selectedSectorValue })
    }

    render() {
        console.log("this.state",this.state)
        // console.log("this.state",this.state,this.getCategoryType(this.state.categoryType))
        return (
            <>
                <div className="dash_body_container">
                    {/*<Header />*/}
                    {/*<Sidebar />*/}
                    {this.state.isLoader && <Loader />}
                    <div className="body_container">

                        <div className="header-text  mb-4 mt-3">

                            <div className="d-flex align-items-center">
                                <p className="header-icon"><Brands /></p>
                                <h4>Edit {this.state.categoryTitle}</h4>
                            </div>

                        </div>
                        <div className="rounded-body py-5">
                            <div className="cm-form-wrapper rounded-textbox-form add-manag px-0">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <level>Sector Name</level>
                                            <input type="text"
                                                   className="form-control"
                                                   placeholder="Enter Place of work name" name="categoryTitle" value={this.state.categoryTitle}
                                                   onChange={this.onChanged}
                                            />
                                            <InlineError
                                                message={this.state.categoryTitleError}
                                            />
                                        </div>

                                    </div>


                                </div>
                                <div className="row">
                                    <div className="col-lg-6 ">
                                        <div className="form-group">
                                            <level>Sector</level>
                                            <Select
                                                mode="multiple"
                                                className="cm_multiselect cmslect_multicheck"
                                                placeholder="Please select"
                                                style={{ width: '100%' }}
                                                onChange ={(e) =>this.sectorSelect(e)}
                                                value={this.state.selectedSectorValue}
                                            >
                                                {this.state.sectorDropDown}
                                            </Select>
                                            <InlineError
                                                message={this.state.sectorError}
                                            />
                                        </div>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 tab-add-btn-group">
                                        <Link to={"/placeofwork"} className="btn btn-secondary" >Cancel</Link>
                                        <button className="btn btn-primary" type='button' onClick={this.editCategory.bind(this)}>Save</button>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default EditPow;