import {
    MEETING_SUCCESS,
    EXPO_USER_SUCCESS,
    PAGE_USER_SUCCESS,
    USER_CONNECTION_SUCCESS,
    GROUP_ID,
    PAGE_ID,
    EXPO_ID,
    ACTIVE_CHAT,
    SELECTED_USER,
    CHAT_TOGGLE,
    CHAT_OPEN,
    NETWORK_TIME,
    PAGE_TYPE,
    EXPO_CHAT_TYPE,
    SHOWCASE_ID
} from "../action/group.actions";

import { _Api } from '../../services/Api';
import Url from '../../services/BaseUrl';

import Store from '../../redux/store/Store';

const InitalState = {
}



// get all  users details
export const groupDetail = (state = InitalState, action) => {

    switch (action.type) {

        case MEETING_SUCCESS:
            let data = action.meetingSuccess;

            return data

        default:
            return state
    }

}

const expoUserInitalState = []

export const expoUser = (state = expoUserInitalState, action) => {

    switch (action.type) {

        case EXPO_USER_SUCCESS:
            let data = action.Success;

            return data

        default:
            return state
    }

}
const pageUserInitalState = []

export const pageUser = (state = pageUserInitalState, action) => {

    switch (action.type) {

        case PAGE_USER_SUCCESS:
            let data = action.Success;

            return data

        default:
            return state
    }

}
const userConnectionInitalState = []

export const userConnection = (state = userConnectionInitalState, action) => {

    switch (action.type) {

        case USER_CONNECTION_SUCCESS:
            let data = action.Success;

            return data

        default:
            return state
    }

}

const chatIdState = {
    groupId: "",
    pageId: "",
    expoId: "",
    selectedUser: {},
    activeChatType: 2,
    isChatOpen: 0,
    pageType: "",
    expoChatType: 2,
    showCaseId: ""
}

export const chatId = (state = chatIdState, action) => {

    switch (action.type) {

        case GROUP_ID:
            return Object.assign(state, { groupId: action.id });
            break;

        case PAGE_ID:
            return Object.assign(state, { pageId: action.id });
            break;

        case EXPO_ID:
            return Object.assign(state, { expoId: action.id });
            break;

        case ACTIVE_CHAT:
            return Object.assign(state, { activeChatType: action.activeChatType, expoChatType: action.expoChatType });
            break;

        case SELECTED_USER:
            return Object.assign(state, { selectedUser: action.selectedUser });
            break;

        case CHAT_TOGGLE:
            return Object.assign(state, { isChatOpen: !state.isChatOpen });
            break;

        case CHAT_OPEN:
            // let userType = localStorage.getItem("userType")
            // if (userType !== 0) {
            //     let req = {
            //         otherUserId: localStorage.getItem('id'),
            //         type: 2,
            //         exhibitionId: localStorage.getItem('exhibition')
            //     }
            //     _Api(Url.CHECK_PLAN_USER.method, Url.CHECK_PLAN_USER.url, req)
            //         .then(resp => {
            //             const { isBuy } = resp?.responseData
            //             if (isBuy == 0) {
            //                 Store.dispatch({ type: 'SHOW_PLAN_USER_POPUP' })
            //                 return state;

            //             }
            //             else
            //                 return Object.assign(state, { isChatOpen: 1 });
                        
            //         }).catch(err => {
            //             return state

            //         })
            //         return state;

            // }
            // else
                return Object.assign(state, { isChatOpen: 1 });
            break;

        case PAGE_TYPE:
            return Object.assign(state, { pageType: action.pageType });
            break;

        case EXPO_CHAT_TYPE:
            return Object.assign(state, { expoChatType: action.expoChatType });
            break;
        case SHOWCASE_ID:
            return Object.assign(state, { showCaseId: action.id });
            break;

        default:
            return state
    }

}

const networkState = {
    timer: 60000,
}

export const networking = (state = networkState, action) => {

    switch (action.type) {

        case NETWORK_TIME:
            return Object.assign(state, { timer: action.timer });
            break;

        default:
            return state
    }

}