import { Link } from "react-router-dom";
import React from "react";

export const Pagination = (props) => {
    const { totalPage, currentPage } = props

    const onPress = (type) => {
        if (type == 'IN') {
            if (currentPage < totalPage) {
                props.onPressBtn(currentPage + 1)
            }
        }
        else {
            if (currentPage > 1) {
                props.onPressBtn(currentPage - 1)
            }
        }
    }


    return (
        <div className="pagination-wrapper">
            <div className=" d-flex align-items-center">
                {currentPage != 1 && <a onClick={() => onPress("DE")} > <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                    <span ><strong>Previous</strong></span> </a>}
                <div className="pagination-process-circle active ml-2">{props.currentPage}</div> of &nbsp;
                 <div className="pagination-process-circle"> {totalPage} </div>

                {/* <div className="pagination-process-circle">2</div>
            <div className="pagination-process-circle">3</div>
            <div className="pagination-process-circle">4</div>
            <div className="pagination-process-circle">5</div> */}
                {currentPage != totalPage && <a onClick={() => onPress("IN")} > <span  ><strong>Next</strong></span>
                    <i class="fa fa-long-arrow-right" aria-hidden="true"></i>  </a>}
            </div>

        </div>

    )
}