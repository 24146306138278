import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import { Hert, Chat, LandingUser, Cross, BusinessCalling, ZoomVideo, MapMarker, Clock, FooterLocation } from '../../SvgIcon';
import socket from '../../services/socket';
import { connect } from "react-redux";
import { getExhibitionId, groupActions, setCount, setCountSupport } from "../../redux/action";
import { createBrowserHistory } from "history";
import dateFormat from 'dateformat';
import { Misscall } from "../../SvgIcon";
import { Tooltip, Button } from 'antd';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import Linkify from 'react-linkify';
import { withRouter } from "react-router-dom";
import ContentEditable from 'react-contenteditable';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import TooltipTrigger from 'react-popper-tooltip';
import ReactTooltip from "react-tooltip";
import siteSetting from '../../config/env/Index';
import { SecureLink } from "react-secure-link";
import ProfileModal from './ProfileModal';
import { Tabs } from 'antd';
import { Modal } from 'antd'
import Velocity from 'velocity-animate';
import { notify } from '../../components/Toaster';

import { getTimeZone, getTimeFormate } from "../../utils/DateZone"


import { _Api } from "../../services/Api";
import Url from "../../services/BaseUrl";

var _ = require('lodash');

const { TabPane } = Tabs;

var demoMode = true; // used for tab

const tabs = [
    {
        title: 'Page',
    },
    {
        title: 'Expo',
    },
    {
        title: 'Showcase',
    }
];

const history = createBrowserHistory();

const text = <span>prompt text</span>;

const buttonWidth = 70;
let isProd = process.env.REACT_APP_ENV == 'prod'
var lastScrollTop = 0;

const AdminId = siteSetting.AdminId

class ChatBoxHeaderNew extends Component {
    constructor() {
        super();
        var isTooltipResponse = this.getDeviceType();
        this.state = {
            isShow: false,
            chatType: 1,
            message: '',
            expoMsgList: [],
            onlineUser: [],
            groupId: "",
            pageId: "",
            expoId: "",
            pageMsgList: [],
            userMsgList: [],
            selectedUser: {},
            selectedUserId: "",
            pageUser: [],
            userConnection: [],
            expoUser: [],
            pageName: "",
            totalUnreadMsg: 0,
            isTooltip: isTooltipResponse,
            isChatExpand: false,
            showCaseMsgList: [],
            showCaseUser: [],
            showCaseUnreadCount: 1,
            showProfileModal: false,
            selectedUserInfo: {},
            current: 1, //used to find current tab
            tooltipUser: {},
            tooltipUserId: "",

            flag: undefined,
            animatePosition: 33,
            width: 33,
            data: 3,
            isShowGlobalPopup: false,
            globalMessage: '<span></span>',
            flag1: false
        }

        this.dropdownBoxRef = React.createRef();

        this.contentEditableRef = React.createRef();
        this.ChatBox = React.createRef();
        //this.messagesEnd=React.createRef();
        this.onChanged = this.onChanged.bind(this);
        // this.checkUserType=this.checkUserType.bind(this);

        //used for tabs
        this.changeTab = this.changeTab.bind(this);
        this.moveTracker = this.moveTracker.bind(this);

    }

    moveTracker() {
        var title = ReactDOM.findDOMNode(this).querySelector('.tabs__titles__title[data-active="true"]');
        var tracker = ReactDOM.findDOMNode(this).querySelector('.tabs__tracker');
        Velocity(tracker, { width: title.clientWidth, left: title.offsetLeft }, { duration: 1000, easing: [250, 15] })
    }

    changeTab2(e, title = "Expo") {
        // console.log( "ghhhhhhhhhhhhhhhhhhhhhhhhh", title)
        this.changeExpoChatType(2)

        this.setState({
            current: 1
        }, function () {
            this.moveTracker();
        })
    }

    changeTab(e, title) {
        console.log(e.target.value, "ghhhhhhhhhhhhhhhhhhhhhhhhh", title)
        if (title === "Page") {
            this.changeExpoChatType(1)
        }
        if (title === "Expo") {
            this.changeExpoChatType(2)
        }
        if (title === "Showcase") {
            this.changeExpoChatType(3)
        }
        e.stopPropagation();
        console.log("sssssssssssss", e.target.getAttribute('data-index'))
        this.setState({
            current: e.target.getAttribute('data-index')
        }, function () {
            this.moveTracker();
        })
    }

    getDeviceType = () => {
        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            return 0;
        }
        if (
            /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
                ua
            )
        ) {
            return 0;
        }
        return 1;
    };
    callback = (key) => {
        console.log(key, "gggggggggggg");
    }
    handleChange = evt => {
        let msg = evt.target.value;
        console.log("msg", msg)
        if (msg.startsWith("<br><br>")) {
            msg = msg.slice(0, -8);
        }
        if (msg.startsWith("&nbsp;")) {
            msg = msg.replace(/&nbsp;/g, '');
        }
        msg = msg.replace(/(<((?!br)[^>]+)>)/ig, " ");
        //msg=msg.replace(/<br\s*[\/]?>/gi," ");
        if (msg.endsWith("<div><br></div>")) {
            msg = msg.slice(0, -15);
        }
        if (msg.endsWith("<br>  <br> ")) {
            msg = msg.slice(0, -11);
        }
        this.setState({ message: msg });
    };

    getDate = (info) => {
        if (info) {
            var date = new Date(info)
            return dateFormat(date, "dddd, mmmm dS yyyy").toLocaleString();  //June 5th, 2020, 7:40:00 pm
        }
        return null;
    }

    getTime = (info) => {
        if (info) {
            var date = new Date(info)
            return dateFormat(date, "h:MM TT").toLocaleString();  //June 5th, 2020, 7:40:00 pm
        }
        return null;
    }

    handleClose = () => {
        this.setState({ isShowGlobalPopup: false, globalMessage: '', })
    }

    checkDefaultActiveChat = (path) => {
        let urlArr = [
            "/media-viewed",
            "/job-viewed",
            "/view-block-connections",
            "/view-pending-connections",
            "/view-requested-connections",
            "/view-suggested-connections",
            "/view-connections",
            "/user/profile/:id",
            "/community",
            "/dashboard",
            "/profile",
            "/profile/edit",
            "/change-password",
            "/networking/search",
            "/document-download",
            "/cartview",
            "/manage-services",
            "/enquiry",
            "/enquiry",
            "/event-detail",
            "/myevent",
            "/manage-company",
            "/edit-manage-company",
            "/edit-sectors-categories",
            "/plan",
            "/pay-user",
            "/enquiry-details/:enId",
            "/calling",
            "/calling/:id"
        ]
        if (_.includes(urlArr, path)) {
            return true;
        } else {
            return false;
        }
    }

    componentDidMount() {
        console.log("notify", `${siteSetting.env}`)
        let self = this;



        if (this.checkDefaultActiveChat(this.props.pathDatail.path)) {
            this.setState({
                chatType: 2
            })
            self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 2, expoChatType: 2 })
        }
        Events.scrollEvent.register('begin', function (to, element) {

        });

        Events.scrollEvent.register('end', function (to, element) {

        });

        if (localStorage.getItem('accessToken') && localStorage.getItem('accessToken') !== '') {
            let exhibitionId = self.props.loading.exhibitionId;
            if (exhibitionId !== "" && exhibitionId !== null) {
                self.socketEvent();
            } else {
                self.props.dispatch(getExhibitionId()).then(function () {
                    self.socketEvent();
                })
            }
        }
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside = e => {
        //alert(12);
        if (this.ChatBox && this.ChatBox.current && !this.ChatBox.current.contains(e.target)) {
            const element = document.querySelector("body");
            if (element.classList.contains("webchatopen")) {
                if (!element.classList.contains("conference")) {
                    this.setState({ isChatExpand: false });
                    this.props.dispatch({ type: 'CHAT_TOGGLE' })
                }
            }
            //document.querySelector('body').classList.remove('');

        }
    };

    switchFunction = (flag, data) => {
        this.setState({ flag: flag })
        let tempData = data ? data : this.state.data

        if (tempData === 1) {
            this.setState({ animatePosition: 0 })
        }

        if (tempData === 2) {
            if (flag === 1) {
                this.setState({ animatePosition: 0 })
            }
            else if (flag === 2 && this.props.userDetail.userType == 0) {
                this.setState({ animatePosition: 50 })
            }
            else if (flag === 2 && this.props.userDetail.userType != 0) {
                this.setState({ animatePosition: 0 })
            }
            else {
                this.setState({ animatePosition: 50 })
            }
        }

        if (tempData === 3) {
            if (flag === 3) {
                this.setState({ animatePosition: 66 })
            }
            else if (flag === 2) {
                this.setState({ animatePosition: 33 })
            }
            else {
                this.setState({ animatePosition: 0 })

            }
        }
        console.log(tempData, "flag", flag, data, this.props.userDetail.userType);
    }
    socketEvent = () => {
        let self = this;
        let exhibitionId = self.props.loading.exhibitionId;
        self.props.dispatch(groupActions.expoUserList({ exhibitionId: exhibitionId }))
            .then(function (user) {
                self.setState({
                    expoUser: self.props.expoUser
                })
            })

        self.props.dispatch(groupActions.userConnectionList({ sortField: "lastMsgtimeStamp", sortType: 2, type: 7 }))
            .then(function (user) {
                self.setState({
                    userConnection: self.props.userConnection
                }, function () {
                    self.unreadMsg({});
                })
            })

        if (localStorage.getItem('userType') == 2) {
            socket.emit('groupAdd', { id: localStorage.getItem('id'), type: 5 });
        }
        if (localStorage.getItem('userType') == 4) {
            socket.emit('groupAdd', { id: localStorage.getItem('ownerId'), type: 5 });
        }
        socket.emit('groupAdd', { id: exhibitionId, type: 1 });

        self.groupAdd(self.props);
        socket.emit('groupAdd', { type: 22, userId: AdminId });

        if (self.props.chatId.groupId && self.props.chatId.groupId !== "") {
            socket.emit('groupAdd', { id: self.props.chatId.groupId, type: 7 });
        }

        socket.on('connect', function (data) {
            console.log("connect", data);
            //socket.emit('getMessage',{groupId:exhibitionId,type:1});

            socket.emit('groupAdd', { id: exhibitionId, type: 1 });

            self.groupAdd(self.props);

            if (self.state.selectedUserId && self.state.selectedUserId !== "") {
                console.log("user connect", self.state.selectedUserId)
                socket.emit('groupAdd', { type: 7, userId: self.state.selectedUserId });
            }
        });
        socket.on(`${siteSetting.env}`, function (data) {            
            console.log("broadCastNow", data, `${siteSetting.env}`)
            const { message } = data
            self.setState({ globalMessage: message, isShowGlobalPopup: true })
            // console.log("yesNow", data)

        })
        socket.on('reconnecting', function (data) {
            console.log("reconnecting", data);
            //socket.emit('getMessage',{groupId:exhibitionId,type:1});

            socket.emit('groupAdd', { id: exhibitionId, type: 1 });

            self.groupAdd(self.props);

            if (self.state.selectedUserId && self.state.selectedUserId !== "") {
                console.log("user connect", self.state.selectedUserId)
                socket.emit('groupAdd', { type: 7, userId: self.state.selectedUserId });
            }
        });
        socket.on('error', function (error) {
            console.log("err_socket", error)

        })
        socket.on('connect_error', function (error) {
            console.log("err_socket", error)

        })
        socket.on('online', function (params) {
            console.log("online", params);
            self.changeUserStatus(params.user._id, 1);
        })
        socket.on('offline', function (params) {
            console.log("offline", params);
            self.changeUserStatus(params.user._id, 0);
        })
        socket.on('groupMsg', function (params) {
            console.log("groupMsg", params);
            if (params.type === 7) {
                var checkUser = _.find(self.state.userConnection, function (obj) {
                    return obj.groupId === params.group;
                });
                console.log("checkUser", checkUser);
                if (checkUser) {
                    checkUser.lastMsgtimeStamp = params.lastMsgtimeStamp;
                    checkUser.lastMessageDate = params.lastMessageDate;
                    let userConnection = self.state.userConnection;
                    var index = _.findIndex(userConnection, { _id: checkUser._id });
                    userConnection.splice(index, 1, checkUser);
                    self.setState({
                        userConnection
                    }, function () {
                        self.userListSort();
                    })
                }
                if (params.group === self.props.chatId.groupId && self.props.selectedUserId !== "") {
                    self.setState(prevState => ({
                        userMsgList: [...prevState.userMsgList, params]
                    }), function () {
                        socket.emit('messageRead', { groupId: self.props.chatId.groupId, readType: 1 });
                        if (params.userData._id === self.props.userDetail._id) {
                            self.scrollToBottom();
                        } else {
                            self.scrollToBottomLive();
                        }
                        self.userListSort();
                    })
                }
            } else if (params.type === 1) {
                if (params.group === self.props.chatId.expoId) {
                    self.setState(prevState => ({
                        expoMsgList: [...prevState.expoMsgList, params]
                    }), function () {
                        if (params.userData._id === self.props.userDetail._id) {
                            self.scrollToBottom();
                        } else {
                            self.scrollToBottomLive();
                        }
                        self.expoUserListSort();
                    })
                }
            } else {
                if (params.type !== 22) {
                    if (params.group === self.props.chatId.pageId) {
                        self.setState(prevState => ({
                            pageMsgList: [...prevState.pageMsgList, params]
                        }), function () {
                            if (params.userData._id === self.props.userDetail._id) {
                                self.scrollToBottom();
                            } else {
                                self.scrollToBottomLive();
                            }
                            self.pageUserListSort();
                        })
                    }
                    if (params.type === 5 && self.props.chatId.showCaseId === params.group) {
                        self.setState(prevState => ({
                            showCaseMsgList: [...prevState.showCaseMsgList, params]
                        }), function () {
                            if (params.userData._id === self.props.userDetail._id) {
                                self.scrollToBottom();
                            } else {
                                self.scrollToBottomLive();
                            }
                        })
                        self.unreadMsg(params);
                    }
                }
            }

        })
        socket.on('checkChatPlan', function (params) {
            self.props.dispatch({ type: 'SHOW_PLAN_USER_POPUP' })

        })

        socket.on('checkCallPlan', function (params) {
            self.props.dispatch({ type: 'SHOW_PLAN_USER_POPUP' })

        })


        socket.on('groupADD', function (params) {
            console.log("groupADD", params)
            socket.emit('getMessage', params);
            if (params.type === 22) {
                socket.emit('getMyUserList', params);
            }

            if (params.type === 7) {
                socket.emit('getMyUserList', params);
            } else if (params.type === 1) {
                self.props.dispatch({ type: 'EXPO_ID', id: params.groupId })
                self.setState({
                    expoId: params.groupId
                })
            } else {
                if (params.type !== 22) {
                    if (params.type) {
                        socket.emit('getMyUserList', params);
                    }
                    self.props.dispatch({ type: 'PAGE_ID', id: params.groupId })
                    self.props.dispatch({ type: 'PAGE_TYPE', pageType: params.type })
                    self.setState({
                        pageId: params.groupId
                    })
                    if (params.type === 5 && params.isShowCase === true) {
                        self.props.dispatch({ type: 'SHOWCASE_ID', id: params.groupId })
                    }
                }
            }
        })
        socket.on('groupId', function (params) {
            console.log("groupId", params)
            if (params.type === 7) {
                self.props.dispatch({ type: 'GROUP_ID', id: params.groupId })
                self.setState({
                    groupId: params.groupId
                })
                //params.userData.unReadMsgCount=0;
                var checkUser = _.result(_.find(self.state.userConnection, function (obj) {
                    return obj._id === params.userData._id;
                }), '_id');
                console.log("checkUser", checkUser);
                if (!checkUser) {
                    if (self.props.userDetail._id !== params.userData._id) {
                        self.setState({
                            userConnection: [...self.state.userConnection, params.userData]
                        })
                    }
                } else {
                    params.userData.unReadCount = { count: 0 };
                    let userConnection = self.state.userConnection;
                    var index = _.findIndex(userConnection, { _id: params.userData._id });
                    userConnection.splice(index, 1, params.userData);
                    self.setState({
                        userConnection
                    })
                }
            } else if (params.type === 1) {
                var checkUser = _.result(_.find(self.state.expoUser, function (obj) {
                    return obj._id === params.userData._id;
                }), '_id');
                if (!checkUser) {
                    self.setState({
                        expoUser: [...self.state.expoUser, params.userData]
                    })
                }
            } else {
                if (params.type !== 22) {
                    if (self.props.chatId.pageId === params.groupId) {
                        var checkUser = _.result(_.find(self.state.pageUser, function (obj) {
                            return obj._id === params.userData._id;
                        }), '_id');
                        if (!checkUser) {
                            self.setState({
                                pageUser: [...self.state.pageUser, params.userData]
                            })
                        }
                    }
                    if (params.type === 5 && self.props.chatId.showCaseId === params.groupId) {
                        var checkUser = _.result(_.find(self.state.showCaseUser, function (obj) {
                            return obj._id === params.userData._id;
                        }), '_id');
                        if (!checkUser) {
                            self.setState({
                                showCaseUser: [...self.state.showCaseUser, params.userData]
                            })
                        }
                    }
                }
            }
        })
        socket.on('groupRemove', function (params) {
            console.log("groupRemove", params)
            //console.log("groupRemove", params)
            if (params.type === 1) {
                let filteredArray = self.state.expoUser.filter(obj => obj._id !== params.userData._id)
                self.setState({ expoUser: filteredArray });
            } else {
                if (params.type !== 22) {
                    let filteredArray = self.state.pageUser.filter(obj => obj._id !== params.userData._id)
                    self.setState({ pageUser: filteredArray });
                    if (params.type === 5 && params.groupId === self.props.chatId.showCaseId) {
                        let filteredArrayShowCase = self.state.showCaseUser.filter(obj => obj._id !== params.userData._id)
                        self.setState({ showCaseUser: filteredArrayShowCase });
                    }
                }
            }
        })
        socket.on('lastMsg', function (params, type, lastMsgId, order) {
            console.log("lastMsggg", params, type, lastMsgId, order)
            if (type === 7) {
                let reverseData = _.reverse(_.clone(params.data));
                if (lastMsgId) {
                    let margeData = _.merge(_.keyBy(reverseData, '_id'), _.keyBy(self.state.userMsgList, '_id'));
                    var values = _.values(margeData);

                    if (values && values.length > 0) {
                        self.setState({
                            userMsgList: values
                        })
                    }
                } else {
                    self.setState(prevState => ({
                        userMsgList: reverseData
                    }), function () {
                        socket.emit('messageRead', { groupId: self.props.chatId.groupId, readType: 1 });  //all read
                        self.scrollToBottom();
                    })
                }
            } else if (type === 1) {
                let reverseData = _.reverse(_.clone(params.data));
                if (lastMsgId) {
                    let margeData = _.merge(_.keyBy(reverseData, '_id'), _.keyBy(self.state.expoMsgList, '_id'));
                    var values = _.values(margeData);

                    if (values && values.length > 0) {
                        self.setState({
                            expoMsgList: values
                        })
                    }
                } else {
                    self.setState({
                        expoMsgList: reverseData
                    }, function () {
                        self.scrollToBottom();
                    })
                }

            } else {
                if (type !== 22) {
                    let reverseData = _.reverse(_.clone(params.data));
                    if (lastMsgId) {
                        let margeData = _.merge(_.keyBy(reverseData, '_id'), _.keyBy(self.state.pageMsgList, '_id'));
                        var values = _.values(margeData);

                        if (values && values.length > 0) {
                            self.setState({
                                pageMsgList: values
                            })
                            if (type === 5 && self.props.chatId.showCaseId === params.groupId) {
                                self.setState({
                                    showCaseMsgList: values
                                })
                            }
                        }
                    } else {
                        self.setState(prevState => ({
                            pageMsgList: reverseData
                        }), function () {
                            self.scrollToBottom();
                        })
                        if (type === 5 && self.props.chatId.showCaseId === params.groupId) {
                            self.setState(prevState => ({
                                showCaseMsgList: reverseData
                            }), function () {
                                self.scrollToBottom();
                            })
                        }
                    }
                }
            }
        })

        socket.on('getMyUserList', function (params) {
            console.log("getMyUserList", params);
            if (params.type == 22) {
                self.unreadMsg2(params);
            }
            if (params.type === 7) {

                if (self.props.chatId.selectedUser && self.props.chatId.selectedUser._id !== "") {
                    //params.userData.unReadCount.count=0
                    let index = _.findIndex(params.user.data, { _id: self.props.chatId.selectedUser._id });
                    let user = _.find(params.user.data, { _id: self.props.chatId.selectedUser._id });
                    if (user) {
                        user.unReadCount.count = 0;
                        params.user.data.splice(index, 1, user);
                    }
                }

                self.setState({
                    userConnection: params.user.data
                }, function () {
                    self.unreadMsg(params);
                })
            } else if (params.type === 1) {
                self.setState({
                    expoUser: params.user.data
                })
            } else {
                if (params.type !== 22) {
                    self.setState({
                        pageUser: params.user.data
                    })
                    if (params.type === 5 && self.props.chatId.showCaseId === params.groupId) {
                        self.setState({
                            showCaseUser: params.user.data
                        })
                        self.unreadMsg(params);
                    }
                }
            }
        })
        socket.on('userListData', function (params) {
            console.log("userListDataaaa", params.type);
            if (params.type == 22) {
                self.unreadMsg(params);

            }
            if (params.type === 7) {

                var checkUser = _.result(_.find(self.state.userConnection, function (obj) {
                    return obj._id.toString() === params.userData._id.toString();
                }), '_id');
                if (!checkUser) {
                    self.setState({
                        userConnection: [...self.state.userConnection, params.userData]
                    }, function () {
                        self.unreadMsg(params);
                        self.userListSort();
                    })
                } else {
                    let userConnection = self.state.userConnection;
                    //console.log(self.props.chatId.selectedUser&&params.userData._id === self.props.chatId.selectedUser._id)
                    if (self.props.chatId.selectedUser && params.userData._id === self.props.chatId.selectedUser._id) {
                        params.userData.unReadCount.count = 0
                    } else {
                        let user = _.find(userConnection, { _id: params.userData._id });
                        console.log("user", user)
                        params.userData.unReadCount.count = user.unReadCount ? user.unReadCount.count + 1 : 0;
                    }
                    let index = _.findIndex(userConnection, { _id: params.userData._id });
                    userConnection.splice(index, 1, params.userData);
                    self.setState({
                        userConnection
                    }, function () {
                        self.unreadMsg(params);
                        self.userListSort();
                    })
                }

            }

        })

        socket.on('messageCount', function (params) {
            console.log("messageCount", params);
            let userConnection = self.state.userConnection;

            let user = _.find(userConnection, { groupId: params.groupId });
            console.log("user", user)
            if (user && user.unReadCount) {
                if (user.unReadCount) {
                    user.unReadCount.count = 0
                }
                let index = _.findIndex(userConnection, { groupId: params.groupId });
                userConnection.splice(index, 1, user);
                self.setState({
                    userConnection
                }, function () {
                    self.unreadMsg(params);
                })
            }
        })
    }

    changeUserStatus(userId, status) {
        let self = this;
        var checkUser = _.find(self.state.userConnection, function (obj) {
            return obj && obj._id === userId;
        });

        if (checkUser) {
            checkUser.isOnline = status;
            let userConnection = self.state.userConnection;
            var index = _.findIndex(userConnection, { _id: checkUser._id });
            userConnection.splice(index, 1, checkUser);
            self.setState({
                userConnection
            })
        }
    }

    componentWillUpdate(nextProps) {
        let self = this;
        //console.log("componentWillUpdate=========",nextProps,this.props);
        if (nextProps.pathDatail !== this.props.pathDatail) {
            // console.log("componentWillUpdate", nextProps);
            if (nextProps.chatId && nextProps.chatId.activeChatType === 2) {
                if (this.checkDefaultActiveChat(nextProps.pathDatail.path)) {
                    this.setState({
                        chatType: 2,
                        current: 1
                    })
                    self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 2, expoChatType: 2 })
                }
            }
            // this.changeTab2()
            this.groupAdd(nextProps);


        }
    }

    groupAdd = (props) => {
        let self = this;
        socket.emit('updateUser', {});
        console.log("self.props.match.path", props)
        if (props.pathDatail && props.pathDatail.path && (props.pathDatail.path === "/home")) {
            self.setState({
                "pageName": "HOME"
            })
            socket.emit('groupAdd', { type: 8 });
            //self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 2, expoChatType: 2 })
        } else if (props.pathDatail && props.pathDatail.path && (props.pathDatail.path === "/seminar")) {
            self.setState({
                "pageName": "CONFERENCE"
            })
            socket.emit('groupAdd', { type: 9 });
            //self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 2, expoChatType: 1 })
        } else if (props.pathDatail && props.pathDatail.path && (props.pathDatail.path === "/business" || props.pathDatail.path === "/business-list" || props.pathDatail.path === "/business-list/:searchKey")) {
            self.setState({
                "pageName": "BRAND"
            })
            socket.emit('groupAdd', { type: 10 });
            //self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 2, expoChatType: 1 })
        } else if (props.pathDatail && props.pathDatail.path && (props.pathDatail.path === "/job" || props.pathDatail.path === "/newjob" || props.pathDatail.path === "/job/:pageNo" || props.pathDatail.path === "/newjob/:pageNo")) {
            self.setState({
                "pageName": "PRODUCT"
            })
            socket.emit('groupAdd', { type: 11 });
            //self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 2, expoChatType: 1 })
        } else if (props.pathDatail && props.pathDatail.path && (props.pathDatail.path === "/industry")) {
            self.setState({
                "pageName": "SECTORS"
            })
            socket.emit('groupAdd', { type: 12 });
            //self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 2, expoChatType: 1 })
        } else if (props.pathDatail && props.pathDatail.path && (props.pathDatail.path === "/lounges")) {
            self.setState({
                "pageName": "LOUNGES"
            })
            socket.emit('groupAdd', { type: 13 });
            //self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 2, expoChatType: 1 })
        } else if (props.location && props.location.pathname && (props.location.pathname === "/networking")) {
            self.setState({
                "pageName": "NETWORK LOUNGES"
            })
            socket.emit('groupAdd', { type: 14 });
            //self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 2, expoChatType: 1 })
        } else if (props.pathDatail && props.pathDatail.path && (props.pathDatail.path === "/networking/:id")) {
            self.setState({
                "pageName": "NETWORK LOUNGES"
            })
            socket.emit('groupAdd', { type: 14 });
            //self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 2, expoChatType: 1 })
        } else if (props.pathDatail && props.pathDatail.path && props.pathDatail.path === "/network-lounge/:id") {
            self.setState({
                "pageName": "NETWORK LOUNGES"
            })
            socket.emit('groupAdd', { type: 15, id: props.pathDatail.params.id });
            //self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 2, expoChatType: 1 })
        } else if (props.pathDatail && props.pathDatail.path && props.pathDatail.path === "/seminar/:coId/:name/:id") {
            self.setState({
                "pageName": "STAGE"
            })
            socket.emit('groupAdd', { type: 3, id: props.pathDatail.params.id });
            //self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 2, expoChatType: 1 })
        } else if (props.pathDatail && props.pathDatail.path && props.pathDatail.path === "/stages") {
            self.setState({
                "pageName": "STAGES"
            })
            socket.emit('groupAdd', { type: 19 });
            //self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 2, expoChatType: 1 })
        } else if (props.pathDatail && props.pathDatail.path && props.pathDatail.path === "/seminar/:coId") {
            self.setState({
                "pageName": "CONFERENCE"
            })
            socket.emit('groupAdd', { type: 2, id: props.pathDatail.params.coId });
            //self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 2, expoChatType: 1 })
        } else if (props.pathDatail && props.pathDatail.path && props.pathDatail.path === "/lounge/:id") {
            self.setState({
                "pageName": "LOUNGE"
            })
            socket.emit('groupAdd', { type: 4, id: props.pathDatail.params.id });
            //self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 2, expoChatType: 1 })
        } else if (props.pathDatail && props.pathDatail.path && (props.pathDatail.path === "/business/:brandId" || props.pathDatail.path === "/business/profile/:brandId" || props.pathDatail.path === "/business/:brandId/:companyId")) {
            self.setState({
                "pageName": "BRAND"
            })
            socket.emit('groupAdd', { type: 5, id: props.pathDatail.params.brandId });
            //self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 2, expoChatType: 1 })
        } else if (props.pathDatail && props.pathDatail.path && (props.pathDatail.path === "/web-job-details/:id" || props.pathDatail.path === "/job/:id/detail" || props.pathDatail.path === "/job/:id")) {
            self.setState({
                "pageName": "PRODUCT"
            })
            socket.emit('groupAdd', { type: 6, id: props.pathDatail.params.id });
            //self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 2, expoChatType: 1 })
        } else if (props.pathDatail && props.pathDatail.path && props.pathDatail.path === "/community/view/page=:page") {
            self.setState({
                "pageName": "PEOPLE"
            })
            socket.emit('groupAdd', { type: 16 });
            //self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 2, expoChatType: 1 })
        } else if (props.pathDatail && props.pathDatail.path && props.pathDatail.path === "/search/results/all/page=:page&keywords=:searchKey") {
            self.setState({
                "pageName": "SEARCH"
            })
            socket.emit('groupAdd', { type: 17 });
            //self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 2, expoChatType: 1 })
        } else if (props.pathDatail && props.pathDatail.path && props.pathDatail.path === "/trainings") {
            self.setState({
                "pageName": "OFFERS"
            })
            socket.emit('groupAdd', { type: 18 });
            //self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 2, expoChatType: 1 })
        } else if (props.pathDatail && props.pathDatail.path && props.pathDatail.path === "/web-training-details/:id") {
            self.setState({
                "pageName": "OFFERS"
            })
            socket.emit('groupAdd', { type: 20, id: props.pathDatail.params.id });
            //self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 2, expoChatType: 1 })
        } else {
            //this page not available
            socket.emit('groupAdd', { type: 100 });
        }
    }

    changeChatType = (chatType) => {
        let self = this;
        if (this.props.chatId.activeChatType !== chatType) {
            if (chatType === 2) {
                self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: chatType, expoChatType: 2 })
            } else {
                this.switchFunction(2);
                self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: chatType, expoChatType: 2 })
            }
            this.setState({
                userMsgList: [],
                chatType,
                //groupId
            }, function () {
                if (chatType !== 3) {
                    self.setState({
                        selectedUser: {},
                        selectedUserId: "",
                    })
                    self.props.dispatch({ type: 'SELECTED_USER', selectedUser: {} })
                    self.props.dispatch({ type: 'GROUP_ID', id: "" })
                }
                setTimeout(function () {
                    self.scrollToBottom();
                }, 50)
            })
        }
    }

    changeExpoChatType = (chatType) => {
        this.switchFunction(chatType);
        let self = this;
        if (this.props.chatId.expoChatType !== chatType) {
            self.props.dispatch({ type: 'EXPO_CHAT_TYPE', expoChatType: chatType });
            setTimeout(function () {
                self.scrollToBottom();
            }, 50)
        }
    }

    sendMessage = (ev) => {
        let oldMessage = true
        let self = this;
        ev.preventDefault();
        //console.log("opsihgadh", this.state.message.trim())
        let msg = this.state.message;
        if (msg.endsWith("<div><br></div>")) {
            msg = msg.slice(0, -15);
        }
        self.setState({
            message: msg
        }, function () {
            if (self.props.chatId.activeChatType === 2) {
                if (self.props.chatId.expoChatType === 1) {
                    if (self.props.chatId.pageId !== "") {
                        if (self.state.message.trim() !== '') {
                            console.log("oldMessage", oldMessage)
                            socket.emit('groupSend', {
                                groupId: self.props.chatId.pageId,
                                message: self.state.message.trim(),
                                oldMessage: oldMessage

                            });
                            self.setState({
                                message: ''
                            }, function () {
                                self.contentEditableRef.current.focus();
                                self.scrollToBottom();
                            })
                        }
                    } else {
                        this.groupAdd(self.props);
                    }
                } else if (self.props.chatId.expoChatType === 2) {
                    if (self.props.chatId.expoId !== "") {
                        if (self.state.message.trim() !== '') {
                            console.log("oldMessage", oldMessage)
                            socket.emit('groupSend', {
                                groupId: self.props.chatId.expoId,
                                message: self.state.message.trim(),
                                oldMessage: oldMessage

                            });
                            self.setState({
                                message: ''
                            }, function () {
                                self.contentEditableRef.current.focus();
                                self.scrollToBottom();
                            })
                        }
                    } else {
                        let exhibitionId = self.props.loading.exhibitionId;
                        socket.emit('groupAdd', { id: exhibitionId, type: 1 });
                    }
                } else {
                    if (self.props.chatId.showCaseId !== "") {
                        if (self.state.message.trim() !== '') {
                            console.log("oldMessage", oldMessage)
                            socket.emit('groupSend', {
                                groupId: self.props.chatId.showCaseId,
                                message: self.state.message.trim(),
                                oldMessage: oldMessage

                            });
                            self.setState({
                                message: ''
                            }, function () {
                                self.contentEditableRef.current.focus();
                                self.scrollToBottom();
                            })
                        }
                    } else {
                        this.groupAdd(self.props);
                    }
                }
            }
            if (self.props.chatId.activeChatType === 3) {
                if (self.props.chatId.groupId !== "") {
                    if (self.state.message.trim() !== '') {
                        oldMessage = self.state.userMsgList.length == 0 ? false : true

                        if (oldMessage == false) {
                            let req = {
                                otherUserId: localStorage.getItem('id'),
                                type: 1,
                                exhibitionId: localStorage.getItem('exhibition')
                            }
                            _Api(Url.CHECK_PLAN_USER.method, Url.CHECK_PLAN_USER.url, req)
                                .then(resp => { }).catch(err => { })
                        }


                        console.log("oldMessage", oldMessage)
                        socket.emit('groupSend', {
                            oldMessage: oldMessage,
                            groupId: self.props.chatId.groupId, message: self.state.message.trim()
                        });
                        self.setState({
                            message: ''
                        }, function () {
                            self.contentEditableRef.current.focus();
                            self.scrollToBottom();
                        })
                    }
                } else {

                }
            }
        })

    }

    inputFocus = () => {
        if (this.contentEditableRef && this.contentEditableRef.current) {
            this.contentEditableRef.current.focus();
        }
    }

    onChanged(e) {
        let self = this;
        let keyName = e.target.name;
        let KeyValue = e.target.value;

        this.setState({ [e.target.name]: e.target.value }, function () {
        });
        if (e.target.name === 'categoryImg') {

            let reader = new FileReader();
            let file = e.target.files[0];

            reader.onloadend = () => {
                this.setState({
                    categoryImgPrev: reader.result
                });
            }
            reader.readAsDataURL(file)

            this.setState({
                categoryImgPrev: file
            })
            this.setState({
                categoryImg: e.target.files[0]
            });
        }
    }
    handleChatMenu = (e) => {

        let self = this;
        console.log("handleChatMenu expoChatType", self.props);



        if (self.props.userDetail.userType == 2 || self.props.userDetail.userType == 4) {
            if (!this.checkDefaultActiveChat(self.props.pathDatail.path)) {
                //3
                this.setState({ data: 3, width: 33 })
                console.log("flag aaaaaaaa")
                this.switchFunction(self.props.chatId.expoChatType, 3)


            } else {
                //2
                this.setState({ data: 2, width: 50 })
                console.log("flag bbbbbbbbb")
                this.switchFunction(self.props.chatId.expoChatType, 2);

            }
        } else {

            if (self.props.userDetail.userType != undefined) {
                if (!this.checkDefaultActiveChat(self.props.pathDatail.path)) {
                    //2
                    this.setState({ data: 2, width: 50 })

                    this.switchFunction(self.props.chatId.expoChatType, 2);

                } else {
                    //1
                    this.setState({ data: 1, width: 100 })
                    console.log("flag ddddddddd")
                    this.switchFunction(self.props.chatId.expoChatType, 1)

                }
            }

        }



        self.props.dispatch({ type: 'CHAT_TOGGLE' })
        this.setState({
            isChatExpand: !this.state.isChatExpand
        })

        document.body.classList.remove('chat_open_mob');
        self.inputFocus();
        // this.setState({
        //     isChatExpand:!this.state.isChatExpand
        // })

        setTimeout(function () {

            self.scrollToBottom();
        }, 100)
    }
    checkUserType = (message) => {
        if (this.props.userDetail._id === message.userData._id) {
            return true;
        } else {
            return false
        }
    }

    scrollToBottom = () => {
        // const isShow = localStorage.getItem("showAll") == "true"
        // if(!isShow) return
        var st = this.chatbody ? this.chatbody.scrollTop : 0;
        try {
            this.messagesEnd.scrollIntoView({ behavior: "smooth" })

        } catch (err) {
            console.log("scrollToBottom err", err);
        }
        lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
        //console.log("scrollToBottom",lastScrollTop,this.chatbody.scrollTop);
    }

    scrollToBottomLive = () => {
        console.log("scrollToBottomLive");
        var st = this.chatbody ? this.chatbody.scrollTop : 0; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
        console.log("live", st >= lastScrollTop, st, lastScrollTop)
        if (st >= lastScrollTop) {
            try {
                this.messagesEnd.scrollIntoView({ behavior: "smooth" });

            } catch (err) { }
            lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
        }
    }

    selectUser = (user) => {
        let self = this;
        if (this.props.userDetail._id !== user._id && this.props.chatId.selectedUser._id !== user._id) {
            this.setState({
                selectedUser: user,
                selectedUserId: user._id,
                chatType: 3,
                isShow: true
            }, function () {
                self.props.dispatch({ type: 'CHAT_OPEN' })
                self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 3 })
                self.props.dispatch({ type: 'SELECTED_USER', selectedUser: user })
                socket.emit('groupAdd', { type: 7, userId: user._id });
                let userConnection = self.state.userConnection;
                let user1 = _.find(userConnection, { _id: user._id });
                //console.log("user1", user1)
                if (user1) {
                    console.log("user", user1)
                    user1.unReadCount = { count: 0 };
                    let index = _.findIndex(userConnection, { _id: user._id });
                    userConnection.splice(index, 1, user1);
                    self.setState({
                        userConnection
                    })
                }
            })
        }
    }

    videoCalling = (userId) => {
        let self = this;
        if (userId !== "" && self.props.chatId.groupId !== "") {
            window.open('/calling/' + self.props.chatId.groupId, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
        }
    }

    shouldShowContactAvatar = (item, i, chat) => {
        return (
            item.userData._id !== this.props.userDetail._id &&
            ((chat[i + 1] && chat[i + 1].userData._id === this.props.userDetail._id) || !chat[i + 1])
        );
    }

    shouldShowGroupContactAvatar = (item, i, chat) => {
        return (
            ((chat[i - 1] && chat[i] && chat[i - 1].userData._id !== chat[i].userData._id) || !chat[i - 1])
        );
    }

    shouldShowTime = (item, i, chat) => {
        return (
            ((chat[i + 1] && chat[i + 1].timeStamp && new Date(chat[i + 1].timeStamp).getDate() !== new Date(chat[i].timeStamp).getDate()) || !chat[i + 1])
        );
    }

    shouldShowDate = (item, i, chat) => {
        return (
            ((chat[i - 1] && chat[i - 1].timeStamp && new Date(chat[i - 1].timeStamp).getDate() !== new Date(chat[i].timeStamp).getDate()) || !chat[i - 1])
        );
    }

    callTime = (message) => {
        if (message.callEndTime && message.callEndTime !== "" && message.callStartTime && message.callStartTime !== "") {
            var diffMs = (new Date(message.callEndTime) - new Date(message.callStartTime));
            if (diffMs) {
                return (<>&nbsp;|&nbsp;<b>{Math.round(diffMs / 60000)}min</b></>)
            }
        }
    }

    onChatBodyScroll(e) {
        //console.log("onChatBodyScroll",e)
        var st = this.chatbody.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
        //console.log("st < lastScrollTop",st <= lastScrollTop,st , lastScrollTop)
        if (st <= lastScrollTop && lastScrollTop < 10) {
            console.log("onChatBodyScroll ======")
            let groupId = '';
            let lastMsgId = '';
            let type = "";
            if (this.props.chatId.activeChatType === 3) {
                groupId = this.props.chatId.groupId;
                lastMsgId = this.state.userMsgList[0] ? this.state.userMsgList[0]._id : "";
                type = 7;
            } else if (this.props.chatId.activeChatType === 2) {
                groupId = this.props.chatId.expoId;
                lastMsgId = this.state.expoMsgList[0] ? this.state.expoMsgList[0]._id : "";
                type = 1;
            } else {
                groupId = this.props.chatId.pageId;
                lastMsgId = this.state.pageMsgList[0] ? this.state.pageMsgList[0]._id : "";
                type = this.props.chatId.pageType
            }
            if (groupId !== '' && lastMsgId !== '') {
                console.log("lastMsgId", lastMsgId);
                socket.emit('getMessage', { groupId, lastMsgId, order: 1, type });
            }
            lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
        }

    }

    openProfile = (user) => {
        //console.log("user",user);
        this.setState({ showProfileModal: true, selectedUserInfo: user })
        // let self=this;
        // if(this.props.userDetail._id !== userId) {
        //     if (self.props.match && (self.props.match.path === "/calling/:id" || self.props.match.path === "/networking/search" || self.props.match.path === "/lounge/:id" || self.props.match.path === "/network-lounge/:id")) {

        //     } else {
        //         if (userId && userId !== "") {
        //             self.props.history.push('/user/profile/' + userId)
        //         }
        //     }
        // }
    }
    closeProfile = () => {

        this.setState({ showProfileModal: false })
    }

    unreadMsg2(params) {
        console.log("asdsadasd", params)
        let self = this;
        let totalUnreadMsg = 0;
        _.map(self.state.userConnection, function (user) {
            if (user && user.unReadCount && user.unReadCount.count) {
                totalUnreadMsg = totalUnreadMsg + user.unReadCount.count;
            }
        })
        if (params?.type == 22) {
            self.props.dispatch(setCountSupport(params?.user?.data[0]?.unReadCount?.count == 0 ? false : true))
        }
        else
            self.props.dispatch(setCount(totalUnreadMsg))

        self.setState({
            totalUnreadMsg,
        })
    }


    unreadMsg(params) {
        console.log("asdsadasddddddddd", params)

        let self = this;
        let totalUnreadMsg = 0;
        _.map(self.state.userConnection, function (user) {
            if (user && user.unReadCount && user.unReadCount.count) {
                totalUnreadMsg = totalUnreadMsg + user.unReadCount.count;
            }
        })
        if (params?.type == 22) {
            self.props.dispatch(setCountSupport(params?.userData?.unReadCount?.count == 0 ? false : true))
        }
        else
            self.props.dispatch(setCount(totalUnreadMsg))

        self.setState({
            totalUnreadMsg,
        })
    }

    userListSort() {
        let self = this;
        let userConnection = self.state.userConnection;

        let sorted = userConnection.sort((a, b) => b['lastMsgtimeStamp'] - a['lastMsgtimeStamp']);
        //let sortRes=_.orderBy(userConnection, ['lastMsgtimeStamp'],['desc']);
        console.log("sortRes", sorted, userConnection)

        self.setState({
            userConnection: sorted
        })
    }

    pageUserListSort() {
        let self = this;
        let pageUser = self.state.pageUser;

        let sorted = pageUser.sort((a, b) => b['lastMsgtimeStamp'] - a['lastMsgtimeStamp']);
        //let sortRes=_.orderBy(userConnection, ['lastMsgtimeStamp'],['desc']);
        console.log("sortRes", sorted, pageUser)

        self.setState({
            pageUser: sorted
        })
    }

    expoUserListSort() {
        let self = this;
        let expoUser = self.state.expoUser;

        let sorted = expoUser.sort((a, b) => b['lastMsgtimeStamp'] - a['lastMsgtimeStamp']);
        //let sortRes=_.orderBy(userConnection, ['lastMsgtimeStamp'],['desc']);
        console.log("sortRes", sorted, expoUser)

        self.setState({
            expoUser: sorted
        })
    }

    handleKeyUp = (e) => {
        //console.log("e",e,e.target.value)
        if (e.key === '') {
            this.contentEditableRef.current.innerHTML = '';
        }
        if (e.key === 'Enter' && e.shiftKey) {

        } else if (e.key === 'Enter') {
            this.sendMessage(e)
        }
    }

    handleKeyPress = (e) => {
        console.log("#####e", e.key)
        console.log("#####e", this.contentEditableRef.current.innerHTML)
        // if (e.key === 'Enter'&& e.shiftKey) {
        //
        // }else if(e.key === 'Enter'){
        //
        // }
        if (e.key === '') {
            this.contentEditableRef.current.innerHTML = '';
        }
    }

    refreshPage = () => {
        window.location.reload(false)
    }

    checkSeletedUser = (user) => {
        console.log(user, "checkSeletedUser");
        if (this.props.chatId.selectedUser && user && this.props.chatId.selectedUser._id === user?._id) {
            return true;
        } else {
            return false;
        }
    }

    getJobRole = (user) => {
        if (user.jobRole && user.jobRole.length > 0) {
            return _.map(user.jobRole, function (obj, i) {
                if (i < user.jobRole.length) {
                    return `${obj.name}${i != user.jobRole.length - 1 ? "," : ""}`
                } else {
                    return obj.name
                }
            })
        } else {
            return user.jobTitleOther ? user.jobTitleOther : ""
        }
    }

    chatHeaderMsg = () => {
        if (this.props.chatId.expoChatType === 1) {
            return "Chat visible to everyone on page"
        } else if (this.props.chatId.expoChatType === 2) {
            return "Chat visible to everyone in exhibition"
        } else {
            return "This is the live chat on your showcase"
        }
    }

    handleAcceptReject = (id, meetingStatus, item) => {
        // let { page, limit, search } = this.state
        item.callStatus = meetingStatus
        this.setState({ flag1: !this.state.flag1 })
        let req = {
            meetingId: id,
            meetingStatus

        }
        _Api(Url.ACCEPTREJECT_MEETING.method, Url.ACCEPTREJECT_MEETING.url, req)
            .then(resp => {



            }).catch(err => {
                let errorMessage = err.error && err.error.responseMessage || err.message;
                notify("err", errorMessage);


            })

    }

    render() {
        console.log("this.state", this.state)
        console.log("this.props", this.props)
        let { tooltipUser } = this.state;

        const isShow = true

        // if (!isShow) {
        //     return <></>
        // }
        // else if (!isShow)
        //     return <>
        //      {this.state.isShowGlobalPopup && <>
        //                 <Modal
        //                     wrapClassName="signup_parent_modal"
        //                     className="cm_popup signup_modal cstmMdl-notification"
        //                     //cm_popup term-services
        //                     visible={this.state.isShowGlobalPopup}
        //                     // onOk={this.handleOk}
        //                     onCancel={this.handleClose}
        //                     footer={null}
        //                     centered
        //                     maskClosable={false}
        //                 >
        //                     <div className="sign-modal-content">
        //                         <div className="row align-items-center text-center">
        //                             <div className="col-md-12" >
        //                                 {/* <div className="logo">
        //                             <img src={require('../../assets/images/splash.png')} className="img-fluid splash-img" />
        //                             <img src={require('../../assets/images/logo.png')} className="img-fluid logo-img" />
        //                         </div> */}
        //                                 <div dangerouslySetInnerHTML={{ __html: this.state.globalMessage }}></div>


        //                             </div>
        //                         </div>

        //                     </div>
        //                 </Modal>

        //             </>}
        //     </>
        // else
            return (
                <>
                    <div className={`chat-wrapper ${this.props.chatId.isChatOpen ? 'showchat' : ''}`} ref={this.ChatBox}>
                        {
                            // profile modal
                            this.state.showProfileModal && <ProfileModal closeModalProfile={this.handleChatMenu} closeModal={this.closeProfile} showModal={this.openProfile} selectedUserInfo={this.state.selectedUserInfo} />
                        }
                        <div className="chat-header">

                            <div className={`chat-icon-wrapper ${this.props.chatId.isChatOpen ? '' : 'bg-blue'}`} onClick={this.handleChatMenu}>

                                {this.props.chatId.isChatOpen ?
                                    <span className="chat pos-relative cros"><Cross /></span>
                                    :
                                    <span className="chat pos-relative"><Chat />{this.state.totalUnreadMsg ? <span
                                        className="counter chat-counter white-counter">{this.state.totalUnreadMsg}</span> : ""}</span>
                                }
                            </div>
                            {this.props.chatId.activeChatType === 3 && this.props.chatId.selectedUser._id ?
                                <div className="chat-current-profile">
                                    {
                                        this.props.chatId.selectedUser._id ?
                                            <>
                                                <img onClick={() => this.openProfile(this.props.chatId.selectedUser)} className="img-fluid" src={this.props.chatId.selectedUser.image ? this.props.chatId.selectedUser.image : require(`../../assets/images/login_user.png`)} />
                                                {/*<span className={(this.props.chatId.selectedUser && (this.props.chatId.selectedUser.isOnline === 1 || this.props.chatId.selectedUser.isOnline === "1")) ? "dot green" : "dot yellow"}></span>*/}
                                            </>
                                            : ""
                                    }
                                </div> : ""}
                            <div className="chat-scroll"
                            // onClick={this.handleChatMenu}
                            >
                                {this.props.chatId.activeChatType === 3 ?
                                    this.state.userConnection.length > 0 ?
                                        this.state.userConnection.map((user, index) => (
                                            user ?
                                                this.state.isTooltip === 1 ?
                                                    <div className={this.checkSeletedUser(user) ? "profile-dp selected-user" : "profile-dp"} >
                                                        {/*onClick={()=> this.props.history.push(`/user/profile/${user._id}`)}*/}
                                                        <div className="profile-dp" data-tip={`${"uc-" + user?._id}`} data-for={`${"uc-" + user?._id}`} key={"uc-" + user?._id} onClick={() => this.selectUser(user)} onMouseOver={() => this.setState({ tooltipUser: user, tooltipUserId: "uc-" + user._id })} onMouseOut={() => this.setState({ tooltipUser: {}, tooltipUserId: "" })} >
                                                            <img className="img-fluid" src={user.image && user.image !== "" ? user.image : require(`../../assets/images/login_user.png`)} data-tip={`${"uc-" + user?._id}`} data-for={`${"uc-" + user?._id}`} key={"uc-" + user?._id} onClick={() => this.selectUser(user)} onMouseOver={() => this.setState({ tooltipUser: user, tooltipUserId: "uc-" + user._id })} onMouseOut={() => this.setState({ tooltipUser: {}, tooltipUserId: "" })} />
                                                        </div>


                                                        {user?.unReadCount?.count > 0 ? <span className="unread counter chat-counter">{user.unReadCount.count}</span> : ""}
                                                        <span className={(user.isOnline === 1 || user.isOnline === "1") ? "dot green" : "dot yellow"}></span>
                                                    </div>
                                                    : <div className={this.checkSeletedUser(user) ? "profile-dp selected-user" : "profile-dp"} key={user._id}
                                                        onClick={() => this.selectUser(user)}
                                                    >

                                                        <img className="img-fluid"
                                                            src={user.image && user.image !== "" ? user.image : require(`../../assets/images/login_user.png`)} />

                                                        {user?.unReadCount?.count > 0 ? <span
                                                            className="unread counter chat-counter">{user.unReadCount.count}</span> : ""}
                                                        <span
                                                            className={(user.isOnline === 1 || user.isOnline === "1") ? "dot green" : "dot yellow"}></span>
                                                    </div>

                                                : "")) : ""


                                    : ""
                                }

                                {this.props.chatId.activeChatType === 2 && this.props.chatId.expoChatType === 3 ?
                                    this.state.showCaseUser.length > 0 ?
                                        this.state.showCaseUser.map((user, index) => (
                                            this.state.isTooltip === 1 ?
                                                <div className="profile-dp" key={"su-" + user._id} >
                                                    <div className="profile-dp" data-tip={`${"su-" + user._id}`} data-for={`${"su-" + user._id}`} onClick={() => this.openProfile(user)} onMouseOver={() => this.setState({ tooltipUser: user, tooltipUserId: "su-" + user._id })} onMouseOut={() => this.setState({ tooltipUser: {}, tooltipUserId: "" })}>
                                                        <img className="img-fluid" src={user.image && user.image !== "" ? user.image : require(`../../assets/images/login_user.png`)} data-tip data-for={`${"su-" + user._id}`} onClick={() => this.openProfile(user)} onMouseOver={() => this.setState({ tooltipUser: user, tooltipUserId: "su-" + user._id })} onMouseOut={() => this.setState({ tooltipUser: {}, tooltipUserId: "" })} />
                                                    </div>



                                                    <span className={true || (user.isOnline === 1 || user.isOnline === "1") ? "dot green" : "dot yellow"}></span>
                                                </div>
                                                :
                                                <div className="profile-dp" key={user._id} onClick={() => this.openProfile(user)}>

                                                    <img className="img-fluid" src={user.image && user.image !== "" ? user.image : require(`../../assets/images/login_user.png`)} />

                                                    <span className={true || (user.isOnline === 1 || user.isOnline === "1") ? "dot green" : "dot yellow"}></span>
                                                </div>

                                        )) : ""

                                    : ""
                                }

                                {this.props.chatId.activeChatType === 2 && this.props.chatId.expoChatType === 2 ?
                                    this.state.expoUser.length > 0 ?
                                        this.state.expoUser.map((user, index) => (
                                            this.state.isTooltip === 1 ?
                                                <div className="profile-dp" key={"eu-" + user._id} >
                                                    <div className="profile-dp" data-tip data-for={`${"eu-" + user._id}`} onClick={() => this.openProfile(user)} onMouseOver={() => this.setState({ tooltipUser: user, tooltipUserId: "eu-" + user._id })} onMouseOut={() => this.setState({ tooltipUser: {}, tooltipUserId: "" })}>
                                                        <img data-tip data-for={`${"eu-" + user._id}`} className="img-fluid" src={user.image && user.image !== "" ? user.image : require(`../../assets/images/login_user.png`)} />
                                                    </div>



                                                    <span className={true || (user.isOnline === 1 || user.isOnline === "1") ? "dot green" : "dot yellow"}></span>
                                                </div>
                                                :
                                                <div className="profile-dp" key={user._id} onClick={() => this.openProfile(user)}>

                                                    <img className="img-fluid" src={user.image && user.image !== "" ? user.image : require(`../../assets/images/login_user.png`)} />

                                                    <span className={true || (user.isOnline === 1 || user.isOnline === "1") ? "dot green" : "dot yellow"}></span>
                                                </div>
                                        )) : ""

                                    : ""
                                }
                                {this.props.chatId.activeChatType === 2 && this.props.chatId.expoChatType === 1 ?
                                    this.state.pageUser.length > 0 ?
                                        this.state.pageUser.map((user, index) => (
                                            this.state.isTooltip === 1 ?
                                                <div className="profile-dp" key={"pu-" + user._id} >

                                                    <div className="profile-dp" data-tip data-for={`${"pu-" + user._id}`} onClick={() => this.openProfile(user)} onMouseOver={() => this.setState({ tooltipUser: user, tooltipUserId: "pu-" + user._id })} onMouseOut={() => this.setState({ tooltipUser: {}, tooltipUserId: "" })}>
                                                        <img className="img-fluid" src={user.image && user.image !== "" ? user.image : require(`../../assets/images/login_user.png`)} data-tip data-for={`${"pu-" + user._id}`} onClick={() => this.openProfile(user)} onMouseOver={() => this.setState({ tooltipUser: user, tooltipUserId: "pu-" + user._id })} onMouseOut={() => this.setState({ tooltipUser: {}, tooltipUserId: "" })} />
                                                    </div>

                                                    <span className={true || (user.isOnline === 1 || user.isOnline === "1") ? "dot green" : "dot yellow"}></span>
                                                </div>
                                                :
                                                <div className="profile-dp" key={user._id} onClick={() => this.openProfile(user)}
                                                >

                                                    <img className="img-fluid"
                                                        src={user.image && user.image !== "" ? user.image : require(`../../assets/images/login_user.png`)} />

                                                    <span
                                                        className={true || (user.isOnline === 1 || user.isOnline === "1") ? "dot green" : "dot yellow"}></span>
                                                </div>

                                        )) : ""

                                    : ""
                                }
                            </div>
                        </div>
                        <div className="chatbody-parent">
                            <div className="wrap-chat-header">
                                <div className="chat-icon-wrapper">

                                    <div className={this.props.chatId.activeChatType === 2 ? "active  cursor-point" : " cursor-point"} onClick={() => this.changeChatType(2)}>Chat</div>
                                    <div className={this.props.chatId.activeChatType === 3 ? "active  cursor-point pos-relative" : " cursor-point pos-relative"} onClick={() => this.changeChatType(3)}>People
                                    {this.state.totalUnreadMsg > 0 ? <span className="unread counter chat-counter white-counter">{this.state.totalUnreadMsg}</span> : ""}
                                    </div>
                                </div>
                                {
                                    this.props.chatId.activeChatType === 2 ?


                                        <>
                                            <div className="tabswitchanipation-page">
                                                <div className="">
                                                    <div className="tabs-scroll">
                                                        {
                                                            !this.checkDefaultActiveChat(this.props.pathDatail.path) ?
                                                                <button className={`btn ${this.state.flag === 1 ? "active-button" : ""}`} style={{ width: this.state.width + "%" }} onClick={() => this.changeExpoChatType(1)}>Page</button>
                                                                : ""
                                                        }
                                                        <button className={`btn ${this.state.flag === 2 ? "active-button" : ""}`} style={{ width: this.state.width + "%" }} onClick={() => this.changeExpoChatType(2)}>Expo</button>
                                                        {this.props.userDetail.userType == 2 || this.props.userDetail.userType == 4 ?
                                                            <button className={`btn ${this.state.flag === 3 ? "active-button" : ""}`} style={{ width: this.state.width + "%" }} onClick={() => this.changeExpoChatType(3)}>Showcase</button>

                                                            : ""}
                                                        <div className="animate-me" style={{ width: this.state.width + "%", left: this.state.animatePosition + "%" }}></div>
                                                    </div>
                                                </div>
                                            </div>


                                            {/* <nav class="nav nav-pills nav-fill">
                                            {!this.checkDefaultActiveChat(this.props.pathDatail.path) ?
                                                <a class={this.props.chatId.expoChatType === 1 ? "nav-item nav-link active" : " nav-item nav-link"} onClick={() => this.changeExpoChatType(1)} href="#">Page</a>
                                                : ""}
                                            <a class={this.props.chatId.expoChatType === 2 ? "nav-item nav-link active" : " nav-item nav-link"} onClick={() => this.changeExpoChatType(2)} href="#">Expo</a>
                                            {this.props.userDetail.userType == 2 || this.props.userDetail.userType == 4 ?
                                                <a class={this.props.chatId.expoChatType === 3 ? "nav-item nav-link active" : " nav-item nav-link"} onClick={() => this.changeExpoChatType(3)} href="#">Showcase
                                                </a>
                                                : ""}
                                        </nav>
                                        <p className="text-center">{this.chatHeaderMsg()}</p> */}
                                        </>
                                        : ""


                                }
                                {this.props.chatId.activeChatType === 3 && this.props.chatId.selectedUser._id ?
                                    <div className="chat-current-online-body">

                                        {this.props.chatId.selectedUser._id ?
                                            <div className="d-flex align-items-center justify-content-between w-100">
                                                <div onClick={() => this.openProfile(this.props.chatId.selectedUser)} className="cursor-point">
                                                    {/*<h4>{this.state.selectedUser.firstName+" "+this.state.selectedUser.lastName}</h4>*/}
                                                    {/*<p>{this.state.selectedUser.companyName}</p>*/}
                                                    <h4>{this.props.chatId.selectedUser.firstName + " " + this.props.chatId.selectedUser.lastName}</h4>
                                                    <p style={{ color: '#fff' }}>{this.props.chatId.selectedUser.companyName}</p>
                                                </div>
                                                <div className="quadrado">
                                                    {/*<i className="fa fa-user-plus cursor-point mr-2" aria-hidden="true"></i>*/}

                                                    {/* ------ redcamera for busy call------- */}
                                                    <span className=" mr-3 cursor-point " onClick={() => this.videoCalling(this.props.chatId.selectedUser._id)}><ZoomVideo /></span>
                                                </div>
                                            </div>
                                            : ""}


                                    </div> : ""}
                            </div>

                            <div className="chatbody" id={"chatbody-slide" + `${this.props.chatId.expoChatType}`} ref={(el) => { this.chatbody = el; }} onScroll={() => this.onChatBodyScroll()}>


                                {/*{console.log(this.props.chatId.expoChatType, "this.props.chatId.activeChatType")}*/}
                                <div className="" id="chatBody">
                                    <div  >
                                        {this.props.chatId.activeChatType === 2 && this.props.chatId.expoChatType === 1 ?
                                            this.state.pageMsgList && this.state.pageMsgList.length > 0 ?
                                                this.state.pageMsgList.map((message, i) => (

                                                    <>
                                                        {this.shouldShowDate(message, i, this.state.pageMsgList) ?
                                                            <p className="date-time mt-2 date-show"><span>{this.getDate(message.timeStamp ? message.timeStamp : new Date())}</span></p> : ""}
                                                        {message.messageType === 1 ? this.checkUserType(message) === false ?
                                                            <div className={`${this.shouldShowContactAvatar(message, i, this.state.pageMsgList) ? 'other' : 'other less'}`} key={'pageMsg_' + i}>
                                                                <div className="dp pos-relative ">
                                                                    {this.shouldShowGroupContactAvatar(message, i, this.state.pageMsgList) &&
                                                                        <img className="img-fluid cursor-point" onClick={() => this.openProfile(message.userData)}
                                                                            src={message.userData.image && message.userData.image !== "" ? message.userData.image : require('../../assets/images/login_user.png')}
                                                                        />
                                                                        // onClick={() => this.selectUser(message.userData)}
                                                                    }
                                                                </div>
                                                                <div className="msz-box-parent">
                                                                    <h6>{this.shouldShowGroupContactAvatar(message, i, this.state.pageMsgList) && message.userData.firstName} {this.shouldShowGroupContactAvatar(message, i, this.state.pageMsgList) && message.userData.companyName ? ', ' + message.userData.companyName : ""}</h6>
                                                                    <div className="msz-box">
                                                                        <Linkify
                                                                            componentDecorator={(
                                                                                decoratedHref,
                                                                                decoratedText,
                                                                                key
                                                                            ) => (
                                                                                    <SecureLink href={decoratedHref} key={key}>
                                                                                        {decoratedText}
                                                                                    </SecureLink>
                                                                                )}
                                                                        >
                                                                            {ReactHtmlParser(message.message)}
                                                                        </Linkify>
                                                                    </div>
                                                                    {/*{this.shouldShowTime(message, i, this.state.pageMsgList) &&*/}
                                                                    <p className="date-time mt-2">{this.getTime(message.timeStamp ? message.timeStamp : new Date())}</p>
                                                                    {/*}*/}
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className={`${this.shouldShowGroupContactAvatar(message, i, this.state.pageMsgList) ? 'me' : 'me less'}`} key={'pageMsg_' + i}>
                                                                <div className="msz-box-parent">
                                                                    <div className="msz-box">
                                                                        <Linkify
                                                                            componentDecorator={(
                                                                                decoratedHref,
                                                                                decoratedText,
                                                                                key
                                                                            ) => (
                                                                                    <SecureLink href={decoratedHref} key={key}>
                                                                                        {decoratedText}
                                                                                    </SecureLink>
                                                                                )}
                                                                        >
                                                                            {ReactHtmlParser(message.message)}
                                                                        </Linkify>
                                                                    </div>

                                                                    {/*{this.shouldShowTime(message, i, this.state.pageMsgList) &&*/}
                                                                    <p className="date-time mt-2">{this.getTime(message.timeStamp ? message.timeStamp : new Date())}</p>
                                                                    {/*}*/}
                                                                </div>

                                                            </div> : ""}</>
                                                )) :
                                                <div className="no-chat-parent ab">
                                                    <span><Chat /></span>
                                                    <p>Select a contact to start a conversation.</p>
                                                </div>
                                            : ""}
                                        {
                                            this.props.chatId.activeChatType === 2 && this.props.chatId.expoChatType === 2 ?
                                                this.state.expoMsgList && this.state.expoMsgList.length > 0 ?
                                                    this.state.expoMsgList.map((message, i) => (
                                                        <>
                                                            {this.shouldShowDate(message, i, this.state.expoMsgList) ?
                                                                <p className="date-time mt-2 date-show"><span>{this.getDate(message.timeStamp ? message.timeStamp : new Date())}</span></p> : ""}
                                                            {message.messageType === 1 ? this.checkUserType(message) === false ?
                                                                <div className={`${this.shouldShowContactAvatar(message, i, this.state.expoMsgList) ? 'other' : 'other less'}`} key={'expoMsg_' + i}>
                                                                    <div className="dp pos-relative ">
                                                                        {this.shouldShowGroupContactAvatar(message, i, this.state.expoMsgList) &&
                                                                            <img className="img-fluid cursor-point" onClick={() => this.openProfile(message.userData)}
                                                                                src={message.userData.image && message.userData.image !== "" ? message.userData.image : require(`../../assets/images/login_user.png`)}
                                                                            />

                                                                        }
                                                                    </div>
                                                                    <div className="msz-box-parent">
                                                                        <h6>{this.shouldShowGroupContactAvatar(message, i, this.state.expoMsgList) && message.userData.firstName}{this.shouldShowGroupContactAvatar(message, i, this.state.expoMsgList) && message.userData.companyName ? ', ' + message.userData.companyName : ""}</h6>
                                                                        <div className="msz-box">
                                                                            <Linkify
                                                                                componentDecorator={(
                                                                                    decoratedHref,
                                                                                    decoratedText,
                                                                                    key
                                                                                ) => (
                                                                                        <SecureLink href={decoratedHref} key={key}>
                                                                                            {decoratedText}
                                                                                        </SecureLink>
                                                                                    )}
                                                                            >
                                                                                {ReactHtmlParser(message.message)}
                                                                            </Linkify>
                                                                        </div>
                                                                        {/*{this.shouldShowTime(message, i, this.state.expoMsgList) &&*/}
                                                                        <p className="date-time mt-2">{this.getTime(message.timeStamp ? message.timeStamp : new Date())}</p>
                                                                        {/*}*/}
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className={`${this.shouldShowGroupContactAvatar(message, i, this.state.expoMsgList) ? 'me' : 'me less'}`} key={'expoMsg_' + i}>
                                                                    <div className="msz-box-parent">
                                                                        <div className="msz-box">
                                                                            <Linkify
                                                                                componentDecorator={(
                                                                                    decoratedHref,
                                                                                    decoratedText,
                                                                                    key
                                                                                ) => (
                                                                                        <SecureLink href={decoratedHref} key={key}>
                                                                                            {decoratedText}
                                                                                        </SecureLink>
                                                                                    )}
                                                                            >
                                                                                {ReactHtmlParser(message.message)}
                                                                            </Linkify>
                                                                        </div>
                                                                        {/*{this.shouldShowTime(message, i, this.state.expoMsgList) &&*/}
                                                                        <p className="date-time mt-2">{this.getTime(message.timeStamp ? message.timeStamp : new Date())}</p>
                                                                        {/*}*/}
                                                                    </div>
                                                                </div> : ""}</>
                                                    )) :
                                                    <div className="no-chat-parent abc">
                                                        <span><Chat /></span>
                                                        <p>Select a contact to start a conversation.</p>
                                                    </div>

                                                : ""}

                                        {
                                            this.props.chatId.activeChatType === 2 && this.props.chatId.expoChatType === 3 ?
                                                this.state.showCaseMsgList && this.state.showCaseMsgList.length > 0 ?
                                                    this.state.showCaseMsgList.map((message, i) => (
                                                        <>
                                                            {this.shouldShowDate(message, i, this.state.showCaseMsgList) ?
                                                                <p className="date-time mt-2 date-show"><span>{this.getDate(message.timeStamp ? message.timeStamp : new Date())}</span></p> : ""}
                                                            {message.messageType === 1 ? this.checkUserType(message) === false ?
                                                                <div className={`${this.shouldShowContactAvatar(message, i, this.state.showCaseMsgList) ? 'other' : 'other less'}`} key={'expoMsg_' + i}>
                                                                    <div className="dp pos-relative ">
                                                                        {this.shouldShowGroupContactAvatar(message, i, this.state.showCaseMsgList) &&
                                                                            <img className="img-fluid cursor-point" onClick={() => this.openProfile(message.userData)}
                                                                                src={message.userData.image && message.userData.image !== "" ? message.userData.image : require(`../../assets/images/login_user.png`)}
                                                                            />

                                                                        }
                                                                    </div>
                                                                    <div className="msz-box-parent">
                                                                        <h6>{this.shouldShowGroupContactAvatar(message, i, this.state.showCaseMsgList) && message.userData.firstName}{this.shouldShowGroupContactAvatar(message, i, this.state.showCaseMsgList) && message.userData.companyName ? ', ' + message.userData.companyName : ""}</h6>
                                                                        <div className="msz-box">
                                                                            <Linkify
                                                                                componentDecorator={(
                                                                                    decoratedHref,
                                                                                    decoratedText,
                                                                                    key
                                                                                ) => (
                                                                                        <SecureLink href={decoratedHref} key={key}>
                                                                                            {decoratedText}
                                                                                        </SecureLink>
                                                                                    )}
                                                                            >
                                                                                {ReactHtmlParser(message.message)}
                                                                            </Linkify>
                                                                        </div>
                                                                        {/*{this.shouldShowTime(message, i, this.state.expoMsgList) &&*/}
                                                                        <p className="date-time mt-2">{this.getTime(message.timeStamp ? message.timeStamp : new Date())}</p>
                                                                        {/*}*/}
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className={`${this.shouldShowGroupContactAvatar(message, i, this.state.showCaseMsgList) ? 'me' : 'me less'}`} key={'expoMsg_' + i}>
                                                                    <div className="msz-box-parent">
                                                                        <div className="msz-box">
                                                                            <Linkify
                                                                                componentDecorator={(
                                                                                    decoratedHref,
                                                                                    decoratedText,
                                                                                    key
                                                                                ) => (
                                                                                        <SecureLink href={decoratedHref} key={key}>
                                                                                            {decoratedText}
                                                                                        </SecureLink>
                                                                                    )}
                                                                            >
                                                                                {ReactHtmlParser(message.message)}
                                                                            </Linkify>
                                                                        </div>
                                                                        {/*{this.shouldShowTime(message, i, this.state.expoMsgList) &&*/}
                                                                        <p className="date-time mt-2">{this.getTime(message.timeStamp ? message.timeStamp : new Date())}</p>
                                                                        {/*}*/}
                                                                    </div>
                                                                </div> : ""}</>
                                                    )) :
                                                    <div className="no-chat-parent abcd">
                                                        <span><Chat /></span>
                                                        <p>Select a contact to start a conversation.</p>
                                                    </div>

                                                : ""}
                                        {
                                            this.props.chatId.activeChatType === 3 ?
                                                this.state.userMsgList && this.state.userMsgList.length > 0 ?
                                                    this.state.userMsgList.map((message, i) => (

                                                        message.messageType  == 6 ? 
                                                        this.checkUserType(message) === false ?
                                                                        <div className={`${this.shouldShowContactAvatar(message, i, this.state.userMsgList) ? 'other' : 'other less'}`} key={'userMsg_' + i}>
                                                                            <div className="dp pos-relative">
                                                                                {this.shouldShowContactAvatar(message, i, this.state.userMsgList) ?
                                                                                    <img className="img-fluid" src={message.userData.image && message.userData.image !== "" ? message.userData.image : require('../../assets/images/login_user.png')} />
                                                                                    : ""}
                                                                            </div>
                                                                            <div className="msz-box-parent">
                                                                                <div className="msz-box">
                                                                                    <Linkify
                                                                                        componentDecorator={(
                                                                                            decoratedHref,
                                                                                            decoratedText,
                                                                                            key
                                                                                        ) => (
                                                                                                <SecureLink href={decoratedHref} key={key}>
                                                                                                    {decoratedText}
                                                                                                </SecureLink>
                                                                                            )}
                                                                                    >
                                                                                        {ReactHtmlParser(message.message)}
                                                                                    </Linkify>
                                                                                </div>
                                                                                {/*{this.shouldShowTime(message, i, this.state.userMsgList) &&*/}
                                                                                <p className="date-time mt-2">{this.getTime(message.timeStamp ? message.timeStamp : new Date())}</p>
                                                                                {/*}*/}
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className={`${this.shouldShowGroupContactAvatar(message, i, this.state.userMsgList) ? 'me' : 'me less'}`} key={'userMsg_' + i}>
                                                                            <div className="msz-box-parent">
                                                                                <div className="msz-box">
                                                                                    <Linkify
                                                                                        componentDecorator={(
                                                                                            decoratedHref,
                                                                                            decoratedText,
                                                                                            key
                                                                                        ) => (
                                                                                                <SecureLink href={decoratedHref} key={key}>
                                                                                                    {decoratedText}
                                                                                                </SecureLink>
                                                                                            )}
                                                                                    >
                                                                                        {ReactHtmlParser(message.message)}
                                                                                    </Linkify>
                                                                                </div>
                                                                                {/*{this.shouldShowTime(message, i, this.state.userMsgList) &&*/}
                                                                                <p className="date-time mt-2">{this.getTime(message.timeStamp ? message.timeStamp : new Date())}</p>
                                                                                {/*}*/}
                                                                            </div>
                                                                        </div>



                                                        :


                                                        message.messageType  == 5 ? 
                                                        this.checkUserType(message) === false ?
                                                                        <div className={`${this.shouldShowContactAvatar(message, i, this.state.userMsgList) ? 'other' : 'other less'}`} key={'userMsg_' + i}>
                                                                            <div className="dp pos-relative">
                                                                                {this.shouldShowContactAvatar(message, i, this.state.userMsgList) ?
                                                                                    <img className="img-fluid" src={message.userData.image && message.userData.image !== "" ? message.userData.image : require('../../assets/images/login_user.png')} />
                                                                                    : ""}
                                                                            </div>
                                                                            <div className="msz-box-parent">
                                                                                <div className="msz-box">
                                                                                    <Linkify
                                                                                        componentDecorator={(
                                                                                            decoratedHref,
                                                                                            decoratedText,
                                                                                            key
                                                                                        ) => (
                                                                                                <SecureLink href={decoratedHref} key={key}>
                                                                                                    {decoratedText}
                                                                                                </SecureLink>
                                                                                            )}
                                                                                    >
                                                                                        {ReactHtmlParser(message.message)}
                                                                                    </Linkify>
                                                                                </div>
                                                                                <p className="date-time mt-2">{this.getTime(message.timeStamp ? message.timeStamp : new Date())}</p>

                                                                                {message.reason && <> <div className="msz-box">
                                                                                
                                                                                <span> {message.reason}</span>
                                                                                    </div>

                                                                                <p className="date-time mt-2">{this.getTime(message.timeStamp ? message.timeStamp : new Date())}</p>
                                                                                
                                                                                </>}
                                                                                {/*{this.shouldShowTime(message, i, this.state.userMsgList) &&*/}
                                                                               
                                                                                {/*}*/}
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className={`${this.shouldShowGroupContactAvatar(message, i, this.state.userMsgList) ? 'me' : 'me less'}`} key={'userMsg_' + i}>
                                                                            <div className="msz-box-parent">
                                                                                <div className="msz-box">
                                                                                    <Linkify
                                                                                        componentDecorator={(
                                                                                            decoratedHref,
                                                                                            decoratedText,
                                                                                            key
                                                                                        ) => (
                                                                                                <SecureLink href={decoratedHref} key={key}>
                                                                                                    {decoratedText}
                                                                                                </SecureLink>
                                                                                            )}
                                                                                    >
                                                                                        {ReactHtmlParser(message.message)}
                                                                                    </Linkify>
                                                                                  
                                                                                </div>
                                                                                <p className="date-time mt-2">{this.getTime(message.timeStamp ? message.timeStamp : new Date())}</p>
                                                                               {message.reason && <> <div className="msz-box">
                                                                                
                                                                                <span> {message.reason}</span>
                                                                                    </div>

                                                                                <p className="date-time mt-2">{this.getTime(message.timeStamp ? message.timeStamp : new Date())}</p>
                                                                                
                                                                                </>}
                                                                                {/*}*/}
                                                                            </div>
                                                                        </div>
                                                        
                                                        
                                                        
                                                        
                                                        
                                                        
                                                        
                                                        
                                                        
                                                        :


                                                        message.messageType == 4 ?
                                                            <div className="call_notification menoti drysidebar-mtng-card chatMeetingCard">
                                                                {console.log("djkk", this.state.userMsgList)}
                                                                <div className="meeting-dry-crd-top">
                                                                    <div className="mtng-dry-icn">
                                                                        <MapMarker />
                                                                    </div>
                                                                    <div className="mtng-dry-cnt">
                                                                        <h4>{message?.message}</h4>
                                                                        <ul className="list-unstyled mb-0">
                                                                            <li className="tmdry">
                                                                                <Clock />
                                                                                <span>{getTimeFormate(message?.callStartTime)}, {getTimeZone(message?.callStartTime, message?.timezone)}
                                                                                </span>
                                                                            </li>
                                                                            {message?.location && <li>
                                                                                <FooterLocation />
                                                                                <span>{message?.location}</span>
                                                                            </li>}
                                                                        </ul>
                                                                        <div className="dry-mtng-invt-users">
                                                                            <div className="dryUsers">
                                                                                <img src={message?.userImage ? message?.userImage : require("../../assets/images/login_user.png")} />
                                                                            </div>
                                                                            <div className="dryUsers">
                                                                                <img src={message?.senderImage ? message?.senderImage : require("../../assets/images/login_user.png")} />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="meeting-dry-crd-btm">
                                                                    {message.callStatus == 0 && message?.userData?._id != localStorage.getItem("id") && <ul className="mb-0 list-unstyled d-flex pb-0 justify-content-center">
                                                                        <li>
                                                                            <button type="button" onClick={() => this.handleAcceptReject(message.meetingId, 1, message)} className="btn btn-success"><i class="fa fa-check wtic"></i>Accept</button>
                                                                        </li>
                                                                        <li>
                                                                            <button type="button" onClick={() => this.handleAcceptReject(message.meetingId, 2, message)} className="btn btn-danger"><i class="fa fa-times wtic"></i>Decline</button>
                                                                        </li>
                                                                    </ul>}
                                                                    {message.callStatus == 2 && <ul className="mb-0 list-unstyled d-flex pb-0 justify-content-center">

                                                                        <li className="list-inline-item">
                                                                            <button type="button" className="btn btn-sm btn-danger disabled">Declined</button>
                                                                        </li>

                                                                    </ul>}
                                                                    {message.callStatus == 1 && <ul className="mb-0 list-unstyled d-flex pb-0 justify-content-center">

                                                                        <li className="list-inline-item">
                                                                            <button type="button" className="btn btn-success btn-sm disabled" >Accepted</button>
                                                                        </li>

                                                                    </ul>}
                                                                    {message.callStatus == 0 && message?.userData?._id == localStorage.getItem("id") && <ul className="mb-0 list-unstyled d-flex pb-0 justify-content-center">

                                                                        <li className="list-inline-item">
                                                                            <button type="button" className="btn btn-info btn-sm no-cursor" >Pending</button>
                                                                        </li>

                                                                    </ul>}
                                                                    
                                                                </div>

                                                            </div>


                                                            :

                                                            <>
                                                                {console.log("djkk", this.state.userMsgList)}
                                                                {this.shouldShowDate(message, i, this.state.userMsgList) ?
                                                                    <p className="date-time mt-2 date-show"><span>{this.getDate(message.timeStamp ? message.timeStamp : new Date())}</span></p> : ""}

                                                                {message.messageType !== 1 ? (message.callStatus === 0 || message.callStatus === 2) && new Date() - message.timeStamp > 120000 ?

                                                                    this.checkUserType(message) === false ?
                                                                        <div className="call_notification">
                                                                            <h5><span><Misscall /></span> Missed a call from&nbsp;<b>{message.userData.firstName + (message.userData.companyName ? ', ' + message.userData.companyName : "")}</b> </h5>
                                                                            <p>{this.getTime(message.timeStamp ? message.timeStamp : new Date())}
                                                                                {/*&nbsp;|&nbsp;<b>4 mins</b> */}
                                                                            </p>
                                                                            <hr />
                                                                            <h6 className="text-center cursor-point" onClick={() => this.videoCalling(this.props.chatId.selectedUser._id)} >Call back</h6>
                                                                        </div> :
                                                                        <div className="call_notification menoti">
                                                                            <h5><span><Misscall /></span>
                                                                                {/*<b>{message.userData.firstName+', '+message.userData.companyName}</b>&nbsp; */}
                                                                                Missed a call from you</h5>
                                                                            <p>{this.getTime(message.timeStamp ? message.timeStamp : new Date())}
                                                                                {/*&nbsp;|&nbsp;<b>4 mins</b> */}
                                                                            </p>
                                                                        </div>
                                                                    : message.callStatus === 3 ? <div className="call_notification menoti">
                                                                        <h5><span><ZoomVideo /></span>
                                                                            You were in a call with&nbsp;<b> {this.props.chatId.selectedUser.firstName + (this.props.chatId.selectedUser.companyName ? ", " + this.props.chatId.selectedUser.companyName : "")}</b>&nbsp;
                                                        </h5>
                                                                        <p>{this.getTime(message.timeStamp ? message.timeStamp : new Date())}
                                                                            {this.callTime(message)}
                                                                        </p></div> : ""

                                                                    :
                                                                    this.checkUserType(message) === false ?
                                                                        <div className={`${this.shouldShowContactAvatar(message, i, this.state.userMsgList) ? 'other' : 'other less'}`} key={'userMsg_' + i}>
                                                                            <div className="dp pos-relative">
                                                                                {this.shouldShowContactAvatar(message, i, this.state.userMsgList) ?
                                                                                    <img className="img-fluid" src={message.userData.image && message.userData.image !== "" ? message.userData.image : require('../../assets/images/login_user.png')} />
                                                                                    : ""}
                                                                            </div>
                                                                            <div className="msz-box-parent">
                                                                                <div className="msz-box">
                                                                                    <Linkify
                                                                                        componentDecorator={(
                                                                                            decoratedHref,
                                                                                            decoratedText,
                                                                                            key
                                                                                        ) => (
                                                                                                <SecureLink href={decoratedHref} key={key}>
                                                                                                    {decoratedText}
                                                                                                </SecureLink>
                                                                                            )}
                                                                                    >
                                                                                        {ReactHtmlParser(message.message)}
                                                                                    </Linkify>
                                                                                </div>
                                                                                {/*{this.shouldShowTime(message, i, this.state.userMsgList) &&*/}
                                                                                <p className="date-time mt-2">{this.getTime(message.timeStamp ? message.timeStamp : new Date())}</p>
                                                                                {/*}*/}
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className={`${this.shouldShowGroupContactAvatar(message, i, this.state.userMsgList) ? 'me' : 'me less'}`} key={'userMsg_' + i}>
                                                                            <div className="msz-box-parent">
                                                                                <div className="msz-box">
                                                                                    <Linkify
                                                                                        componentDecorator={(
                                                                                            decoratedHref,
                                                                                            decoratedText,
                                                                                            key
                                                                                        ) => (
                                                                                                <SecureLink href={decoratedHref} key={key}>
                                                                                                    {decoratedText}
                                                                                                </SecureLink>
                                                                                            )}
                                                                                    >
                                                                                        {ReactHtmlParser(message.message)}
                                                                                    </Linkify>
                                                                                </div>
                                                                                {/*{this.shouldShowTime(message, i, this.state.userMsgList) &&*/}
                                                                                <p className="date-time mt-2">{this.getTime(message.timeStamp ? message.timeStamp : new Date())}</p>
                                                                                {/*}*/}
                                                                            </div>
                                                                        </div>
                                                                }
                                                            </>

                                                    )) :
                                                    _.isEmpty(this.props.chatId.selectedUser )
                                                   ? <div className="no-chat-parent abced">
                                                   <span><Chat /></span>
                                                   <p>Select a contact to start a conversation.</p>
                                                   {/* No messages to show */}
                                               </div> : <div className="no-chat-parent abced">
                                                        <span><Chat /></span>
                                                        <p>No messages to show.</p>
                                                        {/* No messages to show */}
                                                    </div>
                                                : ""}
                                               
                                        <div ref={(el) => { this.messagesEnd = el; }} />
                                    </div>
                                </div>


                                {/* Meeting New */}

                                {/* Meeting New End */}

                            </div>


                            {
                                console.log("pankajyadav", this.props.chatId.activeChatType)
                            }

                            {(this.props.chatId.activeChatType === 3 && this.props.chatId.groupId) || this.props.chatId.activeChatType === 2 || this.props.chatId.activeChatType === 1 ?
                                <div className={this.props.chatId.groupId ? 'bottom fullwidth' : 'bottom fullwidth'}>
                                    <form onSubmit={this.sendMessage} >
                                        <div className="search_wrapper  chatbox-textbox mr-2">
                                            <i onClick={this.sendMessage}><LandingUser /></i>

                                            <ContentEditable
                                                innerRef={this.contentEditableRef}
                                                //   value = {this.state.message}
                                                html={this.state.message} // innerHTML of the editable div
                                                disabled={false}       // use true to disable editing
                                                onChange={this.handleChange} // handle innerHTML change
                                                tagName='article' // Use a custom HTML tag (uses a div by default)
                                                className="chattextbox"
                                                onKeyUp={this.handleKeyUp}
                                                onKeyDown={this.handleKeyPress}
                                                onFocus={() => this.scrollToBottom()}
                                            />

                                        </div>
                                    </form>
                                </div>
                                : ""}

                        </div>

                    </div>

                    {this.state.tooltipUserId != "" ?

                        <ReactTooltip clickable={true} id={`${this.state.tooltipUserId}`} place="left" offset={{ top: -60 }} effect="solid">

                            <div className="tooltip-wrap">
                                <div className="card-tooltip">
                                    <div className="profile-dp" onClick={() => this.openProfile(tooltipUser)} >
                                        <img className="img-fluid" src={tooltipUser.image && tooltipUser.image !== "" ? tooltipUser.image : require(`../../assets/images/login_user.png`)} />
                                    </div>
                                    <div className="profile-details">
                                        <h5>{tooltipUser.firstName && tooltipUser.firstName !== "" ? tooltipUser.firstName : ""}&nbsp;{tooltipUser.lastName && tooltipUser.lastName !== "" ? tooltipUser.lastName : ""}</h5>
                                        <h6 className="profile">{this.getJobRole(tooltipUser)}</h6>
                                        <h6>{tooltipUser.companyName && tooltipUser.companyName !== "" ? tooltipUser.companyName : ""}</h6>
                                    </div>
                                </div>
                                <hr />
                                {tooltipUser.country && tooltipUser.country != "" ? <p><i class="fa fa-map-marker" aria-hidden="true"></i>{tooltipUser.city ? tooltipUser.city + "," : ""} {tooltipUser.country ? tooltipUser.country : ""}</p> : ""}
                                <p><i class="fa fa-user" aria-hidden="true"></i>{tooltipUser.userType === 0 || tooltipUser.userType === "0" ? "Buyer" : "Seller"}</p>
                            </div>
                        </ReactTooltip>
                        : ""}


                    {this.state.isShowGlobalPopup && <>
                        <Modal
                            wrapClassName="signup_parent_modal"
                            className="cm_popup signup_modal cstmMdl-notification"
                            //cm_popup term-services
                            visible={this.state.isShowGlobalPopup}
                            // onOk={this.handleOk}
                            onCancel={this.handleClose}
                            footer={null}
                            centered
                            maskClosable={false}
                        >
                            <div className="sign-modal-content">
                                <div className="row align-items-center text-center">
                                    <div className="col-md-12" >
                                        {/* <div className="logo">
                                    <img src={require('../../assets/images/splash.png')} className="img-fluid splash-img" />
                                    <img src={require('../../assets/images/logo.png')} className="img-fluid logo-img" />
                                </div> */}
                                        <div dangerouslySetInnerHTML={{ __html: this.state.globalMessage }}></div>


                                    </div>
                                </div>

                            </div>
                        </Modal>

                    </>}
                </>

            );




    }
}

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(withRouter(ChatBoxHeaderNew));
