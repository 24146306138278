/**
 * @About
 * Base url of all api.
 */

export default {

  // role
  'GET_ROLE': { url: `user/getRole`, method: 'GET' },
  'SAVE_SHOWCASE': { url: `user/updateService`, method: 'POST' },
  'USER_SIGNUP': { url: `user/signup`, method: 'POST' },
  'USER_SIGNIN': { url: `user/login`, method: 'POST' },
  'USER_FORGOT_PASSWORD': { url: `user/forgotPassword`, method: 'POST' },
  'USER_RESET_PASSWORD': { url: `user/resetPassword`, method: 'POST' },
  'USER_GET_BRAND_CATEGORY': { url: `brandcategory/getCategory`, method: 'POST' },
  'GET_COUNTRY_LIST': { url: `country/getCountry`, method: 'GET' },
  'GET_COUNTRY_STATE_LIST': { url: `country/getState`, method: 'GET' },
  'APPLY_PROMOCODE': { url: `promocode/applyPromocode`, method: 'POST' },
  'CHANGE_PASSWORD': { url: `user/changePassword`, method: 'POST' },

  //Admin
  'ADD_EXHIBITION': { url: `exhibition/createExhibition`, method: 'POST' },
  'EDIT_EXHIBITION': { url: `exhibition/editExhibition`, method: 'POST' },
  'EXHIBITION_LIST_DELETE': { url: `exhibition/deleteExhibition`, method: 'GET' },
  'EXHIBITION_LIST_STATUS': { url: `exhibition/changeStatus`, method: 'POST' },
  'GET_EXHIBITION_LIST': { url: `exhibition/list`, method: 'GET' },
  'GET_EXHIBITION_VIEW_PARTICULAR': { url: `exhibition/particularExhibition`, method: 'GET' },
  'IS_EMAIL_ID_ALREADY_EXIST': { url: `user/isEmailExist`, method: 'GET' },

  //Conference
  'ADD_CONFERENCE': { url: `conference/addConference`, method: 'POST' },
  'EDIT_CONFERENCE': { url: `conference/editConference`, method: 'POST' },
  'CONFERENCE_LIST_DELETE': { url: `conference/deleteConference`, method: 'GET' },
  'CONFERENCE_LIST_STATUS': { url: `conference/changeStatus`, method: 'GET' },
  'GET_CONFERENCE_LIST': { url: `conference/list`, method: 'GET' },

  'GET_PARTICULAR_CONFERENCE': { url: `conference/particularConference`, method: 'GET' },
  // conference // user side

  'GET_CONFERENCE_WEB': { url: `conference/conferenceList`, method: 'GET' },
  'GET_CONFERENCE_WEB_DETAILS': { url: `conference/detail`, method: 'POST' },

  //Add stages
  'ADD_STAGES': { url: `stage/addStage`, method: 'POST' },
  'EDIT_STAGES': { url: `stage/editStage`, method: 'POST' },
  'GET_STAGE_LIST': { url: `stage/list`, method: 'GET' },
  'GET_PARTICULAR_STAGE': { url: `stage/particularStage`, method: 'GET' },
  'GET_STAGE_STATUS': { url: `stage/changeStatus`, method: 'GET' },
  'CHANGE_FORLIVE_STAGE': { url: `stage/changeMainStage`, method: 'GET' },
  'DELETE_STAG': { url: `stage/deleteStage`, method: 'GET' },

  //Lounge
  'ADD_LOUNGE': { url: `lounge/addLounge`, method: 'POST' },
  'EDIT_LOUNGE': { url: `lounge/editLounge`, method: 'POST' },
  'GET_LOUNGE_LIST': { url: `/lounge/list`, method: 'GET' },
  'GET_LOUNGE_STATUS': { url: `lounge/changeStatus`, method: 'GET' },
  'DELETE_LOUNGE': { url: `lounge/deleteLounge`, method: 'GET' },
  'VIEW_LOUNGE': { url: `lounge/particularLounge`, method: 'GET' },

  //Speaker
  'ADD_SPEAKER': { url: `speaker/addSpeaker`, method: 'POST' },
  'EDIT_SPEAKER': { url: `user/editUser`, method: 'POST' },
  'GET_SPEAKER_LIST': { url: `speaker/list`, method: 'GET' },
  'DELETE_SPEAKER': { url: `speaker/deleteSpeaker`, method: 'GET' },
  'VIEW_PARTICULAR_SPEAKER': { url: `speaker/particularSpeaker`, method: 'GET' },

  //time table
  'ADD_TIME_TABLE': { url: `timeTable/addTimeTable`, method: 'POST' },
  'EDIT_TIME_TABLE': { url: `timeTable/editTimeTable`, method: 'POST' },
  'GET_TIME_TABLE_LIST': { url: `timeTable/list`, method: 'GET' },
  'GET_PARTICULAR_TIME_TABLE': { url: `timeTable/particularTimeTable`, method: 'GET' },
  'DELETE_TIME_TABLE': { url: `timeTable/deleteTimeTable`, method: 'GET' },

  //networking lounge
  'ADD_NETWORKING_LOUNGE': { url: `networkLounge/addNetworkLounge`, method: 'POST' },
  'EDIT_NETWORKING_LOUNGE': { url: `networkLounge/editNetworkLounge`, method: 'POST' },
  'GET_NETWORKING_LOUNGE_LIST': { url: `networkLounge/list`, method: 'GET' },
  'DELETE_NETWORKING_LOUNGE': { url: `networkLounge/deleteNetworkLounge`, method: 'GET' },
  'STATUS_NETWORKING_LOUNGE': { url: `networkLounge/changeStatus`, method: 'GET' },
  'VIEW_NETWORKING_LOUNGE': { url: `networkLounge/particularNetworkLounge`, method: 'GET' },

  //brand
  'GET_BRAND_LIST': { url: `brands/list`, method: 'GET' },
  'DELETE_BRAND_LIST': { url: `brands/deleteBrands`, method: 'GET' },
  'STATUS_BRAND_LIST': { url: `brands/changeStatus`, method: 'GET' },
  'ADD_BRAND': { url: `brands/addBrand`, method: 'POST' },
  'EDIT_BRAND': { url: `brands/editBrand`, method: 'POST' },
  'GET_PARTICULAR_BRAND': { url: `brands/particularBrands`, method: 'GET' },
  'GET_BRAND_FOR_ASSIGN': { url: `user/brandList`, method: 'GET' },
  'ASSIGN_BRAND': { url: `user/assignBrand`, method: 'POST' },

  //add staff
  'ADD_STAFF': { url: `staff/addStaff`, method: 'POST' },
  'EDIT_STAFF': { url: `staff/editStaff`, method: 'PST' },
  'GET_STAFF_LIST': { url: `staff/list`, method: 'GET' },
  'VIEW_PARTICULAR_STAFF': { url: `staff/particularStaff`, method: 'GET' },
  'DELETE_STAFF': { url: `staff/deleteStaff`, method: 'GET' },
  'GET_STAFF_LIST_Details': { url: `user/staffList`, method: 'GET' },




  //Add product
  'ADD_PRODUCT': { url: `product/addProduct`, method: 'POST' },
  'EDIT_PRODUCT': { url: `product/editProduct`, method: 'POST' },
  'GET_PRODUCT_LIST': { url: `product/list`, method: 'GET' },
  'DELETE_PRODUCT': { url: `product/deleteProduct`, method: 'GET' },
  'VIEW_PARTICULAR_PRODUCT': { url: `product/particularProduct`, method: 'GET' },
  'PRODUCT_STATUS': { url: `product/changeStatus`, method: 'GET' },


  //payment
  'USER_TRANSACTION': { url: `transaction/payment`, method: 'POST' },
  'USER_TRANSACTION_V2': { url: `transaction/paymentV2`, method: 'POST' },
  'GET_TRANSACTION_DETAILS': { url: `transaction/getPaymentDetails`, method: 'GET' },
  'GET_TRANSACTION_DETAILS_V2': { url: `transaction/getPaymentDetailsV2`, method: 'GET' },
  'GET_SUCCESS_TRANSACTION_DETAILS': { url: `transaction/getTransactionDetails`, method: 'GET' },
  'GET_PAYMENT_SUCCESS_DETAILS': { url: `transaction/conferencePaymentSuccess`, method: 'GET' },



  'CONFERENCE_PAYMENT': { url: `transaction/joinConferenceV2`, method: 'POST' },

  'PASSWORD_CHECK': { url: `conference/isPasswordCheck`, method: 'POST' },





  // transacton 

  'GET_TRANSACTION': { url: `transaction/transactionDetails`, method: 'GET' },
  'GET_TRANSACTION_DETAILS_DATA': { url: `transaction/particularTransactionDetail`, method: 'GET' },
  'EDIT_VAT_NUMBER': { url: `transaction/editVat`, method: 'POST' },

  // PromoCode
  'GET_PROMOLIST': { url: `/promocode/list`, method: 'GET' },
  'GET_PROMODETAIL': { url: `/promocode/particularPromocode`, method: 'GET' },
  'PROMO_LIST_DELETE': { url: `/promocode/deletePromocode`, method: 'GET' },
  'PROMO_STATUS_CHANGE': { url: `/promocode/changeStatus`, method: 'GET' },
  'PROMO_EXHIBITION_DATA': { url: `/exhibition/exhibitionList`, method: 'GET' },
  'PROMO_CONFERENCE_DATA': { url: `/conference/conferenceList`, method: 'GET' },
  'Add_PROMO': { url: `/promocode/addPromocode`, method: 'POST' },

  // ad  
  'GET_Ad_LIST': { url: `ads/list`, method: 'GET' },
  'GET_Ad_LIST_MESSAGE': { url: `message/list`, method: 'GET' },
  'ADD_Ad': { url: `ads/addAds`, method: 'POST' },
  'EDIT_ADD': { url: `ads/editAds`, method: 'POST' },
  'AD_DELTED': { url: `ads/delete`, method: 'GET' },
  'GET_PARTICULAR_AD': { url: `ads/particularAds`, method: 'GET' },
  'AD_CHANGE_STATUS': { url: `ads/changeStatus`, method: 'GET' },



  // User
  'GET_USER': { url: `/user/userList`, method: 'GET' },
  'USER_STATUS_CHANGE': { url: '/user/changeStatus', method: 'GET' },
  'DELETE_USER': { url: '/user/deleteUser', method: 'GET' },
  'GET_USER_PROFILE': { url: '/user/viewProfileV2', method: 'GET' },
  'ADD_USER': { url: 'user/addUser', method: 'POST' },

  //CATEGORY
  'CATEGORY_LIST': { url: `category/list`, method: 'POST' },
  'CATEGORY_ADD': { url: `category/create`, method: 'POST' },
  'CATEGORY_EDIT': { url: `category/update`, method: 'POST' },
  'CATEGORY_DELTED': { url: `category/delete`, method: 'POST' },
  'CATEGORY_DETAILS': { url: `category/details`, method: 'POST' },
  'CATEGORY_CHANGE_STATUS': { url: `category/changeStatus`, method: 'POST' },
  'GET_CATEGORY_LIST': { url: `category/categorylist`, method: 'POST' },


  // upload media
  'UPLOAD_MEDIA': { url: `conference/uploadMedia`, method: 'POST' },

  // new change api's

  'USER_STATUS': { url: `user/changeStatus`, method: 'GET' },
  'USER_DELETE': { url: `user/deleteUser`, method: 'GET' },
  'CHANGE_PLAN': { url: `user/upgradePlan`, method: 'POST' },
  'USER_DETAILS': { url: `user/detail`, method: 'GET' },
  "UPDATE_BRAND_INFO": { url: `user/updateBrandProfile`, method: "POST" },
  "DELETE_BRAND_IMG": { url: `user/deleteImage`, method: "POST" },


  'ADD_NEW_BRAND': { url: `user/addBrand`, method: 'POST' },

  'ADD_NEW_USER': { url: `user/addUser`, method: 'POST' },
  'ADD_NEW_STAFF': { url: `user/addStaff`, method: 'POST' },
  'GET_USER_LIST': { url: `user/list`, method: 'GET' },
  'EDIT_USER_PROFILE': { url: `/user/editProfileV2`, method: "POST" },
  "USER_IMAGE_UPLOAD": { url: `user/uploadImage`, method: "POST" },
  "UPDATE_USER_INFO": { url: `user/editUser`, method: "POST" },


  //Subscription Plan

  "SUBSCRIPTION_PLAN_LIST": { url: `/subscriptionPlan/list`, method: "GET" },
  'SUBSCRIPTION_PLAN_STATUS_CHANGE': { url: `/subscriptionPlan/changeStatus`, method: 'GET' },
  'SUBSCRIPTION_PLAN_DELETE': { url: `/subscriptionPlan/delete`, method: 'GET' },
  'ADD_SUBSCRIPTION_PLAN': { url: `/subscriptionPlan/add`, method: 'POST' },
  'GET_SUBSCRIPTION_DETAIL': { url: `/subscriptionPlan/detail`, method: "GET" },
  'EDIT_SUBSCRIPTION_DETAIL': { url: `/subscriptionPlan/edit`, method: 'POST' },


  //media
  "ADD_MEDIA": { url: `gallery/add`, method: "POST" },
  "GET_MEDIA": { url: `gallery/list`, method: "GET" },
  "DELETE_MEDIA": { url: `gallery/delete`, method: "GET" },





  // /brochure/add

  "ADD_BROCHURE": { url: `brochure/add`, method: "POST" },
  "GET_BROCHURE": { url: `brochure/list`, method: "GET" },
  "DELETE_BROCHURE": { url: 'brochure/delete', method: "GET" },



  //user report admin
  "USER_REPORT_LIST": { url: `/user-report/list`, method: 'POST' },
  "USER_REPORT_STATUS_CHANGE": { url: `/user-report/changeStatus`, method: 'POST' },

  // feature Api

  "FEATURED_BRAND_LIST": { url: `/user/brandFeatured-List`, method: 'GET' },
  "FEATURED_PRODUCT_LIST": { url: `/product/product-List`, method: 'GET' },
  "FEATURED_BRAND_FEATURE_UPDATE": { url: `/user/updateFeaturedBrand`, method: 'POST' },
  "FEATURED_PRODUCT_FEATURE_UPDATE": { url: `/product/updateFeaturedProduct`, method: 'POST' },
  "UPDATE_OREDER_FEATURE_PRODUCT": { url: `/product/updateSetOrder`, method: 'POST' },
  "UPDATE_OREDER_FEATURE_BRAND": { url: `/user/updateSetOrder`, method: 'POST' },
  "MANAGE_STAGE_LIST": { url: `/stage/stage-List`, method: 'GET' },
  "MANAGE_LOUNGE_LIST": { url: `/lounge/lounge-List`, method: 'GET' },
  "UPDATE_OREDER_STAGE": { url: `/stage/updateSetOrder`, method: 'POST' },
  "UPDATE_OREDER_CONFERENCE": { url: `conference/updateSetOrder`, method: 'POST' },
  "UPDATE_OREDER_LOUNGE": { url: `/lounge/updateSetOrder`, method: 'POST' },

  // wish list data
  "ADD_WISHLIST": { url: `/wishList/create`, method: 'POST' },
  "REMOVE_WISHLIST": { url: `/wishList/remove`, method: 'POST' },
  "WISHLIST_LIST": { url: `/wishList/list`, method: 'GET' },

  "FILTER_BRAND_LIST": { url: `/user/brand_list`, method: 'POST' },
  "FILTER_PRODUCT_LIST": { url: `/product/product_list`, method: 'POST' },

  "FILTER_BRAND_LIST_V2": { url: `/user/brand_Filter_list`, method: 'POST' },

  // 
  "GET_USER_HOME": { url: `/user/isHome`, method: 'POST' },

  // meeting
  "CALLING": { url: `group/calling`, method: 'POST' },
  "JOIN_MEETING": { url: `group/joinMeeting`, method: 'POST' },
  "JOIN_ONE_MEETING": { url: `group/joinOneMeeting`, method: 'POST' },
  "END_MEETING": { url: `group/endMeeting`, method: 'POST' },
  "GROUP_USER_LIST": { url: `group/user`, method: 'POST' },
  //Community 
  "COMMUNITY_REQUESTLIST": { url: `/userConnection/requestRecieveList`, method: 'POST' },
  "COMMUNITY_PENDINGLIST": { url: `/userConnection/requestSendList`, method: 'POST' },
  "COMMUNITY_ACCEPTREJECT": { url: `/userConnection/acceptReject`, method: 'POST' },
  "COMMUNITY_CONNECTION_LIST": { url: `/userConnection/list`, method: 'POST' },
  "COMMUNITY_CONNECTION_REMOVE": { url: `/userConnection/cancelRequest`, method: 'POST' },
  "COMMUNITY_CONNECTION_BLOCK": { url: `/userConnection/blockRequest`, method: 'POST' },
  "COMMUNITY_SUGGESTIONLIST": { url: `/userConnection/suggestionList`, method: 'POST' },
  "COMMUNITY_CREATEREQUEST": { url: `/userConnection/createRequest`, method: 'POST' },
  "DOWNLOAD_CSV": { url: `/userConnection/exportConnectionList`, method: 'POST' },





  // attendeee

  "GET_ATTENDEE_LIST": { url: `attendeeJoin/getAttendee`, method: 'POST' },
  // networking
  "GET_NETWORKING_LIST": { url: `/networkLounge/list`, method: 'GET' },

  //
  "GET_BRAND_DASHBOARD": { url: `user/brandAnalytics`, method: 'POST' },
  'MOST_RECENT_CONNECTION': { url: `userConnection/recentConnection`, method: 'POST' },
  'MOST_VISTITED_PRODUCT': { url: `product/mostVisitedProducts`, method: 'POST' },
  'GET_PRODUCT_CATEGORY': { url: `user/getProductCategory`, method: 'GET' },


  // View connection Detail 

  'GET_CONNECTION_PROFILE': { url: '/user/profileView', method: 'GET' },
  'GET_CONNECTION_ADDREPORT': { url: `/user-report/create`, method: 'POST' },


  //

  'CHECK_SEARCH_PLAN': { url: `/user/checkSearchPlan`, method: 'POST' },
  'SEARCH_TRANSACTION': { url: `/transaction/searchPayment`, method: 'POST' },
  'SEARCH_TRANSACTION_V2': { url: `/transaction/searchPaymentv2`, method: 'POST' },
  'SEARCH_LIST': { url: `user/search`, method: 'POST' },
  'RECENT_SEARCH_LIST': { url: `/user/recentSearch`, method: 'POST' },
  'CLEAR_SEARCH_LIST': { url: `/user/clearSearches`, method: 'POST' },
  'GET_PEOPLE_LIST': { url: `/user/peopleList`, method: 'POST' },
  'SEARCH_COUNT': { url: `/user/searchCount`, method: 'POST' },
  'GET_PEOPLE_LIST2': { url: `/user/peopleListV2`, method: 'POST' },


  // Notification
  'NOTIFICATION_LIST': { url: `/notification/list`, method: 'POST' },
  'USER_DEVICETOKEN': { url: `user/updateDeviceToken`, method: 'POST' },
  'NOTIFICATION_RESET': { url: `/notification/countSet`, method: 'POST' },




  // new speaker

  'ADD_NEW_SPEAKER': { url: `stage/addSpeaker`, method: 'POST' },
  'Edit_NEW_SPEAKER': { url: `stage/editSpeaker`, method: 'POST' },
  'Delete_NEW_SPEAKER': { url: `stage/deleteSpeaker`, method: 'POST' },
  'List_NEW_SPEAKER': { url: `stage/speakerList`, method: 'POST' },

  // 



  'ADD_NEW_TIMETABLE': { url: `timeTable/addTimeTable`, method: 'POST' },
  'STAFF_ENABLE_DISABLE': { url: `user/isBrandEnable`, method: 'POST' },
  'COUNT_BROUCHERS': { url: `brochureData/brochureCount`, method: 'POST' },
  'GET_BROUCHERS_COUNT': { url: `brochureData/list`, method: 'POST' },


  // 'List_NEW_SPEAKER': { url: `stage/speakerList`, method: 'POST'},



  // Brand Screen
  'GET_ALL_BRAND_DATA': { url: `user/isBrand`, method: 'POST' },
  'GET_ALL_CONNECTION': { url: `/userConnection/connectionList`, method: 'POST' },
  'GET_PRODUCT_CATEGORY_LIST': { url: `/category/categorylist1`, method: 'POST' },
  'GET_BRANDPROFILE_DETAIL': { url: `/user/getBrandDetail`, method: 'POST' },
  'GET_WEB_LOUNGE': { url: `lounge/lounge-List`, method: 'GET' },
  'GET_WEB_STAGE': { url: `stage/stage-List`, method: 'GET' },
  'GET_BRAND_MOSTVISITED_PRODUCT': { url: `product/mostVisitedMyProduct`, method: 'POST' },



  // get dynamic exhibition id
  'GET_EXHIBITION_ID': { url: `exhibition/exhibitionUserList`, method: 'GET' },
  'ADMIN_GET_SERVICES': { url: `user/getManageServicesFromAdmin`, method: 'GET' },
  'ADMIN_SAVE_SERVICES': { url: `user/updateServiceFromAdmin`, method: 'POST' },

  'ADMIN_GET_QUESTION_LIST': { url: `questionaire/list`, method: 'GET' },
  'ADMIN_ADD_QUESTION': { url: `questionaire/addQuestion`, method: 'POST' },
  'GET_PARTICULAR_QUESTION_DATA': { url: `questionaire/particularQuestion`, method: 'GET' },
  'QUESTION_DATA_DELTED': { url: `questionaire/deleteQuestion`, method: 'GET' },
  'QUESTION_STATUS_CHANGE': { url: `questionaire/changeStatus`, method: 'GET' },
  'QUESTION_ALL_ANSWER_LIST': { url: `userAnswer/list`, method: 'GET' },
  'EDIT_ANSWER_LIST': { url: `userAnswer/editUserAnswer`, method: 'POST' },
  'USER_ANS_LIST': { url: `userAnswer/answerList`, method: 'GET' },
  'PREVIOUS_QUESTION_LIST': { url: `questionaire/previousQuestionList`, method: 'GET' },



  'GET_SECTOR_LIST': { url: `sector/list`, method: 'GET' },
  'GET_PLACE_OF_WORK_LIST': { url: `placeOfWork/list`, method: 'POST' },
  'GET_PLACE_OF_WORK_LIST_placeOfWorkList': { url: `placeOfWork/placeOfWorkList`, method: 'POST' },
  'GET_ROLE_LIST': { url: `role/list`, method: 'POST' },
  'GET_COUNTRY_LIST_V2': { url: `country/list`, method: 'GET' },
  'GET_CITY_LIST': { url: `city/list`, method: 'GET' },
  'GET_CITY_LIST2': { url: `city/cityList`, method: 'POST' },

  'USER_SIGNUP_V2': { url: `user/signupV2`, method: 'POST' },
  'EVENT_QUESTION': { url: `questionaire/getQuestionListByUser`, method: 'POST' },
  'USER_ANSWER_SUBMIT': { url: `userAnswer/addUserAnswer`, method: 'POST' },

  'GET_SECTOR_SIGNUP_LIST': { url: `sector/sectorList`, method: 'GET' },

  //SECTOR
  'SECTOR_LIST': { url: `sector/listForAdmin`, method: 'GET' },
  'SECTOR_ADD': { url: `sector/addSector`, method: 'POST' },
  'SECTOR_EDIT': { url: `sector/editSector`, method: 'POST' },
  'SECTOR_DELTED': { url: `sector/delete`, method: 'POST' },
  'SECTOR_DETAILS': { url: `sector/particularSector`, method: 'POST' },
  'SECTOR_STATUS_CHANGE': { url: `sector/status`, method: 'POST' },



  //registerColleagues
  'GET_REGISTER_COLLEAGUES': { url: `user/registerColleagues`, method: 'POST' },
  'ADD_ENQUIRE_MESSAGE': { url: `enquire/add`, method: 'POST' },
  'GET_LIST_ENQUIRES': { url: `enquire/list`, method: 'GET' },
  'GET_PARTICULAR_ENQ': { url: `enquire/particularEnquiry`, method: 'GET' },
  'CHANGE_STATUES_ENQUIRES': { url: `enquire/statusChange`, method: 'GET' },


  // product CATEGORY
  'ADD_CATEGORY_PRODUCT': { url: `productAndService/add`, method: 'POST' },
  'GET_CATEGORY_PRODUCT': { url: `productAndService/listForAdmin`, method: 'GET' },
  'GET_CATEGORY_PRODUCT_PARTICULAR': { url: `productAndService/particularProductAndServices`, method: 'POST' },
  'EDIT_CATEGORY_PRODUCT': { url: `productAndService/edit`, method: 'POST' },
  'DELETE_CATEGORY_PRODUCT': { url: `productAndService/delete`, method: 'POST' },
  'STATUS_CATEGORY_PRODUCT': { url: `productAndService/status`, method: 'POST' },
  'GET_CATEGORY_PRODUCT_WEB': { url: `productAndService/list`, method: 'GET' },

  // company 
  'EDIT_COMPANY': { url: `user/editCompany`, method: 'POST' },

  //Place of work
  'POW_LIST': { url: `placeOfWork/listForAdmin`, method: 'POST' },
  'POW_ADD': { url: `placeOfWork/add`, method: 'POST' },
  'POW_EDIT': { url: `placeOfWork/edit`, method: 'POST' },
  'POW_DELTED': { url: `placeOfWork/delete`, method: 'POST' },
  'POW_DETAILS': { url: `placeOfWork/particularPlaceOfWork`, method: 'POST' },
  'POW_STATUS_CHANGE': { url: `placeOfWork/status`, method: 'POST' },

  //Role
  'ROLE_LIST': { url: `role/listForAdmin`, method: 'POST' },
  'ROLE_ADD': { url: `role/addRole`, method: 'POST' },
  'ROLE_EDIT': { url: `role/editRole`, method: 'POST' },
  'ROLE_DELTED': { url: `role/delete`, method: 'POST' },
  'ROLE_DETAILS': { url: `role/particularRole`, method: 'POST' },
  'ROLE_STATUS_CHANGE': { url: `role/status`, method: 'POST' },
  'GET_PRODUCT_LIST_NEW': { url: `product/featured-list`, method: 'GET' },
  'GET_OFFER_LIST_NEW': { url: `offer/offer-List`, method: 'GET' },

  // offers
  //Add product
  'ADD_OFFER': { url: `offer/addOffer`, method: 'POST' },
  'EDIT_OFFER': { url: `offer/editOffer`, method: 'POST' },
  'GET_OFFER_LIST': { url: `offer/list`, method: 'GET' },
  'DELETE_OFFER': { url: `offer/deleteOffer`, method: 'GET' },
  'VIEW_PARTICULAR_OFFER': { url: `offer/particularOffer`, method: 'GET' },
  'OFFER_STATUS': { url: `offer/changeStatus`, method: 'GET' },

  // feature product
  'FEATURE_PRODUCT_WEB': { url: `product/featured-listWeb`, method: 'GET' },
  "FILTER_OFFER_LIST": { url: `offer/offerList`, method: 'POST' },

  // assign spotlight
  "ASSIGN_BRAND_SPOTLIGHT": { url: `spotLight/add`, method: 'POST' },
  "GET_ADMIN_SPOTLIGHT": { url: `spotLight/list`, method: 'POST' },
  "CHANGE_STATUS": { url: `spotLight/status`, method: 'POST' },
  "DELETE_STATUS": { url: `spotLight/delete`, method: 'POST' },


  "GET_WEB_SPOTLIGHT": { url: `spotLight/listForWeb`, method: 'POST' },
  "ADD_REMINDER": { url: `reminderSession/add`, method: 'POST' },
  "GET_LIST_REMINDER": { url: `reminderSession/listForAdmin`, method: 'GET' },
  "GET_MY_EVENT": { url: `reminderSession/list`, method: 'GET' },
  "DELETE_EVENT": { url: `reminderSession/delete`, method: 'POST' },
  'GET_PRODUCT_LIST_NEW': { url: `product/featured-list`, method: 'GET' },
  'VIEW_MEDIA_ANALYSIS': { url: `mediaLog/add`, method: 'POST' },
  'MEDIA_ANALYSIS': { url: `mediaLog/list`, method: 'GET' },
  'GET_PRODUCT_COUNT': { url: `productViewedLog/list`, method: 'GET' },
  'EDIT_COMPANY_NEW': { url: `company/edit`, method: 'POST' },
  'GET_COMPANY_NEW': { url: `company/getCompanyDetail`, method: 'GET' },
  'STAFF_DOWNLOAD': { url: `user/exportStaffMember`, method: 'GET' },

  'BRAND_ASSIGN_NEW_LIST': { url: `user/listOfBrand`, method: 'GET' },

  'ASSIGN_ENQUIRE': { url: `enquire/assignEnquiry`, method: 'GET' },

  'CHECK_PLAN_USER': { url: `user/checkPlan`, method: 'POST' },

  'JOIN_LOUNG_PLAN': { url: `loungePlanLog/join`, method: 'POST' },

  'GET_DEFAULT_PLAN': { url: `defaultPlan/particularPlan`, method: 'GET' },
  'EDIT_DEFAULT_PLAN': { url: `defaultPlan/edit`, method: 'POST' },


  'ANALYTICS_ADMIN': { url: `user/dashboard`, method: 'POST' },

  'ANALYTICS_REPLAY': { url: `replaySession/create`, method: 'POST' },

  'UPDATED_USER_KEY': { url: `user/updateKey`, method: 'POST' },

  'AdminBroadcast': { url: `adminBroadcast/create`, method: 'POST' },
  'AdminBroadcastList': { url: `adminBroadcast/list`, method: 'POST' },
  'AdminBroadcastDelete': { url: `adminBroadcast/delete`, method: 'POST' },
  'AdminBroadcastGetDetail': { url: `adminBroadcast/getDetails`, method: 'POST' },
  'AdminBroadcastEdit': { url: `adminBroadcast/edit`, method: 'POST' },
  'OFFER_ANA_LIST': { url: `offerLog/list`, method: 'GET'}, 
  'NEW_ALL_CONNECTION_LIST': { url: `userConnection/viewList`, method: 'POST'}, 


  // job profile
  'ADD_JOB_PROFILE': { url: `careerProfile/add`, method: 'POST'}, 
  'GET_JOB_PROFILE': { url: `careerProfile/list`, method: 'GET'}, 
  'EDIT_JOB_PROFILE': { url: `careerProfile/edit`, method: 'POST'}, 


  'DELETE_RESUME': { url: `careerProfile/delete`, method: 'POST'}, 
  
  // plan add admin
  'PLAN_ADD': { url: `plan/add`, method: 'POST'}, 
  'PLAN_LIST': { url: `plan/listForAdmin`, method: 'GET'}, 
  'DELETE_PLAN': { url: `plan/delete`, method: 'POST'}, 
  'STATUS_PLAN': { url: `plan/status`, method: 'POST'}, 

  'GET_PLAN_ITEM': { url: `plan/particularPlan`, method: 'POST'}, 
  'EDIT_PLAN': { url: `plan/edit`, method: 'POST'}, 

  'GET_LIST_ADMIN' : { url: `manageSubscription/listForAdmin`, method: 'GET'}, 

  'MANAGE_SUBSCRIPTION_LIST' : { url: `manageSubscription/edit`, method: 'POST'}, 

  'ADD_OPTION' : { url: `plan/addOption`, method: 'POST'}, 

  'STATUS_OPTION' : { url: `manageSubscription/status`, method: 'POST'}, 

  'STATUS_OPTION_PLAN' : { url: `plan/status`, method: 'POST'}, 

  'GET_PLAN_USER_SIDE' : { url: `plan/planListForUsers`, method: 'GET'}, 
  'GET_PLAN_USER_SIDE_LIST' : { url: `manageSubscription/listForUser`, method: 'GET'}, 

  'FILTER_STATUS_CHANGE' : { url: `questionaire/hideQuestion`, method: 'POST'}, 


  // invite url
  'ADD_INVITE_URL' : { url: `invite/add`, method: 'POST'}, 
  'GET_INVITE_URL' : { url: `invite/list`, method: 'GET'}, 
  'DELETE_INVITE_URL' : { url: `invite/delete`, method: 'GET'}, 
  'GET_INVITEURL_ONE' : { url: `invite/get`, method: 'GET'}, 
  'EDIT_INVITE_URL': { url: `invite/edit`, method: 'POST' },
  'STATUS_INVITE_URL': { url: `invite/status`, method: 'GET' },
  'IS_VALID_INVITE_ID': { url: `invite/isValid`, method: 'GET' },
  'USER_INVITE_LIST': { url: `invite/getParticularUserData`, method: 'GET' },




  // diary
   
  'ADD_MEETING' : { url: `reminderSession/addMeeting`, method: 'POST'},
  'ACCEPTREJECT_MEETING' : { url: `reminderSession/acceptRejectMeeting`, method: 'POST'},
  'Edit_MEETING': { url: `reminderSession/editMeeting`, method: 'POST'},
  'RESHDULE_MEETING': { url: `reminderSession/rescheduleMeeting`, method: 'POST'},


  

  




  


  
  

  
  
  



  



  


  


  

  
  

}


