
import { Chat, NoMediaICon } from "../../../SvgIcon";
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';
import { Link } from "react-router-dom";
import Footer from "../../layout/Footer"
import { Pagination } from "../../../components";
import util from '../..//../utils/Util';
import { Menu, Dropdown, Button } from 'antd';
import ReportModal from '../../web/community/ReportUserModal';
import socket from "../../../services/socket";
import { groupActions } from "../../../redux/action";

import { BtnRed, CmBtn, BackBtn } from "../../../components/common/Button";
import { ZoomVideo } from "../../../SvgIcon";
import React, { Component } from 'react';
import WebHeader from '../../layout/WebHeader';

import { connect } from "react-redux";

import { MySpinner } from "../../../components/index"
class BusinessConnectionsDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            exhibtionId: '',
            page: 1,
            limit: 40,
            loading: false,
            isLoader: false,
            snNo: 0,
            totalCount: 1,
            DetailList: []


        }
    }

    componentDidMount() {
        this.getConnection();
        this.props.dispatch({ type: 'PATH_ADD', payload:this.props.match })
    }

    getList = (api, title, status) => {
        let { page, limit } = this.state;
        this.setState({ title: title });

        let req = {
            page: page ? page : 1,
            limit: limit,
            connectionType: this.props.history.location.pathname.includes("business") ? 2 : 1,
            status: status ? status : ""
        }

        let url = Url[api].url, method = Url[api].method;
        _Api(method, url, req)
            .then(resp => {

                this.setState({ DetailList: resp.responseData?.result?.record, totalCount: resp.responseData?.result?.totalCount })


            }).catch((err) => {
                this.handleError(err);
            })
    }
    getConnection() {
        let commingFrom = "block", toProfile = "/connection-profile";
        switch (this.props.history.location.pathname) {
            case "/business-connections":
                this.getList("COMMUNITY_CONNECTION_LIST", "My Connections");
                commingFrom = "connection";
                toProfile = "/connection-profile";
                break;
            case "/business-requested-connections":
                this.getList("COMMUNITY_REQUESTLIST", " Pending Connection Requests");
                commingFrom = "requested";
                toProfile = "/requested-profile/";
                break;
            case "/business-pending-connections":
                this.getList("COMMUNITY_PENDINGLIST", "Pending Connections");
                commingFrom = "pending";
                toProfile = "/pending-profile";
                break;


        }
        this.setState({ commingFrom: commingFrom, toProfile: toProfile });
    }

    handleError(err) {
        let errorMessage = err.error && err.error.responseMessage || err.message;
        notify("err", errorMessage);
    }

    handleSearch = e => this.setState({ search: e.target.value, brandListData: [], page: 1, snNo: 0 }, () => this.getCategory());


    onAcceptReject(e, requestId, status) {
        e.preventDefault();
        let req = {
            requestId: requestId,
            status: status
        }
        _Api(Url.COMMUNITY_ACCEPTREJECT.method, Url.COMMUNITY_ACCEPTREJECT.url, req)
            .then(resp => {
                this.getConnection();
            }).catch((err) => {
                this.handleError(err);
            })

    }
    onMoreClick = (e, id, userId, a, event, data) => {
        switch (e.key) {
            case "Remove":
                this.onRemoveAndBlock("COMMUNITY_CONNECTION_REMOVE", 3, id, event.domEvent);
                break;
            case "Block":
                this.onRemoveAndBlock("COMMUNITY_CONNECTION_BLOCK", 1, id, event.domEvent);
                break;
            case "Report":

                this.showModal(id, data, event.domEvent);
                break;
            case "AddBusinessConnection":
                this.onCreateBussinessRequest(userId);
                break;
            default:
                return null;


        }
    }
    onRemoveAndBlock = (api, status, id, e) => {
        this.setState({ [`connection${id}`]: true });
        e.preventDefault();
        let req = {
            requestId: id,
            status: status
        }
        _Api(Url[api].method, Url[api].url, req)
            .then(resp => {
                this.setState({ [`connection${id}`]: false });
                this.getConnection();
            }).catch((err) => {
                this.handleError(err);
            })

    }
    showModal = (id, data, e) => {
        e.preventDefault();
        this.setState({
            isReport: true,
            userData: data
        });
    };

    onConnectRequest(e, requestId, status) {
        this.setState({ [`connection${requestId}`]: true });
        e.preventDefault();
        let req = {
            requestedUserId: requestId,
            connectionType: 1
        }
        _Api(Url.COMMUNITY_CREATEREQUEST.method, Url.COMMUNITY_CREATEREQUEST.url, req)
            .then(resp => {
                this.getConnection();
                notify(resp.responseData.message);
                this.setState({ [`connection${requestId}`]: false })
            }).catch((err) => {
                this.handleError(err);
            })

    }

    onClickConnection = (id, user_id, e, isFromRequested = false) => {
        let props = this.props.scope.props;
        if (isFromRequested)
            props.history.push(`/connection-profile/${user_id}/connection/${id}/requested`)

        else { props.history.push(`/connection-profile/${user_id}/connection/${id}`) }
    }

    videoCalling = (userId) => {
        let self = this;
        if (userId && !this.props.isProd) {
            self.setState({
                ['videoConnectionLoder_' + userId]: true
            })
            self.props.dispatch(groupActions.joinOneMeeting({ userId: userId, type: 7 }))
                .then(function (res) {
                    self.setState({
                        ['videoConnectionLoder_' + userId]: false
                    })
                    if (res.statusCode === 1 || res.statusCode === "1") {
                        window.open('/calling/' + self.props.groupDetail.meeting._id, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                        //window.location.href = '/calling/' + self.props.groupDetail.meeting._id;
                        //history.push('/calling/'+self.props.groupDetail.meeting._id)
                    } else {

                    }
                    console.log("res", res)
                })
        }
        else {

            notify("success", 'Will be live on 8th September, 2020');

        }
    }

    selectUser = (user) => {
        let self = this;
        // console.log("user",user);
        if (this.props.userDetail._id !== user._id && !this.props.isProd) {
            this.setState({
                selectedUser: user,
                selectedUserId: user._id,
                chatType: 3,
                isShow: true
            }, function () {
                self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 3 })
                self.props.dispatch({ type: 'CHAT_OPEN' })
                document.body.classList.toggle('chat_open_mob');
                self.props.dispatch({ type: 'SELECTED_USER', selectedUser: user })
                socket.emit('groupAdd', { type: 7, userId: user._id });
                //socket.emit('userConnection', {userId: user._id});
            })
        }
        else {

            notify("success", 'Will be live on 8th September, 2020');

        }
    }

    handleOk = e => {
        console.log(e);
        this.setState({
            isReport: false
        });
    };

    handleCancel = e => {
        console.log(e);
        this.setState({
            isReport: false
        });
    };
    onCreateBussinessRequest = (id) => {
        this.setState({ [`connection${id}`]: true })
        let req = {
            requestedUserId: id,
            connectionType: 2
        }
        _Api(Url.COMMUNITY_CREATEREQUEST.method, Url.COMMUNITY_CREATEREQUEST.url, req)
            .then(resp => {
                this.getConnection();
                notify("success", resp.responseData.message);
                this.setState({ [`connection${id}`]: false })
            }).catch((err) => {
                this.handleError(err);
            })
    }
    onConnectRequest(e, requestId, status) {
        this.setState({ [`connection${requestId}`]: true });
        e.preventDefault();
        let req = {
            requestedUserId: requestId,
            connectionType: 1
        }
        _Api(Url.COMMUNITY_CREATEREQUEST.method, Url.COMMUNITY_CREATEREQUEST.url, req)
            .then(resp => {
                this.getConnection();
                notify(resp.responseData.message);
                this.setState({ [`connection${requestId}`]: false })
            }).catch((err) => {
                this.handleError(err);
            })

    }
    onClickConnection = (id, user_id) => {
        this.props.history.push(`/connection-profile/${user_id}/connection/${id}`);
    }

    handlePageChange = (page) => {
        this.setState({ loader: true, page, snNo: page - 1, DetailList: [] }, () => {
            this.getConnection();
        })

    }


    render() {
        let { DetailList, commingFrom, limit } = this.state;
        const Communtiy = (props) => (<Menu onClick={(e) => this.onMoreClick(e, props.Id, props.userId, "", e, props.data)} >
            {props.isRemove && <Menu.Item key="Remove"> Remove Connection</Menu.Item>}
            <Menu.Item key="Block">Block</Menu.Item>
            <Menu.Item key="Report">Report</Menu.Item>
            {/* {props.isBusinessRequest && < Menu.Item key="AddBusinessConnection">Send business request</Menu.Item>} */}
        </Menu>)

        return (

            <section className="brand-home-banner-bg">
               {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
               {/* {    <ChatBox history={this.props.history} match={this.props.match} />}*/}
                <div className="container-fluid cm-section featured-brands industries-serve">
                    <BackBtn className="btn btn-light cursor-point float-right" to="community" />
                    <div class="brandhome cummunity">
                        <div className="cm-wrapper">
                            <div className="header-container">

                                <div className="header-text">
                                    <h1 className="title">{this.state.title}</h1>
                                </div>
                                {/* <div className="search_wrapper">
                                <i className="fa fa-search" />
                                <input type="search"
                                    placeholder="Search by Brand"
                                    name='search'
                                    onChange={this.handleSearch}
                                />
                            </div> */}

                            </div>
                            <div className="row">
                                {
                                    DetailList && DetailList.map(item => (

                                        <div className="four-div">
                                            <div className="card text-center community-card">
                                                <div className="logo-wrapper">
                                                    <img src={item?.userData?.logoImage ? item?.userData?.logoImage : require('../../../assets/images/placeholder.png')} />
                                                </div>
                                                <h5 className="text-capitalize"> {item?.userData?.companyName}</h5>
                                                {commingFrom == "connection" && <span>{item?.userData?.email}</span>}
                                                {commingFrom == "connection" && <span>{item?.userData?.fullPhoneNumber}</span>}
                                                <hr />
                                                <div className="connection-detail-parent">
                                                    <div className=" imgparent pr-0">
                                                        <div className="img-wrapper pos-relative image-chat">
                                                            <img className="img-fluid cursor-point" onClick={(e) => this.onClickConnection(item?._id, item?.userData?._id, e)} src={item?.userData?.image || require('../../../assets/images/login_user.png')} />
                                                            <span className="chat cursor-point" onClick={() => this.selectUser(item?.userData)} ><Chat /></span>
                                                        </div>
                                                    </div>
                                                    <div className=" textparent no-gutter d-flex align-items-start flex-column">
                                                        <h4 onClick={(e) => this.onClickConnection(item?._id, item?.userData?._id, e)} ><strong>{util.getTitleCase(item?.userData?.firstName)} {util.getTitleCase(item?.userData?.lastName)}</strong></h4>
                                                        <span><small>{item?.userData?.JobTitle}</small></span>

                                                    </div>
                                                </div>
                                                <br />
                                                {commingFrom == "pending" && <p className="text-center"><span className="action_group  ">
                                                    <CmBtn text="Cancel Request" className="btn btn-blue" bg='var(--btncolor)' onClick={(e) => this.onRemoveAndBlock("COMMUNITY_CONNECTION_REMOVE", 3, item?._id, e)} />

                                                </span>
                                                </p>
                                                }
                                                {commingFrom == "requested" && <p className="text-center"><span className="action_group  ">
                                                    <CmBtn text=" Accept" className="btn btn-blue mr-2" bg='var(--btncolor)' onClick={(e) => this.onAcceptReject(e, item?._id, 1)} />
                                                    <CmBtn text="Ignore" className="btn btn-blue" bg="#fff" color="#A3AAB0" border="1px solid #91cbd3" onClick={(e) => this.onAcceptReject(e, item?._id, 2)} />

                                                </span>
                                                </p>
                                                }
                                            </div>
                                        </div>










                                    )) || <div className="w-100 text-center row nodata m-auto">
                                        <div className="m-auto">
                                            <NoMediaICon />
                                            
                                            <h2>No Data Found</h2>
                                        </div>                    </div>
                                }
                            </div>
                            {
                            DetailList?.length > 0 &&
                            <div >
                               <Pagination
                                    totalPage={Math.ceil(this.state.totalCount / limit)}
                                    currentPage={this.state.page}
                                    onPressBtn={this.handlePageChange}
                                />
                            </div>
                        }
                        </div>
                    </div>
                </div>



                <Footer />
                {this.state.isReport &&

                    <ReportModal
                        text='Close Me'
                        userData={this.state.userData}
                        closePopup={() => { this.setState({ isReport: false }) }}

                    />

                }
            </section >




        )
    }
}

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(BusinessConnectionsDetails);


