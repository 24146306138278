import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';
import { Tab, Tabs } from 'react-bootstrap'
import { BackBtn } from '../../../components/common/Button';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';
import WebHeader from "../../layout/WebHeader";
import { connect } from "react-redux";
import { ZoomVideo, Chat } from "../../../SvgIcon";
import { Menu, Dropdown, Button } from 'antd';
import { BtnRed, CmBtn } from "../../../components/common/Button";

import ReportModal from '../../web/community/ReportUserModal';



import { Spinner } from "react-bootstrap";
import socket from "../../../services/socket";
import { groupActions } from "../../../redux/action";
import LazyLoad from 'react-lazy-load';



// import { Tabs } from 'antd';

var _ = require('lodash');
let isProd = process.env.REACT_APP_ENV == 'prod'

class CommunityUserProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userData: [],
            connectionStatus: "",
            startLoader: false,
            userType:null,
            userConnection:null

        }
    }

    changeUserStatus(userId,status){
        console.log("nbzjkcldcdn", {params : this.props.match.params.id , userId, status})
        let self=this;
        if(status == 1){
            if(this.props.match.params.id == userId)
            this.setState({userConnection: 1})
        }
        else {
            if(this.props.match.params.id == userId)
            this.setState({userConnection: 0})

        }
        
    }

    componentDidMount() {
        if(this.props.match.path == "/user/profile/connection/:id")
        this.props.dispatch({ type: 'PATH_ADD', payload:this.props.match })
        this.getUserProfile();

        var self = this
      
        socket.on('connect', function (data) {

        });

        socket.on('online', function (params) {
            console.log("onlineeeeeeeeeeeee",params);
            self.changeUserStatus(params.user._id, 1);
        })

   
        socket.on('offline', function (params) {
            console.log("offline", params);
            self.changeUserStatus(params.user._id, 0);
        })
    }

    componentDidUpdate(prevProps) {

        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.getUserProfile()


        }
    }

    handleError(err) {
        this.setState({ isLoader: false });
        let errorMessage = err.error && err.error.responseMessage || err.message;
        notify("err", errorMessage);
    }

    getUserProfile = () => {
        let req = {
            requestUserId: this.props.match.params.id || localStorage.id || '',
        }
        _Api(Url.GET_CONNECTION_PROFILE.method, Url.GET_CONNECTION_PROFILE.url, '', `?requestUserId=${req.requestUserId}`)
            .then(resp => {
                // console.log("dnskms", resp)
                this.setState({ userConnection :resp.responseData.result?.isOnline , userType: resp.responseData.result?.userType,  userData: resp.responseData.result, connectionStatus: resp.responseData.connectionStatus })

                if (this.props.match.path == "/user/profile/connection/:id") {

                    this.onAccept(resp.responseData.connectionStatus?._id, 1 )
                }

            }).catch(err => {
                this.setState({ isLoader: false });
                let errorMessage = err.error && err.error.responseMessage || err.message;
                notify("err", errorMessage);
            });
    }
    onMoreClick = (e) => {
        switch (e.key) {
            case "Remove":
                this.onRemoveAndBlock("COMMUNITY_CONNECTION_REMOVE", 3);
                this.props.history.push('/community');
                break;
            case "Block":
                this.onRemoveAndBlock("COMMUNITY_CONNECTION_BLOCK", 1);
                this.props.history.push('/community');
                break;
            case "AddBusinessConnection":
                this.onCreateBussinessRequest("AddBusinessConnection");
                break;
            case "Report":
                this.showModal();
                break;


        }
    }
    showModal = () => {
        this.setState({
            isReport: true
        });
    };

    onRemoveAndBlock = (api, status, id) => {

        // console.log("poiugdhj", this.state.connectionStatus)

        let req = {
            requestId: this.state.connectionStatus._id,
            status: status
        }
        _Api(Url[api].method, Url[api].url, req)
            .then(resp => {
                this.getUserProfile();
            }).catch((err) => {
                this.handleError(err);
            })

    }


    onRemoveAndBlock2 = (api, status, id) => {       
        let req = {
            requestId: id,
            status: status
        }
        _Api(Url[api].method, Url[api].url, req)
            .then(resp => {
                this.getUserProfile();
            }).catch((err) => {
                this.handleError(err);
            })

    }

    onCreateBussinessRequest = () => {
        let req = {
            requestedUserId: this.props.match.params.id,
            connectionType: 2
        }
        _Api(Url.COMMUNITY_CREATEREQUEST.method, Url.COMMUNITY_CREATEREQUEST.url, req)
            .then(resp => {
                notify("success", resp.responseData.message);
                this.getUserProfile();
                notify(resp.responseData.message);
            }).catch((err) => {
                this.handleError(err);
            })
    }
    getPathName = (value) => {
        if (value.includes("/connection-profile"))
            return "connection";
        else if (value.includes("/pending-profile"))
            return "pending";
        else if (value.includes("/suggested-profile"))
            return "suggested";
        else if (value.includes("/requested-profile"))
            return "requested";

    }
    onAccept(requestId, status) {
        let req = {
            requestId: requestId,
            status: status
        }
        let { connectionId, id } = this.props.match.params;
        _Api(Url.COMMUNITY_ACCEPTREJECT.method, Url.COMMUNITY_ACCEPTREJECT.url, req)
            .then(resp => {
                notify("success", resp.responseData.message);
                this.props.history.push(`/user/profile/${this.props.match.params.id}`);
                this.getUserProfile();

            }).catch((err) => {
                this.handleError(err);
            })

    }
    onAcceptReject(e, requestId, status) {
        e.preventDefault();
        let req = {
            requestId: requestId,
            status: status
        }
        let { connectionId, id } = this.props.match.params;
        _Api(Url.COMMUNITY_ACCEPTREJECT.method, Url.COMMUNITY_ACCEPTREJECT.url, req)
            .then(resp => {
                notify("success", resp.responseData.message);
                this.props.history.push(`/connection-profile/${id}/connection/${connectionId}`);
                this.getUserProfile();

            }).catch((err) => {
                this.handleError(err);
            })

    }
    onConnectRequest(e, requestId, status) {
        e.preventDefault();
        let req = {
            requestedUserId: requestId,
            connectionType: 1
        }
        _Api(Url.COMMUNITY_CREATEREQUEST.method, Url.COMMUNITY_CREATEREQUEST.url, req)
            .then(resp => {
                notify("success", resp.responseData.message);
                this.getUserProfile();
            }).catch((err) => {
                this.handleError(err);
            })

    }


    selectUser = (user) => {
        let self = this;
        if (this.props.userDetail._id !== user._id) {
            this.setState({
                selectedUser: user,
                selectedUserId: user._id,
                chatType: 3,
                isShow: true
            }, function () {
                self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 3 })
                self.props.dispatch({ type: 'CHAT_OPEN' })
                document.body.classList.toggle('chat_open_mob');
                self.props.dispatch({ type: 'SELECTED_USER', selectedUser: user })
                socket.emit('groupAdd', { type: 7, userId: user._id });
                //socket.emit('userConnection', {userId: user._id});
            })
        }
        // else {
        //     notify("success", "This page will be live on the 8th September, 2020. ");

        // }
    }



    videoCalling = (userId) => {
        let self = this;

        if (userId) {
            this.setState({ startLoader: true })
            self.setState({
                ['videoConnectionLoder_' + userId]: true
            })
            self.props.dispatch(groupActions.joinOneMeeting({ userId: userId, type: 7 }))
                .then(function (res) {
                    self.setState({
                        ['videoConnectionLoder_' + userId]: false, startLoader: false
                    })
                    if (res.statusCode === 1 || res.statusCode === "1") {
                        window.open('/calling/' + self.props.groupDetail.meeting._id, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                        //window.location.href = '/calling/' + self.props.groupDetail.meeting._id;
                        //history.push('/calling/'+self.props.groupDetail.meeting._id)
                    } else {
                        this.setState({ startLoader: false })

                    }                    
                    self.setState({ startLoader: false })
                })
        }
        else {
            notify("success", "This page will be live on the 8th September, 2020. ");

        }
    }

    goBack = (scope) => {
        scope.props.history.goBack();
    }

    render() {
        let { userData } = this.state;
        let isConnectionProfile = true;
        let requestType = this.props.history.location.pathname.includes("requested");


        return (
            <>
                <div className={this.props.userDetail && (this.props.userDetail.userType === 1 || this.props.userDetail.userType === "1") ? 'dash_body_container' : 'dash_body_container without-sidenav'} >
                    {
                        <>
                           {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
                           {/* {    <ChatBox history={this.props.history} match={this.props.match} />}*/}
                        </>
                    }
                    <div className="body_container profile-body">
                        <div className="pading-top80">
                            <div className="rounded-body card-wrapper mt-0 ">
                                {this.props.match.params.id && isConnectionProfile && <Detail {...this} {...this.props} userConnection = {this.state.userConnection} startLoader={this.startLoader} selectUser={this.selectUser} videoCalling={this.videoCalling} data={userData} scope={this} isOnline = {this.state.isOnline} connectionStatus={this.state.connectionStatus} onMoreClick={this.onMoreClick} connectionId={this.props.match.params.connectionId} requestType={requestType} currentUser={this.props.profile.profileInfo} />}

                            </div>
                        </div>

                    </div>
                </div>

            </>
        );
    }
}
// export default UserProfile;
function mapStateToProps(state) {    
    return {
        ...state
    };
}

export default connect(mapStateToProps)(CommunityUserProfile);

const Detail = (props) => {
    // console.log("ksjgvgdhjksakjdhbbh", props.connectionStatus)
    const CommunityMenu = (props) => {
        return (<Menu onClick={props.onMoreClick} >
            {props.isRemove && <Menu.Item key="Remove"> Remove Connection</Menu.Item>}
            <Menu.Item key="Block">Block</Menu.Item>
            <Menu.Item key="Report">Report</Menu.Item>
            {/* {props.isBusinessRequest && < Menu.Item key="AddBusinessConnection">Send business request</Menu.Item>} */}
        </Menu>)
    }
    let {roleId,
        sectorId,
        registrationId,placeOfWork, _id, firstName, lastName, companyName, jobTitleOther, city, country, phoneNumber, email, image, productAndServiceOfferId, attendingOrLookingforId, streetAddress1, zipCode, placeOfWorkId, jobTitleId, budgetId, noOfPeopleWorkId, userType, placeOfWorkOther } = props.data;
    return (

        <>
            <div className="table-wrapper">
                <a > <button onClick={() => props.scope.goBack(props.scope)} className="btn btn-light cursor-point float-right"><i class="fa fa-arrow-left" aria-hidden="true"></i>Back</button></a>
                {/* <LazyLoad> */}
                    <div className="profile-icon-wrapper pos-relative image-chat chat-profile">
                        <img className="img-fluid" src={image || require('../../../assets/images/login_user.png')} />
                        {props?.connectionStatus?.status === 1 && <span className= {`chat ${props.userConnection == 1 ? 'green' : 'yellow'}`} onClick={() => props.selectUser(props.data)} ><Chat /></span>}
                        <input type="file" />
                    </div>
                {/* </LazyLoad> */}

                <br />
                <div className="self-detail">
                    {firstName && 
                    <h4 className="text-center mb-2">{`${firstName} ${lastName}`}</h4>}
                    <p>{jobTitleOther ? jobTitleOther :  jobTitleId?.categoryTitle}</p>
                    <p className="pb-3">{companyName ? companyName : ''}</p>

                    {(!props.requestType && (props.connectionStatus === 4)) && <p className="text-center mb-4 pb-3"><span className="action_group  ">
                        <CmBtn text="Connect" className="btn btn-blue" bg='var(--btncolor)' onClick={(e) => props.scope.onConnectRequest(e, _id, 1)} />
                        &nbsp; &nbsp;
                        
                        
                    </span>
                    { props?.data?.userType != 0 &&  <Link  to={`/business/${props?.data?.userType == 4 ? props?.data?.ownerId : props?.data?._id }`}   className="btn btn-blue business-btn"  bg="#2d688d">View Brand Showcase </Link>}
                    &nbsp; &nbsp;
                        <Dropdown overlay={<CommunityMenu onMoreClick={props.onMoreClick} />} placement="bottomCenter">
                            <button className="btn btn-blue" >More</button>
                        </Dropdown>
                    </p>
                    }

                    {(!props.requestType && (props.connectionStatus === 1 || props.connectionStatus?.status === 1)) && <p className="text-center mb-4 pb-3"><span className="action_group  ">
                        <button className="btn btn-blue" onClick={() => props.videoCalling(_id)} ><span className="zoom"><ZoomVideo /></span>Video Call</button>  &nbsp; &nbsp;
                        <button className="btn btn-blue" onClick={() => props.selectUser(props.data)} ><span className="zoom"><Chat /></span>Chat</button>
                        { props?.data?.userType != 0 &&  <Link  to={`/business/${props?.data?.userType == 4 ? props?.data?.ownerId : props?.data?._id }`}   className="btn btn-blue business-btn"  bg="#2d688d">View Brand Showcase </Link>}
                        &nbsp; &nbsp;
                        
                        <Dropdown overlay={<CommunityMenu isBusinessRequest={true} isRemove={true} onMoreClick={props.onMoreClick} />} placement="bottomCenter">
                            <button className="btn btn-blue" >More</button>
                        </Dropdown>
                        &nbsp; &nbsp;

                    </span>
                    </p>
                    }
                    {((props.connectionStatus === 0 || props.connectionStatus?.status === 0) && props.connectionStatus.requestedUserId == props.userDetail._id) && <p className="text-center mb-4 pb-3"><span className="action_group  ">
                        <CmBtn text="Cancel Request" className="btn btn-blue" bg='var(--btncolor)' onClick={(e) => props.scope.onRemoveAndBlock2("COMMUNITY_CONNECTION_REMOVE", 3, props?.connectionStatus?._id)} />
                        &nbsp; &nbsp;
                        { props?.data?.userType != 0 &&  <Link  to={`/business/${props?.data?.userType == 4 ? props?.data?.ownerId : props?.data?._id }`}   className="btn btn-blue business-btn"  bg="#2d688d">View business Showcase </Link>}
                        &nbsp; &nbsp;
                    </span>
                        <Dropdown overlay={<CommunityMenu onMoreClick={props.onMoreClick} />} placement="bottomCenter">
                            <button className="btn btn-blue" >More</button>
                        </Dropdown>
                    </p>
                    }

                    {!props.requestType && (props.connectionStatus === 2 || props.connectionStatus === 3) && <p className="text-center mb-4 pb-3"><span className="action_group  ">  &nbsp; &nbsp;
                        <CmBtn text="Video Call" className="btn btn-blue" onClick={(e) => props.scope.onConnectRequest(e, _id, 1)} />
                        &nbsp; &nbsp;
                        { props?.data?.userType != 0 &&  <Link  to={`/business/${props?.data?.userType == 4 ? props?.data?.ownerId : props?.data?._id }`}   className="btn btn-blue business-btn"  bg="#2d688d">View business Showcase </Link>}
                        &nbsp; &nbsp;
                        <Dropdown overlay={<CommunityMenu onMoreClick={props.onMoreClick} />} placement="bottomCenter">
                            <button className="btn btn-blue">More</button>
                        </Dropdown>
                    </span>
                    </p>

                    }
                    {((props.connectionStatus === 0 || props.connectionStatus?.status === 0) && props.connectionStatus.requestedUserId != props.userDetail._id) && <p className="text-center mb-4 pb-3"><span className="action_group">
                        <CmBtn text=" Accept" className="btn btn-blue" bg='var(--btncolor)' onClick={(e) => props.scope.onAcceptReject(e, props.connectionStatus._id, 1)} />
                        <CmBtn text="Ignore" className="btn btn-blue" bg="#fff" color="#A3AAB0" border="1px solid #91cbd3" onClick={(e) => props.scope.onAcceptReject(e, props.connectionStatus._id, 2)} />
                        { props?.data?.userType != 0 &&  <Link  to={`/business/${props?.data?.userType == 4 ? props?.data?.ownerId : props?.data?._id }`}   className="btn btn-blue business-btn"  bg="#2d688d">View business Showcase </Link>}
                    </span>
                    </p>
                    }

                    {(!props.requestType && (props.connectionStatus === 1 || props.connectionStatus?.status === 1) && props.connectionStatus?.isBusinessConnection != 2) && <p className="text-center mb-4 pb-3"><span className="action_group  ">
                        <p className="text-center viewbrandshowcse">
                            {/* <CmBtn onClick={() => props.onCreateBussinessRequest("AddBusinessConnection")} text="Send business request" className="btn btn-blue business-btn" bg='var(--btncolor)' /> */}
                            {/* { props?.data?.userType != 0 &&  <Link  to={`/brand/${props?.data?.userType == 4 ? props?.data?.ownerId : props?.data?._id }`}   className="btn btn-blue business-btn"  bg="#2d688d">View Brand Showcase </Link>} */}
                        </p>

                    </span>
                    </p>
                    }
                

        


                </div>
                <div class="header-text text-left self-deatils-table">
                    <h1 class="title">Work Details</h1>

                </div>
                <div className="table-responsive vertical-table">
                <ul className="detail_row">
                    <li>Sector</li>
                    <li>
                        {sectorId?.map((item) => (
                            <span>{`${item.name}, ${""}`}</span>
                        ))}
                    </li>
                    <li>Place of Work</li>
                    <li>
                        {placeOfWork?.map((item) => (
                            <span>{`${item.name}, ${""}`}</span>
                        ))}
                    </li>
                        {/*<li>Place of work</li>*/}
                        {/*<li>{placeOfWorkOther ? placeOfWorkOther : placeOfWorkId?.categoryTitle}</li>*/}
                        {/*<li>No of people work with</li>*/}
                        {/*    <li>{noOfPeopleWorkId?.categoryTitle}</li>*/}
                        {/* <li>Product & Service you offer</li>
                        <li>{productAndServiceOfferId?.map((item, index) => <p key={index}>{item?.categoryTitle}</p>)}</li> */}
                   </ul>
                        <ul className="detail_row">
                            <li>Job Role </li>
                            <li>
                                {roleId?.map((item) => (
                                    <span>{`${item.name}, ${""}`}</span>
                                ))}
                            </li>
                            {/*<li>Job Title</li>*/}
                            {/*<li>{jobTitleOther ? jobTitleOther : jobTitleId?.categoryTitle}</li>*/}
                            {/* <li>Why are your attending & what are you looking for ?</li>
                            <li>{attendingOrLookingforId?.map((item, index) => <p key={index}>{item?.categoryTitle}</p>)}</li> */}
                        </ul>
                    <ul className="detail_row">
                        <li>Country</li>
                        <li>{country}</li>
                        <li>City</li>
                        <li>{city}</li>
                    </ul>
                        {/* <ul className="detail_row"> */}
                            {/* <li>No of people work with</li>
                            <li>{noOfPeopleWorkId?.categoryTitle}</li> */}
                            {/* <li>What is your budget to spend in next 6 months ?</li>
                            <li>{budgetId?.categoryTitle}</li> */}
                        {/* </ul> */}
                        {/* <tr>
                            <td></td>
                            <td></td>
                            <td>City</td>
                            <td>{city}</td>
                        </tr> */}
                </div>
                {props.scope.state.isReport &&

                    <ReportModal
                        text='Close Me'
                        userData={props.data}
                        closePopup={() => { props.scope.setState({ isReport: false }) }}

                    />

                }
            </div>

        </>

    )
}