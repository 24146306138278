import {  
    GET_ROLE_SUCCESS
} from "../action/Type";




const InitalState = {
    profileInfo:{
       role:null
    }
}


// get all  users details
export const profile = (state = InitalState , action) => {   
    switch (action.type) {      
        case GET_ROLE_SUCCESS:
            return {
                ...state,
                ...action.payload
            }
    
        default:
            return state
    }

}