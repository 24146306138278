import React, { Component, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { Modal, Button, Checkbox, Calendar } from 'antd';
import { Pencil, Calender, Clock, Hert, Ticket, TwoUsers, PlayIcon, NoMediaICon, Applebtn, Googlebtn, Delete } from "../../../SvgIcon";

import { UsersIcn, ListIcn, FooterLocation } from '../../../components/common/SvgIcon';
import { Chat , ZoomVideo} from '../../../SvgIcon';

import moment from "moment-timezone"
import Loader from "../../../components/Loader"
import InlineError from "../../../components/InlineError";
import { notify } from '../../../components/Toaster';
import Spotlight from '../../../screens/web/conference/Spotlight'
import WebHeader from '../../layout/WebHeader';
import _ from "lodash"
import { ExclamationCircleOutlined } from '@ant-design/icons';


import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import Footer from "../../layout/Footer";

import Media from "../../../components/common/WebMedia"

import { Link, withRouter } from "react-router-dom"
import { getTimeZone, getTimeFormate } from "../../../utils/DateZone"
import { connect } from "react-redux";
// import { Modal } from 'antd';

import DiaryCalendar from '../../../screens/web/diary/DiaryCalendar';
import { groupActions } from "../../../redux/action";



import { getHome } from "../../../redux/action/Get";

import {
    MyAlert,
    MySpinner
} from "../../../components/index"
import socket from "../../../services/socket";
import AddToCalendar from 'react-add-to-calendar';

import DiaryMeetingModal from "../../web/diary/DiaryMeetingModal"
import ReshudleMetting from "../../web/diary/ReshudleMetting"

const StagJoinCard = React.lazy(() => import('../components/stages/StagJoinCard'));
const LoungeJoinCard = React.lazy(() => import('../components/lounges/LoungeJoinCard'));

const antIcon = <LoadingOutlined style={{ fontSize: 72 }} spin />;

let isProd = process.env.REACT_APP_ENV == 'prod'

const { confirm } = Modal;

class ConferenceDetail extends Component {


    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.carousel = React.createRef();

        this.state = {
            isCommingSoon: true,
            isfree: true,
            conferenceDetail: '',
            isLoading: true,
            stagList: [],
            loungList: [],
            speakersList: [],
            timetableList: [],
            password: '',
            passwordError: '',
            noOfDays: 1,
            dayStatus: [],
            npOfConference: 0,
            globalLoader: true,
            tab: 0,
            tabData: [],
            allTimeTableData: [],
            mediaData: [],
            isLoadingBtn: {},
            visible: false,
            selectedTableItem: '',
            calenderData: {},
            spotlight: [],
            selectedReminder: '',
            meetingAcceptModal: false,
            selectedMetting: '',
            typeTab: 2,
            meetingModal: false,
            mettingData: null,
            isEditTrue: false,
            reshduleMettingModal:false,
            selectedItems:[]




        }
        this.child = React.createRef();

        window.scrollTo(0, 0)

    }

    handleSetReminder = () => {
        console.log("jhgdhjjjjjjjjjjjj", this.state.selectedTableItem)
        const { _id, stageId, } = this.state.selectedTableItem
        let req = {
            conferenceId: this.props.match.params.coId,
            sessionId: _id,
            stageId: stageId._id
        }

        _Api(Url.ADD_REMINDER.method, Url.ADD_REMINDER.url, req)
            .then(() => {
                notify("success", 'Reminder added');
                this.setState({ visible: false })
            }).catch(err => {
                let errorMessage = err.error && err.error.responseMessage || err.message;
                notify("err", errorMessage);

            })
    }


    componentDidUpdate(prevProps) {
        console.log("dksjhagdjkls", prevProps.match.params.date , this.props.match.params.date)

        if (prevProps.match.params.date !== this.props.match.params.date || prevProps.match.params.created !== this.props.match.params.created) { 
            this.getConferenceDetail1()
        }

       
        
    }

    getConferenceDetail1 = (id) => {
        let { page, limit, search } = this.state
        let req = {
            conferenceId: this.props.match.params.coId
        }
        _Api(Url.GET_MY_EVENT.method, Url.GET_MY_EVENT.url, '')
            .then(resp => {
                console.log("testforit", resp.responseData.result.data)

                const timeTableDetail = resp.responseData.result.data
                // const { startDate, endDate } = conferenceDetail

                // console.log("dahj", )


                this.removeDuplicates(timeTableDetail?.map(item => ({ reminderId: item?._id, ssId: item?.sessionId?._id, ...item?.sessionId, ...item })))

                // let days = moment(endDate).diff(moment(startDate), 'days')



                // this.setState({ npOfConference: days })

                var grades = {};
                timeTableDetail.forEach(function (item) {
                    var grade = grades[item.startDate] = grades[item.startDate] || {};
                    grade[item.startDate] = true;
                });            
            

                this.setState({
                    globalLoader: false,
                    allTimeTableData: this.sortByTime(timeTableDetail),
                    // mediaData: mediaDetail,
                    // speakersList: speakerDetail, stagList: stageDetail, loungList: loungeDetail, conferenceDetail, 
                    isLoading: false
                },()=>{
                    console.log("ncdkjcndjskcn", this.state.allTimeTableData)
                    if(this.props.match.params.date)
                    this.handleTab2(this.props.match.params.date)
                  let index =   this.state.allTimeTableData.findIndex(item => item._id == this.props.match.params.meetingId)
                  
                  if(index != -1)
                  this.showAcceptModal(this.state.allTimeTableData[index])

                })


            }).catch(err => {
                console.log("dshhjk", err)
                this.setState({ globalLoader: false, isLoading: false })

            })

    }



    next = () => {
        this.carousel.next();
    }
    previous = () => {
        this.carousel.prev();
    }

    componentDidMount() {
        let self = this;
        if(this.props.match.params.meetingId)
         this.getConferenceDetail1()
        else
        this.getConferenceDetail()
        this.props.dispatch({ type: 'PATH_ADD', payload: this.props.match })
        this.getInfo()

    }

    getInfo = () => {
       let self = this
        socket.on('stageEdit', function (params) {
            console.log("stageEdit", params)
            window.location.reload()

        })
        socket.on('stageDelete', function (params) {
            console.log("stageDelete", params)
            window.location.reload()

        })

        socket.on('meetingListUpdate', function (params) {
            console.log("meetingListUpdate",params )
            if (params?.userId == localStorage.getItem("id"))
                    self.getConferenceDetail()
                // window.location.reload()
            if (params?.senderId == localStorage.getItem("id"))
                   self.getConferenceDetail()
                // window.location.reload()
        })



    }
    componentWillUnmount() {
        socket.off("stageEdit")
        socket.off("stageDelete")
        socket.off("meetingListUpdate")
    }


    removeDuplicates = (data) => {
        // console.log("askjhgdj", data)

        // Create an array of objects 


        // Display the list of array objects 


        // Declare a new array 
        let newArray = [];

        // Declare an empty object 
        let uniqueObject = {};

        // Loop for the array elements 
        for (let i in data) {

            // Extract the title 
            // console.log("jkddkmjfhjfkwel", moment(data[i]['startDate']).format('DD-MM-YYYY'))

            let objTitle = moment(data[i]['startDate']).format('DD-MM-YYYY')

            // Use the title as the index 
            uniqueObject[objTitle] = data[i];

            // console.log("dkjhsgbjnkdsmf", objTitle, uniqueObject)

        }

        // Loop to push unique object into array 
        for (let i in uniqueObject) {
            newArray.push(uniqueObject[i]);
        }

        // Display the unique objects 
     
        this.setState({ tabData: _.sortBy(newArray, 'startDate') }, () => {
            this.getTimeTableAccordingToDate(data, this.state.tabData[this.state.tab]?.startDate)
        })

    }

    // sortByTime = (arr) => {
    //     console.log("kjhasdjkkbnd", arr.forEach(item=> console.log("timess===>>",moment(item.startTime).format('hh:mm'))))
    //     console.log("kjhasdjkkbnd2", _.sortBy(arr, 'startTime').forEach(item=> console.log("timess2===>>",moment(item.startTime).format('hh:mm'))))
    //     return _.sortBy(arr, 'startTime')
    // }

    sortByTime = (arr) => {
        console.log("kjhasdjkkbnd", arr.forEach(item => console.log("timess===>>", moment(item.startTime).format('hh:mm'))))
        console.log("kjhasdjkkbnd2", _.sortBy(arr, 'startTime').forEach(item => console.log("timess2===>>", moment(item.startTime).format('hh:mm'))))
        return _.sortBy(arr, function (dateObj) {
            return moment.tz(new Date(dateObj.startTime), dateObj.timezone)
        });

    }





    getTimeTableAccordingToDate = (data = [], search) => {
        console.log("kdhsfjk", search )
        if(!search) return

        let arr = data.filter(item => moment(item.startDate).format('MM-DD-YYYY') == moment(search).format('MM-DD-YYYY'))

        console.log("kjhdghjkl", arr, this.sortByTime(arr))

        this.setState({ timetableList: this.sortByTime(arr) })


    }

    getConferenceDetail = () => {
        let { page, limit, search } = this.state
        let req = {
            conferenceId: this.props.match.params.coId
        }
        _Api(Url.GET_MY_EVENT.method, Url.GET_MY_EVENT.url, '')
            .then(resp => {
                console.log("jhgvbhnjms", resp.responseData.result.data)

                const timeTableDetail = resp.responseData.result.data
                // const { startDate, endDate } = conferenceDetail

                // console.log("dahj", )


                this.removeDuplicates(timeTableDetail?.map(item => ({ reminderId: item?._id, ssId: item?.sessionId?._id, ...item?.sessionId, ...item })))

                // let days = moment(endDate).diff(moment(startDate), 'days')



                // this.setState({ npOfConference: days })

                var grades = {};
                timeTableDetail.forEach(function (item) {
                    var grade = grades[item.startDate] = grades[item.startDate] || {};
                    grade[item.startDate] = true;
                });

              
            

                this.setState({
                    globalLoader: false,
                    allTimeTableData: this.sortByTime(timeTableDetail),
                    // mediaData: mediaDetail,
                    // speakersList: speakerDetail, stagList: stageDetail, loungList: loungeDetail, conferenceDetail, 
                    isLoading: false
                },()=>{
                    if(this.props.match.params.date)
                    this.handleTab2(this.props.match.params.date)
                })


            }).catch(err => {
                console.log("dshhjk", err)
                this.setState({ globalLoader: false, isLoading: false })

            })

    }



    handleTab = (tab, date) => {
        console.log("dahjdk", date, tab)
        this.getTimeTableAccordingToDate(this.state.allTimeTableData?.map(item => ({ reminderId: item?._id, ssId: item?.sessionId?._id, ...item?.sessionId, ...item })), date)
        this.setState({ tab })

    }

    handleTab2 = (date) => {
        // this.getTimeTableAccordingToDate(this.state.allTimeTableData?.map(item => ({ reminderId: item?._id, ssId: item?.sessionId?._id, ...item?.sessionId, ...item })), date)
       
        console.log("cbhdsj", moment(Number(date)).toDate().getDate())
        date = moment(Number(date)).format('DD-MM-YYYY')

     console.log("filter1",  date)
    
     let tab =   this.state.tabData.findIndex((item, index)=> moment(item.startDate).format('DD-MM-YYYY') == date)
    
     console.log("filter2",   moment(this.props.match.params.date).format().toString)
        // this.setState({ tab, date })
        this.handleTab(tab, moment(Number(this.props.match.params.date)))

    }



    deleteTimeTable = (id) => {
        let req = {
            isDeleted: 0,
            sessionId: id,
        }
        _Api(Url.DELETE_EVENT.method, Url.DELETE_EVENT.url, req)
            .then(resp => {
                console.log('delete', resp.responseData.message)
                notify('success', resp.responseData.message)
                this.setState({ visible: false })
                this.getConferenceDetail()
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
            })
    }


    // delete modal
    showDeleteConfirm = (id) => {
        confirm({
            title: 'Are you sure you want to delete this?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            onOk: () => {
                this.deleteTimeTable(id)
            },
            onCancel: () => {
                this.setState({ visible: false })
            },
        });
    }

    showSpeakerProfileModal = (data) => {
        this.setState({
            visibleSpeakerProfile: true,
            selectedSpeakerData: data
        });
    };



    handleCancel = e => {

        this.setState({
            visible1: false, visible: false,
            visibleSpeakerProfile: false,
        });
    };







    handleTableClick = (item) => {
        console.log("dsacdhj", item)
        this.setState({
            visible1: true,
            selectedTableItem: item,
            calenderData: {
                title: item?.sessionName,
                description: item?.description,
                location: 'UK',
                startTime: moment(item.startDate),
                endTime: moment(item.endDate)
            }

        })
    }


    handleJoinReplay = (id, sessionId, stageId) => {
        // let { page, limit, search } = this.state
        let req = {
            conferenceId: id,
            sessionId,
            stageId

        }
        _Api(Url.ANALYTICS_REPLAY.method, Url.ANALYTICS_REPLAY.url, req)
            .then(resp => {
                this.getConferenceDetail()


            }).catch(err => {


            })

    }


    handleAcceptReject = (id, meetingStatus) => {
        // let { page, limit, search } = this.state
        let req = {
            meetingId: id,
            meetingStatus

        }
        _Api(Url.ACCEPTREJECT_MEETING.method, Url.ACCEPTREJECT_MEETING.url, req)
            .then(resp => {
                console.log("pankajyadav", resp)

                this.handleAcceptCancel()
                this.getConferenceDetail()

            }).catch(err => {

                let errorMessage = err.error && err.error.responseMessage || err.message;
                notify("err", errorMessage);
                this.handleAcceptCancel()
                this.getConferenceDetail()




            })

    }

    onReject = (id, status) => {
        confirm({
            title: 'Are you sure you want to reject this?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            onOk: () => {
                this.handleAcceptReject(id, status)
            },
            onCancel: () => {
                this.setState({ visible: false })
            },
        });
    }

    handleAcceptCancel = () => {
        this.setState({ meetingAcceptModal: false });
        this.props.history.push("/event-detail")
    };

    showAcceptModal = (item) => {
        this.setState({
            meetingAcceptModal: true,
            selectedMetting: item
        });
    }


    onMeetingClose = () => {
        // ReactDOM.unmountComponentAtNode(document.getElementById('eventDetails'))
        this.setState({ meetingModal: false, reshduleMettingModal:false })

    }

    selectedMettingData = (mettingData) => {
        this.setState({ mettingData: mettingData, meetingModal: true }, () => {
            this.clickChild()
        })
        this.handleAcceptCancel()
    }

    selectedMettingData2 = (mettingData) => {
        this.setState({ mettingData: mettingData, reshduleMettingModal: true }, () => {
            this.clickChild()
        })
        this.handleAcceptCancel()
    }


    selectUser = (user) => {
        let self = this;
        console.log("userrrr", user, this.props.userDetail._id);
        // if (this.props.userDetail._id !== user._id) {
        this.setState({
            selectedUser: user,
            selectedUserId: user._id,
            chatType: 3,
            isShow: true
        }, function () {
            self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 3 })
            self.props.dispatch({ type: 'CHAT_OPEN' })
            document.body.classList.toggle('chat_open_mob');
            self.props.dispatch({ type: 'SELECTED_USER', selectedUser: user })
            socket.emit('groupAdd', { type: 7, userId: user._id });
            //socket.emit('userConnection', {userId: user._id});
        })
        // }

    }


    videoCalling = (userId, index) => {
        let self = this;

        if (userId) {
            this.setState({ startLoader: true })

            let oldArr = this.state.selectedItems
            oldArr.push(userId)

            self.setState({
                selectedItems: oldArr,

                ['videoConnectionLoder_' + userId]: true
            })
            self.props.dispatch(groupActions.joinOneMeeting({ userId: userId, type: 7 }))
                .then(function (res) {
                    self.setState({
                        ['videoConnectionLoder_' + userId]: false, startLoader: false,
                        selectedItems: self.state.selectedItems.filter(item => item != userId)
                    })
                    if (res.statusCode === 1 || res.statusCode === "1") {
                        //window.location.href = '/calling/' + self.props.groupDetail.meeting._id;
                        window.open('/calling/' + self.props.groupDetail.meeting._id, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                        //history.push('/calling/'+self.props.groupDetail.meeting._id)
                    } else {
                        self.setState({ startLoader: false, selectedItems: self.state.selectedItems.filter(item => item != userId) })

                    }
                    console.log("res", res)
                    // self.setState({ startLoader: false,  selectedItems: self.state.selectedItems.filter(item => item != userId) })
                })
        }


    }





    render() {
        console.log("conferenceDetail", this.state.timetableList);
        const { selectedSpeakerData, mediaData, tabData, tab, globalLoader, password, passwordError, conferenceDetail, stagList, loungList, speakersList, timetableList } = this.state

        let event = {
            title: 'Sample Event',
            description: 'This is the sample event provided as an example only',
            location: 'Portland, OR',
            startTime: '2016-09-16T20:15:00-04:00',
            endTime: '2016-09-16T21:45:00-04:00'
        }
        let items = [
            { apple: 'Apple Calendar' },
            { google: 'Google' }
        ];



        if (globalLoader)
            return (
                <section className="brand-home-banner-bg webhome">
                    {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
                    <div className="centerLoader" >
                        <Spin size="large" tip="Loading..." indicator={antIcon} />
                    </div>
                </section>


            )

        else
            return (
                <section id="eventDetails" className="Home-banner-bg diary-wrapper-cmn py-3 py-md-4">
                    <Suspense fallback={<div></div>}>
                        <div className="calenar-btn-view text-center">
                            <h1 className="home-heading pos-relative mb-5 text-center">Diary
                                      <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                            </h1>
                            <div className="dry-evnt-btns mb-2 mb-md-3 pt-2">
                                <button type="button" onClick={() => this.setState({ typeTab: 1 })} className={`btn mr-md-3 mb-2 mb-md-0 ${this.state.typeTab == 1 ? 'active' : ''}`}><i className="fa fa-calendar"></i> Calendar</button>
                                <button type="button" onClick={() => this.setState({ typeTab: 2 })} className={`btn mr-md-3 mb-2 mb-md-0 ${this.state.typeTab == 2 ? 'active' : ''}`}><i className="fa fa-table"></i> Table Form</button>
                            </div>

                            {
                                this.state.typeTab == 1 ?
                                    <div className={this.state.isCommingSoon ? " container-fluid brandhome pos-relative" : " container-fluid"}>
                                        <div className="cm-wrapper confreance-detail-parent conference-dd pt-4 pt-md-4">
                                            {!this.state.isLoading && timetableList.length == 0 ?
                                                <div className="w-100 text-center row nodata m-auto">
                                                    <div className="m-auto">
                                                        <NoMediaICon />

                                                        <h2>No Data Found</h2>
                                                    </div>
                                                </div>
                                                :
                                                ''
                                            }

                                            {!this.state.isLoading && timetableList.length != 0 ?
                                                <DiaryCalendar
                                                    {...this}
                                                    {...this.state}
                                                    {...this.props}
                                                />
                                                :
                                                ''
                                            }

                                        </div>
                                    </div>

                                    : <div className={this.state.isCommingSoon ? " container-fluid brandhome pos-relative" : " container-fluid"}>
                                        <div className="cm-wrapper confreance-detail-parent conference-dd evnt-diary-wrp pt-4">
                                            <div className="event-timetable">

                                                <div className="event-tab-parent">
                                                    {
                                                        tabData.map((item, index) => {

                                                            return (

                                                                <div className={`event-tab ${tab == index ? 'active' : ''}`} onClick={() => this.handleTab(index, item.startDate)} >
                                                                    <div>
                                                                        <h3>{moment(item?.startDate).format('Do')}</h3>
                                                                        <h6>{moment(item?.startDate).format('MMMM')}</h6>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })

                                                    }


                                                </div>

                                                <div className="event-tabbody-parent mt-4">
                                                    {
                                                        timetableList.map((item, index) => {
                                                            let speaker = item?.speakerId ? item?.speakerId : []
                                                            // console.log("abcdefgh", item)
                                                            if (item.eventType == 1) {
                                                                const { userData, senderData } = item
                                                                return (
                                                                    <div className="event-tabbody meeting-body-event cmn-diary-meeting">
                                                                        <div className="events-type-diary w-100">
                                                                            <h4>Meeting</h4>
                                                                        </div>

                                                                        <div className="diaryevnt-time time-details">
                                                                            <span className="d-block mb-2"><Clock /></span>
                                                                            <h6 className="mt-2 "><b>Start Time</b></h6>
                                                                            <h6 className="mt-2 ">{getTimeZone(item?.startTime, moment.tz.guess())}</h6>
                                                                            <h6 className="mt-2 "><b>End Time</b> </h6>
                                                                            <h6 className="mt-2 ">{getTimeZone(item?.endTime, moment.tz.guess())}</h6>
                                                                            <div className="btm-time-btns">
                                                                            {senderData?._id == localStorage.getItem("id") && item.meetingStatus == 1 &&  
                                                                             <button  
                                                                             onClick={(e) => this.videoCalling(userData._id, index)}
                                                                              className="btn btn-blue mt-2"><span className="zoom"><ZoomVideo /></span>Video Call</button>
                                                                            
                                                                            }
                                                                             {senderData?._id != localStorage.getItem("id") && item.meetingStatus == 1 &&  
                                                                             <button  
                                                                             onClick={(e) => this.videoCalling(senderData._id, index)}
                                                                              className="btn btn-blue mt-2"><span className="zoom"><ZoomVideo /></span>Video Call</button>
                                                                            
                                                                            }
                                                                           
                                                                                {/* <button type="button" onClick={() => this.showAcceptModal(item)} className="btn btn-comm w-100">Updated</button> */}

                                                                            </div>
                                                                        </div>
                                                                        <div className="event-detail diary-evnt-dtls text-left" >
                                                                            <div className="diry-evts-title">
                                                                                <h2 style={{ cursor: 'pointer' }} onClick={() => this.showAcceptModal(item)} >{item?.name}</h2>
                                                                                <div className="topbar-diary-icn">


                                                                                    {senderData?._id != localStorage.getItem("id") && <button type="button" onClick={() => this.selectedMettingData(item)} className="btn">
                                                                                        <Pencil />
                                                                                    </button>}
                                                                                    &nbsp;
                                                                                {<button type="button" onClick={() => this.showDeleteConfirm(item?.reminderId)} className="btn">
                                                                                        <Delete />
                                                                                    </button>}
                                                                                </div>  
                                                                            </div>


                                                                            <div className="diary-evnt-spkr">
                                                                                <div className="row">
                                                                                    <div className="col-md-12">
                                                                                        <div className="speaker-details">
                                                                                            <div className="profile " style={{ marginTop: 8 }}>
                                                                                                <img src={userData?.image ? userData?.image : require('../../../assets/images/login_user.png')} className="img-fluid" />

                                                                                                <span className="text">
                                                                                                    <p>{userData.firstName} {userData.lastName} </p>
                                                                                                    <span className="">(Organiser)</span>
                                                                                                    {/* <p className="mb-0">Software Engineerr</p> */}
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="profile" style={{ marginTop: 8 }}>
                                                                                                <img src={senderData?.image ? senderData?.image : require('../../../assets/images/login_user.png')} className="img-fluid" />

                                                                                                <span className="text">
                                                                                                    <p>{senderData?.firstName} {senderData?.lastName}</p>
                                                                                                    {/* <p className="mb-0">Software Engineerr</p> */}
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {senderData?._id == localStorage.getItem("id") && <div className="col-md-6 d-none">
                                                                                        <div className="btm-diaryevnt-btns text-md-right">
                                                                                            {item.meetingStatus == 0 && <ul className="list-inline mb-0">
                                                                                                <li className="list-inline-item">
                                                                                                    <button type="button" onClick={() => this.handleAcceptReject(item._id, 1)} className="btn dbtn btn-success btn-sm">Accept</button>
                                                                                                </li>
                                                                                                <li className="list-inline-item">
                                                                                                    <button type="button" onClick={() => this.onReject(item._id, 2)} className="btn dbtn btn-danger btn-sm">Decline</button>
                                                                                                </li>
                                                                                                <li className="list-inline-item">
                                                                                                    <span onClick={() => this.selectUser(userData)} className="chat cursor-point yellow">
                                                                                                        <Chat />
                                                                                                    </span>
                                                                                                </li>
                                                                                            </ul>}

                                                                                        </div>
                                                                                    </div>}
                                                                                    
                                                                                    
                                                                                    <div className="col-md-12">
                                                                                        <div className="btm-diaryevnt-btns he text-md-right">
                                                                                            <ul className="list-inline mb-0">
                                                                                                {senderData?._id == localStorage.getItem("id") && <>
                                                                                                    {item.meetingStatus == 0 && <> <li className="list-inline-item">
                                                                                                        <button type="button" onClick={() => this.handleAcceptReject(item._id, 1)} className="btn dbtn btn-success btn-sm">Accept</button>
                                                                                                    </li>
                                                                                                    <li className="list-inline-item">
                                                                                                        <button type="button" onClick={() => this.onReject(item._id, 2)} className="btn dbtn btn-danger btn-sm">Decline</button>
                                                                                                    </li>
                                                                                                    <li className="list-inline-item">
                                                                                                        <span onClick={() => this.selectUser(userData)} className="chat cursor-point yellow">
                                                                                                            <Chat />
                                                                                                        </span>
                                                                                                    </li> </>}
                                                                                                </>}
                                                                                                
                                                                                            {senderData?._id == localStorage.getItem("id") && item.meetingStatus == 2 && <><li className="list-inline-item">
                                                                                                    <button type="button" className="btn btn-sm btn-outline-secondary no-cursor">Declined</button>
                                                                                                </li>
                                                                                                <li className="list-inline-item">
                                                                                                    <span onClick={() => this.selectUser(userData)} className="chat cursor-point yellow">
                                                                                                        <Chat />
                                                                                                    </span>
                                                                                                </li></>}

                                                                                            {/* {senderData?._id == localStorage.getItem("id") && item.meetingStatus == 2 && <>

                                                                                                <li className="list-inline-item">
                                                                                                    <button type="button" className="btn btn-sm btn-outline-secondary no-cursor">Declined</button>
                                                                                                </li>
                                                                                                <li className="list-inline-item">
                                                                                                    <span onClick={() => this.selectUser(userData)} className="chat cursor-point yellow">
                                                                                                        <Chat />
                                                                                                    </span>
                                                                                                </li>

                                                                                            </>} */}








                                                                                            {senderData?._id != localStorage.getItem("id") && item.meetingStatus == 2 && <>

                                                                                                <li className="list-inline-item">
                                                                                                    <button type="button" className="btn btn-sm btn-outline-secondary no-cursor">Declined</button>
                                                                                                </li>
                                                                                                <li className="list-inline-item">
                                                                                                    <span onClick={() => this.selectUser(senderData)} className="chat cursor-point yellow">
                                                                                                        <Chat />
                                                                                                    </span>
                                                                                                </li>

                                                                                            </>}

                                                                                            {senderData?._id == localStorage.getItem("id")  && <>

                                                                                                <li className="list-inline-item">
                                                                                                    <button type="button" onClick={()=> this.selectedMettingData2(item)} className="btn btn-outline-warning btn-sm" >Reshdule</button>
                                                                                                </li>

                                                                                            </>}




                                                                                            {senderData?._id == localStorage.getItem("id") && item.meetingStatus == 1 && <>

                                                                                                {/* <li className="list-inline-item">
                                                                                                    <button type="button" className="btn btn-outline-success btn-sm" >Accepted</button>
                                                                                                </li> */}
                                                                                                <li className="list-inline-item">
                                                                                                    <span onClick={() => this.selectUser(userData)} className="chat cursor-point yellow">
                                                                                                        <Chat />
                                                                                                    </span>
                                                                                                </li>

                                                                                            </>}

                                                                                            {senderData?._id != localStorage.getItem("id") && item.meetingStatus == 1 && <>

                                                                                                <li className="list-inline-item">
                                                                                                    <button type="button" className="btn btn-outline-success btn-sm no-cursor" >Accepted</button>
                                                                                                </li>
                                                                                                <li className="list-inline-item">
                                                                                                    <span onClick={() => this.selectUser(senderData)} className="chat cursor-point yellow">
                                                                                                        <Chat />
                                                                                                    </span>
                                                                                                </li>

                                                                                            </>}





                                                                                            {senderData?._id != localStorage.getItem("id") && item.meetingStatus == 0 && <>

                                                                                                <li className="list-inline-item">
                                                                                                    <button type="button" className="btn btn-sm btn-outline-info">Pending</button>
                                                                                                </li>
                                                                                                <li className="list-inline-item">
                                                                                                    <span onClick={() => this.selectUser(senderData)} className="chat cursor-point yellow">
                                                                                                        <Chat />
                                                                                                    </span>
                                                                                                </li>

                                                                                            </>}
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                            
                                                                        </div>
                                                                    </div>
                                                                )

                                                            }
                                                            else {
                                                                return (
                                                                    <div className="event-tabbody conference-body-event cmn-diary-meeting">
                                                                        <div className="events-type-diary w-100">
                                                                            <h4>Conference</h4>
                                                                        </div>
                                                                        
                                                                        <div className="time-details">
                                                                            <h6 className="">
                                                                                <span ><Clock /></span><br />
                                                                                <h6 className="mt-2 "><b>Start Time</b></h6>
                                                                                <h6 className="mt-2 ">{getTimeZone(item?.startDate, moment.tz.guess())}</h6>
                                                                                <h6 className="mt-2 "><b>End Time</b> </h6>
                                                                                <h6 className="mt-2 ">{getTimeZone(item?.endDate, moment.tz.guess())}</h6>
                                                                                {/* <h6>(GMT)</h6> */}
                                                                            </h6>
                                                                            <Link to={`/seminar/${item?.stageId?.conferenceId}`} class="btn btn-con mt-2 mb-2" >View Conference</Link>
                                                                            {/* <button type="button" class="btn btn-blue mt-2 mb-2" ><span>< PlayIcon /></span>Rewatch   </button> */}
                                                                            {/* <button type="button" onClick={() => this.handleTableClick(item)} class="btn btn-blue mt-2 mb-2" ><span className="cal"><Calender /></span>Attend   </button> */}

                                                                            {
                                                                                moment().isSameOrBefore(item?.endDate) &&
                                                                                <button type="button" onClick={() => this.handleTableClick(item)} class="btn btn-blue mt-2 mb-2" ><span className="cal"><Calender /></span>Attending</button>}

                                                                            {item?.videoUrl &&
                                                                                <Link onClick={() => this.handleJoinReplay(item?.stageId?.conferenceId, item?.ssId, item?.stageId?._id)} to={`/seminar/${item?.stageId?.conferenceId}/${'table'}/${item?.ssId}`} className="btn btn-blue blue mt-2 mb-2"><span className="play-icon">< PlayIcon /></span>Replay</Link>}


                                                                        </div>
                                                                        <div className="event-detail">
                                                                            <div className="diry-evts-title">
                                                                                <h2>{item?.sessionName}<small>. Session</small></h2>
                                                                                    <div className="topbar-diary-icn" onClick={() => this.showDeleteConfirm(item?.reminderId)}>
                                                                                        <button className="btn" onClick={() => this.showDeleteConfirm(item?.reminderId)}>
                                                                                            <Delete />
                                                                                        </button>
                                                                                    </div>  
                                                                                </div>

                                                                            <h4>{item?.stageId?.stageName}<small>.&nbsp;Stage</small></h4>
                                                                            <p>{item?.description}
                                                                            </p>
                                                                            <div className="speaker-details">
                                                                                <div >
                                                                                    {
                                                                                        speaker.map((speaker, ind) => {
                                                                                            return (

                                                                                                <div className="profile" style={{ marginTop: 8 }}>
                                                                                                    {speaker?.image ?
                                                                                                        <img className="img-fluid" src={speaker?.image} onClick={() => this.showSpeakerProfileModal(speaker)} />
                                                                                                        :
                                                                                                        <img src={require('../../../assets/images/login_user.png')} onClick={() => this.showSpeakerProfileModal(speaker)} className="img-fluid" />
                                                                                                    }
                                                                                                    <span className="text" onClick={() => this.showSpeakerProfileModal(speaker)}>
                                                                                                        <p>{speaker?.firstName} {speaker?.lastName}</p>
                                                                                                        <p className="mb-0">{speaker?.jobTitleOther}</p>
                                                                                                    </span>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                    }
                                                    {
                                                        !this.state.isLoading && timetableList.length == 0 &&
                                                        <div className="w-100 text-center row nodata m-auto">
                                                            <div className="m-auto">
                                                                <NoMediaICon />

                                                                <h2>No Data Found</h2>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                            }

                            <Footer />
                            <Modal
                                footer={null}
                                centered
                                visible={this.state.visible1}
                                onOk={this.handleOk}
                                onCancel={this.handleCancel}
                                wrapClassName="mail_modal"
                                className="cm_popup signup_modal "
                            >
                                <div className="sign-modal-content timetable-modal">
                                    <div className="row align-items-center">
                                        <div className="col-md-12" >
                                            <h5 className="pos-relative text-center mt-5 mb-5">
                                                A reminder for this session has been set. You will recieve a notification just before the session starts.
                                            <br />
                                                To set a reminder in your calendar click below.<span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                            </h5>
                                            {/* <h4 className="text-center mt-5 mb-5">Do you want to add a reminder in: </h4> 
                                        <Link to="" class="calenderbtn">
                                            <span>Google Calender</span>
                                            <span><Googlebtn/></span>
                                        </Link>
                                            <br/>
                                        <Link to="" class="calenderbtn">
                                            <span>Apple Calender</span>
                                            <span><Applebtn/></span>
                                        </Link> */}
                                            <div className="text-center">
                                                <AddToCalendar
                                                    event={{ ...this.state.calenderData }}
                                                    // defaultValue={""}
                                                    optionsOpen={true}
                                                    buttonLabel="Do you want to add a reminder in"
                                                    listItems={items}

                                                />

                                                {/* <a onClick={this.handleSetReminder} class="calenderbtn mt-2">
                                                <span>Reminder for session</span>
                                                <span><Applebtn /></span>
                                            </a> */}
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                    </Suspense>








                    <Modal
                        footer={null}
                        centered
                        visible={this.state.visibleSpeakerProfile}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        wrapClassName="mail_modal"
                        className="cm_popup signup_modal "
                        zIndex="99999"
                    >
                        <div className="sign-modal-content timetable-modal">
                            <div className="row align-items-center">

                                <div className="col-md-12" >


                                    {

                                        <div className="text-center spaker-detail-popup mt-5">
                                            <div className="profile">
                                                <img className="img-fluid" src={selectedSpeakerData?.image ? selectedSpeakerData?.image : require("../../../assets/images/placeholder.png")} />
                                            </div>
                                            <div className="details">
                                                <h3>{selectedSpeakerData?.firstName} {selectedSpeakerData?.lastName}</h3>
                                                <h6>{selectedSpeakerData?.jobTitleOther}</h6>
                                                <p>{selectedSpeakerData?.about}</p>

                                            </div>
                                        </div>

                                    }



                                </div>
                            </div>
                        </div>
                    </Modal>









                    <Modal
                        wrapClassName="signup_parent_modal"
                        className="cm_popup signup_modal meeting-diary-mld-popup"
                        //cm_popup term-services
                        visible={this.state.meetingAcceptModal}
                        // onOk={this.handleOk}
                        onCancel={this.handleAcceptCancel}
                        width={500}
                        footer={null}
                        centered
                    >

                        <div className="sign-modal-content meeting-accept-mdal">
                            <div className="row align-items-center">

                                <div className="col-md-12" >
                                    {
                                        this.state?.selectedMetting?.userData?._id == localStorage.getItem("id") &&
                                        <h4><b> Meeting Requested with {this.state?.selectedMetting?.senderData?.firstName} {this.state?.selectedMetting?.senderData?.lastName}</b></h4>
                                    }
                                    {
                                        this.state?.selectedMetting?.senderData?._id == localStorage.getItem("id") &&

                                        <h4><b>Meeting request from {this.state?.selectedMetting?.userData?.firstName} {this.state?.selectedMetting?.userData?.lastName}</b></h4>


                                    }

                                    <h5 className="auth-heading pos-relative text-left meetingName mt-3 mt-md-4 mb-md-3">
                                        {this.state.selectedMetting.name}
                                    </h5>
                                    <div className="meeting-accept-mdl mb-2 mb-md-3">
                                        <ul className="list-unstyled mb-0">
                                            <li>
                                                <UsersIcn />
                                                <span>{this.state.selectedMetting?.meetingType == 0 ? ' Online Meeting' : 'Meeting in person'}</span>
                                            </li>
                                            <li>
                                                <Clock />
                                                <span>{getTimeFormate(this.state.selectedMetting?.startDate)}, {getTimeZone(this.state.selectedMetting?.startTime, moment.tz.guess())}</span>
                                            </li>
                                            <li>
                                                <div className="meetng-prsn-dry">
                                                    <div className="mtng-prsn-lft">
                                                        <UsersIcn />
                                                        <div className="mtng-desc">
                                                            <span className="d-block mb-2">
                                                                <img className="imgStyle" src={this.state?.selectedMetting?.senderData?.image ? this.state?.selectedMetting?.senderData?.image : require("../../../assets/images/login_user.png")} />
                                                                {this.state?.selectedMetting?.senderData?.firstName} {this.state?.selectedMetting?.senderData?.lastName}</span>
                                                            <span className="d-block">
                                                                <img className="imgStyle" src={this.state?.selectedMetting?.userData?.image ? this.state?.selectedMetting?.userData?.image : require("../../../assets/images/login_user.png")} />
                                                                {this.state?.selectedMetting?.userData?.firstName} {this.state?.selectedMetting?.userData?.lastName} <strong className="d-block">(Organiser)</strong></span>
                                                        </div>
                                                    </div>
                                                    <div className="acpt-mtngD">
                                                        {this.state?.selectedMetting?.meetingStatus == 1 ? <button type="button" className="btn btn-outline-success btn-sm no-cursor" >Accepted</button> : ''}
                                                        {this.state?.selectedMetting?.meetingStatus == 2 ? <button type="button" className="btn btn-sm btn-outline-secondary no-cursor" >Declined</button> : ''}
                                                        {this.state?.selectedMetting?.meetingStatus == 0 ? <button type="button" className="btn btn-sm btn-outline-info no-cursor" >Pending</button> : ''}
                                                    </div>
                                                </div>

                                            </li>
                                            {this.state.selectedMetting?.meetingType == 1 && this.state.selectedMetting?.location ? <li className="place-mtng">
                                                <FooterLocation />
                                                <span>{this.state.selectedMetting?.location}</span>
                                            </li> : ''}
                                            {this.state.selectedMetting?.description ?
                                                <li className="smList">
                                                    <ListIcn />
                                                    <span> {this.state.selectedMetting?.description}</span>
                                                </li> : ''
                                            }
                                        </ul>
                                    </div>

                                    {this.state?.selectedMetting?.userData?._id == localStorage.getItem("id") && <div className="form-group text-center mb-0">
                                        <button type="button" onClick={() => this.selectedMettingData(this.state.selectedMetting)} className="btn btn-blue w-100">Edit</button>
                                    </div>}

                                    {this.state?.selectedMetting?.senderData?._id == localStorage.getItem("id") && <div className="form-group text-center mb-0">

                                        {
                                            this.state?.selectedMetting?.meetingStatus == 0 && <>
                                                <button type="button" onClick={() => this.handleAcceptReject(this.state?.selectedMetting?._id, 1)} className="btn dbtn btn-success btn-sm w-50">Accept</button>
                                                <button type="button" onClick={() => this.onReject(this.state?.selectedMetting?._id, 2)} className="btn dbtn btn-danger btn-sm w-50">Decline</button>
                                            </>
                                        }

                                    </div>}

                                </div>
                            </div>
                        </div>

                    </Modal>


                    <Modal
                        wrapClassName="signup_parent_modal"
                        className="cm_popup signup_modal "
                        //cm_popup term-services
                        visible={this.state.meetingModal}
                        // onOk={this.handleOk}
                        onCancel={this.onMeetingClose}
                        footer={null}
                        centered
                    >
                        <DiaryMeetingModal {...this.props} setClick={click => this.clickChild = click} getConferenceDetail={this.getConferenceDetail} onMeetingClose={this.onMeetingClose} isEditTrue={this.state.meetingModal} diaryData={this.state.mettingData} />
                    </Modal>




                    <Modal
                        wrapClassName="signup_parent_modal meetingModal"
                        className="cm_popup signup_modal "
                        //cm_popup term-services
                        visible={this.state.reshduleMettingModal}
                        // onOk={this.handleOk}
                        onCancel={this.onMeetingClose}
                        footer={null}
                        centered
                    >
                        <ReshudleMetting {...this.props} setClick={click => this.clickChild = click} getConferenceDetail={this.getConferenceDetail} onMeetingClose={this.onMeetingClose} isEditTrue={this.state.reshduleMettingModal} diaryData={this.state.mettingData} />
                    </Modal>













                </section>
            )
    }

}

function mapStateToProps(state) {
    // console.log("njkdlsj", state)


    return {
        ...state

    };
}

export default connect(mapStateToProps)(ConferenceDetail);