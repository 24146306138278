import React from "react"
import { Hert, ExhibitionManagement, Pencil, UploadVideo , UploadImages} from '../../SvgIcon';


import { CircleCross } from '../../SvgIcon';

import InlineError from '../InlineError'

import { Player, BigPlayButton } from 'video-react';
import { notify } from '../../components/Toaster';
import siteSetting from "../../config/env/Index";
import Croper from "../Croper";

export default class MediaSelector extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            media: [],
            isLoading: [],
            selectedMedia: [1],
            isDisable: false,
            mediaUrl: '',
            mediaUrlError: '',
            isSelectUrl: false,
            isSelectMedia: false,
            mediaImgPreview:"",
        }
    }

    componentDidMount() {

    }

    handleImageUpload = (event) => {
        
        let reader = new FileReader();
        let file = event.target.files[0];
        let { valiDate } = this.state;

        if (file) {
            reader.readAsDataURL(file)
        }
        if (file) {
           
            let isVideo = file.type.split("/")[0]

            if (isVideo == "video" || file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png' ) {
                
                let size =( Math.round(file.size/1000000) )
               
                
                if (size <= siteSetting.videoUploadLimit) {
                    reader.addEventListener("load", () => {
                        this.setState({mediaUrl:'',mediaUrlError:'', profilePicPreview: reader.result }, function () {
                        })
                    }, false);
                   
                    valiDate = false;
                  
                    this.setState({
                        mediaUrl:'',
                        mediaUrlError:'',mediaError:'',
                        isSelectMedia: true,
                        isSelectUrl: false,
                        media: event.target.files[0],
                        profilePicErrorMsg: 'Profile pic size should be less than 5Mb.'
                    });
                } else {
                    
                    notify("err", "file size to large")
                    // this.setState({
                    //     isSelectMedia: true,
                    //     isSelectUrl: false,
                    //     media: event.target.files[0],
                    //     mediaError: ''
                    // });
                    // alert("wrong file")
                }
            } else {
                valiDate = false;
                this.setState({ mediaError: 'File type not supported.' })
            }
        }
    }






    handleSave = () => {
        const UrlRegx = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        const { mediaUrl } = this.state
        if (mediaUrl != '' && !UrlRegx.test(mediaUrl))
            this.setState({ mediaUrlError: '*Please enter valid youtube url' })
        else
            this.props.getMedia({ media: this.state.media, url: this.state.mediaUrl })
    }


    handleOnChange = (e) => {
        this.setState({ mediaUrl: e.target.value }, () => {
            if (this.state.mediaUrl == '') {
                this.setState({
                    isSelectMedia: false,
                    isSelectUrl: false, mediaUrlError: ''
                })
            }
            else {
                this.setState({
                    isSelectMedia: false,
                    isSelectUrl: true, mediaUrlError: ''
                })

            }
        })

    }

    handleCropperOpen = () => {
        this.setState({ isCropper: true })
    }

    finalCropedImage = (categoryImgPrev, categoryImg, visibleVal) => {



            this.setState({
                visibleVal,
                isCropper: false,
                mediaImgPreview: categoryImgPrev,
                media: categoryImg,
                isSelectMedia:true

            })


    }



    render() {

        const { media, selectedMedia, mediaUrl } = this.state
       
        return (
            <div className='popup'>
                <div className='popup_inner form small add-media-popup'>
                    <div className="mb-4 mt-4">
                        <div className="rounded-textbox-form">

                            <div className="header-text">
                                <h4>Add Media (image/video)</h4>
                            </div>
                            <br/>
                            <div className="row">
                                <div className="col-sm-6 offset-sm-3 text-center">
                               
                                    <level>Add youtube url or upload video</level>
                                    <input type="link"
                                        className="form-control"
                                        placeholder="Enter youtube url"
                                        maxLength={64}
                                        name="videoUrl"
                                        value={mediaUrl}
                                        onChange={this.handleOnChange}
                                        readOnly={this.state.isSelectMedia}
                                    />
                                    <InlineError
                                        message={this.state.mediaUrlError}
                                    />
                               

                                </div>
                               
                            </div>
                            <br/>
                            <div className="text-center media-img-parent">
                           
                                <div className="">
                                    <span className="or">Or</span>
                                    <div className="form-group custom-upload-file">
                                        <ul className="list-inline mt-3 pt-1">
                                            <li className="list-inline-item">
                                                <div className="upload-inner">

                                                    {
                                                        this.state.profilePicPreview ?
                                                            this.state.media ?
                                                                this.state.media.type.split("/")[0] == "video" ?
                                                                    < Player >
                                                                        <source src={URL.createObjectURL(this.state.media)} />
                                                                        <BigPlayButton position="center" />
                                                                    </Player>

                                                                    :

                                                                    <img onClick={() => this.inputElement.click()} src={this.state.profilePicPreview} />





                                                                : <span className="upload-icon"><UploadVideo /></span> :''
                                                }
                                                <span className="upload-icon"><UploadVideo /></span>
                                                   <input readOnly={this.state.isSelectUrl} 
                                                    type="file" ref={my_file => this.inputElement = my_file}
                                                     onChange={this.handleImageUpload}
                                                    className="d-block"
                                                    accept="video/mp4,video/x-m4v,video/*"
                                                    />

                                                 
                                                </div>
                                             
                                                <level>Maximum Size : {siteSetting.videoUploadLimit} MB</level>
                                            </li>
                                        </ul>
                                    </div>


                                </div>
                            
                              <span className="or">Or</span>
                              <br/><br/>
                              <level>Add Media Image</level>
                              <div className="form-group custom-upload-file">
                                <ul className="list-inline  pt-1">
                                    <li className="list-inline-item">
                                        <div className="upload-inner" onClick={()=>this.handleCropperOpen()}>
                                            {this.state.mediaImgPreview?
                                            <img src={this.state.mediaImgPreview} />:
                                            <span className="upload-icon"><UploadImages /></span>}
                                        </div>
                                        
                                    </li>
                                </ul>
                                <level className="size_msz"><b>Image should be 1920x1920 </b></level ><br/><level className="size_msz"><b>Max size 2MB / JPG format</b> </level>
                            </div>
                           
                            </div>

                        </div>
                        <div className="text-center pt-4">
                            <button 
                            
                            type="button"
                            className="btn btn-red" onClick={this.handleSave}
                                disabled={!this.state.isSelectUrl && !this.state.isSelectMedia}
                            >Save
                                {/* &nbsp; {this.state.isLoading && <MySpinner/>} */}
                            </button>

                        </div>
                        <button className="close-btn" onClick={this.props.closePopup}><CircleCross /></button>
                    </div>
                </div>
                {this.state.isCropper ?
                    <Croper aspect={1.5 / 1}
                        // onImageCrop={img => this.setState({ media: img })}
                        imgPrev={'1.5.jpg'}
                            finalCropedImage={(categoryImgPrev, categoryImg, visibleVal) => this.finalCropedImage(categoryImgPrev, categoryImg, visibleVal)} visibleVal={this.state.isCropper}
                            onClose={() => this.setState({ isCropper: false })}
                    />:""}
            </div >

        )


    }
}