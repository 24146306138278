import React, { Component } from 'react';
import { Hert, Brands, Pencil, Delete, UploadVideo, LandingUser, Chat, ZoomVideo } from '../../SvgIcon';
import ContentEditable from "react-contenteditable";
import Sidebar from '../../screens/layout/Header';
import Header from '../../screens/layout/Header';
import profilebody from '../../components/support/profilebody';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import socket from '../../services/socket';
import { connect } from "react-redux";
import { getExhibitionId, groupActions, setCount } from "../../redux/action";
import { createBrowserHistory } from "history";
import dateFormat from 'dateformat';
import { Misscall } from "../../SvgIcon";
import { Tooltip, Button } from 'antd';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import Linkify from 'react-linkify';
import { withRouter } from "react-router-dom";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import TooltipTrigger from 'react-popper-tooltip';
import ReactTooltip from "react-tooltip";
import { SecureLink } from "react-secure-link";
var _ = require('lodash');

const history = createBrowserHistory();

const text = <span>prompt text</span>;

const buttonWidth = 70;
let isProd = process.env.REACT_APP_ENV == 'prod'
var lastScrollTop = 0;



const AdminId = '5efa2db5d6d8f60f5bcf7aa2'


class SuportAdmin extends Component {
    constructor() {
        super();
        var isTooltipResponse = this.getDeviceType();
        this.state = {
            isShow: false,
            chatType: 1,
            message: '',
            expoMsgList: [],
            onlineUser: [],
            groupId: "",
            pageId: "",
            expoId: "",
            pageMsgList: [],
            userMsgList: [],
            selectedUser: null,
            selectedUserId: "",
            pageUser: [],
            userConnection: [],
            expoUser: [],
            pageName: "",
            totalUnreadMsg: 0,
            isTooltip: isTooltipResponse,
            isChatExpand: false,

        }

        this.contentEditableRef = React.createRef();
        this.ChatBox = React.createRef();
        //this.messagesEnd=React.createRef();
        this.onChanged = this.onChanged.bind(this);
        // this.checkUserType=this.checkUserType.bind(this);

    }

    getDeviceType = () => {
        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            return 0;
        }
        if (
            /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
                ua
            )
        ) {
            return 0;
        }
        return 1;
    };

    handleChange = evt => {
        let msg = evt.target.value;
        console.log("msg", msg)
        if (msg.startsWith("<br><br>")) {
            msg = msg.slice(0, -8);
        }
        if (msg.startsWith("&nbsp;")) {
            msg = msg.replace(/&nbsp;/g, '');
        }
        msg = msg.replace(/(<((?!br)[^>]+)>)/ig, " ");
        //msg=msg.replace(/<br\s*[\/]?>/gi," ");
        if (msg.endsWith("<div><br></div>")) {
            msg = msg.slice(0, -15);
        }
        if (msg.endsWith("<br>  <br> ")) {
            msg = msg.slice(0, -11);
        }
        this.setState({ message: msg });
    };

    getDate = (info) => {
        if (info) {
            var date = new Date(info)
            return dateFormat(date, "dddd, mmmm dS yyyy").toLocaleString();  //June 5th, 2020, 7:40:00 pm
        }
        return null;
    }

    getTime = (info) => {
        if (info) {
            var date = new Date(info)
            return dateFormat(date, "h:MM TT").toLocaleString();  //June 5th, 2020, 7:40:00 pm
        }
        return null;
    }

    checkDefaultActiveChat = (path) => {
        let urlArr = [
            "/media-viewed",
            "/job-viewed",
            "/view-block-connections",
            "/view-pending-connections",
            "/view-requested-connections",
            "/view-suggested-connections",
            "/view-connections",
            "/user/profile/:id",
            "/community",
            "/dashboard",
            "/profile",
            "/profile/edit",
            "/change-password",
            "/networking/search",
            "/document-download",
            "/cartview",
            "/manage-services",
            "/enquiry",
            "/enquiry",
            "/event-detail",
            "/myevent",
            "/manage-company",
            "/edit-manage-company",
            "/edit-sectors-categories",
            "/plan",
            "/pay-user",
            "/enquiry-details/:enId"
        ]
        if (_.includes(urlArr, path)) {
            return true;
        } else {
            return false;
        }
    }

    componentDidMount() {
        console.log("yessssssssss12121212")
        let self = this;
        if (this.checkDefaultActiveChat(this.props.pathDatail.path)) {
            this.setState({
                chatType: 2
            })
            self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 2 })
        }
        Events.scrollEvent.register('begin', function (to, element) {

        });

        Events.scrollEvent.register('end', function (to, element) {

        });

        // if (localStorage.getItem('accessToken') && localStorage.getItem('accessToken') !== '') {
        //     let exhibitionId = self.props.loading.exhibitionId;
        //     if(exhibitionId!==""&&exhibitionId!==null) {
        //         self.socketEvent();
        //     }else {
        //         self.props.dispatch(getExhibitionId()).then(function (){

        //         })
        //     }
        // }
        this.socketEvent();
        console.log("yesssssss1111111111")
        document.addEventListener("mousedown", this.handleClickOutside);

    }
    handleClickOutside = e => {
        //alert(12);
        if (this.ChatBox && this.ChatBox.current && !this.ChatBox.current.contains(e.target)) {
            const element = document.querySelector("body");
            if (element.classList.contains("webchatopen")) {
                if (!element.classList.contains("conference")) {
                    this.setState({ isChatExpand: false });
                    this.props.dispatch({ type: 'CHAT_TOGGLE' })
                }
            }
            //document.querySelector('body').classList.remove('');

        }
    };
    socketEvent = () => {
        console.log("yesssssss222222222222222222")
        let self = this;

        socket.emit('getMyUserList', { type: 22 });

        // socket.on('connect', function (data) {
        //     console.log("connectttttttttt", data);
        //     //socket.emit('getMessage',{groupId:exhibitionId,type:1});




        // });
        // socket.on('reconnecting', function (data) {
        //     console.log("connectttttttttt", data);
        //     //socket.emit('getMessage',{groupId:exhibitionId,type:1});

        //     socket.emit('groupAdd', {type: 22 });



        // });
        socket.on('error', function (error) {
            console.log("err_socket", error)

        })
        socket.on('connect_error', function (error) {
            console.log("err_socket", error)

        })
        socket.on('online', function (params) {
            console.log("online", params);
            self.changeUserStatus(params.user._id, 1);
        })
        socket.on('offline', function (params) {
            console.log("offline", params);
            self.changeUserStatus(params.user._id, 0);
        })
        socket.on('groupMsg', function (params) {
            console.log("groupMsg", params);
            if (params.type === 22) {
                var checkUser = _.find(self.state.userConnection, function (obj) {
                    return obj.groupId === params.group;
                });
                console.log("checkUser", checkUser);
                if (checkUser) {
                    checkUser.lastMsgtimeStamp = params.lastMsgtimeStamp;
                    checkUser.lastMessageDate = params.lastMessageDate;
                    let userConnection = self.state.userConnection;
                    var index = _.findIndex(userConnection, { _id: checkUser._id });
                    userConnection.splice(index, 1, checkUser);
                    self.setState({
                        userConnection
                    }, function () {
                        self.userListSort();
                    })
                }

                if (params.group === self.state.groupId && self.props.selectedUserId !== "") {
                    self.setState(prevState => ({
                        userMsgList: [...prevState.userMsgList, params]
                    }), function () {
                        console.log("dkjhjk", self.state.groupId)
                        socket.emit('messageRead', { groupId: self.state.groupId, readType: 1 });
                        if (params.userData._id === self.props.userDetail._id) {
                            self.scrollToBottom();
                        } else {
                            self.scrollToBottomLive();
                        }
                        self.userListSort();
                    })
                }
            }


        })
        socket.on('groupADD', function (params) {
            console.log("groupADDddd", params)
            socket.emit('getMessage', params);


            // if (params.type === 22) {
            //     socket.emit('getMyUserList', params);
            //     self.setState({
            //         pageId: params.groupId
            //     })
            // } 

        })
        socket.on('groupId', function (params) {
            console.log("groupId", params)
            if (params.type === 22) {

                self.setState({
                    groupId: params.groupId
                })
                //params.userData.unReadMsgCount=0;
                var checkUser = _.result(_.find(self.state.userConnection, function (obj) {
                    return obj._id === params.userData._id;
                }), '_id');
                console.log("checkUser", checkUser);
                if (!checkUser) {
                    if (self.props.userDetail._id !== params.userData._id) {
                        self.setState({
                            userConnection: [...self.state.userConnection, params.userData]
                        })
                    }
                } else {
                    params.userData.unReadCount = { count: 0 };
                    let userConnection = self.state.userConnection;
                    var index = _.findIndex(userConnection, { _id: params.userData._id });
                    userConnection.splice(index, 1, params.userData);
                    self.setState({
                        userConnection
                    })
                }
            }
        })
        socket.on('groupRemove', function (params) {
            console.log("groupRemove", params)
            if (params.type === 1) {
                let filteredArray = self.state.expoUser.filter(obj => obj._id !== params.userData._id)
                self.setState({ expoUser: filteredArray });
            } else {
                let filteredArray = self.state.pageUser.filter(obj => obj._id !== params.userData._id)
                self.setState({ pageUser: filteredArray });
            }
        })
        socket.on('lastMsg', function (params, type, lastMsgId, order) {

            console.log("ldkjhgjk", params, type, lastMsgId)

            if (type === 22) {
                let reverseData = _.reverse(_.clone(params.data));
                if (lastMsgId) {
                    let margeData = _.merge(_.keyBy(reverseData, '_id'), _.keyBy(self.state.userMsgList, '_id'));
                    var values = _.values(margeData);

                    if (values && values.length > 0) {
                        self.setState({
                            userMsgList: values
                        })
                    }
                } else {
                    self.setState(prevState => ({
                        userMsgList: reverseData
                    }), function () {
                        socket.emit('messageRead', { groupId: self.state.groupId, readType: 1 });  //all read
                        self.scrollToBottom();
                    })
                }
            } else if (type === 1) {
                let reverseData = _.reverse(_.clone(params.data));
                if (lastMsgId) {
                    let margeData = _.merge(_.keyBy(reverseData, '_id'), _.keyBy(self.state.expoMsgList, '_id'));
                    var values = _.values(margeData);

                    if (values && values.length > 0) {
                        self.setState({
                            expoMsgList: values
                        })
                    }
                } else {
                    self.setState({
                        expoMsgList: reverseData
                    }, function () {
                        self.scrollToBottom();
                    })
                }

            } else {
                let reverseData = _.reverse(_.clone(params.data));
                if (lastMsgId) {
                    let margeData = _.merge(_.keyBy(reverseData, '_id'), _.keyBy(self.state.pageMsgList, '_id'));
                    var values = _.values(margeData);

                    if (values && values.length > 0) {
                        self.setState({
                            pageMsgList: values
                        })
                    }
                } else {
                    self.setState(prevState => ({
                        pageMsgList: reverseData
                    }), function () {
                        self.scrollToBottom();
                    })
                }
            }
        })

        socket.on('getMyUserList', function (params) {
            console.log("getMyUserList", params);
            if (params.type === 22) {

                if (self.state.selectUser && self.state.selectUser._id !== "") {
                    //params.userData.unReadCount.count=0
                    let index = _.findIndex(params.user.data, { _id: self.state.selectUser._id });
                    let user = _.find(params.user.data, { _id: self.state.selectUser._id });
                    if (user) {
                        user.unReadCount.count = 0;
                        params.user.data.splice(index, 1, user);
                    }
                }

                console.log('ewqeqwewqewq', params)

                self.setState({
                    userConnection: params.user.data
                }, function () {
                    self.unreadMsg();
                })
            } else if (params.type === 1) {
                self.setState({
                    expoUser: params.user.data
                })
            } else {
                self.setState({
                    pageUser: params.user.data
                })
            }
        })
        socket.on('userListData', function (params) {
            console.log("userListData", params);
            if (params.type === 22) {

                var checkUser = _.result(_.find(self.state.userConnection, function (obj) {
                    return obj._id.toString() === params.userData._id.toString();
                }), '_id');
                if (!checkUser) {
                    self.setState({
                        userConnection: [...self.state.userConnection, params.userData]
                    }, function () {
                        self.unreadMsg();
                        self.userListSort();
                    })
                } else {
                    let userConnection = self.state.userConnection;
                    //console.log(self.props.chatId.selectedUser&&params.userData._id === self.props.chatId.selectedUser._id)
                    if (self.state.selectedUser && params.userData._id === self.state.selectedUser._id) {
                        params.userData.unReadCount.count = 0
                    } else {
                        let user = _.find(userConnection, { _id: params.userData._id });
                        console.log("user", user)
                        params.userData.unReadCount.count = user.unReadCount ? user.unReadCount.count + 1 : 0;
                    }
                    let index = _.findIndex(userConnection, { _id: params.userData._id });
                    userConnection.splice(index, 1, params.userData);
                    self.setState({
                        userConnection
                    }, function () {
                        self.unreadMsg();
                        self.userListSort();
                    })
                }

            }

        })

        socket.on('messageCount', function (params) {
            console.log("messageCount", params);
            let userConnection = self.state.userConnection;

            let user = _.find(userConnection, { groupId: params.groupId });
            console.log("user", user)
            if (user && user.unReadCount) {
                if (user.unReadCount) {
                    user.unReadCount.count = 0
                }
                let index = _.findIndex(userConnection, { groupId: params.groupId });
                userConnection.splice(index, 1, user);
                self.setState({
                    userConnection
                }, function () {
                    self.unreadMsg();
                })
            }
        })
    }

    changeUserStatus(userId, status) {
        let self = this;
        var checkUser = _.find(self.state.userConnection, function (obj) {
            return obj && obj._id === userId;
        });

        if (checkUser) {
            checkUser.isOnline = status;
            let userConnection = self.state.userConnection;
            var index = _.findIndex(userConnection, { _id: checkUser._id });
            userConnection.splice(index, 1, checkUser);
            self.setState({
                userConnection
            })
        }
    }

    componentWillUpdate(nextProps) {
        let self = this;
        //console.log("componentWillUpdate=========",nextProps,this.props);
        if (nextProps.pathDatail !== this.props.pathDatail) {
            // console.log("componentWillUpdate", nextProps);
            if (nextProps.chatId && nextProps.chatId.activeChatType === 1) {
                if (this.checkDefaultActiveChat(nextProps.pathDatail.path)) {
                    this.setState({
                        chatType: 2
                    })
                    self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 2 })
                }
            }
            this.groupAdd(nextProps);
        }
    }

    groupAdd = (props) => {
        let self = this;
        console.log("self.props.match.path", props)
        socket.emit('groupAdd', { type: 22 });
    }

    changeChatType = (chatType) => {
        let self = this;
        if (this.props.chatId.activeChatType !== chatType) {
            if (chatType === 2) {
                let exhibitionId = self.props.loading.exhibitionId;
                self.props.dispatch(groupActions.expoUserList({ exhibitionId: exhibitionId }))
                    .then(function (user) {
                        self.setState({
                            expoUser: self.props.expoUser
                        })
                    })
            }
            self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: chatType })
            this.setState({
                userMsgList: [],
                chatType,
                //groupId
            }, function () {
                if (chatType !== 3) {
                    self.setState({
                        selectedUser: {},
                        selectedUserId: "",
                    })
                    self.props.dispatch({ type: 'SELECTED_USER', selectedUser: {} })
                    self.props.dispatch({ type: 'GROUP_ID', id: "" })
                }
                self.scrollToBottom();
            })
        }
    }

    sendMessage = (ev) => {
        let self = this;
        ev.preventDefault();
        //console.log("opsihgadh", this.state.message.trim())
        let msg = this.state.message;
        if (msg.endsWith("<div><br></div>")) {
            msg = msg.slice(0, -15);
        }
        self.setState({
            message: msg
        }, function () {

            if (self.state.groupId !== "") {
                if (self.state.message.trim() !== '') {
                    socket.emit('groupSend', { groupId: self.state.groupId, message: self.state.message.trim() });
                    self.setState({
                        message: ''
                    }, function () {
                        self.contentEditableRef.current.focus();
                        self.scrollToBottom();
                    })
                }
            }


        })

    }

    inputFocus = () => {
        if (this.contentEditableRef && this.contentEditableRef.current) {
            this.contentEditableRef.current.focus();
        }
    }

    onChanged(e) {
        let self = this;
        let keyName = e.target.name;
        let KeyValue = e.target.value;

        this.setState({ [e.target.name]: e.target.value }, function () {
        });
        if (e.target.name === 'categoryImg') {

            let reader = new FileReader();
            let file = e.target.files[0];

            reader.onloadend = () => {
                this.setState({
                    categoryImgPrev: reader.result
                });
            }
            reader.readAsDataURL(file)

            this.setState({
                categoryImgPrev: file
            })
            this.setState({
                categoryImg: e.target.files[0]
            });
        }
    }
    handleChatMenu = () => {
        console.log("handleChatMenu");
        let self = this;
        self.props.dispatch({ type: 'CHAT_TOGGLE' })
        this.setState({
            isChatExpand: !this.state.isChatExpand
        })

        document.body.classList.remove('chat_open_mob');
        self.inputFocus();
        // this.setState({
        //     isChatExpand:!this.state.isChatExpand
        // })

        setTimeout(function () {

            self.scrollToBottom();
        }, 100)
    }
    checkUserType = (message) => {
        if (this.props.userDetail._id === message.userData._id) {
            return true;
        } else {
            return false
        }
    }

    scrollToBottom = () => {
        // const isShow = localStorage.getItem("showAll") == "true"
        // if(!isShow) return
        //console.log("scrollToBottom");
        var st = this.chatbody ? this.chatbody.scrollTop : 0;
        try {
            this.messagesEnd.scrollIntoView({ behavior: "smooth" });

        } catch (err) {
            console.log("scrollToBottom err", err);
        }
        lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    }

    scrollToBottomLive = () => {
        console.log("scrollToBottomLive");
        var st = this.chatbody?.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
        console.log("live", st >= lastScrollTop, st, lastScrollTop)
        if (st >= lastScrollTop) {
            try {
                this.messagesEnd.scrollIntoView({ behavior: "smooth" });

            } catch (err) { }
            lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
        }
    }

    selectUser = (user) => {
        console.log("userrrrrcallllllll", user)
        let self = this;
        this.setState({ selectUser: user })
        socket.emit('groupAdd', { type: 22, userId: user._id });
        // if (this.props.userDetail._id !== user._id&&this.props.chatId.selectedUser._id!== user._id) {
        //     this.setState({
        //         selectedUser: user,
        //         selectedUserId: user._id,
        //         chatType: 3,
        //         isShow: true
        //     }, function () {
        //         // self.props.dispatch({ type: 'CHAT_OPEN' })
        //         // self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 3 })
        //         // self.props.dispatch({ type: 'SELECTED_USER', selectedUser: user })
        //         socket.emit('groupAdd', { type: 22, userId: user._id });
        //         let userConnection=self.state.userConnection;
        //         let user1 = _.find(userConnection, {_id: user._id});
        //         //console.log("user1", user1)
        //         if(user1) {
        //             console.log("user", user1)
        //             user1.unReadCount = {count: 0};
        //             let index = _.findIndex(userConnection, {_id: user._id});
        //             userConnection.splice(index, 1, user1);
        //             self.setState({
        //                 userConnection
        //             })
        //         }
        //     })
        // }
    }

    videoCalling = (userId) => {
        let self = this;
        if (userId !== "" && self.props.chatId.groupId !== "") {
            window.open('/calling/' + self.props.chatId.groupId, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
        }
    }

    shouldShowContactAvatar = (item, i, chat) => {
        return (
            item.userData._id !== this.props.userDetail._id &&
            ((chat[i + 1] && chat[i + 1].userData._id === this.props.userDetail._id) || !chat[i + 1])
        );
    }

    shouldShowGroupContactAvatar = (item, i, chat) => {
        return (
            ((chat[i - 1] && chat[i] && chat[i - 1].userData._id !== chat[i].userData._id) || !chat[i - 1])
        );
    }

    shouldShowTime = (item, i, chat) => {
        return (
            ((chat[i + 1] && chat[i + 1].timeStamp && new Date(chat[i + 1].timeStamp).getDate() !== new Date(chat[i].timeStamp).getDate()) || !chat[i + 1])
        );
    }

    shouldShowDate = (item, i, chat) => {
        return (
            ((chat[i - 1] && chat[i - 1].timeStamp && new Date(chat[i - 1].timeStamp).getDate() !== new Date(chat[i].timeStamp).getDate()) || !chat[i - 1])
        );
    }

    callTime = (message) => {
        if (message.callEndTime && message.callEndTime !== "" && message.callStartTime && message.callStartTime !== "") {
            var diffMs = (new Date(message.callEndTime) - new Date(message.callStartTime));
            if (diffMs) {
                return (<>&nbsp;|&nbsp;<b>{Math.round(diffMs / 60000)}min</b></>)
            }
        }
    }

    onChatBodyScroll(e) {
        //console.log("onChatBodyScroll",e)
        var st = this.chatbody.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
        //console.log("st < lastScrollTop",st <= lastScrollTop,st , lastScrollTop)
        if (st <= lastScrollTop && lastScrollTop < 10) {
            console.log("onChatBodyScroll ======")
            let groupId = '';
            let lastMsgId = '';
            let type = "";
            if (this.props.chatId.activeChatType === 3) {
                groupId = this.props.chatId.groupId;
                lastMsgId = this.state.userMsgList[0] ? this.state.userMsgList[0]._id : "";
                type = 7;
            } else if (this.props.chatId.activeChatType === 2) {
                groupId = this.props.chatId.expoId;
                lastMsgId = this.state.expoMsgList[0] ? this.state.expoMsgList[0]._id : "";
                type = 1;
            } else {
                groupId = this.props.chatId.pageId;
                lastMsgId = this.state.pageMsgList[0] ? this.state.pageMsgList[0]._id : "";
                type = this.props.chatId.pageType
            }
            if (groupId !== '' && lastMsgId !== '') {
                console.log("lastMsgId", lastMsgId);
                socket.emit('getMessage', { groupId, lastMsgId, order: 1, type });
            }
            lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
        }

    }

    openProfile = (userId) => {
        let self = this;
        if (self.props.match && (self.props.match.path === "/calling/:id" || self.props.match.path === "/networking/search" || self.props.match.path === "/lounge/:id" || self.props.match.path === "/network-lounge/:id")) {

        } else {
            if (userId && userId !== "") {
                self.props.history.push('/user/profile/' + userId)
            }
        }
    }

    unreadMsg() {
        let self = this;
        let totalUnreadMsg = 0;
        _.map(self.state.userConnection, function (user) {
            if (user && user.unReadCount && user.unReadCount.count) {
                totalUnreadMsg = totalUnreadMsg + user.unReadCount.count;
            }
        })
        self.props.dispatch(setCount(totalUnreadMsg))
        self.setState({
            totalUnreadMsg,
        })
    }

    userListSort() {
        let self = this;
        let userConnection = self.state.userConnection;

        let sorted = userConnection.sort((a, b) => b['lastMsgtimeStamp'] - a['lastMsgtimeStamp']);
        //let sortRes=_.orderBy(userConnection, ['lastMsgtimeStamp'],['desc']);
        console.log("sortRes", sorted, userConnection)

        self.setState({
            userConnection: sorted
        })
    }

    pageUserListSort() {
        let self = this;
        let pageUser = self.state.pageUser;

        let sorted = pageUser.sort((a, b) => b['lastMsgtimeStamp'] - a['lastMsgtimeStamp']);
        //let sortRes=_.orderBy(userConnection, ['lastMsgtimeStamp'],['desc']);
        console.log("sortRes", sorted, pageUser)

        self.setState({
            pageUser: sorted
        })
    }

    expoUserListSort() {
        let self = this;
        let expoUser = self.state.expoUser;

        let sorted = expoUser.sort((a, b) => b['lastMsgtimeStamp'] - a['lastMsgtimeStamp']);
        //let sortRes=_.orderBy(userConnection, ['lastMsgtimeStamp'],['desc']);
        console.log("sortRes", sorted, expoUser)

        self.setState({
            expoUser: sorted
        })
    }

    handleKeyUp = (e) => {
        //console.log("e",e,e.target.value)
        if (e.key === '') {
            this.contentEditableRef.current.innerHTML = '';
        }
        if (e.key === 'Enter' && e.shiftKey) {

        } else if (e.key === 'Enter') {
            this.sendMessage(e)
        }
    }

    handleKeyPress = (e) => {
        console.log("#####e", e.key)
        console.log("#####e", this.contentEditableRef.current.innerHTML)
        // if (e.key === 'Enter'&& e.shiftKey) {
        //
        // }else if(e.key === 'Enter'){
        //
        // }
        if (e.key === '') {
            this.contentEditableRef.current.innerHTML = '';
        }
    }

    refreshPage = () => {
        window.location.reload(false)
    }

    checkSeletedUser = (user) => {
        console.log(user, "checkSeletedUser");
        if (this.props.chatId.selectedUser && user && this.props.chatId.selectedUser._id === user?._id) {
            return true;
        } else {
            return false;
        }
    }

    getJobRole = (user) => {
        if (user.jobRole && user.jobRole.length > 0) {
            return _.map(user.jobRole, function (obj, i) {
                if (i < user.jobRole.length) {
                    return `${obj.name}${i != user.jobRole.length - 1 ? "," : ""}`
                } else {
                    return obj.name
                }
            })
        } else {
            return user.jobTitleOther ? user.jobTitleOther : ""
        }
    }


    ProfileClick() {
        this.props.history.push("/profilebody");
    }


    render() {
        if(this.props.onlySocket)
         return <></>
        return (
            <>
                <div className="dash_body_container">
                    <Header {...this} />
                    <Sidebar />

                    <div className="body_container">

                        <div className="header-text  mb-4 mt-3">

                            <div className="d-flex align-items-center">
                                <p className="header-icon"><Brands /></p>
                                <h4>Support Chat</h4>
                                
                            </div>
                           
                        </div>
                        {/* <div className="search_wrapper  mr-2">
                                <i className="fa fa-search" />
                                <input type="search"
                                    placeholder="Search by name"
                                    name='search'
                                    // value={search}
                                    // onChange={this.handleSearch}
                                />
                            </div> */}
                        <div className="support-tabbar-parent">
                            <div className="support-tabbar-hader-parent">
                               <div className="scroll">
                               {
                                    this.state.userConnection?.map((user, index) => {
                                        console.log("dlakjshjk", user)
                                        return (

                                            <div className="support-tabbar-hader cursor-point" onClick={() => this.selectUser(user)}>
                                                <div className={this.state.selectUser?._id == user._id ? 'header active' : 'header'}>
                                                    <div className="profile pos-relative" >

                                                        <img src={user.image && user.image !== "" ? user.image : require(`../../assets/images/login_user.png`)} />
                                                        {/* <span className={(user.isOnline === 1 || user.isOnline === "1") ? "dot green" : "dot yellow"}></span> */}
                                                    </div>
                                                    <div className="discription">
                                                        <h6>{user?.firstName} {user?.lastName}</h6>
                                                        <p >{user?.companyName}</p>
                                                        {/* 4 */}
                                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p> */}
                                                    </div>
                                                    <div className="date">
                                                        {/* <p>Jun 12, 2017</p> */}
                                                        {user?.unReadCount?.count > 0 ? <div className="chat-counter"><p>{user.unReadCount.count}</p></div> : ""}

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                               </div>
                            </div>
                            <div className="support-tabbar-body">
                                <div class="chat-current-online-body">
                                    <div class="d-flex align-items-center justify-content-between w-100">
                                        <div class="cursor-point">
                                            <a target="_blank" href={`user-profile/${this.state.selectUser?._id}`}> <h4>{this.state.selectUser?.firstName} {this.state.selectUser?.lastName}</h4>
                                                <p >{this.state.selectUser?.companyName}</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>




                                <div className="chat-body-parent">

                                    <div className="chatbody" ref={(el) => { this.chatbody = el; }} onScroll={() => this.onChatBodyScroll()} >
                                        <div className="" id="chatBody">
                                            {

                                                this.state.userMsgList && this.state.userMsgList.length > 0 ?
                                                    this.state.userMsgList.map((message, i) => (
                                                        <>
                                                            {this.shouldShowDate(message, i, this.state.userMsgList) ?
                                                                <p className="date-time mt-2 date-show"><span>{this.getDate(message.timeStamp ? message.timeStamp : new Date())}</span></p> : ""}

                                                            {message.messageType !== 1 ? (message.callStatus === 0 || message.callStatus === 2) && new Date() - message.timeStamp > 120000 ?

                                                                this.checkUserType(message) === false ?
                                                                    <div className="call_notification">
                                                                        <h5><span><Misscall /></span> Missed a call from&nbsp;<b>{message.userData.firstName + (message.userData.companyName ? ', ' + message.userData.companyName : "")}</b> </h5>
                                                                        <p>{this.getTime(message.timeStamp ? message.timeStamp : new Date())}
                                                                            {/*&nbsp;|&nbsp;<b>4 mins</b> */}
                                                                        </p>
                                                                        <hr />
                                                                        <h6 className="text-center cursor-point" onClick={() => this.videoCalling(this.props.chatId.selectedUser._id)} >Call back</h6>
                                                                    </div> :
                                                                    <div className="call_notification menoti">
                                                                        <h5><span><Misscall /></span>
                                                                            {/*<b>{message.userData.firstName+', '+message.userData.companyName}</b>&nbsp; */}
                                                                            Missed a call from you</h5>
                                                                        <p>{this.getTime(message.timeStamp ? message.timeStamp : new Date())}
                                                                            {/*&nbsp;|&nbsp;<b>4 mins</b> */}
                                                                        </p>
                                                                    </div>
                                                                : message.callStatus === 3 ? <div className="call_notification menoti">
                                                                    <h5><span><ZoomVideo /></span>
                                                                        You were in a call with&nbsp;<b> {this.props.chatId.selectedUser.firstName + (this.props.chatId.selectedUser.companyName ? ", " + this.props.chatId.selectedUser.companyName : "")}</b>&nbsp;
                                                        </h5>
                                                                    <p>{this.getTime(message.timeStamp ? message.timeStamp : new Date())}
                                                                        {this.callTime(message)}
                                                                    </p></div> : ""

                                                                :
                                                                this.checkUserType(message) === false ?
                                                                    <div className={`${this.shouldShowContactAvatar(message, i, this.state.userMsgList) ? 'other' : 'other less'}`} key={'userMsg_' + i}>
                                                                        <div className="dp pos-relative">
                                                                            {this.shouldShowContactAvatar(message, i, this.state.userMsgList) ?
                                                                                <img className="img-fluid" src={message.userData.image && message.userData.image !== "" ? message.userData.image : require('../../assets/images/login_user.png')} />
                                                                                : ""}
                                                                        </div>
                                                                        <div className="msz-box-parent">
                                                                            <div className="msz-box">
                                                                                <Linkify
                                                                                    componentDecorator={(
                                                                                        decoratedHref,
                                                                                        decoratedText,
                                                                                        key
                                                                                    ) => (
                                                                                            <SecureLink href={decoratedHref} key={key}>
                                                                                                {decoratedText}
                                                                                            </SecureLink>
                                                                                        )}
                                                                                >
                                                                                    {ReactHtmlParser(message.message)}
                                                                                </Linkify>
                                                                            </div>
                                                                            {/*{this.shouldShowTime(message, i, this.state.userMsgList) &&*/}
                                                                            <p className="date-time mt-2">{this.getTime(message.timeStamp ? message.timeStamp : new Date())}</p>
                                                                            {/*}*/}
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className={`${this.shouldShowGroupContactAvatar(message, i, this.state.userMsgList) ? 'me' : 'me less'}`} key={'userMsg_' + i}>
                                                                        <div className="msz-box-parent">
                                                                            <div className="msz-box">
                                                                                <Linkify
                                                                                    componentDecorator={(
                                                                                        decoratedHref,
                                                                                        decoratedText,
                                                                                        key
                                                                                    ) => (
                                                                                            <SecureLink href={decoratedHref} key={key}>
                                                                                                {decoratedText}
                                                                                            </SecureLink>
                                                                                        )}
                                                                                >
                                                                                    {ReactHtmlParser(message.message)}
                                                                                </Linkify>
                                                                            </div>
                                                                            {/*{this.shouldShowTime(message, i, this.state.userMsgList) &&*/}
                                                                            <p className="date-time mt-2">{this.getTime(message.timeStamp ? message.timeStamp : new Date())}</p>
                                                                            {/*}*/}
                                                                        </div>
                                                                    </div>}
                                                        </>
                                                    )) :
                                                    <div className="no-chat-parent">
                                                        <span><Chat /></span>{
                                                            this.state.selectUser ?
                                                                <p>Let's start a conversation.</p>
                                                                :
                                                                <p>Select a contact to start a conversation.</p>
                                                        }

                                                    </div>

                                            }
                                            <div ref={(el) => { this.messagesEnd = el; }} />
                                        </div>


                                        {/* <div class="" >
                                                            <p class="date-time mt-2 date-show"><span>Thursday, December 10th 2020</span></p>
                                                            <div class="other less">
                                                                <div class="dp pos-relative ">
                                                                    <img class="img-fluid cursor-point" src={require('../../assets/images/login_user.png')}/>
                                                                </div>
                                                                <div class="msz-box-parent">
                                                                    <h6>radha, Test Company</h6>
                                                                    <div class="msz-box">Hi <br/></div>
                                                                    <p class="date-time mt-2">4:19 PM</p>
                                                                </div>
                                                            </div>
                                                            <p class="date-time mt-2 date-show"><span>Tuesday, January 19th 2021</span></p>
                                                            <div class="other">
                                                                <div class="dp pos-relative ">
                                                                <img className="img-fluid" src={require('../../assets/images/login_user.png')} />
                                                                </div>
                                                                <div class="msz-box-parent">
                                                                    <h6>Rajeev, Test Company</h6>
                                                                    <div class="msz-box">bdgydbh dhuchdgcjdc hduchdhcud ytdycugdychd <br/></div>
                                                                    <p class="date-time mt-2">5:25 PM</p>
                                                                    </div>
                                                            </div>
                                                                <div class="me">
                                                                    <div class="msz-box-parent">
                                                                        <div class="msz-box">hi hihihihihi hi hi hi hihihihihi hi hi hi hihihihihi hi hi hi hihihihihi hi hihi hihihihihi hi hi <br/></div>
                                                                        <p class="date-time mt-2">10:33 PM</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                            </div> */}
                                    </div>
                                    {this.state.selectUser && <div className="bottom fullwidth">
                                        <form>
                                            <div className="search_wrapper  chatbox-textbox mr-2">
                                            <i onClick={this.sendMessage}><LandingUser /></i>

                                                <ContentEditable
                                                    innerRef={this.contentEditableRef}
                                                    //   value = {this.state.message}
                                                    html={this.state.message} // innerHTML of the editable div
                                                    disabled={false}       // use true to disable editing
                                                    onChange={this.handleChange} // handle innerHTML change
                                                    tagName='article' // Use a custom HTML tag (uses a div by default)
                                                    className="chattextbox"
                                                    placeholder="type a message"
                                                    onKeyUp={this.handleKeyUp}
                                                    onKeyDown={this.handleKeyPress}
                                                    onFocus={() => this.scrollToBottom()}
                                                />

                                            </div>
                                        </form>
                                    </div>}

                                </div>
                            </div>
                        </div>
                        <div>

                        </div>
                        <div>

                            {/* <Route path="/profilebody" component={profilebody} />
                                  */}

                        </div>

                    </div>
                </div>
            </>
        );
    }
}


function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(withRouter(SuportAdmin));
