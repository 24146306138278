import React, { Component } from 'react';
import WebHeader from '../../../screens/layout/WebHeader'

import YouTube from 'react-youtube';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import ReactPlayer from "react-player"

import CommingSoon from "../../layout/CommingSoon"
import { Player, BigPlayButton } from 'video-react';


import getYouTubeID from 'get-youtube-id';
import {connect} from "react-redux";
import siteSetting from "../../../config/env/Index";
import ReactGA from "react-ga";

class Live extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoader: true,
      playerUrl: '',
      isStatus: false,
      youId: '',
      image:''
    }
    window.scrollTo(0, 0)
  }



  componentDidMount() {
    console.log("live", this.props)
      this.props.dispatch({ type: 'PATH_ADD', payload:this.props.match })
    if (this.props.match.params.name == "stage") {
      this.getStage()
        if(siteSetting.googleAnalyticsCode&&siteSetting.googleAnalyticsCode!==""){
            //ReactGA.initialize(siteSetting.googleAnalyticsCode);
            // ReactGA.pageview(window.location.pathname + window.location.search);
            ReactGA.initialize(
                [
                    {
                        trackingId: siteSetting.googleAnalyticsCode,
                        gaOptions: {
                            name: 'Stag-'+this.props.match.params.id,
                            userId: localStorage.getItem("id")
                        }
                    }
                ],
                { debug: true, alwaysSendToDefaultTracker: false }
            );
            ReactGA.event({
                category: 'User',
                action: 'Stag-'+this.props.match.params.id,
                value: 1
            });
        }
    }
    else if (this.props.match.params.name == "lounge") {
      this.getLounge()
    }

    else if (this.props.match.params.name == "table") {
      this.getTimeTable()
    }
  }


  getStage = () => {
    _Api(Url.GET_PARTICULAR_STAGE.method, Url.GET_PARTICULAR_STAGE.url, '', `?stageId=${this.props.match.params.id}`)
      .then(resp => {
        console.log('respexList', resp.responseData.result)
        this.setState({ image:resp?.responseData?.result?.image , playerUrl: resp?.responseData?.result?.streamUrl, isStatus: resp?.responseData?.result?.streamUrl ? true : false, isLoader: false })
        var id = getYouTubeID(resp?.responseData?.result?.streamUrl);
        this.setState({ youId: id })

        console.log("jidhshgfjksdbhf", id); // "9bZkp7q19f0"


      }).catch(err => {
        this.setState({ isLoader: false })
      })
  }

  getLounge = () => {
    _Api(Url.VIEW_LOUNGE.method, Url.VIEW_LOUNGE.url, '', `?loungeId=${this.props.match.params.id}`)
      .then(resp => {
        console.log('respexList', resp.responseData.result)
        this.setState({ image:resp?.responseData?.result?.image , playerUrl: resp?.responseData?.result?.streamUrl, isStatus: resp?.responseData?.result?.streamUrl ? true : false, isLoader: false })
        var id = getYouTubeID(resp?.responseData?.result?.streamUrl);
        this.setState({ youId: id })
      }).catch(err => {
        this.setState({ isLoader: false })
      })
  }

  getTimeTable = () => {
    _Api(Url.GET_PARTICULAR_TIME_TABLE.method, Url.GET_PARTICULAR_TIME_TABLE.url, '', `?timeTableId=${this.props.match.params.id}`)
      .then(resp => {
        console.log('respexList', resp.responseData.result)
        this.setState({ image:resp?.responseData?.result?.image , playerUrl: resp?.responseData?.result?.videoUrl, isStatus: resp?.responseData?.result?.videoUrl ? true : false, isLoader: false })
        var id = getYouTubeID(resp?.responseData?.result?.videoUrl);
        this.setState({ youId: id })
      }).catch(err => {
        this.setState({ isLoader: false })
      })
  }

  render() {
    const opts = {
      height: '400',
      width: '640',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
      },
    };
    console.log("sdhghfjk", this.state.playerUrl)
    return (
      <>
          {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
          
              {/*<ChatBox history={this.props.history} match={this.props.match} />*/}
        <div className="container-fluid video-parent">
          <div className="row no-gutters">
            <div className="col-md-12 ">
              {
                !this.state.isLoader && this.state.isStatus
                  ?
                  <div className="single video-of-placeholder live-video">
                      <YouTube videoId={this.state.youId} opts={opts} onReady={this._onReady} className="video-player" containerClassName="video-player-parent" />

                      {/* <Player>
                                                <source src={this.state.playerUrl} />
                                                <BigPlayButton position="center" />
                                            </Player> */}
                    {/* <ReactPlayer width="442" height="390" url={this.state.playerUrl} /> */}
                  </div>
                  :
                  this.state.isLoader ? <h1>Loading</h1> :
                  //  <CommingSoon
                  //  hideHeader = {true}
                  //  />
                   <div className="single video-of-placeholder live-video live-img-parent">
                      <img src={this.state.image ? this.state.image :   require('../../../assets/images/placeholder.png')} />
                     
                      <div className="live-img-text"> 
                        <div className="text">
                        <div className="text-center">
                          
                          <h2>Nothing's happening on the stage</h2>
                          <p>We'll notify you about a new schedule segment.</p>
                          </div>
                      
                        </div>
                      </div>
                   </div>
              }
            </div>
          </div>
        </div>
      </>
    )
  }
}



function mapStateToProps(state) {
    return {
        ...state,
    };
}

export default connect(mapStateToProps)(Live);