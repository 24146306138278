import React, { Component } from 'react';
import { CmBtn } from "../../../components/common/Button";
import { TwoUsers, NoMediaICon } from "../../../SvgIcon"
import Footer from "../../layout/Footer";
import moment from "moment-timezone";
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';

import { notify } from '../../../components/Toaster';

import { Spin , Tooltip} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import LazyLoad from 'react-lazy-load';
import {connect} from "react-redux";
import socket from "../../../services/socket";
import { getTimeZone } from "../../../utils/DateZone"

const antIcon = <LoadingOutlined style={{ fontSize: 72 }} spin />;
var _ = require('lodash');
class Networking extends Component {

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.carousel = React.createRef();

        this.state = {
            isCommingSoon: false,
            isLoading: true,
            networkLoungList: []
        }
        window.scrollTo(0, 0)

    }

    componentDidMount() {
        this.getHomeDetails()
        this.props.dispatch({ type: 'PATH_ADD', payload:this.props.match })
        let self=this;
        socket.on('networkLoungeEnter', function (param) {
            console.log("networkLoungeEnter",param)
            let networkLoungList=self.state.networkLoungList;
            let lounge = _.find(networkLoungList, {_id: param.loungeId});
            if(lounge) {
                console.log("lounge", lounge)
                lounge.onlineJoinUser=param.userCount;
                let index = _.findIndex(networkLoungList, {_id: param.loungeId});
                //networkLoungList.splice(index, 1, lounge);
                networkLoungList[index]=lounge;
                console.log("######",networkLoungList)
                self.setState({
                    networkLoungList:networkLoungList
                })
            }
        })

        socket.on('networkLoungeExist', function (param) {
            console.log("networkLoungeExist", param)
            let networkLoungList = self.state.networkLoungList;
            let lounge = _.find(networkLoungList, {_id: param.loungeId});
            if (lounge) {
                console.log("lounge", lounge)
                if(param.userCount>=0){
                    lounge.onlineJoinUser=param.userCount;
                }
                let index = _.findIndex(networkLoungList, {_id: param.loungeId});
                //networkLoungList.splice(index, 1, lounge);
                networkLoungList[index] = lounge;
                self.setState({
                    networkLoungList: networkLoungList
                })
            }
        })
    }

    componentWillMount(){
        socket.off("networkLoungeEnter");
        socket.off("networkLoungeExist");
    }

    handleError(err) {
        let errorMessage = err.error && err.error.responseMessage || err.message;
        notify("err", errorMessage);
    }

    getHomeDetails = () => {

        if(this.props.loading?.exhibitionId){
            _Api(Url.GET_NETWORKING_LIST.method, Url.GET_NETWORKING_LIST.url, '', `?exhibitionId=${this.props.loading?.exhibitionId}`)
            .then(resp => {
                console.log('jdkasjldjhsabdklsajh', resp.responseData)
                this.setState({ isLoading: false, networkLoungList: resp.responseData.result.data })

            }).catch(() => {
                this.setState({ isLoading: false })
            })

        }
        else{
            _Api(Url.GET_EXHIBITION_ID.method, Url.GET_EXHIBITION_ID.url, '')
                .then(resp => {
                    _Api(Url.GET_NETWORKING_LIST.method, Url.GET_NETWORKING_LIST.url, '', `?exhibitionId=${resp?.responseData?.exhibitionDetails?._id}`)
                    .then(resp => {
                        console.log('jdkasjldjhsabdklsajh', resp.responseData)
                        this.setState({ isLoading: false, networkLoungList: resp.responseData.result.data })
        
                    }).catch(() => {
                        this.setState({ isLoading: false })
                    })
                }).catch((err) => { })
        }     
    }

    next = () => {
        this.carousel.next();
    }
    previous = () => {
        this.carousel.prev();
    }

    handleJoin = (id, item)  => {
        console.log("test123",navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1)
        if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {            
            this.handleJoin1(id, item)
            
        }
        else{ 
            this.handleJoin2(id, item)
        }    

    }

    handleJoin2 = (id, item) => {
        const { numberofAttendees, onlineJoinUser } = item
        let req = {
            otherUserId: localStorage.getItem('id'),
            type: 4,
            networkLoungeId: item._id,
            exhibitionId: localStorage.getItem('exhibition')
        }
        _Api(Url.CHECK_PLAN_USER.method, Url.CHECK_PLAN_USER.url, req)
            .then(resp => {
                const { isBuy } = resp?.responseData
                if (isBuy == 0) {
                    this.props.dispatch({ type: 'SHOW_PLAN_USER_POPUP', payload: this.props.match })
                }
                else {
                    if (onlineJoinUser < numberofAttendees) {
                        window.open('/network-lounge/' + id, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                        let req = { networkLoungeId: item._id }

                        _Api(Url.JOIN_LOUNG_PLAN.method, Url.JOIN_LOUNG_PLAN.url, req)
                            .then(resp => {
                            }).catch(err => { })
                    }
                    else
                        notify("err", "This lounges is full , you can not join at this time")
                }

            }).catch(err => {

            })

    }






    handleJoin1 = (id, item) => {
        const { numberofAttendees, onlineJoinUser } = item
        var windowReference = window.open();
        let req = {
            otherUserId: localStorage.getItem('id'),
            type: 4,
            networkLoungeId: item._id,
            exhibitionId: localStorage.getItem('exhibition')
        }
        _Api(Url.CHECK_PLAN_USER.method, Url.CHECK_PLAN_USER.url, req)
            .then(resp => {
                const { isBuy } = resp?.responseData
                if (isBuy == 0) {
                    this.props.dispatch({ type: 'SHOW_PLAN_USER_POPUP', payload: this.props.match })
                }
                else {
                    if (onlineJoinUser < numberofAttendees) {
                      var newWin =   window.open('/network-lounge/' + id, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                        if (!newWin || newWin.closed || typeof newWin.closed == 'undefined')
                        windowReference.location = '/network-lounge/' + id
                        let req = { networkLoungeId: item._id }

                        _Api(Url.JOIN_LOUNG_PLAN.method, Url.JOIN_LOUNG_PLAN.url, req)
                            .then(resp => {
                            }).catch(err => { })
                    }
                    else
                        notify("err", "This lounges is full , you can not join at this time")
                }

            }).catch(err => {

            })

    }


    // handleJoin = (id, item) => {
    //     console.log("bsjdksamls", item)
    //     const { numberofAttendees, onlineJoinUser } = item
    //     if (onlineJoinUser < numberofAttendees)
    //         window.open('/network-lounge/' + id, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
    //     else
    //         notify("err", "This lounges is full , you can not join at this time")

    // }

    render() {
        const { isLoading, networkLoungList } = this.state

        if (isLoading)
            return (
                <section className="brand-home-banner-bg webhome">
                    {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
                    <div className="centerLoader" >
                        <Spin size="large" tip="Loading..." indicator={antIcon} />
                    </div>
                </section>


            )

        else
            return (
                <section className="brand-home-banner-bg">                   
                        {/*<ChatBox history={this.props.history} match={this.props.match} />*/}
                    <div className={"cm-section container-fluid"}>


                        <div className="cm-wrapper home-networking">
                            <div className="header-text">
                                <h1 className="title">Networking Lounges</h1>
                                <a > <button onClick={() => this.props.history.goBack()} className="btn btn-light"><i class="fa fa-arrow-left" aria-hidden="true"></i>Back</button></a>
                            </div>
                            <div className="row">
                                {
                                    networkLoungList && networkLoungList.map((item) => {
                                        return (

                                            <div className="col-md-3 mb-5 conference-lounges cursor-point"
                                                onClick={() => this.handleJoin(item._id, item)}
                                            >
                                                <div className="card-img-wrapper">
                                                    <div class="offer-badge">

                                                        {item?.onlineJoinUser != 0 && item?.onlineJoinUser && <div class="offer-badge-text ">
                                                            <span><TwoUsers /></span> {item?.onlineJoinUser} People
                                                    </div>
                                                        }

                                                    </div>
                                                    <div className="card">
                                                        <LazyLoad>
                                                            <img className="img-fluid" src={item.image ? item.image : require("../../../assets/images/placeholder.png")} />
                                                        </LazyLoad>
                                                    </div>
                                                </div>
                                                <div className="cm-card-body">
                                                <Tooltip placement="leftBottom" title={item.loungeName} >
                                                    <h5 class="h5"><strong>{item.loungeName}</strong></h5>
                                                </Tooltip>
                                                    <p className="des"> {moment(item.startDate).format("DD/ MM/ YYYY")} <br />

                                                    {getTimeZone(item?.startTime, moment.tz.guess())}</p>

                                                    <>
                                                        <hr />
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-grow-1">
                                                                <CmBtn onClick={() => this.handleJoin(item._id, item)} className="btn-blue" bg='var(--btncolor)' text="Join" />
                                                            </div>
                                                        </div>
                                                    </>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    !this.state.isLoading && networkLoungList.length == 0 &&
                                    <div className="w-100 text-center row nodata m-auto">
                                        <div className="m-auto">
                                            <NoMediaICon />
                                            
                                            <h2>No Data Found</h2>
                                        </div>
                                    </div>
                                }
                            </div>

                        </div>

                        
                    </div >
                    <Footer />
                </section >
            )
    }

}
// export default Networking;
function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(Networking);