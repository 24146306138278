import React, { Component } from 'react';
import { useHistory } from "react-router-dom";
import { PromoCodeIcon } from '../../../SvgIcon';
import util from '../../../utils/Util.js';
import { Link } from "react-router-dom";
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { DatePicker, TimePicker, Radio } from 'antd';
import moment from 'moment-timezone';
import InlineError from '../../../components/InlineError';
import { Select, Checkbox } from 'antd';
import Loader from '../../../components/Loader';
import { notify } from '../../../components/Toaster';
const { Option } = Select;


const disabledDate = (current) => {
    // Can not select days before today and today
    // return current && moment(current.valueOf()).format('MM/DD/YYYY') < moment().format('MM/DD/YYYY');;
    return current && moment(current.valueOf()) < moment();
}

// const disabledDateEnd = (current) => {
//     // Can not select days before today and today
//     return current && current.valueOf() < startDate;
// }



const formField = ["validFor", "conferenceType", "exhibition", "promocode", "discountType", "startDate", "endDate", "endTime", "maxDiscountAmount", "maximumUser", "startTime", "discountValue"]
const format = 'HH:mm';

//Add New Exhibition screen
class AddPromoCode extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isConferenceSelected: false,
            exhibitionData: [],
            conferenceData: [],
            hasError: false,
            planList:[]


        }
    }

    componentDidMount() {
        this.getexhibitionList();
        this.getList()
    }
    handleError(err) {
        this.setState({ isLoader: false });
        let errorMessage = err.error && err.error.responseMessage || err.message;
        notify("err", errorMessage);
    }
    getexhibitionList = () => {
        _Api(Url.PROMO_EXHIBITION_DATA.method, Url.PROMO_EXHIBITION_DATA.url)
            .then(resp => {
                let responseData = resp.responseData.exhibitionDetails;
                this.setState({ exhibitionData: responseData });

            }).catch(err => {
                this.handleError(err);
            });
    }
    getConferenceList = (id) => {
        _Api(Url.PROMO_CONFERENCE_DATA.method, Url.PROMO_CONFERENCE_DATA.url, '', `?exhibitionId=${id}`)
            .then(resp => {

                let responseData = resp.responseData.result;
                this.setState({ conferenceData: responseData });

            }).catch(err => {
                this.handleError(err);
            });
    }
    onSelectConference = (value) => {
        this.setState({ conferenceType: value, conferenceTypeError: "" });
    }
    handleOnChangeValue = (e) => {
        if ((e.target.name == "discountValue" || e.target.name == "maxDiscountAmount") && this.state.discountType == 1) {
            let fieldName = e.target.name == "discountValue" && "maxDiscountAmount" || "discountValue", errorFieldName = `${fieldName}Error`
            this.setState({ [fieldName]: e.target.value, [errorFieldName]: "" });

        }
        let stateObject = e.target.name + "Error";
        this.setState({ [e.target.name]: e.target.value, [stateObject]: "" });
    }
    handleOnChange = (type, e) => {
        let errorState = type + "Error";
        this.setState({ [type]: e, [errorState]: '' });
    }

    selectOnChange = (type, e) => {
        if (type == "exhibition") {
            this.getConferenceList(e);

        }
        if (type == "discountType" && (e == 1 && this.state.discountValue && this.state.maxDiscountAmount && this.state.discountValue != this.state.maxDiscountAmount)) {
            this.setState({ discountValueError: 'Discount value and maximum value should be same for fixed value' })


        }
        let stateObject = type + 'Error';
        this.setState({
            [type]: e, [stateObject]: ''
        })
    }

    promoSubmit = () => {
        let isError = false;
        formField.forEach((item) => {
            let checkValue = ["conferenceType", "discountType", "exhibition", "validFor"];
            if (!this.state[item]) {
                if (checkValue.includes(item) && this.state[item] >= 0) { }
                else {
                    let stateObject = item + 'Error', fieldValue = item == "promocode" && "Activation Code" || item.replace(/([A-Z])/g, ' $1').trim();
                    isError = item == "conferenceType" ? this.state.validFor == 2 ? true : false : true;
                    this.setState({ [stateObject]: stateObject === "conferenceTypeError" && this.state.validFor != 2 ? "" : `${(fieldValue.toLowerCase()).charAt(0).toUpperCase() + fieldValue.slice(1)} can not be empty` || 'Please Enter Valid Value' });


                }
            }
            if (this.state["discountType"] == 1) {
                if (this.state.discountValue != this.state.maxDiscountAmount) { isError = true; this.setState({ discountValueError: "Discount Value should be equal to maximum discout value" }) }

            }

        });


        if (!isError) {
            let { promocode, discountType, startTime, startDate, maximumUser, endDate, endTime, exhibition, conferenceType, maxDiscountAmount, description, validFor, discountValue } = this.state;
            if (startDate && startDate._d > endDate && endDate._d) {
                this.setState({ startDateError: "Start Date should be less than or equal to End Date" });
                return false;
            }

            else {
                let param = {
                    title: promocode,
                    discountType: discountType,
                    startDate: moment(startDate).format("YYYY") + moment(startDate).format("MM") + moment(startDate).format("DD"),
                    endDate: moment(endDate).format("YYYY") + moment(endDate).format("MM") + moment(endDate).format("DD"),
                    startTime: moment(startTime).format("HHmmss"),
                    endTime: moment(endTime).format("HHmmss"),
                    numberofUser: maximumUser,
                    exhibitionId: exhibition,
                    conferenceId: conferenceType,
                    maximumDiscount: maxDiscountAmount,
                    description: description,
                    validFor: validFor,
                    discountValue: discountValue
                };


                _Api(Url.Add_PROMO.method, Url.Add_PROMO.url, param)
                    .then(resp => {
                        notify('success', resp.responseData.message);
                        this.props.history.goBack();

                    }).catch(err => {
                        this.handleError(err);
                    })

            }
        }

    }
    onValidForSelect = e => {
        this.setState({
            validFor: e.target.value, validForError: '', isConferenceSelected: e.target.value === 2
        });

    };






    getList = (isShowLoader = true) => {
        this.setState({ isLoader: isShowLoader })
        let { page, limit, search } = this.state
        let req = {
            page:  1,
            limit: 20,
            search: ''
        }
        _Api(Url.PLAN_LIST.method, Url.PLAN_LIST.url, '', `?page=${req.page}&limit=${req.limit}&search=${req.search}`)
            .then(resp => {
                console.log('planList', resp.responseData.result)
                this.setState({ planList: resp.responseData.result.data })

            }).catch(err => {
                this.setState({ isLoader: false })


            })
    }


    render() {
        const CheckboxGroup = Checkbox.Group;
        const plainOptions = [{ displayValue: 'More Searches', value: 1 }, { displayValue: 'Seminar', value: 2 }];
        let { startDate, endDate, conferenceData, exhibitionError, conferenceTypeError, exhibitionData, validForError, maximumUser, description, maxDiscountAmount, discountValue, discountType, promocode, startDateError, promocodeError, maxDiscountAmountError, maximumUserError, discountTypeError, discountValueError, startTimeError, endDateError, endTimeError } = this.state;
       let type =  [4,0, 3]

        return (

            <>
                <div className="dash_body_container">
                    <Header />
                    <Sidebar />
                    <div className="body_container">
                        <div className="header-text  mb-4 mt-3">
                            <div className="d-flex align-items-center">
                                <p className="header-icon"><PromoCodeIcon /></p>
                                <h4>Add Activation Code </h4>
                            </div>
                        </div>
                        <div className="rounded-body-form">
                            <form className="cm-form-wrapper add-promo-code">
                                <div className="form-group">
                                    <level>Activation Code Title</level>
                                    <input type="text"
                                        className="form-control"
                                        placeholder="Enter Code"
                                        maxLength={64}
                                        name="promocode"
                                        value={promocode}
                                        onChange={this.handleOnChangeValue}
                                    />
                                    <InlineError message={promocodeError} />
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <level>Valid from date and time</level>

                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <DatePicker
                                                        // disabledDate={disabledDate}
                                                        value={startDate}
                                                        onChange={(e) => this.handleOnChange("startDate", e)}
                                                        name="startDate"
                                                        format={"DD-MM-YYYY"}


                                                    />
                                                    <InlineError
                                                        message={startDateError}
                                                    />

                                                </div>
                                            </div>


                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <TimePicker use12Hours format="h:mm a"
                                                        // value={startTime}
                                                        name='startTime'
                                                        onChange={(e) => this.handleOnChange("startTime", e)} />
                                                    <InlineError
                                                        message={startTimeError}
                                                    />

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-6">

                                        <level>Valid to date and time</level>
                                        <div className="form-row">
                                            <div className="col-md-6">

                                                <div className="form-group">
                                                    <DatePicker
                                                        // disabledDate={disabledDate}
                                                        value={endDate}
                                                        onChange={(e) => this.handleOnChange("endDate", e)}
                                                        name="endDate"
                                                        format={"DD-MM-YYYY"}
                                                    />
                                                    <InlineError
                                                        message={endDateError}
                                                    />

                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <TimePicker use12Hours format="h:mm a"
                                                        onChange={(e) => this.handleOnChange("endTime", e)} />
                                                    <InlineError
                                                        message={endTimeError}
                                                    />


                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-6 ">

                                        <div className="form-group">
                                            <level>Discount type</level>
                                            <Select
                                                name='discountType'
                                                placeholder="Select"
                                                value={discountType}
                                                onChange={(e) => this.selectOnChange("discountType", e)}
                                            >
                                                {util.discountTypeOptions.map((item) => {
                                                    return (<Option value={item.value}>{item.displayValue}</Option>)
                                                })}
                                            </Select>
                                            <InlineError message={discountTypeError} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3">
                                        <div className="form-group">
                                            <level> Discount Value</level>
                                            <input type="number"
                                                className="form-control"
                                                placeholder="Discount Value"
                                                maxLength={64}
                                                name="discountValue"
                                                value={discountValue}
                                                onChange={this.handleOnChangeValue}
                                            />
                                            <InlineError message={discountValueError} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3">
                                        <div className="form-group">
                                            <level>Max Discount Amount</level>
                                            <input type="number"
                                                className="form-control"
                                                placeholder="Max Discount Amount"
                                                maxLength={64}
                                                name="maxDiscountAmount"
                                                value={maxDiscountAmount}
                                                onChange={this.handleOnChangeValue}
                                            />
                                            <InlineError message={maxDiscountAmountError} />
                                        </div>
                                    </div>


                                </div>

                                <div className="row">
                                    <div className="col-lg-6">

                                        <div className="form-group">
                                            <level>Description</level>
                                            <textarea type="text"
                                                className="form-control textarea"
                                                placeholder="Description"
                                                rows="4"
                                                name="description"
                                                value={description}
                                                onChange={this.handleOnChangeValue}

                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group d-grid">
                                            <level>Valid For</level>
                                            <br />
                                            <Radio.Group name="validFor" onChange={this.onValidForSelect} value={this.state.validFor} >
                                                {this.state.planList.map((item, index)=> {
                                                    return (<><Radio className="radio-button-Style" value={type[index]}> {item.planName}</Radio> <br /></>)
                                                })
                                            }  
                                               {plainOptions.map((item, index)=> {
                                                    return (<><Radio className="radio-button-Style" value={item.value}> {item.displayValue}</Radio> <br /></>)
                                                })
                                            }  
                                                                                         
                                            </Radio.Group>
                                            <InlineError message={validForError} />
                                        </div>
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-lg-6">

                                        <div className="form-group">
                                            <level>Maximum Users</level>
                                            <input type="number"
                                                className="form-control"
                                                placeholder="Maximum Users"
                                                maxLength={64}
                                                name="maximumUser"
                                                value={maximumUser}
                                                onChange={this.handleOnChangeValue}
                                            />
                                            <InlineError message={maximumUserError} />
                                        </div>
                                    </div>
                                    < div className="col-lg-6">
                                        <div className="form-group">
                                            <level>Exhibition Name</level>
                                            <Select
                                                name='exhibition'
                                                placeholder="Select"
                                                onChange={(e) => this.selectOnChange("exhibition", e)}
                                            >
                                                {exhibitionData &&
                                                    exhibitionData.map((item) => {
                                                        return (<Option value={item._id}>{item.exhibitionName}</Option>)
                                                    })

                                                }
                                            </Select>
                                            <InlineError message={exhibitionError} />
                                        </div>
                                    </div>
                                    {this.state.isConferenceSelected && < div className="col-lg-6">
                                        <div className="form-group">
                                            <level>Seminar Name</level>
                                            <Select
                                                name='conferenceType'
                                                placeholder="Select"
                                                onChange={(e) => this.onSelectConference(e)}

                                            >
                                                {conferenceData &&
                                                    conferenceData.map((item) => {
                                                        return (<Option key={item._id}>{item.conferenceName}</Option>)
                                                    })

                                                }
                                            </Select>
                                            <InlineError message={conferenceTypeError} />
                                        </div>
                                    </div>}
                                </div>

                                <div className="d-flex justify-content-between">
                                    <Link to="/promo-code" className="btn btn-secondary" type='button'>Back</Link>
                                    <button className="btn btn-primary " type='button' onClick={() => this.promoSubmit()} >Submit</button>
                                </div>
                            </form>

                        </div>

                    </div>
                </div>
            </>
        );
    }
}
export default AddPromoCode;
