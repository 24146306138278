/**
 * @About
 * This file mange environment of the project
 */

//  // const AdminId = '5ee63999e41e842a24bbd396'
// const AdminId = '5efa2db5d6d8f60f5bcf7aa2'



export const stagHjSetting = {
    api: {
        url: "https://stagapihj.professionalbeauty.world/pbWorld/api/v1/"
    },
    socketUrl:"https://staghjsocket.professionalbeauty.world",
    googleAnalyticsCode:"G-39WBP7MD26",
    AdminId :'5ee63999e41e842a24bbd396',
    env:'stag'
}


export const devHjSetting = {
    api: {
        url: "https://devapihj.professionalbeauty.world/pbWorld/api/v1/"
    },
    socketUrl:"https://devhjsocket.professionalbeauty.world",
    googleAnalyticsCode:"G-39WBP7MD26",
    AdminId :'5ee63999e41e842a24bbd396',
    env:'broadCastNow_jobfinder_prod_dev'
}

export const devSetting = {
    api: {
        url: "https://devapi.professionalbeautyworldwide.com/pbWorld/api/v1/"
    },
    socketUrl:"https://devsocket.professionalbeautyworldwide.com",
    googleAnalyticsCode:"G-39WBP7MD26",
    AdminId :'5ee63999e41e842a24bbd396',
    env:'broadCastNow_jobfinder_prod_dev'
}

export const betaSetting = {
    api: {
        url: "https://betaapi.professionalbeauty.world/pbWorld/api/v1/"
    },
    socketUrl:"https://betasocket.professionalbeauty.world/",
    AdminId :'5ee63999e41e842a24bbd396',
    stripe_key:"pk_live_51H2GwRFiXx7agYP2eSZcvLT5LCLEj6w7R6qJpDJuCfTmFybQVAG6dv2a9zq7ikC9BkwGCjOo8q6EXvk4mZt7Z55k00RI0TnBlh",
    env:'broadCastNow_jobfinder_prod_jobfinder_prod'
}

export const stagSetting = {
    api: {
        url: "https://stagapi.professionalbeautyworldwide.com/pbWorld/api/v1/"
    },
    socketUrl:"https://stagsocket.professionalbeautyworldwide.com",
    AdminId :'5ee63999e41e842a24bbd396',
    env:'stag'
}

export const prodSetting = {
    api: {
        url: "https://api.professionalbeauty.world/pbWorld/api/v1/"
    },
    stripe_key:"pk_live_51H2GwRFiXx7agYP2eSZcvLT5LCLEj6w7R6qJpDJuCfTmFybQVAG6dv2a9zq7ikC9BkwGCjOo8q6EXvk4mZt7Z55k00RI0TnBlh",
    socketUrl:"https://socket.professionalbeauty.world",
    googleAnalyticsCode:"UA-175588236-1",
    hotjarId:"1967304",
    AdminId :'5efa2db5d6d8f60f5bcf7aa2',
    env:'broadCastNow_jobfinder_prod_jobfinder_prod'
}

export const prod1Setting = {
    api: {
        url: "https://api.professionalbeauty.world/pbWorld/api/v1/"
    },
    stripe_key:"pk_live_51H2GwRFiXx7agYP2eSZcvLT5LCLEj6w7R6qJpDJuCfTmFybQVAG6dv2a9zq7ikC9BkwGCjOo8q6EXvk4mZt7Z55k00RI0TnBlh",
    socketUrl:"https://socket.professionalbeauty.world",
    googleAnalyticsCode:"UA-175588236-1",
    hotjarId:"1967304",
    AdminId :'5efa2db5d6d8f60f5bcf7aa2',
    env:'broadCastNow_jobfinder_prod_jobfinder_prod'
}

export const amStageSetting = {
    api: {
        url: "https://stagapi.aestheticmedicine.online/pbWorld/api/v1/"
    },
    socketUrl:"https://stagsocket.aestheticmedicine.online/",
    googleAnalyticsCode:"G-39WBP7MD26",
    newWindowTitle:"aestheticmedicine",
    AdminId :'5efa2db5d6d8f60f5bcf7aa2',
    env:'stag'
}

export const amProdSetting = {
    api: {
        url: "https://api.aestheticmedicine.online/pbWorld/api/v1/"
    },
    socketUrl:"https://socket.aestheticmedicine.online/",
    stripe_key:"pk_live_51H2GwRFiXx7agYP2eSZcvLT5LCLEj6w7R6qJpDJuCfTmFybQVAG6dv2a9zq7ikC9BkwGCjOo8q6EXvk4mZt7Z55k00RI0TnBlh",
    googleAnalyticsCode:"G-9CWK203W7P",
    hotjarId:"1967304",
    newWindowTitle:"aestheticmedicine",
    AdminId :'5efa2db5d6d8f60f5bcf7aa2',
    env:'broadCastNow_jobfinder_prod_jobfinder_prod'
}

export const localNetworkSetting = {
    api: { 
        url: "http://192.168.21.122:5001/pbWorld/api/v1/"
    },
    socketUrl:"http://192.168.21.122:4001",
    AdminId :'5ee63999e41e842a24bbd396',
}

export const localhostSetting = {
    api: {
        url: "http://localhost:5001/pbWorld/api/v1/"
    },
    socketUrl:"http://localhost:4001",
    googleAnalyticsCode:"",
    AdminId :'5ee63999e41e842a24bbd396',
}

export const devAmMay2021Setting = {
    api: {
        url: "https://devapi.aestheticmedicine.online/pbWorld/api/v1/"
    },
    socketUrl:"https://devsocket.aestheticmedicine.online/",
    googleAnalyticsCode:"G-39WBP7MD26",
    newWindowTitle:"aestheticmedicine",
    AdminId :'5efa2db5d6d8f60f5bcf7aa2',
}

export const prodAmMay2021Setting = {
    api: {
        url: "https://api.aestheticmedicine.online/pbWorld/api/v1/"
    },
    socketUrl:"https://socket.aestheticmedicine.online/",
    stripe_key:"pk_live_51H2GwRFiXx7agYP2eSZcvLT5LCLEj6w7R6qJpDJuCfTmFybQVAG6dv2a9zq7ikC9BkwGCjOo8q6EXvk4mZt7Z55k00RI0TnBlh",
    googleAnalyticsCode:"G-9CWK203W7P",
    hotjarId:"1967304",
    newWindowTitle:"aestheticmedicine",
    AdminId :'5efa2db5d6d8f60f5bcf7aa2',
    env:'broadCastNow_jobfinder_prod_jobfinder_prod'
}

export const devJobJuly2021Setting = {
    api: {
        url: "https://devapi.worldwidejobfinder.com/pbWorld/api/v1/"
    },
    socketUrl:"https://devsocket.worldwidejobfinder.com/",
    googleAnalyticsCode:"",
    newWindowTitle:"worldwidejobfinder",
    AdminId :'5efa2db5d6d8f60f5bcf7aa2',
    env:'broadCastNow_jobfinder_prod_dev'
}

export const prodJobJuly2021Setting = {
    api: {
        url: "https://api.worldwidejobfinder.com/pbWorld/api/v1/"
    },
    socketUrl:"https://socket.worldwidejobfinder.com/",
    stripe_key:"pk_live_51H2GwRFiXx7agYP2eSZcvLT5LCLEj6w7R6qJpDJuCfTmFybQVAG6dv2a9zq7ikC9BkwGCjOo8q6EXvk4mZt7Z55k00RI0TnBlh",
    googleAnalyticsCode:"",
    hotjarId:"2430642",
    newWindowTitle:"worldwidejobfinder",
    AdminId :'5efa2db5d6d8f60f5bcf7aa2',
    env:'broadCastNow_jobfinder_prod_jobfinder_prod'
}
