import React, { Component } from 'react';

import WebHeader from '../../layout/WebHeader';
import Footer from "../../layout/Footer"
import { Pagination } from "../../../components";
import FilterHeader from "../../layout/FilterHeader";
import CommingSoonLayer from "../../../components/common/CommingSoonLayer";
import { Brands, DeliverTraingle } from '../../../SvgIcon';
import { Link } from "react-router-dom";
import { Empty } from 'antd';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';

import { connect } from "react-redux";
import { NoMediaICon } from "../../../SvgIcon";
import { notify } from '../../../components/Toaster';
import LazyLoad from 'react-lazy-load'


import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
let isProd = process.env.REACT_APP_ENV == 'prod'
const antIcon = <LoadingOutlined style={{ fontSize: 72 }} spin />;


class ViewIndustry extends Component {

    constructor(props) {
        super(props);
        this.state = {
            exhibtionId: '',
            page: 1,
            limit: 10,
            loading: false,
            isLoader: false,
            search: '',
            snNo: 0,
            sortInfo: {},
            industryData: [],
            totalCount: 1

        }
        window.scrollTo(0,0);
    }

    componentDidMount() {
        this.props.dispatch({ type: 'PATH_ADD', payload:this.props.match })
        this.getCategory();
    }
    getCategory = (filter) => {
        let { appLocalState } = this.props;
        let { page, limit, search } = this.state;
        let params = {
            categoryType: "4",
            page: page ? page : 1,
            limit: limit,
            searchKey: appLocalState.search || search.toString()
        }
        if (params) {
            Object.assign(params, filter)

        }
        _Api(Url.GET_CATEGORY_LIST.method, Url.GET_CATEGORY_LIST.url, params)
            .then(resp => {
                this.setState({ industryData: resp.responseData?.result })

            }).catch(() => {

            })
    }
    onPressBtn = (page) => {
        this.setState({ page, snNo: page - 1, brandListData: [] }, () => {
            this.getCategory()
        })

    }
    handleSearch = e => this.setState({ search: e.target.value, brandListData: [], page: 1, snNo: 0 }, () => this.getCategory());

    onIndustryClick = id => {
        let { appLocalState, dispatch, } = this.props;
        if (appLocalState.search) {
            _Api(Url.SEARCH_COUNT.method, Url.SEARCH_COUNT.url)
                .then(resp => {


                }).catch(err => {
                    // this.handleError(err);
                });
        }
        this.props.history.push(`/product/${id}`);

    }

    handleError(err) {
        let errorMessage = err.error && err.error.responseMessage || err.message;
        notify("err", errorMessage);
    }


    render() {
        let { industryData } = this.state;

        // if (isProd)
        // return (
        //     <section className="brand-home-banner-bg webhome">
        //          {//<WebHeader history={this.props.history} match={this.props.match} />}               
        //         <div className="centerLoader" >
        //             {
        //                 isProd ? 
        //                 <h1><b>This page will be live on the 8th September, 2020. </b></h1>
        //                 :
        //                 <Spin size="large" tip="Loading..." indicator={antIcon} />

        //             }                      

        //         </div>
        //     </section>


        // )
        // else

        return (

            <section className="brand-home-banner-bg">

                {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
                {/*<ChatBox history={this.props.history} match={this.props.match} />*/}
               {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
               {/*     <ChatBox history={this.props.history} match={this.props.match} />*/}
                <FilterHeader scope={this} setSelected={"Sectors"} ishideHeader={true} />
                <div className="container-fluid cm-section featured-brands industries-serve">
                    <div className="cm-wrapper">
                        <div className="header-container">

                            <div className="header-text">
                                <h1 className="title">Sectors</h1>
                            </div>

                            {/* <div className="search_wrapper">
                                <i className="fa fa-search" />
                                <input type="search"
                                    placeholder="Search by Industry"
                                    name='search'
                                    onChange={this.handleSearch}
                                />
                            </div> */}

                        </div>



                        <div className="row d-flex flex-wrap five justify-content-center align-item-center mb-3">
                            {
                                industryData.map(item => (
                                    <div className="card community-card" onClick={() => this.onIndustryClick(item?._id)}>
                                        <div className="mb-5 mt-0 pos-relative">
                                            <LazyLoad>

                                                <img className="img-fluid" src={item?.imageUrl}
                                                />
                                            </LazyLoad>

                                            {/* <body className="bottom-left skew-text ">
                                                <div className="d-flex skew-text justify-content-center">
                                                    <h5 className="d-inline">{item?.categoryTitle}</h5>
                                                    <i className="fa fa-long-arrow-right  bg-transparent" />
                                                </div>
                                            </body> */}
                                            <div className="bottom-text">

                                                <div className="postion-rel d-flex align-items-center traingletext">
                                                    <span className="title d-flex justify-content-between align-items-center w-100">
                                                        {item?.categoryTitle}<i class="fa fa-long-arrow-right float-right" aria-hidden="true"></i></span>
                                                    <span className="traingle"><DeliverTraingle /></span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                ))
                            }
                        </div>
                        {industryData?.length == 0 && <div className="w-100 text-center row nodata m-auto">
                            <div className="m-auto">
                                <NoMediaICon />
                                
                                <h2>No Industry Found</h2>
                            </div>                    </div>
                        }
                        {
                            industryData.length > 0 &&
                            <div >
                                <Pagination
                                    totalPage={Math.ceil(this.state.totalCount / 10)}
                                    currentPage={this.state.page}
                                    onPressBtn={this.onPressBtn}
                                />
                            </div>
                        }
                    </div>
                </div>


                <Footer />
            </section>




        )
    }
}

function mapStateToProps(state) {
    return {
        appLocalState: state.appLocalState
    };
}

export default connect(mapStateToProps)(ViewIndustry);


