import React, { Component } from 'react';
import { DatePicker, TimePicker, InputNumber, Select } from 'antd';
import moment from 'moment-timezone';
import { BellIcn, Hert, CaretDown, ListIcn, UsersIcn, VideoCall } from '../../../components/common/SvgIcon';
import { Calendar, Clock } from '../../../SvgIcon';
import { NotificationIcon } from '../../../SvgIcon';
// import TimezoneSelect from 'react-timezone-select'
import { TimezoneSelect, clientTz } from 'timezone-select-js';
import { connect } from "react-redux";
// import TimezonePicker from 'react-timezone-picker';
import InlineError from "../../../components/InlineError"

import TimeZone from "../../../utils/timeZone"

import { notify } from "../../../components/Toaster"

import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
const format = 'HH:mm';
const { Option } = Select;


class DiaryMeetingModal extends Component {
    constructor() {
        super()
        this.state = {
            meetingModal: false,
            format: 'HH:mm',
            senderId: '',
            meetingType: 0,
            name: '',
            nameError: '',
            diaryType: 0,
            startDate: '',
            startDateError: '',
            startTime: '',
            endTime: '',
            timezone: clientTz(),
            description: '',
            meetingNotification: [],
            location: '',
            time: [],
            notiValue: 30,
            notiHr: 0,
            notiType: 0,
            isLoading: false,
            timeError: '',
            message:''

        }
    }

    componentDidMount() {
        console.log("thisistest", moment(this.props?.loading?.start_Date * 1000).format())       
        if( this.props.setClick)  
        this.props.setClick(this.reSet);  
       
        this.setState({
            diaryType: this.props?.loading?.eventType,
            
          
        })

        if (this.props.isEditTrue) {           
       
           
        }
        else {
            this.setState({ userData: this.props.state.userData })
            this.setState({
                startDate: moment(this.props?.loading?.start_Date * 1000),
                
              },()=>{
                  this.setState({
                      startTime: moment(this.state.startDate),
                      endTime:moment(this.state.startDate),
                      time:[this.state.startDate,this.state.startDate]
                  })
              })
        }
        function calcHeight(value) {
            let numberOfLineBreaks = (value.match(/\n/g) || []).length;
            let newHeight = 20 + numberOfLineBreaks * 20 + 12 + 2;
            return newHeight;
        }

       
    }



    // meetingType:{type:Number},//0=online meeting1=meeting in person
    // diaryType:{type:Number},//0=online1=hybrid
    // eventType:{type:Number,default:0},//0=reminder1=meeting
    // meetingNotification:[{
    // notificationType:{type:Number},//0=email1=notification
    // time:{type:Date},
    // timeType:{type:Number},//0=min1=hour


    clearFields = () => {
        this.setState({
            nameError: '',
            startDateError: '',
            timeError: ''
        })
    }

    clearValues = () => {
        if(!this.props.isEditTrue){
            this.setState({ startDate: moment(this.props?.loading?.start_Date * 1000),
                time:[moment(this.props?.loading?.start_Date * 1000),moment(this.props?.loading?.start_Date * 1000)]}
                )
        }
        this.setState({
            meetingModal: false,
            format: 'HH:mm',
            senderId: '',
            meetingType: 0,
            name: '',
            nameError: '',
            diaryType: this.props?.loading?.eventType,

            startDateError: '',
            startTime: '',
            endTime: '',
            timezone: moment.tz.guess(),
            description: '',
            meetingNotification: [],
            location: '',
            notiValue: 30,
            notiHr: 0,
            notiType: 0,
            isLoading: false,
            timeError: ''
        })


    }

    handleTime1 = (time) => {
        // moment('12:08:23', 'HH:mm:ss')
        console.log("timeee", time)
        if (time)
            this.setState({ startTime: time,  })
        this.clearFields()

    }

    handleTime2 = (time) => {
        // moment('12:08:23', 'HH:mm:ss')
        console.log("timeee", time)
        if (time)
            this.setState({ endTime: time })
        this.clearFields()

    }



    handleSubmit = () => {
        let method = Url.ADD_MEETING.method
        let url = Url.ADD_MEETING.url
        let req = {      
            startDate: this.state.startDate,
            startTime: this.state.startTime,
            endTime: this.state.endTime,
            timezone: this.state.timezone,
            reason:this.state.message.trim()
        }

        // console.log("timeeeeDD", this.state.startTime == this.state.endTime, moment(this.state.startTime).format('hh:mm'),moment(this.state.endTime).format('hh:mm') )


        if (this.props.isEditTrue) {
            method = Url.RESHDULE_MEETING.method
            url = Url.RESHDULE_MEETING.url
            req.meetingId = this.props?.diaryData?._id

        }
        let diff =  moment(this.state.endTime).diff(this.state.startTime, 'minutes')

        console.log("timeeeeDD", diff)

         if (moment(this.state.startTime).format('hh:mm') == moment(this.state.endTime).format('hh:mm')) {
            this.setState({ timeError: 'Start and end time can not be same' })
        }
        else if(diff < 1){
            this.setState({ timeError: 'Please select a valid time' })

        }
        else {


            this.setState({ isLoading: true })
            _Api(method, url, req)
                .then(resp => {
                    console.log("respresprespresp", resp);
                    this.setState({ visible: false, isLoading: false, message:'' })
                    this.clearValues()
                    notify("success", resp?.responseData?.message);

                   
                    this.props.onMeetingClose()                    

                    if(this.props.getConferenceDetail)
                    this.props.getConferenceDetail()


                }).catch(err => {
                    this.setState({ isLoading: false });
                    let errorMessage = err.error && err.error.responseMessage || err.message;
                    notify("err", errorMessage);

                })
        }
    }

    componentWillUnmount(){
        this.setState({message:'' })

    }


    handleOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
        this.clearFields()
    }
    handleMettingType = (type) => {
        this.setState({ meetingType: type })
    }


    handleTimeZone = (timezone) => {
        this.setState({ timezone })
    }


    handleDate = (date, type) => {
        this.setState({ [type]: date, time: [date, date], startTime: date, endTime: date })
        this.clearFields()
    }

    handleTime = (time) => {
        // moment('12:08:23', 'HH:mm:ss')
        console.log("timeee", time)
        if(time)
        this.setState({ startTime: time[0], endTime: time[1], time })
        this.clearFields()

    }

    handleChangeSelect = (value) => {
        console.log(`selected ${value}`);
        this.setState({ notiHr: value })
    }

    handleOnChangeNumber = (value) => {
        console.log(`value ${value}`);
        this.setState({ notiValue: value })
        // this.clearErrorMessage()
    }

    addNotification = () => {
        this.setState({
            meetingNotification: [... this.state.meetingNotification, {
                notificationType: this.state.notiType,
                time: this.state.notiValue,
                timeType: this.state.notiHr
            }]
        })

    }


    handleDelete = (id) => {
        this.setState({
            meetingNotification: this.state.meetingNotification.filter((item, index) => index != id)
        })

    }

    handleChangeTimeZone = (value) => {
        // console.log("kjfhdjsklf", )
        this.setState({ timezone: value.value })
    }

    handleChangeNotificationTye = (value) => {
        // console.log("sagvbhdnm", handleChangeNotificationTye)
        this.setState({ notiType: value })
    }
    reSet = () => {
        console.log("okaytest", this.props.diaryData)
        this.setState({ ...this.props.diaryData, userData: this.props.diaryData.senderData, startDate: moment(this.props.diaryData?.startDate), time: [moment.tz(this.props.diaryData?.startTime, this.props.diaryData?.timezone), moment.tz(this.props.diaryData?.endTime, this.props.diaryData?.timezone)] })
    }

    render() {

        const {
            senderId,
            meetingType,
            name,
            nameError,
            diaryType,
            startDate,
            startTime,
            endTime,
            timezone,
            description,
            meetingNotification,
            location,
            selectedTimezone,
            setSelectedTimezone,
            notiValue,
            notiHr,
            notiType,
            startDateError,
            timeError,
            message
        } = this.state
        // console.log("thispropsed", moment.unix(this.props?.loading?.end_Date))




        // start_Date: action?.payload?.start_Date,
        // end_Date: action?.payload?.end_Date



        // start_Date
        const disabledDateEnd = (current) => {
           
            return  current && current < moment()
        }


        return (
            <>
                <div className="sign-modal-content diary-meeting-modal-wrap">
                    <div className="row align-items-center">
                        <div className="col-md-12" >
                            <h3 className="auth-heading pos-relative text-center mt-md-0 mt-2 mb-3 mb-md-4">
                                Reschedule Meeting
                                <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                            </h3>

                            <div className="diary-meeting-form">
                                <form>
                                  
                                   
                                    
                                    <div className="form-group">
                                        <label>When</label>
                                        <div className="dirymeeting-time">
                                            <div className="row">
                                                <div className="col-md-3 col-5 pl-md-15-mtng pr-m-2 mb-3 mb-md-0">
                                                    <div className="datepicker-diary">
                                                        <div className="icn-wr">
                                                            <Calendar />
                                                            <DatePicker
                                                                disabledDate={disabledDateEnd}
                                                                format="DD/MM/YYYY"
                                                                value={this.state.startDate}
                                                                onChange={(e) => this.handleDate(e, "startDate")}
                                                            />
                                                        </div>
                                                        <InlineError message={startDateError} />
                                                    </div>

                                                </div>
                                                <div className="col-md-4 pl-m-2 col-7 mb-3 mb-md-0">
                                                    <div className="time-pcker-wrap">
                                                        <div className="strt-time icn-wr">
                                                            <Clock />
                                                            <TimePicker
                                                                title="Start Time"
                                                                defaultValue={moment(this.state.startTime, "HH:mm")}
                                                                value={moment.tz(this.state.startTime, this.state.timezone)}
                                                                format={"HH:mm"}
                                                                onChange={(e) => this.handleTime1(e)}
                                                            />
                                                        </div>
                                                        <div className="end-time icn-wr">
                                                            <Clock />
                                                            <TimePicker
                                                                title="End Time"
                                                                defaultValue={moment(this.state.endTime, "HH:mm")}
                                                                value={moment.tz(this.state.endTime, this.state.timezone)}
                                                                format={"HH:mm"}
                                                                onChange={(e) => this.handleTime2(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <InlineError message={timeError} />
                                                    

                                                </div>
                                                <div className="col-md-5 mb-3 mb-md-0">
                                                    {/* <button type="button" className="btn btn-gray w-100">{this.state.timezone}</button> */}
                                                    {/* <TimezonePicker onChange={console.log} /> */}
                                                    {/* <Select showSearch defaultValue={this.state.timezone} value={this.state.timezone} onChange={this.handleChangeTimeZone} className="form-control top-Rightmee" suffixIcon={<CaretDown />}>

                                                        {
                                                            TimeZone.map((item, index) => {
                                                                return (
                                                                    <Option value={item}>{item}</Option>
                                                                )
                                                            })
                                                        }
                                                    </Select> */}
                                                    <div className="timezone-wrapper">
                                                        <TimezoneSelect
                                                            defaultValue = {this.state.timezone}
                                                            value={this.state.timezone}
                                                            onChange={this.handleChangeTimeZone}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div> 
                                    <div className="form-group desc-atnd label-icn">
                                        <label><ListIcn />Message</label>
                                        <textarea
                                            className="form-control"
                                            name="message"
                                            placeholder="Enter here..."
                                            rows="4"
                                            maxLength={256}
                                            value={message}
                                            onChange={this.handleOnChange}
                                        ></textarea>
                                    </div>                         
                                    <div className="form-group text-center mb-0">
                                        <button type="button" disabled={this.state.isLoading} onClick={this.handleSubmit} className="btn btn-red m-0">{this.props.isEditTrue ? 'Request' : 'Send Meeting request'}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        ...state,
    };
}
export default connect(mapStateToProps)(DiaryMeetingModal);

