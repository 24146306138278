import React, { Component } from 'react';
import { Hert, Brands, Pencil, Delete, UploadVideo } from '../../../SvgIcon';
import { Link } from "react-router-dom";
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';
import Pagination from 'react-js-pagination';
import { Select, TreeSelect } from 'antd';
import InlineError from "../../../components/InlineError";
import { _Api, _ApiMultipart } from "../../../services/Api";
import Url from "../../../services/BaseUrl";
import { notify } from "../../../components/Toaster";
import Loader from "../../../components/Loader";
import CommingSoonLayer from "../../../components/common/CommingSoonLayer";
import Croper from "../../../components/Croper";

const { Option } = Select;
const { SHOW_PARENT, SHOW_CHILD, SHOW_ALL } = TreeSelect;
// import BackBtn from '../../../components/common/Button'

function handleChange(value) {
    console.log(`selected ${value}`);
}

class AddCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryTitle: "",
            categoryDesc: "",
            categoryType: 0,

            categoryTitleError: "",
            categoryDescError: "",
            categoryTypeError: "",
            categoryImgPrev: require('../../../assets/images/placeholder-img.png'),
            categoryImg: "",
            isLoader: false,
            isCommingSoon: false,
            placeOfworkList: [],
            placeOfWorkData: [],
            placeOfWorkError: '',
            placeofWorkId: [],
            parentCategoryId: this.props.match.params.catogoryId && this.props.match.params.catogoryId !== "" ? this.props.match.params.catogoryId : "",
            visibleVal: false,


            // new
            sectorList: [],
            sectorId: []





        }
        this.onChanged = this.onChanged.bind(this);
        this.addCategory = this.addCategory.bind(this);
    }

    componentDidMount() {
        // this.getCategoryDetails()
        
        this.getSectorList()

        if (this.props.match.params.PId) {
            this.getCategory()
         
        }
    }

    getSectorList = () => {
        let params = {
            // page: this.state.page,
            // limit: this.state.limit,
            // searchKey: this.state.searchKey,
            // sortField:this.state.sortField,
            // sortType:this.state.sortType,
            // parentCategoryId:this.state.parentCategoryId,
            // categoryType: ""+this.state.categoryType,
            level: "1"
        }
        _Api(Url.SECTOR_LIST.method, Url.SECTOR_LIST.url, params)
            .then(resp => {
                console.log('responseDataaaa', resp.responseData);
                this.setState({
                    sectorList: resp.responseData.result,
                    totalCount: resp.responseData.totalCount,
                    isLoader: false
                })
            }).catch(err => {
                this.setState({ isLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
                else if (err)
                    // notify("err", err.message)
                    this.setState({ isLoader: false })
            })
    }










    addCategory() {
        let self = this;
        let { placeofWorkId } = this.state;
        this.setState({
            categoryTitleError: "",
            categoryDescError: "",
            categoryTypeError: "",
            placeOfWorkError: ""
        })
        if (this.state.categoryTitle === '') {
            this.setState({ categoryTitleError: '*Please enter category title.' })
        }
        // else if (this.state.categoryDesc === ''){
        //     this.setState({ categoryDescError: '*Please enter description.' })
        // }
        else if (this.state.selectId == "") {
            this.setState({ categoryTypeError: '*Please select sector ' })


        } else {
            this.setState({ isLoader: true })
            let data = {
                'name': this.state.categoryTitle,
                'sectorId': this.state.sectorId.toString()
            }

            if(this.props.match.params.PId)
            {
                data.productAndServicesId = this.props.match.params.PId
            }          

            _Api(this.props.match.params.PId ? Url.EDIT_CATEGORY_PRODUCT.method : Url.ADD_CATEGORY_PRODUCT.method, this.props.match.params.PId ?  Url.EDIT_CATEGORY_PRODUCT.url : Url.ADD_CATEGORY_PRODUCT.url, data)
                .then(resp => {
                    this.setState({ isLoader: false })
                    this.props.history.goBack()
                    //console.log("resp",resp);
                    // self.props.history.push(this.props.match.params.catogoryId && this.props.match.params.catogoryId != "" ? "/category/" + this.props.match.params.catogoryId : "/category")
                }).catch(err => {
                    this.setState({ isLoader: false })
                    if (err.error) {
                        if (err.error.errorCode == 5) {
                            self.setState({
                                categoryTitleError: err.error.responseMessage
                            })
                        } else {
                            notify("err", err.error.responseMessage)
                        }
                    } else if (err) {
                        // notify("err", err.message)
                    }
                })
        }

    }

    selectOnChange = (type, e) => {
        console.log("jhgsfghjk", e)
        let self = this;
        this.setState({
            sectorId: e,
        })
    }

    onChanged(e) {
        console.log("eeee", e);
        let self = this;
        let keyName = e.target.name;
        let KeyValue = e.target.value;
        this.setState({
            categoryTitleError: "",
            categoryDescError: "",
            categoryTypeError: "",
            placeOfWorkError: ""
        })
        this.setState({ [e.target.name]: e.target.value }, function () {
         
        });
        if (e.target.name === 'categoryImg') {
            // console.log("e",e);
            let reader = new FileReader();
            let file = e.target.files[0];

            reader.onloadend = () => {
                this.setState({
                    categoryImgPrev: reader.result
                });
            }
            reader.readAsDataURL(file)

            this.setState({
                categoryImgPrev: file
            })
            this.setState({
                categoryImg: e.target.files[0]
            });
        }
    }

    getCategory() {
        let params = {
            productAndServicesId: this.props.match.params.PId ,
        }
        _Api(Url.GET_CATEGORY_PRODUCT_PARTICULAR.method, Url.GET_CATEGORY_PRODUCT_PARTICULAR.url, params)
            .then(resp => {
                console.log('responseDataaaaaaaaaaaaaaaa', resp.responseData.result);
                const {name, sectorId} = resp.responseData.result
               
                this.setState({
                    categoryTitle: name,
                    sectorId
                    
                })
            }).catch(err => {              
                this.setState({ isLoader: false })
            })
    }

    onChangePlacework = value => {
        this.setState({
            placeofWorkId: value,
        })
    }

    handleCroper = () => {
        this.setState({
            visibleVal: true
        })
    }

    finalCropedImage = (categoryImgPrev, categoryImg, visibleVal) => {
        this.setState({
            visibleVal,
            categoryImgPrev: categoryImgPrev,
            categoryImg: categoryImg

        })
    }

    render() {
        // console.log("this.state###", this.state);
        return (
            <>
                <div className="dash_body_container">
                    <Header />
                    <Sidebar />
                    {this.state.isLoader && <Loader />}
                    {this.state.isCommingSoon && <CommingSoonLayer />}
                    <div className="body_container">

                        <div className="header-text  mb-4 mt-3">

                            <div className="d-flex align-items-center">
                                <p className="header-icon"><Brands /></p>
                                <h4>Add {this.props.match.params.catogoryId && this.props.match.params.catogoryId != "" ? "Sub-Category" : "Category"}</h4>
                            </div>
                            {/*<Link to="/category"><button className="btn btn-light"><i className="fa fa-arrow-left" aria-hidden="true"></i>Back</button></Link>*/}
                        </div>
                        <div className="rounded-body py-5">
                            <div className="cm-form-wrapper rounded-textbox-form add-manag px-0">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <level>Category Title</level>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter category title" name="categoryTitle" value={this.state.categoryTitle}
                                                onChange={this.onChanged}
                                            />
                                            <InlineError
                                                message={this.state.categoryTitleError}
                                            />
                                        </div>
                                        {/* <div className="form-group cm_text-area">
                                            <level>Description</level>
                                            <textarea rows="4"
                                                className="form-control hi"
                                                placeholder="Enter description"
                                                 value={this.state.categoryDesc}
                                                onChange={this.onChanged}
                                                name="categoryDesc"
                                            />
                                            <InlineError
                                                message={this.state.categoryDescError}
                                            />
                                        </div> */}

                                    </div>

                                    <div className="col-md-6">
                                        {this.props.match.params.catogoryId && this.props.match.params.catogoryId != "" ? "" :
                                            <div className="form-group">
                                                <level>Select Sector</level>
                                                <Select
                                                    mode="multiple"
                                                    value={this.state.sectorId}
                                                    className="cm_multiselect cmslect_multicheck"
                                                    placeholder="Select" name="categoryType"
                                                    onChange={(e) => this.selectOnChange("categoryType", e)}>
                                                    {
                                                        this.state.sectorList?.map((item, index) => {
                                                            return (
                                                                <Option value={item._id}>{item.name}</Option>

                                                            )
                                                        })
                                                    }


                                                </Select>
                                                <InlineError
                                                    message={this.state.categoryTypeError}
                                                />
                                            </div>}


                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-sm-12 tab-add-btn-group">
                                        <Link to={"/jobcategory"} className="btn btn-secondary" type='button'>Cancel</Link>
                                        <button className="btn btn-primary" type='button' onClick={this.addCategory.bind(this)}>Save</button>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                    {/* {this.state.visibleVal ?
                        <Croper aspect={16 / 9}
                            // onImageCrop={img => this.setState({ media: img })}

                            finalCropedImage={(categoryImgPrev, categoryImg, visibleVal) => this.finalCropedImage(categoryImgPrev, categoryImg, visibleVal)} visibleVal={this.state.visibleVal} />
                        : ""} */}
                </div>
            </>
        );
    }
}
export default AddCategory;