import React, { Component } from 'react';
import { Hert } from '../../SvgIcon';
import { Link } from "react-router-dom"
import {Modal} from 'antd';
import { notify } from '../../components/Toaster';
import Loader from '../../components/Loader';
import Select from 'react-select';
import { _Api } from '../../services/Api';
import Url from '../../services/BaseUrl'
//validation
import { validateEmail } from '../../components/Validation';

import {connect} from "react-redux";

import {getExhibitionId, SIGNUP_PROCESS, TOGGLE_QUESTION, userActions} from "../../redux/action";
import InlineError from "../../components/InlineError";
import {MySpinner} from "../../components";

var _ = require('lodash');

class Question extends Component {
    constructor(props) {
        super(props);
        this.state = {
            questionStep:1,
            questionList:[],
            userAns:[],
            questionCount:[1,2,3,4],
            staticQuestion:4,
            sectorList:[],
            placeOfWorkList:[],
            roleList:[],
            sectorError:"",
            placeofWorkError:"",
            roleError:"",

            registrationIdError:"",
            countryError:"",
            cityError:"",
            countryList:[],
            cityList:[],
            registrationId:"",
            selectedSector:[],
            selectedSectorCount:0,
            selectedPlaceOfWork:[],
            placeofworkCount:0,
            selectedRole:[],
            roleCount:0,
            selectedCountryCode:"",
            // selectedCountry:"",
            // selectedCity:"",
            selectedCityId:"",
            otherCity:"",
            showOtherCity:false,
            countrySelectedOption:null,
            citySelectedOption:null,
            isNext:false,
            lastQuestion:[]
        }

    }
    componentDidMount() {
        if (localStorage.getItem('accessToken') && localStorage.getItem('accessToken') !== '') {

        }
        this.getSector();
        let self = this;
        let exhibitionId = self.props.loading.exhibitionId;
        if(exhibitionId&&exhibitionId!==""){
            self.getQuestion(exhibitionId);
        }else{
            self.props.dispatch(getExhibitionId())
                .then(function (){
                    self.getQuestion(self.props.loading.exhibitionId);
                })
        }
    }

    checkStepClass=(step)=>{
        if(step<this.state.questionStep){
            return 'signup-step done';
        }else if(step===this.state.questionStep){
            return 'signup-step active';
        }else{
            return 'signup-step';
        }
    }

    checkSelected=(field,value)=>{
        let selectedField=this.state['userAns-'+field]
        let checkResult=_.find(selectedField,function (obj){
            return obj==value;
        })
        //console.log("selectedField",selectedField,checkResult,value)
        if(checkResult){
            return true;
        }else{
            return false;
        }
    }

    checkSelectedStatic=(field,value)=>{
        let selectedField=this.state[field]
        let checkResult=_.find(selectedField,function (obj){
            return obj===value;
        })
        //console.log("selectedField",selectedField,checkResult,value)
        if(checkResult){
            return true;
        }else{
            return false;
        }
    }

    checkRadioSelected=(field,value)=>{
        let selectedField=this.state['userAns-'+field]
        if(selectedField===value){
            return true;
        }else{
            return false;
        }
    }

    getSector=()=>{
        _Api(Url.GET_SECTOR_SIGNUP_LIST.method, Url.GET_SECTOR_SIGNUP_LIST.url)
            .then(resp => {
                if (resp.statusCode == 1) {
                    this.setState({
                        sectorList: resp.responseData.result,
                    })
                }
            }).catch(err => {
            if (err.error)
                notify("err", err.error.responseMessage)
        })
    }

    getplaceOfWork=(e)=>{
        let self=this;
        e.preventDefault();
        let sectorId=this.state.selectedSector;
        self.setState({"isLoading":true})
        _Api(Url.GET_PLACE_OF_WORK_LIST.method, Url.GET_PLACE_OF_WORK_LIST.url,{sectorId:sectorId.toString()})
            .then(resp => {
                if (resp.statusCode == 1) {
                    this.setState({
                        placeOfWorkList: resp.responseData.result.data,
                    })
                    self.setState({"isLoading":false})
                    self.signupNext(2);
                }
            }).catch(err => {
            if (err.error)
                notify("err", err.error.responseMessage)
        })
    }

    signupNext=(step)=>{
        this.setState({
            questionStep:step
        })
    }

    getRole=(e)=>{
        let self=this;
        e.preventDefault();
        let placeOfWorkId=this.state.selectedPlaceOfWork;
        self.setState({"isLoading":true})
        _Api(Url.GET_ROLE_LIST.method, Url.GET_ROLE_LIST.url, {placeOfWorkId: placeOfWorkId.toString()})
            .then(resp => {
                if (resp.statusCode == 1) {
                    this.setState({
                        roleList: resp.responseData.result.data,
                    })
                    self.setState({"isLoading":false})
                    self.signupNext(3);
                }
            }).catch(err => {
            if (err.error)
                notify("err", err.error.responseMessage)
        })
    }

    signupNextStep=(e)=>{
        let self=this;
        e.preventDefault();
        this.setState({
            sectorError:"",
            placeofWorkError:"",
            roleError:"",
            registrationIdError:"",
            countryError:"",
            cityError:"",
        })
        if(this.state.questionStep==1){
            //console.log("this.props.selectedSector.length",this.props.selectedSector.length,this.props.selectedSector);
            if(this.state.selectedSector.length>0){
                self.getplaceOfWork(e);
            }else{
                this.setState({
                    sectorError:"*Please select any sector"
                })
            }
        }else if(this.state.questionStep==2){
            if(this.state.selectedPlaceOfWork.length>0) {
                self.getRole(e);
            }else{
                this.setState({
                    placeofWorkError:"*Please select any place of work"
                })
            }
        }else if(this.state.questionStep==3){
            if(this.state.registrationIdRequired==true){
                // if(this.state.registrationId.trim()===""){
                //     this.setState({ registrationIdError: '*Please enter.' })
                // }else{
                this.getCountry();
                this.signupNext(4);
                //}
            }else {
                let checkRegistrationId = 0;
                if (this.state.selectedRole.length > 0) {
                    let roleArr = ["5fc9d333611cf52928910101",
                        "5fc73e5a237eae12638c982e",
                        "5fc72577237eae12638c9823",
                        "5fc72850237eae12638c9825",
                        "5fc724ba237eae12638c9822",
                        "5fc78d3c237eae12638c9834"];
                    _.map(this.state.selectedRole, function (role) {
                        let checkResult = _.find(roleArr, function (obj) {
                            return obj === role;
                        })
                        if (checkResult) {
                            checkRegistrationId++;
                        }
                    })

                    if (checkRegistrationId > 0) {
                        self.setState({
                            registrationIdRequired:true
                        })
                        //self.state.stateSet('registrationIdRequired',true)
                    } else {
                        this.getCountry();
                        this.signupNext(4);
                    }
                } else {
                    this.setState({
                        roleError: "*Please select any role"
                    })
                }
            }
        }else if(this.state.questionStep==4){
            if(!this.state.selectedCountry||this.state.selectedCountry===""){
                this.setState({ countryError: '*Please select country.' })
            }
                // else if(!this.state.selectedCity||this.state.selectedCity===""){
                //     this.setState({ cityError: '*Please select city.' })
            // }
            else{
                this.signupNext(5);
            }
        }
    }

    getCountry=()=>{
        let self=this;
        self.setState({"isLoading":true})
        _Api(Url.GET_COUNTRY_LIST_V2.method, Url.GET_COUNTRY_LIST_V2.url)
            .then(resp => {
                if (resp.statusCode == 1) {
                    this.setState({
                        countryList: resp.responseData.result,
                    })
                }
                self.setState({"isLoading":false})
            }).catch(err => {
            if (err.error)
                notify("err", err.error.responseMessage)
        })
    }

    getCity=(selectedCountryCode)=>{
        let self=this;
        //let selectedCountryCode=this.props.selectedCountryCode;
        _Api(Url.GET_CITY_LIST.method, Url.GET_CITY_LIST.url,'', '?countryCode='+selectedCountryCode)
            .then(resp => {
                if (resp.statusCode == 1) {
                    this.setState({
                        cityList: resp.responseData.result,
                    })
                }
            }).catch(err => {
            if (err.error)
                notify("err", err.error.responseMessage)
        })
    }

    selectCountry=(val)=>{
        //console.log("value",val);
        let value=val.value;
        let data=value.split("#");
        // this.props.selectCountry(data[0],data[1]);
        this.setState({
            countrySelectedOption:val,
            selectedCountryCode:data[0],
            selectedCountry:data[1],
            showOtherCity:false,
            selectedCity:"",
            selectedCityId:"",
            citySelectedOption:null
        })
        this.getCity(data[0]);
    }

    selectCity=(val)=>{
        let value=val.value;
        let data=value.split("#");
        //this.props.selectCity(data[1],data[0])
        if(value==="other"){
            this.setState({
                citySelectedOption:val,
                showOtherCity:true,
                selectedCity:"Other"
            })
        }else{
            this.setState({
                citySelectedOption:val,
                showOtherCity:false,
                selectedCity:data[1],
                selectedCityId:data[0]
            })
        }
    }

    getQuestion=(exhibitionId)=>{
        let self=this;
        _Api(Url.EVENT_QUESTION.method, Url.EVENT_QUESTION.url,{exhibitionId:exhibitionId})
            .then(resp => {
                if (resp.statusCode == 1) {
                    let arr = resp.responseData.result.data
                    // let lastObj = arr.splice(-1).pop()


                    console.log("isdaghjkl", arr)
                    this.setState({
                        questionList: arr,
                        //  lastQuestion:lastObj
                    },function (){
                        let questionCount=self.state.questionCount

                        // this.setState({lastQuestion : questionCount.slice(-1).pop()})
                        self.state.questionList.map((ques,i)=>{
                            questionCount.push(self.state.questionCount.length+1)
                        })
                        self.setState({
                            questionCount
                        })
                    })
                }
            }).catch(err => {
            if (err.error)
                notify("err", err.error.responseMessage)
        })
    }

    questionNextStep=(e,i,question)=>{
        let self=this;
        e.preventDefault();
        console.log("question",question);

        if (question?._id ==  "60011dcf6169f7265dcea8aa") {
            if (this.state["userAns-5fffd3682112b80659e30dc5"]) {

                if (this.state["userAns-60011dcf6169f7265dcea8aa"] == "" || this.state["userAns-60011dcf6169f7265dcea8aa"] == undefined) {
                    this.setState({
                        [question._id + '-error']: "*Please answer this question"
                    })

                }
                else {
                    this.submitEventQuestion();

                }

            }

            else {
                this.submitEventQuestion();

            }

        }

        else   if(question.isMandatory===2){
            if(question.questionType===1){
                if(this.state['userAns-'+question._id]&&this.state['userAns-'+question._id].trim()!==""){
                    if(this.state.questionStep<this.state.questionCount.length){
                        this.setState({
                            questionStep:this.state.staticQuestion+i+1
                        })
                    }else{
                        this.submitEventQuestion();
                    }
                }else{
                    this.setState({
                        [question._id+'-error']:"*Please answer this question"
                    })
                }
            }else{
                if(this.state['userAns-'+question._id]&&this.state['userAns-'+question._id].length>0){
                    if(this.state.questionStep<this.state.questionCount.length){
                        this.setState({
                            questionStep:this.state.staticQuestion+i+1
                        })
                    }else{
                        this.submitEventQuestion();
                    }
                }else{
                    this.setState({
                        [question._id+'-error']:"*Please answer this question"
                    })
                }
            }
        }else{
            if(this.state.questionStep<this.state.questionCount.length){
                this.setState({
                    questionStep:this.state.staticQuestion+i+1
                })
            }else{
                this.submitEventQuestion();
            }
        }

    }

    backQuestionStep=()=>{
        if(this.state.questionStep==3){
            if(this.state.registrationIdRequired===false){
                this.setState({
                    questionStep:this.state.questionStep-1
                })
            }else{
                this.setState({
                    registrationIdRequired:false
                })
            }
        }else{
            this.setState({
                questionStep:this.state.questionStep-1
            })
        }
    }

    onChecked=(e,question)=>{
        let selectedField=this.state['userAns-'+question]?this.state['userAns-'+question]:[];
        let checkResult=_.find(selectedField,function (obj){
            return obj===e.target.value;
        })
        //console.log("onChecked",e.target.value,selectedField,checkResult);
        if(checkResult){
            let newData = _.remove(selectedField, function(n) {
                return e.target.value===n;
            });
            //console.log("======",newData);
            // if(question == "5fffd3682112b80659e30dc5" && this.state.questionCount.length == 9)
            // {
            //     this.state.questionList.splice(-1).pop()
            //     this.state.questionCount.splice(-1).pop()
            //     this.setState({isNext:false})
            // }
            this.setState({
                [question+'-error']:"",
                ['userAns-'+question]:selectedField,
            })
        }else {

            if(question == "5fffd3682112b80659e30dc5" && this.state.questionCount.length == 8)
            {
                this.state.questionList.push(this.state.lastQuestion)
                this.state.questionCount.push(this.state.questionCount.length+1)
                this.setState({isNext:true})
            }




            selectedField.push(e.target.value);
            this.setState({
                [question+'-error']:"",
                ['userAns-'+question]:selectedField,
            })
        }
    }

    onCheckedBox=(id,question)=>{
        let selectedField=this.state['userAns-'+question]?this.state['userAns-'+question]:[];
        let checkResult=_.find(selectedField,function (obj){
            return obj===id;
        })
        let questionId=question;
        let nextQuestion=_.find(this.state.questionList,function (obj){
            return obj.previousQuestionId===questionId;
        })
        if(nextQuestion){
            this.setState({
                ['userAns-'+nextQuestion._id]:[]
            })
        }
        //console.log("onChecked",e.target.value,selectedField,checkResult);
        if(checkResult){
            let newData = _.remove(selectedField, function(n) {
                return id===n;
            });
            //console.log("======",newData);
            // if(question == "5fffd3682112b80659e30dc5" && this.state.questionCount.length == 9)
            // {
            //     this.state.questionList.splice(-1).pop()
            //     this.state.questionCount.splice(-1).pop()
            //     this.setState({isNext:false})
            // }
            this.setState({
                [question+'-error']:"",
                ['userAns-'+question]:selectedField,
            })
        }else {
            selectedField.push(id);
            if(question == "5fffd3682112b80659e30dc5" && this.state.questionCount.length == 8)
            {

                this.state.questionCount.push(this.state.questionCount.length+1)
                this.state.questionList.push(this.state.lastQuestion)
                this.setState({isNext:true })
            }
            this.setState({
                [question+'-error']:"",
                ['userAns-'+question]:selectedField,
            })
        }
    }

    onRadioSelect=(e,question)=>{
        this.setState({
            [question+'-error']:"",
            ['userAns-'+question]:e.target.value,
        })
    }

    onRadioSelectBox=(id,question)=>{
        this.setState({
            [question+'-error']:"",
            ['userAns-'+question]:id,
        })
    }

    getOption=(question,index)=>{
        let option=[];

        let preQuesAns=this.state['userAns-'+question.previousQuestionId];
        _.map(preQuesAns,function (obj){
            let optionValue=_.find(question.option,function (opt){
                if(opt.name===obj){
                    return opt;
                }
            });
            console.log("=========",optionValue,obj);
            if(optionValue){
                option=_.union(option, optionValue.value);
            }

        })
        //console.log("=========",option);
        return option;
    }

    textAnsSave=(e,question)=>{
        this.setState({
            [question._id+'-error']:"",
            ['userAns-'+question._id]:e.target.value
        })
    }

    submitEventQuestion=()=>{
        let self=this;
        console.log("submitEventQuestion");
        let questionList=this.state.questionList;
        let userAns=[];
        _.map(questionList,function (ques){
            let ans={};
            ans.questionId=ques._id;
            if(ques.questionType===1){
                ans.plainTextAnswer=self.state['userAns-'+ques._id]?self.state['userAns-'+ques._id]:"";
            }else if(ques.questionType===2){
                ans.optionAnswerArray=self.state['userAns-'+ques._id];
            }else{
                ans.optionAnswerArray=self.state['userAns-'+ques._id]?[self.state['userAns-'+ques._id]]:[];
            }
            userAns.push(ans);
        })
        //console.log("userAns",userAns);
        let data={
            exhibitionId:self.props.loading.exhibitionId,
            userAnswer:userAns,
            country: this.state.selectedCountry,
            country_Code: this.state.selectedCountryCode,
            registrationId: this.state.registrationId,
            placeOfWork: this.state.selectedPlaceOfWork.toString(),
            sectorId: this.state.selectedSector.toString(),
            roleId: this.state.selectedRole.toString(),
        }
        if(this.state.showOtherCity===true){
            data.city= this.state.otherCity;
        }else{
            data.city= this.state.selectedCity;
            data.cityId=this.state.selectedCityId;
        }
        _Api(Url.USER_ANSWER_SUBMIT.method, Url.USER_ANSWER_SUBMIT.url,data)
            .then(resp => {
                if (resp.statusCode == 1) {
                    this.props.dispatch({ type: TOGGLE_QUESTION, value: 0 });
                    self.props.dispatch(userActions.profile())
                }
            }).catch(err => {
            if (err.error)
                notify("err", err.error.responseMessage)
        })
    }

    onCheckedBoxStatic=(id,field,countField)=>{
        let selectedField=this.state[field]
        if(field==='selectedSector'){
            this.setState({
                selectedPlaceOfWork:[],
                placeofworkCount:0,
                selectedRole:[],
                roleCount:0,
                registrationId:"",
                registrationIdRequired:false,
            })
        }else if(field==='selectedPlaceOfWork'){
            this.setState({
                selectedRole:[],
                roleCount:0,
                registrationId:"",
                registrationIdRequired:false,
            })
        }else if(field==='selectedRole'){
            this.setState({
                registrationId:"",
                registrationIdRequired:false,
            })
        }
        let checkResult=_.find(selectedField,function (obj){
            return obj===id;
        })
        //console.log("onChecked",e.target.value,selectedField,checkResult);
        if(checkResult){
            let newData = _.remove(selectedField, function(n) {
                return id===n;
            });
            //console.log("======",newData);
            this.setState({
                [field]:selectedField,
                [countField]:this.state[countField]-1
            })
        }else {
            selectedField.push(id);
            this.setState({
                [field]:selectedField,
                [countField]:this.state[countField]+1
            })
        }
    }

    sugnUpOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    logOut=()=>{
        localStorage.clear();
        window.location.href="/"
    }

    skipQuestion=()=>{
        this.submitEventQuestion();
    }

    render() {
        console.log("thisprops",this.state.questionList);
        console.log("this.state",this.state);
        let {sectorError,placeofWorkError,roleError,registrationIdError,countryError,cityError,registrationId}=this.state;
        let option;
        let countryData =[];
        {this.state.countryList.length>0 &&this.state.countryList.map((item) => (
            countryData.push( { value: item.country_code+"#"+item.country_name, label: item.country_name })
        ))}
        let cityData=[];
        {this.state.cityList.length>0&&this.state.cityList.map((item) => (
            cityData.push( { value: item._id+"#"+item.city_name, label: item.city_name })
        ))}
        cityData.push( { value: 'other', label: 'Other' })
        return (
            <>
                <Modal
                    wrapClassName="signup_parent_modal transparent"
                    className="cm_popup signup_modal "
                    visible={this.props.setting.eventQuestionnaire}
                    //visible={false}
                    onCancel={()=>this.logOut()}
                    footer={null}
                    centered
                    maskClosable={false}
                    closable={true}
                >
                    <div className="sign-modal-content">
                        <div className="top_banner pos-relative">
                            <img src={require('../../assets/images/popup-image.png')} className="img-fluid" />

                            <h4 className="text-center popup_mid_test ">
                                <b className="pos-relative">Explore Brands & Innovations</b>
                                <span className="heading-after-border heading-after-border-center">
                                    <span className="heart"><Hert/></span></span>
                            </h4>
                            {this.state.questionStep!==1?
                                <button type="button" className="btn sign-backbtn btn-on-img" onClick={this.backQuestionStep}> &nbsp;Back
                                </button>:""}
                            {(this.state.questionStep>=6 && this.state.questionStep < 8)?
                                <button type="button" className="btn btn-on-img question-skip" onClick={this.skipQuestion}>Skip
                                </button>:""}
                        </div>
                        <div className="row align-items-center">
                            <div className="col-md-12">

                                <div className="signup-tab mt-3 mb-5">
                                    <div className="Thing-line"><span className="circle_bullet"></span><span
                                        className="heart"><Hert/></span></div>
                                    {this.state.questionCount.map((ques,i)=>(
                                        <div key={"step-"+ques} className={this.checkStepClass(i+1)}><span>{i+1}</span></div>
                                    ))}
                                </div>
                                {this.state.questionStep===1?
                                    <div id="step1" >
                                        <h3 className="auth-heading pos-relative text-center mt-5">
                                            Which Sector(s) do you currently work in?
                                            <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                        </h3>
                                        {/*<p className="home-subheading text-center">Free for professionals and low cost options for brands and suppliers </p>*/}
                                        <form className="rounded-textbox-form mt-5" onSubmit={this.signupNextStep}>

                                            {this.state.sectorList.length>0&&this.state.sectorList.map((item) => (
                                                <div className={this.checkSelectedStatic('selectedSector',item._id)?"form-group form-control select_field cursor-point":"form-group form-control cursor-point"} key={item._id} onClick={(e)=>this.onCheckedBoxStatic(item._id,'selectedSector','selectedSectorCount')}>
                                                    <div className="check_wrapper circle-checkbox ">
                                                        <input name={'selectedSector-'+item.name} checked={this.checkSelectedStatic('selectedSector',item._id)} type="checkbox" value={item._id} />
                                                        <span />
                                                    </div>
                                                    <span>{item.name}</span>
                                                </div>
                                            ))}
                                            <InlineError
                                                message={sectorError}/>
                                            <div className="text-center">
                                                <button type="submit" disabled={this.state.isLoading}  className="btn btn-red"> &nbsp;Next
                                                    &nbsp;
                                                    {this.state.isLoading &&
                                                    <MySpinner
                                                    />}
                                                </button>
                                            </div>

                                        </form>
                                    </div>
                                    :""}
                                {this.state.questionStep===2?
                                    <div id="step2" >
                                        <h3 className="auth-heading pos-relative text-center mt-5">
                                            What is your place of work?
                                            <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                        </h3>
                                        {/*<p className="home-subheading text-center">Free for professionals and low cost options for brands and suppliers </p>*/}
                                        <form className="rounded-textbox-form mt-5" onSubmit={this.signupNextStep}>
                                            {this.state.placeOfWorkList.length>0&&this.state.placeOfWorkList.map((item) => (
                                                <div className={this.checkSelectedStatic('selectedPlaceOfWork',item._id)?"form-group form-control select_field cursor-point":"form-group form-control cursor-point"} key={item._id} onClick={(e)=>this.onCheckedBoxStatic(item._id,'selectedPlaceOfWork','placeofworkCount')}>
                                                    <div className="check_wrapper circle-checkbox ">
                                                        <input name={'selectedPlaceOfWork-'+item.name} checked={this.checkSelectedStatic('selectedPlaceOfWork',item._id)} type="checkbox" value={item._id} />
                                                        <span />
                                                    </div>
                                                    <span>{item.name}</span>
                                                </div>
                                            ))}
                                            <InlineError
                                                message={placeofWorkError}/>
                                            <div className="text-center">
                                                <button type="submit"  disabled={this.state.isLoading} className="btn btn-red"> &nbsp;Next
                                                    &nbsp;
                                                    {this.state.isLoading &&
                                                    <MySpinner
                                                    />}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                    :""}
                                {this.state.questionStep===3?
                                    this.state.registrationIdRequired===false?
                                        <div id="step3" >
                                            <h3 className="auth-heading pos-relative text-center mt-5">
                                                What is your role?
                                                <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                            </h3>
                                            {/*<p className="home-subheading text-center">Free for professionals and low cost options for brands and suppliers </p>*/}
                                            <form className="rounded-textbox-form mt-5" onSubmit={this.signupNextStep}>
                                                {this.state.roleList.length>0&&this.state.roleList.map((item) => (
                                                    <div className={this.checkSelectedStatic('selectedRole',item._id)?"form-group form-control select_field cursor-point":"form-group form-control cursor-point"} key={item._id} onClick={(e)=>this.onCheckedBoxStatic(item._id,'selectedRole','roleCount')}>
                                                        <div className="check_wrapper circle-checkbox ">
                                                            <input name={'selectedRole-'+item.name} checked={this.checkSelectedStatic('selectedRole',item._id)} type="checkbox" value={item._id} />
                                                            <span />
                                                        </div>
                                                        <span>{item.name}</span>
                                                    </div>
                                                ))}
                                                <InlineError
                                                    message={roleError}/>
                                                <div className="text-center">
                                                    <button type="submit" disabled={this.state.isLoading} className="btn btn-red"> &nbsp;Next
                                                        &nbsp;
                                                        {this.state.isLoading &&
                                                        <MySpinner
                                                        />}
                                                    </button>
                                                </div>

                                            </form>
                                        </div>
                                        :
                                        <div className="mt-5">
                                            <h4 className="pl-4 pr-4">Do you have a registered GDC/ GMC/ NMC/ GPhC number or overseas equivalent?</h4>
                                            <form className="rounded-textbox-form mt-5" onSubmit={this.signupNextStep}>
                                                <div className="form-group">
                                                    <input type="text"
                                                           autocomplete="do-not-show-ac"
                                                           className="form-control"
                                                           placeholder="Enter GDC/ GMC/ NMC/ GPhc number"
                                                           name='registrationId'
                                                           value={registrationId}
                                                           onChange={this.sugnUpOnChange}
                                                    />
                                                    <InlineError
                                                        message={registrationIdError}/>
                                                </div>

                                                <div className="text-center">
                                                    <button type="submit"  disabled={this.props.isLoading} className="btn btn-red"> &nbsp;Next
                                                        &nbsp;
                                                        {this.state.isLoading &&
                                                        <MySpinner
                                                        />}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    :""}
                                {this.state.questionStep===4?
                                    <div id="step4" >
                                        <form className="rounded-textbox-form mt-5 " onSubmit={this.signupNextStep}>

                                            <div className="form-group">
                                                <h4 className="text-center"><b>Which country are you currently based?</b>
                                                    {/* <span className="red-star"> * </span> */}
                                                </h4>
                                                <Select showSearch
                                                        placeholder="Select Country"
                                                        onChange={(e)=>this.selectCountry(e)}
                                                        value={this.state.countrySelectedOption}
                                                        options={countryData}
                                                >

                                                </Select>
                                                <InlineError
                                                    message={countryError}/>
                                            </div>
                                            <div className="form-group">
                                                <h4 className="text-center"><b>Which is your closest large city?</b>
                                                    {/* <span className="red-star"> * </span> */}
                                                </h4>
                                                <Select showSearch
                                                        placeholder="Select City"
                                                        onChange={this.selectCity}
                                                        value={this.state.selectedCityOption}
                                                        options={cityData}
                                                >


                                                </Select>

                                                <InlineError
                                                    message={cityError}/>
                                            </div>
                                            {
                                                this.state.showOtherCity === true ?
                                                    <div className="form-group">
                                                        <input type="text"
                                                               autoComplete="do-not-show-ac"
                                                               className="form-control"
                                                               placeholder="City Name"
                                                               id="city"
                                                               maxLength={250}
                                                               name='otherCity'
                                                               value={this.state.otherCity}
                                                               onChange={this.sugnUpOnChange}
                                                        />
                                                    </div>:""}

                                            <div className="text-center">
                                                <button type="submit" disabled={this.state.isLoading} className="btn btn-red"> &nbsp;Next
                                                    &nbsp;
                                                    {this.state.isLoading &&
                                                    <MySpinner
                                                    />}
                                                </button>
                                            </div>

                                        </form>

                                    </div> :""}
                                {this.state.questionList.length > 0 && this.state.questionList.map((item,i) => (
                                    this.state.questionStep===(this.state.staticQuestion+i+1)?
                                        <div key={"step-ques-"+item._id} id={"step-"+i}>

                                            <h4 className="text-center"><b> {item.question}</b></h4>
                                            <form className="rounded-textbox-form mt-4" onSubmit={(e)=>this.questionNextStep(e,i+1,item)} >

                                                {item.questionType===1?
                                                    <div>
                                                        <input type="text" rows="4" placeholder="Please Enter" className="form-control hi" value={this.state['userAns-'+item._id]} onChange={(e)=>this.textAnsSave(e,item)}/>



                                                    </div>
                                                    :""}
                                                {item.questionType===2?
                                                    item.isRelation===1&&this.state.questionList[i-1].questionType!==1?

                                                        this.getOption(item,i).map((opt,i)=>(
                                                            <div
                                                                className={this.checkSelected(item._id, opt) ? "form-group form-control select_field cursor-point" : "form-group form-control cursor-point"}
                                                                key={item._id+"-"+opt}
                                                                onClick={(e) => this.onCheckedBox(opt, item._id)}
                                                            >
                                                                <div className="check_wrapper circle-checkbox ">
                                                                    <input name={'selectedSector-' + opt}
                                                                           checked={this.checkSelected(item._id, opt)}
                                                                           type="checkbox" value={opt}
                                                                        //onChange={(e) => this.onChecked(e, item._id)}
                                                                    />
                                                                    <span/>
                                                                </div>
                                                                <span>{opt}</span>
                                                            </div>
                                                        ))
                                                        :
                                                        item.option.map((opt,i)=>(
                                                            <div
                                                                className={this.checkSelected(item._id, opt.name) ? "form-group form-control select_field cursor-point" : "form-group form-control cursor-point"}
                                                                key={item._id+"-"+opt.name}
                                                                onClick={(e) => this.onCheckedBox(opt.name, item._id)}
                                                            >
                                                                <div className="check_wrapper circle-checkbox ">
                                                                    <input name={'selectedSector-' + opt.name}
                                                                           checked={this.checkSelected(item._id, opt.name)}
                                                                           type="checkbox" value={opt.name}
                                                                        //onChange={(e) => this.onChecked(e, item._id)}
                                                                    />
                                                                    <span/>
                                                                </div>
                                                                <span>{opt.name}</span>
                                                            </div>
                                                        ))

                                                    :""}
                                                {item.questionType===3?
                                                    item.isRelation===1&&this.state.questionList[i-1].questionType!==1?
                                                        this.getOption(item,i).map((opt,i)=>(
                                                            <div
                                                                className={this.checkRadioSelected(item._id, opt) ? "form-group form-control select_field cursor-point" : "form-group form-control cursor-point"}
                                                                key={item._id+"-"+opt}
                                                                onClick={(e) => this.onRadioSelectBox(opt, item._id)}
                                                            >
                                                                <div className="check_wrapper circle-checkbox ">
                                                                    <input name={'radio-' + item._id}
                                                                           checked={this.checkRadioSelected(item._id, opt)}
                                                                           type="radio" value={opt}
                                                                        //onChange={(e) => this.onRadioSelect(e, item._id)}
                                                                    />
                                                                    <span/>
                                                                </div>
                                                                <span>{opt}</span>
                                                            </div>
                                                        ))
                                                        :
                                                        item.option.map((opt,i)=>(
                                                            <div
                                                                className={this.checkRadioSelected(item._id, opt.name) ? "form-group form-control select_field cursor-point" : "form-group form-control cursor-point"}
                                                                key={item._id+"-"+opt.name}
                                                                onClick={(e) => this.onRadioSelectBox(opt.name, item._id)}
                                                            >
                                                                <div className="check_wrapper circle-checkbox ">
                                                                    <input name={'radio-' + item._id}
                                                                           checked={this.checkRadioSelected(item._id, opt.name)}
                                                                           type="radio" value={opt.name}
                                                                        //onChange={(e) => this.onRadioSelect(e, item._id)}
                                                                    />
                                                                    <span/>
                                                                </div>
                                                                <span>{opt.name}</span>
                                                            </div>

                                                        ))
                                                    :""}

                                                <InlineError
                                                    message={this.state[item._id+"-error"]?this.state[item._id+"-error"]:""}/>
                                                <div className="text-center">
                                                    <button type="submit" disabled={this.props.isLoading}
                                                            className="btn btn-red"> &nbsp;{this.state.isNext && this.state.questionList.length != 5 ? 'Next' : (this.state.questionCount.length ===this.state.questionStep ) ?'Complete Registration':'Next'}
                                                        &nbsp;
                                                        {this.state.isLoading &&
                                                        <MySpinner
                                                        />}
                                                    </button>
                                                </div>

                                            </form>
                                        </div>
                                        :""
                                ))}
                            </div>
                        </div>
                    </div>
                </Modal>
            </>

        )
    }
}
function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(Question);