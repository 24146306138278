import React, { Component } from 'react';
import { Hert, ExhibitionManagement, Pencil, Delete } from '../../../SvgIcon';
import { Link } from "react-router-dom";
import { DatePicker, TimePicker, Empty } from 'antd';
import moment from 'moment-timezone';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';

// import Pagination from 'react-js-pagination';
import Loader from '../../../components/Loader'
import { notification } from 'antd';

import { Modal, Button, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import {NetworkingLoungeLoader, Pagination} from "../../index"

import {getTimeFormate} from "../../../utils/DateZone"

const { confirm } = Modal;

class NetworkLounge extends Component {

    constructor(props) {
        super(props)
        this.state = {
            // networking
            isDeleted: '',
            loungeId: '',
            status: "",
            page: 1,
            limit: 10,
            loading: false,
            visible: false,
            exhibitionListData: [],
            selectedId: '',
            search: '',
            totalCount: '',           
            snNo: 0,
            networkingLoungeData:[]

        }
    }


    componentDidMount() {
        this.getNetworkingLoungeList()

    }



    /***
    * networkkkkkkkkkkkkkkkkk 
    */


    getNetworkingLoungeList = (isLoader=true) => {
        this.setState({ isLoader: isLoader })
        let { page, limit, search } = this.state
        let req = {
            page: page ? page : 1,
            limit: limit,
        }

        _Api(Url.GET_NETWORKING_LOUNGE_LIST.method, Url.GET_NETWORKING_LOUNGE_LIST.url, '', `?page=${req.page}&limit=${req.limit}&exhibitionId=${this.props.match.params.exId}`)
            .then(resp => {
               
                
                this.setState({ networkingLoungeData: resp.responseData.result.data, totalCount: resp.responseData.result.totalCount, isLoader: false })

            }).catch(err => {
                this.setState({ isLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
                else if (err)
                    // // notify("err", err.message)
                this.setState({ isLoader: false })
            })
    }

    handlePageChange = page => this.setState({ loader: true, page }, () => this.getNetworkingLoungeList());

    handleSearch = e => this.setState({ search: e.target.value }, () => this.getNetworkingLoungeList());



    deleteNetworkingLounge = (id) => {
        let req = {
            isDeleted: 0,
            loungeId: id,

        }
        _Api(Url.DELETE_NETWORKING_LOUNGE.method, Url.DELETE_NETWORKING_LOUNGE.url, '', `?loungeId=${req.loungeId}&isDeleted=${req.isDeleted}`)
            .then(resp => {
               
                
                notify('success', resp.responseData.message)
                this.setState({ visible: false })
                this.getNetworkingLoungeList(false)
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
            })


    }

    //Show modal
    showModal = (id) => {
        this.setState({
            visible: true,
            selectedId: id
        });

    };
    //Cancel modal
    handleCancel = () => {
        this.setState({ visible: false });
    }


    // delete modal
    showDeleteConfirm = (id) => {
        confirm({
            title: 'Are you sure you want to delete this?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            onOk: () => {
             this.deleteNetworkingLounge(id)
            },
            onCancel: () => {
                this.handleCancel()
            },
        });
    }



    statusNetworkingLounge = (e, id) => {
        let req = {
            loungeId: id.toString(),
            status: (e.target.checked ? 1 : 0),
        }
        _Api(Url.STATUS_NETWORKING_LOUNGE.method, Url.STATUS_NETWORKING_LOUNGE.url, '', `?loungeId=${req.loungeId}&status=${req.status}`)
            .then(resp => {
                
                notify('success', resp.responseData.message)
                this.setState({ visible: false })
                this.getNetworkingLoungeList(false)
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
            })

    }

    /**
     * end network
     */



    onPressBtn = (page) => {
        this.setState({ page, snNo: page - 1 , networkingLoungeData:[]}, () => {
            this.getNetworkingLoungeList()
        })

    }

    render() {
        const { isLoader, networkingLoungeData, loading, visible, } = this.state

        return (
            <>

                <div className="header-container ">
                                    <div class="header-text d-flex w-100 justify-content-betweenmb-4 mt-3">
                                        <div class="d-flex flex-grow-1 align-items-center">
                                            <h4>Networking Lounge</h4>
                                        </div>
                                        <div class="">
                                        <Link to={`/seminardetails/${this.props.match.params.exId}/networking/add-networking-lounge`} className="btn btn-red">Add New Lounge</Link>
                                        </div>
                                    </div>
                </div>
                  
                    <div className="table-responsive">
                        <table className="table table-bordered rounded-table">
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>Networking Lounge</th>
                                    <th>Start Date & Time</th>
                                    <th>End Date & Time</th>
                                    <th>Max Entry</th>
                                    <th>Created Date</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoader &&<NetworkingLoungeLoader/>}
                                {
                                    networkingLoungeData?.map((item, index) => (
                                        <tr key={item._id}>
                                            <td><span className="circle-serial">{(this.state.snNo * 10) + index + 1}</span></td>
                                            <td><Link to={`/networking-details/${item._id}`}>{item.loungeName}</Link></td>
                                            <td>
                                                <p><p>{getTimeFormate(item?.startDate)}</p>  <p>{moment(item?.startTime).format("LT")}</p> <p>{moment(item?.startDate).format('dddd')}</p></p>
                                            </td>
                                            <td>
                                                <p><p>{getTimeFormate(item?.endDate)}</p>  <p>{moment(item?.endTime).format("LT")}</p> <p>{moment(item?.endDate).format('dddd')}</p></p>
                                            </td>
                                            <td>{item?.numberofAttendees}</td>
                                            <td>{getTimeFormate(item?.created)}</td>
                                            <td>
                                                <p className="toggle-parent ">
                                                    <label className="switch">
                                                        <input type="checkbox"
                                                            checked={item?.status === 1 ? true : false}
                                                            onChange={(e) => this.statusNetworkingLounge(e, item?._id)}
                                                        />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </p>
                                            </td>
                                            {/* {onClick={() => this.this.history.push("/")} */}
                                            <td className="action_group">
                                            <Link to={`/seminardetails/${this.props.match.params.exId}/networking/editnetworkinglounge/${item?._id}`}> <p><p className="circle-serial" ><Pencil /></p></p></Link>                                           
                                                <p onClick={() => this.showDeleteConfirm(item?._id)}><p className="circle-serial"><Delete /></p></p>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                       
                        <div>
                        {!isLoader && networkingLoungeData.length == 0 &&
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}

                        </div> 
                            {
                                networkingLoungeData && networkingLoungeData.length != 0 &&
                                <Pagination
                                    totalPage={Math.ceil(this.state.totalCount / 10)}
                                    currentPage={this.state.page}
                                    onPressBtn={this.onPressBtn}
                                />
                            }
                      

                    </div>


                   


                    {/* <div className="d-flex justify-content-between pt-4">
                                <Link to="/seminardetails" className="btn btn-secondary">Previous</Link>
                                <Link to="/business" className="btn btn-red">Next</Link>
                            </div> */}




                <Modal show={visible} onHide={this.handleCancel} className="cm_order_view dlt_item">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <img src={require('../../assets/img/Delete-Email.png')} /> */}
                        <br />
                        <br />
                        <p>Are you sure you want to delete this ?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="footer_btn_wrapper btn_nospace">
                            <Button variant="warning" loading={loading} onClick={() => this.deleteNetworkingLounge(this.state.selectedId)} >
                                Yes
                        </Button>
                            <Button variant="dark" onClick={this.handleCancel}>
                                No
                        </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}


export default NetworkLounge