import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { CategoryManagement } from '../../../components/common/SvgIcon';
import { Select, DatePicker, TimePicker, } from 'antd';
import RichTextEditor from 'react-rte';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import moment from "moment"
// components
import {

    MyAlert,
    MySpinner,
    AdLoader
} from "../../../components/index"

const { Option } = Select;
const userTypes = ['All', 'Candidate Ticket', 'Operator Showcase', 'Agency Showcase'];


class EditNotification extends Component {
    constructor() {
        super();
        this.state = {
            value: RichTextEditor.createEmptyValue(),
            isChecked: false,
            dateTime: '',
            userType: [],
            isLoading:false,
        }
    }

    componentDidMount() {
        if (this.props.match.params.id)
            this.getDetail()
    }

    getDetail = () => {
        let req = {
            messageId: this.props.match.params.id
        }
        _Api(Url.AdminBroadcastGetDetail.method, Url.AdminBroadcastGetDetail.url, req)
            .then(resp => {
                const { message, msgBroadcastTimestamp, userType } = resp.responseData.data
                console.log("kjdhhgfdv", resp.responseData.data)
                this.setState({
                    dateTime:  moment(new Date(msgBroadcastTimestamp)) ,
                    userType,
                    value: RichTextEditor.createValueFromString(message, 'html')
                })
            }).catch(err => { })
    }

    onChange = (dateString) => {
        console.log('Formatted Selected Time: ', dateString);
    }

    onOk = (value) => {
        console.log('onOk: ', value);
    }


    onChecked = () => {
        this.setState({ isChecked: !this.state.isChecked })
    }

    onListChange = (list) => {
        console.log("abcdef", list)
        this.setState({userType: list})

    }

    onChange = (value, dateString) => {
        this.setState({ value });
        if (this.props.onChange) {
            this.props.onChange(
                value.toString('html')
            );
        }
    }


    onChangeDate = (date) => {
        console.log("datetime", date.valueOf())
        this.setState({ dateTime: date })

    }





    onSubmit = () => {
        let req = {
            msgBroadcastDateTime: String(this.state.dateTime.valueOf()),
            isNow: !this.state.isChecked ? '1' : '2',
            userType: String(this.state.userType),
            message: this.state.value.toString('html'),
        }

        let method
        let url

        if (this.props.match.params.id) {
            method = Url.AdminBroadcastEdit.method
            url = Url.AdminBroadcastEdit.url

            req.messageId = this.props.match.params.id
        }
        else {
            method = Url.AdminBroadcast.method
            url = Url.AdminBroadcast.url
        }
        this.setState({isLoading:true})

        _Api(method, url, req)
            .then(resp => {
                this.setState({isLoading:false})
                this.props.history.goBack()

            }).catch(err => {
                this.setState({isLoading:false})

            })

    }



    render() {
        console.log("abbbbbbbbbbbb", this.state.dateTime);

        return (
            <>
                <div className="dash_body_container">
                    <div className="body_container">
                        <div className="header-container">
                            <div className="d-flex flex-grow-1 align-items-center">
                                <p className="header-icon"><CategoryManagement /></p>
                                <h4>Notification</h4>
                            </div>
                            <Link to={'/notification-list'} > <button className="btn btn-light"><i class="fa fa-arrow-left" aria-hidden="true"></i>Back</button></Link>
                        </div>
                        <div className="rounded-body-form">
                            <form className="rounded-textbox-form">
                                <div className="form-group">
                                    <label>User Type</label>
                                    <Select
                                        mode="multiple"
                                        className="cm_multiselect cmslect_multicheck sendNott-cstm"
                                        placeholder="Please select"
                                        style={{ width: '100%' }}
                                        defaultValue={this.state.userType}
                                        value={this.state.userType}
                                        
                                        onChange={this.onListChange}
                                    >
                                        {userTypes.map((user, index) => (
                                            <Option value={index+1} key={user}>{user}</Option>
                                        ))}

                                    </Select>
                                </div>
                                <div className="form-group">
                                    <label>Message </label>
                                    <RichTextEditor value={this.state.value} onChange={this.onChange} />
                                </div>
                                <div className="form-group csmt-rad-btn">
                                    <label>Message Broadcast Time</label>
                                    <div className="assign-checkboc-parent">
                                        <div>
                                            <label className="cm_container">
                                                Now
                                                <input onChange={this.onChecked} type="radio" checked={!this.state.isChecked} name="msgBrdTime" />
                                                <span className="radio-checkmark"></span>
                                            </label>
                                        </div>
                                        <div>
                                            <label className="cm_container">
                                                Date Time
                                                <input onChange={this.onChecked} type="radio" checked={this.state.isChecked} name="msgBrdTimea" />
                                                <span className="radio-checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {this.state.isChecked && <div className="form-group">
                                    <label>Date and Time</label>
                                    <div className="form-row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <DatePicker
                                                    showTime    
                                                    value={this.state.dateTime}                                                
                                                    onChange={this.onChangeDate}
                                                    onOk={this.onOk}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                {/* <TimePicker /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                
                                <div className="form-group text-center">
                                    <button disabled={this.state.isLoading} onClick={this.onSubmit} type="button" className="btn btn-red">Submit
                                    &nbsp;
                                    {   this.state.isLoading && <MySpinner />}
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </>
        );
    }
}

export default EditNotification;
