import React, { Component } from 'react';
import { Hert, Brands, Pencil, Delete, UploadVideo } from '../../../SvgIcon';
import { Link } from "react-router-dom";
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';
import Pagination from 'react-js-pagination';
import { Select, TreeSelect } from 'antd';
import InlineError from "../../../components/InlineError";
import { _Api, _ApiMultipart } from "../../../services/Api";
import Url from "../../../services/BaseUrl";
import { notify } from "../../../components/Toaster";
import Loader from "../../../components/Loader";
import CommingSoonLayer from "../../../components/common/CommingSoonLayer";
import Croper from "../../../components/Croper";

const { Option } = Select;
const { SHOW_PARENT, SHOW_CHILD, SHOW_ALL } = TreeSelect;
// import BackBtn from '../../../components/common/Button'

function handleChange(value) {
    console.log(`selected ${value}`);
}

class AddCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryTitle: "",
            categoryDesc: "",
            categoryType: 0,

            categoryTitleError: "",
            categoryDescError: "",
            categoryTypeError: "",
            categoryImgPrev: require('../../../assets/images/placeholder-img.png'),
            categoryImg: "",
            isLoader: false,
            isCommingSoon: false,
            placeOfworkList: [],
            placeOfWorkData: [],
            placeOfWorkError: '',
            placeofWorkId: [],
            parentCategoryId: this.props.match.params.catogoryId && this.props.match.params.catogoryId !== "" ? this.props.match.params.catogoryId : "",
            visibleVal: false
        }
        this.onChanged = this.onChanged.bind(this);
        this.addCategory = this.addCategory.bind(this);
    }

    componentDidMount() {
        this.getCategoryDetails()
        this.getCategory()
    }

    getCategoryDetails() {
        let self = this;
        if (this.props.match.params.catogoryId && this.props.match.params.catogoryId !== "") {
            let params = {
                categoryId: this.props.match.params.catogoryId,
            }
            _Api(Url.CATEGORY_DETAILS.method, Url.CATEGORY_DETAILS.url, params)
                .then(resp => {
                    //console.log('responseData', resp.responseData);
                    self.setState({
                        //categoryId: resp.responseData.result._id,
                        //categoryTitle: resp.responseData.result.categoryTitle,
                        //categoryDesc: resp.responseData.result.categoryDesc,
                        categoryType: resp.responseData.result.categoryType,
                        //categoryImgPrev: resp.responseData.result.imageUrl,
                        // list:resp.responseData.result,
                        // totalCount:resp.responseData.totalCount
                    })
                }).catch(err => {
                    this.setState({ isLoader: false })
                    if (err.error)
                        notify("err", err.error.responseMessage)
                   // else if (err)
                //     // notify("err", err.message)
                    this.setState({ isLoader: false })
                })
        }
    }

    addCategory() {
        let self = this;
        let { placeofWorkId } = this.state;
        this.setState({
            categoryTitleError: "",
            categoryDescError: "",
            categoryTypeError: "",
            placeOfWorkError: ""
        })
        if (this.state.categoryTitle === '') {
            this.setState({ categoryTitleError: '*Please enter category title.' })
        }
        // else if (this.state.categoryDesc === ''){
        //     this.setState({ categoryDescError: '*Please enter description.' })
        // }
        else if (this.state.categoryType === 0) {
            this.setState({ categoryTypeError: '*Please enter category type.' })
        } else if ((this.state.categoryType === 2 || this.state.categoryType === "2") && placeofWorkId.length === 0) {
            this.setState({ placeOfWorkError: '*Please select any job title.' })
        } else {
            this.setState({ isLoader: true })
            let data = new FormData();
            data.append('categoryTitle', this.state.categoryTitle);
            data.append('categoryDesc', this.state.categoryDesc);
            data.append('categoryType', this.state.categoryType);
            data.append('image', this.state.categoryImg);
            if (this.state.parentCategoryId && this.state.parentCategoryId !== "") {
                data.append('parentCategoryId', this.state.parentCategoryId);
            }
            if (this.state.categoryType === 2 || this.state.categoryType === "2") {
                data.append('placeofWorkId', this.state.placeofWorkId);
            }

            _ApiMultipart(Url.CATEGORY_ADD.method, Url.CATEGORY_ADD.url, data)
                .then(resp => {
                    this.setState({ isLoader: false })
                    //console.log("resp",resp);
                    self.props.history.push(this.props.match.params.catogoryId && this.props.match.params.catogoryId != "" ? "/category/" + this.props.match.params.catogoryId : "/category")
                }).catch(err => {
                    this.setState({ isLoader: false })
                    if (err.error) {
                        if (err.error.errorCode == 5) {
                            self.setState({
                                categoryTitleError: err.error.responseMessage
                            })
                        } else {
                            notify("err", err.error.responseMessage)
                        }
                    } else if (err) {
                        // notify("err", err.message)
                    }
                })
        }

    }

    selectOnChange = (type, e) => {
        let self = this;
        this.setState({
            [type]: e,
        }, function () {

        })
    }

    onChanged(e) {
        console.log("e", e);
        let self = this;
        let keyName = e.target.name;
        let KeyValue = e.target.value;
        this.setState({
            categoryTitleError: "",
            categoryDescError: "",
            categoryTypeError: "",
            placeOfWorkError: ""
        })
        this.setState({ [e.target.name]: e.target.value }, function () {
            // if(keyName==='searchKey'){
            //     if(KeyValue.length>=3){
            //         self.getCategory();
            //     }
            // }
        });
        if (e.target.name === 'categoryImg') {
            // console.log("e",e);
            let reader = new FileReader();
            let file = e.target.files[0];

            reader.onloadend = () => {
                this.setState({
                    categoryImgPrev: reader.result
                });
            }
            reader.readAsDataURL(file)

            this.setState({
                categoryImgPrev: file
            })
            this.setState({
                categoryImg: e.target.files[0]
            });
        }
    }

    getCategory() {
        let params = {
            categoryType: "1",
        }
        _Api(Url.GET_CATEGORY_LIST.method, Url.GET_CATEGORY_LIST.url, params)
            .then(resp => {
                //console.log('responseData', resp.responseData);
                this.setState({
                    placeOfworkList: resp.responseData.result,
                    totalCount: resp.responseData.totalCount
                })
                let placeOfWorkData = resp.responseData.result.map((item1, index1) => {
                    let childData = item1.subCategory.map((data, index2) => {
                        let ddd = {
                            title: data.categoryTitle,
                            value: data._id,
                            children: []
                        }
                        return ddd
                    })
                    //console.log("childData",childData)
                    let dd = {
                        selectable: childData.length > 0 ? false : true,
                        title: childData.length > 0 ? <b style={{ color: '#000' }}>{item1.categoryTitle}</b> : item1.categoryTitle,
                        value: item1._id,
                        checkable: childData.length > 0 ? false : true,
                        children: childData
                    }
                    return dd
                })
                // placeOfWorkData.push({
                //     title: 'Other',
                //     value: 'other',
                //     children:[]
                // })
                this.setState({
                    placeOfWorkData
                })
            }).catch(err => {
                this.setState({ isLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
                this.setState({ isLoader: false })
            })
    }

    onChangePlacework = value => {
        this.setState({
            placeofWorkId: value,
        })
    }

    handleCroper = () => {
        this.setState({
            visibleVal: true
        })
    }

    finalCropedImage = (categoryImgPrev, categoryImg, visibleVal) => {
        this.setState({
            visibleVal,
            categoryImgPrev: categoryImgPrev,
            categoryImg: categoryImg

        })     
    }

    render() {
        // console.log("this.state###", this.state);
        return (
            <>
                <div className="dash_body_container">
                    <Header />
                    <Sidebar />
                    {this.state.isLoader && <Loader />}
                    {this.state.isCommingSoon && <CommingSoonLayer />}
                    <div className="body_container">

                        <div className="header-text  mb-4 mt-3">

                            <div className="d-flex align-items-center">
                                <p className="header-icon"><Brands /></p>
                                <h4>Add {this.props.match.params.catogoryId && this.props.match.params.catogoryId != "" ? "Sub-Category" : "Category"}</h4>
                            </div>
                            {/*<Link to="/category"><button className="btn btn-light"><i className="fa fa-arrow-left" aria-hidden="true"></i>Back</button></Link>*/}
                        </div>
                        <div className="rounded-body py-5">
                            <div className="cm-form-wrapper rounded-textbox-form add-manag px-0">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <level>Category Title</level>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter category title" name="categoryTitle" value={this.state.categoryTitle}
                                                onChange={this.onChanged}
                                            />
                                            <InlineError
                                                message={this.state.categoryTitleError}
                                            />
                                        </div>
                                        <div className="form-group cm_text-area">
                                            <level>Description</level>
                                            <textarea rows="4"
                                                className="form-control hi"
                                                placeholder="Enter description"
                                                 value={this.state.categoryDesc}
                                                onChange={this.onChanged}
                                                name="categoryDesc"
                                            />
                                            <InlineError
                                                message={this.state.categoryDescError}
                                            />
                                        </div>

                                    </div>

                                    <div className="col-md-6">
                                        {this.props.match.params.catogoryId && this.props.match.params.catogoryId != "" ? "" :
                                            <div className="form-group">
                                                <level>Category Type</level>
                                                <Select placeholder="Select" name="categoryType"
                                                    onChange={(e) => this.selectOnChange("categoryType", e)}>
                                                    <Option value="1">Place of work</Option>
                                                    <Option value="2">job title</Option>
                                                    <Option value="3">Services</Option>
                                                    <Option value="4">Product</Option>
                                                    {/*<Option value="5">Budget</Option>*/}
                                                    {/*<Option value="6">6=Number of people I work</Option>*/}
                                                </Select>
                                                <InlineError
                                                    message={this.state.categoryTypeError}
                                                />
                                            </div>}
                                        {this.state.categoryType === 2 || this.state.categoryType === "2" ?
                                            <div className="form-group placework">
                                                <label>Place of work</label>
                                                <TreeSelect
                                                    style={{ width: '100%' }}
                                                    dropdownClassName="new_single_check"
                                                    value={this.state.placeofWorkId}
                                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    treeData={this.state.placeOfWorkData}
                                                    placeholder="Please select work"
                                                    //treeDefault
                                                    multiple={false}
                                                    treeCheckable={true}
                                                    dropdownClassName={"new_multi_check"}
                                                    onChange={this.onChangePlacework}
                                                    className="cm_multiselect"
                                                    showArrow
                                                    showCheckedStrategy={SHOW_ALL}
                                                //treeCheckStrictly={true}
                                                //dropdownMatchSelectWidth={50}
                                                />

                                                <InlineError
                                                    message={this.state.placeOfWorkError}
                                                />

                                            </div>
                                            : ""}
                                        {this.props.match.params.catogoryId && this.props.match.params.catogoryId != "" ? "" :
                                            this.state.categoryType === 4 || this.state.categoryType === "4" ?
                                                <div className="form-group custom-upload-file">
                                                    <ul className="list-inline mt-3 pt-1">
                                                        <li className="list-inline-item">
                                                            <div className="upload-inner">
                                                                <span className="upload-icon">
                                                                    {/* <UploadVideo/> */}
                                                                    <img src={this.state.categoryImgPrev}
                                                                        alt="category image"
                                                                         onClick={(e) => this.categoryImg.click()}
                                                                        // onClick={this.handleCroper}
                                                                    />


                                                                </span> <input type="file" className="d-block" placeholder=""
                                                                    name="categoryImg"
                                                                    ref={(ref) => this.categoryImg = ref}
                                                                    onChange={this.onChanged} />
                                                                <input type="file" className="d-block" placeholder=""
                                                                    name="categoryImg"
                                                                    ref={(ref) => this.categoryImg = ref}
                                                                    onChange={this.onChanged} />

                                                            </div>
                                                            {/*<level>Maximum Size : 128 gb</level>*/}
                                                        </li>
                                                        {/*<li className="list-inline-item">*/}
                                                        {/*    <button className="btn add-btn h-100" type="button">+</button>*/}
                                                        {/*</li>*/}
                                                    </ul>
                                                </div>
                                                : ""}

                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-sm-12 tab-add-btn-group">
                                        <Link to={this.props.match.params.catogoryId && this.props.match.params.catogoryId != "" ? "/category/" + this.props.match.params.catogoryId : "/category"} className="btn btn-secondary" type='button'>Cancel</Link>
                                        <button className="btn btn-primary" type='button' onClick={this.addCategory.bind(this)}>Save</button>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                    {/* {this.state.visibleVal ?
                        <Croper aspect={16 / 9}
                            // onImageCrop={img => this.setState({ media: img })}

                            finalCropedImage={(categoryImgPrev, categoryImg, visibleVal) => this.finalCropedImage(categoryImgPrev, categoryImg, visibleVal)} visibleVal={this.state.visibleVal} />
                        : ""} */}
                </div>
            </>
        );
    }
}
export default AddCategory;