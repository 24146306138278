import React, { Component } from 'react';
import { Chat, UploadVideo, NoMediaICon, Delete } from '../../SvgIcon';
import AddBrochures from "../../screens/admin/exhibiton_management/popups/Brochures"

import { _Api } from '../../services/Api';
import Url from '../../services/BaseUrl';

import { Modal, Button, Space } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { notify } from '../../components/Toaster';
import { Link } from "react-router-dom";

const { confirm } = Modal;

class Brochures extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            getMedia: [],
            updateMedia: [],
            brochure: false,
            brochureData: []


        }
    }



    getFile = () => {
        this.inputElement.click();
    }

    componentDidMount() {
        this.getBrochure()
    }


    // 
    getBrochure = () => {
        _Api(Url.GET_BROCHURE.method, Url.GET_BROCHURE.url, '', `?brandId=${this.props.match.params.brandId}`)
            .then(resp => {
                
                this.setState({
                    brochureData: resp.responseData.result
                })

            }).catch(err => {

            })
    }



    handleImageUpload = (event) => {
        let reader = new FileReader();
        let file = event.target.files[0];
        let { valiDate } = this.state;
        reader.addEventListener("load", () => {
            this.setState({ profilePicPreview: reader.result }, function () {
               
                let oldArr = this.state.updateMedia

                oldArr.push(reader.result)
                this.setState({
                    updateMedia: oldArr
                })
            })
        }, false);
        if (file) {
            reader.readAsDataURL(file)
        }
        if (file) {
            if (file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png' || file.type === 'image/svg+xml') {
              
                if (file.size > 2000000) {
                    valiDate = false;
                    this.setState({
                        media: event.target.files[0],
                        profilePicErrorMsg: 'Profile pic size should be less than 5Mb.'
                    }, () => {
                        this.props.getMedia({ media: this.state.media, mediaPreview: URL.createObjectURL(file) })
                    });
                } else {
                    this.setState({
                        media: event.target.files[0],
                        mediaError: ''
                    }, () => {
                        this.props.getMedia({ media: file, mediaPreview: URL.createObjectURL(file) })
                    });

                }
            } else {
                valiDate = false;
                this.setState({ mediaError: 'File type not supported.' })
            }
        }
    }



    handlePopup = () => {
        this.setState({ brochure: !this.state.brochure })
        this.getBrochure()
    }


    // delete modal
    showDeleteConfirm = (id) => {
        confirm({
            title: 'Are you sure you want to delete this?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            onOk: () => {
                this.deleteBrochure(id)
            },
            onCancel: () => {
                this.handleCancel()
            },
        });
    }

    deleteBrochure = (id) => {
        let req = {
            isDeleted: 0,
            brochureId: id,
        }
        _Api(Url.DELETE_BROCHURE.method, Url.DELETE_BROCHURE.url, '', `?brochureId=${req.brochureId}`)
            .then(resp => {
                // notify('success', resp.responseData.message)
                this.setState({ visible: false })
                this.getBrochure()
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
            })
    }

    handleCancel = () => {
        this.setState({ visible: false });
    };

    onDownloadBrochores = (item) => {
        // console.log("pdiuysdghjk", item)

//   fetch(item.media)
//   .then(resp => resp.blob())
//   .then(blob => {
//     const url = window.URL.createObjectURL(blob);
//     const a = document.createElement('a');
//     a.style.display = 'none';
//     a.href = url;
//     // the filename you want
//     a.download = 'todo-1.json';
//     document.body.appendChild(a);
//     a.click();
//     window.URL.revokeObjectURL(url);
//     alert('your file has downloaded!'); // or you know, something with better UX...
//   })
//   .catch(() => alert('oh no!'));
       
        let req = {
            brochureId: item?._id,
            brandId:item?.brandId
        }
        console.log("sacghdjk", item)
        window.open(item.media, "_blank");
        _Api(Url.COUNT_BROUCHERS.method, Url.COUNT_BROUCHERS.url, req)
            .then(resp => {
               
                

            }).catch(err => { })
    }


    render() {
        let isFromVeiwProfile = this.props.isFromVeiwProfile;
        return (
            <>
                <div className="d-flex mt-2">
                    <div className="row brochure-parent w-100 text-center">
                        {this.state?.brochureData?.length == 0 && <div className="w-100 text-center row nodata m-auto">
                            <div className="m-auto">
                                <NoMediaICon />
                                
                                <h2>No Data Found</h2>
                            </div>

                        </div>}

                        {
                            this.state.brochureData?.map((item, index) => {
                                return (
                                    <div className="four-div">

                                        <div className="community-card brochure">
                                            {!isFromVeiwProfile && <div className="media-delete-icon" onClick={() => this.showDeleteConfirm(item?._id)}>
                                                <Delete />
                                            </div>
                                            }
                                            <div className="row">
                                                <div className=" imgparent pr-0">
                                                    <div className="img-wrapper">
                                                        <img src={item?.coverImage ? item?.coverImage : require('../../assets/images/placeholder.png')} className="img-fluid" />
                                                        <label className="upload-photo-wrapper">
                                                            <a onClick={() => this.onDownloadBrochores(item)} > <span> <p className="m-0"><i class="fa fa-download" aria-hidden="true"></i></p></span> </a>

                                                        </label>
                                                    </div>
                                                </div>
                                                <div className=" textparent no-gutter d-flex align-items-start flex-column">

                                                    <p>{item?.name}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                )
                            })
                        }

                        <div>
                            {/* <input type="file" onChange={this.handlePopup} id="my_file" ref={my_file => this.inputElement = my_file} /> */}
                        </div>
                    </div>



                    {/* 
                <button type="button btn btn-primary h-100 ml-3" id="get_file" value="Grab file" ref="get_file" onClick={this.handleAddPopup} >+</button> */}
                    {/* <button type="button btn btn-primary h-100 ml-3" id="get_file" value="Grab file" ref="get_file" onClick={this.handlePopup} >+</button> */}
                    {
                        this.state.brochure ?
                            <AddBrochures
                                {...this.props}
                                text='Close Me'
                                // getProductDetails={this.getProductDetails}
                                closePopup={this.handlePopup}
                            />
                            : null
                    }
                </div >
                <div className="row">
                    <div className="col-sm-12 text-center">
                        {!isFromVeiwProfile && <button className="btn btn-red addbtn addblue" onClick={this.handlePopup} >+ Add</button>}
                        {isFromVeiwProfile && this.state.brochure.length > 0 && <Link to={`/view-document/${this.props.match.params.brandId}`} className="btn btn-red addbtn">View All Brochures</Link>}
                    </div>

                </div>
            </>
        );
    }
}
export default Brochures;
