
import React, { Component } from 'react';
import ReactPlayer from 'react-player';


import { Link } from "react-router-dom";
import { DatePicker, TimePicker, Empty } from 'antd';
import moment from 'moment-timezone';
import { _Api } from '../../services/Api';
import Url from '../../services/BaseUrl';
import { notify } from '../../components/Toaster';
// import { Modal, Button } from 'react-bootstrap';
import { Delete, NoMediaICon } from '../../SvgIcon'
import { notification } from 'antd';
import { MediaSelector } from "../../components/index"

import YouTube from 'react-youtube';

import { Player, BigPlayButton } from 'video-react';

import { Modal, Button, Space } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;





export default class Media extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            getMedia: [],
            updateMedia: [],
            showModal: false,
            visible: false,
            confirmLoading: false,
            selectedItem: '',
            selectedIndex: 0,

            isFlag: false

        }
    }



    getFile = () => {
        this.inputElement.click();
    }

    componentDidMount() {
        setTimeout(() => this.setState({ isFlag: true }), 5000)
    }



    handleImageUpload = (event) => {
        let reader = new FileReader();
        let file = event.target.files[0];
        let { valiDate } = this.state;
        reader.addEventListener("load", () => {
            this.setState({ profilePicPreview: reader.result }, function () {
               
                let oldArr = this.state.updateMedia

                oldArr.push(reader.result)
                this.setState({
                    updateMedia
                        : oldArr
                })
            })
        }, false);
        if (file) {
            reader.readAsDataURL(file)
        }
        if (file) {
            if (file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png' || file.type === 'image/svg+xml') {
                console.log("media2", event.target.files[0], file.size)
                if (file.size > 2000000) {
                    valiDate = false;
                    this.setState({
                        media: event.target.files[0],
                        profilePicErrorMsg: 'Profile pic size should be less than 5Mb.'
                    }, () => {

                    });
                } else {
                    this.setState({
                        media: event.target.files[0],
                        mediaError: ''
                    }, () => {
                        this.props.getMedia({ media: file, mediaPreview: URL.createObjectURL(file) })
                    });

                }
            } else {
                valiDate = false;
                this.setState({ media: 'File type not supported.' })
            }
        }
    }



    handleMedia = (data) => {
        console.log("kjhgcjdsk", data)
        this.props.getMedia(data)
        this.handleAddPopup()

    }


    handleAddPopup = () => {
        this.setState({ showModal: !this.state.showModal })
    }



    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        this.setState({
            ModalText: 'The modal will be closed after two seconds',
            confirmLoading: true,
        });
        setTimeout(() => {
            this.setState({
                visible: false,
                confirmLoading: false,
            });
        }, 2000);
    };

    handleCancel = () => {
        console.log('Clicked cancel button');
        this.setState({
            visible: false,
        });
    };






    onClickItem = (item, index) => {
        console.log("kdsjhghfjk", item)
        let { media, brandUrl, exhibitionUrl, conferenceUrl } = item

        this.setState({ selectedIndex: index, selectedItem: media[0] ? media[0] : brandUrl[0] ? brandUrl[0] : conferenceUrl[0] ? conferenceUrl[0] : exhibitionUrl[0] ? exhibitionUrl[0] : '', visible: true })



    }





    // delete modal
    showDeleteConfirm = (id) => {
        confirm({
            title: 'Are you sure you want to delete this?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            onOk: () => {
                this.deleteMedia(id)
            },
            onCancel: () => {
                this.handleCancel()
            },
        });
    }

    deleteMedia = (id) => {
        let req = {
            isDeleted: 0,
            galleryId: id,

        }
        _Api(Url.DELETE_MEDIA.method, Url.DELETE_MEDIA.url, '', `?galleryId=${req.galleryId}&isDeleted=${req.isDeleted}`)
            .then(resp => {
                // notify('success', resp.responseData.message)
                this.setState({ visible: false })

                this.props.onDeleteMedia()

            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
            })
    }

    handleCancel = () => {
        this.setState({ visible: false, selectedItem: '' });
    };


    goNext = (index, mediaData) => {
        console.log("kjhagcfvghbaj", index, mediaData.length)
        if (index + 1 < mediaData.length) {
            let { media, brandUrl } = mediaData[index + 1]

            this.setState({ selectedIndex: index + 1, selectedItem: media[0] ? media[0] : brandUrl[0], visible: true })
        }

    }

    goBack = (index, mediaData) => {
        if (index > 0) {
            let { media, brandUrl } = mediaData[index - 1]

            this.setState({ selectedIndex: index - 1, selectedItem: media[0] ? media[0] : brandUrl[0], visible: true })
        }

    }


    goToAllMedia = () => {
        console.log("pocikjhgdvhcjds")
    }


    render() {

        const { mediaData } = this.props
        const { visible, confirmLoading, ModalText, selectedItem, isFlag } = this.state;
        let isFromVeiwProfile = this.props.isFromVeiwProfile;




        return (
            <>
                <div className="d-flex mt-2">

                    <div className="row w-100 text-center m-auto">
                    
                        {
                            mediaData?.map((item, index) => {

                                console.log("cnksajncksjan", item)

                                let { media, brandUrl, conferenceUrl, exhibitionUrl } = item
                                media = media[0]
                                if (!this.props.addHide && index < 3)
                                    return (
                                        <div className="col-md-4">
                                            {
                                                media ? media?.urlType == 'I' ?
                                                    <div key={item._id} className="card video-card">                                                       
                                                        <img
                                                            onClick={() => this.onClickItem(item, index)}
                                                            src={media?.url}
                                                        />
                                                    </div>
                                                    :
                                                    <div className="card video-card">

                                                       
                                                        <img
                                                            onClick={() => this.onClickItem(item, index)}
                                                            src={media?.thumbUrl}
                                                        />
                                                    </div>
                                                    :
                                                    <div className="card video-card">
                                                        {/* <ReactPlayer url={brandUrl[0]} /> */}
                                                        
                                                        <div className="video-parent " >
                                                            {/* <iframe width="560"
                                                     height="315" 
                                                     src={brandUrl[0]} 
                                                     frameborder="0" allow="accelerometer; 
                                                     autoplay; 
                                                     encrypted-media; 
                                                     gyroscope; picture-in-picture"
                                                      allowfullscreen></iframe> */}

                                                            <ReactPlayer width="442" height="390" url={brandUrl[0] ? brandUrl[0] : conferenceUrl[0] ? conferenceUrl[0] : exhibitionUrl[0] ? exhibitionUrl[0] : ''} />

                                                            {/* <iframe id="player" type="text/html" width="640" height="390"
                                                            src={brandUrl[0]}
                                                            frameborder="0"></iframe> */}

                                                            {/* <YouTube  opts={{
                                                            height: '400',
                                                            width: '640',
                                                            playerVars: {
                                                               // https://developers.google.com/youtube/player_parameters,
                                                               ...brandUrl[0],
                                                                autoplay: 0,
                                                            },
                                                        }} onReady={this._onReady} className="video-player" containerClassName="video-player-parent" /> */}

                                                        </div>
                                                        <div className="layerdiv" onClick={() => this.onClickItem(item, index)} ></div>


                                                    </div>
                                                // <ul className="list-inline mb-0">

                                                //     <li className="list-inline">
                                                //         <Player>
                                                //             <source src={brandUrl[0]} />
                                                //         </Player>
                                                //         {/* <ReactPlayer url={brandUrl[0]} /> */}
                                                //     </li>
                                                // </ul>
                                            }
                                        </div>
                                    )
                                else if (this.props.addHide)
                                    return (
                                        <div key={item._id} className="col-md-4">
                                            {
                                                media ? media?.urlType == 'I' ?
                                                    <div className="card video-card">
                                                    
                                                        <img
                                                            onClick={() => this.onClickItem(item, index)}
                                                            src={media?.url}
                                                        />
                                                    </div>
                                                    :
                                                    <div className="card video-card">
                                                      
                                                        <img
                                                            onClick={() => this.onClickItem(item, index)}
                                                            src={media?.thumbUrl}
                                                        />
                                                    </div>
                                                    :
                                                    <div className="card video-card">                                                       
                                                     
                                                        <div className="video-parent " >
                                                       

                                                            <ReactPlayer width="442" height="390" url={brandUrl[0] ? brandUrl[0] : conferenceUrl[0] ? conferenceUrl[0] : exhibitionUrl[0] ? exhibitionUrl[0] : ''} />

                                   
                                                         

                                                        </div>
                                                        <div className="layerdiv" onClick={() => this.onClickItem(item, index)} ></div>


                                                    </div>
                      
                                            }
                                        </div>
                                    )
                            })



                        }

                    </div>




                    {/* {!this.props.addHide && <button type="button btn btn-primary h-100 ml-3" id="get_file" value="Grab file" ref="get_file" onClick={this.handleAddPopup} >+</button>} */}



                    <Modal
                        visible={visible}
                        onCancel={this.handleCancel}
                        footer={null}
                        centered
                        className="media-popup"
                    >
                        <div className="col-md-10 offset-md-1">
                            {this.state.selectedIndex != 0 && <div className="prev" onClick={() => this.goBack(this.state.selectedIndex, mediaData)}>
                                <i class="fa fa-arrow-circle-o-left" aria-hidden="true"></i>
                            </div>}
                            {

                                selectedItem?.urlType == 'I' ?
                                    <div className="card video-card">
                                        <img
                                            src={selectedItem?.url}
                                            className="img-fluid" />
                                    </div>
                                    :
                                    selectedItem?.urlType == 'V' ?
                                        <div className="card video-card">
                                            <Player>
                                                <source src={selectedItem?.url} />
                                                <BigPlayButton position="center" />
                                            </Player>
                                        </div>
                                        :
                                        <div className=" mb-0">

                                            <div className="card video-card">

                                                {/* <iframe width="560"
                                                     height="315" 
                                                     src={selectedItem} 
                                                     frameborder="0" allow="accelerometer; 
                                                     autoplay; 
                                                     encrypted-media; 
                                                     gyroscope; picture-in-picture"
                                                      allowfullscreen></iframe> */}

                                                {/* <iframe id="player" type="text/html" width="640" height="390"
                                                            src={selectedItem}
                                                            frameborder="0"></iframe> */}
                                                {/* <Player>
                                                <source src={selectedItem} />
                                                <BigPlayButton position="center" />
                                            </Player> */}
                                                <ReactPlayer url={selectedItem} />
                                            </div>
                                        </div>
                            }
                            {this.state.selectedIndex + 1 != mediaData?.length && <div className="next">
                                <i onClick={() => this.goNext(this.state.selectedIndex, mediaData)} class="fa fa-arrow-circle-o-right" aria-hidden="true"></i>
                            </div>}

                        </div>
                    </Modal>



                  

                </div >
                {/* {<div className="row">
                    <div className="col-sm-12 text-center">
                        {!this.props.isAdd && mediaData?.length != 0 && <Link to="/media" className="btn btn-red addbtn">View All Media</Link>}
                        {(!this.props.addHide && !isFromVeiwProfile) && <button onClick={this.handleAddPopup} type="button" className="btn btn-red addbtn addblue">+ Add</button>}
                    </div>
                </div>} */}
            </>
        )
    }

}

const videoLoop = [1, 2, 3]


























