/**
 * @About
 * 
 * 
 * 
 */
import promise from 'bluebird'
import {
    IS_LOADING_START,
    GET_USER_PROFILE_SUCCESS,
    IS_LOADING_STOP,
    LOG_OUT_SUCCESS,
    GET_USER_CART_LIST_SUCCESS,
    GET_USER_WISH_LIST_SUCCESS,
    SET_LOCAL_STATE,
    GET_NOTIFICATION_SUCCESS,

    GET_BRAND_DATA,
    GET_HOME_DATA,
    GET_RECENTSEARCH_DATA,
    GET_LOUNGE_DATA,
    GET_CONFERENCE_DATA,
    GET_BRANDDETAIL_SUCCESS,
    GET_EXHIBITION_ID_SUCCESS,
    SHOW_SUPPORT_CHAT,
    HIDE_SUPPORT_CHAT


} from './Type'

import { _Api } from '../../services/Api'
import Url from '../../services/BaseUrl'
import { notify } from '../../components/Toaster'

var navigator_info = window.navigator;
var screen_info = window.screen;
var uid = navigator_info.mimeTypes.length;
uid += navigator_info.userAgent.replace(/\D+/g, '');
uid += navigator_info.plugins.length;
uid += screen_info.height || '';
uid += screen_info.width || '';
uid += screen_info.pixelDepth || '';



export const showChat = req => dispatch => {
    dispatch({ type: 'SHOW_SUPPORT_CHAT' })
}
export const hideChat = req => dispatch => {
    dispatch({ type: 'HIDE_SUPPORT_CHAT' })
}

export const setCount = req => dispatch => {
    dispatch({ type: 'SET_COUNT', payload: { req } })
}

export const setCountSupport = req => dispatch => {
    dispatch({ type: 'SET_COUNT_SUPPORT', payload: { req } })
}



export const getExhibitionId = req => dispatch => {
    return new promise(function (resolve, reject) {
        try {
            _Api(Url.GET_EXHIBITION_ID.method, Url.GET_EXHIBITION_ID.url, '', `?uuId=${uid}`)
                .then(resp => {
                    console.log("dfgdhj", resp?.responseData?.exhibitionDetails)
                    localStorage.setItem("exhibition", resp?.responseData?.exhibitionDetails?._id)
                    dispatch({
                        type: GET_EXHIBITION_ID_SUCCESS,
                        payload: { exhibitionId: resp?.responseData?.exhibitionDetails?._id, eventType :resp?.responseData?.exhibitionDetails?.eventType,
                        
                            start_Date: resp?.responseData?.exhibitionDetails?.start_Date,
                            end_Date: resp?.responseData?.exhibitionDetails?.end_Date,
                            timezone: resp?.responseData?.exhibitionDetails?.timezone,
                        }
                    })
                    resolve(resp);
                }).catch((err) => {
                    reject(err);
                })
        } catch (error) {
            reject(error);
        }
    })
}




// action for get user profile
export const getBrandHome = req => dispatch => {
    try {
        let url = Url["GET_ALL_BRAND_DATA"].url, method = Url["GET_ALL_BRAND_DATA"].method

        _Api(method, url)
            .then(resp => {
                dispatch({ type: GET_BRAND_DATA, payload: resp.responseData })

            }).catch((err) => {
            })

    } catch (error) {


    }

}

export const getNetworkData = req => dispatch => {
    try {
        // let req = { exhibitionId: req }
        if (req) {
            _Api(Url.GET_NETWORKING_LIST.method, Url.GET_NETWORKING_LIST.url, '', `?exhibitionId=${req}`)
                .then(resp => {
                    dispatch({ type: GET_LOUNGE_DATA, payload: resp.responseData?.result?.data ? resp.responseData?.result?.data : [] })
                }).catch((err) => {
                })
        }
        else {
            _Api(Url.GET_EXHIBITION_ID.method, Url.GET_EXHIBITION_ID.url, '')
                .then(resp => {
                    _Api(Url.GET_NETWORKING_LIST.method, Url.GET_NETWORKING_LIST.url, '', `?exhibitionId=${resp?.responseData?.exhibitionDetails?._id}`)
                        .then(resp => {
                            dispatch({ type: GET_LOUNGE_DATA, payload: resp.responseData?.result?.data ? resp.responseData?.result?.data : [] })
                        }).catch((err) => {
                        })
                }).catch((err) => { })
        }
    } catch (error) { }

}



export const getConference = req => dispatch => {
    try {
        // let req = { exhibitionId: req }
        if (req) {
            _Api(Url.GET_CONFERENCE_WEB.method, Url.GET_CONFERENCE_WEB.url, '', `?exhibitionId=${req}`)
                .then(resp => {
                    dispatch({ type: GET_CONFERENCE_DATA, payload: resp.responseData.result ? resp.responseData.result : [] })
                }).catch((err) => {
                })
        }
        else {
            _Api(Url.GET_EXHIBITION_ID.method, Url.GET_EXHIBITION_ID.url, '')
                .then(resp => {
                    _Api(Url.GET_CONFERENCE_WEB.method, Url.GET_CONFERENCE_WEB.url, '', `?exhibitionId=${resp?.responseData?.exhibitionDetails?._id}`)
                        .then(resp => {
                            dispatch({ type: GET_CONFERENCE_DATA, payload: resp.responseData.result ? resp.responseData.result : [] })
                        }).catch((err) => { })

                }).catch((err) => { })

        }

    } catch (error) { }

}






// action for get user profile
export const getHome = req => dispatch => {
    try {

        if (req) {
            let reqq = { exhibitionId: req }
            _Api(Url.GET_USER_HOME.method, Url.GET_USER_HOME.url, reqq)
                .then(resp => {
                    dispatch({ type: GET_HOME_DATA, payload: resp })
                }).catch((err) => {
                })
        }
        else {

            _Api(Url.GET_EXHIBITION_ID.method, Url.GET_EXHIBITION_ID.url, '')
                .then(resp => {
                    _Api(Url.GET_USER_HOME.method, Url.GET_USER_HOME.url, { exhibitionId: resp?.responseData?.exhibitionDetails?._id })
                        .then(resp => {
                            dispatch({ type: GET_HOME_DATA, payload: resp })
                        }).catch((err) => { })

                }).catch((err) => { })

        }

    } catch (error) { }

    // try {
    //     _Api(Url.GET_USER_HOME.method, Url.GET_USER_HOME.url)
    //         .then(resp => {
    //             console.log("scjkldjhcfkdl", resp?.responseData)
    //             dispatch({ type: GET_HOME_DATA, payload: resp })

    //         }).catch((err) => {

    //         })

    // } catch (error) {


    // }

}










// action for get user profile
export const userProfile = req => dispatch => {

    try {


        dispatch({ type: IS_LOADING_START })

        _Api(Url.GET_USER_PROFILE.method, Url.GET_USER_PROFILE.url).then(resp => {
            console.log("resppp", resp)

            let payload = {
                profileInfo: resp.responseData.userProfile,

            }

            dispatch({ type: GET_USER_PROFILE_SUCCESS, payload })
            dispatch({ type: IS_LOADING_STOP })
        }).catch(err => {
            dispatch({ type: IS_LOADING_STOP })

            // if (err.error)
            //     notify("err", err.error.message)
            // else if (err)
            //     // notify("err", err.message)
        })

    } catch (error) {
        // notify("err", error.message)

    }

}


// action for get cart list
export const getCart = req => dispatch => {

    try {


        dispatch({ type: IS_LOADING_START })

        _Api(Url.USER_GET_CART_LIST.method, Url.USER_GET_CART_LIST.url).then(resp => {

            let payload = {
                cartListDetails: resp.responseData.result,

            }

            dispatch({ type: GET_USER_CART_LIST_SUCCESS, payload })
            dispatch({ type: IS_LOADING_STOP })
        }).catch(err => {
            dispatch({ type: IS_LOADING_STOP })

            // if (err.error)
            //     notify("err", err.error.message)
            // else if (err)
            //     // notify("err", err.message)
        })

    } catch (error) {
        notify("err", error.message)

    }

}




// action for get wish list
export const getWishList = (download = 0, type = 1) => dispatch => {
    console.log("lkjhsgchdjk", { download, type })
    const { method, url } = Url.WISHLIST_LIST;
    try {
        dispatch({ type: IS_LOADING_START })
        _Api(method, url, '', `?download=${download}&xlsType=${type}`).then(resp => {
            if (download == 1)
                window.open(resp.responseData.file, "_blank");
            else
                dispatch({
                    type: GET_USER_WISH_LIST_SUCCESS, payload: {
                        brandList: resp.responseData.brandList,
                        productList: resp.responseData.productList,
                        offerList: resp.responseData.offerList
                    }
                })
            dispatch({ type: IS_LOADING_STOP });
        }).catch(err => {
            dispatch({ type: IS_LOADING_STOP });

            // if (err.error)
            //     notify("err", err.error.message)
            // else if (err)
            //     // notify("err", err.message)
        })

    } catch (error) {
        notify("err", error.message)

    }

}

// To maintain local state on redux
//TODO:need to move this in common place

export const setLocalState = data => dispatch => {
    console.log("Adagsdhjk", data)
    dispatch({ type: SET_LOCAL_STATE, data });
}


// action for notification list
export const getNotificationList = req => dispatch => {
    const { method, url } = Url.NOTIFICATION_LIST;
    try {
        return new promise(function (resolve, reject) {
            dispatch({ type: IS_LOADING_START })
            _Api(method, url).then(resp => {
                dispatch({
                    type: GET_NOTIFICATION_SUCCESS, payload: {
                        notification: resp.responseData.notification,
                        totalCount: resp.responseData.totalCount,
                        unReadCount: resp.responseData.unReadCount
                    }
                })
                dispatch({ type: IS_LOADING_STOP });
                resolve(resp.responseData);
            }).catch(err => {
                dispatch({ type: IS_LOADING_STOP });
                reject(err);
            })
        })
    } catch (error) {
        // notify("err", error.message)

    }

}


export const getRecentList = req => dispatch => {
    const { method, url } = Url.RECENT_SEARCH_LIST;
    try {
        dispatch({ type: IS_LOADING_START })
        _Api(method, url).then(resp => {
            dispatch({
                type: GET_RECENTSEARCH_DATA, payload: {
                    recentData: resp.responseData
                }
            })
            dispatch({ type: IS_LOADING_STOP });
        }).catch(err => {
            dispatch({ type: IS_LOADING_STOP });

        })

    } catch (error) {
        // notify("err", error.message)

    }

}

export const getBrandDetail = req => dispatch => {
    const { method, url } = Url.GET_BRANDPROFILE_DETAIL;
    try {
        dispatch({ type: IS_LOADING_START })
        _Api(method, url).then(resp => {
            dispatch({
                type: GET_BRANDDETAIL_SUCCESS, payload: {


                }
            })
            dispatch({ type: IS_LOADING_STOP });
        }).catch(err => {
            dispatch({ type: IS_LOADING_STOP });

            // if (err.error)
            //     notify("err", err.error.message)
            // else if (err)
            //     // notify("err", err.message)
        })

    } catch (error) {
        // notify("err", error.message)

    }

}
