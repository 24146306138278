import React, { Component } from 'react';
import { _Api } from '../../services/Api';
import Url from '../../services/BaseUrl';
import { notify } from '../Toaster';
import { connect } from "react-redux";
import { getWishList } from '../../redux/action';
import { getBrandHome } from "../../redux/action/Get"
import { Spinner } from "react-bootstrap";
import LazyLoad from 'react-lazy-load';


class VisitedBrands extends Component {


    constructor(props) {
        super(props);
        this.state = {

        }
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.carousel = React.createRef();
    }


    next = () => {
        this.carousel.next();
    }
    previous = () => {
        this.carousel.prev();
    }

    addToWishList = (id, api) => {
        let stateName = `wish${id}`;
        this.setState({ [`isWishlistLoader${id}`]: true, [stateName]: !this.state[stateName] });

        let params = { brandId: id }

        _Api(Url[api].method, Url[api].url, params)
            .then(() => {
                this.props.dispatch(getWishList());
                // this.props.dispatch(getBrandHome())
                this.props.brandListData.forEach(item => {
                    if (item?.brandData?._id == id) {
                        item.wishListData = !item.wishListData
                    }
                });
                
                // scope.getList("FEATURED_BRAND_LIST", "brandListData","mostVisitedBrand");
                setTimeout(() => {
                    this.setState({ [`isWishlistLoader${id}`]: false });

                }, 600);
            }).catch(() => {
                // this.handleError(err);
            })
    }
    handleError(err) {
        let errorMessage = err.error && err.error.responseMessage || err.message;
        notify("err", errorMessage);
    }

    onClickBrand = item => {
        // this.props.history.push(`/brand/${id}`);
        this.props.history.push(`/business/${item._id}/${item?.activeCompany}`);
       //this.props.history.push(`/brand/${id}`);
    }

    render() {
        let brandListData = this.props.brandListData;


        return (
            <div className=" row mb-3">
                {
                    brandListData.filter((item, index) => index < 8).map(brand => (
                        <div className="col-xl-3 col-lg-4 col-sm-6 cursor-point" >
                            <LazyLoad>

                            <div className="card brand-card  mb-5 pos-relative"  >
                                
                                    <img className="img-fluid mb-4" src={brand?.brandData?.coverImage || require('../../assets/images/profile-placeholder.png')} onClick={() => this.onClickBrand(brand?.brandData)} />
                             
                                <div className="top-right">
                                    {
                                        <div className={(brand?.wishListData) && "blue-circle red-circle" || "blue-circle"} onClick={() => this.addToWishList(brand?.brandData?._id, brand?.wishListData && "REMOVE_WISHLIST" || "ADD_WISHLIST")}>
                                            {this.state[`isWishlistLoader${brand?.brandData?._id}`] &&
                                                <Spinner className="text-center" size="xl" animation="border" variant="primary" /> || <i className="fa fa-heart-o" ></i>}

                                        </div>
                                    }
                                </div>
                                <div className=" brand-card-body cursor-point" onClick={() => this.onClickBrand(brand?.brandData)}>
                                    <div className="brand-img-wrapper">
                                      
                                            <img className="img-fluid" src={brand?.brandData?.logoImage || require('../../assets/images/placeholder.png')} />
                                     
                                    </div>
                                    <h4>{brand?.brandData?.companyName}</h4>
                                    {/* <p>{item?.about}</p> */}
                                </div>
                            </div>
                            </LazyLoad>
                        </div >


                    ))
                }

            </div>


            // <div className="carousel-wrapper m-0 viewbrand">
            //     <i class="fa fa-long-arrow-left" onClick={this.previous} />


            //     <Carousel ref={node => (this.carousel = node)} {...settings} className="health-parameter-choose">
            //         {
            //             brandListData.map(brand =>
            //                 (

            //                     <div class="people_saying">
            //                         <div className="card brand-card  mb-5 pos-relative">
            //                             <img className="img-fluid mb-4" src={brand?.brandData?.coverImage || require('../../assets/images/profile-placeholder.png')} />
            //                             <div className="top-right">
            //                                 {
            //                                     <div className={(brand?.wishListData) && "blue-circle red-circle" || "blue-circle"} onClick={() => this.addToWishList(brand?.brandData?._id, brand?.wishListData && "REMOVE_WISHLIST" || "ADD_WISHLIST")}>
            //                                         {this.state[`isWishlistLoader${brand?.brandData?._id}`] &&
            //                                             <Spinner className="text-center" size="xl" animation="border" variant="primary" /> || <i className="fa fa-heart-o" ></i>}

            //                                     </div>
            //                                 }
            //                             </div>                                        
            //                             <div className=" brand-card-body cursor-point" onClick={() => this.onClickBrand(brand?.brandData?._id)}>
            //                                 <div className="brand-img-wrapper">
            //                                     <img className="img-fluid" src={brand?.brandData?.logoImage || require('../../assets/images/placeholder.png')} />
            //                                 </div>
            //                                 <h4>{brand?.brandData?.companyName}</h4>

            //                             </div>
            //                         </div>
            //                     </div>


            //                 )
            //             )
            //         }
            //     </Carousel>




            //     <i class="fa fa-long-arrow-right" onClick={this.next} />
            // </div>


        )

    }
}
function mapStateToProps(state) {
    return {
        wishList: state.wishList
    };
}


export default connect(mapStateToProps)(VisitedBrands);
function SampleNextArrow(props) {
    return (
        <div>
            <i className="fa fa-long-arrow-right" onClick={this.next} />

        </div>

    );
}

function SamplePrevArrow(props) {
    return (
        <div>
            <i className="fa fa-long-arrow-right" onClick={this.next} />

        </div>

    );
}


