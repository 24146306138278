import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Sidebar from '../../../layout/Sidebar';
import Header from '../../../layout/Header';
import TimeTablePopup from '../popups/TimeTablePopup';
import { BackBtn } from '../../../../components/common/Button';


import { Tab, Tabs, } from 'react-bootstrap';
import { DatePicker, TimePicker, Select } from 'antd';
import { Pencil, Delete, UploadVideo, ExhibitionManagement, CircleCross } from '../../../../SvgIcon';
import { _Api } from '../../../../services/Api'
import Url from '../../../../services/BaseUrl'
import InlineError from '../../../../components/InlineError';
import { notify } from '../../../../components/Toaster';
// import moment from 'moment-timezone';
import { validateEmail } from '../../../../components/Validation'
import Cookies from 'js-cookie';
import ReactPlayer from 'react-player';

import { Modal, Button, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import PhoneInput from 'react-phone-input-2';

import Loader from '../../../../components/Loader'
import { MySpinner } from "../../../../components/index"

import { Days } from "../../../../utils/Days"

import Croper from "../../../../components/Croper";


const { confirm } = Modal;



const { Option } = Select;


// import { AddNewConference } from '../../../SvgIcon'

const disabledDate = (current) => {
    // Can not select days before today and today
    return current && moment(current.valueOf()).format('MM/DD/YYYY') < moment().format('MM/DD/YYYY');;
}

var moment = require('moment-timezone');

class AddStages extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            stageName: '',
            stageNameError: '',
            conferenceId: '',
            url: '',
            urlError: '',
            stageType: '',
            stageTypeError: '',
            startDate: '',
            endDate: '',
            startTime: '',
            endTime: '',
            numberofAttendees: '',
            loungeNameError: '',
            numberofAttendeesError: '',
            startTimeError: '',
            endDateError: '',

            setOrderError: '',
            selectedFile: '',
            file: null,
            loading: false,
            visible: false,
            media: [],
            isStage: false,
            isLoading: false,
            spekerData: [],
            viewStageDetails: '',
            isEdit: false,
            streamUrl: '',
            streamUrlError: '',
            tabActive: 'speaker'


        }
    }
    // this.props.match.params.exId

    handleOnChange = (type, e) => {
        console.log('qwerty', e)
        this.setState({ [type]: e, startDateError: '', stageTypeError: '', endDateError: '', startTimeError: '', endTimeError: "", liveDateError: '', liveTimeError: '', streamUrlError: '' })
    }

    stageOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, stageNameError: '', numberofAttendeesError: '', startTimeError: '', endDateError: '', setOrderError: '', streamUrlError: '' })
    }

    componentDidMount() {
        this.setState({ tabActive: localStorage.getItem('st_tabActive') ? localStorage.getItem('st_tabActive') : 'speaker' })
        if (this.props.match.params.id) {
            this.getParticularStage(true)
            this.getSpekerList()
        }
    }



    getParticularStage = (status = false) => {
        let req = {
            stageId: this.props.match.params.stId
        }
        _Api(Url.GET_PARTICULAR_STAGE.method, Url.GET_PARTICULAR_STAGE.url, '', `?stageId=${this.props.match.params.id}`)
            .then(resp => {
                const { streamUrl, image, speakerData, stageName, setOrder, startDate, endDate, stageUrl, startTime, endTime } = resp.responseData.result
                this.setState({
                    isStage: true,
                    streamUrl,
                    profilePicPreview: image,
                    viewStageDetails: resp.responseData.result,
                    stageName, stageType: setOrder, startDate: moment(new Date(startDate)), endDate: moment(new Date(endDate)), url: stageUrl, startTime: moment(new Date(startTime)), endTime: moment(new Date(endTime))
                })
            }).catch(err => {
            })
    }



    getSpekerList = (status = false) => {
        let req = {
            stageId: this.props.match.params.id,
            page: 1,
            limit: 10
        }
        _Api(Url.List_NEW_SPEAKER.method, Url.List_NEW_SPEAKER.url, req)
            .then(resp => {
                const { speaker } = resp.responseData.result
                this.setState({
                    spekerData: speaker
                })
            }).catch(err => {
            })
    }




    handleTab = (tab) => {
        localStorage.setItem('st_tabActive', tab)
        this.setState({ tabActive: tab })
    }





    handleChange = (e) => {
        console.log('console', e.target.files)
        this.setState({ selectedFile: e.target.files, file: URL.createObjectURL(e.target.files[0]) })

    }
    handleImageUpload = (event) => {
        let reader = new FileReader();
        let file = event.target.files[0];
        let { valiDate } = this.state;

        if (file) {
            reader.readAsDataURL(file)
        }
        if (file) {
            console.log(file, "media1")
            if (file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png' || file.type === 'image/svg+xml') {

                reader.addEventListener("load", () => {
                    this.setState({ profilePicPreview: reader.result }, function () {
                    })
                }, false);

                if (file.size > 2000000) {
                    console.log("lkjhgfdsdfghjklkjhgfghjkmnbvccvbnm>>>>>>>>>>>>>>>>>>")
                    valiDate = false;
                    console.log(event, "media", event.target.files[0])
                    this.setState({
                        media: event.target.files[0],
                        profilePicErrorMsg: 'Profile pic size should be less than 5Mb.'
                    });
                } else {
                    console.log("media3hhhjshs", event.target.files[0], file.size)
                    this.setState({
                        media: event.target.files[0],
                        mediaError: ''
                    });
                    // alert("wrong file")
                }
            } else {
                valiDate = false;
                this.setState({ media: 'File type not supported.' })
            }
        }
    }


    componentWillUnmount() {
        // Cookies.remove('data_stage')
    }


    render() {

        let { streamUrl, streamUrlError, viewStageDetails, stageType, endDate, stageName, stageNameError, url, urlError, stageTypeError, startDate, startDateError, startTime, startTimeError, endTime, endTimeError, endDateError } = this.state
        // console.log('getDatatatat', new Date(viewStageDetails?.endDate).getDay())
        return (
            <>
                {this.state.isLoading && <Loader />}
                <div className="dash_body_container">
                    <Header />
                    <Sidebar />
                    <div className="body_container">
                        <div className="header-container">
                            <div className="d-flex align-items-center">
                                <p className="header-icon"><ExhibitionManagement /></p>
                                <h4>{viewStageDetails?.stageName}</h4>
                            </div>
                            <a > <button onClick={() => this.props.history.goBack()} className="btn btn-light"><i class="fa fa-arrow-left" aria-hidden="true"></i>Back</button></a>
                        </div>
                        <div className="rounded-body-form">

                            <>
                                <div className="header-text  mb-4 mt-3">
                                    <div className="ml-auto">

                                        <Link className="btn btn-red" to={`/add-stages/${viewStageDetails?.conferenceId}/${viewStageDetails?._id}`}>Edit</Link>
                                    </div>
                                </div>


                                <div className="table-responsive">

                                    <table className="table vertical-table table-bordered rounded-table">
                                        <tr>
                                            <th>Stage Name</th>
                                            <td>{viewStageDetails?.stageName}</td>
                                            <th>Stream url</th>
                                            <td>{viewStageDetails?.streamUrl ? viewStageDetails?.streamUrl : ''}</td>
                                        </tr>

                                        <tr>
                                            <th>Start Date</th>

                                            <td>{viewStageDetails?.startDate ? Days[new Date(viewStageDetails?.startDate).getDay()] : ''} {moment(new Date(viewStageDetails?.startDate)).format('LL')}</td><th>Start Time</th><td>{moment(viewStageDetails?.startTime).format('LT')}</td>
                                        </tr>
                                        <tr>
                                            <th>End Date</th>
                                            <td>{viewStageDetails?.startDate ? Days[new Date(viewStageDetails?.endDate).getDay()] : ''} {moment(viewStageDetails?.endDate).format('LL')}</td><th>End Time</th><td>{moment(viewStageDetails?.endTime).format('LT')}</td>
                                        </tr>
                                        {/* <tr>                                     
                                       <th>Stage URL</th>
                                       <td>{viewStageDetails?.stageUrl}</td>
                                   </tr> */}

                                    </table>
                                </div>
                            </>



                            <div className="blue-border card-wrapper">

                                <Tabs activeKey={this.state.tabActive} onSelect={(e) => this.handleTab(e)} id="uncontrolled-tab-example">
                                    <Tab eventKey="speaker" title="Speaker">
                                        <Speaker getParticularStage={() => this.getSpekerList()} speakerData={this.state.spekerData} {...this.props} isAdd={this.state.isStage} />
                                    </Tab>
                                    <Tab eventKey="timetable" title="Timetable">
                                        <Timetable speakerData={this.state.spekerData} {...this.props} isAdd={this.state.isStage} />
                                    </Tab>

                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default AddStages;



class Speaker extends React.Component {

    constructor() {
        super();
        this.state = {
            showPopup: false,
            speakerDetails: [],
            visible: false,
            loading: false,
            speakerviewData: {},
            isEdit: false,
            speakerData: []


        };
    }
    togglePopup(item, isEdit = false) {
        // console.log('sdfghj', item);

        this.setState({
            showPopup: !this.state.showPopup, speakerviewData: item, isEdit: isEdit
        });
        this.props.getParticularStage()
        // if (this.props.isAdd)
        this.getSpeakerDetails()

    }
    // componentDidMount() {
    //     if (this.props.isAdd)
    //         this.getSpeakerDetails()

    // }



    // delete modal
    showDeleteConfirm = (id) => {
        confirm({
            title: 'Are you sure you want to delete this?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            onOk: () => {
                this.deleteSpeaker(id)
            },
            onCancel: () => {
                this.handleCancel()
            },
        });
    }

    componentDidMount() {
        this.getSpeakerDetails()

    }



    getSpeakerDetails = () => {
        let req = {
            stageId: this.props.match.params.id,
            page: 1,
            limit: 10
        }
        _Api(Url.List_NEW_SPEAKER.method, Url.List_NEW_SPEAKER.url, req)
            .then(resp => {
                const { speaker } = resp.responseData.result
                this.setState({
                    speakerData: speaker
                })
            }).catch(err => {
            })


        // _Api(Url.GET_SPEAKER_LIST.method, Url.GET_SPEAKER_LIST.url, "", "?stageId=" + "" + this.props.match.params.id)
        //     .then(resp => {
        //         console.log('resp1111111', resp?.responseData?.result?.data)
        //         this.setState({ speakerDetails: resp?.responseData?.result?.data })
        //         // this.props.history.push(`/seminardetails/${this.props.match.params.exid}/networking`)
        //         // this.props.history.goback()
        //     }).catch(err => {

        //     })

    }


    deleteSpeaker = (id) => {
        let req = {
            stageId: this.props.match.params.id,
            speakerId: id,

        }
        _Api(Url.Delete_NEW_SPEAKER.method, Url.Delete_NEW_SPEAKER.url, req)
            .then(resp => {
                // console.log('delete', resp.responseData.message)
                notify('success', resp.responseData.message)
                this.setState({ visible: false })
                this.getSpeakerDetails()
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
            })


    }

    //Show modal
    showModal = (id) => {
        this.setState({
            visible: true,
            selectedId: id
        });

    };
    //Cancel modal
    handleCancel = () => {
        this.setState({ visible: false });
    }

    render() {
        let { speakerData, visible, loading } = this.state       
        return (
            <div className="pl-4 pr-4">
                {this.props.isAdd && <div className="text-right">
                    <button className="btn btn-red" onClick={this.togglePopup.bind(this, false)}>Add Speaker</button>
                </div>
                }

                <div className="table-responsive mt-4 ">
                    <table className="table table-bordered rounded-table">
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>Speaker Name</th>
                                <th>Speaker Image</th>
                                <th>Bio</th>
                                <th>Designation</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                speakerData?.map((item, index) => (
                                    <tr>
                                        <td><span className="circle-serial">{index + 1}</span></td>
                                        <td>{item?.firstName} {item?.lastName}</td>

                                        <td className="">

                                          {item?.image ? 
                                           <div className="sperkerdetails ">
                                             <img className="circle-image" src={item?.image} className="circle-imgs"/>
                                          </div>
                                            :
                                          <p data-letters={`${item?.firstName ? item?.firstName.charAt(0) : ''} ${item?.lastName ? item?.lastName.charAt(0) : ''}`}></p>
}
                                        </td>

                                        <td>{item?.about ? item?.about : 'NA'}</td>
                                        <td>{item?.jobTitleOther}</td>
                                        <td className="action_group">
                                            <p onClick={() => this.togglePopup.bind(this)(item, true)}><p className="circle-serial"><Pencil /></p></p>
                                            <p onClick={() => this.showDeleteConfirm(item?._id)}><p className="circle-serial"><Delete /></p></p>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                <Modal show={visible} onHide={this.handleCancel} className="cm_order_view dlt_item">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <img src={require('../../assets/img/Delete-Email.png')} /> */}
                        <br />
                        <br />
                        <p>Are you sure you want to delete this ?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="footer_btn_wrapper btn_nospace">
                            <Button variant="warning" loading={loading} onClick={() => this.deleteSpeaker(this.state.selectedId)} >
                                Yes
                        </Button>
                            <Button variant="dark" onClick={this.handleCancel}>
                                No
                        </Button>
                        </div>
                    </Modal.Footer>
                </Modal>

                <div className="text-right pt-4">
                    {/* <button to="" className="btn btn-primary">Save</button> */}
                </div>
                {this.state.showPopup ?
                    <StagePopup
                        {...this.props}
                        text='Close Me'
                        closePopup={this.togglePopup.bind(this)}
                        speakerviewData={this.state.speakerviewData}
                        isEdit={this.state.isEdit}

                    />
                    : null
                }
            </div>

        )
    }
}


//
class Timetable extends Component {
    constructor() {
        super();
        this.state = {
            showPopup: false,
            visible: false,
            loading: false,
            timeTableDetails: [],
            timeTable: {},
            isEdit: true
        };

    }
    togglePopup(item, isEdit) {
        console.log("dksajhdb", item)
        this.setState({
            showPopup: !this.state.showPopup,
            timeTable: item,
            isEdit: isEdit
        });
        this.getTimeTableDetails()
    }

    componentDidMount() {
        if (this.props.match.params.id)
            this.getTimeTableDetails()
    }
    getTimeTableDetails = () => {
        _Api(Url.GET_TIME_TABLE_LIST.method, Url.GET_TIME_TABLE_LIST.url, "", "?page=1&limit=100&stageId=" + "" + this.props.match.params.id)
            .then(resp => {
                // console.log('aaaaaaaaaaaaa', resp?.responseData?.result)
                const { data, totalCount } = resp?.responseData?.result
                this.setState({ timeTableDetails: data })
                // this.props.history.push(`/seminardetails/${this.props.match.params.exid}/networking`)
                // this.props.history.goback()

            }).catch(err => {
            })

    }


    deleteTimeTable = (id) => {
        let req = {
            isDeleted: 0,
            timeTableId: id,
        }
        _Api(Url.DELETE_TIME_TABLE.method, Url.DELETE_TIME_TABLE.url, '', `?timeTableId=${req.timeTableId}&isDeleted=${req.isDeleted}`)
            .then(resp => {
                console.log('delete', resp.responseData.message)
                notify('success', resp.responseData.message)
                this.setState({ visible: false })
                this.getTimeTableDetails()
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
            })
    }

    // delete modal
    showDeleteConfirm = (id) => {
        confirm({
            title: 'Are you sure you want to delete this?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            onOk: () => {
                this.deleteTimeTable(id)
            },
            onCancel: () => {
                this.handleCancel()
            },
        });
    }

    //Show modal
    showModal = (id) => {
        this.setState({
            visible: true,
            selectedId: id
        });

    };
    //Cancel modal
    handleCancel = () => {
        this.setState({ visible: false });
    };



    render() {
        let { timeTableDetails, visible, loading } = this.state

        console.log("fdsjhghfjk", timeTableDetails)

        return (
            <div className="pl-4 pr-4">

                {this.props.isAdd && <div className="text-right">
                    <button className="btn btn-red" onClick={this.togglePopup.bind(this)}>Add Timetable</button>
                </div>
                }

                <div className="table-responsive mt-4">
                    <table className="table table-bordered rounded-table">
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>Session Name</th>
                                {/* <th>Speaker Name</th> */}
                                {/* <th>Job Profile</th> */}
                                <th>Start Time</th>
                                <th>End Time</th>
                                <th>Date</th>
                                {/* <th>Stage</th> */}
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                timeTableDetails?.map((item, index) => (
                                    <tr key={item._id}>
                                        <td><span className="circle-serial">{index + 1}</span></td>
                                        <td>{item?.sessionName}</td>
                                        {/* <td>{item?.speakerId?.firstName} {item?.speakerId?.lastName}</td> */}
                                        {/* <td>{item?.speakerId?.jobTitleOther}</td> */}
                                        <td>{moment(item?.startDate).format('LT')}</td>
                                        <td>{moment(item?.endDate).format('LT')}</td>
                                        <td><p><p>{moment(item?.startDate).format('DD/ MM/ YYYY')}</p> <p>{moment(item?.startDate).format('dddd')}</p></p></td>
                                        {/* <td>{item?.stage}</td> */}
                                        <td className="action_group">
                                            <p onClick={() => this.togglePopup.bind(this)(item, true)}><p className="circle-serial"><Pencil /></p></p>
                                            <p onClick={() => this.showDeleteConfirm(item?._id)}><p className="circle-serial"><Delete /></p></p>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>




                <div className="text-right pt-4">
                    {/* <button to="" className="btn btn-primary">Save</button> */}
                </div>
                {this.state.showPopup ?
                    <TimeTablePopup
                        {...this.props}
                        speakerData={this.props.speakerData}
                        timeTableDetails={timeTableDetails}
                        text='Close Me'
                        closePopup={this.togglePopup.bind(this)}
                        timeTable={this.state.timeTable}
                        isEdit={this.state.isEdit}
                    />
                    : null
                }

            </div>

        )
    }
}

class StagePopup extends React.Component {


    static getDerivedStateFromProps(nextProps, prevState) {

        if (prevState.flag == false && nextProps.isEdit == true) {
            let { speakerviewData } = nextProps
            console.log("lfkjknkfmsjfmksdlnfjksdm,", speakerviewData)
            return {
                speakerName: `${speakerviewData.firstName}`,
                lastName: `${speakerviewData.lastName}`,
                // email: speakerviewData.email,
                profilePicPreview: speakerviewData.image,
                speakerDesignation: speakerviewData.jobTitleOther,
                // phoneNumber: String(speakerviewData.phoneNumber),
                description: speakerviewData.about,
                spekerProfilePic: speakerviewData.profileImage,
                flag: true
            }

        }



        // Return null to indicate no change to state.
        return null;
    }



    constructor(props) {
        super(props);
        this.state = {
            speakerName: '',
            speakerNameError: '',
            speakerDesignation: '',
            speakerDesignationError: '',
            email: '',
            emailError: '',
            phoneNumber: '',
            phoneNumberError: '',
            spekerProfilePic: '',
            description: '',
            descriptionError: '',
            profilePicPreview: '',
            isEdit: this.props.isEdit,
            emailEdit: true,
            id: '',
            flag: false,
            isLoading: false,
            lastName: '',
            code: '',
            isCropper: false,


        };
    }

    handleChange = (e) => {

        console.log('asasas', e.target.value, e.target.name)
        this.setState({ [e.target.name]: e.target.value, descriptionError: '', speakerNameError: '', phoneNumberError: '', speakerDesignationError: '', emailError: '' })
        if (e.target.value != '' && e.target.name == 'email')
            this.setState({ emailEdit: false })
    }


    addSpeaker = () => {
        if (this.state.isEdit == true) {
            this.editSpeaker()
        }
        else
            this.submitSpeaker()

    }

    finalCropedImage = (categoryImgPrev, categoryImg, visibleVal) => {
        console.log("ldjshgvhbjkd", { categoryImgPrev, categoryImg, visibleVal })
        this.setState({
            visibleVal,
            isCropper: false,
            profilePicPreview: categoryImgPrev,
            media: categoryImg

        })
    }



    handlePicUpload = (event) => {
        let reader = new FileReader();
        let file = event.target.files[0];
        let { valiDate } = this.state;

        if (file) {
            reader.readAsDataURL(file)
        }
        if (file) {
            console.log(event, "media1", event.target.files[0])
            if (file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png') {
                reader.addEventListener("load", () => {
                    this.setState({ profilePicPreview: reader.result }, function () {
                    })
                }, false);
                if (file.size > 2000000) {
                    console.log("lkjhgfdsdfghjklkjhgfghjkmnbvccvbnm>>>>>>>>>>>>>>>>>>")
                    valiDate = false;
                    console.log(event, "media", event.target.files[0])
                    this.setState({
                        media: event.target.files[0],
                        profilePicErrorMsg: 'Profile pic size should be less than 5Mb.'
                    });
                } else {
                    console.log("media3hhhjshs", event.target.files[0], file.size)
                    this.setState({
                        media: event.target.files[0],
                        mediaError: ''
                    });
                }
            } else {
                valiDate = false;
                this.setState({ mediaError: 'File type not supported.' })

            }
        }
    }


    submitSpeaker = () => {
        let { lastName, speakerName, description, speakerDesignation, spekerProfilePic, email, phoneNumber, media } = this.state
        let reg = /^[1-9][0-9]{7,18}$/;
        if (speakerName == '')
            this.setState({ speakerNameError: '*Please enter first name.' })
        // else if (phoneNumber == '')
        //     this.setState({ phoneNumberError: '*Please enter phone number.' })
        // else if (!reg.test(phoneNumber))
        //     this.setState({ phoneNumberError: '*Please enter valid phone number.' })
        // else if (speakerDesignation == '')
        //     this.setState({ speakerDesignationError: '*Please enter Designation.' })
        // else if (email == '')
        //     this.setState({ emailError: '*Please enter email.' })
        // else if (!validateEmail(email).status)
        //     this.setState({ emailError: "*Please enter valid email." })
        // else if (description == '')
        //     this.setState({ descriptionError: '*Please enter bio.' })

        else {
            this.setState({ isLoading: true })
            let formData = new FormData()
            // formData.append("userType", 3)
            formData.append("exhibitionId", localStorage.getItem('exhibition'))  
            formData.append("stageId", this.props.match.params.id)
            formData.append("firstName", speakerName)
            // formData.append('countryCode',  this.state.code)
            formData.append("lastName", lastName)
            // formData.append("phoneNumber", phoneNumber)
            formData.append("jobTitleOther", speakerDesignation)
            formData.append("image", media)
            // formData.append("email", email)
            formData.append("about", description ? description : '')
            _Api(Url.ADD_NEW_SPEAKER.method, Url.ADD_NEW_SPEAKER.url, formData)
                .then(resp => {
                    // localStorage.setItem('speakerId', resp.responseData.stageDetails._id)
                    notify('success', resp.responseData.message)
                    this.setState({ isLoading: false })
                    this.props.closePopup()
                }).catch(err => {
                    this.setState({ isLoading: false })
                    if (err.error)
                        notify("err", err.error.responseMessage)
                    // else if (err)
                        // notify("err", err.message)
                })
        }

    }

    editSpeaker = () => {
        let reg = /^[1-9][0-9]{7,18}$/;
        let { lastName, speakerName, description, speakerDesignation, spekerProfilePic, email, phoneNumber, media } = this.state
        if (speakerName == '')
            this.setState({ speakerNameError: '*Please enter speaker name.' })

        else {
            this.setState({ isLoading: true })
            var formData = new FormData()
            formData.append("speakerId", this.props.speakerviewData._id)
            formData.append("stageId", this.props.match.params.id)
            formData.append("firstName", speakerName)
            formData.append("lastName", lastName)
            formData.append("jobTitleOther", speakerDesignation)
            formData.append("image", media)
            formData.append("about", description ? description : '')

            _Api(Url.Edit_NEW_SPEAKER.method, Url.Edit_NEW_SPEAKER.url, formData)
                .then(resp => {
                    // localStorage.setItem('speakerId', resp.responseData.stageDetails._id)
                    notify('success', resp.responseData.message)
                    this.setState({ isEdit: false })
                    this.setState({ isLoading: false })
                    this.props.closePopup()
                }).catch(err => {
                    this.setState({ isLoading: false })
                    if (err.error)
                        notify("err", err.error.responseMessage)
                    // else if (err)
                        // notify("err", err.message)
                })
        }
    }
    render() {
        // console.log('props', this.props.isEdit);
        let { lastName, speakerName, speakerDesignation, spekerProfilePic, description, descriptionError, email, phoneNumber, emailError, phoneNumberError, speakerNameError, speakerDesignationError } = this.state
        return (
            <div className='popup'>
                <div className='popup_inner'>
                    <div className="rounded-body-form">
                        <div className="cm-form-wrapper rounded-textbox-form">
                            <h3 className="text-center bold"><strong> {this.state.isEdit == true ? "Edit Speaker" : "Add Speaker"}</strong></h3>
                            <div style={{ cursor: 'poniter' }} className="upload-wrapper">
                                <label className="upload-photo-wrapper" onClick={() => this.setState({ isCropper: true })}>
                                    {this.state.profilePicPreview ? <img style={{ width: 100, height: 100 }} src={this.state.profilePicPreview} /> :
                                        <>    <span> <img className="img-fluid" src={require('../../../../assets/images/upload-photo.svg')} /></span>
                                            <p className="m-0">upload photo</p> </>
                                    }
                                    {/* <>    <span> <img className="img-fluid" src={require('../../../../assets/images/upload-photo.svg')} /></span>
                                            <p className="m-0">upload photo</p> </> */}
                                    {/* <input id="file-upload"
                                        type="file"
                                        onChange={this.handlePicUpload}
                                    /> */}
                                </label>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <level>First Name</level>
                                        <input type="text"
                                            className="form-control"
                                            placeholder="First Name"
                                            maxLength={64}
                                            name="speakerName"
                                            value={speakerName}
                                            onChange={this.handleChange}
                                        />
                                        <InlineError
                                            message={speakerNameError}
                                        />
                                    </div>

                                    {/* <div className="form-group">
                                        <level>Designation</level>
                                        <input type="text"
                                            className="form-control"
                                            placeholder="Designation"
                                            maxLength={64}
                                            name="speakerDesignation"
                                            value={speakerDesignation}
                                            onChange={this.handleChange}
                                        />
                                        <InlineError
                                            message={speakerDesignationError}
                                        />
                                    </div> */}



                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <level>Last Name</level>
                                        <input type="text"
                                            className="form-control"
                                            placeholder="Last Name"
                                            maxLength={64}
                                            name="lastName"
                                            value={lastName}
                                            onChange={this.handleChange}
                                        />
                                        {/* <InlineError
                                            message={speakerNameError}
                                        /> */}
                                    </div>

                                </div>
                                {/* <div className="col-md-6">
                                    <div className="form-group">
                                        <level>Phone Number</level>
                                        

                                    <PhoneInput
                                    country={'gb'}
                                    placeholder="Phone number"
                                    enableLongNumbers="false"
                                    autoFormat="false"
                                    value={this.state.phoneNumber}
                                    onChange={(phoneNumber, code) => this.setState({ phoneNumber, phoneNumberError: '', code: code.dialCode })}
                                />
                                <InlineError
                                    message={phoneNumberError}
                                />
                                    </div>
                                </div> */}
                                {/* <div className="col-md-6">
                                    <div className="form-group">
                                        <level>Email</level>
                                        <input type="text"
                                            className="form-control"
                                            placeholder="Email"
                                            maxLength={64}
                                            name="email"
                                            value={email}
                                            onChange={this.handleChange}
                                        />
                                        <InlineError
                                            message={emailError}

                                        />
                                    </div>
                                </div> */}

                                <div className="col-12">
                                    <div className="form-group">
                                        <level>Designation</level>
                                        <input type="text"
                                            className="form-control"
                                            placeholder="Designation"
                                            maxLength={256}
                                            name="speakerDesignation"
                                            value={speakerDesignation}
                                            onChange={this.handleChange}
                                        />
                                        <InlineError
                                            message={speakerDesignationError}
                                        />
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="form-group">
                                        <level>Bio</level>
                                        <textarea className="textarea form-control" rows="4"
                                            placeholder="Bio..."
                                            maxLength={512}
                                            name="description"
                                            value={description}
                                            onChange={this.handleChange}
                                        />
                                        <InlineError
                                            message={descriptionError}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="text-center">
                                <button className="btn btn-primary" disabled={this.state.isLoading} onClick={this.addSpeaker}>Save
                                {this.state.isLoading &&
                                        <MySpinner />
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                    <button className="close-btn" onClick={this.props.closePopup}><CircleCross /></button>
                </div>

                {this.state.isCropper ?
                    <Croper aspect={2.5 / 3}
                        // onImageCrop={img => this.setState({ media: img })}
                        imgPrev={'2.5-3.jpg'}
                        finalCropedImage={(categoryImgPrev, categoryImg, visibleVal) => this.finalCropedImage(categoryImgPrev, categoryImg, visibleVal)} visibleVal={this.state.isCropper}
                        onClose={() => this.setState({ isCropper: false })}
                    />
                    : ""}
            </div>
        );
    }
}
