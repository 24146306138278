import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { CategoryManagement} from '../../../components/common/SvgIcon';
import {Select, DatePicker, TimePicker,  } from 'antd';
import RichTextEditor from 'react-rte';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';

const { Option} = Select;
const userType = ['All', 'Attenty', 'Brand Showcase', 'Premium Brand Showcase'];


class EditNotification extends Component {
    constructor(){
        super();
        this.state = {
            value: RichTextEditor.createEmptyValue(),
            isChecked:false
        }
    }

    onChange = (value, dateString) => {
        this.setState({value});
        if (this.props.onChange) {
          this.props.onChange(
            value.toString('html')
          );
        }
    }

    // submit = () => {
    //     if (this.state.step == 1 && this.state.isEditExhibition == false) {
    //         this.exhibitionSubmit()
    //     }
    //     else if (this.state.step == 1 && this.state.isEditExhibition) {
    //         this.exhibitionEditSubmit()
    //     }
    //     else {
    //         this.setState({ step: this.state.step + 1 }, () => {
    //             this.props.history.push(`/addexhibition/exhibitiondetails/${this.state.step}/${this.props.match.params?.exId}`)
    //         })

    //     }
    // }



    // exhibitionSubmit = () => {
    //     let reg1 = /^\d+$/;
    //     let { isChecked, exhibitionName, startDate, endDate, liveDate, liveTime, startTime, endTime, exhibitionFee } = this.state
    //     if (exhibitionName == '')
    //         this.setState({ exhibitionNameError: '*Please enter exhibition name.' })
    //     else if (startDate == '')
    //         this.setState({ startDateError: '*Please enter start date.' })
    //     else if (startTime == '')
    //         this.setState({ startTimeError: '*Please enter start time.' })
    //     else if (endDate == '')
    //         this.setState({ endDateError: '*Please enter end date.' })
    //     else if (endTime == '')
    //         this.setState({ endTimeError: '*Please enter end time.' }) 

    //     else if (isChecked && exhibitionFee == '')
    //         this.setState({ exhibitionFeeError: '*Please enter exhibition fee.' })

    //     else if (isChecked && !reg1.test(exhibitionFee))
    //         this.setState({ exhibitionFeeError: '*Please enter valid fee.' })
    //     else {
    //         this.setState({ isLoader: true })
    //         let req = {
    //             exhibitionName: exhibitionName, 
    //             start_Date: ((Date.parse(startDate)) / 1000).toString(),
    //             startTime: startTime,
    //             end_Date: (Date.parse(endDate) / 1000).toString(),
    //             endTime: endTime,
    //             live_Date: ((Date.parse(startDate)) / 1000).toString(),
    //             liveTime: startTime,
    //             timezone:'Europe/London',
    //             exhibitionFee: (!isChecked ? 0 : exhibitionFee).toString()
    //         }
    //         if(this.state.selectedSectorValue.length>0){
    //             req.sectorId=this.state.selectedSectorValue.toString();
    //         }
    //         _Api(Url.ADD_EXHIBITION.method, Url.ADD_EXHIBITION.url, req)
    //             .then(resp => {
    //                 this.props.history.push(`/addexhibition/exhibitiondetails/2/${resp.responseData?.exhibtionDetails?._id}`)
    //                 this.setState({ isLoader: false, step: 2, isEditExhibition: true })

    //             }).catch(err => {
    //                 this.setState({ isLoader: false })
    //                 if (err.error)
    //                     notify("err", err.error.responseMessage)
    //                 else if (err)
    //                     this.setState({ isLoader: false })
    //                 // notify("err", err.message)
    //             })
    //     }
    // }


    // exhibitionEditSubmit = () => {
    //     let { isChecked, exhibitionName, startDate, endDate, liveDate, liveTime, startTime, endTime, exhibitionFee } = this.state
    //     if (exhibitionName == '')
    //         this.setState({ exhibitionNameError: '*Please enter Exhibition name.' })
    //     else if (startDate == '')
    //         this.setState({ startDateError: '*Please enter start date.' })
    //     else if (startTime == '')
    //         this.setState({ startTimeError: '*Please enter start time.' })
    //     else if (endDate == '')
    //         this.setState({ endDateError: '*Please enter end date.' })
    //     else if (endTime == '')
    //         this.setState({ endTimeError: '*Please enter end time.' })
    //     // else if (liveDate == '')
    //     //     this.setState({ liveDateError: '*Please enter end time.' })
    //     // else if (liveTime == '')
    //     //     this.setState({ liveTimeError: '*Please enter live time.' })
    //     else if (isChecked && exhibitionFee == '')
    //         this.setState({ exhibitionFeeError: '*Please enter exhibition fee.' })
    //     else {
    //         this.setState({ isLoader: true })
    //         let req = {
    //             exhibtionId: this.props.match.params.exId,
    //             exhibitionName: exhibitionName,
    //             start_Date: (Date.parse(startDate) / 1000).toString(),
    //             startTime: startTime,
    //             end_Date: (Date.parse(endDate) / 1000).toString(),
    //             endTime: endTime,
    //             live_Date: (Date.parse(startDate) / 1000).toString(),
    //             liveTime: startTime,
    //             timezone:'Europe/London',
    //             exhibitionFee: (!isChecked ? "0" : exhibitionFee).toString()
    //         }
    //         _Api(Url.EDIT_EXHIBITION.method, Url.EDIT_EXHIBITION.url, req)
    //             .then(resp => {
    //                 this.setState({ isLoader: false, step: 2, isEditExhibition: true })
    //                 this.props.history.push(`/addexhibition/exhibitiondetails/2/${this.props.match.params.exId}`)
    //             }).catch(err => {
    //                 this.setState({ isLoader: false })
    //                 // this.props.history.push('/seminardetails')
    //                 if (err.error)
    //                     notify("err", err.error.responseMessage)
    //                 // else if (err)
    //                     // notify("err", err.message)
    //             })
    //     }
    // }

    onChange = (dateString)=>{


    console.log('Formatted Selected Time: ', dateString);
};

onOk= (value)=> {
    console.log('onOk: ', value);
}


onChecked = ()=>{
    // this.setState({isChecked: !this.state.isChecked})
}



    render(){
        console.log("abbbbbbbbbbbb", this.state.isChecked);


        return(
            <>
                <div className="dash_body_container">
                    <div className="body_container">   
                        <div className="header-container">
                            <div className="d-flex flex-grow-1 align-items-center">
                                <p className="header-icon"><CategoryManagement /></p>
                                <h4>Notification</h4>
                            </div>
                            <Link to={'/notification-list'} > <button className="btn btn-light"><i class="fa fa-arrow-left" aria-hidden="true"></i>Back</button></Link>
                        </div>
                        <div className="rounded-body-form">
                            <form className="rounded-textbox-form">
                                <div className="form-group">
                                    <label>User Type</label>
                                    <Select
                                        mode="multiple"
                                        className="cm_multiselect cmslect_multicheck"
                                        placeholder="Please select"
                                        style={{ width: '100%' }}
                                    >
                                        {userType.map(user => (
                                            <Option key={user}>{user}</Option>
                                        ))}

                                    </Select>
                                </div>
                                <div className="form-group">
                                    <label>Message </label>
                                    <RichTextEditor value={this.state.value} onChange={this.onChange}/>
                                </div>
                                <div className="form-group csmt-rad-btn">
                                    <label>Message Broadcast Time</label>
                                    <div className="assign-checkboc-parent">
                                        <div>
                                            <label className="cm_container">
                                                Now
                                                <input onClick={this.onChecked} type="radio"checked={!this.state.ischecked} />
                                                <span className="radio-checkmark"></span>
                                            </label>
                                        </div>
                                        <div>
                                            <label className="cm_container">
                                                Date Time 
                                                <input onClick={this.onChecked} type="radio" checked={this.state.isChecked} />
                                                <span className="radio-checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                               {this.state.ischecked && <div className="form-group">
                                    <label>Date and Time</label>
                                    <div className="form-row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <DatePicker showTime onChange={this.onChange} onOk={this.onOk} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                {/* <TimePicker /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                <div className="form-group text-center">
                                    <button type="submit" className="btn btn-red">Submit</button>
                                </div>
                            </form>
                        </div>
                        
                    </div>
                </div>
            </>
        );
    }
}

export default EditNotification;