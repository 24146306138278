import React, { Component } from 'react';

import Footer from "../../layout/Footer"
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import {connect} from "react-redux";
import LazyLoad from 'react-lazy-load';

class ViewBrochures extends Component {

    constructor(props) {
        super(props);
        this.state = {
            brochureData: [],
            page: 1,
            limit: 50

        }
    }

    componentDidMount() {
        this.getBrochure();
    }
    getBrochure = () => {
        _Api(Url.GET_BROCHURE.method, Url.GET_BROCHURE.url, '', `?brandId=${this.props.match.params.id}`)
            .then(resp => {
                this.setState({
                    brochureData: resp.responseData.result
                })

            }).catch(() => {

            })
    }

    render() {
        let { brochureData } = this.state;

        return (

            <section className="brand-home-banner-bg">

                {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
                <div className="container-fluid cm-section featured-brands industries-serve">
                    <div className="cm-wrapper">
                        <div className="header-container">

                            <div className="header-text">
                                <h1 className="title">Brochures</h1>
                            </div>

                        </div>



                        <div className="row d-flex flex-wrap five justify-content-center align-item-center mb-3">

                            {
                                brochureData?.map((item) => {
                                    return (
                                        <div className="col-md-4 ">

                                            <div className="community-card brochure">
                                                <div className="row">
                                                    <div className="col-md-5 pr-0">
                                                        <LazyLoad>
                                                            <div className="img-wrapper">
                                                                <img src={item.coverImage ? item.coverImage : require('../../../assets/images/placeholder.png')} className="img-fluid" />
                                                                <label className="upload-photo-wrapper">
                                                                    <a target="_blank" href={item?.media} download> <span> <p className="m-0"><i class="fa fa-download" aria-hidden="true"></i></p></span> </a>

                                                                </label>
                                                            </div>
                                                        </LazyLoad>
                                                    </div>
                                                    <div className="col-md-6 no-gutter d-flex align-items-start flex-column">
                                                        <p>{item?.name}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>


                <Footer />
            </section>




        )
    }
}
// export default ViewBrochures;
function mapStateToProps(state) {
    return {
        ...state,
    };
}

export default connect(mapStateToProps)(ViewBrochures);
