import React from 'react';
import { Cross } from '../../SvgIcon';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import socket from "../../services/socket";
import {groupActions} from "../../redux/action";
import { CSSTransition } from 'react-transition-group';
import { Modal, Select, Tabs } from 'antd';

import DiaryMeetingModal from '../../screens/web/diary/DiaryMeetingModal';



class ProfileModal extends React.Component {

state = {
    show : true,
    meetingModal:false,
    userData: this.props.selectedUserInfo
}

    openProfile=(userId)=>{
        let self=this;
        if(this.props.userDetail._id !== userId) {
            if (self.props.match && (self.props.match.path === "/calling/:id" || self.props.match.path === "/networking/search" || self.props.match.path === "/lounge/:id" || self.props.match.path === "/network-lounge/:id")) {

            } else {
                if (userId && userId !== "") {
                    self.props.history.push('/user/profile/' + userId)  
                    this.props.closeModalProfile()                                
                    this.props.closeModal()
                }
            }
        }
    }

    selectUser = (user) => {
        let self = this;
        if (this.props.userDetail._id !== user._id) {
            this.setState({
                selectedUser: user,
                selectedUserId: user._id,
                chatType: 3,
                isShow: true
            }, function () {
                self.props.dispatch({ type: 'CHAT_OPEN' })
                self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 3 })
                self.props.dispatch({ type: 'SELECTED_USER', selectedUser: user })
                socket.emit('groupAdd', { type: 7, userId: user._id });
                this.props.closeModal();
            })
        }
    }

    videoCalling = (userId) => {
        let self = this;
        var windowReference = window.open();
        if (userId) {
            self.setState({
                ['videoConnectionLoder_' + userId]: true
            })
            self.props.dispatch(groupActions.joinOneMeeting({ userId: userId, type: 7 }))
                .then(function (res) {
                    self.setState({
                        ['videoConnectionLoder_' + userId]: false
                    })
                    if (res.statusCode === 1 || res.statusCode === "1") {
                        var newWin = window.open('/calling/' + self.props.groupDetail.meeting._id, "_blank", "width=" + window.screen.width + ",height=" + window.screen.height)
                        //console.log("newWin",newWin);
                        if(!newWin || newWin.closed || typeof newWin.closed=='undefined')
                        {
                            windowReference.location =  '/calling/' + self.props.groupDetail.meeting._id;
                            //alert("New windows pop-up is blocked, Please allow.")
                        }
                    } else {

                    }
                    //console.log("myWindow", res)
                })
        }
    }

    handleChildClick = (e) => {
        e.stopPropagation();
      }

      closeModal = () => {
        this.setState({show : false})

        setTimeout(() => {
            this.props.closeModal()
          }, 1000);
    
      }

      onMeetingClose = ()=>{
        this.setState({meetingModal:false})
    }

    render() {
        //console.log("fhhhhhhhhhhhhhhhhhh", this.props)
        let user=this.props.selectedUserInfo;
        return (
            <>
                <div className="cm-modal"  onClick={() => this.closeModal()}>
               
                    <div className="modal-content" id={this.state.show?`slide` : "slide-close"} onClick={this.handleChildClick}>
                        <div className="cross-icon-wrapper">
                            <span className="cros" onClick={() => this.closeModal()}><Cross /></span>
                        </div>

                        <div className="profile-icon-wrapper pos-relative image-chat chat-profile">
                            <img className="img-fluid" src={user.image&&user.image!==""?user.image:require('../../assets/images/login_user.png')} />

                        </div>
                        <div className="text-center mt-5">
                            <p><strong>{user.firstName && user.firstName !== "" ? user.firstName : ""}&nbsp;{user.lastName && user.lastName !== "" ? user.lastName : ""}</strong></p>
                            <p>{user.companyName && user.companyName !== "" ? user.companyName : ""}</p>
                            <p>{user.city ? user.city+"," : ""} {user.country ? user.country : ""}</p>
                        </div>
                        <div className="mt-4 text-center ">

                            {this.props.userDetail._id !== user._id?
                                <>
                                    <button className="btn btn-blue btn-navyblue" onClick={()=>this.openProfile(user._id)}>Visit Profile</button> <br />
                            <button className="btn btn-blue btn-navyblue" onClick={() => this.selectUser(user)}>Chat</button> <br />
                            <button className="btn btn-blue btn-navyblue" onClick={() => this.videoCalling(user._id)}>Video Call</button>
                            <button className="btn btn-blue btn-navyblue" onClick={() => this.setState({meetingModal:true})}>Request Meeting</button>
                                </>
                                :""}
                        </div>
                    </div>
              
                </div>

                <Modal
                        wrapClassName="signup_parent_modal meetingModal"
                        className="cm_popup signup_modal "
                        //cm_popup term-services
                        visible={this.state.meetingModal}
                        // onOk={this.handleOk}
                        onCancel={this.onMeetingClose}
                        footer={null}
                        centered
                    >
                        <DiaryMeetingModal {...this} {...this.props} {...this.state} onMeetingClose = {this.onMeetingClose} />
                    </Modal>
            </>
        );
    }
}





















function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(withRouter(ProfileModal));
