
import React, { Component } from 'react';
import { Hert, ExhibitionManagement, Pencil, Delete ,ZoomVideo } from '../../../../SvgIcon';
import { Link } from "react-router-dom";
import { BtnRed, LiveBtn } from "../../../../components/common/Button";
import Sidebar from '../../../layout/Sidebar';
import Header from '../../../layout/Header';
import AddStaff from '../popups/AddStaff'
import EditStaff from '../popups/EditStaff'
import EditProduct from '../popups/EditProduct'
import { Modal, Button } from 'react-bootstrap';
import AddProduct from '../popups/AddProduct';
import ReactPlayer from 'react-player';
import { _Api } from '../../../../services/Api';
import Url from '../../../../services/BaseUrl';
import { notify } from '../../../../components/Toaster';
import Media from "../../../../components/common/Media"
import Brochures from "../../../../components/common/Brochures"
import ProductShowcase from "../../../../components/common/ProductShowcase";
import MediaCarousel from "../../../../components/carousel/MediaCarousel";
import IndustrieCarousel from "../../../../components/carousel/IndustrieCarousel";

import {Empty} from "antd"


import WebHeader from '../../../layout/WebHeader';
class ProfessionalBeauty extends Component {

    constructor() {
        super();
        this.state = {
            addStaff: false,
            addProduct: false,
            editStaff: false,
            page: '',
            limit: 10,
            productListData: [],
            staffId: '',
            loading: false,
            visible: false,
            selectedId: '',
            productId: '',
            brandInfo: ''
        };
    }

    togglePopup(id, staffId) {
        console.log('produwertyuictId', id, staffId)
        if (id === "addStaff") {
            this.setState({
                addStaff: !this.state.addStaff,
            });
        }
        else if (id === "editStaff") {
            this.setState({
                editStaff: !this.state.editStaff, staffId: staffId
            });
        }

        else if (id === "editProduct") {
            this.setState({
                editProduct: !this.state.editProduct, productId: staffId
            });
        }
        else {
            this.setState({
                addProduct: !this.state.addProduct
            });
        }
    }
    componentDidMount() {
        this.getBrandDetails()
        // this.getStaffDetails()
        this.getProductDetails()

    }

    // getStaffDetails = () => {
    //     this.setState({ isLoader: true })
    //     let { page, limit, search } = this.state
    //     let req = {
    //         page: page ? page : 1,
    //         limit: limit,
    //     }
    //     _Api(Url.GET_STAFF_LIST.method, Url.GET_STAFF_LIST.url, '', `?page=${req.page}&limit=${req.limit}`)
    //         .then(resp => {
    //             console.log('respexList', resp.responseData.result.data)
    //             this.setState({ StaffListData: resp.responseData.result.data, totalCount: resp.responseData.result.totalCount, isLoader: false })
    //         }).catch(err => {
    //             this.setState({ isLoader: false })
    //             if (err.error)
    //                 notify("err", err.error.responseMessage)
    //             else if (err)
    //                 // notify("err", err.message)
    //             this.setState({ isLoader: false })
    //         })
    // }

    getProductDetails = () => {
        this.setState({ isLoader: true })
        let { page, limit, search } = this.state
        let req = {
            page: page ? page : 1,
            limit: limit,
        }
        _Api(Url.GET_PRODUCT_LIST.method, Url.GET_PRODUCT_LIST.url, '', `?page=${req.page}&limit=${req.limit}&brandId=${this.props.match.params.brandId}`)
            .then(resp => {
                console.log('respexList', resp.responseData.result.data)
                this.setState({ productListData: resp.responseData.result.data, totalCount: resp.responseData.result.totalCount, isLoader: false })
            }).catch(err => {
                this.setState({ isLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
                this.setState({ isLoader: false })
            })
    }

    deleteProduct = (id) => {
        let req = {
            isDeleted: 0,
            productId: id,
        }
        _Api(Url.DELETE_PRODUCT.method, Url.DELETE_PRODUCT.url, '', `?productId=${req.productId}&isDeleted=${req.isDeleted}`)
            .then(resp => {
                console.log('delete', resp.responseData.message)
                notify('success', resp.responseData.message)
                this.setState({ visible: false })
                this.getProductDetails()
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
            })


    }

    deleteStaff = (id) => {
        let req = {
            isDeleted: 0,
            staffId: id,

        }
        _Api(Url.DELETE_STAFF.method, Url.DELETE_STAFF.url, '', `?staffId=${req.staffId}&isDeleted=${req.isDeleted}`)
            .then(resp => {
                console.log('delete', resp.responseData.message)
                notify('success', resp.responseData.message)
                this.setState({ visible: false })
                this.getStaffDetails()
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
            })


    }
    //Show modal
    showModal = (id) => {
        this.setState({
            visible: true,
            selectedId: id
        });

    };
    //Cancel modal
    handleCancel = () => {
        this.setState({ visible: false });
    };


    productStatus = (e, id) => {
        let req = {
            productId: id.toString(),
            status: (e.target.checked ? 1 : 0),
        }
        _Api(Url.PRODUCT_STATUS.method, Url.PRODUCT_STATUS.url, '', `?productId=${req.productId}&status=${req.status}`)
            .then(resp => {
                console.log('delete', resp.responseData)
                notify('success', resp.responseData.message)
                this.setState({ visible: false })
                this.getProductDetails()
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
            })
    }

    getBrandDetails = (e, id) => {

        _Api(Url.USER_DETAILS.method, Url.USER_DETAILS.url, '', `?requestedUserId=${this.props.match.params.brandId}`)
            .then(resp => {
                console.log('jadnsklsandl', resp.responseData)

                this.setState({ StaffListData: resp.responseData.result.staffData, brandInfo: resp.responseData.result })

            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
            })
    }

    handleImageUpload = (event) => {
        console.log("kdjhgdhjaksjshdjsabbdask")
        let reader = new FileReader();
        let file = event.target.files[0];
        let { valiDate } = this.state;
        reader.addEventListener("load", () => {
            this.setState({ profilePicPreview: reader.result }, function () {
            })
        }, false);
        if (file) {
            reader.readAsDataURL(file)
        }
        if (file) {
            console.log(event, "media1", event.target.files[0])
            if (file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png' || file.type === 'image/svg+xml') {
                console.log("media2", event.target.files[0], file.size)
                if (file.size > 2000000) {
                    console.log("lkjhgfdsdfghjklkjhgfghjkmnbvccvbnm>>>>>>>>>>>>>>>>>>")
                    valiDate = false;
                    console.log(event, "media", event.target.files[0])
                    this.setState({
                        profileImage: event.target.files[0],
                        profilePicErrorMsg: 'Profile pic size should be less than 5Mb.'
                    }, () => {
                        this.updateInfoBrand()
                    });
                } else {
                    console.log("media3hhhjshs", event.target.files[0], file.size)
                    this.setState({
                        profileImage: event.target.files[0],
                        profileImageError: ''
                    }, () => {
                        this.updateInfoBrand()
                    });
                }
            } else {
                valiDate = false;
                this.setState({ media: 'File type not supported.' })

            }
        }
    }



    updateInfoBrand = (e, id) => {

        let formData = new FormData()
        formData.append('requestUserId', this.props.match.params.brandId)
        formData.append('image', this.state.profileImage)
        formData.append('coverImage', this.state.profileImage)


        _Api(Url.UPDATE_BRAND_INFO.method, Url.UPDATE_BRAND_INFO.url, formData)
            .then(resp => {
                console.log('delete', resp.responseData)
                notify('success', resp.responseData.message)
                this.setState({ visible: false })
                this.getBrandDetails()
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
            })
    }






    render() {
        let { StaffListData, productListData, visible, loading, brandInfo } = this.state;

        console.log("dsjahgdhj", brandInfo)
        return (
            <>
                <div className="dash_body_container brand ">
                    {/* <WebHeader/> */}
                    {/*<ChatBox/>*/}
                   
                    <div className="body_container">



                        <div className="header-text  mb-4 mt-3">
                            <div className="d-flex align-items-center">
                            <h4>{brandInfo?.companyName}</h4>
                            </div>

                            {/* <Link target="_blank" to="/user-management" className="btn btn-light">Back</Link> */}
                            <a > <button onClick={()=> this.props.history.goBack()} className="btn btn-light"><i class="fa fa-arrow-left" aria-hidden="true"></i>Back</button></a>



                        </div>
                        <div className="">
                            <div className="rounded-body pr-5 card-wrapper mt-0">



                                <>


                                    <div className="pos-relative">
                                        <div className="professional-beauty-banner-wrapper">
                                        <img className="img-fluid w-100 banner" src={brandInfo?.coverImage ? brandInfo?.coverImage  : require('../../../../assets/images/professional-beauty-banner.png')} />
                                            <div className="upload-wrapper">
                                                <label for="file-upload" className="upload-photo-wrapper">
                                                    <span> <p className="m-0"><i><Pencil /></i></p></span>

                                                </label>
                                                <input id="file-upload"
                                                    type="file"
                                                    onChange={this.handleImageUpload}
                                                />
                                            </div>
                                        </div>
                                        <div className="brand-logo-parent">
                                            <img className="img-fluid w-100 banner" src={require('../../../../assets/images/placeholder.png')} />
                                            <div className="upload-wrapper">
                                                <label for="file-upload" className="upload-photo-wrapper">
                                                    <span> <p className="m-0"><i><Pencil /></i></p></span>

                                                </label>
                                                <input id="file-upload"
                                                    type="file"
                                                    onChange={this.handleImageUpload}
                                                />
                                            </div>

                                        </div>
                                    </div>

                                    <div className="company-profile mt-5">
                                        <div class="header-text mb-4 mt-5 d-flex justify-content-between">
                                            <h4>Company Profile</h4>
                                        </div>
                                        <div className="profile-edit">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.  eiusmod tempor incididunt ut
        labore et dolore magna aliqua.  Quis ipsum suspendisse ultrices gravida. Risus commodo viver</p>
                                        <div className="upload-wrapper">
                                                <label for="file-upload" className="upload-photo-wrapper">
                                                    <span> <p className="m-0"><i><Pencil /></i></p></span>
                                                </label>
                                            
                                            </div>
                                            </div>

                                        {/* <div className="form-group ">

                                            <textarea rows="4"
                                                className="form-control hi"
                                                placeholder="Lorem ipsum" name="categoryDesc"

                                            />

                                        </div> */}

                                    </div>
                                    <div className="staff mt-5">
                                        <div className="header-text mb-4 mt-5 d-flex justify-content-between">

                                            <h4>Staf on the Stand</h4>
                                            <button className="btn btn-red" onClick={this.togglePopup.bind(this, 'addStaff')}>Add Staff</button>

                                        </div>
                                        <row className="row">
                                        {StaffListData?.map((item, index) => {
                                            return (

                                                <div className="col-md-4 ">
                                                <div className="card community-card">
                                                    <div className="row">
                                                        <div className="col-md-4 pr-0">
                                                            <div className="img-wrapper">
                                                                <img className="img-fluid" src={item?.thumbUrl ? item?.thumbUrl : require('../../../../assets/images/chat-user.png')} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8 no-gutter d-flex align-items-start flex-column">
                                                            <h4><strong>{item.firstName} {item.lastName}</strong></h4>
                                                            <span><small>{item.jobTitleOther}</small></span>
                                                            <span className="action_group  ">
                                                                {/* <button className="btn btn-blue" ><span className="zoom"><ZoomVideo /></span>Video Call</button> */}
                                                                <p><p className="circle-serial" ><Pencil /></p></p>
                                                                <p></p>
                                                                <p><p className="circle-serial" ><Delete /></p></p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                               
                                            )
                                        })
                                        }
                                        {StaffListData && StaffListData.length == 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                                    </row>                                       
                                           
                                      
                                       
                                    </div>

                                    <div className="Industries">
                                    <div className="text-center">
                                    <div className="header-text mb-4 mt-5 d-flex justify-content-between">
                                        <h4>Industries We serve</h4>
                                    </div>

                                        <IndustrieCarousel /> {/* Indestries Slider **/}

                                       <div className="text-right">
                                       <BtnRed to="#" text="View All Industries"/>
                                       </div>
                                    </div>
                    

                                    </div>

                                    <div className="produçt-showcase">
                                    <div className="text-center">
                                    <div className="header-text mb-4 mt-5 d-flex justify-content-between">
                                        <h4>Products/Brands</h4>
                                        <button className="btn btn-red" onClick={this.togglePopup.bind(this)}>Add Products</button>
                                    </div>
                                    <ProductShowcase
                                            {...this.props}
                                            productListData={productListData}
                                            statusProduct={this.productStatus}
                                            deleteProduct={this.deleteProduct}
                                            editSuccess={this.getProductDetails}
                                        />


                                       <div className="text-right">
                                       <BtnRed to="#" text="View All Product"/>
                                       </div>
                                    </div>
                    

                                    </div>

                                    <div className="images-video mt-5">
                                    <div className="header-text mb-4 mt-5 d-flex justify-content-between">
                                        <h4>Images/Videos</h4>
                                        
                                    </div>
                                      
                                        <Media /> 
                                        
                                        <div className="text-right">
                                       <BtnRed to="#" text="View All Media"/>
                                       </div>
                                    </div>

                                    {/* ----------------------Brochures -------------- */}
                                <div className="fragment-wrapper">
                                   <div className="header-text ">
                                        <h4>Document</h4>
                                    </div>
                                    <Brochures />
                                </div>
                                    {/* <div className="header-text mb-4 mt-5 d-flex justify-content-between">

                                        <h4>Products</h4>
                                        <button className="btn btn-red" onClick={this.togglePopup.bind(this)}>Add Products</button>

                                    </div>
                                    <row className="row text-center">
                                        <div className="col-md-3">
                                            <div className="card community-card">

                                                <img className="img-fluid" src={require('../../../../assets/images/imgpsh_fullsize_anim.png')} />
                                                <span>Product Name</span>

                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card community-card">
                                                <img className="img-fluid" src={require('../../../../assets/images/imgpsh_fullsize_anim.png')} />
                                                <span>Product Name</span>

                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card community-card">

                                                <img className="img-fluid" src={require('../../../../assets/images/imgpsh_fullsize_anim.png')} />
                                                <span>Product Name</span>


                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="card community-card">

                                                <img className="img-fluid" src={require('../../../../assets/images/imgpsh_fullsize_anim.png')} />

                                                <span>Product Name</span>
                                            </div>
                                        </div>
                                    </row>



                                    <div className="header-text mb-4 mt-5 d-flex justify-content-between">

                                        <h4>Products</h4>
                                        <button className="btn btn-red" onClick={this.togglePopup.bind(this)}>Add Products</button>

                                    </div>
                                    <row className="row text-center">
                                        <div className="col-md-3">
                                            <div className="card community-card">

                                                <img className="img-fluid" src={require('../../../../assets/images/imgpsh_fullsize_anim.png')} />
                                                <span>Product Name</span>

                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card community-card">
                                                <img className="img-fluid" src={require('../../../../assets/images/imgpsh_fullsize_anim.png')} />
                                                <span>Product Name</span>

                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card community-card">

                                                <img className="img-fluid" src={require('../../../../assets/images/imgpsh_fullsize_anim.png')} />
                                                <span>Media</span>


                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="card community-card">

                                                <img className="img-fluid" src={require('../../../../assets/images/imgpsh_fullsize_anim.png')} />

                                                <span>Product Name</span>
                                            </div>
                                        </div>
                                    </row>
                                    */}
                                    {/*-------------------------*/}
                                    {/* <div className="fragment-wrapper">
                                        <div className="header-text d-flex justify-content-between">

                                            <h4>Products/Brands</h4>
                                            <button className="btn btn-red" onClick={this.togglePopup.bind(this)}>Add Products</button>

                                        </div>
                                        <ProductShowcase />
                                    </div> */}
                                  
                                </>




                            </div>
                        </div>

                      


                        <Modal show={visible} onHide={this.handleCancel} className="cm_order_view dlt_item">
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>
                                {/* <img src={require('../../assets/img/Delete-Email.png')} /> */}
                                <br />
                                <br />
                                <p>Are you sure you want to delete this ?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="footer_btn_wrapper btn_nospace">
                                    <Button variant="warning" loading={loading} onClick={() => this.deleteStaff(this.state.selectedId)} >
                                        Yes
                        </Button>
                                    <Button variant="dark" onClick={this.handleCancel}>
                                        No
                        </Button>
                                </div>
                            </Modal.Footer>
                        </Modal>

                    </div>
                </div>

                {
                    this.state.addStaff ?
                        <AddStaff
                            {...this.props}
                            text='Close Me'
                            closePopup={this.togglePopup.bind(this, "addStaff")}
                            getStaff={this.getBrandDetails}
                        />
                        : null
                }

                {
                    this.state.addProduct ?
                        <AddProduct
                            {...this.props}
                            text='Close Me'
                            getProductDetails={this.getProductDetails}
                            closePopup={this.togglePopup.bind(this, "addProduct")}
                        />
                        : null
                }

                {
                    this.state.editStaff ?
                        <EditStaff
                            text='Close Me'
                            closePopup={this.togglePopup.bind(this, "editStaff")}
                            staffId={this.state.staffId}
                        />
                        : null
                }
                {
                    this.state.editProduct ?
                        <EditProduct
                            text='Close Me'
                            closePopup={this.togglePopup.bind(this, "editProduct")}
                            productId={this.state.productId}
                        />
                        : null
                }



            </>

        );
    }
}
export default ProfessionalBeauty;

const loop = [0, 1, 2, 3]

const chatLoop = [0, 1, 2, 3, 4, 5, 6, 7, 8]
















// import React, { Component } from 'react';
// import { Hert, ExhibitionManagement, Pencil, Delete } from '../../../../SvgIcon';
// import { Link } from "react-router-dom";
// import Sidebar from '../../../layout/Sidebar';
// import Header from '../../../layout/Header';
// import AddStaff from '../popups/AddStaff'
// import EditStaff from '../popups/EditStaff'
// import EditProduct from '../popups/EditProduct'

// import { Tab, Tabs } from 'react-bootstrap';
// import AddProduct from '../popups/AddProduct';

// import { _Api } from '../../../../services/Api';
// import Url from '../../../../services/BaseUrl';
// import { notify } from '../../../../components/Toaster';




// class ProfessionalBeauty extends Component {

//     constructor() {
//         super();
//         this.state = {
//             addStaff: false,
//             addProduct: false,
//             editStaff: false,
//             page: '',
//             limit: 10,
//             productListData: [],
//         };
//     }

//     togglePopup(id) {

//         if (id === "editProduct") {
//             this.setState({
//                 editProduct: !this.state.editProduct,
//             });
//         }

//     }
//     componentDidMount() {
//         this.getStaffDetails()
//         this.getProductDetails()
//     }

//     getStaffDetails = () => {
//         this.setState({ isLoader: true })
//         let { page, limit, search } = this.state
//         let req = {
//             page: page ? page : 1,
//             limit: limit,
//         }
//         _Api(Url.GET_STAFF_LIST.method, Url.GET_STAFF_LIST.url, '', `?page=${req.page}&limit=${req.limit}`)
//             .then(resp => {
//                 console.log('respexList', resp.responseData.result.data)
//                 this.setState({ StaffListData: resp.responseData.result.data, totalCount: resp.responseData.result.totalCount, isLoader: false })
//             }).catch(err => {
//                 this.setState({ isLoader: false })
//                 if (err.error)
//                     notify("err", err.error.responseMessage)
//                 else if (err)
//                     // notify("err", err.message)
//                 this.setState({ isLoader: false })
//             })
//     }

//     getProductDetails = () => {
//         this.setState({ isLoader: true })
//         let { page, limit, search } = this.state
//         let req = {
//             page: page ? page : 1,
//             limit: limit,
//         }
//         _Api(Url.GET_PRODUCT_LIST.method, Url.GET_PRODUCT_LIST.url, '', `?page=${req.page}&limit=${req.limit}`)
//             .then(resp => {
//                 console.log('respexList', resp.responseData.result.data)
//                 this.setState({ productListData: resp.responseData.result.data, totalCount: resp.responseData.result.totalCount, isLoader: false })
//             }).catch(err => {
//                 this.setState({ isLoader: false })
//                 if (err.error)
//                     notify("err", err.error.responseMessage)
//                 else if (err)
//                     // notify("err", err.message)
//                 this.setState({ isLoader: false })
//             })
//     }

//     render() {
//         let { StaffListData, productListData } = this.state;
//         return (
//             <>
//                 <div className="dash_body_container">
//                     <Header />
//                     <Sidebar />
//                     <div className="body_container">



//                         <div className="header-text  mb-4 mt-3">
//                             <div className="d-flex align-items-center">
//                                 <h4>Loreal</h4>
//                             </div>

//                             {/* <Link target="_blank" to="/user-management" className="btn btn-light">Back</Link> */}
//                             <Link target="_blank" to="/business" className="btn btn-light">Back</Link>


//                         </div>
//                         <div className="">
//                             <div className="rounded-body pr-5 card-wrapper mt-0">


//                                 <img className="img-fluid w-100" src={require('../../../../assets/images/professional-beauty-banner.png')} />

//                                 {/* <img className="img-fluid ggggggggg" src={require('../../../../assets/images/beauty-logo.png')} /> */}


//                                 <div className="text-wrapper w-95">
//                                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

//                                 </div>







//                                 <div className="fragment-wrapper">


//                                     <div className="header-text d-flex justify-content-between">

//                                         <h4>Staf on the Stand</h4>
//                                         <button className="btn btn-red" onClick={this.togglePopup.bind(this, 'addStaff')}>Add Staff</button>

//                                     </div>
//                                     <div className="row">
//                                         {
//                                             StaffListData?.map((item, index) => (
//                                                 <div className="col-md-4">
//                                                     <div className="card community-card">
//                                                         <div className="row">
//                                                             <div className="col-md-4">
//                                                                 <div className="img-wrapper">
//                                                                     <img className="img-fluid" src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcR04Y9YQ59uATLECEfA605xY3za-yNLbSRtRA&usqp=CAU" />
//                                                                 </div>
//                                                             </div>
//                                                             <div className="col-md-8 d-flex align-items-start flex-column">
//                                                                 <h4><strong>{item.staffName}</strong></h4>
//                                                                 <span><small>{item.jobProfile}</small></span>
//                                                                 <span className="action_group mt-auto">

//                                                                     <button className="btn btn-blue" >Connect</button>
//                                                                     <p><p className="circle-serial" onClick={this.togglePopup.bind(this, 'editStaff')}><Pencil /></p></p>
//                                                                    <p></p> <p><p className="circle-serial" onClick={this.togglePopup.bind(this, 'editStaff')}><Delete /></p></p>


//                                                                 </span>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             ))
//                                         }




//                                     </div>

//                                 </div>


//                                 <div className="fragment-wrapper">




//                                     <div className="header-text d-flex justify-content-between">

//                                         <h4>Products</h4>
//                                         <button className="btn btn-red" onClick={this.togglePopup.bind(this)}>Add Products</button>

//                                     </div>
//                                     <div className="row text-center">

//                                     <div className="col-md-3">
//                                             <div className="card community-card">
//                                                 <div className="text-right">
//                                                     <label class="switch">
//                                                         <input type="checkbox" checked />
//                                                         <span class="slider round"></span>
//                                                     </label>
//                                                 </div>



//                                                 <Link to="/job-details"> <img className="img-fluid" src={require('../../../../assets/images/imgpsh_fullsize_anim.png')} />
//                                                 </Link>

//                                                 <div className="action_group d-flex">
//                                                     <span className="flex-grow-1">Product Name <br />
//                                                         <span className="blue">$50</span></span>
//                                                     <p><p className="circle-serial" onClick={this.togglePopup.bind(this, 'editProduct')}><Pencil /></p></p>
//                                                     <p></p><p><p className="circle-serial" onClick={this.togglePopup.bind(this, 'editStaff')}><Delete /></p></p>

//                                                 </div>
//                                             </div>
//                                         </div>

//                                         <div className="col-md-3">
//                                             <div className="card community-card">
//                                                 <div className="text-right">
//                                                     <label class="switch">
//                                                         <input type="checkbox" checked />
//                                                         <span class="slider round"></span>
//                                                     </label>
//                                                 </div>



//                                                 <Link to="/job-details"> <img className="img-fluid" src={require('../../../../assets/images/imgpsh_fullsize_anim.png')} />
//                                                 </Link>

//                                                 <div className="action_group d-flex justify-content-between">
//                                                 <span className="flex-grow-1">Product Name <br />
//                                                         <span className="blue">$50</span></span>
//                                                     <p><p className="circle-serial" onClick={this.togglePopup.bind(this, 'editProduct')}><Pencil /></p></p>
//                                                     <p></p><p><p className="circle-serial" onClick={this.togglePopup.bind(this, 'editStaff')}><Delete /></p></p>

//                                                 </div>
//                                             </div>
//                                         </div>

//                                         <div className="col-md-3">
//                                             <div className="card community-card">
//                                                 <div className="text-right">
//                                                     <label class="switch">
//                                                         <input type="checkbox" checked />
//                                                         <span class="slider round"></span>
//                                                     </label>
//                                                 </div>



//                                                 <Link to="/job-details"> <img className="img-fluid" src={require('../../../../assets/images/imgpsh_fullsize_anim.png')} />
//                                                 </Link>

//                                                 <div className="action_group d-flex justify-content-between">
//                                                 <span className="flex-grow-1">Product Name <br />
//                                                         <span className="blue">$50</span></span>
//                                                     <p><p className="circle-serial" onClick={this.togglePopup.bind(this, 'editProduct')}><Pencil /></p></p>
//                                                     <p></p><p><p className="circle-serial" onClick={this.togglePopup.bind(this, 'editStaff')}><Delete /></p></p>

//                                                 </div>
//                                             </div>
//                                         </div>

//                                         <div className="col-md-3">
//                                             <div className="card community-card">
//                                                 <div className="text-right">
//                                                     <label class="switch">
//                                                         <input type="checkbox" checked />
//                                                         <span class="slider round"></span>
//                                                     </label>
//                                                 </div>



//                                                 <Link to="/job-details"> <img className="img-fluid" src={require('../../../../assets/images/imgpsh_fullsize_anim.png')} />
//                                                 </Link>

//                                                 <div className="action_group d-flex justify-content-between">
//                                                 <span className="flex-grow-1">Product Name <br />
//                                                         <span className="blue">$50</span></span>
//                                                     <p><p className="circle-serial" onClick={this.togglePopup.bind(this, 'editProduct')}><Pencil /></p></p>
//                                                     <p></p><p><p className="circle-serial" onClick={this.togglePopup.bind(this, 'editStaff')}><Delete /></p></p>

//                                                 </div>
//                                             </div>
//                                         </div>

//                                     </div>

//                                     <div className="text-right">
//                                         <Link to="/view-jobs" className="btn btn-blue-large">View All Products</Link>

//                                     </div>



//                                 </div>



//                                 <div className="fragment-wrapper">





//                                     <div className="header-text">
//                                         <h4>Media</h4>
//                                     </div>
//                                     <div className="d-flex mt-2">
//                                         <div className="row w-95 text-center">

//                                             <div className="col-md-4">
//                                                 <div className="card video-card">
//                                                     <img className="img-fluid" src={"https://canyonavenuesports.com/wp-content/uploads/Pro-Down-Square-Tackling-Dummy-Custom-Color.jpg"} />

//                                                 </div>
//                                             </div>
//                                             <div className="col-md-4">
//                                                 <div className="card video-card">

//                                                     <img className="img-fluid" src={"https://canyonavenuesports.com/wp-content/uploads/Pro-Down-Square-Tackling-Dummy-Custom-Color.jpg"} />


//                                                 </div>
//                                             </div>

//                                             <div className="col-md-4">
//                                                 <div className="card video-card">

//                                                     <img className="img-fluid" src={"https://canyonavenuesports.com/wp-content/uploads/Pro-Down-Square-Tackling-Dummy-Custom-Color.jpg"} />

//                                                 </div>
//                                             </div>

//                                         </div>
//                                         <div className="add-video">
//                                             <input type='file'
//                                             />

//                                         </div>


//                                     </div>
//                                 </div>

//                             </div>
//                         </div>


//                     </div>
//                 </div>











//                 {
//                     this.state.addStaff ?
//                         <AddStaff
//                             text='Close Me'
//                             closePopup={this.togglePopup.bind(this, "addStaff")}
//                             getStaff={this.props.getStaffDetails}
//                         />
//                         : null
//                 }

//                 {
//                     this.state.addProduct ?
//                         <AddProduct
//                             text='Close Me'
//                             closePopup={this.togglePopup.bind(this, "addProduct")}
//                         />
//                         : null
//                 }

//                 {
//                     this.state.editStaff ?
//                         <EditStaff
//                             text='Close Me'
//                             closePopup={this.togglePopup.bind(this, "editStaff")}
//                         />
//                         : null
//                 }
//                 {
//                     this.state.editProduct ?
//                         <EditProduct
//                             text='Close Me'
//                             closePopup={this.togglePopup.bind(this, "editProduct")}
//                         />
//                         : null
//                 }

//             </>
//         );
//     }
// }
// export default ProfessionalBeauty;

// const loop = [0, 1, 2, 3]

// const chatLoop = [0, 1, 2, 3, 4, 5, 6, 7, 8]















// import React, { Component } from 'react';
// import { Hert, ExhibitionManagement, Pencil, Delete } from '../../../../SvgIcon';
// import { Link } from "react-router-dom";
// import Sidebar from '../../../layout/Sidebar';
// import Header from '../../../layout/Header';
// import AddStaff from '../popups/AddStaff'
// import EditStaff from '../popups/EditStaff'
// import EditProduct from '../popups/EditProduct'

// import { Tab, Tabs } from 'react-bootstrap';
// import AddProduct from '../popups/AddProduct';

// import { _Api } from '../../../../services/Api';
// import Url from '../../../../services/BaseUrl';
// import { notify } from '../../../../components/Toaster';




// class ProfessionalBeauty extends Component {

//     constructor() {
//         super();
//         this.state = {
//             addStaff: false,
//             addProduct: false,
//             editStaff: false,
//             page: '',
//             limit: 10,
//             productListData: [],
//         };
//     }

//     togglePopup(id) {

//         if (id === "editProduct") {
//             this.setState({
//                 editProduct: !this.state.editProduct,
//             });
//         }

//     }
//     componentDidMount() {
//         this.getStaffDetails()
//         this.getProductDetails()
//     }

//     getStaffDetails = () => {
//         this.setState({ isLoader: true })
//         let { page, limit, search } = this.state
//         let req = {
//             page: page ? page : 1,
//             limit: limit,
//         }
//         _Api(Url.GET_STAFF_LIST.method, Url.GET_STAFF_LIST.url, '', `?page=${req.page}&limit=${req.limit}`)
//             .then(resp => {
//                 console.log('respexList', resp.responseData.result.data)
//                 this.setState({ StaffListData: resp.responseData.result.data, totalCount: resp.responseData.result.totalCount, isLoader: false })
//             }).catch(err => {
//                 this.setState({ isLoader: false })
//                 if (err.error)
//                     notify("err", err.error.responseMessage)
//                 else if (err)
//                     // notify("err", err.message)
//                 this.setState({ isLoader: false })
//             })
//     }

//     getProductDetails = () => {
//         this.setState({ isLoader: true })
//         let { page, limit, search } = this.state
//         let req = {
//             page: page ? page : 1,
//             limit: limit,
//         }
//         _Api(Url.GET_PRODUCT_LIST.method, Url.GET_PRODUCT_LIST.url, '', `?page=${req.page}&limit=${req.limit}`)
//             .then(resp => {
//                 console.log('respexList', resp.responseData.result.data)
//                 this.setState({ productListData: resp.responseData.result.data, totalCount: resp.responseData.result.totalCount, isLoader: false })
//             }).catch(err => {
//                 this.setState({ isLoader: false })
//                 if (err.error)
//                     notify("err", err.error.responseMessage)
//                 else if (err)
//                     // notify("err", err.message)
//                 this.setState({ isLoader: false })
//             })
//     }

//     render() {
//         let { StaffListData, productListData } = this.state;
//         return (
//             <>
//                 <div className="dash_body_container">
//                     <Header />
//                     <Sidebar />
//                     <div className="body_container">



//                         <div className="header-text  mb-4 mt-3">
//                             <div className="d-flex align-items-center">
//                                 <h4>Loreal</h4>
//                             </div>

//                             {/* <Link target="_blank" to="/user-management" className="btn btn-light">Back</Link> */}
//                             <Link target="_blank" to="/business" className="btn btn-light">Back</Link>


//                         </div>
//                         <div className="">
//                             <div className="rounded-body pr-5 card-wrapper mt-0">


//                                 <img className="img-fluid w-100" src={require('../../../../assets/images/professional-beauty-banner.png')} />

//                                 {/* <img className="img-fluid ggggggggg" src={require('../../../../assets/images/beauty-logo.png')} /> */}


//                                 <div className="text-wrapper w-95">
//                                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

//                                 </div>







//                                 <div className="fragment-wrapper">


//                                     <div className="header-text d-flex justify-content-between">

//                                         <h4>Staf on the Stand</h4>
//                                         <button className="btn btn-red" onClick={this.togglePopup.bind(this, 'addStaff')}>Add Staff</button>

//                                     </div>
//                                     <div className="row">
//                                         {
//                                             StaffListData?.map((item, index) => (
//                                                 <div className="col-md-4">
//                                                     <div className="card community-card">
//                                                         <div className="row">
//                                                             <div className="col-md-4">
//                                                                 <div className="img-wrapper">
//                                                                     <img className="img-fluid" src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcR04Y9YQ59uATLECEfA605xY3za-yNLbSRtRA&usqp=CAU" />
//                                                                 </div>
//                                                             </div>
//                                                             <div className="col-md-8 d-flex align-items-start flex-column">
//                                                                 <h4><strong>{item.staffName}</strong></h4>
//                                                                 <span><small>{item.jobProfile}</small></span>
//                                                                 <span className="action_group mt-auto">

//                                                                     <button className="btn btn-blue" >Connect</button>
//                                                                     <p><p className="circle-serial" onClick={this.togglePopup.bind(this, 'editStaff')}><Pencil /></p></p>
//                                                                    <p></p> <p><p className="circle-serial" onClick={this.togglePopup.bind(this, 'editStaff')}><Delete /></p></p>


//                                                                 </span>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             ))
//                                         }




//                                     </div>

//                                 </div>


//                                 <div className="fragment-wrapper">




//                                     <div className="header-text d-flex justify-content-between">

//                                         <h4>Products</h4>
//                                         <button className="btn btn-red" onClick={this.togglePopup.bind(this)}>Add Products</button>

//                                     </div>
//                                     <div className="row text-center">

//                                     <div className="col-md-3">
//                                             <div className="card community-card">
//                                                 <div className="text-right">
//                                                     <label class="switch">
//                                                         <input type="checkbox" checked />
//                                                         <span class="slider round"></span>
//                                                     </label>
//                                                 </div>



//                                                 <Link to="/job-details"> <img className="img-fluid" src={require('../../../../assets/images/imgpsh_fullsize_anim.png')} />
//                                                 </Link>

//                                                 <div className="action_group d-flex">
//                                                     <span className="flex-grow-1">Product Name <br />
//                                                         <span className="blue">$50</span></span>
//                                                     <p><p className="circle-serial" onClick={this.togglePopup.bind(this, 'editProduct')}><Pencil /></p></p>
//                                                     <p></p><p><p className="circle-serial" onClick={this.togglePopup.bind(this, 'editStaff')}><Delete /></p></p>

//                                                 </div>
//                                             </div>
//                                         </div>

//                                         <div className="col-md-3">
//                                             <div className="card community-card">
//                                                 <div className="text-right">
//                                                     <label class="switch">
//                                                         <input type="checkbox" checked />
//                                                         <span class="slider round"></span>
//                                                     </label>
//                                                 </div>



//                                                 <Link to="/job-details"> <img className="img-fluid" src={require('../../../../assets/images/imgpsh_fullsize_anim.png')} />
//                                                 </Link>

//                                                 <div className="action_group d-flex justify-content-between">
//                                                 <span className="flex-grow-1">Product Name <br />
//                                                         <span className="blue">$50</span></span>
//                                                     <p><p className="circle-serial" onClick={this.togglePopup.bind(this, 'editProduct')}><Pencil /></p></p>
//                                                     <p></p><p><p className="circle-serial" onClick={this.togglePopup.bind(this, 'editStaff')}><Delete /></p></p>

//                                                 </div>
//                                             </div>
//                                         </div>

//                                         <div className="col-md-3">
//                                             <div className="card community-card">
//                                                 <div className="text-right">
//                                                     <label class="switch">
//                                                         <input type="checkbox" checked />
//                                                         <span class="slider round"></span>
//                                                     </label>
//                                                 </div>



//                                                 <Link to="/job-details"> <img className="img-fluid" src={require('../../../../assets/images/imgpsh_fullsize_anim.png')} />
//                                                 </Link>

//                                                 <div className="action_group d-flex justify-content-between">
//                                                 <span className="flex-grow-1">Product Name <br />
//                                                         <span className="blue">$50</span></span>
//                                                     <p><p className="circle-serial" onClick={this.togglePopup.bind(this, 'editProduct')}><Pencil /></p></p>
//                                                     <p></p><p><p className="circle-serial" onClick={this.togglePopup.bind(this, 'editStaff')}><Delete /></p></p>

//                                                 </div>
//                                             </div>
//                                         </div>

//                                         <div className="col-md-3">
//                                             <div className="card community-card">
//                                                 <div className="text-right">
//                                                     <label class="switch">
//                                                         <input type="checkbox" checked />
//                                                         <span class="slider round"></span>
//                                                     </label>
//                                                 </div>



//                                                 <Link to="/job-details"> <img className="img-fluid" src={require('../../../../assets/images/imgpsh_fullsize_anim.png')} />
//                                                 </Link>

//                                                 <div className="action_group d-flex justify-content-between">
//                                                 <span className="flex-grow-1">Product Name <br />
//                                                         <span className="blue">$50</span></span>
//                                                     <p><p className="circle-serial" onClick={this.togglePopup.bind(this, 'editProduct')}><Pencil /></p></p>
//                                                     <p></p><p><p className="circle-serial" onClick={this.togglePopup.bind(this, 'editStaff')}><Delete /></p></p>

//                                                 </div>
//                                             </div>
//                                         </div>

//                                     </div>

//                                     <div className="text-right">
//                                         <Link to="/view-jobs" className="btn btn-blue-large">View All Products</Link>

//                                     </div>



//                                 </div>



//                                 <div className="fragment-wrapper">





//                                     <div className="header-text">
//                                         <h4>Media</h4>
//                                     </div>
//                                     <div className="d-flex mt-2">
//                                         <div className="row w-95 text-center">

//                                             <div className="col-md-4">
//                                                 <div className="card video-card">
//                                                     <img className="img-fluid" src={"https://canyonavenuesports.com/wp-content/uploads/Pro-Down-Square-Tackling-Dummy-Custom-Color.jpg"} />

//                                                 </div>
//                                             </div>
//                                             <div className="col-md-4">
//                                                 <div className="card video-card">

//                                                     <img className="img-fluid" src={"https://canyonavenuesports.com/wp-content/uploads/Pro-Down-Square-Tackling-Dummy-Custom-Color.jpg"} />


//                                                 </div>
//                                             </div>

//                                             <div className="col-md-4">
//                                                 <div className="card video-card">

//                                                     <img className="img-fluid" src={"https://canyonavenuesports.com/wp-content/uploads/Pro-Down-Square-Tackling-Dummy-Custom-Color.jpg"} />

//                                                 </div>
//                                             </div>

//                                         </div>
//                                         <div className="add-video">
//                                             <input type='file'
//                                             />

//                                         </div>


//                                     </div>
//                                 </div>

//                             </div>
//                         </div>


//                     </div>
//                 </div>











//                 {
//                     this.state.addStaff ?
//                         <AddStaff
//                             text='Close Me'
//                             closePopup={this.togglePopup.bind(this, "addStaff")}
//                             getStaff={this.props.getStaffDetails}
//                         />
//                         : null
//                 }

//                 {
//                     this.state.addProduct ?
//                         <AddProduct
//                             text='Close Me'
//                             closePopup={this.togglePopup.bind(this, "addProduct")}
//                         />
//                         : null
//                 }

//                 {
//                     this.state.editStaff ?
//                         <EditStaff
//                             text='Close Me'
//                             closePopup={this.togglePopup.bind(this, "editStaff")}
//                         />
//                         : null
//                 }
//                 {
//                     this.state.editProduct ?
//                         <EditProduct
//                             text='Close Me'
//                             closePopup={this.togglePopup.bind(this, "editProduct")}
//                         />
//                         : null
//                 }

//             </>
//         );
//     }
// }
// export default ProfessionalBeauty;

// const loop = [0, 1, 2, 3]

// const chatLoop = [0, 1, 2, 3, 4, 5, 6, 7, 8]



