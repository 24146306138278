import React, { Component } from 'react';
import Footer from '../../../layout/Footer';
import { Hert, ExhibitionManagement, Pencil, Delete , UploadVideo} from '../../../../SvgIcon';
import { Link } from "react-router-dom";
import Sidebar from '../../../layout/Sidebar';
import Header from '../../../layout/Header';
import { DatePicker, TimePicker, Select } from 'antd';
import { _Api } from '../../../../services/Api';
import Url from '../../../../services/BaseUrl';
import { notify } from '../../../../components/Toaster';
import InlineError from '../../../../components/InlineError';
import moment from 'moment-timezone';
import Loader from '../../../../components/Loader';
import TimeZone from "../../../../utils/timeZone"


import Croper from "../../../../components/Croper";
const { RangePicker } = DatePicker



class EditNetworkingLounge extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loungeName: '',
            conferenceId: '',
            loungesUrl: '',
            setOrder: '',
            startDate: moment(),
            endDate: moment(),
            startTime: moment(),
            endTime: moment(),
            numberofAttendees: '',
            loungeNameError: '',
            numberofAttendeesError: '',
            startTimeError: '',
            endDateError: '',
            setOrderError: '',
            description:'',
            isLoader:false,
            isCropper:false,
            timezone: moment.tz.guess(),


        }
    }
    onChangeDate = (value, dateString) => {      
        if (value)
            this.setState({ startDate: value[0] , startTime: value[0],  endDate: value[1], endTime: value[1] }, () => {
                console.log("abcdefghij", this.state.startDate, this.state.endDate)
            })
    }
    handleOnChange = (type, e) => {
        console.log('qwerty', e)
        this.setState({ [type]: e, startDateError: '', endDateError: '', startTimeError: '', endTimeError: "", liveDateError: '', liveTimeError: '' })
    }

    networkingLoungeOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, loungeNameError: '', numberofAttendeesError: '', startTimeError: '', endDateError: '',  setOrderError: '' })
    }

    componentDidMount() {
        this.getNetworkingLoungeList()
    }


    getNetworkingLoungeList = () => {
        this.setState({ isLoader: true })
        let { page, limit, search } = this.state
        let req = {

        }

        _Api(Url.VIEW_NETWORKING_LOUNGE.method, Url.VIEW_NETWORKING_LOUNGE.url,"","?loungeId="+""+ this.props.match.params.lgId)
            .then(resp => {
                console.log('respexList', resp.responseData.result)

                const {image,description,timezone, numberofAttendees, startDate,setOrder, endDate,  startTime, endTime , loungesUrl} = resp.responseData.result
                         this.setState({
                            timezone: timezone ? timezone :  moment.tz.guess(),
                            description,
                            numberofAttendees,
                           profilePicPreview:  image,
                            setOrder,
                            startDate: moment(new Date(startDate)), endDate: moment(new Date(endDate)), startTime: moment(new Date(startTime)), endTime: moment(new Date(endTime)) , loungesUrl,
                             
                            networkingLoungeData: resp.responseData.result, loungeName: resp.responseData.result.loungeName, 
                            isLoader: false 

                        
                        })

            }).catch(err => {
                this.setState({ isLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
                this.setState({ isLoader: false })
            })
    }

    EditNetworkingLounge = () => {
        let {description, media, loungeName, conferenceId, loungesUrl, setOrder, startDate, endDate, startTime, endTime, numberofAttendees } = this.state
        try {
            // let req = {
            //     loungeId: this.props.match.params.lgId,
            //     loungeName: loungeName,
            //     loungesUrl: loungesUrl,
            //     setOrder: setOrder,
            //     startDate: (Date.parse(endDate)),
            //     endDate: (Date.parse(endDate)),
            //     startTime: startTime,
            //     endTime: endTime,
            //     numberofAttendees: numberofAttendees
            // }

            this.setState({isLoader:true})


            let formData = new FormData()
            formData.append("loungeId", this.props.match.params.lgId)
            formData.append("description", description)              
            formData.append("loungeName", loungeName)           
            formData.append("loungesUrl",loungesUrl)
            // formData.append("setOrder", setOrder)
            // formData.append("euroAmount", 0)
            formData.append("startDate", (Date.parse(startDate)).toString())
            formData.append("endDate", (Date.parse(endDate)).toString())
            formData.append("startTime", startTime)
            formData.append("endTime", endTime)
            formData.append("image", media)
            formData.append("numberofAttendees", numberofAttendees)
            formData.append("timezone", this.state.timezone)
            _Api(Url.EDIT_NETWORKING_LOUNGE.method, Url.EDIT_NETWORKING_LOUNGE.url, formData)
                .then(resp => {
                    this.setState({isLoader:false})
                    this.props.history.goBack()
                    notify('success', resp.responseData.message)
                }).catch(err => {
                    this.setState({isLoader:false})
                    if (err.error)
                        notify("err", err.error.responseMessage)
                    // else if (err)
                        // notify("err", err.message)
                })



        } catch  { }

    }

    handleChangeTimeZone = (value) => {
        // moment.tz.setDefault(value)
       console.log("dkjhasghdjk",  moment.tz(this.state.startDate, this.state.timezone).utcOffset() )
        this.setState({ timezone: value })
    }

    handleImageUpload = (event) => {
        console.log(event, "eventeventevent", event.target.files[0])
        let reader = new FileReader();
        let file = event.target.files[0];
        let { valiDate } = this.state;

        if (file) {
            reader.readAsDataURL(file)
        }
        if (file) {
            console.log(file, "media1")
            if (file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png') {

                reader.addEventListener("load", () => {
                    this.setState({ profilePicPreview: reader.result }, function () {
                    })
                }, false);

                if (file.size > 2000000) {
                    console.log("lkjhgfdsdfghjklkjhgfghjkmnbvccvbnm>>>>>>>>>>>>>>>>>>")
                    valiDate = false;
                    console.log(event, "media", event.target.files[0])
                    this.setState({
                        media: event.target.files[0],
                        profilePicErrorMsg: 'Profile pic size should be less than 5Mb.'
                    });
                } else {
                    console.log("media3hhhjshs", event.target.files[0], file.size)
                    this.setState({
                        media: event.target.files[0],
                        mediaError: ''
                    });
                    // alert("wrong file")
                }
            } else {
                valiDate = false;
                this.setState({ media: 'File type not supported.' })
            }
        }
    }


    finalCropedImage = (categoryImgPrev, categoryImg, visibleVal) => {
        this.setState({
            visibleVal,
            isCropper: false,
            profilePicPreview: categoryImgPrev,
            media: categoryImg

        })
      
    }




    render() {
        let {isLoader, loungeUrl,description, loungeName, loungeNameError, conferenceId, loungesUrl, setOrder, setOrderError, startDate, startDateError, endDate, endDateError, startTime, startTimeError, endTime, endTimeError, numberofAttendees, numberofAttendeesError } = this.state
        return (
            <>
                <div className="dash_body_container">   
                {isLoader && <Loader />}             
                    <div className="body_container">
                        <div className="header-text  mb-4 mt-3">
                            <div className="d-flex align-items-center">
                                <p className="header-icon"><ExhibitionManagement /></p>
                                <h4>Edit Networking Lounge
                                    <br />
                                    {/* <ul className="header-breadcrum">
                                        <li><a href="#">Exhibition Management</a>/</li>
                                        <li><a href="#">Exhibition Detail</a></li>
                                    </ul> */}

                                </h4></div>

                                <a > <button onClick={()=> this.props.history.goBack()} className="btn btn-light"><i class="fa fa-arrow-left" aria-hidden="true"></i>Back</button></a>

                        </div>
                        <div className="rounded-body-form">

                            <form className="cm-form-wrapper">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <level>Lounge Name</level>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter Lounge Name"
                                                maxLength={64}
                                                name="loungeName"
                                                value={loungeName}
                                                onChange={this.networkingLoungeOnChange}
                                            />
                                            <InlineError
                                                message={loungeNameError}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <level>Allowed No. of attendees</level>
                                            <input type="number"
                                                className="form-control"
                                                placeholder="Enter Number"
                                                maxLength={64}
                                                name="numberofAttendees"
                                                value={numberofAttendees}
                                                onChange={this.networkingLoungeOnChange}
                                            />
                                            <InlineError
                                                message={numberofAttendeesError}
                                            />

                                        </div>
                                    </div>

                                    {/* <div className="col-md-6"> */}
                                        {/* <div className="form-group">
                                            <level>Set Order</level>
                                            <input type="number" className="form-control"
                                                placeholder="Set order"
                                                maxLength={64}
                                                name="setOrder"
                                                value={setOrder}
                                                onChange={this.networkingLoungeOnChange}
                                            />
                                            <InlineError
                                                message={setOrderError}
                                            />
                                        </div> */}
                                    {/* </div> */}
                                </div>

                                <div className="row">
                                <div className="col-md-6">
                                    <level>Time Zone</level>

                                        <div className="form-group">
                                            <Select showSearch autoComplete="new-password" placeholder="Time zone"
                                                value={this.state.timezone}
                                                defaultValue={this.state.timezone}

                                                onChange={(e) => this.handleChangeTimeZone(e)} name='country'

                                            >
                                                {TimeZone.map((item) => {
                                                    return (<Select.Option value={item}>{item}</Select.Option>)
                                                })}

                                            </Select>

                                        </div>


                                    </div>
                                    <div className="col-md-6">
                                    <level>Start, End date and time</level>
                                    <div className="form-group">
                                            <RangePicker
                                            inputReadOnly
                                                showTime={{ format: 'HH:mm' }}
                                                value={[moment.tz(startDate, this.state.timezone), moment.tz(endDate, this.state.timezone)]}
                                                format="DD-MM-YYYY HH:mm"
                                                onChange={this.onChangeDate}
                                            // onOk={onOk}
                                            />
                                            <InlineError
                                                message={startDateError}
                                            />
                                        </div>


                                    </div>
                                   
                                </div>

                                <div className="row">
                                    {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <level>Add Lounge URL</level>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Lounge URL"
                                                maxLength={500}
                                                name="loungeUrl"
                                                value={loungeUrl}
                                                onChange={this.networkingLoungeOnChange}
                                                />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <level>Allowed No. of attendees</level>
                                            <input type="number"
                                                className="form-control"
                                                placeholder="Enter Number"
                                                maxLength={64}
                                                name="numberofAttendees"
                                                value={numberofAttendees}
                                                onChange={this.networkingLoungeOnChange}
                                            />
                                            <InlineError
                                                message={numberofAttendeesError}
                                            />

                                        </div>
                                    </div> */}
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                      
                                        <level>Cover image</level>
                                        <div className="form-group custom-upload-file">
                                            <ul className="list-inline mt-3 pt-1">
                                                <li className="list-inline-item">
                                                    <div className="upload-inner" onClick={() => this.setState({isCropper: true})} >
                                                        {
                                                            this.state.profilePicPreview ?
                                                                <img onClick={() => this.setState({isCropper: true})} src={this.state.profilePicPreview} />
                                                                : <span className="upload-icon"><UploadVideo /></span>
                                                        }

                                                        {/* <input accept="image/*" ref="fileUploader" type="file"  placeholder="Enter Video Link"
                                                            onChange={this.handleImageUpload}
                                                            className="d-block"

                                                        /> */}
                                                    </div>
                                               
                                                </li>


                                            </ul>
                                        </div>


                                        <div className="add-video">
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <br/>
                                <br/>
                             

                                <div className="row">
                                    <level>Description</level>
                                    <textarea className="textarea form-control" rows="4"
                                        placeholder="Description..."
                                        maxLength={500}
                                        name="description"
                                        value={description}
                                        onChange={this.networkingLoungeOnChange}
                                    />
                                  
                                </div>

                                <div className="text-right">
                                    {/* <Link to="/seminardetails" onClick={this.AddNetworkingLounge} className="btn btn-primary">Save</Link> */}
                                    <button type='button' onClick={this.EditNetworkingLounge} className="btn btn-primary">Save</button>
                                </div>
                            </form>

                            {this.state.isCropper ?
                    <Croper aspect={2.5 / 2.5}
                        // onImageCrop={img => this.setState({ media: img })}
                            //imgPrev={'2.5-1.png'}
                        finalCropedImage={(categoryImgPrev, categoryImg, visibleVal) => this.finalCropedImage(categoryImgPrev, categoryImg, visibleVal)} visibleVal={this.state.isCropper}
                        onClose={() => this.setState({ isCropper: false })}
                    />
                    : ""}

                        </div>

                    </div>
                </div>
            </>
        );
    }
}
export default EditNetworkingLounge;