import React, { Component } from 'react';
import { Chat, UploadVideo, Pencil, Delete, NoMediaICon, NewBadges, Brands } from '../../SvgIcon';
import { Link } from "react-router-dom";
import { Carousel } from 'antd';
import EditProduct from '../../screens/admin/exhibiton_management/popups/EditOffer'
import { Empty } from 'antd';
import Slider from "react-slick";
import { Tooltip } from 'antd';

import { Spinner } from "react-bootstrap";

import { _Api } from '../../services/Api';
import Url from '../../services/BaseUrl';

import { notify } from '../../components/Toaster';

import { getWishList } from '../../redux/action';
// const SlickArrowRight = () => (
//     <button className="slick-next slick-arrow" aria-hidden="true" type="button">
//         <span><Charven /></span>

//     </button>
// );
// const SlickArrowLeft = () => (
//     <button className="slick-prev slick-arrow" aria-hidden="true" type="button">
//         <span className="left"><Charven /></span>
//     </button>
// );


class ProductShowcase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEdit: false,
            productId: ''

        }
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.carousel = React.createRef();
    }
    next = () => {
        this.carousel.next();
    }
    previous = () => {
        this.carousel.prev();
    }

    togglePopup(id, staffId) {

        if (id === "addStaff") {
            this.setState({
                addStaff: !this.state.addStaff,
            });
        }
        else if (id === "editStaff") {
            this.setState({
                editStaff: !this.state.editStaff, staffId: staffId, isEdit: false
            });
        }

        else if (id === "editProduct") {
            this.setState({
                editProduct: !this.state.editProduct, productId: staffId
            });
        }
        else {
            this.setState({
                addProduct: !this.state.addProduct
            });
        }
    }
    handleError(err) {
        let errorMessage = err.error && err.error.responseMessage || err.message;
        notify("err", errorMessage);
    }


    handleEdit = (id) => {

        this.setState({ isEdit: !this.state.isEdit, productId: id })
        try {
            this.props.editSuccess()
        } catch (err) { }
    }
    addToWishList = (event, category, id, api) => {

        event.preventDefault();
        event.stopPropagation();
        let stateName = `wish${id}`;
        this.setState({ [`isWishlistLoader${id}`]: true, [stateName]: !this.state[stateName] });
        let self = this;
        let params = category == "brand" ? { brandId: id } : { productId: id };
        let { method, url } = Url[api];
        _Api(method, url, params)
            .then(resp => {
                self.props.dispatch(getWishList());
                self.props.scope.getProductDetails();
                setTimeout(() => {
                    this.setState({ [`isWishlistLoader${id}`]: false });

                }, 300);


            }).catch((err) => {
                this.handleError(err);
            })
    }

    render() {
        let isFromVeiwProfile = this.props.isFromVeiwProfile;
        let userType = localStorage.getItem("userType")



        const settings = {
            infinite: true,
            speed: 500,
            slidesToShow: this.props.productListData?.length < 4 ? 1 : userType == 1 ? 3 : 5,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 1500,
            dots: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                    breakpoint: 1700,
                    settings: {
                        slidesToShow: userType == 1 ? 3 : 4,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                },
                userType == 1 ?
                    {
                        breakpoint: 1450,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            infinite: true,
                            dots: false
                        }
                    }
                    :
                    "",

                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                },

                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        dots: true
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true
                    }
                }
            ]
        }






        const CardComponentWithCarousel = (props) => {

            return (
                <div className="featured-brands showcase">
                    <div className=" row mb-3">
                        {
                            props.productListData?.map(item => {
                                return (
                                    <div className="six-div">
                                        <div className="card brand-card mb-5">

                                            {!isFromVeiwProfile && <div className="text-right">
                                                <p class="toggle-parent mb-0"><label class="switch">
                                                    <input type="checkbox"
                                                        checked={item?.status === 1 ? true : false}
                                                        onChange={(e) => props.statusProduct(e, item?._id)}
                                                    />
                                                    <span class="slider round"></span></label></p>
                                            </div>}
                                            <Link to={userType == 1 ? `/training-details/${item?._id}` : `/web-training-details/${item?._id}`}> <img className="img-fluid" src={item?.image ? item?.image : require('../../assets/images/placeholder.png')} />
                                            </Link>
                                            <Tooltip placement="bottom" title={item?.brandId?.companyName}>
                                                <div className="top-left">
                                                    {item?.brandId?.companyName && <Link to={`/business/${item?.brandId?._id}`} className="btn btn-card">{item?.brandId?.companyName}</Link>}
                                                </div>
                                            </Tooltip>
                                            <hr />
                                            <div className="action_group d-flex justify-content-between align-items-center">
                                                <span className="flex-grow-1">{item?.name} <br />

                                                </span>
                                                {!isFromVeiwProfile && < a onClick={() => props.handleEdit(item?._id)}> <p className="circle-serial mr-2" ><Pencil /></p> </a>
                                                }{!isFromVeiwProfile && < a onClick={() => props.deleteProduct(item?._id)}><p className="circle-serial"><Delete /></p></a>
                                                }
                                                <Tooltip placement="bottom" title={item?.brandId?.companyName}>



                                                </Tooltip>

                                            </div>
                                        </div>
                                    </div>

                                )
                            }
                            )
                        }
                        {/* </Carousel> */}
                    </div>
                    {/* <i className="fa fa-long-arrow-right" onClick={this.next} /> */}


                </div >


            )
        }

        const CardComponentWithOutCarousel = (props) => {

            return (
                <div className="carousel-wrapper product-showcase-slider-wrapper d-flex productshowcase">



                    {props.productListData?.map(item => {

                        return (
                            <div className="prodoct-showcase-slider">
                                <div className="card community-card">
                                    {!isFromVeiwProfile && <div className="text-right">
                                        <p class="toggle-parent mb-0"><label class="switch">
                                            <input type="checkbox"
                                                checked={item?.status === 1 ? true : false}
                                                onChange={(e) => props.statusProduct(e, item?._id)}
                                            />
                                            <span class="slider round"></span></label></p>
                                    </div>}



                                    <Link to={userType == 1 ? `/training-details/${item?._id}` : `/web-training-details/${item?._id}`} > <img className="img-fluid" src={item?.image ? item?.image : require('../../assets/images/placeholder.png')} />
                                    </Link>
                                    <hr />
                                    <div className="action_group d-flex justify-content-between align-items-center">
                                        <span className="flex-grow-1">{item?.productName} <br />
                                            {/* <span className="blue">{item?.price ?  `$${item?.price}` : ''}</span> */}
                                        </span>
                                        {!isFromVeiwProfile && <a onClick={() => props.handleEdit(item?._id)}> <p><p className="circle-serial mr-2" ><Pencil /></p></p> </a>}
                                        {!isFromVeiwProfile && <a onClick={() => props.deleteProduct(item?._id)}><p className="circle-serial"><Delete /></p></a>}
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    )
                    }


                </div>


            )
        }




        return (


            <>

                {
                    true ? <CardComponentWithCarousel {...this.props} {...this.state} {...this} /> : <CardComponentWithOutCarousel {...this.props} {...this} />
                }
                {<div className="row">
                    <div className="col-sm-12 text-center">
                        {this.props.isFromVeiwProfile && this.props.productListData?.length > 0 && <Link to={`/web-view-job`} className="btn btn-red addbtn">View All </Link>}
                    </div>
                </div>}



                {


                    this.props.productListData &&
                    this.props.productListData.length == 0 &&
                    <div className="w-100 text-center row nodata m-auto">
                        <div className="m-auto">
                            {console.log("sagdvbh", this.props.productListData.length)}
                            <NoMediaICon />
                            
                            <h2>No Data Found</h2>
                        </div>                    </div>





                }
                {
                    this.state.isEdit && <EditProduct
                        {...this.props}
                        text='Close Me'
                        closePopup={() => this.handleEdit('')}
                        productId={this.state.productId}
                        editSuccess={() => { }}
                    />
                }

            </>










        )
    }
}
export default ProductShowcase;


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div>
            <i className="fa fa-long-arrow-right" onClick={this.next} />

        </div>

    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div>
            <i className="fa fa-long-arrow-right" onClick={this.next} />

        </div>

    );
}
