import {
    PROFILE_SUCCESS
} from "../action/user.actions";

const InitalState = {
}

// get all  users details
export const userDetail = (state = InitalState , action) => {
    switch (action.type) {

        case PROFILE_SUCCESS:
            let userInfo = action.profileSuccess;
            let showcaseId =   action.showcaseId
            
            return userInfo
    
        default:
            return state
    }

}