import React, { Component } from 'react';
import { CircleTick, Hert, FooterPhone, FooterLocation, FooterMail } from '../../SvgIcon';
import Footer from '../layout/Footer';
import { Link } from "react-router-dom";
import WebHeader from "./WebHeader";
class PageNotFound extends Component {
    constructor(props) {
        super(props);
    }




    render() {
        // console.log("dkjhgsdhj", this.props)
        return (
            <section className="Home-banner-bg">
                {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
                <div className="container-fluid banner-bg">

                    <div className="row ">

                    <div className="col-lg-12 text-center">
                        <div className="notfound">
                            <div className="notfound-404">
                                <h3>Oops! Page not found</h3>
                                <h1><span>4</span><span>0</span><span>4</span></h1>
                                <h2>we are sorry, but the page you requested was not found</h2>
                            </div>
                            
                        </div>

                    </div>


                    </div>
                    <Footer />

                </div>

                {/* <div className="container-fluid Join-us-section">
                   

                  

                </div> */}

            </section>

        )
    }
}
export default PageNotFound;