import { BtnRed, CmBtn } from "../../../components/common/Button";
import { ZoomVideo, Chat, NoMediaICon } from "../../../SvgIcon";
import React, { Component } from 'react';
import WebHeader from '../../layout/WebHeader';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';
import { Link, useLocation } from "react-router-dom";
import util from '../..//../utils/Util';
import { Menu, Dropdown, Button, Empty } from 'antd';
import { MdLoader } from '../../../components/index';
import { Modal } from 'antd';
import userProfile from '../community/CommunityUserProfile.js';
import ReportModal from '../../web/community/ReportUserModal';

import FilterHeader from "../../layout/FilterHeader";
import { Pagination } from "../../../components";
import LazyLoad from 'react-lazy-load';

import Footer from "../../layout/Footer"


import { groupActions } from "../../../redux/action";

import { connect } from "react-redux";
import { Brands, Location, Hert } from '../../../SvgIcon';

import socket from "../../../services/socket";


import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { PeoppleLoader } from "../../../components/common/SkletonLoader"

const antIcon = <LoadingOutlined style={{ fontSize: 72 }} spin />;

let isProd = process.env.REACT_APP_ENV == 'prod'
var _ = require('lodash');


class ViewCommunity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            peopleList: [],
            page: 1,
            limit: 10,
            loading: true,
            isLoader: false,
            search: '',
            snNo: 0,
            totalCount: 0

        }
    }
    componentDidMount() {
      
        this.props.dispatch({ type: 'PATH_ADD', payload: this.props.match })
        this.setState({ page: this.props.match.params.page ? Number(this.props.match.params.page) : 1 }, () => {
            this.setParams(null, true);
        })


    }


    getList = () => {
        let { appLocalState } = this.props;

        this.setState({ page: 1, peopleList: [] }, () => {
            this.getfilterData(false)
        })



    }

    setParams = ()=>{
        var url_string = window.location.href
        var url = new URL(url_string);
        var c = url.searchParams.get("search");
        console.log("urllllllllllllll", JSON.parse(c))
        if(c)
        this.getfilterData2(null, true, JSON.parse(c));
        else
        this.getfilterData(null, true);

    }
    getfilterData2 = (a = null, isLoading = false, appLocalState) => {
        if (isLoading)
            window.scrollTo(0, 0)
        this.setState({ loading: true })       
        let { page, limit, search } = this.state;
        let req = {
            page: page ? page : 1,
            limit: limit,
            search: appLocalState?.search || search.toString()
        }
        let { multiFilter } = appLocalState;
        let filter = {
            placeOfWorkId: multiFilter.placeOfWorkId.join(),
            //productAndServiceOfferId: multiFilter.productAndServiceId.join(),
            //noOfPeopleWorkId: multiFilter.noOfPeopleWorkId.join(),
            //budgetId: multiFilter.budgetId.join(),
            //jobTitleId: multiFilter.jobTitleId.join(),
            //location: multiFilter.location.join(),
            //attendingOrLookingforId: multiFilter.attendingOrLookingforId.join()
            country: multiFilter.location.toString(),
            city: multiFilter.city.toString(),
            sectorId: multiFilter.sectorId.toString(),
            roleId: multiFilter.jobTitleId.toString(),
        }

        console.log("dettgyhujew", JSON.stringify(multiFilter))

        this.props.history.push(`/community/view/page=${req?.page}?search=${JSON.stringify(appLocalState)}`)




        let userAns = multiFilter.userAns;
        let keys = _.keys(userAns);
        let userAnswer = [];
        _.map(keys, function (key) {
            let data = {
                questionId: key,
                optionAnswerArray: userAns[key]
            }
            userAnswer.push(data);
        })

        if (userAnswer.length > 0) {
            filter.userAnswer = userAnswer
        }

        if (filter) {
            Object.assign(req, filter)
        }

        let url = Url["GET_PEOPLE_LIST2"].url, method = Url["GET_PEOPLE_LIST2"].method;
        _Api(method, url, req)
            .then(resp => {
                this.setState({ ["peopleList"]: resp.responseData?.result, totalCount: resp.responseData.totalCount, loading: false })
            }).catch((err) => {
                this.setState({ loading: false })
                // this.handleError(err);
            });

    }


    getfilterData = (a = null, isLoading = false) => {
        if (isLoading)
            window.scrollTo(0, 0)
        this.setState({ loading: isLoading })
        let { appLocalState } = this.props;
        let { page, limit, search } = this.state;
        let req = {
            page: page ? page : 1,
            limit: limit,
            search: appLocalState.search || search.toString()
        }
        let { multiFilter } = appLocalState;
        let filter = {
            placeOfWorkId: multiFilter.placeOfWorkId.join(),
            //productAndServiceOfferId: multiFilter.productAndServiceId.join(),
            //noOfPeopleWorkId: multiFilter.noOfPeopleWorkId.join(),
            //budgetId: multiFilter.budgetId.join(),
            //jobTitleId: multiFilter.jobTitleId.join(),
            //location: multiFilter.location.join(),
            //attendingOrLookingforId: multiFilter.attendingOrLookingforId.join()
            country: multiFilter.location.toString(),
            city: multiFilter.city.toString(),
            sectorId: multiFilter.sectorId.toString(),
            roleId: multiFilter.jobTitleId.toString(),
        }

        console.log("dettgyhujew", JSON.stringify(multiFilter))

        this.props.history.push(`/community/view/page=${req?.page}?search=${JSON.stringify(appLocalState)}`)




        let userAns = multiFilter.userAns;
        let keys = _.keys(userAns);
        let userAnswer = [];
        _.map(keys, function (key) {
            let data = {
                questionId: key,
                optionAnswerArray: userAns[key]
            }
            userAnswer.push(data);
        })

        if (userAnswer.length > 0) {
            filter.userAnswer = userAnswer
        }

        if (filter) {
            Object.assign(req, filter)
        }

        let url = Url["GET_PEOPLE_LIST2"].url, method = Url["GET_PEOPLE_LIST2"].method;
        _Api(method, url, req)
            .then(resp => {
                this.setState({ ["peopleList"]: resp.responseData?.result, totalCount: resp.responseData.totalCount, loading: false })
            }).catch((err) => {
                this.setState({ loading: false })
                // this.handleError(err);
            });

    }
    handleError(err) {
        let errorMessage = err.error && err.error.responseMessage || err.message;
        notify("err", errorMessage);
    }
    onAcceptReject(e, requestId, status) {
        e.preventDefault();
        let req = {
            requestId: requestId,
            status: status
        }
        _Api(Url.COMMUNITY_ACCEPTREJECT.method, Url.COMMUNITY_ACCEPTREJECT.url, req)
            .then(resp => {
                this.getfilterData();
            }).catch((err) => {
                this.handleError(err);
            })

    }
    onMoreClick = (e, id, userId, a, event, data) => {
        switch (e.key) {
            case "Remove":
                this.onRemoveAndBlock("COMMUNITY_CONNECTION_REMOVE", 3, id, event.domEvent);
                break;
            case "Block":
                this.onRemoveAndBlock("COMMUNITY_CONNECTION_BLOCK", 1, id, event.domEvent);
                break;
            case "Report":
                this.showModal(id, data, event.domEvent);
                break;
            case "AddBusinessConnection":
                this.onCreateBussinessRequest(userId);
                break;
            default:
                return null;


        }
    }
    onRemoveAndBlock = (api, status, id, e) => {
        this.setState({ [`connection${id}`]: true });
        e.stopPropagation();
        let req = {
            requestId: id,
            status: status
        }
        _Api(Url[api].method, Url[api].url, req)
            .then(resp => {
                this.setState({ [`connection${id}`]: false });
                this.getfilterData();
            }).catch((err) => {
                this.handleError(err);
            })

    }
    showModal = (id, data, e) => {
        e.preventDefault();
        this.setState({
            isReport: true,
            userData: data
        });
    };

    onCreateBussinessRequest = (id) => {
        this.setState({ [`connection${id}`]: true })
        let req = {
            requestedUserId: id,
            connectionType: 2
        }
        _Api(Url.COMMUNITY_CREATEREQUEST.method, Url.COMMUNITY_CREATEREQUEST.url, req)
            .then(resp => {
                this.getfilterData();
                notify("success", resp.responseData.message);
                this.setState({ [`connection${id}`]: false })
            }).catch((err) => {
                this.handleError(err);
            })
    }

    onClickConnection = (id) => {
        this.props.history.push(`/user/profile/${id}`);
    }
    // community/view/page=1
    onPressBtn = (page) => {
        this.props.history.push(`/community/view/page=${page}`)
        this.setState({ page, snNo: page - 1, }, () => {
            this.getfilterData(null, true)
        })
    }
    // videoCalling = (userId) => {
    //     let self = this;

    // if (userId) {
    //     this.setState({ startLoader: true })
    //     self.setState({
    //         ['videoConnectionLoder_' + userId]: true
    //     })
    //     self.props.dispatch(groupActions.joinOneMeeting({ userId: userId, type: 7 }))
    //         .then(function (res) {
    //             self.setState({
    //                 ['videoConnectionLoder_' + userId]: false, startLoader: false
    //             })
    //             if (res.statusCode === 1 || res.statusCode === "1") {
    //                 window.location.href = '/calling/' + self.props.groupDetail.meeting._id;
    //                 //history.push('/calling/'+self.props.groupDetail.meeting._id)
    //             } else {
    //                 this.setState({ startLoader: false })

    //             }
    //             console.log("res", res)
    //             this.setState({ startLoader: false })
    //         })
    // }
    // }
    onConnectRequest(e, requestId, status) {

        e.stopPropagation();
        let req = {
            requestedUserId: requestId,
            connectionType: 1
        }
        _Api(Url.COMMUNITY_CREATEREQUEST.method, Url.COMMUNITY_CREATEREQUEST.url, req)
            .then(resp => {
                notify("success", resp.responseData.message);
                this.getfilterData();
            }).catch((err) => {
                this.handleError(err);
            })

    }

    onLinkClick = (value, scope) => {
        _Api(Url.SEARCH_COUNT.method, Url.SEARCH_COUNT.url)
            .then(resp => {


            }).catch(err => {
                // scope.handleError(err);
            });
        scope.props.history.push(value);

    }

    videoCalling = (userId) => {
        let self = this;
        var windowReference = window.open();
        if (userId) {
            self.setState({
                ['videoConnectionLoder_' + userId]: true
            })
            self.props.dispatch(groupActions.joinOneMeeting({ userId: userId, type: 7 }))
                .then(function (res) {
                    self.setState({
                        ['videoConnectionLoder_' + userId]: false
                    })
                    if (res.statusCode === 1 || res.statusCode === "1") {
                        var newWin = window.open('/calling/' + self.props.groupDetail.meeting._id, "_blank", "width=" + window.screen.width + ",height=" + window.screen.height)
                        console.log("newWin", newWin);
                        if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
                            windowReference.location = '/calling/' + self.props.groupDetail.meeting._id;
                            //alert("New windows pop-up is blocked, Please allow.")
                        }
                    } else {

                    }
                    console.log("myWindow", res)
                })
        }
    }

    selectUser = (user) => {
        let self = this;
        // console.log("user",user);
        if (this.props.userDetail._id !== user._id) {
            this.setState({
                selectedUser: user,
                selectedUserId: user._id,
                chatType: 3,
                isShow: true
            }, function () {
                self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 3 })
                self.props.dispatch({ type: 'CHAT_OPEN' })
                document.body.classList.toggle('chat_open_mob');
                self.props.dispatch({ type: 'SELECTED_USER', selectedUser: user })
                socket.emit('groupAdd', { type: 7, userId: user._id });
                //socket.emit('userConnection', {userId: user._id});
            })
        }

    }

    



    createSearchResult = () => {
        let { peopleList } = this.state
        let desArray = []

        peopleList?.length > 0 && peopleList.forEach((item => {
            desArray.push(
                <div  className="notify-row cursor-point srch-list-rslt">
                    {/* <LazyLoad> */}
                    <div className="profile-dp notify-cht" >
                        <img onClick={(e) => this.onLinkClick(`/user/profile/${item._id}`, this)}  src={item?.image || require('../../../assets/images/placeholder/user.jpg')} className="circle-image" />
                        {/* <span className="chat" onClick={() => this.selectUser(item)} ><Chat /></span> */}
                        {<span className={`chat cursor-point ${item.isOnline == 1 ? 'green' : 'yellow'}`} onClick={() => this.selectUser(item)} ><Chat /></span>}
                    </div>
                    {/* </LazyLoad> */}

                    <div className="message">
                        <div onClick={(e) => this.onLinkClick(`/user/profile/${item._id}`, this)} >
                            <Link ><h5><b>{item?.firstName} {item?.lastName}</b><span className="mr-3">• <span><small> People</small></span></span></h5> </Link>
                            {/* <p>{item?.jobTitleId}</p> */}
                            <h6>{item?.companyName}</h6>
                            <h5 className="mt-3 mb-2"><span>{item?.country && <Location />}</span>{item?.country}</h5>
                        </div>
                        <div className="action_group srch-btn-lst">
                          { localStorage.getItem('id') != item._id &&  <span className="action_group  ">
                                {item?.userConnection.status == 1 && <button className="btn btn-blue" onClick={(e) => this.videoCalling(item._id, e)} ><span className="zoom"><ZoomVideo /></span>Video Call</button>}
                                {item?.userConnection.status == item._id && <CmBtn text="Requst Received" className="btn btn-blue" onClick={(e) => this.onClickConnection(item?._id)} />}
                                {item?.userConnection == 4 && <CmBtn text="Connect" className="btn btn-blue" bg='var(--btncolor)' onClick={(e) => this.onConnectRequest(e, item._id, 1)} />}
                                {item?.userConnection.status == 0 && item?.userConnection.requestedUserId != item._id && <CmBtn text="Cancel Request" className="btn btn-blue" bg='var(--connectBtn)' onClick={(e) => this.onRemoveAndBlock("COMMUNITY_CONNECTION_REMOVE", 3, item?.userConnection?._id, e)} />
                                }
                                {item?.userConnection.status == 0 && item?.userConnection.requestedUserId == item._id && < div className="d-flex justify-content-start">
                                    <CmBtn text=" Accept" className="btn btn-blue mr-2" bg='var(--btncolor)' onClick={(e) => this.onAcceptReject(e, item?.userConnection?._id, 1)} />
                                    &nbsp;&nbsp; <CmBtn text="Ignore" className="btn btn-blue" bg="#fff" color="#A3AAB0" border="1px solid #91cbd3" onClick={(e) => this.onAcceptReject(e, item?.userConnection?._id, 2)} />
                                </div>}
                                {(item?.userPlan == 2 || item?.userPlan == 3) && <Link to={`/business/${item?.userType == 4 ? item?.ownerId : item?._id}/${item?.activeCompany}`} className="btn btn-blue business-btn" bg="#2d688d">View Brand Showcase </Link>}
                            </span>}
                            
                        </div>
                      
                    </div>
                </div >
            )

        }))
        return desArray;

    }



    render() {

        let { peopleList, totalCount } = this.state;
        let selectedItem = this.props.selectedItem;
        const Communtiy = (props) => (<Menu onClick={(e) => this.onMoreClick(e, props.Id, props.userId, "", e, props.data)} >
            {props.isRemove && <Menu.Item key="Remove"> Remove Connection</Menu.Item>}
            <Menu.Item key="Block">Block</Menu.Item>
            <Menu.Item key="Report">Report</Menu.Item>
            {/* {props.isBusinessRequest && < Menu.Item key="AddBusinessConnection">Send business request</Menu.Item>} */}
        </Menu>)


        console.log("asjfcghjk", this.state.peopleList)

        return (

            <div className="Home-banner-bg">
                {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
                {/*     <ChatBox history={this.props.history} match={this.props.match} />*/}
                <FilterHeader {...this} scope={this} setSelected={"People"} isHideSearch={true} />

                <div className="header-wrapper">
                    <div className="container search-result-parent">
                        {/* {this.state.loading && <center><Spin size="large" tip="Loading..." indicator={antIcon} /></center>} */}
                        {/* {this.state.loading ? 
                        // <PeoppleLoader />
                        <center><Spin size="large" tip="Loading..." indicator={antIcon} /></center>
                         : */}

                        <div className="cm-wrapper">
                            <div className="checkbox-item-wrap">
                                {/* <div className="d-flex align-items-center">
                                    <p className="header-icon"><Hert className="red" /></p>
                                    {!this.state.loading &&  <h6>About {this.state.loading ? 'Loading...' : totalCount} Results found</h6>}
                                    <h6>About {totalCount} Results found</h6>
                                </div> */}
                            </div>
                            {this.state.loading && <center><Spin size="large" tip="Loading..." indicator={antIcon} /></center>}
                            <div >
                                {this.createSearchResult()}
                            </div>
                            {
                                this.state.peopleList?.length != 0 && Math.ceil(this.state.totalCount / 10) > 1 &&
                                <div >
                                    <Pagination
                                        totalPage={Math.ceil(this.state.totalCount / 10)}
                                        currentPage={this.state.page}
                                        onPressBtn={this.onPressBtn}
                                    />
                                </div>
                            }



                            {
                                !this.state.loading && this.state.peopleList?.length == 0 && <div className="w-100 text-center row nodata m-auto">
                                    <div className="m-auto">
                                        <NoMediaICon />
                                        
                                        <h2>No People Found</h2>
                                    </div>
                                </div>
                            }


                        </div>


                    </div>
                </div>


                <Footer />
            </div>



        )
    }
}

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(ViewCommunity);

