import React, { Component } from 'react';
import { Hert, Brands, Pencil, Delete ,UploadVideo} from '../../../SvgIcon';
import { Link } from "react-router-dom";
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';
import Pagination from 'react-js-pagination';
import {Select, TreeSelect} from 'antd';
import InlineError from "../../../components/InlineError";
import {_Api, _ApiMultipart} from "../../../services/Api";
import Url from "../../../services/BaseUrl";
import {notify} from "../../../components/Toaster";
import Loader from "../../../components/Loader";
import WebHeader from "../../layout/WebHeader";
import {connect} from "react-redux";

const { Option } = Select;
const { SHOW_PARENT,SHOW_CHILD,SHOW_ALL } = TreeSelect;
// import BackBtn from '../../../components/common/Button'

function handleChange(value) {
    //console.log(`selected ${value}`);
}

let isProd = process.env.REACT_APP_ENV == 'prod'

class ChangePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            oldPassword:"",
            newPassword:"",
            confPassword:"",

            oldPasswordError:"",
            newPasswordError:"",
            confPasswordError:"",

            passwordShow1:false,
            passwordShow2:false,
            passwordShow3:false,



        }
        this.onChanged=this.onChanged.bind(this);
        this.changePassword=this.changePassword.bind(this);
    }

    componentDidMount(){
        this.props.dispatch({ type: 'PATH_ADD', payload:this.props.match })
        //this.getCategoryDetails()
        //this.getCategory()
    }

    onChanged(e) {
        console.log("e", e);
        let self=this;
        let keyName=e.target.name;
        let KeyValue=e.target.value;
        this.setState({
            oldPasswordError:"",
            newPasswordError:"",
            confPasswordError:"",
        })
        this.setState({[e.target.name]: e.target.value},function () {
            // if(keyName==='searchKey'){
            //     if(KeyValue.length>=3){
            //         self.getCategory();
            //     }
            // }
        });

    }

    changePassword(){
        let self=this;
        let {placeofWorkId}=this.state;
        this.setState({
            oldPasswordError:"",
            newPasswordError:"",
            confPasswordError:"",
        })
        if (this.state.oldPassword === ''){
            this.setState({ oldPasswordError: '*Please enter old password.' })
        }
        else if (this.state.newPassword === ''){
                this.setState({ newPasswordError: '*Please enter new password.' })
        }
        else if (this.state.newPassword.length <= 4)
            this.setState({ newPasswordError: '*New Password must be greater than 4 characters.' })


        else if (this.state.confPassword !==this.state.newPassword){
            this.setState({ confPasswordError: "*Confirm password doesn't match." })
        }
        else {
            this.setState({isLoader: true})
            let param={
                oldPassword:this.state.oldPassword,
                newPassword:this.state.newPassword
            }

            _Api(Url.CHANGE_PASSWORD.method, Url.CHANGE_PASSWORD.url, param)
                .then(resp => {
                    this.setState({
                        isLoader: false,
                        oldPasswordError:"",
                        newPasswordError:"",
                        confPasswordError:"",
                        oldPassword:"",
                        newPassword:"",
                        confPassword:"",
                    })
                    notify("success", 'Password has been changed successfully')
                    //console.log("resp",resp);

                }).catch(err => {
                this.setState({isLoader: false})
                if (err.error) {
                    if (err.error.errorCode == 13) {
                        self.setState({
                            oldPasswordError: err.error.responseMessage
                        })
                    } else {
                        notify("err", err.error.responseMessage)
                    }
                } else if (err) {
                    // notify("err", err.message)
                }
            })
        }
    }



    render() {
        //console.log("this.state",this.state);
        //console.log("this.props",this.props);
        return (
            <>
              
                 <div className={this.props.userDetail && (this.props.userDetail.userType===1||this.props.userDetail.userType==="1") ?   'dash_body_container' :'dash_body_container without-sidenav' } >
                    {this.props.userDetail && (this.props.userDetail.userType===1||this.props.userDetail.userType==="1")?
                    <><Header />
                        <Sidebar />
                        </>
                    :
                    <>
                        {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
                      {/*{    <ChatBox history={this.props.history} match={this.props.match} />}*/}
                        </>
                    }

                    <div className="body_container pt-3">

                        <div className="header-text  mb-4 mb-md-0">

                            <div className="d-flex align-items-center">
                                <p className="header-icon"><Brands /></p>
                                <h4>Change Password</h4>
                            </div>

                        </div>
                        <div className="rounded-body py-5">
                            <div className="cm-form-wrapper rounded-textbox-form add-manag shadow-wrapper">
                                <div className="row change-pass">
                                    <div className="col-md-12">
                                        <div className="form-group pos-relative">
                                            <level>Old Password</level>
                                            <input type= {this.state.passwordShow1 ? 'text' :"password"  }
                                                   className="form-control"
                                                   placeholder="Enter old password" name="oldPassword" value={this.state.oldPassword}
                                                   onChange={this.onChanged}
                                            />
                                            <div className="password-show">
                                            {
                                                this.state.passwordShow1 ?
                                                    <a onClick={() => this.setState({ passwordShow1: !this.state.passwordShow1 })} ><i class="fa fa-eye" aria-hidden="true"></i> </a>
                                                    :
                                                    <a onClick={() => this.setState({ passwordShow1: !this.state.passwordShow1 })}><i class="fa fa-eye-slash" aria-hidden="true"></i></a>

                                            }
                                                </div>
                                            <InlineError
                                                message={this.state.oldPasswordError}
                                            />
                                        </div>
                                        <div className="form-group pos-relative">
                                            <level>New Password</level>
                                            <input type= {this.state.passwordShow2 ? 'text' :"password"  }
                                                   className="form-control"
                                                   placeholder="Enter new passowrd" name="newPassword" value={this.state.newPassword}
                                                   onChange={this.onChanged}
                                            />
                                            <div className="password-show">
                                            {
                                                this.state.passwordShow2 ?
                                                    <a onClick={() => this.setState({ passwordShow2: !this.state.passwordShow2 })} ><i class="fa fa-eye" aria-hidden="true"></i> </a>
                                                    :
                                                    <a onClick={() => this.setState({ passwordShow2: !this.state.passwordShow2 })}><i class="fa fa-eye-slash" aria-hidden="true"></i></a>

                                            }
                                                </div>
                                            <InlineError
                                                message={this.state.newPasswordError}
                                            />
                                        </div>
                                        <div className="form-group pos-relative">
                                            <level>Confirm Password</level>
                                            <input type= {this.state.passwordShow3 ? 'text' :"password"  }
                                                   className="form-control"
                                                   placeholder="Enter confirm password" name="confPassword" value={this.state.confPassword}
                                                   onChange={this.onChanged}
                                            />
                                            <div className="password-show">
                                            {
                                                this.state.passwordShow3 ?
                                                    <a onClick={() => this.setState({ passwordShow3: !this.state.passwordShow3 })} ><i class="fa fa-eye" aria-hidden="true"></i> </a>
                                                    :
                                                    <a onClick={() => this.setState({ passwordShow3: !this.state.passwordShow3 })}><i class="fa fa-eye-slash" aria-hidden="true"></i></a>

                                            }
                                                </div>
                                            <InlineError
                                                message={this.state.confPasswordError}
                                            />
                                        </div>

                                        <div className="form-group">

                                            <button className="btn btn-primary" type='button' onClick={this.changePassword.bind(this)} >Save</button>
                                        </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                {/*</section >*/}
            </>
        );
    }
}
function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(ChangePassword);