import React, { Component } from 'react';
// import { Hert, ExhibitionManagement, Pencil, Delete } from '../../../SvgIcon';
import { Link } from "react-router-dom";
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';
import moment from 'moment-timezone'
import Media from "../../../components/common/Media"

import WebHeader from '../../layout/WebHeader';



// // components
// import {
//     ConferenceDetails,
//     NetworkLounge,
//     Brands,
//     MyAlert,
//     MySpinner,
// } from "../../../../components/index"
// import { BackBtn } from "../../../../components/common/Button";



class MediaScreen extends Component {
    constructor(props) {
        super(props)
        this.state = {
            exhibitionDetails: '',
            mediaData: []
        }
    }
    componentDidMount() {
        
        this.getMedia()
    }




    handleMedia = (data, urll) => {
        const { media, url } = data
        let formData = new FormData()
        formData.append('exhibitionId', this.props.match.params.exId)
        formData.append('media', media ? media : '')
        formData.append('exhibitionUrl', url ? url : '')

        _Api(Url.ADD_MEDIA.method, Url.ADD_MEDIA.url, formData)
            .then(resp => {
                console.log('delete', resp.responseData)
                notify('success', resp.responseData.message)
                // this.setState({ visible: false, isEdit:false })
                this.getMedia()
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
            })

    }

    getMedia = () => {

        let mediaType = localStorage.getItem("mediaType")
        let mediaId = localStorage.getItem("mediaId")
        _Api(Url.GET_MEDIA.method, Url.GET_MEDIA.url, '', `?${mediaType}=${mediaId}`)
            .then(resp => {

                console.log("kdjskmsnakjsndkjasdn", resp)

                this.setState({
                    mediaData : resp.responseData.result
                })
                this.setState({ visible: false, isEdit:false })

            }).catch(err => {
                // if (err.error)
                //     notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
            })

    }

    render() {      
        let userType = localStorage.getItem("userType")
        return (
            <>

                { userType == 1 &&
                 <>
                    <Header />
                    <Sidebar />
                </>
                }
               <section className={`brand-home-banner-bg brandprofile-banner-bg ${userType == 1 ? "brand dash_body_container withsidenavbar" : 'pl-5 pr-5'} `} >

                    <div className="body_container">
                        <div className="header-text  mb-4 pt-3">
                            <div className="d-flex align-items-center">
                                <h4>Media</h4>
                            </div>

                            <a > <button onClick={() => this.props.history.goBack()} className="btn btn-light"><i class="fa fa-arrow-left" aria-hidden="true"></i>Back</button></a>


                        </div>
                        <div className="rounded-body-form">


                            <div className="images-video mt-4">
                                <Media
                                    {...this.props}
                                    mediaData={this.state.mediaData}
                                    getMedia={(data) => this.handleMedia(data)}
                                    addHide={true}
                                    isAdd={true}
                                    onDeleteMedia={this.getMedia}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
export default MediaScreen;