/**
 * @About 
 * This file is entry point of config
 */

import _ from 'lodash';

import {stagHjSetting, devHjSetting, betaSetting, devSetting, stagSetting, prodSetting, localNetworkSetting, localhostSetting, prod1Setting, amProdSetting, amStageSetting,devAmMay2021Setting,prodAmMay2021Setting,devJobJuly2021Setting,prodJobJuly2021Setting } from './Setting';
// import {hotjar} from "react-hotjar";




let defaultSetting = {
    api: {
        url: "https://devapi.professionalbeautyworldwide.com/pbWorld/api/v1/"
    },
    socketUrl: "https://devsocket.professionalbeautyworldwide.com",
    lat: null,
    long: null,
    google_map_key: null,
    AdminId: '5ee63999e41e842a24bbd396',
    stripe_key: "pk_test_51H2GwRFiXx7agYP2Xdb11k5lCSRYeeuuKZyCR2WzoFVC9UDkRZFzTD6KXrnQBZHrO0WYf4aICoiLEOjgKlsDIhR600phiNt4sA",
    imageUploadLimit: 2,           //MB
    videoUploadLimit: 100,          //MB
    cvUploadLimit: 5, 
    googleAnalyticsCode: "",
    newWindowTitle: "professionalbeautyworldwide",
    env:'broadCastNow_jobfinder_prod_dev'
    // hotjarId:""
}

let siteSetting = defaultSetting;
console.log("process.env", process.env);
switch (process.env.REACT_APP_ENV) {
    case "staghj":
        case "staginghj":
            siteSetting = _.extend(defaultSetting, stagHjSetting);
            break;
    case "devhj":
    case "developerhj":
        siteSetting = _.extend(defaultSetting, devHjSetting);
        break;
    case "prod":
    case "production":
        siteSetting = _.extend(defaultSetting, prodSetting);
        break;
    case "prod1":
        siteSetting = _.extend(defaultSetting, prod1Setting);
        break;
    case "beta":
        siteSetting = _.extend(defaultSetting, betaSetting);
        break;
    case "stag":
    case "staging":
        siteSetting = _.extend(defaultSetting, stagSetting);
        break;
    case "dev":
    case "developer":
        siteSetting = _.extend(defaultSetting, devSetting);
        break;
    case "localnetwork":
        siteSetting = _.extend(defaultSetting, localNetworkSetting);
        break;
    case "local":
    case "localhost":
        siteSetting = _.extend(defaultSetting, localhostSetting);
        break;
    case "amStag":
        siteSetting = _.extend(defaultSetting, amStageSetting);
        break;
    case "amProd":
        siteSetting = _.extend(defaultSetting, amProdSetting);
        break;
    case "dev-am-may2021":
        siteSetting = _.extend(defaultSetting, devAmMay2021Setting);
        break;
    case "prod-am-may2021":
        siteSetting = _.extend(defaultSetting, prodAmMay2021Setting);
        break;
    case "dev-job-july2021":
        siteSetting = _.extend(defaultSetting, devJobJuly2021Setting);
        break;
    case "prod-job-july2021":
        siteSetting = _.extend(defaultSetting, prodJobJuly2021Setting);
        break;
    default:
        siteSetting = _.extend(defaultSetting, devSetting);
}
export default siteSetting;

