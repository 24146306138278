import React, { Component, Suspense } from 'react';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { Modal, Button, Checkbox } from 'antd';
import { Calender, Clock, Hert, Ticket, TwoUsers, PlayIcon, NoMediaICon, Applebtn, Googlebtn } from "../../../SvgIcon";
import moment from "moment"
import Loader from "../../../components/Loader"
import InlineError from "../../../components/InlineError";
import { notify } from '../../../components/Toaster';
import Spotlight from '../../../screens/web/conference/Spotlight'
import WebHeader from '../../layout/WebHeader';
import _ from "lodash"
import Cookies from "js-cookie";


import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import Footer from "../../layout/Footer";

import Media from "../../../components/common/WebMedia"

import { Link, withRouter } from "react-router-dom"
import { getTimeZone } from "../../../utils/DateZone"
import { connect } from "react-redux";


import { getHome } from "../../../redux/action/Get";

import {
    MyAlert,
    MySpinner
} from "../../../components/index"
import socket from "../../../services/socket";
import AddToCalendar from 'react-add-to-calendar';

const StagJoinCard = React.lazy(() => import('../components/stages/StagJoinCard'));
const LoungeJoinCard = React.lazy(() => import('../components/lounges/LoungeJoinCard'));

const antIcon = <LoadingOutlined style={{ fontSize: 72 }} spin />;

let isProd = process.env.REACT_APP_ENV == 'prod'

class ConferenceDetail extends Component {


    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.carousel = React.createRef();

        this.state = {
            isCommingSoon: true,
            isfree: true,
            conferenceDetail: '',
            isLoading: true,
            stagList: [],
            loungList: [],
            speakersList: [],
            timetableList: [],
            password: '',
            passwordError: '',
            noOfDays: 1,
            dayStatus: [],
            npOfConference: 0,
            globalLoader: true,
            tab: 0,
            tabData: [],
            allTimeTableData: [],
            mediaData: [],
            isLoadingBtn: {},
            visible: false,
            visible1: false,
            selectedTableItem: '',
            calenderData: {},
            spotlight: [],
            selectedReminder: '',
            bannerStag: {}
        }

        window.scrollTo(0, 0)

    }

    handleSetReminder = () => {
        console.log("jhgdhjjjjjjjjjjjj", this.state.selectedTableItem)
        const { _id, stageId, } = this.state.selectedTableItem
        let req = {
            conferenceId: this.props.match.params.coId,
            sessionId: _id,
            stageId: stageId._id
        }

        _Api(Url.ADD_REMINDER.method, Url.ADD_REMINDER.url, req)
            .then(() => {
                notify("success", 'Reminder added');
                this.setState({ visible1: false })
            }).catch(err => {
                let errorMessage = err.error && err.error.responseMessage || err.message;
                notify("err", errorMessage);

            })
    }


    next = () => {
        this.carousel.next();
    }
    previous = () => {
        this.carousel.prev();
    }

    componentDidMount() {
        let self = this;
        this.getConferenceDetail()
        this.getSpotLight()
        this.props.dispatch({ type: 'PATH_ADD', payload: this.props.match })
        socket.on('loungeEnter', function (param) {
            let loungList = self.state.loungList;
            let lounge = _.find(loungList, { _id: param.loungeId });
            if (lounge) {
                lounge.onlineJoinUser = param.userCount;
                let index = _.findIndex(loungList, { _id: param.loungeId });
                loungList.splice(index, 1, lounge);
                self.setState({
                    loungList
                })
            }
        })

        socket.on('loungeExist', function (param) {
            let loungList = self.state.loungList;
            let lounge = _.find(loungList, { _id: param.loungeId });
            if (lounge) {
                if (param.userCount >= 0) {
                    lounge.onlineJoinUser = param.userCount;
                }
                let index = _.findIndex(loungList, { _id: param.loungeId });
                loungList.splice(index, 1, lounge);
                self.setState({
                    loungList
                })
            }
        })
    }

    removeDuplicates = (data) => {

        // Create an array of objects 


        // Display the list of array objects 


        // Declare a new array 
        let newArray = [];

        // Declare an empty object 
        let uniqueObject = {};

        // Loop for the array elements 
        for (let i in data) {

            // Extract the title 
            let objTitle = moment(data[i]['startDate']).format('DD-MM-YYYY')

            // Use the title as the index 
            uniqueObject[objTitle] = data[i];
        }

        // Loop to push unique object into array 
        for (let i in uniqueObject) {
            newArray.push(uniqueObject[i]);
        }

        // Display the unique objects 
        this.setState({ tabData: _.sortBy(newArray, 'startDate') }, () => {
            this.getTimeTableAccordingToDate(data, this.state.tabData[0]?.startDate)
        })

    }


    getTimeTableAccordingToDate = (data = [], search) => {

        this.setState({ timetableList: data.filter(item => moment(item.startDate).format('MM-DD-YYYY') == moment(search).format('MM-DD-YYYY')) })


    }




    getSpotLight = () => {
        let { page, limit, search } = this.state
        let req = {
            conferenceId: this.props.match.params.coId
        }
        _Api(Url.GET_WEB_SPOTLIGHT.method, Url.GET_WEB_SPOTLIGHT.url, req)
            .then(resp => {
                console.log("kijhgvfchjkl", resp?.responseData?.result)
                const { result } = resp?.responseData
                this.setState({
                    spotlight: result
                })

            }).catch(err => {


            })

    }



    getConferenceDetail = () => {
        let { page, limit, search } = this.state
        let req = {
            conferenceId: this.props.match.params.coId
        }
        _Api(Url.GET_CONFERENCE_WEB_DETAILS.method, Url.GET_CONFERENCE_WEB_DETAILS.url, req)
            .then(resp => {

                const { timeTableDetail, speakerDetail, stageDetail, loungeDetail, conferenceDetail, mediaDetail } = resp.responseData
                const { startDate, endDate } = conferenceDetail
                Cookies.set('cof_plan', JSON.stringify(conferenceDetail), { expires: 1 })

                this.removeDuplicates(timeTableDetail)

                let days = moment(endDate).diff(moment(startDate), 'days')

                console.log("safcgvhbdkm", conferenceDetail)



                this.setState({ npOfConference: days })

                var grades = {};
                timeTableDetail.forEach(function (item) {
                    var grade = grades[item.startDate] = grades[item.startDate] || {};
                    grade[item.startDate] = true;
                });


                this.setState({
                    globalLoader: false,
                    // bannerStag: stageDetail.splice(-1).pop(),
                    allTimeTableData: timeTableDetail,
                    mediaData: mediaDetail,
                    speakersList: speakerDetail, stagList: stageDetail, loungList: loungeDetail, conferenceDetail, isLoading: false
                })


            }).catch(err => {
                this.setState({ globalLoader: false, isLoading: false })

            })

    }





    onlyJoin = (name, id, coId, item = {}) => {
        const { numberofAttendees, onlineJoinUser } = item

        if (name == "lounge") {
            if (onlineJoinUser < numberofAttendees)
                window.open('/lounge/' + id, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
            else
                notify("err", "This lounges is full , you can not join at this time")
        }
        else
            this.props.history.push(`/seminar/${coId}/${name}/${id}`)

    }


    handleJoin = (conference, stageId = '', loungeId = '', ) => {
        //console.log("conference.isPassword && !conference.isAllowed",conference,conference.isPassword ,!conference.isAllowed);
        if (conference.isPassword && !conference.isAllowed) {
            this.setState({
                visible: true,

            });

        }

        else {
            this.setState({
                ['isLoadingBtn' + conference]: true, ['isLoadingBtn' + stageId]: true, ['isLoadingBtn' + loungeId]: true
            })

            let req = {
                conferenceId: conference,
                stageId: stageId,
                loungeId: loungeId,

            }
            _Api(Url.CONFERENCE_PAYMENT.method, Url.CONFERENCE_PAYMENT.url, req)
                .then(resp => {
                    this.setState({
                        ['isLoadingBtn' + conference]: false, ['isLoadingBtn' + stageId]: false, ['isLoadingBtn' + loungeId]: false
                    })
                    this.props.dispatch(getHome());

                    this.setState({ isLoadingBtn: false, })
                    this.getConferenceDetail()
                    if (stageId != '') {
                        this.onlyJoin('stage', stageId, conference)
                    }
                    else if (loungeId != '') {
                        //this.props.history.push(`/lounge/${loungeId}`)
                        window.open('/lounge/' + loungeId, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                    }
                }).catch(err => {
                    this.setState({
                        ['isLoadingBtn' + conference]: false, ['isLoadingBtn' + stageId]: false, ['isLoadingBtn' + loungeId]: false
                    })

                })
        }

    }
    handleJoinConf = (conference, conferenceDetail, stageId = '', loungeId = '', ) => {
        // console.log("jjjjjjjjjjjjjjjjjjjjj",{conferenceDetail, conference} );
        if (conferenceDetail.isPassword && !conferenceDetail.isAllowed) {
            this.setState({
                visible: true,

            });

        }

        else {
            this.setState({
                ['isLoadingBtn' + conference]: true, ['isLoadingBtn' + stageId]: true, ['isLoadingBtn' + loungeId]: true
            })

            let req = {
                conferenceId: conference,
                stageId: stageId,
                loungeId: loungeId,

            }
            _Api(Url.CONFERENCE_PAYMENT.method, Url.CONFERENCE_PAYMENT.url, req)
                .then(resp => {
                    this.setState({
                        ['isLoadingBtn' + conference]: false, ['isLoadingBtn' + stageId]: false, ['isLoadingBtn' + loungeId]: false
                    })
                    this.props.dispatch(getHome());

                    if (this.state.conferenceDetail?.isPaid) {
                        this.props.history.push(`/seminar/${this.props.match.params.coId}/payment`)
                    }


                    this.setState({ isLoadingBtn: false, })
                    this.getConferenceDetail()
                    if (stageId != '') {
                        this.onlyJoin('stage', stageId, conference)
                    }
                    else if (loungeId != '') {
                        //this.props.history.push(`/lounge/${loungeId}`)
                        window.open('/lounge/' + loungeId, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                    }
                }).catch(err => {
                    this.setState({
                        ['isLoadingBtn' + conference]: false, ['isLoadingBtn' + stageId]: false, ['isLoadingBtn' + loungeId]: false
                    })

                })
        }

    }
    handleOnChangeConference = (e) => {
        this.setState({ [e.target.name]: e.target.value, passwordError: '' })
    }
    submitPassword = (e, stageId = '', loungeId = '') => {
        if (e) e.preventDefault()
        if (this.state.password == '') {
            this.setState({ passwordError: '*Please enter password' })
        }
        else {
            this.setState({ isLoading: true })
            let req = {
                conferenceId: this.state.conferenceDetail._id,
                stageId: stageId,
                loungeId: loungeId,
                isPassword: this.state.password
            }
            _Api(Url.CONFERENCE_PAYMENT.method, Url.CONFERENCE_PAYMENT.url, req)
                .then(resp => {

                    this.setState({ isLoading: false, password: '', visible: false, })
                    this.getConferenceDetail()
                    this.handleCancel()
                }).catch(err => {
                    this.setState({ isLoading: false })
                    if (err.error)
                        notify("err", err.error.responseMessage)
                    // else if (err)
                    //     // notify("err", err.message)
                })
            // }    
        }

    }

    showModal = () => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {

        this.setState({
            visible1: false, visible: false,
        });
    };


    handleTab = (tab, date) => {
        this.getTimeTableAccordingToDate(this.state.allTimeTableData, date)
        this.setState({ tab })

    }

    handleTableClick = (item) => {
        this.setState({
            visible1: true, selectedTableItem: item,
            calenderData: {
                title: item?.sessionName,
                description: item?.description,
                location: 'UK',
                startTime: item?.startTime,
                endTime: item?.endTime
            }
        })
    }

    handleJoinConf2 = (conference, conferenceDetail, stageId = '', loungeId = '', ) => {
        this.setState({
            ['isLoadingBtn' + conference]: true, ['isLoadingBtn' + stageId]: true, ['isLoadingBtn' + loungeId]: true
        })

        let req = {
            conferenceId: conference,
            stageId: stageId,
            loungeId: loungeId,

        }
        _Api(Url.CONFERENCE_PAYMENT.method, Url.CONFERENCE_PAYMENT.url, req)
            .then(resp => {
                this.setState({
                    ['isLoadingBtn' + conference]: false, ['isLoadingBtn' + stageId]: false, ['isLoadingBtn' + loungeId]: false
                })
                this.props.dispatch(getHome())

                this.setState({ isLoadingBtn: false, })
                this.getConferenceDetail()
                if (stageId != '') {
                    this.onlyJoin('stage', stageId, conference)
                }
                else if (loungeId != '') {
                    //this.props.history.push(`/lounge/${loungeId}`)
                    window.open('/lounge/' + loungeId, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                }
            }).catch(err => {
                this.setState({
                    ['isLoadingBtn' + conference]: false, ['isLoadingBtn' + stageId]: false, ['isLoadingBtn' + loungeId]: false
                })
            })
    }

    submitPassword = (e, stageId = '', loungeId = '') => {
        if (e) e.preventDefault()
        if (this.state.password == '') {
            this.setState({ passwordError: '*Please enter password' })
        }
        else {
            this.setState({ isLoading: true })
            let req = {
                conferenceId: this.state.conferenceDetail._id,
                stageId: stageId,
                loungeId: loungeId,
                isPassword: this.state.password
            }
            _Api(Url.PASSWORD_CHECK.method, Url.PASSWORD_CHECK.url, req)
                .then(resp => {
                    this.setState({ isLoading: false, password: '', visible: false, })
                    if (this.state.conferenceDetail?.isPaid) {
                        this.props.history.push(`/seminar/${this.props.match.params.coId}/payment`)
                    }
                    else {

                        if (this.state.conferenceDetail?.isPaid == 0) {
                            console.log("dlhfdgh", this.state.conferenceDetail?.isPaid == 0)
                            this.handleJoinConf2(this.state.conferenceDetail._id, this.state.conferenceDetail)
                        }

                    }

                    this.getConferenceDetail()
                    this.handleCancel()
                }).catch(err => {
                    this.setState({ isLoading: false })
                    if (err.error)
                        notify("err", err.error.responseMessage)
                    // else if (err)
                    //     // notify("err", err.message)
                })
            // }    
        }

    }


    render() {
        console.log("conferenceDetail", this.state.bannerStag);
        const { bannerStag, mediaData, tabData, tab, globalLoader, password, passwordError, conferenceDetail, stagList, loungList, speakersList, timetableList } = this.state

        let event = {
            title: 'Sample Event',
            description: 'This is the sample event provided as an example only',
            location: 'Portland, OR',
            startTime: '2016-09-16T20:15:00-04:00',
            endTime: '2016-09-16T21:45:00-04:00'
        }
        let items = [
            { apple: 'Apple Calendar' },
            { google: 'Google' }
        ];



        if (globalLoader)
            return (
                <section className="brand-home-banner-bg webhome">
                    {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
                    <div className="centerLoader" >
                        <Spin size="large" tip="Loading..." indicator={antIcon} />
                    </div>
                </section>


            )

        else
            return (
                <section className="Home-banner-bg">
                    <Suspense fallback={<div></div>}>
                        <div className={this.state.isCommingSoon ? " container-fluid brandhome pos-relative" : " container-fluid"}>
                            <div className="cm-wrapper confreance-detail-parent conference-dd pt-4">
                               
                                {
                                    this.state.stagList.length == 0 &&                                    
                                        !this.state.isLoading && 
                                        <div className="w-100 text-center row nodata m-auto">
                                            <div className="m-auto">
                                                <NoMediaICon />
                                                
                                                <h2>No Stage Found</h2>
                                            </div>
                                        </div>

                                        
                                    

                                }

<>
                                      
                                        <div className="row mt-3">
                                            <div className="col-sm-12">                                                
                                                <div className="header-text justify-content-end mb-0">
                                                <a > <button onClick={() => this.props.history.goBack()} className="btn btn-light"><i class="fa fa-arrow-left" aria-hidden="true"></i>Back</button></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3 pos-relative">
                                            {/* <div className="tonext d-none d-sm-block">
                                        <i class="fa fa-long-arrow-right" />
                                        </div> */}

                                            {
                                                this.state.stagList?.map((item, index) => {
                                                    console.log("lkjmk", item)
                                                    return (
                                                        <a
                                                            className="col-sm-6 col-md-4 col-xl-3"
                                                            onClick={() => item.isAllowed ? this.onlyJoin('stage', item._id, conferenceDetail._id) : this.handleJoin(item.conferenceId, item._id)}
                                                        >
                                                            {/* <div className="col-sm-6 col-md-4 col-xl-3">                                                            */}
                                                            <div className="conference-block">
                                                                <div className="img">
                                                                    <img className="img-fluid mb-4" src={item.image ? item.image:require('../../../assets/images/placeholder.png')} />
                                                                </div>

                                                                <div className="text">
                                                                    <div className="leftborder-parent">
                                                                        {/* <h4>{item?.stageName}</h4> */}
                                                                        <h5>{item?.stageName}</h5>
                                                                        <h6>
                                                                            <span><Calender /></span>
                                                                            <span className="ml-2">{moment(item?.startDate).format('MMM DD')}&nbsp;</span>
                                                                        </h6>
                                                                        <h6>
                                                                            <span><Clock /></span>
                                                                            <span className="ml-2">{getTimeZone(item?.startTime, item?.timezone)}  to   {moment(item?.endDate).format('MMM DD')}, {getTimeZone(item?.endTime, item?.timezone)}</span>

                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* </div> */}
                                                        </a>
                                                    )
                                                })
                                            }
                                        </div>
                                       
                                    </>                            
                              
                            </div>
                            

                        </div>
                        <Footer />
                        <Modal
                            footer={null}
                            centered
                            visible={this.state.visible1}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                            wrapClassName="mail_modal"
                            className="cm_popup signup_modal "
                        >
                            <div className="sign-modal-content">
                                <div className="row align-items-center">
                                    <div className="col-md-12" >
                                        <h3 className="auth-heading pos-relative text-center mt-5">
                                            Attending Session<span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                        </h3>
                                        {/* <h4 className="text-center mt-5 mb-5">Do you want to add a reminder in: </h4> */}
                                        <div className="text-center">
                                            <AddToCalendar
                                                event={{ ...this.state.calenderData }}
                                                buttonLabel="Do you want to add a reminder in"
                                                listItems={items} />
                                            {/* <Link to="" class="calenderbtn">
                                            <span>Google Calender</span>
                                            <span><Googlebtn/></span>
                                        </Link> */}
                                            {/* <br/>
                                        <Link to="" class="calenderbtn">
                                            <span>Apple Calender</span>
                                            <span><Applebtn/></span>
                                        </Link>
                                         */}
                                            <a onClick={this.handleSetReminder} class="calenderbtn">
                                                <span>Reminder for session</span>
                                                {/* <span><Applebtn /></span> */}
                                            </a>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </Modal>

                        <Modal
                            footer={null}
                            centered
                            visible={this.state.visible}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                            className="paymentpopup"
                        >
                            <div className="rounded-textbox-form mt-5 mb-4 w-100" >
                                <div className="row">
                                    <form className="col-md-10 offset-md-1" onSubmit={this.submitPassword} >
                                        <div className="form-group">
                                            <level>Enter password</level>
                                            <input type="password"
                                                className="form-control"
                                                placeholder="Enter password"
                                                maxLength={50}
                                                name="password"
                                                value={password}
                                                onChange={this.handleOnChangeConference}
                                            />
                                            <InlineError
                                                message={this.state.passwordError}
                                            />
                                        </div>

                                        <div className="form-group text-center">
                                            <button type="submit" class="btn btn-red btn-label" onClick={this.submitPassword}>Submit</button>
                                        </div>

                                    </form>
                                </div>
                            </div>

                        </Modal>



















                    </Suspense>

                </section>
            )
    }

}

function mapStateToProps(state) {


    return {
        ...state

    };
}

export default connect(mapStateToProps)(ConferenceDetail);