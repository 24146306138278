import React, { Component } from 'react';

import WebHeader from '../../screens/layout/WebHeader'
import Footer from "../../screens/layout/Footer"
import FilterHeader from "../../screens/layout/FilterHeader";
import {CmBtn} from "./Button";
import { Hert } from "../../SvgIcon"

class Filter extends Component {




    render() {
        let isMobile = true;

        return (

            <section className="brand-home-banner-bg">

                <WebHeader />

                {/* <FilterHeader isMobile ={isMobile} /> */}

                <div className="container-fluid cm-section">

                    <div className="web-filter">

                        <div className="header-container m-0">
                            <div className="header-text d-flex flex-grow-1">
                                <h1 className="title">All Job Filter</h1>
                            </div>

                            <CmBtn text="Clear" bg="#B8D0D6" className="btn-filter"/>
                            <CmBtn text="Cancel" bg="#D23A27" className="btn-filter"/>
                            <CmBtn text="Apply" bg="#316689" className="btn-filter"/>
                        </div>

                        <hr className="mb-0"/>

                        <div className="row no-gutters">
                            {/* <div className="col-lg-4 border-right">
                                <div className="checkbox-item-wrap border-bottom">


                                    <div className="d-flex align-items-center">

                                         <p className="header-icon"><Hert className="red" /></p>
                                        <h5>  Place of work</h5>
                                    </div>


                                    {
                                        loop.map(item => (
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input"
                                                       type="checkbox"
                                                />
                                                <label className="form-check-label"
                                                       htmlFor="inlineCheckbox2">Input Amount</label>
                                            </div>
                                        ))
                                    }
                                </div>

                                <div className="checkbox-item-wrap">

                                    <div className="d-flex align-items-center">
                                        <p className="header-icon"><Hert className="red" /></p>
                                    <h5>Product and services they offer</h5>
                                    </div>

                                    {
                                        loop.map(item => (
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input"
                                                       type="checkbox"
                                                />
                                                <label className="form-check-label"
                                                       htmlFor="inlineCheckbox2">Professional Beauty & spa supplies</label>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div> */}









                            {/* <div className="col-lg-4 border-right">

                                <div className="checkbox-item-wrap">

                                    <div className="d-flex align-items-center">
                                        <p className="header-icon"><Hert className="red" /></p>
                                    <h5>Product and services they offer</h5>
                                    </div>

                                    {
                                        loop2.map(item => (
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input"
                                                       type="checkbox"
                                                />
                                                <label className="form-check-label"
                                                       htmlFor="inlineCheckbox2">Professional Beauty & spa supplies</label>
                                            </div>
                                        ))
                                    }
                                </div>


                            </div> */}







                            <div className="col-lg-4">




                                <div className="checkbox-item-wrap border-bottom">

                                    <div className="d-flex align-items-center">
                                        <p className="header-icon"><Hert className="red" /></p>
                                    <h5>Number of people I work with</h5>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">

                                            {
                                                loop.map(item => (
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input"
                                                               type="checkbox"
                                                        />
                                                        <label className="form-check-label"
                                                               htmlFor="inlineCheckbox2">just me</label>
                                                    </div>
                                                ))
                                            }

                                        </div>
                                        <div className="col-md-6">

                                            {
                                                loop.map(item => (
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input"
                                                               type="checkbox"
                                                        />
                                                        <label className="form-check-label"
                                                               htmlFor="inlineCheckbox2">1-6</label>
                                                    </div>
                                                ))
                                            }

                                        </div>
                                    </div>

                                </div>


                                {/* <div className="checkbox-item-wrap border-bottom">

                                    <div className="d-flex align-items-center">
                                        <p className="header-icon"><Hert className="red" /></p>
                                    <h5>What is your budget to spend in next 6 months</h5>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">

                                            {
                                                loop.map(item => (
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input"
                                                               type="checkbox"
                                                        />
                                                        <label className="form-check-label"
                                                               htmlFor="inlineCheckbox2">$0 - $1000</label>
                                                    </div>
                                                ))
                                            }

                                        </div>
                                        <div className="col-md-6">

                                            {
                                                loop.map(item => (
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input"
                                                               type="checkbox"
                                                        />
                                                        <label className="form-check-label"
                                                               htmlFor="inlineCheckbox2">$1000 - 5 million</label>
                                                    </div>
                                                ))
                                            }

                                        </div>
                                    </div>

                                </div> */}


                                <div className="checkbox-item-wrap">

                                    <div className="d-flex align-items-center">
                                        <p className="header-icon"><Hert className="red" /></p>
                                    <h5>Add a country</h5>
                                    </div>

                                    <div className="cm-form-wrapper" style={{ maxWidth: "100%"}}>


                                        <div className="form-group">
                                            <input type="text"
                                                   className="form-control"
                                                   placeholder="Add a country"
                                            />
                                        </div>

                                    </div>
                                            {
                                                loop.map(item => (
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input"
                                                               type="checkbox"
                                                        />
                                                        <label className="form-check-label"
                                                               htmlFor="inlineCheckbox2">just me</label>
                                                    </div>
                                                ))
                                            }


                                </div>





                            </div>
                        </div>
                </div>


                </div>


                <div className="container-fluid">
                    <Footer/>
                </div>
            </section>




        )
    }
}
export default Filter;

const loop = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

const loop2 = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

const tableLoop = [0, 1, 2, 3, 4];