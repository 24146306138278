import React, { Component } from 'react';
import { Logout, CircleTickFill, Hert, CircleCross, LandingUser, LandingBrand, LandingPremiumBrand, HertBorder } from '../../SvgIcon';
import { injectStripe } from 'react-stripe-elements'
import { Link } from "react-router-dom";
import ReactMomentCountDown from 'react-moment-countdown';
import moment from 'moment-timezone';
import Cookies from 'js-cookie'
import { _Api } from '../../services/Api';
import Url from '../../services/BaseUrl';
import YouTube from 'react-youtube';
import WebHeader from '../layout/WebHeader';
import { Tabs } from 'antd';
import { connect } from "react-redux";
const dateInFuture = moment('2020-09-08');
const currentDate = moment();
const future = moment('2020-09-08 10:03:02');
// const timeLeft = moment(future.diff(currentDate)).format("HH:mm:ss");
//Landing page 


const { TabPane } = Tabs;
var countDownDate = new Date("May 17, 2021 08:00:00 GMT").getTime();
class LandingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            days: '',
            hours: '',
            minutes: '',
            seconds: '',
            totalAmount: '$500.00',
            isPlan: false,
            plans: [],
            sideList: []
        }
        window.scrollTo(0, 0)
    }

    componentDidMount() {
        if (localStorage.getItem('accessToken') && localStorage.getItem('accessToken') !== '') {
            // this.getRole()
            this.getPlan()
            this.getPlan2()
            setInterval(() => this.time(), 1000);
        } else {
            this.props.history.push('/')
        }

    }

    getPlan2 = () => {

        _Api(Url.GET_PLAN_USER_SIDE_LIST.method, Url.GET_PLAN_USER_SIDE_LIST.url, '')
            .then(resp => {
                console.log("pankajyadav", resp.responseData.result)
                this.setState({
                    sideList: resp.responseData.result

                })
            }).catch(err => { })
    }



    getPlan = () => {

        _Api(Url.GET_PLAN_USER_SIDE.method, Url.GET_PLAN_USER_SIDE.url, '')
            .then(resp => {
                console.log("pankajyadav", resp.responseData.result)
                this.setState({
                    plans: resp.responseData.result

                })
            }).catch(err => { })
    }






    time = () => {

        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        this.setState({ days: days, hours: hours, minutes: minutes, seconds: seconds })

    }


    goToNext = (amount) => {
        Cookies.set("amt_plan", JSON.stringify(amount))
        // this.props.history.push("/payment")
        this.props.history.push("/pay-user")

    }


    getRole = () => {
        _Api(Url.GET_ROLE.method, Url.GET_ROLE.url, '')
            .then(resp => {
                if (resp.statusCode == 1) {

                    this.setState({ isPlan: resp.responseData.result?.userPlan == 1 ? false : true })

                }
                else { }
            }).catch(err => { })

    }

    handleLogout = () => {
        // let flag = window.confirm("Are you sure want to logout ?")
        // if (flag) {
        Cookies.remove('amt_plan');
        Cookies.remove('step0');
        Cookies.remove('step');
        localStorage.clear()
        setTimeout(() => window.location.href = ('/'), 300)

        //}
    }

    getButtOn = (matched) => {
        switch (matched) {
            case 0:
                return <CircleTickFill />
            case 1:
                return <CircleCross/>
            case 2:
                return "prohibited"
            case 3:
                return "unlimited"
            case 4:
                return "limit"
            case 5:
                return "text"
            default:
                return <CircleCross/>;
        }
    }

    render() {
        const opts = {
            height: '400',
            width: '640',
            playerVars: {
                // https://developers.google.com/youtube/player_parameters
                autoplay: 0,
            },
        };
        localStorage.setItem('totalAmount', this.state.totalAmount)

        let { days, hours, minutes, seconds } = this.state
        return (

            // <button type="button" className="btn btn-primary"  onClick={()=> this.props.history.push("/sign-in")}>JOIN NOW</button>
            <section className="Home-banner-bg landing-page cm-home brand-home-banner-bg cyan_bg hj">
                {/* <WebHeader history={this.props.history} match={this.props.match} /> */}

                <div className="container minusmargin-header text-center mb-5">
                    <h1 className="home-heading pos-relative fontw-300 ">
                        Upgrade to a showcase
                        <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                    </h1>
                    {/* <p className="home-subheading">Brands and Suppliers choose your plan, starting from just $500.</p> */}
                </div>
                <div className="container ">

                    <div className="tablediv hidemobile991">
                        <div className="first gray" style={{border: "none"}}>
                            <div className="first gray" style={{ width : "100%"}}>

                                <div className="header">
                                    <h1 className="text-center"><span><LandingBrand /></span></h1>
                                    <h6>Brand Showcase</h6>
                                    <h3>{this.state.totalAmount}</h3>
                                </div>
                                <div className="tb_body">
                                {
                                                this.state.sideList.map((item, index) => {
                                                    return (
                                                        <p>{item.name}</p>
                                                    )
                                                })
                                            }
                                </div>
                            </div>
                            {/* <span className="mt-2"><small>* Subject to editorial control. Video to be supplied 1 week ahead of the event** Subject to timing</small></span> */}
                        </div>
                        {
                                    this.state.plans.map((item, index) => {
                                        console.log("ehwjfkm", item)
                                        return (
                                            <div className={(index + 1) % 2 == 0 ? "Second red" : (index + 1) % 3 == 0 ? 'Second blue' : 'Second sky-blue'} style={{ border: "none" }}>
                                                <div className={(index + 1) % 2 == 0 ? "Second red" : (index + 1) % 3 == 0 ? 'Second blue' : 'Second sky-blue'} style={{ width: "100%" }}>
                                                    <div className="header">
                                                        {/* <h1 className="text-center"><span><LandingUser /></span></h1> */}
                                                        <h6> {item.planName}</h6>
                                                        {
                                                            item.planType == 0 ? <h3>Free</h3> : <h3>{item.defaultCurrency == 1 ? `£${item.euroAmount}` : `$${item.dollarAmount}`}</h3>
                                                        }
                                                          {index == 0 && <span className="jobPlnSml">for those activity looking for work</span>}
                                                           {index == 1 && <span className="jobPlnSml">for salons, spas etc</span>}
                                                                        {index == 2 && <span className="jobPlnSml">for recruitment, travel agencies etc</span>}

                                                    </div>

                                                    {
                                                        item.optionPlan.map((item2, index2) => {
                                                            return (
                                                                <p><span className="correct">{item2.type == 5 ? item2.value : item2.type == 4 ? item2.value : this.getButtOn(item2.type)}</span></p>

                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div className="btn-parent mt-2">
                                                    {item.planType != 0 && index != 0 && <button onClick={() => this.goToNext({defaultVatText : item.defaultVatText, defaultCurrency: item.defaultCurrency,  usdVat: item.dollarVatAmount, gbpVat: item.euroVatAmount, usd: item.dollarAmount, gbp: item.euroAmount, purchased: 0, planId: item._id })} type="button" className="btn btn-red w-75">Buy Now</button>}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                        
                        
                        
                    </div>
                    {/* -----------------------for responsive------------------ */}
                    <div className="showmobile991 plan-responsive">

                        <Tabs defaultActiveKey="1" className="home-tab">
                            
                            
                        {
                                        this.state.plans?.map((item, index) => {
                                            return (
                                                <TabPane tab={item.planName} key={index + 1}>
                                                    <div className="table-responsivee">
                                                        <table className="table table-bordered rounded-table">
                                                            <thead>
                                                                <td colSpan="2">
                                                                    <div className="header" >
                                                                        <h6 className="text-light-black">{item.planName}</h6>
                                                                        {
                                                                            item.planType == 0 ? <h3 className="text-light-black">Free</h3> : <h3 className="text-light-black">{item.defaultCurrency == 1 ? `£${item.euroAmount}` : `$${item.dollarAmount}`}</h3>
                                                                        }
                                                                          {index == 0 && <span className="jobPlnSml">for those activity looking for work</span>}
                                                                           {index == 1 && <span className="jobPlnSml">for salons, spas etc</span>}
                                                                        {index == 2 && <span className="jobPlnSml">for recruitment, travel agencies etc</span>}


                                                                    </div>
                                                                </td>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    item.optionPlan.map((item2, index2) => {
                                                                        return (
                                                                            <tr>
                                                                                <td> <p>
                                                                                {this.state.sideList[index2]?.name}
                                                                                     </p></td>
                                                                                <td><p><span className="correct">{item2.type == 5 ? item2.value : item2.type == 4 ? item2.value : this.getButtOn(item2.type)}</span></p></td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>

                                                        </table>
                                                        <center> <div className="btn-parent mt-2">
                                                                {index != 0 && item.planType != 0 && <button onClick={() => this.goToNext({defaultVatText : item.defaultVatText, defaultCurrency: item.defaultCurrency, usdVat: item.dollarVatAmount, gbpVat: item.euroVatAmount, usd: item.dollarAmount, gbp: item.euroAmount, purchased: 0, planId: item._id })} type="button" className="btn btn-red w-75">Buy Now</button>}
                                                            </div>
                                                            </center>
                                                    </div>
                                                </TabPane>
                                            )
                                        })
                                    }
                        </Tabs>

                    </div>

                </div>


                <br /><br />



            </section>




        )
    }
}
// export default injectStripe(LandingPage);
function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(LandingPage);

const worldEductionLeading = [
    {
        img: 'World Spa & Wellness.png',
        text: 'World Spa & Wellness',
        link: 'http://professionalbeauty.co.uk/site/WSWConvention2020'
    },
    {
        img: 'Contents, Ingredients and Packaging.png',
        text: 'Contents, Ingredients and Packaging',
        link: 'http://professionalbeauty.co.uk/site/CIPIConference2020 ',
    },
    {
        img: 'International Manufacturers and Distributors.png',
        text: 'International Manufacturers and Distributors',
        link: 'http://professionalbeauty.co.uk/site/IMFConference2020'
    },
    {
        img: 'Aesthetic Medicine.png',
        text: 'Aesthetic Medicine',
        link: 'https://aestheticmed.co.uk/site/AMLiveOnlineConference'
    },
    {
        img: 'Salon Business.png',
        text: 'Salon Business',
        link: 'http://professionalbeauty.co.uk/site/SalonBusinessForumProgramme'
    },
    {
        img: 'Hair and Beauty Workshops.png',
        text: 'Hair and Beauty Workshops',
        link: 'https://professionalbeauty.co.uk/site/hairandbeautyworkshops'
    }
]


const speakers = [
    {
        img: 'Aaron Chatterley, Founder - FeelUnique.png',
        text: 'Aaron Chatterley',
        des: ' Founder - \n' +
            'FeelUnique'
    },
    {
        img: 'Andrew Gibson, Chairman, Wellness Tourism Association.png',
        text: 'Andrew Gibson',
        des: ' Chairman, Wellness Tourism Association'

    },
    {
        img: 'Anna Brightman, Co-Founder - UpCircle.png',
        text: 'Anna Brightman',
        des: ' Co-Founder UpCircle'
    },
    {
        img: 'Antonia Burrell, Celebrity Facialist.png',
        text: 'Antonia Burrell',
        des: 'Celebrity Facialist'
    },
    {
        img: 'Hellen Ward, Co-Founder, Richard Ward Hair & Metrospa.png',
        text: 'Hellen Ward',
        des: 'Co-Founder, Richard Ward Hair & Metrospa'
    },
    {
        img: 'Mr Ali Ghanem, Consultant Aesthetic Plastic Surgeon and Clinical Senior Lecturer Barts & London School of Medicine & Dentistry.png',
        text: 'Mr Ali Ghanem',
        des: 'Consultant Aesthetic Plastic Surgeon and Clinical Senior Lecturer Barts & London School\n' +
            'of Medicine & Dentistry'
    }
]





const networking = [
    {
        img: 'Chat Icon.png',
        text: 'Search people and brands'
    },
    {
        img: 'Digital Business Card icon.png',
        text: 'Chat one-to-one'
    },
    {
        img: 'Network in virtual lounges icon.png',
        text: 'Meet on a video call'
    },
    {
        img: 'Search Icon.png',
        text: 'Leave a digital business card'
    },
    {
        img: 'Video icon.png',
        text: 'Network in virtual lounges'
    }

]


const brand = [
    {
        img: 'celluma.png'
    },
    {
        img: 'cosmetica updated logo.png'
    },
    {
        img: 'gharieni_logo_grau.png'
    }
    ,
    {
        img: 'Jett Medical.png'
    },
    {
        img: 'Jessica.png'
    },

    {
        img: 'Madi Logo copy.png'
    },
    {
        img: 'Mii.png'
    },
    {
        img: 'Schwarzkopf copy.png'
    }

]
