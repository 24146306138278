import promise from 'bluebird'

import { _Api } from "../../services/Api";
import Url from "../../services/BaseUrl";
import {getWishList} from "./Get";
import {SIGNUP_PROCESS} from "./authSetting.actions";
import {TOGGLE_QUESTION} from "./setting.actions";

export const PROFILE_REQUEST = 'PROFILE_REQUEST';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PROFILE_ERROR = 'PROFILE_ERROR';

export const userActions = {
    profile,
};

function profile(params) {
    try {
        return function (dispatch) {
            return new promise(function (resolve, reject) {
                dispatch(request(params));

                _Api(Url.USER_DETAILS.method, Url.USER_DETAILS.url, '')
                    .then(response => {
                        resolve(response);
                        console.log("cskjvhbjcksacnjs", response.responseData); 
                        localStorage.setItem("userType", response.responseData?.result?.userType)
                        if(response.responseData?.result?.activeCompany)
                        localStorage.setItem("company", response.responseData?.result?.activeCompany)
                        dispatch(success({
                            ...response.responseData.result,
                            isQuestionComplete:response.responseData.isQuestionComplete,
                            servicesShowcaseId: response.responseData.servicesShowcaseId ? response.responseData.servicesShowcaseId : [],
                            userPlan: response.responseData?.userPlan
                        }));
                        if(response.responseData&&response.responseData.isQuestionComplete===false){
                            dispatch({ type: TOGGLE_QUESTION, value: 1 });
                        }
                    })
                    .catch((err) => {
                        // console.log("err",err);
                        // localStorage.clear();
                        // window.location.reload();
                        reject(err);
                        dispatch(failure(err));
                    })
            })
        };
        function request(profileRequest) { return { type: PROFILE_REQUEST, profileRequest } }
        function success(profileSuccess, showcaseId) { return { type: PROFILE_SUCCESS, profileSuccess, showcaseId } }
        function failure(profileFail) { return { type: PROFILE_ERROR, profileFail } }
    } catch (err) { }
}

// function logout(params) {
//
//     return function (dispatch) {
//
//         return new promise(function (resolve, reject) {
//
//             params.deviceToken = 'sfsafdsfsafsa';
//             const apiParam = new URLSearchParams();
//             apiParam.append('deviceToken', 'sadsadsadas');
//             apiParam.append('userId', params.userId);
//
//             dispatch(request(apiParam));
//             userService.logout(apiParam, params)
//                 .then(response => {
//                     resolve(response);
//                     let { serverResponseData } = ResponseFilter(response);
//                     dispatch(success(serverResponseData.data));
//                 })
//                 .catch((err) => {
//                     reject(err);
//                     dispatch(failure(err));
//
//                 })
//         })
//     };
//     function request(profileRequest) { return { type: userConstants.PROFILE_REQUEST, profileRequest } }
//     function success(logoutSuccess) { return { type: userConstants.LOGOUT_SUCCESS, logoutSuccess } }
//     function failure(profileFail) { return { type: userConstants.PROFILE_ERROR, profileFail } }
// }
// function changePassword(params) {
//
//     return function (dispatch) {
//
//         return new promise(function (resolve, reject) {
//             params.deviceToken = 'sfsafdsfsafsa';
//             dispatch(request(params));
//             userService.changePassword(params)
//                 .then(response => {
//                     console.log(response,'jhtgyhedede');
//
//                     resolve(response);
//                     let { serverResponseData } = ResponseFilter(response);
//                     if (serverResponseData.data) {
//                         let data = serverResponseData.data;
//                     }
//                     dispatch(success(serverResponseData.data.reviews));
//                 })
//                 .catch((err) => {
//                     console.log(err,'jhtgyhbgbgedede');
//                     reject(err);
//                     dispatch(failure(err));
//
//                 })
//         })
//     };
//     function request(changePasswordRequest) { return { type: userConstants.CHANGEPASSWORD_REQUEST, changePasswordRequest } }
//     function success(changePasswordSuccess) { return { type: userConstants.CHANGEPASSWORD_SUCCESS, changePasswordSuccess } }
//     function failure(changePasswordFail) { return { type: userConstants.CHANGEPASSWORD_ERROR, changePasswordFail } }
// }
