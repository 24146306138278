import React, { Component } from 'react';
import { Hert, ExhibitionManagement, Pencil, Delete } from '../../../../SvgIcon';
import { Link } from "react-router-dom";
import Sidebar from '../../../layout/Sidebar';
import Header from '../../../layout/Header';
import { Tab, Tabs } from 'react-bootstrap'
import AddStaff from '../popups/AddStaff'
import AddProduct from '../popups/AddProduct';
import { _Api } from '../../../../services/Api';
import Url from '../../../../services/BaseUrl';
import { notify } from '../../../../components/Toaster';
import moment from 'moment-timezone'
import Media from "../../../../components/common/Media"
import { Stages, Lounges } from "./AddNewConference"
import Spotlight from "./Spotlight/Spotlight"
import { Modal, Button, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { getTimeZone, getTimeFormate } from "../../../../utils/DateZone"

const { confirm } = Modal;


class ConfirenceDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addStaff: false,
            addProduct: false,
            conferencedetailsData: '',
            conferenceName: '',
            exhibitionFee: '',
            media: '',
            stageData: [],
            loungeData: [],
            mediaData: [],
            password: '',
            coverImg: '',
            description: '',
            visible: false,
            tabActive: 'stages'

        };
    }

    togglePopup(id) {

        if (id === "addStaff") {
            this.setState({
                addStaff: !this.state.addStaff,
            });
        }
        else {
            this.setState({
                addProduct: !this.state.addProduct
            });
        }

    }
    componentDidMount() {
        this.setState({ tabActive: localStorage.getItem('cf_tabActive') ? localStorage.getItem('cf_tabActive') : 'stages' })

        this.getParticularConference()
        this.getMedia()
        this.handleAllMedia()
    }

    getParticularConference = () => {
        let req = {
            conferenceId: this.props.match.params.coId
        }
        _Api(Url.GET_PARTICULAR_CONFERENCE.method, Url.GET_PARTICULAR_CONFERENCE.url, '', `?conferenceId=${req.conferenceId}`)
            .then(resp => {
                console.log('cinid', resp.responseData.result)
                let params = resp.responseData.result
                this.setState({ conferencedetailsData: resp.responseData.result, description: resp.responseData.result.description ? resp.responseData.result.description : '', coverImg: params.coverImage, password: params.isPublic ? params.isPassword : '', loungeData: params.loungeData, stageData: params.stageData, conferenceName: params.conferenceName, dollarAmount: params.dollarAmount, euroAmount: params.euroAmount, media: params.media, isLoader: false })
                // this.props.history.push(`/seminardetails/${this.props.match.params.exid}`)
            }).catch(err => {
                this.setState({ isLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
                else if (err)
                    this.setState({ isLoader: false })
                // notify("err", err.message)
            })
    }

    handleMedia = (data, urll) => {

        const { media, url } = data
        console.log("kcjhsgdhbcjnksdjbhvg", media, url)

        let formData = new FormData()
        formData.append('conferenceId', this.props.match.params.coId)
        formData.append('media', media ? media : '')
        formData.append('conferenceUrl', url ? url : '')
        _Api(Url.ADD_MEDIA.method, Url.ADD_MEDIA.url, formData)
            .then(resp => {
                notify('success', resp.responseData.message)
                this.getMedia()
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
            })

    }




    getMedia = () => {
        _Api(Url.GET_MEDIA.method, Url.GET_MEDIA.url, '', `?conferenceId=${this.props.match.params.coId}&limit=${3}&page=${1}`)
            .then(resp => {
                this.setState({
                    mediaData
                        : resp.responseData.result
                })
                // this.setState({ visible: false, isEdit:false })

            }).catch(err => {
                // if (err.error)
                //     notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
            })

    }

    handleAllMedia = () => {
        localStorage.setItem("mediaType", "conferenceId")
        localStorage.setItem("mediaId", this.props.match.params.coId)
    }

    statusStages = (status, id) => {
        let req = {
            stageId: id,
            status: status,
        }
        _Api(Url.GET_STAGE_STATUS.method, Url.GET_STAGE_STATUS.url, '', `?stageId=${req.stageId}&status=${req.status}`)
            .then(resp => {
                // console.log('delete', resp.responseData)
                // notify('success', resp.responseData.message)
                // this.setState({ visible: false })
                this.getParticularConference()


            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
            })

    }

    // changeSTatus
    statusStagesForMain = (status, id) => {
        let req = {
            stageId: id,
            status: status,
        }
        _Api(Url.CHANGE_FORLIVE_STAGE.method, Url.CHANGE_FORLIVE_STAGE.url, '', `?conferenceId=${this.props.match.params.coId}&stageId=${req.stageId}&status=${req.status}`)
            .then(resp => {
                // console.log('delete', resp.responseData)
                // notify('success', resp.responseData.message)
                // this.setState({ visible: false })
                this.getParticularConference()


            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
            })

    }










    statusLounges = (status, id) => {
        let req = {
            loungeId: id,
            status: status,
        }
        _Api(Url.GET_LOUNGE_STATUS.method, Url.GET_LOUNGE_STATUS.url, '', `?loungeId=${req.loungeId}&status=${req.status}`)
            .then(resp => {
                this.getParticularConference()
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
            })
    }



    handleDeleteLounge = (id) => {
        let req = {
            loungeId: id,
        }
        _Api(Url.DELETE_LOUNGE.method, Url.DELETE_LOUNGE.url, '', `?loungeId=${req.loungeId}`)
            .then(resp => {
                this.getParticularConference()
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
            })
    }







    handleDeleteStag = (id) => {
        let req = {
            stageId: id,
            isDeleted: 0,


        }
        _Api(Url.DELETE_STAG.method, Url.DELETE_STAG.url, '', `?stageId=${req.stageId}&isDeleted=${req.isDeleted}`)
            .then(resp => {
                // console.log('delete', resp.responseData)
                // notify('success', resp.responseData.message)
                // this.setState({ visible: false })
                this.getParticularConference()


            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
            })

    }



    // delete modal
    onStagDelete = (data) => {
        confirm({
            title: 'Are you sure you want to delete this?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            onOk: () => {
                this.handleDeleteStag(data._id)
            },
            onCancel: () => {
                this.handleCancel()
            },
        });
    }



    //Cancel modal
    handleCancel = () => {
        // console.log('delete', resp.responseData.message)
        this.setState({ visible: false });
    };



    onDeleteLounge = (data) => {
        confirm({
            title: 'Are you sure you want to delete this?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            onOk: () => {
                this.handleDeleteLounge(data._id)
            },
            onCancel: () => {
                this.handleCancel()
            },
        });

    }


    handleTab = (tab) => {
        localStorage.setItem('cf_tabActive', tab)
        this.setState({ tabActive: tab })
    }

    render() {
        let { conferenceName,
            exhibitionFee,
            stageData,
            loungeData,
            password,
            dollarAmount, euroAmount,
            coverImg,
            description,
            conferencedetailsData,

            media } = this.state;
        var aestTime = new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
        console.log('jlasokdsadosakdsad', conferencedetailsData?.startDate)


        console.log("jlasokdsadosakdsad", moment.tz.guess())
        return (
            <>
                <div className="dash_body_container">
                    <div className="body_container">
                        <div className="header-text  mb-4 mt-3">

                            <div className="d-flex align-items-center">
                                <p className="header-icon"><ExhibitionManagement /></p>
                                <h4>{conferenceName}</h4>
                            </div>
                            <a > <button onClick={() => this.props.history.goBack()} className="btn btn-light"><i class="fa fa-arrow-left" aria-hidden="true"></i>Back</button></a>
                        </div>
                        <div className="rounded-body pb-5 pl-4 pr-4">
                            <div className=" pb-5 pl-4 pr-4 pt-5">
                                <div className="header-text  mb-4 mt-3">
                                    <div className="ml-auto">
                                        <Link className="btn btn-red" to={`/seminardetails/${this.props.match.params.exId}/add-new-seminar/${this.props.match.params.coId}`} >
                                            Edit</Link>
                                    </div>
                                </div>
                                <div className="table-responsive table-inverse-color">
                                    <table class="table vertical-table table-bordered rounded-table inverse-color">
                                        <thead>
                                            <tr>
                                                <td>Seminar Name</td>
                                                <td>{conferenceName}</td>
                                            </tr>
                                            <tr>
                                            <td>Start Date</td>
        <td>{moment(conferencedetailsData?.startDate ).format('dddd')} {moment(conferencedetailsData?.startDate).format('LL')} {getTimeZone(conferencedetailsData?.startDate, moment.tz.guess())}</td>
                                        </tr>
                                        <tr>
                                            <td>End Date</td>
        <td>{moment(conferencedetailsData?.endDate ).format('dddd')} {moment(conferencedetailsData?.endDate).format('LL')} {getTimeZone(conferencedetailsData?.endDate, moment.tz.guess())}</td>
                                        </tr>   
                                            <tr>
                                                <td>Number of Stages</td>
                                                <td>{stageData?.length}</td>
                                            </tr>
                                            <tr>
                                                <td>Number of Lounges</td>
                                                <td>{loungeData?.length}</td>
                                            </tr>
                                          {password &&  <tr>
                                                <td>Conference Password</td>
                                                <td>{password}</td>
                                            </tr>}
                                            <tr>
                                                <td>Conference Fee</td>
                                                <td>{conferencedetailsData.isPaid == 1 ?<p><p>{conferencedetailsData?.dollarAmount} $</p>  <p>{conferencedetailsData?.euroAmount} €</p> </p> : 'Free'}</td>
                                               
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                                {description &&
                                    <>
                                        <div className="header-container">
                                            <div className="d-flex align-items-center">
                                                <h4>Description</h4>
                                                {/* <br/>*/}
                                            </div>

                                        </div>
                                        <h6>{description}</h6>
                                    </>
                                }

                                {/* <div className="row">

                                <div className="header-text  mb-4 mt-5 d-flex justify-content-start">
                                    <h4 className="mb-auto">Cover image</h4>
                                </div>
                                <div className="confrence-cover">
                                    <div className="card video-card">
                                        <img className="img-fluid" src={coverImg} />
                                    </div>
                                </div>
                                <div className="header-text  mb-4 mt-5 d-flex justify-content-start">
                                    <h4 className="mb-auto">Cover image</h4>
                                </div>
                                <div className="confrence-cover">
                                    <div className="card video-card">
                                        <img className="img-fluid" src={coverImg} />
                                    </div>
                                </div>
                                <div className="header-text  mb-4 mt-5 d-flex justify-content-start">
                                    <h4 className="mb-auto">Cover image</h4>
                                </div>
                                <div className="confrence-cover">
                                    <div className="card video-card">
                                        <img className="img-fluid" src={coverImg} />
                                    </div>
                                </div>               <div className="col-sm-12 mt-3 mb-3">
                                            <div className="header-text">
                                                <h1 className="title">Description</h1>
                                            </div>
                                            <h6>{description}</h6>

                                        </div>
                                    </div> */}

                                <div className="header-text  mb-4 mt-5 d-flex justify-content-start">
                                    <h4 className="mb-auto">Cover image</h4>
                                </div>
                                <div className="confrence-cover">
                                    <div className="card video-card">
                                        <img className="img-fluid" src={coverImg} />
                                    </div>
                                </div>


                                <div className="header-container">
                                    <div className="d-flex align-items-center">
                                        <h4>Media</h4>
                                    </div>
                                </div>
                                <div className="images-video mt-5">
                                    <Media
                                        {...this.props}
                                        mediaData={this.state.mediaData}
                                        getMedia={(data, url) => this.handleMedia(data)}
                                        onDeleteMedia={this.getMedia}
                                    />
                                    {/* { this.state.mediaData && <div className="row">
                            <div className="col-sm-12 text-center">
                                <button onClick={this.handleAllMedia} type="button" className="btn btn-red addbtn">View All Media</button>
                            </div>
                        </div>} */}
                                </div>

                                <div className="blue-border card-wrapper confrence-view-detail new-confrence-height">

                                    <Tabs activeKey={this.state.tabActive} onSelect={(e) => this.handleTab(e)} id="uncontrolled-tab-example">
                                        <Tab eventKey="stages" title="Stages">
                                            <Stages
                                                {...this.props}
                                                stagesList={this.state.stageData}
                                                statusStages={(status, id) => this.statusStages(status, id)}
                                                statusStagesForMain = {(status, id) => this.statusStagesForMain(status, id)}
                                                isAdded={true}
                                                onStagDelete={this.onStagDelete}

                                            // getParticularConference
                                            />
                                        </Tab>
                                        <Tab eventKey="lounges" title="Lounges">
                                            <Lounges
                                                {...this.props}
                                                loungeList={this.state.loungeData}
                                                statusLounges={(status, id) => this.statusLounges(status, id)}
                                                isAdded={true}
                                                onDeleteLounge={this.onDeleteLounge}
                                            />
                                        </Tab>
                                        <Tab eventKey="Spotlight" title="Spotlight">
                                            <Spotlight
                                                {...this.props}
                                                loungeList={this.state.loungeData}
                                                statusLounges={(status, id) => this.statusLounges(status, id)}
                                                isAdded={true}
                                                onDeleteLounge={this.onDeleteLounge}
                                            />
                                        </Tab>
                                    </Tabs>


                                </div>

                            </div>


                        </div>

                    </div>
                </div>
            </>
        );
    }
}
export default ConfirenceDetails;




