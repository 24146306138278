/**
 * @about admin js file contains admin routing of app
 * routing app
 * react-router-dom
 */
// react lib
import React, { Component } from 'react';
// roucting lib
import {
    Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';




// custome components & screens & pages
import {
    // AddCategoryManagement,
    AddNewExhibition,
    ExhibitonManagement,
    AddBrand,
    AddNewConference,
    AddStages,
    AddLounge,
    ExhibitionView,
    TableConference,
    EditConference,
    EditNetworkingLounge,
    AddNetworkingLounge,
    NetworkingDetails,
    ConferenceDetailView,
    AdManagementComp,
    AddNewAd,
    AdViewDetails,
    ProfessionalBeauty,
    AssignBrand,
    AssignSpot,
    AssignProduct,
    AssignOffer,
    EditNewExhibition,
    Attendee,
    ProductCategory,
    ProductCategoryList,
    Reminder,
    Dashboard
} from "../../screens/index"

import 
    Header
 from "../../screens/layout/Header"
 import 
    Sidebar
 from "../../screens/layout/Sidebar"

import PageNotFoundInner from '../../screens/layout/PageNotFoundInner';

import BrandProfile from '../../screens/web/brand/BrandProfile';
import QuestionnaireList from '../../screens/admin/questionnaire/QuestionnaireList';
import AddQuestionnaire from '../../screens/admin/questionnaire/AddQuestionnaire';
import ViewQuestionnaire from '../../screens/admin/questionnaire/ViewQuestionnaire';
import ViewQuestionAnswerList from '../../screens/admin/questionnaire/ViewQuestionAnswerList';
import AdminManageServices from '../../screens/web/brand/AdminManageServices';
import RoleList from "../../screens/admin/RoleManagement/RoleList";
import AddRole from "../../screens/admin/RoleManagement/AddRole";
import EditRole from "../../screens/admin/RoleManagement/EditRole";
import PowList from "../../screens/admin/placeOfWorkManagement/PowList";
import AddPow from "../../screens/admin/placeOfWorkManagement/AddPow";
import EditPow from "../../screens/admin/placeOfWorkManagement/EditPow";
import sectorList from "../../screens/admin/sectorManagement/sectorList";
import AddSector from "../../screens/admin/sectorManagement/AddSector";
import EditSector from "../../screens/admin/sectorManagement/EditSector";
import EditSectorsCategories from "../../screens/web/brand/EditSectorsCategories"
import SuportAdmin from '../../components/support/SuportAdmin';
import NotificationList from '../../screens/admin/notificationList/Notification';
import EditNotification from '../../screens/admin/notificationList/EditNotification';
import SendNotification from '../../screens/admin/notificationList/SendNotification';
import SubscriptionPlan from '../../screens/admin/subscriptionPlan/SubscriptionPlan';
import AddSubscriptionPlan from '../../screens/admin/subscriptionPlan/AddSubscriptionPlan';
import InviteCreate from '../../screens/admin/Invite/CreateInvite';
import InviteLit from '../../screens/admin/Invite/InviteList';
import InviteUserList from '../../screens/admin/Invite/UserData';

import ManageCompany from '../../screens/web/brand/ManageCompany';
import EditManageCompany from '../../screens/web/brand/EditManageCompany';





const Admin = ({ match }) => {
    const { path } = match   
    return (
        <div className="dash_body">
            <Header />
            <Sidebar />
            <Switch>
                <Route exact path={`/exhibition`} component={ExhibitonManagement} />
                {/* <Route exact path={exhibitonmanagement`} component={ExhibitonManagement} /> */}

                <Route
                    exact
                    path={`/addexhibition/:stepName/:step`}
                    component={AddNewExhibition} />
                    <Route
                    exact
                    path={`/analytics`}
                    component={Dashboard} />
                <Route
                    exact
                    path={`/addexhibition/:stepName/:step/:exId`}
                    component={AddNewExhibition} />
                      <Route
                    exact
                    path={`/exhibition/edit/:exId`}
                    component={EditNewExhibition} />
                <Route exact
                    path={`/professional-beauty-world/:exId`}
                    component={ExhibitionView} />
                <Route
                 exact
                    path={`/business/details/:brandId`}
                    component={BrandProfile} />
                      <Route
                 exact
                    path={`/business/details/:brandId/:companyId`}
                    component={BrandProfile} />
            <Route exact path="/suport-admin" component={SuportAdmin} />
            <Route exact path='/edit-sectors-categories/:id' component={EditSectorsCategories} />
            <Route exact path='/edit-sectors-categories/:id/:cId' component={EditSectorsCategories} />

                <Route exact path={`/addbusiness/`} component={AddBrand} />
                <Route exact path={`/manageservices/:id`} component={AdminManageServices} />
                <Route exact path={`/addbusiness/:exId`} component={AddBrand} />
                <Route exact path={`/assignbusiness/:exId`} component={AssignBrand} />
                <Route exact path={`/assignspotlight/:exId/:coId`} component={AssignSpot} />
                <Route exact path={`/assignspotlight/job/:type/:exId/:coId`} component={AssignProduct} />
                <Route exact path={`/assignspotlight/training/:exId/:coId`} component={AssignOffer} />
                <Route exact path={`/seminardetails/:exId/add-new-seminar`} component={AddNewConference} />
                <Route exact path={`/seminardetails/:exId/add-new-seminar/:coId`} component={AddNewConference} />
                <Route exact path={`/seminardetails/:exId/view/:coId`} component={ConferenceDetailView} />
                <Route exact path={`/add-stages/:coId`} component={AddStages} />
                <Route exact path={`/add-stages/:coId/:stId`} component={AddStages} />
                <Route exact path={`/add-lounge/:coId`} component={AddLounge} />
                <Route exact path={`/add-lounge/:coId/:lgId`} component={AddLounge} />
                <Route exact path={`/seminardetails/:exid/networking/editnetworkinglounge/:lgId`} component={EditNetworkingLounge} />
                <Route exact path={`/seminardetails/:exid/networking/add-networking-lounge`} component={AddNetworkingLounge} />
                {/* <Route exact path={`/seminardetails/:exid/networking/add-networking-lounge/:lgId`} component={AddNetworkingLounge} /> */}

                <Route exact path={`/editconference/:id`} component={EditConference} />
                <Route exact path={`/table-conference`} component={TableConference} />
                <Route exact path={`/networking-details/:id`} component={NetworkingDetails} />

                <Route exact path={`/ads`} component={AdManagementComp} />
                <Route exact path={`/reminder`} component={Reminder} />
                <Route exact path={`/add-ads`} component={AddNewAd} />
                <Route exact path={`/add-ads/:adId`} component={AddNewAd} />
                <Route exact path={`/ads-details/:adId`} component={AdViewDetails} />
                <Route exact path={`/questionnaire`} component={QuestionnaireList} />
                <Route exact path={`/view-questionnaire/:id`} component={ViewQuestionnaire} />
                <Route exact path={`/view-questionn-answer-list/:id`} component={ViewQuestionAnswerList} />
                <Route exact path={`/addQuestionnaire`} component={AddQuestionnaire} />

                <Route exact path={`/attendee`} component={Attendee} />
                <Route exact path={`/jobcategory/add`} component={ProductCategory} />
                <Route exact path={`/jobcategory/edit/:PId`} component={ProductCategory} />
                <Route exact path={`/jobcategory`} component={ProductCategoryList} />
                <Route exact path={`/notification-list`} component={NotificationList} />
                <Route exact path={`/edit-notification`} component={EditNotification} />
                <Route exact path={`/send-notification`} component={SendNotification} />
                <Route exact path={`/send-notification/:id`} component={SendNotification} />
                <Route exact path={`/subscription-plan`} component={SubscriptionPlan} />
                <Route exact path={`/add-subscription-plan`} component={AddSubscriptionPlan} />
                <Route exact path={`/edit-subscription-plan/:id`} component={AddSubscriptionPlan} />

                <Route exact path='/manage-company/:id/:cId' component={ManageCompany} />

                <Route exact path='/edit-manage-company/:id/:cId' component={EditManageCompany} />
        
                <Route
                    exact
                    path="/role"
                    component={RoleList}
                />

                <Route
                    exact
                    path="/role/add"
                    component={AddRole}
                />

                <Route
                    exact
                    path="/role/edit/:id"
                    component={EditRole}
                />

                <Route
                    exact
                    path="/placeofwork"
                    component={PowList}
                />

                <Route
                    exact
                    path="/placeofwork/add"
                    component={AddPow}
                />

                <Route
                    exact
                    path="/placeofwork/edit/:id"
                    component={EditPow}
                />

                <Route
                    exact
                    path="/sector"
                    component={sectorList}
                />

                <Route
                    exact
                    path="/sector/add"
                    component={AddSector}
                />
                <Route
                    exact
                    path="/sector/edit/:sectorId"
                    component={EditSector}
                />

<Route
                    exact
                    path="/create-invite"
                    component={InviteCreate}
                />
                  <Route
                    exact
                    path="/create-invite/:id"
                    component={InviteCreate}
                />
                <Route
                    exact
                    path="/invite"
                    component={InviteLit}
                />
                 <Route
                    exact
                    path="/invite-users/:id"
                    component={InviteUserList}
                />




                <Route path='/404' component={PageNotFoundInner} />
                <Route path='*' component={PageNotFoundInner} status={404} />
            </Switch>
        </div>

    )
}


export default Admin;
