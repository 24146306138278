import React, { Component } from 'react'
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';
import { Link } from "react-router-dom";
import '../../../assets/css/adManagement.css'
import { AdManagement, Filter, Pencil, Delete, UploadVideo } from '../../../SvgIcon';
import { DatePicker, TimePicker, Select, Modal } from 'antd';
import Croper from "../../../components/Croper";

// components
import {

    MyAlert,
    MySpinner,
    AdLoader
} from "../../../components/index"
import InlineError from '../../../components/InlineError'
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';
// import { User } from '../../../components/common/SvgIcon';
import { Brochures, ShowcaseVisitor, ProductViwer, Offer, MediaAnalytics, ReplaySession, EnquireAnalytics, DashboardUser, Video, UserConnection } from '../../../SvgIcon'
import moment from "moment"
import { Tree } from 'antd';
import { Cross } from '../../../components/common/SvgIcon';

const { TreeNode } = Tree;
const { Option } = Select;
const dasboardItemList = ['Active User', 'Showcase Visitor', 'Product Viewer', 'Offer Analytics', 'Brouchures Download', 'Media Analytics', 'Replay Session', 'Video Session', 'User Connection', 'Enquire Analytics', 'Session Based Attendees', 'Active User', 'Session Based Attendees', 'Showcase Visitor', 'Product Viewer', 'Brouchures Download', 'Offer Analytics']

const dasboardItemList2 = [
    { name: "Active User", img: <DashboardUser /> },
    { name: "Showcase Visitor", img: <ShowcaseVisitor /> },
    { name: "Product Viewer", img: <ProductViwer /> },
    { name: "Offer Analytics", img: <Offer /> },
    { name: "Brouchures Download", img: <Brochures /> },
    { name: "Media Analytics", img: <Video /> },
    { name: "Replay Session", img: <ReplaySession /> },
    // {name:"Manage Session", img : <ReplaySession />},
    { name: "Session based Attendees", img: <DashboardUser /> },
    { name: "Video Session", img: <MediaAnalytics /> },
    { name: "User Connection", img: <UserConnection /> },
    { name: "Enquire Analytics", img: <EnquireAnalytics /> }
    ,
]


class AddNewAd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            brandListData: [],
            conferenceList: [],
            isShow: false,
            // startDate: moment().startOf('day').valueOf(),
            // endDate: moment().endOf('day').valueOf(),
            startDate: '',
            endDate: '',
            userType: 1,
            selectedIds: [],
            type: 1

        }

    }

    //exhibitaion onhange
    handleOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, adNameError: '', adCategoryError: '', brandError: '', emailError: '', phoneError: '', redirectionUrlError: '', imageError: '' })
    }

    handleOnChangeSelect1 = (e) => {
        this.setState({ startDate: moment(e).startOf('day').valueOf() })
    }

    handleOnChangeSelect2 = (e) => {
        this.setState({ endDate: moment(e).endOf('day').valueOf() })
    }









    componentDidMount() {
        this.getBrandList()
        this.getConferenceList()

    }


    // user/dashboard
    // POST
    // type=1,2 useractive and showcase
    // showcaseId
    // dateTo,
    // dateFrom



    getData = (req) => {
        this.setState({ isLoading: true })
        _Api(Url.ANALYTICS_ADMIN.method, Url.ANALYTICS_ADMIN.url, req)
            .then(resp => {
                const { file } = resp.responseData.result
                this.setState({ isLoading: false })
                window.open(file, "_blank");
                this.onCancel()
            }).catch(err => {
                this.setState({ isLoading: false })
            })
    }



    handleDownloadReport = (type) => {
        if (type == 1) {
            let req = {
                type: 1,
                dateTo: this.state.endDate,
                dateFrom: this.state.startDate
            }
            this.getData(req)
        }
        else if (type == 2) {
            this.setState({ type: 2, userType: 2, isShow: true })
        }
        else if (type == 3) {
            this.setState({ type: 3, userType: 2, isShow: true })
        }
        else if (type == 4) {
            this.setState({ type: 4, userType: 2, isShow: true })
        }
        else if (type == 5) {
            this.setState({ type: 5, userType: 2, isShow: true })
        }
        else if (type == 6) {
            this.setState({ type: 6, userType: 2, isShow: true })
        }
        else if (type == 7) {
            this.setState({ type: 7, userType: 2, isShow: true })
        }

        else if (type == 9) {
            this.setState({ type: 9, userType: 2, isShow: true })
        }
        else if (type == 10) {
            this.setState({ type: 10, userType: 2, isShow: true })
        }
        else if (type == 11) {
            this.setState({ type: 11, userType: 2, isShow: true })
        }
        else if (type == 8) {
            this.setState({ type: 8, userType: 2, isShow: true })
        }



    }

    handlePopupDownload = (type) => {
        if (type == 1) {
            let req = {
                type: 1,
                dateTo: this.state.endDate,
                dateFrom: this.state.startDate
            }
            this.getData(req)
        }
        else if (type == 2) {
            let req = {
                type: 2,
                showCaseId: this.state.selectedIds.toString(),
                dateTo: this.state.endDate,
                dateFrom: this.state.startDate
            }
            this.getData(req)

        }
        else if (type == 3) {
            let req = {
                type: 3,
                showCaseId: this.state.selectedIds.toString(),
                dateTo: this.state.endDate,
                dateFrom: this.state.startDate
            }
            this.getData(req)

        }
        else if (type == 4) {
            let req = {
                type: 4,
                showCaseId: this.state.selectedIds.toString(),
                dateTo: this.state.endDate,
                dateFrom: this.state.startDate
            }
            this.getData(req)

        }
        else if (type == 5) {
            let req = {
                type: 5,
                showCaseId: this.state.selectedIds.toString(),
                dateTo: this.state.endDate,
                dateFrom: this.state.startDate
            }
            this.getData(req)

        }
        else if (type == 6) {
            let req = {
                type: 6,
                showCaseId: this.state.selectedIds.toString(),
                dateTo: this.state.endDate,
                dateFrom: this.state.startDate
            }
            this.getData(req)

        }

        else if (type == 7) {
            let req = {
                type: 7,
                conferenceId: this.state.selectedIds.toString(),
                dateTo: this.state.endDate,
                dateFrom: this.state.startDate
            }
            this.getData(req)

        }


        else if (type == 9) {
            let req = {
                type: 9,
                showCaseId: this.state.selectedIds.toString(),
                dateTo: this.state.endDate,
                dateFrom: this.state.startDate
            }
            this.getData(req)

        }
        else if (type == 10) {
            let req = {
                type: 10,
                showCaseId: this.state.selectedIds.toString(),
                dateTo: this.state.endDate,
                dateFrom: this.state.startDate
            }
            this.getData(req)

        }
        else if (type == 11) {
            let req = {
                type: 11,
                showCaseId: this.state.selectedIds.toString(),
                dateTo: this.state.endDate,
                dateFrom: this.state.startDate
            }
            this.getData(req)

        }

        else if (type == 8) {
            let req = {
                type: 8,
                conferenceId: this.state.selectedIds.toString(),
                dateTo: this.state.endDate,
                dateFrom: this.state.startDate
            }
            this.getData(req)

        }



    }


    onCancel = () => {
        this.setState({
            isShow: false,
            type: 1,
            userType: 1,
            selectedIds: [],
            isSelectAll: false

        })
    }


    getBrandList = () => {

        _Api(Url.GET_BRAND_FOR_ASSIGN.method, Url.GET_BRAND_FOR_ASSIGN.url, '', `?&exhibitionId=${localStorage.getItem('exhibition')}`)
            .then(resp => {
                this.setState({ brandListData: resp.responseData?.result })

            }).catch(err => {
                // if (err.error)
                //     notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)

            })
    }


    handleSelect = (e, arr = []) => {
        this.setState({ selectedIds: e }, () => {
            if (this.state.selectedIds.length == arr.length) {
                this.setState({ isSelectAll: true })
            }
            else {
                this.setState({ isSelectAll: false })
            }
        })
    }

    radioChange = (e) => {
        // console.log("eee", e.target.value)
        this.setState({ userType: e.target.value })
    }

    getConferenceList = () => {
        _Api(Url.GET_CONFERENCE_LIST.method, Url.GET_CONFERENCE_LIST.url, '', `?exhibitionId=${localStorage.getItem('exhibition')}`)
            .then(resp => {
                this.setState({ conferenceList: resp.responseData?.result?.data, })
            }).catch(err => {

            })
    }

    selectAll = (arr) => {
        if (this.state.isSelectAll) {
            this.setState({ isSelectAll: false, selectedIds: [] })
        }
        else {
            this.setState({ selectedIds: arr.map(item => item._id), isSelectAll: true })

        }

    }

    resetSelectAll = () => {
        this.setState({ isSelectAll: false, selectedIds: [] })

    }


    render() {
        let { startDate,
            endDate,
            brandListData,
            conferenceList

        } = this.state

        console.log("wekhgfhj", { conferenceList })

        return (
            <div className="dash_body_container">
                <div className="body_container">
                    <div className="header-text  mb-4 mt-3">
                        <div className="d-flex align-items-center">
                            <p className="header-icon"><AdManagement /></p>
                            <h4> Analytics </h4>
                            <br />
                        </div>

                        <div className="row d-flex align-items-center">
                            <div className="col-md-4">
                                <h4 className="font-weight-bold mb-0">Duration:</h4>
                            </div>
                            <div className="col-md-4">
                                <DatePicker
                                    // value={[moment(startDate), moment(endDate)]}
                                    format="DD-MM-YYYY"
                                    onChange={this.handleOnChangeSelect1}
                                // onOk={onOk}
                                />
                            </div>
                            <div className="col-md-4 mt-3 mt-md-0">
                                <DatePicker
                                    // value={[moment(startDate), moment(endDate)]}
                                    format="DD-MM-YYYY"
                                    onChange={this.handleOnChangeSelect2}
                                // onOk={onOk}
                                />
                            </div>

                        </div>

                    </div>
                </div>


                <div className="">
                    <div className="py-5 row rounded-body text-center">

                        {
                            dasboardItemList2.map((item, index) => {
                                return (
                                    <div className="col-md-6 col-lg-4 col-sm-12 col-xl-3">
                                        {/*analytics-card*/}
                                        <div className="rounded-body">
                                            <div className="card community-card analytics-card skyblue">
                                                <div className="icon">
                                                    {item.img}
                                                    {/* <img src={item.img} /> */}

                                                </div>

                                                <h5 className="mt-3 font-weight-bold text-dark">{item.name}</h5>
                                                {/* <hr /> */}
                                                <button type="button" onClick={() => this.handleDownloadReport(index + 1)} className="btn mt-4 btn-white">Download report</button>
                                            </div>

                                        </div>

                                    </div>
                                )
                            })
                        }

                    </div>
                    <SelectTypeModal
                        type={this.state.type}
                        selectedIds={this.state.selectedIds}
                        isShow={this.state.isShow}
                        onCancel={this.onCancel}
                        brandListData={brandListData}
                        userType={this.state.userType}
                        handleSelect={this.handleSelect}
                        handlePopupDownload={this.handlePopupDownload}
                        radioChange={this.radioChange}
                        conferenceList={conferenceList}
                        isLoading={this.state.isLoading}
                        selectAll={this.selectAll}
                        isSelectAll={this.state.isSelectAll}
                        resetSelectAll={this.resetSelectAll}


                    />


                </div>
            </div>
        )
    }
}
export default AddNewAd;



const SelectTypeModal = (props) => {
    const { resetSelectAll, selectAll, isSelectAll, conferenceList, selectedIds, isShow, onCancel, brandListData, handleSelect, userType, radioChange, type, handlePopupDownload, isLoading } = props

    let arr = (type == 7 || type == 8) ? conferenceList : brandListData

    return (
        <>
            <Modal
                wrapClassName="signup_parent_modal"
                className="cm_popup signup_modal "
                //cm_popup term-services
                visible={isShow}
                // onOk={this.handleOk}
                onCancel={onCancel}
                footer={null}
                centered
                maskClosable={false}
            >
                <div className="sign-modal-content">
                    <div className="row  text-center d-flex align-items-center">
                        <div className="col-md-12 text-left" >

                            {<h4 className="auth-heading text-center mt-4 font-weight-bold h5 ">
                                {(type == 7 || type == 8) ? 'Select Conference' : 'Select Brands'}
                            </h4>}
                            <div className="justify-content-center mt-3 mb-3 d-flex">
                                {/* {type == 8 && <div className="assign-checkboc-parent">
                                    <div>
                                        <label className="cm_container mr-4">
                                            User
                                                                    <input type="radio" value="1" onChange={radioChange} checked={userType == 1} name="radio" />
                                            <span className="radio-checkmark"></span>
                                        </label>
                                    </div>
                                    <div>
                                        <label className="cm_container">
                                            Showcase
                                                                    <input type="radio" value="2" onChange={radioChange} checked={userType == 2} name="radio" />
                                            <span className="radio-checkmark"></span>
                                        </label>
                                    </div>
                                </div>} */}
                            </div>

                            <div className="mt-4">

                                <div className="col-md-12">
                                    <div className="check_wrapper">
                                        <input type="checkbox"

                                            value="option2"
                                            checked={isSelectAll}
                                            onChange={() => selectAll(arr)}
                                        /> <span />
                                        &nbsp;<h6> {'Select all'}</h6> 
                                      
                                    </div>
                                    {selectedIds.length != 0 && <div className="check_wrapper">
                                    <button className="btn btn-red sm-btn"
                                     onClick={() => resetSelectAll()}
                                    >Reset</button>
                                    </div>}
                                    <div className="overflow-auto" style={{ maxHeight: "256px" }}>
                                        <Tree
                                            checkable
                                            checkedKeys={selectedIds}
                                            // defaultExpandedKeys={['0-0-0', '0-0-1']}
                                            // defaultSelectedKeys={['0-0-0', '0-0-1']}
                                            // defaultCheckedKeys={['0-0-0', '0-0-1']}
                                            // onSelect={this.onSelect}
                                            onCheck={(e) => props.handleSelect(e, arr)}
                                        >
                                            {
                                                arr?.map((item, index) =>
                                                    <TreeNode
                                                        key={item._id}
                                                        title={item.companyName ? item.companyName : item.conferenceName}
                                                        value={item._id}
                                                    />
                                                )
                                            }

                                        </Tree>

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <button type="button" onClick={() => handlePopupDownload(type)} className="btn btn-red btn-navyblue btn-down">
                        Download
                        {
                            isLoading && <MySpinner />
                        }





                    </button>
                </div>
            </Modal>
        </>
    )
}