import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';
import moment from 'moment-timezone';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';
import util from '../../../utils/Util.js';
import { PaymentIcon } from '../../../SvgIcon'

class TransactionDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            transactionData: []
        }
    }
    
    componentDidMount() {
        this.getParticularTransaction();
    }
    getParticularTransaction = () => {
        let req = {
            transactionId: this.props.match.params.id,
        }
        _Api(Url.GET_TRANSACTION_DETAILS_DATA.method, Url.GET_TRANSACTION_DETAILS_DATA.url, '', `?transactionId=${req.transactionId}`)
            .then(resp => {
                this.setState({ transactionData: resp.responseData.result });

            }).catch(err => {
                this.setState({ isLoader: false });
                let errorMessage = err.error && err.error.responseMessage || err.message;
                notify("err", errorMessage);
            });
    }

    render() {
        let { transactionData } = this.state;
        return (
            <>
                <div className="dash_body_container">
                    <Header />
                    <Sidebar />
                    <div className="body_container tran-details">

                        <div className="header-text  mb-4 mt-3">
                            <div className="d-flex align-items-center">
                                <p className="header-icon"><PaymentIcon /></p>
                                <h4>Transaction Detail</h4></div>
                        </div>
                        <div className="rounded-box">
                            <div class="table-responsive no-shadow">
                                <table class="table table-bordered rounded-table">
                                    <thead>
                                        <tr>
                                            <th>S.No.</th>
                                            <th>Transaction ID  </th>
                                            <th>Payment Method</th>
                                            <th>Currency</th>
                                            <th>Username</th>
                                            <th>Company Name</th>
                                            <th>Conference</th>
                                            <th>Purchased</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><span className="circle-serial"> 1</span></td>
                                            <td>{transactionData.transactionUniqueId}</td>
                                            <td>{transactionData.transactionObject && transactionData.transactionObject.source["object"]}</td>
                                            <td>{transactionData.paymentType == 0 && "USD" || transactionData.paymentType == 1 && "GBP"}</td>
                                            <td>{transactionData.userData && transactionData.userData.firstName}</td>
                                            <td>{transactionData.userData && transactionData.userData.companyName}</td>
                                            <td>{transactionData.exhibitionData && transactionData.exhibitionData.exhibitionName}</td>
                                            <td>{transactionData.exhibitionData && util.getPurchaseValue(transactionData.purchased)}</td>
                                            <td>{moment(transactionData.created).format("MM/ DD/ YY")}</td>
                                        </tr>



                                    </tbody>
                                </table>
                            </div>
                            <br /><br />
                            <div class="table-responsive no-shadow transaction-details-table">
                                <table class="table table-bordered rounded-table">
                                    <tr>
                                        <td>VAT Registration No.</td>
                                        <td>{transactionData.vatNumber}</td>
                                        <td>Subtotal</td>
                                        <td>{transactionData.amount}</td>
                                    </tr>
                                    <tr>
                                        <td>Brand Showcase</td>
                                        <td>{transactionData.amount}</td>
                                        <td>Discount</td>
                                        <td>{transactionData.discountAmount}</td>
                                    </tr>
                                    <tr>
                                        <td>Promocode</td>
                                        <td>{transactionData.promocodeTitle}</td>
                                        <td>VAT(20%)</td>
                                        <td>{transactionData.vatAmount}</td>
                                    </tr>


                                </table>
                            </div>

                            <div className="bottom-btn">
                                <Link to="/transactionlist" className="btn btn-warning">Back</Link>
                                <div className="total-box  d-flex">
                                    <p>Total</p>
                                    <p>{transactionData.totalAmount}</p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </>
        );
    }
}
export default TransactionDetail;