import React, { Component, Suspense } from 'react';
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { Modal, Button, Checkbox } from 'antd';
import { Calender, Clock, Hert, Ticket, TwoUsers, PlayIcon, NoMediaICon, Applebtn, Googlebtn } from "../../../SvgIcon";
import moment from "moment"
import Loader from "../../../components/Loader"
import InlineError from "../../../components/InlineError";
import { notify } from '../../../components/Toaster';
import Spotlight from '../../../screens/web/conference/Spotlight'
import WebHeader from '../../layout/WebHeader';
import _ from "lodash"
import Cookies from "js-cookie";
import ReactPlayer from 'react-player';

// import { HashLink } from 'react-router-hash-link';


import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import Footer from "../../layout/Footer";

import Media from "../../../components/common/WebMedia"

import { Link, withRouter } from "react-router-dom"
import { getTimeZone } from "../../../utils/DateZone"
import { connect } from "react-redux";


import { getHome } from "../../../redux/action/Get";

import {
    MyAlert,
    MySpinner
} from "../../../components/index"
import socket from "../../../services/socket";
import AddToCalendar from 'react-add-to-calendar';

const StagJoinCard = React.lazy(() => import('../components/stages/StagJoinCard'));
const LoungeJoinCard = React.lazy(() => import('../components/lounges/LoungeJoinCard'));

const antIcon = <LoadingOutlined style={{ fontSize: 72 }} spin />;

let isProd = process.env.REACT_APP_ENV == 'prod'
// let Link      = Scroll.Link;
class ConferenceDetail extends Component {


    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.carousel = React.createRef();

        this.state = {
            isCommingSoon: true,
            isfree: true,
            conferenceDetail: '',
            isLoading: true,
            stagList: [],
            loungList: [],
            speakersList: [],
            timetableList: [],
            password: '',
            passwordError: '',
            noOfDays: 1,
            dayStatus: [],
            npOfConference: 0,
            globalLoader: true,
            tab: 0,
            tabData: [],
            allTimeTableData: [],
            mediaData: [],
            isLoadingBtn: {},
            visible: false,
            visible1: false,
            visibleSpeakerProfile: false,
            selectedTableItem: '',
            calenderData: {},
            spotlight: [],
            selectedReminder: '',
            bannerStag: {},
            selectedSpeakerData: "",
            isPlaying:true
        }

        window.scrollTo(0, 0)

        this.playerRef = React.createRef()

    }

    handleSetReminder = () => {
        console.log("jhgdhjjjjjjjjjjjj", this.state.selectedTableItem)
        const { _id, stageId, } = this.state.selectedTableItem
        let req = {
            conferenceId: this.props.match.params.coId,
            sessionId: _id,
            stageId: stageId._id
        }

        _Api(Url.ADD_REMINDER.method, Url.ADD_REMINDER.url, req)
            .then(() => {
                // notify("success", 'Reminder added');
                // this.setState({ visible1: false })
                this.getConferenceDetail()
            }).catch(err => {
                let errorMessage = err.error && err.error.responseMessage || err.message;
                notify("err", errorMessage);

            })
    }


    next = () => {
        this.carousel.next();
    }
    previous = () => {
        this.carousel.prev();
    }

    getInfo = () => {
        let id = this.props.match.params.coId

        socket.on('stageEdit', function (params) {
            // console.log("stageEdit",params )
            if (params?.conferenceId == id)
                window.location.reload()
        })
        socket.on('stageDelete', function (params) {
            // console.log("stageDelete",params )
            if (params?.conferenceId == id)
                window.location.reload()
        })

    }

    componentWillUnmount() {
        socket.off("stageEdit")
        socket.off("stageDelete")
    }

    componentDidMount() {

        // console.log("avghjklacnasbdjknsa",moment(`${moment(1612953101000).format('DD/MM/YYYY')} ${moment('Wed Feb 10 2021 16:20:00 GMT+0530').format('hh : mm a')}`, 'DD/MM/YYYY hh:mm a' ).valueOf(),    moment(1612953101000).format('DD/ MM/ YYYY'), moment('Wed Feb 10 2021 16:20:00 GMT+0530').format('hh : mm') )






        let self = this;
        this.getConferenceDetail()
        this.getInfo()
        this.getSpotLight()
        this.props.dispatch({ type: 'PATH_ADD', payload: this.props.match })
        socket.on('loungeEnter', function (param) {
            let loungList = self.state.loungList;
            let lounge = _.find(loungList, { _id: param.loungeId });
            if (lounge) {
                lounge.onlineJoinUser = param.userCount;
                let index = _.findIndex(loungList, { _id: param.loungeId });
                loungList.splice(index, 1, lounge);
                self.setState({
                    loungList
                })
            }
        })

        socket.on('loungeExist', function (param) {
            let loungList = self.state.loungList;
            let lounge = _.find(loungList, { _id: param.loungeId });
            if (lounge) {
                if (param.userCount >= 0) {
                    lounge.onlineJoinUser = param.userCount;
                }
                let index = _.findIndex(loungList, { _id: param.loungeId });
                loungList.splice(index, 1, lounge);
                self.setState({
                    loungList
                })
            }
        })
    }

    removeDuplicates = (data) => {

        // Create an array of objects 


        // Display the list of array objects 


        // Declare a new array 
        let newArray = [];

        // Declare an empty object 
        let uniqueObject = {};

        // Loop for the array elements 
        for (let i in data) {

            // Extract the title 
            let objTitle = moment(data[i]['startDate']).format('DD-MM-YYYY')

            // Use the title as the index 
            uniqueObject[objTitle] = data[i];
        }

        // Loop to push unique object into array 
        for (let i in uniqueObject) {
            newArray.push(uniqueObject[i]);
        }

        // Display the unique objects 
        this.setState({ tabData: _.sortBy(newArray, 'startDate') }, () => {
            this.getTimeTableAccordingToDate(data, this.state.tabData[this.state.tab]?.startDate)
        })

    }


    getTimeTableAccordingToDate = (data = [], search) => {

        this.setState({ timetableList: data.filter(item => moment(item.startDate).format('MM-DD-YYYY') == moment(search).format('MM-DD-YYYY')) })


    }




    getSpotLight = () => {
        let { page, limit, search } = this.state
        let req = {
            conferenceId: this.props.match.params.coId
        }
        _Api(Url.GET_WEB_SPOTLIGHT.method, Url.GET_WEB_SPOTLIGHT.url, req)
            .then(resp => {
                console.log("kijhgvfchjkl", resp?.responseData?.result)
                const { result } = resp?.responseData
                this.setState({
                    spotlight: result
                })

            }).catch(err => {


            })

    }

    handleJoinReplay = (sessionId, stageId) => { 
        // let { page, limit, search } = this.state
        let req = {
            conferenceId: this.props.match.params.coId,
            sessionId,
            stageId
        }
        _Api(Url.ANALYTICS_REPLAY.method, Url.ANALYTICS_REPLAY.url, req)
            .then(resp => {
               

            }).catch(err => {


            })

    }




    getConferenceDetail = () => {
        let { page, limit, search } = this.state
        let req = {
            conferenceId: this.props.match.params.coId
        }
        _Api(Url.GET_CONFERENCE_WEB_DETAILS.method, Url.GET_CONFERENCE_WEB_DETAILS.url, req)
            .then(resp => {
                const { timeTableDetail, speakerDetail, stageDetail, loungeDetail, conferenceDetail, mediaDetail, mainStageData } = resp.responseData
                const { startDate, endDate } = conferenceDetail
                Cookies.set('cof_plan', JSON.stringify(conferenceDetail), { expires: 1 })

                if (conferenceDetail?.dollarAmount == 0 && conferenceDetail?.isPassword == "" && conferenceDetail?.isAllowed == false) {
                    this.handleJoinConf(conferenceDetail._id, conferenceDetail)
                }

                this.removeDuplicates(timeTableDetail)

                let days = moment(endDate).diff(moment(startDate), 'days')

                this.setState({ npOfConference: days })
                var grades = {};
                timeTableDetail.forEach(function (item) {
                    var grade = grades[item.startDate] = grades[item.startDate] || {};
                    grade[item.startDate] = true;
                });


                this.setState({
                    globalLoader: false,
                    bannerStag: mainStageData,
                    allTimeTableData: timeTableDetail,
                    mediaData: mediaDetail,
                    speakersList: speakerDetail, stagList: stageDetail, loungList: loungeDetail, conferenceDetail, isLoading: false
                })


            }).catch(err => {
                this.setState({ globalLoader: false, isLoading: false })

            })

    }

    // onlyJoin = (name, id, coId, item = {}) => {
    //     const { numberofAttendees, onlineJoinUser } = item
    //     var windowReference = window.open();
    //     let req = {}
    //     console.log("item", item);
    //     if (name == "lounge") {
    //         if (onlineJoinUser < numberofAttendees) {
    //             if (item.loungeType === "1" || item.loungeType === 1) {
    //                 req.loungeId = item._id
    //                 windowReference.location = '/lounge/' + id, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height;
    //                 // window.open('/lounge/' + id, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
    //             } else {
    //                 req.networkLoungeId = item._id
    //                 windowReference.location = '/network-lounge/' + id, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height                           
    //                 // window.open('/network-lounge/' + id, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
    //             }
    //         } else {
    //             notify("err", "This lounges is full , you can not join at this time")
    //         }
    //     }
    //     else {
    //         this.props.history.push(`/seminar/${coId}/${name}/${id}`)
    //     }



    // }





    onlyJoin = (name, id, coId, item ) => {
        console.log("test123",navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1)
        if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {            
            this.onlyJoin1(name, id, coId, item)
            
        }
        else{ 
        this.onlyJoin2(name, id, coId, item)
        }

      
            

    }

    onlyJoin2 = (name, id, coId, item = {}) => {
        const { numberofAttendees, onlineJoinUser } = item
        if (name == "lounge") {
            let req = {
                otherUserId: localStorage.getItem('id'),
                type: 4,
                loungeId: item._id,
                exhibitionId: localStorage.getItem('exhibition')
            }
            _Api(Url.CHECK_PLAN_USER.method, Url.CHECK_PLAN_USER.url, req)
                .then(resp => {
                    const { isBuy } = resp?.responseData
                    if (isBuy == 0) {
                        this.props.dispatch({ type: 'SHOW_PLAN_USER_POPUP', payload: this.props.match })
                    }
                    else {
                        if (onlineJoinUser < numberofAttendees) {
                            // window.open('/lounge/' + id, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                            
                            window.open(`https://video.worldwidejobfinder.com?lounge=${id}&token=${localStorage.getItem('accessToken')}&name=${item.loungeName}`, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                            
                            let req = { loungeId: item._id }

                            _Api(Url.JOIN_LOUNG_PLAN.method, Url.JOIN_LOUNG_PLAN.url, req)
                                .then(resp => {
                                }).catch(err => { })

                        }
                        else
                            notify("err", "This lounges is full , you can not join at this time")
                    }

                }).catch(err => {

                })

        }
        else
            this.props.history.push(`/seminar/${coId}/${name}/${id}`)

    }







    onlyJoin1 = (name, id, coId, item = {}) => {
        const { numberofAttendees, onlineJoinUser } = item
        var windowReference = window.open();
        if (name == "lounge") {
            let req = {
                otherUserId: localStorage.getItem('id'),
                type: 4,
                loungeId: item._id,
                exhibitionId: localStorage.getItem('exhibition')
            }
            _Api(Url.CHECK_PLAN_USER.method, Url.CHECK_PLAN_USER.url, req)
                .then(resp => {
                    const { isBuy } = resp?.responseData
                    if (isBuy == 0) {
                        this.props.dispatch({ type: 'SHOW_PLAN_USER_POPUP', payload: this.props.match })
                    }
                    else {
                        if (onlineJoinUser < numberofAttendees) {
                            console.log("onee11222",id,  localStorage.getItem('accessToken'))
                            // console.log("onee11", `https://video.worldwidejobfinder.com?lounge=${id}&token=${localStorage.getItem('accessToken')}`)
    
                            var newWin = window.open(`https://video.worldwidejobfinder.com?lounge=${id}&token=${localStorage.getItem('accessToken')}&name=${item.loungeName}`, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                            if (!newWin || newWin.closed || typeof newWin.closed == 'undefined')
                                windowReference.location = `https://video.worldwidejobfinder.com?lounge=${id}&token=${localStorage.getItem('accessToken')}&name=${item.loungeName}`
                            let req = { networkLoungeId: item._id }
    
                            _Api(Url.JOIN_LOUNG_PLAN.method, Url.JOIN_LOUNG_PLAN.url, req)
                                .then(resp => {
                                }).catch(err => { })
                        }
                        // if (onlineJoinUser < numberofAttendees) {
                           
                        //     var newWin = window.open('/lounge/' + id, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                        //     if (!newWin || newWin.closed || typeof newWin.closed == 'undefined')
                        //     windowReference.location = '/lounge/' + id
                        //     let req = { loungeId: item._id }

                        //     _Api(Url.JOIN_LOUNG_PLAN.method, Url.JOIN_LOUNG_PLAN.url, req)
                        //         .then(resp => {
                        //         }).catch(err => { })

                        // }
                        else
                            notify("err", "This lounges is full , you can not join at this time")
                    }

                }).catch(err => {

                })

        }
        else
            this.props.history.push(`/seminar/${coId}/${name}/${id}`)

    }


    handleJoin = (conference, stageId = '', loungeId = '', ) => {
        //console.log("conference.isPassword && !conference.isAllowed",conference,conference.isPassword ,!conference.isAllowed);
        if (conference.isPassword && !conference.isAllowed) {
            this.setState({
                visible: true,

            });

        }

        else {
            this.setState({
                ['isLoadingBtn' + conference]: true, ['isLoadingBtn' + stageId]: true, ['isLoadingBtn' + loungeId]: true
            })

            let req = {
                conferenceId: conference,
                stageId: stageId,
                loungeId: loungeId,

            }
            _Api(Url.CONFERENCE_PAYMENT.method, Url.CONFERENCE_PAYMENT.url, req)
                .then(resp => {
                    this.setState({
                        ['isLoadingBtn' + conference]: false, ['isLoadingBtn' + stageId]: false, ['isLoadingBtn' + loungeId]: false
                    })
                    this.props.dispatch(getHome());

                    this.setState({ isLoadingBtn: false, })
                    this.getConferenceDetail()
                    if (stageId != '') {
                        this.onlyJoin('stage', stageId, conference)
                    }
                    else if (loungeId != '') {
                        //this.props.history.push(`/lounge/${loungeId}`)
                        window.open('/lounge/' + loungeId, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                    }
                }).catch(err => {
                    this.setState({
                        ['isLoadingBtn' + conference]: false, ['isLoadingBtn' + stageId]: false, ['isLoadingBtn' + loungeId]: false
                    })

                })
        }

    }
    handleJoinConf = (conference, conferenceDetail, stageId = '', loungeId = '', ) => {
        // console.log("jjjjjjjjjjjjjjjjjjjjj",{conferenceDetail, conference} );
        if (conferenceDetail.isPassword && !conferenceDetail.isAllowed) {
            this.setState({
                visible: true,

            });

        }

        else {
            this.setState({
                ['isLoadingBtn' + conference]: true, ['isLoadingBtn' + stageId]: true, ['isLoadingBtn' + loungeId]: true
            })

            let req = {
                conferenceId: conference,
                stageId: stageId,
                loungeId: loungeId,

            }
            _Api(Url.CONFERENCE_PAYMENT.method, Url.CONFERENCE_PAYMENT.url, req)
                .then(resp => {
                    this.setState({
                        ['isLoadingBtn' + conference]: false, ['isLoadingBtn' + stageId]: false, ['isLoadingBtn' + loungeId]: false
                    })
                    this.props.dispatch(getHome());

                    if (this.state.conferenceDetail?.isPaid) {
                        this.props.history.push(`/seminar/${this.props.match.params.coId}/payment`)
                    }


                    this.setState({ isLoadingBtn: false, })
                    this.getConferenceDetail()
                    if (stageId != '') {
                        this.onlyJoin('stage', stageId, conference)
                    }
                    else if (loungeId != '') {
                        //this.props.history.push(`/lounge/${loungeId}`)
                        window.open('/lounge/' + loungeId, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                    }
                }).catch(err => {
                    this.setState({
                        ['isLoadingBtn' + conference]: false, ['isLoadingBtn' + stageId]: false, ['isLoadingBtn' + loungeId]: false
                    })

                })
        }

    }
    handleOnChangeConference = (e) => {
        this.setState({ [e.target.name]: e.target.value, passwordError: '' })
    }
    submitPassword = (e, stageId = '', loungeId = '') => {
        if (e) e.preventDefault()
        if (this.state.password == '') {
            this.setState({ passwordError: '*Please enter password' })
        }
        else {
            this.setState({ isLoading: true })
            let req = {
                conferenceId: this.state.conferenceDetail._id,
                stageId: stageId,
                loungeId: loungeId,
                isPassword: this.state.password
            }
            _Api(Url.CONFERENCE_PAYMENT.method, Url.CONFERENCE_PAYMENT.url, req)
                .then(resp => {

                    this.setState({ isLoading: false, password: '', visible: false, })
                    this.getConferenceDetail()
                    this.handleCancel()
                }).catch(err => {
                    this.setState({ isLoading: false })
                    if (err.error)
                        notify("err", err.error.responseMessage)
                    // else if (err)
                    //     // notify("err", err.message)
                })
            // }    
        }

    }

    showModal = () => {
        this.setState({
            visible: false,
        });
    };
    showSpeakerProfileModal = (data) => {
        this.setState({
            visibleSpeakerProfile: true,
            selectedSpeakerData: data
        });
    };

    handleCancel = e => {

        this.setState({
            visible1: false, visible: false,
            visibleSpeakerProfile: false,
        });
    };


    handleTab = (tab, date) => {
        this.getTimeTableAccordingToDate(this.state.allTimeTableData, date)
        this.setState({ tab })

    }

    handleTableClick = (item) => {
        console.log("dsacdhj", item)
        this.setState({
            selectedTableItem: item,
            calenderData: {
                title: item?.sessionName,
                description: item?.description,
                location: 'UK',
                startTime: moment(item.startDate),
                endTime: moment(item.endDate)
            }
        }, () => {
            this.setState({ visible1: true, })
            this.handleSetReminder()

        })
    }

    // componentWillUnmount(){
    //     document.body.classList.remove('webchatopen');
    //     document.body.classList.remove('conference');
    // }

    handleJoinConf2 = (conference, conferenceDetail, stageId = '', loungeId = '', ) => {
        this.setState({
            ['isLoadingBtn' + conference]: true, ['isLoadingBtn' + stageId]: true, ['isLoadingBtn' + loungeId]: true
        })

        let req = {
            conferenceId: conference,
            stageId: stageId,
            loungeId: loungeId,

        }
        _Api(Url.CONFERENCE_PAYMENT.method, Url.CONFERENCE_PAYMENT.url, req)
            .then(resp => {
                this.setState({
                    ['isLoadingBtn' + conference]: false, ['isLoadingBtn' + stageId]: false, ['isLoadingBtn' + loungeId]: false
                })
                this.props.dispatch(getHome())

                this.setState({ isLoadingBtn: false, })
                this.getConferenceDetail()
                if (stageId != '') {
                    this.onlyJoin('stage', stageId, conference)
                }
                else if (loungeId != '') {
                    //this.props.history.push(`/lounge/${loungeId}`)
                    window.open('/lounge/' + loungeId, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                }
            }).catch(err => {
                this.setState({
                    ['isLoadingBtn' + conference]: false, ['isLoadingBtn' + stageId]: false, ['isLoadingBtn' + loungeId]: false
                })
            })
    }

    submitPassword = (e, stageId = '', loungeId = '') => {
        if (e) e.preventDefault()
        if (this.state.password == '') {
            this.setState({ passwordError: '*Please enter password' })
        }
        else {
            this.setState({ isLoading: true })
            let req = {
                conferenceId: this.state.conferenceDetail._id,
                stageId: stageId,
                loungeId: loungeId,
                isPassword: this.state.password
            }
            _Api(Url.PASSWORD_CHECK.method, Url.PASSWORD_CHECK.url, req)
                .then(resp => {
                    this.setState({ isLoading: false, password: '', visible: false, })
                    if (this.state.conferenceDetail?.isPaid) {
                        this.props.history.push(`/seminar/${this.props.match.params.coId}/payment`)
                    }
                    else {

                        if (this.state.conferenceDetail?.isPaid == 0) {
                            console.log("dlhfdgh", this.state.conferenceDetail?.isPaid == 0)
                            this.handleJoinConf2(this.state.conferenceDetail._id, this.state.conferenceDetail)
                        }

                    }

                    this.getConferenceDetail()
                    this.handleCancel()
                }).catch(err => {
                    this.setState({ isLoading: false })
                    if (err.error)
                        notify("err", err.error.responseMessage)
                    // else if (err)
                    //     // notify("err", err.message)
                })
            // }    
        }

    }
    scrollToTop = () => {
        scroll.scrollToTop();
    };

    onREady = ()=>{
        console.log("daghsdjkl", this.playerRef)
       setTimeout(()=>  this.setState({isPlaying: false}), 300)
        // this.playerRef.current.play()
    }

    render() {

        const { bannerStag, mediaData, tabData, tab, globalLoader, password, passwordError, conferenceDetail, stagList, loungList, speakersList, timetableList, selectedSpeakerData } = this.state
        console.log("this.state+++===", this.state.timetableList);
        let event = {
            title: 'Sample Event',
            description: 'This is the sample event provided as an example only',
            location: 'Portland, OR',
            startTime: '2016-09-16T20:15:00-04:00',
            endTime: '2016-09-16T21:45:00-04:00'
        }
        let items = [
            { apple: 'Apple Calendar' },
            { google: 'Google' }
        ];

        let isOpen = true

        console.log("conferenceDetail111111111111111111111",
            bannerStag?.streamUrl
        );

        if (globalLoader)
            return (
                <section className="brand-home-banner-bg webhome">
                    {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
                    <div className="centerLoader" >
                        <Spin size="large" tip="Loading..." indicator={antIcon} />
                    </div>
                </section>


            )

        else
            return (
                <section className="Home-banner-bg">
                    <Suspense fallback={<div></div>}>
                        <div className={this.state.isCommingSoon ? " container-fluid brandhome pos-relative" : " container-fluid"}>

                            <div className="cm-wrapper confreance-detail-parent conference-dd pt-4">
                                {(bannerStag != undefined) && <>
                                    <div className="leftborder-parent  mb-4">
                                        <h4>{conferenceDetail?.conferenceName}&nbsp;&nbsp;
                                </h4>
                                    </div>
                                </>}
                                {/* {bannerStag &&
                                    <div className="leftborder-parent  mb-4">
                                        <h4>{bannerStag?.stageName}&nbsp;&nbsp;
                                </h4>
                                        <h6><span><Calender /></span> {moment(bannerStag?.startDate).format('MMM DD')},{getTimeZone(bannerStag?.startTime, moment.tz.guess())}  to   {moment(bannerStag?.endDate).format('MMM DD')}, {getTimeZone(bannerStag?.endTime, moment.tz.guess())}<span>&nbsp;&nbsp;&nbsp;
                                </span> </h6>
                                    </div>
                                } */}
                                {/* {
                                    this.state.stagList.length == 0 && !bannerStag &&                                   
                                        !this.state.isLoading && 
                                        <div className="w-100 text-center row nodata m-auto">
                                            <div className="m-auto">
                                                <NoMediaICon />
                                                
                                                <h2>No Stage Found</h2>
                                            </div>
                                        </div>                                      
                                    

                                } */}

                                {(conferenceDetail?.dollarAmount != 0 || conferenceDetail?.isPassword != "") && !globalLoader && (conferenceDetail.isAllowed == false) && this.state.stagList.length == 0 && (bannerStag == "" || bannerStag == undefined) &&
                                    <>
                                        <div className="leftborder-parent  mb-4">
                                            <h4>{conferenceDetail?.conferenceName}&nbsp;&nbsp;
                                </h4>
                                            <h6><span><Calender />&nbsp;&nbsp;</span> {moment(conferenceDetail?.startDate).format('MMM DD')},{getTimeZone(conferenceDetail?.startTime, moment.tz.guess())}  to   {moment(conferenceDetail?.endDate).format('MMM DD')}, {getTimeZone(conferenceDetail?.endTime, moment.tz.guess())}<span>&nbsp;&nbsp;&nbsp;
                                </span> </h6>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-7 col-lg-9">
                                                <div className="image-banner">
                                                    <img src={conferenceDetail?.mainStageImage ? conferenceDetail?.mainStageImage : require('../../../assets/images/placeholder.png')} className="img-fluid" />
                                                </div>
                                                {conferenceDetail?.description && <div className="detail pt-0">
                                                            <p className="mb-0">{conferenceDetail?.description}</p>
                                                        </div>}
                                            </div>
                                            <div className="col-md-5 col-lg-3 join-card">
                                                <p>Ticket</p>
                                                <div className="circle">
                                                    <Ticket />
                                                </div>
                                                <h5>{conferenceDetail?.conferenceName}</h5>
                                                {conferenceDetail?.euroAmount != undefined &&
                                                    <h2>{conferenceDetail?.euroAmount == 0 ? 'FREE' : `£${conferenceDetail?.euroAmount}`}
                                                        {/* {conferenceDetail?.euroAmount != undefined &&
                                                         conferenceDetail?.euroAmount == 0 ? '' : `£${conferenceDetail?.euroAmount}`
                                                     } */}

                                                    </h2>
                                                }

                                                <h6 className="mt-5">What are you waitng for ! </h6>
                                                <button onClick={() => this.handleJoinConf(conferenceDetail._id, conferenceDetail)} className="btn btn-primary btn-block" type='button'
                                                    disabled={this.state[`isLoadingBtn${conferenceDetail._id}`]}
                                                >
                                                    Join Now &nbsp; {this.state[`isLoadingBtn${conferenceDetail._id}`] &&
                                                        <MySpinner />}
                                                </button>

                                            </div>
                                        </div>
                                    </>
                                }

                                {(conferenceDetail.isAllowed == false && (conferenceDetail?.dollarAmount != 0 || conferenceDetail?.isPassword != "")) ?

                                    bannerStag && <>
                                        <div className="row">
                                            <div className="col-md-7 col-lg-9">
                                                <div className="full-image-banner">
                                                    {
                                                        // bannerStag?.streamUrl ?
                                                        // <div className="video ">
                                                        //     <ReactPlayer controls={true} playing={true} muted={false} width="442" height="390" url={bannerStag?.streamUrl} />
                                                        //     <div className="overlay"></div>
                                                        // </div> :
                                                        <img src={conferenceDetail?.mainStageImage ? conferenceDetail?.mainStageImage : require('../../../assets/images/placeholder1.1.png')} className="img-fluid" />
                                                    }
                                                    <div className="detail d-flex justify-content-between align-items-center">
                                                        <div>
                                                            {/* <h4>{bannerStag?.stageName}</h4> */}
                                                            <h5>{bannerStag?.stageName}</h5>
                                                            <h6 className="d-flex">
                                                                <span><Calender /></span>
                                                                <span className="ml-2">{moment(bannerStag?.startDate).format('MMM DD')}&nbsp;</span>
                                                                <span><Clock /></span>
                                                                <span className="ml-2">{getTimeZone(bannerStag?.startTime, moment.tz.guess())}  to   {moment(bannerStag?.endDate).format('MMM DD')}, {getTimeZone(bannerStag?.endTime, moment.tz.guess())}</span>

                                                            </h6>
                                                        </div>
                                                    </div>
                                                    {conferenceDetail?.description && <div className="detail pt-0">
                                                            <p className="mb-0">{conferenceDetail?.description}</p>
                                                        </div>}
                                                </div>
                                            </div>
                                            <div className="col-md-5 col-lg-3 join-card">
                                                <p>Ticket</p>
                                                <div className="circle">
                                                    <Ticket />
                                                </div>
                                                <h5>{conferenceDetail?.conferenceName}</h5>
                                                {conferenceDetail?.euroAmount != undefined &&
                                                    <h2>{conferenceDetail?.euroAmount == 0 ? 'FREE' : `£${conferenceDetail?.euroAmount}`}
                                                        {/* {conferenceDetail?.euroAmount != undefined &&
                                                         conferenceDetail?.euroAmount == 0 ? '' : `£${conferenceDetail?.euroAmount}`
                                                     } */}

                                                    </h2>
                                                }

                                                {/* {conferenceDetail?.dollarAmount != undefined &&
                                                    <h2>{conferenceDetail?.dollarAmount == 0 ? 'FREE' : `$${conferenceDetail?.dollarAmount}`}</h2>
                                                } */}
                                                <h6 className="mt-5">What are you waitng for ! </h6>
                                                <button onClick={() => this.handleJoinConf(conferenceDetail._id, conferenceDetail)} className="btn btn-primary btn-block" type='button'
                                                    disabled={this.state[`isLoadingBtn${conferenceDetail._id}`]}
                                                >
                                                    Join Now &nbsp; {this.state[`isLoadingBtn${conferenceDetail._id}`] &&
                                                        <MySpinner />}
                                                </button>

                                            </div>
                                        </div>
                                        {/* {conferenceDetail?.description && <div className="row">
                                            <div className="col-sm-12 mt-3 mb-3">
                                                <div className="header-text">
                                                    <h1 className="title">Description</h1>
                                                </div>
                                                <h6>{conferenceDetail?.description}</h6>

                                            </div>
                                        </div>} */}

                                        {/* <div className="row">
                                            <div className="col-sm-12 mt-3 mb-3 spotlight-web">
                                                <div className="header-text">
                                                    <h1 className="title">Spotlight</h1>
                                                </div>
                                                <Spotlight
                                                    data={this.state.spotlight}
                                                />
                                                {
                                                    !this.state.isLoading && this.state.spotlight.length == 0 &&
                                                    <div className="w-100 text-center row nodata m-auto">
                                                        <div className="m-auto">
                                                            <NoMediaICon />
                                                            
                                                            <h2>No Data Found</h2>
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        </div> */}
                                    </>
                                    :
                                    <>
                                        <div clapssName="row">
                                            {bannerStag ?
                                                <div className="col-md-12 cursor-point"
                                                >
                                                    <div className="full-image-banner">
                                                        {
                                                            bannerStag?.streamUrl ?
                                                                <div className="video ">
                                                                    <ReactPlayer
                                                                    ref={this.playerRef}
                                                                    // onBuffer={()=> alert('okk')}
                                                                    onReady = {this.onREady}
                                                                     controls={true} 
                                                                     playing={true} 
                                                                     muted={this.state.isPlaying}
                                                                      width="442"
                                                                     height="600"
                                                                       url={bannerStag?.streamUrl} />
                                                                    {/* <a
                                                                        onClick={() => bannerStag.isAllowed ? this.onlyJoin('stage', bannerStag._id, conferenceDetail._id) : this.handleJoin(bannerStag.conferenceId, bannerStag._id)}
                                                                        className="overlay"></a> */}
                                                                </div> :
                                                                <img
                                                                    // onClick={() => bannerStag.isAllowed ? this.onlyJoin('stage', bannerStag._id, conferenceDetail._id) : this.handleJoin(bannerStag.conferenceId, bannerStag._id)}
                                                                    src={conferenceDetail?.mainStageImage ? conferenceDetail?.mainStageImage : bannerStag?.image ? bannerStag?.image : require('../../../assets/images//placeholder1.1.png')} className="img-fluid" />
                                                        }

                                                        <div className="detail d-flex justify-content-between align-items-center">
                                                            <div>
                                                                {/* <h4>{bannerStag?.stageName}</h4> */}
                                                                <h5>{bannerStag?.stageName}</h5>
                                                                <h6 className="d-flex">
                                                                    <span><Calender /></span>
                                                                    <span className="ml-2">{moment(bannerStag?.startDate).format('MMM DD')}&nbsp;</span>
                                                                    <span><Clock /></span>
                                                                    <span className="ml-2">{getTimeZone(bannerStag?.startTime, moment.tz.guess())}  to   {moment(bannerStag?.endDate).format('MMM DD')}, {getTimeZone(bannerStag?.endTime, moment.tz.guess())}</span>

                                                                </h6>
                                                            </div>
                                                            <div className="d-flex">
                                                                {/* <HashLink smooth to="#timeHere" id="joinButton" className="btn btn-lg btn-primary btn-blue btn-block hiii11" spy={true} smooth={true} offset={50} duration={500} delay={1000} style={{ maxWidth: '250px' }} >View Stage Timetable</HashLink> */}
                                                                {loungList.length != 0 && <ScrollLink
                                                                    to="Lounges"
                                                                    id="joinButton"
                                                                    spy={true}
                                                                    smooth={true}
                                                                    offset={-70}
                                                                    duration={1500}
                                                                    style={{ maxWidth: '200px' }}

                                                                    className="btn btn-lg btn-primary btn-blue btn-block confebtn mr-3"
                                                                >Go to Lounges</ScrollLink>}
                                                                &nbsp;&nbsp;
                                                            <ScrollLink
                                                                    to="timeHere"
                                                                    id="joinButton"
                                                                    spy={true}
                                                                    smooth={true}
                                                                    offset={-70}
                                                                    duration={1500}

                                                                    className="btn btn-lg btn-primary btn-blue btn-block confebtn"
                                                                >View Stage Timetable</ScrollLink>


                                                            </div>
                                                        </div>
                                                       {conferenceDetail?.description && <div className="detail pt-0">
                                                            <p className="mb-0">{conferenceDetail?.description}</p>
                                                        </div>}
                                                    </div>
                                                </div>
                                                :
                                                <>
                                                    <div className="cm-wrapper confreance-detail-parent pt-4">
                                                        <div className="leftborder-parent  mb-4">
                                                            <h4>{conferenceDetail?.conferenceName}&nbsp;&nbsp;
                                </h4>
                                                            <h6><span><Calender />&nbsp;&nbsp;</span> {moment(conferenceDetail?.startDate).format('MMM DD')},{getTimeZone(conferenceDetail?.startTime, moment.tz.guess())}  to   {moment(conferenceDetail?.endDate).format('MMM DD')}, {getTimeZone(conferenceDetail?.endTime, moment.tz.guess())}<span>&nbsp;&nbsp;&nbsp;
                                </span> </h6>
                                              
                                                        </div>
                                                    
                                                    </div>
                                                    {/* <div className="row"> */}
                                                    <div className="col-md-12">
                                                        <div className="full-image-banner">
                                                            <img src={conferenceDetail?.mainStageImage ? conferenceDetail?.mainStageImage : require('../../../assets/images/placeholder.png')} className="img-fluid" />
                                                                   <div className="d-flex" style={{justifyContent: 'flex-end'}}>
                                                        {loungList.length != 0 && <ScrollLink
                                                                    to="Lounges"
                                                                    id="joinButton"
                                                                    spy={true}
                                                                    smooth={true}
                                                                    offset={-70}
                                                                    duration={1500}
                                                                    style={{ maxWidth: '200px' }}

                                                                    className="btn btn-lg btn-primary btn-blue btn-block confebtn mr-3"
                                                                >Go to Lounges</ScrollLink>}
                                                        </div>
                                                        </div>
                                                        
                                                        {conferenceDetail?.description && <div className="detail pt-0">
                                                            <p className="mb-0">{conferenceDetail?.description}</p>
                                                        </div>}
                                                    </div>
                                                    {/* </div> */}
                                                </>

                                            }
                                        </div>


                                        {/* {conferenceDetail?.description && <div className="row">
                                            <div className="col-sm-12 mt-3 mb-3">
                                                <div className="header-text">
                                                    <h1 className="title">Description</h1>
                                                </div>
                                                <h6>{conferenceDetail?.description}</h6>

                                            </div>
                                        </div>} */}






                                        <div className="row mt-3">
                                            <div className="col-sm-12">
                                                <div className="header-text justify-content-end mb-0">
                                                    {this.state.stagList.length > 3 && <>   <Link to={`/stages/${this.props.match.params.coId}`} class="btn btn-primary cursor-point d-none d-sm-block">View All</Link>
                                                        <Link to={`/stages/${this.props.match.params.coId}`} className="d-block d-sm-none small-view-btn">
                                                            <i class="fa fa-eye" aria-hidden="true"></i>
                                                        </Link>
                                                    </>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3 pos-relative">
                                            {/* <div className="tonext d-none d-sm-block">
                                        <i class="fa fa-long-arrow-right" />
                                        </div> */}

                                            {
                                                this.state.stagList?.map((item, index) => {
                                                    console.log("lkjmk", item)
                                                    return (
                                                        <a
                                                            className="four-div"
                                                            onClick={() => item.isAllowed ? this.onlyJoin('stage', item._id, conferenceDetail._id) : this.handleJoin(item.conferenceId, item._id)}
                                                        >
                                                            {/* <div className="col-sm-6 col-md-4 col-xl-3">                                                            */}
                                                            <div className="conference-block">
                                                                <div className="img">
                                                                    <img className="img-fluid mb-4" src={item.image ? item.image : require('../../../assets/images/placeholder.png')} />
                                                                </div>

                                                                <div className="text">
                                                                    <div className="leftborder-parent">
                                                                        {/* <h4>{item?.stageName}</h4> */}
                                                                        <h5>{item?.stageName}</h5>
                                                                        <h6>
                                                                            <span><Calender /></span>
                                                                            <span className="ml-2">{moment(item?.startDate).format('MMM DD')}&nbsp;</span>
                                                                        </h6>
                                                                        <h6>
                                                                            <span><Clock /></span>
                                                                            <span className="ml-2">{getTimeZone(item?.startTime, moment.tz.guess())}  to   {moment(item?.endDate).format('MMM DD')}, {getTimeZone(item?.endTime, moment.tz.guess())}</span>

                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* </div> */}
                                                        </a>
                                                    )
                                                })
                                            }
                                        </div>
                                        {/* <div className="row">
                                            <div className="col-sm-12 mt-3 mb-3 spotlight-web">
                                                <div className="header-text">
                                                    <h1 className="title">Spotlight</h1>
                                                </div>
                                                <Spotlight
                                                    data={this.state.spotlight}
                                                />
                                                {
                                                    !this.state.isLoading && this.state.spotlight.length == 0 &&
                                                    <div className="w-100 text-center row nodata m-auto">
                                                        <div className="m-auto">
                                                            <NoMediaICon />
                                                            
                                                            <h2>No Data Found</h2>
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        </div> */}
                                    </>

                                }
                                {this.state.spotlight.length != 0 && <div className="row">
                                    <div className="col-sm-12 mt-3 mb-3 spotlight-web">
                                        <div className="header-text">
                                            <h1 className="title">Spotlight</h1>
                                        </div>
                                        <Spotlight
                                            data={this.state.spotlight}
                                        />
                                        {
                                            !this.state.isLoading && this.state.spotlight.length == 0 &&
                                            <div className="w-100 text-center row nodata m-auto">
                                                <div className="m-auto">
                                                    <NoMediaICon />

                                                    <h2>No Data Found</h2>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>}
                                {/* ------------lounge---------- */}
                                {loungList.length != 0 && <div id="Lounges">
                                    <div className="header-text">
                                        <h1 className="title">Lounges</h1>
                                    </div>

                                    <LoungeJoinCard
                                        {...this}
                                        list={loungList}
                                        conferenceDetail={conferenceDetail}

                                    />
                                    {
                                        !this.state.isLoading && loungList.length == 0 &&
                                        <div className="w-100 text-center row nodata m-auto">
                                            <div className="m-auto">
                                                <NoMediaICon />

                                                <h2>No Data Found</h2>
                                            </div>
                                        </div>
                                    }
                                </div>}





                                <div className="event-timetable evnt-diary-wrp mt-5" id="timeHere">
                                    <h1 className="home-heading pos-relative mb-5 text-center">{conferenceDetail?.conferenceName} Timetable
                                  <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                    </h1>
                                    <br />
                                    <div className="event-tab-parent">
                                        {
                                            tabData.map((item, index) => {

                                                return (

                                                    <div className={`event-tab ${tab == index ? 'active' : ''}`} onClick={() => this.handleTab(index, item.startDate)} >
                                                        <div>
                                                            <h3>{moment(item?.startDate).format('Do')}</h3>
                                                            <h6>{moment(item?.startDate).format('MMMM')}</h6>
                                                        </div>
                                                    </div>
                                                )
                                            })

                                        }


                                    </div>

                                    <div className="event-tabbody-parent mt-4">
                                        {
                                            timetableList.map((item, index) => {
                                                let speaker = item?.speakerId ? item?.speakerId : []

                                                console.log("dsakjhbdjn", item)
                                                return (
                                                    <div className="event-tabbody">
                                                        <div className="time-details">
                                                            <h6 className="">
                                                                {/* <span ><Clock /></span><br /> */}
                                                                <h6 className="">
                                                                    <span ><Clock /></span><br />
                                                                    <h6 className="mt-2 "><b>Start Time</b></h6>
                                                                    <h6 className="mt-2 ">{getTimeZone(item?.startDate, moment.tz.guess())}</h6>
                                                                    <h6 className="mt-2 "><b>End Time</b> </h6>
                                                                    <h6 className="mt-2 ">{getTimeZone(item?.endDate, moment.tz.guess())}</h6>
                                                                    {/* <h6>(GMT)</h6> */}
                                                                </h6>
                                                                {/* <h6 className="mt-2">{getTimeZone(item?.startDate, moment.tz.guess())} - {getTimeZone(item?.endDate, moment.tz.guess())}</h6> */}
                                                                {/* <h6>(GMT)</h6> */}
                                                            </h6>
                                                            {
                                                                moment().isSameOrBefore(item?.endDate) &&
                                                                <button type="button" onClick={() => this.handleTableClick(item)} class="btn btn-blue mt-2 mb-2" ><span className="cal"><Calender /></span>{item?.isAttend ? 'Attending' : 'Set Reminder'}   </button>}
                                                            {conferenceDetail.isAllowed && item?.videoUrl &&
                                                                <Link onClick = {()=> this.handleJoinReplay(item._id, item?.stageId?._id)} to={`/seminar/${this.props.match.params.coId}/${'table'}/${item._id}`}
                                                                    className="btn btn-blue blue mt-2 mb-2"><span className="play-icon">< PlayIcon /></span>Replay</Link>
                                                            }

                                                        </div>
                                                        <div className="event-detail">

                                                            <h2>{item?.sessionName}<small>. Session</small></h2>
                                                            <h4>{item?.stageId?.stageName}<small>.&nbsp;Stage</small></h4>
                                                            <p>{item?.description} </p>
                                                            <div className="speaker-details">
                                                                {/* <div className="img speaker_img"> */}
                                                                <div >
                                                                    {
                                                                        speaker.map((speaker, ind) => {
                                                                            return (

                                                                                <div className="profile" style={{ marginTop: 8 }}>
                                                                                    {speaker?.image ?
                                                                                        <img className="img-fluid" src={speaker?.image} onClick={() => this.showSpeakerProfileModal(speaker)} />
                                                                                        :
                                                                                        <img src={require('../../../assets/images/login_user.png')} onClick={() => this.showSpeakerProfileModal(speaker)} className="img-fluid" />
                                                                                    }
                                                                                    <span className="text" onClick={() => this.showSpeakerProfileModal(speaker)}>
                                                                                        <p>{speaker?.firstName} {speaker?.lastName}</p>
                                                                                        <p className="mb-0">{speaker?.jobTitleOther}</p>
                                                                                    </span>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                        {
                                            !this.state.isLoading && timetableList.length == 0 &&
                                            <div className="w-100 text-center row nodata m-auto">
                                                <div className="m-auto">
                                                    <NoMediaICon />

                                                    <h2>No Data Found</h2>
                                                </div>
                                            </div>
                                        }




                                    </div>


                                </div>
                                <br />
                                <div className="speakers-parent mt-5">
                                    <h1 className="home-heading pos-relative mb-5 text-center">Speakers
                                  <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                    </h1>

                                    <br />
                                    <div className="row">
                                        {
                                            speakersList.map((item, index) => {
                                                return (
                                                    <div className="four-div speaker cursor-point" onClick={() => this.showSpeakerProfileModal(item)}>
                                                        <img className="img-fluid" src={item?.image ? item?.image : require("../../../assets/images/placeholder.png")} />
                                                        <div className="text-uperimg">
                                                            <h3>{item?.firstName} {item?.lastName}</h3>
                                                            <p>{item?.jobTitleOther}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                        {
                                            !this.state.isLoading && speakersList.length == 0 &&
                                            <div className="w-100 text-center row nodata m-auto">
                                                <div className="m-auto">
                                                    <NoMediaICon />

                                                    <h2>No Data Found</h2>
                                                </div>
                                            </div>
                                        }


                                    </div>
                                </div>
                                <br />
                                {mediaData.length != 0 && <div className="speakers-parent mt-5">
                                    <h1 className="home-heading pos-relative mb-5 text-center">Media
                                  <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                    </h1>
                                    <br />
                                    <div className="row">
                                        <div className="images-video mt-4">
                                            <Media
                                                {...this.props}
                                                mediaData={mediaData}
                                                // getMedia={(data) => this.handleMedia(data)}
                                                addHide={true}
                                                isAdd={true}
                                            // onDeleteMedia={this.getMedia}
                                            />
                                        </div>

                                        {
                                            !this.state.isLoading && mediaData.length == 0 &&
                                            <div className="w-100 text-center row nodata m-auto">
                                                <div className="m-auto">
                                                    <NoMediaICon />

                                                    <h2>No Data Found</h2>
                                                </div>
                                            </div>
                                        }


                                    </div>
                                </div>}
                                <br />
                            </div>
                            

                        </div>
                        <Footer />
                        <Modal
                            footer={null}
                            centered
                            visible={this.state.visible1}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                            wrapClassName="mail_modal"
                            className="cm_popup signup_modal "
                        >
                            <div className="sign-modal-content timetable-modal">
                                <div className="row align-items-center">
                                    <div className="col-md-12" >
                                        <h5 className="pos-relative text-center mt-5 mb-5">
                                            A reminder for this session has been set. You will recieve a notification just before the session starts.
                                            <br />
                                            To set a reminder in your calendar click below.<span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>

                                        </h5>
                                        {/* <h4 className="text-center mt-5 mb-5">Do you want to add a reminder in: </h4> 
                                        <Link to="" class="calenderbtn">
                                            <span>Google Calender</span>
                                            <span><Googlebtn/></span>
                                        </Link>
                                            <br/>
                                        <Link to="" class="calenderbtn">
                                            <span>Apple Calender</span>
                                            <span><Applebtn/></span>
                                        </Link> */}
                                        <div className="text-center">
                                            <AddToCalendar
                                                event={{
                                                    ...this.state.calenderData
                                                }}
                                                // defaultValue={""}
                                                optionsOpen={isOpen}
                                                buttonLabel=""
                                                listItems={items}

                                            />

                                            {/* <a onClick={this.handleSetReminder} class="calenderbtn mt-2">
                                                <span>Reminder for session</span>
                                                <span><Applebtn /></span>
                                            </a> */}
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </Modal>

                        <Modal
                            footer={null}
                            centered
                            visible={this.state.visible}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                            className="paymentpopup"
                        >
                            <div className="rounded-textbox-form mt-5 mb-4 w-100" >
                                <div className="row">
                                    <form className="col-md-10 offset-md-1" onSubmit={this.submitPassword} >
                                        <div className="form-group">
                                            <label>Enter Seminar password </label><br />
                                            <input type="password"
                                                className="form-control"
                                                placeholder="Enter password"
                                                maxLength={50}
                                                name="password"
                                                value={password}
                                                onChange={this.handleOnChangeConference}
                                            />
                                            <InlineError
                                                message={this.state.passwordError}
                                            />
                                            <p className="passowrdtext" style={{ margin: 10 }}>If you haven’t received a password contact <a href="mailto:info@worldwidejobfinder.com">
                                            info@worldwidejobfinder.com</a></p>
                                        </div>

                                        <div className="form-group text-center">
                                            <button type="submit" class="btn btn-red btn-label" onClick={this.submitPassword}>Submit</button>
                                        </div>

                                    </form>
                                </div>
                            </div>

                        </Modal>


                        <Modal
                            footer={null}
                            centered
                            visible={this.state.visibleSpeakerProfile}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                            wrapClassName="mail_modal"
                            className="cm_popup signup_modal "
                            zIndex="99999"
                        >
                            <div className="sign-modal-content timetable-modal">
                                <div className="row align-items-center">
                                    <div className="col-md-12" >

                                        {

                                            <div className="text-center spaker-detail-popup mt-5">
                                                <div className="profile">
                                                    <img className="img-fluid" src={selectedSpeakerData?.image ? selectedSpeakerData?.image : require("../../../assets/images/placeholder.png")} />
                                                </div>
                                                <div className="details">
                                                    <h3>{selectedSpeakerData?.firstName} {selectedSpeakerData?.lastName}</h3>
                                                    <h6>{selectedSpeakerData?.jobTitleOther}</h6>
                                                    <p>{selectedSpeakerData?.about}</p>

                                                </div>
                                            </div>

                                        }



                                    </div>
                                </div>
                            </div>
                        </Modal>
















                    </Suspense>

                </section>
            )
    }

}

function mapStateToProps(state) {


    return {
        ...state

    };
}

export default connect(mapStateToProps)(ConferenceDetail);