/**
 * @About
 * 
 */

// root reducer
import rootReducer from "../reducer/Index";
import { createStore, applyMiddleware } from 'redux'
import thunk from "redux-thunk";


const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;









