import React, { Component } from 'react';
import { Hert } from '../../../SvgIcon';
import { DatePicker, Modal } from 'antd';
import moment from "moment"

import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { getTimeFormate } from "../../../utils/DateZone"

import InlineError from "../../../components/InlineError"

class WorkExperience extends Component {
    constructor() {
        super()
        this.state = {
            visible: false,
            orgName: '',
            orgNameError: '',
            des: '',
            desError: '',
            isCurrentCompnay: false,
            wrokFrom: '',
            wrokFromError: '',
            workTill: '',
            workTillError: '',
            describe: '',
            describeError: '',
            editId: ''
        }
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        this.setState({ loading: true });
    };

    handleCancel = () => {
        this.setState({ visible: false });
    }


    //
    handleOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
        this.clearErrorMessage()
    }

    handleCheck = () => {
        this.setState({ isCurrentCompnay: !this.state.isCurrentCompnay },()=>{
            if(this.state.isCurrentCompnay)
            {
                this.setState({workTill: moment() })

            }
            else{
                this.setState({workTill: '' })

            }
        })
    }

    handleDate = (date, type) => {
        this.setState({ [type]: date })
        this.clearErrorMessage()
    }


    handleSubmit = () => {
        let req = {
            workExperience: [{
                "organization": this.state.orgName,
                "designation": this.state.des,
                "currentCompany": this.state.isCurrentCompnay ? 1 : 2,
                "workedFrom": this.state.wrokFrom.valueOf(),
                "workedTill": this.state.workTill.valueOf(),
                "description": this.state.describe
            }]
        }
        _Api(Url.ADD_JOB_PROFILE.method, Url.ADD_JOB_PROFILE.url, req)
            .then(resp => {
                console.log("respresprespresp", resp);
                this.setState({ visible: false, })
                this.handleClear()
                this.props.getDetails()

            }).catch(err => {
                this.setState({ isLoader: false });
                let errorMessage = err.error && err.error.responseMessage || err.message;

            });
    }

    setEdit = (item) => {
        console.log("dgvhbjnmkd", item)
        this.setState({
            editId: item._id,
            orgName: item.organization,
            des: item.designation,
            isCurrentCompnay: item.currentCompany == 1 ? true : false,
            wrokFrom: moment(new Date(item.workedFrom)),
            workTill: moment(new Date(item.workedTill)),
            describe: item.description,
            visible: true,
            // dateTime:  moment(new Date(msgBroadcastTimestamp))
        })
    }

    submitEdit = () => {

        let req = {
            "workExperienceId": this.state.editId,
            workExperience: {
                "organization": this.state.orgName,
                "designation": this.state.des,
                "currentCompany": this.state.isCurrentCompnay ? 1 : 2,
                "workedFrom": this.state.wrokFrom.valueOf(),
                "workedTill": this.state.workTill.valueOf(),
                "description": this.state.describe
            }
        }
        _Api(Url.EDIT_JOB_PROFILE.method, Url.EDIT_JOB_PROFILE.url, req)
            .then(resp => {
                console.log("respresprespresp", resp);
                this.setState({ visible: false, orgName: '', des: '', isCurrentCompnay: false, wrokFrom: '', workTill: '', describe: '' })
                this.props.getDetails()
                this.handleClear()

            }).catch(err => {
                this.setState({ isLoader: false });
                let errorMessage = err.error && err.error.responseMessage || err.message;

            })

    }

    submit = () => {
        if (this.state.orgName == "") {
            this.setState({ orgNameError: "*Please enter value" })
        }
        else if (this.state.des == "") {
            this.setState({ desError: "*Please enter value" })
        }
        else if (this.state.wrokFrom == "") {
            this.setState({ wrokFromError: "*Please enter value" })
        }
        else if (this.state.workTill == "" && this.state.isCurrentCompnay == false) {
            this.setState({ workTillError: "*Please enter value" })
        }
        else if (this.state.editId != '') {
            this.submitEdit()

        }
        else {
            this.handleSubmit()

        }

    }

    handleClear = () => {
        this.setState({
            visible: false,
            orgName: '', des: '', isCurrentCompnay: false, wrokFrom: '', workTill: '', describe: '', editId: ''
        })

    }

    clearErrorMessage = ()=>{
        this.setState({
            orgNameError: '', desError: '',  wrokFromError: '', workTillError: '', })


    }

    delete = (id) => {

        let req = {
            workExperienceId:id
        }
        _Api(Url.DELETE_RESUME.method, Url.DELETE_RESUME.url, req)
            .then(resp => {
                console.log("delete", resp);               
                this.props.getDetails()

            }).catch(err => {
                //alert("radha catch");
                this.setState({ isLoader: false });
                let errorMessage = err.error && err.error.responseMessage || err.message;
                // notify("err", errorMessage);
            });
    }


    render() {
        const { visible, orgName, des, describe } = this.state;
        const { workExperience } = this.props
        const disabledDateEnd = (current) => {
            // Can not select days before today and today
            return current && current.valueOf() < this.state.wrokFrom;
        }
        const disabledDateEnd1 = (current) => {
            // Can not select days before today and today
            return current && current.valueOf() > moment();
        }
        return (
            <>
                <div className="job-common-list wrk-exprcnt-wrp">
                    <div className="jb-cmn-topbar d-flex align-items-center">
                        <h3 className="mb-0">Work Experience</h3>
                        <div className="icn-topbtn ml-auto">
                            <button className="btn-jbcn" onClick={this.showModal}>
                                <i className="fa fa-plus"></i>
                            </button>
                        </div>
                        <Modal
                            centered
                            wrapClassName="signup_parent_modal"
                            className="cm_popup signup_modal "
                            visible={visible}
                            onOk={this.handleOk}
                            onCancel={this.handleClear}
                            footer={null}
                        >
                            <div className="sign-modal-content job-frm-mdl">
                                <div className="row align-items-center">
                                    <div className="col-md-12" >
                                        <h3 className="auth-heading pos-relative text-center mt-5 mb-5">
                                            Work Experience
                                            <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                        </h3>
                                        <form className="cm-form-wrapper ">
                                            <div className="form-group">
                                                <label>Organization</label>
                                                <input
                                                    className="form-control"
                                                    placeholder="enter here..."
                                                    maxLength={256}
                                                    name="orgName"
                                                    value={orgName}
                                                    onChange={this.handleOnChange}

                                                />

                                                <InlineError
                                                    message={this.state.orgNameError}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Designation</label>
                                                <input
                                                    className="form-control"
                                                    placeholder="enter here..."
                                                    maxLength={256}
                                                    name="des"
                                                    value={des}
                                                    onChange={this.handleOnChange}
                                                />

                                                <InlineError
                                                    message={this.state.desError}
                                                />
                                            </div>
                                            <div className="form-group csmt-rad-btn">
                                                <label>Is this your current Company</label>
                                                <div className="assign-checkboc-parent">
                                                    <div>
                                                        <label className="cm_container">
                                                            No
                                                            <input checked={!this.state.isCurrentCompnay} type="radio" onClick={this.handleCheck} name="crntCompany" />
                                                            <span className="radio-checkmark"></span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label className="cm_container">
                                                            Yes
                                                            <input checked={this.state.isCurrentCompnay} type="radio" onClick={this.handleCheck} name="crntCompany" />
                                                            <span className="radio-checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label>Worked From</label>
                                                        <DatePicker
                                                         disabledDate={disabledDateEnd1}
                                                            value={this.state.wrokFrom}
                                                            onChange={(e) => this.handleDate(e, "wrokFrom")}
                                                        />

                                                        <InlineError
                                                            message={this.state.wrokFromError}
                                                        />
                                                    </div>
                                                    {!this.state.isCurrentCompnay && <div className="col-md-6">
                                                        <label>Worked Till</label>
                                                        <DatePicker
                                                        disabledDate={disabledDateEnd}
                                                            value={this.state.workTill}
                                                            onChange={(e) => this.handleDate(e, "workTill")}
                                                        />

                                                        <InlineError
                                                            message={this.state.workTillError}
                                                        />
                                                    </div>}
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>Describe your job role</label>
                                                <textarea
                                                    rows="5" className="form-control"
                                                    name="describe"
                                                    value={describe}
                                                    onChange={this.handleOnChange}
                                                ></textarea>
                                            </div>
                                            <div className="form-group text-center popCar-btn">
                                                <button onClick={this.handleClear} className="btn btn-primary mr-2 jb-btn">Cancel</button>
                                                <button type="button" onClick={this.submit} className="btn btn-primary jb-btn">Save</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                    <div className="job-cmnbody p-2 p-md-3">
                        {
                            workExperience?.map((item, index) => {
                                return (
                                    <div className="jobprofile-list-wrp d-flex align-items-center">
                                        <div className="jobprofile-left-wrp">
                                            <div className="job-dtl-crd">
                                                <h4>{item.designation}</h4>
                                                <ul className="list-unstyled mb-0">
                                                    <li>{item.organization}</li>

                                                    <li className="text-muted">{getTimeFormate(item.workedFrom)}-  {item.currentCompany == 1 ? "Present" : getTimeFormate(item.workedTill)}</li>
                                                    <li>{item.description ? item.description : ''}</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="jobprofile-right-wrp ml-auto">
                                      
                                            <button onClick={() => this.setEdit(item)} className="btn-jbcn">
                                                <i className="fa fa-pencil"></i>
                                            </button>
                                            <button onClick={() => this.delete(item._id)} className="btn-jbcn btn-danger">
                                            <i className="fa fa-trash"></i>
                                            </button>
                                        </div>
                                    </div>

                                )
                            })
                        }
                    </div>
                </div>
            </>
        )
    }
}

export default WorkExperience