import React from "react"
import { connect } from "react-redux";
import { Link, NavLink, withRouter,  } from 'react-router-dom';
import { Menu, Dropdown, Empty, Tooltip, Modal } from 'antd';
import '../../assets/css/web/WebHeader.css';

// import { requestFirebaseNotificationPermission, onMessageListener } from '../../firebase';

import Welcome from "../authentication/Welcome"


import {
    MenuIcon, Cross, Chat, HalfLogo,
    Fillhert,
    NotificationIcon,
    HeaderHeart,
    HeaderNoti,
    HeaderInfo,
    QuestionMark,
    Headersearch,
    ProfileChangePass,
    ProfileMangeservices,
    ProfileLogout,
    ProfileDashboard,
    ProfileTermCondition,
    ProfileBrand,
    ProfileUser
} from "../../SvgIcon";


import { setLocalState, getExhibitionId } from '../../redux/action';


import { _Api } from "../../services/Api";
import Url from "../../services/BaseUrl";
import { notify } from "../../components/Toaster";
import { history } from "../../routing";
import { userActions, getWishList, getNotificationList, getRecentList } from "../../redux/action";
import CallModal from "../../components/call/callModal";
import Search from '../layout/Search';
import SearchPopUp from '../layout/SearchPopUp';
import util from '../../utils/Util.js';
import { bindActionCreators } from "redux"
import Store from '../../redux/store/Store';

import { getBrandHome, getHome, getNetworkData, getConference } from "../../redux/action/Get"
import socket from '../../services/socket';
import Question from "../eventQuestionnaire/Question";

import Const from "../../services/Const"

let isProd = process.env.REACT_APP_ENV == 'prod'


const SupportBoxOpen = (props) => {
    document.body.classList.add('support-open');
    Store.dispatch({ type: 'SHOW_SUPPORT_CHAT' })
}

let isFlag = localStorage.getItem('accessToken') && localStorage.getItem('accessToken') !== ''
const menu = (
    <Menu>
        <Menu.Item>
            <a href="mailto:info@worldwidejobfinder.com">
                Contact Us
        </a>
        </Menu.Item>
        <Menu.Item>
            <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/playlist?list=PL7wFRa79J1gmYszKRfZXsHCzTelyj4R6V">
                HELP Videos</a>
        </Menu.Item>
        <Menu.Item>
            <a target="_blank" rel="noopener noreferrer" href="https://pb-world-comman.s3.eu-west-2.amazonaws.com/HJPB+Timetable.pdf">
               Timetable</a>
        </Menu.Item>
        <Menu.Item>
            <a onClick={() => SupportBoxOpen()}>Support Chat</a>
        </Menu.Item>

    </Menu>
);

const {isHeader, webHeader, searchTab} = Const 

console.log("dnsmd", isHeader)


class Header extends React.Component {
    constructor() {
        super()
        this.state = {
            isBrand: false,
            userDetail: {},
            tab: 0,
            visible: false,
            isPaidUser: false,
            repayment: 0,
            isShow: false,
            detailList: [],
            typing: false,
            typingTimeout: 0,
            menuvisible: false,
            unReadCount: 0
        }
        this.socketEvent();
    }

    // handleSupportChatOpen = () => document.body.classList.toggle('')
    // handleCloseSupportChat = () => document.body.classList.remove('menu_open')
    socketEvent = () => {
        let self = this;
        socket.on('notificationCount', function (params) {
            // console.log("notificationCount", params);
            self.setState({
                unReadCount: self.state.unReadCount + 1
            })
            self.props.dispatch(getNotificationList())
        })
        socket.on('notificationListUpdate', function (params) {
            // console.log("notificationListUpdate", params);
            self.props.dispatch(getNotificationList())
        })
    }



    showProfileMenu = () => {
        this.setState({
            menuvisible: true,
        });
    };

    handleOk = e => {
        console.log(e);
        this.setState({
            menuvisible: false,
        });
    };

    handleCancel = e => {
        console.log(e);
        this.setState({
            menuvisible: false,
        });
    };






    handleMenuOpen = () => document.body.classList.toggle('menu_open')
    handleCloseMenu = () => document.body.classList.remove('menu_open')

    handelSearchOpen = () => {
        this.setState({ isMobileSearch: true });
        document.body.classList.toggle('search_open');
    }

    componentDidMount() {
        let self = this;

        this.props.dispatch(getExhibitionId())
  

        if (localStorage.getItem('accessToken') && localStorage.getItem('accessToken') !== '' && localStorage.getItem('userType') != 1) {
            self.props.dispatch(getHome(this.props.loading?.exhibitionId))
            self.props.dispatch(getConference(this.props.loading?.exhibitionId))
            self.props.dispatch(getBrandHome(this.props.loading?.exhibitionId))
            self.props.dispatch(getNetworkData(this.props.loading?.exhibitionId))
            this.props.dispatch(getWishList())
            this.selectedTab();
            self.props.dispatch(userActions.profile())
            this.props.dispatch(getWishList());
            this.props.dispatch(getNotificationList())
                .then(function (res) {
                    //console.log("##############################res",res);
                    self.setState({
                        unReadCount: res.unReadCount
                    })
                })
            this.props.dispatch(getRecentList());
        } else {
            window.location.href = "/"
        }
    }
    getRecentSearchList = () => {

        let { recentSearches, brandDetail, productDetail, tags } = this.props.recentSearchList.recentData;
        this.setState({
            recentSearches: recentSearches,
            recommendedBrandDetails: brandDetail,
            recommendedProductDetails: productDetail,
            recentTags: tags,
        });
    }


    selectedTab = () => {

        let path = window.location.pathname.split('/')[1]

        switch (path) {
            case 'home':
                this.setState({ tab: 1 })
                break;
            case 'conference':
                this.setState({ tab: 2 })
                break;
            case 'brand':
                this.setState({ tab: 3 })
                break;
            case 'community':
                this.setState({ tab: 4 })
                break;
            case 'networking':
            case 'networklounges':
                this.setState({ tab: 5 })
                break;
            case 'newproduct':
                this.setState({ tab: 6 })
                break;
            case 'offers':
                this.setState({ tab: 7 })
                break;


            default:
                this.setState({ tab: -1 })
        }
    }

    handleLogout = () => {
        localStorage.clear()
        history.push("/")
        window.location.reload()
    }
    handleChatOpen = () => {
        this.props.dispatch({ type: 'CHAT_TOGGLE' });
        document.body.classList.toggle('chat_open_mob');

    }
    handleChatMenu = () => {
        //this.props.dispatch({type:'CHAT_TOGGLE'});
        document.body.classList.remove('chat_open_mob');
    }
    onBagClick() {
        history.push(`/cartview`);
    }

    onSearhClick = () => {
        this.setState({ isSearchClick: true });
        this.props.dispatch(getRecentList());
        _Api(Url["CHECK_SEARCH_PLAN"].method, Url["CHECK_SEARCH_PLAN"].url, { exhibitionId: this.props.loading?.exhibitionId })
            .then(resp => {


                this.getRecentSearchList()
                this.setState({ visible: resp?.responseData?.isBuy == 0, isPaidUser: resp?.responseData?.isBuy == 1 });

            }).catch((err) => {
                if (err.error?.errorCode == 19) {
                    this.setState({ visible: true, isPaidUser: false, repayment: 1 });
                }
                else {
                    // this.handleError(err);
                }

            });

    }
    onLinkClick = (value, scope) => {
        _Api(Url.SEARCH_COUNT.method, Url.SEARCH_COUNT.url)
            .then(() => {
                this.setState({ isPaidUser: false })
                window.location.href = value
                // scope.props.history.push(value);
                // window.location.reload()


            }).catch(err => {
                if (err.error?.errorCode == 19) {
                    this.setState({ visible: true, isPaidUser: false, repayment: 1 });
                }
                scope.handleError(err);
            });

    }

    selectUser = (user) => {
        let self = this;
        // console.log("userrrrrr",user);
        if (this.props.userDetail._id !== user._id) {
            this.setState({
                selectedUser: user,
                selectedUserId: user._id,
                chatType: 3,
                isShow: true
            }, function () {
                self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 3 })
                self.props.dispatch({ type: 'CHAT_OPEN' })
                document.body.classList.toggle('chat_open_mob');
                self.props.dispatch({ type: 'SELECTED_USER', selectedUser: user })
                socket.emit('groupAdd', { type: 7, userId: user._id });
                //socket.emit('userConnection', {userId: user._id});
            })
        }


    }


    createSearchResult = () => {
        let { detailList } = this.state
        let desArray = []
        detailList?.length > 0 && detailList.forEach((item => {


            switch (Number(item.dataType)) {
                case 1:
                    desArray.push(<div>
                        <div className="notify-row cursor-point">
                            <div className="profile-dp">
                                <img src={item?.logoImage || require('../../assets/images/login_user.png')} className="circle-image" />
                            </div>
                            <div className="message">
                                <div onClick={() => this.onLinkClick(`/business/${item._id}/${item?.activeCompany}`, this)} ><h5><b>{item.companyName || item?.name}</b> <span><small> • Business</small></span></h5> </div>
                            </div>
                        </div>
                    </div>);
                    break;
                case 2:
                    desArray.push(
                        <div>
                            <div className="notify-row cursor-point">
                                <div className="profile-dp">
                                    <img src={item.image || require('../../assets/images/login_user.png')} className="circle-image" />
                                </div>
                                <div className="message">
                                    <div onClick={() => this.onLinkClick(`/web-job-details/${item._id}`, this)}><h5><b>{item.name}</b><span><small> • Job</small></span> </h5> </div>

                                </div>
                            </div>
                        </div>);
                    break;
                case 5:
                    desArray.push(<div>
                        <div className="notify-row cursor-point">
                            <div className="profile-dp">
                                <img src={item.coverImage || require('../../assets/images/login_user.png')} className="circle-image" />
                            </div>
                            <div className="message">
                                <div onClick={() => this.onLinkClick(`/seminar/${item._id}`, this)}><h5><b>{item?.name} </b><span><small> • Seminar</small></span> </h5> </div>

                            </div>
                        </div>
                    </div>);
                    break;
                case 7:
                    desArray.push(<div>
                        <div className="notify-row cursor-point">
                            <div className="profile-dp">
                                <img src={item.image || require('../../assets/images/login_user.png')} className="circle-image" />
                            </div>
                            <div className="message">

                                <div onClick={() => this.onLinkClick(`/home`, this)}><h5><b>{item?.name} </b><span><small> • Lounge</small></span> </h5> </div>

                            </div>
                        </div>
                    </div>);
                    break;
                case 6:
                    desArray.push(<div>
                        <div className="notify-row cursor-point">
                            <div className="profile-dp">
                                <img src={item.image || require('../../assets/images/login_user.png')} className="circle-image" />
                            </div>
                            <div className="message">
                                <div onClick={() => this.onLinkClick(`/home`, this)}><h5><b>{item?.name} </b><span><small> • Stage</small></span> </h5> </div>

                            </div>
                        </div>
                    </div>);
                    break;

                case 4:
                    desArray.push(<div>
                        <div className="notify-row cursor-point">
                            <div className="profile-dp">
                                <img src={item.image || require('../../assets/images/login_user.png')} className="circle-image" />
                            </div>
                            <div className="message">
                    <div onClick={() => this.onLinkClick(`/user/profile/${item._id}`, this)}><h5><b>{item?.name}</b><span><small> • People</small></span>  </h5> </div>
                          
                            </div>
                            <div className="srch-chat-icn">
                                {<span onClick={() => this.selectUser(item)} className={`chat cursor-point ${item.isOnline == 1 ? 'green' : 'yellow'}`} ><Chat /></span>}
                            </div>
                        </div>
                    </div>);
                    break;

            }

        }))

        return desArray;
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (e.target.value.trim() != '') {
                history.push(`/search/results/all/page=1&keywords=${e.target.value}`);
                window.location.reload()
            }
        }
    }
    handleError(err) {
        let errorMessage = err.error && err.error.responseMessage || err.message;
        notify("err", errorMessage);
    }
    onBlur = (e) => {
        console.log("hgggggggggggggggggggggggggggg")
        document.activeElement.blur();
      }

    getList = () => {
        let { appLocalState } = this.props;
        this.setState({ isLoader: true })
        let req = {
            page: 1,
            limit: 10,
            searchKey: appLocalState.search,
            exhibitionId: this.props.loading.exhibitionId
        }
        let stateData = "seacrhData"

        let url = Url["SEARCH_LIST"].url, method = Url["SEARCH_LIST"].method;
        _Api(method, url, req)
            .then(resp => {
                this.setState({
                    isLoading: false,
                    detailList: resp.responseData?.details || []
                })

            }).catch((err) => {
                if (err?.error?.errorCode == 19) {
                    this.setState({ visible: true, isPaidUser: false, repayment: 1 });
                }
                if (err?.error?.errorCode == 5) {
                    this.setState({
                        detailList: [],
                        isEmpty: true
                    })



                }
                else {

                    // this.handleError(err);
                    //handle for error : This will stop loader
                    this.setState({ isLoading: false, [`${stateData}Loaded`]: true });
                }


            })
    }

    onTabClick = () => {
        let { appLocalState, dispatch } = this.props;
        this.selectedTab()
        appLocalState["search"] = "";
        dispatch(setLocalState(appLocalState));
    }


    onChangeSearch = e => {
        const self = this;
        const val = e.target.value

        //  if(e.target.value)
        let { appLocalState, dispatch } = this.props;
        appLocalState["search"] = val

        if (val.trim() != '') {
            dispatch(setLocalState(appLocalState));
            if (val) {
                this.setState({ isEdit: val && true || false, isSearchClick: false, isEmpty: false })
            }
            else {
                this.setState({ isSearchClick: true })
            }

        }

        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            typing: false,
            typingTimeout: setTimeout(function () {
                self.getList()
            }, 300)
        });
    }





    onNotificationClick = () => {
        this.setState({
            unReadCount: 0,
            isNotificationVisible: !this.state.isNotificationVisible
        });



    }
    onClickRecenKey = (value) => {
        let { appLocalState, dispatch } = this.props;
        appLocalState["search"] = value;
        dispatch(setLocalState(appLocalState));
        history.push(`/search/results/all/page=1&keywords=${value}`);
        window.location.reload()
    }

    handleSearch = () => {
        history.push(`/search/results/all/page=1&keywords=${this.props.appLocalState.search}`)
        window.location.reload()
    }



    render() {
        const { tab, recentSearches, isEdit, isNotificationVisible, recommendedProductDetails, recommendedBrandDetails } = this.state;
        let { wishList = {}, appLocalState, notificatonList, loading } = this.props;
        let { productList = [], brandList = [] } = wishList;
        if (this.props.chatId.isChatOpen) {
            document.body.classList.add('webchatopen');
            document.body.classList.add('conference');
        }
        else {
            document.body.classList.remove('webchatopen');
            document.body.classList.remove('conference');
        }
        // if (this.props.chatId.isChatOpen) {
        //     document.body.classList.add('webchatopen');
        // }

        // else {
        //     document.body.classList.remove('webchatopen');
        // }

        if (this.state.isNotificationVisible) {
            const element = document.querySelector("body");
            if (!element.classList.contains("conference")) {
                document.body.classList.toggle('notificationopen');
            }

        }
        else {
            document.body.classList.remove('notificationopen');
        }
        // console.log("dshgfsdbjkdsvfdvfdv", this.props.meaasgeCount);
        var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
        // console.log("dshgfsdbjkdsvfdvfdv", isMobile);
        const isShow = true

        console.log("dwgdhjkdsadasd",this.props.userDetail?.isQuestionComplete, this.props.userDetail?.isNewUser)
        let isSHowPlan  = this.state.visible ? this.state.visible :  this.props.showUserPlanPopup 

        const { pathname } = window.location


        return (
            <>

                <header className="header-wrapper">

                    <div className="mobile_trigger order-0" >
                        <span className="menuicon cur-point" onClick={this.handleMenuOpen}><MenuIcon onClick={this.handleMenuOpen} /></span>
                    </div>

                    {/* --------------------responsive view only visible in > 991px screen-------------- */}
                    <div className="sidebar mobileWrp-jobfinder">
                        <div className="cm-logo text-center justify-content-center">
                             <img src={require('../../../src/assets/images/aesthetic/logo.png')} className="img-fluid radha" />
                            {/*<><span className="logo ml-0"><HalfLogo /></span>aestheticmedicine.online</>*/}

                        </div>

                        <div className="cross float-right pr-3 " onClick={this.handleCloseMenu}>
                            <Cross />
                        </div>
                        <ul>
                            <li><NavLink to="/home" onClick={this.handleCloseMenu}><span className="menu_text">HOME</span></NavLink></li>
        <> <li onClick={this.handleCloseMenu}><NavLink to="/seminar"><span className="menu_text" onClick={this.handleCloseMenu}>{webHeader.tab2}</span></NavLink></li>
        <li onClick={this.handleCloseMenu}><NavLink to="/business" ><span className="menu_text" onClick={this.handleCloseMenu}>{webHeader.tab3}</span></NavLink></li>
                                <li onClick={this.handleCloseMenu}><NavLink to="/community" ><span className="menu_text" onClick={this.handleCloseMenu}>CONNECTIONS</span></NavLink></li>
                                {/* <li onClick={this.handleCloseMenu} ><NavLink to="/networking" ><span className="menu_text" onClick={this.handleCloseMenu}>NETWORKING</span></NavLink></li> */}
        <li onClick={this.handleCloseMenu} ><NavLink to="/newjob" ><span className="menu_text" onClick={this.handleCloseMenu}>{webHeader.tab6}</span></NavLink></li>
        <li onClick={this.handleCloseMenu} ><NavLink to="/trainings" ><span className="menu_text" onClick={this.handleCloseMenu}>{webHeader.tab7}</span></NavLink></li>
                            </>
                        </ul>

                    </div>

                    <div className="cm-section container-fluid pl_sm_0">
                        <div className="d-flex align-items-center pt-3 pb-3 flex-wrap justify-content-end">

                            <div className="logo mr-auto ">

                                <img src={require('../../../src/assets/images/aesthetic/logo.png')} className="img-fluid" />
                            </div>

                            {this.props.userDetail._id ?
                                <>
                                     {isShow?
                                    <>
                                    { <span onClick={this.handleChatOpen} title="Chat" className="chat pos-relative"><Chat />{this.props.meaasgeCount ? <span className="counter chat-counter white-counter">{this.props.meaasgeCount}</span> : ""}</span>}

                                    {((this.props.userDetail.userType === 2 || this.props.userDetail.userType === 4) ) && <a className="fa fa-search" onClick={this.handelSearchOpen} onKeyDown={this.handleKeyDown} onClick={this.handelSearchOpen}><Headersearch /></a>}

                                    { !this.props.appLocalState.search && <i HeaderSearch onKeyPress={(e) => this.handleKeyDown(e)} value={appLocalState.search} onKeyDown={(e) => this.handleKeyDown(e)} onClick={this.handelSearchOpen} onChange={(e) => this.onChangeSearch(e)} />
                                    }

                                    {(this.props.userDetail.userType != 4 && this.props.userDetail.userPlan == 1)  && <Link to="/plan" className="btn btn-red addbtn mobhide" title="Take a Showcase">Take a Showcase</Link>}
                                    { <Tooltip placement="leftBottom" title="Help" className="d-none d-sm-block ">
                                        <Dropdown overlay={menu} placement="bottomCenter" arrow trigger={['click']} className="">

                                            <a target="_blank" className="help " >
                                                {!this.props.isSupportShow && this.props.supportNotify ? <span className="counter"></span> : ''}
                                                <QuestionMark />
                                            </a>

                                        </Dropdown>
                                    </Tooltip>}

                                    { <Dropdown overlay={menu} placement="bottomCenter" arrow trigger={['click']} className="d-block d-sm-none">
                                        <a target="_blank" className="help " >
                                            {!this.props.isSupportShow && !this.props.isSupportShow && this.props.supportNotify ? <span className="counter"></span> : ''}


                                            <QuestionMark />


                                        </a>
                                    </Dropdown>

                                    }




                                    {
                                        isMobile ?
                                            <Dropdown visible={isNotificationVisible} onVisibleChange={(e) => this.onNotificationClick(e)} trigger="click" overlayClassName="notifcn-csmt-wrp" overlay={<SearchPopUp scope={this} />

                                            } placement="bottomRight" className="d-block d-sm-none">
                                                <a onClick={(e) => this.onNotificationClick(e)} className="mr-1 cursor-point">
                                                    <span className="pos-relative cursor-point notification">
                                                        <HeaderNoti />
                                                        {this.state.unReadCount > 0 && <span className="counter-mob d-block d-sm-none"></span>}
                                                    </span>
                                                </a>
                                            </Dropdown>

                                            :
                                            <Tooltip placement="top" title="Notification" className="d-none d-sm-block ">
                                                {<Dropdown overlayClassName="notifcn-csmt-wrp" visible={isNotificationVisible} onVisibleChange={(e) => this.onNotificationClick(e)} trigger="click" overlay={<SearchPopUp scope={this} />

                                                } placement="bottomRight" >

                                                    <a onClick={(e) => this.onNotificationClick(e)} className="mr-1 cursor-point">

                                                        <span className="pos-relative cursor-point notification">
                                                            <HeaderNoti />
                                                            {this.state.unReadCount > 0 && <span className="counter d-none d-sm-block">{this.state.unReadCount}</span>}
                                                        </span>
                                                    </a>
                                                </Dropdown>}
                                            </Tooltip>

                                    }




                                    { <Link to={`/cartview`} className="showon-desktop">
                                        <Tooltip placement="top" title="Wishlist">
                                            <span className="pos-relative cursor-point notification" onClick={() => this.onBagClick}>
                                                <HeaderHeart />
                                            </span>
                                        </Tooltip>
                                    </Link>}

                                 </>: this.props.userDetail.userType != 4 && this.props.userDetail.userPlan == 1 && <Link to="/plan" className="btn btn-red addbtn mobhide" title="Take a Showcase">Take a Showcase</Link> }
                                    <div className="ml-3 header_drop hidemobile991">
                                        <Dropdown overlay={
                                            <Menu>

                                                <p className="text showmobile991">
                                                    <p className="mb-0">{this.props.userDetail.firstName ? this.props.userDetail.firstName : ""}</p>
                                                    <p className="mb-0">{String(this.props.userDetail.email).toLowerCase()}</p>
                                                </p>
                                                {(this.props.userDetail.userType === 2 || this.props.userDetail.userType === 4) ?
                                                    <Menu.Item key="1">
                                                        <Link to="/dashboard">Dashboard</Link>
                                                    </Menu.Item>
                                                    : ""}

                                                
                                                    <Menu.Item key="2">
                                                        <Link to="/profile">Personal Profile</Link>
                                                    </Menu.Item>                                                       
                                                
                                                
                                                <Menu.Item >
                                                       <Link to="/career-profile">Career Profile</Link>
                                                   </Menu.Item>
                                                 {isHeader.isManageCompany && (this.props.userDetail.userType === 2 || this.props.userDetail.userType === 4) ?
                                                    //  {((this.props.userDetail.userType === 2  && this.props.userDetail?.userPlan === 3) || (this.props.userDetail.userType === 4 && this.props.userDetail?.userPlan === 3)) ?

                                                    <Menu.Item >
                                                        <Link to="/manage-company">Manage Company</Link>
                                                    </Menu.Item>
                                                    : ""}


                                                {isHeader.isManageShowCase && (this.props.userDetail.userType === 2 || this.props.userDetail.userType === 4) ?
                                                    <Menu.Item >
                                                        <Link to={`/business/profile/${localStorage.getItem("ownerId") ? localStorage.getItem("ownerId") : localStorage.getItem("id")}`}>Manage Showcase</Link>
                                                    </Menu.Item>
                                                    : ""}


                                                {isHeader.isEnquiries && (this.props.userDetail.userType === 2 || this.props.userDetail.userType === 4) ?
                                                    <Menu.Item >
                                                        <Link to={`/enquiry`}>Enquiries</Link>
                                                    </Menu.Item>
                                                    : ""}

                                                {<Menu.Item >
                                                    <Link to="/event-detail">My Event</Link>
                                                </Menu.Item>}
                                                <Menu.Item key="5">
                                                    <Link to="/change-password">Change Password</Link>
                                                </Menu.Item>
                                                <Menu.Item key="6">
                                                    {/*<Link to="https://bit.ly/PBWORLDTC" target="_blank">Terms and Conditions</Link>*/}
                                                    <a href="https://pb-world-comman.s3.eu-west-2.amazonaws.com/Worldwidejobfinder+Terms+and+Conditions.pdf" target="_blank">Terms and Conditions</a>
                                                </Menu.Item>
                                             


                                                
                                                <Menu.Divider />
                                                <Menu.Item key="7">
                                                    <div onClick={() => { this.handleLogout() }}>Logout</div>
                                                </Menu.Item>
                                                
                                            </Menu>
                                        } trigger={['click']}>
                                            <div className="profile">
                                                <img src={this.props.userDetail.image || require('../../assets/images/login_user.png')} className="img-fluid" />
                                                <span className="text"><p>{this.props.userDetail.firstName ? this.props.userDetail.firstName : ""}</p><p className="mb-0">{String(this.props.userDetail.email).toLowerCase()}</p></span>
                                                <span className="ml-2"><i className="fa fa-caret-down" aria-hidden="true"></i></span>
                                            </div>
                                        </Dropdown>
                                    </div>
                                    <div className="ml-3 mr-3 header_drop showmobile991">
                                        <div className="profile">
                                            <img src={this.props.userDetail.image || require('../../assets/images/login_user.png')} className="img-fluid" onClick={this.showProfileMenu} />
                                        </div>

                                    </div>
                                </>
                                :
                                !isFlag && <div className="header-btn-group d-flex">
                                    {/* <Link to="/signup" className="btn btn-red">Join now</Link> */}
                                    {/* <Link to="/signin" className="btn btn-red"  >Sign in</Link> */}
                                </div>}

                        </div>

                    </div>

                </header>
                {/* --------------------responsive view only visible in > 991px screen-------------- */}
                {(isHeader.isSearch && (this.props.userDetail.userType === 2 || this.props.userDetail.userType === 4) )?
                 <>{this.state["isMobileSearch"] && <div className="res-search-wrapper ">
                    {(this.state.isSearchClick) &&
                        this.getSeachSection(recentSearches, recommendedBrandDetails, recommendedProductDetails, this.state.isPaidUser) || this.getSeachChangeSection(this.state.isPaidUser)}
                </div> } </>
                :""}
                <div className="exhibitor-parent">
                    {(this.props.userDetail.userType != 4 && this.props.userDetail.userPlan == 1 )  && <Link to="/plan" className="btn btn-primary addbtn mobshow" title="Take a Showcase">Take a Showcase</Link>}
                </div>



                {/* <div>
 
                  <div className="location_wrapper search_wrapper w-100 d-flex align-items-center mr-2">
                        <input type="search"
                            placeholder="Search..."
                            name='location'
                            className="flex-grow-1"

                        />
                        <Location />
                    </div> 
                </div>*/}

                {/* --------------------for dashboard-------------- */}
                {this.props.userDetail._id ?
                    <div className="nav-wrapper">

                        <div className="cm-section container-fluid">

                            <nav className="navbar navbar-expand-lg d-flex justify-content-between align-items-center">
                                <div className="">
                                    <ul className="navbar-nav d-flex align-items-center justify-content-between">
                                        <li className={`nav-item homeicon  ${['home'].includes(pathname.split('/')[1]) ? 'active' : ''}`} onClick={(e) => this.onTabClick(e)}>
                                            <Link to="/home"><i class="fa fa-home" aria-hidden="true"></i><span className="sr-only">(current)</span></Link>
                                        </li>
                                        {

                                            <>
                                                <li className={`nav-item text-cente ${['seminar'].includes(pathname.split('/')[1])? 'active' : ''}`} onClick={(e) => this.onTabClick(e)}>
                                        <Link to="/seminar">{webHeader.tab2}</Link>
                                                </li>

                                                <li className={`nav-item ${['business'].includes(pathname.split('/')[1])? 'active' : ''}`} onClick={(e) => this.onTabClick(e)}>
                                                    <Link to="/business">{webHeader.tab3}</Link>
                                                </li>

                                                <li className={`nav-item ${['community'].includes(pathname.split('/')[1]) ? 'active' : ''}`} onClick={(e) => this.onTabClick(e)}>
                                                    <Link to="/community">{webHeader.tab4}</Link>
                                                </li>

                                                {/* <li className={`nav-item ${['networking'].includes(pathname.split('/')[1])? 'active' : ''}`} onClick={(e) => this.onTabClick(e)}>
                                                    <Link to="/networking">{webHeader.tab5}</Link>
                                                </li> */}
                                                <li className={`nav-item ${['newjob'].includes(pathname.split('/')[1]) ? 'active' : ''}`} onClick={(e) => this.onTabClick(e)}>
                                                    <Link to="/newjob">{webHeader.tab6}</Link>
                                                </li>
                                                <li className={`nav-item ${['trainings'].includes(pathname.split('/')[1]) ? 'active' : ''}`} onClick={(e) => this.onTabClick(e)}>
                                                    <Link to="/trainings">{webHeader.tab7}</Link>
                                                </li>
                                            </>
                                        }
                                    </ul>
                                </div>
                                { (isHeader.isSearch && (this.props.userDetail.userType === 2 || this.props.userDetail.userType === 4) ) ?
                                <>{!this.state["isMobileSearch"] && <div>
                                    <ul className="mb-0">
                                        <li className="hidemobile991">
                                            {this.state.isSearchClick &&
                                                this.getSeachSection(recentSearches, recommendedBrandDetails, recommendedProductDetails, this.state.isPaidUser) || this.getSeachChangeSection(this.state.isPaidUser)}
                                        </li>
                                    </ul>
                                </div> }
                                </>
                               :""}

                            </nav>
                        </div>

                    </div>

                    : ""}

                <CallModal history={history} match={this.props.match} />
                {isSHowPlan && <Search scope={this} visible={isSHowPlan} /> || <Search scope={this} isOpen={this.state.isOpen} />}
                {

                }




                <Modal
                    footer={null}
                    centered
                    visible={this.state.menuvisible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    className="Profile-dropdown-popup"
                >
                    <div className="profileicon-parent">
                        <img src={this.props.userDetail.image || require('../../assets/images/login_user.png')} className="img-fluid" />
                        <div>
                            <p className="mb-0" >{this.props.userDetail.firstName ? this.props.userDetail.firstName : ""}</p>
                            <p className="mb-0 mailid">{String(this.props.userDetail.email).toLowerCase()}</p>
                        </div>
                    </div>
                    {(this.props.userDetail.userType === 2 || this.props.userDetail.userType === 4) && <Link to="/dashboard" onClick={this.handleCancel}><span className="icon"><ProfileDashboard /></span>Dashboard</Link>}
                    <Link to="/profile" onClick={this.handleCancel}><span className="icon"><ProfileUser /></span>Personal Profile</Link>
                    <Link onClick={this.handleCancel} to="/career-profile"><span className="icon"><ProfileChangePass /></span>Career Profile</Link>
                    {isHeader.isManageCompany && (this.props.userDetail.userType === 2 || this.props.userDetail.userType === 4) && <Link onClick={this.handleCancel} to="/manage-company"><span className="icon"><ProfileMangeservices /></span>Manage Company</Link>}
                    {isHeader.isManageShowCase && (this.props.userDetail.userType === 2 || this.props.userDetail.userType === 4) && <Link onClick={this.handleCancel} to={`/business/profile/${localStorage.getItem("ownerId") ? localStorage.getItem("ownerId") : localStorage.getItem("id")}`}><span className="icon"><ProfileBrand /></span>Manage Showcase</Link>}
                    {/* {(this.props.userDetail.userType === 2 || this.props.userDetail.userType === 4) && <Link onClick={this.handleCancel} to="/manage-services"><span className="icon"><ProfileMangeservices /></span>Manage Categories</Link>} */}
                    {/* {(this.props.userDetail.userType === 2 || this.props.userDetail.userType === 4) && <Link onClick={this.handleCancel} to={`/business/profile/${localStorage.getItem("ownerId") ? localStorage.getItem("ownerId") : localStorage.getItem("id")}`}><span className="icon"><ProfileBrand /></span>Manage Showcase</Link>} */}
                    {isHeader.isEnquiries &&(this.props.userDetail.userType === 2 || this.props.userDetail.userType === 4) && <Link onClick={this.handleCancel} to={`/enquiry`}><span className="icon"><ProfileBrand /></span>Enquiries</Link>}

                    {<Link onClick={this.handleCancel} to="/event-detail"><span className="icon"><ProfileMangeservices /></span>My Event</Link>}


                    <Link onClick={this.handleCancel} to="/change-password"><span className="icon"><ProfileChangePass /></span>Change Password</Link>
                    <a onClick={this.handleCancel} href="https://pb-world-comman.s3.eu-west-2.amazonaws.com/Worldwidejobfinder+Terms+and+Conditions.pdf" target="_blank"><span className="icon"><ProfileTermCondition /></span>Terms and Conditions</a>
                    
                    
                    <Link onClick={this.handleCancel} to={`/cartview`} className="showon-mobile mobile_fav">
                        <span className="icon"><HeaderHeart /></span>Wishlist
                    {/* &nbsp;<span className="pos-relative cursor-point" onClick={() => this.onBagClick}>
                        <span className="counter">2</span>
                    </span>  */}
                    </Link>
                    <a>
                        <div onClick={() => { this.handleLogout() }}><span className="icon"><ProfileLogout /></span>Logout</div>
                    </a>
                </Modal>
                {/* <Question /> */}
                {
                    // this.props.userDetail?.isQuestionComplete == true &&  this line comment beccause question comment
                    this.props.userDetail?.isNewUser == true && <Welcome/>

                }

                

            </>
        )
    }

    handleVisibleChange = () => {
        this.setState({ isPaidUser: false, brandDetails: [], productDetails: [] });
    };
    onClearClick = () => {
        _Api(Url["CLEAR_SEARCH_LIST"].method, Url["CLEAR_SEARCH_LIST"].url)
            .then(() => {
                this.getRecentSearchList();

                this.props.dispatch(getRecentList());

            }).catch((err) => {
                // this.handleError(err);
            });

    }
    handleScroll = (e) => {
            document.activeElement.blur();
      }
    onClickEdit = () => {
        let { appLocalState, dispatch } = this.props;
        appLocalState["search"] = "";
        dispatch(setLocalState(appLocalState));
        this.setState({ isEdit: false })
    }

    getSeachSection(recentSearches, brandDetails, productDetails, isActive) {

        console.log("lkjsakldkjsamk", { recentSearches, brandDetails, productDetails, isActive });
        const isShow = localStorage.getItem("showAll") == "true"



        return <div className="ml-3 header_drop">
            <Dropdown visible={isActive} onVisibleChange={this.handleVisibleChange} overlayClassName="serach-root-popup"
                overlay={<Menu className=" header-search radha">
                    <div className="group-btn">
                        <a href="/business-list" className="btn btn-blue" >{searchTab.tab1}</a>
                        <a href="/newjob" className="btn btn-blue">{searchTab.tab2}</a>
                        <a href="/community/view/page=1" className="btn btn-blue">{searchTab.tab3}</a>
                        <a href="/trainings" className="btn btn-blue">{searchTab.tab4}</a>
                    </div>

                    {recentSearches && recentSearches.length > 0 || this.state.recentTags && this.state.recentTags.length > 0 && <Menu.Divider className="mb-3" />}

                    {recentSearches && recentSearches.length > 0 || this.state.recentTags && this.state.recentTags.length > 0 && <div>
                        <div className="search-small-header align-items-center">
                            <label className="form-check-label checkbox-header">
                                Recent
                            </label>
                            <button onClick={this.onClearClick} className="btn btn-blue">Clear</button>
                        </div>
                        {getRecentSearch(recentSearches)}
                    </div>}
                    {brandDetails && brandDetails.length > 0 && <Menu.Divider className="mb-3" />}

                    {brandDetails && brandDetails.length > 0 && <div>
                        <div className="search-small-header align-items-center">
                            <label className="form-check-label checkbox-header">
                                Recommended Business
                            </label>
                        </div>
                        {getListRender(brandDetails, "companyName")}
                    </div>}
                    {productDetails && productDetails.length > 0 && <Menu.Divider className="mb-3" />}

                    {productDetails && productDetails.length > 0 && <div >
                        <div className="search-small-header align-items-center">
                            <label className="form-check-label checkbox-header">
                                Recommended Products
                            </label>
                        </div>
                        {getListRender(productDetails, "productName")}
                    </div>
                    }
                    {this.state.recentTags && this.state.recentTags.map(item => (
                        <div className="form-group form-check cursor-ponter" onClick={() => this.onClickRecenKey(item.searchTitle)}><div>
                            <a>  <i className="fa fa-clock-o" />  <span>{item.searchTitle}</span> </a>
                        </div>

                        </div>
                    ))
                    }


                </Menu>} placement="bottomRight" trigger={['click']}>
                <div className="search_wrapper  mr-2">


                    {!this.props.appLocalState.search && <i className="fa fa-search" /> || <i className="fa fa-times" aria-hidden="true" onClick={() => this.onClickEdit} />}
                    { <input type="search"
                        placeholder="Search Business, Jobs & People"
                        name='search'
                        autoComplete="off"                      
                        value={this.props.appLocalState.search}
                        onClick={this.onSearhClick}

                        onChange={this.onChangeSearch.bind(this)}
                    />}
                </div>

            </Dropdown>

        </div>;
    }

    getResultForSearch = (data)=>{

    }


    getSeachChangeSection(isActive) {
        let { detailList } = this.state;
        const isShow = localStorage.getItem("showAll") == "true"
        let isVisible = ((detailList?.length == 0) || this.state.isEmpty);

        let desArray = this.createSearchResult();


        return <div className="ml-3 header_drop">
            <Dropdown overlayClassName="serach-root-popup" visible={isActive} onVisibleChange={this.handleVisibleChange} overlay={<Menu clickable={true} className="header-search"  onScroll={this.handleScroll} >
                {isVisible && <div className="group-btn">
                  <Link to="/business-list" className="btn btn-blue">{searchTab.tab1}</Link>
                    <Link to="/job" className="btn btn-blue">{searchTab.tab2}</Link>
                    <Link to="/community/view/page=1" className="btn btn-blue">{searchTab.tab3}</Link>
                    <Link to="/industry" className="btn btn-blue">{searchTab.tab4}</Link>
                </div>}
                {desArray}
                <div className="text-center">
                    {!isVisible && <button className="btn btn btn-blue  mt-2 mb-2" onClick={this.handleSearch}> View All Results </button>}
                </div>
                {isVisible && <div > <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>}
               
            </Menu>
            
        
        }
        
        placement="bottomRight" trigger={['click']}>
                <div className="search_wrapper  mr-2">
                    {!this.props.appLocalState.search && <i className="fa fa-search" /> || <i className="fa fa-times" aria-hidden="true" onClick={this.onClickEdit} />}
                    <input type="search"
                        placeholder="Search Businesses, Jobs & People"
                        onBlur={this.onBlur}
                        name='search'
                        autoComplete="off"
                        value={this.props.appLocalState.search}
                        onClick={this.onSearhClick}
                        onKeyDown={this.handleKeyDown}
                        onChange={this.onChangeSearch.bind(this)}
                    />
                </div>

              
            </Dropdown>

           

        </div>;
    }
}
function getListRender(array, value) {
    // console.log("dashdghjkas", array)
    return <ul className="search-list">
        {array && array.map((item) => {
            let toRetun = value == "productName" && `/web-job-details/${item._id}` || `/business/${item._id}`
            return (<li>
                <Link className="cursor-point" to={toRetun}><div>
                    <div className="img">
                        <img src={value == "companyName" ? item?.logoImage ? item?.logoImage : require('../../assets/images/placeholder/brand.jpg') : item?.image || require('../../assets/images/placeholder/brand.jpg')} />
                    </div>
                    <Tooltip placement="bottom" title={item[value]}>
                        <p>{item[value]}</p>
                    </Tooltip>

                </div></Link>
            </li >);

        })}
    </ul >;
}



function getRecentSearch(array) {

    return <ul className="search-list">
        {array && array.map((item) => {
            return (item.productData &&
                <li>
                    <Link className="cursor-point" to={`/web-job-details/${item?.productData?._id}`}><div>
                        <div className="img">
                            <img src={item.image || require('../../assets/images/placeholder/stages.jpg')} />
                        </div>
                        <p>{item.productData.productName}</p>
                    </div></Link>
                </li >) ||
                (item.brandData &&
                    <li>
                        <Link className="cursor-point" to={`/business/${item?.brandData?._id}`}><div>
                            <div className="img">
                                <img src={item.image || require('../../assets/images/placeholder/brand.jpg')} />
                            </div>
                            <p>{item.brandData.companyName}</p>
                        </div></Link>
                    </li >);

        })}
    </ul >
}


// export default Header;
function mapStateToProps(state) {
    // <span className="chat pos-relative"><Chat />{this.state.totalUnreadMsg?<span className="counter chat-counter white-counter">{this.state.totalUnreadMsg}</span>:""}</span>
    const {profile} = state
    return {
        ...state,
        meaasgeCount: state?.loading?.messageCount,
        supportNotify: state?.loading?.supportNotify,
        isSupportShow: state?.loading?.isSupportChat,
        showUserPlanPopup: state?.loading?.showUserPlanPopup,
        profile : profile
    };
}

// const mapDispatchToProps = dispatch => {
//     return {
//         action: bindActionCreators({ getBrandHome }, dispatch)
//     }
// }

export default connect(mapStateToProps)(withRouter(Header))
