import React, { Component } from 'react';
import Footer from '../../../layout/Footer';
import { Hert, ExhibitionManagement, Pencil, Delete } from '../../../../SvgIcon';
import { Link } from "react-router-dom";
import Sidebar from '../../../layout/Sidebar';
import Header from '../../../layout/Header';
import InlineError from '../../../../components/InlineError';
import { _Api } from '../../../../services/Api';
import Url from '../../../../services/BaseUrl';
import { notify } from '../../../../components/Toaster'
import { Tab, Tabs } from 'react-bootstrap';
import moment from 'moment-timezone';
import Loader from '../../../../components/Loader'
// import { AddNewConference } from '../../../SvgIcon'

class EditConference extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            exhibitionId: this.props.match.params.exid,
            conferenceName: '',
            conferenceNameError: '',
            media: '',
            mediaError: '',
            exhibitionFee: '',
            isChecked: false,
            stageList: [],
            loungeList: [],
            loungeId: '',
            status: "",
            stageId: '',
            isLoader: false,
            videoUrl: '',


        }
    }

    handleOnChangeConference = (e) => {
        this.setState({ [e.target.name]: e.target.value, conferenceNameError: '' })
    }
    componentDidMount() {
        this.getParticularConference()
    }

    getParticularConference = () => {
        let req = {
            conferenceId: this.props.match.params.id
        }
        _Api(Url.GET_PARTICULAR_CONFERENCE.method, Url.GET_PARTICULAR_CONFERENCE.url, '', `?conferenceId=${req.conferenceId}`)
            .then(resp => {
                console.log('cinid', resp.responseData.result)
                let params = resp.responseData.result
                this.setState({ conferenceName: params.conferenceName, exhibitionFee: params.exhibitionFee, media: params.media, isLoader: false })
                // this.props.history.push(`/seminardetails/${this.props.match.params.exid}`)
            }).catch(err => {
                this.setState({ isLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
                else if (err)
                    this.setState({ isLoader: false })
                // notify("err", err.message)
            })
    }
    editConference = () => {
        let { conferenceName, exhibitionFee, media } = this.state
        try {
            if (conferenceName == '')
                this.setState({ conferenceNameError: 'Please enter confrence name' })
            else {
                console.log('meddd', media)
                this.setState({ isLoader: true })
                let formData = new FormData()
                formData.append("conferenceId", this.props.match.params.id)
                formData.append("conferenceName", conferenceName)
                formData.append("exhibitionFee", exhibitionFee ? exhibitionFee : 0)
                formData.append("media", media)

                _Api(Url.EDIT_CONFERENCE.method, Url.EDIT_CONFERENCE.url, formData)
                    .then(resp => {
                        localStorage.setItem('conid', resp.responseData.conferenceDetails._id)
                        console.log('cinid', resp.responseData.conferenceDetails._id)
                        this.setState({ isLoader: false })
                        this.props.history.goBack()
                        notify('success', resp.responseData.message)
                    }).catch(err => {
                        this.setState({ isLoader: false })
                        if (err.error)
                            notify("err", err.error.responseMessage)
                        else if (err)
                            this.setState({ isLoader: false })
                        // notify("err", err.message)
                    })
            }
        } catch{ }
    }
    handlePicUpload = (event) => {
        console.log(event, "eventeventevent", event.target.files[0])
        let reader = new FileReader();
        let file = event.target.files[0];
        let { valiDate } = this.state;
        reader.addEventListener("load", () => {
            this.setState({ profilePicPreview: reader.result }, function () {
            })
        }, false);
        if (file) {
            reader.readAsDataURL(file)
        }
        if (file) {
            console.log(event, "media1", event.target.files[0])
            if (file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png' || file.type === 'image/svg+xml') {
                console.log("media2", event.target.files[0], file.size)
                if (file.size > 2000000) {
                    console.log("lkjhgfdsdfghjklkjhgfghjkmnbvccvbnm>>>>>>>>>>>>>>>>>>")
                    valiDate = false;
                    console.log(event, "media", event.target.files[0])
                    this.setState({
                        media: event.target.files[0],
                        profilePicErrorMsg: 'Profile pic size should be less than 5Mb.'
                    });
                } else {
                    console.log("media3hhhjshs", event.target.files[0], file.size)
                    this.setState({
                        media: event.target.files[0],
                        mediaError: ''
                    });
                }
            } else {
                valiDate = false;
                this.setState({ media: 'File type not supported.' })

            }
        }
    }








    //for check box
    handleChangedChecked = () => {
        this.setState({ isChecked: !this.state.isChecked })
    }

    render() {
        console.log('state', this.state.media)
        let { isLoader, videoUrl, conferenceName, conferenceNameError, exhibitionFee, media } = this.state
        return (
            <>
                <div className="dash_body_container">                  
                    {isLoader && <Loader />}
                    <div className="body_container">
                        <div className="header-text  mb-4 mt-3">
                            <div className="d-flex align-items-center">
                                <p className="header-icon"></p>
                                <h4>Add New Seminar
                                <br />
                                    {/* <ul className="header-breadcrum">
                                        <li><a >Exhibition Management</a>/</li>
                                        <li><a >Exhibition Detail</a></li>
                                    </ul> */}
                                </h4></div>
                            <Link target="_blank" to="/seminar-detail" className="btn btn-light">Back</Link>
                        </div>
                        <div className="rounded-body-form">
                            <form className="cm-form-wrapper rounded-textbox-form">
                                <div className="row">
                                    <div className="col-md-6">
                                        <level>Seminar Name</level>
                                        <input type="text"
                                            className="form-control"
                                            placeholder="Conference Name"
                                            maxLength={64}
                                            name="conferenceName"
                                            value={conferenceName}
                                            onChange={this.handleOnChangeConference}
                                        />
                                        <InlineError
                                            message={conferenceNameError}
                                        />

                                    </div>
                                    <div className="col-md-6">


                                        <level>Seminar Fee</level>
                                        <div className="form-group d-flex justify-content-between">

                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input"
                                                    type="checkbox"
                                                    id="inlineCheckbox1"
                                                    value="option1"
                                                    checked={!this.state.isChecked}
                                                    onChange={this.handleChangedChecked}
                                                />
                                                <label className="form-check-label"
                                                    htmlFor="inlineCheckbox1">Free</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input"
                                                    type="checkbox"
                                                    id="inlineCheckbox2"
                                                    value="option2"
                                                    checked={this.state.isChecked}
                                                    onChange={this.handleChangedChecked}
                                                />
                                                <label className="form-check-label"
                                                    htmlFor="inlineCheckbox2">Input Amount</label>
                                            </div>
                                            {
                                                this.state.isChecked &&
                                                <div className="enter-amount">
                                                    <input type="number"
                                                        className="form-control"
                                                        placeholder="Enter amount"
                                                        maxLength={15}
                                                        name="exhibitionFee"
                                                        value={exhibitionFee}
                                                        onChange={this.handleOnChangeConference}

                                                    />

                                                </div>
                                            }
                                        </div>

                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <level>Add link or upload multiple videos</level>
                                        <input type="link"
                                            className="form-control"
                                            placeholder="Enter video link"
                                            maxLength={64}
                                            // readOnly
                                            name="videoUrl"
                                            value={videoUrl}
                                            onChange={this.handleOnChangeConference}
                                        />
                                    </div>
                                    <div className="col-md-6 d-flex">
                                        <div className="blue-border upload-video">
                                            <input type='file'
                                                // disabled
                                                onChange={this.handlePicUpload}
                                            />
                                        </div>
                                        <div className="add-video">
                                        </div>
                                    </div>
                                </div>
                                <div className="text-right">
                                    <button className="btn btn-primary " type='button' onClick={this.editConference}>Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default EditConference;

