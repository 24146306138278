import React, { Component } from 'react';
import {SoonText } from '../../SvgIcon';
import {
    ClipLoader
} from 'react-spinners';

const CommingSoonLayer = (props) => {
    return (
        <div className="loader_wrapper commingsoon">
               <div className="centertext">
                 <div class="hit-the-floor">
                     <p>COMING</p>
                     <p><SoonText/></p>
                 </div>
               </div>
     
        </div>
    )
}
export default CommingSoonLayer;