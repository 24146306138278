import promise from 'bluebird'
import { _Api } from "../../services/Api";
import Url from "../../services/BaseUrl";

export const PATH_ADD = 'PATH_ADD';
export const PATH_REMOVE = 'PATH_REMOVE';

export const pathActions = {

};
