import React, { Component } from 'react';
import { Hert, Brands, Pencil, Delete, UploadVideo } from '../../../SvgIcon';
import { Link } from "react-router-dom";
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';
import Pagination from 'react-js-pagination';
import { Select, TreeSelect } from 'antd';
import InlineError from "../../../components/InlineError";
import { _Api, _ApiMultipart } from "../../../services/Api";
import Url from "../../../services/BaseUrl";
import { notify } from "../../../components/Toaster";
import Loader from "../../../components/Loader";
import CommingSoonLayer from "../../../components/common/CommingSoonLayer";
import Croper from "../../../components/Croper";

const { Option } = Select;
const { SHOW_PARENT, SHOW_CHILD, SHOW_ALL } = TreeSelect;
// import BackBtn from '../../../components/common/Button'

function handleChange(value) {
    console.log(`selected ${value}`);
}

class AddSector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryTitle: "",
            categoryDesc: "",
            categoryType: 0,

            categoryTitleError: "",
            categoryDescError: "",
            categoryTypeError: "",
            categoryImgPrev: require('../../../assets/images/placeholder-img.png'),
            categoryImg: "",
            isLoader: false,
            isCommingSoon: false,
            placeOfworkList: [],
            placeOfWorkData: [],
            placeOfWorkError: '',
            placeofWorkId: [],
            parentCategoryId: this.props.match.params.catogoryId && this.props.match.params.catogoryId !== "" ? this.props.match.params.catogoryId : "",
            visibleVal: false
        }
        this.onChanged = this.onChanged.bind(this);
        this.addCategory = this.addCategory.bind(this);
    }

    componentDidMount() {

    }

    addCategory() {
        let self = this;
        let { placeofWorkId } = this.state;
        this.setState({
            categoryTitleError: "",
            categoryDescError: "",
            categoryTypeError: "",
            placeOfWorkError: ""
        })
        if (this.state.categoryTitle === '') {
            this.setState({ categoryTitleError: '*Please enter sector name' })
        } else {
            this.setState({ isLoader: true })
            let data = {
                name:this.state.categoryTitle
            };

            _Api(Url.SECTOR_ADD.method, Url.SECTOR_ADD.url, data)
                .then(resp => {
                    this.setState({ isLoader: false })
                    //console.log("resp",resp);
                    self.props.history.push("/sector")
                }).catch(err => {
                    this.setState({ isLoader: false })
                    if (err.error) {
                        if (err.error.errorCode == 5) {
                            self.setState({
                                categoryTitleError: err.error.responseMessage
                            })
                        } else {
                            notify("err", err.error.responseMessage)
                        }
                    } else if (err) {
                        // notify("err", err.message)
                    }
                })
        }

    }

    onChanged(e) {
        console.log("e", e);
        let self = this;
        let keyName = e.target.name;
        let KeyValue = e.target.value;
        this.setState({
            categoryTitleError: "",
        })
        this.setState({ [e.target.name]: e.target.value }, function () {
        });
    }

    render() {
        // console.log("this.state###", this.state);
        return (
            <>
                <div className="dash_body_container">
                    {/*<Header />*/}
                    {/*<Sidebar />*/}
                    {this.state.isLoader && <Loader />}
                    {this.state.isCommingSoon && <CommingSoonLayer />}
                    <div className="body_container">

                        <div className="header-text  mb-4 mt-3">

                            <div className="d-flex align-items-center">
                                <p className="header-icon"><Brands /></p>
                                <h4>Add Sector</h4>
                            </div>

                        </div>
                        <div className="rounded-body py-5">
                            <div className="cm-form-wrapper rounded-textbox-form add-manag px-0">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <level>Sector Name</level>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter Name" name="categoryTitle" value={this.state.categoryTitle}
                                                onChange={this.onChanged}
                                            />
                                            <InlineError
                                                message={this.state.categoryTitleError}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 tab-add-btn-group">
                                        <Link to={"/sector"} className="btn btn-secondary" type='button'>Cancel</Link>
                                        <button className="btn btn-primary" type='button' onClick={this.addCategory.bind(this)}>Save</button>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>

                </div>
            </>
        );
    }
}
export default AddSector;