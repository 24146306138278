import React, { Component } from 'react';
import { Brands } from '../../../SvgIcon';
import { Link } from "react-router-dom";
import { Empty } from 'antd';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';
import { Pagination } from '../../../components/common/Pagination';
import { Delete } from '../../../SvgIcon';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Loader from '../../../components/Loader';



const DragHandle = sortableHandle((props) => {
    if (props.isIndex) {
        return <td> <span className="circle-serial">{props.children}</span></td>
    }
    else {
        return (<td>{props.children}</td>)
    }

});

const SortableItem = sortableElement(({ item, indexValue, scope }) => {
    console.log("dwqhdjk", item)
    return <tr>
        <DragHandle isIndex={true}>{(scope.state.snNo * 10) + indexValue + 1}</DragHandle>
        <DragHandle> {item?.productName}</DragHandle>
        <DragHandle>{ item?.isNewProduct == 1 ? 'New Product' : 'Product'}</DragHandle>
        
        <DragHandle>{item?.brandId?.companyName}</DragHandle>
        <DragHandle> {item?.brandId?.userPlan == 3 ? "Premium brand showcase" : "Brand showcase"}</DragHandle>
        {/* <DragHandle>{item?.price || 0}</DragHandle> */}
        <td className="action_group">
            <p onClick={() => scope.showModal(item?._id)}><p className="circle-serial"><Delete /></p></p>
        </td>
    </tr>
});

const SortableContainer = sortableContainer(({ children }) => {
    return <tbody>{children}</tbody>
});

const { confirm } = Modal;

class FeatureProductList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 1,
            limit: 20,
            loading: false,
            visible: false,
            isLoader: false,
            search: '',
            productListData: [],
            snNo: 0,
            totalCount: 1
        }
    }

    componentDidMount() {
        this.getProductList()
    }

    getProductList = (isLoader = true) => {
        this.setState({ isLoader: isLoader })
        let { page, limit, search } = this.state
        let req = {
            page: page ? page : 1,
            limit: 12,
            search: search.toString(),
            fromAdmin: true
        }
        _Api(Url.FEATURED_PRODUCT_LIST.method, Url.FEATURED_PRODUCT_LIST.url, '', `?page=${req.page}&limit=${req.limit}&search=${req.search}`)
            .then(resp => {

                if (this.props.match.params.exId)
                    this.setState({ productListData: resp.responseData?.result?.data.map(item => ({ ...item, ...item?.brandId })), totalCount: resp.responseData?.result?.totalCount, isLoader: false })
                else
                    this.setState({ productListData: resp.responseData?.result?.data, totalCount: resp.responseData?.result?.totalCount, isLoader: false })

            }).catch((err) => {
                this.setState({ isLoader: false })
                this.handleError(err);
            })
    }

    handleSearch = e => this.setState({ search: e.target.value, productListData: [], page: 1, snNo: 0 }, () => this.getProductList());
    handleError(err) {
        this.setState({ isLoader: false });
        let errorMessage = err.error && err.error.responseMessage || err.message;
        notify("err", errorMessage);
    }
    // update feature modal
    showModal = (id) => {
        confirm({
            title: 'Are you sure you want to remove from featured product?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            onOk: () => {
                this.onUpdateFeature(id)
            },
            onCancel: () => {
                this.handleCancel()
            },
        });
    }

    onUpdateFeature = (id) => {
        let params = {
            product: [id],
            isFeatured: 2

        }
        _Api(Url.FEATURED_PRODUCT_FEATURE_UPDATE.method, Url.FEATURED_PRODUCT_FEATURE_UPDATE.url, params)
            .then(resp => {
                // notify("success", "Feature removed Successfully");
                this.getProductList();
            }).catch(err => {
                let errorMessage = err.error && err.error.responseMessage || err.message;
                // notify("err", errorMessage);

            })

    }
    handleCancel = () => {
        this.setState({ visible: false });
    };

    onPressBtn = (page) => {
        this.setState({ page, snNo: page - 1, productListData: [] }, () => {
            this.getProductList()
        })

    }
    updateOrderList() {
        let sortedList = [];
        this.state.productListData.forEach((element, index) => {
            sortedList.push({ _id: element._id, orderValue: index + 1 })

        });
        let params = {
            orderArray: sortedList
        }
        _Api(Url.UPDATE_OREDER_FEATURE_PRODUCT.method, Url.UPDATE_OREDER_FEATURE_PRODUCT.url, params)
            .then(resp => {
                notify("success", "Product Sorted Successfully");

            }).catch(err => {
                let errorMessage = err.error && err.error.responseMessage || err.message;
                notify("err", errorMessage);

            })


    }
    onSortEnd = ({ oldIndex, newIndex }) => {
        this.setState(({ productListData }) => ({
            productListData: arrayMove(productListData, oldIndex, newIndex),
        }), () => this.updateOrderList());
    };

    render() {

        let id = this.props?.match?.params?.exId ? this.props.match.params.exId : ''
        const { isLoader, productListData, totalCount } = this.state;
        return (
            <>
                <div className="dash_body_container">
                    {isLoader && <Loader />}
                    <Header />
                    <Sidebar />
                    <>
                        <div class="header-text d-flex mb-4 mt-3">
                            <div class="d-flex flex-grow-1 align-items-center">
                                <p className="header-icon"><Brands /></p><h4>Popular Jobs</h4>
                            </div>
                            <div class="search_wrapper  mr-2">
                                <i class="fa fa-search"></i>
                                <input type="search" placeholder="Search..." name="search"
                                    onChange={this.handleSearch}
                                />
                            </div>
                            <div class="">
                                <Link to={`/Add-feature-joblist/${totalCount}`} className="btn btn-blue-large mr-2">Add Feature Jobs</Link>
                            </div>
                        </div>
                    </>
                    <div class="table-responsive">
                        <table class="table table-bordered rounded-table">
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>Job Name</th>
                                    <th>Job Type</th>
                                    <th>Business</th>
                                    <th>Business Type</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
                                {productListData.map((item, index) => {
                                    return (<SortableItem key={item?._id} index={index} item={item} scope={this} indexValue={index} />)
                                })}
                            </SortableContainer>
                        </table>

                        <div>
                            {!isLoader && productListData && productListData.length == 0 &&
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

export default FeatureProductList