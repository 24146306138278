import React, { Component } from 'react';
import WebHeader from '../../layout/WebHeader';
import { Link } from "react-router-dom";
import { Hert } from '../../../SvgIcon';
import Footer from "../../layout/Footer"
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import Masonry from 'react-masonry-css'
import { Menu, Dropdown, Checkbox, DatePicker, TimePicker, Empty } from 'antd';
import Select from 'react-select';
import InlineError from "../../../components/InlineError";
import { connect } from "react-redux";
import { notify } from "../../../components/Toaster";
import Loader from "../../../components/Loader";
import { userActions } from "../../../redux/action";
var _ = require('lodash');
class EditSectorsCategories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCheckboxTab: false,
            list: [],
            selectedId: [],
            toggle: false,
            userData: {},
            message: ''

        }

    }

    componentDidMount() {
        this.getInfo()
        this.getUserProfile()
    }

    getInfo = () => {
        _Api(
            Url.GET_CATEGORY_PRODUCT_WEB.method,
            Url.GET_CATEGORY_PRODUCT_WEB.url,
            "",
            // `?requestUserId=${req.requestUserId}`
        )
            .then((resp) => {

                let responseData = resp.responseData.result;
                // console.log("kjdhagdhjk", responseData)

                this.setState({
                    isLoader: false,
                    list: responseData

                })
            })
            .catch((err) => {
                this.setState({ isLoader: false });
            });
    }






    handleSelect = (id, item, userPlan) => {
       
        let isPremium = userPlan == 3
        let oldArr = this.state.selectedId
       

        let flag = oldArr.findIndex(item => item?.id == id)
        if (flag == -1 && (isPremium && this.state.selectedId?.length < 20 || (isPremium == false && this.state.selectedId?.length < 10 ))) {

            if (oldArr.length > 0) {
                let arr = oldArr.filter(item => item.sectorId.length == 0)              
                if (arr.length != 0) {
                    this.setState({ message: arr[0]['id'] })
                    // window.scrollTo(0, 0)

                }
                else {
                    let obj = {
                        id: id,
                        sectorId: []
                    }
                    oldArr.push(obj)
                    this.setState({ selectedId: oldArr, message: '' })
                }

            }
            else {
                let obj = {
                    id: id,
                    sectorId: []
                }
                oldArr.push(obj)
                this.setState({ selectedId: oldArr, message: '' })
            }

        }
        else {
            this.setState({ selectedId: this.state.selectedId.filter(item => item?.id != id), message: '' })

        }

    }

    getUserProfile = () => {
        let self = this;
        let req = {
            requestUserId: this.props.match.params.id || localStorage.id || "",
        };
        _Api(
            Url.GET_USER_PROFILE.method,
            Url.GET_USER_PROFILE.url,
            "",
            `?requestUserId=${req.requestUserId}`
        )
            .then((resp) => {
                let responseData = resp.responseData.result;
                let arr = responseData?.manageCategoriesId
                console.log("dsahdjaaaaaaaa", responseData)
                arr.forEach(item => item?.sectorId?.forEach(item2 => { self.onSubCheck(item.id, item2) }))
                this.setState({
                    isLoader: false,
                    currentType: responseData.userPlan, brandType: responseData.userPlan,
                    userData: responseData, ...responseData,
                })


            })
            .catch((err) => {
                console.log("dsahdjaaaaaaaa", err)
                this.setState({ isLoader: false });
            });
    }


    showCheckboxTab = (e) => {
        this.setState({
            showCheckboxTab: true
        })
    }

    // sign up submit
    handleSubmit = (e) => {
        e.preventDefault();

        let arr = this.state.selectedId.filter(item => item.sectorId.length == 0)              
        if (arr.length != 0) {
            this.setState({ message: arr[0]['id'] })
        }        

       else  if (this.state.message == "") {
            this.setState({ isLoading: true, })
            let req = {
                companyId:this.props.match.params.cId || localStorage.getItem("company") || ""  ,
                manageCategoriesId: this.state.selectedId,
                requestUserId: this.props.match.params.id || "",
            }

            _Api(Url.EDIT_COMPANY.method, Url.EDIT_COMPANY.url, req)
                .then(resp => {
                    this.setState({ isLoading: false })
                    this.props.history.goBack()
                }).catch(err => {
                    if (err.error)
                        notify("err", err.error.responseMessage)
                })
        }

    }


    checkInputCheck = (id, data) => {
        let arr = this.state.selectedId.filter(item => (item.id == id && item?.sectorId.indexOf(data) != -1) ? true : false)
        return arr.length ? true : false
    }

    onSubCheck = (data, item, ind) => {
        let oldArr = this.state.selectedId
        let flag = oldArr.findIndex(itemIn => itemIn?.id == data._id)
        if (flag == -1) {
            let obj = {
                id: data._id,
                sectorId: [item._id]
            }
            oldArr.push(obj)
            this.setState({ selectedId: oldArr })
        }
        else {

            let arr = this.state.selectedId[flag].sectorId
            if (arr.indexOf(item._id) == -1)
                this.state.selectedId[flag].sectorId.push(item._id)
            else {
                let arr = this.state.selectedId[flag].sectorId.filter(itemm => itemm != item._id)
                this.state.selectedId[flag].sectorId = arr

            }

        }

        this.setState({ toggle: !this.state.toggle, message: '' })
    }



    render() {
        console.log("shdgjkssssssss", this.state.selectedId)

        let userType = localStorage.getItem("userType")
        const { userDetail } = this.props ? this.props : {}

        return (
            <section className={`brand-home-banner-bg brandprofile-banner-bg ${userType == 1 ? "brand dash_body_container withsidenavbar" : ''} `}>
                {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
                {this.state.isLoader && <Loader />}
                {/*<ChatBox history={this.props.history} match={this.props.match} />*/}

                <div className="container-fluid  manage-company edit-companysector">
                    {/* <a > <button onClick={() => this.props.history.goBack()} className="btn btn-light"><i class="fa fa-arrow-left" aria-hidden="true"></i>Back</button></a> */}
                    <div className="row">
                        <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2">
                            <div className=" pt-5 text-center">
                                <h1 className="home-heading pos-relative mb-5">
                                    Company Sectors & Categories
                                <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                </h1>
                                <div className="pos-relative">
                                    {/* <h2 className="heighlighter"> Promoted Search Categories Selected ({this.state.count}/{userDetail?.userPlan == 3 ? 30 : 20})</h2>
                            <p className="home-subheading">Choose {userDetail?.userPlan == 3 ? 30 : 20} categories you want your brand to feature higher in the search listings for.</p> */}
                             <p className="home-subheading">Choose {userDetail?.userPlan == 3 ? 20 : 10} categories you want your brand to feature higher in the search listings for.</p>
                                    <h2 className="heighlighter">Categories Selected ({this.state.selectedId?.length}/{userDetail?.userPlan == 3 ? 20 : 10})</h2>
                                    <p className="text-danger mb-0"><b>Unchecking a category will delete the products related to that category.</b></p>
                                </div>
                            </div>
                            <form class="rounded-textbox-form mt-5">
                                {
                                    this.state?.list?.map((item, index) => {
                                        return (
                                            <>
                                                <div className="checkbox-tab-parent">
                                                    <div className="checkbox-tab" onClick={(e) => this.showCheckboxTab()}>
                                                        <div className="check_wrapper" >
                                                            <input onChange={() => this.handleSelect(item?._id, item, userDetail?.userPlan)} type="checkbox" value={item._id} checked={this.state.selectedId.findIndex(item2 => item2?.id == item._id) != -1} />
                                                            <span />
                                                        </div>
                                                        {item.name}
                                                    </div>
                                                    {
                                                        this.state.message && this.state.message == item._id &&
                                                        <InlineError message='*Select relevant sector(s)' />
                                                    }
                                                    {/* <InlineError message={this.state.message} /> */}

                                                    {this.state.selectedId.findIndex(item2 => item2.id == item._id) != -1 && <div className="checkbox_tab-body">
                                                        <div className="d-flex edit-sectorcategory">
                                                            {
                                                                item?.sectorData?.map((itemIn, ind) => {
                                                                    return (
                                                                        <span onClick={() => this.onSubCheck(item, itemIn, ind)} className={this.checkInputCheck(item?._id, itemIn._id) ? 'un_select_field select_field ' : "un_select_field"} >{itemIn?.name}</span>


                                                                    )
                                                                })
                                                            }
                                                        </div>



                                                    </div>}
                                                </div>

                                            </>
                                        )
                                    })
                                }
                                {/* </div> */}
                            </form>

                            <div className="d-flex justify-content-center mt-4 mb-5">
                                <button type="button" onClick={() => this.props.history.goBack()} class="btn btn-warning btn-sm changepass mr-3" >Cancel</button>
                                <button type="button" onClick={this.handleSubmit} class="btn btn-warning btn-sm" >Save</button>
                            </div>
                        </div>
                    </div>

                </div>
                {userType != 1 && <Footer />}
            </section >
        )
    }
}
function mapStateToProps(state) {
    // console.log("abcdefghhfiekjnmkwejnfnw", state)
    return {
        ...state
    };
}

export default connect(mapStateToProps)(EditSectorsCategories);