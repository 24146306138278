import React, { Component } from 'react';
import { BackBtn, BtnRed, CmBtn } from "../../../components/common/Button";
import CommingSoonLayer from "../../../components/common/CommingSoonLayer";
import { Calender, Clock, Hert } from "../../../SvgIcon";
import Footer from "../../layout/Footer";
import WebHeader from '../../layout/WebHeader';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';
import { Modal, Button ,Select } from 'antd';
import { connect } from "react-redux";
import { getWishList } from '../../../redux/action';
import { Link } from "react-router-dom"
import {
    MyAlert,
    MySpinner
} from "../../../components/index";

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;
let isProd = process.env.REACT_APP_ENV == 'prod'
const antIcon = <LoadingOutlined style={{ fontSize: 72 }} spin />;
class NewProductDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ProductDetails: [],
            loading: false,
            visible: false,
            message:''
        }

        window.scrollTo(0, 0)

    }
    showModal = () => {
        this.setState({
          visible: true,
        });
      };
    
      handleOk = () => {
        this.setState({ loading: true });
        setTimeout(() => {
          this.setState({ loading: false, visible: false });
        }, 3000);
      };
    
      handleCancel = () => {
        this.setState({ visible: false });
      };




      handleOnChange = (e) => {        
        this.setState({ [e.target.name]: e.target.value,})
    }














    render() {
        const { visible, loading, message } = this.state;
        return (
        <section className={`brand-home-banner-bg brandprofile-banner-bg`} >
            {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
            {/* <ChatBox history={this.props.history} match={this.props.match} />*/}
            <div className="cm-section container-fluid">
                <div className=" card-wrapper product-detail mt-0">
                    <div className="row">
                        <div className="col-md-5 pr-5">
                            {/* {brandData && <h5><Link to={`/brand/${brandData?._id}`}>{brandData?.companyName}</Link></h5>} */}
                            <div className="card community-card">
                                <img className="img-fluid" src={require('../../../assets/images/placeholder.png')} />

                            </div>

                        </div>
                        <div className="col-md-7">
                            <div className="d-flex justify-content-between align-items-center">
                                {/* <span>dhcgbndhvgcnd /dhyv7i i8hj/dvfdv</span>  */}
                                {/* {brandData && <h5><Link to={`/brand/${brandData?._id}`}>{brandData?.companyName}</Link></h5>} */}
                                <a > <button onClick={() => this.props.history.goBack()} className="btn btn-light">Back</button></a>
                            </div>
                            <div className="product-detale">
                                <h1 className="light-black">productName<br />
                                    {/* <span className="light-blue">{ProductDetails.price ? `$${ProductDetails.price}` : ''}</span> */}
                                </h1>
                                <p><b>Brand : </b><Link to="">companyName</Link></p>
                                <p><b>Sector : </b>sectorName</p>
                                <p><b>Category : </b>categoryName</p>
                                <hr />
                                <p>descriptio</p>
                            </div>
                            {<div className="product-details-btn-parent">
                                <button type="button" className="btn btn-red  btn-label btn-navyblue mr-3">Add to Wishlist
                                </button>
                               <button className="btn btn-red btn-label" onClick={this.showModal}> 
                                Enquire Now</button> 
                            </div>}
                        </div>
                        {/* <div className="col-md-2">
                                

                            </div> */}
                    </div>


                </div>

                

            </div>
            <Footer />

            <Modal
            wrapClassName="signup_parent_modal"
            className="cm_popup signup_modal "
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
                    centered
                    maskClosable={false}
                    >
                     <div className="sign-modal-content">
                        <div className="row align-items-center">
                            <div className="col-md-12" >
                                <h3 className="auth-heading pos-relative text-center mt-5">
                                    Reason for enquiry
                                    <span className="heading-after-border heading-after-border-center">
                                        <span className="heart"><Hert /></span>
                                    </span>
                                </h3>
                                <form className="rounded-textbox-form mt-5" >
                               
                                    <div className="form-group">
                                        <h5><b>What is the reason for your enquiry?</b></h5>
                                            <textarea rows="4" placeholder="Please Enter" 
                                            className="form-control hi" 

                                            value={message}
                                            onChange={this.handleOnChange}
                                            
                                            ></textarea>
                                            {/* <InlineError
                                                message={firstNameError}
                                            /> */}
                                    </div>
                                    <div class="text-center">
                                       
                                        <button type="submit" class="btn btn-red"> &nbsp;Submit &nbsp;</button>
                                    </div>
                                    <div class="form-group form-check text-center">
                                    <Link to="">Skip</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
        </Modal>
        </section>




        )
    }

}


export default NewProductDetails;

