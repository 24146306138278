import React, { Component } from 'react';

const Loader = (props) => {
    return (
        <div className="loader_wrapper"><div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
    )
}
export default Loader;



export const PbLoader = () => {
    return (
        <div className="loader-main">
            <div className="loader">
                <img src={require('../../assets/images/favicon.ico')} alt="logo" />
            </div>
        </div>
    )
}