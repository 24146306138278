import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history'
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { getRole } from '../redux/action/Auth'
import CategoryList from '../screens/admin/categoryManagement/CategoryList';
// import PaymentSuccess from '../screens/home/PaymentSuccess';
// import Payment from '../screens/home/Payment';
import SearchPayment from '../screens/home/SearchPaymentNew';
import ConferencePayment from '../screens/home/ConferencePayment';



import SearchPaymentSuccess from '../screens/home/SearchSuccessNew';
import ConferencePaymentSuccess from '../screens/home/ConferencePaymentSuccess';

import UserManagement from "../screens/admin/userManagement/UserManagement";

import TransactionList from '../screens/admin/transactions/TransactionList'
import TransactionDetail from '../screens/admin/transactions/TransactionDetail'

import ProfessionalBeauty from '../screens/admin/exhibiton_management/brands/ProfessionalBeauty'
import PromoCode from '../screens/admin/promoCode/PromoCode'
import AddPromoCode from '../screens/admin/promoCode/AddPromoCode'

import EditManagement from '../screens/admin/adManagement/EditManagement';
import ProductDetails from '../screens/admin/exhibiton_management/brands/ProductDetails';
import OfferDetails from '../screens/admin/exhibiton_management/brands/OfferDetailss';
// admin routes
import Admin from "./admin/Admin";
// imports screens & components
import { Media } from "../screens/index";
import ViewProducts from '../screens/admin/exhibiton_management/brands/ViewProducts';
import ViewDetails from '../screens/admin/exhibiton_management/conference/ViewDetails';
import UserProfile from '../screens/admin/userManagement/UserProfile';
import Profile from '../screens/admin/userManagement/Profile';
import EditUserProfile from '../screens/admin/userManagement/EditUserProfile';

import ViewStageDetails from '../screens/admin/exhibiton_management/conference/ViewStageDetails';

import SearchView from '../screens/admin/searchManagement/Search';
import SearchDeatil from '../screens/admin/searchManagement/SearchDetail'


import PageNotFound from '../screens/layout/PageNotFound';
import AddSearch from '../screens/admin/searchManagement/AddSearch';
import DefaultPlan from '../screens/admin/searchManagement/DefaultPlan';
// ----------brands-----------
import BrandsList from '../screens/admin/brandMangement/BrandsList';
// import BrandsDetail from '../screens/admin/brandMangement/BrandsDetail';
import CommingSoon from '../screens/layout/CommingSoon'
import AddCategory from "../screens/admin/categoryManagement/AddCategory";
import CategoryDeatil from "../screens/admin/categoryManagementNew/Detail"
import Cartview from "../screens/cart/CartView";
//----------web-------------
import EditCategory from "../screens/admin/categoryManagement/EditCategory";
import WebHome from '../screens/web/home/Home';
import Conference from '../screens/web/conference/Conference';
import ConferenceDetail from '../screens/web/conference/ConferenceDetail';
import ConferenceDetail1 from '../screens/web/conference/ConferenceDetail1';
import StagesConference from '../screens/web/conference/StagesConference';

import EventDetail from '../screens/web/conference/EventDetail';
import BrandProfile from '../screens/web/brand/BrandProfile';
import ManageServices from '../screens/web/brand/ManageServices';
import WebProductDetails from '../screens/web/brand/ProductDetails'
import WebOfferDetails from '../screens/web/brand/OfferDetails'
import ManageCompany from '../screens/web/brand/ManageCompany';
import EditManageCompany from '../screens/web/brand/EditManageCompany';
import EditSectorsCategories from '../screens/web/brand/EditSectorsCategories';
import WebDashboard from "../screens/web/dashboard/WebDashboard";

import ChangePassword from "../screens/admin/setting/changePassword";

//-----------report------------
import UserReport from '../screens/admin/reportmanagement/UserReport';
import WebBrand from "../screens/web/brand/WebBrand";
import ViewBrand from "../screens/web/brand/ViewBrand";
import WebViewProduct from "../screens/web/brand/WebViewProduct";
import ViewIndustry from "../screens/web/brand/ViewIndustry";
import Filter from "../components/common/Filter";
import BrandViewProduct from "../screens/web/brand/BrandViewProduct";

//----------feature list ------------

import FeatureBrandList from '../screens/admin/featured/FeatureBrandList';
import FeatureProductList from '../screens/admin/featured/FeatureProductList';
import BrandList from '../screens/admin/featured/BrandList';
import ProductList from '../screens/admin/featured/ProductList';
import ManageStage from '../screens/admin/featured/ManageStage';
import ManageConferences from '../screens/admin/featured/ManageConferences';
import ManageLounge from '../screens/admin/featured/ManageLounge';
import Communitys from "../screens/web/community/Community";
import ViewIndustryDetail from '../screens/web/brand/ViewIndustryDetail';
import CommunityDetails from '../screens/web/community/CommunityDetails';
import MyPlans from '../screens/home/Plans';
import Stages from '../screens/web/home/Stages';
import Lounges from '../screens/web/home/Lounges';
import ViewBrochures from '../screens/web/brand/ViewBrochures';
import Networking from '../screens/web/networking/Networking';
import NewProduct from '../screens/web/newproduct/NewProduct';
import NewProductDetails from '../screens/web/newproduct/NewProductDetails';
import Enquiry from '../screens/web/newproduct/Enquiry';
import EnquiryDetails from '../screens/web/newproduct/EnquiryDetails';
import NewOffer from '../screens/web/newoffer/NewOffer';
import AddOffer from '../screens/web/newoffer/AddOffer';
import CommunityUserProfile from '../screens/web/community/CommunityUserProfile';
import SearchUserProfile from '../screens/web/community/ShowUserProfile';
import Live from '../screens/admin/media/Live';
import NetworkLounges from "../screens/web/networking/NetworkLounges";
import ViewCommunity from '../screens/web/community/ViewCommunity';
import ViewSearch from '../screens/web/brand/ViewSearch';
import BusinessConnectionDetail from '../screens/web/community/BusinessConnectionsDetails'
import Brouchers from '../screens/web/dashboard/Brochures';
import BrandDetail from '../screens/web/brand/BrandDetail';
import ProductViewed from '../screens/web/dashboard/ProductViewed';
import OfferViewed from '../screens/web/dashboard/OfferView';
import MediaViewed from '../screens/web/dashboard/MediaViewed';
import CheckRole from "../screens/auth/CheckRole";
import PaymentNew from '../screens/home/PaymentNew';
import PaymentSuccessNew from "../screens/home/PaymentSuccessNew";
import OfferDetailss from '../screens/admin/exhibiton_management/brands/OfferDetailss';
import ChatBoxHeaderNew from "../components/chat/ChatBoxHeaderNew";
import Plan_landing from '../screens/home/Plan_landing';
import CountDown from '../screens/home/CountDown';
import JobProfile from '../screens/web/jobProfile/JobProfile';
import Diary from '../screens/web/diary/Diary';

export const history = createBrowserHistory();

const ChatBoxHeader = React.lazy(() => import("../components/chat/ChatBoxHeader"));
const SupportChatBox = React.lazy(() => import("../components/support/SupportChatBox"));

const WebHeader = React.lazy(() => import("../screens/layout/WebHeader"));

const AdminRoute = ({ component: Component, isAdmin, ...rest }) => {
  console.log("isAdmin", isAdmin)
  return (
    <Route
      {...rest}
      render={props =>
        isAdmin ? (
          <Component {...rest} {...props} />
        ) :
          (
            <Redirect
              to={{ pathname: "/", state: { from: props.location } }}
            />
          )
      }
    />
  )
}


history.listen(({ pathname }) => {
  window.scrollTo(0, 0)
})

let isProd = process.env.REACT_APP_ENV == 'prod'

class Routes extends Component {
  constructor(props) {
    super(props);
    // this.props.action.getRole()
  }

  render() {

    let type = localStorage.getItem('userType')
    let accessToken = localStorage.getItem('accessToken')
    let showAll = localStorage.getItem('showAll')
    // console.log("jkdfndsjkfhkjdsf",  showAll == "true")
    return (
      <Router history={history}>
        <CheckRole history={this.props.history} match={this.props.match} />
        <div className="dash_body">
          {accessToken && type != 1 ?
            <>
              <WebHeader {...this.props} history={this.props.history} match={this.props.match} /> </> : ''
          }

          {accessToken && type != 1 ?
            <>
              {/*<ChatBoxHeader />*/}
              <ChatBoxHeaderNew />
             
             { this.props.isSupportShow && <SupportChatBox {...this} {...this.props} history={this.props.history} match={this.props.match}
              /> }
            </>
            : ''
          }

          <Switch>

            <Route exact path="/dashboard" component={WebDashboard} />
            <Route exact path="/career-profile" component={JobProfile} />
          <Route exact path="/plans" component={Plan_landing} />
            <Route exact path="/coming-soon" component={CountDown} />
            {/*<Route exact path="/paymentsuccess" component={PaymentSuccess} />*/}

            <Route exact path="/pay-success" component={PaymentSuccessNew} />
           

            {/*<Route exact path="/payment" component={Payment} />*/}
            <Route exact path="/pay-user" component={PaymentNew} />
            
            <Route exact path="/stages" component={Stages} />
            <Route exact path="/lounges" component={Lounges} />
            <Route exact path="/networklounges" component={NetworkLounges} />
            <Route exact path="/plan" component={MyPlans} />

            <AdminRoute
              isAdmin={localStorage.getItem("userType") === 1 || localStorage.getItem("userType") === "1" ? true : false}
              exact
              path="/category"
              component={CategoryList}
            />

            <AdminRoute
              exact
              isAdmin={localStorage.getItem("userType") === 1 || localStorage.getItem("userType") === "1" ? true : false}
              path="/category/add"
              component={AddCategory}
            />
            <AdminRoute
              exact
              isAdmin={localStorage.getItem("userType") === 1 || localStorage.getItem("userType") === "1" ? true : false}
              path="/category/edit/:catogoryId"
              component={EditCategory}
            />
            <AdminRoute
              exact
              isAdmin={localStorage.getItem("userType") === 1 || localStorage.getItem("userType") === "1" ? true : false}
              path="/category/:catogoryId"
              component={CategoryDeatil}
            />
            <AdminRoute
              exact

              isAdmin={localStorage.getItem("userType") === 1 || localStorage.getItem("userType") === "1" ? true : false}
              path="/category/:catogoryId/add"
              component={AddCategory}
            />

            <AdminRoute
              exact
              isAdmin={localStorage.getItem("userType") === 1 || localStorage.getItem("userType") === "1" ? true : false}
              path="/category/:catogoryId/edit/:id"
              component={EditCategory}
            />

            <AdminRoute
              isAdmin={localStorage.getItem("userType") === 1 || localStorage.getItem("userType") === "1" ? true : false}
              path="/user-management"
              component={UserManagement}
            />

            <AdminRoute
              isAdmin={localStorage.getItem("userType") === 1 || localStorage.getItem("userType") === "1" ? true : false}
              path="/professional-beauty"
              component={ProfessionalBeauty}
            />

            <AdminRoute
              isAdmin={localStorage.getItem("userType") === 1 || localStorage.getItem("userType") === "1" ? true : false}
              path="/promo-code"
              component={PromoCode}
            />

            <AdminRoute
              isAdmin={localStorage.getItem("userType") === 1 || localStorage.getItem("userType") === "1" ? true : false}
              path="/add-promo-code"
              component={AddPromoCode}
            />

            <Route exact path="/job/view/:id" component={ProductDetails} />
            <Route exact path="/job-details/:id" component={ProductDetails} />

            <Route exact path="/view-jobs" component={ViewProducts} />
            <Route exact path="/user-profile/:id" component={Profile} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/view-industry-detail/:id" component={ViewIndustryDetail} />
            <Route exact path="/connection-profile/:id/connection/:connectionId" component={CommunityUserProfile} />
            <Route exact path="/pending-profile/:id/connection/:connectionId" component={CommunityUserProfile} />
            <Route exact path="/connection-profile/:id/connection/:connectionId" component={CommunityUserProfile} />
            <Route exact path="/connection-profile/:id/connection/:connectionId/requested" component={CommunityUserProfile} />
            <Route exact path="/search" component={SearchView} />
            <Route exact path="/add-plan" component={DefaultPlan} />
            <Route exact path="/add-search" component={AddSearch} />
            
            <Route exact path="/edit-search/:id" component={AddSearch} />
            <Route exact path="/view-search/:id" component={SearchDeatil} />
            <Route exact path="/view/:id" component={ViewDetails} />
            <Route exact path="/stage-detail/:id" component={ViewStageDetails} />

            <Route exact path="/user-management" component={UserManagement} />
            <Route exact path="/professional-beauty" component={ProfessionalBeauty} />
            <Route exact path="/edit-profile/:id" component={EditUserProfile} />
            <Route exact path="/profile/edit" component={EditUserProfile} />
            <Route exact path="/edit-business/:id" component={EditUserProfile} />


            <Route exact path="/edit-ads" component={EditManagement} />
            <Route exact path="/professional-beauty/:id" component={ProfessionalBeauty} />

            {/* ---admin------------transaction routes-------------- */}
            <Route exact path="/transactionlist" component={TransactionList} />
            <Route exact path="/transactiondetail/:id" component={TransactionDetail} />
            {/* ---------------brands routes-------------- */}
            <Route exact path="/businesses" component={BrandsList} />
            {/*<Route exact path="/businesssdetail" component={BrandsDetail} />*/}
            {/*<Route exact path='/commingsoon' component={CommingSoon} />*/}
            <Route exact path='/home' component={WebHome} />


            <Route exact path='/business/profile/:brandId' isUser={true} component={BrandProfile} />
            <Route exact path='/cartview' component={Cartview} />
            <Route exact path='/business/:brandId' isUser={true} component={BrandDetail} />
            <Route exact path='/business/:brandId/:companyId' isUser={true} component={BrandDetail} />

            <Route exact path="/business/staf/:id" component={CommunityUserProfile} />
            <Route exact path="/user/profile/:id" component={Profile} />
            <Route exact path="/user/profile/connection/:id" component={Profile} />
            <Route exact path="/document-download" component={Brouchers} />
            <Route exact path="/document-download/:pageNo" component={Brouchers} />
            <Route exact path="/job-viewed" component={ProductViewed} />
            <Route exact path="/job-viewed/:pageNo" component={ProductViewed} />
            <Route exact path="/training-viewed" component={OfferViewed} />
            <Route exact path="/training-viewed/:pageNo" component={OfferViewed} />
            
            <Route exact path="/media-viewed" component={MediaViewed} />
            <Route exact path="/media-viewed/:pageNo" component={MediaViewed} />


            <Route exact path='/businesshome-page' component={WebDashboard} />
            <Route exact path='/community' component={Communitys} />
            <Route exact path='/manage-services' component={ManageServices} />
            <Route exact path='/manage-company' component={ManageCompany} />
            {/* <Route exact path='/manage-company/:id' component={ManageCompany} /> */}
            <Route exact path='/edit-manage-company' component={EditManageCompany} />
            <Route exact path='/edit-sectors-categories' component={EditSectorsCategories} />
            <Route exact path='/businessprofile' component={BrandProfile} />
            <Route exact path='/seminar' component={Conference} />
            <Route exact path='/seminar/:coId' component={ConferenceDetail1} />
            <Route exact path='/stages/:coId' component={StagesConference} />
            <Route exact path='/seminar1/:coId' component={ConferenceDetail1} />
            <Route exact path='/event-detail' component={EventDetail} />
            <Route exact path='/event-detail/:date' component={EventDetail} />
            <Route exact path='/event-detail/:date/:created' component={EventDetail} />
            <Route exact path='/event-detail/:date/:created/:meetingId' component={EventDetail} />
            <Route exact path='/event-detail/:date/:created/:meetingId/:flag' component={EventDetail} />


            <Route exact path='/diary' component={Diary} />

            <Route exact path='/business' component={WebBrand} />

            {/*remove*/}
            <Route exact path='/view-business' component={ViewBrand} />
            <Route exact path='/view-business/featured' component={ViewBrand} />
            {/*remove*/}

            <Route exact path='/business-list' component={ViewBrand} />
            <Route exact path='/business-list/:searchKey' component={ViewBrand} />

            {/*remove*/}
            <Route exact path='/web-view-job' component={WebViewProduct} />
            <Route exact path='/web-view-job/:id' component={WebViewProduct} />
            {/*remove*/}

            <Route exact path='/job' component={WebViewProduct} />
            <Route exact path='/job/:pageNo' component={WebViewProduct} />

            {/*remove*/}
            <Route exact path='/view-industry' component={ViewIndustry} />
            {/*remove*/}

            <Route exact path='/industry' component={ViewIndustry} />

            <Route exact path='/filter' component={Filter} />
            <Route exact path='/web-job-details/:id' component={WebProductDetails} />
            <Route exact path='/job/:id/detail' component={WebProductDetails} />

            {/* <Route exact path="/job-details/:id" component={OfferDetails} /> */}

            <Route exact path='/web-training-details/:id' component={WebOfferDetails} />
            <Route exact path='/training/:id/detail' component={WebOfferDetails} />

            <Route exact path="/training-details/:id" component={OfferDetailss} />

            <Route exact path='/seminar/:coId/:name/:id' component={Live} />

            <Route exact path='/view-profile' component={CommunityDetails} />
            <Route exact path='/view-connections' component={CommunityDetails} />
            <Route exact path='/view-connections/:id' component={CommunityDetails} />
            <Route exact path='/view-pending-connections' component={CommunityDetails} />
            <Route exact path='/view-requested-connections' component={CommunityDetails} />
            <Route exact path='/view-suggested-connections' component={CommunityDetails} />
            <Route exact path='/view-block-connections' component={CommunityDetails} />
            <Route exact path='/business-connections' component={BusinessConnectionDetail} />
            <Route exact path='/business-pending-connections' component={BusinessConnectionDetail} />
            <Route exact path='/business-requested-connections' component={BusinessConnectionDetail} />
            <Route exact path="/search/paymentsuccess" component={ViewSearch} />
            <Route exact path='/business/detail/:brandId' component={BrandDetail} />
            <Route exact path='/business/product/:brandId' component={BrandViewProduct} />

            <Route exact path='/change-password' component={ChangePassword} />
            <Route exact path='/user-report' component={UserReport} />
            {/* feature list */}
            <Route exact path='/feature-businesslist' component={FeatureBrandList} />
            <Route exact path='/feature-joblist' component={FeatureProductList} />
            <Route exact path='/Add-feature-businesslist/:featuredCount' component={BrandList} />
            <Route exact path='/Add-feature-joblist/:featuredCount' component={ProductList} />
            <Route exact path='/manage-stages' component={ManageStage} />
            <Route exact path='/manage-seminar' component={ManageConferences} />

            <Route exact path='/manage-lounges' component={ManageLounge} />
            <Route exact path='/view-document/:id' component={ViewBrochures} />
            <Route exact path='/networking' component={Networking} />
            <Route exact path='/newjob' component={WebViewProduct} />
            <Route exact path='/newjob/:pageNo' component={WebViewProduct} />
            <Route exact path='/newjob-details' component={NewProductDetails} />
            <Route exact path='/enquiry' component={Enquiry} />
            <Route exact path='/enquiry/:pageNO' component={Enquiry} />
            <Route exact path='/enquiry-details/:enId' component={EnquiryDetails} />
            <Route exact path='/trainings' component={NewOffer} />
            <Route exact path="/payment/search" component={SearchPayment} />
            <Route exact path="/payment/details" component={SearchPaymentSuccess} />
            <Route exact path="/seminar/:coId/payment" component={ConferencePayment} />
            <Route exact path="/payment/seminar/:coId" component={ConferencePaymentSuccess} />


            <Route exact path="/addtraining" component={AddOffer} />







            <Route path="/community/view/page=:page" component={ViewCommunity} />
            <Route path="/search/results/all/page=:page&keywords=:searchKey" component={ViewSearch} />
            <Route exact path="/community/view/:searchKey" component={ViewCommunity} />
            <Route exact path='/media' component={Media} />
            <Route exact path='/media/all' component={Media} />

            <Route path='/2021' component={() => {
              window.location.href = '/';
              return null;
            }} />

            {localStorage.getItem("userType") === 1 || localStorage.getItem("userType") === "1" ?
              <AdminRoute
                isAdmin={localStorage.getItem("userType") === 1 || localStorage.getItem("userType") === "1" ? true : false}
                path="/"
                component={Admin}
              />
              : ""}
              <Route path='/invalid-url' invalidUrl={true} component={PageNotFound} />
            <Route path='/404' component={PageNotFound} />
            <Route path='*' component={PageNotFound} status={404} />
          </Switch>
        </div>
      </Router>
    )
  }
}


const mapStateToProps = (state) => {
  const { profile, loading } = state

  return {
    userType: profile?.profileInfo?.userType,
    userPlan: profile?.profileInfo?.userPlan,
    isSupportShow: loading?.isSupportChat
  }
}
const mapDispatchToProps = dispatch => {
  return {
    action: bindActionCreators({ getRole }, dispatch)
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Routes);
