import React, { Component } from 'react';
import { Hert, ExhibitionManagement, Pencil, Delete } from '../../../../SvgIcon';
import { Link } from "react-router-dom";
import Sidebar from '../../../layout/Sidebar';
import Header from '../../../layout/Header';
import EditProduct from '../popups/EditProduct'

import { _Api } from '../../../../services/Api';
import Url from '../../../../services/BaseUrl';
import { notify } from '../../../../components/Toaster';


import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import WebHeader from '../../../layout/WebHeader';

import Linkify from 'react-linkify';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { SecureLink } from "react-secure-link";


let isProd = process.env.REACT_APP_ENV == 'prod'
const antIcon = <LoadingOutlined style={{ fontSize: 72 }} spin />;


class ProductDetails extends Component {

    constructor() {
        super();
        this.state = {
            addStaff: false,
            addProduct: false,
            editStaff: false,
            page: '',
            limit: 10,
            productListData: [],
            ProductDetails: '',
            productId: ''
        };
        window.scrollTo(0, 0)
    }

    togglePopup(id, productId) {

        if (id === "editProduct") {
            this.setState({
                editProduct: !this.state.editProduct, productId: productId
            });
        }

    }
    getParticularViewProduct = () => {
        _Api(Url.VIEW_PARTICULAR_OFFER.method, Url.VIEW_PARTICULAR_OFFER.url, '', `?offerId=${this.props.match.params.id}`)
            .then(resp => {
                console.log('reaERTYp', resp.responseData.result)

                this.setState({ ProductDetails: resp.responseData.result })
                // this.props.closePopup()
                // notify('success', resp.responseData.message)
            }).catch(err => {
                this.setState({ isLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
                this.setState({ isLoader: false })
            })
    }
    componentDidMount() {
        this.getParticularViewProduct()

        console.log("poiuhshgvdhbsnjam")
    }



    render() {
        let { StaffListData, productListData, ProductDetails } = this.state;
        console.log("kdashgdhbj", ProductDetails)
        let { brandData } = ProductDetails ? ProductDetails : {}
        let userType = localStorage.getItem("userType")

        // if ( userType != 1)
        // return (
        //     <section className="brand-home-banner-bg webhome">
        //         // {userType != 1 &&{/* <WebHeader history={this.props.history} match={this.props.match} /> */}}               
        //         <div className="centerLoader" >
        //             {                      
        //                 <Spin size="large" tip="Loading..." indicator={antIcon} />

        //             }                      

        //         </div>
        //     </section>


        // )
        // else
        return (
            <>

                {userType != 1 ?
                    {/* <WebHeader history={this.props.history} match={this.props.match} /> */ } :
                    <>
                        <Header />
                        <Sidebar />
                    </>
                }
                <section className={`brand-home-banner-bg pt-5 brandprofile-banner-bg ${userType == 1 ? "brand dash_body_container withsidenavbar" : ''} `} >


                    <div className="cm-section container-fluid product-detail">


                        <div className="header-text  mb-4 ">
                            <div className="d-flex align-items-center">
                                {/* <p className="header-icon"><ExhibitionManagement /></p> */}

                                {/* <h4>{exhibitionDetails?.exhibitionName}</h4> */}
                            </div>




                        </div>



                        <div className="card-wrapper product-detail mt-0">
                            <div className="row">
                                <div className="col-md-5 pr-5">
                                    <div className="card community-card">
                                        <img className="img-fluid" src={ProductDetails.image ? ProductDetails.image : require('../../../../assets/images/placeholder.png')} />

                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="d-flex justify-content-between align-items-center">
                                        {/* <span>dhcgbndhvgcnd /dhyv7i i8hj/dvfdv</span>  */}
                                        <a > <button onClick={() => this.props.history.goBack()} className="btn btn-light"><i class="fa fa-arrow-left" aria-hidden="true"></i>Back</button></a>

                                    </div>

                                    <h1 className="light-black res-h1">{ProductDetails.name}<br />
                                        {/* <span className="light-blue">{ProductDetails.price ? `$${ProductDetails.price}` : ''}</span> */}
                                    </h1>
                                    <p><b>Showcase :</b> {brandData?.companyName}
                                    </p>
                                    <br/>
                                    <p><b>Category : </b>{ProductDetails.manageCategoryData?.map((item)=> item?.name)}</p>
                                    {/* <p><b>Sector : </b>{ProductDetails.manageSectorData?.map((item)=> item?.name)}</p>     */}
                                    <hr />
                                    <p>
                                    <Linkify
                                        componentDecorator={(
                                            decoratedHref,
                                            decoratedText,
                                            key
                                        ) => (
                                                <SecureLink href={decoratedHref} key={key}>
                                                    {decoratedText}
                                                </SecureLink>
                                            )}
                                    >
                                        {ReactHtmlParser(ProductDetails.description)}
                                    </Linkify>




                                </p>
                                    {/* <button className="btn btn-red" onClick={this.togglePopup.bind(this)}>Add To WishList</button> */}

                                </div>

                            </div>


                        </div>



                    </div>
                </section>


            </>
        );
    }
}
export default ProductDetails;

const loop = [0, 1, 2, 3]

const chatLoop = [0, 1, 2, 3, 4, 5, 6, 7, 8]



