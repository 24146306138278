import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { AdManagement, Filter, Pencil, Delete } from '../../../SvgIcon';
import '../../../assets/css/adManagement.css';
import { Menu, Dropdown, Checkbox, DatePicker, TimePicker, Empty } from 'antd';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { Pagination } from '../../../components/common/Pagination';
import { Modal, Button, Space } from 'antd';

import { notification } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import {
    MyAlert,
    MySpinner,
    AdLoader1
} from "../../../components/index"

import {getTimeFormate} from "../../../utils/DateZone"

const { confirm } = Modal;

class AdManagementComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDeleted: '',
            adId: '',
            status: "",
            page: 1,
            limit: 10,
            loading: false,
            visible: false,
            selectedId: '',
            adData: [],
            selectedId: '',
            isLoader: false,
            search: '',
            totalCount: [],
            snNo: 0,
            selectedItems: [],
        }
    }



    componentDidMount() {
        this.getAdList()
    }









    getAdList = (isShowLoader = true, isDownload = 0) => {
        this.setState({ isLoader: isShowLoader })
        let { page, limit, search } = this.state
        let req = {
            page: page ? page : 1,
            limit: limit,
            search: search.toString()
        }
        _Api(Url.GET_LIST_REMINDER.method, Url.GET_LIST_REMINDER.url, '', `?page=${req.page}&limit=${req.limit}&search=${req.search}&download=${isDownload}`)
            .then(resp => {
                console.log('respexList', resp.responseData.result)
                if (resp.responseData.file) {
                    window.open(resp.responseData.file, "_blank");
                    this.setState({ isLoader: false, });
                }
                else
                this.setState({ adData: resp.responseData.result.data, totalCount: resp.responseData.result.totalCount, isLoader: false })

            }).catch(err => {
                this.setState({ isLoader: false })
                // if (err.error)
                //     notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)

            })
    }




    handlePageChange = page => this.setState({ loader: true, page, adData: [] }, () => this.getAdList(false));

    handleSearch = e => this.setState({ search: e.target.value, page: 1, snNo: 0, adData: [] }, () => this.getAdList());



    deleteAd = (id) => {
        let req = {
            isDeleted: 0,
            adsId: id,

        }
        this.setState({ visible: false })
        _Api(Url.AD_DELTED.method, Url.AD_DELTED.url, '', `?adsId=${req.adsId}`)
            .then(resp => {
                notification.success({ message: 'Success', description: resp.responseData.message }); this.setState({ visible: false })
                this.getAdList(false)
            }).catch(err => {
                this.setState({ visible: false })
                if (err.error)
                    notification.error({ message: 'Error', description: err.error.responseMessage });
                else if (err)
                    notification.error({ message: 'Error', description: err.message });
            })
    }

    //Show modal
    showModal = (id) => {
        this.setState({
            visible: true,
            selectedId: id
        });

    };
    //Cancel modal
    handleCancel = () => {
        // console.log('delete', resp.responseData.message)
        this.setState({ visible: false });
    };

    statusAd = (e, id) => {

        let oldArr = this.state.selectedItems
        oldArr.push(id)

        this.setState({ selectedItems: oldArr })




        let req = {
            adsId: id.toString(),
            status: (e.target.checked ? 1 : 0),
        }
        _Api(Url.AD_CHANGE_STATUS.method, Url.AD_CHANGE_STATUS.url, '', `?adsId=${req.adsId}&status=${req.status}`)
            .then(resp => {
                notification.success({ message: 'Success', description: resp.responseData.message });
                this.setState({ visible: false, selectedItems: this.state.selectedItems.filter(item => item != id) })
                this.getAdList(false)
            }).catch(err => {
                this.setState({ visible: false, selectedItems: this.state.selectedItems.filter(item => item != id) })
                if (err.error)
                    notification.error({ message: 'Error', description: err.error.responseMessage });
                else if (err)
                    notification.error({ message: 'Error', description: err.message });

            })

    }





    onPressBtn = (page) => {
        this.setState({ page, snNo: page - 1, adData: [] }, () => {
            this.getAdList()
        })

    }

    // delete modal
    showDeleteConfirm = (id) => {
        confirm({
            title: 'Are you sure you want to delete this?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            onOk: () => {
                this.deleteAd(id)
            },
            onCancel: () => {
                this.handleCancel()
            },
        });
    }



    render() {
        const { adData, isLoader, visible, loading, selectedItems } = this.state
        const menu = (
            <Menu className="filter-dropdown-list p-3">
                <ul className="list-unstyled">
                    <li className="main-category mb-3"><Checkbox onChange={onChange} checked>Category</Checkbox>
                        <ul className="list-unstyled">
                            <li><Checkbox onChange={onChange}>Makeup Products</Checkbox></li>
                            <li><Checkbox onChange={onChange}>Furniture and salon supplies</Checkbox></li>
                            <li><Checkbox onChange={onChange}>Education/Training</Checkbox></li>
                        </ul>
                    </li>
                    <li className="main-category"><Checkbox onChange={onChange} checked>Date</Checkbox>
                        <ul className="list-unstyled">
                            <li>
                                <div className="row">
                                    <div className="col-6">
                                        <DatePicker onChange={onChange} />
                                    </div>
                                    <div className="col-6">
                                        <TimePicker use12Hours onChange={onChange} />
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </Menu>
        );
        function onChange(e) {
            console.log(`checked = ${e.target.checked}`);
        };
        function onChange(date, dateString) {
            console.log(date, dateString);
        };
        function onChange(time, timeString) {
            console.log(time, timeString);
        };
        return (
            <>
                <div className="dash_body_container">
                    <div className="body_container">
                        <div className="header-text d-flex mb-4 mt-3">
                            <div className="d-flex flex-grow-1 align-items-center">
                                <p className="header-icon"><AdManagement /></p>
                                <h4>Reminder</h4></div>
                            <div className="search_wrapper  mr-2">
                                <i className="fa fa-search" />
                                <input type="search"
                                    placeholder="Search..."
                                    name='search'
                                    // value={search}
                                    onChange={this.handleSearch}
                                />
                            </div>

                            <a  className="btn btn-red" onClick={()=> this.getAdList(false, 1)}>Export CSV</a>
                            {/* <Dropdown overlay={menu} trigger={['click']} className="filter-dropdown">
                                <a className="ant-dropdown-link filter-icon ml-2" onClick={e => e.preventDefault()}>
                                    <Filter /><DownOutlined />
                                </a>
                            </Dropdown> */}
                            {/* <Link href="/" className="filter-icon ml-2"><Filter /></Link> */}
                        </div>

                        <div class="table-responsive">
                            <table class="table table-bordered rounded-table">
                                <thead>
                                    <tr>
                                        <th>S.No.</th>
                                        <th>User Name </th>                                    
                                        <th>Session name</th>                                        
                                        <th>Seminars Name</th>
                                        <th>Stage name</th>
                                        <th>Date</th>
                                        {/* <th>Status</th>
                                        <th>Action</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoader && <AdLoader1 />}
                                    {
                                        adData?.map((item, index) => {

                                            return (
                                                <tr>
                                                    <td><span className="circle-serial">{(this.state.snNo * 10) + index + 1}</span></td>
                                                    <td>
                                                        <Link to={`/user-profile/${item.userId._id}`}>{item.userId.firstName}</Link>
                                                    </td> 
                                                    <td>
                                                        {item?.sessionId.sessionName}
                                                    </td> 
                                                    <td>
                                                        {item?.conferenceName}
                                                    </td>                                               
                                                    <td>
                                                        {item?.stageId.stageName}
                                                    </td>
                                                    <td>{getTimeFormate(item.created)}</td>
                                               
                                                    {/* <td className="action_group">
                                                        <Link to={`/add-ads/${item._id}`}> <p><p className="circle-serial" ><Pencil /></p></p></Link>

                                                        <a onClick={() => this.showDeleteConfirm(item._id)} ><p className="circle-serial"><Delete /></p></a>
                                                    </td> */}
                                                </tr>
                                            )


                                        })
                                    }

                                </tbody>
                            </table>
                            <div>
                            {!isLoader && adData.length == 0 &&
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                             </div>

                                {
                                    adData.length != 0 &&
                                    <Pagination
                                        totalPage={Math.ceil(this.state.totalCount / 10)}
                                        currentPage={this.state.page}
                                        onPressBtn={this.onPressBtn}
                                    />
                                }
                        </div>
                       

                    </div>
                </div>

                <Modal show={visible} onHide={this.handleCancel} className="cm_order_view dlt_item">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <img src={require('../../assets/img/Delete-Email.png')} /> */}
                        <br />
                        <br />
                        <p>Are you sure you want to delete this ?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="footer_btn_wrapper btn_nospace">
                            <Button variant="warning" loading={loading} onClick={() => this.deleteAd(this.state.selectedId)} >
                                Yes
                        </Button>
                            <Button variant="dark" onClick={this.handleCancel}>
                                No
                        </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default AdManagementComp;
const loop = [0, 1, 2, 3]