import React, { Component } from 'react';
import { Hert, ExhibitionManagement, Pencil, Delete } from '../../../../SvgIcon';
import { Link } from "react-router-dom";
import Sidebar from '../../../layout/Sidebar';
import Header from '../../../layout/Header';
import { Tab, Tabs } from 'react-bootstrap'
import AddStaff from '../popups/AddStaff'
import AddProduct from '../popups/AddProduct';
import { _Api } from '../../../../services/Api';
import Url from '../../../../services/BaseUrl';
import { notify } from '../../../../components/Toaster';
import moment from 'moment-timezone'
import { CmBtn } from "../../../../components/common/Button";



class AdViewDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addStaff: false,
            addProduct: false,
            networkingLoungeViewData: [],
        };
    }

    togglePopup(id) {

        if (id === "addStaff") {
            this.setState({
                addStaff: !this.state.addStaff,
            });
        }
        else {
            this.setState({
                addProduct: !this.state.addProduct
            });
        }

    }

    componentDidMount() {
        this.getLoungeViewDetails()
    }

    getLoungeViewDetails = () => {
        _Api(Url.VIEW_LOUNGE.method, Url.VIEW_LOUNGE.url, '', `?loungeId=${this.props.match.params.id}`)
            .then(resp => {
                console.log('respexList', resp.responseData.result)
                this.setState({ viewLoungeDetails: resp?.responseData?.result })
            }).catch(err => {
                this.setState({ isLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
                this.setState({ isLoader: false })
            })
    }



    handleJoin = (id, item) => {
        console.log("test123", navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1)
        if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
            this.handleJoin1(id, item)

        }
        else {
            this.handleJoin2(id, item)
        }

    }

    handleJoin2 = (id, item) => {
        // const { numberofAttendees, onlineJoinUser } = item
        const numberofAttendees = 3, onlineJoinUser = 2
        let req = {
            otherUserId: localStorage.getItem('id'),
            type: 4,
            networkLoungeId: item._id,
            exhibitionId: localStorage.getItem('exhibition')
        }
        _Api(Url.CHECK_PLAN_USER.method, Url.CHECK_PLAN_USER.url, req)
            .then(resp => {
                const { isBuy } = resp?.responseData
                if (isBuy == 0) {
                    this.props.dispatch({ type: 'SHOW_PLAN_USER_POPUP', payload: this.props.match })
                }
                else {
                    if (true) {
                        console.log("onee11222",id,  localStorage.getItem('accessToken'))
                        console.log("onee11", `https://video.worldwidejobfinder.com?lounge=${id}&token=${localStorage.getItem('accessToken')}`)

                        window.open(`https://video.worldwidejobfinder.com?lounge=${id}&token=${localStorage.getItem('accessToken')}&name=${item.loungeName}`, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                        let req = { networkLoungeId: item._id }

                        _Api(Url.JOIN_LOUNG_PLAN.method, Url.JOIN_LOUNG_PLAN.url, req)
                            .then(resp => {
                            }).catch(err => { })
                    }
                    else
                        notify("err", "This lounges is full , you can not join at this time")
                }

            }).catch(err => {

            })

    }






    handleJoin1 = (id, item) => {
        // const { numberofAttendees, onlineJoinUser } = item
        const numberofAttendees = 3, onlineJoinUser = 2
        var windowReference = window.open();
        let req = {
            otherUserId: localStorage.getItem('id'),
            type: 4,
            networkLoungeId: item._id,
            exhibitionId: localStorage.getItem('exhibition')
        }
        _Api(Url.CHECK_PLAN_USER.method, Url.CHECK_PLAN_USER.url, req)
            .then(resp => {
                const { isBuy } = resp?.responseData
                if (isBuy == 0) {
                    this.props.dispatch({ type: 'SHOW_PLAN_USER_POPUP', payload: this.props.match })
                }
                else {                 
                        if (true) {
                            var newWin = window.open(`https://video.worldwidejobfinder.com?lounge=${id}&token=${localStorage.getItem('accessToken')}&name=${item.loungeName}`, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                            if (!newWin || newWin.closed || typeof newWin.closed == 'undefined')
                                windowReference.location = `https://video.worldwidejobfinder.com?lounge=${id}&token=${localStorage.getItem('accessToken')}&name=${item.loungeName}`
                            let req = { networkLoungeId: item._id }
    
                            _Api(Url.JOIN_LOUNG_PLAN.method, Url.JOIN_LOUNG_PLAN.url, req)
                                .then(resp => {
                                }).catch(err => { })
                        }
                    else
                        notify("err", "This lounges is full , you can not join at this time")
                }

            }).catch(err => {

            })

    }








    render() {
        let { viewLoungeDetails } = this.state;
        return (
            <>
                <div className="dash_body_container">
                    <Header />
                    <Sidebar />
                    <div className="body_container">


                        <div className="header-text  mb-4 mt-3">
                            <div className="d-flex align-items-between">

                                <h4>{viewLoungeDetails?.loungeName}</h4>

                            </div>

                            <a > <button onClick={() => this.props.history.goBack()} className="btn btn-light"><i class="fa fa-arrow-left" aria-hidden="true"></i>Back</button></a>

                        </div>

                        <div className="rounded-body-form">
                            <div className="table-wrapper ">
                            <div className="header-text  mb-4 mt-3">
                            <div className="d-flex align-items-center">
                                        {/* <div className="flex-grow-1"> */}
                                            <CmBtn onClick={() => this.handleJoin(viewLoungeDetails._id, viewLoungeDetails)} className="btn btn-red" bg='var(--btncolor)' text="Join" />
                                        {/* </div> */}
                                    </div>
                                    <div className="ml-auto">                                     

                                            <Link className="btn btn-red" to={`/add-lounge/${viewLoungeDetails?.conferenceId}/${viewLoungeDetails?._id}`}>Edit</Link>
                                    </div>
                                </div>


                                <div className="table-responsive">

                                    <table className="table vertical-table table-bordered rounded-table">
                                        <tr>
                                            <th>Lounge Name</th>
                                            <td>{viewLoungeDetails?.loungeName}</td>
                                            <th>Allowed no. of Attendees</th>
                                            <td>{viewLoungeDetails?.numberofAttendees}</td>
                                        </tr>

                                        <tr>
                                            <th>Start date</th>
                                            <td>{moment(viewLoungeDetails?.startDate).format('dddd')} {moment(viewLoungeDetails?.startDate).format('LL')}</td><th>Start Time</th><td>{moment(viewLoungeDetails?.startTime).format('LT')}</td>
                                        </tr>
                                        <tr>
                                            <th>End date</th>
                                            <td>{moment(viewLoungeDetails?.endtDate).format('dddd')} {moment(viewLoungeDetails?.endDate).format('LL')}</td><th>End Time</th><td>{moment(viewLoungeDetails?.endTime).format('LT')}</td>
                                        </tr>
                                        <tr>
                                           
                                            {/* <th>Lounge URL</th>
                                            <td>{viewLoungeDetails?.loungesUrl}</td> */}
                                             <th></th>
                                            <td></td>
                                        </tr>

                                    </table>
                                </div>

                                <div className="header-text  mb-4 mt-5 d-flex justify-content-start">
                                    <h4 className="mb-auto">Cover image</h4>
                                </div>
                                <div className="confrence-cover">
                                    <div className="card video-card">
                                        <img className="img-fluid" src={viewLoungeDetails?.image} />
                                    </div>
                                </div>                     


                            </div>


                        </div>

                    </div>
                </div>
            </>
        );
    }
}
export default AdViewDetails;

const loop = [0, 1, 2, 3]

const chatLoop = [0, 1, 2, 3, 4, 5, 6, 7, 8]



