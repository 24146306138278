import React, { Component } from 'react';
import { BtnRed, CmBtn, LiveBtn } from "../../../components/common/Button";
import ProductCarousel from "../../../components/carousel/ProductCarousel"
import IndustrieCarousel from "../../../components/carousel/IndustrieCarousel";
import { Calender, Clock, Hert, DeliverTraingle, TwoUsers, NoMediaICon } from "../../../SvgIcon"
import WebHeader from '../../layout/WebHeader'
import Footer from "../../layout/Footer";

import CommingSoonLayer from "../../../components/common/CommingSoonLayer";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { Progress } from 'antd';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { connect } from "react-redux";

import { notify } from '../../../components/Toaster';

import { Spin, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import LazyLoad from 'react-lazy-load';
import socket from "../../../services/socket";
import Thankyou from "../../home/HomeCommingSoon"
import Const from "../../../services/Const"
import { getTimeZone } from "../../../utils/DateZone"

const antIcon = <LoadingOutlined style={{ fontSize: 72 }} spin />;
let isProd = process.env.REACT_APP_ENV == 'prod'
var _ = require('lodash');
const {isHideNetwork} = Const
class Networking extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.carousel = React.createRef();

        this.state = {
            isCommingSoon: false,
            isLoading: true,
            networkLoungList: []
        }
        window.scrollTo(0, 0)
        let self = this;
        socket.on('networkLoungeEnter', function (param) {
            console.log("networkLoungeEnter", param)
            let networkLoungList = self.state.networkLoungList;
            let lounge = _.find(networkLoungList, { _id: param.loungeId });
            if (lounge) {
                // console.log("lounge", lounge)
                lounge.onlineJoinUser = param.userCount;
                let index = _.findIndex(networkLoungList, { _id: param.loungeId });
                //networkLoungList.splice(index, 1, lounge);
                networkLoungList[index] = lounge;
                // console.log("######",networkLoungList)
                self.setState({
                    networkLoungList: networkLoungList
                }, function () {
                    // console.log("self.state",self.state)
                })
            }
        })

        socket.on('networkLoungeExist', function (param) {
            console.log("networkLoungeExist", param)
            let networkLoungList = self.state.networkLoungList;
            let lounge = _.find(networkLoungList, { _id: param.loungeId });
            if (lounge) {
                // console.log("lounge", lounge)
                if (param.userCount >= 0) {
                    lounge.onlineJoinUser = param.userCount;
                }

                let index = _.findIndex(networkLoungList, { _id: param.loungeId });
                //networkLoungList.splice(index, 1, lounge);
                networkLoungList[index] = lounge;
                self.setState({
                    networkLoungList: networkLoungList
                }, () => {
                    // console.log("updateee valuee", index)
                })
            }
        })
    }



    componentDidMount() {
        this.props.dispatch({ type: 'PATH_ADD', payload: this.props.match })
        this.getHomeDetails()
        let self = this;

        socket.on('networkLoungeEnter', function (param) {
            console.log("networkLoungeEnter", param)
            let networkLoungList = self.state.networkLoungList;
            let lounge = _.find(networkLoungList, { _id: param.loungeId });
            if (lounge) {
                // console.log("lounge", lounge)
                lounge.onlineJoinUser = param.userCount;
                let index = _.findIndex(networkLoungList, { _id: param.loungeId });
                //networkLoungList.splice(index, 1, lounge);
                networkLoungList[index] = lounge;
                // console.log("######",networkLoungList)
                self.setState({
                    networkLoungList: networkLoungList
                }, function () {
                    // console.log("self.state",self.state)
                })
            }
        })

        socket.on('networkLoungeExist', function (param) {
            console.log("networkLoungeExist", param)
            let networkLoungList = self.state.networkLoungList;
            let lounge = _.find(networkLoungList, { _id: param.loungeId });
            if (lounge) {
                // console.log("lounge", lounge)
                if (param.userCount >= 0) {
                    lounge.onlineJoinUser = param.userCount;
                }

                let index = _.findIndex(networkLoungList, { _id: param.loungeId });
                //networkLoungList.splice(index, 1, lounge);
                networkLoungList[index] = lounge;
                self.setState({
                    networkLoungList: networkLoungList
                }, () => {
                    // console.log("updateee valuee", index)
                })
            }
        })
    }

    componentWillMount() {
        socket.off("networkLoungeEnter");
        socket.off("networkLoungeExist");
    }

    handleError(err) {
        let errorMessage = err.error && err.error.responseMessage || err.message;
        notify("err", errorMessage);
    }

    getHomeDetails = () => {

        if (this.props.loading?.exhibitionId) {
            _Api(Url.GET_NETWORKING_LIST.method, Url.GET_NETWORKING_LIST.url, '', `?exhibitionId=${this.props.loading?.exhibitionId}`)
                .then(resp => {
                    console.log('jdkasjldjhsabdklsajh', resp.responseData)
                    this.setState({ isLoading: false, networkLoungList: resp.responseData.result.data })

                }).catch(() => {
                    this.setState({ isLoading: false })
                })

        }
        else {
            _Api(Url.GET_EXHIBITION_ID.method, Url.GET_EXHIBITION_ID.url, '')
                .then(resp => {
                    _Api(Url.GET_NETWORKING_LIST.method, Url.GET_NETWORKING_LIST.url, '', `?exhibitionId=${resp?.responseData?.exhibitionDetails?._id}`)
                        .then(resp => {
                            console.log('jdkasjldjhsabdklsajh', resp.responseData)
                            this.setState({ isLoading: false, networkLoungList: resp.responseData.result.data })

                        }).catch(() => {
                            this.setState({ isLoading: false })
                        })
                }).catch((err) => { })
        }
    }


    next = () => {
        this.carousel.next();
    }

    previous = () => {
        this.carousel.prev();
    }

    onlyJoin = (name, id, coId, item = {}) => {
        const { numberofAttendees, onlineJoinUser } = item
        if (name == "lounge") {


        }
        else
            this.props.history.push(`/seminar/${coId}/${name}/${id}`)

    }

    handleJoin = (id, item)  => {
        console.log("test123",navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1)
        if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {            
            this.handleJoin1(id, item)
            
        }
        else{ 
            this.handleJoin2(id, item)
        }    

    }

    handleJoin2 = (id, item) => {
        // console.log("onee11222",  localStorage.getItem)
  
        const { numberofAttendees, onlineJoinUser } = item
        let req = {
            otherUserId: localStorage.getItem('id'),
            type: 4,
            networkLoungeId: item._id,
            exhibitionId: localStorage.getItem('exhibition')
        }
        _Api(Url.CHECK_PLAN_USER.method, Url.CHECK_PLAN_USER.url, req)
            .then(resp => {
                const { isBuy } = resp?.responseData
                if (isBuy == 0) {
                    this.props.dispatch({ type: 'SHOW_PLAN_USER_POPUP', payload: this.props.match })
                }
                else {
                    if (onlineJoinUser < numberofAttendees) {
                        console.log("onee11222",id,  localStorage.getItem('accessToken'))
                        console.log("onee11", `https://video.worldwidejobfinder.com?lounge=${id}&token=${localStorage.getItem('accessToken')}`)

                        window.open(`https://video.worldwidejobfinder.com?lounge=${id}&token=${localStorage.getItem('accessToken')}&name=${item.loungeName}`, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                        let req = { networkLoungeId: item._id }

                        _Api(Url.JOIN_LOUNG_PLAN.method, Url.JOIN_LOUNG_PLAN.url, req)
                            .then(resp => {
                            }).catch(err => { })
                    }
                    else
                        notify("err", "This lounges is full , you can not join at this time")
                }

            }).catch(err => {

            })

    }






    handleJoin1 = (id, item) => {
        console.log("onee", id)
        const { numberofAttendees, onlineJoinUser } = item
        var windowReference = window.open();
        let req = {
            otherUserId: localStorage.getItem('id'),
            type: 4,
            networkLoungeId: item._id,
            exhibitionId: localStorage.getItem('exhibition')
        }
        _Api(Url.CHECK_PLAN_USER.method, Url.CHECK_PLAN_USER.url, req)
            .then(resp => {
                const { isBuy } = resp?.responseData
                if (isBuy == 0) {
                    this.props.dispatch({ type: 'SHOW_PLAN_USER_POPUP', payload: this.props.match })
                }
                else {

                
                    
                    if (onlineJoinUser < numberofAttendees) {
                        var newWin = window.open(`https://video.worldwidejobfinder.com?lounge=${id}&token=${localStorage.getItem('accessToken')}&name=${item.loungeName}`, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                        if (!newWin || newWin.closed || typeof newWin.closed == 'undefined')
                            windowReference.location = `https://video.worldwidejobfinder.com?lounge=${id}&token=${localStorage.getItem('accessToken')}&name=${item.loungeName}`
                        let req = { networkLoungeId: item._id }

                        _Api(Url.JOIN_LOUNG_PLAN.method, Url.JOIN_LOUNG_PLAN.url, req)
                            .then(resp => {
                            }).catch(err => { })
                    }


                    // if (onlineJoinUser < numberofAttendees) {
                    //     var newWin = window.open('/network-lounge/' + id, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                    //     if (!newWin || newWin.closed || typeof newWin.closed == 'undefined')
                    //         windowReference.location = '/network-lounge/' + id
                    //     let req = { networkLoungeId: item._id }

                    //     _Api(Url.JOIN_LOUNG_PLAN.method, Url.JOIN_LOUNG_PLAN.url, req)
                    //         .then(resp => {
                    //         }).catch(err => { })
                    // }
                    else
                        notify("err", "This lounges is full , you can not join at this time")
                }

            }).catch(err => {

            })

    }


    searchNetowrk = (name, id, coId, item ) => {
        console.log("test123",navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1)
        if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {            
            this.searchNetowrk1()
            
        }
        else{ 
        this. searchNetowrk2()
        }

      
            

    }

    searchNetowrk2 = () => {
        let req = {
            otherUserId: localStorage.getItem('id'),
            type: 3,
            exhibitionId: localStorage.getItem('exhibition')
        }
        _Api(Url.CHECK_PLAN_USER.method, Url.CHECK_PLAN_USER.url, req)
            .then(resp => {
                const { isBuy } = resp?.responseData
                if (isBuy == 0) {
                    this.props.dispatch({ type: 'SHOW_PLAN_USER_POPUP', payload: this.props.match })
                }
                else {
                    window.open('/networking/search', "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                }

            }).catch(err => {

            })
    }






    searchNetowrk1 = () => {
        var windowReference = window.open();
        let req = {
            otherUserId: localStorage.getItem('id'),
            type: 3,
            exhibitionId: localStorage.getItem('exhibition')
        }
        _Api(Url.CHECK_PLAN_USER.method, Url.CHECK_PLAN_USER.url, req)
            .then(resp => {
                const { isBuy } = resp?.responseData
                if (isBuy == 0) {
                    this.props.dispatch({ type: 'SHOW_PLAN_USER_POPUP', payload: this.props.match })
                }
                else {
                    var newWin = window.open('/networking/search', "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                    if (!newWin || newWin.closed || typeof newWin.closed == 'undefined')
                        windowReference.location = '/networking/search'
                    // window.open()
                }

            }).catch(err => {

            })
    }





    render() {
        console.log("this======", this.state)
        const { isLoading, networkLoungList } = this.state
        const isShow = localStorage.getItem("showAll") == "true"
        if (isLoading)
            return (
                <section className="brand-home-banner-bg webhome">
                    {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
                    <div className="centerLoader" >
                        {

                            <Spin size="large" tip="Loading..." indicator={antIcon} />

                        }
                    </div>
                </section>


            )
            else  if (isHideNetwork)
            return <Thankyou />
     else       
            return (
                <section className="brand-home-banner-bg">
                    {/* {this.state.isLoading && <Loader />} */}
                    {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
                    {/*    <ChatBox history={this.props.history} match={this.props.match} />*/}
                    <div className={"cm-section container-fluid"}>

                        {/*<div className={this.state.isCommingSoon?"cm-section container-fluid pos-relative":"cm-section container-fluid"}>*/}
                        {/* {this.state.isCommingSoon && <CommingSoonLayer />} */}
                        <div className="cm-wrapper confreance-detail-parent pt-4">
                            <div className="leftborder-parent  mb-4">
                                <h1 className="title mb-1">Speed Networking</h1>
                                <h6><p>Please also check out the conference networking lounges to continue the conversation from the stages and meet like minded professionals.</p> </h6>
                            </div>
                            <div className="row">

                                <div className="col-md-12">
                                    <div className="image-banner networking-banner">
                                        {/* <img src={require('../../../assets/images/home-networkin.png')} className="img-fluid" /> */}
                                        <img src={require('../../../assets/images/feb-event/networking.jpg')} className="img-fluid" />


                                        <div className="centered">
                                            <span className="d-none d-lg-inline-block">Click Start Networking below to be matched with fellow professionals in our speed networking sessions</span> <br />
                                           

                                            <button className="btn btn-red enter-btn" onClick={() => this.searchNetowrk()}>
                                                Start Networking
                                            </button>
                                            <br />
                                            <span className="d-none d-lg-inline-block">Professional Speed Networking -  daily at 3pm (GMT +1)</span>


                                        </div>






                                    </div>
                                    <span className="d-block d-lg-none mt-3">Click Start Networking below to be matched with fellow professionals in our speed networking sessions</span>
                                    
                                </div>
                            </div>
                        </div>
                        <br />
                        <br />
                        <br />
                        <div className="leftborder-parent  mb-4">
                            <h1 className="title mb-1">Lounges</h1>
                        </div>
                        <div className="cm-wrapper home-networking">
                            <div className="header-text justify-content-end">
                                {networkLoungList && networkLoungList.length > 3 && <BtnRed to="networklounges" text="View All" />}
                            </div>
                            <div className="row">
                                {
                                    networkLoungList && networkLoungList.filter((item, index) => index < 8).map((item, index) => {
                                        return (

                                            <div className="col-xl-3 col-lg-4 col-sm-6 mb-5 conference-lounges networking-lounges cursor-point"
                                            // onClick={() => this.handleJoin(item._id, item)}
                                            >
                                                <div className="card-img-wrapper">
                                                    <div class="offer-badge">

                                                        {item?.onlineJoinUser != 0 && item?.onlineJoinUser && <div class="offer-badge-text ">
                                                            <span><TwoUsers /></span> {item?.onlineJoinUser} People
                                                     </div>
                                                        }


                                                    </div>
                                                    <div className="card">
                                                        {/* <Link to={`/lounge/${item._id}`} > */}
                                                        {/* <LazyLoad> */}
                                                        <img className="img-fluid" src={item.image ? item.image : require("../../../assets/images/placeholder1.png")} />
                                                        {/* </LazyLoad> */}
                                                        {/* </Link> */}
                                                    </div>
                                                </div>
                                                <div className="cm-card-body">
                                                    <Tooltip placement="leftBottom" title={item.loungeName} >
                                                        <h5 class="h5"><strong>{item.loungeName}</strong></h5>
                                                    </Tooltip>
                                                    <p className="des"> {moment(item.startDate).format("DD/MM/YYYY")} <br />

                                                    {getTimeZone(item?.startTime, moment.tz.guess())}</p>

                                                    <>
                                                        <hr />
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-grow-1">
                                                                <CmBtn onClick={() => this.handleJoin(item._id, item)} className="btn-blue" bg='var(--btncolor)' text="Join" />
                                                            </div>
                                                        </div>
                                                    </>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    !this.state.isLoading && networkLoungList.length == 0 &&
                                    <div className="w-100 text-center row nodata m-auto">
                                        <div className="m-auto">
                                            <NoMediaICon />

                                            <h2>No Data Found</h2>
                                        </div>
                                    </div>
                                }
                            </div>

                        </div>

                        
                    </div>
                    <Footer />
                </section>
            )
    }

}


function mapStateToProps(state) {
    const { network } = state?.loading

    return {
        ...state
        //wishList: state.wishList,
        //network: network
    };
}

export default connect(mapStateToProps)(Networking);

