import {
    GET_USER_CART_LIST_SUCCESS,
    GET_USER_CART_LIST_FAILED,
    GET_USER_WISH_LIST_SUCCESS,
    GET_USER_WISH_LIST_FAILED,
    SET_LOCAL_STATE,
    GET_NOTIFICATION_SUCCESS,
    GET_NOTIFICATION_FAILED,
    GET_RECENTSEARCH_DATA,
    GET_BRANDDETAIL_SUCCESS,
    GET_BRANDDETAIL_FAILED,
    SET_LOCAL_STATE_QUESTION
} from "../action/Type";
import BrandDetail from "../../screens/web/brand/BrandDetail";




const InitalState = {
    cartListDetails: [],
    wishListDetail: []
}



const LocalStateIntialValue = {
    filter: {
        placeOfWorkId: "",
        productAndServiceId: "",
        noOfPeopleWorkId: "",
        budgetId: "",
        location: "",
        jobTitleId: "",
        attendingOrLookingforId: "",
        sectorId:"",
        city:"",
        servicesShowcaseId:""
    },
    multiFilter: {
        placeOfWorkId: [],
        jobTitleId: [],
        noOfPeopleWorkId: [],
        productAndServiceId: [],
        budgetId: [],
        location: [],
        attendingOrLookingforId: [],
        sectorId:[],
        city:[],
        userAns:[],
        plainTextAnswer:[]
    },
    connectionFilter: {
        all: false,
        myConnection: true,
        pendingConnection: true,
        requestConnection: true,
        suggestionConnection: true,
        blockConnection: false
    },
    businsessFilter: {
        all: true,
        myConnection: true,
        pendingConnection: true,
        requestConnection: true

    },
    search: ""
}
const BrandProfile = {
    brandId :""

}

// get cart list users
export const cartList = (state = InitalState, action) => {
    if (action.payload)
        //console.log("ddddddddddddddddddddnnnnnnnnnnnnnn", action.payload.cartListDetails)
    switch (action.type) {

        case GET_USER_CART_LIST_SUCCESS:
            return {
                ...state,
                cartListDetails: action.payload.cartListDetails
            }
        case GET_USER_CART_LIST_FAILED:
            return {
                ...state,
            }
        default:
            return { ...state }
    }
}

//get cart list users
export const wishList = (state = {}, action) => {
    const { payload } = action;
    switch (action.type) {
        case GET_USER_WISH_LIST_SUCCESS:
            return {
                ...state,
                ...payload
            }
        case GET_USER_WISH_LIST_FAILED:
            return {
                ...state,
            }
        default:
            return { ...state }
    }

}


export const appLocalState = (state = LocalStateIntialValue, action) => {
    const { data = {} } = action;
    switch (action.type) {
        case SET_LOCAL_STATE:
            return {
                ...state,
                ...data
            }
        case SET_LOCAL_STATE_QUESTION:
            return {
                ...state,
                ...data
            }
        default:
            return  state 
    }

}

export const notificatonList = (state = {}, action) => {
    const { payload } = action;
    switch (action.type) {
        case GET_NOTIFICATION_SUCCESS:
            return {
                ...state,
                ...payload
            }
        case GET_NOTIFICATION_FAILED:
            return {
                ...state,
            }
        default:
            return { ...state }
    }

}

export const recentSearchList = (state = {}, action) => {
    const { payload } = action;
    switch (action.type) {
        case GET_RECENTSEARCH_DATA:
            return {
                ...state,
                ...payload
            }
        default:
            return { ...state }
    }

}

export const brandDetailData = (state = {BrandProfile}, action) => {
    const { payload } = action;
    switch (action.type) {
        case GET_BRANDDETAIL_SUCCESS:
            return {
                ...state,
                ...payload
            }
        case GET_BRANDDETAIL_FAILED:
            return {
                ...state,
            }
        default:
            return { ...state }
    }

}
