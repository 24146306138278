import React, { Component } from 'react';
import { useHistory } from "react-router-dom";
import { SearchIcon } from '../../../SvgIcon';
import { Link } from "react-router-dom";
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { BackBtn } from '../../../components/common/Button'
import InlineError from '../../../components/InlineError';
import { Select, Checkbox } from 'antd';
import Loader from '../../../components/Loader';
import util from '../../../utils/Util.js';
import { notify } from '../../../components/Toaster';
import { TreeSelect } from 'antd';
//import { Item } from 'react-bootstrap/lib/breadcrumb';
const { Option } = Select;
const { SHOW_PARENT, SHOW_CHILD, SHOW_ALL } = TreeSelect;


const format = 'HH:mm';



class ViewQuestionnaire extends Component {
    constructor(props) {
        super(props)
        this.state = {
            //transactionData: []
            particularQuestionData: []
        }
    }
   
    componentDidMount() {
        this.getParticularQuestion();
    }
    getParticularQuestion = () => {
      
        //console.log("propspropsprops", this.props.match.params.id);
        let req = {
            questionId: this.props.match.params.id,
        }
        _Api(Url.GET_PARTICULAR_QUESTION_DATA.method, Url.GET_PARTICULAR_QUESTION_DATA.url, '', `?questionId=${req.questionId}`)
            .then(resp => {
                //console.log("respresprespresp" , resp);
                this.setState({ particularQuestionData: resp.responseData.result });
                //console.log("particularQuestionDataparticularQuestionData" , resp.responseData.result);

            }).catch(err => {
                //alert("radha catch");
                this.setState({ isLoader: false });
                let errorMessage = err.error && err.error.responseMessage || err.message;
                notify("err", errorMessage);
            });
    }



    render() {
        
        let { particularQuestionData } = this.state;

        return (

            <>
                <div className="dash_body_container">
                    {/*<Header />*/}
                    {/*<Sidebar />*/}
                    <div className="body_container">
                        <div className="header-text  mb-4 mt-3">
                            <div className="d-flex align-items-center">
                                <p className="header-icon"><SearchIcon /></p>
                                <h4>Question option details</h4>
                            </div>
                            <BackBtn to="questionnaire" />

                        </div>
                        <div className="row">
                            <div className="col-md-6 offset-sm-3">

                           
                        <div className="rounded-body-form">
                            <form className="cm-form-wrapper add-promo-code">
                                
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <level>Exhibition Name :</level>
                                        <b>{particularQuestionData.exhibitionName}</b>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                        <div className="form-group">
                                            <level>Signup Question :</level>
                                            <b>{particularQuestionData.question}</b>
                                        </div>
                                </div>
                                
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                        <div className="form-group">
                                            <level>Filter Question :</level>
                                            <b>{particularQuestionData.filterQuestion}</b>
                                        </div>
                                </div>
                                
                            </div>
                            


                                <div className="row">
                                    <div className="col-lg-12 ">
                                        <div className="form-group">
                                            <level>Question Type : </level>
                                            
                                            {particularQuestionData.questionType == 1 &&
                                                <b>Text answer</b>
                                                }
                                                {particularQuestionData.questionType == 2 &&
                                                <b>Multiple choice</b>
                                                }
                                                {particularQuestionData.questionType == 3 &&
                                                <b>Single choice</b>
                                                }
                                        </div>
                                    </div>
                                </div>
                                {particularQuestionData.questionType !==1?
                                    particularQuestionData.previousQuestionId&&particularQuestionData.previousQuestionId!==""?
                                        <div className="row">
                                            <div className="col-lg-12 ">
                                                <div className="form-group">
                                                    <level>Option: </level>

                                                        {
                                                            particularQuestionData?.option?.map((item,i)=>(
                                                                <>
                                                                <b><span>({i+1}){item.name},&nbsp;</span></b>
                                                                    <br/>
                                                                    <level>Related Option: </level>
                                                                    {item.value.map((val)=>(
                                                                        <span>{val},&nbsp;</span>
                                                                    ))}
                                                                <br/>
                                                                </>
                                                            ))
                                                        }

                                                </div>
                                            </div>
                                        </div>:
                                    <div className="row">
                                        <div className="col-lg-12 ">
                                            <div className="form-group">
                                            <level>Option: </level>
                                            <b> 
                                               {
                                                  particularQuestionData?.option?.map((item)=>(<span>{item.name},&nbsp;</span>))
                                                }
                                             </b>
                                            </div>
                                        </div> 
                                    </div>

                                :""}
                            </form>

                        </div>
                        
                        </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

} export default ViewQuestionnaire;

