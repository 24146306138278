import React, { Component } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import { Tab, Tabs } from "react-bootstrap";
import { BackBtn } from "../../../components/common/Button";
import { _Api } from "../../../services/Api";
import Url from "../../../services/BaseUrl";
import { notify } from "../../../components/Toaster";
import WebHeader from "../../layout/WebHeader";
import { connect } from "react-redux";

import { None } from "amazon-chime-sdk-js";

// import { Tabs } from 'antd';

let isProd = process.env.REACT_APP_ENV == "prod";

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: [],
    };
  }

  componentDidMount() {
    this.props.dispatch({ type: "PATH_ADD", payload: this.props.match });
    this.getUserProfile();
    this.getList();
  }

  getList = (api, title) => {
    let req = {
      requestUserId: this.props.match.params.id || localStorage.id || "",
    };
    this.setState({ title: title });
    let reqq = {
      page: 1,
      limit: 10,
      connectionType: 1,
      status: "",
      requestUserId: req.requestUserId,
    };
    _Api(
      Url.COMMUNITY_CONNECTION_LIST.method,
      Url.COMMUNITY_CONNECTION_LIST.url,
      reqq
    )
      .then((resp) => {
        console.log(
          "adsfghjklajdqwfgyhuejiwqge",
          resp.responseData?.result?.record
        );
        this.setState({
          DetailList: resp.responseData?.result?.record,
          totalCount: resp.responseData?.result?.totalCount,
        });
      })
      .catch((err) => {
        this.handleError(err);
      });
  };

  getUserProfile = () => {
    let req = {
      requestUserId: this.props.match.params.id || localStorage.id || "",
    };
    _Api(
      Url.GET_USER_PROFILE.method,
      Url.GET_USER_PROFILE.url,
      "",
      `?requestUserId=${req.requestUserId}`
    )
      .then((resp) => {
        this.setState({ userData: resp.responseData.result });
      })
      .catch((err) => {
        this.setState({ isLoader: false });
        let errorMessage =
          (err.error && err.error.responseMessage) || err.message;
        notify("err", errorMessage);
      });
  };

  render() {
    let { userData } = this.state;
    let { companyName } = userData ? userData : "";

    return (
      <>
        {/*<div className="dash_body_container">*/}
        {/*    <Header />*/}
        {/*    <Sidebar />*/}
        <div
          className={
            this.props.userDetail &&
            (this.props.userDetail.userType === 1 ||
              this.props.userDetail.userType === "1")
              ? "dash_body_container"
              : "dash_body_container without-sidenav"
          }
        >
          {this.props.userDetail &&
          (this.props.userDetail.userType === 1 ||
            this.props.userDetail.userType === "1") ? (
            <>
              <Header />
              <Sidebar />
            </>
          ) : (
            <>
              {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
              {/*{    <ChatBox history={this.props.history} match={this.props.match} />}*/}
            </>
          )}
          <div className="body_container profile-body">
            {this.props.match.params.id ? (
              <div className="header-text  mb-4 pt-3">
                <div className="d-flex align-items-center">
                  <h4>{this.props.match.params.id ? companyName : ""}</h4>
                </div>

                {this.props.match.params.id && (
                  <a>
                    {" "}
                    <button
                      onClick={() => this.props.history.goBack()}
                      className="btn btn-light"
                    >
                      <i class="fa fa-arrow-left" aria-hidden="true"></i>Back
                    </button>
                  </a>
                )}
              </div>
            ) : (
              ""
            )}
            <div className="pading-top80">
              <div className="rounded-body card-wrapper mt-0">
                {!this.props.match.params.id && (
                  <Detail data={userData} isFromProfile={true} />
                )}
                {this.props.match.params.id && (
                  <Tabs defaultActiveKey="detail" id="uncontrolled-tab-example">
                    <Tab eventKey="detail" title="Detail">
                      <Detail data={userData} />
                    </Tab>
                    <Tab eventKey="community" title="Community">
                      <Community
                        data={userData}
                        connections={this.state.DetailList}
                        requestUserId={this.props.match.params.id}
                      />
                    </Tab>
                  </Tabs>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
// export default UserProfile;
function mapStateToProps(state) {
  console.log("kdjhsgad", state);
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(UserProfile);

const Detail = (props) => {
  let {
    _id,
    firstName,
    lastName,
    companyName,
    placeOfWork,
    placeOfWorkOther,
    city,
    country,
    phoneNumber,
    email,
    image,
    productAndServiceOfferId,
    jobTitleOther,
    attendingOrLookingforId,
    streetAddress1,
    zipCode,
    placeOfWorkId,
    jobTitleId,
    budgetId,
    noOfPeopleWorkId,
    userType,
    roleId,
    sectorId,
    registrationId
  } = props.data;
  return (
    <>
      <div className="table-wrapper">
        {props.isFromProfile &&
        (props.isFromProfile.userType === 1 ||
          props.isFromProfile.userType === "1") ? (
          <div className="header-text mb-4">
            <div className="d-flex align-items-center">
              <h4>Profile Details</h4>
            </div>
            <Link
              to={
                (props.isFromProfile && `profile/edit`) ||
                `/edit-profile/${_id}`
              }
              className="btn btn-red"
            >
              Edit
            </Link>
          </div>
        ) : (
          <>
            <div className="header-text mb-4">
              <div className="d-flex align-items-center">
                <h4>Profile Details</h4>
              </div>
            </div>
            <Link
              to={
                (props.isFromProfile && `profile/edit`) ||
                `/edit-profile/${_id}`
              }
              className="btn btn-red float-right"
            >
              Edit
            </Link>
          </>
        )}
        <div className="profile-icon-wrapper">
          <img
            className="img-fluid"
            src={image || require("../../../assets/images/login_user.png")}
          />
          <input type="file" />
        </div>

        <p className="text-center mt-2 mb-4">
          <strong>
            {firstName} {lastName}
          </strong>
        </p>
        <div className="vertical-table table-responsive user-profile">
          <ul className="detail_row">
            <li>Company Name</li>
            <li>{companyName}</li>
            <li>Email Address</li>
            <li>{email}</li>
          </ul>
          <ul className="detail_row">
          <li>Phone Number</li>
            <li>{phoneNumber && `+${phoneNumber}`}</li>
            <li>Country</li>
            <li>{country}</li>
          </ul>
          <ul className="detail_row">
          <li>City</li>
            <li>{city}</li>
            <li>Sector</li>
            <li>
              {sectorId?.map((item) => (
                  <span>{`${item.name}, ${""}`}</span>
              ))}
            </li>
           
          </ul>

          <ul className="detail_row">
          <li>Place of Work</li>
            <li>
              {placeOfWork?.map((item) => (
                  <span>{`${item.name}, ${""}`}</span>
              ))}
            </li>
            <li>Job Role </li>
            <li>
              {roleId?.map((item) => (
                  <span>{`${item.name}, ${""}`}</span>
              ))}
            </li>
          
          </ul>
          <ul className="detail_row">
            {registrationId?
                <>
            <li>GDC/GMC/NMC/GPhC number</li>
            <li>
            {registrationId}
            </li>
            </>
                :""}

          </ul>
        </div>
      
      </div>

      {/* {userType !== 1 && <div className="table-wrapper">

                <div className="header-text  mb-4 mt-3">
                    <h4>Work Details</h4>
                </div>
                <div className="vertical-table table-responsive">
                    <ul className="detail_row">
                        <li>Place of Work</li>
                        <li>{placeOfWorkId && placeOfWorkId.categoryTitle || placeOfWorkOther}</li>
                        <li>No. of People Work With</li>
                        <li>{noOfPeopleWorkId && noOfPeopleWorkId.categoryTitle}+</li> */}
      {/* <li>Product & Service you offer</li>
                            <li>{productAndServiceOfferId && productAndServiceOfferId.map(e => e["categoryTitle"]).join(",")}</li> */}

      {/* </ul>
                    <ul className="detail_row">
                        <li>Job Title</li>
                        <li>{jobTitleId && jobTitleId.categoryTitle || jobTitleOther}</li> */}
      {/* <li>Why are you attending & what are you looking for?</li>
                        <li>{attendingOrLookingforId && attendingOrLookingforId.map(e => e["categoryTitle"]).join(",")}</li> */}
      {/* </ul> */}
      {/* <ul className="detail_row">
                        <li>No. of People Work With</li>
                        <li>{noOfPeopleWorkId && noOfPeopleWorkId.categoryTitle}+</li>
                        <li>What is your budget to spend in the next 6 months?</li>
                        <li>{budgetId && budgetId.categoryTitle}</li>
                    </ul>
                    */}
      {/* </div>

            </div>
            } */}
    </>
  );
};

const Community = (props) => {
  // let userType = props.userDetail.userType

  const downloadCsv = () => {
    let req = {
      requestUserId: props.requestUserId,
    };
    _Api(Url.DOWNLOAD_CSV.method, Url.DOWNLOAD_CSV.url, req)
      .then((resp) => {
        window.open(resp.responseData.result, "_blank");
      })
      .catch((err) => {
        this.handleError(err);
      });
  };

  return (
    <>
      <div className="header-text mb-4">
        <h4>
          User Connection
          <i
            class="fa fa-download"
            style={{ fontSize: "30px", marginLeft: "30px", cursor: "pointer" }}
            aria-hidden="true"
            title="Download csv"
            onClick={downloadCsv}
          ></i>
        </h4>
      </div>
      <div className="row">
        {props?.connections?.map((item, index) => {
          return (
            <div className="col-md-4">
              <div className="card community-card">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="img-wrapper">
                      <img
                        className="img-fluid"
                        src={require("../../../assets/images/login_user.png")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-8 d-flex flex-column">
                    <h4 className={"cursor-point"}>
                      <strong>
                        {item?.userData?.firstName} {item?.userData?.lastName}
                      </strong>
                    </h4>
                    <span>
                      <small className="gray-color">
                        {item?.userData?.JobTitle}
                      </small>
                    </span>
                    <h5 className="mt-auto">
                      <strong>{item?.userData?.companyName}</strong>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
