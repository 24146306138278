import React from 'react';
import { Tooltip } from 'antd';
import { Link } from "react-router-dom";
import {NewBadges ,Brands } from '../../../SvgIcon';


const Spotlight = (props) => {
    const { data } = props
    console.log("kjdshjkfl",data)
    return (
        <div className="carousel-wrapper featured-brands showcase mt-0 viewbrand">
            <div className="row mb-3">
                {
                    // 1st-brand 2-product 3-offer 4-new product
                    data?.map((item, index) => {
                        if (item.type == 1)
                            return ( 
                            <div key={index} className=" product-showcase-devide roduct-showcase-devide-brand">
                                <Link to={`/business/${item?.data?._id}/${item?.data?.activeCompany}`}  >
                                    <div 
                                    // onClick={(e) => this.onClickBrand(item?._id, e)}
                                    >

                                        <div className="card brand-card new-product mb-5">

                                            <img className="img-fluid mb-4" src={item?.data?.coverImage || require('../../../assets/images/profile-placeholder.png')} />


                                            <div className=" brand-card-body cursor-point" >
                                                <div className="brand-img-wrapper">

                                                    <img className="img-fluid" src={item?.data?.logoImage || require('../../../assets/images/placeholder.png')} />

                                                </div>
                                                <Tooltip placement="top" title={item?.data?.companyName}>
                                                       
                                                <h4 className="wrap-p1 w-100">{item?.data?.companyName}</h4>
                                                </Tooltip>
                                                {/* <p>{item?.data?.about}</p> */}
                                            </div>
                                        </div>

                                    </div>
                                </Link>
                            </div>
                            )
                        else if (item.type == 2)
                            return (
                                <div key={index} className=" product-showcase-devide">
                                <Link to={`/web-job-details/${item?.data?._id}`} >
                                    <div className="card brand-card new-product mb-5">
                                        {/* <LazyLoad> */}
                                            <img className="img-fluid" src={item?.data?.image || require('../../../assets/images/placeholder.png')} />
                                        {/* </LazyLoad> */}
                                       
                                        {item?.isNewProduct == 1 && <div className="newBadge">
                                                <p> New<span className="traingle"><NewBadges /></span></p>
                                            </div>}
                                        {/* <div className="top-right">
                                            {
                                                < div className={(item?.data?.wishListData) && "blue-circle red-circle" || "blue-circle"} onClick={(e) => this.addToWishList(e, 'product', item?.data?._id, item?.data?.wishListData && "REMOVE_WISHLIST" || "ADD_WISHLIST")}>
                                                    {this.state[`isWishlistLoader${item?.data?._id}`] &&
                                                        <Spinner className="text-center" size="xl" animation="border" variant="primary" /> || <i className="fa fa-heart-o" ></i>}

                                                </div>}

                                        </div> */}
                                        <hr />
                                        <div className="action_group text-center">
                                            <div className="w-100">
                                                <Tooltip placement="top" title={item?.data?.productName}>
                                                    <h6 className="wrap-p2 w-100">{item?.data?.productName} <br /></h6>
                                                    </Tooltip>    
                                                    <hr className="w-100 mb-2"/>
                                                    {item?.data?.brandId?.companyName &&
                                                    <Tooltip placement="top" title={item?.data?.brandId?.companyName}>
                                                        <h6 className="wrap-p1 w-100"><i><Brands /></i>{item?.data?.brandId?.companyName}</h6>
                                                        </Tooltip>
                                                    }
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            )
                        else if (item.type == 3)
                            return (
                                <div key={index} className=" product-showcase-devide">
                                    <Link to={`/web-training-details/${item?.data._id}`} >
                                        <div className="card brand-card new-product mb-5">
                                            {/* <LazyLoad> */}
                                            <img className="img-fluid" src={item?.data?.image || require('../../../assets/images/placeholder.png')} />
                                            {/* </LazyLoad> */}
                                            {/* {item?.data?.brandId?.companyName && <div className="top-left">
                                                <button className="btn btn-card">{item?.data?.brandId?.companyName}</button>
                                            </div>
                                            } */}
                                            <div className="newBadge offer">
                                                <p> Offer<span className="traingle"><NewBadges /></span></p>
                                            </div>
                                            <hr />
                                            <div className="action_group text-center">
                                              <div className="w-100">
                                              <Tooltip placement="top" title={item?.data?.name}>
                                                    <h6 className="wrap-p2 w-100">{item?.data?.name}<br /></h6>
                                                    </Tooltip>
                                                <hr className="w-100 mb-2"/>
                                                {item?.data?.companyName && 
                                                <Tooltip placement="top" title={item?.data?.companyName}>
                                                   <h6 className="wrap-p1 w-100"><i><Brands /></i>{item?.data?.companyName}</h6>
                                                </Tooltip>
                                                }
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                            )
                        else if (item.type == 4)
                            return (
                                <div key={index} className=" product-showcase-devide">
                                    <Link to={`/web-job-details/${item?.data?._id}`} >
                                        <div className="card brand-card new-product mb-5">
                                            {/* <LazyLoad> */}
                                            <img className="img-fluid" src={item?.data?.image || require('../../../assets/images/placeholder.png')} />
                                            {/* </LazyLoad> */}
                                            <div className="newBadge">
                                                <p> New<span className="traingle"><NewBadges /></span></p>
                                            </div>
                                            <hr />
                                            <div className="action_group text-center">
                                            <div className="w-100">
                                                <Tooltip placement="top" title={item?.data?.productName}>
                                                   
                                                    <h6 className="wrap-p2 w-100">{item?.data?.productName}<br /></h6>
                                                
                                                </Tooltip>
                                                <Tooltip placement="top" title={item?.data?.brandId?.companyName}>
                                                    <hr className="w-100 mb-2"/>
                                                    {item?.data?.brandId?.companyName &&
                                                    <h6 className="wrap-p1 w-100"><i><Brands /></i>{item?.data?.brandId?.companyName}</h6>
                                                    }
                                                </Tooltip>
                                                </div>     
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                            )
                    })
                }

            </div>
        </div>
    );
}

export default Spotlight;

