import React, { Component } from 'react';
import CommingSoonLayer from "../../components/common/CommingSoonLayer";
import { Modal, Button, Checkbox } from 'antd';
import CommonModal from '../layout/CommonModal';
import { _Api } from '../../services/Api';
import Url from '../../services/BaseUrl';
import { notify } from '../../components/Toaster';
import { Menu, Dropdown, Descriptions, Empty } from 'antd';
import Cookies from 'js-cookie'
import { Hert } from '../../SvgIcon';
import Store from '../../redux/store/Store';

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subscriptionListData: []
        }
    }


    handleCancel = e => {
        console.log(e);
        this.props.scope.setState({ visible: false })
        Store.dispatch({ type: 'HIDE_PLAN_USER_POPUP' })  
    }
    onClickPurchase = (type) => {
        //
        if (type != "Search") {
            Store.dispatch({ type: 'HIDE_PLAN_USER_POPUP' })  
            this.props.scope.setState({ visible: false });
            this.props.scope.props.history.push("/plan");

        }
        else {
            this.props.scope.setState({ visible: false, isOpen: true }, () => this.getSubsriptionList());

        }


    }
    handleCommonCancel = e => {
        this.props.scope.setState({ isOpen: false })
    }
    componentDidMount() {
        this.getSubsriptionList();

    }
    getSubsriptionList = () => {
        _Api(Url.SUBSCRIPTION_PLAN_LIST.method, Url.SUBSCRIPTION_PLAN_LIST.url, `?exhibitionId=5f058d7073866056602d2ae5`)
            .then(resp => {
                let responseData = resp.responseData.result;
                this.setState({ subscriptionListData: responseData.data });

            }).catch(err => {
                this.handleError(err);
            });
    }

    handleError(err) {
        this.setState({ isLoader: false });
        let errorMessage = err.error && err.error.responseMessage || err.message;
        notify("err", errorMessage);
    }
    handleOnClick = () => {
        let { dollarAmount, euroAmount, selectedId } = this.state;
        console.log("jshdhgjk", this.state)


        if (selectedId) {
            Cookies.set("amt_search_plan", JSON.stringify({ usd: dollarAmount, gbp: euroAmount, searchPurchased: 0, searchId: selectedId, repayment: this.props.scope.state.repayment }))
            
            this.props.scope.props.history.push(`/payment/search`);
            this.handleCancel()
        }
        else {
            notify("error", "Please select any seacrh plan");
        }

    }
    onCheckedboxClick = (data) => {
        this.setState({ selectedId: data._id, [`radio${data._id}`]: true, dollarAmount: data.dollarAmount, euroAmount: data.euroAmount });

    }

    render() {
        let { subscriptionListData } = this.state;
        console.log("skjhgdsjksa", {subscriptionListData})

        return (

            <div>
                <Modal
                    footer={null}
                    centered
                    onCancel={this.handleCancel}
                    className="paymentpopup searchpopup"
                    visible={this.props.visible}
                    width="700"
                >
                    <div className="rounded-textbox-form mt-5 mb-4 w-100" >
                        <div className="row">
                            <div className="col-md-12 ">
                                <div className="form-group">
                                    <h2><b>Upgrade to Brand Showcase</b></h2>
                                    <h2><b>or Premium Brand Showcase</b></h2>


                                    <div className="form-group text-center">
                                        <button type="button" class="btn btn-red btn-label" onClick={() => this.onClickPurchase("Showcase")}>&nbsp;&nbsp;&nbsp;Purchase Brand Showcase&nbsp;&nbsp;&nbsp;</button>
                                    </div>
                                </div>
                                <p className="text-center mt-5 mb-5">
                                    <span className="or ">OR</span>
                                </p>
                                <div className="form-group searchprice">
                                    <h2 className=" pos-relative text-center">
                                        <b>Plans</b>
                                        <span className="heading-after-border heading-after-border-center">
                                            <span className="heart"><Hert /></span></span>
                                    </h2>
                                    <div className="row no-gutters mt-5">
                                        {subscriptionListData && subscriptionListData.map((item, index) => (
                                            < div class="col-md-6">
                                                <label className="cm_container ml-0 mb-3">
                                                    <b>{item.planName}</b>
                                                    <input class="form-check-input"
                                                        type="radio"
                                                        checked={this.state.selectedId == item._id}
                                                        onClick={() => this.onCheckedboxClick(item)}

                                                    />
                                                    <span className="radio-checkmark"></span>
                                                </label>        
                                                
                                                <p><strong>Price (In USD)</strong>&nbsp; &nbsp; {item?.dollarAmount && `$${item?.dollarAmount}`}</p>
                                                <p><strong>Price (In GBP)</strong>&nbsp; {item?.euroAmount && `£${item?.euroAmount}`}</p>
                                                <p><strong>Valid For</strong>&nbsp; {item?.searchPlan == 1 && "One Day" || item?.searchPlan == 2 && "Exhibition"}</p>

                                                                    <ul className="pl-0">
                                                                    {item?.numberOfSearches &&<li> <i class="fa fa-circle" aria-hidden="true"></i> Number of Searches:  {item?.numberOfSearches}</li>}
                                                                    {item?.limitOfNetworkLounge &&<li className="text-nowrap"><i class="fa fa-circle" aria-hidden="true"></i> Number of Network Lounge:  {item?.limitOfNetworkLounge}</li>}
                                                                  {item?.limitOfCalls && <li><i class="fa fa-circle" aria-hidden="true"></i> Number of Call:  {item?.limitOfCalls}</li>}
                                                                  {item?.limitOfChat &&  <li><i class="fa fa-circle" aria-hidden="true"></i> Number of Chat:  {item?.limitOfChat}</li>}
                                                                  {item?.limitOfLounge &&  <li><i class="fa fa-circle" aria-hidden="true"></i> Number of Lounges:  {item?.limitOfLounge}</li>}
                                                                    <li className="text-nowrap" ><i class="fa fa-circle" aria-hidden="true"></i> Download Connection:  {'Yes'}</li>
                                                                    </ul> 

                                               {/* {item?.numberOfSearches && 
                                               {item?.limitOfNetworkLounge && }
                                               {item?.limitOfCalls && }
                                               {item?.limitOfChat &&}
                                               {item?.limitOfLounge && } */}
                                               { }
                                            </div>))
                                        }
                                    </div>

                                    <div className="form-group text-center">
                                        <button type="button" class="btn btn-red btn-label" onClick={this.handleOnClick}>&nbsp;&nbsp;Buy Plan&nbsp;&nbsp;</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </Modal>
            </div>

        )
    }
}
export default Search;