import React, { Component } from 'react';
import { Hert } from '../../SvgIcon';
import { Link } from "react-router-dom"
import { Modal, Select } from 'antd';
import { notify } from '../../components/Toaster';
import { userActions } from "../../redux/action";
import Loader from '../../components/Loader'
//Api
import { login } from '../../redux/action/Auth'
import { getRole } from '../../redux/action/Auth'
import { _Api } from '../../services/Api';
import Url from '../../services/BaseUrl'
//validation
import { validateEmail } from '../../components/Validation';
//validation message
import InlineError from '../../components/InlineError'

// components
import {
    MyAlert,
    MySpinner
} from "../../components/index"


import Cookies from 'js-cookie';
import { connect } from "react-redux";
import {
    TOGGLE_LOGIN,
    TOGGLE_FORGOT,
    TOGGLE_RESET,
    TOGGLE_SIGNUP
} from "../../redux/action";

const { Option } = Select;


class Welcome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: true
        }
    }

    componentDidMount() {

    }


    update = () => {
        let self = this

        _Api(Url["UPDATED_USER_KEY"].method, Url["UPDATED_USER_KEY"].url)
            .then(resp => {
                self.props.dispatch(userActions.profile())

            }).catch((err) => {

            })
    }
    handleClose = () => {
        this.setState({ isShow: false })
        this.update()
    }

    render() {

        console.log("lkjvjhxcklv", this.props.setting.eventQuestionnaire, this.props.profile?.profileInfo.isNewUser)

        return (
            <>
                <Modal
                    wrapClassName="signup_parent_modal"
                    className="cm_popup signup_modal "
                    //cm_popup term-services
                    visible={this.state.isShow}
                    // onOk={this.handleOk}
                    onCancel={this.handleClose}
                    footer={null}
                    centered
                    maskClosable={false}
                >
                    <div className="sign-modal-content">
                        <div className="row align-items-center text-center">
                            <div className="col-md-12" >
                                <div className="logo">
                                    <img src={require('../../assets/images/splash.png')} className="img-fluid splash-img" />
                                    <img src={require('../../assets/images/logo.png')} className="img-fluid logo-img" />
                                </div>

                                <h3 className="auth-heading pos-relative mt-n4 h1">
                                    <strong>Your dream job starts here.</strong>
                                    <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                </h3>
                                <h5 className="mt-5 font-weight-normal pl-lg-4 pr-lg-4">
                                Whilst we are busy building the jobs and training programmes you can in the meantime upload your CV and career profile by clicking on your name.<br/>
                                You will receive an email once the portal is live.
                               </h5>
                                <p className=" pl-lg-4 pr-lg-4">
                                    For information on how to navigate the platform, please visit the '?' in the top right of your screen.
                               </p>
                            </div>
                        </div>

                    </div>
                </Modal>

            </>

        )
    }
}

function mapStateToProps(state) {
    const { profile } = state

    return {
        ...state,
        profile: profile

    };
}

export default connect(mapStateToProps)(Welcome);