import React, { Component } from 'react';

import {Link} from "react-router-dom"
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import Footer from "../../layout/Footer";
import LazyLoad from 'react-lazy-load';


import moment from "moment-timezone"

import {getTimeZone} from "../../../utils/DateZone"

import { Calender, NoMediaICon, Ticket } from "../../../SvgIcon";

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import Thankyou from "../../home/HomeCommingSoon"

import Const from "../../../services/Const"



const antIcon = <LoadingOutlined  style={{ fontSize: 72 }} spin />;

const {isHideConference, tab2Body} = Const

console.log("sghdjks", {Const})

class Conference extends Component {

    static getDerivedStateFromProps(nextProps, prevState) {
        // do things with nextProps.someProp and prevState.cachedSomeProp
        const { conference } = nextProps
        if (conference && conference != prevState?.conference) {           
            return {              
                 list: conference, isLoading: false ,
                 conference:conference              
            }
        }
        return null

    }


    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.carousel = React.createRef();

        this.state = {
            isCommingSoon: true,
            isLoading: true,
            limit:100,
            list: [],
            snNo: 0,
            page: 1,
        }

    }


    next = () => {
        this.carousel.next();
    }
    previous = () => {
        this.carousel.prev();
    }


    componentDidMount() {
        // this.getConfrence()
        this.props.dispatch({ type: 'PATH_ADD', payload:this.props.match })
    }




    getConfrence = () => {
        let { page, limit } = this.state
        _Api(Url.GET_CONFERENCE_WEB.method, Url.GET_CONFERENCE_WEB.url, '', `?exhibitionId=${this.props.loading?.exhibitionId}`)
            .then(resp => {
                console.log('confirencelist', resp.responseData.result)
                this.setState({ list: resp.responseData.result, isLoading: false })
            }).catch(() => {
                this.setState({ isLoading: false })

            })

    }



    onPressBtn = (page) => {
        this.setState({ page, snNo: page - 1, list: [] }, () => {
            this.getConfrence()
        })

    }


    


    render() {
        const { list,  isLoading,} = this.state
        const isShow = localStorage.getItem("showAll") == "true"
        console.log("asdjks", list)

        if (isLoading)
            return (
                <section className="brand-home-banner-bg webhome">
                    {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
                    <div className="centerLoader" >
                    {
                            // isProd ? 
                            // <h1><b>This page will be live on the 8th September, 2020. </b></h1>
                            // :
                            <Spin size="large" tip="Loading..." indicator={antIcon} />

                        }                      
                       
                    </div>
                </section>


            )
            else  if (isHideConference)
            return <Thankyou />
     else
        return (

            <section className="Home-banner-bg">
                {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
             {/*{      <ChatBox history={this.props.history} match={this.props.match} />}*/}
                <div className={this.state.isCommingSoon ? "cm-section container-fluid brandhome pos-relative" : "cm-section container-fluid"}>
                    {/* <div  className={this.state.isCommingSoon?"cm-section zigzek pos-relative":"cm-section zigzek"}> */}
                    {/* {this.state.isCommingSoon && <CommingSoonLayer />} */}
                    <div className="cm-wrapper">
                        <div className="header-text">
                          <h1 className="title">{tab2Body.h1}</h1>
                        </div>
                        <div className="row ">                          
                            {
                                list.map((item) => {
                                    return (

                                        <div className="col-sm-6 col-md-4 col-xl-3">
                                            <div className="conference-block">
                                            <Link to={`/seminar/${item?._id}`}>
                                                <div className="img">
                                                <LazyLoad>
                                                   
                                                    <img className="img-fluid mb-4" src={item?.coverImage ? item?.coverImage : require('../../../assets/images/placeholder.png')} />
                                                </LazyLoad>
                                                    <div className="free-paid">
                                                    
                                                        {
                                                            item?.euroAmount == 0 ? <button className="btn btn-card live"><span><Ticket /></span>FREE</button> : <button className="btn btn-card live"><span><Ticket /></span>{`£${item?.euroAmount}`}</button>
                                                        }
                                                    </div>
                                                </div>
                                                </Link>
                                                <div className="text">
                                                    <div className="leftborder-parent">
                                                        <h4>{item?.conferenceName}</h4>
                                                        <h6><span><Calender /></span>
                                                         <span>{moment(item?.startDate).format('MMM DD')}, {getTimeZone(item?.startDate, moment.tz.guess())}  to  {moment(item?.endDate).format('MMM DD')}, {getTimeZone(item?.endDate, moment.tz.guess())} </span>   {/* Sept 27, 5:30PM to Sept 28, 6:00PM EST  */}
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    )
                                })
                            }
                             {
                                !isLoading && list.length == 0 &&
                                <div className="w-100 text-center row nodata m-auto">
                                    <div className="m-auto">
                                        <NoMediaICon />
                                        
                                        <h2>No Data Found</h2>
                                    </div>
                                </div>
                            }
                            {/* {
                                list.length != 0 &&
                                <Pagination
                                    totalPage={Math.ceil(this.state.totalCount / 10)}
                                    currentPage={this.state.page}
                                    onPressBtn={this.onPressBtn}
                                />
                            } */}

                        </div>                        
                    
                    </div>
                    
                </div>
                <Footer />
              </section>




        )
    }

}

function mapStateToProps(state) {
    const { conference } = state?.loading

    return {
        wishList: state.wishList,
        conference: conference
    };
}

export default connect(mapStateToProps)(Conference);




