import React from "react"

import { Alert, Spinner } from "react-bootstrap"


// user for alert
export const MyAlert = (props) => {
    return (
        <Alert variant={props.type ? 'success' : 'danger'}>
            {props.message}
        </Alert>
    )
}


// my spinner
export const MySpinner = (props) => <Spinner size="sm" animation="border" color="#fff" className="ml-2 mr-2"/>

export const MdLoader = (props) => <Spinner className ="loader-connection" size="xl" animation="border" />



