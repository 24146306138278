import React, { Component } from 'react';
import WebHeader from '../layout/WebHeader';
import { Tabs } from 'antd';
import Footer from "../../screens/layout/Footer";
import { Filter, SearchIcon, ZoomVideo, NoMediaICon, NewBadges, Brands } from "../../SvgIcon";
import { Link } from 'react-router-dom';
import { _Api } from '../../services/Api';
import Url from '../../services/BaseUrl';
import { notify } from '../../components/Toaster';
import { getWishList } from '../../redux/action';
import { connect } from "react-redux";
import { Alert, Spinner } from "react-bootstrap"
import { Tooltip } from 'antd';

import Thankyou from "../home/HomeCommingSoon" 

const { TabPane } = Tabs;
class CartView extends Component {
   constructor(props) {
      super(props);
      this.state = {
         isCommingSoon: true
      }
   }

   componentDidMount() {
      this.props.dispatch({ type: 'PATH_ADD', payload:this.props.match })
      this.props.dispatch(getWishList())
   }

   /** brands start */
   addToWishList = (category, id, e) => {
      let self = this;
      this.setState({ [`isWishlistLoader${id}`]: true });
      let params = category == "offer" ? {offerId: id} : category == "brand" ? { brandId: id } : { productId: id };
      let { method, url } = Url["REMOVE_WISHLIST"];
      _Api(method, url, params)
         .then(resp => {
            self.props.dispatch(getWishList());
            this.setState({ [`isWishlistLoader${id}`]: false });
         }).catch((err) => {
            this.handleError(err);
         })
   }
   handleError(err) {
      let errorMessage = err.error && err.error.responseMessage || err.message;
      notify("err", errorMessage);
   }
   onClickBrand = id => {
      this.props.history.push(`/business/${id}`);
   }
   render() {
      let { wishList = {} } = this.props;
      let { productList = [], brandList = [], offerList = [] } = wishList;
      console.log("jdjaskdjkaskl", {offerList})



      const isShow = localStorage.getItem("showAll") == "true"

     
      return (
         <section className="Home-banner-bg">
           {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
           {/* <ChatBox history={this.props.history} match={this.props.match} />*/}
            <div className={this.state.isCommingSoon ? "cm-section container-fluid brandhome pos-relative" : "cm-section container-fluid"}>
               <div className="cm-wrapper">
                  <div className="row">
                     <div className="col-md-12">
                        <Tabs className="mt-5" tabBarStyle={{ "Border": "none" }} centered>
                           <TabPane tab="Jobs" key="1">
                             
                           { 
                           <p  onClick={(e) =>  this.props.dispatch(getWishList(1,1))} className="downloadbtn text-center cursor-point">
                              <span>Download jobs
                           <i class="fa fa-download" style={{ fontSize: '14px', marginLeft: '11px', cursor: 'pointer' }} aria-hidden="true"  title="Download csv"  ></i>
                           </span>
                           </p> 
                           // <i class="fa fa-download" style={{ fontSize: '30px', marginLeft: '30px', cursor: 'pointer' }} aria-hidden="true"  ></i>
                           }
                              <div className="tab-product">
                                 <div className="featured-brands showcase mt-5">
                                    <div className=" row mb-3">
                                    {console.log("kjhgfghj", productList)}
                                       {
                                          productList.map(item => (
                                             
                                             <div className="six-div">
                                                <div className="card brand-card mb-5 new-product">
                                                   <Link to={`/web-job-details/${item?.productId?._id}`} ><img className="img-fluid" src={item?.productId?.image || require('../../assets/images/placeholder.png')} /></Link>
                                                   {item?.productId?.companyName && <div className="top-left">
                                                      <button className="btn btn-card">{item?.productId?.companyName}</button>
                                                   </div>
                                                   }
                                                    {item?.productId?.isNewProduct == 1 && <div className="newBadge">
                                                <p> New<span className="traingle"><NewBadges /></span></p>
                                            </div>}
                                             {/* <Tooltip placement="bottom" title={item?.productId?.companyName}>
                                                <div className="top-left">
                                                      {item?.productId?.companyName && <Link to={`/brand/${item?.brandId?._id}`} className="btn btn-card">{item?.brandId?.companyName}</Link>}
                                                </div>
                                             </Tooltip> */}
                                                   <div className="top-right">
                                                      {
                                                         < div className={"blue-circle red-circle"} onClick={(e) => this.addToWishList('product', item?.productId?._id, e)}>
                                                            {this.state[`isWishlistLoader${item?.productId?._id}`] &&
                                                               <Spinner className="text-center" size="xl" animation="border" variant="primary" /> || <i className="fa fa-heart-o" ></i>}

                                                         </div>}
                                                   </div>
                                                   <hr />
                                                   <div className="action_group ">
                                                            <Tooltip placement="top" title={item?.productId.productName}>
                                                                <h6 className="wrap-p2 w-100">{item?.productId.productName} <br />
                                                                    {/* <span className="blue">${item.price ? item.price : 0}</span> */}
                                                                </h6>
                                                                <hr className="w-100 mb-2" />
                                                                {item?.brandData?.companyName &&
                                                                    <h6 className="wrap-p2 w-100"><i><Brands /></i>{item.brandData?.companyName}</h6>
                                                                }
                                                            </Tooltip>


                                                        </div>
                                                </div>
                                             </div>
                                          ))
                                       }
                                       {
                                          productList?.length == 0 && <div className="w-100 text-center row nodata m-auto">
                                             <div className="m-auto">
                                                <NoMediaICon />
                                                
                                                <h2>No job in  Favourites</h2>
                                                <Link to="/job" className="btn btn-primary">Discover jobs now</Link>
                                             </div>
                                          </div>
                                       }
                                    </div> </div>

                              </div>
                              
                           </TabPane>




                           <TabPane tab="Training" key="2">
                           {
                           //  <i class="fa fa-download" style={{ fontSize: '30px', marginLeft: '30px', cursor: 'pointer' }} aria-hidden="true" title="Download csv"  ></i>}
                           
                           <p  onClick={(e) =>  this.props.dispatch(getWishList(1,3))} className="downloadbtn text-center cursor-point">
                              <span>Download Training
                           <i class="fa fa-download" style={{ fontSize: '14px', marginLeft: '11px', cursor: 'pointer' }} aria-hidden="true"  title="Download csv"  ></i>
                           </span>
                           </p> 
                           }                     
                              <div className="tab-product">
                                 <div className="featured-brands showcase mt-5 new-product">
                                    <div className=" row mb-3">
                                    {console.log("kjhgfghj", productList)}
                                       {
                                          offerList.map(item => (
                                             
                                             <div className="six-div">
                                                <div className="card brand-card mb-5">
                                                   <Link to={`/web-training-details/${item?.offerId?._id}`} ><img className="img-fluid" src={item?.offerId?.image || require('../../assets/images/placeholder.png')} /></Link>
                                                   {item?.offerId?.companyName && <div className="top-left">
                                                      <button className="btn btn-card">{item?.offerId?.companyName}</button>
                                                   </div>
                                                   }
                                             {/* <Tooltip placement="bottom" title={item?.productId?.companyName}>
                                                <div className="top-left">
                                                      {item?.productId?.companyName && <Link to={`/brand/${item?.brandId?._id}`} className="btn btn-card">{item?.brandId?.companyName}</Link>}
                                                </div>
                                             </Tooltip> */}
                                                   <div className="top-right">
                                                      {
                                                         < div className={"blue-circle red-circle"} onClick={(e) => this.addToWishList('offer', item?.offerId?._id, e)}>
                                                            {this.state[`isWishlistLoader${item?.offerId?._id}`] &&
                                                               <Spinner className="text-center" size="xl" animation="border" variant="primary" /> || <i className="fa fa-heart-o" ></i>}

                                                         </div>}
                                                   </div>
                                                   <hr />
                                                   <div className="action_group ">
                                                            <Tooltip placement="top" title={item?.offerId.name}>
                                                                <h6 className="wrap-p2 w-100">{item?.offerId.name} <br />
                                                                    {/* <span className="blue">${item.price ? item.price : 0}</span> */}
                                                                </h6>
                                                                <hr className="w-100 mb-2" />
                                                                {item?.brandData?.companyName &&
                                                                    <h6 className="wrap-p2 w-100"><i><Brands /></i>{item.brandData?.companyName}</h6>
                                                                }
                                                            </Tooltip>


                                                        </div>
                                                </div>
                                                </div>
                                             
                                          ))
                                       }
                                       {
                                          offerList?.length == 0 && <div className="w-100 text-center row nodata m-auto">
                                             <div className="m-auto">
                                                <NoMediaICon />
                                                
                                                <h2>No training in  Favourites</h2>
                                                <Link to="/trainings" className="btn btn-primary">Discover training now</Link>
                                             </div>
                                          </div>
                                       }
                                    </div> </div>

                              </div>
                              
                           </TabPane>































                           
                           <TabPane tab="Businesses" key="3">
                           {<p  onClick={(e) =>  this.props.dispatch(getWishList(1,2))} className="downloadbtn text-center cursor-point">
                              <span>Download Businesses
                           <i class="fa fa-download" style={{ fontSize: '14px', marginLeft: '11px', cursor: 'pointer' }} aria-hidden="true"  title="Download csv"  ></i>
                           </span>
                           </p> }
                           {/* { <i class="fa fa-download" style={{ fontSize: '30px', marginLeft: '30px', cursor: 'pointer' }} aria-hidden="true" title="Download csv" onClick={(e) =>  this.props.dispatch(getWishList(1,2))} ></i>} */}
                              <div className=" row mb-3 row viewbrand mt-5">
                                 {
                                    brandList.map(item => (
                                       <div className="four-div">

                                          <div className="card brand-card mb-5 cursor-point">
                                             <img className="img-fluid mb-4" onClick={() => this.onClickBrand(item?.brandId?._id)} src={item?.brandId?.coverImage || require('../../assets/images/profile-placeholder.png')} />
                                             <Tooltip placement="bottom" title={item?.brandId?.companyName}>
                                                <div className="top-left">
                                                      {item?.brandId?.companyName && <Link to={`/business/${item?.brandId?._id}`} className="btn btn-card">{item?.brandId?.companyName}</Link>}
                                                </div>
                                             </Tooltip>
                                             <div className="top-right">
                                                {
                                                   < div className={"blue-circle red-circle"} onClick={() => this.addToWishList('brand', item?.brandId?._id)}>
                                                      {this.state[`isWishlistLoader${item?.brandId?._id}`] &&
                                                         <Spinner className="text-center" size="xl" animation="border" variant="primary" /> || <i className="fa fa-heart-o" ></i>}

                                                   </div>}

                                             </div>
                                             <div className=" brand-card-body cursor-point" onClick={() => this.onClickBrand(item?.brandId?._id)}>
                                                <div className="brand-img-wrapper">
                                                   <img className="img-fluid" src={item?.brandId?.logoImage || require('../../assets/images/placeholder.png')} />
                                                </div>
                                                <h4>{item?.brandId?.companyName}</h4>                                               
                                             </div>
                                          </div>
                                       </div>


                                    ))
                                 }

                                 {
                                    brandList?.length == 0 && <div className="w-100 text-center row nodata m-auto">
                                    <div className="m-auto">
                                          <NoMediaICon />
                                          
                                          <h2>No business in Favourites</h2>
                                          <Link to="/business-list" className="btn btn-primary">Discover businesses now</Link>
                                       </div>
                                    </div>
                                 }

                              </div>
                           </TabPane>

                        </Tabs>
                     </div>
                  </div>
               </div>
            </div>
         </section >
      )
   }
}


function mapStateToProps(state) {
   return {
      wishList: state.wishList
   };
}

export default connect(mapStateToProps)(CartView);
