import React, { Component, Suspense } from 'react';
import { NoMediaICon } from "../../../SvgIcon"
import Footer from "../../layout/Footer";
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { connect } from "react-redux";
import { getWishList } from '../../../redux/action';
import { LoadingOutlined } from '@ant-design/icons';
import { StageLoader } from "../../../components/index";
import { notify } from '../../../components/Toaster';
import { getHome } from "../../../redux/action/Get";
import LazyLoad from 'react-lazy-load';
import socket from "../../../services/socket";
import { BtnRed, CmBtn } from "../../../components/common/Button";
import { Link } from "react-router-dom"

import { getTimeZone } from "../../../utils/DateZone"

import { Calender, Ticket } from "../../../SvgIcon";
import moment from "moment"

import Thankyou from "../../home/HomeCommingSoon"
import Question from "../../eventQuestionnaire/Question";

import Const from "../../../services/Const"

// components
const LoungeCard = React.lazy(() => import('../components/lounges/LoungeCard'));
const StageCard = React.lazy(() => import('../components/stages/StageCard'));
const BrandCard = React.lazy(() => import('../components/brands/BrandCard'));
const ProductCard = React.lazy(() => import('../components/products/ProductCard'));
const IndustriesCard = React.lazy(() => import('../components/industries/IndustrieCard'));


let count = 0
const antIcon = <LoadingOutlined style={{ fontSize: 72 }} spin />;
var _ = require('lodash');

const {isHideHome, home} = Const

class WebHome extends Component {
    static getDerivedStateFromProps(nextProps, prevState) {
        // do things with nextProps.someProp and prevState.cachedSomeProp
        const { homeHome } = nextProps
        if (homeHome && homeHome != prevState?.homeHome) {
            const { brandData, industriesData, loungeData, stageData, productData, conferenceData } = homeHome?.responseData
            return {

                productListData: productData, isLoading: false, conferenceData, loungList: loungeData, brandListData: brandData, industryData: industriesData
            }
        }
        return null

    }

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.carousel = React.createRef();

        this.state = {
            isCommingSoon: false,
            isMultiStage: false,
            isLoading: true,
            brandListData: [],
            productListData: [],
            industryData: [],
            loungList: [],
            conferenceData: [],
            adsList: [],
            adsImage: '',
            redirectionUrl: '',
            selectedItems: [],
            homeHome: [],
            // ---change by radha---
            isLoader: false,
        }
        window.scrollTo(0, 0);
        if (localStorage.getItem("showAll") || localStorage.getItem("isComming")) {

        } else {
            if (localStorage.getItem("userType") && localStorage.getItem("userType") != 4 && localStorage.getItem("checkStatus") == 1 ) {
               
                    // window.location.href = "/plans"
              
            } else {
                // window.location.href = "/coming-soon"
            }
            // this.props.history.push("/coming-soon")
        }
    }



    componentDidMount() {
        let self = this;
        this.getHomeDetails()
        this.getAdList()
        this.props.dispatch({ type: 'PATH_ADD', payload: this.props.match })

        socket.on('loungeEnter', function (param) {
            console.log("loungeEnter", param)
            let loungList = self.state.loungList;
            let lounge = _.find(loungList, { _id: param.loungeId });
            if (lounge) {
                console.log("lounge", lounge)
                lounge.onlineJoinUser = param.userCount;
                let index = _.findIndex(loungList, { _id: param.loungeId });
                loungList.splice(index, 1, lounge);
                self.setState({
                    loungList
                })
            }
        })

        socket.on('loungeExist', function (param) {
            console.log("loungeExist", param)
            let loungList = self.state.loungList;
            let lounge = _.find(loungList, { _id: param.loungeId });
            if (lounge) {
                console.log("lounge", lounge)
                if (lounge.onlineJoinUser > 0) {
                    lounge.onlineJoinUser = param.userCount;
                }
                let index = _.findIndex(loungList, { _id: param.loungeId });
                loungList.splice(index, 1, lounge);
                self.setState({
                    loungList
                })
            }
        })

        socket.on('networkLoungeEnter', function (param) {
            console.log("networkLoungeEnter", param)
            let networkLoungList = self.state.loungList;
            let lounge = _.find(networkLoungList, { _id: param.loungeId });
            if (lounge) {
                // console.log("lounge", lounge)
                lounge.onlineJoinUser = param.userCount;
                let index = _.findIndex(networkLoungList, { _id: param.loungeId });
                //networkLoungList.splice(index, 1, lounge);
                networkLoungList[index] = lounge;
                // console.log("######",networkLoungList)
                self.setState({
                    networkLoungList: networkLoungList
                }, function () {
                    // console.log("self.state",self.state)
                })
            }
        })

        socket.on('networkLoungeExist', function (param) {
            console.log("networkLoungeExist", param)
            let networkLoungList = self.state.loungList;
            let lounge = _.find(networkLoungList, { _id: param.loungeId });
            if (lounge) {
                // console.log("lounge", lounge)
                if (param.userCount >= 0) {
                    lounge.onlineJoinUser = param.userCount;
                }

                let index = _.findIndex(networkLoungList, { _id: param.loungeId });
                //networkLoungList.splice(index, 1, lounge);
                networkLoungList[index] = lounge;
                self.setState({
                    networkLoungList: networkLoungList
                }, () => {
                    // console.log("updateee valuee", index)
                })
            }
        })

    }

    componentWillMount() {
        socket.off("loungeEnter");
        socket.off("loungeExist");
        socket.off("networkLoungeEnter");
        socket.off("networkLoungeExist");
    }

    handleError(err) {
        // notify("err", errorMessage);
    }

    getHomeDetails = (e, id) => {
        this.props.dispatch(getWishList());
        this.props.dispatch(getHome());
    }


    addToWishList = (category, id, wishListData) => {
        let stateName = `wish${id}`
        let oldArr = this.state.selectedItems
        oldArr.push(id)
        this.setState({ selectedItems: oldArr })

        this.setState({ [stateName]: !this.state[stateName] }, () => {
            let params = category == "brand" ? { brandId: id } : { productId: id }
            if (this.state[stateName] && !wishListData) {
                _Api(Url["ADD_WISHLIST"].method, Url["ADD_WISHLIST"].url, params)
                    .then(() => {

                        this.state.brandListData.forEach(item => {
                            if (item._id == id) {
                                item.wishListData = !item.wishListData
                            }
                        });
                        this.state.productListData.forEach(item => {
                            if (item._id == id) {
                                item.wishListData = !item.wishListData
                            }
                        });

                        // if (category == "brand") {
                        //     this.getHomeDetails("FEATURED_BRAND_LIST", "brandListData");
                        // }
                        // else {
                        //     this.getHomeDetails("FEATURED_PRODUCT_LIST", "productListData");
                        // }

                        setTimeout(() => this.setState({ selectedItems: this.state.selectedItems.filter(item => item != id) }), 1500)


                    }).catch((err) => {
                        this.handleError(err);
                        setTimeout(() => this.setState({ selectedItems: this.state.selectedItems.filter(item => item != id) }), 1500)
                    })
            }
            else {
                _Api(Url["REMOVE_WISHLIST"].method, Url["REMOVE_WISHLIST"].url, params)
                    .then(() => {
                        this.state.brandListData.forEach(item => {
                            if (item._id == id) {
                                item.wishListData = !item.wishListData
                            }
                        });
                        this.state.productListData.forEach(item => {
                            if (item._id == id) {
                                item.wishListData = !item.wishListData
                            }
                        });
                        // if (category == "brand") {
                        //     this.getHomeDetails("FEATURED_BRAND_LIST", "brandListData");
                        // }
                        // else {
                        //     this.getHomeDetails("FEATURED_PRODUCT_LIST", "productListData");

                        // }
                        setTimeout(() => this.setState({ selectedItems: this.state.selectedItems.filter(item => item != id) }), 1500)
                    }).catch((err) => {
                        this.handleError(err);
                        setTimeout(() => this.setState({ selectedItems: this.state.selectedItems.filter(item => item != id) }), 1500)
                    })

            }

        }
        );

    }


    next = () => {
        this.carousel.next();
    }
    previous = () => {
        this.carousel.prev();
    }



    handleJoin = (id) => {
        this.props.history.push(`/seminar/${id}`)
    }



    getAdList = () => {
        _Api(Url.GET_Ad_LIST_MESSAGE.method, Url.GET_Ad_LIST_MESSAGE.url, '')
            .then(resp => {
                this.setState({ adsList: resp.responseData.result.data }, () => {
                    this.setImageInterval()
                })
            }).catch(err => {
                // ---change by radha---
                this.setState({ isLoader: false })

            })
    }


    setImageInterval = () => {
        setInterval(() => {
            this.setImages()
        }, 5000)

    }


    setImages = () => {
        try {
            let ads = this.state.adsList

            if (ads.length == count)
                count = 0
            else {
                if (ads[count])
                    this.setState({ adsImage: ads[count].thumbUrl, redirectionUrl: ads[count].redirectionUrl })
                count = count + 1
            }

        } catch (err) { }
    }

    time = (countDownDate) => {

        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

        return { remaning: `${days}d ${hours}hr ${minutes}m`, isShow: days >= 0 }
        // 1hr:54m:07
        // this.setState({ days: days, hours: hours, minutes: minutes, seconds: seconds })

    }







    onlyJoin = (name, id, coId, item) => {
        console.log("test123", navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1)
        if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
            this.onlyJoin1(name, id, coId, item)

        }
        else {
            this.onlyJoin2(name, id, coId, item)
        }




    }

    onlyJoin2 = (name, id, coId, item = {}) => {

        const { numberofAttendees, onlineJoinUser } = item

        console.log("item", item);
        let req = {
            otherUserId: localStorage.getItem('id'),
            type: 4,
            networkLoungeId: item._id,
            exhibitionId: localStorage.getItem('exhibition')
        }

        _Api(Url.CHECK_PLAN_USER.method, Url.CHECK_PLAN_USER.url, req)
            .then(resp => {
                const { isBuy } = resp?.responseData
                if (isBuy == 0) {
                    this.props.dispatch({ type: 'SHOW_PLAN_USER_POPUP', payload: this.props.match })
                }

                else {

                    let reqq = {}

                    if (name == "lounge") {
                        if (onlineJoinUser < numberofAttendees) {
                            console.log("onee11222",id,  localStorage.getItem('accessToken'))
                            console.log("onee11", `https://video.worldwidejobfinder.com?lounge=${id}&token=${localStorage.getItem('accessToken')}`)
    
                            window.open(`https://video.worldwidejobfinder.com?lounge=${id}&token=${localStorage.getItem('accessToken')}&name=${item.loungeName}`, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                            let req = { networkLoungeId: item._id }
    
                            _Api(Url.JOIN_LOUNG_PLAN.method, Url.JOIN_LOUNG_PLAN.url, req)
                                .then(resp => {
                                }).catch(err => { })
                        } else {
                            notify("err", "This lounges is full , you can not join at this time")
                        }
                    }
                    else {
                        this.props.history.push(`/seminar/${coId}/${name}/${id}`)
                    }


                }




            })
    }








    onlyJoin1 = (name, id, coId, item = {}) => {
        const { numberofAttendees, onlineJoinUser } = item
        var windowReference = window.open();

        // console.log("item", item);
        let req = {
            otherUserId: localStorage.getItem('id'),
            type: 4,
            networkLoungeId: item._id,
            exhibitionId: localStorage.getItem('exhibition')
        }

        _Api(Url.CHECK_PLAN_USER.method, Url.CHECK_PLAN_USER.url, req)
            .then(resp => {
                const { isBuy } = resp?.responseData
                if (isBuy == 0) {
                    this.props.dispatch({ type: 'SHOW_PLAN_USER_POPUP', payload: this.props.match })
                }

                else {

                    let reqq = {}

                    if (name == "lounge") {
                        if (onlineJoinUser < numberofAttendees) {
                            var newWin = window.open(`https://video.worldwidejobfinder.com?lounge=${id}&token=${localStorage.getItem('accessToken')}&name=${item.loungeName}`, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                            if (!newWin || newWin.closed || typeof newWin.closed == 'undefined')
                                windowReference.location = `https://video.worldwidejobfinder.com?lounge=${id}&token=${localStorage.getItem('accessToken')}&name=${item.loungeName}`
                            let req = { networkLoungeId: item._id }
    
                            _Api(Url.JOIN_LOUNG_PLAN.method, Url.JOIN_LOUNG_PLAN.url, req)
                                .then(resp => {
                                }).catch(err => { })
                        } else {
                            notify("err", "This lounges is full , you can not join at this time")
                        }
                    }
                    else {
                        this.props.history.push(`/seminar/${coId}/${name}/${id}`)
                    }


                }




            })
    }


    onClickBrand = item => {
        this.props.history.push(`/business/${item._id}/${item?.activeCompany}`);
    }

    onIndustryClick = id => { this.props.history.push(`/job/${id}`); }


    render() {
        console.log("this.props", this.props);
        if (this.props.profile && this.props.profile.profileInfo && this.props.profile.profileInfo.userType == 1) {
            this.props.history.push("/exhibition")
        }
        const { selectedItems, productListData, redirectionUrl, adsImage, brandListData, industryData, loungList, isLoader, conferenceData } = this.state

        if (isHideHome)
            return <Thankyou />
        else
            return (
                <>
                    <section className="brand-home-banner-bg webhome">
                        <Suspense fallback={<div></div>}>
                            <div className={"cm-section container-fluid"}>
                                {this.state.isLoading && <StageLoader />}
                                <div className="cm-wrapper">
                                    <div className="header-text">
            <h1 className="title">{home.h1}</h1>
                                        {conferenceData?.length > 2 && <BtnRed to="seminar" text="More Seminar" />}
                                        {conferenceData?.length > 2 && <Link to="/seminar" className="showmobile991 small-view-btn"><i class="fa fa-eye" aria-hidden="true"></i></Link>}
                                    </div>

                                    {/* <StageCard
                                {...this}
                                list={conferenceData}
                            /> */}
                                    <div className="row ">

                                        {
                                            conferenceData.map((item) => {
                                                return (

                                                    <div className="col-sm-6 col-md-4 col-xl-3">
                                                        <div className="conference-block">
                                                            <Link to={`/seminar/${item?._id}`}>
                                                                <div className="img">
                                                                    <LazyLoad>

                                                                        <img className="img-fluid mb-4" src={item?.coverImage ? item?.coverImage : require('../../../assets/images/placeholder.png')} />
                                                                    </LazyLoad>
                                                                    <div className="free-paid">

                                                                        {
                                                                            item?.euroAmount == 0 ? <button className="btn btn-card live"><span><Ticket /></span>FREE</button> : <button className="btn btn-card live"><span><Ticket /></span>{`£${item?.euroAmount}`}</button>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                            <div className="text">
                                                                <div className="leftborder-parent">
                                                                    <h4>{item?.conferenceName}</h4>
                                                                    <h6><span><Calender /></span>
                                                                        <span>{moment(item?.startDate).format('MMM DD')}, {getTimeZone(item?.startDate, moment.tz.guess())}  to  {moment(item?.endDate).format('MMM DD')}, {getTimeZone(item?.endDate, moment.tz.guess())} </span>   {/* Sept 27, 5:30PM to Sept 28, 6:00PM EST  */}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                )
                                            })
                                        }


                                        {
                                            !this.state.isLoading && conferenceData.length == 0 &&
                                            <div className="w-100 text-center row nodata m-auto">
                                                <div className="m-auto">
                                                    <NoMediaICon />

                                                    <h2>No Data Found</h2>
                                                </div>
                                            </div>
                                        }
                                    </div>



                                </div>



                                <div className="cm-wrapper brand text-center mb-0 viewbrand">
                                    <div className="row">
                                        <div className="col-sm-12 cm-wrapper featured-brands pb-0">
                                            <div className="header-text">
                                                <h1 className="title">{home.h2}</h1>
                                                {brandListData?.length != 0 && <BtnRed to="business-list" text="View All" />}
                                                {brandListData?.length != 0 && <Link to="/business-list" className="showmobile991 small-view-btn"><i class="fa fa-eye" aria-hidden="true"></i></Link>}
                                            </div>
                                            <BrandCard
                                                {...this}
                                                list={brandListData}
                                                selectedItems={selectedItems}
                                            />
                                            {
                                                !this.state.isLoading && brandListData?.length == 0 &&
                                                <div className="w-100 text-center row nodata m-auto">
                                                    <div className="m-auto">
                                                        <NoMediaICon />

                                                        <h2>No Data Found</h2>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {adsImage && <div className="ads d-block">
                                        {
                                            redirectionUrl ?
                                                <a href={redirectionUrl} target="_blank">
                                                    <LazyLoad>
                                                        <img className="img-fluid" src={adsImage} />
                                                    </LazyLoad>
                                                </a>
                                                :

                                                <img className="img-fluid" src={adsImage} />

                                        }

                                    </div>}

                                </div>
                                {/* <div className="cm-wrapper featured-brands industries-serve">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="header-text">
                                        <h1 className="title mr-3">Industries</h1>
                                        {industryData?.length > 4 && <BtnRed to="industry" text="View All" />}
                                        {industryData?.length > 4 && <Link to="/industry" className="showmobile991 small-view-btn"><i class="fa fa-eye" aria-hidden="true"></i></Link>}
                                    </div>
                                    <IndustriesCard
                                        {...this}
                                        list={industryData}

                                    />
                                </div>
                            </div>

                        </div> */}

                                <div className="cm-wrapper featured-brands showcase">
                                    <div className="header-text">
                                        <h1 className="title">{home.h3}</h1>
                                        {productListData?.length != 0 && <BtnRed to="job" text="View All" />}
                                        {
                                            productListData?.length != 0 && <Link to="/job" className="showmobile991 small-view-btn"><i class="fa fa-eye" aria-hidden="true"></i></Link>}

                                    </div>
                                    <ProductCard
                                        {...this}
                                        list={productListData}
                                        selectedItems={selectedItems}
                                    />
                                    {
                                        !this.state.isLoading && productListData?.length == 0 &&
                                        <div className="w-100 text-center row nodata m-auto">
                                            <div className="m-auto">
                                                <NoMediaICon />

                                                <h2>No Data Found</h2>
                                            </div>
                                        </div>
                                    }
                                </div>


                                <div className="cm-wrapper home-networking">
                                    <div className="header-text">
                                        <h1 className="title">Lounges</h1>
                                        {loungList?.length > 4 && <BtnRed to="lounges" text="View All" />}
                                        {loungList?.length > 4 && <Link to="/lounges" className="showmobile991 small-view-btn"><i class="fa fa-eye" aria-hidden="true"></i></Link>}
                                    </div>
                                    <LoungeCard
                                        {...this}
                                        list={loungList}
                                    />

                                    {
                                        !this.state.isLoading && loungList?.length == 0 &&
                                        <div className="w-100 text-center row nodata m-auto">
                                            <div className="m-auto">
                                                <NoMediaICon />

                                                <h2>No Data Found</h2>
                                            </div>
                                        </div>
                                    }

                                </div>
                                
                            </div>
                        </Suspense>
                        <Footer />
                    </section>
                    
                    {/*<Question />*/}
                </>
            )
    }

}
function mapStateToProps(state) {
    const { homeHome } = state?.loading

    return {
        ...state,
        wishList: state.wishList,
        homeHome: homeHome
    };
}

export default connect(mapStateToProps)(WebHome);
