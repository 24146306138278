import React, { Component } from 'react';
import { Hert, Brands, Pencil, Delete, UploadVideo } from '../../../SvgIcon';
import { Link } from "react-router-dom";
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';
import Pagination from 'react-js-pagination';
import { Select } from 'antd';
import { _Api } from "../../../services/Api";
import Url from "../../../services/BaseUrl";
import { notify } from "../../../components/Toaster";
import dateFormat from "dateformat";
import { Modal, Button, Space, Empty } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

const { Option } = Select;
// import BackBtn from '../../../components/common/Button'

function handleChange(value) {
    console.log(`selected ${value}`);
}

class CategoryDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            limit: 50,
            download: 0,
            searchKey: '',
            sortField: 'categoryTitle',
            sortType: 1,

            categoryTitle: "",
            categoryDesc: "",
            categoryType: 0,

            categoryTitleError: "",
            categoryDescError: "",
            categoryTypeError: "",
            categoryImgPrev: "",
            categoryImg: "",
            isLoader: false,
            categoryId: "",
            list: [],
            level: 2,
            details:''
        }
        this.onChanged = this.onChanged.bind(this);
        this.getCategoryDetails = this.getCategoryDetails.bind(this);
        this.deleteCategory = this.deleteCategory.bind(this);
        this.getDate = this.getDate.bind(this);
    }
    componentDidMount() {
        this.getCategoryDetails()
        this.getCategory()
    }
    getCategoryDetails() {
        let self = this;
        let params = {
            productAndServicesId: this.props.match.params.catogoryId, 
        }
      
        _Api(Url.GET_CATEGORY_PRODUCT_PARTICULAR.method, Url.GET_CATEGORY_PRODUCT_PARTICULAR.url, params)
            .then(resp => {
                console.log('responseDataaaa', );
                self.setState({details : resp?.responseData?.result})
                // self.setState({
                //     categoryId: resp.responseData.result._id,
                //     categoryTitle: resp.responseData.result.categoryTitle,
                //     categoryDesc: resp.responseData.result.categoryDesc,
                //     categoryType: resp.responseData.result.categoryType,
                //     categoryImgPrev: resp.responseData.result.imageUrl,
                //     // list:resp.responseData.result,
                //     // totalCount:resp.responseData.totalCount
                // })
            }).catch(err => {
                this.setState({ isLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
                this.setState({ isLoader: false })
            })
    }

    selectOnChange = (type, e) => {
        let self = this;
        this.setState({
            [type]: e,
        }, function () {

        })
    }

    onChanged(e) {
        console.log("e", e);
        let self = this;
        let keyName = e.target.name;
        let KeyValue = e.target.value;
        this.setState({
            categoryTitleError: "",
            categoryDescError: "",
            categoryTypeError: ""
        })
        this.setState({ [e.target.name]: e.target.value }, function () {
            if(keyName==='searchKey'){
                // if(KeyValue.length>=3){
                    self.getCategory();
                // }
            }
        });
        if (e.target.name === 'categoryImg') {
            // console.log("e",e);
            let reader = new FileReader();
            let file = e.target.files[0];

            reader.onloadend = () => {
                this.setState({
                    categoryImgPrev: reader.result
                });
            }
            reader.readAsDataURL(file)

            this.setState({
                categoryImgPrev: file
            })
            this.setState({
                categoryImg: e.target.files[0]
            });
        }
    }

    getCategoryType = (type) => {
        if (type === 1 || type === "1") {
            return "Place of work"
        } else if (type === 2 || type === "2") {
            return "Job title"
        } else if (type === 3 || type === "3") {
            return "Services"
        } else if (type === 4 || type === "4") {
            return "Product"
        }
    }

    getCategory() {
        let params = {
            page: this.state.page,
            limit: this.state.limit,
            searchKey: this.state.searchKey,
            sortField: this.state.sortField,
            sortType: this.state.sortType,
            parentCategoryId: this.props.match.params.catogoryId,
            //categoryType: ""+this.state.categoryType,
            level: "" + this.state.level
        }
        _Api(Url.CATEGORY_LIST.method, Url.CATEGORY_LIST.url, params)
            .then(resp => {
                console.log('responseData', resp.responseData);
                this.setState({
                    isLoader:false,
                    list: resp.responseData.result,
                    totalCount: resp.responseData.totalCount
                })
            }).catch(err => {
                this.setState({ isLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
                this.setState({ isLoader: false })
            })
    }

    changeStatus = (e, id, status) => {
        console.log("change status")
        let req = {
            categoryId: id,
            status: status ? "0" : "1",
        }
        _Api(Url.CATEGORY_CHANGE_STATUS.method, Url.CATEGORY_CHANGE_STATUS.url, req)
            .then(resp => {
                this.getCategory()
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
            })
    }

    deleteCategory(categoryId) {
        let req = {
            categoryId: categoryId,
        }
        _Api(Url.CATEGORY_DELTED.method, Url.CATEGORY_DELTED.url, req)
            .then(resp => {
                this.getCategory()
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
            })
    }

    getDate(date) {
        if (date && date != '') {
            return dateFormat(date, 'dd-mm-yyyy')
        }
    }

    render() {

        console.log("poihjnmk", this.state.details)

        return (
            <>
                <div className="dash_body_container">
                    <Header />
                    <Sidebar />
                    <div className="body_container category-details">

                        <div className="header-text  mb-4 mt-3">

                            <div className="d-flex align-items-center">
                                <p className="header-icon"><Brands /></p>
                                <h4>Category</h4>
                            </div>
                            <button onClick={()=>  this.props.history.goBack()} className="btn btn-light"><i className="fa fa-arrow-left" aria-hidden="true"></i>Back</button>

                        </div>
                        <div className="rounded-body pb-5 pl-4 pr-4">

                            <div className="profile-section-details">
                                {this.state.categoryImgPrev ?
                                    <div className="profile-dp">
                                        <img src={this.state.categoryImgPrev} />
                                    </div> : ""}


                                <div className="table-wrapper">
                                    <div className="table-responsive no-shadow transaction-details-table">
                                        <table className="table table-bordered rounded-table">
                                            <tr>
                                                <th>Category Title</th>
                                                <td>{this.state.details?.name}</td>
                                            </tr>
                                            <tr>
                                                <th>Sector</th>
                                                <td>{this.state.details?.sectorData?.map(item => `${item?.name}, `)}</td>
                                            </tr>                                           
                                        </table>
                                    </div>
                                </div>


                            </div>                        
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default CategoryDetails;