import React, { useEffect, useRef, useState, useCallback } from 'react';
import Cropper from 'react-easy-crop'
import NoSsr from '@material-ui/core/NoSsr'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
// import {FlipIcon} from '@material-ui/icons'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Modal } from 'antd';
import '../assets/css/cropperStyles.css'
import { Hert } from '../SvgIcon';
import siteSetting from '../config/env/Index';
import {notify} from "./Toaster";
import imageCompression from 'browser-image-compression';

var _URL = window.URL || window.webkitURL;

const useStyles = makeStyles((theme) =>
    createStyles({
        cropContainer: {
            position: 'relative',
            width: '100%',
            height: 200,
            background: '#333',
            [theme.breakpoints.up('sm')]: {
                height: 300,
            },
        },
        cropButton: {
            flexShrink: 0,
            marginLeft: 16,
        },
        controls: {
            padding: 16,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            [theme.breakpoints.up('sm')]: {
                flexDirection: 'row',
                alignItems: 'center',
            },
        },
        sliderContainer: {
            display: 'flex',
            flex: '1',
            alignItems: 'center',
        },
        sliderLabel: {
            [theme.breakpoints.down('xs')]: {
                minWidth: 65,
            },
        },
        slider: {
            padding: '22px 0px',
            marginLeft: 16,
            [theme.breakpoints.up('sm')]: {
                flexDirection: 'row',
                alignItems: 'center',
                margin: '0 16px',
            },
        },
    })
)

function Croper({ aspect = 3 / 1, finalCropedImage, visibleVal, onClose, cropShape = 'rect', imgPrev = 'transparent.png' }) {  //round
    if (cropShape === 'round') {
        aspect = 1 / 1
    }
    const classes = useStyles();
    const [visible, setVisible] = useState(true);
    const [objectUrl, setobjectUrl] = useState('');
    const [categoryImgPrev, setCategoryImgPrev] = useState(require('../assets/images/placeholder/' + imgPrev));
    const [categoryImg, setCategoryImg] = useState(true);
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [flip, setFlip] = useState({ horizontal: false, vertical: false })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)

    const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }

    function handleOk(e) {

        setVisible(!visible)
    };

    function handleCroper(e) {

        setVisible(false)
        onClose()
    };

  async  function  onChanged(e) {
        if (e.target.name === 'categoryImg') {
            let reader = new FileReader();
            let file = e.target.files[0];           
            console.log(`originalFile size ${file.size / 1024 / 1024} MB`);
            const compressedFile = await imageCompression(file, options);

          
            console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);
            let size =( Math.round(compressedFile.size/1000000) )

            if (size <= siteSetting.imageUploadLimit) {
                reader.onloadend = () => {
                    setCategoryImgPrev(reader.result)
                }
                setobjectUrl(URL.createObjectURL(file))
                reader.readAsDataURL(file)
                setCategoryImgPrev(file)
            }else{
                notify("err", "file size to large")
            }
        }
    }

    async function setfinalCropedImage() {
        if (categoryImgPrev && croppedAreaPixels.width && croppedAreaPixels.height) {
            const croppedImage = await getCroppedImg(objectUrl, croppedAreaPixels, rotation, flip)
            setCroppedImage(croppedImage)
            visibleVal = false
            finalCropedImage(URL.createObjectURL(croppedImage), croppedImage, visibleVal)
        }
    }

    function getRadianAngle(degreeValue) {
        return (degreeValue * Math.PI) / 180
    }

    async function getCroppedImg(
        imageSrc,
        pixelCrop,
        rotation = 0,
        flip = { horizontal: false, vertical: false }
    ) {
        const image = await createImage(imageSrc);
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        if (!ctx) {
            return null
        }

        const maxSize = Math.max(image.width, image.height)
        const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

        // set width to double image size to allow for a safe area for the
        // image to rotate in without being clipped by canvas context
        canvas.width = safeArea
        canvas.height = safeArea

        // translate canvas context to a central location to allow rotating and flipping around the center.
        ctx.translate(safeArea / 2, safeArea / 2)
        ctx.rotate(getRadianAngle(rotation))
        ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
        ctx.translate(-safeArea / 2, -safeArea / 2)

        // draw rotated image and store data.
        ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5)
        const data = ctx.getImageData(0, 0, safeArea, safeArea)

        // set canvas width to final desired crop size - this will clear existing context
        canvas.width = pixelCrop.width
        canvas.height = pixelCrop.height

        // paste generated rotate image with correct offsets for x,y crop values.
        ctx.putImageData(
            data,
            0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
            0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
        )

        // As Base64 string
        // return canvas.toDataURL('image/jpeg');

        // As a blob
        return new Promise((resolve, reject) => {
            canvas.toBlob(file => {

                resolve(file)
            }, 'image/jpeg')
        })
    }

    const createImage = (url) =>
        new Promise((resolve, reject) => {
            const image = new Image()
            image.addEventListener('load', () => resolve(image))
            image.addEventListener('error', error => reject(error))
            image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
            image.src = url
        })

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])


    // const image = document.getElementById('image');
    // const Cropperr = new Cropper(image, {
    //     aspectRatio: 16 / 9,
    //     crop(event) {
    //         console.log(event.detail.x);
    //         console.log(event.detail.y);
    //         console.log(event.detail.width);
    //         console.log(event.detail.height);
    //         console.log(event.detail.rotate);
    //         console.log(event.detail.scaleX);
    //         console.log(event.detail.scaleY);
    //     }
    // })


    const _crop = () => {
        // image in dataUrl
        console.log(this.refs.cropper.getCroppedCanvas().toDataURL());
    }

    return (

        <Modal
            footer={null}
            centered
            visible={visible}
            onOk={handleOk}
            onCancel={handleCroper}
            className="paymentpopup croperpopup"
        >


            <h4 className="text-center  pos-relative mb-5"><b>Crop the image</b>
                <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
            </h4>

            <div className="cm_crop-container-parent">
                <div className="crop-container cm_crop-container">
                    <div className={classes.cropContainer}>
                        <NoSsr>
                            {/* <CropperJS
                                ref='cropper'
                                crossOrigin={'j'}
                                src='http://i.imgur.com/n483ZwJ.jpg'
                                style={{ height: 400, width: '100%' }}
                                // Cropper.js options
                                aspectRatio={16 / 9}
                                guides={false}
                                // crop={_crop} 
                                
                                /> */}
                            {/* <img id="image" /> */}
                            {/* <Cropperr /> */}

                            <Cropper
                                cropShape={cropShape}
                                image={categoryImgPrev}
                                crop={crop}
                                zoom={zoom}
                                aspect={aspect}
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                                transform={[
                                    `translate(${crop.x}px, ${crop.y}px)`,
                                    `rotateZ(${rotation}deg)`,
                                    `rotateY(${flip.horizontal ? 180 : 0}deg)`,
                                    `rotateX(${flip.vertical ? 180 : 0}deg)`,
                                    `scale(${zoom})`,
                                ].join(' ')}
                                onRotationChange={setRotation}
                            // cropSize={{
                            //     width: 300,
                            //     height: 100
                            // }}
                            />
                        </NoSsr>
                    </div>
                </div>
                {/*<div className="controls">*/}
                {/*    <i class="cm_croper_control" >Zoom In</i>*/}
                {/*    <Slider*/}
                {/*        value={zoom}*/}
                {/*        min={1}*/}
                {/*        max={3}*/}
                {/*        step={0.1}*/}
                {/*        aria-labelledby="Zoom"*/}
                {/*        onChange={(e, zoom) => setZoom(zoom)}*/}
                {/*        classes={{ container: 'slider' }}*/}
                {/*    />*/}
                {/*     &nbsp;<i class="cm_croper_control" >Zoom Out</i>*/}
                {/*</div>*/}
                <div className="controls">

                    <div>
                        <Typography variant="overline" classes={{ root: classes.sliderLabel }}>
                            Zoom
                    </Typography>
                        <Slider
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            classes={{ root: classes.slider }}
                            onChange={(e, zoom) => setZoom(zoom)}
                        />
                    </div>
                    <div >
                        <Typography variant="overline" classes={{ root: classes.sliderLabel }}>
                            Rotation
                    </Typography>
                        <Slider
                            value={rotation}
                            min={0}
                            max={360}
                            step={1}
                            aria-labelledby="Rotation"
                            classes={{ root: classes.slider }}
                            onChange={(e, rotation) => setRotation(rotation)}
                        />
                    </div>
                    <div >
                        <IconButton
                            aria-label="Flip Horizontal"
                            onClick={() => {
                                setFlip(prev => ({ horizontal: !prev.horizontal, vertical: prev.vertical }))
                                setRotation(prev => 360 - prev)
                            }}
                        >
                            <Button >Horizontal Flip</Button>
                        </IconButton>
                        <IconButton
                            aria-label="Flip Vertical"
                            onClick={() => {
                                setFlip(prev => ({ horizontal: prev.horizontal, vertical: !prev.vertical }))
                                setRotation(prev => 360 - prev)
                            }}
                        >
                            <Button  >Vertical Flip</Button>
                        </IconButton>
                    </div>
                </div>
            </div>

            <div className="croper-bottom-btn">
                <div className="breakonphone">
                <span className="upload-icon btn btn-primary floar-right" onClick={(e) => categoryImg.click()}>Browse Image</span>
                <span>&nbsp;&nbsp;&nbsp;(max size* 2MB)&nbsp;&nbsp;&nbsp;</span>
                </div>
                <input type="file" className="d-block" placeholder=""
                    accept="image/*"
                    hidden
                    name="categoryImg"
                    ref={(ref) => setCategoryImg(ref)}
                    onChange={onChanged} />
                <button className="btn btn-primary" type='button' onClick={setfinalCropedImage}>Upload</button>
            </div>
        </Modal>
    )
}

export default Croper;