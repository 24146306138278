import React, { Component } from 'react';
import { Pencil, Delete, UploadVideo, ExhibitionManagement, CircleCross,NewBadges } from '../../../../../SvgIcon';
import { Link } from "react-router-dom";
import Sidebar from '../../../../layout/Sidebar';
import Header from '../../../../layout/Header';
import { DatePicker, TimePicker, Select } from 'antd';
import { _Api } from '../../../../../services/Api';
import Url from '../../../../../services/BaseUrl';
import { notify } from '../../../../../components/Toaster';
import InlineError from '../../../../../components/InlineError';
import moment from 'moment-timezone';
import Loader from '../../../../../components/Loader'
import Croper from "../../../../../components/Croper";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { Tooltip } from 'antd';


const { Option } = Select;
const { confirm } = Modal;

const disabledDate = (current) => {
    // Can not select days before today and today
    return current && moment(current.valueOf()) < moment();
}


class Spotlight extends Component {
    constructor(props) {
        super(props)
        this.state = {
            brandData: [],
            productData: [],
            offerData: [],
            newProductData: [],
            result:[],
            visible:false
        }
    }


    componentDidMount() {
        this.getDetails()
    }


    getDetails = () => {
        let req = {
            conferenceId: this.props.match.params.coId
        }
        _Api(Url.GET_ADMIN_SPOTLIGHT.method, Url.GET_ADMIN_SPOTLIGHT.url, req)
            .then(resp => {

                const {
                    brandData,
                    productData,
                    offerData,
                    newProductData
                } = resp.responseData

                console.log("ldkajhgsvhbj",  )

                this.setState({result: resp.responseData.result})
            }).catch(err => { })
    }


    changeStatus = (status, id)=>{
        let req = {
            spotLightId: id,
            status: status,
        }
        _Api(Url.CHANGE_STATUS.method, Url.CHANGE_STATUS.url,req)
            .then(resp => {
               this.getDetails()

               
            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
            })

    }


    // delete modal
    onStagDelete = (data) => {
        confirm({
            title: 'Are you sure you want to delete this?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            onOk: () => {
                this.handleDelete(data._id)
            },
            onCancel: () => {
                this.handleCancel()
            },
        });
    }

    handleCancel = () => {
        // console.log('delete', resp.responseData.message)
        this.setState({ visible: false });
    };




    handleDelete = (id) => {
        let req = {
            spotLightId: id,
            isDeleted: 0,


        }
        _Api(Url.DELETE_STATUS.method, Url.DELETE_STATUS.url, req)
            .then(resp => {
                this.getDetails()
              


            }).catch(err => {
                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)
            })

    }



    





    render() {
        console.log("pojhvbsdnnm", this.state.media)
        let { result, isLoader, brandData, productData, offerData, newProductData } = this.state

        return (
            <>
                <div className="spot-admin">
                    {isLoader && <Loader />}
                    <div className="d-flex justify-content-center">
                        <Link to={`/assignspotlight/${this.props.match.params.exId}/${this.props.match.params.coId}`} className="btn btn-primary mr-3">ADD Business</Link> <br />
                        <Link to={`/assignspotlight/job/2/${this.props.match.params.exId}/${this.props.match.params.coId}`}  className="btn btn-primary mr-3">ADD Job</Link> <br />
                        <Link to={`/assignspotlight/job/1/${this.props.match.params.exId}/${this.props.match.params.coId}`}  className="btn btn-primary mr-3">ADD New Job</Link> <br />
                        <Link to={`/assignspotlight/training/${this.props.match.params.exId}/${this.props.match.params.coId}`}  className="btn btn-primary">ADD Training</Link>
                    </div>
                    {/* // 1st-brand 2-product 3-offer 4-new product */}
                    <div>
                        <div className="row mt-4 confrence-details featured-brands showcase mt-0 viewbrand ">
                            {
                                result?.map(item =>{
                                    console.log("dshbjnk", item)

                                    if(item.type == 1)
                                    return(

                                        <div to={`/business/${item?.data._id}/${item?.data?.activeCompany}`} className="four-div cursor-point admin-brand" >
                                        <div 
                                        // onClick={(e) => this.onClickBrand(item?._id, e)}
                                        > 
    
                                            <div className="card brand-card new-product mb-5">
                                                <Link to={`/business/details/${item?.data._id}/${item?.data?.activeCompany}`} >
     
                                                <img className="img-fluid mb-4" src={item?.data?.coverImage || require('../../../../../assets/images/profile-placeholder.png')} />
                                                </Link>
    
                                                <div className=" brand-card-body cursor-point" >
                                                <Link to={`/business/details/${item?.data._id}/${item?.data?.activeCompany}`} >
                                                    <div className="brand-img-wrapper">
                                                         
                                                        <img className="img-fluid" src={item?.data?.logoImage || require('../../../../../assets/images/placeholder.png')} />
    
                                                    </div>
                                                    </Link>
                                                    <h4>{item?.data?.companyName}</h4>
                                                    <p>{item?.data?.about}</p>
                                                    <div className="d-flex">
                                                <div className="action_group justify-content-center">
                                                    <a
                                                    onClick={(e) => this.onStagDelete(item)}
                                                    ><p className="circle-serial"><Delete /></p></a>
                                                    <p className="toggle-parent ">
                                                        <label className="switch">
                                                            <input type="checkbox"
                                                            checked={item.status === 1 ? true : false}
                                                            onChange={(e) => this.changeStatus(item.status == 1 ? 0 : 1, item._id)}
                                                            />
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </p>
                                                </div>
                                            </div>
                                                </div>
                                            </div>
    
                                        </div>
                                    </div>



                                )
                                else  if(item.type == 2)
                                return(
                                    // =====================
                             <div className="four-div mb-5 admin-product">
                                <div className="card brand-card new-product mb-5" key = {item._id}>
                                        <div className="text-right">
                                            <p className="toggle-parent ">
                                                    <label className="switch">
                                                        <input type="checkbox" checked={item.status === 1 ? true : false} onChange={(e) => this.changeStatus(item.status == 1 ? 0 : 1, item._id)}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                            </p>
                                        </div>
                                        <Link to={`/job-details/${item?.data?._id}`} >
                                                <img className="img-fluid" src={item?.data?.image ? item?.data?.image : require("../../../../../assets/images/placeholder.png")} alt='imgage not avaliable' />
                                        </Link>
                                        <hr />
                                        <div className="action_group " style={{display: 'initial'}}>
                                        <div className="d-flex justify-content-between align-items-center"> 
                                        <span className="flex-grow-1">{item?.data?.productName} <br /></span>
                                            <a onClick={(e) => this.onStagDelete(item)} ><p className="circle-serial"><Delete /></p></a>
                                        </div>
                                        </div>
                                </div>
                             </div>
  // =====================
                                )
                                // return(

                                    
                                //     <div key = {item._id} className="col-md-4 mb-5">
                                //         <div className="card-img-wrapper">
                                //             <div className="card"> 
                                //             <Link to={`/job-details/${item?.data?._id}`} >
                                //                 <img className="img-fluid" src={item?.data?.image ? item?.data?.image : require("../../../../../assets/images/placeholder.png")} alt='imgage not avaliable' />
                                //             </Link>
                                //             </div>
                                //         </div>
                                //         <div className="cm-card-body">
                                //             <h4><strong>{item?.data?.productName}</strong></h4>
                                //             <div className="d-flex">
                                //                 <div className="action_group">
                                //                     <a
                                //                     onClick={(e) => this.onStagDelete(item)}
                                //                     ><p className="circle-serial"><Delete /></p></a>
                                //                     <p className="toggle-parent ">
                                //                         <label className="switch">
                                //                             <input type="checkbox"
                                //                             checked={item.status === 1 ? true : false}
                                //                             onChange={(e) => this.changeStatus(item.status == 1 ? 0 : 1, item._id)}
                                //                             />
                                //                             <span className="slider round"></span>
                                //                         </label>
                                //                     </p>
                                //                 </div>
                                //             </div>
                                //         </div>

                                //     </div>
                                // )
                                else  if(item.type == 3)
                                return(
                                    <div className="four-div mb-5 admin-product">
                                    <div className="card brand-card new-product mb-5" key = {item._id}>
                                        <div className="text-right">
                                               <p className="toggle-parent ">
                                                        <label className="switch">
                                                            <input type="checkbox"
                                                            checked={item.status === 1 ? true : false}
                                                            onChange={(e) => this.changeStatus(item.status == 1 ? 0 : 1, item._id)}
                                                            />
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </p>
                                        </div>
                                        <Link to={`/training-details/${item?.data?._id}`} >
                                                <img className="img-fluid" src={item?.data?.image ? item?.data?.image : require("../../../../../assets/images/placeholder.png")} alt='imgage not avaliable' />
                                        </Link>
                                        <div className="newBadge offer">
                                            <p> Training<span className="traingle"><NewBadges /></span></p>
                                        </div>
                                        <hr />
                                        <div className="action_group " style={{display: 'initial'}}>
                                        <div className="d-flex justify-content-between align-items-center"> 
                                            <span className="flex-grow-1">{item?.data?.name} <br /></span>
                                            <a onClick={(e) => this.onStagDelete(item)} ><p className="circle-serial"><Delete /></p></a>
                                        </div>
                                        </div>
                                </div>
                                </div>
                                    // <div key = {item._id} className="col-md-4 mb-5">
                                    //     <div className="card-img-wrapper">
                                    //     <Link to={`/offer-details/${item?.data?._id}`} >
                                    //             <img className="img-fluid" src={item?.data?.image ? item?.data?.image : require("../../../../../assets/images/placeholder.png")} alt='imgage not avaliable' />
                                    //         </Link>
                                    //     </div>
                                    //     <div className="cm-card-body">
                                    //         <h4><strong>{item?.data?.name}</strong></h4>
                                    //         <div className="d-flex">
                                    //             <div className="action_group">
                                    //                 <a onClick={(e) => this.onStagDelete(item)} ><p className="circle-serial"><Delete /></p></a>
                                    //                 <p className="toggle-parent ">
                                    //                     <label className="switch">
                                    //                         <input type="checkbox"
                                    //                         checked={item.status === 1 ? true : false}
                                    //                         onChange={(e) => this.changeStatus(item.status == 1 ? 0 : 1, item._id)}
                                    //                         />
                                    //                         <span className="slider round"></span>
                                    //                     </label>
                                    //                 </p>
                                    //             </div>
                                    //         </div>
                                    //     </div>

                                    // </div>
                               
                            
                                )

                                else  if(item.type == 4)
                                return(
                                    <div className="four-div mb-5 admin-product">
                                     <div className="card brand-card new-product mb-5" key = {item._id}>
                                        <div className="text-right">
                                        <p className="toggle-parent ">
                                                        <label className="switch">
                                                            <input type="checkbox"
                                                            checked={item.status === 1 ? true : false}
                                                            onChange={(e) => this.changeStatus(item.status == 1 ? 0 : 1, item._id)}
                                                            />
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </p>
                                        </div>
                                        <Link to={`/job-details/${item?.data?._id}`} >
                                                <img className="img-fluid" src={item?.data?.image ? item?.data?.image : require("../../../../../assets/images/placeholder.png")} alt='imgage not avaliable' />
                                            </Link>
                                        <div className="newBadge">
                                            <p> New<span className="traingle"><NewBadges /></span></p>
                                        </div>
                                        <hr />
                                        <div className="action_group " style={{display: 'initial'}}>
                                        <div className="d-flex justify-content-between align-items-center"> 
                                            <span className="flex-grow-1">{item?.data?.productName} <br /></span>
                                            <a onClick={(e) => this.onStagDelete(item)} ><p className="circle-serial"><Delete /></p></a>
                                            </div>
                                        </div>
                                </div>
                                </div>

                                    // <div key = {item._id} className="col-md-4 mb-5">
                                    //     <div className="card-img-wrapper">
                                    //     <Link to={`/job-details/${item?.data?._id}`} >
                                    //             <img className="img-fluid" src={item?.data?.image ? item?.data?.image : require("../../../../../assets/images/placeholder.png")} alt='imgage not avaliable' />
                                    //         </Link>
                                    //     </div>
                                    //     <div className="cm-card-body">
                                    //         <h4><strong>{item?.data?.productName}</strong></h4>
                                    //         <div className="d-flex">
                                    //             <div className="action_group">
                                    //                 <a
                                    //                 onClick={(e) => this.onStagDelete(item)}
                                    //                 ><p className="circle-serial"><Delete /></p></a>
                                    //                 <p className="toggle-parent ">
                                    //                     <label className="switch">
                                    //                         <input type="checkbox"
                                    //                         checked={item.status === 1 ? true : false}
                                    //                         onChange={(e) => this.changeStatus(item.status == 1 ? 0 : 1, item._id)}
                                    //                         />
                                    //                         <span className="slider round"></span>
                                    //                     </label>
                                    //                 </p>
                                    //             </div>
                                    //         </div>
                                    //     </div>

                                    // </div>
                               
                                )
                            
                            
                            }
                                )
                            }

                        </div>
                       
                      
                    </div>

                    






                </div>
            </>
        );
    }
}
export default Spotlight;