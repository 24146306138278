import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { UserManagementIcon, Pencil, Delete, Unmute } from '../../../SvgIcon';
import InlineError from "../../../components/InlineError"
import { DatePicker, TimePicker, Radio } from 'antd';

import {
    MySpinner
} from "../../../components/index"


import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';

class AddSubscriptionPlan extends Component {
    constructor() {
        super()
        this.state = {
            order: 1,
            value: 'free',
            isLoader: false,
            planName: '',
            planNameError: '',
            planType: 0,
            planTypeError: '',
            euroAmount: '',
            euroAmountError: '',
            dollarAmount: '',
            dollarAmountError: '',
            euroVatAmount: '',
            euroVatAmountError: '',
            dollarVatAmount: '',
            dollarVatAmountError: '',
            defaultCurrency: 0,
            defaultVatText:0


        }
    }

    componentDidMount(){
        if(this.props.match.params.id)
        this.getItem()

    }

    getItem = ()=>{
        let req = {
            planId:this.props.match.params.id
        }
        _Api(Url.GET_PLAN_ITEM.method, Url.GET_PLAN_ITEM.url, req)
        .then(resp => {
            console.log("addPlan", resp.responseData.result)

            const {defaultVatText,planName, planType, euroAmount, dollarAmount, euroVatAmount, dollarVatAmount, defaultCurrency} = resp.responseData.result || {}
            this.setState({
                defaultVatText,
                value : planType == 0 ? "free":"payable",
                planName, planType, euroAmount, dollarAmount, euroVatAmount, dollarVatAmount, defaultCurrency
            })
        
           
        }).catch(err => {
            this.setState({ isLoader: false })
        })
    }

    submit = ()=>{
        if(this.props.match.params.id)
        this.editPlan()
        else
        this.addPlan()
    }

    editPlan = (isShowLoader = true, isDownload = 0) => {
        this.setState({ isLoader: isShowLoader })
        let {defaultVatText, planName, planType, euroAmount, dollarAmount, euroVatAmount, dollarVatAmount, defaultCurrency } = this.state
        let req

        console.log("planType", planType)

        if (planType == 0) {
            req = {
                planName,
                planType,
                planId:this.props.match.params.id
            }
            if (planName == "") {
                this.setState({ planNameError: "*Please enter value" })
            }

            else {

                _Api(Url.EDIT_PLAN.method, Url.EDIT_PLAN.url, req)
                    .then(resp => {
                        console.log("addPlan", resp)
                        this.setState({ isLoader: false })
                        this.props.history.goBack()
                    }).catch(err => {
                        this.setState({ isLoader: false })
                    })
            }
        }
        else {
            req = {
                planId:this.props.match.params.id,
                planName,
                planType,
                euroAmount,
                dollarAmount,
                euroVatAmount,
                dollarVatAmount,
                defaultCurrency,
                defaultVatText
            }
            if (planName == "") {
                this.setState({ planNameError: "*Please enter value" })
            }
            else {

                _Api(Url.EDIT_PLAN.method, Url.EDIT_PLAN.url, req)
                    .then(resp => {
                        console.log("addPlan", resp)
                        this.setState({ isLoader: false })
                        this.props.history.goBack()
                    }).catch(err => {
                        this.setState({ isLoader: false })
                    })
            }
        }

    }

 

    addPlan = (isShowLoader = true, isDownload = 0) => {
        this.setState({ isLoader: isShowLoader })
        let {defaultVatText, planName, planType, euroAmount, dollarAmount, euroVatAmount, dollarVatAmount, defaultCurrency } = this.state
        let req

        console.log("planType", planType)

        if (planType == 0) {
            req = {
                planName,
                planType
            }
            if (planName == "") {
                this.setState({ planNameError: "*Please enter value" })
            }

            else {

                _Api(Url.PLAN_ADD.method, Url.PLAN_ADD.url, req)
                    .then(resp => {
                        console.log("addPlan", resp)
                        this.setState({ isLoader: false })
                        this.props.history.goBack()
                    }).catch(err => {
                        this.setState({ isLoader: false })
                    })
            }
        }
        else {
            req = {
                planName,
                planType,
                euroAmount,
                dollarAmount,
                euroVatAmount,
                dollarVatAmount,
                defaultCurrency,
                defaultVatText
            }
            if (planName == "") {
                this.setState({ planNameError: "*Please enter value" })
            }
            else {

                _Api(Url.PLAN_ADD.method, Url.PLAN_ADD.url, req)
                    .then(resp => {
                        console.log("addPlan", resp)
                        this.setState({ isLoader: false })
                        this.props.history.goBack()
                    }).catch(err => {
                        this.setState({ isLoader: false })
                    })
            }
        }

    }

    handleClear = () => {
        this.setState({
            planNameError: '',
            planTypeError: '',
            euroAmountError: '',
            dollarAmountError: '',
            euroVatAmountError: '',
            dollarVatAmountError: '',

        })
    }



    handleOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
        this.handleClear()
    }


    onSelect = (e) => {
        console.log("abcdefgh", e.target.value)
        this.setState({ planType: e.target.value })
        if (e.target.value == 0) {
            this.setState({ value: "free" })
        }
        else
            this.setState({ value: "payable" })
    }

    onValidForSelect = e => {
        this.setState({
            defaultCurrency: e.target.value
        });

    }
    onValidForSelect = e => {
        this.setState({
            defaultVatText: e.target.value
        });

    };



    render() {
        console.log("thisstate", this.state)
        let { planName, planNameError, planType, planTypeError, euroAmount, euroAmountError, dollarAmount, dollarAmountError, euroVatAmount, euroVatAmountError, dollarVatAmount, dollarVatAmountError } = this.state
        const plainOptions = [{ displayValue: 'USD', value: 0 },{ displayValue: 'GBP', value: 1 }];
        const plainOptions2 = [{ displayValue: 'Rest of the World', value: 0 },{ displayValue: 'UK', value: 1 }];
        return (
            <>
                <div className="dash_body_container">
                    <div className="body_container">
                        <div className="header-container">
                            <div className="d-flex flex-grow-1 align-items-center">
                                <p className="header-icon"><UserManagementIcon /></p>
                                <h4>Subscription</h4>
                            </div>
                            <Link to={'/subscription-plan'} > <button className="btn btn-light"><i class="fa fa-arrow-left" aria-hidden="true"></i>Back</button></Link>
                        </div>
                        <div className="rounded-body-form">
                            <form className="rounded-textbox-form">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Subscrition Title</label>
                                            <input
                                                className="form-control"
                                                placeholder="enter here..."
                                                maxLength={256}
                                                name="planName"
                                                value={planName}
                                                onChange={this.handleOnChange}

                                            />
                                            <InlineError
                                                message={planNameError}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Subscrition Type</label>
                                            <select className="form-control" value={planType} name="planType" onChange={this.onSelect}>
                                                <option value="0">Free</option>
                                                <option value="1">Payable</option>
                                            </select>
                                            <InlineError
                                                message={planTypeError}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={`row add-sbs-Plan ${this.state.value}`}>
                                    <div className="col-md-12">
                                        <h5 className="adn-head">Add Subscrition Amount</h5>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>In GBP</label>
                                            <input
                                                className="form-control" placeholder="Enter GPB Amount"
                                                maxLength={10}
                                                name="euroAmount"
                                                value={euroAmount}
                                                onChange={this.handleOnChange}
                                            />
                                            <InlineError
                                                message={euroAmountError}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>In USD</label>
                                            <input
                                                className="form-control"
                                                placeholder="Enter USD Amount"
                                                name="dollarAmount"
                                                value={dollarAmount}
                                                onChange={this.handleOnChange}
                                            />
                                            <InlineError
                                                message={dollarAmountError}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <h5 className="adn-head">Apply Vat %age(applicable only in UK)</h5>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>GBP Vat %age</label>
                                            <input className="form-control"
                                                placeholder="Enter %age"
                                                name="euroVatAmount"
                                                value={euroVatAmount}
                                                onChange={this.handleOnChange}

                                            />
                                            <InlineError
                                                message={euroVatAmountError}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>USD Vat %age</label>
                                            <input className="form-control" placeholder="Enter %age"
                                                name="dollarVatAmount"
                                                value={dollarVatAmount}
                                                onChange={this.handleOnChange}

                                            />
                                            <InlineError
                                                message={dollarVatAmountError}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group d-grid">
                                            <level>Default Currency</level>
                                            <br />
                                            <Radio.Group name="validFor" defaultValue={this.state.defaultCurrency} onChange={this.onValidForSelect} value={this.state.defaultCurrency} >
                                                {plainOptions.map((item) => {
                                                    return (<><Radio className="radio-button-Style" value={item.value}> {item.displayValue}</Radio> <br /></>)

                                                })}
                                            </Radio.Group>

                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group d-grid">
                                            <level>Default Where are you based?</level>
                                            <br />
                                            <Radio.Group name="validFor" defaultValue={this.state.defaultVatText} onChange={this.onValidForSelect} value={this.state.defaultVatText} >
                                                {plainOptions2.map((item) => {
                                                    return (<><Radio className="radio-button-Style" value={item.value}> {item.displayValue}</Radio> <br /></>)

                                                })}
                                            </Radio.Group>

                                        </div>
                                    </div>

                                </div>
                                <div className="form-group text-md-right">
                                    <button type="button" disabled={this.state.isLoader} onClick={this.submit} className="btn btn-red">Save
                                    {
                                            this.state.isLoader && <MySpinner />

                                        }
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default AddSubscriptionPlan;