import React, { Component } from 'react';
import WebHeader from '../../layout/WebHeader';
import { Tabs } from 'antd';
import Footer from "../../layout/Footer";
import { Filter, SearchIcon, ZoomVideo } from "../../../SvgIcon";
import Connections from "../community/Connections";
import BusinessConnections from "../community/BusinessConnections";
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';

import { connect } from "react-redux";

import { Menu, Dropdown, Button } from 'antd';

import Thankyou from "../../home/HomeCommingSoon"

import Const from "../../../services/Const"


import { setLocalState } from '../../../redux/action';
import { appLocalState } from "../../../redux/reducer/getCart";
const { TabPane } = Tabs;

const {isHideCommunity} = Const
let isProd = process.env.REACT_APP_ENV == 'prod'

class Community extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedList: ["all"],
            all: true,
            selectedBusinessList: ["all"],
            selectedConnection: "1",
            isFilterShow: false,
            isBussinessFilterShow: false,
             isRefreshed:false,
            
            connectionFilter: {
                all: false,
                myConnection: false,
                pendingConnection: false,
                requestConnection: false,
                suggestionConnection: false,
                blockConnection: false
            },
            businessConnectionFilter: {
                all: false,
                myConnection: false,
                pendingConnection: false,
                requestConnection: false,
                suggestionConnection: false,
                blockConnection: false
            }

        }
    }

  
    componentDidMount() {
        let { appLocalState, dispatch, } = this.props;
        this.props.dispatch({ type: 'PATH_ADD', payload:this.props.match })
        console.log("kjhdsjk", JSON.parse(localStorage.getItem('filter_conn')))
        if(localStorage.getItem('filter_conn'))
        dispatch(setLocalState(JSON.parse(localStorage.getItem('filter_conn'))))
    }

    handleSelect = (e, key, stateData) => {
        localStorage.removeItem("filter_conn")
        let { appLocalState, dispatch, } = this.props;       
        if (key == "all") {
            Object.keys(appLocalState[stateData]).map(item => {

                appLocalState[stateData][item] = e.target.checked
            }
            )
        }
        else {
            appLocalState[stateData][key] = e.target.checked
            appLocalState[stateData]["all"] = false
        }

        // appLocalState.connectionFilter[key] = e.target.checked;
        dispatch(setLocalState(appLocalState));
        localStorage.setItem("filter_conn", JSON.stringify(appLocalState))      

    }

    // componentWillU(){
    //     let { appLocalState, dispatch, } = this.props;
    //     localStorage.setItem("filter_conn", JSON.stringify(appLocalState))
    // }


    tabChange = (e) => {
        this.setState({ selectedConnection: e, isRefreshed: !this.state.isRefreshed });
    }

    filterhideShow = (value) => {
        this.setState({ isFilterShow: value });
    }
    BusinessFilterHideShow = (value) => {
        this.setState({ isBussinessFilterShow: value });

    }



    render() {
        let isfilterVisisble = false;
        const isShow = localStorage.getItem("showAll") == "true"
        let filter = [
            { value: "all", displayValue: "All" },
            { value: "myConnection", displayValue: "My Connections" },
            { value: "pendingConnection", displayValue: "Received Invitations" },
            { value: "requestConnection", displayValue: "Sent Invitations" },
            { value: "suggestionConnection", displayValue: "Suggested Connections" },
            { value: "blockConnection", displayValue: "Blocked Connections" }
        ]
        if (this.state.selectedConnection == "2") {

            filter = [
                { value: "all", displayValue: "All" },
                { value: "myConnection", displayValue: "My Connections" },
                { value: "pendingConnection", displayValue: "Received Invitations" },
                { value: "requestConnection", displayValue: "Sent Invitations" }
            ]
            if (this.state["isBussinessFilterShow"]) {
                isfilterVisisble = true
            }

        }

        if (this.state.selectedConnection == "1" && this.state["isFilterShow"]) {
            isfilterVisisble = true;
        }

        // console.log("dlkjshgvfgvabs", this.state.selectedList, this.state.selectedBusinessList)
        const { connectionFilter, businsessFilter } = this.props.appLocalState;
        const { all, myConnection, requestConnection, suggestionConnection, pendingConnection, blockConnection } = connectionFilter;
        let activeStateData = this.state.selectedConnection == "2" && businsessFilter || connectionFilter;

        // console.log("dcgshjkl", connectionFilter)


        if (isHideCommunity)
        return <Thankyou />
    else
        return (


            <section className="brand-home-banner-bg cm-section">

               {/* <WebHeader history={this.props.history} match={this.props.match} /> */}
               {/*{     <ChatBox history={this.props.history} match={this.props.match} />}*/}
                <div className="container-fluid">
                    <div className="d-flex justify-content-around">

                        {/* <div className="header-text">
                        <p className="header-icon"><Filter /></p>
                    </div> */}
                        <div className="pos-relative w-100 connection_parent" >
                            {/* <Tabs onChange={(e) => this.tabChange(e)}>
                                <TabPane tab="Connections" key="1">
                                    <Connections isRefreshed = {this.state.isRefreshed} isProd = {isProd} filterHideShow={this.filterhideShow} scope={this} />
                                </TabPane>
                                <TabPane tab="Business Connections" key="2">
                                    <BusinessConnections isRefreshed = {this.state.isRefreshed} isProd = {isProd}  BusinessFilterHideShow={this.BusinessFilterHideShow} scope={this} />
                                </TabPane>
                            </Tabs>  */}
                           
                            {isfilterVisisble && <div className="cummunity-filter">

                                <Dropdown className="ml-2 commuty-wrpper-lst" overlayClassName="commuty-dropdown-lst" overlay={
                                    <Menu className="cummunity">
                                        {filter && filter.map(item => (
                                            <p>
                                                <div className="check_wrapper">
                                                    <input
                                                        type="checkbox"
                                                        checked={activeStateData[item.value]}
                                                        onChange={(e) => this.handleSelect(e, item.value, this.state.selectedConnection == "2" && "businsessFilter" || "connectionFilter")}
                                                    />
                                                    <span />

                                                </div>
                                                {item.displayValue}
                                            </p>


                                        ))}
                                    </Menu>
                                } placement="bottomRight" size="400px">
                                    <div className="profile">
                                        <span className="filter"><Filter /></span>
                                    </div>

                                </Dropdown>

                            </div>
                            }
                            <Connections isRefreshed = {this.state.isRefreshed} isProd = {isProd} filterHideShow={this.filterhideShow} scope={this} />
                         </div>
             

                    </div>

                </div>
                <Footer />
            </section>




        )
    }
}
function mapStateToProps(state) {
    return {
        appLocalState: state.appLocalState
    };
}

export default connect(mapStateToProps)(Community);
