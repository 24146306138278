import React from 'react'
import { CircleCross } from '../../../../SvgIcon';
import moment from 'moment-timezone';
import Util from '../../../../utils/Util';

const promocodeColumn = [
    { fieldName: "Activation Code", dataIndex: "title", type: "string" },
    { fieldName: "Maximum User", dataIndex: "numberofUser", type: "string" },
    { fieldName: "Valid from", dataIndex: "startDate", type: "date" },
    { fieldName: "Start Time", dataIndex: "startTime", type: "time" },
    { fieldName: "Valid to", dataIndex: "endDate", type: "date" },
    { fieldName: "End Time", dataIndex: "endTime", type: "time" },
    { fieldName: "Discount Type", dataIndex: "discountType", type: "string" },
    { fieldName: "Max Discount", dataIndex: "maximumDiscount", type: "string" },
    { fieldName: "Valid For", dataIndex: "validFor", type: "string", type: "string" },
    { fieldName: "Conference Name", dataIndex: "exhibitionData", subfieldName: "exhibitionName", type: "string" }
]




class ViewPromoDetail extends React.Component {
    constructor(props) {
        super();
    }
  
    getValue = (value, type, fieldName) => {
        console.log("AATulik St",  moment(1599579070000).format("MM-DD-YY"))
        console.log("AATulik End ",  moment(1599579065000).format("MM-DD-YY"))

        console.log("AATulik ss",  moment(1599579070000).format('dddd'), moment(1599579070000).format('LL'))
        console.log("AATulik er",  moment(1599579065000).format('dddd'),moment(1599579065000).format('LL'))


       

        switch (type) {
            case "date":
                value = value && moment(value.toString()).format("MM-DD-YY");
                break;
            case "time":
                value = (value == 0 && "12:00 AM") || (value && moment(value.toString(), 'HHmmss').format("LT")) || '';
                break;
            default:
                value = fieldName == "Discount Type" && (value == 1 ? "Fixed Value" : "Percentage") || value;
                break;
        }

        return value;
    }


    getRow = () => {

        let row = [], data = this.props.data;
        for (let i = 0; i < promocodeColumn.length; i += 2) {
            let firstRowDataValue = typeof (data[promocodeColumn[i].dataIndex]) == "object" && data[promocodeColumn[i].dataIndex][promocodeColumn[i].subfieldName] || data[promocodeColumn[i].dataIndex],
                secondRowDataValue = typeof (data[promocodeColumn[i + 1].dataIndex]) == "object" && data[promocodeColumn[i + 1].dataIndex][promocodeColumn[i + 1].subfieldName] || data[promocodeColumn[i + 1].dataIndex];
            row.push(
                <tr>
                    <th>{promocodeColumn[i].fieldName}</th>
                    <td>{
                        promocodeColumn[i].fieldName == "Valid For" && Util.getPurchaseValue(firstRowDataValue) || this.getValue(firstRowDataValue, promocodeColumn[i].type, promocodeColumn[i].fieldName)}</td>

                    <th>{promocodeColumn[i + 1].fieldName}</th>
                    <td>{
                        promocodeColumn[i + 1].fieldName == "Valid For" && Util.getPurchaseValue(secondRowDataValue) ||
                        this.getValue(secondRowDataValue, promocodeColumn[i + 1].type, promocodeColumn[i + 1].fieldName)}</td>
                </tr>
            )
        }
        return row;
    }

    render() {

        return (
            <div className='popup'>
                <div className='popup_inner user-report-popup'>
                    <div className="table-responsive no-shadow">
                        <table className="table vertical-table table-bordered rounded-table">
                            {this.getRow()}
                        </table>
                    </div>
                    <button className="close-btn" onClick={this.props.closePopup}><CircleCross /></button>

                </div>
            </div>
        );
    }
}

export default ViewPromoDetail