import {
    IS_LOADING_START,
    LOGIN_SUCCESS,
    IS_LOADING_STOP,
    LOG_OUT_SUCCESS,
    GET_ROLE_SUCCESS

} from './Type'
import siteSetting from '../../config/env/Index'
//api
import { _Api } from '../../services/Api';
//api url
import Url from '../../services/BaseUrl'
//loader
import Loader from '../../components/Loader'
//notification tooster
import { notify } from '../../components/Toaster'



// action for login
export const login = req => dispatch => {
    try {
        dispatch({ type: IS_LOADING_START })
        _Api(Url.USER_SIGNIN.method, Url.USER_SIGNIN.url, req)
            .then(resp => {             

                let payload = {
                    userProfile: resp.responseData.userProfile
                }
                localStorage.setItem("accessToken", resp.responseData.accessToken)
                localStorage.setItem("isLoggedIn", "true")
                if (resp.responseData.userProfile.userType == 1) {
                    this.props.history.push('/exhibiton')
                }

                dispatch({ type: LOGIN_SUCCESS, payload })

                dispatch({ type: IS_LOADING_STOP })

                notify("success", resp.responseData.message)


            }).catch(err => {
                dispatch({ type: IS_LOADING_STOP })

                if (err.error)
                    notify("err", err.error.responseMessage)
               // else if (err)
                //     // notify("err", err.message)

                if (err.errorCode == 2)
                    dispatch({ type: LOG_OUT_SUCCESS })



            })

    } catch (error) {
        notify("err", error.message)

    }

}




export const getRole = req => dispatch => {
    try {
        _Api(Url.GET_ROLE.method, Url.GET_ROLE.url, req)
            .then(resp => {   
                localStorage.setItem("checkStatus", resp?.responseData?.result?.userPlan)
                // console.log("psalhdjksadbj", resp?.responseData?.result?.userPlan)                       
                let payload = {
                    profileInfo: resp?.responseData?.result
                }
                dispatch({ type: GET_ROLE_SUCCESS, payload })
            }).catch(err => { })
    } catch (error) { }
}

