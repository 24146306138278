/**
 * @about this file contains action types
 * which type of action fire or which type
 * 
 */

// used for global loading and loader
export const IS_LOADING_START = "IS_LOADING_START";
export const IS_LOADING_STOP = "IS_LOADING_STOP";

export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS";

// local loading
export const IS_LOADER_START = "IS_LOADER_START";
export const IS_LOADER_STOP = "IS_LOADER_STOP";

//
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAILED = "GET_USER_PROFILE_FAILED";

export const GET_USER_CART_LIST_SUCCESS = "GET_USER_CART_LIST_SUCCESS";
export const GET_USER_CART_LIST_FAILED = "GET_USER_CART_LIST_FAILED";

export const GET_USER_WISH_LIST_SUCCESS = "GET_USER_WISH_LIST_SUCCESS";
export const GET_USER_WISH_LIST_FAILED = "GET_USER_WISH_LIST_FAILED";


// for login
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

// logout
export const IS_LOGGED_IN = "IS_LOGGED_IN";
export const IS_LOGGED_OUT = "IS_LOGGED_OUT"
export const LOG_OUT_SUCCESS = "LOG_OUT_SUCCESS"
export const LOG_OUT_FAILURE = "LOG_OUT_FAILURE"

//redux localstate
export const SET_LOCAL_STATE = "SET_LOCAL_STATE"
export const SET_LOCAL_STATE_QUESTION="SET_LOCAL_STATE_QUESTION"

// for notification 
export const GET_NOTIFICATION_SUCCESS ="GET_NOTIFICATION_SUCCESS"
export const GET_NOTIFICATION_FAILED ="GET_NOTIFICATION_FAILED"



export const GET_HOME_DATA ="GET_HOME_DATA"
export const GET_BRAND_DATA ="GET_BRAND_DATA"
export const GET_RECENTSEARCH_DATA ="GET_RECENTSEARCH_DATA"

export const GET_LOUNGE_DATA ="GET_LOUNGE_DATA"
export const GET_CONFERENCE_DATA ="GET_CONFERENCE_DATA"


export const GET_BRANDDETAIL_SUCCESS ="GET_BRANDDETAIL_SUCCESS"
export const GET_BRANDDETAIL_FAILED ="GET_BRANDDETAIL_FAILED"

export const GET_EXHIBITION_ID_SUCCESS ="GET_EXHIBITION_ID_SUCCESS"

export const SHOW_SUPPORT_CHAT ="SHOW_SUPPORT_CHAT"
export const HIDE_SUPPORT_CHAT ="HIDE_SUPPORT_CHAT"

export const SHOW_PLAN_USER_POPUP ='SHOW_PLAN_USER_POPUP'
export const HIDE_PLAN_USER_POPUP ='HIDE_PLAN_USER_POPUP'



