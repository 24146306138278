import React, { Component, Suspense } from 'react';
import { NoMediaICon } from "../../../SvgIcon"
import Footer from "../../layout/Footer";
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

const StageCard = React.lazy(() => import('../components/stages/StageCard'));

const antIcon = <LoadingOutlined style={{ fontSize: 72 }} spin />;
class WebHome extends Component {

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.carousel = React.createRef();

        this.state = {
            isMultiStage: false,
            isLoading: true,
            stageList: []
        }
        window.scrollTo(0, 0)
    }



    componentDidMount() {
        this.getHomeDetails()
        this.props.dispatch({ type: 'PATH_ADD', payload: this.props.match })
    }
    getHomeDetails = (e, id) => {
        _Api(Url.GET_EXHIBITION_ID.method, Url.GET_EXHIBITION_ID.url, '')
            .then(resp => {
                _Api(Url.GET_WEB_STAGE.method, Url.GET_WEB_STAGE.url, '', `?exhibitionId=${resp?.responseData?.exhibitionDetails?._id}`)
                    .then(resp => {
                        const { totalCount, data } = resp.responseData?.result
                        this.setState({ isLoading: false, stageList: data })
                    }).catch(err => {
                        this.setState({ isLoading: false })
                    })

            }).catch((err) => { })

    }



    next = () => {
        this.carousel.next();
    }
    previous = () => {
        this.carousel.prev();
    }



    handleJoin = (id) => {
        this.props.history.push(`/seminar/${id}`)
    }
    onlyJoin = (name, id, coId) => {
        this.props.history.push(`/seminar/${coId}/${name}/${id}`)
    }

    render() {
        const { stageList } = this.state

        if (this.state.isLoading)
            return (
                <section className="brand-home-banner-bg webhome">
                    <div className="centerLoader" >
                        <Spin size="large" tip="Loading..." indicator={antIcon} />
                    </div>
                </section>


            )
        else

            return (
                <section className="brand-home-banner-bg">
                    <Suspense fallback={<div></div>}>
                        <div className={"cm-section container-fluid"}>
                            <div className="cm-wrapper">
                                <div className="header-text">
                                    <h1 className="title">Stages</h1>
                                    <a > <button onClick={() => this.props.history.goBack()} className="btn btn-light"><i class="fa fa-arrow-left" aria-hidden="true"></i>Back</button></a>
                                </div>
                                <StageCard
                                    {...this}
                                    list={stageList}
                                />
                                {
                                    !this.state.isLoading && stageList.length == 0 &&
                                    <div className="w-100 text-center row nodata m-auto">
                                        <div className="m-auto">
                                            <NoMediaICon />
                                            
                                            <h2>No Data Found</h2>
                                        </div>
                                    </div>
                                }
                            </div>
                            <Footer />
                        </div>
                    </Suspense>
                </section>
            )
    }

}
// export default WebHome;
function mapStateToProps(state) {
    return {
        ...state
    };
}
export default connect(mapStateToProps)(WebHome);

