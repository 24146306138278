import React, { Component } from 'react';
import { Brands, Hert, ZoomVideo, Delete, Chat, Pencil, NoMediaICon, NewBadges } from '../../../SvgIcon';
import { CmBtn } from "../../../components/common/Button";
import ProductShowcase from "../../../components/common/ProductShowcase";
import Media from "../../../components/common/Media";
import Footer from "../../layout/Footer"
import Brochures from "../../../components/common/Brochures";
import { connect } from "react-redux";
import { getWishList, groupActions } from '../../../redux/action';
import { Tooltip } from "antd";

import { Link } from "react-router-dom";
import AddStaff from '../../admin/exhibiton_management/popups/AddStaff'
import EditProduct from '../../admin/exhibiton_management/popups/EditProduct';
import AddProduct from '../../admin/exhibiton_management/popups/AddProduct';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';
import InlineError from '../../../components/InlineError';
import ContentEditable from "react-contenteditable";

import Loader from "../../../components/Loader"

import { MySpinner } from "../../../components/index";

import { Spinner } from "react-bootstrap";

import { Menu, Dropdown } from "antd"

import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import socket from "../../../services/socket";

import Croper from "../../../components/Croper";

import LazyLoad from 'react-lazy-load';


import Const from "../../../services/Const"

const {manageProfile} = Const

const { confirm } = Modal;

var _ = require('lodash');

class BrandDetail extends Component {

    constructor() {
        super();
        this.state = {
            addStaff: false,
            addProduct: false,
            editStaff: false,
            page: '',
            limit: 10,
            productListData: [],
            StaffListData: [],
            staffId: '',
            loading: true,
            visible: false,
            selectedId: '',
            productId: '',
            brandInfo: '',
            about: '',
            isEdit: false,
            mediaData: [],
            coverLoader: false,
            logoLoader: false,
            loaderMedia: false,

            selectedItems: [],
            selectStaff: [],
            isCropper: false,
            copperType: '',
            brochureList: [],
            offerData: [],
            userData: {},

            visiableEnquire: '',
            message: '',

            isInfoPublic: true,
            isChatPublic: true,
            isVideoPublic: true,
            isMobilePublic: true,
            brochureData: [],

            messageError: ''

        };
        window.scrollTo(0, 0)
    }

    togglePopup(id, staffId) {
        console.log('produwertyuictId', id, staffId)
        if (id === "addStaff") {
            this.setState({
                addStaff: !this.state.addStaff,
            });
        }
        else if (id === "editStaff") {
            this.setState({
                editStaff: !this.state.editStaff, staffId: staffId
            });
        }

        else if (id === "editProduct") {
            this.setState({
                editProduct: !this.state.editProduct, productId: staffId
            });
        }
        else {
            this.setState({
                addProduct: !this.state.addProduct
            });
        }
    }
    componentDidMount() {
        let self = this
        this.props.dispatch({ type: 'PATH_ADD', payload: this.props.match })
        window.scrollTo(0, 0);
        this.handleAllMedia()
        this.getData();
        // this.props.dispatch(getBrandDetail( {BrandProfile : {brandId: "5f1a667c4bc85016ee28f126" }}))
        socket.on('connect', function (data) {
        });

        socket.on('online', function (params) {
            console.log("onlineeeeeeeeeeeee", params);
            self.changeUserStatus(params.user._id, 1);
        })

        socket.on('offline', function (params) {
            console.log("offline", params);
            self.changeUserStatus(params.user._id, 0);
        })

        this.getOfferDetails()       

        this.getAdditionalInfo()
        this.getBrochure()


    }

    getAdditionalInfo = () => {
        let self = this;
        let req = {
            requestUserId: this.props.match.params.id || localStorage.id || "",
        };
        _Api(
            Url.GET_COMPANY_NEW.method,
            Url.GET_COMPANY_NEW.url,
            "",
            `?companyId=${this.props.match.params.companyId}`
        )
            .then((resp) => {
                let responseData = resp.responseData?.result
                console.log('dhgasvhjk', responseData)
                this.setState({

                    userData: responseData,

                })

            })
            .catch((err) => {
            });
    }


    getOfferDetails = () => {
        this.setState({ isLoader: true })
        let { page, limit, search } = this.state
        let req = {
            page: page ? page : 1,
            limit: 100,
        }
        _Api(Url.GET_OFFER_LIST.method, Url.GET_OFFER_LIST.url, '', `?page=${req.page}&limit=${req.limit}&brandId=${this.props.match.params.brandId}`)
            .then(resp => {
                console.log('respexList', resp.responseData.result.data)
                this.setState({ offerData: resp.responseData.result.data, totalCount: resp.responseData.result.totalCount, isLoader: false })
            }).catch(err => {
                this.setState({ isLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
                this.setState({ isLoader: false })
            })
    }
















    changeUserStatus(userId, status) {
        let self = this;
        let users = this.state.StaffListData
        var checkUser = _.find(users, function (obj) {
            return obj._id === userId;
        });

        if (checkUser) {
            checkUser.isOnline = status;
            let userConnection = this.state.connectionList
            var index = _.findIndex(userConnection, { _id: checkUser._id });
            //  userConnection[index].userData =  checkUser 
            self.setState({
                connectionList: userConnection
            })
            console.log("sdafhgjaskld", { userConnection })

            // 
        }

        console.log("sdafhgjaskld", { checkUser, users, })
    }

    getStaffDetails = () => {
        // this.setState({ isLoader: true })
        let { page, limit, search } = this.state
        let req = {
            page: page ? page : 1,
            limit: limit,
        }
        _Api(Url.GET_STAFF_LIST_Details.method, Url.GET_STAFF_LIST_Details.url, '', `?page=${req.page}&limit=${req.limit}&brandId=${this.props.match.params.brandId}`)
            .then(resp => {
                console.log('respexList', resp.responseData.result.data)
                this.setState({ StaffListData: resp.responseData.result, isLoader: false })
            }).catch(err => {
                this.setState({ isLoader: false })
                // if (err.error)
                //     notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
                // this.setState({ isLoader: false })
            })
    }

    getData = () => {
        this.getBrandDetails()

    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.brandId !== this.props.match.params.brandId) {
            this.getData()
        }
    }
    // delete modal




    //Show modal
    showModal = (id) => {
        this.setState({
            visible: true,
            selectedId: id
        });

    };
    //Cancel modal
    handleCancel = () => {
        this.setState({ visible: false });
    };




    getBrandDetails = () => {
        _Api(Url.GET_BRANDPROFILE_DETAIL.method, Url.GET_BRANDPROFILE_DETAIL.url, { brandId: this.props.match.params.brandId })
            .then(resp => {
                console.log('jadnsklsandl', resp.responseData)

                this.setState({
                    loading: false,
                    brandInfo: resp.responseData.brandDetail,
                    productListData: resp.responseData.productList?.data,
                    StaffListData: resp.responseData.staffList?.result,
                    mediaData: resp.responseData.mediaList?.record,
                    brochureList: resp.responseData.brochureList?.record
                })

            }).catch(() => {
                this.setState({ loading: false })
                // if (err.error)
                //     // notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
            })
    }


    updateInfoBrand = () => {
        let formData = new FormData()
        formData.append('requestUserId', this.props.match.params.brandId)
        formData.append('logoImage', this.state.logoImg)
        formData.append('coverImage', this.state.coverImg)
        formData.append('about', this.state.about)


        _Api(Url.UPDATE_BRAND_INFO.method, Url.UPDATE_BRAND_INFO.url, formData)
            .then(() => {
                this.setState({ visible: false, isEdit: false, logoLoader: false, coverLoader: false, coverImg: '', logoImg: '' })
                this.getBrandDetails()
            }).catch(err => {
                this.setState({ visible: false, isEdit: false, logoLoader: false, coverLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
            })
    }

    // delete modal
    showDeleteConfirm = (id) => {
        confirm({
            title: 'Are you sure you want to delete this?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            onOk: () => {
                this.deleteStaff(id)
            },
            onCancel: () => {
                this.handleCancel()
            },
        });
    }



    //Cancel modal
    handleCancel = () => {
        // console.log('delete', resp.responseData.message)
        this.setState({ visible: false, });
    };

    handleEnq = () => {
        this.setState({
            visiableEnquire: false,
            isInfoPublic: true,
            isChatPublic: true,
            isVideoPublic: true,
            isMobilePublic: true,
            message: ''
        })
    }

    handleOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, })
    }

    //Create exhibition submit 
    submitMessage = () => {
        if (this.state.isInfoPublic == false && this.state.isVideoPublic == false && this.state.isChatPublic == false && this.state.isMobilePublic == false) {
            this.setState({ messageError: '*Please select at least one.' })

        }
        else {
            this.setState({ isLoader: true })
            let req = {
                // exhibitionName: exhibitionName,  
                enquiryReason: this.state.message,
                category: 2,
                isInfoPublic: this.state.isInfoPublic ? 1 : 2,
                isVideoPublic: this.state.isVideoPublic ? 1 : 2,
                isChatPublic: this.state.isChatPublic ? 1 : 2,
                isMobilePublic: this.state.isMobilePublic ? 1 : 2,
                brandId: this.props.match.params.brandId
            }

            _Api(Url.ADD_ENQUIRE_MESSAGE.method, Url.ADD_ENQUIRE_MESSAGE.url, req)
                .then(resp => {
                    this.setState({
                        isLoader: false, visiableEnquire: false, message: '',
                        isInfoPublic: true,
                        isChatPublic: true,
                        isVideoPublic: true,
                        isMobilePublic: true
                    })
                }).catch(err => {
                    this.setState({ isLoader: false })
                    if (err.error)
                        notify("err", err.error.responseMessage)
                    else if (err)
                        this.setState({ isLoader: false })
                })
        }

    }






    handleEdit = () => {
        this.setState({ isEdit: !this.state.isEdit })
    }

    handleMedia = (data) => {
        this.setState({ loaderMedia: true })
        const { media, url } = data
        let formData = new FormData()
        formData.append('brandId', this.props.match.params.brandId)
        formData.append('media', media ? media : '')
        formData.append('brandUrl', url ? url : '')
        _Api(Url.ADD_MEDIA.method, Url.ADD_MEDIA.url, formData)
            .then(resp => {
                this.setState({ loaderMedia: false })
                notify('success', resp.responseData.message)
                this.getMedia()
            }).catch(err => {
                this.setState({ loaderMedia: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
            })

    }

    getMedia = () => {
        _Api(Url.GET_MEDIA.method, Url.GET_MEDIA.url, '', `?brandId=${this.props.match.params.brandId}&limit=${3}&page=${1}`)
            .then(resp => {
                this.setState({
                    mediaData: resp.responseData.result
                })
                // this.setState({ visible: false, isEdit:false })

            }).catch(() => {
                // if (err.error)
                //     notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
            })

    }


    addToWishList = (event, category, id, api) => {
        event.preventDefault();
        event.stopPropagation();
        let stateName = `wish${id}`;
        this.setState({ [`isWishlistLoader${id}`]: true, [stateName]: !this.state[stateName] });
        let self = this;
        let params = category == "offer" ? { offerId: id } : category == "brand" ? { brandId: id } : { productId: id };
        let { method, url } = Url[api];
        _Api(method, url, params)
            .then(() => {
                self.props.dispatch(getWishList());
                this.getOfferDetails()
                this.getData();
                setTimeout(() => {
                    this.setState({ [`isWishlistLoader${id}`]: false });

                }, 300);


            }).catch((err) => {
                this.handleError(err);
                this.setState({ [`isWishlistLoader${id}`]: false });
            })
    }
    handleError(err) {
        let errorMessage = err.error && err.error.responseMessage || err.message;
        notify("err", errorMessage);
    }

    handleAllMedia = () => {
        localStorage.setItem("mediaType", "brandId")
        localStorage.setItem("mediaId", this.props.match.params.brandId)


    }

    onConnectRequest(e, requestId) {
        e.preventDefault();
        let oldArr = this.state.selectedItems
        oldArr.push(requestId)
        this.setState({ selectedItems: oldArr })

        let req = {
            requestedUserId: requestId,
            connectionType: 1
        }
        _Api(Url.COMMUNITY_CREATEREQUEST.method, Url.COMMUNITY_CREATEREQUEST.url, req)
            .then(resp => {
                this.getStaffDetails()
                setTimeout(() => this.setState({ selectedItems: this.state.selectedItems.filter(item => item != requestId) }), 1000)
                notify(resp.responseData.message);
            }).catch((err) => {
                this.setState({ selectedItems: [] })
                this.handleError(err);
            })

    }

    getConnectionStatusButton = (item, index) => {
        let toReturn;
        if (item?.userConnection) {
            switch (item.userConnection.status) {
                case 0:
                case "0":
                    toReturn = <><button className="btn btn-blue mt-2" bg='var(--connectBtn)' ><span className="zoom"></span>Pending</button> <button onClick={() => this.videoCalling(item._id, index)} className="btn btn-blue mt-2"><span className="zoom"><ZoomVideo /></span>Video Call</button></>
                    break;
                case 1:
                case "1":
                    toReturn = <button onClick={() => this.videoCalling(item._id, index)} className="btn btn-blue mt-2"><span className="zoom"><ZoomVideo /></span>Video Call</button>
                    break;
                case 2:
                case "2":
                    toReturn = <><CmBtn text="Connect" className="btn btn-blue mt-2" bg='var(--btncolor)' onClick={(e) => this.onConnectRequest(e, item?._id)} /> <button onClick={() => this.videoCalling(item._id, index)} className="btn btn-blue mt-2"><span className="zoom"><ZoomVideo /></span>Video Call</button></>
                    break;
            }
        }
        else {
            toReturn = <><CmBtn text="Connect" className="btn btn-blue" bg='var(--btncolor)' onClick={(e) => this.onConnectRequest(e, item?._id)} /><button onClick={() => this.videoCalling(item._id, index)} className="btn btn-blue mt-2"><span className="zoom"><ZoomVideo /></span>Video Call</button></>

        }
        return <span className="">{toReturn} </span>;
    }

    removeImage = (type) => {

        let formData = {
            requestUserId: this.props.match.params.brandId

        }
        if (type == 'logo')
            formData.logoImage = 1
        else
            formData.coverImage = 1

        if (type == 'logo') {
            this.setState({ logoImgPreview: '' })
        }
        else if (type == 'cover')
            this.setState({ coverImgPreview: '' })

        _Api(Url.DELETE_BRAND_IMG.method, Url.DELETE_BRAND_IMG.url, formData)
            .then(() => {
                this.setState({ visible: false, isEdit: false, logoLoader: false, coverLoader: false })
                this.getBrandDetails()
            }).catch(err => {
                this.setState({ visible: false, isEdit: false, logoLoader: false, coverLoader: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
            })
    }



    videoCalling = (userId) => {
        let self = this;

        if (userId) {
            this.setState({ startLoader: true })

            let oldArr = this.state.selectedItems
            oldArr.push(userId)

            self.setState({
                selectedItems: oldArr,

                ['videoConnectionLoder_' + userId]: true
            })
            self.props.dispatch(groupActions.joinOneMeeting({ userId: userId, type: 7 }))
                .then(function (res) {
                    self.setState({
                        ['videoConnectionLoder_' + userId]: false, startLoader: false,
                        selectedItems: self.state.selectedItems.filter(item => item != userId)
                    })
                    if (res.statusCode === 1 || res.statusCode === "1") {
                        //window.location.href = '/calling/' + self.props.groupDetail.meeting._id;
                        window.open('/calling/' + self.props.groupDetail.meeting._id, "professionalbeautyworldwide", "width=" + window.screen.width + ",height=" + window.screen.height)
                        //history.push('/calling/'+self.props.groupDetail.meeting._id)
                    } else {
                        self.setState({ startLoader: false, selectedItems: self.state.selectedItems.filter(item => item != userId) })

                    }
                    console.log("res", res)
                    // self.setState({ startLoader: false,  selectedItems: self.state.selectedItems.filter(item => item != userId) })
                })
        }


    }



    selectUser = (user) => {
        let self = this;
        // console.log("userrrrrr",user);
        if (this.props.userDetail._id !== user._id) {
            this.setState({
                selectedUser: user,
                selectedUserId: user._id,
                chatType: 3,
                isShow: true
            }, function () {
                self.props.dispatch({ type: 'ACTIVE_CHAT', activeChatType: 3 })
                self.props.dispatch({ type: 'CHAT_OPEN' })
                document.body.classList.toggle('chat_open_mob');
                self.props.dispatch({ type: 'SELECTED_USER', selectedUser: user })
                socket.emit('groupAdd', { type: 7, userId: user._id });
                //socket.emit('userConnection', {userId: user._id});
            })
        }


    }

    finalCropedImage = (categoryImgPrev, categoryImg, visibleVal) => {
        console.log("ldjshgvhbjkd", { categoryImgPrev, categoryImg, visibleVal })
        if (this.state.copperType == "cover") {
            this.setState({
                visibleVal,
                isCropper: false,
                coverImgPreview: categoryImgPrev,
                coverImg: categoryImg

            }, () => this.updateInfoBrand())
        }
        else {
            this.setState({
                visibleVal,
                isCropper: false,
                logoImgPreview: categoryImgPrev,
                logoImg: categoryImg

            }, () => this.updateInfoBrand())

        }
    }


    handleCropperOpen = (type) => {
        console.log("dsajhgfdghjkas", type)
        this.setState({ copperType: type, isCropper: true })

    }

    onStafStatusChange = (e, item) => {
        let id = item._id
        let oldArr = this.state.selectStaff
        oldArr.push(id)

        this.setState({ selectStaff: oldArr })

        let req = {
            requestId: item._id,
            isHide: item.isHide ? item.isHide == 1 ? 2 : 1 : 2,
        }
        _Api(Url.STAFF_ENABLE_DISABLE.method, Url.STAFF_ENABLE_DISABLE.url, req)
            .then(() => {
                this.getStaffDetails()
                this.setState({ visible: false, selectStaff: this.state.selectStaff.filter(item => item != id) })

            }).catch(err => {
                this.setState({ visible: false, selectStaff: this.state.selectStaff.filter(item => item != id) })
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
            })

    }

    getBrochure = () => {
        _Api(Url.GET_BROCHURE.method, Url.GET_BROCHURE.url, '', `?brandId=${this.props.match.params.brandId}`)
            .then(resp => {

                this.setState({
                    brochureData: resp.responseData.result
                })

            }).catch(err => {

            })
    }

    render() {

        // console.log("lskjhgafdghjk")

        const {
            facebookLink,
            instagramLink,
            linkedinLink,
            phoneNumber,
            twitterLink,
            youtubeLink,
            companyEmail,
            companyWebsite,
            companyName
        } = this.state.userData || {}

        console.log("dkjhgsdhbasjklm", this.state.userData)



        let { offerData, selectStaff, selectedItems, StaffListData, productListData, loading, brandInfo } = this.state;
        let userType = localStorage.getItem("userType");
        let userId = localStorage.getItem("id");
        let isFromVeiwProfile = userType == 1 ? false : !this.props.history.location.pathname.includes("profile");

        return (

            <section className={`brand-home-banner-bg brandprofile-banner-bg ${userType == 1 ? "brand dash_body_container withsidenavbar" : ''} `}>
                {/* {userType != 1 &&{/* <WebHeader history={this.props.history} match={this.props.match} /> */}
                {/*{userType != 1 && <ChatBox history={this.props.history} match={this.props.match} />}*/}



                {this.state.loaderMedia && <Loader />}
                <div className="container-fluid brandhome">
                    <div className="header-text  mb-4 pt-3">
                        <div className="d-flex align-items-center">
                            <p className="header-icon"><Brands /></p>

                            <h4>{brandInfo?.companyName}</h4>
                        </div>

                        {/* <a > <button onClick={()=> this.props.history.goBack()} className="btn btn-light"><i class="fa fa-arrow-left" aria-hidden="true"></i>Back</button></a> */}


                    </div>
                    <div className=" mt-3">
                        {!loading && <div className="pos-relative">
                            <div className="professional-beauty-banner-wrapper">
                                <img className={`img-fluid w-100 banner ${this.state.coverImgPreview ? '': brandInfo?.coverImage ? '' : 'placeholder' }`} src={this.state.coverImgPreview ? this.state.coverImgPreview : brandInfo?.coverImage ? brandInfo?.coverImage : require('../../../assets/images/profile-placeholder.png')} />
                                <div className="top-right d-flex">
                                    <button onClick={() => this.setState({ visiableEnquire: true })} className="btn btn-blue" >Enquire Now</button>&nbsp;&nbsp;
                                    {
                                        < div className={(brandInfo?.wishListData) && "blue-circle red-circle" || "blue-circle"} onClick={(e) => this.addToWishList(e, 'brand', brandInfo?._id, brandInfo?.wishListData && "REMOVE_WISHLIST" || "ADD_WISHLIST")}>
                                            {this.state[`isWishlistLoader${brandInfo?._id}`] &&
                                                <Spinner className="text-center" size="xl" animation="border" variant="primary" /> || <i className="fa fa-heart-o" ></i>}

                                        </div>}
                                </div>


                            </div>

                            <div className="brand-logo-parent">
                                <img className="img-fluid w-100 banner" src={this.state.logoImgPreview ? this.state.logoImgPreview : brandInfo?.logoImage ? brandInfo?.logoImage : require('../../../assets/images/placeholder.png')} />

                            </div>



                        </div>}

                        <br /><br />
                        {(!(brandInfo?.about == undefined || brandInfo?.about == '') || youtubeLink || facebookLink || companyWebsite || linkedinLink || instagramLink) && <div className="company-profile mt-5"><h1 className="home-heading pos-relative mb-5 text-center">Company Profile
                                  <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                        </h1>
                            <div className="custom-icons">
                                <ul className="list-unstyled mb-0 text-center">
                                    {companyEmail &&
                                        <li className="list-inline-item">
                                            <a target="_blank" href={`mailto:${companyEmail}`}>
                                                <i class="fa fa-envelope-o" aria-hidden="true"></i>
                                                {/* <span>{companyEmail}</span> */}
                                            </a>

                                        </li>
                                    }
                                    {phoneNumber &&
                                        <li className="list-inline-item">
                                            <a target="_blank" href={`tel:${phoneNumber}`} className="d-flex align-items-center">
                                                <i class="fa fa-phone" aria-hidden="true"></i>
                                                <span>{phoneNumber}</span>
                                            </a>
                                        </li>
                                    }
                                    {companyWebsite && <li className="list-inline-item">
                                        <a target="_blank" href={companyWebsite}>
                                            <i class="fa fa-globe" aria-hidden="true"></i>
                                        </a>
                                    </li>}
                                    {linkedinLink && <li className="list-inline-item">
                                        <a target="_blank" href={linkedinLink}>
                                            <i class="fa fa-linkedin-square" aria-hidden="true"></i>
                                        </a>
                                    </li>}
                                    {twitterLink && <li className="list-inline-item">
                                        <a target="_blank" href={twitterLink}>
                                            <i class="fa fa-twitter" aria-hidden="true"></i>
                                        </a>
                                    </li>}
                                    {facebookLink && <li className="list-inline-item">
                                        <a target="_blank" href={facebookLink}>
                                            <i class="fa fa-facebook-official" aria-hidden="true"></i>
                                        </a>
                                    </li>}
                                    {youtubeLink && <li className="list-inline-item">
                                        <a target="_blank" href={youtubeLink}>
                                            <i class="fa fa-youtube" aria-hidden="true"></i>
                                        </a>
                                    </li>}
                                    {instagramLink &&
                                        <li className="list-inline-item">
                                            <a target="_blank" href={instagramLink}>
                                                <i class="fa fa-instagram" aria-hidden="true"></i>
                                            </a>
                                        </li>
                                    }
                                </ul>
                            </div>

                            {/* {
                                (brandInfo?.about == undefined || brandInfo?.about == '') &&
                                <div className="w-100 text-center row nodata m-auto">
                                    <div className="m-auto">
                                        <NoMediaICon />
                                        
                                        <h2>No Data Found</h2>
                                    </div>
                                </div>
                            } */}

                            {!(brandInfo?.about == undefined || brandInfo?.about == '') && <div className="profile-edit">
                                {/* <pre>{brandInfo?.about} {!this.state.isEdit} </pre> */}
                                <div className="row">
                                    <div className="col-md-8 offset-md-2">
                                        <ContentEditable
                                            html={brandInfo?.about} // innerHTML of the editable div
                                            disabled={true} // use true to disable edition
                                        // onChange={this.handleChange} // handle innerHTML change
                                        />
                                    </div>
                                </div>
                            </div>}

                        </div>}
                    </div>


                    {
                        // localStorage.getItem("userType") != 4 &&
                        <>

                            <div className="staff mt-5">
                                <h1 className="home-heading pos-relative mb-5 text-center">Staff on the Stand
                          <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                                </h1>
                                <div className="row ">
                                    {StaffListData?.filter(staff => staff?.isHide == 1 || staff?.isHide == undefined).map((item, index) =>
                                        (
                                            <div className="four-div">
                                                <div className="card community-card staff-container">
                                                    <div className="row ">
                                                        <div className=" imgparent pr-0">
                                                            <div className="img-wrapper pos-relative image-chat">
                                                                <img src={item?.thumbUrl ? item?.thumbUrl : require('../../../assets/images/placeholder.png')} />
                                                                {/* {item?.userConnection?.status == "1" && <span
                                                                    onClick={() => this.selectUser(item)}
                                                                    className="chat cursour-pointer"><Chat /></span>} */}
                                                                {item._id != userId &&
                                                                    <span
                                                                        onClick={() => this.selectUser(item)}
                                                                        className={`chat cursor-point ${item.isOnline == 1 ? 'green' : 'yellow'}`} >
                                                                        <Chat /></span>}
                                                            </div>
                                                        </div>
                                                        <div className=" textparent no-gutter d-flex align-items-start flex-column">
                                                            <Tooltip placement="top" title={`${item?.firstName} ${item?.lastName}`}>
                                                                {
                                                                    (userType == 1 || this.props.match.params.brandId == userId) ?
                                                                        <h4><strong>{item?.firstName} {item?.lastName}</strong></h4>
                                                                        :
                                                                        <Link to={`/user/profile/${item?._id}`}> 
                                                                         <h4><strong>{item?.firstName} {item?.lastName}</strong></h4>
                                                                         </Link>
                                                                }
                                                            </Tooltip>
                                                            <span className="descript mb-2"><small >{item?.jobTitleOther ? item?.jobTitleOther : item?.jobTitleId}</small></span>
                                                            <div>

                                                                {userType == 1 ? null : selectedItems.indexOf(item._id) == -1 ? item._id != userId ? this.getConnectionStatusButton(item, index) : '' : <MySpinner />}
                                                                {!isFromVeiwProfile && <span className="action_group  mt-3">
                                                                    {
                                                                        selectStaff.indexOf(item._id) == -1 ?
                                                                            <p className="toggle-parent ">
                                                                                <label className="switch">
                                                                                    <input type="checkbox"
                                                                                        checked={item.isHide ? item.isHide == 1 ? true : false : true}
                                                                                        onChange={(e) => this.onStafStatusChange(e, item)}
                                                                                    />
                                                                                    <span className="slider round"></span>
                                                                                </label>

                                                                            </p>
                                                                            :
                                                                            <MySpinner />
                                                                    }
                                                                    {
                                                                        console.log("jhgcjkdsml", localStorage.getItem("ownerId") != item._id)
                                                                    }
                                                                    <a onClick={() => this.togglePopup('editStaff', item)}> <p><p className="circle-serial" ><Pencil /></p></p> </a>
                                                                    {/* <p><p className="circle-serial" ><Pencil /></p></p> */}
                                                                    {(item._id != localStorage.id && localStorage.getItem("ownerId") != item._id) && <a onClick={() => this.showDeleteConfirm(item?._id)}> <p><p className="circle-serial" ><Delete /></p></p></a>}
                                                                </span>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )
                                    }
                                    {StaffListData && StaffListData.length == 0 &&
                                        <>
                                            <div className="w-100 text-center row nodata m-auto">
                                                <div className="m-auto">
                                                    <NoMediaICon />
                                                    
                                                    <h2>No Data Found</h2>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>

                            </div>
                        </>

                    }


                    {productListData?.length != 0 && <div className="produçt-showcase mt-5">
                        <h1 className="home-heading pos-relative mb-5 text-center">{manageProfile.h1}
                          <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                        </h1>

                        <div className="cm-wrapper featured-brands showcase row mb-3">
                            {
                                productListData?.map((item, index) =>
                                    // col-xl-2 col-lg-3 col-md-4 col-sm-6
                                    <div key={index} className=" product-showcase-devide">
                                        <Link to={`/web-job-details/${item._id}`} >
                                            <div className="card brand-card mb-5">
                                                <LazyLoad>
                                                    <img className="img-fluid" src={item?.image || require('../../../assets/images/placeholder.png')} />
                                                </LazyLoad>
                                                {item?.brandId?.companyName &&
                                                    <div className="top-left">
                                                        <button className="btn btn-card">{item?.brandId?.companyName}</button>
                                                    </div>
                                                }
                                                 {item?.isNewProduct == 1 && <div className="newBadge">
                                                            <p> New<span className="traingle"><NewBadges /></span></p>
                                                        </div>}
                                                <div className="top-right">
                                                    {
                                                        < div className={(item?.wishListData) && "blue-circle red-circle" || "blue-circle"} onClick={(e) => this.addToWishList(e, 'product', item?._id, item?.wishListData && "REMOVE_WISHLIST" || "ADD_WISHLIST")}>
                                                            {this.state[`isWishlistLoader${item?._id}`] &&
                                                                <Spinner className="text-center" size="xl" animation="border" variant="primary" /> || <i className="fa fa-heart-o" ></i>}

                                                        </div>}

                                                </div>
                                                <hr />
                                                <div className="action_group text-center">
                                                    <Tooltip placement="top" title={item?.productName}>
                                                        <span className="wrap-p2">{item?.productName} <br />
                                                            {/* <span className="blue">${item?.price || 0}</span> */}
                                                        </span>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>

                                )
                            }

                            {
                                productListData?.length == 0 &&

                                <div className="w-100 text-center row nodata m-auto">
                                    <div className="m-auto">
                                        <NoMediaICon />
                                        
                                        <h2>No Data Found</h2>
                                    </div>                    </div>

                            }


                        </div>
                        {/* <div className="col-sm-12 text-center">
                            {productListData?.length > 0 && <Link to={`/business/product/${this.props.match.params.brandId}`} className="btn btn-red addbtn">View All </Link>}
                        </div> */}


                        {/* <ProductShowcase
                            {...this.props}
                            productListData={productListData}
                            statusProduct={this.productStatus}
                            deleteProduct={this.deleteProduct}
                            editSuccess={this.getProductDetails}
                            isFromVeiwProfile={isFromVeiwProfile}
                            addToWishList={this.addToWishList}
                            scope={this}

                        /> */}
                        {!isFromVeiwProfile && <div className="row">
                            <div className="col-sm-12 text-center">
                                {/* <button type="button" className="btn btn-red addbtn addbtn-gray">View All Products</button> */}
                                {/* {userType != 1 && <button type="button" onClick={this.togglePopup.bind(this)} className="btn btn-red addbtn">Add Products</button>} */}
                            </div>
                        </div>
                        }
                    </div>
                    }



                    {offerData?.length != 0 && <div className="produçt-showcase mt-5">
                        <h1 className="home-heading pos-relative mb-5 text-center">{manageProfile.h2}
                          <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                        </h1>

                        <div className="cm-wrapper featured-brands showcase row mb-3">
                            {
                                offerData.map((item, index) =>
                                    // col-xl-2 col-lg-3 col-md-4 col-sm-6
                                    <div key={index} className=" product-showcase-devide">
                                        <Link to={`/web-training-details/${item._id}`} >
                                            <div className="card brand-card new-product mb-5">
                                                <LazyLoad>
                                                    <img className="img-fluid" src={item?.image || require('../../../assets/images/placeholder.png')} />
                                                </LazyLoad>

                                                <div className="top-right">
                                                    {
                                                        < div className={(item?.wishListData) && "blue-circle red-circle" || "blue-circle"} onClick={(e) => this.addToWishList(e, 'offer', item?._id, item?.wishListData && "REMOVE_WISHLIST" || "ADD_WISHLIST")}>
                                                            {this.state[`isWishlistLoader${item?._id}`] &&
                                                                <Spinner className="text-center" size="xl" animation="border" variant="primary" /> || <i className="fa fa-heart-o" ></i>}

                                                        </div>}

                                                </div>
                                                <hr />
                                                <div className="action_group text-center">
                                                    <div className="w-100">
                                                        <Tooltip placement="top" title={item?.name}>
                                                            <span className="wrap-p2">{item?.name} <br />
                                                                {/* <span className="blue">${item?.price || 0}</span> */}
                                                            </span>
                                                        </Tooltip>
                                                        <hr className="w-100 mb-2" />
                                                        {item?.brandId?.companyName &&
                                                            <Tooltip placement="top" title={item?.brandId?.companyName}>
                                                                <h6 className="wrap-p1 w-100"><i><Brands /></i>{item?.brandId?.companyName}</h6>
                                                            </Tooltip>

                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                        </Link>
                                    </div>

                                )
                            }

                            {
                                offerData?.length == 0 &&

                                <div className="w-100 text-center row nodata m-auto">
                                    <div className="m-auto">
                                        <NoMediaICon />
                                        
                                        <h2>No Data Found</h2>
                                    </div>                    </div>

                            }


                        </div>

                        {/* <div className="col-sm-12 text-center">
                            {productListData?.length > 0 && <Link to={`/business/product/${this.props.match.params.brandId}`} className="btn btn-red addbtn">View All </Link>}
                        </div> */}


                        {/* <ProductShowcase
                            {...this.props}
                            productListData={productListData}
                            statusProduct={this.productStatus}
                            deleteProduct={this.deleteProduct}
                            editSuccess={this.getProductDetails}
                            isFromVeiwProfile={isFromVeiwProfile}
                            addToWishList={this.addToWishList}
                            scope={this}

                        /> */}


                    </div>
                    }
                    {this.state.mediaData?.length != 0 && <div className="images-video mt-5">
                        <h1 className="home-heading pos-relative mb-5 text-center">Images/ Videos
                          <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                        </h1>
                        <Media
                            {...this.props}
                            mediaData={this.state.mediaData}
                            getMedia={(data, url) => this.handleMedia(data)}
                            mediaType="brandId"
                            mediaId={this.props.match.params.brandId}
                            onDeleteMedia={this.getMedia}
                            isFromVeiwProfile={isFromVeiwProfile}
                            isVisitor={true}
                        />


                        {/* {this.state.mediaData?.length != 0 && <div className="row">
                            <div className="col-sm-12 text-center">
                                <button onClick={this.handleAllMedia} type="button" className="btn btn-red addbtn">View All Media</button>
                                <button onClick={this.handleAllMedia} type="button" className="btn btn-red addbtn addblue">+ Add</button>
                            </div>
                        </div>} */}
                    </div>}

                    {this.state.brochureData.length != 0 && <div className=" mt-5">
                        <h1 className="home-heading pos-relative mb-5 text-center">{manageProfile.h3}
                            <span className="heading-after-border heading-after-border-center"><span className="heart"><Hert /></span></span>
                        </h1>
                        <Brochures
                            {...this.props}
                            isFromVeiwProfile={isFromVeiwProfile}

                        />
                        <br />

                    </div>}

                </div>

                {userType != 1 && <Footer />}


                {
                    this.state.addStaff ?
                        <AddStaff
                            {...this.props}
                            text='Close Me'
                            closePopup={this.togglePopup.bind(this, "addStaff")}
                            getStaff={this.getStaffDetails}
                        />
                        : null
                }

                {
                    this.state.addProduct ?
                        <AddProduct
                            {...this.props}
                            text='Close Me'
                            getProductDetails={this.getProductDetails}
                            closePopup={this.togglePopup.bind(this, "addProduct")}
                        />
                        : null
                }

                {
                    this.state.editStaff ?
                        <AddStaff
                            {...this.props}
                            text='Close Me'
                            closePopup={this.togglePopup.bind(this, "editStaff")}
                            getStaff={this.getStaffDetails}
                            isEdit={true}
                            staffData={this.state.staffId}
                        />
                        : null
                }
                {
                    this.state.editProduct ?
                        <EditProduct
                            text='Close Me'
                            closePopup={this.togglePopup.bind(this, "editProduct")}
                            productId={this.state.productId}
                        />
                        : null
                }

                {this.state.isCropper ?
                    <Croper aspect={2.5 / 1}
                        // onImageCrop={img => this.setState({ media: img })}
                        imgPrev={'2.5-1.png'}
                        finalCropedImage={(categoryImgPrev, categoryImg, visibleVal) => this.finalCropedImage(categoryImgPrev, categoryImg, visibleVal)} visibleVal={this.state.isCropper}
                        onClose={() => this.setState({ isCropper: false })}
                    />
                    : ""}

                <Modal
                    wrapClassName="signup_parent_modal"
                    className="cm_popup signup_modal "
                    visible={this.state.visiableEnquire}
                    onOk={this.handleEnq}
                    onCancel={this.handleEnq}
                    footer={null}
                    centered
                    maskClosable={false}
                >
                    <div className="sign-modal-content">
                        <div className="row align-items-center">
                            <div className="col-md-12" >
                                {/* <h3 className="auth-heading pos-relative text-center mt-5">
                                Reason for enquiry
                                    <span className="heading-after-border heading-after-border-center">
                                    <span className="heart"><Hert /></span>
                                </span>
                            </h3> */}
                                <form className="rounded-textbox-form mt-5" >
                                    <div className="form-group">
                                        <h5><b>What is the reason for your enquiry?</b></h5>
                                        <textarea rows="4" placeholder="Please Enter, What is the reason for your enquiry?"
                                            className="form-control hi"
                                            name="message"
                                            value={this.state.message}
                                            onChange={this.handleOnChange}
                                        ></textarea>
                                        {/* <InlineError
                                                message={firstNameError}
                                            /> */}
                                        <h5 className="mt-4 mb-1"><b>How would you like to be contacted?</b></h5>
                                        <h5><b>uncheck those that do not apply</b></h5>


                                        <div>
                                            <label class="form-check-label ml-4">
                                                <input class="form-check-input" type="checkbox"
                                                    name="remember"
                                                    checked={this.state.isMobilePublic}
                                                    onClick={() => this.setState({ messageError: '', isMobilePublic: !this.state.isMobilePublic })}
                                                /> Phone
                                    </label>
                                        </div>
                                        <div>
                                            <label class="form-check-label ml-4">
                                                <input class="form-check-input" type="checkbox"
                                                    name="remember"
                                                    checked={this.state.isInfoPublic}
                                                    onClick={() => this.setState({ messageError: '', isInfoPublic: !this.state.isInfoPublic })}
                                                /> Email
                                    </label>
                                        </div>
                                        <div>
                                            <label class="form-check-label ml-4">
                                                <input class="form-check-input" type="checkbox"
                                                    name="remember"
                                                    checked={this.state.isChatPublic}
                                                    onClick={() => this.setState({ messageError: '', isChatPublic: !this.state.isChatPublic })}
                                                /> Chat
                                    </label>
                                        </div>
                                        <div>
                                            <label class="form-check-label ml-4">
                                                <input class="form-check-input" type="checkbox"
                                                    name="remember"
                                                    checked={this.state.isVideoPublic}
                                                    onClick={() => this.setState({ messageError: '', isVideoPublic: !this.state.isVideoPublic })}

                                                /> Video Call
                                    </label>
                                            <InlineError
                                                message={this.state.messageError}
                                            />
                                        </div>
                                        <h6 className="mt-3"><b>Note- </b>by checking phone or email you are agreeing to share the contact information recorded in your profile to the showcase holder</h6>
                                        {/* <label class="form-check-label ml-4 fs-12 mt-3">
                                    <input class="form-check-input mt-1" type="checkbox" name="remember"
                                        checked={this.state.isInfoPublic}
                                        onClick={() => this.setState({ isInfoPublic: !this.state.isInfoPublic })}/>
                                   
                                    </label> */}
                                    </div>
                                    <div class="text-center">

                                        <button type="button" onClick={this.submitMessage} class="btn btn-red"
                                            disabled={this.state.isLoader}

                                        > &nbsp;Submit Enquiry &nbsp;
                                    {this.state.isLoader &&
                                                <MySpinner />}


                                        </button>
                                    </div>
                                    {/* <div class="form-group form-check text-center">
                                    <Link to="">Skip</Link>
                                </div> */}
                                </form>
                            </div>
                        </div>
                    </div>
                </Modal>
            </section >




        )
    }
}


function mapStateToProps(state) {
    return {
        ...state,
        wishList: state.wishList

    };
}

export default connect(mapStateToProps)(BrandDetail);
