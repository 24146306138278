import React, { Component } from 'react';
import { render } from "react-dom";
import { Brands } from '../../../../../SvgIcon';
import { Link } from "react-router-dom";
import { Empty } from 'antd';
import { _Api } from '../../../../../services/Api';
import Url from '../../../../../services/BaseUrl';
import { notify } from '../../../../../components/Toaster';
import Sidebar from '../../../../layout/Sidebar';
import Header from '../../../../layout/Header';
import { Pagination } from '../../../../../components/common/Pagination';
import { Delete } from '../../../../../SvgIcon';
// import ExampleBoard from 'react-virtualized-dnd';
import util from '../../../../../utils/Util.js';
import Loader from '../../../../../components/Loader';
const style = {

};

class ProductList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isDeleted: '',
            exhibtionId: '',
            status: "",
            page: 1,
            limit: 5000,
            loading: false,
            visible: false,
            selectedId: '',
            exhibitionListData: [],
            isLoader: false,
            search: '',
            productListData: [],
            isEditExhibition: false,
            snNo: 0,
            assignProductIds: [],
            items: Array.from({ length: 20 }),
            selectedArr:[]

        }
    }
    componentDidMount() {
        this.getProductList()
    }

    /** brands start */

    getProductList = (isLoader = true) => {
        this.setState({ isLoader: isLoader })
        let { page, limit, search } = this.state
        let req = {
            page: page ? page : 1,
            limit: limit,
            search: search.toString(),
            isNewProduct:this.props?.match?.params?.type
        }

        _Api(Url.GET_PRODUCT_LIST_NEW.method, Url.GET_PRODUCT_LIST_NEW.url, '', `?page=${req.page}&limit=${req.limit}&status=1&search=${req.search}&isNewProduct=${req.isNewProduct}&conferenceId=${this.props?.match?.params?.coId ? this.props?.match?.params?.coId : ''}`)
            .then(resp => {
                this.setState({ productListData: resp.responseData?.result?.data, totalCount: resp.responseData?.result?.totalCount, isLoader: false })

            }).catch(() => {
                this.setState({ isLoader: false });
            })
    }

    handlePageChange = page => this.setState({ loader: true, page }, () => this.getProductList());

    handleSearch = e => this.setState({ search: e.target.value, productListData: [], page: 1, snNo: 0 }, () => this.getProductList());




    //Cancel modal
    handleCancel = () => {
        // console.log('delete', resp.responseData.message)
        this.setState({ visible: false });
    };
    handleSelect = (id, item) => {
        console.log("dkjhgfdhjksahb", item)
        let oldArr = this.state.selectedArr
        console.log("dkjhgfdhjksahb", oldArr.findIndex(item => item?._id == id))
        if (oldArr.findIndex(item => item?._id == id) == -1) {
            let obj = {
                exhibitionId: this.props?.match?.params?.exId,                    
                type: this.props?.match?.params?.type == 1 ? 4 : 2,         
                conferenceId: this.props?.match?.params?.coId
            }
            if(this.props?.match?.params?.type == 1)
                obj.newProductId = id             
            else
                obj.productId = id                
            
            oldArr.push(obj)
            this.setState({ selectedArr: oldArr })
        }
        else {
            this.setState({ selectedArr: this.state.selectedArr.filter(item => item?._id != id) })

        }

    }

    // handleSelect = (e, id, isFeatured) => {
    //     if (isFeatured && !e.target.checked) {
    //         let params = {
    //             product: [id],
    //             isFeatured: 2

    //         }
    //         _Api(Url.FEATURED_PRODUCT_FEATURE_UPDATE.method, Url.FEATURED_PRODUCT_FEATURE_UPDATE.url, params)
    //             .then(resp => {
    //                 // notify("success", "Feature removed Successfully");
    //                 this.getProductList();
    //             }).catch(err => {
    //                 let errorMessage = err.error && err.error.responseMessage || err.message;
    //                 // notify("err", errorMessage);

    //             })
    //     }


    //     if (e.target.checked) {
    //         this.state.assignProductIds.push(id);
    //     }
    //     else {
    //         var index = this.state.assignProductIds.indexOf(id);
    //         if (index > -1) {
    //             this.state.assignProductIds.splice(index, 1);
    //         }
    //     }
    //     let checkboxFiield = 'checkbox' + id
    //     this.setState({ [checkboxFiield]: e.target.checked })

    // }



    onPressBtn = (page) => {
        this.setState({ page, snNo: page - 1, productListData: [] }, () => {
            this.getProductList()
        })

    }


    onClickAssign = () => {
        let req = {
            spotLightData: this.state.selectedArr
        }
        this.setState({ loading: true })
        _Api(Url.ASSIGN_BRAND_SPOTLIGHT.method, Url.ASSIGN_BRAND_SPOTLIGHT.url, req)
            .then(resp => {
                this.setState({ loading: false })
                this.props.history.goBack()
            }).catch(err => {
                this.setState({ loading: false })
                if (err.error)
                    notify("err", err.error.responseMessage)
                // else if (err)
                //     // notify("err", err.message)
            })
    }


    // onClickAssign = () => {
    //     let { assignProductIds } = this.state;
    //     let params = {
    //         product: assignProductIds,
    //         isFeatured: 1

    //     }

    //     if (assignProductIds.length > 0) {
    //         _Api(Url.FEATURED_PRODUCT_FEATURE_UPDATE.method, Url.FEATURED_PRODUCT_FEATURE_UPDATE.url, params)
    //             .then(resp => {
    //                 notify("success", "Feature Added Successfully");
    //                 this.props.history.push("/feature-joblist")

    //             }).catch(err => {
    //                 let errorMessage = err.error && err.error.responseMessage || err.message;
    //                 notify("err", errorMessage);

    //             })
    //     } else {
    //         notify("err", "Please select at least one new brand for add in featured brand")

    //     }

    // }
    render() {
        let id = this.props?.match?.params?.exId ? this.props.match.params.exId : ''
        const { isLoader, productListData } = this.state;

        return (
            <>
                <div className="dash_body_container">
                    {isLoader && <Loader />}
                    <Header />
                    <Sidebar />

                    <>
                        <div class="header-text d-flex mb-4 mt-3">
                            <div class="d-flex flex-grow-1 align-items-center">
                                <p className="header-icon"><Brands /></p><h4>Jobs</h4>
                            </div>
                            <div class="search_wrapper  mr-2">
                                <i class="fa fa-search"></i>
                                <input type="search" placeholder="Search..." name="search"
                                    onChange={this.handleSearch}
                                />
                            </div>
                            <div className="">
                                <button onClick={this.onClickAssign} className="btn btn-blue-large mr-2">Assign Job</button>
                                <a onClick = {()=> this.props.history.goBack()}> <button className="btn btn-light"><i class="fa fa-arrow-left" aria-hidden="true"></i>Back</button></a>
                            </div>
                        </div>

                    </>

                    <div class="table-responsive">
                        <table class="table table-bordered rounded-table">
                            <thead>
                                <tr>
                                    <th>Select</th>
                                    <th>Job</th>
                                    <th>Business</th>
                                    <th>Business Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                {productListData?.map((item, index) => {
                                    return (
                                        <tr key={item._id}>
                                            <td>
                                                    <div className="check_wrapper">
                                                        {
                                                            item?.spotLightData ? <input type="checkbox"
                                                                readOnly
                                                                checked={true}
                                                                // onClick={() => this.handleSelect(item?._id, item)}

                                                                value="option1"
                                                            /> :
                                                                <input type="checkbox"
                                                                    onClick={() => this.handleSelect(item?._id, item)}

                                                                    value="option1"
                                                                />
                                                        }


                                                        <span />
                                                    </div>

                                                </td>

                                            <td> <Link to={`/job-details/${item?._id}`} > {item?.productName}</Link></td>
                                    <td> {item?.brandId?.companyName}</td>
                                    <td> {item?.userPlan == 3 ? "Premium brand showcase" : "Brand showcase"}</td>
                                        </tr>
                                    )
                                })

                                }
                            </tbody>
                        </table>

                        <div>
                            {!isLoader && productListData && productListData.length == 0 &&
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

export default ProductList;
