import React from 'react'
import { CircleCross } from '../../../SvgIcon';
import { _Api } from '../../../services/Api';
import Url from '../../../services/BaseUrl';
import { notify } from '../../../components/Toaster';
import InlineError from '../../../components/InlineError';
import { Select } from 'antd'

import { MySpinner } from "../../../components/index"


const { Option } = Select;
class ReportUserModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false


        }
    }
    onChnage = (e) => {
        this.setState({ [e.target.name]: e.target.value, descriptionError: '' });
    }
    onSubmit = (e) => {
        if (!this.state.description || (this.state.description.trim() == '')) {
            this.setState({ descriptionError: "Description can not empty" })
        }
        else {
            let params = {
                reportedUserId: this.props.userData._id? this.props.userData._id :  this.props.userData.userId,
                reportedMsg: this.state.description

            }

            _Api(Url.GET_CONNECTION_ADDREPORT.method, Url.GET_CONNECTION_ADDREPORT.url, params)
                .then(resp => {
                    notify("success", "User Reported Successfully");
                    this.props.closePopup();
                }).catch(err => {
                    let errorMessage = err.error && err.error.responseMessage || err.message;
                    notify("err", errorMessage);

                })

        }

    }
    render() {
        let { firstName, lastName, _id, id } = this.props.userData;
        let { descriptionError, description } = this.state;
        return (
            <div className='popup'>
                <div className='popup_inner small form'>
                    <div className="mb-4 mt-4">
                        <div className="rounded-textbox-form">

                            <div className="header-text">
                                <h4>Report User</h4>
                            </div>

                            <div className="form-group">
                                <level>{firstName} {lastName}</level>

                            </div>

                            <div className="form-group">
                                <level>Message</level>
                                <textarea type="text"
                                    className="form-control textarea"
                                    placeholder="Description"
                                    rows="5"
                                    maxLength={500}
                                    autoComplete="off"
                                    name="description"
                                    value={description}
                                    onChange={this.onChnage}


                                />
                                <InlineError
                                    message={descriptionError}
                                />
                            </div>

                            <div className="text-center pt-4">
                                <button className="btn btn-red" onClick={this.onSubmit} >Report
                                &nbsp; {this.state.loading && <MySpinner />}
                                </button>
                            </div>

                            <button className="close-btn" onClick={this.props.closePopup}><CircleCross /></button>


                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ReportUserModal